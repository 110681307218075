import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/dist/utils'
import invariant from 'tiny-invariant'
import { AggregatedCreditNote } from 'CreditNotes/types'
import { creditNote } from 'CreditNotes/ports'
import { useEffect, useState } from 'react'

export const useAggregatedCreditNote = (
  creditNoteId: string
): {
  creditNote: AggregatedCreditNote | null
} => {
  const [aggregatedCreditNote, setAggregatedCreditNote] =
    useState<AggregatedCreditNote | null>(null)

  invariant(creditNoteId, 'Credit note ID is required')

  const creditNoteQuery = useQuery(dashboardv99990101Client.getCreditNote, {
    id: creditNoteId
  })

  const lineItemsQuery = useQuery(
    dashboardv99990101Client.getCreditNoteLineItems,
    {
      creditNoteId: creditNoteId
    }
  )

  const groupsQuery = useQuery(
    dashboardv99990101Client.getCreditNoteLineItemGroups,
    {
      creditNoteId: creditNoteId
    }
  )

  // If we don't wait for all data to settle, we end up having flashes of old data when one part of
  // the aggregate is returned from the API and the others are pending.
  useEffect(() => {
    if (
      !creditNoteQuery.data ||
      !groupsQuery.data ||
      !lineItemsQuery.data ||
      creditNoteQuery.isRefetching ||
      groupsQuery.isRefetching ||
      lineItemsQuery.isRefetching
    ) {
      return
    }

    setAggregatedCreditNote(
      creditNote.fromServer(
        creditNoteQuery.data,
        groupsQuery.data.items,
        lineItemsQuery.data.items
      )
    )
  }, [
    creditNoteQuery.data,
    groupsQuery.data,
    lineItemsQuery.data,
    creditNoteQuery.isRefetching,
    groupsQuery.isRefetching,
    lineItemsQuery.isRefetching
  ])

  return {
    creditNote: aggregatedCreditNote
  }
}
