import { Box, Checkbox, Icon, InputGroup, Link, Text } from '@chakra-ui/react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import {
  GreyGrey20,
  GreyGrey70,
  IndigoIndigo50,
  Lato14Bold,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  NumberField,
  PriceField,
  SelectField,
  TextField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo, useState } from 'react'
import { useSeatBasedPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/SeatBasedPrice/useSeatBasedPriceForm'
import { useCommonFieldsForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/CommonFields/useCommonFieldsForm'
import {
  AvailableStandardFrequency,
  BillingType,
  SeatBasedProrationStrategy,
  SeatOveragesBillingFrequency
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { SeatBasedTierInput } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/inputs/SeatBasedTierInput/SeatBasedTierInput'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import SeatProrationDropdown from 'common/drawers/PricingEditor/view/editors/SeatBasedPrice/SeatProrationDropdown/SeatProrationDropdown.tsx'

export const SeatBasedPriceForm = () => {
  const { fieldsConfig: fieldsConfigCommon } = useCommonFieldsForm()
  const { fieldsConfig: fieldsConfigSeatBased, currency } =
    useSeatBasedPriceForm()
  const flags = useFlags()
  const currencyOptions = useMemo(
    () =>
      fieldsConfigCommon.currency.options.map(option => ({
        ...option,
        label: option.label.split(' ')[0]
      })),
    [fieldsConfigCommon.currency.options]
  )
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)

  const isInAdvanceSelected = () =>
    fieldsConfigSeatBased.billingType.value == 'IN_ADVANCE'
  const isContractedMinimumSet = () =>
    parseInt(fieldsConfigSeatBased.minimumSeats.value) > 0
  const isBillingFrequencyAnnuallyOrQuarterly = () =>
    isInAdvanceSelected() &&
    ['QUARTERLY', 'YEARLY'].includes(
      fieldsConfigSeatBased.billingFrequency.value
    )
  const isProrateStrategyIncrements = () =>
    fieldsConfigSeatBased.prorationStrategy.value === 'PRORATE_INCREMENTS'

  const [showMinimumSeats, setShowMinimumSeats] = useState(
    isContractedMinimumSet
  )

  const toolTipBoxes =
    fieldsConfigSeatBased.overagesBillingFrequency.options.map(value => (
      <>
        <Box {...Lato14Bold} marginBottom="8px">
          {value.label}
        </Box>
        <Box marginBottom="16px">{value.description}</Box>
      </>
    ))

  return (
    <Box paddingBottom="8px">
      <SelectFieldWithActions
        data-testid="seatBased.seatType"
        label="Seat metric"
        options={fieldsConfigSeatBased.seatTypeId.options}
        validationErrors={fieldsConfigSeatBased.seatTypeId.validationErrors}
        value={fieldsConfigSeatBased.seatTypeId.value}
        onChange={newValue =>
          fieldsConfigSeatBased.seatTypeId.onChange(newValue)
        }
        isDisabled={fieldsConfigSeatBased.seatTypeId.disabled}
        placeholder="Select seat metric"
        onAddNew={fieldsConfigSeatBased.seatTypeId.onAddNew}
      />

      <SelectField
        label="Billing frequency"
        value={fieldsConfigSeatBased.billingFrequency.value}
        options={fieldsConfigSeatBased.billingFrequency.options}
        onChange={newValue =>
          fieldsConfigSeatBased.billingFrequency.onChange(
            newValue as AvailableStandardFrequency
          )
        }
        validationErrors={
          fieldsConfigSeatBased.billingFrequency.validationErrors
        }
        isDisabled={fieldsConfigSeatBased.billingFrequency.disabled}
        placeholder="Select billing frequency"
      />

      {flags.inAdvanceSeats && (
        <Checkbox
          isChecked={isInAdvanceSelected()}
          isDisabled={fieldsConfigSeatBased.billingType.disabled}
          onChange={() => {
            const newValue = isInAdvanceSelected() ? 'IN_ARREARS' : 'IN_ADVANCE'
            fieldsConfigSeatBased.billingType.onChange(newValue as BillingType)
          }}
          placeItems="start"
        >
          <Box>
            <Text {...Lato14Bold}>Bill in advance</Text>
            <Text {...Lato14ExtendedRegular} color="inherit">
              Choose in advance to charge customers upfront. By default
              customers are charged in arrears.{' '}
              <Link
                href="https://docs.sequencehq.com"
                isExternal
                color={IndigoIndigo50}
              >
                Learn more
              </Link>
            </Text>
          </Box>
        </Checkbox>
      )}

      <Box mt={4} />

      {!fieldsConfigSeatBased.pricePerSeat.hidden ? (
        <InputGroup display="flex" alignItems="center">
          <PriceField
            data-testid="seatBased.pricePerSeat"
            label="Seat Price"
            value={fieldsConfigSeatBased.pricePerSeat.value}
            onChange={newValue =>
              fieldsConfigSeatBased.pricePerSeat.onChange(newValue)
            }
            validationErrors={
              fieldsConfigSeatBased.pricePerSeat.validationErrors
            }
            currency={currency}
            isDisabled={fieldsConfigSeatBased.pricePerSeat.disabled}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
          />
          <Box mt={6}>
            <SelectField
              data-testid="common-field-currency"
              value={fieldsConfigCommon.currency.value}
              onChange={newCurrency =>
                fieldsConfigCommon.currency.onChange(newCurrency as Currency)
              }
              options={currencyOptions}
              validationErrors={fieldsConfigCommon.currency.validationErrors}
              isDisabled={fieldsConfigCommon.currency.disabled}
              placeholder="Select currency"
              width="150px"
              inputStyle={{
                borderLeft: 'none',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
            />
          </Box>
        </InputGroup>
      ) : (
        <Box marginBottom="16px" paddingBottom="8px" width="100%">
          <Box display="flex" gap={4} alignItems="center">
            <Box {...Lato14Bold}>Pricing Tiers</Box>
            <Box mt={4}>
              <SelectField
                data-testid="common-field-currency"
                value={fieldsConfigCommon.currency.value}
                onChange={newCurrency =>
                  fieldsConfigCommon.currency.onChange(newCurrency as Currency)
                }
                options={currencyOptions}
                validationErrors={fieldsConfigCommon.currency.validationErrors}
                isDisabled={fieldsConfigCommon.currency.disabled}
                placeholder="Select currency"
                width="90px"
                height="24px"
                backgroundColor={GreyGrey20}
              />
            </Box>
          </Box>
          <SeatBasedTierInput
            value={fieldsConfigSeatBased.tiers.value}
            onChange={fieldsConfigSeatBased.tiers.onChange}
            isDisabled={fieldsConfigSeatBased.tiers.disabled}
            currency={currency}
            validationErrors={fieldsConfigSeatBased.tiers.validationErrors}
          />
        </Box>
      )}

      <Checkbox
        disabled={fieldsConfigSeatBased.minimumSeats.disabled}
        isChecked={showMinimumSeats}
        onChange={() => {
          const newValue = !showMinimumSeats

          if (!newValue) {
            fieldsConfigSeatBased.minimumSeats.onChange('0')
          }

          setShowMinimumSeats(newValue)
        }}
        size="md"
        spacing="8px"
      >
        <Text {...Lato14Bold}>Contract a minimum number of seats</Text>
      </Checkbox>
      {showMinimumSeats && (
        <Box display="flex" gap="8px" mt="16px">
          <NumberField
            label=""
            value={fieldsConfigSeatBased.minimumSeats.value}
            precision={0}
            onChange={newValue =>
              fieldsConfigSeatBased.minimumSeats.onChange(newValue)
            }
            validationErrors={
              fieldsConfigSeatBased.minimumSeats.validationErrors
            }
            isDisabled={fieldsConfigSeatBased.minimumSeats.disabled}
            width="100px"
          />
          <Text {...Lato14ExtendedRegular} color={GreyGrey70} pt={2}>
            Included
          </Text>
        </Box>
      )}

      <Box mt={3} />

      <Box
        display="flex"
        gap={2}
        my={3}
        cursor="pointer"
        onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
        data-testid="priceForm.additionalOptions"
      >
        <Text {...Lato14Bold} color="#5F5CFF">
          Additional options
        </Text>
        {showAdditionalOptions ? (
          <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
        ) : (
          <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
        )}
      </Box>

      {showAdditionalOptions && (
        <>
          {flags.inAdvanceSeats &&
            fieldsConfigSeatBased.overagesBillingFrequency.value &&
            isInAdvanceSelected() &&
            isBillingFrequencyAnnuallyOrQuarterly() && (
              <HorizontalSelectorField
                label="Overages billing frequency"
                data-testid="seatBased.overagesBillingFrequency"
                tooltip={{
                  title: 'Overages billing frequency',
                  content: <Box paddingTop="8px">{toolTipBoxes}</Box>
                }}
                value={fieldsConfigSeatBased.overagesBillingFrequency.value}
                onChange={newValue =>
                  fieldsConfigSeatBased.overagesBillingFrequency.onChange(
                    newValue as SeatOveragesBillingFrequency
                  )
                }
                options={fieldsConfigSeatBased.overagesBillingFrequency.options}
                validationErrors={
                  fieldsConfigSeatBased.overagesBillingFrequency
                    .validationErrors
                }
                isDisabled={
                  fieldsConfigSeatBased.overagesBillingFrequency.disabled
                }
              />
            )}
          {!flags.useSeatsProrateAllChanges ? (
            <Checkbox
              display="flex"
              gap={2}
              alignItems="flex-start"
              isChecked={isProrateStrategyIncrements()}
              isDisabled={fieldsConfigSeatBased.prorationStrategy.disabled}
              onChange={() => {
                const newValue = isProrateStrategyIncrements()
                  ? 'USE_MAXIMUM'
                  : 'PRORATE_INCREMENTS'
                fieldsConfigSeatBased.prorationStrategy.onChange(
                  newValue as SeatBasedProrationStrategy
                )
              }}
            >
              <Box>
                <Text {...Lato14Bold}>
                  Prorate overage fees for added seats
                </Text>
                <Text {...Lato14ExtendedRegular} color="inherit">
                  Charge a prorated overage fee for seats added mid-billing
                  period (default). Disable to charge the full price for added
                  seats.{' '}
                  <Link
                    href="https://docs.sequencehq.com"
                    isExternal
                    color={IndigoIndigo50}
                  >
                    Learn more
                  </Link>
                </Text>
              </Box>
            </Checkbox>
          ) : (
            <SeatProrationDropdown
              value={fieldsConfigSeatBased.prorationStrategy.value}
              onChange={fieldsConfigSeatBased.prorationStrategy.onChange}
            />
          )}
        </>
      )}
    </Box>
  )
}
