import {
  Button as ChakraButton,
  forwardRef,
  IconButton
} from '@chakra-ui/react'
import { CSSProperties, FC } from 'react'

type PropsWithChildren = {
  children: React.ReactNode
  leadingIcon?: React.ReactElement
  trailingIcon?: React.ReactElement
}
type PropsWithIcon = { icon: React.ReactElement; 'aria-label': string }
type Props = {
  variant?: 'primary' | 'secondary' | 'ghost' | 'destructive' | 'unstyled'
  onClick?: () => void
  disabled?: boolean
  size?: string
  style?: CSSProperties
  id?: string
  'data-testid'?: string
} & (PropsWithChildren | PropsWithIcon)

export const Button: FC<Props> = forwardRef<Props, 'button'>((props, ref) => {
  const {
    variant = 'primary',
    children,
    onClick,
    disabled = false,
    size,
    style,
    id,
    'data-testid': dataTestId
  } = props

  const sharedProps = {
    id,
    onClick,
    variant: `component-library-${
      variant === 'destructive' ? 'warning' : variant
    }`,
    ref,
    isDisabled: disabled,
    width: size,
    height: size,
    style,
    'data-testid': dataTestId
  }

  if (isIconButtonProps(props)) {
    return (
      <IconButton
        {...sharedProps}
        aria-label={props['aria-label']}
        icon={props.icon}
        width={size ?? '32px'}
        height={size ?? '32px'}
      />
    )
  }

  return (
    <ChakraButton
      {...sharedProps}
      type="button"
      leftIcon={props.leadingIcon}
      rightIcon={props.trailingIcon}
    >
      {children}
    </ChakraButton>
  )
})

const isIconButtonProps = (props: Props): props is PropsWithIcon =>
  'icon' in props
