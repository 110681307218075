import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Text } from '@chakra-ui/react'

type ChevronButtonProps = {
  disabled?: boolean
  onClick: () => void
  children: string
}

export const ChevronButton = ({
  children,
  onClick,
  disabled
}: ChevronButtonProps) => (
  <Button
    variant="plain"
    px={0}
    justifyContent="space-between"
    onClick={onClick}
    tabIndex={-1}
    isDisabled={disabled}
    padding="8px 0"
    _hover={{
      opacity: 0.6
    }}
  >
    <Text fontSize="14px" lineHeight="16px">
      {children}
    </Text>
    <ChevronRightIcon />
  </Button>
)
