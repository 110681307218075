import { NEW_PRICE_PATTERN } from 'modules/Cube/domain/cube.constants'
import {
  DedupedPrice,
  usePriceDeduplication
} from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/usePriceDeduplication'
import { useCallback } from 'react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

export type v1ApiNewPrice = Dashboardv20240509Api.PostPrice.PriceBody & {
  id: string
}
export type v1ApiPrice = Dashboardv20240509Api.PostPrice.Price

type UseSavePrices = () => {
  createPrices: (
    newPrices: v1ApiNewPrice[]
  ) => Promise<{ temporaryId: string; price: v1ApiPrice }[] | null>
}

export const useSavePrices: UseSavePrices = () => {
  const { dedupePrices } = usePriceDeduplication()

  const createPrices = useCallback(
    async (
      newPrices: v1ApiNewPrice[]
    ): Promise<{ temporaryId: string; price: v1ApiPrice }[] | null> => {
      const dedupeResult = await dedupePrices(newPrices)

      const response = (
        await Promise.all(
          dedupeResult.map(async (dedupedPrice: DedupedPrice) => {
            /**
             * If we found an existing match, we don't need to create a new price id,
             * and we can instead return the price we matched to.
             */
            if (!dedupedPrice.price.id.match(NEW_PRICE_PATTERN)) {
              return Promise.resolve(
                dedupedPrice.matchingNewPrices.map(matchingNewPrice => ({
                  temporaryId: matchingNewPrice.id,
                  price: dedupedPrice.price
                }))
              )
            }

            const priceResponse = await dashboardv20240509Client.postPrice({
              body: dedupedPrice.price
            })

            if (priceResponse.error || !priceResponse.data) {
              return null
            }

            return dedupedPrice.matchingNewPrices.map(matchingNewPrice => ({
              temporaryId: matchingNewPrice.id,
              price: priceResponse.data as v1ApiNewPrice
            }))
          })
        )
      ).flatMap(result => result)

      if (response.some(price => !price)) {
        return null
      }

      return response.filter(
        (newPrice): newPrice is { temporaryId: string; price: v1ApiPrice } =>
          Boolean(newPrice?.price)
      )
    },
    [dedupePrices]
  )

  return {
    createPrices
  }
}
