import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { ValidationResult } from 'modules/Cube/domain/cube.domain.types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type UseQuoteEditorContent = () => {
  title: {
    value: string
    disabled: boolean
    onChange: (newTitle: string) => void
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  }
  validationErrors: ValidationResult[]
}

export const useTitleEditor: UseQuoteEditorContent = () => {
  const cubeContext = useCubeContext()

  /**
   * `allowSync` is used to prevent the `syncTitleToDomain` from being called when non-input events that change
   * the quote title occur, for example, when a published quote title is changed and those changes are discarded.
   */
  const [localState, setLocalState] = useState({
    title: cubeContext.queries.rawData.data.common.title ?? '',
    allowSync: false
  })

  const syncTitleToDomain = useDebouncedCallback((newTitle: string) => {
    cubeContext.mutators.updateData({ common: { title: newTitle } })
    setLocalState(prev => ({ ...prev, allowSync: false }))
  }, 500)

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      /**
       * Move to the Blocknote editor when pressing return from the title editor
       */
      if (event.key === 'Enter') {
        const blockNoteContentEditable = document.querySelector(
          '.ProseMirror'
        ) as HTMLDivElement

        if (!blockNoteContentEditable) {
          return
        }

        blockNoteContentEditable.focus()
      }
    },
    []
  )

  const title = useMemo(() => {
    return {
      value: localState.title,
      disabled:
        !cubeContext.queries.availableFeatures.quote.edit.available.enabled,
      onChange: (newTitle: string) => {
        setLocalState(prev => ({ ...prev, title: newTitle, allowSync: true }))
      },
      onKeyDown
    }
  }, [
    localState.title,
    cubeContext.queries.availableFeatures.quote.edit.available.enabled,
    onKeyDown
  ])

  /**
   * Sync domain to the title if it changes, since the domain is still
   * ultimately the primary source of truth, so if it changes we should reflect
   * it here.
   */
  useEffect(() => {
    setLocalState({
      title: cubeContext.queries.rawData.data.common.title ?? '',
      allowSync: false
    })
  }, [cubeContext.queries.rawData.data.common.title])

  /** Sync the title to the domain! */
  useEffect(() => {
    if (
      localState.title !== cubeContext.queries.rawData.data.common.title &&
      localState.allowSync
    ) {
      syncTitleToDomain(localState.title)
    }
  }, [
    localState.title,
    cubeContext.queries.rawData.data.common.title,
    syncTitleToDomain,
    localState.allowSync
  ])

  return {
    title,
    validationErrors:
      cubeContext.queries.validation.activeValidationResults?.common.title ?? []
  }
}
