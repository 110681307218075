import { Grid } from '@chakra-ui/react'
import { useStytchB2BClient, useStytchMember } from '@stytch/react/dist/b2b'
import { Member } from '@stytch/vanilla-js'
import Spinner from 'components/Loading/Spinner'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react'

type Action = 'view' | 'edit' | 'invite' | 'revoke' | 'delete'

type Filter = 'all' | 'active' | 'invited'
export interface TeamSettingsState {
  action: Action
  setAction: (action: Action) => void
  members: Member[]
  refetchMembers: () => void
  user: Member
  filter: Filter
  setFilter: (filter: Filter) => void
  selectedMember?: Member
  setSelectedMember: (member?: Member) => void
}

export const TeamSettings = createContext<TeamSettingsState | null>(null)

export const TeamSettingsProvider = ({ children }: PropsWithChildren) => {
  const stytch = useStytchB2BClient()
  const [action, setAction] = useState<Action>('view')
  const [filter, setFilter] = useState<Filter>('all')
  const [selectedMember, setSelectedMember] = useState<Member>()
  const { member: user } = useStytchMember()
  const [members, setMembers] = useState<{
    loaded: boolean
    members: Member[]
  }>({ loaded: false, members: [] })

  const fetchMembers = useCallback(async () => {
    const resp = await stytch.organization.members.search({})
    setMembers({ members: resp.members, loaded: true })
  }, [stytch.organization.members])

  useEffect(() => {
    if (!members.loaded) {
      void fetchMembers()
    }
  }, [fetchMembers, members.loaded, stytch.organization.members])

  if (!members.loaded || !user) {
    return (
      <Grid height="100vh" placeItems="center" width="100%">
        <Spinner />
      </Grid>
    )
  }

  return (
    <TeamSettings.Provider
      value={{
        action,
        setAction,
        members: members.members,
        refetchMembers: () => void fetchMembers(),
        user,
        filter,
        setFilter,
        selectedMember,
        setSelectedMember
      }}
    >
      {children}
    </TeamSettings.Provider>
  )
}
