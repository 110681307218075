import { FC } from 'react'
import { SelectField } from 'components/FormFields'
import { required, skip } from '@sequencehq/validation'
import { Country, TaxRateModel, TaxStatus } from '@sequencehq/core-models'

type TaxRateFieldProps = {
  customerCountry: Country | undefined
  customerTaxStatus: TaxStatus
  isLoading: boolean
  taxRates: TaxRateModel[]
}

export const TaxRateField: FC<TaxRateFieldProps> = ({
  customerCountry,
  customerTaxStatus,
  isLoading,
  taxRates
}) => {
  return (
    <SelectField
      fieldName="taxRateId"
      fieldLabel="Tax rate"
      validate={customerTaxStatus !== 'TAX_EXEMPT' ? required : skip}
      options={taxRates
        .filter(({ country }) => country === customerCountry)
        .map(({ id, name }) => {
          return {
            value: id,
            label: name
          }
        })}
      placeholder={isLoading ? 'Loading...' : 'Choose tax rate'}
    ></SelectField>
  )
}
