import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { AvailableServices } from 'modules/Integrations/domain'
import { useCallback, useMemo, useState } from 'react'

export const useIntegrationsContent = (props: {
  defaultService?: AvailableServices
}) => {
  const ctx = useIntegrationsDomainContext()
  const [selectedIntegration, setSelectedIntegration] =
    useState<AvailableServices | null>(props.defaultService ?? null)

  const showFeaturedIntegrations = useMemo(() => {
    return Boolean(ctx.queries.featuredIntegrations.length)
  }, [ctx.queries.featuredIntegrations])

  const selectIntegration = useCallback((integration: AvailableServices) => {
    setSelectedIntegration(integration)
  }, [])

  const activeResolvedIntegration = useMemo(() => {
    if (!selectedIntegration) {
      return null
    }
    return ctx.queries.resolvedIntegrations[selectedIntegration]
  }, [ctx.queries.resolvedIntegrations, selectedIntegration])

  return {
    showFeaturedIntegrations,
    activeIntegration: activeResolvedIntegration,
    selectIntegration
  }
}
