import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './dropboxSignLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { DropboxSignIntegrationDetail } from './DropboxSignIntegrationDetail'

export const dropboxSignIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/cpq/esignature',
  title: 'Dropbox Sign',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: true
  },
  description:
    'Automatically collect payments from customers and reconcile Sequence invoices automatically.',
  components: {
    detail: DropboxSignIntegrationDetail,
    management: NoConfiguration
  }
}
