import { Flex, Text } from '@chakra-ui/react'
import { Member } from '@stytch/vanilla-js'
import { MemberActionsButton } from 'components/TeamSettings/common/ActionMenuButton'
import { MemberBadge } from 'components/TeamSettings/common/MemberBadge'
import { MemberRowView } from 'components/TeamSettings/types'

export const generateMemberViewData = (
  members: Member[],
  userId: string,
  canDeleteMembers = false,
  onResendInvite: (member: Member) => Promise<void>,
  onRevokeInvite: (member: Member) => void
) => {
  return members.map(
    member =>
      ({
        email: (
          <Flex gap={2} alignItems="center">
            <Text>{member.email_address}</Text>
            {member.member_id === userId && (
              <MemberBadge status="currentUser" />
            )}
          </Flex>
        ),
        status: <MemberBadge status={member.status} />,
        actions:
          member.member_id !== userId && canDeleteMembers ? (
            <MemberActionsButton
              member={member}
              onResendInvite={onResendInvite}
              onRevokeInvite={onRevokeInvite}
            />
          ) : null
      } as MemberRowView)
  )
}
