import { AvailableStandardFrequency } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

export const defaultAvailableFrequenciesOptions: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'MONTHLY',
    label: 'Monthly'
  },
  {
    value: 'QUARTERLY',
    label: 'Quarterly'
  },
  {
    value: 'YEARLY',
    label: 'Annually'
  }
]
