import { Box, Text } from '@chakra-ui/react'
import { IdentificationIcon } from '@heroicons/react/16/solid'
import { useQuery } from '@sequencehq/api/utils'
import {
  Pill,
  PillProps,
  PopoverWrapper,
  PreviewCard,
  SequenceKebabMenu
} from '@sequencehq/core-components'
import { Lato13Regular, RedRed50 } from '@sequencehq/design-tokens'
import { getPortalDomain } from '@sequencehq/utils'
import { Customer } from 'modules/Customer/types'
import { EditCustomerModalForm } from 'components/EditCustomer'
import { getLogoSrc } from 'Customer/utils/logo'
import { openOverlay } from 'features/overlay'
import { formatApiAddress } from 'lib/address'
import { getEnvironment } from 'lib/environment/environment'
import { FC, SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { match } from 'ts-pattern'
import { useDispatch } from 'features/store'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

type PreviewCardCustomer = Pick<
  Customer,
  'id' | 'legalName' | 'address' | 'archivedAt' | 'domain'
>

type Props =
  | {
      customerId: string
    }
  | {
      customer: PreviewCardCustomer
    }

function shouldUseQuery(props: Props): props is { customerId: string } {
  return 'customerId' in props
}

const useCustomerForPreviewCard = (props: Props) => {
  const shouldQuery = shouldUseQuery(props)

  const { data } = useQuery(
    dashboardv99990101Client.getCustomer,
    {
      id: shouldQuery ? props.customerId : ''
    },
    { enabled: shouldQuery, staleTime: 1000 * 20 }
  )

  const customer = shouldQuery ? data : props.customer

  return customer
}

type CustomerPreviewCardState = 'default' | 'edit'
export const CustomerPreviewCard: FC<Props> = props => {
  const customer = useCustomerForPreviewCard(props)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const portalDomain = getPortalDomain(getEnvironment())

  const [state, setState] = useState<CustomerPreviewCardState>('default')

  return (
    <>
      <CustomerPreviewCardUI
        customer={customer}
        onClickViewInPortal={() => {
          if (customer) {
            window.open(`${portalDomain}/customers/${customer.id}`, '_blank')
          }
        }}
        onClickView={() => {
          if (customer) {
            navigate(`/customers/${customer.id}`)
          }
        }}
        onClickEdit={() => {
          setState('edit')
        }}
        onClickArchive={() => {
          if (customer) {
            dispatch(
              openOverlay({
                content: 'archiveCustomerModal',
                data: {
                  customer
                }
              })
            )
          }
        }}
      />
      {match(state)
        .with('edit', () => {
          if (!customer) {
            return null
          }
          return (
            <EditCustomerModalForm
              customerId={customer.id}
              onClose={() => setState('default')}
            />
          )
        })
        .otherwise(() => null)}
    </>
  )
}

export const CustomerPreviewCardPill: FC<
  Props & { variant?: PillProps['variant'] }
> = ({ variant, ...props }) => {
  const customer = useCustomerForPreviewCard(props)
  const navigate = useNavigate()

  if (!customer) {
    return null
  }

  return (
    <PopoverWrapper
      popover={<CustomerPreviewCard {...props} />}
      offset={[-4, 4]}
      openDelay={850}
    >
      <CustomerPreviewCardPillUI
        logoSrc={getLogoSrc(customer)}
        name={customer?.legalName ?? 'Unknown'}
        variant={variant}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          navigate(`/customers/${customer.id}`)
        }}
      />
    </PopoverWrapper>
  )
}

const CustomerPreviewCardUI: FC<{
  customer: PreviewCardCustomer | null | undefined
  onClickView: () => void
  onClickViewInPortal: () => void
  onClickEdit: () => void
  onClickArchive: () => void
}> = ({
  customer,
  onClickView,
  onClickViewInPortal,
  onClickEdit,
  onClickArchive
}) => {
  if (!customer) {
    return null
  }

  const address = formatApiAddress(customer.address)

  return (
    <PreviewCard
      title={`${customer.legalName}${customer.archivedAt ? ' (archived)' : ''}`}
      subtitle={
        <div>
          {address.map((addressPart, i) => (
            <Text key={i} {...Lato13Regular} lineHeight="18px">
              {addressPart}
            </Text>
          ))}
        </div>
      }
      logoSrc={getLogoSrc(customer)}
      button={{
        label: 'View customer',
        onClick: onClickView
      }}
      kebabMenu={
        <SequenceKebabMenu
          items={[
            {
              label: 'View in customer portal',
              onClick: withoutPropagation(onClickViewInPortal)
            },
            {
              label: 'Edit customer',
              onClick: withoutPropagation(onClickEdit),
              divider: true
            },
            {
              label: 'Archive customer',
              onClick: withoutPropagation(onClickArchive),
              style: { color: RedRed50 }
            }
          ]}
        />
      }
    />
  )
}

const withoutPropagation = (fn: () => void) => (e: SyntheticEvent) => {
  e.stopPropagation()
  fn()
}

const CustomerPreviewCardPillUI: FC<{
  name: string
  logoSrc?: string
  variant?: PillProps['variant']
  onClick: (e: React.MouseEvent) => void
}> = ({ name, logoSrc, variant, onClick }) => {
  const icon = logoSrc ? (
    <Box
      width={4}
      height={4}
      backgroundImage={`url(${logoSrc}), none`}
      backgroundPosition="center center"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
    />
  ) : (
    <IdentificationIcon width={16} height={16} />
  )
  return (
    <Pill icon={icon} interactive variant={variant} onClick={onClick}>
      {name}
    </Pill>
  )
}
