import { FC } from 'react'
import { required } from '@sequencehq/validation'
import {
  CompoundInputFieldNew,
  CustomSelectNew,
  CustomSelectNewProps,
  InfoPopoverProps
} from '@sequencehq/forms'
import { useNavigate } from 'react-router-dom'
import { useUsageMetric } from 'components/CreateUsageMetric'
import { useFetchUsageMetrics } from 'lib/hooks/useFetchUsageMetrics.ts'
import { UsageMetricModel } from '@sequencehq/core-models'

type UsageMetricInputProps = {
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
}

export const UsageMetricInput: FC<UsageMetricInputProps> = ({
  disabled,
  ...rest
}) => {
  const navigate = useNavigate()
  const { state, dispatch } = useUsageMetric()

  const usageMetricsResult = useFetchUsageMetrics()

  const { usageMetricId, usageMetric } = state
  const usageMetrics = usageMetricsResult.metrics ?? []

  return (
    <CompoundInputFieldNew<CustomSelectNewProps<UsageMetricModel>>
      fieldLabel="Usage metric"
      items={usageMetrics}
      itemToString={item =>
        item ? `${item.name} ${item.aggregationType}` : ''
      }
      itemToContent={item => ({
        title: `${item.name} ${item.aggregationType}`
      })}
      validate={required}
      placeholder={
        usageMetricsResult.error
          ? 'Failed to load'
          : usageMetricsResult.loading
          ? 'Loading ...'
          : 'Choose usage metric'
      }
      disabled={
        disabled || !!usageMetricsResult.error || usageMetricsResult.loading
      }
      Component={CustomSelectNew}
      addNewAction={() => navigate('metrics/new')}
      borderRadius="6px"
      modelId={usageMetricId}
      model={usageMetric ?? null}
      setModelId={id => {
        dispatch({ type: 'setUsageMetricId', usageMetricId: id })
      }}
      {...rest}
    />
  )
}
