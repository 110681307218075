import { required } from '@sequencehq/validation'
import { useState } from 'react'
import { useField } from 'react-final-form'

export const useDateRange = () => {
  const { input: startDateInput, meta: startDateMeta } = useField<Date>(
    'startDate',
    {
      validate: required
    }
  )

  const { input: endDateInput, meta: endDateMeta } = useField<Date>('endDate', {
    validate: required
  })

  const selectedDates: [Date | undefined, Date | undefined] = [
    startDateInput.value,
    endDateInput.value
  ]

  const [popoverOpen, setPopoverOpen] = useState(false)

  return {
    startDateInput,
    endDateInput,
    startDateMeta,
    endDateMeta,
    selectedDates,
    popoverOpen,
    setPopoverOpen
  }
}
