import { useCallback, useEffect, useState } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems.ts'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'

export const useFetchSeatMetrics = () => {
  const [seatMetrics, setSeatMetrics] = useState<
    DashboardApi20240730.GetSeatMetrics.SeatMetric[] | null
  >(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const loadMetrics = useLoadAllItems(dashboard20240730Client.getSeatMetrics)

  const fetchMetrics = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const metricsData = await loadMetrics()

      if (metricsData) {
        setSeatMetrics(metricsData)
      }
    } catch (e) {
      setError(e as Error)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchMetrics()
  }, [fetchMetrics])

  return { metrics: seatMetrics, loading, error, refetch: fetchMetrics }
}
