import { Flex } from '@chakra-ui/react'
import { NavigationV3Provider } from 'components/NavigationV3/NavigationV3Provider'
import { Container } from 'components/NavigationV3/components/Container/Container'
import { Header } from 'components/NavigationV3/components/Header/Header'
import { Footer } from 'components/NavigationV3/components/Footer/Footer'
import { NavLinks } from 'components/NavigationV3/components/NavLinks/NavLinks'
import { IntegrationsNavigation } from 'modules/Integrations'

export const NavigationV3 = () => {
  return (
    <NavigationV3Provider>
      <Container>
        <Flex direction="column" width="100%">
          <Header />
          <NavLinks />
          <IntegrationsNavigation />
        </Flex>

        <Footer />
      </Container>
    </NavigationV3Provider>
  )
}
