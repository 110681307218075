import { durationsToDateAdapter } from '@sequencehq/utils/dates'
import { add } from '@sequencehq/utils/dates'
import { CubeReducerState } from 'modules/Cube/domain/cube.domain.types'

export const getTotalPricingDuration = (prevState: CubeReducerState) => {
  /**
   * Given we have a nice adapter for durations, the most convenient way
   * to get back a sum of durations (a nicely formatted one, too) is to
   * transform into an absolute, and transform back!
   *
   * Durations are always variable, in terms of absolute time, based on the
   * start date of the duration. Even years are not immune from this (leap years!).
   * Therefore we will always produce a slightly different result when using
   * custom durations (in particular, with days) based on the start date used. This
   * is a completely unavoidable part of datetime, and probably why we shouldn't ever
   * make custom durations an option when quoting unless the user has selected a start
   * date.
   */
  const scheduleStartDate = prevState.data.common.startDate ?? new Date()
  const orderedPhases = prevState.data.common.phaseIds.map(
    id => prevState.data.phases[id]
  )
  const resolvedPhaseDates = orderedPhases.reduce(
    (acc, phase, idx) => {
      const previousPhaseEndDate = idx > 0 ? acc[idx - 1]?.end : undefined
      const previousPhaseEndDatePlusDay = previousPhaseEndDate
        ? add(previousPhaseEndDate, { days: 1 })
        : undefined

      const absolutePhaseStartDate =
        idx === 0 ? scheduleStartDate : previousPhaseEndDatePlusDay

      return [
        ...acc,
        {
          start: absolutePhaseStartDate,
          end:
            phase.duration && absolutePhaseStartDate
              ? add(absolutePhaseStartDate, phase.duration)
              : undefined
        }
      ]
    },
    [] as Array<{
      start: Date | undefined
      end: Date | undefined
    }>
  )

  if (resolvedPhaseDates.length === 0) {
    return undefined
  }

  const finalPhaseEndDate =
    resolvedPhaseDates[resolvedPhaseDates.length - 1].end

  if (!finalPhaseEndDate) {
    return undefined
  }

  return durationsToDateAdapter.toDuration(scheduleStartDate)(finalPhaseEndDate)
}
