import { useLoadPricingEditor } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/useLoadPricingEditor/useLoadPricingEditor'
import {
  PricingEditorData,
  usePricingEditor
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/usePricingEditor'
import {
  PricingEditorProps,
  PricingEditorMode
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { pricingEditorLoaderToEditor } from 'modules/Cube/view/common/drawers/priceEditor/drawer/adapters/pricingEditor.adapters'
import { useEffect, useMemo } from 'react'
import { dequal } from 'dequal'
import { NEW_PRODUCT_PATTERN } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import { STANDARD_AVAILABLE_FREQUENCIES } from 'modules/Cube/domain/cube.constants'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useFlags } from 'launchdarkly-react-client-sdk'

type UsePricingEditorRoot = (props: PricingEditorProps) => {
  pricingEditor: PricingEditorData
}

export const usePricingEditorRoot: UsePricingEditorRoot = props => {
  const notifications = useNotifications()
  const flags = useFlags()

  const isWorkingWithANewProduct = Boolean(
    props.mode === PricingEditorMode.ADD_PRODUCT ||
      props.productId?.match(NEW_PRODUCT_PATTERN)
  )

  const loader = useLoadPricingEditor({
    customer: props.customer,
    productId: props.productId,
    currency: props.scheduleCurrency,
    frequencies: props.availableFrequencies ?? STANDARD_AVAILABLE_FREQUENCIES,
    skip: {
      prices: Boolean(isWorkingWithANewProduct),
      product: Boolean(isWorkingWithANewProduct || props.existingData?.product)
    }
  })

  const pricingEditor = usePricingEditor({
    onSave: props.onSave,
    onClose: props.onClose
  })

  const transformedApiData = useMemo(() => {
    if (!loader.data || loader.error || loader.loading) {
      return
    }

    if (props.mode === PricingEditorMode.ADD_PRODUCT) {
      return pricingEditorLoaderToEditor({
        enableListPrices: flags.enableListPrices
      })({
        loadedData: {
          ...loader.data,
          product: {
            id: `new-product::${crypto.randomUUID()}`,
            name: '',
            label: ''
          },
          prices: []
        },
        mode: props.mode,
        priceId: props.existingData?.price?.id,
        availableFrequencies:
          props.availableFrequencies ?? STANDARD_AVAILABLE_FREQUENCIES,
        currency: props.scheduleCurrency,
        availableFields: props.availableFields,
        disabledFields: props.disabledFields,
        defaultPricingType: props.pricingModelForNewPrice
      })
    }

    return pricingEditorLoaderToEditor({
      enableListPrices: flags.enableListPrices
    })({
      loadedData: {
        ...loader.data,
        product: props.existingData?.product ?? loader.data.product,
        prices: [
          ...(props.existingData?.productPrices ?? []),
          ...loader.data.prices,
          ...(props.existingData?.price ? [props.existingData?.price] : [])
        ],
        listPrices: props.existingData?.listPrices ?? []
      },
      mode: props.mode,
      priceId: props.existingData?.price?.id,
      availableFrequencies:
        props.availableFrequencies ?? STANDARD_AVAILABLE_FREQUENCIES,
      currency: props.scheduleCurrency,
      availableFields: props.availableFields,
      disabledFields: props.disabledFields,
      defaultPricingType: props.pricingModelForNewPrice,
      listPriceId: props.listPriceId
    })
  }, [loader, props, flags])

  useEffect(() => {
    if (
      pricingEditor.editor.loaded &&
      (props.productId === pricingEditor.data.product?.id || !props.productId)
    ) {
      return
    }

    if (!transformedApiData) {
      return
    }

    if (!transformedApiData.configuration.availableCurrencies.length) {
      notifications.displayNotification(
        'No available currencies are configured, cannot edit prices',
        {
          type: 'error'
        }
      )
      props.onClose()
    }

    pricingEditor.functions.loadPricingEditor(transformedApiData)
  }, [pricingEditor, props, transformedApiData, notifications])

  /**
   * This use effect keeps the internal store in sync with any new data
   * supporting data (e.g. metrics) pushed to the store that may change
   * during editing.
   */
  useEffect(() => {
    if (!pricingEditor.editor.loaded || !transformedApiData) {
      return
    }

    if (!dequal(transformedApiData.data.metrics, pricingEditor.data.metrics)) {
      pricingEditor.functions.updateData({
        metrics: transformedApiData.data.metrics
      })
    }
    pricingEditor
  }, [pricingEditor, props, transformedApiData])

  return {
    pricingEditor
  }
}
