import { Box, Center, Text } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey30,
  GreyGrey60,
  Lato13Bold
} from '@sequencehq/design-tokens'

interface EmptyStateProps {
  label: string
  Icon: JSX.Element
}

export const EmptyState = ({ Icon, label }: EmptyStateProps) => {
  return (
    <Box
      borderRadius="6px"
      width="100%"
      height="80%"
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      backgroundColor={GreyGrey10}
      gap="12px"
    >
      <Center
        borderRadius="6px"
        width="24px"
        height="24px"
        backgroundColor={GreyGrey30}
      >
        {Icon}
      </Center>
      <Text {...Lato13Bold} color={GreyGrey60}>
        {label}
      </Text>
    </Box>
  )
}
