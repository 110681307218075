import { StandardLayout } from '@sequencehq/core-components'
import { JournalReportsContent } from './content/JournalReportsContent'
import { JournalReportsHeader } from './JournalReportsHeader'

export const JournalReports = () => {
  return (
    <StandardLayout
      header={<JournalReportsHeader />}
      content={<JournalReportsContent />}
    />
  )
}
