import { FC } from 'react'

import { Box } from '@chakra-ui/react'

import { SwitchLineInput, TextField } from '@sequencehq/core-components'
import { Label } from '@sequencehq/forms'

import { CustomerContactFieldsConfig } from './useContactModalForm'

interface Props {
  fieldsConfig: CustomerContactFieldsConfig
  hideLabels?: boolean
}

export const ContactModalForm: FC<Props> = ({
  fieldsConfig,
  hideLabels = false
}) => {
  return (
    <>
      <Box mb={4}>
        {!hideLabels && <Label>Name</Label>}
        <TextField
          data-testid="contact.name"
          value={fieldsConfig.name.value}
          validationErrors={fieldsConfig.name.validationErrors}
          onChange={fieldsConfig.name.onChange}
          placeholder="John Doe"
        />
      </Box>

      <Box mb={4}>
        {!hideLabels && <Label>Email</Label>}
        <TextField
          data-testid="contact.email"
          value={fieldsConfig.email.value}
          onChange={fieldsConfig.email.onChange}
          validationErrors={fieldsConfig.email.validationErrors}
          placeholder="email@example.com"
          data-1p-ignore
          data-lpignore
        />
      </Box>

      {fieldsConfig.billingPreference.value !== 'PRIMARY' && (
        <SwitchLineInput
          value={fieldsConfig.billingPreference.value !== 'NONE'}
          onChange={checked => {
            if (checked) {
              fieldsConfig.billingPreference.onChange('STANDARD')
            } else {
              fieldsConfig.billingPreference.onChange('NONE')
            }
          }}
          text="Send invoice notifications and payment reminders to this recipient"
        />
      )}
    </>
  )
}
