import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'

export type v1ApiCreateListPriceData = Omit<
  Dashboardv20240509Api.GetListPrice.ListPrice,
  'id' | 'createdAt'
>

export type v1ApiCreateListPriceResult =
  Dashboardv20240509Api.PostListPrice.Response['data']

type CreateListPrice = (data: v1ApiCreateListPriceData) => Promise<{
  success: boolean
  error: PricingEditorPortErrors | null
  data: v1ApiCreateListPriceResult
}>

export const createListPrice: CreateListPrice = async (
  data: v1ApiCreateListPriceData
) => {
  const res = await dashboardv20240509Client.postListPrice({
    body: data
  })

  return {
    success: !res.error,
    error: res.error ? PricingEditorPortErrors.Other : null,
    data: res.data
  }
}
