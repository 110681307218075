import { Text } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'

export const BigQueryIntegrationDetail = () => {
  return (
    <Text {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
      Export all your Sequence data to a wide range of destinations, such as
      data warehouses and Google Sheets. Use data exports to connect your
      billing and invoicing data to other internal sources, leverage your
      existing data tools for analysis and reports, and build custom
      applications.
    </Text>
  )
}
