import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

type FlaggedRouteProps = {
  flag: string
  children: ReactNode
}

export const FlaggedRoute: FC<FlaggedRouteProps> = ({ flag, children }) => {
  const flags = useFlags()

  return flags?.[flag] ? children : <Navigate to="/home" />
}
