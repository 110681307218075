import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, useCallback } from 'react'
import { CustomerContactsRoot } from 'CustomerContacts/index'
import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext'
import { EditCustomerForm } from 'Customer/drawers/EditCustomer/EditCustomerForm'

type EditCustomerModalFormProps = {
  customerId: string
  onClose?: () => void
}

export const EditCustomerModalForm: FC<EditCustomerModalFormProps> = ({
  customerId,
  onClose
}) => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(closeOverlay())
    onClose?.()
  }, [dispatch])

  return (
    <CustomerContextProvider customerId={customerId}>
      <CustomerContactsRoot customerId={customerId}>
        <EditCustomerForm onSuccess={close} onClose={close} />
      </CustomerContactsRoot>
    </CustomerContextProvider>
  )
}
