import { PricingEditorRoot } from 'common/drawers/PricingEditor/view/pricingEditor.root'
import { EditorMode as PriceEditorMode } from 'common/drawers/PricingEditor/domain'
import { useNewAddProductOutletConnector } from './hooks/useNewAddProductOutletConnector'

export const NewAddProductRouteComponent = () => {
  const { onSuccess } = useNewAddProductOutletConnector()

  return (
    <PricingEditorRoot
      variant="list"
      initialMode={PriceEditorMode.ADD_PRODUCT}
      onSave={onSuccess}
    />
  )
}
