import { Box } from '@chakra-ui/react'
import {
  CheckboxGroupField,
  HorizontalSelectorField,
  PriceField
} from '@sequencehq/core-components'
import { useMinimumEditorForm } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/content/MinimumEditorForm/useMinimumEditorForm'
import { MinimumScopeTarget } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'

export const MinimumEditorForm = () => {
  const formHook = useMinimumEditorForm()

  return (
    <Box padding="24px">
      <PriceField
        label="Minimum amount"
        {...formHook.fieldConfig.value}
        currency={formHook.currency}
      />
      <HorizontalSelectorField
        isDisabled={formHook.fieldConfig['scope.target'].disabled}
        label="Apply across"
        {...formHook.fieldConfig['scope.target']}
        onChange={newValue =>
          formHook.fieldConfig['scope.target'].onChange(
            newValue as MinimumScopeTarget
          )
        }
      />
      {!formHook.fieldConfig['scope.priceIds'].hidden && (
        <CheckboxGroupField {...formHook.fieldConfig['scope.priceIds']} />
      )}
    </Box>
  )
}
