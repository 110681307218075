import { FC } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import CtaCard from 'components/InsightsV2/widgets/InsightsTemplates/CtaCard'

export const InsightsTemplates: FC = () => {
  const dataCategories = [
    {
      id: 'billing-schedule',
      title: 'Billing Schedules',
      description:
        'Billing Schedules generate customer invoices based on the attached products and pricing.'
    },
    {
      id: 'active-schedules-count',
      title: 'Active Schedules Count',
      description:
        'A calculated metric reporting the monthly count of active billing schedules.'
    },
    {
      id: 'invoices',
      title: 'Invoices',
      description:
        'Invoices show charges and payment information for a billing period.'
    },
    {
      id: 'credit-notes',
      title: 'Credit Notes',
      description:
        'Credit notes are adjustments against issued invoices that need to be corrected.'
    },
    {
      id: 'usage-metrics',
      title: 'Usage Metrics',
      description:
        'Usage Metrics aggregate multiple events into overall usage measures (e.g. total API calls or total payment amounts).'
    },
    {
      id: 'usage-events-count',
      title: 'Usage Events Aggregated',
      description:
        'Usage Events represent aggregated units of usage you charge for (e.g. Total API calls or payments counts).'
    },
    {
      id: 'line-items',
      title: 'Line Items',
      description: `Individual products listed separately on a customer's invoice.`
    },
    {
      id: 'revenue',
      title: 'Revenue',
      description:
        'Calculated metric reporting the total revenue amount over time by customer, product and currency.'
    },
    {
      id: 'revenue-bridge',
      title: 'Revenue Bridge',
      description:
        'Categorization of revenue changes across different time periods.'
    },
    {
      id: 'customers',
      title: 'Customers',
      description: 'The customers you bill using Sequence.'
    },
    {
      id: 'gross-invoiced-amount',
      title: 'Gross Invoiced Amount',
      description:
        'A calculated metric reporting the total gross invoiced amount over time, by customer and currency.'
    },
    {
      id: 'net-invoiced-amount',
      title: 'Net Invoiced Amount',
      description:
        'A calculated metric reporting the total net invoiced amount over time, by customer and currency.'
    }
  ]

  const ctaCards = [
    {
      template: 'ALL' as const,
      title: 'Blank template',
      copyLink:
        'https://docs.google.com/spreadsheets/d/1eF92SGzDSL41vRth_8Vbh_eXbfV22sdW3NkrN1K1N1U/copy',
      image: '/img/raw-templates.png',
      imageAlt: 'Blank template',
      icon: '/img/raw-templates-icon.svg',
      description:
        'Build any analysis on top of live Sequence data from scratch. Data is synced daily.',
      availableExportData: dataCategories
    },
    {
      template: 'REVENUE_ANALYTICS' as const,
      title: 'Revenue analytics template',
      copyLink:
        'https://docs.google.com/spreadsheets/d/1l8jcMF0DU1hj9SCaSfUO457F50wTmlo6g4HQ0zEyhAI/copy',
      exampleLink:
        'https://docs.google.com/spreadsheets/d/1uA1u5BUIagAYSyVWKZyR-x2e-RWZfTFdE3XUNReHRtM/edit?usp=sharing',
      image: '/img/accounts-receivables-templates.png',
      imageAlt: 'Revenue Analytics Template',
      icon: '/img/accounts-receivables-templates-icon.svg',
      description: `Gain insights into your revenues and understand changes over time. Data is synced daily.`,
      availableExportData: dataCategories.filter(
        dataCategory =>
          dataCategory.id === 'invoices' ||
          dataCategory.id === 'customers' ||
          dataCategory.id === 'active-schedules-count' ||
          dataCategory.id === 'revenue' ||
          dataCategory.id === 'revenue-bridge'
      )
    },
    {
      template: 'USAGE_ANALYTICS' as const,
      title: 'Usage analytics template',
      copyLink:
        'https://docs.google.com/spreadsheets/d/1fJlyKbWLFvAEIFOzQR2RqCZFYgffsF-OHgcuhtHQAS8/copy',
      exampleLink:
        'https://docs.google.com/spreadsheets/d/1F71MHtj0tJkj4_SCtRJqy9STC46sLZiSjKqssfllc_Q/edit?usp=sharing',
      image: '/img/usage-templates.png',
      imageAlt: 'Usage Analytics Template',
      icon: '/img/usage-templates-icon.svg',
      description:
        'Analyze usage patterns and spot anomalies and trends. Data is synced daily.',
      availableExportData: dataCategories.filter(
        dataCategory =>
          dataCategory.id === 'usage-events-count' ||
          dataCategory.id === 'usage-metrics'
      )
    },
    {
      template: 'SALES_COMMISSIONS' as const,
      title: 'Sales commission template',
      copyLink:
        'https://docs.google.com/spreadsheets/d/17Cc7zU_K6xOU1s4IjaHYDomx3c-DR7TUkEZDSZmTXlo/copy',
      exampleLink:
        'https://docs.google.com/spreadsheets/d/1B1aPHvd1XVCcAyTf3x43PuaiRSof6lhvDv0ydv7tLoA/edit?usp=sharing',
      image: '/img/sales-templates.png',
      imageAlt: 'Sales Commission Template',
      icon: '/img/sales-templates-icon.svg',
      description:
        'Calculate revenue-based commissions and track your won deals revenues. Data is synced daily.',
      availableExportData: dataCategories.filter(
        dataCategory =>
          dataCategory.id === 'customers' ||
          dataCategory.id === 'invoices' ||
          dataCategory.id === 'billing-schedule'
      )
    },
    {
      template: 'AR_ANALYTICS' as const,
      title: 'Accounts receivable template',
      copyLink:
        'https://docs.google.com/spreadsheets/d/15lppotITdjHABrPzUWquZOberQNxFopxm0t2Bguq5X8/copy',
      exampleLink:
        'https://docs.google.com/spreadsheets/d/1_SWxiSLB31FOoENfV2qj72UQuY2-UAU-VArjf43sFhY/edit?usp=sharing',
      image: '/img/accounts-receivables-templates.png',
      imageAlt: 'Accounts Receivables Template',
      icon: '/img/accounts-receivables-templates-icon.svg',
      description: `Gain insights into your accounts receivable and spot uncollected revenues. Data is synced daily.`,
      availableExportData: dataCategories.filter(
        dataCategory =>
          dataCategory.id === 'invoices' || dataCategory.id === 'customers'
      )
    }
  ]

  return (
    <>
      <Flex flexDirection="column" p="24px">
        <Text textStyle="sectionHeader" mb="8px">
          Live Google Sheets Exports
        </Text>
        <Text textStyle="default">
          Create live reports on top of your Sequence data using a simple
          spreadsheet interface.
        </Text>
      </Flex>
      <Flex p="24px" flexWrap="wrap">
        {ctaCards.map(ctaCard => (
          <CtaCard
            key={ctaCard.title}
            template={ctaCard.template}
            title={ctaCard.title}
            copyLink={ctaCard.copyLink}
            exampleLink={ctaCard.exampleLink}
            image={ctaCard.image}
            imageAlt={ctaCard.imageAlt}
            icon={ctaCard.icon}
            description={ctaCard.description}
            availableExportData={ctaCard.availableExportData}
          />
        ))}
      </Flex>
    </>
  )
}

export default InsightsTemplates
