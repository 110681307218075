import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { FC } from 'react'

type ConfirmExecuteQuoteModalProps = {
  onClose: () => void
  onConfirm: () => void
  submitting: boolean
}

export const ConfirmExecuteQuoteModal: FC<
  ConfirmExecuteQuoteModalProps
> = props => {
  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
          top="118px"
          width="414px"
          maxWidth="414px"
        >
          <Box color={GreyGrey100} {...Lato14Bold} marginBottom="4px">
            Create billing schedule
          </Box>
          <Box {...Lato13Regular} color={GreyGrey80} marginBottom="16px">
            You’re about to convert this quote into a draft billing schedule.
          </Box>
          <Flex gap="8px" justifyContent="flex-end">
            <Button
              variant="secondary2"
              onClick={props.onClose}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              isLoading={props.submitting}
              onClick={props.onConfirm}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              Create billing schedule
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
