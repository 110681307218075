import { useStandardPriceForm } from './useStandardPriceForm'
import {
  TextField,
  SelectField,
  HorizontalSelectorField,
  PriceField
} from '@sequencehq/core-components'
import { PricingInformation } from '../common/PricingInformation'
import { Box, Icon, Text } from '@chakra-ui/react'
import {
  AvailableStandardFrequency,
  BillingType
} from 'common/drawers/PricingEditor/domain'
import { Lato14Bold } from '@sequencehq/design-tokens'
import { useState } from 'react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const StandardPriceVariantPriceForm = () => {
  const { fieldsConfig, priceDetails } = useStandardPriceForm()
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)

  return (
    <>
      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <SelectField
        label="List price"
        data-testid="standard.listPriceId"
        value={fieldsConfig.listPriceId.value ?? ''}
        onChange={fieldsConfig.listPriceId.onChange}
        options={fieldsConfig.listPriceId.options}
        validationErrors={fieldsConfig.listPriceId.validationErrors}
        isDisabled={fieldsConfig.listPriceId.disabled}
        placeholder="Select list price"
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="standard.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      {!fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="standard.currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}

      <Box paddingBottom="8px">
        <PriceField
          label="Price"
          data-testid="standard.price"
          value={fieldsConfig.price.value}
          onChange={newValue => fieldsConfig.price.onChange(newValue)}
          validationErrors={fieldsConfig.price.validationErrors}
          currency={fieldsConfig.currency.value}
          isDisabled={fieldsConfig.price.disabled}
        />
      </Box>

      <ExternalLedgerForm />

      <Box
        display="flex"
        gap="4px"
        my="16px"
        padding="8px 0px"
        cursor="pointer"
        onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
      >
        <Text {...Lato14Bold} color="#5F5CFF">
          Show additional fields
        </Text>
        {showAdditionalOptions ? (
          <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
        ) : (
          <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
        )}
      </Box>
      <Box
        width="100%"
        marginBottom="16px"
        visibility={showAdditionalOptions ? 'visible' : 'hidden'}
      >
        <PricingInformation
          value={fieldsConfig.pricingModel.value}
          isDisabled={fieldsConfig.pricingModel.disabled}
          options={fieldsConfig.pricingModel.options}
          validationErrors={fieldsConfig.pricingModel.validationErrors}
          onChange={fieldsConfig.pricingModel.onChange}
          description={fieldsConfig.pricingModel.description}
        />

        <HorizontalSelectorField
          label="Frequency"
          data-testid="standard.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <HorizontalSelectorField
          label="Type"
          data-testid="standard.billingType"
          tooltip={{
            title: 'Billing type',
            content: (
              <Box paddingTop="8px">
                <Box {...Lato14Bold} marginBottom="8px">
                  In arrears
                </Box>
                <Box marginBottom="16px">
                  Charges will be applied at the end of the billing period
                </Box>
                <Box {...Lato14Bold} marginBottom="8px">
                  In advance
                </Box>
                <Box>
                  Charges will be applied at the start of the billing period
                </Box>
              </Box>
            )
          }}
          value={fieldsConfig.billingType.value}
          onChange={newValue =>
            fieldsConfig.billingType.onChange(newValue as BillingType)
          }
          options={fieldsConfig.billingType.options}
          validationErrors={fieldsConfig.billingType.validationErrors}
          isDisabled={fieldsConfig.billingType.disabled}
        />
      </Box>
    </>
  )
}
