import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import { HubSpotConfig } from 'modules/Integrations/integrationsConfig/hubSpot/hubspot.integration.config'

export const hubSpotConfigurationMutator = async (
  data: HubSpotConfig
): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  const enabledRes = await dashboardv20240509Client.putIntegration({
    service: 'HubSpot',
    body: {
      enabled: data.importCustomerWorkflow,
      enabledPipelineStages: undefined
    }
  })

  if (enabledRes.error) {
    return {
      success: false,
      error: IntegrationsPortErrors.Other
    }
  }

  return {
    success: true,
    error: null
  }
}
