import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey30,
  GreyGrey60,
  Lato13Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  RadioGroupField,
  TextAreaField
} from '@sequencehq/core-components'
import {
  FeedbackType,
  IssueSeverity,
  useShareFeedbackModal
} from 'components/ShareFeedbackModal/useShareFeedbackModal'
import { FC } from 'react'

export const ShareFeedbackModal: FC<{
  onClose: () => void
}> = props => {
  const { queries, mutators } = useShareFeedbackModal({
    onClose: props.onClose
  })

  return (
    <Modal isOpen onClose={mutators.closeModal} isCentered>
      <ModalOverlay>
        <ModalContent
          maxWidth="490px"
          backgroundColor={GreyGrey0}
          borderRadius="8px"
        >
          <ModalHeader
            padding="20 24px"
            borderBottom={`1px solid ${GreyGrey30}`}
            display="flex"
            {...Lato16Bold}
          >
            Share feedback
          </ModalHeader>

          <ModalBody padding="24px" display="flex" flexDirection="column">
            <HorizontalSelectorField
              styles={{
                wrapper: {
                  marginBottom: '24px'
                }
              }}
              {...queries.fieldsConfig.feedbackType}
              label=""
              onChange={value =>
                queries.fieldsConfig.feedbackType.onChange(
                  value as FeedbackType
                )
              }
            />
            {queries.feedbackType === 'issue' && (
              <RadioGroupField
                styles={{
                  wrapper: {
                    marginBottom: '24px'
                  }
                }}
                {...queries.fieldsConfig.issueSeverity}
                label=""
                value={queries.fieldsConfig.issueSeverity.value ?? ''}
                onChange={value =>
                  queries.fieldsConfig.issueSeverity.onChange(
                    value as IssueSeverity
                  )
                }
              />
            )}
            <TextAreaField
              styles={{
                wrapper: {
                  marginBottom: '8px'
                }
              }}
              {...queries.fieldsConfig.message}
              placeholder={
                queries.feedbackType === 'feedback'
                  ? 'Type your feedback here...'
                  : 'Give details about your issue here'
              }
            />
            <Box {...Lato13Regular} color={GreyGrey60}>
              Your feedback will be shared with the team and we'll get back to
              you as soon as possible.
            </Box>
          </ModalBody>
          <ModalFooter
            padding="12px"
            borderTop={`1px solid ${GreyGrey30}`}
            height="auto"
            display="flex"
            justifyContent="flex-end"
            gap="8px"
          >
            <Button variant="secondary2" onClick={mutators.closeModal}>
              Cancel
            </Button>
            <Button
              variant="primary2"
              isDisabled={queries.isSaving}
              onClick={() => {
                mutators.saveFeedback().catch(e => {
                  throw e
                })
              }}
            >
              Share feedback
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
