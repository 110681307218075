import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dashboard/v20240730'
import { useCallback } from 'react'

type Body = DashboardApi20240730.PostTaxRegistration.TaxRegistrationBody & {
  customerId: string
}
type UseAddTaxRegistration = () => {
  addTaxRegistration: (
    addTaxRegistrationBody: Body
  ) => Promise<DashboardApi20240730.PostTaxRegistration.TaxRegistration | null>
}

export const useAddTaxRegistration: UseAddTaxRegistration = () => {
  const addTaxRegistration = useCallback(
    async (addTaxRegistrationBody: Body) => {
      const response = await dashboard20240730Client
        .postTaxRegistration({
          body: addTaxRegistrationBody
        })
        .then(result => {
          if (result?.error) {
            return null
          }

          return result?.data
        })
      return response
    },
    []
  )
  return {
    addTaxRegistration
  }
}
