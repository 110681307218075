import { stytchClient } from 'lib/auth/stytch'
import { apiQueryClient } from './apiQueryClient'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { isDemoEnv } from 'lib/environment/environment'

export const initializeApiClients = () => {
  /**
   * Set a placeholder organisationId when none was found - this ensures
   * the unscoped data remains 'scoped' away from other organisations, and
   * makes it so that integration tests are running in a representative
   * caching environment too.
   */
  const organizationId =
    stytchClient.session.getSync()?.organization_id ??
    'no-organisation-id-found'
  dashboardv99990101Client.initialize({
    isDemoEnv: isDemoEnv(),
    demoApiKey: import.meta.env.VITE_DEMO_API_KEY,
    apiQueryClient,
    baseQueryKey: organizationId
  })
  dashboardv20240509Client.initialize({
    isDemoEnv: isDemoEnv(),
    demoApiKey: import.meta.env.VITE_DEMO_API_KEY,
    apiQueryClient,
    baseQueryKey: organizationId
  })
  dashboard20240730Client.initialize({
    isDemoEnv: isDemoEnv(),
    demoApiKey: import.meta.env.VITE_DEMO_API_KEY,
    apiQueryClient,
    baseQueryKey: organizationId
  })
}
