import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'

export const useCustomer = () => {
  const cubeContext = useCubeContext()

  const { customers } = cubeContext.queries.rawData.data

  const customerId = cubeContext.queries.rawData.data.common.customerId
  return customers[customerId]
}
