import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { NewListPriceEditorProps } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { NEW_PRICE_PATTERN } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { EditorMode as PriceEditorMode } from 'modules/common/drawers/PricingEditor/domain'
import { ListPrice } from 'Products/types'

export const useNewListPriceEditorConnector = (): NewListPriceEditorProps => {
  const params = useParams<{
    productId: string
    listPriceId: string
  }>()
  const productContext = useProductContext()

  const initialPriceEditorMode = useMemo(() => {
    if (!params.listPriceId || params.listPriceId.match(NEW_PRICE_PATTERN)) {
      return PriceEditorMode.CREATE
    }

    return PriceEditorMode.EDIT
  }, [params.listPriceId])

  const onSave = useCallback(
    (newData: { price: ListPrice }) =>
      productContext.functions.updateListPriceState(newData.price),
    [productContext.functions]
  )

  return {
    initialPriceEditorMode,
    onSave
  }
}
