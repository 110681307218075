import { Box, Flex, IconButton, Button } from '@chakra-ui/react'
import { TextInputField, Label, InfoPopoverProps } from '@sequencehq/forms'
import { FieldArray } from 'react-final-form-arrays'
import { CrossIcon } from '@sequencehq/core-components'
import { UsageEventPropertyInput } from 'components/CreateUsageMetric/UsageEventPropertyInput'
import { FC } from 'react'
import { SelectField } from 'components/FormFields'
import { metricPropertyMatchersOptions } from '@sequencehq/core-models'

type EventProperty = {
  name: string
  value: string
  comparisonType: 'is' | 'isNot'
}

type EventPropertyListProps = {
  fieldName: string
  addPropertyLabel: string
  infoPopover?: InfoPopoverProps
  preventDuplicateSuggestions?: boolean
  useMatcher?: boolean
}

export const EventPropertyList: FC<EventPropertyListProps> = ({
  fieldName,
  addPropertyLabel,
  infoPopover,
  preventDuplicateSuggestions,
  useMatcher = false
}) => (
  <FieldArray<EventProperty> name={fieldName}>
    {({ fields }) => {
      const filterNames = preventDuplicateSuggestions
        ? fields.value.map(({ name }) => name).filter(Boolean)
        : []

      return (
        <>
          <Flex>
            <Flex mr={2} flex={2}>
              <Label infoPopover={infoPopover}>Event property</Label>
            </Flex>
            {useMatcher && <Flex mr={2} flex={1}></Flex>}
            <Flex flex={2}>
              <Label>Value</Label>
            </Flex>
          </Flex>
          {fields.map((name, index) => (
            <Flex key={index}>
              <Flex mr={2} flex={2}>
                <UsageEventPropertyInput
                  data-testid={name}
                  fieldName={`${name}.name`}
                  filterNames={filterNames}
                />
              </Flex>

              {useMatcher && (
                <Flex mr={2} flex={1}>
                  <SelectField
                    fieldName={`${name}.comparisonType`}
                    fieldLabel=""
                    options={metricPropertyMatchersOptions}
                  />
                </Flex>
              )}

              <Flex flex={2} alignItems="center">
                <TextInputField
                  data-testid={name}
                  fieldName={`${name}.value`}
                />
                {fields.length === 1 ? (
                  <Box />
                ) : (
                  <IconButton
                    aria-label="Delete filter"
                    icon={<CrossIcon />}
                    onClick={() => {
                      fields.remove(index)
                    }}
                    variant="clear"
                    alignSelf={'center'}
                    mt={'8px'}
                  />
                )}
              </Flex>
            </Flex>
          ))}

          <Button
            variant="tertiary"
            onClick={() =>
              fields.push({ name: '', value: '', comparisonType: 'is' })
            }
          >
            {addPropertyLabel}
          </Button>
        </>
      )
    }}
  </FieldArray>
)
