import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext.tsx'
import { CustomerContactsRoot } from 'modules/CustomerContacts'
import { EditCustomerForm } from 'Customer/drawers/EditCustomer/EditCustomerForm.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import { useEditCustomerEditorConnector } from 'Cube/view/common/drawers/editCustomerEditor/useEditCustomerEditor.connector'
import {
  ModalContextProvider,
  SimpleModalUI
} from '@sequencehq/core-components'

export const EditCustomerEditorCubeRoute = () => {
  const navigate = useNavigate()
  const { onCancel, onSuccess } = useEditCustomerEditorConnector()

  const { customerId } = useParams<{ customerId: string }>()

  if (!customerId) {
    navigate('/quotes')
    return <></>
  }

  return (
    <ModalContextProvider isOpen>
      <SimpleModalUI
        variant="drawer"
        title="Edit customer"
        cancelButtonText="Cancel"
        submitButtonText="Update customer"
        onCancel={onCancel}
        submitDisabled
        closeOnSubmit={false}
        onSubmit={onCancel}
      >
        <CustomerContextProvider customerId={customerId}>
          <CustomerContactsRoot customerId={customerId}>
            <EditCustomerForm onSuccess={onSuccess} onClose={onCancel} />
          </CustomerContactsRoot>
        </CustomerContextProvider>
      </SimpleModalUI>
    </ModalContextProvider>
  )
}
