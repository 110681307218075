import { useEffect, useState } from 'react'

import {
  DefaultReactSuggestionItem,
  SuggestionMenuProps
} from '@blocknote/react'
import { Box, Center, Grid } from '@chakra-ui/react'

import {
  BorderRadius4,
  BorderRadius8,
  GreyGrey20,
  GreyGrey30,
  GreyGrey40,
  GreyGrey80,
  GreyWhite,
  Lato13Regular
} from '@sequencehq/design-tokens'

import { useContentEditor } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/useContentEditor'
import { getCustomSuggestionMenuItems } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/CustomSuggestionMenu/menuItems'

export const CustomSuggestionMenu = (
  props: SuggestionMenuProps<DefaultReactSuggestionItem>
) => {
  const hook = useContentEditor()

  const [isMouseOver, setIsMouseOver] = useState<boolean>(false)

  const allMenuItems = getCustomSuggestionMenuItems(
    hook.features.contentEditor.editor,
    hook.features.contentEditor.addPhaseBlock.enabled
  )

  useEffect(() => {
    setIsMouseOver(false)
  }, [props.selectedIndex])

  if (props.items.length === 0) {
    return null
  }

  const showSeparatorAfter = ['Paragraph', 'Numbered list', 'Pricing']

  return (
    <Box
      borderRadius={BorderRadius8}
      border={`1px solid ${GreyGrey40}`}
      width="220px"
      padding="4px"
      boxShadow="0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
      backgroundColor={GreyWhite}
      overflowY="scroll"
      onMouseMove={() => setIsMouseOver(true)}
      onMouseOut={() => setIsMouseOver(false)}
    >
      {props.items.map((item, index) => {
        const disabled =
          item.title === 'Pricing' &&
          !hook.features.contentEditor.addPhaseBlock.enabled

        const showSeparator =
          showSeparatorAfter.includes(item.title) &&
          props.items.length === allMenuItems.length

        return (
          <>
            <Grid
              key={item.title}
              gridTemplateColumns="16px 1fr"
              height="32px"
              padding="8px"
              borderRadius={BorderRadius4}
              alignItems="center"
              gap="8px"
              width="100%"
              onClick={() => !disabled && props.onItemClick?.(item)}
              {...(isMouseOver && {
                _hover: { cursor: 'pointer', backgroundColor: GreyGrey20 }
              })}
              {...(index === props.selectedIndex &&
                !isMouseOver && { background: GreyGrey20 })}
              {...(disabled && { opacity: 0.5 })}
            >
              <Center color={GreyGrey80}>{item.icon}</Center>
              <Box {...Lato13Regular} color={GreyGrey80}>
                {item.title}
              </Box>
            </Grid>

            {showSeparator && (
              <Box
                width="calc(100% + 8px)"
                position="relative"
                left="-4px"
                height="1px"
                background={GreyGrey30}
                marginY="4px"
              />
            )}
          </>
        )
      })}
    </Box>
  )
}
