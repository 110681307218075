import { Grid, GridItem } from '@chakra-ui/react'
import { GreyGrey0 } from '@sequencehq/design-tokens'
import { BillingScheduleHeader } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleHeader/BillingScheduleHeader'
import { PhasedPricingWidget } from 'modules/Cube/view/common/widgets/PricingPhases/PhasedPricingWidget'
import { AdditionalInformationWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/AdditionalInformation/AdditionalInformationWidget'
import { CustomerWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/Customer/CustomerWidget'
import { BillingScheduleInspector } from './BillingScheduleInspector'

export const BillingScheduleLayout = () => {
  return (
    <Grid
      data-testid="schedule.layout"
      templateAreas={`
    "header"
    "content"
    `}
      templateRows={`auto 1fr`}
      columnGap="20px"
      width="100%"
      height="100vh"
      backgroundColor={GreyGrey0}
      overflow="hidden"
    >
      <GridItem gridArea="header">
        <BillingScheduleHeader />
      </GridItem>
      <Grid
        templateColumns="1fr auto"
        templateAreas={`"main sidebar"`}
        gridArea="content"
        height="100%"
        overflow="hidden"
      >
        <GridItem gridArea="main" width="100%" overflow="auto">
          <Grid rowGap="20px" justifyItems="center" padding="24px">
            <CustomerWidget />
            <PhasedPricingWidget />
            <AdditionalInformationWidget />
          </Grid>
        </GridItem>

        <GridItem gridArea="sidebar" zIndex="10">
          <BillingScheduleInspector />
        </GridItem>
      </Grid>
    </Grid>
  )
}
