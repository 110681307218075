import { Flex, GridItem } from '@chakra-ui/react'
import { PriceMeta } from './PriceMeta'
import { TiersTable } from './TiersTable'
import { useReviewForm } from './useReviewForm'
import { AdditionalInfo } from './AdditionalInfo/AdditionalInfo'

export const ReviewForm = () => {
  const { price, product } = useReviewForm()
  return (
    <GridItem
      gridArea="content"
      overflow="auto"
      data-testid="pricingEditor.review"
    >
      <Flex gap="24px" direction="column">
        <PriceMeta price={price} product={product} />
        <TiersTable price={price} />
        <AdditionalInfo price={price} />
      </Flex>
    </GridItem>
  )
}
