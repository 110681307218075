import { FC } from 'react'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { TextField } from '@sequencehq/core-components'
import { useSendTestInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmSendTestInvoiceModal/useSendTestInvoiceModal'

type ConfirmSendTestInvoiceModalProps = {
  onClose: () => void
  onConfirm: (email: string) => void
}

export const ConfirmSendTestInvoiceModal: FC<
  ConfirmSendTestInvoiceModalProps
> = ({ onConfirm, onClose }) => {
  const { fields, handlers, queries } = useSendTestInvoiceModal({
    handlers: { onConfirm }
  })

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Send test invoice
          </Box>
          <Box color={GreyGrey80} {...Lato14ExtendedRegular} marginBottom="8px">
            <TextField
              label="Email address"
              value={fields.email.value}
              onChange={fields.email.onChange}
              validationErrors={fields.email.validationErrors}
            />
          </Box>
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              height="32px"
              width="100%"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              height="32px"
              width="100%"
              isDisabled={!queries.canSend}
              onClick={handlers.send}
            >
              Send
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
