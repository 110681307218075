import {
  BillingType,
  IntegrationExternalId,
  IntegrationService
} from '@sequencehq/core-models'
import { match } from 'ts-pattern'
import { XeroIntegration } from 'lib/hooks/useXeroIntegration.ts'

type ToExternalIdArgs = {
  xeroIntegration?: XeroIntegration
  externalIds?: IntegrationExternalId[]
}

// if we have a price, we want to use the externalIds from that
// otherwise, we want to use the defaultLedgerAccount from each service

export const toExternalIds = ({
  externalIds,
  xeroIntegration
}: ToExternalIdArgs): Partial<Record<IntegrationService, string>> => {
  // If we have a price, we want to use the externalIds from that
  if (externalIds?.length) {
    return externalIds.reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value
      }),
      {}
    )
  }

  return xeroIntegration ? { Xero: xeroIntegration.defaultLedgerAccount } : {}
}

type UpdateXeroExternalIdsArgs = {
  xeroIntegration: XeroIntegration
  externalId?: string
  billingType: BillingType
}

export const updateXeroExternalIds = ({
  xeroIntegration,
  billingType,
  externalId
}: UpdateXeroExternalIdsArgs) => {
  return match(billingType)
    .with('IN_ADVANCE', () => {
      return !externalId || externalId === xeroIntegration.defaultLedgerAccount
        ? xeroIntegration.creditDefaultLedgerAccount
        : externalId
    })
    .with('IN_ARREARS', () => {
      return !externalId ||
        externalId === xeroIntegration.creditDefaultLedgerAccount
        ? xeroIntegration.defaultLedgerAccount
        : externalId
    })
    .otherwise(() => undefined)
}

export const externalIdsMapToArray = (
  externalIdsMap: Partial<Record<IntegrationService, string>>
): IntegrationExternalId[] => {
  return Object.entries(externalIdsMap).map(([key, value]) => ({
    key: key as IntegrationService,
    value
  }))
}
