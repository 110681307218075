import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCallback, useEffect, useState } from 'react'

export type v1ApiQuote = DashboardApi20240730.GetQuotes.QuotesList

type UseGetQuotes = (
  customerId: string,
  limit: number
) => v1ApiQuote | undefined

export const useGetQuotes: UseGetQuotes = (customerId, limit) => {
  const [quotes, setQuotes] = useState<v1ApiQuote | undefined>(undefined)
  const list = useCallback(async () => {
    const quotesResponse = await dashboard20240730Client.getQuotes({
      customerId,
      limit
    })
    const result = quotesResponse.data
    if (result) {
      setQuotes(result)
    }
  }, [])

  useEffect(() => {
    void list()
  }, [customerId, limit])

  return quotes
}
