import { useToast } from '@chakra-ui/react'
import { MutationResultSelectorResult } from '@reduxjs/toolkit/dist/query/core/buildSelectors'
import { UseMutationStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Toast } from '@sequencehq/core-components'
import { BuildMD, QueryParams } from 'lib/types'
import { useEffect, useId } from 'react'

type UseNotifyArgs<Mutation extends QueryParams> = {
  result: UseMutationStateResult<
    BuildMD<Mutation>,
    MutationResultSelectorResult<BuildMD<Mutation>>
  >
  successMessage?: (response: Mutation['response']) => string
  errorMessage?: string
  onSuccess?: (response: Mutation['response']) => void
  onError?: () => void
}

export const useHandleMutationResult = <Mutation extends QueryParams>({
  result,
  successMessage,
  errorMessage,
  onSuccess,
  onError
}: UseNotifyArgs<Mutation>) => {
  const toast = useToast()
  const id = useId()

  const validatedData = result?.isSuccess ? result.data?.value() : undefined

  useEffect(() => {
    if (!validatedData) {
      return
    }

    if (successMessage) {
      toast({
        id: `success-${id}`,
        position: 'bottom',
        duration: 6000,
        render: () => (
          <Toast
            type="success"
            title={successMessage(validatedData)}
            isClosable={true}
          />
        )
      })
    }

    if (onSuccess) {
      onSuccess(validatedData)
    }
  }, [id, validatedData, toast, onSuccess, successMessage])

  useEffect(() => {
    if (result?.isError && errorMessage && !toast.isActive(`error-${id}`)) {
      toast({
        id: `error-${id}`,
        position: 'bottom',
        duration: 6000,
        render: () => (
          <Toast type="error" title={errorMessage} isClosable={true} />
        )
      })
    }
    if (result?.isError && onError) {
      onError()
    }
  }, [errorMessage, id, result?.isError, onError, toast])
}
