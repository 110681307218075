import { FC } from 'react'
import { required } from '@sequencehq/validation'
import { TabSelectField } from 'components/FormFields'

const typeOptions = [
  {
    label: 'Fixed',
    value: 'fixed'
  },
  {
    label: 'Percentage',
    value: 'percentage'
  }
]

interface LineItemRateTypeProps {
  isDisabled?: boolean
}

const LineItemRateTypeInput: FC<LineItemRateTypeProps> = ({ isDisabled }) => (
  <TabSelectField
    fieldName="rateType"
    fieldLabel="Rate Type"
    options={typeOptions}
    validate={required}
    disabled={isDisabled}
  />
)

export default LineItemRateTypeInput
