import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'
import { useQuotePdfDownload } from '@sequencehq/quote-content'
import { useNotifications } from 'lib/hooks/useNotifications'

type UseHeader = (hook: QuoteEditorPreviewProps) => {
  exitBackButton: {
    onClick: () => void
  }
  downloadPdfButton: {
    onClick: () => void
  }
}

export const useHeader: UseHeader = hook => {
  const navigate = useNavigate()

  const fetchPdfQuoteRendererData = useCallback(
    () => hook.pdfRendererData,
    [hook.pdfRendererData]
  )

  const pdfQuoteDownloadHook = useQuotePdfDownload({
    notificationHook: useNotifications,
    fetchPdfQuoteRendererData
  })

  const exitBackButtonOnClick = useCallback(() => {
    navigate(`/quotes/${hook.quoteId}`)
  }, [hook.quoteId, navigate])

  return {
    exitBackButton: {
      onClick: exitBackButtonOnClick
    },
    downloadPdfButton: {
      onClick: () => {
        void pdfQuoteDownloadHook.onClick()
      }
    }
  }
}
