import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  IntegrationService,
  IntegrationCustomer
} from '@sequencehq/core-models'
import { useLazyGetApiIntegrationsCustomersServiceQuery } from 'features/api'
import * as Sentry from '@sentry/react'

type UseLoadIntegrationCustomers = (props: {
  integrationService: IntegrationService
}) => {
  loading: boolean
  data?: {
    integrationCustomers: IntegrationCustomer[]
  }
}

export const useLoadIntegrationCustomers: UseLoadIntegrationCustomers = ({
  integrationService
}) => {
  const [integrationCustomers, setIntegrationCustomers] = useState<
    IntegrationCustomer[] | undefined
  >()
  const [loaded, setLoaded] = useState(false)
  const [fetchIntegrationCustomers, { isLoading }] =
    useLazyGetApiIntegrationsCustomersServiceQuery()

  const loadIntegrationCustomers = useCallback(
    async (service: IntegrationService) =>
      fetchIntegrationCustomers({ service }).then(
        response => response.data?.value()?.items ?? []
      ),
    [fetchIntegrationCustomers]
  )

  useEffect(() => {
    if (integrationService) {
      loadIntegrationCustomers(integrationService)
        .then(loadedIntegrationCustomers => {
          setIntegrationCustomers(loadedIntegrationCustomers)
          setLoaded(true)
        })
        .catch(error => Sentry.captureException(error))
    }
  }, [loadIntegrationCustomers, integrationService])

  const data = useMemo(() => {
    if (isLoading || !integrationCustomers) {
      return
    }

    return {
      integrationCustomers
    }
  }, [isLoading, integrationCustomers])

  return {
    loading: isLoading || !loaded,
    data
  }
}
