import { createToastFn } from '@chakra-ui/react'
import { ServerError } from 'components/Toaster'

const toast = createToastFn('ltr')

type ShowErrorArgs = {
  type: 'Server error' | 'Data validation error'
  message: string | undefined
  sequenceRequestId?: string | null
}

export const showError = ({
  type,
  message,
  sequenceRequestId
}: ShowErrorArgs) => {
  const id = message || sequenceRequestId
  if (id) {
    toast({
      id,
      position: 'bottom-right',
      title: type,
      description: (
        <ServerError message={message} sequenceRequestId={sequenceRequestId} />
      ),
      status: 'error',
      duration: 10000,
      isClosable: true
    })
  }
}
