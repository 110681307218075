import { useCallback } from 'react'
import { useSaveProduct } from 'modules/Products/hooks/useSaveProduct'
import { useUpdateProduct } from 'modules/Products/hooks/useUpdateProduct'
import { useNotifications } from 'lib/hooks/useNotifications'
import { Product } from 'Products/types'

export const useProductRoot = () => {
  const notifications = useNotifications()
  const saveProduct = useSaveProduct()
  const updateProduct = useUpdateProduct()

  const createProduct = useCallback(
    async (product: {
      name: string
      label?: string
      taxCategoryId?: string
      revenueRecognitionMethod?: Product['revenueRecognitionMethod']
    }) => {
      notifications.displayNotification('Creating product...', {
        duration: 30000
      })

      const saveResult = await saveProduct.createProduct({
        name: product.name,
        label: product.label,
        taxCategoryId: product.taxCategoryId,
        revenueRecognitionMethod: product.revenueRecognitionMethod
      })

      if (saveResult) {
        notifications.displayNotification('Product created', {
          type: 'success'
        })
        return saveResult
      } else {
        notifications.displayNotification('Failed to create product', {
          type: 'error'
        })
      }
    },
    [notifications, saveProduct]
  )

  const editProduct = useCallback(
    async (
      id: string,
      product: {
        name: string
        label?: string
        taxCategoryId?: string
        revenueRecognitionMethod?: Product['revenueRecognitionMethod']
      }
    ) => {
      notifications.displayNotification('Updating product...', {
        duration: 30000
      })

      const saveResult = await updateProduct.editProduct(id, {
        name: product.name,
        label: product.label,
        taxCategoryId: product.taxCategoryId,
        revenueRecognitionMethod: product.revenueRecognitionMethod
      })

      if (saveResult) {
        notifications.displayNotification('Product updated', {
          type: 'success'
        })
        return saveResult
      } else {
        notifications.displayNotification('Failed to update product', {
          type: 'error'
        })
      }
    },
    [notifications, updateProduct]
  )

  return {
    functions: {
      createProduct,
      editProduct
    }
  }
}
