import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import { QuickBooksConfig } from 'modules/Integrations/integrationsConfig/quickBooks/quickbooks.integration.config'

export const quickBooksConfigurationLoader = async (): Promise<{
  data: QuickBooksConfig | null
  error: IntegrationsPortErrors | null
}> => {
  const integrationsResponse = await dashboardv20240509Client.getIntegrations()

  if (integrationsResponse.error) {
    return {
      data: null,
      error: IntegrationsPortErrors.Other
    }
  }

  const integration = integrationsResponse.data?.items.find(
    int => int.service === 'QuickBooks_Online'
  )

  return {
    data: {
      importCustomerWorkflow: integration?.enabled ?? false
    },
    error: null
  }
}
