import {
  ActionHandler,
  UpdateConfiguration
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import deepmerge from 'deepmerge'

export const updateConfiguration: ActionHandler<UpdateConfiguration> =
  prevState => action => {
    return {
      ...prevState,
      configuration: deepmerge(prevState.configuration, action.payload)
    }
  }
