import { useMutation } from '@sequencehq/api/utils'
import {
  dashboardv99990101Client,
  Dashboardv99990101Api
} from '@sequencehq/api/dashboard/v99990101'

type CreateCreditNoteLineItemBody =
  Dashboardv99990101Api.PostCreditNoteLineItem.CreateCreditNoteLineItemBody

export const useCreateCreditNoteLineItem = ({
  creditNoteId,
  groupId
}: { creditNoteId: string; groupId: string }) => {
  const createCreditNoteLineItemMutation = useMutation(
    dashboardv99990101Client.postCreditNoteLineItem
  )

  return async (
    input: Omit<
      CreateCreditNoteLineItemBody,
      'creditNoteId' | 'groupId' | 'externalIds'
    >
  ) => {
    await createCreditNoteLineItemMutation.mutateAsync({
      creditNoteId,
      body: {
        creditNoteId,
        groupId,
        title: input.title,
        description: input.description,
        quantity: input.quantity,
        rate: input.rate,
        taxRate: input.taxRate,
        rateDisplay: input.rateDisplay,
        externalIds: []
      }
    })
  }
}
