import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'

export type v1ApiProduct = Dashboardv20240509Api.GetProducts.Product

type UseLoadProducts = () => (props: {
  prices: { productId: string }[]
}) => Promise<{
  products: v1ApiProduct[]
}>

export const useLoadProducts: UseLoadProducts = () => {
  const load = useCallback(
    async ({ prices }: { prices: { productId: string }[] }) => {
      const allProductIds = prices
        .map(price => price.productId)
        .reduce(
          (acc: string[], productId: string) =>
            acc.includes(productId) ? acc : [...acc, productId],
          []
        )

      const products = (
        await Promise.all(
          allProductIds.map(productId =>
            dashboardv20240509Client.getProduct({ id: productId })
          )
        )
      )
        .map(res => res.data)
        .filter((product): product is v1ApiProduct => Boolean(product))

      return {
        products
      }
    },
    []
  )

  return load
}
