import { Tabs, TabList, Tab } from '@chakra-ui/react'
import { Option } from '@sequencehq/core-models'

type TabSelectProps<Value = string, Label = string> = {
  options: Option<Value, Label>[]
  disabled?: boolean
  onChange: (value: Value) => void
  value: string
}

export const TabSelect = <Value extends string, Label extends string>({
  options,
  disabled,
  onChange,
  value,
  ...props
}: TabSelectProps<Value, Label>) => {
  const valueIndex = options.findIndex(option => option.value === value)

  return (
    <Tabs
      isManual
      variant="select-tabs"
      index={valueIndex}
      width="fit-content"
      onChange={index => onChange(options[index].value)}
      {...props}
    >
      <TabList>
        {options.map(option => {
          return (
            <Tab
              key={option.value}
              isDisabled={!!disabled}
              value={option.value}
            >
              {option.label}
            </Tab>
          )
        })}
      </TabList>
    </Tabs>
  )
}
