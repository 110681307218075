import { Box } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  NumberField,
  PriceField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import { usePackagedPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/PackagedPrice/usePackagedPriceForm'
import { UsageMetricParameterFields } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/utils/UsageMetricParameterFields'
import { AvailableStandardFrequency } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

export const PackagedPriceForm = () => {
  const { fieldsConfig, currency } = usePackagedPriceForm()
  return (
    <Box paddingBottom="8px">
      <HorizontalSelectorField
        label="Frequency"
        data-testid="common-field-billingFrequency"
        value={fieldsConfig.billingFrequency.value}
        options={fieldsConfig.billingFrequency.options}
        onChange={newValue =>
          fieldsConfig.billingFrequency.onChange(
            newValue as AvailableStandardFrequency
          )
        }
        validationErrors={fieldsConfig.billingFrequency.validationErrors}
        isDisabled={fieldsConfig.billingFrequency.disabled}
      />
      <SelectFieldWithActions
        label="Usage Metric"
        options={fieldsConfig.usageMetricId.options}
        validationErrors={fieldsConfig.usageMetricId.validationErrors}
        value={fieldsConfig.usageMetricId.value}
        onChange={fieldsConfig.usageMetricId.onChange}
        isDisabled={fieldsConfig.usageMetricId.disabled}
        placeholder="Select usage metric"
        onAddNew={fieldsConfig.usageMetricId.onAddNew}
      />
      <UsageMetricParameterFields
        fieldsConfig={fieldsConfig}
        usageMetricId={fieldsConfig.usageMetricId.value}
      ></UsageMetricParameterFields>
      <PriceField
        label="Price per package"
        validationErrors={fieldsConfig.pricePerPackage.validationErrors}
        value={fieldsConfig.pricePerPackage.value}
        onChange={fieldsConfig.pricePerPackage.onChange}
        isDisabled={fieldsConfig.pricePerPackage.disabled}
        currency={currency}
      />
      <NumberField
        label="Package size"
        validationErrors={fieldsConfig.packageSize.validationErrors}
        precision={0}
        value={fieldsConfig.packageSize.value}
        onChange={fieldsConfig.packageSize.onChange}
        isDisabled={fieldsConfig.packageSize.disabled}
      />
    </Box>
  )
}
