import { CreateUsageEventForm } from 'components/CreateUsageEvent/CreateUsageEventForm'
import Sidebar from 'components/Sidebar'
import { useNavigate } from 'react-router-dom'
import { DrawerForm } from 'components/Modal'
import { usePersistMagicTableQueryParams } from '@sequencehq/tables'
import { useCallback } from 'react'

export const CreateUsageEventDrawer = () => {
  const navigate = useNavigate()
  const { magicTableQueryParams } = usePersistMagicTableQueryParams()

  const onSuccess = useCallback(() => {
    navigate(`..${magicTableQueryParams}`)
  }, [navigate, magicTableQueryParams])

  const handleCancel = useCallback(() => {
    navigate(`..${magicTableQueryParams}`)
  }, [navigate, magicTableQueryParams])

  return (
    <Sidebar>
      <CreateUsageEventForm
        formContainer={DrawerForm}
        onSuccess={onSuccess}
        handleCancel={handleCancel}
      />
    </Sidebar>
  )
}
