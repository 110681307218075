import { Button, Divider, Flex, Input, Link, Text } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey40,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useStytchB2BClient } from '@stytch/react/dist/b2b'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Section } from 'modules/LoginApp/components/common/Section'
import { TextDialog } from 'modules/LoginApp/components/common/TextDialog'
import { isEmailDisallowed } from 'modules/LoginApp/helpers'
import { GoogleIcon } from 'components/icons/GoogleIcon'
import { LightningIcon } from 'components/icons/LightningIcon'
import { MicrosoftIcon } from 'components/icons/MicrosoftIcon'
import { Wordmark } from 'components/icons/Wordmark'
import { motion, useAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const LoginPage = () => {
  const [searchParams] = useSearchParams()
  const returnTo = searchParams.get('return_to') ?? '/home'
  window.localStorage.setItem('return_to', returnTo)

  return (
    <PageContainer>
      <Wordmark width="126" height="26.74" />
      <EmailLoginForm />

      <Section
        bg={GreyGrey0}
        borderColor={GreyGrey40}
        boxShadow="none"
        padding="16px 20px"
        flexDirection="row"
      >
        <LightningIcon />
        <Text {...Lato14ExtendedRegular} color="gray.80">
          We’ll send you an email with a link to sign in without a password.
        </Text>
      </Section>

      <Text position="absolute" bottom="40px" textAlign="center" zIndex={10}>
        New to Sequence?{' '}
        <Link color="indigo.50" href="https://access.sequencehq.com">
          Request early access
        </Link>
      </Text>
    </PageContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SocialLogin = () => {
  return (
    <>
      <Flex direction="column" gap="16px">
        <Button
          leftIcon={<GoogleIcon />}
          variant="secondary2"
          size="md"
          padding="12px"
          width="100%"
        >
          Continue with Google
        </Button>
        <Button
          leftIcon={<MicrosoftIcon />}
          variant="secondary2"
          size="md"
          padding="12px"
          width="100%"
        >
          Continue with Microsoft
        </Button>
      </Flex>
      <Flex gap="8px" alignItems="center">
        <Divider color="gray.30" />
        <Text flexShrink={0} color="gray.60">
          Or, use your email address
        </Text>
        <Divider color="gray.30" />
      </Flex>
    </>
  )
}

const EmailLoginForm = () => {
  const [email, setEmail] = useState('')
  const [showTip, setShowTip] = useState(false)
  const stytch = useStytchB2BClient()
  const navigate = useNavigate()
  const controls = useAnimation()

  useEffect(() => {
    if (
      email.includes('@') &&
      email.includes('.') &&
      !email.endsWith('.') &&
      isEmailDisallowed(email)
    ) {
      setShowTip(true)
    } else {
      setShowTip(false)
    }
  }, [email])

  const shakeForm = () => {
    void controls.start({
      x: [0, -4, 8, -8, 4, 0],
      transition: { duration: 0.5 }
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (!email) {
      shakeForm()

      return
    }

    await stytch.magicLinks.email.discovery.send({
      email_address: email,
      discovery_redirect_url: `${window.location.origin}/auth/callback`,
      login_template_id: 'magic_link_login_email'
    })

    const queryParams = new URLSearchParams()
    queryParams.append('email', email)
    navigate(`/check-email?${queryParams.toString()}`)
  }

  return (
    <Section as={motion.section} animate={controls}>
      <Text {...Lato16Bold} textAlign="center" color="gray.90">
        Sign in to your account
      </Text>

      <Flex
        as="form"
        onSubmit={e => {
          void handleSubmit(e)
        }}
        flexDirection="column"
        gap="16px"
      >
        <Input
          variant="formInput2"
          onChange={e => setEmail(e.target.value)}
          value={email}
          placeholder="name@work-email.com"
          type="email"
          onInvalid={() => shakeForm()}
          isRequired
        />

        {showTip && (
          <TextDialog>
            <strong>Use your work email:</strong> We suggest using your work
            email to sign in to Sequence.
          </TextDialog>
        )}
        <Button variant="primary2" width="100%" type="submit">
          Sign in with email
        </Button>
      </Flex>
    </Section>
  )
}

export default LoginPage
