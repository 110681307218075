import { PricingEditorMode } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { NEW_PRICE_PATTERN } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useMemo } from 'react'

export const useEditorHeader = () => {
  const pricingEditorContext = usePricingEditorContext()

  const editorTitle = useMemo(() => {
    if (pricingEditorContext.configuration.mode === PricingEditorMode.VIEW) {
      return 'View price'
    }

    if (pricingEditorContext.configuration.mode === PricingEditorMode.EDIT) {
      return 'Edit price'
    }

    if (
      pricingEditorContext.configuration.mode === PricingEditorMode.ADD_PRODUCT
    ) {
      return 'Add new product'
    }

    return (pricingEditorContext.editor.selectedPrice ?? '').match(
      NEW_PRICE_PATTERN
    )
      ? 'Configure new price'
      : 'Configure price'
  }, [pricingEditorContext])

  return {
    title: editorTitle,
    showNewPill:
      pricingEditorContext.editor.selectedPrice?.match(NEW_PRICE_PATTERN)
  }
}
