import { Inspector } from '@sequencehq/core-components'
import { QuoteInformationInspectorItem } from './items/QuoteInformationInspectorItem'
import {
  LinkSalesforceAccountWidget,
  LinkSalesforceOpportunityWidget,
  SalesforceInspectorItem
} from 'Integrations/index'
import { useQuoteEditorInspector } from './useQuoteEditorInspector'
import { AttachmentsInspectorItem } from './items/AttachmentsInspector'

export const QuoteEditorInspector: React.FC = () => {
  const hook = useQuoteEditorInspector()

  return (
    <Inspector showContentOnMount>
      <Inspector.ItemGroup>
        <QuoteInformationInspectorItem />
        <SalesforceInspectorItem>
          <LinkSalesforceAccountWidget {...hook.customerLinking} />
          <LinkSalesforceOpportunityWidget
            sequenceEntityLabel="quote"
            {...hook.opportunityLinking}
          />
        </SalesforceInspectorItem>
        <AttachmentsInspectorItem />
      </Inspector.ItemGroup>
    </Inspector>
  )
}
