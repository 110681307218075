import { Button, Flex, Text, Center, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon'
import {
  GreyGrey60,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato14ExtendedRegular,
  Lato16Bold,
  YellowYellow10,
  YellowYellow60
} from '@sequencehq/design-tokens'
import QuestionMarkCircleIcon from '@heroicons/react/16/solid/QuestionMarkCircleIcon'
import { ShareFeedbackModal } from 'components/ShareFeedbackModal/ShareFeedbackModal'
import { FC, useMemo, useState } from 'react'
import { match } from 'ts-pattern'

type ErrorMessageProps = {
  errorType: 'notFound' | 'general'
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ errorType }) => {
  const [showShareFeedbackModal, setShowShareFeedbackModal] = useState(false)

  const errorContent = useMemo(() => {
    return match(errorType)
      .with('notFound', () => (
        <>
          <Text {...Lato16Bold} color={GreyGrey90}>
            Oops! Page not found
          </Text>
          <Text {...Lato14ExtendedRegular} color={GreyGrey80}>
            We can't find the page you're looking for. Try going back to{' '}
            <Link
              as={RouterLink}
              to={'/home'}
              color={IndigoIndigo50}
              variant="inline"
            >
              home
            </Link>
            .
          </Text>
        </>
      ))
      .with('general', () => (
        <>
          <Text {...Lato16Bold} color={GreyGrey90}>
            Oops! Something went wrong
          </Text>
          <Text
            {...Lato14ExtendedRegular}
            color={GreyGrey80}
            textAlign="center"
          >
            Try reloading the page if you believe this is an error, or send us
            feedback.
          </Text>
        </>
      ))
      .exhaustive()
  }, [errorType])

  return (
    <>
      <Center width="100%" height="100%" padding="20px">
        <Flex flexDirection="column" alignItems="center" gap="16px">
          <Center
            width="48px"
            height="48px"
            borderRadius="48px"
            backgroundColor={YellowYellow10}
          >
            <ExclamationTriangleIcon
              width="24px"
              height="24px"
              color={YellowYellow60}
            />
          </Center>

          <Flex direction="column" gap="4px" alignItems="center">
            {errorContent}
          </Flex>

          <Button
            variant="secondary2"
            height="32px"
            gap="8px"
            onClick={() => setShowShareFeedbackModal(true)}
          >
            <QuestionMarkCircleIcon
              height="16px"
              width="16px"
              color={GreyGrey60}
            />
            Submit feedback
          </Button>
        </Flex>
      </Center>

      {showShareFeedbackModal && (
        <ShareFeedbackModal onClose={() => setShowShareFeedbackModal(false)} />
      )}
    </>
  )
}
