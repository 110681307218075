import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const editableDuringStatuses = [
  CubeStatus.ScheduleUnsaved,
  CubeStatus.ScheduleDraft,
  CubeStatus.SchedulePending
]

export const getTaxRateAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const showTaxRate = !prevState.configuration.features.showNewTaxManagement

  if (!editableDuringStatuses.includes(prevState.data.common.status)) {
    return {
      available: {
        visible: showTaxRate,
        enabled: false
      },
      reasons: [
        {
          reasonType: showTaxRate
            ? DisabledReasonType.InvalidStatus
            : DisabledReasonType.FeatureDisabled,
          metadata: {}
        }
      ]
    }
  }

  const customer = prevState.data.customers[prevState.data.common.customerId]
  if (
    customer &&
    ['TAX_EXEMPT', 'REVERSE_CHARGED'].includes(customer.taxStatus)
  ) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: showTaxRate
            ? DisabledReasonType.InvalidStatus
            : DisabledReasonType.FeatureDisabled,
          metadata: {
            status: customer.taxStatus
          }
        }
      ]
    }
  }

  return {
    available: {
      visible: showTaxRate,
      enabled: true
    },
    reasons: showTaxRate
      ? []
      : [
          {
            reasonType: DisabledReasonType.FeatureDisabled,
            metadata: {}
          }
        ]
  }
}
