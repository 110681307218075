import { Box, Button, Flex } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey30,
  GreyGrey50,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80
} from '@sequencehq/design-tokens'
import { Wordmark } from 'components/icons'
import { ShareFeedbackModal } from 'components/ShareFeedbackModal/ShareFeedbackModal'
import { useFooter } from 'components/NavigationV3/components/Footer/useFooter'
import { SequenceMenu, SequenceMenuItemType } from '@sequencehq/core-components'

import QuestionMarkCircleIcon from '@heroicons/react/20/solid/QuestionMarkCircleIcon'
import ArrowUpRightIcon from '@heroicons/react/16/solid/ArrowUpRightIcon'
import BookOpenIcon from '@heroicons/react/16/solid/BookOpenIcon'
import AcademicCapIcon from '@heroicons/react/16/solid/AcademicCapIcon'
import NewspaperIcon from '@heroicons/react/16/solid/NewspaperIcon'
import FlagIcon from '@heroicons/react/16/solid/FlagIcon'

export const Footer = () => {
  const { feedbackModal } = useFooter()

  const items: SequenceMenuItemType[] = [
    {
      label: 'Documentation',
      onClick: () => {
        window.open(
          'https://docs.sequencehq.com/reference/gettingStarted',
          '_blank'
        )
      },
      iconLeft: <BookOpenIcon color="inherit" width="16px" height="16px" />,
      iconRight: (
        <ArrowUpRightIcon color={GreyGrey60} width="16px" height="16px" />
      )
    },
    {
      label: 'Guides',
      onClick: () => {
        window.open(
          'https://docs.sequencehq.com/getting-started/overview',
          '_blank'
        )
      },
      iconLeft: <AcademicCapIcon color="inherit" width="16px" height="16px" />,
      iconRight: (
        <ArrowUpRightIcon color={GreyGrey60} width="16px" height="16px" />
      )
    },
    {
      label: 'Changelog',
      divider: feedbackModal.available,
      onClick: () => {
        window.open('https://www.sequencehq.com/changelog', '_blank')
      },
      iconLeft: <NewspaperIcon color="inherit" width="16px" height="16px" />,
      iconRight: (
        <ArrowUpRightIcon color={GreyGrey60} width="16px" height="16px" />
      )
    },
    {
      label: 'Share feedback',
      onClick: () => {
        feedbackModal.toggle()
      },
      iconLeft: <FlagIcon color="inherit" width="16px" height="16px" />,
      hidden: !feedbackModal.available
    }
  ]

  return (
    <Flex
      width="100%"
      p="8px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box p="8px" position="relative" top="1px">
        <Wordmark width="74px" height="16px" color={GreyGrey50} />
      </Box>

      <SequenceMenu
        menuButton={
          <Button
            variant="unstyled"
            display="flex"
            width="28px"
            height="28px"
            minWidth="initial"
            color={GreyGrey60}
            _hover={{ color: GreyGrey70, background: GreyGrey20 }}
            _active={{ color: GreyGrey80, background: GreyGrey30 }}
            _focus={{ color: GreyGrey80, background: GreyGrey30 }}
          >
            <QuestionMarkCircleIcon
              width="20px"
              height="20px"
              color="inherit"
            />
          </Button>
        }
        items={items}
        alignment="top-left"
      />

      {feedbackModal.visible && (
        <ShareFeedbackModal onClose={feedbackModal.toggle} />
      )}
    </Flex>
  )
}
