import { ComponentProps, FC, useRef } from 'react'
import { Button } from './Button'

type Props = ComponentProps<typeof Button> & {
  accept: string
  multiple?: boolean
  onFilesPicked?: (files: File[]) => void
}

export const FilePickerButton: FC<Props> = ({
  accept,
  multiple = false,
  onFilesPicked,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const handleFilePicked = () => {
    const files = fileInputRef.current?.files
    if (!files) {
      return
    }
    onFilesPicked?.(Array.from(files))
    fileInputRef.current.value = ''
  }

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept={accept}
        multiple={multiple}
        onChange={handleFilePicked}
      />
      <Button {...props} onClick={triggerFileInput} />
    </>
  )
}
