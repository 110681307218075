import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey30,
  GreyGrey60,
  IndigoIndigo50,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { useEditorBreadcrumb } from 'modules/Products/drawers/ListPriceEditor/components/EditorBreadcrumb/useEditorBreadcrumb'
import ChevronRightIcon from '@heroicons/react/16/solid/ChevronRightIcon'
import { Fragment } from 'react'

export const EditorBreadcrumb = () => {
  const { steps } = useEditorBreadcrumb()

  return (
    <Flex
      alignItems="center"
      padding="20px 16px"
      borderBottom={`1px solid ${GreyGrey30}`}
      backgroundColor={GreyGrey0}
      data-testid="editor-breadcrumb"
    >
      {steps.map((step, index) => (
        <Fragment key={step.title}>
          <Text
            {...Lato13Bold}
            color={step.active ? IndigoIndigo50 : GreyGrey60}
          >
            {step.title}
          </Text>
          {index < steps.length - 1 && (
            <ChevronRightIcon height="16px" width="16px" color={GreyGrey60} />
          )}
        </Fragment>
      ))}
    </Flex>
  )
}
