import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { InviteMemberForm } from 'components/TeamSettings/forms/InviteMemberForm'

interface InviteMemberModalProps {
  isOpen: boolean
  onClose: () => void
}

export const InviteMemberModal = ({
  isOpen,
  onClose
}: InviteMemberModalProps) => {
  return (
    <Modal variant="v2" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as="h2">
          Invite member
          <ModalCloseButton top={3} right={4} />
        </ModalHeader>
        <InviteMemberForm onCancel={onClose} />
      </ModalContent>
    </Modal>
  )
}
