import { CreditNoteStatus } from 'CreditNotes/types'

export const CREDIT_NOTE_STATUSES: Record<CreditNoteStatus, CreditNoteStatus> =
  {
    DRAFT: 'DRAFT',
    FINAL: 'FINAL',
    SENT: 'SENT',
    VOIDED: 'VOIDED'
  } as const

export const CREDIT_NOTE_STATUS_LABELS: Record<CreditNoteStatus, string> = {
  DRAFT: 'Draft',
  FINAL: 'Final',
  SENT: 'Issued',
  VOIDED: 'Void'
} as const

export const MAX_QUANTITY = 999999999
export const MAX_INPUT_TOTAL = 9999999999999
