import {
  Box,
  Button,
  Circle,
  Flex,
  FormLabel,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField as NumberInput,
  NumberInputStepper,
  Text
} from '@chakra-ui/react'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import { FormErrors, Label, TextInputField } from '@sequencehq/forms'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'
import {
  composeValidators,
  greaterThan,
  isEmail,
  isUrl,
  required,
  requiredAllowZero
} from '@sequencehq/validation'
import { AddressInput } from 'components/FormComponents'
import { NumberInputField } from 'components/FormFields'
import Spinner from 'components/Loading/Spinner'
import SettingsTab from 'components/SettingsPage/SettingsTab'
import { MerchantIcon } from 'components/icons'
import arrayMutators from 'final-form-arrays'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC, memo } from 'react'
import { Field, Form } from 'react-final-form'
import { Switch } from '@sequencehq/core-components'
import { useFetchMerchantBySequenceAccountId } from 'components/MerchantSettings/hooks/useFetchMerchantBySequenceAccountId'

type Merchant = DashboardApi20240730.GetMerchant.Merchant

type FormValues = Pick<
  Merchant,
  | 'invoiceNumberPrefix'
  | 'creditNoteNumberPrefix'
  | 'legalCompanyName'
  | 'address'
  | 'phoneNumber'
  | 'email'
  | 'defaultDueDateDays'
  | 'customerPortalEnabled'
  | 'ccMerchantOnInvoiceEmails'
  | 'showUsageInCustomerPortal'
  | 'supportEmail'
  | 'supportUrl'
  | 'lastInvoiceNumber'
  | 'lastCreditNoteNumber'
>

const MerchantSettings: FC = memo(() => {
  const { merchant, loading: isFetchingMerchant } =
    useFetchMerchantBySequenceAccountId()

  if (isFetchingMerchant || !merchant) {
    return <Spinner></Spinner>
  }

  const customerPortalInfoPopoverBody = (
    <Text>
      A branded portal for customers to view past invoices, usage and credit
      balances. Read more in the guide{' '}
      <Link
        color={IndigoIndigo50}
        href="https://docs.sequencehq.com/customers/customer-portal"
        target="_blank"
      >
        here
      </Link>
      .
    </Text>
  )

  const showUsageInCustomerPortalInfoPopoverBody = (
    <Text>
      If turned off, usage charts will no longer be shown in the end-customer
      portal.
    </Text>
  )

  const ccMerchantOnEmailsInfoPopoverBody = (
    <Text>
      Determines whether the merchant email in included as a CC on end-customer
      emails.
    </Text>
  )

  return (
    <SettingsTab
      title="Merchant Details"
      summarySection={
        <Flex flexDirection="row">
          <Circle backgroundColor="#F0F4F7" size="37px">
            <MerchantIcon></MerchantIcon>
          </Circle>
          <Box width="8px"></Box>
          <Flex flexDirection="column">
            <Box height="12px"></Box>
            <Text>Manage merchant details for invoices and credit notes </Text>
          </Flex>
        </Flex>
      }
    >
      <Form<FormValues>
        keepDirtyOnReinitialize
        destroyOnUnregister
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
            changeValue(state, field, () => value)
          }
        }}
        initialValues={{
          ...merchant
        }}
        onSubmit={async (values, form) => {
          // Temporarily type-casting until we replace usage of the rtk-query PUT merchant hook
          const result = await dashboard20240730Client.putMerchant({
            id: merchant.id,
            body: {
              ...merchant,
              ...values
            }
          })

          return handleFormResponse(result, form.getRegisteredFields())
        }}
        render={({ handleSubmit, submitting, submitError }) => (
          <Flex
            as="form"
            px={5}
            py={2}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            w="100%"
          >
            <FormErrors formError={submitError} />
            <Text textStyle="sectionHeader">Company Details</Text>
            <Box h="6px"></Box>
            <Flex>
              <Flex flexDirection="column" width="48%">
                <Box h="6px"></Box>
                <TextInputField
                  fieldName="legalCompanyName"
                  fieldLabel="Legal Company Name"
                  validate={required}
                />
                <Box height="10px" />
                <TextInputField
                  fieldName="phoneNumber"
                  fieldLabel="Phone Number (optional)"
                />
                <Box height="10px" />
                <TextInputField
                  fieldName="email"
                  fieldLabel="Email"
                  validate={isEmail}
                />
                <Box height="10px" />
                <TextInputField fieldName="appUrl" fieldLabel="App URL" />
              </Flex>
              <Box width="4%"></Box>
              <Flex flexDirection="column" width="48%">
                <FormLabel>Company Address</FormLabel>
                <Box height={2} />
                <AddressInput name="address." />
              </Flex>
            </Flex>
            <Box height="10px" />
            <Flex>
              <Flex flexDirection="column" width="48%">
                <TextInputField
                  fieldName="supportEmail"
                  fieldLabel="Support email"
                  validate={isEmail}
                />
                <Box height="10px" />
                <Flex paddingTop="14px" alignItems="baseline">
                  <Label
                    infoPopover={{
                      title: 'End-Customer Portal',
                      body: customerPortalInfoPopoverBody,
                      padding: '0px 12px 0px 0px'
                    }}
                  >
                    End-customer portal enabled
                  </Label>

                  <Field name="customerPortalEnabled">
                    {props => (
                      <Switch
                        name={props.input.name}
                        defaultChecked={merchant.customerPortalEnabled}
                        value={props.input.value}
                        onChange={props.input.onChange}
                      />
                    )}
                  </Field>
                </Flex>
                <Flex paddingTop="14px" alignItems="baseline">
                  <Label
                    infoPopover={{
                      title: 'Show usage in end-customer portal',
                      body: showUsageInCustomerPortalInfoPopoverBody,
                      padding: '0px 12px 0px 0px'
                    }}
                  >
                    Show usage in end-customer portal
                  </Label>

                  <Field name="showUsageInCustomerPortal">
                    {props => (
                      <Switch
                        name={props.input.name}
                        defaultChecked={merchant.showUsageInCustomerPortal}
                        value={props.input.value}
                        onChange={props.input.onChange}
                      />
                    )}
                  </Field>
                </Flex>
                <Flex paddingTop="14px" alignItems="baseline">
                  <Label
                    infoPopover={{
                      title: 'CC merchant on end-customer invoice emails',
                      body: ccMerchantOnEmailsInfoPopoverBody,
                      padding: '0px 12px 0px 0px'
                    }}
                  >
                    CC merchant on end-customer invoice emails
                  </Label>

                  <Field name="ccMerchantOnInvoiceEmails">
                    {props => (
                      <Switch
                        name={props.input.name}
                        defaultChecked={merchant.ccMerchantOnInvoiceEmails}
                        value={props.input.value}
                        onChange={props.input.onChange}
                      />
                    )}
                  </Field>
                </Flex>
              </Flex>
              <Box width="4%"></Box>

              <Flex flexDirection="column" width="48%">
                <TextInputField
                  fieldName="supportUrl"
                  fieldLabel="Support URL"
                  validate={isUrl}
                />
              </Flex>
            </Flex>

            <Box height="24px" />

            <Flex>
              <Flex flexDirection="column" width="66%">
                <Text textStyle="sectionHeader">Invoice numbering</Text>
                <Box h="16px"></Box>
                <Flex flexDirection="row">
                  <TextInputField
                    fieldName="invoiceNumberPrefix"
                    fieldLabel="Prefix"
                    validate={required}
                  />
                  <Box width="60px" />
                  <TextInputField
                    fieldName="lastInvoiceNumber"
                    fieldLabel="Latest Invoice Number"
                    validate={requiredAllowZero}
                    infoPopover={{
                      title: 'Latest invoice number',
                      body: 'Your next finalized invoice number will use this number plus one. Changing it may create duplicate invoice numbers.'
                    }}
                  />
                </Flex>
                <Box h="16px"></Box>
                <Text textStyle="sectionHeader">Credit note numbering</Text>
                <Box h="16px"></Box>
                <Flex flexDirection="row">
                  <TextInputField
                    fieldName="creditNoteNumberPrefix"
                    fieldLabel="Prefix"
                    validate={required}
                  />
                  <Box width="60px" />
                  <TextInputField
                    fieldName="lastCreditNoteNumber"
                    fieldLabel="Latest Credit Note Number"
                    validate={requiredAllowZero}
                    infoPopover={{
                      title: 'Latest credit note number',
                      body: 'Your next finalized credit note number will use this number plus one. Changing it may create duplicate credit note numbers.'
                    }}
                  />
                </Flex>
                <Box h="16px"></Box>
                <Text textStyle="sectionHeader">Payment terms</Text>
                <Box h="16px"></Box>
                <Flex flexDirection="row" width="50%">
                  <NumberInputField
                    fieldName="defaultDueDateDays"
                    fieldLabel="Default Payment Terms (days)"
                    validate={composeValidators(required, greaterThan(0))}
                  >
                    <NumberInput></NumberInput>
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInputField>
                </Flex>
              </Flex>
            </Flex>

            <Box height={5} />
            <Flex justifyContent="flex-end">
              <Box w="16px"></Box>
              <Button variant="primary" type="submit" isDisabled={submitting}>
                Save changes
              </Button>
            </Flex>
          </Flex>
        )}
      />
    </SettingsTab>
  )
})

export default MerchantSettings
