import { useSaveStatus } from './hooks/useSaveStatus'

import { Box, Flex, Text } from '@chakra-ui/react'
import { CheckIcon, ArrowPathIcon } from '@heroicons/react/16/solid'

import { GreyGrey60, Lato13Regular } from '@sequencehq/design-tokens'

export const QuoteEditorHeaderSaveStatus = ({
  isSaving
}: {
  isSaving: boolean
}) => {
  const { isDone, isLoading } = useSaveStatus(isSaving)

  if (isLoading) {
    return (
      <Flex gap="6px">
        <ArrowPathIcon width="16px" height="16px" color={GreyGrey60} />
        <Text color={GreyGrey60} {...Lato13Regular}>
          Saving...
        </Text>
      </Flex>
    )
  }

  if (!isDone) {
    return <Box />
  }

  return (
    <Flex gap="6px">
      <CheckIcon width="16px" height="16px" color={GreyGrey60} />
      <Text color={GreyGrey60} {...Lato13Regular}>
        Saved
      </Text>
    </Flex>
  )
}
