import { FC } from 'react'

interface PlusIconProps {
  width?: number
  height?: number
  color?: string
}

export const PlusIcon: FC<PlusIconProps> = ({
  width = 15,
  height = 15,
  color = '#2D3748'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.29199 6.02136V0.0213623H8.29199V6.02136H14.292V8.02136H8.29199V14.0214H6.29199V8.02136H0.291992V6.02136H6.29199Z"
      fill={color}
    />
  </svg>
)
