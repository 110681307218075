import {
  AggregatedCreditNote,
  AggregatedLineItemGroup,
  ServerLineItem
} from 'CreditNotes/types'
import { differenceBy } from 'lodash'
import { removeUnselectedLineItems } from 'CreditNotes/utils/lineItemGroup'
import { useMutation } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useCallback } from 'react'

export const useSaveAggregatedCreditNote = (creditNoteId: string) => {
  const postLineItemMutation = useMutation(
    dashboardv99990101Client.postCreditNoteLineItem
  )

  const putLineItemMutation = useMutation(
    dashboardv99990101Client.putCreditNoteLineItem
  )

  const deleteLineItemGroupMutation = useMutation(
    dashboardv99990101Client.deleteCreditNoteLineItemGroup
  )

  const deleteLineItemMutation = useMutation(
    dashboardv99990101Client.deleteCreditNoteLineItem
  )

  const performSave = useCallback(
    async (diffs: ReturnType<typeof diffLineItemGroups>) => {
      const deletions = diffs.lineItemsToDelete.map(id =>
        deleteLineItemMutation.mutateAsync({ id, creditNoteId })
      )
      const additions = diffs.lineItemsToAdd.map(item =>
        postLineItemMutation.mutateAsync({ body: item, creditNoteId })
      )
      const updates = diffs.lineItemsToUpdate.map(item =>
        putLineItemMutation.mutateAsync({
          body: item,
          id: item.id,
          creditNoteId
        })
      )
      const groupDeletions = diffs.groupsToDelete.map(id =>
        deleteLineItemGroupMutation.mutateAsync({ id, creditNoteId })
      )

      await Promise.all([
        ...deletions,
        ...additions,
        ...updates,
        ...groupDeletions
      ])
    },
    [
      creditNoteId,
      deleteLineItemMutation,
      deleteLineItemGroupMutation,
      postLineItemMutation,
      putLineItemMutation
    ]
  )

  return useCallback(
    (
      serverCreditNote: AggregatedCreditNote,
      clientCreditNote: AggregatedCreditNote
    ) => {
      const diffs = diffLineItemGroups(
        serverCreditNote.lineItemGroups.map(removeUnselectedLineItems),
        clientCreditNote.lineItemGroups.map(removeUnselectedLineItems)
      )
      performSave(diffs)
    },
    [performSave]
  )
}

export const diffLineItemGroups = (
  storedLineItemGroups: AggregatedLineItemGroup[],
  changedLineItemGroups: AggregatedLineItemGroup[]
) => {
  const diffs = {
    groupsToDelete: [] as string[],
    lineItemsToDelete: [] as string[],
    lineItemsToAdd: [] as Omit<ServerLineItem, 'id'>[],
    lineItemsToUpdate: [] as ServerLineItem[]
  }

  for (const storedGroup of storedLineItemGroups) {
    const changedGroup = changedLineItemGroups.find(
      g => g.id === storedGroup.id
    )
    if (!changedGroup) {
      diffs.groupsToDelete.push(storedGroup.id)
      diffs.lineItemsToDelete.push(...storedGroup.lineItems.map(i => i.id))
      continue
    }

    diffs.lineItemsToDelete.push(
      ...differenceBy(storedGroup.lineItems, changedGroup.lineItems, 'id').map(
        item => item.id
      )
    )

    for (const item of changedGroup.lineItems) {
      const storedItem = storedGroup.lineItems.find(i => i.id === item.id)
      if (!storedItem) {
        const { id, ...itemToAdd } = item
        diffs.lineItemsToAdd.push(itemToAdd)
        continue
      }
      if (
        item.quantity !== storedItem.quantity ||
        item.netTotal !== storedItem.netTotal
      ) {
        diffs.lineItemsToUpdate.push(item)
      }
    }
  }

  return diffs
}
