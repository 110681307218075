import { createContext, useContext } from 'react'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import { dequal } from 'dequal'

const CubeContext = createContext<CubeDomainInterface>({
  queries: {} as CubeDomainInterface['queries'],
  mutators: {} as CubeDomainInterface['mutators']
})

/**
 * The hook for consuming the billing schedule editor context - should
 * only be used within the main loop of the billing schedule (i.e. NOT
 * in any components which are rendered via child routes - those should
 * use the OutletProvider driven mechanism)
 * @returns
 */
export const useCubeContext = () => {
  const cubeContext = useContext(CubeContext)

  if (dequal(cubeContext, { queries: {}, mutators: {} })) {
    throw new Error('Cannot use cube context before initialisation')
  }

  return cubeContext
}

export const CubeProvider = CubeContext.Provider
