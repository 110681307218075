import { Flex, Grid, Text } from '@chakra-ui/react'
import { IntegrationService, PriceModel } from '@sequencehq/core-models'
import {
  GreyGrey100,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import Loading from 'components/Loading'
import { ListPrice } from 'modules/Products/types'
import { Fragment, useMemo } from 'react'
import { useXeroIntegration } from 'lib/hooks/useXeroIntegration.ts'

const transformListPriceIntegrations = (price: ListPrice) =>
  price.integrationIds.map(({ service, id }) => ({
    key: service,
    value: id
  }))

export const ProductPriceAdditionalInfo = ({
  price
}: {
  price: PriceModel | ListPrice
}) => {
  const externalIds =
    'integrationIds' in price
      ? transformListPriceIntegrations(price)
      : price.externalIds
  const { xeroIntegration, loading: xeroIntegrationLoading } =
    useXeroIntegration()

  const mappedLedgerServices = useMemo(() => {
    return {
      Xero: xeroIntegration?.ledgerAccounts?.reduce((valueAcc, value) => {
        if (!valueAcc[value.code]) {
          valueAcc[value.code] = value.name
        }
        return valueAcc
      }, {} as Record<string, string>)
    } as Record<IntegrationService, Record<string, string>>
  }, [xeroIntegration])

  if (!externalIds || externalIds.length === 0) {
    return null
  }

  if (xeroIntegrationLoading) {
    return (
      <Flex width="100%" placeContent="center" height="50px">
        <Loading />
      </Flex>
    )
  }

  const hasValidExternalIds = externalIds.some(
    externalId => mappedLedgerServices[externalId.key]
  )

  if (!hasValidExternalIds) {
    return null
  }

  return (
    <Flex flexDirection="column" mt={6}>
      <Text {...Lato14Bold} color={GreyGrey100}>
        Additional information
      </Text>
      <Grid templateColumns="160px 1fr" gap={3} mt={4} color={GreyGrey70}>
        {externalIds.map((externalId, index) =>
          mappedLedgerServices[externalId.key] ? (
            <Fragment key={index}>
              <Text {...Lato13Regular}>{externalId.key} Ledger account</Text>
              <Text color={GreyGrey90}>
                {mappedLedgerServices[externalId.key][externalId.value]} (
                {externalId.value})
              </Text>
            </Fragment>
          ) : null
        )}
      </Grid>
    </Flex>
  )
}

export default ProductPriceAdditionalInfo
