import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import {
  PricingEditorFormData,
  PricingModel
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { useCallback, useMemo } from 'react'
import { currencyToName } from '@sequencehq/core-models'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  FormFields,
  toBillingFrequencyLabel,
  useForm,
  toPriceSummary
} from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import deepmerge from 'deepmerge'
import { internalPriceToApiPrice } from 'modules/Cube/view/common/drawers/priceEditor/drawer/adapters/pricingEditor.adapters'

const PRICING_MODEL_OPTIONS: {
  value: PricingModel
  label: string
  description: string
}[] = [
  {
    value: 'STANDARD',
    label: 'Standard',
    description: 'Charge a recurring or one-off fixed fee'
  },
  {
    value: 'LINEAR',
    label: 'Linear',
    description: 'Charge a single, linear rate for usage.'
  },
  {
    value: 'VOLUME',
    label: 'Volume tiers',
    description: 'All usage is charged a single unit fee based on total volume.'
  },
  {
    value: 'GRADUATED',
    label: 'Graduated tiers',
    description:
      'Usage in each tier is charged separately using respective tier fees.'
  },
  {
    value: 'PACKAGED',
    label: 'Packaged',
    description: 'Charge a fixed fee for a packaged quantity of units.'
  },
  {
    value: 'SEAT_BASED_LINEAR',
    label: 'Seat-based → Linear Pricing',
    description: 'Charge a single, linear rate for seats.'
  },
  {
    value: 'SEAT_BASED_GRADUATED',
    label: 'Seat-based → Graduated Pricing',
    description:
      'Seat usage in each tier is charged separately using respective tier fees.'
  }
]

type CommonFields = PricingEditorFormData['common']
type CommonFieldsConfig = {
  listPriceId: FormFields<CommonFields>['listPriceId'] & { hidden: boolean }
  name: FormFields<CommonFields>['name'] & { hidden: boolean }
  currency: FormFields<CommonFields>['currency'] & { hidden: boolean }
  pricingModel: FormFields<CommonFields>['pricingModel'] & {
    description: string
    hidden: boolean
  }
}
type UseCommonFieldsForm = () => {
  fieldsConfig: CommonFieldsConfig
}

export const useCommonFieldsForm: UseCommonFieldsForm = () => {
  const pricingEditorContext = usePricingEditorContext()
  const flags = useFlags()

  const availableCurrencies = useMemo(() => {
    return pricingEditorContext.configuration.availableCurrencies.map(
      currency => ({
        label: `${currency} - ${currencyToName[currency]}`,
        value: currency
      })
    )
  }, [pricingEditorContext.configuration.availableCurrencies])

  const availablePricingModelOptions = useMemo(() => {
    if (flags.enableSeats) {
      return PRICING_MODEL_OPTIONS
    }

    /**
     * Include seat based if the current price is seat based, even if the flag is
     * disabled. This prevents an error or otherwise weird state if the price
     * is seat based.
     */
    if (
      ['SEAT_BASED_LINEAR', 'SEAT_BASED_GRADUATED'].includes(
        pricingEditorContext.data.formData.common.pricingModel
      )
    ) {
      return PRICING_MODEL_OPTIONS
    }

    return PRICING_MODEL_OPTIONS.filter(
      option =>
        !['SEAT_BASED_LINEAR', 'SEAT_BASED_GRADUATED'].includes(option.value)
    )
  }, [flags, pricingEditorContext.data.formData.common.pricingModel])

  const availableListPriceOptions = useMemo(() => {
    if (!pricingEditorContext.configuration.enableListPrices) {
      return []
    }

    const existingListPrices = Object.values(
      pricingEditorContext.data.listPrices
    )

    return existingListPrices.map(listPrice => ({
      value: listPrice.id,
      label: `${toPriceSummary(
        internalPriceToApiPrice(pricingEditorContext.data.product.id)(listPrice)
      )} / ${toBillingFrequencyLabel(listPrice.modelSpecific.billingFrequency)}`
    }))
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.data.listPrices,
    pricingEditorContext.data.product.id
  ])

  /**
   * Initialise the form management
   */
  const checkIfCommonFieldDisabledByDomain = useCallback(
    ({ property }: { property: keyof CommonFields }) =>
      pricingEditorContext.functions.fieldIsDisabled(`common.${property}`),
    [pricingEditorContext]
  )

  const { fields, queries } = useForm({
    value: pricingEditorContext.data.formData.common,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required],
        disabled: checkIfCommonFieldDisabledByDomain
      },
      {
        property: 'currency',
        validation: [required],
        disabled: checkIfCommonFieldDisabledByDomain,
        options: availableCurrencies
      },
      {
        property: 'pricingModel',
        validation: [required],
        disabled: checkIfCommonFieldDisabledByDomain,
        options: availablePricingModelOptions
      },
      {
        property: 'listPriceId',
        disabled: () =>
          !pricingEditorContext.derived.queries.availableFeatures
            .changeListPrice,
        options: availableListPriceOptions
      }
    ],
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          common: isValid
        }
      })
    },
    onChange: newData => {
      pricingEditorContext.functions.updateFormData({
        common: newData
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return deepmerge(
      fields,
      {
        pricingModel: {
          description:
            availablePricingModelOptions.find(
              option => option.value === queries.formData.pricingModel
            )?.description ?? '',
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        },
        currency: {
          hidden:
            Boolean(pricingEditorContext.configuration.currency) ||
            ['SEAT_BASED_LINEAR', 'SEAT_BASED_GRADUATED'].includes(
              pricingEditorContext.data.formData.common.pricingModel
            )
        },
        name: {
          hidden: ['SEAT_BASED_LINEAR', 'SEAT_BASED_GRADUATED'].includes(
            pricingEditorContext.data.formData.common.pricingModel
          )
        },
        listPriceId: {
          /*
           * The logic here is a bit tenuous, but captures that we don't want to show the list price field when:
           * 1. List prices as a feature is disabled
           * 2. The price being edited is a list price itself OR a standalone variant.
           *
           * Showing all fields is a proxy for (2) because those are the kinds of prices for which we show all fields.
           * When we de-fork (BADGE-1326), we'll hoist the checks for list / standalone variant / variant based on list price up in the stack
           * so it's clearer when this field shows vs it doesn't (i.e. it won't appear in the branch for list prices + standalone variants at all).
           * */
          hidden:
            !pricingEditorContext.configuration.enableListPrices ||
            pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields,
          onChange: (newListPriceId: string) => {
            const updateToListPrice =
              pricingEditorContext.data.listPrices[newListPriceId]
            pricingEditorContext.functions.updateData({
              formData: {
                common: {
                  ...updateToListPrice.common,
                  listPriceId: newListPriceId
                },
                [updateToListPrice.common.pricingModel]:
                  updateToListPrice.modelSpecific
              }
            })
          }
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    )
  }, [
    fields,
    availablePricingModelOptions,
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields,
    pricingEditorContext.configuration.currency,
    pricingEditorContext.data.formData.common.pricingModel,
    pricingEditorContext.data.listPrices,
    pricingEditorContext.functions,
    queries.formData.pricingModel
  ])

  return {
    fieldsConfig: enhancedFields
  }
}
