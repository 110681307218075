import {
  PostActionStage,
  Price
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { match } from 'ts-pattern'

/**
 * When we update the form, we want to ensure that we propogate the changes to the
 * representation of the new prices in the prices datastructure. This allows us to
 * drive functionality such as the label shown in the price list.
 * @returns
 */
export const syncFormToNewPrice: PostActionStage = () => prevState => {
  if (!prevState.editor.selectedPrice) {
    return prevState
  }

  const existingPrice = prevState.data.prices[prevState.editor.selectedPrice]

  if (!existingPrice) {
    throw new Error('No existing price found')
  }

  /**
   * What's this all about?
   *
   * It's a bunch of faff that mostly boils down to avoiding the use of an 'as'
   * and, in doing so, gives us protection against the form and price data ever
   * drifting out of sync and causing any weird bugs during this sync process.
   *
   * If you want to see it in action, go and change the form data type to mismatch
   * the equivalent value on the price - you'll see the error here prompting you to
   * either transform the value or otherwise fix something.
   *
   * I'm up for any ideas to do this without this big ol' pattern match, but given it's
   * rather general in implementation, it shouldn't get in the way outside of flagging
   * issues.
   *
   */
  const newPrice: Price = {
    id: existingPrice.id,
    ...match(prevState.data.formData)
      .with({ common: { pricingModel: 'STANDARD' } }, matchedFormData => ({
        common: {
          ...existingPrice.common,
          ...matchedFormData.common
        },
        modelSpecific: {
          ...existingPrice.modelSpecific,
          ...matchedFormData[matchedFormData.common.pricingModel]
        }
      }))
      .with({ common: { pricingModel: 'LINEAR' } }, matchedFormData => ({
        common: {
          ...existingPrice.common,
          ...matchedFormData.common
        },
        modelSpecific: {
          ...existingPrice.modelSpecific,
          ...matchedFormData[matchedFormData.common.pricingModel]
        }
      }))
      .with({ common: { pricingModel: 'VOLUME' } }, matchedFormData => ({
        common: {
          ...existingPrice.common,
          ...matchedFormData.common
        },
        modelSpecific: {
          ...existingPrice.modelSpecific,
          ...matchedFormData[matchedFormData.common.pricingModel]
        }
      }))
      .with({ common: { pricingModel: 'GRADUATED' } }, matchedFormData => ({
        common: {
          ...existingPrice.common,
          ...matchedFormData.common
        },
        modelSpecific: {
          ...existingPrice.modelSpecific,
          ...matchedFormData[matchedFormData.common.pricingModel]
        }
      }))
      .with(
        { common: { pricingModel: 'SEAT_BASED_LINEAR' } },
        matchedFormData => ({
          common: {
            ...existingPrice.common,
            ...matchedFormData.common
          },
          modelSpecific: {
            ...existingPrice.modelSpecific,
            ...matchedFormData[matchedFormData.common.pricingModel]
          }
        })
      )
      .with(
        { common: { pricingModel: 'SEAT_BASED_GRADUATED' } },
        matchedFormData => ({
          common: {
            ...existingPrice.common,
            ...matchedFormData.common
          },
          modelSpecific: {
            ...existingPrice.modelSpecific,
            ...matchedFormData[matchedFormData.common.pricingModel]
          }
        })
      )
      .with({ common: { pricingModel: 'PACKAGED' } }, matchedFormData => ({
        common: {
          ...existingPrice.common,
          ...matchedFormData.common
        },
        modelSpecific: {
          ...existingPrice.modelSpecific,
          ...matchedFormData[matchedFormData.common.pricingModel]
        }
      }))
      .exhaustive()
  }

  return {
    ...prevState,
    data: {
      ...prevState.data,
      prices: {
        ...prevState.data.prices,
        [prevState.editor.selectedPrice]: newPrice
      }
    }
  }
}
