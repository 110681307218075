import { compose } from 'lodash/fp'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import { Presentation } from 'modules/Cube/domain/cube.domain.types'
import { QuoteBlock } from '@sequencehq/quote-content'
import { blockAdapters } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks'
import { blocknoteSchema } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/blocknoteSchema'

const groupNumberedLists = (
  blockNote: (typeof blocknoteSchema.BlockNoteEditor)['document']
) =>
  blockNote.reduce((acc, block) => {
    if (
      acc[acc.length - 1]?.type === 'numberedListItem' &&
      block.type === 'numberedListItem'
    ) {
      return [
        ...acc.slice(0, acc.length - 1),
        {
          type: 'numberedListItem',
          children: [
            ...(acc[acc.length - 1].children.length > 0
              ? acc[acc.length - 1].children
              : [acc[acc.length - 1]]),
            block
          ]
        }
      ] as (typeof blocknoteSchema.BlockNoteEditor)['document']
    }

    return [
      ...acc,
      block
    ] as (typeof blocknoteSchema.BlockNoteEditor)['document']
  }, [] as (typeof blocknoteSchema.BlockNoteEditor)['document'])

const groupBulletLists = (
  blockNote: (typeof blocknoteSchema.BlockNoteEditor)['document']
) =>
  blockNote.reduce((acc, block) => {
    if (
      acc[acc.length - 1]?.type === 'bulletListItem' &&
      block.type === 'bulletListItem'
    ) {
      return [
        ...acc.slice(0, acc.length - 1),
        {
          type: 'bulletListItem',
          children: [
            ...(acc[acc.length - 1].children.length > 0
              ? acc[acc.length - 1].children
              : [acc[acc.length - 1]]),
            block
          ]
        }
      ] as (typeof blocknoteSchema.BlockNoteEditor)['document']
    }

    return [
      ...acc,
      block
    ] as (typeof blocknoteSchema.BlockNoteEditor)['document']
  }, [] as (typeof blocknoteSchema.BlockNoteEditor)['document'])

export const presentationToBlockNoteAdapter = {
  out:
    (ctx: { cubeQueries: CubeDomainInterface['queries'] }) =>
    (blockNote: (typeof blocknoteSchema.BlockNoteEditor)['document']) => {
      const preparedBlocknote = compose(
        groupBulletLists,
        groupNumberedLists
      )(blockNote)

      const transformed = preparedBlocknote
        .map(block => {
          const adapter = blockAdapters.out[block.type]
          if (!adapter) {
            return null
          }
          return adapter(ctx)(block) as QuoteBlock
        })
        .filter(Boolean) as QuoteBlock[]

      return transformed
    },
  in: (
    presentation: Presentation
  ): Array<typeof blocknoteSchema.PartialBlock> => {
    const transformed = presentation.flatMap(block => {
      const adapter = blockAdapters.in[block.blockType]
      if (!adapter) {
        return []
      }
      return adapter(block)
    })

    if (transformed.length === 0) {
      return [
        {
          type: 'paragraph'
        }
      ]
    }

    return transformed
  }
}
