import { useCallback, useMemo, useState } from 'react'

import { useCustomerContactsContext } from 'modules/CustomerContacts/communication/internal/CustomerContacts.domain.context'
import { CustomerContact } from 'modules/CustomerContacts/domain/CustomerContacts.domain.types'
import { sortContacts } from 'modules/CustomerContacts/utils/sortContacts'

export type UseContactTableInterface = {
  contacts: CustomerContact[]
  markAsPrimaryBillingContact: (contactId: string) => Promise<void>
  editContact: {
    contactId: string | undefined
    editContactById: (contactId: string) => void
  }
  archiveContact: (contactId: string) => Promise<void>
}

type UseContactTable = () => UseContactTableInterface

export const useContactTable: UseContactTable = () => {
  const ctx = useCustomerContactsContext()

  const [editingContactId, setEditContactById] = useState<string | undefined>()

  const contacts = useMemo(
    () => sortContacts(ctx.queries.data.contacts),
    [ctx.queries.data.contacts]
  )

  const markAsPrimaryBillingContact = useCallback(
    (contactId: string) =>
      ctx.mutators.external.out.markAsPrimaryBillingContact(contactId),
    [ctx.mutators.external.out]
  )

  const editContactById = useCallback(
    (contactId: string) => {
      const thisContact = ctx.queries.data.contacts.find(
        contact => contact.id === contactId
      )

      if (!thisContact) {
        return
      }

      ctx.mutators.internal.updateForm({
        ...thisContact,
        name: thisContact.name || ''
      })

      setEditContactById(contactId)
    },
    [ctx.mutators.internal, ctx.queries.data.contacts]
  )

  const archiveContact = useCallback(
    (contactId: string) => ctx.mutators.external.out.archiveContact(contactId),
    [ctx.mutators.external.out]
  )

  return {
    contacts,
    markAsPrimaryBillingContact,
    editContact: {
      contactId: editingContactId,
      editContactById
    },
    archiveContact
  }
}
