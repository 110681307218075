import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box
} from '@chakra-ui/react'

import { FC } from 'react'

export interface AccordionProps {
  availableExportData: {
    id: string
    title: string
    description: string
  }[]
}

export const Accordion: FC<AccordionProps> = ({ availableExportData }) => {
  return (
    <ChakraAccordion
      variant="templateModal"
      overflow="scroll"
      allowMultiple={true}
    >
      {availableExportData.map(data => (
        <AccordionItem key={data.id}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Text textStyle="accordionTitle" pb="4px">
                {data.title}{' '}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <Text textStyle="accordionDescription">{data.description}</Text>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </ChakraAccordion>
  )
}
