import { useEffect, useState } from 'react'
import {
  dashboardv20240509Client,
  type Dashboardv20240509Api
} from '@sequencehq/api/dashboard/v20240509'

export type BillingScheduleList =
  Dashboardv20240509Api.GetBillingSchedules.BillingScheduleList
export type BillingSchedule =
  Dashboardv20240509Api.GetBillingSchedule.BillingSchedule

export const useFetchBillingSchedules = ({
  customerId
}: {
  customerId: string
}) => {
  const [billingSchedules, setBillingSchedules] = useState<
    BillingScheduleList | undefined
  >()

  useEffect(() => {
    const fetchData = async () => {
      const result = await dashboardv20240509Client.getBillingSchedules({
        customerId: customerId,
        limit: 12
      })

      if (result.data) {
        setBillingSchedules(result.data)
      }
    }

    void fetchData()
  }, [customerId])

  return {
    billingSchedules
  }
}
