import { v1ApiLoadedData } from '../../loadData'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type UseLoadLedgerIntegrations = () => Promise<
  | {
      data: v1ApiLoadedData['xeroIntegration']
      error: null
    }
  | {
      data: null
      error: PricingEditorPortErrors
    }
>

export const loadLedgerIntegrations: UseLoadLedgerIntegrations = async () => {
  const xeroIntegrationResponse = await dashboard20240730Client.getIntegration(
    'Xero'
  )

  if (xeroIntegrationResponse.error || !xeroIntegrationResponse.data) {
    return {
      data: null,
      error:
        xeroIntegrationResponse.res.status === 404
          ? PricingEditorPortErrors.NotFound
          : PricingEditorPortErrors.Other
    }
  }

  return {
    data: xeroIntegrationResponse.data as v1ApiLoadedData['xeroIntegration'],
    error: null
  }
}
