import { Card, CardBody, CardHeader, Flex } from '@chakra-ui/react'
import { GreyGrey30 } from '@sequencehq/design-tokens'

interface ChartTooltipProps {
  children: React.ReactElement[]
}
export const ChartTooltip = ({ children }: ChartTooltipProps) => {
  return (
    <Card
      bg="white"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      borderRadius="md"
      width="270px"
      border={`1px solid ${GreyGrey30}`}
    >
      <CardHeader
        px={3}
        py={2}
        bg="gray.10"
        borderBottom={`1px solid ${GreyGrey30}`}
      >
        {children[0]}
      </CardHeader>
      <CardBody py={2} px={3} as={Flex} justifyContent="space-between">
        {children[1]}
      </CardBody>
    </Card>
  )
}
