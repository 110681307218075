import { Minimum } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'
import { Minimum as CubeMinimum } from 'modules/Cube/domain/cube.domain.types'
import { enforceMinimumPrecision } from '@sequencehq/utils'

export const minimumEditorCubeAdapter = {
  in: (minimum: CubeMinimum): Minimum => ({
    ...minimum,
    value: enforceMinimumPrecision(2)(minimum.value)
  }),
  out: (minimum: Minimum): CubeMinimum => ({
    ...minimum,
    value: enforceMinimumPrecision(2)(minimum.value)
  })
}
