import {
  ActionHandler,
  IntegrationsReducerState,
  UpdateDataAction
} from 'modules/Integrations/domain/integrations.domain.types'
import deepmerge from 'deepmerge'

export const updateData: ActionHandler<UpdateDataAction> =
  prevState => action => {
    return {
      ...prevState,
      data: deepmerge(prevState.data, action.payload, {
        arrayMerge: (_, source: unknown[]) => source
      })
    } as IntegrationsReducerState
  }
