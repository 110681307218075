import { Box, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey80,
  GreyWhite,
  Lato14Bold,
  Lato14ExtendedRegular,
  Lato20Bold,
  LineHeights20
} from '@sequencehq/design-tokens'
import { useInvoiceEditorSummary } from 'InvoiceEditor/components/Summary/useInvoiceEditorSummary'
import { GroupSummary } from '@sequencehq/invoice-content'
import { TextAreaInput } from '@sequencehq/core-components'

export const Totals = () => {
  const { data, editMemo, showMemo } = useInvoiceEditorSummary()

  return (
    <Box
      padding="24px"
      border={`${GreyGrey30} 1px solid`}
      boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 4px 0px rgba(0, 0, 0, 0.02)"
      borderRadius="8px"
      backgroundColor={GreyWhite}
    >
      <Flex direction="column" gap="20px">
        <Flex direction="column" gap="4px">
          <Text {...Lato14Bold} lineHeight={LineHeights20}>
            Total
          </Text>
          <Text {...Lato20Bold}>{data.total}</Text>
        </Flex>

        <GroupSummary
          billingPeriod={data.billingPeriod}
          groupSummary={data.groupSummary}
          totals={data.totals}
        />

        {showMemo.available && (
          <Flex direction="column" gap="4px">
            <Text {...Lato14Bold} lineHeight={LineHeights20}>
              Memo
            </Text>
            {editMemo.available ? (
              <TextAreaInput
                onChange={editMemo.onChange}
                value={data.memo}
                maxLength={300}
                placeholder="Add an optional memo"
              />
            ) : (
              <Text {...Lato14ExtendedRegular} color={GreyGrey80}>
                {data.memo}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
