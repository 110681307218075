import { Box, Flex, FormLabel } from '@chakra-ui/react'
import { TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  nineDigitNumber,
  required,
  swiftCode
} from '@sequencehq/validation'
import { AddressInput } from 'components/FormComponents'
import { FC } from 'react'

export const UsWireFields: FC<{ fieldName: string }> = ({ fieldName }) => {
  return (
    <Flex flexDirection="column">
      <Flex>
        <Box width="200px">
          <TextInputField
            fieldLabel="Account Number"
            fieldName={`${fieldName}.value.usWireDestination.accountNumber`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Account Name"
            fieldName={`${fieldName}.value.usWireDestination.accountName`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Bank Name"
            fieldName={`${fieldName}.value.usWireDestination.bankName`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Bank Routing Number"
            fieldName={`${fieldName}.value.usWireDestination.bankRoutingNumber`}
            validate={composeValidators(required, nineDigitNumber)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Swift Code"
            fieldName={`${fieldName}.value.usWireDestination.swiftCode`}
            validate={composeValidators(required, swiftCode)}
          />
        </Box>
      </Flex>
      <Box height="24px"></Box>

      <FormLabel>Bank Address</FormLabel>
      <Box height={2} />

      <AddressInput
        name={`${fieldName}.value.usWireDestination.bankAddress.`}
      />
    </Flex>
  )
}
