import { Center, Flex, Grid, Text } from '@chakra-ui/react'
import { Badge, SequenceMenu, Button } from '@sequencehq/core-components'
import { toBillingScheduleStatusBadgeProps } from '@sequencehq/core-models'
import {
  GreyGrey30,
  GreyGrey60,
  RedRed60,
  GreyGrey80,
  Lato16Bold,
  GreyGrey70
} from '@sequencehq/design-tokens'
import {
  BillingScheduleModals,
  useBillingScheduleHeader
} from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleHeader/useBillingScheduleHeader'
import {
  MinusCircleIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/16/solid'
import { CubeStatus } from 'modules/Cube/domain/cube.domain.types'
import { scheduleStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { ConfirmStartScheduleModal } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleHeader/modals/ConfirmStartSchedule/ConfirmStartScheduleModal'
import { ArchiveBillingScheduleModal } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleHeader/modals/ArchiveScheduleModal/ArchiveBillingScheduleModal'
import { Blocker } from 'common/components/Blocker'
import { SteppedConfirmStartModal } from './modals/SteppedConfirmStartModal/SteppedConfirmStartModal'

export const BillingScheduleHeader = () => {
  const {
    title,
    status,
    save,
    cancel,
    saveDraft,
    blocker,
    isArchived,
    isNew,
    modals,
    activateModal
  } = useBillingScheduleHeader()

  const menuItems =
    !isArchived && !isNew
      ? [
          {
            onClick: () => activateModal(BillingScheduleModals.Archive),
            label: 'Archive schedule',
            style: { color: RedRed60 },
            iconLeft: (
              <MinusCircleIcon width="16px" height="16px" color={RedRed60} />
            )
          }
        ]
      : []

  return (
    <Grid
      templateColumns={`1fr auto`}
      gridArea="header"
      position="sticky"
      top={0}
      left={0}
      h={47}
      borderBottom={`1px solid ${GreyGrey30}`}
      alignItems="center"
      px="16px"
      columnGap="24px"
    >
      <Flex alignItems="center" gap="8px">
        <Text {...Lato16Bold} color={isArchived ? GreyGrey60 : GreyGrey80}>
          {title}
        </Text>
        {status !== CubeStatus.ScheduleUnsaved && (
          <Badge
            {...toBillingScheduleStatusBadgeProps({
              status: scheduleStatusAdapter.out(status) ?? 'DRAFT'
            })}
          />
        )}
      </Flex>

      <Flex alignItems="center" gap="8px">
        <Button variant="secondary" onClick={cancel.onClick}>
          Cancel
        </Button>
        {saveDraft.show && (
          <Button
            variant="secondary"
            onClick={saveDraft.onClick}
            disabled={saveDraft.disabled}
          >
            {saveDraft.text}
          </Button>
        )}
        {save.show && (
          <Button
            variant="primary"
            data-testid="schedule.start"
            onClick={save.onClick}
            disabled={save.disabled}
          >
            {save.text}
          </Button>
        )}
        {menuItems.length > 0 && (
          <SequenceMenu
            alignment="bottom-right"
            items={menuItems}
            menuButton={
              <Button variant="ghost" size="32px">
                <Center>
                  <EllipsisHorizontalIcon
                    width="16px"
                    height="16px"
                    color={GreyGrey70}
                  />
                </Center>
              </Button>
            }
          />
        )}
      </Flex>
      {modals.confirmStart.active && (
        <ConfirmStartScheduleModal
          onClose={modals.confirmStart.onClose}
          onConfirm={modals.confirmStart.onConfirm}
          submitting={modals.confirmStart.submitting}
        />
      )}
      {modals.archive.active && (
        <ArchiveBillingScheduleModal
          status={modals.archive.status}
          submitting={modals.archive.submitting}
          onClose={modals.archive.onClose}
          onConfirm={modals.archive.onConfirm}
        />
      )}
      {modals.steppedConfirmStart.active && (
        <SteppedConfirmStartModal
          onClose={modals.steppedConfirmStart.onClose}
          onConfirm={modals.steppedConfirmStart.onConfirm}
          submitting={modals.steppedConfirmStart.submitting}
          billingScheduleQueries={
            modals.steppedConfirmStart.billingScheduleQueries
          }
        />
      )}
      <Blocker
        name="billing schedule editor"
        dirty={blocker.enabled}
        ignoreBlock={blocker.ignoreBlock}
        submitting={blocker.submitting}
      />
    </Grid>
  )
}
