// dist/index.ts
var LineHeights16 = "16px";
var LineHeights20 = "20px";
var LineHeights24 = "24px";
var LineHeights28 = "28px";
var LineHeights32 = "32px";
var LineHeights40 = "40px";
var LineHeights48 = "48px";
var LineHeights64 = "64px";
var FontWeightsRegular = 400;
var FontWeightsBold = 700;
var FontSize12 = "12px";
var FontSize13 = "13px";
var FontSize14 = "14px";
var FontSize16 = "16px";
var FontSize18 = "18px";
var FontSize20 = "20px";
var FontSize24 = "24px";
var FontSize28 = "28px";
var FontSize32 = "32px";
var FontSize40 = "40px";
var Lato40Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "64px", "fontSize": "40px" };
var Lato40Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "64px", "fontSize": "40px" };
var Lato32Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "40px", "fontSize": "32px" };
var Lato32Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "40px", "fontSize": "32px" };
var Lato28Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "32px", "fontSize": "28px" };
var Lato28Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "32px", "fontSize": "28px" };
var Lato24Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "28px", "fontSize": "24px" };
var Lato24Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "28px", "fontSize": "24px" };
var Lato20Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "24px", "fontSize": "20px" };
var Lato20Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "24px", "fontSize": "20px" };
var Lato16Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "24px", "fontSize": "16px" };
var Lato16Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "24px", "fontSize": "16px" };
var Lato14ExtendedBold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "20px", "fontSize": "14px" };
var Lato14ExtendedRegular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "20px", "fontSize": "14px" };
var Lato14Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "16px", "fontSize": "14px" };
var Lato14Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "14px" };
var Lato13Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "16px", "fontSize": "13px" };
var Lato13Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "13px" };
var GreyWhite = "#ffffff";
var GreyGrey0 = "#fcfcfd";
var GreyGrey10 = "#f7f9fb";
var GreyGrey20 = "#f1f4f8";
var GreyGrey30 = "#e4e8ef";
var GreyGrey40 = "#d1d9e4";
var GreyGrey50 = "#a9b3c5";
var GreyGrey60 = "#7483a0";
var GreyGrey70 = "#485972";
var GreyGrey80 = "#344054";
var GreyGrey90 = "#1d2939";
var GreyGrey100 = "#14171c";
var GreenGreen10 = "#eaf8ef";
var GreenGreen20 = "#ebfbc9";
var GreenGreen30 = "#d7f792";
var GreenGreen40 = "#b8f740";
var GreenGreen50 = "#aeee26";
var GreenGreen60 = "#94d40c";
var GreenGreen70 = "#4a9000";
var GreenGreen80 = "#1b6600";
var GreenGreen90 = "#0e3300";
var TurquoiseTurquoise10 = "#e3fcff";
var TurquoiseTurquoise20 = "#c0f7ff";
var TurquoiseTurquoise30 = "#7ef6f7";
var TurquoiseTurquoise40 = "#63ddde";
var TurquoiseTurquoise50 = "#4dd9db";
var TurquoiseTurquoise60 = "#00a0a2";
var TurquoiseTurquoise70 = "#005e61";
var TurquoiseTurquoise80 = "#05454a";
var TurquoiseTurquoise90 = "#003337";
var IndigoIndigo10 = "#ebebff";
var IndigoIndigo20 = "#dedaff";
var IndigoIndigo30 = "#d8c4ff";
var IndigoIndigo40 = "#9694eb";
var IndigoIndigo50 = "#716fff";
var IndigoIndigo60 = "#4745ba";
var IndigoIndigo70 = "#373691";
var IndigoIndigo80 = "#26137d";
var IndigoIndigo90 = "#170568";
var PurplePurple10 = "#ffe5ff";
var PurplePurple20 = "#ffccff";
var PurplePurple30 = "#f7b7f7";
var PurplePurple40 = "#d194eb";
var PurplePurple50 = "#b15ec5";
var PurplePurple60 = "#9140a6";
var PurplePurple70 = "#642d73";
var PurplePurple80 = "#501e5e";
var PurplePurple90 = "#410c5b";
var RedRed10 = "#ffe5ea";
var RedRed20 = "#ffccd5";
var RedRed30 = "#ffa8b2";
var RedRed40 = "#fc7182";
var RedRed50 = "#ff4c62";
var RedRed60 = "#ec3854";
var RedRed70 = "#cc213b";
var RedRed80 = "#a1001d";
var RedRed90 = "#690013";
var OrangeOrange10 = "#ffe6d9";
var OrangeOrange20 = "#ffccb0";
var OrangeOrange30 = "#ffb38a";
var OrangeOrange40 = "#ff8e52";
var OrangeOrange50 = "#f27029";
var OrangeOrange60 = "#d25b23";
var OrangeOrange70 = "#a64714";
var OrangeOrange80 = "#7d350f";
var OrangeOrange90 = "#5d2200";
var YellowYellow10 = "#fff5db";
var YellowYellow20 = "#ffe6a8";
var YellowYellow30 = "#ffda7b";
var YellowYellow40 = "#fccf5b";
var YellowYellow50 = "#ffbf40";
var YellowYellow60 = "#d1992a";
var YellowYellow70 = "#966c16";
var YellowYellow80 = "#63470f";
var YellowYellow90 = "#442900";
var Lato12Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "16px", "fontSize": "12px" };
var Lato12UppercaseBold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "16px", "fontSize": "12px", "letterSpacing": "0.12px", "textCase": "uppercase" };
var Lato12Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px" };
var Lato12UppercaseRegular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px", "textCase": "uppercase", "letterSpacing": "0.12px" };
var FontFamiliesLato = "Lato";
var FontFamiliesInconsolata = "Inconsolata";
var ShadowColourGrey100 = "#14171c";
var Shadow1 = "0 2px 4px 0 14171C08";
var ShadowXs = "0 0 2px 0 #00000005, 0 1px 1px 0 #0000000d";
var ShadowS = "0 1px 3px 0 #00000014, 0 0 5px 0 #00000008";
var ShadowSEmboss = "0 1px 3px 0 #00000014, 0 0 5px 0 #00000008, inset 0 -1px 1px 0 #00000012";
var ShadowM = "0 4px 8px 0 #0000000a, 0 0 4px 0 #00000005";
var ShadowL = "0 8px 16px 0 #0000000d, 0 0 4px 0 #00000005";
var BackgroundColoursCtaBackgroundColour = "#003337";
var BackgroundColoursSuccess = "#ebfbc9";
var BackgroundColoursNeutral = "#f1f4f8";
var BackgroundColoursInformative = "#dedaff";
var BackgroundColoursWarning = "#ffe6a8";
var BackgroundColoursError = "#ffccd5";
var BackgroundColoursHighlight = "#ffccff";
var OnBackgroundColoursMainDark = "#14171c";
var OnBackgroundColoursMainLight = "#fcfcfd";
var OnBackgroundColoursSuccess = "#1b6600";
var OnBackgroundColoursNeutral = "#344054";
var OnBackgroundColoursInformative = "#26137d";
var OnBackgroundColoursWarning = "#63470f";
var OnBackgroundColoursError = "#a1001d";
var OnBackgroundColoursHighlight = "#501e5e";
var BadgeMdContainerSuccess = { "fill": "#ebfbc9", "borderRadius": "4px", "height": "24px", "verticalPadding": "px", "horizontalPadding": "8px" };
var BadgeMdContainerNeutral = { "fill": "#f1f4f8", "borderRadius": "4px", "height": "24px", "verticalPadding": "px", "horizontalPadding": "8px" };
var BadgeMdContainerInfo = { "fill": "#dedaff", "borderRadius": "4px", "height": "24px", "verticalPadding": "px", "horizontalPadding": "8px" };
var BadgeMdContainerWarning = { "fill": "#ffe6a8", "borderRadius": "4px", "height": "24px", "verticalPadding": "px", "horizontalPadding": "8px" };
var BadgeMdContainerError = { "fill": "#ffccd5", "borderRadius": "4px", "height": "24px", "verticalPadding": "px", "horizontalPadding": "8px" };
var BadgeMdContainerHighlight = { "fill": "#ffccff", "borderRadius": "4px", "height": "24px", "verticalPadding": "px", "horizontalPadding": "8px" };
var BadgeSmContainerSuccess = { "fill": "#ebfbc9", "borderRadius": "4px", "height": "20px", "horizontalPadding": "px" };
var BadgeSmContainerNeutral = { "fill": "#f1f4f8", "borderRadius": "4px", "height": "20px", "horizontalPadding": "px" };
var BadgeSmContainerInfo = { "fill": "#dedaff", "borderRadius": "4px", "height": "20px", "horizontalPadding": "px" };
var BadgeSmContainerWarning = { "fill": "#ffe6a8", "borderRadius": "4px", "height": "20px", "horizontalPadding": "px" };
var BadgeSmContainerError = { "fill": "#ffccd5", "borderRadius": "4px", "height": "20px", "horizontalPadding": "px" };
var BadgeSmContainerHighlight = { "fill": "#ffccff", "borderRadius": "4px", "height": "20px", "horizontalPadding": "px" };
var BadgeTextLabelSuccess = { "typography": { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px" }, "fill": "#1b6600" };
var BadgeTextLabelNeutral = { "typography": { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px" }, "fill": "#344054" };
var BadgeTextLabelInfo = { "typography": { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px" }, "fill": "#26137d" };
var BadgeTextLabelWarning = { "typography": { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px" }, "fill": "#63470f" };
var BadgeTextLabelError = { "typography": { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px" }, "fill": "#a1001d" };
var BadgeTextLabelHighlight = { "typography": { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "16px", "fontSize": "12px" }, "fill": "#501e5e" };
var Spacing4 = "px";
var Spacing8 = "8px";
var Spacing12 = "12px";
var Spacing16 = "16px";
var Spacing20 = "20px";
var Spacing24 = "24px";
var Spacing32 = "32px";
var Spacing40 = "40px";
var Spacing48 = "48px";
var Spacing56 = "56px";
var Spacing72 = "72px";
var Spacing80 = "80px";
var BorderRadius4 = "4px";
var BorderRadius6 = "6px";
var BorderRadius8 = "8px";
var Sizing8 = "8px";
var Sizing12 = "12px";
var Sizing16 = "16px";
var Sizing18 = "18px";
var Sizing20 = "20px";
var Sizing24 = "24px";
var Sizing32 = "32px";
var Sizing40 = "40px";
var Sizing48 = "48px";
var Sizing56 = "56px";
var Sizing72 = "72px";
var Sizing80 = "80px";
var LetterSpacingSm = "0.12px";
var TextCaseUppercase = "uppercase";
var Lato18Bold = { "fontFamily": "Lato", "fontWeight": 700, "lineHeight": "24px", "fontSize": "18px" };
var Lato18Regular = { "fontFamily": "Lato", "fontWeight": 400, "lineHeight": "24px", "fontSize": "18px" };
var Inconsolata14Regular = { "fontFamily": "Inconsolata", "fontWeight": 400, "fontSize": "14px", "lineHeight": "16px" };
var Inconsolata13Bold = { "fontFamily": "Inconsolata", "fontWeight": 700, "fontSize": "13px", "lineHeight": "16px" };
var TokenSetOrder0 = "Sequence Tokens";
var Main = "[object Object]";
export {
  BackgroundColoursCtaBackgroundColour,
  BackgroundColoursError,
  BackgroundColoursHighlight,
  BackgroundColoursInformative,
  BackgroundColoursNeutral,
  BackgroundColoursSuccess,
  BackgroundColoursWarning,
  BadgeMdContainerError,
  BadgeMdContainerHighlight,
  BadgeMdContainerInfo,
  BadgeMdContainerNeutral,
  BadgeMdContainerSuccess,
  BadgeMdContainerWarning,
  BadgeSmContainerError,
  BadgeSmContainerHighlight,
  BadgeSmContainerInfo,
  BadgeSmContainerNeutral,
  BadgeSmContainerSuccess,
  BadgeSmContainerWarning,
  BadgeTextLabelError,
  BadgeTextLabelHighlight,
  BadgeTextLabelInfo,
  BadgeTextLabelNeutral,
  BadgeTextLabelSuccess,
  BadgeTextLabelWarning,
  BorderRadius4,
  BorderRadius6,
  BorderRadius8,
  FontFamiliesInconsolata,
  FontFamiliesLato,
  FontSize12,
  FontSize13,
  FontSize14,
  FontSize16,
  FontSize18,
  FontSize20,
  FontSize24,
  FontSize28,
  FontSize32,
  FontSize40,
  FontWeightsBold,
  FontWeightsRegular,
  GreenGreen10,
  GreenGreen20,
  GreenGreen30,
  GreenGreen40,
  GreenGreen50,
  GreenGreen60,
  GreenGreen70,
  GreenGreen80,
  GreenGreen90,
  GreyGrey0,
  GreyGrey10,
  GreyGrey100,
  GreyGrey20,
  GreyGrey30,
  GreyGrey40,
  GreyGrey50,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  GreyWhite,
  Inconsolata13Bold,
  Inconsolata14Regular,
  IndigoIndigo10,
  IndigoIndigo20,
  IndigoIndigo30,
  IndigoIndigo40,
  IndigoIndigo50,
  IndigoIndigo60,
  IndigoIndigo70,
  IndigoIndigo80,
  IndigoIndigo90,
  Lato12Bold,
  Lato12Regular,
  Lato12UppercaseBold,
  Lato12UppercaseRegular,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold,
  Lato14ExtendedBold,
  Lato14ExtendedRegular,
  Lato14Regular,
  Lato16Bold,
  Lato16Regular,
  Lato18Bold,
  Lato18Regular,
  Lato20Bold,
  Lato20Regular,
  Lato24Bold,
  Lato24Regular,
  Lato28Bold,
  Lato28Regular,
  Lato32Bold,
  Lato32Regular,
  Lato40Bold,
  Lato40Regular,
  LetterSpacingSm,
  LineHeights16,
  LineHeights20,
  LineHeights24,
  LineHeights28,
  LineHeights32,
  LineHeights40,
  LineHeights48,
  LineHeights64,
  Main,
  OnBackgroundColoursError,
  OnBackgroundColoursHighlight,
  OnBackgroundColoursInformative,
  OnBackgroundColoursMainDark,
  OnBackgroundColoursMainLight,
  OnBackgroundColoursNeutral,
  OnBackgroundColoursSuccess,
  OnBackgroundColoursWarning,
  OrangeOrange10,
  OrangeOrange20,
  OrangeOrange30,
  OrangeOrange40,
  OrangeOrange50,
  OrangeOrange60,
  OrangeOrange70,
  OrangeOrange80,
  OrangeOrange90,
  PurplePurple10,
  PurplePurple20,
  PurplePurple30,
  PurplePurple40,
  PurplePurple50,
  PurplePurple60,
  PurplePurple70,
  PurplePurple80,
  PurplePurple90,
  RedRed10,
  RedRed20,
  RedRed30,
  RedRed40,
  RedRed50,
  RedRed60,
  RedRed70,
  RedRed80,
  RedRed90,
  Shadow1,
  ShadowColourGrey100,
  ShadowL,
  ShadowM,
  ShadowS,
  ShadowSEmboss,
  ShadowXs,
  Sizing12,
  Sizing16,
  Sizing18,
  Sizing20,
  Sizing24,
  Sizing32,
  Sizing40,
  Sizing48,
  Sizing56,
  Sizing72,
  Sizing8,
  Sizing80,
  Spacing12,
  Spacing16,
  Spacing20,
  Spacing24,
  Spacing32,
  Spacing4,
  Spacing40,
  Spacing48,
  Spacing56,
  Spacing72,
  Spacing8,
  Spacing80,
  TextCaseUppercase,
  TokenSetOrder0,
  TurquoiseTurquoise10,
  TurquoiseTurquoise20,
  TurquoiseTurquoise30,
  TurquoiseTurquoise40,
  TurquoiseTurquoise50,
  TurquoiseTurquoise60,
  TurquoiseTurquoise70,
  TurquoiseTurquoise80,
  TurquoiseTurquoise90,
  YellowYellow10,
  YellowYellow20,
  YellowYellow30,
  YellowYellow40,
  YellowYellow50,
  YellowYellow60,
  YellowYellow70,
  YellowYellow80,
  YellowYellow90
};
