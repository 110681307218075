import { useProductContext } from 'Products/hooks/useProductContext'
import { NewListPriceEditorRoute } from './NewListPriceEditor.route.connector'
import { ListPriceEditorRoute } from './ListPriceEditor.route.connector'

export const ListPriceEditorRouteWrapper = () => {
  const context = useProductContext()

  if (context.features.newPriceEditor) {
    return <NewListPriceEditorRoute />
  }

  return <ListPriceEditorRoute />
}
