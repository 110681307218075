import { TeamSettings } from 'components/TeamSettings/TeamSettingsProvider'
import { useContext } from 'react'

export const useTeamSettings = () => {
  const context = useContext(TeamSettings)

  if (!context) {
    throw new Error(
      'useTeamSettings must be used within a TeamSettingsProvider'
    )
  }
  return context
}
