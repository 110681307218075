import * as Sentry from '@sentry/react'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useMemo, useState } from 'react'
import { getPortalDomain } from '@sequencehq/utils'
import { getEnvironment } from 'lib/environment/environment'
import { useNavigate } from 'react-router-dom'
import { useRemoteQuotePdfDownload } from '@sequencehq/quote-content'
import { useNotifications } from 'lib/hooks/useNotifications'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'

export enum Status {
  Accepted = 'accepted',
  Executed = 'executed',
  Published = 'published',
  NotPublished = 'notPublished'
}

type PublishedStatusActions = {
  visible: boolean
  disabled: boolean
  tooltip: string
  onClick: () => void
}

type UsePublishedStatus = () => {
  status: Status
  features: {
    download: PublishedStatusActions
    openCustomerPortal: PublishedStatusActions
    openBillingSchedule: PublishedStatusActions
    copyLinkAction: PublishedStatusActions & {
      onBlur: () => void
      isCopied: boolean
    }
  }
}

export const usePublishedStatus: UsePublishedStatus = () => {
  const cubeContext = useCubeContext()
  const navigate = useNavigate()
  const [isCopied, setIsCopied] = useState(false)

  const status = useMemo(() => {
    if (cubeContext.queries.rawData.data.quote.executedAt) {
      return Status.Executed
    }

    if (cubeContext.queries.rawData.data.quote.acceptedAt) {
      return Status.Accepted
    }

    if (cubeContext.queries.rawData.data.quote.publishedAt) {
      return Status.Published
    }

    return Status.NotPublished
  }, [
    cubeContext.queries.rawData.data.quote.acceptedAt,
    cubeContext.queries.rawData.data.quote.executedAt,
    cubeContext.queries.rawData.data.quote.publishedAt
  ])

  const { onClick: downloadOnClick } = useRemoteQuotePdfDownload({
    quoteNumber: cubeContext.queries.rawData.data.quote.quoteNumber,
    notificationHook: useNotifications,
    downloadPdfEndpoint: () =>
      dashboardv20240509Client.downloadQuotePdf({
        id: cubeContext.queries.rawData.data.common.id
      })
  })

  const download = useMemo(() => {
    const downloadEnabled = ['GENERATED', 'NOT_YET_REQUESTED'].includes(
      cubeContext.queries.rawData.data.quote.pdfStatus
    )
    return {
      visible: [Status.Published, Status.Accepted, Status.Executed].includes(
        status
      ),
      disabled: !downloadEnabled,
      tooltip: downloadEnabled ? 'Download quote PDF' : 'PDF not ready',
      onClick: downloadOnClick
    }
  }, [downloadOnClick, status])

  const portalUrl = useMemo(() => {
    const { customerId, id } = cubeContext.queries.rawData.data.common
    return `${getPortalDomain(getEnvironment())}/quotes/${customerId}/${id}`
  }, [cubeContext.queries.rawData.data.common])

  const openCustomerPortal = useMemo(() => {
    return {
      visible: [Status.Published, Status.Accepted, Status.Executed].includes(
        status
      ),
      disabled: false,
      tooltip: 'Go to published quote',
      onClick: () => window.open(portalUrl, '_blank')
    }
  }, [status, portalUrl])

  const billingScheduleUrl = useMemo(() => {
    const { billingScheduleId } = cubeContext.queries.rawData.data.quote
    return `/billing-schedules/${billingScheduleId}`
  }, [cubeContext.queries.rawData.data.quote])

  const openBillingSchedule = useMemo(() => {
    return {
      visible: status === Status.Executed,
      disabled: false,
      tooltip: 'Go to billing schedule',
      onClick: () => navigate(billingScheduleUrl)
    }
  }, [status, billingScheduleUrl, navigate])

  const copyLinkTooltipText = isCopied ? 'Link copied' : 'Copy published link'

  const copyLinkAction = useMemo(() => {
    return {
      visible: [Status.Published, Status.Accepted, Status.Executed].includes(
        status
      ),
      disabled: false,
      tooltip: copyLinkTooltipText,
      isCopied,
      onClick: async () => {
        try {
          await navigator.clipboard.writeText(portalUrl)
          setIsCopied(true)
          setTimeout(() => {
            setIsCopied(false)
          }, 1500)
        } catch (e) {
          Sentry.captureException(e)
        }
      },
      onBlur: () => setIsCopied(false)
    }
  }, [status, copyLinkTooltipText, portalUrl, isCopied])

  return {
    status,
    features: {
      download,
      openCustomerPortal,
      openBillingSchedule,
      copyLinkAction
    }
  }
}
