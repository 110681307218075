import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { useSelectCoverImageSrc } from 'Cube/domain/subdomain/coverImage'
import { usePrevious } from 'lib/usePrevious'
import { createContext, PropsWithChildren, useContext, useState } from 'react'

type CoverImageEditorContextData = {
  imageSrc?: string
  setImageSrc: (val?: string) => void
  file?: File
  prevImageSrc?: string
  setFile: (val?: File) => void
  isDisabled?: boolean
}

export const CoverImageEditorContext =
  createContext<CoverImageEditorContextData>({
    setImageSrc: _ => {
      _
    },
    setFile: _ => {
      _
    }
  })

export const useCoverImageEditorContext = () => {
  return useContext(CoverImageEditorContext)
}

export const CoverImageEditorProvider = ({ children }: PropsWithChildren) => {
  const cubeContext = useCubeContext()
  const assetSrc = useSelectCoverImageSrc()

  const [file, setFile] = useState<File | undefined>(undefined)

  // We store this so there's no content jump when the image finishes uploading,
  // and so we can transition out without the image disappearing immediately
  const [imageSrc, setImageSrc] = useState<string | undefined>(assetSrc)

  const prevImageSrc = usePrevious(imageSrc)

  return (
    <CoverImageEditorContext.Provider
      value={{
        file,
        setFile,
        imageSrc,
        setImageSrc,
        prevImageSrc,
        isDisabled:
          !cubeContext.queries.availableFeatures.quote.edit.available.enabled
      }}
    >
      {children}
    </CoverImageEditorContext.Provider>
  )
}
