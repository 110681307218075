import {
  AvailableStandardFrequency,
  UsageCalculationMode
} from 'common/drawers/PricingEditor/domain'
import { UsageTierType } from 'Products/drawers/ListPriceEditor/domain/listPriceEditor.types.ts'
import { UsageCalculationPeriodDropdownValue } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import { BillingFrequency } from '@sequencehq/core-models'
import { defaultAvailableFrequenciesOptions } from 'common/drawers/PricingEditor/domain/pricingEditor.constants.ts'

export const standardPriceFrequencies: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

export const usageCalculationModeDescriptions: Record<
  UsageCalculationMode,
  string
> = {
  CUMULATIVE:
    'Usage is counted from the billing start date and accumulates with each billing period.',
  BILLING_PERIOD: 'Charges are based on usage in each billing period.',
  PERIODIC: 'Charges are based on usage in each billing period.'
}

export const availableUsageTierTypes: {
  label: string
  value: UsageTierType
}[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const availableUsageCalculationModes: {
  label: string
  value: UsageCalculationMode
}[] = [
  {
    label: 'Billing period calculation (default)',
    value: 'BILLING_PERIOD'
  },
  {
    label: 'Cumulative calculation',
    value: 'CUMULATIVE'
  }
]

export const usageCalculationPeriodLabels: Record<
  UsageCalculationPeriodDropdownValue,
  string
> = {
  BILLING_PERIOD: 'Billing period (default)',
  CUMULATIVE_USAGE: 'Cumulative usage',
  QUARTERLY: 'Quarterly',
  ANNUAL: 'Annual'
} as const

const usageCalculationPeriodOptions = {
  billingPeriod: {
    label: usageCalculationPeriodLabels.BILLING_PERIOD,
    value: 'BILLING_PERIOD'
  },
  cumulativeUsage: {
    label: usageCalculationPeriodLabels.CUMULATIVE_USAGE,
    value: 'CUMULATIVE_USAGE'
  },
  quarterly: {
    label: usageCalculationPeriodLabels.QUARTERLY,
    value: 'QUARTERLY'
  },
  annual: {
    label: usageCalculationPeriodLabels.ANNUAL,
    value: 'ANNUAL'
  }
} as const

export const availableUsageCalculationPeriodsByBillingFrequency: Record<
  BillingFrequency,
  {
    label: string
    value: UsageCalculationPeriodDropdownValue
  }[]
> = {
  ONE_TIME: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage
  ],
  MONTHLY: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage,
    usageCalculationPeriodOptions.quarterly,
    usageCalculationPeriodOptions.annual
  ],
  QUARTERLY: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage,
    usageCalculationPeriodOptions.annual
  ],
  YEARLY: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage
  ]
}
