import { useCallback } from 'react'

import {
  dashboardv99990101Client,
  type Dashboardv99990101CommonModels
} from '@sequencehq/api/dashboard/v99990101'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems'

type v99990101ApiCustomer =
  Dashboardv99990101CommonModels.Customers.Customer & {
    aliases: { value: string; id: string; label?: string }[]
  }

export type v1ApiCustomer = Omit<v99990101ApiCustomer, 'contacts'>

type UseLoadCustomers = () => () => Promise<v1ApiCustomer[]>

export const useLoadAllCustomers: UseLoadCustomers = () => {
  const loadCustomers = useLoadAllItems(dashboardv99990101Client.getCustomers)

  const load = useCallback(async () => {
    const allCustomers = (await loadCustomers()) ?? []

    return allCustomers.map(c => ({
      ...c,
      aliases: []
    }))
  }, [])

  return load
}
