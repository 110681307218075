import {
  InvoiceEditorReducerState,
  RecursivePartial,
  UpdateInvoiceEditorDataAction
} from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'
import deepmerge from 'deepmerge'

type UpdateInvoiceEditorData = (
  prevState: InvoiceEditorReducerState
) => (action: UpdateInvoiceEditorDataAction) => InvoiceEditorReducerState

export const updateInvoiceEditorData: UpdateInvoiceEditorData =
  prevState => action => ({
    ...prevState,
    data: deepmerge<
      InvoiceEditorReducerState['data'],
      RecursivePartial<InvoiceEditorReducerState['data']>
    >(prevState.data, action.payload, {
      arrayMerge: (_, source: unknown[]) => source
    })
  })
