import DeleteInvoiceLineItemGroupForm from 'components/DeleteLineItemGroup/DeleteInvoiceLineItemGroupForm'
import {
  DeleteInvoiceLineItemGroupFormMutation,
  DeleteInvoiceLineItemGroupFormProps
} from 'components/DeleteLineItemGroup/types'
import { createFormModal } from 'components/Form/createFormModal'
import { useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation } from 'features/api'

export const DeleteInvoiceLineItemGroupModal = createFormModal<
  DeleteInvoiceLineItemGroupFormMutation,
  DeleteInvoiceLineItemGroupFormProps
>({
  FormComponent: DeleteInvoiceLineItemGroupForm,
  useMutation: useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation,
  modalTitle: 'Delete Line Item Group',
  successMessage: 'Deleted',
  submitTitle: 'Delete line item group',
  formId: 'deleteLineItemGroupForm',
  buttonVariant: 'warning'
})
