import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import {
  lineItemRowHeight,
  lineItemTableHeaderHeight
} from '@sequencehq/invoice-content'
import { toPercentage } from '@sequencehq/formatters'
import {
  toMoney,
  percentageFromDecimal,
  Currency,
  LineItemModel
} from '@sequencehq/core-models'
import { FC } from 'react'
import SelectableSection from 'components/InvoiceLineItemSelect/SelectableSection'

interface InvoiceLineItemTableSelectProps {
  currency: Currency
  lineItems: LineItemModel[]
  selectLineItem: (id: string) => void
  unSelectLineItem: (id: string) => void
  disabledLineItems: string[]
}

const InvoiceLineItemTableSelect: FC<InvoiceLineItemTableSelectProps> = ({
  currency,
  lineItems,
  selectLineItem,
  unSelectLineItem,
  disabledLineItems
}) => {
  return (
    <>
      <Flex h={`${lineItemTableHeaderHeight}px`} flexDirection="column">
        <Box h="8px"></Box>
        <Flex color="gray.60" fontSize="14px" fontWeight="700">
          <Box w="16px"></Box>
          <Text w="176px">Description</Text>
          <Box w="16px"></Box>
          <Text w="88px" textAlign="right">
            Rate
          </Text>
          <Box w="16px"></Box>
          <Text w="60px">Quantity</Text>
          <Box w="16px"></Box>
          <Text w="80px" textAlign="right">
            Net
          </Text>
          <Box w="16px"></Box>
          <Text w="56px" textAlign="right">
            VAT %
          </Text>
          <Box w="16px"></Box>
          <Text w="72px" textAlign="right">
            VAT
          </Text>
          <Box w="16px"></Box>
          <Text w="80px" textAlign="right">
            Gross
          </Text>
        </Flex>
        <Box h="8px"></Box>
      </Flex>

      <Divider borderColor="gray.60" borderTopWidth="2px" />
      {lineItems.map(item => (
        <Box key={item.id}>
          <Flex h={`${lineItemRowHeight}px`} flexDirection="column">
            <Box h="12px"></Box>
            <SelectableSection
              clickSection={selected => {
                if (selected) {
                  selectLineItem(item.id)
                } else {
                  unSelectLineItem(item.id)
                }
              }}
              selectedAndDisabled={disabledLineItems.includes(item.id)}
            >
              <Flex>
                <Box w="16px"></Box>
                <Text w="176px">{item.title}</Text>
                <Box w="16px"></Box>
                <Text w="88px" textAlign="right">
                  {toMoney({
                    currency: currency,
                    value: item.rate
                  })}
                </Text>
                <Box w="16px"></Box>
                <Text w="60px">{item.quantity}</Text>
                <Box w="16px"></Box>
                <Text w="80px" textAlign="right">
                  {toMoney({
                    currency: currency,
                    value: item.netTotal
                  })}
                </Text>
                <Box w="16px"></Box>
                <Text w="56px" textAlign="right">
                  {toPercentage(percentageFromDecimal(item.taxRate))}
                </Text>
                <Box w="16px"></Box>
                <Text w="72px" textAlign="right">
                  {toMoney({
                    currency: currency,
                    value: item.totalTax
                  })}
                </Text>
                <Box w="16px"></Box>
                <Text w="80px" textAlign="right">
                  {toMoney({
                    currency: currency,
                    value: item.grossTotal
                  })}
                </Text>
              </Flex>
            </SelectableSection>
            <Box h="12px"></Box>
          </Flex>

          <Divider borderColor="gray.40" borderTopWidth="1px" />
        </Box>
      ))}
    </>
  )
}

export default InvoiceLineItemTableSelect
