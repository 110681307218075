import { FC } from 'react'
import {
  Button,
  Flex,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Text
} from '@chakra-ui/react'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { Lato16Regular, Lato24Bold } from '@sequencehq/design-tokens'

const DemoInfoModal: FC = () => {
  const dispatch = useDispatch()

  return (
    <ModalContent borderRadius="8px">
      <Flex justify="end" height="36px" px="24px" pt="16px">
        <ModalCloseButton />
      </Flex>

      <ModalBody px="40px" mb="32px">
        <Flex align="center" direction="column">
          <Image
            src="/img/logo.svg"
            height="64px"
            width="64px"
            alt="Sequence"
            mt="12px"
            mb={6}
          />
          <Text {...Lato24Bold} mb="16px">
            You can't save in this demo account
          </Text>
          <Text {...Lato16Regular} align="center">
            You're browsing in demo mode. You won't be able to edit, create or
            delete any data. Get in touch with the Sequence team to set up your
            own account.
          </Text>
        </Flex>
      </ModalBody>

      <ModalFooter justifyContent="center" border="none" pb={0} mb="40px">
        <Button
          variant="secondary"
          mr={3}
          onClick={() => {
            dispatch(closeOverlay())
          }}
        >
          Not now
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            window.open('https://access.sequencehq.com/', '_blank')
          }
        >
          Request account
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default DemoInfoModal
