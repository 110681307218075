import { createContext, useContext } from 'react'

import { dequal } from 'dequal'

import { CustomerContactsDomainInterface } from 'modules/CustomerContacts/domain/CustomerContacts.domain'

const CustomerContactsContext = createContext<CustomerContactsDomainInterface>({
  queries: {} as CustomerContactsDomainInterface['queries'],
  mutators: {} as CustomerContactsDomainInterface['mutators']
})

export const useCustomerContactsContext = () => {
  const customerContactsContext = useContext(CustomerContactsContext)

  if (dequal(customerContactsContext, { queries: {}, mutators: {} })) {
    throw new Error(
      'Cannot use customer contacts context before initialisation'
    )
  }

  return customerContactsContext
}

export const CustomerContactsProvider = CustomerContactsContext.Provider
