import { ListPriceEditorMode } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { useMemo } from 'react'

type BreadcrumbStep = {
  title: string
  active: boolean
}

export const useEditorBreadcrumb = () => {
  const listPriceEditorContext = useListPriceEditorContext()
  const isCreating =
    listPriceEditorContext.configuration.mode === ListPriceEditorMode.CREATE
  const isEditing =
    listPriceEditorContext.configuration.mode === ListPriceEditorMode.EDIT

  const steps = useMemo<BreadcrumbStep[]>(() => {
    return [
      {
        title: 'Configure list price',
        active: isCreating || isEditing
      },
      {
        title: 'Review',
        active:
          listPriceEditorContext.configuration.mode ===
          ListPriceEditorMode.REVIEW
      }
    ]
  }, [isCreating, isEditing, listPriceEditorContext.configuration.mode])

  return {
    steps
  }
}
