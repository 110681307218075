import {
  Box,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  Text
} from '@chakra-ui/react'
import invariant from 'tiny-invariant'
import { Lato16Bold, GreyGrey30, Lato14Bold } from '@sequencehq/design-tokens'
import SnapshotInformation from 'modules/Seats/SeatEvents/Inspector/SnapshotInformation'
import SnapshotData from 'modules/Seats/SeatEvents/Inspector/SnapshotData'
import { useQuery } from '@sequencehq/api/dist/utils'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import PageError from 'components/ErrorPages/PageError'
import { Skeleton } from '@sequencehq/core-components'

const SeatSnapshotInspector = ({ snapshotId }: { snapshotId: string }) => {
  invariant(snapshotId, 'snapshotId is required')

  const seatEventQuery = useQuery(dashboard20240730Client.getSeatEvent, {
    id: snapshotId
  })

  if (seatEventQuery.isPending) {
    return (
      <DrawerContent maxW={490}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={6}
          height="64px"
          borderBottom={`1px solid ${GreyGrey30}`}
        >
          <Text {...Lato16Bold}>Seat event</Text>
          <DrawerCloseButton
            position="static"
            translateX={2}
            transform="auto"
          />
        </Flex>
        <DrawerBody>
          <Flex gap="8px" flexDirection="column">
            <Skeleton width="100%" height="32px" />
            <Skeleton width="100%" height="32px" />
            <Skeleton width="100%" height="32px" />
            <Skeleton width="100%" height="32px" />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    )
  }

  if (seatEventQuery.isError) {
    return (
      <DrawerContent maxW={490}>
        <DrawerCloseButton top={4} right={4} />
        <DrawerBody>
          <PageError />
        </DrawerBody>
      </DrawerContent>
    )
  }

  return (
    <DrawerContent maxW={490}>
      <DrawerBody padding={0} marginBottom={6}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={6}
          height="64px"
          borderBottom={`1px solid ${GreyGrey30}`}
        >
          <Text {...Lato16Bold}>Seat event</Text>
          <DrawerCloseButton
            position="static"
            translateX={2}
            transform="auto"
          />
        </Flex>

        {seatEventQuery.data ? (
          <>
            <Box p={6}>
              <Text {...Lato16Bold} mb={4}>
                Information
              </Text>
              <SnapshotInformation snapshot={seatEventQuery.data} />
            </Box>

            <Box px={6} maxH="40vh">
              <Text {...Lato16Bold} mb={4}>
                Data
              </Text>
              <SnapshotData snapshot={seatEventQuery.data} />
            </Box>
          </>
        ) : (
          <Flex {...Lato14Bold} justifyContent="center">
            No seat event data found
          </Flex>
        )}
      </DrawerBody>
    </DrawerContent>
  )
}

export default SeatSnapshotInspector
