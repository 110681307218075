import { Flex, Box } from '@chakra-ui/react'
import {
  InvoicePaymentStatus,
  InvoiceStatus,
  toInvoiceStatusBadgeProps,
  toInvoicePaymentStatusBadgeProps,
  invoiceStatus as invoiceStatusType,
  invoicePaymentStatus as invoicePaymentStatusType
} from '@sequencehq/core-models'
import { Badge } from '@sequencehq/core-components'
import {
  formatISODate,
  futureRelativeToDate,
  pastRelativeToDate
} from '@sequencehq/formatters'
import { FC, memo } from 'react'
import { startOfDay } from '@sequencehq/utils/dates'
import { InvoicesMagicTable } from 'components/Invoices/InvoicesMagicTable'
import Page from 'components/Page'
import { Outlet, useSearchParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { InvoicesGrid } from 'components/Invoices/InvoicesGrid'
import { InvoicesExportToCsv } from 'components/Invoices/InvoicesExportToCsv'

export const invoiceStatusValues = Object.keys(
  invoiceStatusType.keys
) as InvoiceStatus[]

export const invoicePaymentStatusValues = Object.keys(
  invoicePaymentStatusType.keys
) as InvoicePaymentStatus[]

export type InvoiceBadgesProps = {
  invoiceStatus: InvoiceStatus
  paymentStatus: InvoicePaymentStatus
}

export const invoiceAndPaymentStatusBadge = ({
  invoiceStatus,
  paymentStatus
}: InvoiceBadgesProps) => (
  <InvoiceBadges invoiceStatus={invoiceStatus} paymentStatus={paymentStatus} />
)

export const InvoiceBadges: FC<InvoiceBadgesProps> = memo(
  ({ invoiceStatus, paymentStatus }) => (
    <Flex direction="row">
      <Badge {...toInvoiceStatusBadgeProps({ status: invoiceStatus })} />
      {invoiceStatus === 'SENT' && (
        <Flex direction="row">
          <Box w="2"></Box>
          <Badge
            {...toInvoicePaymentStatusBadgeProps({ status: paymentStatus })}
          />
        </Flex>
      )}
    </Flex>
  )
)

export const statusFilterOptions = () => {
  return invoiceStatusValues.map(status => ({
    label: toInvoiceStatusBadgeProps({ status }).children,
    value: status
  }))
}

export const paymentStatusFilterOptions = () => {
  return invoicePaymentStatusValues.map(status => ({
    label: toInvoicePaymentStatusBadgeProps({ status }).children,
    value: status
  }))
}

export const sentFilterOptions = [
  {
    label: '1 week ago',
    value: pastRelativeToDate(new Date(), { weeks: 1 })
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(new Date(), { months: 6 })
  }
]

export const dueBeforeFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month from now',
    value: futureRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months from now',
    value: futureRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months from now',
    value: futureRelativeToDate(new Date(), { months: 6 })
  }
]

export const dueAfterFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(new Date(), { months: 6 })
  }
]

export const invoiceBeforeFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month from now',
    value: futureRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months from now',
    value: futureRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months from now',
    value: futureRelativeToDate(new Date(), { months: 6 })
  }
]

export const invoiceAfterFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(new Date(), { months: 6 })
  }
]

const Invoices: FC = memo(() => {
  const [searchParams] = useSearchParams()
  const view = searchParams.get('view')

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Invoices"
          addNewConfig={{
            buttonText: 'New invoice',
            overlayPayload: {
              content: 'createInvoiceModal',
              data: {
                customer: undefined
              }
            }
          }}
          menuButtons={<InvoicesExportToCsv />}
          paddingBottom={0}
        >
          {match(view)
            .with('grid', () => <InvoicesGrid />)
            .otherwise(() => (
              <InvoicesMagicTable />
            ))}
        </Page>
      </Flex>

      <Outlet />
    </Flex>
  )
})

export default Invoices
