import {
  ActionHandler,
  IntegrationsReducerState,
  ReloadDataAction
} from 'modules/Integrations/domain/integrations.domain.types'
import deepmerge from 'deepmerge'

/**
 * Reload data differs from an update data call, since it also
 * resets the initialData. This is useful when performing a partial
 * reset after an API call.
 * @param prevState
 * @returns
 */
export const reloadData: ActionHandler<ReloadDataAction> =
  prevState => action => {
    const newData = deepmerge(prevState.data, action.payload, {
      arrayMerge: (_, source: unknown[]) => source
    })

    return {
      ...prevState,
      data: newData,
      initialData: newData
    } as IntegrationsReducerState
  }
