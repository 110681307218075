import {
  TextInputField,
  CombinedFieldProps,
  InputProps
} from '@sequencehq/forms'
import { formatInteger } from '@sequencehq/formatters'
import { parseInteger } from 'lib/parsers'
import { FC, memo } from 'react'

type IntegerInputProps = CombinedFieldProps<string, InputProps>

export const IntegerInput: FC<IntegerInputProps> = memo(props => (
  <TextInputField<string>
    {...props}
    parse={parseInteger}
    format={formatInteger()}
  />
))
