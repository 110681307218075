import { format } from '@sequencehq/utils/dates'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useMemo } from 'react'

type UseQuoteByline = () => {
  createdBy: string | undefined
  createdAt: string | undefined
}

export const useQuoteByline: UseQuoteByline = () => {
  const cubeContext = useCubeContext()

  const createdBy = useMemo(() => {
    return cubeContext.queries.rawData.data.quote.createdByEmail
  }, [cubeContext.queries.rawData.data.quote.createdByEmail])

  const createdAt = useMemo(() => {
    const createdAtValue = cubeContext.queries.rawData.data.common.createdAt

    if (!createdAtValue) {
      return undefined
    }

    return format(new Date(createdAtValue), 'd LLLL yyyy')
  }, [cubeContext.queries.rawData.data.common.createdAt])

  return { createdBy, createdAt }
}
