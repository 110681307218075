import {
  DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'

export type v1ApiQuote = DashboardApi20240730.GetQuote.Quote

type UseDuplicateQuote = () => (quoteId: string) => Promise<v1ApiQuote>

export const useDuplicateQuote: UseDuplicateQuote = () => {
  const duplicate = useCallback(async (quoteId: string) => {
    const quoteResponse = await dashboard20240730Client.postQuoteDuplicate({
      id: quoteId
    })
    const quote = quoteResponse.data

    invariant(quote, 'Quote not found')

    return quote
  }, [])

  return duplicate
}
