import { Box, Grid, Flex, Text, Center } from '@chakra-ui/react'
import {
  Button,
  SequenceMenu,
  SequenceMenuItemType
} from '@sequencehq/core-components'
import {
  RedRed60,
  Lato12Bold,
  Lato14Regular,
  GreyGrey60,
  IndigoIndigo50,
  RedRed50,
  GreyGrey70,
  GreyGrey10,
  Lato14Bold
} from '@sequencehq/design-tokens'
import {
  MinimumRow,
  PhaseHookInterface,
  PricingRow
} from 'modules/Cube/view/common/widgets/PricingPhases/Phase/usePhase'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import PencilSquareIcon from '@heroicons/react/24/solid/PencilSquareIcon'
import TrashIcon from '@heroicons/react/24/solid/TrashIcon'
import { FC, Fragment } from 'react'

const styles = {
  tableHeader: {
    ...Lato12Bold,
    color: GreyGrey60,
    textTransform: 'uppercase' as const,
    border: 'none',
    padding: '10px 8px'
  },
  tableCell: {
    alignItems: 'center',
    ...Lato14Regular,
    border: 'none',
    textAlign: 'left' as const,
    padding: '8px'
  },
  editIcon: {
    width: '16px',
    height: '16px',
    color: IndigoIndigo50
  },
  deleteIcon: {
    width: '16px',
    height: '16px',
    color: RedRed50
  },
  error: {
    ...Lato14Regular,
    color: RedRed60,
    padding: '8px 0 0',
    width: '280px'
  },
  tooltip: {
    ...Lato14Regular,
    color: GreyGrey70,
    lineHeight: '20px'
  }
}

const minimumActionsToKebabItems = (
  row: MinimumRow
): SequenceMenuItemType[] => {
  return [
    {
      isDisabled: !row.actions.edit.available,
      label: 'Edit minimum',
      onClick: row.actions.edit.handler,
      iconLeft: <PencilSquareIcon {...styles.editIcon} />
    },
    {
      isDisabled: !row.actions.delete.available,
      label: 'Remove minimum',
      onClick: row.actions.delete.handler,
      iconLeft: <TrashIcon {...styles.deleteIcon} />
    }
  ]
}

const pricingActionsToKebabItems = (
  row: PricingRow
): SequenceMenuItemType[] => {
  const allActions: Record<
    keyof PricingRow['availableFeatures'],
    SequenceMenuItemType
  > = {
    canEdit: {
      isDisabled: row.availableFeatures.canEdit?.disabled,
      label: 'Edit price',
      tooltip: row.availableFeatures.canEdit?.disabledReason,
      onClick: row.availableFeatures.canEdit?.handler,
      iconLeft: <PencilSquareIcon {...styles.editIcon} />
    },
    canDelete: {
      isDisabled: row.availableFeatures.canDelete?.disabled,
      label: 'Remove product',
      tooltip: row.availableFeatures.canDelete?.disabledReason,
      onClick: row.availableFeatures.canDelete?.handler,
      iconLeft: <TrashIcon {...styles.deleteIcon} />
    },
    canEditDiscount: {
      isDisabled: row.availableFeatures.canEditDiscount?.disabled,
      label: 'Edit discount',
      tooltip: row.availableFeatures.canEditDiscount?.disabledReason,
      onClick: row.availableFeatures.canEditDiscount?.handler,
      iconLeft: <PencilSquareIcon {...styles.editIcon} />
    },
    canDeleteDiscount: {
      isDisabled: row.availableFeatures.canDeleteDiscount?.disabled,
      label: 'Remove discount',
      tooltip: row.availableFeatures.canDeleteDiscount?.disabledReason,
      onClick: row.availableFeatures.canDeleteDiscount?.handler ?? (() => null),
      iconLeft: <TrashIcon {...styles.deleteIcon} />
    }
  }

  return Object.keys(row.availableFeatures).map(
    actionName =>
      allActions[actionName as keyof PricingRow['availableFeatures']]
  )
}

export const PhasePricingTable: FC<{ phasesHook: PhaseHookInterface }> = ({
  phasesHook
}) => {
  return (
    <Box>
      <Box {...Lato14Bold} padding="16px 8px 8px 8px">
        Pricing
      </Box>
      <Box
        marginBottom="8px"
        border={
          phasesHook.validationErrors.length ? `1px solid ${RedRed60}` : ''
        }
        borderRadius="8px"
      >
        <Grid templateColumns="226px 162px 156px 142px 20px" columnGap="8px">
          <Flex {...styles.tableHeader}>PRODUCT</Flex>
          <Flex {...styles.tableHeader}>FREQUENCY</Flex>
          <Flex {...styles.tableHeader}>DISCOUNT</Flex>
          <Flex {...styles.tableHeader} justifyContent="flex-end">
            PRICE
          </Flex>
          <Flex {...styles.tableHeader}></Flex>
        </Grid>
        {phasesHook.pricingGroups.length > 0 &&
          phasesHook.pricingGroups.map(group => {
            return (
              <Fragment
                key={`group${
                  group.minimumRow?.id || group.pricingRows[0]?.id
                }}`}
              >
                {group.minimumRow && (
                  <Grid
                    templateColumns="226px 162px 156px 142px 20px"
                    columnGap="8px"
                    alignItems="center"
                    key={`group${
                      group.minimumRow?.id || group.pricingRows[0]?.id
                    }}`}
                    _hover={{
                      backgroundColor: GreyGrey10
                    }}
                  >
                    <Flex
                      {...styles.tableCell}
                      style={{ textWrap: 'wrap' }}
                      onClick={group.minimumRow.actions.edit.handler}
                      {...Lato14Bold}
                    >
                      {group.minimumRow.name}
                    </Flex>
                    <Flex {...styles.tableCell} color={GreyGrey60}>
                      {group.minimumRow.frequency}
                    </Flex>
                    <Flex {...styles.tableCell}></Flex>
                    <Flex {...styles.tableCell} justifyContent="flex-end">
                      {group.minimumRow.value}
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      maxWidth="36px"
                      height="36px"
                      padding="0px"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <SequenceMenu
                        alignment="bottom-right"
                        menuButton={
                          <Button variant="ghost" size="24px">
                            <Center flexShrink="0">
                              <EllipsisHorizontalIcon
                                width="16px"
                                height="16px"
                                color={GreyGrey60}
                              />
                            </Center>
                          </Button>
                        }
                        items={minimumActionsToKebabItems(group.minimumRow)}
                      />
                    </Flex>
                  </Grid>
                )}
                {group.pricingRows.map(row => (
                  <Grid
                    key={row.id}
                    templateColumns="226px 162px 156px 142px 20px"
                    columnGap="8px"
                    cursor="pointer"
                    borderRadius="8px"
                    minHeight="36px"
                    onClick={row.onClick}
                    _hover={{
                      backgroundColor: GreyGrey10
                    }}
                    data-testid={`cube.priceRow.${row.id}`}
                  >
                    <Flex
                      {...styles.tableCell}
                      style={{ textWrap: 'wrap' }}
                      paddingLeft={group.minimumRow ? '16px' : '8px'}
                    >
                      {row.name}
                    </Flex>
                    <Flex {...styles.tableCell} color={GreyGrey60}>
                      {row.frequency}
                    </Flex>
                    <Flex {...styles.tableCell}>
                      {row.discount ? (
                        <Flex
                          key={row.discount.id}
                          alignContent="center"
                          gap="4px"
                          _hover={{
                            opacity: 0.5
                          }}
                          onClick={e => {
                            e.stopPropagation()
                            row.availableFeatures.canEditDiscount?.handler()
                          }}
                        >
                          {row.discount.label}
                        </Flex>
                      ) : null}
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      justifyContent="flex-end"
                      gap="6px"
                    >
                      {row.price !== row.preDiscountPrice && (
                        <Text
                          textDecoration="line-through"
                          textAlign="right"
                          color={GreyGrey60}
                        >
                          {row.preDiscountPrice}
                        </Text>
                      )}
                      <Text textAlign="right">{row.price}</Text>
                    </Flex>
                    <Flex
                      {...styles.tableCell}
                      maxWidth="36px"
                      height="100%"
                      padding="0px"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <SequenceMenu
                        alignment="bottom-right"
                        data-testid={`cube.priceRow.${row.id}.menu`}
                        menuButton={
                          <Button variant="ghost" size="24px">
                            <Center flexShrink="0">
                              <EllipsisHorizontalIcon
                                width="16px"
                                height="16px"
                                color={GreyGrey60}
                              />
                            </Center>
                          </Button>
                        }
                        items={pricingActionsToKebabItems(row)}
                      />
                    </Flex>
                  </Grid>
                ))}
              </Fragment>
            )
          })}
      </Box>
    </Box>
  )
}
