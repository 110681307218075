import { Customer } from 'components/Customers/types'

export const getCustomerRole = ({
  organizations,
  customerId
}: {
  organizations: Customer['organizations']
  customerId: string
}) => {
  if (!Array.isArray(organizations)) {
    return null
  }

  for (const org of organizations) {
    if (org.owner.id === customerId && org.members.length !== 0) {
      return 'Parent'
    }
    if (org.members.some(member => member.id === customerId)) {
      return 'Child'
    }
  }
  return null
}
