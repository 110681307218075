import { Box, ChakraProps } from '@chakra-ui/react'
import { GreyGrey30, GreyWhite, ShadowM } from '@sequencehq/design-tokens'
import { ReactNode } from 'react'

export const Section = ({
  children,
  ...props
}: { children: ReactNode } & ChakraProps) => {
  return (
    <Box
      {...props}
      borderRadius="8px"
      border={`1px solid ${GreyGrey30}`}
      boxShadow={ShadowM}
      backgroundColor={GreyWhite}
    >
      {children}
    </Box>
  )
}
