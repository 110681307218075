import { Divider, Flex, Text } from '@chakra-ui/react'
import { EntityLink } from '@sequencehq/core-components'
import { toMoney } from '@sequencehq/core-models'
import { Lato14Bold, Lato14Regular } from '@sequencehq/design-tokens'
import { useGetInvoicesByIdQuery } from 'features/api'
import { FC } from 'react'

interface InvoicesPopoverProps {
  invoiceIds: string[]
  total: string
}

export const InvoicesPopover: FC<InvoicesPopoverProps> = ({
  invoiceIds,
  total
}) => (
  <Flex flexDirection="column">
    <InvoicesDetailSection invoiceIds={invoiceIds}></InvoicesDetailSection>
    <Divider color="gray.30"></Divider>
    <InvoicesTotalSection total={total}></InvoicesTotalSection>
  </Flex>
)

type InvoicesDetailSectionProps = {
  invoiceIds: string[]
}

const InvoicesDetailSection: FC<InvoicesDetailSectionProps> = ({
  invoiceIds
}) => {
  return (
    <Flex flexDirection="column" padding="16px 16px 8px 16px" gap="8px">
      {invoiceIds.map(invoiceId => (
        <InvoiceSummary invoiceId={invoiceId} key={invoiceId} />
      ))}
    </Flex>
  )
}

interface InvoiceSummaryProps {
  invoiceId: string
}

const InvoiceSummary: FC<InvoiceSummaryProps> = ({ invoiceId }) => {
  const { data } = useGetInvoicesByIdQuery({
    id: invoiceId
  })

  const invoice = data?.value()

  if (!invoice) {
    return <></>
  }

  return (
    <Flex alignItems="center">
      <EntityLink to={`/invoices/${invoiceId}`}>
        {invoice.invoiceNumber || 'TBC'}
      </EntityLink>
      <Flex grow="1"></Flex>
      <Text {...Lato14Regular} color="gray.80">
        {toMoney({ currency: invoice.currency, value: invoice.grossTotal })}
      </Text>
    </Flex>
  )
}

interface InvoicesTotalSectionProps {
  total: string
}

const InvoicesTotalSection: FC<InvoicesTotalSectionProps> = ({ total }) => {
  return (
    <Flex flexDirection="column" padding="16px">
      <Flex alignItems="center">
        <Text {...Lato14Bold} color="gray.80">
          Total
        </Text>
        <Flex grow="1"></Flex>
        <Text {...Lato14Bold} color="gray.80">
          {total}
        </Text>
      </Flex>
    </Flex>
  )
}
