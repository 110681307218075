import { useEffect, useState } from 'react'
import { useStytchB2BClient } from '@stytch/react/dist/b2b'
import { B2BSessionAuthenticateResponse } from '@stytch/vanilla-js/dist/b2b'

// We should replace this with Stytch SDK's useStytchMemberSession()
// when Stytch have fixed the bug with it
export const useStytchSession = () => {
  const stytch = useStytchB2BClient()

  const [session, setSession] = useState<B2BSessionAuthenticateResponse | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setIsLoading(true)

    stytch.session
      .authenticate()
      .then(response => {
        setSession(response)
        setIsLoading(false)
      })
      .catch(e => {
        setError(e as Error)
        setIsLoading(false)
      })
  }, [stytch.session])

  return { data: session, isLoading, error }
}
