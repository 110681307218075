import { Box } from '@chakra-ui/react'
import { TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  fiveDigitNumber,
  numberWithLengthBetween,
  required,
  threeDigitNumber
} from '@sequencehq/validation'
import { FC } from 'react'

export const CanadianBankAccountFields: FC<{ fieldName: string }> = ({
  fieldName
}) => {
  return (
    <>
      <Box width="250px">
        <TextInputField
          fieldLabel="Account Number"
          fieldName={`${fieldName}.value.canadianBankAccount.accountNumber`}
          validate={composeValidators(required, numberWithLengthBetween(7, 12))}
        />
      </Box>
      <Box width="10px"></Box>
      <Box width="250px">
        <TextInputField
          fieldLabel="Transit Number"
          fieldName={`${fieldName}.value.canadianBankAccount.transitNumber`}
          validate={composeValidators(required, fiveDigitNumber)}
        />
      </Box>
      <Box width="10px"></Box>
      <Box width="250px">
        <TextInputField
          fieldLabel="Institution Number"
          fieldName={`${fieldName}.value.canadianBankAccount.institutionNumber`}
          validate={composeValidators(required, threeDigitNumber)}
        />
      </Box>
    </>
  )
}
