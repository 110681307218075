import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import { BillingScheduleStatus } from '@sequencehq/core-models'

import { Toast } from '@sequencehq/core-components'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, useCallback, useState } from 'react'
import { useCallArchiveBillingSchedule } from 'components/BillingSchedules/hooks/useArchiveBilingSchedule.ts'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { match } from 'ts-pattern'

export type ArchiveBillingScheduleModalProps = {
  billingScheduleId: string
  status: BillingScheduleStatus
  onSuccess?: () => void
}

type UseArchiveBillingSchedule = {
  submitting: boolean
  onConfirm: () => void
  onClose: () => void
}

const useArchiveBillingSchedule: (
  billingScheduleId: string,
  onSuccess?: () => void
) => UseArchiveBillingSchedule = (billingScheduleId, onSuccess) => {
  const formId = 'archiveScheduleForm'

  const archive = useCallArchiveBillingSchedule()

  const dispatch = useDispatch()
  const toast = useToast()

  const [submitting, setSubmitting] = useState<boolean>(false)

  const onConfirm = useCallback(() => {
    setSubmitting(true)
    archive(billingScheduleId)
      .then(() => {
        dispatch(closeOverlay())

        toast({
          id: `success-${formId}`,
          position: 'bottom',
          duration: 6000,
          render: () => (
            <Toast
              title="Schedule archived"
              type="success"
              isClosable={true}
            ></Toast>
          )
        })

        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(() => {
        toast({
          id: `error-${formId}`,
          position: 'bottom',
          duration: 6000,
          render: () => (
            <Toast
              title="Could not archive schedule"
              type="error"
              isClosable={true}
            ></Toast>
          )
        })
      })
      .finally(() => setSubmitting(false))
  }, [])

  const onClose = useCallback(() => dispatch(closeOverlay()), [])

  return {
    submitting,
    onConfirm,
    onClose
  }
}

export const ArchiveBillingScheduleModal: FC<
  ArchiveBillingScheduleModalProps
> = ({ billingScheduleId, status, onSuccess }) => {
  const { submitting, onConfirm, onClose } = useArchiveBillingSchedule(
    billingScheduleId,
    onSuccess
  )

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="14px">
            Archive schedule
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="14px"
          >
            {match(status)
              .with('DRAFT', () => (
                <>This will archive the billing schedule immediately.</>
              ))
              .with('PENDING', () => (
                <>
                  This will cancel and archive the billing schedule immediately.
                </>
              ))
              .with('ACTIVE', () => (
                <>
                  This will cancel and archive the billing schedule immediately.
                  All in progress invoices will be deleted and all draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .with('SUSPENDED', () => (
                <>
                  This will cancel and archive the billing schedule immediately.
                  All in progress invoices will be deleted and all draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .with('CANCELLED', () => (
                <>
                  This will archive the billing schedule immediately. All draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .with('COMPLETED', () => (
                <>
                  This will archive the billing schedule immediately. All draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .exhaustive()}
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            This action cannot be undone.
          </Box>
          <Flex gap="8px" justifyContent="flex-end">
            <Flex basis="50%">
              <Button
                variant="secondary2"
                onClick={onClose}
                height="32px"
                width="100%"
              >
                Cancel
              </Button>
            </Flex>
            <Flex basis="50%">
              <Button
                variant="primary2"
                onClick={() => {
                  void onConfirm()
                }}
                height="32px"
                isLoading={submitting}
                width="100%"
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
