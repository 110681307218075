import { Option } from '@sequencehq/core-models'
import { Contact } from 'modules/Cube/domain/cube.domain.types'

export const contactsToOptions = (
  contacts: Record<string, Contact>,
  disabled: (contact: Contact) => boolean = () => false
): Option[] =>
  Object.values(contacts).map(contact => ({
    label: contact.name ?? contact.email,
    secondaryLabel: contact.name ? contact.email : undefined,
    value: contact.id,
    disabled: disabled(contact)
  }))
