import { IntegrationConfig } from 'modules/Integrations/domain'
import quickBooksLogoSmall from './quickBooksLogoSmall.svg'
import { isProductionEnv } from 'lib/environment/environment'
import { QuickBooksIntegrationDetail } from './QuickBooksIntegrationDetail/QuickBooksIntegrationDetail'
import { quickBooksConfigurationLoader } from './quickBooks.loader'
import { quickBooksConfigurationMutator } from './quickBooks.mutator'
import { QuickBooksConfigurationManagement } from './QuickBooksConfigurationManagement/QuickBooksConfigurationManagement'

export const quickBooksIntegrationConfig: IntegrationConfig = {
  logoUrl: quickBooksLogoSmall,
  smallLogoUrl: quickBooksLogoSmall,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/quickbooks',
  features: {
    canDeleteLinks: true,
    canUninstall: true,
    isDocumentationOnly: false
  },
  title: 'Quickbooks',
  description: `Sync Sequence invoices in real-time and streamline your accounting process.`,
  components: {
    detail: QuickBooksIntegrationDetail,
    management: QuickBooksConfigurationManagement
  },
  communication: {
    connecting: {
      integrationAppParameters: {
        isSandbox: !isProductionEnv()
      }
    },
    configuration: {
      loader: quickBooksConfigurationLoader,
      mutator: quickBooksConfigurationMutator
    }
  }
}

export type QuickBooksConfig = {
  /**
   * This is a value which does not exist on the API; it's effectively how we control
   * if an integration is 'paused' or not for now. It's expected that a way to more
   * directly configure this value will be exposed at some point!
   */
  importCustomerWorkflow: boolean
}
