import { Inspector } from '@sequencehq/core-components'
import {
  HubspotInspectorItem,
  LinkHubSpotCustomerWidget,
  LinkQuickbooksCustomerWidget,
  LinkQuickbooksInvoiceWidget,
  LinkSalesforceAccountWidget,
  LinkSalesforceInvoiceWidget,
  LinkXeroCustomerWidget,
  LinkXeroInvoiceWidget,
  QuickBooksInspectorItem,
  SalesforceInspectorItem,
  XeroInspectorItem
} from 'Integrations/index'
import { InvoiceStripeInspectorItem } from './InvoiceStripeInspectorItem'
import { IntegrationServices, useQuery } from '@sequencehq/api/dist/utils'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import {
  dashboardv99990101Client,
  Dashboardv99990101CommonModels
} from '@sequencehq/api/dashboard/v99990101'

const serviceHasLinkedCustomer =
  (service: IntegrationServices) =>
  (
    customerLinks: Dashboardv99990101CommonModels.Customers.Customer['integrationIds']
  ) => {
    return Boolean(customerLinks.find(cl => cl.service === service)?.id)
  }

export const useInvoiceInspector = () => {
  const ctx = useInvoiceEditorContext()

  const customerIntegrationsQuery = useQuery(
    dashboardv99990101Client.getCustomer,
    {
      id: ctx.data.invoice.customerId
    },
    {
      select: customer => {
        if (!customer) {
          return null
        }
        return customer.integrationIds
      }
    }
  )

  return {
    customerId: ctx.data.invoice.customerId,
    invoiceId: ctx.data.invoice.id,
    stripePayments: {
      value: ctx.data.invoice.paymentOptions.includes('LINK'),
      isDisabled:
        !ctx.derived.queries.availableFeatures.canUpdatePaymentCollectionMethod,
      onChange: (newData: boolean) => {
        alert('newData: ' + newData)
      }
    },
    hasLinkedCustomer: customerIntegrationsQuery.data
      ? {
          xero: serviceHasLinkedCustomer('Xero')(
            customerIntegrationsQuery.data
          ),
          quickbooks: serviceHasLinkedCustomer('QuickBooks_Online')(
            customerIntegrationsQuery.data
          ),
          salesforce: serviceHasLinkedCustomer('Salesforce')(
            customerIntegrationsQuery.data
          )
        }
      : {
          xero: false,
          quickbooks: false,
          salesforce: false
        }
  }
}

export const InvoiceInspector = () => {
  const hook = useInvoiceInspector()

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <XeroInspectorItem>
          <LinkXeroCustomerWidget customerId={hook.customerId} />
          {hook.hasLinkedCustomer.xero && (
            <LinkXeroInvoiceWidget invoiceId={hook.invoiceId} />
          )}
        </XeroInspectorItem>
        <QuickBooksInspectorItem>
          <LinkQuickbooksCustomerWidget customerId={hook.customerId} />
          {hook.hasLinkedCustomer.quickbooks && (
            <LinkQuickbooksInvoiceWidget invoiceId={hook.invoiceId} />
          )}
        </QuickBooksInspectorItem>
        <HubspotInspectorItem>
          <LinkHubSpotCustomerWidget customerId={hook.customerId} />
        </HubspotInspectorItem>
        <SalesforceInspectorItem>
          <LinkSalesforceAccountWidget customerId={hook.customerId} />
          {hook.hasLinkedCustomer.salesforce && (
            <LinkSalesforceInvoiceWidget invoiceId={hook.invoiceId} />
          )}
        </SalesforceInspectorItem>
        <InvoiceStripeInspectorItem />
      </Inspector.ItemGroup>
    </Inspector>
  )
}
