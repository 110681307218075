import { Box, Center, Text } from '@chakra-ui/react'
import { IntegrationService } from '@sequencehq/core-models'
import { usePostApiIntegrationsServiceCustomersProgressMutation } from 'features/api/integratedApiTags'

import * as Sentry from '@sentry/react'
import { isSuccessResponse } from 'components/Form'
import { Progress } from 'components/ImportCustomers/Progress'
import { FC, useEffect, useState } from 'react'

export interface ImportCustomerProgressProps {
  service: IntegrationService
  customerIds: string[]
  setComplete: (complete: boolean) => void
}

export const ImportCustomersProgress: FC<ImportCustomerProgressProps> = ({
  service,
  customerIds,
  setComplete
}) => {
  const [getProgress] = usePostApiIntegrationsServiceCustomersProgressMutation()
  const [count, setCount] = useState<number>(0)
  const [firstCheck, setFirstCheck] = useState<boolean>(true)

  function updateProgress() {
    setFirstCheck(false)
    getProgress({
      service,
      getCustomerImportFromIntegrationProgressEndpointRequestModel: {
        customerIds
      }
    })
      .then(result => {
        if (isSuccessResponse(result)) {
          setCount(current => result.data.value()?.currentCount || current)
          if ((result.data.value()?.currentCount || 0) < customerIds.length) {
            setTimeout(updateProgress, 1000)
          } else if (
            result.data.value()?.currentCount ||
            0 === customerIds.length
          ) {
            setComplete(true)
          }
        }
      })
      .catch(e => Sentry.captureException(e))
  }

  useEffect(() => {
    if (firstCheck) {
      updateProgress()
    }
  })

  return (
    <>
      <Center
        alignItems="center"
        height="100%"
        flexDirection="column"
        px={5}
        py={5}
      >
        <Progress
          service={service}
          currentCount={count}
          customerCount={customerIds.length}
        ></Progress>
        <Box height="8px"></Box>
        <Text fontSize="24px" lineHeight="28px" fontWeight="700">
          Imported {count} of {customerIds.length} customers
        </Text>
        <Box height="8px"></Box>
        <Text fontSize="16px" lineHeight="24px" textAlign="center">
          This might take a few moments. You can close this dialog and the
          import will continue in the background.
        </Text>
      </Center>
    </>
  )
}
