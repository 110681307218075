import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { PricingEditorFormData } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { useMemo } from 'react'
import { useForm } from '@sequencehq/utils'
import { FieldConfig } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/priceForm.types'
import { required } from '@sequencehq/validation'

type UseExternalLedgerForm = () => {
  fieldsConfig: {
    integrations: (FieldConfig<Record<string, string>> & {
      id: string
      label: string
      options: { value: string; label: string }[]
    })[]
  }
}

export const defaultLedgerAccountValues = (
  existingValues: Record<string, string>,
  ledgerIntegrations: Record<string, { id: string; defaultLedgerAccount?: string }>
) => {
  return Object.values(ledgerIntegrations).reduce((acc, integration) => {
    if (existingValues[integration.id]) {
      return {
        ...acc,
        [integration.id]: existingValues[integration.id]
      }
    }

    return {
      ...acc,
      [integration.id]: integration.defaultLedgerAccount
    }
  }, {})
}

export const useExternalLedgerForm: UseExternalLedgerForm = () => {
  const pricingEditorContext = usePricingEditorContext()
  const presetValues = pricingEditorContext.data.formData.common.externalIds
  const valuesWithDefaults = defaultLedgerAccountValues(presetValues, pricingEditorContext.data.ledgerIntegrations)

  const { fields } = useForm<PricingEditorFormData['common']['externalIds']>({
    value: valuesWithDefaults,
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    fieldConfiguration: Object.values(
      pricingEditorContext.data.ledgerIntegrations
    ).map(integration => ({
      property: integration.id,
      disabled: () =>
        pricingEditorContext.functions.fieldIsDisabled(
          'common.externalIntegrations'
        ),
      options: integration.options.map(option => ({
        value: option.code,
        label: `${option.name} (${option.code})`
      })),
      validation: [required]
    })),
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          externalLedger: isValid
        }
      })
    },
    onChange: newData => {
      pricingEditorContext.functions.updateFormData({
        common: {
          externalIds: newData
        }
      })
    }
  })

  const enhancedFieldsConfig = useMemo(() => {
    return Object.entries(fields).map(([integrationId, fieldConfig]) => ({
      ...fieldConfig,
      id: integrationId,
      label: `${integrationId} ledger account`
    }))
  }, [fields])

  return {
    fieldsConfig: {
      integrations: enhancedFieldsConfig
    }
  }
}
