import { Box, Button, Flex, Grid, GridItem, Image } from '@chakra-ui/react'
import headerBackground from './headerBackground.svg'
import {
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  Lato14Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { BookOpenIcon } from '@heroicons/react/16/solid'
import { useIntegrationManagementHeader } from 'modules/Integrations/view/integrationManagement/useIntegrationManagementHeader'

export const IntegrationManagementHeader = () => {
  const hook = useIntegrationManagementHeader()
  return (
    <Grid
      width="100%"
      padding="20px"
      minHeight="132px"
      backgroundImage={headerBackground}
      gridTemplateColumns="48px 1fr"
      gridTemplateRows="48px auto"
      gridTemplateAreas={`
        "logo description"
        "empty actions"
      `}
      gridColumnGap="16px"
      gridRowGap="12px"
      borderBottom={`1px solid ${GreyGrey30}`}
    >
      <GridItem
        gridArea="logo"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image src={hook.integration.logoUrl} width="100%" maxHeight="100%" />
      </GridItem>
      <GridItem gridArea="description">
        <Box {...Lato16Bold} color={GreyGrey90}>
          {hook.integration.title}
        </Box>
        <Box {...Lato14Regular} color={GreyGrey70}>
          {hook.integration.description}
        </Box>
      </GridItem>
      <GridItem gridArea="actions" display="flex" gap="8px">
        <Button
          variant="component-library-secondary"
          onClick={hook.onDocumentationClick}
        >
          <Flex gap="8px">
            <BookOpenIcon height="16px" width="16px" />
            Documentation
          </Flex>
        </Button>
      </GridItem>
    </Grid>
  )
}
