import { INITIAL_LIST_PRICE_STATE } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { ListPriceEditorData } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditor'
import { createContext, useContext } from 'react'

type ListPriceEditorContextData = {
  data: ListPriceEditorData['data']
  derived: ListPriceEditorData['derived']
  editor: ListPriceEditorData['editor']
  configuration: ListPriceEditorData['configuration']
  functions: Omit<ListPriceEditorData['functions'], 'loadListPriceEditor'>
}

// eslint-disable-next-line no-console
const notImplemented = () => console.error('Function not implemented')

const ListPriceEditorContext = createContext<ListPriceEditorContextData>({
  data: INITIAL_LIST_PRICE_STATE.data,
  derived: INITIAL_LIST_PRICE_STATE.derived,
  editor: INITIAL_LIST_PRICE_STATE.editor,
  configuration: INITIAL_LIST_PRICE_STATE.configuration,
  functions: {
    updateData: notImplemented,
    updateEditor: notImplemented,
    updateFormData: notImplemented,
    reviewPrice: notImplemented,
    saveNewPrice: () => Promise.reject('Function not implemented'),
    closeEditor: notImplemented,
    fieldIsDisabled: () => {
      // eslint-disable-next-line no-console
      console.error('Function not implemented')
      return true
    }
  }
})

export const useListPriceEditorContext = () => {
  return useContext(ListPriceEditorContext)
}

export const ListPriceEditorProvider = ListPriceEditorContext.Provider
export { INITIAL_LIST_PRICE_STATE }
