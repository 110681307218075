import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getPublishAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const available = [
    CubeStatus.ScheduleActive,
    CubeStatus.ScheduleDraft,
    CubeStatus.ScheduleUnsaved,
    CubeStatus.SchedulePending,
    CubeStatus.ScheduleUnsaved
  ].includes(prevState.data.common.status)

  if (!available) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: { status: prevState.data.common.status }
        }
      ]
    }
  }

  if (prevState.editor.savingDraft || prevState.editor.savingSchedule) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.SavingInProgress,
          metadata: {}
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
