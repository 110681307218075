import {
  AvailableServices,
  IntegrationFeatureAvailable,
  IntegrationFeatureDisabledReason,
  ResolvedIntegration
} from 'modules/Integrations/domain/integrations.domain.types'

export const getManageAvailable =
  (ctx: { activeIntegrations: ResolvedIntegration[] }) =>
  (service: AvailableServices) =>
  (): IntegrationFeatureAvailable => {
    const activeIntegrationConfig = ctx.activeIntegrations.find(
      int => int.id === service
    )

    const featureAvailable = Boolean(
      activeIntegrationConfig &&
        !activeIntegrationConfig.features.isDocumentationOnly
    )

    return {
      available: {
        visible: featureAvailable,
        enabled: featureAvailable
      },
      reasons: !featureAvailable
        ? [IntegrationFeatureDisabledReason.IntegrationNotActive]
        : []
    }
  }
