import { FC } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { CheckCircleIcon } from 'components/icons'

interface FormSuccessProps {
  message: string
}

export const FormSuccess: FC<FormSuccessProps> = ({ message }) => (
  <Flex py="50px" alignItems="center" flexDirection="column">
    <CheckCircleIcon title={message} width={100} height={100} color="#4A9000" />
    <Box height={2} />
    <Text textStyle="mainText" fontWeight="400">
      {message}
    </Text>
  </Flex>
)
