import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import { Modal } from '@sequencehq/core-components'
import PageError from 'components/ErrorPages/PageError'
import { DefRevScheduleProgressVisualization } from '../common/DefRevScheduleProgressVisualization'
import { DefRevScheduleSummary } from '../common/DefRevScheduleSummary'
import { DefRevScheduleProgressGraph } from '../DefRevScheduleProgressGraph'

export const StraightLineRecognitionDrawerLayout = (props: {
  defRevScheduleId: string
}) => {
  return (
    <Grid
      gridTemplateAreas={`
    "header"
    "content"
    `}
      gridTemplateRows="auto 1fr"
      overflow="hidden"
      height="100%"
      width="100%"
    >
      <GridItem gridArea="header">
        <Modal.Header showCloseButton withBorder>
          Overview
        </Modal.Header>
      </GridItem>
      <GridItem gridArea="content" overflow="auto">
        <ErrorBoundary fallback={PageError}>
          <Modal.Content>
            <Flex flexDirection="column" gap="24px">
              <DefRevScheduleProgressVisualization
                defRevScheduleId={props.defRevScheduleId}
              />
              <DefRevScheduleSummary
                defRevScheduleId={props.defRevScheduleId}
              />
              <DefRevScheduleProgressGraph
                defRevScheduleId={props.defRevScheduleId}
              />
            </Flex>
          </Modal.Content>
        </ErrorBoundary>
      </GridItem>
    </Grid>
  )
}
