import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'

export const removeIntegration = async (
  service: IntegrationServices,
  config: {
    removeLinks: boolean
  }
): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  const res = await Promise.all([
    config.removeLinks
      ? dashboardv20240509Client.deleteIntegrationLinks({
          service
        })
      : Promise.resolve({ error: null }),
    dashboardv20240509Client.deleteIntegration({
      service
    })
  ])

  const hasErrors = res.some(r => r.error)
  return {
    success: !hasErrors,
    error: hasErrors ? IntegrationsPortErrors.Other : null
  }
}
