import {
  CubeReducerState,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'

export const atLeastOneContact = (
  prevState: CubeReducerState
): ValidationResult[] | null => {
  if (prevState.data.quote.contacts.length) {
    return null
  }

  return [{ message: 'At least one recipient is required' }]
}
