import { required } from '@sequencehq/validation'
import {
  CubeReducerState,
  ValidationResults
} from 'modules/Cube/domain/cube.domain.types'
import { adaptValidationFromLib } from 'modules/Cube/domain/postActionStages/validation/util/adaptValidatorFromLib'
import { atLeastOnePhase } from 'modules/Cube/domain/postActionStages/validation/validators/atLeastOnePhase'
import { atLeastOnePriceInPhase } from 'modules/Cube/domain/postActionStages/validation/validators/phases/atLeastOnePriceInPhase'
import { nonFinalPhaseHasDuration } from 'modules/Cube/domain/postActionStages/validation/validators/phases/nonFinalPhaseHasDuration'

type SaveScheduleValidation = (prevState: CubeReducerState) => ValidationResults

export const saveScheduleValidation: SaveScheduleValidation = prevState => {
  return {
    common: {
      currency: null,
      customerId: adaptValidationFromLib(
        required(prevState.data.common.customerId)
      ),
      phaseIds: atLeastOnePhase(prevState),
      phases: prevState.queries.orderedPhases.reduce(
        (acc, resolvedPhase) => ({
          ...acc,
          [resolvedPhase.id]: {
            prices: atLeastOnePriceInPhase({ phaseId: resolvedPhase.id })(
              prevState
            ),
            duration: nonFinalPhaseHasDuration({ phaseId: resolvedPhase.id })(
              prevState
            )
          }
        }),
        {}
      ),
      startDate: adaptValidationFromLib(
        required(prevState.data.common.startDate)
      ),
      title: null
    },
    schedule: {
      recurrenceDayOfMonth: adaptValidationFromLib(
        required(prevState.data.schedule.recurrenceDayOfMonth)
      ),
      taxRate: null
    },
    quote: {
      dealType: null,
      expiry: null,
      presentation: null,
      quoteNumber: null,
      contactIds: null
    }
  }
}
