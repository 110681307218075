import { useFlags } from 'launchdarkly-react-client-sdk'
import { TaxRatesSettings } from 'Settings/view/taxRatesSettings/TaxRatesSettings'
import TaxRateSettings from 'components/TaxRateSettings'
import { AvalaraTaxRatesSettings } from 'Settings/view/taxRatesSettings/avalara/AvalaraTaxRatesSettings'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

const TaxRatesSettingsRoute = () => {
  const flags = useFlags()
  const result = useQuery(dashboardv99990101Client.getEnabledTaxIntegration)

  if (flags.showNewTaxRatesSettings) {
    if (result.data?.taxIntegrationProvider?.toUpperCase() === 'AVALARA') {
      return <AvalaraTaxRatesSettings />
    }

    return <TaxRatesSettings />
  }

  return <TaxRateSettings />
}

export default TaxRatesSettingsRoute
