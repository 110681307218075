import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './netsuiteLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { NetsuiteIntegrationDetail } from './NetsuiteIntegrationDetail/NetsuiteIntegrationDetail'

export const netsuiteIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: 'COMING_SOON',
  documentationUrl: 'https://docs.sequencehq.com/integrations/netsuite',
  title: 'Netsuite',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: true
  },
  description:
    'Export Sequence invoices to Netsuite and streamline your accounting process.',
  components: {
    detail: NetsuiteIntegrationDetail,
    management: NoConfiguration
  }
}
