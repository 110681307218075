import { Box, Flex, Td, Text, Tr } from '@chakra-ui/react'
import { Popover } from '@sequencehq/core-components'
import {
  GreyGrey70,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Customer } from 'components/Customers/types'
import { Link } from 'react-router-dom'
import { CustomerPreviewCardPill } from '../CustomerPreviewCard'

export const CustomerRelationship = ({ customer }: { customer: Customer }) => {
  if (!customer?.organizations?.length) {
    return null
  }

  const isParentAccount = customer?.organizations.some(
    org => org.owner.id === customer.id && org?.members?.length !== 0
  )

  if (!isParentAccount) {
    const parentOrganization = customer.organizations.find(
      org => org.members.length > 0 && org.owner.id !== customer.id
    )

    if (!parentOrganization?.owner?.id) {
      return null
    }

    return (
      <>
        <Text {...Lato13Regular} color={GreyGrey70}>
          Parent customer
        </Text>
        <CustomerPreviewCardPill customerId={parentOrganization.owner.id} />
      </>
    )
  } else {
    const childAccountsCount = customer?.organizations.reduce((count, org) => {
      return count + org.members.length
    }, 0)

    if (childAccountsCount === 0) {
      return null
    } else {
      return (
        <>
          <Box mt="1">
            <Text {...Lato13Regular} color={GreyGrey70}>
              Child customers
            </Text>
          </Box>

          <Flex gap={2} overflow="hidden">
            <Popover
              position="right"
              header="Child customers"
              maxHeight="400px"
              variant="table"
              closeOnBlur={false}
              offset={[75, 25]}
              content={customer.organizations.flatMap(org => {
                return org?.members.map(member => (
                  <Tr
                    key={member.id}
                    sx={{
                      _hover: {
                        cursor: 'default!important',
                        bg: 'inherit!important'
                      }
                    }}
                  >
                    <Td
                      display={'flex'}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text {...Lato13Regular} color={GreyGrey70}>
                        {member.name}
                      </Text>
                      <Text {...Lato13Bold} color={IndigoIndigo50}>
                        <Link
                          to={`/customers/${member.id}`}
                          onClick={e => e.stopPropagation()}
                        >
                          View
                        </Link>
                      </Text>
                    </Td>
                  </Tr>
                ))
              })}
            >
              <Text
                {...Lato13Regular}
                color={GreyGrey70}
                cursor="help"
                borderBottom={`1px dashed ${GreyGrey70}`}
                py="4px"
              >
                {`${childAccountsCount} ${
                  childAccountsCount > 1 ? 'customers' : 'customer'
                }`}
              </Text>
            </Popover>
          </Flex>
        </>
      )
    }
  }
}
