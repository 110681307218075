import { Box, Button, Flex, Text } from '@chakra-ui/react'

import {
  GreyGrey10,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { SwitchLineInput, TextField } from '@sequencehq/core-components'

import { UseQuoteSettingsInterface } from 'modules/Cube/settings/QuoteSettings/useQuoteSettings'
import { LogoDropboxSign } from 'modules/Cube/settings/QuoteSettings/LogoDropboxSign'
import { useQuoteSettingsForm } from 'Cube/settings/QuoteSettings/useQuoteSettingsForm'
import { Label } from '@sequencehq/forms'

export const QuoteSettingsForm = ({
  hook
}: {
  hook: UseQuoteSettingsInterface
}) => {
  const counterSigner = hook.quoteSettings.counterSigners?.[0]

  const { fieldsConfig, submit, isDirty, isSubmitting } = useQuoteSettingsForm({
    fieldValues: {
      isSignaturesEnabled: hook.quoteSettings.isSignaturesEnabled,
      isInternalSignerEnabled: Boolean(counterSigner),
      name: counterSigner?.name,
      email: counterSigner?.email
    },
    onSubmit: hook.functions.onSubmit
  })

  const showSignerFields = fieldsConfig.isInternalSignerEnabled.visible

  return (
    <Flex maxWidth="404px">
      <Flex padding="24px" gap="24px" flexDirection="column">
        <Flex gap="8px" flexDirection="column">
          <Text {...Lato13Bold} color={GreyGrey90}>
            E-signatures
          </Text>

          <Flex gap="4px" flexDirection="column">
            <SwitchLineInput
              value={fieldsConfig.isSignaturesEnabled.value}
              onChange={fieldsConfig.isSignaturesEnabled.onChange}
              text={
                <Text {...Lato13Bold} color={GreyGrey90}>
                  Enable e-signatures
                </Text>
              }
              styles={{ label: { marginLeft: '0px' } }}
            />

            <Flex flexDirection="column" gap="8px" marginLeft="36px">
              <Text {...Lato13Regular} color={GreyGrey70}>
                Enable this setting if you want to allow customers to sign
                quotes digitally.
              </Text>

              <Flex
                paddingX="12px"
                paddingY="16px"
                background={GreyGrey10}
                borderRadius="8px"
                justifyContent="space-between"
              >
                <Text {...Lato13Regular} color={GreyGrey70}>
                  Powered by
                </Text>
                <Box position="relative" top="2px">
                  <LogoDropboxSign />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {showSignerFields && (
          <Flex gap="4px" flexDirection="column">
            <SwitchLineInput
              value={Boolean(fieldsConfig.isInternalSignerEnabled.value)}
              onChange={fieldsConfig.isInternalSignerEnabled.onChange}
              isDisabled={fieldsConfig.isInternalSignerEnabled.disabled}
              text={
                <Text {...Lato13Bold} color={GreyGrey90}>
                  Enable countersigner
                </Text>
              }
              styles={{ label: { marginLeft: '0px' } }}
            />

            <Box marginLeft="36px">
              <Text {...Lato13Regular} color={GreyGrey70}>
                Automatically include an internal person (e.g. your CEO) to
                counter sign every quote.
              </Text>
            </Box>
          </Flex>
        )}

        <Flex flexDirection="column" marginLeft="36px" gap={2}>
          {fieldsConfig.name.visible && showSignerFields && (
            <Box>
              <Label>Name</Label>
              <TextField
                value={fieldsConfig.name.value}
                validationErrors={fieldsConfig.name.validationErrors}
                onChange={fieldsConfig.name.onChange}
                placeholder="John Doe"
              />
            </Box>
          )}

          {fieldsConfig.email.visible && showSignerFields && (
            <Box>
              <Label>Email</Label>
              <TextField
                value={fieldsConfig.email.value}
                onChange={fieldsConfig.email.onChange}
                validationErrors={fieldsConfig.email.validationErrors}
                placeholder="john@acme.com"
                data-1p-ignore
                data-lpignore
              />
            </Box>
          )}
        </Flex>

        <Button
          variant="component-library-primary"
          width="fit-content"
          isLoading={isSubmitting}
          isDisabled={!isDirty}
          onClick={() => void submit()}
        >
          Save changes
        </Button>
      </Flex>
    </Flex>
  )
}
