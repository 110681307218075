import { useCallback } from 'react'

import * as Sentry from '@sentry/react'

import {
  Option,
  CustomerAliasModel,
  CustomerModel,
  customerAliasOptions
} from '@sequencehq/core-models'

import {
  useLazyGetCustomerAliasesQuery,
  useLazyGetCustomersQuery
} from 'features/api'

type CustomerWithAliases = {
  customerName: string
  aliases: string[]
}

type UseGetMagicTableFilterOptions = () => {
  fetchCustomerNameOptions: () => Promise<Option[]>
  fetchCustomerNameWithAliasesOptions: () => Promise<Option[]>
  fetchCustomerAliasOptions: () => Promise<Option[]>
}

const MAX_ITERATIONS: number = 20

export const useGetMagicTableFilterOptions: UseGetMagicTableFilterOptions =
  () => {
    const [fetchAliases] = useLazyGetCustomerAliasesQuery({})
    const [fetchCustomers] = useLazyGetCustomersQuery({})

    const getAllAliases = useCallback(
      async ({
        after,
        acc = [],
        iterations = 0
      }: {
        after?: string
        acc?: CustomerAliasModel[]
        iterations?: number
      }): Promise<CustomerAliasModel[]> => {
        if (iterations === MAX_ITERATIONS) {
          return acc
        }

        const aliasesPage = await fetchAliases({ after })
        const aliasesPageValue = aliasesPage.data?.value()

        if (aliasesPageValue?.pagination.after) {
          return getAllAliases({
            after: aliasesPageValue.pagination.after,
            acc: [...acc, ...aliasesPageValue.items],
            iterations: iterations + 1
          })
        } else {
          return [...acc, ...(aliasesPageValue?.items ?? [])]
        }
      },
      [fetchAliases]
    )

    const getAllCustomers = useCallback(
      async ({
        after,
        acc = [],
        iterations = 0
      }: {
        after?: string
        acc?: CustomerModel[]
        iterations?: number
      }): Promise<CustomerModel[]> => {
        if (iterations === MAX_ITERATIONS) {
          return acc
        }

        const customersPage = await fetchCustomers({ after })
        const customersPageValue = customersPage.data?.value()

        if (customersPageValue?.pagination.after) {
          return getAllCustomers({
            after: customersPageValue.pagination.after,
            acc: [...acc, ...customersPageValue.items],
            iterations: iterations + 1
          })
        } else {
          return [...acc, ...(customersPageValue?.items ?? [])]
        }
      },
      [fetchCustomers]
    )

    const fetchCustomerNameOptions = useCallback(async () => {
      try {
        const allCustomers = await getAllCustomers({})

        return allCustomers.map(customer => ({
          label: customer.legalName,
          value: customer.id
        }))
      } catch (e) {
        Sentry.captureException(e)
        return []
      }
    }, [getAllCustomers])

    const fetchCustomerNameWithAliasesOptions = useCallback(async () => {
      try {
        const fetchedAliases = await getAllAliases({})
        const allCustomers = await getAllCustomers({})

        const allCustomersWithAliases: CustomerWithAliases[] =
          allCustomers.reduce((acc: CustomerWithAliases[], customer) => {
            const aliasesForCustomer = fetchedAliases.filter(
              alias => alias.customerId === customer.id
            )

            return [
              ...acc,
              {
                customerName: customer.legalName,
                aliases: [
                  customer.id,
                  ...aliasesForCustomer.map(alias => alias.value)
                ]
              }
            ]
          }, [])

        return allCustomersWithAliases.map(customer => ({
          label: customer.customerName,
          value: customer.aliases.join(',')
        }))
      } catch (e) {
        Sentry.captureException(e)
        return []
      }
    }, [getAllAliases, getAllCustomers])

    const fetchCustomerAliasOptions = useCallback(async () => {
      try {
        const fetchedAliases = await getAllAliases({})
        return customerAliasOptions(fetchedAliases)
      } catch (e) {
        Sentry.captureException(e)
        return []
      }
    }, [getAllAliases])

    return {
      fetchCustomerNameOptions,
      fetchCustomerNameWithAliasesOptions,
      fetchCustomerAliasOptions
    }
  }
