import type { InvoiceModel } from '@sequencehq/core-models'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import type {
  ApiLineItem,
  ApiLineItemGroup
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'

type UseLoadLineItemsAndGroups = (
  invoiceId: InvoiceModel['id'] | undefined
) => {
  loading: boolean
  data: {
    lineItems: ApiLineItem[]
    lineItemGroups: ApiLineItemGroup[]
  }
  lineItemsError?: any
  lineItemGroupsError?: any
  refetchLineItemsAndGroups: () => Promise<
    ReturnType<UseLoadLineItemsAndGroups>['data']
  >
}

export const useLoadLineItemsAndGroups: UseLoadLineItemsAndGroups =
  invoiceId => {
    const {
      data: lineItemsData,
      isLoading: isLoadingLineItems,
      error: lineItemsError,
      refetch: refetchLineItems
    } = useQuery(
      dashboardv99990101Client.getAllLineItems,
      {
        invoiceId: invoiceId || ''
      },
      {
        enabled: !!invoiceId
      }
    )

    const {
      data: lineItemGroupsData,
      isLoading: isLoadingLineItemGroups,
      error: lineItemGroupsError,
      refetch: refetchLineItemGroups
    } = useQuery(
      dashboardv99990101Client.getAllLineItemGroups,
      {
        invoiceId: invoiceId || ''
      },
      {
        enabled: !!invoiceId
      }
    )

    const lineItems = lineItemsData?.items || []
    const lineItemGroups = lineItemGroupsData?.items || []

    const refetchLineItemsAndGroups = async () => {
      const [refetchedLineItemsData, refetchedLineItemGroupsData] =
        await Promise.all([refetchLineItems(), refetchLineItemGroups()])

      return {
        lineItems: refetchedLineItemsData?.data?.items || [],
        lineItemGroups: refetchedLineItemGroupsData?.data?.items || []
      }
    }

    return {
      loading: isLoadingLineItems || isLoadingLineItemGroups,
      data: { lineItems, lineItemGroups },
      lineItemGroupsError,
      lineItemsError,
      refetchLineItemsAndGroups
    }
  }
