import {
  CorePortErrors,
  CubePortImplementation,
  CubeReducerState,
  CubeStatus,
  ScheduleCubePorts
} from 'modules/Cube/domain/cube.domain.types'
import { useLoadBillingScheduleEditor } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useLoadBillingScheduleEditor'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useSaveBillingScheduleEditor } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useSaveBillingScheduleEditor'
import { billingScheduleAdapter } from 'modules/Cube/communication/external/billingSchedule.api.v1/adapters/billingSchedule.adapters'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import deepmerge from 'deepmerge'
import { usePutBillingSchedulesBillingScheduleIdArchiveMutation } from 'features/api'
import { useLoadSingleCustomer } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders'
import invariant from 'tiny-invariant'
import { useLoadSchedulePreview } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entityLoaders'
import * as Sentry from '@sentry/react'

type UseBillingSchedulePorts = (props: {
  scheduleId?: string
}) => CubePortImplementation

export const useBillingSchedulePortImplementation: UseBillingSchedulePorts =
  props => {
    const notifications = useNotifications()
    const navigate = useNavigate()
    const [existingData, setExistingData] =
      useState<CubeDomainInterface['queries']['rawData']['data']>()

    const billingScheduleLoader = useLoadBillingScheduleEditor()
    const saveBillingScheduleEditor = useSaveBillingScheduleEditor()
    const customerLoader = useLoadSingleCustomer()
    const schedulePreviewLoader = useLoadSchedulePreview()

    const transformSaveData = useCallback(
      (domainOutput: CubeDomainInterface['queries']) => {
        try {
          if (!existingData) {
            throw new Error("Can't save without initialising first")
          }
          return billingScheduleAdapter.out(existingData)(domainOutput)
        } catch (e) {
          Sentry.captureException(e)
          notifications.displayNotification('Failed to save draft', {
            type: 'error'
          })
          return
        }
      },
      [existingData, notifications]
    )

    const saveBillingSchedule = useCallback(
      async (domainOutput: CubeDomainInterface['queries']) => {
        if (!existingData) {
          throw new Error("Can't save without initialising first")
        }

        const dataToSave = transformSaveData(domainOutput)

        invariant(dataToSave, 'Could not create save data')
        // eslint-disable-next-line no-console
        console.info(`%cSaving data...`, 'color: blue', { dataToSave })

        if (
          props.scheduleId === 'new' ||
          !props.scheduleId ||
          CubeStatus.ScheduleDraft === existingData.common.status
        ) {
          notifications.displayNotification('Starting schedule...', {
            duration: 30000
          })
          const saveResult = await saveBillingScheduleEditor.activateSchedule(
            props.scheduleId
          )(dataToSave)

          if (saveResult.success) {
            notifications.displayNotification('Schedule started', {
              type: 'success',
              confetti: true
            })
            navigate(`/billing-schedules`)
            return
          }

          notifications.displayNotification('Failed to start schedule', {
            type: 'error'
          })
          return
        }

        if (CubeStatus.SchedulePending === existingData.common.status) {
          notifications.displayNotification('Updating schedule...', {
            duration: 30000
          })
          const saveResult =
            await saveBillingScheduleEditor.updateBillingSchedule(
              props.scheduleId
            )(dataToSave)

          if (saveResult.success) {
            notifications.displayNotification('Schedule updated', {
              type: 'success'
            })
            navigate(`/billing-schedules`)
            return
          }

          notifications.displayNotification('Failed to start schedule', {
            type: 'error'
          })
          return
        }

        if (CubeStatus.ScheduleActive === existingData.common.status) {
          notifications.displayNotification('Updating schedule...', {
            duration: 30000
          })
          const saveResult =
            await saveBillingScheduleEditor.updateBillingSchedule(
              props.scheduleId
            )(dataToSave)

          if (!saveResult.success) {
            notifications.displayNotification('Failed to update schedule', {
              type: 'error'
            })
            return
          }

          notifications.displayNotification('Schedule updated', {
            type: 'success'
          })
          navigate(`/billing-schedules`)
          return
        }
      },
      [
        saveBillingScheduleEditor,
        props.scheduleId,
        existingData,
        navigate,
        notifications,
        transformSaveData
      ]
    )

    const saveScheduleDraft = useCallback(
      async (
        domainOutput: CubeDomainInterface['queries']
      ): Promise<boolean> => {
        if (!existingData) {
          throw new Error("Can't save draft without initialising first")
        }

        const dataToSave = transformSaveData(domainOutput)

        invariant(dataToSave, 'Could not create save data')

        // eslint-disable-next-line no-console
        console.info(
          `%cSaving draft...`,
          'color: cyan; backgroundColor: blue;',
          {
            dataToSave
          }
        )

        if (!props.scheduleId) {
          notifications.displayNotification('Creating draft...', {
            duration: 30000
          })

          const saveResult =
            await saveBillingScheduleEditor.createBillingScheduleDraft(
              dataToSave
            )

          if (saveResult.success && saveResult.newSchedule?.id) {
            notifications.displayNotification('Draft created', {
              type: 'success'
            })
            navigate(`../${saveResult.newSchedule.id}`, {
              relative: 'path'
            })
            return true
          }

          if (!saveResult.success && saveResult.newSchedule?.id) {
            notifications.displayNotification('Failed to fully create draft', {
              type: 'error'
            })
            navigate(
              `${props.scheduleId === 'new' ? '..' : '.'}/${
                saveResult.newSchedule.id
              }`,
              {
                relative: 'path'
              }
            )
            return false
          }

          notifications.displayNotification('Failed to create draft', {
            type: 'error'
          })
          return false
        }

        notifications.displayNotification('Updating draft...')
        const saveResult =
          await saveBillingScheduleEditor.updateBillingSchedule(
            props.scheduleId
          )(dataToSave)

        if (!saveResult.success) {
          notifications.displayNotification('Failed to update draft', {
            type: 'error'
          })
          return false
        }

        notifications.displayNotification('Draft saved', {
          type: 'success'
        })
        return true
      },
      [
        saveBillingScheduleEditor,
        props.scheduleId,
        existingData,
        navigate,
        notifications,
        transformSaveData
      ]
    )

    const saveSchedule = useCallback(
      async (domainOutput: CubeDomainInterface['queries']) => {
        if (!existingData) {
          throw new Error("Can't save without initialising first")
        }

        try {
          await saveBillingSchedule(domainOutput)
        } catch (e) {
          notifications.displayNotification(
            'An error occurred saving the schedule',
            {
              type: 'error'
            }
          )
          return false
        }

        return true
      },
      [saveBillingSchedule, existingData, notifications]
    )

    const loadCore = useCallback(
      (ctx: { configuration: CubeReducerState['configuration'] }) =>
        async () => {
          const { data: billingScheduleData, error } =
            await billingScheduleLoader(ctx)(props.scheduleId)

          if (!billingScheduleData || error) {
            return {
              data: null,
              error: error || CorePortErrors.Other
            }
          }

          const transformedData = billingScheduleAdapter.in(
            billingScheduleData,
            deepmerge(ctx.configuration, {
              features: {
                stripeIntegrationAvailable: Boolean(
                  billingScheduleData.integrations.find(
                    integration =>
                      integration.service === 'Stripe' && integration.enabled
                  )
                )
              }
            })
          )

          setExistingData(transformedData.data)

          return {
            data: transformedData,
            error: null
          }
        },
      [billingScheduleLoader, props.scheduleId]
    )

    const [saveArchiveSchedule] =
      usePutBillingSchedulesBillingScheduleIdArchiveMutation()
    const archiveSchedule = useCallback(async (): Promise<boolean> => {
      if (!existingData) {
        throw new Error("Can't archive without initialising first")
      }

      const response = await saveArchiveSchedule({
        billingScheduleId: existingData.common.id
      })
      if ('error' in response) {
        notifications.displayNotification('Could not archive schedule', {
          type: 'error'
        })
        return false
      }

      notifications.displayNotification('Schedule archived', {
        type: 'success'
      })
      return true
    }, [saveArchiveSchedule, existingData, notifications])

    const portImplementation: CubePortImplementation = useCallback(
      (ctx): ScheduleCubePorts => {
        return {
          in: {
            core: loadCore(ctx),
            customer: customerLoader,
            schedule: {
              preview: schedulePreviewLoader
            }
          },
          out: {
            save: saveScheduleDraft,
            archive: archiveSchedule,
            schedule: {
              activate: saveSchedule
            }
          }
        }
      },
      [
        loadCore,
        saveScheduleDraft,
        schedulePreviewLoader,
        saveSchedule,
        customerLoader,
        archiveSchedule
      ]
    )

    return portImplementation
  }
