import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import SendInvoiceForm from 'components/UpdateInvoiceStatus/SendInvoiceForm'
import {
  UpdateInvoiceStatusFormMutation,
  UpdateAndSendInvoiceStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import { usePostInvoicesByIdSendMutation } from 'features/api'

const SendInvoiceModal = createFormModal<
  UpdateInvoiceStatusFormMutation,
  UpdateAndSendInvoiceStatusFormProps
>({
  FormComponent: SendInvoiceForm,
  useMutation: usePostInvoicesByIdSendMutation,
  modalTitle: 'Send Invoice',
  successMessage: 'Invoice sent',
  submitTitle: 'Send Invoice',
  formId: 'sendInvoiceForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Invoice ${
        response.invoiceNumber ? response.invoiceNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default SendInvoiceModal
