import {
  Currency,
  DiscountModel,
  PriceModel,
  toDiscountAmount,
  toMoney,
  toPriceSummary
} from '@sequencehq/core-models'
import {
  Schedule,
  Phase,
  Minimum,
  Price,
  DisabledReasonType,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { NEW_PRICE_PATTERN } from 'modules/Cube/domain/cube.constants'
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  isAfter,
  formatDuration,
  format,
  Duration
} from '@sequencehq/utils/dates'
import deepmerge from 'deepmerge'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'
import type { DateDurationConfig } from '@sequencehq/core-components'
import { calculateDiscountedPrice, priceModelAdapter } from '@sequencehq/utils'

export type AvailablePhaseActions = {
  deletePhase: {
    handler: () => void
    available: boolean
  }
}

type AvailableFeature = {
  handler: () => void
  disabled: boolean
  disabledReason?: string
  hidden?: boolean
}

export type PricingRow = {
  id: string
  name: string
  isNewPrice?: boolean
  frequency: string
  discount?: {
    id: DiscountModel['id']
    label: string
  }
  price: string
  preDiscountPrice?: string
  availableFeatures: Partial<{
    canEdit: AvailableFeature
    canDelete: AvailableFeature
    canEditDiscount: AvailableFeature
    canDeleteDiscount: AvailableFeature
  }>
  onClick: () => void
}

type FieldConfig<
  TargetDataStructure extends Record<string, unknown>,
  Property extends keyof TargetDataStructure = keyof TargetDataStructure
> = {
  disabled: boolean
  validationErrors: ValidationResult[]
  value: TargetDataStructure[Property]
  onChange: (newValue: TargetDataStructure[Property]) => void
}

export type MinimumRow = {
  id: Minimum['id']
  name: string
  frequency: string
  value: string
  actions: {
    edit: {
      handler: () => void
      available: boolean
    }
    delete: {
      handler: () => void
      available: boolean
    }
  }
}

type PricingGroup = {
  minimumRow?: MinimumRow
  pricingRows: PricingRow[]
}

export type PhaseHookInterface = {
  isOnlyPhase: boolean
  showProductSearch: boolean
  setShowAddProductSearch: (show: boolean) => void
  pricingGroups: PricingGroup[]
  fieldsConfig: {
    scheduleDateRange: {
      value: Duration | undefined
      minDate: Date | undefined
      fromDate: Date | undefined
      disabled: {
        start: boolean
        end: boolean
      }
      onChangeDuration: (newDuration: Duration | undefined) => void
      onChangeStartDate: (newStartDate: Date | undefined) => void
      validationErrors: {
        startDate: ValidationResult[]
        endDate: ValidationResult[]
      }
    }
    recurrenceDayOfMonth: FieldConfig<Schedule, 'recurrenceDayOfMonth'> & {
      disabledReason: string
      hidden: boolean
    }
  }
  features: {
    addDiscount: {
      handler: () => void
      disabled: boolean
      reason: string
    }
    addMinimum: {
      handler: () => void
      disabled: boolean
      reason: string
    }
    addProduct: {
      handler: () => void
      disabled: boolean
      reason: string
    }
    alignDates: {
      handler: () => void
      visible: boolean
    }
  }
  expanded: boolean
  updateExpanded: (newState: boolean) => void
  validationErrors: ValidationResult[]
  phaseActions: AvailablePhaseActions
  phaseDuration: {
    differenceFormatted: string
    startDateFormatted: string
    endDateFormatted: string
    endDateDescription: string
  }
  phaseComplete: boolean
  phaseDates: {
    start?: Date | undefined
    end?: Date | undefined
  }
  refs: {
    durationInput: React.RefObject<HTMLDivElement>
  }
  durationFilter: (duration: DateDurationConfig) => boolean
}

type UsePhase = (props: {
  phaseId: Phase['id']
  expanded: boolean
  focusDurationOnLoad?: boolean
  onExpand: (expanded: boolean) => void
}) => PhaseHookInterface

export const getPriceGroups =
  (activeVersionMinimums: Minimum[]) =>
  (
    activeVersionPrices: Price[]
  ): {
    minimum?: Minimum
    prices: Price[]
  }[] =>
    Object.values(
      activeVersionPrices.reduce((acc, price) => {
        if (!('usageMetricId' in price.structure)) {
          return deepmerge(acc, {
            'no-minimum': {
              prices: [price]
            }
          })
        }

        const matchingMinimum = activeVersionMinimums.find(
          minimum =>
            minimum.scope.target === 'allUsage' ||
            minimum.scope.priceIds.includes(price.id)
        )

        if (!matchingMinimum) {
          return deepmerge(acc, {
            'no-minimum': {
              prices: [price]
            }
          })
        }

        return deepmerge(acc, {
          [matchingMinimum.id]: {
            minimum: matchingMinimum,
            prices: [price]
          }
        })
      }, {})
    )

const EDIT_PRICE_DISABLED_REASONS: Partial<Record<DisabledReasonType, string>> =
  {
    [DisabledReasonType.TieredPriceTransition]:
      'Change the start and/or end date of this phase to be able to edit this tiered price',
    [DisabledReasonType.CompletedSchedule]:
      "Cannot edit a completed schedule's prices",
    [DisabledReasonType.CompletedPhase]:
      "Cannot edit a completed phase's prices"
  }

const EDIT_DISCOUNT_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.PhaseEndTransition]:
    'Change the start and/or end date of this phase to be able to edit this discount',
  [DisabledReasonType.PhaseStartTransition]:
    'Change the start and/or end date of this phase to be able to edit this discount',
  [DisabledReasonType.CompletedSchedule]:
    "Cannot edit a completed schedule's discounts",
  [DisabledReasonType.CompletedPhase]:
    "Cannot edit a completed phase's discounts"
}

const ADD_DISCOUNT_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.GlobalDiscountExists]:
    'Cannot add another discount when a global discount already exists',
  [DisabledReasonType.PhaseStartTransition]:
    'Change the start and/or end date of this phase to be able to add discounts',
  [DisabledReasonType.PhaseEndTransition]:
    'Change the start and/or end date of this phase to be able to add discounts',
  [DisabledReasonType.CompletedSchedule]:
    'Cannot add a discount to a completed schedule',
  [DisabledReasonType.CompletedPhase]:
    'Cannot add a discount to a completed phase'
}

const ADD_PRODUCT_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.CompletedPhase]:
    'Cannot add a product to a completed phase',
  [DisabledReasonType.CompletedSchedule]:
    'Cannot add a product to a completed schedule'
}

const ADD_MINIMUM_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.MaxMinimums]:
    'Cannot add another minimum when a minimum already exists for this phase',
  [DisabledReasonType.NoValidPrices]:
    'Add a usage based price in order to add a minimum',
  [DisabledReasonType.CompletedSchedule]:
    'Cannot add a minimum to a completed schedule',
  [DisabledReasonType.CompletedPhase]:
    'Cannot add a minimum to a completed phase'
}

const BILLING_FREQUENCY_LABELS: Record<PriceModel['billingFrequency'], string> =
  {
    ONE_TIME: 'One time',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    YEARLY: 'Annually'
  }

export const usePhase: UsePhase = props => {
  const [showAddProductSearch, setShowAddProductSearch] = useState(false)
  const cubeContext = useCubeContext()
  const navigate = useNavigate()

  const durationInputRef = useRef<HTMLInputElement>(null)

  const resolvedPhase = useMemo(() => {
    return cubeContext.queries.resolvedPhases[props.phaseId]
  }, [props.phaseId, cubeContext.queries.resolvedPhases])

  const activeVersionPrices: Price[] = useMemo(() => {
    if (!resolvedPhase) {
      return []
    }
    return cubeContext.queries.resolvedPhases[resolvedPhase.id]?.prices
  }, [resolvedPhase, cubeContext.queries.resolvedPhases])

  const activeVersionMinimums: Minimum[] = useMemo(() => {
    if (!resolvedPhase) {
      return []
    }
    return cubeContext.queries.resolvedPhases[resolvedPhase.id]?.minimums
  }, [cubeContext, resolvedPhase])

  const isFirstPhase = useMemo(() => {
    return cubeContext.queries.orderedPhases[0]?.id === props.phaseId
  }, [cubeContext.queries.orderedPhases, props.phaseId])

  const globalDiscountForPhase =
    cubeContext.queries.resolvedPhases[resolvedPhase.id].globalDiscount

  const pricingGroups = useMemo(() => {
    if (!resolvedPhase) {
      return []
    }

    const priceGroups = getPriceGroups(activeVersionMinimums)(
      activeVersionPrices
    )

    const enhancedPriceGroups: PricingGroup[] = priceGroups.map(group => {
      const enhancedMinimumRow = group.minimum
        ? {
            id: group.minimum.id,
            name: group.minimum.name || 'Minimum commitment',
            frequency:
              BILLING_FREQUENCY_LABELS[group.prices[0]?.billingFrequency] ??
              group.prices[0]?.billingFrequency,
            value: toMoney({
              value: group.minimum.value,
              currency: group.prices[0]?.currency
            }),
            actions: {
              edit: {
                handler: () =>
                  navigate(
                    `./minimum-editor/${props.phaseId}/${group.minimum?.id}`
                  ),
                available:
                  cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
                    .phase.minimum.edit.available.enabled
              },
              delete: {
                handler: () =>
                  group.minimum?.id &&
                  cubeContext.mutators.deleteMinimum(resolvedPhase.id)(
                    group.minimum.id
                  ),
                available:
                  cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
                    .phase.minimum.delete.available.enabled
              }
            }
          }
        : undefined

      const enhancedPricesRows = group.prices
        .map((price): PricingRow | null => {
          const discountForPrice = cubeContext.queries.resolvedPhases[
            resolvedPhase.id
          ].discounts.find(d => d.priceIds.includes(price.id))
          const globalDiscountActive = globalDiscountForPhase

          const availableFeaturesForThisProduct =
            cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
              ?.products[price.productId]

          if (!availableFeaturesForThisProduct) {
            return null
          }

          const availableProductFeatures =
            cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
              .products[price.productId]

          const discountAppliedToThisProduct =
            globalDiscountForPhase || discountForPrice

          const preDiscountPrice = toPriceSummary(priceModelAdapter.in(price))

          const processedPrice =
            calculateDiscountedPrice({
              price: priceModelAdapter.in(price),
              discount: discountAppliedToThisProduct
            }) ?? preDiscountPrice

          return {
            id: price.id,
            name: `${price.name}`,
            isNewPrice: Boolean(price.id.match(NEW_PRICE_PATTERN)),
            frequency: `${
              BILLING_FREQUENCY_LABELS[price.billingFrequency] ??
              price.billingFrequency
            }${price.billingType === 'IN_ADVANCE' ? ' / In advance' : ''}`,
            discount:
              discountForPrice && !globalDiscountActive
                ? {
                    id: discountForPrice.id,
                    label: toDiscountAmount(
                      discountForPrice.discountCalculationType,
                      discountForPrice.amount,
                      cubeContext.queries.selectedCurrency as Currency
                    )
                  }
                : undefined,
            preDiscountPrice,
            price: processedPrice,
            onClick: () =>
              navigate(`./price-editor/${resolvedPhase.id}/${price.productId}`),
            availableFeatures: {
              canEdit: {
                disabled: !availableProductFeatures?.edit.available.enabled,
                disabledReason:
                  EDIT_PRICE_DISABLED_REASONS[
                    availableProductFeatures?.edit.reasons[0]?.reasonType
                  ] ?? '',
                handler: () =>
                  navigate(
                    `./price-editor/${resolvedPhase.id}/${price.productId}`
                  )
              },
              canDelete: {
                disabled: !availableProductFeatures?.delete.available.enabled,
                handler: () =>
                  cubeContext.mutators.deletePrice(resolvedPhase.id)(price.id)
              },
              ...(discountForPrice
                ? {
                    canEditDiscount: {
                      disabled:
                        !availableProductFeatures?.editDiscount.available
                          .enabled,
                      disabledReason:
                        EDIT_DISCOUNT_DISABLED_REASONS[
                          availableProductFeatures?.editDiscount.reasons[0]
                            ?.reasonType
                        ] ?? '',
                      handler: () => {
                        navigate(
                          `./discount-editor/${props.phaseId}/${discountForPrice?.id}`
                        )
                      }
                    },
                    canDeleteDiscount: {
                      disabled:
                        !availableProductFeatures?.deleteDiscount.available,
                      disabledReason:
                        EDIT_DISCOUNT_DISABLED_REASONS[
                          availableProductFeatures?.deleteDiscount.reasons[0]
                            ?.reasonType
                        ] ?? '',
                      handler: () => {
                        cubeContext.mutators.deleteDiscount(
                          discountForPrice.id
                        )(price.id)
                      }
                    }
                  }
                : {})
            }
          }
        })
        .filter((row): row is PricingRow => row !== null)
        .sort((a, b) => (a.name > b.name ? 1 : -1))

      return {
        minimumRow: enhancedMinimumRow,
        pricingRows: enhancedPricesRows
      }
    })

    const globalDiscountRow: PricingGroup[] = globalDiscountForPhase
      ? [
          {
            pricingRows: [
              {
                id: globalDiscountForPhase.id,
                name: 'Global discount',
                frequency: '',
                discount: {
                  id: globalDiscountForPhase.id,
                  label: toDiscountAmount(
                    globalDiscountForPhase.discountCalculationType,
                    globalDiscountForPhase.amount,
                    (cubeContext.queries.selectedCurrency as Currency) ??
                      cubeContext.queries.rawData.configuration.currency.default
                  )
                },
                availableFeatures: {
                  canEditDiscount: {
                    disabled:
                      !cubeContext.queries.availableFeatures.phases[
                        resolvedPhase.id
                      ].phase.discount.edit.available.enabled,
                    disabledReason:
                      EDIT_DISCOUNT_DISABLED_REASONS[
                        cubeContext.queries.availableFeatures.phases[
                          resolvedPhase.id
                        ].phase.discount.edit?.reasons[0]?.reasonType
                      ] ?? '',
                    handler: () =>
                      navigate(
                        `./discount-editor/${props.phaseId}/${globalDiscountForPhase.id}`
                      )
                  },
                  canDeleteDiscount: {
                    disabled:
                      !cubeContext.queries.availableFeatures.phases[
                        resolvedPhase.id
                      ].phase.discount.delete.available.enabled,
                    disabledReason:
                      EDIT_DISCOUNT_DISABLED_REASONS[
                        cubeContext.queries.availableFeatures.phases[
                          resolvedPhase.id
                        ].phase.discount.delete?.reasons[0]?.reasonType
                      ] ?? '',
                    handler: () =>
                      cubeContext.mutators.deleteDiscount(
                        globalDiscountForPhase.id
                      )()
                  }
                },
                price: '',
                onClick: () =>
                  navigate(
                    `./discount-editor/${props.phaseId}/${globalDiscountForPhase.id}`
                  )
              }
            ]
          }
        ]
      : []

    return [...enhancedPriceGroups, ...globalDiscountRow]
  }, [
    resolvedPhase,
    activeVersionMinimums,
    navigate,
    cubeContext.queries,
    activeVersionPrices,
    cubeContext.mutators,
    props.phaseId,
    globalDiscountForPhase
  ])

  const addProductDisabled = useMemo(() => {
    return !cubeContext.queries.availableFeatures.phases[resolvedPhase.id].phase
      .product.add.available
  }, [cubeContext, resolvedPhase.id])

  const showProductSearch = useMemo(() => {
    return !addProductDisabled && showAddProductSearch
  }, [addProductDisabled, showAddProductSearch])

  const availableFeaturesForVersion = useMemo(() => {
    return cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
  }, [cubeContext.queries.availableFeatures, resolvedPhase.id])

  const features = useMemo(() => {
    return {
      addProduct: {
        handler: () => {
          setShowAddProductSearch(true)
        },
        disabled:
          !availableFeaturesForVersion.phase.product.add.available.enabled,
        reason:
          ADD_PRODUCT_DISABLED_REASONS[
            availableFeaturesForVersion.phase.product.add.reasons[0]?.reasonType
          ] ?? ''
      },
      addDiscount: {
        handler: () => {
          navigate(`./discount-editor/${props.phaseId}`)
        },
        disabled:
          !availableFeaturesForVersion.phase.discount.add.available.enabled,
        reason:
          ADD_DISCOUNT_DISABLED_REASONS[
            availableFeaturesForVersion.phase.discount.add.reasons[0]
              ?.reasonType
          ] ?? ''
      },
      addMinimum: {
        handler: () => {
          navigate(`./minimum-editor/${props.phaseId}`)
        },
        disabled:
          !availableFeaturesForVersion.phase.minimum.add.available.enabled,
        reason:
          ADD_MINIMUM_DISABLED_REASONS[
            availableFeaturesForVersion.phase.minimum.add.reasons[0]?.reasonType
          ] ?? ''
      },
      alignDates: {
        handler: () => {
          cubeContext.mutators.alignPhaseDuration(props.phaseId)
        },
        visible:
          availableFeaturesForVersion.phase.dates.alignDates.available.visible
      }
    }
  }, [navigate, props.phaseId, availableFeaturesForVersion, cubeContext])

  const previousPhaseEndDate = useMemo(() => {
    const currentPhaseIndex = cubeContext.queries.orderedPhases.findIndex(
      op => op.id === op.id
    )
    if (currentPhaseIndex < 1) {
      return
    }

    const previousPhase =
      cubeContext.queries.orderedPhases[currentPhaseIndex - 1]

    return previousPhase.dates.absolute.end
  }, [cubeContext])

  const fieldsConfig = useMemo(() => {
    return {
      recurrenceDayOfMonth: {
        hidden:
          !isFirstPhase ||
          // TODO: replace with availableFeatures query
          Boolean(
            quoteStatusAdapter.out(
              cubeContext.queries.rawData.data.common.status
            )
          ),
        value: cubeContext.queries.rawData.data.schedule.recurrenceDayOfMonth,
        onChange: (newValue: number) =>
          cubeContext.mutators.updateData({
            schedule: {
              recurrenceDayOfMonth: newValue
            }
          }),
        validationErrors:
          cubeContext.queries.validation.activeValidationResults?.schedule
            .recurrenceDayOfMonth ?? [],
        disabled:
          !cubeContext.queries.availableFeatures.schedule.recurrenceDayOfMonth
            .available.enabled,
        disabledReason:
          cubeContext.queries.availableFeatures.schedule.recurrenceDayOfMonth.reasons.find(
            reason =>
              reason.reasonType === DisabledReasonType.NonMonthlyPricesPresent
          )
            ? 'Billing date selection is only available when all products are billed monthly'
            : ''
      },
      scheduleDateRange: {
        value: resolvedPhase.dates.duration,
        minDate: previousPhaseEndDate,
        fromDate: resolvedPhase.dates.absolute.start,
        onChangeDuration: (newDuration: Duration | undefined) => {
          cubeContext.mutators.updateData({
            phases: {
              [resolvedPhase.id]: {
                duration: newDuration
              }
            }
          })
        },
        onChangeStartDate: (newStartDate: Date | undefined) => {
          cubeContext.mutators.updateData({
            common: {
              startDate: newStartDate
            }
          })
        },
        validationErrors: {
          startDate: isFirstPhase
            ? cubeContext.queries.validation.activeValidationResults?.common
                .startDate ?? []
            : [],
          endDate:
            cubeContext.queries.validation.activeValidationResults?.common
              .phases?.[props.phaseId]?.duration ?? []
        },
        disabled: {
          start:
            !availableFeaturesForVersion.phase.dates.editStart.available
              .enabled,
          end:
            !availableFeaturesForVersion.phase.dates.editDuration.available
              .enabled
        }
      }
    }
  }, [
    cubeContext,
    resolvedPhase,
    previousPhaseEndDate,
    availableFeaturesForVersion,
    isFirstPhase,
    props.phaseId
  ])

  const updateExpanded = useCallback(
    (newState: boolean) => {
      props.onExpand(newState)
    },
    [props]
  )

  const deletePhase = useCallback(() => {
    cubeContext.mutators.updateData({
      common: {
        phaseIds: cubeContext.queries.rawData.data.common.phaseIds.filter(
          phaseId => phaseId !== props.phaseId
        )
      }
    })

    cubeContext.mutators.deleteData({
      type: 'phases',
      ids: [props.phaseId]
    })
  }, [props.phaseId, cubeContext])

  const phaseActions = useMemo(
    () => ({
      deletePhase: {
        handler: deletePhase,
        available:
          Object.values(cubeContext.queries.rawData.data.phases).length > 1
      }
    }),
    [deletePhase, cubeContext.queries.rawData.data.phases]
  )

  const phaseDuration = useMemo(() => {
    if (!resolvedPhase.dates.duration) {
      return {
        differenceFormatted: 'Forever',
        startDateFormatted: '',
        endDateFormatted: '',
        endDateDescription: 'Phase continues forever'
      }
    }

    const endDateFormatted = resolvedPhase.dates.absolute.end
      ? format(resolvedPhase.dates.absolute.end, 'd LLLL yyyy')
      : ''

    return {
      differenceFormatted: formatDuration(
        {
          ...resolvedPhase.dates.duration,
          days:
            resolvedPhase.dates.duration.days === -1
              ? 0
              : resolvedPhase.dates.duration.days
        },
        {
          format: ['years', 'months', 'days']
        }
      ),
      startDateFormatted: resolvedPhase.dates.absolute.start
        ? format(resolvedPhase.dates.absolute.start, 'd LLLL yyyy')
        : '',
      endDateFormatted,
      endDateDescription: endDateFormatted
        ? `Phase ends on ${endDateFormatted}`
        : ''
    }
  }, [resolvedPhase])

  const phaseComplete = useMemo(() => {
    if (!resolvedPhase.dates.absolute.end) {
      return false
    }
    return isAfter(new Date(), resolvedPhase.dates.absolute.end)
  }, [resolvedPhase])

  const isOnlyPhase = useMemo(() => {
    return Object.values(cubeContext.queries.rawData.data.phases).length === 1
  }, [cubeContext.queries.rawData.data.phases])

  const isLastPhase = useMemo(() => {
    if (isOnlyPhase) {
      return true
    }

    const sortedPhases = cubeContext.queries.orderedPhases

    return sortedPhases[sortedPhases.length - 1].id === props.phaseId
  }, [cubeContext.queries.orderedPhases, isOnlyPhase, props.phaseId])

  const durationFilter = useCallback(
    (duration: DateDurationConfig) => {
      return ![
        ...(isLastPhase ? [] : ['FOREVER']),
        ...(cubeContext.queries.availableFeatures.common.customPhaseDurations
          .available.enabled
          ? []
          : ['CUSTOM'])
      ].includes(duration.value)
    },
    [
      isLastPhase,
      cubeContext.queries.availableFeatures.common.customPhaseDurations
    ]
  )

  useLayoutEffect(() => {
    if (props.focusDurationOnLoad) {
      durationInputRef.current?.focus()
    }
  }, [props.focusDurationOnLoad])

  const validationErrors = useMemo(() => {
    return [
      ...(cubeContext.queries.validation.activeValidationResults?.common
        .phases?.[props.phaseId]?.prices ?? [])
    ]
  }, [cubeContext.queries.validation.activeValidationResults, props.phaseId])

  return {
    isOnlyPhase,
    showProductSearch,
    setShowAddProductSearch,
    pricingGroups: pricingGroups,
    validationErrors,
    fieldsConfig,
    expanded: props.expanded,
    updateExpanded,
    phaseActions,
    phaseDuration,
    phaseComplete,
    features,
    phaseDates: resolvedPhase.dates.absolute,
    refs: {
      durationInput: durationInputRef
    },
    durationFilter
  }
}
