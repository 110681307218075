import {
  INTEGRATIONS_ORDER,
  IntegrationOrderGroup
} from 'Integrations/integrationsConfig/integrations.config'
import {
  IntegrationGroup,
  ResolvedIntegration,
  ResolvedIntegrations
} from 'modules/Integrations/domain/integrations.domain.types'

export const getIntegrationGroups =
  (ctx: {
    resolvedIntegrations: ResolvedIntegrations
    order?: Array<IntegrationOrderGroup>
  }) =>
  (): Partial<Record<ResolvedIntegration['type'], IntegrationGroup>> => {
    const integrations = Object.values(ctx.resolvedIntegrations)

    return (ctx.order ?? INTEGRATIONS_ORDER).reduce((acc, group) => {
      const unsortedIntegrations = integrations.filter(
        int => int.type === group.group && !group.order.includes(int.id)
      )

      return {
        ...acc,
        [group.group]: {
          title: group.group,
          integrations: [
            ...group.order
              .map(id => ctx.resolvedIntegrations[id])
              .filter(Boolean),
            ...unsortedIntegrations
          ]
        }
      }
    }, {})
  }
