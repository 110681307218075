import {
  ActionHandler,
  AddPrice,
  PricingEditorPrice,
  PricingEditorReducerState,
  StandardPrice
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import {
  INITIAL_PRICING_EDITOR_STATE,
  NEW_PRICE_PATTERN
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import deepmerge from 'deepmerge'

export const getNewPrice =
  (prevState: PricingEditorReducerState) =>
  (id: PricingEditorPrice['id']): StandardPrice => ({
    id,
    common: {
      ...INITIAL_PRICING_EDITOR_STATE.data.formData.common,
      pricingModel: 'STANDARD',
      name: prevState.data.product?.name ?? '',
      listPriceId: prevState.configuration.listPriceId,
      currency:
        prevState.configuration.currency ??
        prevState.configuration.availableCurrencies[0],
      externalIds: Object.values(prevState.data.ledgerIntegrations).reduce(
        (acc, account) => ({
          ...acc,
          [account.id]: account.defaultLedgerAccount
        }),
        {}
      )
    },
    modelSpecific: INITIAL_PRICING_EDITOR_STATE.data.formData.STANDARD
  })

export const addPrice: ActionHandler<AddPrice> = prevState => action => {
  //In the v1 release we only allow there to be one new price at a time,
  //so if an existing new price exists, we'll update that one.
  const newPriceId =
    Object.keys(prevState.data.prices).find(key =>
      key.match(NEW_PRICE_PATTERN)
    ) ?? `new-price::${crypto.randomUUID()}`

  return {
    ...prevState,
    data: {
      ...prevState.data,
      prices: {
        ...prevState.data.prices,
        [newPriceId]: {
          ...deepmerge(
            getNewPrice(prevState)(newPriceId),
            action.payload ?? {}
          ),
          id: newPriceId
        }
      }
    },
    editor: {
      ...prevState.editor,
      selectedPrice: newPriceId
    }
  } as PricingEditorReducerState
}
