import { Text } from '@chakra-ui/react'

export const TextDialog = ({
  children,
  ...props
}: React.ComponentProps<typeof Text>) => {
  return (
    <Text
      bgColor="#FFF6DF"
      padding="12px 16px"
      color="#7D5D07"
      borderRadius="8px"
      lineHeight="20px"
      position="relative"
      _before={{
        content: '""',
        position: 'absolute',
        top: '1px',
        left: '22px',
        width: 0,
        height: 0,
        border: '8px solid transparent',
        borderBottomColor: '#FFF6DF',
        borderTop: 0,
        borderRadius: '4px',
        marginLeft: '-8px',
        marginTop: '-8px'
      }}
      {...props}
    >
      {children}
    </Text>
  )
}
