import { useState } from 'react'
import {
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey40,
  Lato16Bold,
  Lato18Bold
} from '@sequencehq/design-tokens'

interface SummaryInputModalProps {
  open: boolean
  value: string
  onSave: (value: string) => void
  onClose: () => void
  modalTitle: string
  inputTitle: string
}
export const SummaryInputModal = ({
  value,
  onSave,
  open,
  onClose,
  inputTitle,
  modalTitle
}: SummaryInputModalProps) => {
  const [inputText, setInputText] = useState<string>(value)

  return (
    <Modal isOpen={open} onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent backgroundColor={GreyGrey0} borderRadius="8px">
          <Flex
            display="flex"
            justify="space-between"
            padding="20px"
            alignItems="center"
          >
            <Text {...Lato18Bold}>{modalTitle}</Text>
            <CloseButton onClick={onClose} />
          </Flex>
          <Divider color={GreyGrey40} />
          <Box padding="20px">
            <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
              {inputTitle}
            </Box>
            <Input
              variant="formInput2"
              onChange={e => setInputText(e.target.value)}
              value={inputText}
              placeholder="123-456-789"
            />
          </Box>
          <Divider color={GreyGrey40} />
          <Flex gap="8px" justifyContent="flex-end" padding="20px">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              borderRadius="md"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={() => onSave(inputText)}
              height="32px"
              borderRadius="md"
            >
              Save
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
