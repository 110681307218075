import { useMemo } from 'react'
import type { LineItemCustomer } from './useLoadLineItemEditor'
import {
  useXeroIntegration,
  type XeroIntegration
} from 'lib/hooks/useXeroIntegration'
import type { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'

type UseLoadLedgerIntegrations = (props?: { customer?: LineItemCustomer }) => {
  loading: boolean
  hasError: boolean
  data?: {
    ledgerIntegrations: {
      key: IntegrationServices
      value: XeroIntegration['ledgerAccounts']
      defaultLedgerAccount?: string
    }[]
  }
}

export const useLoadLedgerIntegrations: UseLoadLedgerIntegrations = props => {
  const customerIntegrations = useMemo(
    () => (props?.customer?.integrationIds ?? []).map(id => id.service),
    [props]
  )

  const {
    xeroIntegration,
    loading: xeroIntegrationLoading,
    hasError: xeroIntegrationError
  } = useXeroIntegration()

  const data = useMemo(() => {
    if (xeroIntegrationLoading || !xeroIntegration) {
      return { ledgerIntegrations: [] }
    }

    return {
      ledgerIntegrations:
        xeroIntegration &&
        xeroIntegration.enabled &&
        customerIntegrations.includes('Xero')
          ? [
              {
                key: 'Xero' as const,
                value: xeroIntegration.ledgerAccounts,
                defaultLedgerAccount: xeroIntegration.defaultLedgerAccount
              }
            ]
          : []
    }
  }, [xeroIntegrationLoading, xeroIntegration, customerIntegrations])

  return {
    loading: xeroIntegrationLoading,
    hasError: xeroIntegrationError,
    data
  }
}
