import { useCallback } from 'react'

import { isDemoEnv } from 'lib/environment/environment'
import {
  NavigationV3ContextType,
  Workspace
} from 'components/NavigationV3/NavigationV3Provider.types'

type UseNavigationV3Queries = ({
  userWorkspaces
}: {
  userWorkspaces: Workspace[] | undefined
}) => {
  queries: NavigationV3ContextType['queries']
}

export const useNavigationV3Queries: UseNavigationV3Queries = ({
  userWorkspaces
}) => {
  const canShareFeedback = useCallback(() => !isDemoEnv(), [])

  const canUseSettings = useCallback(() => !isDemoEnv(), [])

  const canLogOut = useCallback(() => !isDemoEnv(), [])

  const canSwitchWorkspaces = useCallback(
    () => !isDemoEnv() && !!userWorkspaces && userWorkspaces.length > 1,
    [userWorkspaces]
  )

  const canEnterDemoMode = useCallback(() => !isDemoEnv(), [])

  const canRequestSandboxAccess = useCallback(() => isDemoEnv(), [])

  return {
    queries: {
      canShareFeedback,
      canUseSettings,
      canLogOut,
      canSwitchWorkspaces,
      canEnterDemoMode,
      canRequestSandboxAccess
    }
  }
}
