import ApiKeysSettings from 'components/ApiKeysSettings/ApiKeysSettings'
import BankDetailsSettings from 'components/BankDetailsSettings/BankDetailsSettings'
import BillingSchedules from 'components/BillingSchedules'
import EditUsageMetricDrawerParamsHandler from 'components/CreateUsageMetric/EditUsageMetricDrawer'
import { DefaultCreateUsageMetricDrawerRouteComponent } from 'components/CreateUsageMetric/defaultRouteComponent/DefaultCreateUsageMetricDrawerRouteComponent'
import CreditNotePdf from 'components/CreditNotePdf/CreditNotePdf'
import CurrencySettings from 'components/CurrencySettings'
import Customers from 'components/Customers'
import NotFound from 'components/ErrorPages/NotFound'
import PageError from 'components/ErrorPages/PageError'
import Home from 'components/Home/Home'
import HomePage from 'components/Home/HomePage'
import { LineItemEditorRouteComponent } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/LineItemEditorRouteComponent.tsx'
import InvoicePdf from 'components/InvoicePdf/InvoicePdf'
import Invoices from 'components/Invoices'
import { InvoicesCustomerDrawerForm } from 'components/Invoices/CustomerDrawerForm'
import { InvoiceInspectorRedirect } from 'components/Invoices/InvoiceInspector/InvoiceInspectorRedirect'
import Layout from 'components/Layout'
import MerchantSettings from 'components/MerchantSettings/MerchantSettings'
import NotificationSettings from 'components/NotificationSettings/NotificationSettings'
import Overlay from 'components/Overlay'
import { Product } from 'modules/Products/Product/Product'
import { Products } from 'modules/Products/Products'
import { AddProductRouteComponent } from 'modules/Products/drawers/AddProduct/AddProductRouteComponent'
import { ProductPriceRouteComponent } from 'modules/Products/drawers/ProductPrice/ProductPriceRouteComponent'
import { FlaggedRoute } from 'components/QuoteEditor/FlaggedRoute'
import Quotes from 'components/Quotes'
import SeatBalances from 'modules/Seats/SeatBalances'
import { CreateSeatSnapshotFormDrawer } from 'modules/Seats/SeatEvents'
import { CreateSeatTypeFormDrawer } from 'modules/Seats/SeatMetric/CreateSeatTypeFormDrawer'
import SeatTypes from 'modules/Seats/SeatMetrics'
import { SettingsPage } from 'components/SettingsPage'
import TeamSettings from 'components/TeamSettings/TeamSettings'
import { ViewUsageEvent } from 'components/UsageEvent'
import { CreateUsageEventDrawer } from 'components/UsageEvent/CreateUsageEvent'
import UsageEvents from 'components/UsageEvents'
import UsageMetrics from 'components/UsageMetrics'
import { UsageMetricsCreateUsageMetricsRouteComponent } from 'components/UsageMetrics/drawers/UsageMetricsCreateUsageMetricDrawer'
import UserSettings from 'components/UserSettings'
import { ViewUsageMetric } from 'components/ViewUsageMetric'
import { LDFlagKeyMap } from 'launchdarkly-react-client-sdk'
import { Cube, QuoteSettings } from 'modules/Cube'
import { CustomerEditorCubeRoute } from 'modules/Cube/view/common/drawers/customerEditor/CustomerEditor.route.connector'
import { DiscountEditorCubeRoute } from 'modules/Cube/view/common/drawers/discountEditor/DiscountEditor.route.connector'
import { MinimumsEditorCubeRoute } from 'modules/Cube/view/common/drawers/minimumEditor/MinimumEditor.route.connector'
import {
  Navigate,
  Outlet,
  RouteObject,
  createBrowserRouter
} from 'react-router-dom'
import { InsightsV2 } from 'components/InsightsV2/InsightsV2'
import { EditProductRouteComponent } from 'modules/Products/drawers/EditProduct/EditProductRouteComponent'
import { QuoteEditorPreviewRoute } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.route.connector'
import { IntegrationManagement, IntegrationsRoot } from 'modules/Integrations'
import { EditCustomerRouteComponent } from 'modules/Customer/drawers/EditCustomer/EditCustomerRouteComponent'
import { PricingEditorUsageMetricsRouteComponent } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/drawers/UsageMetricsDrawer/PricingEditorUsageMetricsRouteComponent'
import { SeatTypeRouteComponent } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/drawers/CreatSeatTypeDrawer/CreateSeatTypeRouteComponent'
import InsightsTemplates from 'components/InsightsV2/widgets/InsightsTemplates/InsightsTemplates'
import { CreateCreditGrant } from 'modules/Customer/CreateCreditGrant'
import { CustomerPageLayout } from 'modules/Customer/CustomerPageLayout'
import { CustomerOverviewPage } from 'modules/Customer/CustomerOverviewPage'
import { ContactsPage } from 'modules/Customer/components/ContactsPage'
import { CreateCustomerRouteComponent } from 'components/Customers/components/drawers/CreateCustomer/CreateCustomerRouteComponent'
import { ListPriceEditorUsageMetricsRouteComponent } from 'modules/Products/drawers/ListPriceEditor/components/UsageMetricsDrawer/ListPriceEditorUsageMetricsRouteComponent'
import { ListPriceSeatTypeRouteComponent } from 'modules/Products/drawers/ListPriceEditor/components/CreatSeatTypeDrawer/CreateSeatTypeRouteComponent'
import { InvoicesSettings } from 'modules/Settings'
import SeatEvents from 'modules/Seats/SeatEvents/SeatEvents'
import { PricingEditorCubeRouteWrapper } from 'Cube/view/common/drawers/priceEditor/PricingEditorWrapper.route.connector'
import { NewAddProductRouteComponent } from 'Products/drawers/AddProduct/NewAddProductRouteComponent'
import { ListPriceEditorRouteWrapper } from 'Products/drawers/ListPriceEditor/ListPriceEditorWrapper.route.connector'
import { InvoiceEditorRoot } from 'InvoiceEditor/index'
import TaxRatesSettingsRoute from 'Settings/view/taxRatesSettings/TaxRatesSettingsRoute'
import { EditCustomerEditorCubeRoute } from 'Cube/view/common/drawers/editCustomerEditor/EditCustomerEditor.route.connector.tsx'
import { revenueRecognitionRoutes } from 'RevenueRecognition/revenueRecognition.router'
import { creditNotesRoutes } from 'CreditNotes/creditNotes.router'
import { CreditNotesSettings } from 'Settings/view/creditNotesSettings/CreditNotesSettings.tsx'

type DashboardRoutes = (args: { flags: LDFlagKeyMap }) => RouteObject[]

const CUBE_CHILD_PATHS = [
  {
    path: 'customers/:customerId',
    element: <CustomerEditorCubeRoute />
  },
  {
    path: 'customers/:customerId/edit',
    element: <EditCustomerEditorCubeRoute />
  },
  {
    path: 'discount-editor/:phaseId/:discountId?',
    element: <DiscountEditorCubeRoute />
  },
  {
    path: 'minimum-editor/:phaseId/:minimumId?',
    element: <MinimumsEditorCubeRoute />
  },
  {
    path: 'price-editor/:phaseId/:productId?',
    element: <PricingEditorCubeRouteWrapper />,
    children: [
      {
        path: 'metrics/new',
        element: <PricingEditorUsageMetricsRouteComponent />
      },
      {
        path: 'seats/new',
        element: <SeatTypeRouteComponent />
      }
    ]
  },
  {
    path: 'price-editor/:phaseId/:productId/:listPriceId',
    element: <PricingEditorCubeRouteWrapper />,
    children: [
      {
        path: 'metrics/new',
        element: <PricingEditorUsageMetricsRouteComponent />
      },
      {
        path: 'seats/new',
        element: <SeatTypeRouteComponent />
      }
    ]
  }
]

const AppContainer = () => {
  /**
   * The integrations module is split across the navigation and modal
   * components as well as a route component. Therefore we need to
   * hoist the domain if we want to make use of a single domain
   * for the module. We could look into more exotic mechanisms of
   * syncing the two disconnected components, but a simple hoist seems
   * the best bet for now!
   */
  return (
    <IntegrationsRoot>
      <Overlay />
      <Outlet />
    </IntegrationsRoot>
  )
}

export const dashboardRoutes: DashboardRoutes = ({ flags }) => {
  return [
    {
      path: '/',
      element: <AppContainer />,
      children: [
        {
          element: <Layout />,
          errorElement: <PageError />,
          children: [
            { path: '/billing', element: <Navigate to="/billing-schedules" /> },
            { path: '/billing/invoices', element: <Navigate to="/invoices" /> },
            {
              path: '/billing/credit-notes',
              element: <Navigate to="/credit-notes" />
            },

            { path: '/usage', element: <Navigate to="/usage-events" /> },
            {
              path: '/usage/metrics',
              element: <Navigate to="/usage-metrics" />
            },

            {
              path: '/home',
              element: <HomePage />,
              children: [{ index: true, element: <Home /> }]
            },

            {
              path: '/products',
              element: <Products />,
              children: [
                {
                  path: 'new',
                  element: <NewAddProductRouteComponent />,
                  children: [
                    {
                      path: 'metrics/new',
                      element: <ListPriceEditorUsageMetricsRouteComponent />
                    },
                    {
                      path: 'seats/new',
                      element: <ListPriceSeatTypeRouteComponent />
                    }
                  ]
                },
                {
                  path: 'create',
                  element: <AddProductRouteComponent />,
                  children: [
                    {
                      path: 'metrics/new',
                      element: <ListPriceEditorUsageMetricsRouteComponent />
                    },
                    {
                      path: 'seats/new',
                      element: <ListPriceSeatTypeRouteComponent />
                    }
                  ]
                }
              ]
            },
            {
              path: '/products/:productId',
              element: <Product />,
              children: [
                {
                  path: 'edit',
                  element: <EditProductRouteComponent />
                },
                {
                  path: 'price/:priceType/:priceId',
                  element: <ProductPriceRouteComponent />,
                  children: [
                    {
                      path: 'metrics/new',
                      element: <DefaultCreateUsageMetricDrawerRouteComponent />
                    },
                    {
                      path: 'seats/new',
                      element: <ListPriceSeatTypeRouteComponent />
                    }
                  ]
                },
                {
                  path: 'list-price/:listPriceId',
                  element: <ListPriceEditorRouteWrapper />,
                  children: [
                    {
                      path: 'metrics/new',
                      element: <ListPriceEditorUsageMetricsRouteComponent />
                    },
                    {
                      path: 'seats/new',
                      element: <ListPriceSeatTypeRouteComponent />
                    }
                  ]
                }
              ]
            },
            {
              path: '/billing-schedules',
              children: [
                {
                  index: true,
                  element: <BillingSchedules />
                },
                {
                  path: ':id',
                  element: <Cube variant="schedule" />,
                  children: CUBE_CHILD_PATHS
                }
              ]
            },
            {
              path: '/invoices',
              element: <Invoices />,
              children: [
                {
                  path: 'customers/:customerId',
                  element: <InvoicesCustomerDrawerForm />
                }
              ]
            },
            {
              path: '/invoices/inspect/:invoiceId',
              element: <InvoiceInspectorRedirect />
            },
            {
              path: '/invoices/:invoiceId',
              element: <InvoiceEditorRoot />,
              children: [
                {
                  path: ':lineItemGroupId/line-items/:lineItemId?',
                  element: <LineItemEditorRouteComponent />
                }
              ]
            },
            ...creditNotesRoutes(),
            {
              path: '/invoices-pdf/:invoiceId/:invoiceNumber',
              element: <InvoicePdf />
            },

            {
              path: '/credit-notes-pdf/:creditNoteId/:creditNoteNumber',
              element: <CreditNotePdf />
            },

            {
              path: '/usage-events',
              element: <UsageEvents />,
              children: [
                { path: 'new', element: <CreateUsageEventDrawer /> },
                { path: ':eventId', element: <ViewUsageEvent /> }
              ]
            },
            {
              path: '/usage-metrics',
              element: <UsageMetrics />,
              children: [
                { path: ':metricId', element: <ViewUsageMetric /> },
                {
                  path: 'new',
                  element: <UsageMetricsCreateUsageMetricsRouteComponent />
                },
                {
                  path: ':metricId/edit',
                  element: <EditUsageMetricDrawerParamsHandler />
                }
              ]
            },
            {
              path: '/seats',
              element: <SeatTypes />,
              children: [
                {
                  path: 'new',
                  element: <CreateSeatTypeFormDrawer />
                }
              ]
            },
            {
              path: '/seat-balances',
              element: <SeatBalances />,
              children: [
                {
                  path: 'new',
                  element: <CreateSeatSnapshotFormDrawer />,
                  children: [
                    {
                      path: 'seats/new',
                      element: <CreateSeatTypeFormDrawer />
                    }
                  ]
                }
              ]
            },
            {
              path: '/seat-events',
              element: <SeatEvents />,
              children: [
                {
                  path: ':snapshotId',
                  element: <SeatEvents />
                },
                {
                  path: 'new',
                  element: <CreateSeatSnapshotFormDrawer />,
                  children: [
                    {
                      path: 'seats/new',
                      element: <CreateSeatTypeFormDrawer />
                    }
                  ]
                }
              ]
            },
            {
              path: '/insights',
              element: <InsightsV2 />,
              children: [{ path: 'templates', element: <InsightsTemplates /> }]
            },
            {
              path: '/customers',
              element: <Customers />,
              children: [
                {
                  path: 'create',
                  element: <CreateCustomerRouteComponent />
                }
              ]
            },
            {
              path: '/customers/:customerId',
              element: <CustomerPageLayout />,
              children: [
                {
                  path: '',
                  element: <CustomerOverviewPage />,
                  children: [
                    {
                      path: 'edit',
                      element: <EditCustomerRouteComponent />
                    },
                    {
                      path: 'credit-grant',
                      element: <CreateCreditGrant />,
                      children: [
                        {
                          path: 'metrics/new',
                          element: (
                            <DefaultCreateUsageMetricDrawerRouteComponent />
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'contacts',
                  element: <ContactsPage />
                }
              ]
            },
            {
              path: '/quotes',
              children: [
                {
                  index: true,
                  element: (
                    <FlaggedRoute flag="showQuoteBuilder">
                      <Quotes />
                    </FlaggedRoute>
                  )
                },
                {
                  path: ':id',
                  element: <Cube variant="quote" />,
                  children: [
                    ...CUBE_CHILD_PATHS,
                    {
                      path: 'preview',
                      element: <QuoteEditorPreviewRoute />
                    }
                  ]
                }
              ]
            },
            {
              path: '/integration-management/:integrationId',
              element: <IntegrationManagement />
            },
            {
              path: '/integrations',
              element: <Navigate to="/home" />
            },
            {
              path: '/settings',
              children: [
                { path: 'team', element: <TeamSettings /> },
                ...(flags?.showQuoteBuilder && flags?.isQuoteSettingsEnabled
                  ? [{ path: 'quotes', element: <QuoteSettings /> }]
                  : []),
                {
                  path: '*',
                  element: <SettingsPage />,
                  children: [
                    { index: true, element: <UserSettings /> },
                    {
                      path: 'notifications',
                      element: <NotificationSettings />
                    },
                    { path: 'merchant', element: <MerchantSettings /> },
                    { path: 'bank-details', element: <BankDetailsSettings /> },
                    {
                      path: 'tax-rates',
                      element: <TaxRatesSettingsRoute />
                    },
                    { path: 'api-keys', element: <ApiKeysSettings /> },
                    { path: 'currencies', element: <CurrencySettings /> },
                    { path: 'invoices', element: <InvoicesSettings /> },
                    { path: 'credit-notes', element: <CreditNotesSettings /> }
                  ]
                }
              ]
            },
            ...revenueRecognitionRoutes(),
            { path: '*', element: <NotFound /> }
          ]
        }
      ]
    }
  ]
}

export const dashboardRouter = ({ flags }: { flags: LDFlagKeyMap }) =>
  createBrowserRouter(dashboardRoutes({ flags }))
