import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { DateObj, useDayzed } from 'dayzed'
import { Box, Flex } from '@chakra-ui/react'
import { CalendarView } from 'components/FormInputs/Dates/CalendarView'
import { format, add } from '@sequencehq/utils/dates'
import {
  DateRangeDurationsStart,
  DateRangeDurationsEnd
} from 'components/FormInputs/Dates/DateRangeDurations'
import { isValidDate } from '@sequencehq/validation'
import { match } from 'ts-pattern'
import { dayzedAdapter } from '@sequencehq/core-components'

type DateRangePopoverProps = {
  selectedDates: [Date | undefined, Date | undefined]
  minDate?: Date
  maxDate?: Date
  monthsToDisplay: number
  onDateSelected: (date: DateObj) => void
  initialRef?: MutableRefObject<HTMLButtonElement | null>
  focusedInput: 'start' | 'end'
  setPopoverOpen: Dispatch<SetStateAction<boolean>>
}

export const DateRangePopover: FC<DateRangePopoverProps> = ({
  selectedDates,
  minDate,
  maxDate,
  onDateSelected,
  initialRef,
  focusedInput,
  setPopoverOpen
}) => {
  const [offsetStart, setOffsetStart] = useState(0)
  const [offsetEnd, setOffsetEnd] = useState(0)

  const [startCalendarDate, setStartCalendarDate] = useState(
    isValidDate(selectedDates[0]) ? selectedDates[0] : new Date()
  )
  const [endCalendarDate, setEndCalendarDate] = useState(
    getEndCalendarDate(selectedDates)
  )

  useEffect(() => {
    if (!isValidDate(selectedDates[0])) {
      return
    }

    setStartCalendarDate(selectedDates[0])
    setOffsetStart(0)
  }, [selectedDates])

  useEffect(() => {
    if (!isValidDate(selectedDates[1])) {
      if (
        isValidDate(selectedDates[0]) &&
        format(endCalendarDate, 'yyyy-MM') ===
          format(startCalendarDate, 'yyyy-MM')
      ) {
        // setting { months: 1 } could be used to ensure
        // end date calendar is always one month after
        // start date calendar
        setEndCalendarDate(add(startCalendarDate, { months: 0 }))
        setOffsetEnd(0)
      }
      return
    }

    const endDate =
      format(selectedDates[1], 'yyyy-MM') ===
      format(startCalendarDate, 'yyyy-MM')
        ? add(startCalendarDate, { months: 0 })
        : selectedDates[1]

    setEndCalendarDate(endDate)
    setOffsetEnd(0)
  }, [selectedDates, startCalendarDate])

  const {
    calendars: calendarsStart,
    getDateProps: getDatePropsStart,
    getBackProps: getBackPropsStart,
    getForwardProps: getForwardPropsStart
  } = useDayzed({
    selected: (selectedDates as Date[]).map(
      date => date && dayzedAdapter.toDayzed(date)
    ), // isValidDate(value) ? value : undefined,
    onDateSelected,
    minDate:
      focusedInput === 'end' && isValidDate(selectedDates[0])
        ? dayzedAdapter.toDayzed(selectedDates[0])
        : minDate && dayzedAdapter.toDayzed(minDate),
    maxDate: getStartMaxDate({ focusedInput, selectedDates, maxDate }),

    date: startCalendarDate && dayzedAdapter.toDayzed(startCalendarDate), // isValidDate(value) ? value : undefined,
    monthsToDisplay: 1,
    offset: offsetStart,
    onOffsetChanged: offset => setOffsetStart(offset),
    firstDayOfWeek: 1
  })

  const {
    calendars: calendarsEnd,
    getDateProps: getDatePropsEnd,
    getBackProps: getBackPropsEnd,
    getForwardProps: getForwardPropsEnd
  } = useDayzed({
    selected: (selectedDates as Date[]).map(
      date => date && dayzedAdapter.toDayzed(date)
    ), // isValidDate(value) ? value : undefined,
    onDateSelected,
    minDate: getEndMinDate({ focusedInput, selectedDates, minDate }),
    maxDate:
      focusedInput === 'start' && isValidDate(selectedDates[1])
        ? dayzedAdapter.toDayzed(selectedDates[1])
        : maxDate && dayzedAdapter.toDayzed(maxDate),
    date: endCalendarDate && dayzedAdapter.toDayzed(endCalendarDate), // isValidDate(value) ? value : undefined,
    monthsToDisplay: 1,
    offset: offsetEnd,
    onOffsetChanged: offset => setOffsetEnd(offset),
    firstDayOfWeek: 1
  })

  return (
    <Flex>
      <Flex height="320px">
        {match(focusedInput)
          .with('start', () => (
            <>
              <CalendarView
                focusedInput={focusedInput}
                calendar={calendarsStart[0]}
                getDateProps={getDatePropsStart}
                getBackProps={getBackPropsStart}
                getForwardProps={getForwardPropsStart}
                selectedDates={selectedDates}
                initialRef={initialRef}
                type="range"
              />
              <Box width={4} />
              <DateRangeDurationsStart setPopoverOpen={setPopoverOpen} />
            </>
          ))
          .with('end', () => (
            <>
              <CalendarView
                focusedInput={focusedInput}
                calendar={calendarsEnd[0]}
                getDateProps={getDatePropsEnd}
                getBackProps={getBackPropsEnd}
                getForwardProps={getForwardPropsEnd}
                selectedDates={selectedDates}
                initialRef={initialRef}
                type="range"
              />
              <Box width={4} />
              <DateRangeDurationsEnd setPopoverOpen={setPopoverOpen} />
            </>
          ))
          .exhaustive()}
      </Flex>
    </Flex>
  )
}

const getEndCalendarDate = (selected: [Date | undefined, Date | undefined]) => {
  if (isValidDate(selected[1])) {
    if (
      isValidDate(selected[0]) &&
      format(selected[1], 'yyyy-MM') === format(selected[0], 'yyyy-MM')
    ) {
      // setting { months: 1 } could be used to ensure
      // end date calendar is always one month after
      // start date calendar
      return add(selected[0], { months: 0 })
    } else {
      return selected[1]
    }
  } else if (isValidDate(selected[0])) {
    return add(selected[0], { days: 0 })
  } else {
    return add(new Date(), { days: 0 })
  }
}

type GetEndMinDateArgs = {
  focusedInput: 'start' | 'end' | undefined
  selectedDates: [Date | undefined, Date | undefined]
  minDate: Date | undefined
}

const getEndMinDate = ({
  focusedInput,
  selectedDates,
  minDate
}: GetEndMinDateArgs) => {
  if (focusedInput !== 'end' || !isValidDate(selectedDates[0])) {
    return minDate && dayzedAdapter.toDayzed(minDate)
  }

  return selectedDates[0] && dayzedAdapter.toDayzed(selectedDates[0])
}

type GetStartMaxDateArgs = {
  focusedInput: 'start' | 'end' | undefined
  selectedDates: [Date | undefined, Date | undefined]
  maxDate: Date | undefined
}

const getStartMaxDate = ({
  focusedInput,
  selectedDates,
  maxDate
}: GetStartMaxDateArgs) => {
  if (focusedInput !== 'start' || !isValidDate(selectedDates[1])) {
    return maxDate && dayzedAdapter.toDayzed(maxDate)
  }

  return selectedDates[1] && dayzedAdapter.toDayzed(selectedDates[1])
}
