import { required } from '@sequencehq/validation'
import {
  CubeReducerState,
  ValidationResults
} from 'modules/Cube/domain/cube.domain.types'
import { adaptValidationFromLib } from 'modules/Cube/domain/postActionStages/validation/util/adaptValidatorFromLib'
import { atLeastOnePhase } from 'modules/Cube/domain/postActionStages/validation/validators/atLeastOnePhase'
import { atLeastOnePhasesBlock } from 'modules/Cube/domain/postActionStages/validation/validators/atLeastOnePhasesBlock'
import { atLeastOnePriceInPhase } from 'modules/Cube/domain/postActionStages/validation/validators/phases/atLeastOnePriceInPhase'
import { nonFinalPhaseHasDuration } from 'modules/Cube/domain/postActionStages/validation/validators/phases/nonFinalPhaseHasDuration'
import { DEFAULT_VALIDATION_MESSAGES } from './messages.quote'

type ExecuteQuoteValidation = (prevState: CubeReducerState) => ValidationResults

export const executeQuoteValidation: ExecuteQuoteValidation = prevState => {
  return {
    common: {
      title: adaptValidationFromLib(
        required(prevState.data.common.title),
        DEFAULT_VALIDATION_MESSAGES.TITLE
      ),
      customerId: adaptValidationFromLib(
        required(prevState.data.common.customerId),
        DEFAULT_VALIDATION_MESSAGES.CUSTOMER_ID
      ),
      currency: adaptValidationFromLib(
        required(prevState.data.common.currency),
        DEFAULT_VALIDATION_MESSAGES.CURRENCY
      ),
      phaseIds: atLeastOnePhase(prevState),
      phases: prevState.queries.orderedPhases.reduce(
        (acc, resolvedPhase) => ({
          ...acc,
          [resolvedPhase.id]: {
            prices: atLeastOnePriceInPhase({ phaseId: resolvedPhase.id })(
              prevState
            ),
            duration: nonFinalPhaseHasDuration({ phaseId: resolvedPhase.id })(
              prevState
            )
          }
        }),
        {}
      ),
      startDate: adaptValidationFromLib(
        required(prevState.data.common.startDate),
        DEFAULT_VALIDATION_MESSAGES.START_DATE
      )
    },
    schedule: {
      recurrenceDayOfMonth: null,
      taxRate: null
    },
    quote: {
      contactIds: null,
      dealType: adaptValidationFromLib(
        required(prevState.data.quote.dealType),
        DEFAULT_VALIDATION_MESSAGES.DEAL_TYPE
      ),
      expiry: adaptValidationFromLib(
        required(prevState.data.quote.expiresIn?.days),
        DEFAULT_VALIDATION_MESSAGES.EXPIRY
      ),
      presentation: atLeastOnePhasesBlock(prevState),
      quoteNumber: adaptValidationFromLib(
        required(prevState.data.common.alias),
        DEFAULT_VALIDATION_MESSAGES.QUOTE_NUMBER
      )
    }
  }
}
