import { DiscountEditorMode } from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { useDiscountEditorContext } from 'modules/Cube/view/common/drawers/discountEditor/drawer/domainManagement/useDiscountEditorContext'
import { useCallback, useMemo, useState } from 'react'
import { match } from 'ts-pattern'

export const useDiscountEditorContent = () => {
  const [submitting, setSubmitting] = useState(false)
  const discountEditorContext = useDiscountEditorContext()

  const { title, saveText, closeText } = useMemo(() => {
    return match(discountEditorContext.editor.mode)
      .with(DiscountEditorMode.CREATE, () => ({
        title: 'Add discount',
        saveText: 'Add discount',
        closeText: 'Cancel'
      }))
      .with(DiscountEditorMode.VIEW, () => ({
        title: 'View discount',
        saveText: 'Update discount',
        closeText: 'Cancel'
      }))
      .with(DiscountEditorMode.EDIT, () => ({
        title: 'Edit discount',
        saveText: 'Update discount',
        closeText: 'Cancel'
      }))
      .exhaustive()
  }, [discountEditorContext.editor.mode])

  const saveAvailable = useMemo(() => {
    return discountEditorContext.editor.mode !== DiscountEditorMode.VIEW
  }, [discountEditorContext.editor.mode])

  const onSave = useCallback(async () => {
    setSubmitting(true)
    await new Promise(resolve => setTimeout(resolve, 0))
    discountEditorContext.functions.saveDiscount()
    setSubmitting(false)
  }, [discountEditorContext.functions])

  const onClose = useCallback(() => {
    discountEditorContext.functions.closeEditor()
  }, [discountEditorContext.functions])

  const blockerEnabled = useMemo(() => {
    return discountEditorContext.queries.changesMade
  }, [discountEditorContext.queries.changesMade])

  return {
    title,
    onSave,
    onClose,
    closeText,
    saveText,
    saveDisabled: !discountEditorContext.queries.canSave,
    saveAvailable,
    submitting,
    blockerEnabled
  }
}
