import { Box, Flex } from '@chakra-ui/react'
import { TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  nineDigitNumber,
  required
} from '@sequencehq/validation'
import { FC } from 'react'

export const UsAchFields: FC<{ fieldName: string }> = ({ fieldName }) => {
  return (
    <Flex flexDirection="column">
      <Flex>
        <Box width="200px">
          <TextInputField
            fieldLabel="Account Number"
            fieldName={`${fieldName}.value.usAchDestination.accountNumber`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Account Name"
            fieldName={`${fieldName}.value.usAchDestination.accountName`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Bank Name"
            fieldName={`${fieldName}.value.usAchDestination.bankName`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Bank Routing Number"
            fieldName={`${fieldName}.value.usAchDestination.bankRoutingNumber`}
            validate={composeValidators(required, nineDigitNumber)}
          />
        </Box>
      </Flex>
      <Box height="24px"></Box>
    </Flex>
  )
}
