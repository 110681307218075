import { HStack } from '@chakra-ui/react'
import { SelectInput } from '@sequencehq/core-components'
import { GreyGrey40 } from '@sequencehq/design-tokens'

interface DatePresetSelectProps {
  value: string
  onChangeDatePreset: (preset: string) => void
}

export const DatePresetSelect = ({
  value,
  onChangeDatePreset
}: DatePresetSelectProps) => {
  const PRESET_INPUT_OPTIONS = [
    {
      label: 'This month',
      value: 'this_month'
    },
    {
      label: 'Last 3 months',
      value: '3_months'
    },
    {
      label: 'Last 6 months',
      value: '6_months'
    },
    {
      label: 'Last year',
      value: 'last_year'
    }
  ]

  return (
    <HStack
      spacing={0}
      border={`1px solid ${GreyGrey40}`}
      boxShadow={`0px 2px 4px rgba(0, 0, 0, 0.05)`}
      borderRadius="lg"
      overflow="hidden"
      display="inline-flex"
    >
      <SelectInput
        options={PRESET_INPUT_OPTIONS}
        value={value}
        onChange={onChangeDatePreset}
        placeholder="Choose date range"
        width="200px"
        height="36px"
        sx={{
          '& div[role="combobox"]': {
            borderRadius: 0,
            boxShadow: 'none',
            border: 'none',
            outline: 'none!important'
          }
        }}
      />
    </HStack>
  )
}
