import { FC } from 'react'

interface SnowflakeIconProps {
  width?: number
  height?: number
}

export const SnowflakeIcon: FC<SnowflakeIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6101_13204)">
      <path
        d="M48.8126 44.4375L39.0251 38.7833C37.6501 37.9917 35.8918 38.4625 35.096 39.8375C34.7876 40.3792 34.6668 40.975 34.7251 41.5542V52.6042C34.7251 54.1833 36.0085 55.4667 37.5918 55.4667C39.171 55.4667 40.4543 54.1833 40.4543 52.6042V46.25L45.9376 49.4167C47.3126 50.2125 49.0751 49.7375 49.8668 48.3625C50.6626 46.9875 50.1918 45.2292 48.8126 44.4375Z"
        fill="#29B5E8"
      />
      <path
        d="M24.4708 32.3208C24.4874 31.3 23.95 30.35 23.0666 29.8375L13.2791 24.1875C12.8541 23.9417 12.3666 23.8125 11.8791 23.8125C10.8749 23.8125 9.94162 24.35 9.44162 25.2167C8.66662 26.5583 9.12912 28.2792 10.4708 29.0542L16.1124 32.3083L10.4708 35.5667C9.82078 35.9417 9.35412 36.5458 9.16245 37.2708C8.96662 37.9958 9.06662 38.7542 9.44162 39.4042C9.94162 40.2708 10.8749 40.8083 11.8749 40.8083C12.3666 40.8083 12.8541 40.6792 13.2791 40.4333L23.0666 34.7833C23.9416 34.275 24.4791 33.3333 24.4708 32.3208Z"
        fill="#29B5E8"
      />
      <path
        d="M15.5007 20.1834L25.2882 25.8334C26.4299 26.4959 27.8382 26.2793 28.7382 25.4001C29.3091 24.8751 29.6632 24.1251 29.6632 23.2918V11.9668C29.6632 10.3834 28.3799 9.1001 26.8007 9.1001C25.2174 9.1001 23.9341 10.3834 23.9341 11.9668V18.4126L18.3757 15.2043C17.0007 14.4084 15.2424 14.8793 14.4466 16.2543C13.6507 17.6293 14.1257 19.3876 15.5007 20.1834Z"
        fill="#29B5E8"
      />
      <path
        d="M37.5079 32.7793C37.5079 32.996 37.3829 33.296 37.2288 33.4543L33.3496 37.3335C33.1954 37.4877 32.8913 37.6127 32.6746 37.6127H31.6871C31.4704 37.6127 31.1663 37.4877 31.0121 37.3335L27.1288 33.4543C26.9746 33.296 26.8496 32.996 26.8496 32.7793V31.7918C26.8496 31.571 26.9746 31.271 27.1288 31.1168L31.0121 27.2335C31.1663 27.0793 31.4704 26.9543 31.6871 26.9543H32.6746C32.8913 26.9543 33.1954 27.0793 33.3496 27.2335L37.2288 31.1168C37.3829 31.271 37.5079 31.571 37.5079 31.7918V32.7793ZM34.0454 32.3043V32.2627C34.0454 32.1043 33.9538 31.8835 33.8413 31.7668L32.6954 30.6252C32.5829 30.5085 32.3621 30.4168 32.1996 30.4168H32.1579C31.9996 30.4168 31.7788 30.5085 31.6621 30.6252L30.5204 31.7668C30.4079 31.8793 30.3163 32.1002 30.3163 32.2627V32.3043C30.3163 32.4668 30.4079 32.6877 30.5204 32.8002L31.6621 33.946C31.7788 34.0585 31.9996 34.1502 32.1579 34.1502H32.1996C32.3621 34.1502 32.5829 34.0585 32.6954 33.946L33.8413 32.8002C33.9538 32.6877 34.0454 32.4668 34.0454 32.3043Z"
        fill="#29B5E8"
      />
      <path
        d="M39.026 25.8334L48.8135 20.1834C50.1885 19.3918 50.6635 17.6293 49.8676 16.2543C49.0718 14.8793 47.3135 14.4084 45.9385 15.2043L40.4551 18.3709V11.9668C40.4551 10.3834 39.1718 9.1001 37.5926 9.1001C36.0093 9.1001 34.726 10.3834 34.726 11.9668V23.0668C34.6718 23.6418 34.7843 24.2418 35.0968 24.7834C35.8926 26.1584 37.651 26.6293 39.026 25.8334Z"
        fill="#29B5E8"
      />
      <path
        d="M27.2507 38.4501C26.6007 38.3251 25.9049 38.4293 25.2882 38.7835L15.5007 44.4376C14.1257 45.2293 13.6507 46.9876 14.4466 48.3626C15.2424 49.7418 17.0007 50.2126 18.3757 49.4168L23.9341 46.2085V52.6043C23.9341 54.1835 25.2174 55.4668 26.8007 55.4668C28.3799 55.4668 29.6632 54.1835 29.6632 52.6043V41.2793C29.6632 39.8501 28.6174 38.6668 27.2507 38.4501Z"
        fill="#29B5E8"
      />
      <path
        d="M54.9299 25.1835C54.1383 23.8044 52.3758 23.3335 51.0008 24.1294L41.2133 29.7794C40.2758 30.321 39.7591 31.3127 39.7758 32.321C39.7674 33.3252 40.2841 34.3044 41.2133 34.8377L51.0008 40.4919C52.3758 41.2835 54.1341 40.8127 54.9299 39.4377C55.7258 38.0627 55.2508 36.3044 53.8758 35.5085L48.3341 32.3085L53.8758 29.1085C55.2549 28.3169 55.7258 26.5585 54.9299 25.1835Z"
        fill="#29B5E8"
      />
    </g>
    <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#F1F4F8" />
    <defs>
      <clipPath id="clip0_6101_13204">
        <rect
          width="48"
          height="46.9333"
          fill="white"
          transform="translate(8 8.53345)"
        />
      </clipPath>
    </defs>
  </svg>
)
