import { EnrichedText } from '@sequencehq/quote-content'
import { pick } from 'lodash/fp'

export type NestedContent =
  | {
      type: 'text'
      text: string
      styles: {
        bold?: boolean
        italic?: boolean
        strike?: boolean
        underline?: boolean
        textColor?: string
      }
    }
  | { type: 'link'; content: NestedContent[]; href: string }

export const textContentAdapter = {
  in: (content: string | string[] | EnrichedText[]): NestedContent[] => {
    if (typeof content === 'string') {
      return [
        {
          type: 'text',
          text: content,
          styles: {}
        }
      ]
    }

    if (content.length === 0) {
      return [
        {
          type: 'text',
          text: '',
          styles: {}
        }
      ]
    }

    if (typeof content[0] === 'string') {
      return (content as (string | null)[])
        .map(item => (item === null ? '' : item))
        .map(item => ({
          type: 'text',
          text: item,
          styles: {}
        }))
    }

    return (content as EnrichedText[]).reduce(
      (acc: NestedContent[], item: EnrichedText) => {
        const previousItem = acc[acc.length - 1]
        if (
          previousItem &&
          'href' in previousItem &&
          previousItem?.href === item.href
        ) {
          return [
            ...acc.slice(0, -1),
            {
              ...previousItem,
              content: [
                ...previousItem.content,
                {
                  text: item.text,
                  type: 'text' as const,
                  styles: pick([
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'textColor'
                  ])(item.styles)
                }
              ]
            }
          ]
        }

        if (item.href) {
          return [
            ...acc,
            {
              type: 'link' as const,
              content: [
                {
                  text: item.text,
                  type: 'text' as const,
                  styles: pick([
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'textColor'
                  ])(item.styles)
                }
              ],
              href: item.href
            }
          ]
        }

        return [
          ...acc,
          {
            type: 'text' as const,
            text: item.text,
            styles: pick([
              'bold',
              'italic',
              'underline',
              'strike',
              'textColor'
            ])(item.styles)
          }
        ]
      },
      []
    )
  },
  out: (content: NestedContent[]): EnrichedText[] => {
    return content.flatMap(item => {
      if ('text' in item) {
        return {
          styles: pick(['bold', 'italic', 'underline', 'strike', 'textColor'])(
            item.styles
          ),
          text: item.text
        } as EnrichedText
      }

      if (item.content.length) {
        return textContentAdapter.out(item.content).map(textContent => ({
          ...textContent,
          /**
           * It's highly unlikely the user intends to have a link
           * to another part of the customer portal, so let's assume
           * a lack of http or https means they still actually intended
           * to go to a different website.
           */
          href: item.href.includes('http') ? item.href : `https://${item.href}`
        }))
      }

      return []
    })
  }
}
