import { Link } from 'react-router-dom'

import { Button, Center, Spinner } from '@chakra-ui/react'
import { BookOpenIcon } from '@heroicons/react/16/solid'

import Page from 'components/Page/Page'
import { useQuoteSettings } from 'modules/Cube/settings/QuoteSettings/useQuoteSettings'
import { QuoteSettingsForm } from 'modules/Cube/settings/QuoteSettings/QuoteSettingsForm'

export const QuoteSettings = () => {
  const hook = useQuoteSettings()

  return (
    <Page
      title="Quotes"
      actionElement={
        hook.linkDocumentation.visible ? (
          <Button
            variant="component-library-secondary"
            as={Link}
            to={hook.linkDocumentation.href}
            target="_blank"
            rel="noopener noreferrer"
            leftIcon={
              <BookOpenIcon width="16px" height="16px" color="inherit" />
            }
            iconSpacing="8px"
          >
            Documentation
          </Button>
        ) : null
      }
    >
      {hook.isLoading ? (
        <Center width="100%" height="100%">
          <Spinner />
        </Center>
      ) : (
        <QuoteSettingsForm hook={hook} />
      )}
    </Page>
  )
}
