import { Box, Button, Flex, Text, Icon } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  Lato12Bold,
  Lato14Regular,
  Lato16Regular,
  Lato24Bold
} from '@sequencehq/design-tokens'
import { FC } from 'react'
import { Icon as FeatherIcon } from 'react-feather'

export const SimplePaymentPage: FC<{
  icon: FeatherIcon
  iconColour: string
  header: string
  subheader: string
  nextSteps: string[]
  nextStepsActionButton?: string
}> = ({
  icon,
  iconColour,
  header,
  subheader,
  nextSteps,
  nextStepsActionButton
}) => (
  <Flex alignItems="center" justifyContent="center">
    <Box margin="auto" width="380px">
      <Box height="192px" />
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
      >
        <Icon
          as={icon}
          color={iconColour}
          height="36px"
          width="36px"
          strokeWidth="1px"
        />
        <Text {...Lato24Bold}>{header}</Text>
        <Box height="18px" />
        <Text {...Lato16Regular}>{subheader}</Text>
      </Flex>
      <Box height="36px" />
      <Flex
        justifyContent="center"
        flexDirection="column"
        backgroundColor={GreyGrey10}
        padding="16px"
      >
        <Text
          {...Lato12Bold}
          color={GreyGrey70}
          textTransform="uppercase"
          marginBottom="8px"
        >
          What's next:
        </Text>
        {nextSteps.length > 1 ? (
          <ul>
            {nextSteps.map((step, index) => (
              <li key={index}>
                <Text {...Lato14Regular}>{step}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text {...Lato14Regular}>{nextSteps[0]}</Text>
        )}
        {nextStepsActionButton && (
          <Button variant="primary" marginTop="16px" width="100%">
            {nextStepsActionButton}
          </Button>
        )}
      </Flex>
    </Box>
  </Flex>
)
