import { Button, Link, Text } from '@chakra-ui/react'
import { Lato14ExtendedRegular, Lato16Bold } from '@sequencehq/design-tokens'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Section } from 'modules/LoginApp/components/common/Section'
import { Wordmark } from 'components/icons/Wordmark'

export const LinkExpiredPage = () => {
  return (
    <PageContainer>
      <Wordmark width="126" height="26.74" />
      <Section gap="8px">
        <Text {...Lato16Bold} color="gray.90">
          Link expired
        </Text>
        <Text {...Lato14ExtendedRegular} color="gray.80">
          The link you are trying to use has expired. Please try again by
          requesting a new link.
        </Text>
        <Button
          as={Link}
          href="/auth/login"
          mt="2"
          variant="primary2"
          width="100%"
          _hover={{
            textDecoration: 'none'
          }}
        >
          Try again
        </Button>
      </Section>
    </PageContainer>
  )
}

export default LinkExpiredPage
