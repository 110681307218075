import {
  AvailableStandardFrequency,
  CubeReducerState
} from 'modules/Cube/domain/cube.domain.types'

export const getAvailableFrequencies = (
  prevState: CubeReducerState
): AvailableStandardFrequency[] => {
  return prevState.configuration.availableFrequencies
}
