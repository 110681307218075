import { add, setDate } from '@sequencehq/utils/dates'
import { ChevronButton } from 'components/FormInputs/Dates/ChevronButton'
import { VStack } from '@chakra-ui/react'
import { useField } from 'react-final-form'
import { isValidDate } from '@sequencehq/validation'
import { Dispatch, FC, SetStateAction } from 'react'

type DateRangeDurationsProps = {
  setPopoverOpen: Dispatch<SetStateAction<boolean>>
}

export const DateRangeDurationsEnd: FC<DateRangeDurationsProps> = ({
  setPopoverOpen
}) => {
  const { input: startDateInput } = useField<Date>('startDate', {
    subscription: { value: true }
  })

  const { input: endDateInput } = useField<Date>('endDate', {
    subscription: { value: true }
  })

  return (
    <VStack
      flexDirection="column"
      bg="gray.20"
      px={4}
      py={5}
      alignItems="initial"
      spacing={4}
      width="200px"
    >
      <ChevronButton
        disabled={!isValidDate(startDateInput.value)}
        onClick={() => {
          endDateInput.onChange(
            add(startDateInput.value, { years: 1, days: -1 })
          )
          setPopoverOpen(false)
        }}
      >
        1 year
      </ChevronButton>
      <ChevronButton
        disabled={!isValidDate(startDateInput.value)}
        onClick={() => {
          endDateInput.onChange(
            add(startDateInput.value, { months: 6, days: -1 })
          )
          setPopoverOpen(false)
        }}
      >
        6 months
      </ChevronButton>
      <ChevronButton
        disabled={!isValidDate(startDateInput.value)}
        onClick={() => {
          endDateInput.onChange(
            add(startDateInput.value, { months: 3, days: -1 })
          )
          setPopoverOpen(false)
        }}
      >
        3 months
      </ChevronButton>
      <ChevronButton
        disabled={!isValidDate(startDateInput.value)}
        onClick={() => {
          endDateInput.onChange(
            add(startDateInput.value, { months: 2, days: -1 })
          )
          setPopoverOpen(false)
        }}
      >
        2 months
      </ChevronButton>
      <ChevronButton
        disabled={!isValidDate(startDateInput.value)}
        onClick={() => {
          endDateInput.onChange(
            add(startDateInput.value, { months: 1, days: -1 })
          )
          setPopoverOpen(false)
        }}
      >
        1 month
      </ChevronButton>
    </VStack>
  )
}

export const DateRangeDurationsStart: FC<DateRangeDurationsProps> = ({
  setPopoverOpen
}) => {
  const { input: startDateInput } = useField<Date>('startDate', {
    subscription: { value: true }
  })

  return (
    <VStack
      flexDirection="column"
      bg="gray.20"
      px={4}
      py={5}
      alignItems="initial"
      spacing={4}
      width="200px"
    >
      <ChevronButton
        onClick={() => {
          startDateInput.onChange(new Date())
          setPopoverOpen(false)
        }}
      >
        Today
      </ChevronButton>
      <ChevronButton
        disabled={!isValidDate(startDateInput.value)}
        onClick={() => {
          const nextMonth = add(startDateInput.value, { months: 1 })
          startDateInput.onChange(setDate(nextMonth, 1))
          setPopoverOpen(false)
        }}
      >
        First day of next month
      </ChevronButton>
    </VStack>
  )
}
