import { Box } from '@chakra-ui/react'
import { GreyWhite } from '@sequencehq/design-tokens'
import { TitleEditor } from './TitleEditor'
import { ContentEditor } from './ContentEditor'
import { ConfigPills } from './ConfigPills'
import { QuoteByline } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/QuoteByline/QuoteByline'
import { ValidationBanner } from './ValidationBanner/ValidationBanner'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CoverImageEditorUploadButton } from './CoverImageEditor/CoverImageEditor'
import { useCoverImageEditorContext } from './CoverImageEditor/CoverImageEditorContext'

export const QuoteEditorContent = () => {
  const flags = useFlags()
  const { imageSrc, isDisabled } = useCoverImageEditorContext()

  const showUploadButton = flags.useQuoteCoverImages && !isDisabled

  return (
    <Box background={GreyWhite} width="900px">
      <ValidationBanner />
      <Box
        px="35px"
        pt={flags.useQuoteCoverImages ? '16px' : '32px'}
        margin="0 auto"
        role="group"
      >
        {showUploadButton && (
          <Box
            opacity={!imageSrc ? 1 : 0}
            height={imageSrc ? '0px' : undefined}
            pb="16px"
            px="16px"
          >
            <CoverImageEditorUploadButton />
          </Box>
        )}

        <TitleEditor />
        <QuoteByline />
        <ConfigPills />
      </Box>

      <Box>
        <Box pb="64px" margin="0 auto">
          <ContentEditor />
        </Box>
      </Box>
    </Box>
  )
}
