import { Flex } from '@chakra-ui/react'
import { GreyGrey30, GreyWhite, Lato16Bold } from '@sequencehq/design-tokens'
import { useEditorHeader } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/EditorHeader/useEditorHeader'
import { NewPricePill } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/NewPricePill'

export const EditorHeader = () => {
  const { title, showNewPill } = useEditorHeader()

  return (
    <Flex
      {...Lato16Bold}
      alignItems="center"
      padding="0 24px"
      borderBottom={`1px solid ${GreyGrey30}`}
      backgroundColor={GreyWhite}
      height="100%"
      data-testid="priceEditor.header"
      justifyContent="space-between"
    >
      {title}
      {showNewPill && <NewPricePill />}
    </Flex>
  )
}
