import { Button } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { PaymentDetailsDrawer } from '@sequencehq/invoice-content'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'

type PaymentDetailsProps = {
  isOpen: boolean
  onClose: () => void
}

export const PaymentDetails = (props: PaymentDetailsProps) => {
  const { data } = useInvoiceEditorContext()

  return (
    <PaymentDetailsDrawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      footer={
        <Button
          variant="secondary2"
          as={RouterLink}
          to="/settings/bank-details"
          target="_blank"
        >
          Edit payment details
        </Button>
      }
      detailItems={data.merchant.accountDetails}
    />
  )
}
