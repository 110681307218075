import { Box, Grid, GridItem, Image } from '@chakra-ui/react'
import {
  ArrowRightIcon,
  BoltIcon,
  XCircleIcon
} from '@heroicons/react/16/solid'
import { Badge } from '@sequencehq/core-components'
import {
  GreenGreen80,
  GreyGrey0,
  GreyGrey30,
  GreyGrey50,
  GreyGrey70,
  GreyGrey90,
  GreyWhite,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { ReactNode } from 'react'

type WorkflowManagementProps = {
  logoUrls: {
    from: string
    to: string
  }
  onClick?: () => void
  status: 'ACTIVE' | 'INACTIVE'
  label: string
  description?: string
  children?: ReactNode
}

export const WorkflowManagement = (props: WorkflowManagementProps) => {
  return (
    <Grid
      borderRadius="8px"
      border={`1px solid ${GreyGrey30}`}
      gridTemplateRows={'auto auto'}
      gridTemplateColumns={`1fr auto`}
      gridTemplateAreas={`
        "header status"
        "description actions"
        `}
      overflow="hidden"
      userSelect="none"
    >
      <GridItem
        gridArea="header"
        padding="8px"
        paddingLeft="16px"
        backgroundColor={GreyGrey0}
        borderBottom={`1px solid ${GreyGrey30}`}
        display="flex"
        alignItems="center"
        gap="8px"
      >
        <Image src={props.logoUrls.from} height="20px" width="20px" />
        <ArrowRightIcon height="16px" width="16px" color={GreyGrey50} />
        <Image src={props.logoUrls.to} height="20px" width="20px" />
      </GridItem>
      <GridItem
        gridArea="status"
        padding="4px"
        paddingLeft="16px"
        backgroundColor={GreyGrey0}
        borderBottom={`1px solid ${GreyGrey30}`}
        display="flex"
        alignItems="center"
      >
        {props.status === 'ACTIVE' ? (
          <Badge sentiment="success">
            <BoltIcon height="16px" width="16px" color={GreenGreen80} />
            Active
          </Badge>
        ) : (
          <Badge sentiment="neutral">
            <XCircleIcon height="16px" width="16px" color={GreyGrey70} />
            Inactive
          </Badge>
        )}
      </GridItem>
      <GridItem
        gridArea="description"
        display="grid"
        gridTemplateRows={'auto auto'}
        gap="4px"
        padding="12px 0 12px 16px"
        backgroundColor={GreyWhite}
        cursor={props.onClick ? 'pointer' : 'default'}
        onClick={() => {
          props.onClick?.()
        }}
      >
        <Box {...Lato13Bold}>{props.label}</Box>
        {props.description && (
          <Box {...Lato13Regular} color={GreyGrey90}>
            {props.description}
          </Box>
        )}
      </GridItem>
      <GridItem
        gridArea="actions"
        padding="12px 16px 12px 0"
        backgroundColor={GreyWhite}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        cursor={props.onClick ? 'pointer' : 'default'}
        onClick={() => {
          props.onClick?.()
        }}
      >
        {props.children}
      </GridItem>
    </Grid>
  )
}
