import { CreateCustomerForm } from 'components/Customers/components/drawers/CreateCustomer/CreateCustomerForm'
import { useCustomerEditorConnector } from 'modules/Cube/view/common/drawers/customerEditor/useCustomerEditor.connector'

export const CustomerEditorCubeRoute = () => {
  const { onCancel, onSuccess } = useCustomerEditorConnector()

  return (
    <CreateCustomerForm
      onSuccess={customer => onSuccess(customer)}
      onClose={onCancel}
    />
  )
}
