import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import { match } from 'ts-pattern'
import { FC, ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { Step, useSteppedModal, ValidationItem } from './useSteppedModal'

export type SteppedModalProps = {
  onClose: () => void
  onFinalConfirm: () => Promise<void>
  submitting: boolean
  validation: ValidationItem[]
  canSkip?: boolean
  validationSummaryText: string
  confirmationSummaryText: string
  confirmTitleText: string
  confirmActionText: string
}

export const SteppedModal = (props: SteppedModalProps) => {
  const { activeStep, transitionToNextStep } = useSteppedModal(props)

  const ActiveComponent = activeStep?.component
  return (
    <ModalContextProvider isOpen>
      {ActiveComponent &&
        match(activeStep.wrapper)
          .with('MODAL', () => (
            <ActiveComponent {...activeStep.componentProps} />
          ))
          .with('DIALOG', () => (
            <InformationDialog
              activeStep={activeStep}
              closeOnCancel={activeStep.actions?.cancel.closeModal ?? true}
              onConfirm={transitionToNextStep}
              onClose={props.onClose}
            >
              <ActiveComponent {...activeStep.componentProps} />
            </InformationDialog>
          ))
          .exhaustive()}
    </ModalContextProvider>
  )
}

const InformationDialog: FC<{
  activeStep: Step
  children: ReactNode
  closeOnCancel: boolean
  onConfirm: () => void
  onClose: () => void
}> = ({ activeStep, children, closeOnCancel, onConfirm, onClose }) => {
  return (
    <SimpleDialogUI
      data-testid="schedule.confirmStart"
      title={activeStep.modalTitle}
      showCancelButton={activeStep.actions?.cancel?.canSkip}
      cancelButtonText={activeStep.actions?.cancel.text ?? 'Cancel'}
      closeOnCancel={closeOnCancel}
      closeOnSubmit={closeOnCancel}
      onClose={() => {
        activeStep.actions?.close
          ?.onAction()
          .catch(e => Sentry.captureException(e))
          .finally(onClose)
      }}
      onCancel={() => {
        activeStep.actions?.cancel
          .onAction()
          .catch(e => Sentry.captureException(e))
      }}
      confirmButtonText={activeStep.actions?.confirm.text ?? 'Save'}
      onConfirm={() => {
        activeStep.actions?.confirm
          .onAction()
          .then(() => onConfirm())
          .catch(e => {
            Sentry.captureException(e)
          })
      }}
    >
      {children}
    </SimpleDialogUI>
  )
}
