import * as entityLoaders from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders'
import * as commonEntityLoaders from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders'
import { useCallback } from 'react'
import { useNotifications } from 'lib/hooks/useNotifications'
import * as Sentry from '@sentry/react'
import { ApiData } from 'modules/Cube/communication/external/quotes.api.v1/adapters/quote.adapters.in'
import * as entitySaving from 'modules/Cube/communication/external/quotes.api.v1/ports/entitySaving/useSaveQuote'
import { v1ApiCustomer } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadAllCustomers'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  CorePortErrors,
  CubePortImplementationProp
} from 'modules/Cube/domain/cube.domain.types.ts'

type UseLoadQuoteEditor = () => (
  ctx: CubePortImplementationProp
) => (
  quoteId?: string
) => Promise<{ data: ApiData | null; error: CorePortErrors | null }>

export const useLoadQuoteEditor: UseLoadQuoteEditor = () => {
  const { displayNotification } = useNotifications()
  const quoteLoader = entityLoaders.useLoadQuote()
  const integrationsLoader = commonEntityLoaders.useLoadIntegrations()
  const productsLoader = commonEntityLoaders.useLoadProducts()
  const listPricesLoader = commonEntityLoaders.useLoadListPrices()
  const customerLoader = commonEntityLoaders.useLoadSingleCustomer()
  const allCustomersLoader = commonEntityLoaders.useLoadAllCustomers()
  const allProductsLoader = commonEntityLoaders.useLoadAllProducts()
  const enabledCurrenciesLoader = commonEntityLoaders.useLoadEnabledCurrencies()
  const quoteMutator = entitySaving.useSaveQuote()
  const userLoader = entityLoaders.useLoadUser()
  const contactsLoader = entityLoaders.useLoadContacts()

  const loadQuote = useCallback(
    (ctx: CubePortImplementationProp) =>
      async (
        quoteId?: string
      ): Promise<{
        data: Pick<ApiData, 'quote' | 'presentation'> | null
        error: CorePortErrors | null
      }> => {
        if (!quoteId) {
          const newQuote = await quoteMutator.create({
            customerId: ctx.defaultValues?.customerId,
            salesforceOpportunityId: ctx.defaultValues?.salesforceOpportunityId
          })

          if (!newQuote) {
            return {
              data: null,
              error: CorePortErrors.Other
            }
          }

          const loadedNewQuote = await quoteLoader(newQuote.id)

          if (!loadedNewQuote.data || loadedNewQuote.error) {
            return {
              data: null,
              error: loadedNewQuote.error || CorePortErrors.Other
            }
          }

          return {
            data: {
              quote: loadedNewQuote.data,
              presentation: newQuote.presentation
            },
            error: null
          }
        }

        const loadedQuote = await quoteLoader(quoteId)

        if (!loadedQuote.data || loadedQuote.error) {
          return {
            data: null,
            error: loadedQuote.error || CorePortErrors.Other
          }
        }

        return {
          data: {
            quote: loadedQuote.data,
            presentation: loadedQuote.data.presentation
          },
          error: null
        }
      },
    [quoteLoader, quoteMutator]
  )

  const load = useCallback(
    (ctx: CubePortImplementationProp) => async (quoteId?: string) => {
      try {
        const loadedQuote = await loadQuote(ctx)(quoteId)

        if (!loadedQuote.data || loadedQuote.error) {
          return {
            data: null,
            error: loadedQuote.error || CorePortErrors.Other
          }
        }

        const { quote, presentation } = loadedQuote.data

        const [
          integrationsLoaderData,
          customerLoaderData,
          listPricesLoaderData,
          allCustomersLoaderData,
          allProductsLoaderData,
          enabledCurrenciesData,
          createdByUser,
          contactsLoaderData
        ] = await Promise.all([
          integrationsLoader(),
          customerLoader({
            customerId: quote.customerId ?? ''
          }),
          listPricesLoader(),
          allCustomersLoader(),
          allProductsLoader(),
          enabledCurrenciesLoader(),
          userLoader(quote.createdBy),
          contactsLoader(quote.customerId)
        ])

        const pricesData =
          quote.phases
            ?.flatMap(({ prices }) => prices)
            .map(price => ({
              ...price,
              currency: quote.currency as Currency
            })) ?? []
        const productsLoaderData = await productsLoader({
          prices: pricesData
        })

        return {
          data: {
            quote,
            presentation,
            prices: pricesData,
            listPrices: listPricesLoaderData,
            products: [
              ...productsLoaderData.products,
              ...allProductsLoaderData
            ],
            customers: [...allCustomersLoaderData, customerLoaderData].filter(
              (
                c
              ): c is v1ApiCustomer & {
                aliases?: { value: string; id: string; label?: string }[]
              } => Boolean(c)
            ),
            integrations: integrationsLoaderData.integrations,
            currencies: enabledCurrenciesData,
            merchantBranding: quote.merchantBranding,
            createdByUser,
            contacts: contactsLoaderData
          },
          error: null
        }
      } catch (e) {
        displayNotification("Couldn't load quote", {
          type: 'error'
        })

        Sentry.captureException(e)

        return {
          data: null,
          error: CorePortErrors.Other
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allCustomersLoader,
      allProductsLoader,
      enabledCurrenciesLoader,
      customerLoader,
      displayNotification,
      integrationsLoader,
      listPricesLoader,
      loadQuote,
      productsLoader,
      userLoader,
      contactsLoader
    ]
  )

  return load
}
