import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Link, LinkProps } from 'react-router-dom'

export const OutstandingInvoicesItem = ({
  firstColumnText,
  secondColumnText,
  thirdColumnText,
  indicatorIconColor,
  to
}: {
  firstColumnText: string
  secondColumnText: string
  thirdColumnText: string
  indicatorIconColor: string
  to: LinkProps['to']
}) => {
  return (
    <Grid
      as={Link}
      to={to}
      target="_blank"
      templateColumns="repeat(2, 1fr)"
      p={2}
      borderBottom={`1px solid ${GreyGrey30}`}
      _last={{
        border: 'none'
      }}
      _hover={{
        backgroundColor: 'gray.10'
      }}
    >
      <Flex alignItems="center" gap={3}>
        <Box
          w={2}
          h={2}
          borderRadius="full"
          backgroundColor={indicatorIconColor}
        />
        <Text {...Lato13Regular} color={GreyGrey80}>
          {firstColumnText}
        </Text>
      </Flex>

      <Grid templateColumns="repeat(2, 1fr)">
        <Text {...Lato13Regular} color={GreyGrey70} pl={6}>
          {secondColumnText}
        </Text>
        <Text
          {...Lato13Bold}
          color={GreyGrey70}
          textAlign="right"
          fontWeight="600"
        >
          {thirdColumnText}
        </Text>
      </Grid>
    </Grid>
  )
}
