import { Box, Flex, Text } from '@chakra-ui/react'
import { GreyGrey70, Lato12UppercaseBold } from '@sequencehq/design-tokens'
import { AvailableServices } from 'modules/Integrations/domain'
import { FeaturedIntegrationCard } from 'modules/Integrations/view/integrationsBrowserModal/content/home/FeaturedIntegrationCard'
import { useFeaturedIntegrations } from 'modules/Integrations/view/integrationsBrowserModal/content/home/useFeaturedIntegrations'

type FeaturedIntegrationsProps = {
  onSelectIntegration: (integration: AvailableServices) => void
}

export const FeaturedIntegrations = (props: FeaturedIntegrationsProps) => {
  const hook = useFeaturedIntegrations(props)

  return (
    <Flex padding="12px" width="100%" justifyContent="center">
      <Box width="100%">
        <Text
          {...Lato12UppercaseBold}
          color={GreyGrey70}
          textTransform="uppercase"
          marginBottom="16px"
        >
          Featured integrations
        </Text>
        <Flex gap="16px" flexDirection="column">
          {hook.featuredIntegrations.map(int => (
            <FeaturedIntegrationCard key={int.id} {...int} />
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}
