import { CustomerContactFields } from '../view/forms/ContactModalForm/useContactModalForm'
import { CustomerContactsDomainInterfaceData } from './CustomerContacts.domain'

export const INITIAL_DATA: CustomerContactsDomainInterfaceData = {
  customerId: '',
  contacts: []
}

export const INITIAL_FORM_DATA: CustomerContactFields = {
  name: '',
  email: '',
  billingPreference: 'STANDARD'
}
