import { Box, Flex } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  PercentageField,
  PriceField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import { AvailableStandardFrequency } from 'modules/Cube/domain/cube.domain.types'
import { useLinearPriceForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/LinearPrice/useLinearPriceForm'
import { UsageMetricParameterFields } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/UsageMetricParameterFields'
import { LinearPriceType } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const LinearPriceForm = () => {
  const { fieldsConfig, currency, formData, fieldsAreHidden } =
    useLinearPriceForm()

  return (
    <Box paddingBottom={fieldsAreHidden ? '0px' : '8px'}>
      {!fieldsConfig.billingFrequency.hidden && (
        <HorizontalSelectorField
          label="Frequency"
          data-testid="common-field-billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
      )}
      {!fieldsConfig.usageMetricId.hidden && (
        <SelectFieldWithActions
          label="Usage metric"
          data-testid="linear.usageMetric"
          placeholder="Select usage metric"
          value={fieldsConfig.usageMetricId.value}
          options={fieldsConfig.usageMetricId.options}
          onChange={fieldsConfig.usageMetricId.onChange}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
      )}
      <UsageMetricParameterFields
        fieldsConfig={fieldsConfig}
        usageMetricId={fieldsConfig.usageMetricId.value}
      ></UsageMetricParameterFields>
      <HorizontalSelectorField
        label="Type"
        data-testid="linear.linearPriceType"
        value={fieldsConfig.linearPriceType.value}
        options={fieldsConfig.linearPriceType.options}
        onChange={newValue =>
          fieldsConfig.linearPriceType.onChange(newValue as LinearPriceType)
        }
        validationErrors={fieldsConfig.linearPriceType.validationErrors}
        isDisabled={fieldsConfig.linearPriceType.disabled}
      />
      {formData.linearPriceType === 'FIXED' && (
        <PriceField
          label="Price per unit"
          data-testid="linear.price"
          value={fieldsConfig.price.value}
          onChange={newValue => fieldsConfig.price.onChange(newValue)}
          validationErrors={fieldsConfig.price.validationErrors}
          currency={currency}
          isDisabled={fieldsConfig.price.disabled}
        />
      )}
      {fieldsConfig.linearPriceType.value === 'PERCENTAGE' && (
        <>
          <PercentageField
            label="Percentage"
            data-testid="linear.percentage"
            value={fieldsConfig.percentage.value}
            onChange={newValue => fieldsConfig.percentage.onChange(newValue)}
            validationErrors={fieldsConfig.percentage.validationErrors}
            isDisabled={fieldsConfig.percentage.disabled}
          />
          <Flex flexDirection="row">
            <PriceField
              label="Floor fee (optional)"
              data-testid="linear.minPrice"
              value={fieldsConfig.minPrice.value}
              onChange={newValue => fieldsConfig.minPrice.onChange(newValue)}
              validationErrors={fieldsConfig.minPrice.validationErrors}
              currency={currency}
              isDisabled={fieldsConfig.minPrice.disabled}
              styles={
                fieldsAreHidden ? { wrapper: { marginBottom: '0px' } } : {}
              }
            />
            <Box width="16px"></Box>
            <PriceField
              label="Fee cap (optional)"
              data-testid="linear.maxPrice"
              value={fieldsConfig.maxPrice.value}
              onChange={newValue => fieldsConfig.maxPrice.onChange(newValue)}
              validationErrors={fieldsConfig.maxPrice.validationErrors}
              currency={currency}
              isDisabled={fieldsConfig.maxPrice.disabled}
              styles={
                fieldsAreHidden ? { wrapper: { marginBottom: '0px' } } : {}
              }
            />
          </Flex>
        </>
      )}
    </Box>
  )
}

export const HiddenFieldsForm = () => {
  const { fieldsConfig } = useLinearPriceForm()

  return (
    <>
      <HorizontalSelectorField
        label="Frequency"
        data-testid="common-field-billingFrequency"
        value={fieldsConfig.billingFrequency.value}
        options={fieldsConfig.billingFrequency.options}
        onChange={newValue =>
          fieldsConfig.billingFrequency.onChange(
            newValue as AvailableStandardFrequency
          )
        }
        validationErrors={fieldsConfig.billingFrequency.validationErrors}
        isDisabled={fieldsConfig.billingFrequency.disabled}
      />
      <SelectFieldWithActions
        label="Usage metric"
        data-testid="linear.usageMetric"
        placeholder="Select usage metric"
        value={fieldsConfig.usageMetricId.value}
        options={fieldsConfig.usageMetricId.options}
        onChange={fieldsConfig.usageMetricId.onChange}
        validationErrors={fieldsConfig.usageMetricId.validationErrors}
        isDisabled={fieldsConfig.usageMetricId.disabled}
        onAddNew={fieldsConfig.usageMetricId.onAddNew}
      />
    </>
  )
}
