import { useCallback } from 'react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

export type v1ApiContact =
  Dashboardv20240509Api.GetCustomerContacts.CustomerContact

type UseLoadContacts = () => (
  customerId: string | undefined
) => Promise<v1ApiContact[]>

export const useLoadContacts: UseLoadContacts = () => {
  const load = useCallback(async (customerId: string | undefined) => {
    if (!customerId) {
      return []
    }

    const response = await dashboardv20240509Client.getCustomerContacts({
      customerId
    })

    if (response.error || !response.data) {
      throw new Error('Unable to load contacts')
    }

    return response.data.items
  }, [])

  return load
}
