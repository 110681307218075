import type { CountriesAlpha2 } from '@sequencehq/api/utils'
import {
  countriesAlpha2,
  statesWithNamesForCountry
} from '@sequencehq/api/commonEnums'

export const taxStatusOptions = {
  TAXED: 'Taxed',
  TAX_EXEMPT: 'Tax exempt',
  REVERSE_CHARGED: 'Reverse charged'
}

export const getStateOptions = (country?: CountriesAlpha2) => {
  const countryStates = country ? statesWithNamesForCountry(country) : []

  return Object.entries(countryStates).map(([value, label]) => ({
    value,
    label
  }))
}

export const countriesOptions = Object.entries(countriesAlpha2).map(
  ([value, label]) => ({
    value,
    label
  })
)
