import { v1PortImplementation } from 'modules/Integrations/communication'
import {
  IntegrationsDomainInterface,
  IntegrationsPortErrors,
  useIntegrationsDomain
} from 'modules/Integrations/domain'
import { useEffect, useMemo, useState } from 'react'

enum LoadingStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  RELOADING = 'RELOADING',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

type UseIntegrationsRoot = () => {
  domain: IntegrationsDomainInterface
  error: IntegrationsPortErrors | null
  ready: boolean
}

export const useIntegrationsRoot: UseIntegrationsRoot = () => {
  const [error, setError] = useState<IntegrationsPortErrors | null>(null)
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.UNINITIALIZED
  )

  const portImplementation = useMemo(() => {
    return v1PortImplementation({
      configuration: {
        enableFeature: true
      }
    })
  }, [])

  const domain = useIntegrationsDomain({
    ports: portImplementation
  })

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.LOADING ||
      loadingStatus === LoadingStatus.RELOADING ||
      loadingStatus === LoadingStatus.ERROR ||
      domain.queries.rawData.editor.loaded
    ) {
      return
    }

    setLoadingStatus(
      loadingStatus === LoadingStatus.READY
        ? LoadingStatus.RELOADING
        : LoadingStatus.LOADING
    )

    void domain.mutators.external.in.load().then(res => {
      if (res.error) {
        setLoadingStatus(LoadingStatus.ERROR)
        setError(res.error)
        return
      }

      setLoadingStatus(LoadingStatus.READY)
      setError(null)
    })
  }, [
    domain.mutators.external.in,
    loadingStatus,
    domain.queries.rawData.editor.loaded
  ])

  return {
    domain,
    error,
    ready: domain.queries.rawData.editor.loaded
  }
}
