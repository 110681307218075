import { Box, Center, Spinner } from '@chakra-ui/react'
import { GreyGrey40 } from '@sequencehq/design-tokens'

export const WidgetLoading = () => {
  return (
    <Box
      width="390px"
      minHeight="291px"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      border={`1px solid ${GreyGrey40}`}
      borderRadius="lg"
      p={4}
    >
      <Center width="100%" height="100%">
        <Spinner color="gray.100" />
      </Center>
    </Box>
  )
}
