import {
  AvailableStandardFrequency,
  SeatBasedProrationStrategy,
  SeatOveragesBillingFrequency
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types.ts'

export const overagesFrequenciesOptions: {
  value: SeatOveragesBillingFrequency
  label: string
  description: string
}[] = [
  {
    value: 'MONTHLY',
    label: 'Monthly',
    description:
      'Overages will be charged at the end of every month where seats are added'
  },
  {
    value: 'QUARTERLY',
    label: 'Quarterly',
    description:
      'Overages will be charged at the end of every quarter where seats are added'
  },
  {
    value: 'YEARLY',
    label: 'Annually',
    description:
      'Overages will be charged at the end of every year where seats are added'
  }
]

export const prorationStrategyOptions: {
  value: SeatBasedProrationStrategy
  label: string
  description: string
}[] = [
  {
    value: 'USE_MAXIMUM',
    label: 'Charge full amount (no proration)',
    description:
      "'Charge the full monthly price for any seats added in the current billing period.'"
  },
  {
    value: 'PRORATE_INCREMENTS',
    label: 'Prorate added seats only (recommended)',
    description:
      'Charge a prorated fee for seats added in the billing period. Removed seats are charged the full fee.'
  },
  {
    value: 'PRORATE_ALL_CHANGES',
    label: 'Prorate added and removed seats',
    description:
      'Charge a prorated fee for any added and removed seats in the billing period.'
  }
]

export const getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency = (
  selectedPriceFrequency: AvailableStandardFrequency
) => {
  if (selectedPriceFrequency === 'MONTHLY') {
    return []
  }

  return overagesFrequenciesOptions
    .filter(
      ({ value }) => value === 'MONTHLY' || value === selectedPriceFrequency
    )
    .map(option =>
      option.value === 'MONTHLY'
        ? option
        : {
            ...option,
            label: 'End of the billing period',
            description:
              'Overages will be charged at the end of the selected price billing frequency'
          }
    )
}
