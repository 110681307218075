import { RouteObject } from 'react-router-dom'
import CreditNoteDetailRoute from './CreditNoteDetailRoute'
import CreditNotesRoute from './CreditNotesRoute'

export const creditNotesRoutes = (basePath = 'credit-notes'): RouteObject[] => [
  {
    path: basePath,
    children: [
      {
        index: true,
        element: <CreditNotesRoute />
      },
      { path: ':creditNoteId', element: <CreditNoteDetailRoute /> }
    ]
  }
]
