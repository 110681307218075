import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { payRouter } from 'modules/PaymentsApp/paymentsApp.router'
import { RouterProvider } from 'react-router-dom'

const PaymentsAppBase = () => {
  return <RouterProvider router={payRouter} />
}

/**
 * Initialise the payments app with the anonymous launch darkly user. This
 * wont grant us access to user targetted flags, but it will still allow
 * us to make use of features flags that are globally enabled/disabled for
 * all users in the current environment.
 */
export const PaymentsApp = withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
  context: {
    kind: 'user',
    key: import.meta.env.VITE_LAUNCHDARKLY_ANONYMOUS_USER_ID
  },
  options: {
    disableSyncEventPost: true,
    hash: import.meta.env.VITE_LAUNCHDARKLY_ANONYMOUS_USER_HASH
  }
})(PaymentsAppBase)
