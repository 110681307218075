import { DeleteUserForm } from 'components/DeleteUser/DeleteUserForm'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { useDeleteUsersByIdMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'

export interface DeleteUserProps {
  id: string
  email: string
}

export type DeleteUserFormMutation = ExtractMutationParams<
  typeof useDeleteUsersByIdMutation
>

export const DeleteUserModal = createFormModal<
  DeleteUserFormMutation,
  DeleteUserProps
>({
  FormComponent: DeleteUserForm,
  useMutation: useDeleteUsersByIdMutation,
  modalTitleFromProps: () => 'Remove user',
  successMessage: 'User removed',
  submitTitle: 'Remove',
  formId: 'deleteUserForm',
  successToast: (response, inputs) => (
    <Toast
      title="User removed"
      type="success"
      description={`User ${inputs.email} access revoked`}
      isClosable={true}
    ></Toast>
  )
})
