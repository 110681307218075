import { parse } from 'tldts'
import { Environment } from 'modules/LoginApp/types'

export const isEmailDisallowed = (email: string) => {
  const disallowedDomains = [
    'gmail',
    'aol',
    'yahoo',
    'icloud',
    'hotmail',
    'msn',
    'comcast',
    'live',
    'outlook',
    'att',
    'earthlink',
    'me',
    'mac',
    'sbcglobal',
    'verizon',
    'ig',
    'mail',
    'hey',
    'laposte',
    'wanadoo',
    'googlemail',
    'orange',
    'rediffmail',
    'uol',
    'bol',
    'free',
    'gmx',
    'yandex',
    'ymail',
    'libero'
  ]

  const domainWithoutSuffix = parse(email).domainWithoutSuffix

  if (!domainWithoutSuffix) {
    return false
  }

  return disallowedDomains.includes(domainWithoutSuffix)
}

export const getDashboardDomain = (environment?: Environment) => {
  switch (environment) {
    case 'production':
      return 'dashboard.sequencehq.com'
    case 'sandbox':
      return 'dashboard.sandbox.sequencehq.com'
    case 'dev':
    default:
      return 'dashboard.seqhq.io'
  }
}
