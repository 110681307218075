import { Box, Flex, Image } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import quickbooksCover from './quickbooksCover.png'

export const QuickBooksIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={quickbooksCover}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Integrate Sequence with QuickBooks Online to automate your invoicing and
        accounting. Import customers directly from QuickBooks to streamline
        onboarding. Finalized invoices and credit notes in Sequence are
        seamlessly updated in QuickBooks, reducing manual data entry and better
        accuracy.
      </Box>
    </Flex>
  )
}
