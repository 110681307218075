import { Flex, Text } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  NumberField,
  PercentageField,
  PriceField,
  TextField
} from '@sequencehq/core-components'
import { useLineItemEditorForm } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/components/LineItemEditorForm/useLineItemEditorForm.ts'
import { GreyGrey60, Lato13Regular } from '@sequencehq/design-tokens'
import { ExternalLedgerForm } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/components/ExternalLedgerForm/ExternalLedgerForm'
import { type LineItemEditorLineItem } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditor'

export const LineItemEditorForm = ({
  showTaxRate
}: {
  showTaxRate: boolean
}) => {
  const { data, fieldsConfig } = useLineItemEditorForm()

  return (
    <Flex direction="column" padding="24px">
      <HorizontalSelectorField
        label="Type"
        value={fieldsConfig.itemType.value}
        options={fieldsConfig.itemType.options}
        validationErrors={[]}
        onChange={(value: string) =>
          fieldsConfig.itemType.onChange(
            value as LineItemEditorLineItem['itemType']
          )
        }
      />

      <TextField
        data-testid="lineItemEditor.name"
        label="Name"
        value={fieldsConfig.title.value}
        onChange={fieldsConfig.title.onChange}
        validationErrors={fieldsConfig.title.validationErrors}
      />

      <TextField
        data-testid="lineItemEditor.description"
        label="Description"
        value={fieldsConfig.description.value}
        onChange={fieldsConfig.description.onChange}
        validationErrors={fieldsConfig.description.validationErrors}
      />

      <HorizontalSelectorField
        data-testid="lineItemEditor.rate"
        label="Rate"
        value={fieldsConfig.rateType.value}
        options={fieldsConfig.rateType.options}
        validationErrors={[]}
        onChange={(value: string) =>
          fieldsConfig.rateType.onChange(
            value as LineItemEditorLineItem['rateType']
          )
        }
      />

      {fieldsConfig.rateType.value === 'FIXED' && (
        <NumberField
          label="Quantity"
          value={fieldsConfig.quantity.value}
          onChange={newValue => fieldsConfig.quantity.onChange(newValue)}
          validationErrors={fieldsConfig.quantity.validationErrors}
          isDisabled={fieldsConfig.quantity.disabled}
          description={
            fieldsConfig.quantity.disabled ? (
              <Text {...Lato13Regular} color={GreyGrey60}>
                {fieldsConfig.quantity.description}
              </Text>
            ) : null
          }
        />
      )}

      {fieldsConfig.rateType.value === 'PERCENTAGE' && (
        <PriceField
          label="Quantity"
          value={fieldsConfig.quantity.value}
          onChange={newValue => fieldsConfig.quantity.onChange(newValue)}
          validationErrors={fieldsConfig.quantity.validationErrors}
          currency={data.currency}
          placeholder="0.00"
        />
      )}

      {fieldsConfig.rateType.value === 'FIXED' && (
        <PriceField
          data-testid="lineItemEditor.amount"
          label="Amount"
          value={fieldsConfig.rate.value}
          onChange={newValue => fieldsConfig.rate.onChange(newValue)}
          validationErrors={fieldsConfig.rate.validationErrors}
          currency={data.currency}
          placeholder="0.00"
        />
      )}

      {fieldsConfig.rateType.value === 'PERCENTAGE' && (
        <PercentageField
          label="Amount"
          value={fieldsConfig.rate.value}
          onChange={newValue => fieldsConfig.rate.onChange(newValue)}
          validationErrors={fieldsConfig.rate.validationErrors}
          placeholder="0"
        />
      )}

      {showTaxRate ? (
        <PercentageField
          data-testid="lineItemEditor.tax"
          label="Tax"
          value={fieldsConfig.taxRate.value}
          onChange={newValue => fieldsConfig.taxRate.onChange(newValue)}
          validationErrors={fieldsConfig.taxRate.validationErrors}
        />
      ) : null}

      <ExternalLedgerForm />
    </Flex>
  )
}
