import { Inspector, Switch } from '@sequencehq/core-components'
import {
  AccordionButton,
  AccordionIcon,
  Accordion,
  AccordionItem,
  Box,
  Flex,
  AccordionPanel
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { Lato14Bold, Lato14Regular } from '@sequencehq/design-tokens'
import { HealthStatusBadge } from 'Integrations/view/integrationManagement/DefaultIntegrationManagementInspector'
import { useXeroConfigurationManagement } from '../XeroConfigurationManagement/useXeroConfigurationManagement'
import { ConfigurationManagementProps } from 'Integrations/domain'

export const XeroIntegrationManagementInspector: React.FC<
  ConfigurationManagementProps
> = props => {
  const { fieldsConfig } = useXeroConfigurationManagement(props)

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <Inspector.IconItem
          content={
            <Inspector.Content padding="0px">
              <Accordion
                allowMultiple={true}
                defaultIndex={[0, 1]}
                mt="8px"
                px="8px"
              >
                <AccordionItem border="none">
                  <AccordionButton px="8px">
                    <Box
                      as={Flex}
                      flex="1"
                      gap={1}
                      textAlign="left"
                      borderRadius="8px"
                      {...Lato14Bold}
                    >
                      <span>Status</span>
                      <AccordionIcon />
                    </Box>
                  </AccordionButton>
                  <AccordionPanel
                    pb={4}
                    px={2}
                    as={Flex}
                    gap={4}
                    flexDirection="column"
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      {...Lato14Regular}
                    >
                      <p>Health</p>
                      <HealthStatusBadge integration={props.integration} />
                    </Flex>
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      {...Lato14Regular}
                    >
                      <p>Active</p>
                      <Switch
                        data-testid="inspector.switch.importCustomerWorkflow"
                        onChange={fieldsConfig.importCustomerWorkflow.onChange}
                        checked={fieldsConfig.importCustomerWorkflow.value}
                      />
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Inspector.Content>
          }
          icon={<InformationCircleIcon height={16} />}
        />
      </Inspector.ItemGroup>
    </Inspector>
  )
}
