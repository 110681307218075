export const GoogleMailIcon = ({
  height = 16,
  width = 22,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_164_23823)">
        <path
          d="M1.78786 16H5.1818V7.75758L0.333313 4.12121V14.5455C0.333313 15.3503 0.985434 16 1.78786 16Z"
          fill="#4285F4"
        />
        <path
          d="M16.8182 16H20.2121C21.017 16 21.6667 15.3479 21.6667 14.5455V4.12121L16.8182 7.75758"
          fill="#34A853"
        />
        <path
          d="M16.8182 1.45454V7.75757L21.6667 4.12121V2.18182C21.6667 0.383029 19.6133 -0.642425 18.1758 0.436362"
          fill="#FBBC04"
        />
        <path
          d="M5.18182 7.75757V1.45454L11 5.81818L16.8182 1.45454V7.75757L11 12.1212"
          fill="#EA4335"
        />
        <path
          d="M0.333313 2.18182V4.12121L5.1818 7.75757V1.45454L3.82422 0.436362C2.38422 -0.642425 0.333313 0.383029 0.333313 2.18182Z"
          fill="#C5221F"
        />
      </g>
      <defs>
        <clipPath id="clip0_164_23823">
          <rect
            width="21.3333"
            height="16"
            fill="white"
            transform="translate(0.333313)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
