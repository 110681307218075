import {
  CubeReducerState,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'

export const atLeastOnePhase = (
  prevState: CubeReducerState
): ValidationResult[] | null => {
  if (prevState.data.common.phaseIds.length) {
    return null
  }

  return [
    {
      message: 'At least one phase is required'
    }
  ]
}
