import { Currency } from '@sequencehq/core-models'
import { greaterThan, required } from '@sequencehq/validation'
import { FormFields, useForm } from '@sequencehq/utils'
import { useCallback, useMemo } from 'react'
import {
  AvailableStandardFrequency,
  BillingType,
  ListPriceEditorFormData
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { defaultAvailableFrequenciesOptions } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/constants'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'

type StandardPriceFields = ListPriceEditorFormData['STANDARD']
type UseStandardPriceForm = () => {
  fieldsConfig: FormFields<StandardPriceFields>
  currency: Currency
}

const standardPriceFrequencies: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

const availableBillingTypeOptions: { value: BillingType; label: string }[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export const useStandardPriceForm: UseStandardPriceForm = () => {
  const listPriceEditorContext = useListPriceEditorContext()

  const checkIfStandardFieldDisabledByDomain = useCallback(
    ({ property }: { property: keyof StandardPriceFields }) => {
      return listPriceEditorContext.functions.fieldIsDisabled(
        `STANDARD.${property}`
      )
    },
    [listPriceEditorContext.functions]
  )

  const availableFrequencies = useMemo(() => {
    return standardPriceFrequencies.filter(frequency => {
      return listPriceEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [listPriceEditorContext.configuration.availableStandardFrequencies])

  const { fields } = useForm<StandardPriceFields>({
    value: listPriceEditorContext.data.formData.STANDARD,
    showValidationErrors: listPriceEditorContext.editor.showValidationErrors,
    fieldConfiguration: [
      {
        property: 'billingFrequency',
        validation: [required],
        disabled: checkIfStandardFieldDisabledByDomain,
        options: availableFrequencies
      },
      {
        property: 'billingType',
        validation: [required],
        disabled: checkIfStandardFieldDisabledByDomain,
        options: availableBillingTypeOptions
      },
      {
        property: 'price',
        validation: [required, greaterThan(0, "Price can't be zero")],
        disabled: checkIfStandardFieldDisabledByDomain
      }
    ],
    onChange: newData =>
      listPriceEditorContext.functions.updateFormData({
        STANDARD: newData
      }),
    onValidationStateChange: isValid =>
      listPriceEditorContext.functions.updateEditor({
        formsValid: {
          STANDARD: isValid
        }
      })
  })

  return {
    fieldsConfig: fields,
    currency: listPriceEditorContext.data.formData.common.currency
  }
}
