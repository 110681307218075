import { createFormModal } from 'components/Form/createFormModal'
import SendTestInvoiceEmailForm from 'components/SendTestEmail/SendTestInvoiceEmailForm'
import {
  SendTestInvoiceEmailFormMutation,
  SendTestEmailFormProps
} from 'components/SendTestEmail/types'
import { Toast } from '@sequencehq/core-components'
import { usePostInvoicesByIdSendByTestEmailMutation } from 'features/api'

const SendTestInvoiceEmailModal = createFormModal<
  SendTestInvoiceEmailFormMutation,
  SendTestEmailFormProps
>({
  FormComponent: SendTestInvoiceEmailForm,
  useMutation: usePostInvoicesByIdSendByTestEmailMutation,
  modalTitle: 'Send Test Invoice Email',
  successMessage: 'Email sent',
  submitTitle: 'Send Test',
  formId: 'sendTestInvoiceEmailForm',
  successToast: () => (
    <Toast type="success" title={`Test email sent`} isClosable={true}></Toast>
  )
})

export default SendTestInvoiceEmailModal
