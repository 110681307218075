import { UsageMetricModel } from '@sequencehq/core-models'
import { CreateUsageMetricDrawerProps } from 'components/CreateUsageMetric/CreateUsageMetricDrawer'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type UseUsageMetricsCreateUsageMetricDrawerConnector =
  () => CreateUsageMetricDrawerProps

export const useUsageMetricsCreateUsageMetricDrawerConnector: UseUsageMetricsCreateUsageMetricDrawerConnector =
  () => {
    const navigate = useNavigate()

    const onSuccess = useCallback(
      (usageMetric: UsageMetricModel) => {
        navigate(`/usage-metrics/${usageMetric.id}`)
      },
      [navigate]
    )

    const onCancel = useCallback(() => {
      navigate('..')
    }, [navigate])

    return {
      onSuccess,
      onCancel
    }
  }
