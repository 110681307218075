import { Box, Flex, Icon } from '@chakra-ui/react'
import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import {
  GreyGrey30,
  IndigoIndigo50,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { Section } from 'modules/Cube/view/common/atomic/Section'
import { Tooltip } from '@sequencehq/core-components'
import { Phase } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/Phase'
import { usePricingPhases } from 'modules/Cube/view/common/widgets/PricingPhases/usePhasedPricingWidget'

export const PhasedPricingWidget = () => {
  const pricingPhasesHook = usePricingPhases()

  return (
    <Section width="794px">
      {pricingPhasesHook.phases.map(phase => (
        <Phase
          key={phase.id}
          phaseId={phase.id}
          expanded={phase.expanded}
          focusDurationOnLoad={phase.focusDurationOnLoad}
          onExpand={pricingPhasesHook.updatePhaseExpanded(phase.id)}
        />
      ))}
      <Box width="100%" height="1px" background={GreyGrey30}></Box>

      <Flex
        onClick={pricingPhasesHook.addPhase.handler}
        padding="18px 16px"
        {...Lato13Bold}
        color={IndigoIndigo50}
        cursor={!pricingPhasesHook.addPhase.disabled ? 'pointer' : 'default'}
        opacity={pricingPhasesHook.addPhase.disabled ? 0.5 : 1}
        _hover={{
          opacity: !pricingPhasesHook.addPhase.disabled ? 0.8 : undefined
        }}
      >
        <Tooltip
          placement="top"
          label={pricingPhasesHook.addPhase.disabledReason}
        >
          <Flex gap="8px" alignItems="center">
            <Icon
              as={PlusIcon}
              height="16px"
              width="16px"
              color={IndigoIndigo50}
            />
            Add new phase
          </Flex>
        </Tooltip>
      </Flex>
    </Section>
  )
}
