import { CubeReducerState, ListPrice, Product } from '../../cube.domain.types'
import { getSelectedCurrency } from './getSelectedCurrency'

export const getAvailableListPrices = (
  prevState: CubeReducerState
): Record<Product['id'], ListPrice[]> => {
  const selectedCurrency = getSelectedCurrency(prevState)
  if (!selectedCurrency) {
    return prevState.data.listPrices
  }

  const filteredListPrices = Object.entries(prevState.data.listPrices).reduce(
    (acc, [productId, listPrices]) => ({
      ...acc,
      [productId]: listPrices.filter(
        listPrice => listPrice.currency === selectedCurrency
      )
    }),
    {}
  )

  return filteredListPrices
}
