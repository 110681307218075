import { INITIAL_PRICING_EDITOR_STATE } from './pricingEditor.constants'
import {
  PricingEditorDomainInterface,
  PricingEditorPortImplementation,
  PricingEditorReducerState,
  RecursivePartial
} from './pricingEditor.domain.types'
import { pricingEditorReducer } from './pricingEditor.reducer'
import { useCallback, useMemo, useReducer } from 'react'

type UsePricingEditorDomain = (props: {
  ports: PricingEditorPortImplementation
  afterSave?: (data: { price: any }) => void
}) => PricingEditorDomainInterface

export const usePricingEditorDomain: UsePricingEditorDomain = props => {
  const [state, dispatch] = useReducer(
    pricingEditorReducer,
    INITIAL_PRICING_EDITOR_STATE
  )

  const queries = useMemo(() => {
    return {
      ...state.queries,
      rawData: {
        configuration: state.configuration,
        data: state.data,
        editor: state.editor,
        initialData: state.initialData
      }
    }
  }, [state])

  const load = useCallback(async () => {
    const response = await props.ports.in.load()

    if (!response.data) {
      return response
    }

    dispatch({
      type: 'load',
      payload: response.data
    })
    return response
  }, [props.ports.in])

  const updateEditor = useCallback(
    (editorSettings: Partial<PricingEditorReducerState['editor']>) => {
      dispatch({
        type: 'updateEditor',
        payload: editorSettings
      })
    },
    []
  )

  const updatePricingEditorData = useCallback(
    (
      newData: RecursivePartial<
        PricingEditorReducerState['data']['pricingEditorData']
      >
    ) => {
      dispatch({
        type: 'updatePricingEditorData',
        payload: newData
      })
    },
    []
  )

  const updateConfiguration = useCallback(
    (configuration: Partial<PricingEditorReducerState['configuration']>) => {
      dispatch({
        type: 'updateConfiguration',
        payload: configuration
      })
    },
    []
  )

  const updateProduct = useCallback(
    (newData: Partial<PricingEditorReducerState['data']['product']>) => {
      dispatch({
        type: 'updateProduct',
        payload: newData
      })
    },
    []
  )

  const save = useCallback(async () => {
    if (!state.editor.valid) {
      dispatch({
        type: 'updateEditor',
        payload: {
          showValidationErrors: true
        }
      })
      return
    }

    const result = await props.ports.out.save(queries)

    if (props.afterSave && result.success) {
      props.afterSave(result.data)
    }
  }, [props, queries, state.editor.valid])

  return {
    queries,
    mutators: {
      external: {
        in: {
          load
        },
        out: {
          save
        }
      },
      common: {
        updateConfiguration,
        updateEditor,
        updateProduct,
        updatePricingEditorData
      }
    }
  }
}
