import { Duration, add, intervalToDuration } from './dateFnsPassthrough'
import { equals } from 'lodash/fp'
import { match } from 'ts-pattern'

/**
 * A simple comparison function which will check that two
 * (string) dates are in the same year.
 * @param date1
 * @param param1
 * @returns
 */
export const datesInSameYear =
  (
    date1: string,
    {
      localDate = true
    }: {
      localDate?: boolean
    } = {}
  ) =>
  (date2: string): boolean => {
    if (!date1 || !date2) {
      return false
    }

    try {
      return (
        new Date(
          Date.parse(date1 + (localDate ? 'T00:00:00.000Z' : ''))
        ).getFullYear() ===
        new Date(
          Date.parse(date2 + (localDate ? 'T00:00:00.000Z' : ''))
        ).getFullYear()
      )
    } catch (e) {
      return false
    }
  }

/**
 * Function to add two Durations together and return another Duration that is suitable for formatting.
 * Useful because date-fns/formatDuration will render { years: 2, months: 12 } as "2 years 12 months",
 * rather than the more reasonable "3 years".
 *
 * SOURCE: https://github.com/date-fns/date-fns/issues/2253
 */
export const addDurations = (
  durationLeft: Duration,
  durationRight: Duration
): Duration => {
  const baseDate = new Date(0)

  return intervalToDuration({
    start: baseDate,
    end: add(add(baseDate, durationLeft), durationRight)
  })
}

export function normalizeDuration(duration: Duration): Duration {
  const additionalYears = Math.floor((duration.months ?? 0) / 12)

  return {
    years: (duration.years ?? 0) + additionalYears,
    months: (duration.months ?? 0) % 12,
    days: duration.days ?? 0
  }
}

export function durationsMatch(
  duration1: Duration,
  duration2: Duration
): boolean {
  return equals(normalizeDuration(duration1), normalizeDuration(duration2))
}

export const isoTimestampIgnoringTime = (date: Date, type: 'start' | 'end') => {
  const dd = date.getDate().toString().padStart(2, '0')
  const mm = (date.getMonth() + 1).toString().padStart(2, '0')
  const yyyy = date.getFullYear()

  return match(type)
    .with('start', () => `${yyyy}-${mm}-${dd}T00:00:00.000Z`)
    .with('end', () => `${yyyy}-${mm}-${dd}T23:59:59.999Z`)
    .exhaustive()
}
