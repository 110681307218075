import {
  AvailableServices,
  IntegrationFeatureAvailable,
  IntegrationFeatureDisabledReason,
  IntegrationsReducerState,
  ResolvedIntegration,
  ResolvedIntegrations
} from 'modules/Integrations/domain/integrations.domain.types'
import invariant from 'tiny-invariant'

export const getConnectAvailable =
  (ctx: {
    resolvedIntegrations: ResolvedIntegrations
    activeIntegrations: ResolvedIntegration[]
  }) =>
  (service: AvailableServices) =>
  (prevState: IntegrationsReducerState): IntegrationFeatureAvailable => {
    const integrationConfig = ctx.resolvedIntegrations[service]

    invariant(integrationConfig, 'Integration not found')

    const activeIntegrationConfig = ctx.activeIntegrations.find(
      int => int.id === service
    )

    if (activeIntegrationConfig) {
      return {
        available: {
          visible: false,
          enabled: false
        },
        reasons: [IntegrationFeatureDisabledReason.IntegrationActive]
      }
    }
    const featureVisible =
      !integrationConfig.features.isDocumentationOnly &&
      !integrationConfig.isComingSoon

    const featureAvailable = featureVisible && !prevState.editor.connecting

    return {
      available: {
        visible: featureVisible,
        enabled: featureAvailable
      },
      reasons: !featureAvailable
        ? [
            prevState.editor.connecting
              ? IntegrationFeatureDisabledReason.OperationInProgress
              : IntegrationFeatureDisabledReason.FeatureNotAvailable
          ]
        : []
    }
  }
