import {
  Avatar,
  Flex,
  FlexProps,
  IconButton,
  Link,
  LinkProps,
  Text
} from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey40,
  GreyGrey60,
  Lato13Bold,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { ChevronRightIcon } from 'components/icons'

export interface WorkspaceListProps extends FlexProps {}

export const WorkspaceList = ({ children, ...props }: WorkspaceListProps) => {
  return (
    <Flex
      boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.08)"
      direction="column"
      borderWidth={1}
      borderColor="gray.40"
      borderRadius="8px"
      overflow="hidden"
      css={{
        '& > *:not(:last-child)': {
          borderBottom: '1px solid',
          borderColor: GreyGrey40
        }
      }}
      {...props}
    >
      {children}
    </Flex>
  )
}

export interface WorkspaceListItemProps extends LinkProps {
  name: string
  action?: React.ReactNode
  link: string
}

export const WorkspaceListItem = ({
  name,
  action,
  link,
  ...props
}: WorkspaceListItemProps) => {
  return (
    <Link
      href={link}
      _hover={{
        textDecoration: 'none',
        background: GreyGrey10
      }}
      display="flex"
      padding="12px 16px"
      {...props}
    >
      <Flex alignItems="center" gap="8px" flex={1}>
        <Avatar
          {...Lato13Bold}
          name={name.slice(0, 1)}
          fontWeight="bold"
          background="#05454A"
          color="#ffffff"
          height="36px"
          width="36px"
          borderRadius="6px"
        />
        <Text {...Lato14Bold}>{name}</Text>
      </Flex>
      <Flex alignItems="center" gap="8px" {...Lato14Regular}>
        {action}
        <IconButton
          height="20px"
          minWidth="20px"
          borderRadius={0}
          variant="clear"
          aria-label="Action"
          icon={<ChevronRightIcon color={GreyGrey60} />}
        />
      </Flex>
    </Link>
  )
}

WorkspaceList.Item = WorkspaceListItem
