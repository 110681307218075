import { ListPriceEditorMode } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { NEW_PRICE_PATTERN } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { useCallback, useMemo, useState } from 'react'
import { Location } from 'react-router-dom'

export const useEditorFooter = () => {
  const [submitting, setSubmitting] = useState(false)
  const listPriceEditorContext = useListPriceEditorContext()

  const saveText = useMemo(() => {
    if (listPriceEditorContext.editor.selectedPrice?.match(NEW_PRICE_PATTERN)) {
      return 'Create list price'
    }

    return 'Update list price'
  }, [listPriceEditorContext])

  const closeText = useMemo(
    () =>
      listPriceEditorContext.configuration.mode === ListPriceEditorMode.VIEW
        ? 'Close'
        : 'Cancel',
    [listPriceEditorContext]
  )

  const onSave = useCallback(async () => {
    /**
     * This is a bit weird isn't it? ... Yep! The issue with the blocker is
     * that it's rendered into the DOM as a 'static' component, but it's really quite a 'reactionary'
     * and conditional bit of functionality that really should ideally be called in response
     * to an action (such as leaving a route) more directly rather than be tied to current React state,
     * which is difficult to make gel neatly as we need to trigger the React render loop (and make this
     * save function async to give the loop a chance to render before we continue).
     *
     * Because of that, to integrate it well, we need to do a bit of shenanigans to set
     * the blocker as being disabled when adding a new price, and then re-enable it afterwards.
     * The setTimeout here is to give React an opportunity to re-render the component with the
     * blocker removed before we call the 'add price' functionality, and on completion of that
     * we want to re-enable it (in case the add fails for validation, for example).
     *
     * We may want to review Blocker in the future to see if we can integrate it a smidge more
     * neatly.
     */
    setSubmitting(true)
    await new Promise(resolve => setTimeout(resolve, 0))
    await listPriceEditorContext.functions.saveNewPrice()
    setSubmitting(false)
  }, [listPriceEditorContext.functions])

  const onReview = useCallback(() => {
    return listPriceEditorContext.functions.reviewPrice()
  }, [listPriceEditorContext.functions])

  const blockerEnabled = useMemo(() => {
    return Boolean(
      !submitting && listPriceEditorContext.derived.queries.priceHasBeenUpdated
    )
  }, [listPriceEditorContext.derived.queries.priceHasBeenUpdated, submitting])

  const saveDisabled = useMemo(() => {
    return (
      submitting || !listPriceEditorContext.derived.queries.priceHasBeenUpdated
    )
  }, [listPriceEditorContext.derived.queries, submitting])

  const ignoreBlocker = useCallback((nextLocation: Location): boolean => {
    if (
      nextLocation.pathname.match(/\/metrics\/new/) ||
      nextLocation.pathname.match(/\/seat-types\/new/)
    ) {
      return true
    }
    return false
  }, [])

  return {
    reviewAvailable:
      listPriceEditorContext.derived.queries.availableFeatures.review,
    saveAvailable:
      listPriceEditorContext.derived.queries.availableFeatures.save,
    saveDisabled,
    closeText,
    saveText,
    onReview,
    onSave,
    onClose: listPriceEditorContext.functions.closeEditor,
    blockerEnabled,
    submitting,
    ignoreBlocker
  }
}
