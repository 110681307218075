import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { enforceMinimumPrecision } from '@sequencehq/utils'
import {
  ApiListPrice,
  ApiListPriceTier,
  AvailableStandardFrequency,
  GraduatedPrice,
  LinearPrice,
  ListPriceEditorMode,
  ListPriceEditorPercentageTier,
  ListPriceEditorReducerState,
  ListPriceEditorTier,
  PackagedPrice,
  Price,
  PricingModel,
  Product,
  SeatBasedGraduatedPrice,
  SeatBasedLinearPrice,
  StandardPrice,
  UsageTierType,
  VolumePrice
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { LoadedListPriceEditorData } from 'modules/Products/drawers/ListPriceEditor/hooks/useLoadListPriceEditor'
import { INITIAL_LIST_PRICE_STATE } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { sanitizePercentageTiers } from 'modules/Products/drawers/ListPriceEditor/utils/sanitizePercentageTiers'
import { sanitizeTiers } from 'modules/Products/drawers/ListPriceEditor/utils/sanitizeTiers'
import { ListPriceMutationInterface } from 'modules/Products/hooks/entitySaving/useSaveListPrice'
import { ListPrice } from 'modules/Products/types'
import { match } from 'ts-pattern'

export const internalTiersToApiTiers = ({
  tiers
}: {
  tiers: ListPriceEditorTier[]
}): ApiListPriceTier[] => {
  return tiers.map((tier, idx) => {
    return {
      price: enforceMinimumPrecision(2)(tier.unitPrice),
      isPricePercentage: false,
      /**
       * The last tier never has an upper bound, so we will never save it.
       */
      ...(idx === tiers.length - 1 ? {} : { upperBound: tier.lastUnit }),
      fee: enforceMinimumPrecision(2)(tier.flatFee)
    }
  })
}

export const internalPercentageTiersToApiTiers = ({
  tiers,
  includePercentageLimits
}: {
  tiers: ListPriceEditorPercentageTier[]
  includePercentageLimits: boolean
}): ApiListPriceTier[] => {
  return tiers.map((tier, idx) => {
    return {
      price: enforceMinimumPrecision(2)(tier.unitPercentage),
      isPricePercentage: true,
      /**
       * The last tier never has an upper bound, so we will never save it.
       */
      ...(idx === tiers.length - 1
        ? {}
        : { upperBound: enforceMinimumPrecision(2)(tier.lastUnit) }),
      fee: enforceMinimumPrecision(2)(tier.flatFee),
      maxPrice:
        tier.maxPrice && includePercentageLimits
          ? enforceMinimumPrecision(2)(tier.maxPrice)
          : undefined,
      minPrice:
        tier.minPrice && includePercentageLimits
          ? enforceMinimumPrecision(2)(tier.minPrice)
          : undefined
    }
  })
}

/**
 * Converts the internal price to the format expected for the PriceModel, which
 * is compatible with the Sequence API.
 *
 * One quirk to look out for is around 'numbers' - they're all saved as strings, but
 * the values themselves are explicitly float or integer, but this isn't easily conveyed
 * through types alone. The default is usually float (and the float properties can accept
 * an 'integer' string) but in some fields, like 'package_size' are integers, and
 * an API error will occur if sending a float string (e.g. '1.00').
 *
 * The best way to be certain of the target type is to look up the pricing structure proto
 * in sequence-platform-api. The files are call, for example 'PackageListPriceStructure.proto'.
 * If you look at that proto, you will see the types such as BigInteger to give you confidence
 * in the transform here.
 *
 * Further validation of the data on the API can be found in the ListPriceStructure.kt file - such
 * as the check that VOLUME and GRADUATED tiers must have at least one unbounded tier.
 * @param productId
 * @returns
 */
export const internalPriceToApiPrice =
  (productId: Product['id']) =>
  (price: Price): ListPriceMutationInterface & { id?: string } => {
    return {
      id: price.id,
      name: price.common.name,
      currency: price.common.currency,
      billingFrequency: price.modelSpecific.billingFrequency,
      usageCalculationPeriod:
        'usageCalculationPeriod' in price.modelSpecific
          ? price.modelSpecific.usageCalculationPeriod
          : undefined,
      billingType: price.modelSpecific.billingType,
      productId,
      integrationIds: Object.entries(price.common.externalIds).map(
        ([key, code]) => ({
          service: key,
          id: code
        })
      ) as ListPriceMutationInterface['integrationIds'],
      customMetricParameters: match(price)
        .with({ common: { pricingModel: 'STANDARD' } }, () => [])
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with({ common: { pricingModel: 'SEAT_BASED_LINEAR' } }, () => [])
        .with({ common: { pricingModel: 'SEAT_BASED_GRADUATED' } }, () => [])
        .exhaustive(),
      structure: match(price)
        .with(
          { common: { pricingModel: 'STANDARD' } },
          ({ modelSpecific: matchedModel }) => {
            if (matchedModel.billingFrequency === 'ONE_TIME') {
              return {
                pricingType: 'ONE_TIME',
                price: enforceMinimumPrecision(2)(matchedModel.price)
              }
            }

            return {
              pricingType: 'FIXED',
              price: enforceMinimumPrecision(2)(matchedModel.price)
            }
          }
        )
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'LINEAR',
            pricePerUnit:
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? enforceMinimumPrecision(2)(matchedModel.percentage)
                : enforceMinimumPrecision(2)(matchedModel.price),
            minPrice:
              matchedModel.minPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.minPrice
                : undefined,
            maxPrice:
              matchedModel.maxPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.maxPrice
                : undefined,
            usageMetricId: matchedModel.usageMetricId,
            isPricePercentage: matchedModel.linearPriceType === 'PERCENTAGE'
          })
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'VOLUME',
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits:
                      matchedModel.includePercentageLimits
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'GRADUATED',
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits: false
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId,
            usageCalculationMode: matchedModel.usageCalculationMode
          })
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'PACKAGE',
            packageSize: matchedModel.packageSize,
            pricePerPackage: enforceMinimumPrecision(2)(
              matchedModel.pricePerPackage
            ),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED',
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(matchedModel.minimumSeats),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatTypeId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED',
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(matchedModel.minimumSeats),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatTypeId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency,
            tiers: internalTiersToApiTiers({
              tiers: matchedModel.tiers
            })
          })
        )
        .exhaustive() as ListPriceMutationInterface['structure']
    }
  }

export const apiTiersToInternalTiers = (
  apiTiers: ApiListPriceTier[]
): {
  usageTierType: UsageTierType
  percentageTiers: ListPriceEditorPercentageTier[]
  tiers: ListPriceEditorTier[]
} => {
  if (!apiTiers || apiTiers.length === 0) {
    return {
      usageTierType: 'FIXED',
      percentageTiers: sanitizePercentageTiers([
        {
          id: 'percentage-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPercentage: '0',
          flatFee: '0.00',
          maxPrice: '',
          minPrice: ''
        }
      ]),
      tiers: sanitizeTiers([
        {
          id: 'fixed-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPrice: '0.00',
          flatFee: '0.00'
        }
      ])
    }
  }

  if ('isPricePercentage' in apiTiers[0] && apiTiers[0]?.isPricePercentage) {
    return {
      usageTierType: 'PERCENTAGE',
      percentageTiers: sanitizePercentageTiers(
        apiTiers.map((apiTier, idx) => ({
          id: `tier-${idx}`,
          firstUnit:
            idx === 0
              ? '0.00'
              : (
                  parseFloat(apiTiers[idx - 1]?.upperBound ?? '0.00') + 0.01
                ).toFixed(2) ?? '0.00',
          lastUnit: apiTier.upperBound
            ? enforceMinimumPrecision(2)(apiTier.upperBound)
            : '',
          unitPrice: '0.00',
          unitPercentage: enforceMinimumPrecision(2)(apiTier.price ?? '0.00'),
          flatFee: enforceMinimumPrecision(2)(apiTier.fee ?? '0') ?? '0.00',
          maxPrice:
            'maxPrice' in apiTier && apiTier.maxPrice
              ? enforceMinimumPrecision(2)(apiTier.maxPrice)
              : '',
          minPrice:
            'minPrice' in apiTier && apiTier.minPrice
              ? enforceMinimumPrecision(2)(apiTier.minPrice)
              : ''
        }))
      ),
      tiers: sanitizeTiers([
        {
          id: 'fixed-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPrice: '0.00',
          flatFee: '0.00'
        }
      ])
    }
  }

  return {
    usageTierType: 'FIXED',
    percentageTiers: sanitizePercentageTiers([
      {
        id: 'percentage-tier-1',
        firstUnit: '0',
        lastUnit: '',
        unitPercentage: '0',
        flatFee: '0.00',
        maxPrice: '',
        minPrice: ''
      }
    ]),
    tiers: sanitizeTiers(
      apiTiers.map((apiTier, idx) => ({
        id: `tier-${idx}`,
        firstUnit:
          idx === 0
            ? '0'
            : (
                parseFloat(apiTiers[idx - 1]?.upperBound ?? '0') + 1
              ).toString() ?? '0',
        lastUnit: apiTier.upperBound ?? '',
        unitPrice: enforceMinimumPrecision(2)(apiTier.price ?? '0.00'),
        flatFee: enforceMinimumPrecision(2)(apiTier.fee ?? '0') ?? '0.00'
      }))
    )
  }
}

export const apiPriceToInternalPrice =
  (product: Product) =>
  (price: ApiListPrice): Price => {
    const commonData = <PM extends PricingModel>(pricingModel: PM) => ({
      id: price.id,
      common: {
        currency: price.currency,
        name: price.name || product.name,
        pricingModel,
        externalIds: price.integrationIds.reduce(
          (acc, externalId) => ({
            ...acc,
            [externalId.service]: externalId.id
          }),
          {}
        )
      }
    })

    return match(price.structure)
      .with({ pricingType: 'FIXED' }, (matchedApiPrice): StandardPrice => {
        return {
          ...commonData('STANDARD'),
          modelSpecific: {
            billingType: price.billingType,
            price: enforceMinimumPrecision(2)(matchedApiPrice.price),
            billingFrequency: price.billingFrequency
          }
        }
      })
      .with({ pricingType: 'ONE_TIME' }, (matchedApiPrice): StandardPrice => {
        return {
          ...commonData('STANDARD'),
          modelSpecific: {
            billingType: price.billingType,
            price: matchedApiPrice.price,
            billingFrequency: price.billingFrequency
          }
        }
      })
      .with({ pricingType: 'GRADUATED' }, (matchedApiPrice): GraduatedPrice => {
        return {
          ...commonData('GRADUATED'),
          modelSpecific: {
            usageMetricId: matchedApiPrice.usageMetricId,
            usageCalculationMode: matchedApiPrice.usageCalculationMode,
            usageCalculationPeriod: price.usageCalculationPeriod,
            ...apiTiersToInternalTiers(matchedApiPrice.tiers),
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with({ pricingType: 'VOLUME' }, (matchedApiPrice): VolumePrice => {
        return {
          ...commonData('VOLUME'),
          modelSpecific: {
            usageMetricId: matchedApiPrice.usageMetricId,
            ...apiTiersToInternalTiers(matchedApiPrice.tiers),
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            includePercentageLimits:
              matchedApiPrice.tiers.filter(
                tier => !!tier.maxPrice || !!tier.minPrice
              ).length > 0,
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with({ pricingType: 'PACKAGE' }, (matchedApiPrice): PackagedPrice => {
        return {
          ...commonData('PACKAGED'),
          modelSpecific: {
            usageMetricId: matchedApiPrice.usageMetricId,
            pricePerPackage: parseFloat(
              matchedApiPrice.pricePerPackage
            ).toFixed(2),
            packageSize: matchedApiPrice.packageSize,
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with({ pricingType: 'LINEAR' }, (matchedApiPrice): LinearPrice => {
        return {
          ...commonData('LINEAR'),
          modelSpecific: {
            price: matchedApiPrice.isPricePercentage
              ? '0.00'
              : enforceMinimumPrecision(2)(matchedApiPrice.pricePerUnit),
            percentage: matchedApiPrice.isPricePercentage
              ? matchedApiPrice.pricePerUnit
              : '0',
            minPrice:
              matchedApiPrice.isPricePercentage && matchedApiPrice.minPrice
                ? matchedApiPrice.minPrice
                : '',
            maxPrice:
              matchedApiPrice.isPricePercentage && matchedApiPrice.maxPrice
                ? matchedApiPrice.maxPrice
                : '',
            linearPriceType: matchedApiPrice.isPricePercentage
              ? 'PERCENTAGE'
              : 'FIXED',
            usageMetricId: matchedApiPrice.usageMetricId,
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with(
        { pricingType: 'SEAT_BASED' },
        (matchedApiPrice): SeatBasedLinearPrice | SeatBasedGraduatedPrice => {
          // If there are tiers, then it's a graduated price
          if (
            'tiers' in price.structure &&
            (price.structure.tiers ?? []).length > 0
          ) {
            return {
              ...commonData('SEAT_BASED_GRADUATED'),
              modelSpecific: {
                seatTypeId: matchedApiPrice.seatMetricId,
                pricePerSeat: enforceMinimumPrecision(2)(
                  matchedApiPrice.pricePerSeat
                ),
                minimumSeats: (
                  matchedApiPrice.contractedMinimumSeats ?? 0
                ).toFixed(0),
                prorationStrategy: matchedApiPrice.prorationStrategy,
                billingFrequency: price.billingFrequency,
                billingType: price.billingType,
                overagesBillingFrequency:
                  matchedApiPrice.overagesBillingFrequency ?? 'NEVER',
                ...apiTiersToInternalTiers(matchedApiPrice.tiers ?? [])
              }
            }
          }

          return {
            ...commonData('SEAT_BASED_LINEAR'),
            modelSpecific: {
              seatTypeId: matchedApiPrice.seatMetricId,
              pricePerSeat: enforceMinimumPrecision(2)(
                matchedApiPrice.pricePerSeat
              ),
              minimumSeats: (
                matchedApiPrice.contractedMinimumSeats ?? 0
              ).toFixed(0),
              prorationStrategy: matchedApiPrice.prorationStrategy,
              billingFrequency: price.billingFrequency,
              billingType: price.billingType,
              overagesBillingFrequency:
                matchedApiPrice.overagesBillingFrequency ?? 'NEVER'
            }
          }
        }
      )
      .exhaustive()
  }

export const internalPriceToListPrice =
  (productId: string) =>
  (price: Price): ListPrice => {
    return {
      id: price.id,
      // TODO: is this required on list price?
      createdAt: '',
      name: price.common.name,
      currency: price.common.currency,
      billingFrequency: price.modelSpecific.billingFrequency,
      usageCalculationPeriod:
        'usageCalculationPeriod' in price.modelSpecific
          ? price.modelSpecific.usageCalculationPeriod
          : undefined,
      billingType: price.modelSpecific.billingType,
      productId,
      integrationIds: Object.entries(price.common.externalIds).map(
        ([key, code]) => ({
          service: key,
          id: code
        })
      ) as ListPriceMutationInterface['integrationIds'],
      customMetricParameters: match(price)
        .with({ common: { pricingModel: 'STANDARD' } }, () => [])
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            }))
        )
        .with({ common: { pricingModel: 'SEAT_BASED_LINEAR' } }, () => [])
        .with({ common: { pricingModel: 'SEAT_BASED_GRADUATED' } }, () => [])
        .exhaustive(),
      structure: match(price)
        .with(
          { common: { pricingModel: 'STANDARD' } },
          ({ modelSpecific: matchedModel }) => {
            if (matchedModel.billingFrequency === 'ONE_TIME') {
              return {
                pricingType: 'ONE_TIME',
                price: enforceMinimumPrecision(2)(matchedModel.price)
              }
            }

            return {
              pricingType: 'FIXED',
              price: enforceMinimumPrecision(2)(matchedModel.price)
            }
          }
        )
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'LINEAR',
            pricePerUnit:
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? enforceMinimumPrecision(2)(matchedModel.percentage)
                : enforceMinimumPrecision(2)(matchedModel.price),
            minPrice:
              matchedModel.minPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.minPrice
                : undefined,
            maxPrice:
              matchedModel.maxPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.maxPrice
                : undefined,
            usageMetricId: matchedModel.usageMetricId,
            isPricePercentage: matchedModel.linearPriceType === 'PERCENTAGE'
          })
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'VOLUME',
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits:
                      matchedModel.includePercentageLimits
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'GRADUATED',
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits: false
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId,
            usageCalculationMode: matchedModel.usageCalculationMode,
            usageCalculationPeriod: matchedModel.usageCalculationPeriod
          })
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'PACKAGE',
            packageSize: matchedModel.packageSize,
            pricePerPackage: enforceMinimumPrecision(2)(
              matchedModel.pricePerPackage
            ),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED',
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(matchedModel.minimumSeats),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatTypeId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED',
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(matchedModel.minimumSeats),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatTypeId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency,
            tiers: internalTiersToApiTiers({
              tiers: matchedModel.tiers
            })
          })
        )
        .exhaustive() as ListPriceMutationInterface['structure']
    }
  }

export const listPriceEditorLoaderToEditor = (args: {
  loadedData: LoadedListPriceEditorData
  mode?: ListPriceEditorMode
  priceId?: Price['id']
  availableFrequencies: AvailableStandardFrequency[]
  currency?: Currency
}): Pick<ListPriceEditorReducerState, 'data' | 'configuration' | 'editor'> => {
  const prices = arrayToIdKeyedObject(
    args.loadedData.prices.map(apiPriceToInternalPrice(args.loadedData.product))
  )

  return {
    data: {
      prices,
      metrics: arrayToIdKeyedObject(args.loadedData.metrics),
      product: args.loadedData.product,
      formData: INITIAL_LIST_PRICE_STATE.data.formData,
      ledgerIntegrations: arrayToIdKeyedObject(
        args.loadedData.ledgerIntegrations.map(
          ({ key, value, defaultLedgerAccount }) => ({
            id: key,
            defaultLedgerAccount,
            options: value
          })
        )
      )
    },
    configuration: {
      currency: args.currency ?? args.loadedData.availableCurrencies[0],
      availableCurrencies: args.loadedData.availableCurrencies,
      availableStandardFrequencies: args.availableFrequencies,
      mode: args.mode ?? ListPriceEditorMode.CREATE
    },
    editor: {
      loaded: true,
      selectedPrice: args.priceId ?? null,
      showValidationErrors: false,
      formsValid: {}
    }
  }
}

export const arrayToIdKeyedObject = <T extends { id: string }>(
  items: T[]
): Record<T['id'], T> =>
  items?.reduce(
    (acc: Record<T['id'], T>, item) => ({ ...acc, [item.id]: item }),
    {} as Record<T['id'], T>
  ) ?? {}
