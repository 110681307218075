export const Heading1Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.7401 10.9826V12.0001H10.3401V10.9826H11.9516V6.34613C11.9516 6.1628 11.9571 5.97397 11.9681 5.77963L10.8241 6.73663C10.7581 6.78797 10.6921 6.82097 10.6261 6.83563C10.5637 6.84663 10.5032 6.84663 10.4446 6.83563C10.3896 6.82463 10.3401 6.8063 10.2961 6.78063C10.2521 6.7513 10.2191 6.72013 10.1971 6.68713L9.76807 6.09863L12.1936 4.03613H13.3101V10.9826H14.7401Z"
        fill="currentColor"
      />
      <path
        d="M8.16202 4.04736V12.0004H6.67702V8.52436H2.94252V12.0004H1.45752V4.04736H2.94252V7.46836H6.67702V4.04736H8.16202Z"
        fill="currentColor"
      />
    </svg>
  )
}
