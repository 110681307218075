import { Flex, Button, Text } from '@chakra-ui/react'
import ArrowLeftIcon from '@heroicons/react/16/solid/ArrowLeftIcon'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'

import {
  GreyGrey30,
  GreyGrey80,
  GreyWhite,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { useHeader } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/widgets/useHeader'
import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'

type Props = {
  hook: QuoteEditorPreviewProps
}

export const Header = ({ hook }: Props) => {
  const { exitBackButton, downloadPdfButton } = useHeader(hook)

  return (
    <Flex
      background={GreyWhite}
      borderBottom={`${GreyGrey30} 1px solid`}
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      px="16px"
      height="48px"
      position="relative"
    >
      <Button variant="unstyled" onClick={exitBackButton.onClick}>
        <ArrowLeftIcon width="16px" height="16px" color={GreyGrey80} />
      </Button>

      <Flex gap="8px">
        <Button
          variant="component-library-secondary"
          leftIcon={
            <ArrowDownTrayIcon width="16px" height="16px" color="inherit" />
          }
          iconSpacing="4px"
          onClick={downloadPdfButton.onClick}
        >
          Download PDF
        </Button>
        <Button
          variant="component-library-primary"
          onClick={exitBackButton.onClick}
        >
          Go back
        </Button>
      </Flex>

      <Text
        {...Lato14Bold}
        color={GreyGrey80}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        Preview
      </Text>
    </Flex>
  )
}
