import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'
import { scheduleEndStateStatuses } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/utils/availableFeatures.constants'

export const getStripePaymentAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (!prevState.configuration.features.stripeIntegrationAvailable) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.FeatureDisabled,
          metadata: {
            configuration: prevState.configuration.features
          }
        }
      ]
    }
  }

  if (scheduleEndStateStatuses.includes(prevState.data.common.status)) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: {}
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
