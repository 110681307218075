import { Box, Flex, FormLabel, Text } from '@chakra-ui/react'
import { Lato14Regular } from '@sequencehq/design-tokens'
import { CheckboxField, TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  fiveDigitNumber,
  nineDigitNumber,
  required,
  swiftCode,
  threeDigitNumber
} from '@sequencehq/validation'
import { AddressInput } from 'components/FormComponents'
import { FC } from 'react'

export const CanadianInternationalFields: FC<{
  fieldName: string
  values: any
  index: number
}> = ({ fieldName, values, index }) => {
  return (
    <Flex flexDirection="column">
      <Flex>
        <Box width="200px">
          <TextInputField
            fieldLabel="Account Number"
            fieldName={`${fieldName}.value.canadianInternationalDestination.accountNumber`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Legal Name"
            fieldName={`${fieldName}.value.canadianInternationalDestination.legalName`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="250px">
          <TextInputField
            fieldLabel="Transit Number"
            fieldName={`${fieldName}.value.canadianInternationalDestination.transitNumber`}
            validate={composeValidators(required, fiveDigitNumber)}
          />
        </Box>
      </Flex>
      <Box height="12px"></Box>
      <Text textStyle="sectionHeader">Bank details</Text>
      <Box height="12px"></Box>
      <Flex>
        <Box width="200px">
          <TextInputField
            fieldLabel="Bank Name"
            fieldName={`${fieldName}.value.canadianInternationalDestination.bankName`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Institution Number"
            fieldName={`${fieldName}.value.canadianInternationalDestination.institutionNumber`}
            validate={composeValidators(required, threeDigitNumber)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Swift Code"
            fieldName={`${fieldName}.value.canadianInternationalDestination.swiftCode`}
            validate={composeValidators(required, swiftCode)}
          />
        </Box>
      </Flex>
      <Box height="24px"></Box>

      <FormLabel>Bank Address</FormLabel>
      <Box height={2} />

      <AddressInput
        name={`${fieldName}.value.canadianInternationalDestination.bankAddress.`}
      />
      <Box height="24px"></Box>

      <CheckboxField
        fieldName={`${fieldName}.value.canadianInternationalDestination.includeIntermediaryBank`}
      >
        <Text {...Lato14Regular} paddingRight="8px">
          Include intermediary bank
        </Text>
      </CheckboxField>
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        values.bankAccountDetails[index].value?.canadianInternationalDestination
          ?.includeIntermediaryBank && (
          <>
            <Box height="12px"></Box>
            <Text textStyle="sectionHeader">Intermediary bank details</Text>
            <Box height="12px"></Box>
            <Flex>
              <Box width="200px">
                <TextInputField
                  fieldLabel="Bank Name"
                  fieldName={`${fieldName}.value.canadianInternationalDestination.intermediaryBank.bankName`}
                  validate={composeValidators(required)}
                />
              </Box>
              <Box width="10px"></Box>
              <Box width="200px">
                <TextInputField
                  fieldLabel="Routing Number"
                  fieldName={`${fieldName}.value.canadianInternationalDestination.intermediaryBank.routingNumber`}
                  validate={composeValidators(required, nineDigitNumber)}
                />
              </Box>
              <Box width="10px"></Box>
              <Box width="200px">
                <TextInputField
                  fieldLabel="Swift Code"
                  fieldName={`${fieldName}.value.canadianInternationalDestination.intermediaryBank.swiftCode`}
                  validate={composeValidators(required, swiftCode)}
                />
              </Box>
            </Flex>
            <Box height="24px"></Box>

            <FormLabel>Bank Address</FormLabel>
            <Box height={2} />

            <AddressInput
              name={`${fieldName}.value.canadianInternationalDestination.intermediaryBank.bankAddress.`}
            />
          </>
        )
      }
    </Flex>
  )
}
