import { useCallback, useMemo, useState } from 'react'

import * as Sentry from '@sentry/react'

import { useNotifications } from 'lib/hooks/useNotifications'
import { CustomerContact } from './CustomerContacts.domain.types'
import { CustomerContactFields } from '../view/forms/ContactModalForm/useContactModalForm'
import {
  CustomerContactPorts,
  CustomerContactPostBody,
  CustomerContactPutBody
} from '../communication/external/v1/ports/customerContacts.ports'
import {
  INITIAL_DATA,
  INITIAL_FORM_DATA
} from './CustomerContacts.domain.constants'
import { updateContactsState } from '../utils/updateContactsState'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'

export type CustomerContactsDomainInterfaceData = {
  customerId: string
  contacts: CustomerContact[]
}

export type CustomerContactsDomainInterface = {
  queries: {
    data: CustomerContactsDomainInterfaceData
    formData: CustomerContactFields
  }
  mutators: {
    external: {
      in: {
        core: () => Promise<void>
      }
      out: {
        createContact: (body: CustomerContactPostBody) => Promise<void>
        updateContact: ({
          contactId,
          body
        }: {
          contactId: string
          body: CustomerContactPutBody
        }) => Promise<void>
        markAsPrimaryBillingContact: (contactId: string) => Promise<void>
        archiveContact: (contactId: string) => Promise<void>
      }
    }
    internal: {
      updateForm: (newFields: CustomerContactFields) => void
    }
  }
}

type UseCustomerContactsDomain = (props: {
  ports: CustomerContactPorts
}) => CustomerContactsDomainInterface

export const useCustomerContactsDomain: UseCustomerContactsDomain = props => {
  const notifications = useNotifications()

  const { customer } = useCustomerContext()

  const [data, setData] =
    useState<CustomerContactsDomainInterfaceData>(INITIAL_DATA)

  const [formData, setFormData] =
    useState<CustomerContactFields>(INITIAL_FORM_DATA)

  const queries = useMemo(() => ({ data, formData }), [data, formData])

  /**
   * Load contacts
   */
  const loadData = props.ports.load
  const load = useCallback(async () => {
    const loadedData = await loadData()

    setData(loadedData)
  }, [loadData])

  /**
   * Create contacts
   */
  const portCreateContact = props.ports.createContact
  const createContact = useCallback(
    async (body: CustomerContactPostBody) => {
      try {
        const newContact = await portCreateContact(body)

        setData(prev => ({
          ...prev,
          contacts: [...prev.contacts, newContact]
        }))

        notifications.displayNotification('Contact created', {
          type: 'success',
          description: `${
            newContact.name ?? newContact.email
          } was just added to ${customer.legalName}`,
          isClosable: true
        })
      } catch (e) {
        Sentry.captureException(e)

        notifications.displayNotification('Unable to create new contact', {
          type: 'error'
        })
      }
    },
    [notifications, portCreateContact]
  )

  /**
   * Update contacts
   */
  const portUpdateContact = props.ports.updateContact
  const updateContact = useCallback(
    async ({
      contactId,
      body
    }: {
      contactId: string
      body: CustomerContactPutBody
    }) => {
      try {
        const updatedContact = await portUpdateContact({ contactId, body })

        setData(prev => ({
          ...prev,
          contacts: updateContactsState({
            contactId,
            previousContacts: prev.contacts,
            updatedContact
          })
        }))

        notifications.displayNotification('Contact updated', {
          type: 'success',
          description: `${
            updatedContact.name ?? updatedContact.email
          } is now updated`,
          isClosable: true
        })
      } catch (e) {
        Sentry.captureException(e)

        notifications.displayNotification('Unable to update contact', {
          type: 'error'
        })
      }
    },
    [notifications, portUpdateContact]
  )

  const markAsPrimaryBillingContact = useCallback(
    async (contactId: string) => {
      try {
        const thisContact = data.contacts.find(
          contact => contact.id === contactId
        )

        if (!thisContact) {
          throw new Error('Unable to find contact to mark as primary')
        }

        await portUpdateContact({
          contactId,
          body: {
            name: thisContact.name || '',
            email: thisContact.email,
            billingPreference: 'PRIMARY'
          }
        })

        setData(prev => ({
          ...prev,
          contacts: prev.contacts.reduce(
            (acc: CustomerContact[], curr: CustomerContact) => {
              if (curr.id === contactId) {
                return [
                  ...acc,
                  { ...curr, billingPreference: 'PRIMARY' as const }
                ]
              }

              if (curr.billingPreference === 'PRIMARY') {
                return [
                  ...acc,
                  { ...curr, billingPreference: 'STANDARD' as const }
                ]
              }

              return [...acc, curr]
            },
            []
          )
        }))

        notifications.displayNotification('Primary billing contact updated', {
          type: 'success'
        })
      } catch (e) {
        Sentry.captureException(e)

        notifications.displayNotification(
          'Unable to update primary billing contact',
          { type: 'error' }
        )
      }
    },
    [data.contacts, notifications, portUpdateContact]
  )

  /**
   * Archive contacts
   */
  const portArchiveContact = props.ports.archiveContact
  const archiveContact = useCallback(
    async (contactId: string) => {
      try {
        const selectedContact = data.contacts.find(
          contact => contact.id === contactId
        )

        if (!selectedContact) {
          throw new Error('Unable to find contact')
        }

        await portArchiveContact(contactId)

        setData(prev => ({
          ...prev,
          contacts: prev.contacts.filter(contact => contact.id !== contactId)
        }))

        notifications.displayNotification('Contact removed', {
          type: 'success',
          description: `${
            selectedContact.name ?? selectedContact.email
          } has been removed from ${customer.legalName}`,
          isClosable: true
        })
      } catch (e) {
        Sentry.captureException(e)

        notifications.displayNotification('Unable to archive contact', {
          type: 'error'
        })
      }
    },
    [notifications, portArchiveContact]
  )

  /**
   * Forms
   */
  const updateForm = useCallback((newFields: CustomerContactFields) => {
    setFormData(newFields)
  }, [])

  return {
    queries,
    mutators: {
      external: {
        in: { core: load },
        out: {
          createContact,
          updateContact,
          markAsPrimaryBillingContact,
          archiveContact
        }
      },
      internal: {
        updateForm
      }
    }
  }
}
