import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'

export type v1ApiIntegration = Dashboardv20240509Api.GetIntegrations.Integration
type UseLoadIntegrations = () => () => Promise<{
  integrations: v1ApiIntegration[]
}>

export const useLoadIntegrations: UseLoadIntegrations = () => {
  const load = useCallback(async () => {
    const integrationsResponse =
      await dashboardv20240509Client.getIntegrations()

    const integrations = integrationsResponse.data?.items

    invariant(integrations, 'Integrations not found')

    return {
      integrations
    }
  }, [])

  return load
}
