import { useCallback } from 'react'
import { CorePortErrors } from 'modules/Cube/domain/cube.domain.types'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'

export type v1ApiQuote = DashboardApi20240730.GetQuote.Quote

type UseLoadQuote = () => (quoteId: string) => Promise<{
  data: v1ApiQuote | null
  error: CorePortErrors | null
}>

export const useLoadQuote: UseLoadQuote = () => {
  const load = useCallback(async (quoteId: string) => {
    const quoteResponse = await dashboard20240730Client.getQuote({
      id: quoteId
    })

    if (!quoteResponse.data || quoteResponse.error) {
      return {
        data: null,
        error:
          quoteResponse.res.status === 404
            ? CorePortErrors.NotFound
            : CorePortErrors.Other
      }
    }

    return {
      data: quoteResponse.data,
      error: null
    }
  }, [])

  return load
}
