import { Box, Checkbox, Flex } from '@chakra-ui/react'
import { FC, useState } from 'react'

interface SelectableSectionProps {
  clickSection: (selected: boolean) => void
  children: React.ReactNode
  selectedAndDisabled: boolean
}

const SelectableSection: FC<SelectableSectionProps> = ({
  clickSection,
  children,
  selectedAndDisabled
}) => {
  const [checked, setChecked] = useState<boolean>(selectedAndDisabled)
  return (
    <Box position="relative" width="100%">
      <Flex direction={'row'} position="relative">
        <Checkbox
          onChange={e => {
            setChecked(e.target.checked)
            clickSection(e.target.checked)
          }}
          isChecked={checked}
          isDisabled={selectedAndDisabled}
        ></Checkbox>
        <Box
          borderWidth={'2px'}
          borderStyle={checked ? 'solid' : 'dashed'}
          borderColor={checked ? 'turquoise.60' : 'orange.40'}
          backgroundColor={checked ? 'orange.10' : ''}
          ml={'5px'}
          paddingRight={'15px'}
          paddingTop={'5px'}
          paddingBottom={'5px'}
          color="gray.100"
          fontSize="14px"
          fontWeight="400"
        >
          {children}
        </Box>
      </Flex>
    </Box>
  )
}

export default SelectableSection
