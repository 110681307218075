import { durationsToDateAdapter } from '@sequencehq/utils/dates'
import { isEqual } from '@sequencehq/utils/dates'
import {
  ActionHandler,
  AlignPhaseDurationAction
} from 'modules/Cube/domain/cube.domain.types'

export const alignPhaseDuration: ActionHandler<AlignPhaseDurationAction> =
  prevState => action => {
    const phase = prevState.queries.resolvedPhases[action.payload.phaseId]

    if (!phase) {
      return prevState
    }

    const phaseMisaligned = !phase.analysis?.alignedWithPeriod.end

    if (
      !phaseMisaligned ||
      !phase.analysis ||
      !phase.analysis.alignedWithPeriod.nextBillingDateAfterEndDate ||
      !phase.dates.absolute.start
    ) {
      return prevState
    }

    const durationToNextBillingDate = durationsToDateAdapter.toDuration(
      phase.dates.absolute.start
    )(phase.analysis.alignedWithPeriod.nextBillingDateAfterEndDate)

    if (!durationToNextBillingDate) {
      return prevState
    }

    const newDate = durationsToDateAdapter.toDate(phase.dates.absolute.start)(
      durationToNextBillingDate
    )
    /**
     * Even with all the efforts protecting against DST and whatnot, sometimes
     * the durations calculations just end up off by one day. It's not strictly random,
     * but it's also not strictly easy to figure out the logic either. It's always
     * ahead by one day when it is off, though. So this bit of data massaging keeps
     * things lined up correctly!
     */

    if (!newDate) {
      return prevState
    }
    const newDuration = isEqual(
      newDate,
      phase.analysis.alignedWithPeriod.nextBillingDateAfterEndDate
    )
      ? {
          ...durationToNextBillingDate,
          days: (durationToNextBillingDate.days ?? 0) - 1
        }
      : durationToNextBillingDate

    return {
      ...prevState,
      data: {
        ...prevState.data,
        phases: {
          ...prevState.data.phases,
          [action.payload.phaseId]: {
            ...prevState.data.phases[action.payload.phaseId],
            duration: newDuration
          }
        }
      }
    }
  }
