import { Flex, Text } from '@chakra-ui/react'
import {
  DeleteInvoiceLineItemGroupFormMutation,
  DeleteInvoiceLineItemGroupFormProps
} from 'components/DeleteLineItemGroup/types'
import { MutationFormProps } from 'components/Form/types'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const DeleteInvoiceLineItemGroupForm: FC<
  MutationFormProps<DeleteInvoiceLineItemGroupFormMutation> &
    DeleteInvoiceLineItemGroupFormProps
> = ({ invoiceId, id, title, submitForm, formId }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={() =>
        submitForm({
          invoice: invoiceId,
          id
        })
      }
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            px={5}
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            <Text textStyle="taxInfoText">
              Delete line item group '
              {
                <Text as="span" textStyle="bold">
                  {title}
                </Text>
              }
              '? This will delete all line items in this group as well. You
              won't be able to undo this action.
            </Text>
          </Flex>
        )
      }}
    />
  )
}

export default DeleteInvoiceLineItemGroupForm
