import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getContractLengthEditingAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: true,
      enabled:
        !prevState.data.common.isArchived &&
        prevState.data.common.phaseIds.length < 2
    },
    reasons:
      prevState.data.common.phaseIds.length < 2
        ? []
        : [
            {
              reasonType: DisabledReasonType.TooManyPhases,
              metadata: {
                maxAllowedPhases: 1,
                currentPhaseCount: prevState.data.common.phaseIds.length
              }
            }
          ]
  }
}
