import { UseQueryResult } from '@tanstack/react-query'
import { SequenceApiError, SequenceError } from '@sequencehq/api'

export const parseValidationResult = (
  response: UseQueryResult<null, SequenceApiError>
) => {
  const error = response.error?.response
  const hasValidationError = error?.error.type === 'validationError'
  const validationErrors = hasValidationError
    ? error.error?.errors.filter(error => error.code === 'validationError') ??
      []
    : []

  return {
    validating: response.isFetching,
    hasValidationError,
    validationErrors
  }
}

export const processValidationErrors = (
  validationErrors: Array<SequenceError>
): Array<string> => {
  const productErrors =
    validationErrors
      .filter(({ errors }) => errors.some(error => error.key === 'product'))
      .flatMap(({ errors }) => errors) ?? []

  const missingRatesForCategories = productErrors
    .flatMap(({ errors }) => errors)
    .filter(error => error.key === 'taxCategory')
    .map(error => error.detail)
    .filter(detail => typeof detail === 'string')

  return missingRatesForCategories
}
