import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getSetRecipientsAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const isQuoteAccepted: boolean = Boolean(prevState.data.quote.acceptedAt)

  if (isQuoteAccepted) {
    return {
      available: { visible: true, enabled: false },
      reasons: [
        { reasonType: DisabledReasonType.CantChangeWhenAccepted, metadata: {} }
      ]
    }
  }

  const isCustomerSelected: boolean = Boolean(prevState.data.common.customerId)

  if (!isCustomerSelected) {
    return {
      available: { visible: false, enabled: false },
      reasons: [
        { reasonType: DisabledReasonType.NoCustomerSelected, metadata: {} }
      ]
    }
  }

  return {
    available: { visible: true, enabled: true },
    reasons: []
  }
}
