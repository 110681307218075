import { useCallback } from 'react'
import { Product } from 'modules/Cube/domain/cube.domain.types'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type QuoteMutationInterface = v1ApiUpdateQuote & {
  products: {
    new: Product[]
  }
}

export type ApiClientUpdatedQuoteModel =
  DashboardApi20240730.PostQuote.UpdateResponse['data']

export type ApiClientCreatedQuoteModel = NonNullable<
  DashboardApi20240730.PostQuote.CreateResponse['data']
>

export type ApiClientQuoteModel = DashboardApi20240730.GetQuote.Response['data']
export type ApiClientArchivedQuote =
  DashboardApi20240730.PostQuote.ArchiveResponse['data']

type UseSaveQuote = () => {
  create: (
    data?: Partial<ApiClientQuoteModel>
  ) => Promise<ApiClientCreatedQuoteModel | null>
  update: (
    id: string,
    data: v1ApiUpdateQuote
  ) => Promise<ApiClientQuoteModel | null>
  publish: (
    id: string,
    data: {
      sendRecipientsEmail: boolean
      emailMessage: string | undefined
    }
  ) => Promise<ApiClientUpdatedQuoteModel | null>
  accept: (id: string) => Promise<ApiClientUpdatedQuoteModel | null>
  execute: (id: string) => Promise<ApiClientUpdatedQuoteModel | null>
  archive: (id: string) => Promise<ApiClientArchivedQuote | null>
  deleteLatestDraft: (id: string) => Promise<ApiClientQuoteModel | null>
  duplicate: (id: string) => Promise<ApiClientQuoteModel | null>
}

export type v1ApiUpdateQuote = DashboardApi20240730.PutQuote.PutQuoteBody

export const useSaveQuote: UseSaveQuote = () => {
  const create = useCallback(
    async (data: Partial<ApiClientQuoteModel> = {}) => {
      const response = await dashboard20240730Client.postQuote({
        body: { ...data }
      })
      if (response.error) {
        return null
      }

      return response.data ?? null
    },
    []
  )

  const update = useCallback(async (id: string, data: v1ApiUpdateQuote) => {
    const response = await dashboard20240730Client.putQuote({
      id,
      body: data
    })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const publish = useCallback(
    async (
      id: string,
      data: {
        sendRecipientsEmail: boolean
        emailMessage: string | undefined
      }
    ) => {
      const response = await dashboard20240730Client.postQuotePublish({
        id,
        body: data
      })

      if (response.error) {
        return null
      }

      return response.data ?? null
    },
    []
  )

  const accept = useCallback(async (id: string) => {
    const response = await dashboard20240730Client.postQuoteAccept({ id })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const execute = useCallback(async (id: string) => {
    const response = await dashboard20240730Client.postQuoteExecute({ id })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const archive = useCallback(async (id: string) => {
    const response = await dashboard20240730Client.postQuoteArchive({ id })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const deleteLatestDraft = useCallback(async (id: string) => {
    const response = await dashboard20240730Client.deleteQuoteDraftVariant({
      id
    })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const duplicate = useCallback(async (id: string) => {
    const response = await dashboard20240730Client.postQuoteDuplicate({ id })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  return {
    create,
    update,
    publish,
    accept,
    execute,
    archive,
    deleteLatestDraft,
    duplicate
  }
}
