import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import { XeroIntegrationConfig } from 'modules/Integrations/integrationsConfig/xero/xero.integration.config'

export const xeroConfigurationMutator = async (
  data: XeroIntegrationConfig
): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  const result = await dashboardv20240509Client.putIntegration({
    service: 'Xero',
    body: {
      enabled: data.importCustomerWorkflow,
      defaultLedgerAccounts: {
        chargeAccount:
          data.defaultLedgerAccount !== ''
            ? data.defaultLedgerAccount
            : undefined,
        creditAccount:
          data.defaultCreditLedgerAccount !== ''
            ? data.defaultCreditLedgerAccount
            : undefined,
        minimumAccount:
          data.defaultMinimumLedgerAccount !== ''
            ? data.defaultMinimumLedgerAccount
            : undefined
      },
      defaultTaxCodes: {
        untaxedCustomerTaxCode: data.untaxedCustomerTaxCode
      }
    }
  })

  if (result?.error) {
    return {
      success: false,
      error: IntegrationsPortErrors.Other
    }
  }

  return {
    success: true,
    error: null
  }
}
