import { useCallback } from 'react'
import type {
  InvoiceModel,
  InvoicePaymentStatus,
  PaymentProvider,
  IntegrationService,
  UsageDataResponseModel,
  InvoiceUsageItemGroup
} from '@sequencehq/core-models'
import { UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN } from 'InvoiceEditor/domainManagement/invoiceEditor.constants'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import {
  useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation,
  useDeleteInvoicesByInvoiceLineItemsByIdMutation,
  usePostInvoicesByIdFinalizeAndSendMutation,
  usePostInvoicesByIdFinalizeMutation,
  usePostInvoicesByIdRecalculateMutation,
  usePostInvoicesByIdSendMutation,
  usePostInvoicesByIdSendPaymentReminderMutation,
  usePutInvoicesByIdPaymentStatusMutation,
  usePostInvoicesByIdVoidMutation,
  usePostInvoicesByIdSendByTestEmailMutation,
  usePutInvoicesByIdMutation
} from 'features/api'
import {
  usePostApiIntegrationsServiceSyncInvoiceInvoiceIdMutation,
  usePostInvoiceSettingsMutation
} from 'features/api/integratedApi.generated'
import type {
  ApiLineItem,
  ApiLineItemGroup,
  InvoiceEditorReducerState
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { invoiceEditorApiAdapter } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { useLoadLineItemsAndGroups } from 'InvoiceEditor/hooks/useLoadLineItemsAndGroups'
import { useLoadUsageData } from 'InvoiceEditor/hooks/useLoadUsageData'
import { useLoadSubAccountUsage } from 'InvoiceEditor/hooks/useLoadSubAccountUsage'
import { apiQueryClient } from 'features/api/apiQueryClient.ts'

type CreateLineItemGroupData = {
  title: string
  taxCategoryId?: string
}

type CreateLineItemData = {
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  rateDisplay: 'ABSOLUTE' | 'PERCENTAGE'
  externalIds: ApiLineItem['externalIds']
  taxCategoryId?: string
}

type UpdateLineItemData = {
  id: string
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  rateDisplay: 'ABSOLUTE' | 'PERCENTAGE'
  externalIds: ApiLineItem['externalIds']
  taxCategoryId?: string
}

type UpdateLineItemGroupData = {
  id: string
  title: string
  taxCategoryId?: string
}

export const useSaveInvoice = (args: { invoiceId: string }) => {
  const [putInvoiceMutator] = usePutInvoicesByIdMutation()
  const [sendInvoiceMutator] = usePostInvoicesByIdSendMutation()
  const [finaliseInvoiceMutator] = usePostInvoicesByIdFinalizeMutation()
  const [recalculateInvoiceMutator] = usePostInvoicesByIdRecalculateMutation()
  const [voidInvoiceMutator] = usePostInvoicesByIdVoidMutation()
  const [finaliseAndSendInvoiceMutator] =
    usePostInvoicesByIdFinalizeAndSendMutation()
  const [sendPaymentReminderMutator] =
    usePostInvoicesByIdSendPaymentReminderMutation()
  const [paymentStatusMutator] = usePutInvoicesByIdPaymentStatusMutation()
  const [deleteLineItemGroupMutator] =
    useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation()

  const createLineItemGroupMutator = useMutation(
    dashboardv99990101Client.postLineItemGroup
  )
  const updateLineItemGroupMutator = useMutation(
    dashboardv99990101Client.putLineItemGroup
  )

  const createLineItemMutator = useMutation(
    dashboardv99990101Client.postLineItem
  )
  const updateLineItemMutator = useMutation(
    dashboardv99990101Client.putLineItem
  )

  const [deleteLineItemMutator] =
    useDeleteInvoicesByInvoiceLineItemsByIdMutation()
  const [sendTestInvoiceMutator] = usePostInvoicesByIdSendByTestEmailMutation()
  const [syncInvoiceMutator] =
    usePostApiIntegrationsServiceSyncInvoiceInvoiceIdMutation()
  const [createInvoiceSettingsMutator] = usePostInvoiceSettingsMutation()
  const {
    data,
    refetchLineItemsAndGroups,
    lineItemGroupsError,
    lineItemsError
  } = useLoadLineItemsAndGroups(args.invoiceId)
  const { lineItems, lineItemGroups } = data || {}
  const { loadSubAccountUsage } = useLoadSubAccountUsage({
    invoiceId: undefined,
    async: true
  })
  const { loadUsageData } = useLoadUsageData({
    invoiceId: undefined,
    lineItemGroups: [],
    async: true
  })

  const sendInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{
        invoice: InvoiceModel | null
        success: boolean
      }> => {
        const response = await sendInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [sendInvoiceMutator]
  )

  const finaliseInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await finaliseInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [finaliseInvoiceMutator]
  )

  const recalculateInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{
        invoice: InvoiceModel | null
        lineItems: ApiLineItem[] | null
        lineItemGroups: ApiLineItemGroup[] | null
        lineItemGroupUsage:
          | (UsageDataResponseModel & { lineItemGroupId: string })[]
          | null
        subAccountUsageBreakdown: InvoiceUsageItemGroup[] | null
        success: boolean
      }> => {
        // send request to BE to recalculate invoice
        const recalculateInvoiceResponse = await recalculateInvoiceMutator({
          id: invoiceId
        })

        // refresh line items and line item groups
        const {
          lineItems: refetchedLineItems,
          lineItemGroups: refetchedLineItemGroups
        } = await refetchLineItemsAndGroups()
        const subAccountUsageResponse = await loadSubAccountUsage({ invoiceId })

        if (
          'error' in recalculateInvoiceResponse ||
          'error' in subAccountUsageResponse ||
          lineItemGroupsError ||
          lineItemsError ||
          !lineItems ||
          !lineItemGroups
        ) {
          return {
            invoice: null,
            lineItems: null,
            lineItemGroups: null,
            lineItemGroupUsage: null,
            subAccountUsageBreakdown: null,
            success: false
          }
        } else {
          const usageData = await loadUsageData({
            invoiceId,
            lineItemGroups
          })

          return {
            invoice: recalculateInvoiceResponse.data.value() ?? null,
            lineItems: refetchedLineItems,
            lineItemGroups: refetchedLineItemGroups,
            lineItemGroupUsage: usageData.data,
            subAccountUsageBreakdown: subAccountUsageResponse,
            success: true
          }
        }
      },
    [
      lineItems,
      lineItemGroups,
      lineItemGroupsError,
      lineItemsError,
      loadUsageData,
      recalculateInvoiceMutator,
      loadSubAccountUsage
    ]
  )

  const voidInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await voidInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [voidInvoiceMutator]
  )

  const finaliseAndSendInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        // TODO: what about invoice number?
        const response = await finaliseAndSendInvoiceMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [finaliseAndSendInvoiceMutator]
  )

  const sendPaymentReminder = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await sendPaymentReminderMutator({
          id: invoiceId
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [sendPaymentReminderMutator]
  )

  const updatePaymentStatus = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        paymentStatus: InvoicePaymentStatus
      ): Promise<{ invoice: InvoiceModel | null; success: boolean }> => {
        const response = await paymentStatusMutator({
          id: invoiceId,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus
          }
        })

        if ('error' in response) {
          return {
            invoice: null,
            success: false
          }
        } else {
          return {
            invoice: response.data.value() ?? null,
            success: true
          }
        }
      },
    [paymentStatusMutator]
  )

  const createLineItemGroup =
    (invoiceId: InvoiceModel['id']) =>
    async (
      lineItemGroup: CreateLineItemGroupData
    ): Promise<{
      lineItemGroup: ApiLineItemGroup | null
      success: boolean
    }> => {
      const createdLineItemGroup = await createLineItemGroupMutator.mutateAsync(
        {
          invoiceId,
          body: {
            title: lineItemGroup.title,
            taxCategoryId: lineItemGroup.taxCategoryId
          }
        }
      )

      return {
        lineItemGroup: createdLineItemGroup,
        success: createdLineItemGroup !== null
      }
    }

  const createLineItem =
    (invoiceId: InvoiceModel['id']) =>
    async (
      lineItemGroupId: string,
      lineItem: CreateLineItemData
    ): Promise<{
      lineItem: ApiLineItem | null
      success: boolean
    }> => {
      const createdLineItem = await createLineItemMutator.mutateAsync({
        invoiceId,
        body: {
          groupId: lineItemGroupId.match(UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN)
            ? undefined
            : lineItemGroupId,
          title: lineItem.title,
          description: lineItem.description,
          quantity: lineItem.quantity.toString(),
          rate: lineItem.rate,
          taxRate: lineItem.taxRate.toString(),
          rateDisplay: lineItem.rateDisplay,
          externalIds: lineItem.externalIds,
          taxCategoryId: lineItem.taxCategoryId
        }
      })

      return {
        lineItem: createdLineItem,
        success: createdLineItem !== null
      }
    }

  const updateLineItem =
    (invoiceId: InvoiceModel['id']) =>
    async (
      lineItemGroupId: string,
      lineItem: UpdateLineItemData
    ): Promise<{
      lineItem: ApiLineItem | null
      success: boolean
    }> => {
      const updatedLineItem = await updateLineItemMutator.mutateAsync({
        invoiceId,
        id: lineItem.id,
        body: {
          groupId: lineItemGroupId.match(UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN)
            ? undefined
            : lineItemGroupId,
          title: lineItem.title,
          description: lineItem.description,
          quantity: lineItem.quantity.toString(),
          rate: lineItem.rate,
          taxRate: lineItem.taxRate.toString(),
          rateDisplay: lineItem.rateDisplay,
          externalIds: lineItem.externalIds,
          taxCategoryId: lineItem.taxCategoryId
        }
      })

      // Because of different version of the API is being used depending on the feature flag
      // we want to invalidate the only version of the line item groups query that is being used.
      // We only use the latest one because it's backwards compatible
      void apiQueryClient.invalidateQueries({
        queryKey: dashboardv99990101Client.getLineItemGroups.queryKey
      })
      void apiQueryClient.invalidateQueries({
        queryKey: dashboardv99990101Client.getAllLineItemGroups.queryKey
      })

      return {
        lineItem: updatedLineItem,
        success: updatedLineItem !== null
      }
    }

  const updateLineItemGroup =
    (invoiceId: InvoiceModel['id']) =>
    async (
      lineItemGroup: UpdateLineItemGroupData,
      lineItemsForGroup: UpdateLineItemData[] = []
    ): Promise<{
      lineItemGroup: ApiLineItemGroup | null
      lineItems: ApiLineItem[]
      success: boolean
    }> => {
      const updatedLineItemGroup = await updateLineItemGroupMutator.mutateAsync(
        {
          invoiceId,
          id: lineItemGroup.id,
          body: {
            title: lineItemGroup.title,
            taxCategoryId: lineItemGroup.taxCategoryId
          }
        }
      )

      const updatedLineItems = await Promise.all(
        lineItemsForGroup.map(lineItem => {
          return updateLineItem(invoiceId)(lineItemGroup.id, lineItem)
        })
      )

      return {
        lineItems: updatedLineItems
          .map(result => result.lineItem)
          .filter(Boolean) as ApiLineItem[],
        lineItemGroup: updatedLineItemGroup,
        success: updatedLineItemGroup !== null
      }
    }

  const deleteLineItem = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemId: string
      ): Promise<{
        success: boolean
      }> => {
        const response = await deleteLineItemMutator({
          invoice: invoiceId,
          id: lineItemId
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [deleteLineItemMutator]
  )

  const deleteLineItemGroup = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        lineItemGroupId: string
      ): Promise<{
        success: boolean
      }> => {
        const response = await deleteLineItemGroupMutator({
          invoice: invoiceId,
          id: lineItemGroupId
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [deleteLineItemGroupMutator]
  )

  const updateMemo = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newMemo: InvoiceModel['memo']
      ): Promise<{
        memo?: InvoiceModel['memo']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,

          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            memo: newMemo
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            memo: response.data.value()?.memo ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const updateDueDate = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newDueDate: InvoiceModel['dueDate']
      ): Promise<{
        dueDate?: InvoiceModel['dueDate']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,
          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            dueDate: newDueDate
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            dueDate: response.data.value()?.dueDate ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const updatePurchaseOrderNumber = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newPurchaseOrderNumber: InvoiceModel['purchaseOrderNumber']
      ): Promise<{
        purchaseOrderNumber?: InvoiceModel['purchaseOrderNumber']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,
          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            purchaseOrderNumber: newPurchaseOrderNumber
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            purchaseOrderNumber:
              response.data.value()?.purchaseOrderNumber ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const updateReference = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        newReference: InvoiceModel['reference']
      ): Promise<{
        reference?: InvoiceModel['reference']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,

          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            reference: newReference
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            reference: response.data.value()?.reference ?? '',
            success: true
          }
        }
      },
    [putInvoiceMutator]
  )

  const sendTestInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        email: string
      ): Promise<{ invoice?: InvoiceModel; success: boolean }> => {
        const response = await sendTestInvoiceMutator({
          id: invoiceId,
          testEmail: email
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            invoice: response.data.value(),
            success: true
          }
        }
      },
    [sendTestInvoiceMutator]
  )

  const syncToIntegration = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (service: IntegrationService): Promise<{ success: boolean }> => {
        const response = await syncInvoiceMutator({
          invoiceId: invoiceId,
          service: service
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [syncInvoiceMutator]
  )

  const createInvoiceSettings = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        paymentProvider: PaymentProvider
      ): Promise<{ success: boolean }> => {
        const response = await createInvoiceSettingsMutator({
          createInvoiceSettingsEndpointCreateInvoiceSettingsRequestModel: {
            invoiceId,
            paymentProvider
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        } else {
          return {
            success: true
          }
        }
      },
    [createInvoiceSettingsMutator]
  )

  const updateInvoice = useCallback(
    (invoiceId: InvoiceModel['id']) =>
      async (
        reducerState: InvoiceEditorReducerState['data'],
        payload: Partial<Pick<InvoiceModel, 'billingPeriod' | 'accountingDate'>>
      ): Promise<{
        dueDate?: InvoiceModel['dueDate']
        success: boolean
      }> => {
        const response = await putInvoiceMutator({
          id: invoiceId,

          updateInvoiceEndpointUpdateInvoiceRequestModel: {
            ...invoiceEditorApiAdapter.out.invoice.update(reducerState),
            ...payload
          }
        })

        if ('error' in response) {
          return {
            success: false
          }
        }

        return {
          ...payload,
          success: true
        }
      },
    [putInvoiceMutator]
  )

  return {
    createInvoiceSettings,
    createLineItemGroup,
    createLineItem,
    deleteLineItem,
    deleteLineItemGroup,
    finaliseAndSendInvoice,
    finaliseInvoice,
    recalculateInvoice,
    sendInvoice,
    sendPaymentReminder,
    sendTestInvoice,
    syncToIntegration,
    updateLineItemGroup,
    updateLineItem,
    updatePaymentStatus,
    voidInvoice,
    updateMemo,
    updateDueDate,
    updatePurchaseOrderNumber,
    updateReference,
    updateInvoice
  }
}
