import { Flex } from '@chakra-ui/react'
import {
  GreenGreen10,
  GreenGreen80,
  IndigoIndigo10,
  IndigoIndigo50,
  Lato12UppercaseBold,
  YellowYellow10,
  YellowYellow80
} from '@sequencehq/design-tokens'

interface MemberBadgeProps {
  status: string
}

const baseStyles = {
  borderRadius: '6px',
  padding: '0 6px',
  height: '24px',
  textTransform: 'capitalize',
  display: 'inline-flex',
  placeItems: 'center'
}

export const MemberBadge = ({ status }: MemberBadgeProps) => {
  switch (status) {
    case 'active':
      return (
        <Flex
          sx={baseStyles}
          bg={GreenGreen10}
          color={GreenGreen80}
          {...Lato12UppercaseBold}
        >
          Active
        </Flex>
      )
    case 'invited':
      return (
        <Flex
          sx={baseStyles}
          bg={YellowYellow10}
          color={YellowYellow80}
          {...Lato12UppercaseBold}
        >
          Pending
        </Flex>
      )
    case 'currentUser':
      return (
        <Flex
          sx={baseStyles}
          bg={IndigoIndigo10}
          color={IndigoIndigo50}
          {...Lato12UppercaseBold}
        >
          You
        </Flex>
      )
    default:
      return <></>
  }
}
