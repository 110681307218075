import { Link } from '@chakra-ui/react'
import { Lato13Regular } from '@sequencehq/design-tokens'
import { ArrowUpRightIcon } from '@heroicons/react/16/solid'
import { ComponentProps } from 'react'

const AvalaraTaxCodesSearchLink = (
  linkProps: Pick<ComponentProps<typeof Link>, 'pb'>
) => {
  return (
    <Link
      display="flex"
      alignItems="center"
      href="https://taxcode.avatax.avalara.com/"
      isExternal
      {...Lato13Regular}
      color="indigo.50"
      {...linkProps}
    >
      Search tax codes on avalara.com
      <ArrowUpRightIcon
        style={{ display: 'inline', marginLeft: '4px' }}
        height="16px"
        width="16px"
      />
    </Link>
  )
}

export default AvalaraTaxCodesSearchLink
