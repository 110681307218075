import { ElementType, FC, ReactNode } from 'react'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { useLinkCustomerToService } from './useLinkCustomerToService'
import { UnlinkCustomerModal } from '../UnlinkCustomerModal'
import { LinkCustomerModal } from '../LinkCustomerModal'
import { LinkEntityWidget } from '../../LinkEntityWidget'

export const LinkCustomerToServiceWidget: FC<{
  entityIcon?: ReactNode
  externalEntityLabel: string
  sequenceEntityLabel: string
  customerId: string
  service: IntegrationServices
  canUnlink?: boolean
  canLink?: boolean
  noLinkFoundText?: string
  linkModal?: ElementType<{
    customerId: string
    service: IntegrationServices
  }>
  unlinkModal?: ElementType<{
    customerId: string
    service: IntegrationServices
  }>
}> = props => {
  const hook = useLinkCustomerToService({
    customerId: props.customerId,
    service: props.service,
    canUnlink: props.canUnlink
  })

  const LinkModal = props.linkModal ?? LinkCustomerModal
  const UnlinkModal = props.unlinkModal ?? UnlinkCustomerModal

  return (
    <LinkEntityWidget
      data-testid={`${props.service}.linkCustomer`}
      canLink={props.canLink}
      externalEntityLabel={props.externalEntityLabel}
      sequenceEntityLabel={props.sequenceEntityLabel}
      isLoaded={hook.isLoaded}
      serviceName={hook.serviceName}
      linkedAccount={hook.linkedAccount.value ? hook.linkedAccount : null}
      noLinkFoundText={props.noLinkFoundText}
      linkModal={
        <LinkModal customerId={props.customerId} service={props.service} />
      }
      unlinkModal={
        <UnlinkModal customerId={props.customerId} service={props.service} />
      }
    />
  )
}
