import { useState } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  Text
} from '@chakra-ui/react'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import {
  Lato14Regular,
  Lato16Regular,
  Lato24Bold
} from '@sequencehq/design-tokens'
import * as Sentry from '@sentry/react'

const disableDemoEmailCapture = () =>
  window.localStorage.setItem('collectEmail', 'false')

const CollectEmailModal = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState(false)

  return (
    <ModalContent borderRadius="8px">
      <ModalBody px="40px">
        <Flex align="center" direction="column">
          <Image
            src="/img/logo.svg"
            height="64px"
            width="64px"
            alt="Sequence"
            mt="12px"
            mb={6}
          />
          <Text {...Lato24Bold} mb="16px">
            Welcome to Sequence
          </Text>
          <Text {...Lato16Regular} align="center">
            B2B companies of all sizes use Sequence to scale pricing, billing
            and invoicing workflows
          </Text>

          <Flex direction="column" gap="8px" align="center">
            <FormControl isInvalid={email.length > 0 && !emailValid}>
              <FormLabel {...Lato16Regular} mt={6} mb="8px">
                Enter your work email:
              </FormLabel>

              <Input
                type="email"
                value={email}
                placeholder="Email"
                onChange={e => {
                  setEmail(e.target.value)
                  setEmailValid(e.target.validity.valid)
                }}
              />

              {!emailValid && email.length > 0 && (
                <FormErrorMessage {...Lato14Regular}>
                  Please enter a valid email.
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter justifyContent="center" border="none" pb={0} mb="40px">
        <Button
          variant="component-library-secondary"
          mr={3}
          onClick={() => window.open('https://sequencehq.com', '_blank')}
        >
          Learn more
        </Button>
        <Button
          variant="component-library-primary"
          isDisabled={email.length === 0 || !emailValid}
          onClick={() => {
            if (email.length === 0 || !emailValid) {
              return
            }

            fetch(`https://www.sequencehq.com/api/addSubscriber`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email,
                location: 'demo'
              })
            })
              .then(res => {
                if (!res.ok) {
                  return
                } else {
                  disableDemoEmailCapture()
                  dispatch(closeOverlay())
                }
              })
              .catch(error => Sentry.captureException(error))
          }}
        >
          Explore demo
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default CollectEmailModal
