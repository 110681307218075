import { useOutletContext, Outlet } from 'react-router-dom'
import { InvoiceEditor } from './domainManagement/useInvoiceEditor'
import { useInvoiceEditorContext } from './hooks/useInvoiceEditorContext'

export const useInvoiceEditorOutletContext = () =>
  useOutletContext<InvoiceEditor>()

export const InvoiceEditorOutletProvider = () => {
  const invoiceEditorContext = useInvoiceEditorContext()

  return <Outlet context={invoiceEditorContext} />
}
