import {
  CubeReducerState,
  PostActionStage,
  ValidationResults
} from 'modules/Cube/domain/cube.domain.types'
import { publishQuoteValidation } from 'modules/Cube/domain/postActionStages/validation/validation.quote.publish'
import { saveQuoteValidation } from 'modules/Cube/domain/postActionStages/validation/validation.quote.save'
import { publishScheduleValidation } from 'modules/Cube/domain/postActionStages/validation/validation.schedule.publish'
import { acceptQuoteValidation } from 'modules/Cube/domain/postActionStages/validation/validation.quote.accept'
import { executeQuoteValidation } from 'modules/Cube/domain/postActionStages/validation/validation.quote.execute'
import { saveScheduleValidation } from 'modules/Cube/domain/postActionStages/validation/validation.schedule.save'
import { scheduleStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { deleteLatestQuoteDraftValidation } from 'modules/Cube/domain/postActionStages/validation/validation.quote.deleteLatestDraft'

export const validationResultSetHasErrors = (
  results: Partial<ValidationResults>
): boolean => {
  return Object.values(results)
    .filter(Boolean)
    .some(result => {
      if (
        'message' in result &&
        result.message &&
        typeof result.message === 'string'
      ) {
        return true
      }

      return validationResultSetHasErrors(result as Partial<ValidationResults>)
    })
}

const quoteValidationSet = (prevState: CubeReducerState) => ({
  save: saveQuoteValidation(prevState),
  publish: publishQuoteValidation(prevState),
  accept: acceptQuoteValidation(prevState),
  execute: executeQuoteValidation(prevState),
  deleteLatestDraft: deleteLatestQuoteDraftValidation(prevState)
})

const scheduleValidationSet = (prevState: CubeReducerState) => ({
  save: saveScheduleValidation(prevState),
  publish: publishScheduleValidation(prevState),
  accept: acceptQuoteValidation(prevState),
  execute: executeQuoteValidation(prevState),
  deleteLatestDraft: deleteLatestQuoteDraftValidation(prevState)
})

export const validation: PostActionStage = () => prevState => {
  const validationResults = scheduleStatusAdapter.out(
    prevState.data.common.status
  )
    ? scheduleValidationSet(prevState)
    : quoteValidationSet(prevState)

  return {
    ...prevState,
    queries: {
      ...prevState.queries,
      validation: {
        allValidationResults: validationResults,
        validationErrorsPresent: {
          save: validationResultSetHasErrors(validationResults.save),
          publish: validationResultSetHasErrors(validationResults.publish),
          accept: validationResultSetHasErrors(validationResults.accept),
          execute: validationResultSetHasErrors(validationResults.execute),
          deleteLatestDraft: validationResultSetHasErrors(
            validationResults.deleteLatestDraft
          )
        },
        activeValidationResults: prevState.editor.activeValidationSet
          ? validationResults[prevState.editor.activeValidationSet]
          : null
      }
    }
  }
}
