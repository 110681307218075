import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react'
import { GreyGrey80, Lato13Bold } from '@sequencehq/design-tokens'
import { FC } from 'react'

interface ProgressBarProps {
  label: string
  value: number
  total: number
  color: string
  backgroundColor: string
  tooltip?: React.ReactNode
}

export const ProgressBar: FC<ProgressBarProps> = ({
  label,
  value,
  total,
  color,
  backgroundColor,
  tooltip
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Text color={GreyGrey80} {...Lato13Bold}>
        {label}
      </Text>
      <Popover trigger="hover" placement="right-start">
        <PopoverTrigger>
          <Box
            padding="16px"
            minW={75}
            width={`${(value / total) * 100}%`}
            borderRadius="6px"
            backgroundColor={backgroundColor}
            color={color}
            fontWeight="700"
            borderWidth="1px"
          >
            {value}
          </Box>
        </PopoverTrigger>
        {tooltip && <PopoverContent width={250}>{tooltip}</PopoverContent>}
      </Popover>
    </Box>
  )
}
