import {
  SimpleModalUI,
  InputSelectControl,
  ModalContextProvider
} from '@sequencehq/core-components'
import { Customer } from './types'
import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'
import { integrationName } from 'lib/integrations/integrationName'
import { useCustomerIntegrationLinkForm } from './useCustomerIntegrationLinkForm'
import { Box, Flex, Text } from '@chakra-ui/react'
import Spinner from 'components/Loading/Spinner'
import { Label } from '@sequencehq/forms'
import { getLogo } from 'lib/integrations/logos'

export const CustomerIntegrationLinkModal = (props: {
  /**
   * The customer provided from `/customers` won't have contacts,
   * but from the `/customers/:id` page it will, so we need to accept both types.
   *
   * In the case that the object is missing `contacts`,
   * we'll fetch the customer within the `useCustomerIntegrationLinkForm` hook and use the returned contacts.
   */
  customer: Customer | Omit<Customer, 'contacts'>
  integrationService: IntegrationServices
}) => {
  const { fields, ready, onCancel, onSubmit, submitting } =
    useCustomerIntegrationLinkForm(props)

  return (
    <ModalContextProvider isOpen>
      <SimpleModalUI
        title={`Link ${props.customer.legalName} to ${integrationName(
          props.integrationService
        )}`}
        cancelButtonText="Cancel"
        submitButtonText="Link"
        submitDisabled={!ready || submitting}
        onSubmit={() => void onSubmit()}
        onCancel={onCancel}
      >
        {ready ? (
          <Flex flexDirection="column">
            <Flex gap="4px" as={Label}>
              {getLogo(props.integrationService, 18)}
              {`${integrationName(props.integrationService)} customers`}
            </Flex>

            <InputSelectControl
              options={fields.integrationId.options}
              placeholder="Choose customer"
              onChange={fields.integrationId.onChange}
            />
          </Flex>
        ) : (
          <Box overflow="hidden">
            <Flex direction="column" gap="16px">
              <Text>
                Loading customers from{' '}
                {integrationName(props.integrationService)}
                ...
              </Text>
              <Spinner></Spinner>
            </Flex>
          </Box>
        )}
      </SimpleModalUI>
    </ModalContextProvider>
  )
}
