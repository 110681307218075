/**
 * Only logs when the local storage item is enabled, to allow
 * for simple debugging in any environment without otherwise spamming.
 *
 * If a function is passed in, it will be evaluated. This is useful for
 * if we want to do some relatively expensive logging, but dont want this
 * to impact performance when logs aren't enabled.
 * @param args
 * @returns
 */
export const cubeDeveloperLog = (...args: unknown[]) => {
  if (localStorage.getItem('enable-cube-logs')) {
    // eslint-disable-next-line no-console
    return console.info(
      ...args.map(arg => (typeof arg === 'function' ? arg() : arg) as unknown)
    )
  }
  return
}
