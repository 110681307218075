import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback } from 'react'

type UsePricesFooter = () => {
  disabled: boolean
  addPrice: () => void
}
export const usePricesFooter: UsePricesFooter = () => {
  const pricingEditorContext = usePricingEditorContext()

  const disabled = !pricingEditorContext.derived.queries.canAddPrice
  const defaultPricingType = pricingEditorContext.data.defaultPricingType

  const addPrice = useCallback(() => {
    if (disabled) {
      return
    }

    pricingEditorContext.functions.addPrice({
      common: { pricingModel: defaultPricingType }
    })
  }, [defaultPricingType, disabled, pricingEditorContext.functions])

  return {
    disabled,
    addPrice
  }
}
