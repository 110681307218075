import { useCallback, useMemo, useState } from 'react'
import { FormFields, useForm } from '@sequencehq/utils'
import { isEmail, required } from '@sequencehq/validation'

type EmailFormFields = {
  email: string
}

type UseSendTestInvoiceModal = (props: {
  handlers: {
    onConfirm: (email: string) => void
  }
}) => {
  fields: FormFields<EmailFormFields>
  handlers: {
    send: () => void
  }
  queries: {
    canSend: boolean
  }
}

export const useSendTestInvoiceModal: UseSendTestInvoiceModal = props => {
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false)
  const form = useForm<EmailFormFields>({
    value: { email: '' },
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'email',
        validation: [required, isEmail]
      }
    ]
  })

  const handleSend = useCallback(() => {
    if (!form.queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    props.handlers.onConfirm(form.fields.email.value)
  }, [form.fields.email.value, form.queries.isValid, props.handlers])

  const canSend = useMemo(() => form.queries.isValid, [form.queries.isValid])

  return {
    fields: form.fields,
    handlers: {
      send: handleSend
    },
    queries: {
      canSend
    }
  }
}
