import type { TaxStatus } from '@sequencehq/core-models'
import type { CountriesAlpha2 } from '@sequencehq/api/commonEnums'
import type { ApiLineItemGroupTaxCategory } from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'
import { useTaxIntegrationProvider } from 'lib/hooks/useTaxIntegrationProvider.ts'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import { ArrowUpRightIcon } from '@heroicons/react/16/solid'
import { useNavigate } from 'react-router-dom'
import { SteppedInvoiceConfirmationModal } from 'InvoiceEditor/components/modals/SteppedInvoiceConfirmationModal.tsx'
import { AvalaraBlocker } from 'common/components/AvalaraBlocker/AvalaraBlocker'

interface Props {
  onClose: () => void
  onConfirm: () => Promise<void>
  submitting: boolean
  customerTaxStatus?: TaxStatus
  customerCountry?: CountriesAlpha2
  validationSummaryText: string
  confirmationSummaryText: string
  confirmTitleText: string
  confirmActionText: string
  taxCategoriesOnInvoice: ApiLineItemGroupTaxCategory[]
}

export const InvoiceConfirmationModal = ({
  onClose,
  onConfirm,
  submitting,
  customerTaxStatus,
  customerCountry,
  validationSummaryText,
  confirmationSummaryText,
  taxCategoriesOnInvoice,
  confirmTitleText,
  confirmActionText
}: Props) => {
  const { isAvalara } = useTaxIntegrationProvider()

  const { data: avalaraMappingsData } = useQuery(
    dashboardv99990101Client.getAllTaxCategoriesWithAvalaraMappings,
    undefined,
    {
      enabled: isAvalara
    }
  )
  const navigate = useNavigate()

  if (isAvalara) {
    const avalaraMappings = avalaraMappingsData?.items ?? []

    const unmappedTaxCategories =
      taxCategoriesOnInvoice
        .filter(taxCategory => {
          const mappingForCategory = avalaraMappings.find(
            mapping => mapping.taxCategoryId === taxCategory.id
          )

          return !mappingForCategory?.avalaraTaxCode
        })
        .filter(Boolean) ?? []

    if (unmappedTaxCategories.length !== 0) {
      return (
        <AvalaraBlocker
          confirmTitleText={confirmTitleText}
          onClose={onClose}
        >
          This invoice includes line items in a tax category that have not yet
          been mapped to an Avalara tax code.
        </AvalaraBlocker>
      )
    }
  }

  return (
    <SteppedInvoiceConfirmationModal
      onClose={onClose}
      onConfirm={onConfirm}
      submitting={submitting}
      customerTaxStatus={customerTaxStatus}
      customerCountry={customerCountry}
      validationSummaryText={validationSummaryText}
      confirmationSummaryText={confirmationSummaryText}
      confirmTitleText={confirmTitleText}
      confirmActionText={confirmActionText}
      taxCategoriesOnInvoice={taxCategoriesOnInvoice}
    />
  )
}
