import { Button, ButtonGroup } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FC } from 'react'

type SeatBalancesViewSelectorProps = {
  active: 'balances' | 'snapshots'
}

export const SeatBalancesViewSelector: FC<SeatBalancesViewSelectorProps> = ({
  active
}) => {
  return (
    <ButtonGroup
      size="sm"
      variant="buttonGroup"
      boxShadow="0px 1px 3px 0px rgba(20, 23, 28, 0.08), 0px 0 5px 0px rgba(0, 0, 0, 0.03) inset"
      borderRadius={8}
      isAttached
    >
      <Button isActive={active === 'balances'} as={Link} to="/seat-balances">
        Customer view
      </Button>
      <Button
        isActive={active === 'snapshots'}
        as={Link}
        to={`/seat-balances?view=snapshots`}
      >
        Seat events
      </Button>
    </ButtonGroup>
  )
}
