import {
  CubeReducerState,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getArchiveQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: !prevState.data.common.isArchived,
      enabled: !prevState.data.common.isArchived
    },
    reasons: []
  }
}
