import { ValidationError } from '@sequencehq/utils'
import deepmerge from 'deepmerge'
import { useMemo } from 'react'

export interface StripeInspectorItemData {
  acceptPaymentsViaStripe: {
    value: boolean
    disabled?: boolean
  }
}

export interface StripeInspectorFields {
  acceptPaymentsViaStripe: {
    value: boolean
    onChange: (value: boolean) => void
    isDisabled: boolean
    validationErrors: Array<ValidationError>
  }
}

type UseStripePaymentWidget = (props: {
  dataFromInterface: StripeInspectorItemData
  onChange: (data: StripeInspectorItemData) => void
  disabled?: boolean
}) => {
  fields: StripeInspectorFields
}

export const useStripePaymentWidget: UseStripePaymentWidget = ({
  dataFromInterface,
  onChange,
  disabled
}) => {
  const fields = useMemo(() => {
    return {
      acceptPaymentsViaStripe: {
        value: dataFromInterface.acceptPaymentsViaStripe.value,
        isDisabled:
          (disabled || dataFromInterface.acceptPaymentsViaStripe.disabled) ??
          false,
        onChange: (value: boolean) => {
          onChange(
            deepmerge(dataFromInterface, { acceptPaymentsViaStripe: { value } })
          )
        },
        validationErrors: []
      }
    }
  }, [dataFromInterface, onChange, disabled])

  return {
    fields
  }
}
