import { TaxRateModel } from '@sequencehq/core-models'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import {
  Schedule,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'
import { useMemo } from 'react'

type FieldConfig<
  TargetDataStructure extends Record<string, unknown>,
  Property extends keyof TargetDataStructure = keyof TargetDataStructure
> = {
  disabled: boolean
  validationErrors: ValidationResult[]
  value: TargetDataStructure[Property]
  onChange: (newValue: TargetDataStructure[Property]) => void
}

type UseAdditionalInformationWidget = () => {
  fieldsConfig: {
    autoIssueInvoices: FieldConfig<Schedule, 'autoIssueInvoices'>
    purchaseOrderNumber: FieldConfig<Schedule, 'purchaseOrderNumber'>
    reference: FieldConfig<Schedule, 'reference'> & {
      hidden: boolean
    }
    label: FieldConfig<Schedule, 'label'> & {
      hidden: boolean
    }
    taxRate: {
      options: {
        id: TaxRateModel['id']
        label: string
        value: TaxRateModel['id']
      }[]
      value: TaxRateModel['id']
      onChange: (newTaxRateId: TaxRateModel['id']) => void
      disabled: boolean
      validationErrors: ValidationResult[]
      hidden: boolean
    }
  }
}

export const useAdditionalInformationWidget: UseAdditionalInformationWidget =
  () => {
    const cubeContext = useCubeContext()

    const taxRateOptions = useMemo(() => {
      return Object.values(cubeContext.queries.availableTaxRates).map(
        taxRate => ({
          id: taxRate.id,
          label: taxRate.name,
          value: taxRate.id
        })
      )
    }, [cubeContext.queries.availableTaxRates])

    const fieldsConfig = useMemo(() => {
      return {
        autoIssueInvoices: {
          value: cubeContext.queries.rawData.data.schedule.autoIssueInvoices,
          onChange: (newValue: boolean) =>
            cubeContext.mutators.updateData({
              schedule: {
                autoIssueInvoices: newValue
              }
            }),
          validationErrors: [],
          disabled:
            !cubeContext.queries.availableFeatures.schedule.autoIssueInvoices
              .available.enabled
        },
        purchaseOrderNumber: {
          value: cubeContext.queries.rawData.data.schedule.purchaseOrderNumber,
          onChange: (newValue: string) =>
            cubeContext.mutators.updateData({
              schedule: {
                purchaseOrderNumber: newValue
              }
            }),
          validationErrors: [],
          disabled:
            !cubeContext.queries.availableFeatures.schedule.purchaseOrderNumber
              .available.enabled
        },
        reference: {
          hidden:
            !cubeContext.queries.availableFeatures.schedule.reference.available
              .visible,
          value: cubeContext.queries.rawData.data.schedule.reference,
          onChange: (newValue: string) =>
            cubeContext.mutators.updateData({
              schedule: {
                reference: newValue
              }
            }),
          validationErrors: [],
          disabled:
            !cubeContext.queries.availableFeatures.schedule.reference.available
              .enabled
        },
        label: {
          hidden:
            !cubeContext.queries.availableFeatures.schedule.label.available
              .visible,
          value: cubeContext.queries.rawData.data.schedule.label,
          onChange: (newValue: string) =>
            cubeContext.mutators.updateData({
              schedule: {
                label: newValue
              }
            }),
          validationErrors: [],
          disabled:
            !cubeContext.queries.availableFeatures.schedule.label.available
              .enabled
        },
        taxRate: {
          options: taxRateOptions,
          value: cubeContext.queries.rawData.data.schedule.taxRateId,
          onChange: (newTaxRate: TaxRateModel['id']) =>
            cubeContext.mutators.updateData({
              schedule: {
                taxRateId: newTaxRate
              }
            }),
          disabled:
            !cubeContext.queries.availableFeatures.schedule.taxRate.available
              .enabled,
          validationErrors:
            cubeContext.queries.validation.activeValidationResults?.schedule
              .taxRate ?? []
        }
      }
    }, [cubeContext, taxRateOptions])

    const enhancedFieldsConfig = useMemo(() => {
      return {
        ...fieldsConfig,
        taxRate: {
          ...fieldsConfig.taxRate,
          hidden:
            !cubeContext.queries.availableFeatures.schedule.taxRate.available
              .visible
        }
      }
    }, [
      cubeContext.queries.availableFeatures.schedule.taxRate.available.visible,
      fieldsConfig
    ])

    return {
      fieldsConfig: enhancedFieldsConfig
    }
  }
