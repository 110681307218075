import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import type { AvalaraIntegrationConfig } from 'Integrations/integrationsConfig/avalara/avalara.integration.config'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export const avalaraConfigurationLoader = async (): Promise<{
  data: AvalaraIntegrationConfig | null
  error: IntegrationsPortErrors | null
}> => {
  const taxIntegrationResult =
    await dashboardv99990101Client.getEnabledTaxIntegration()

  // For now, if Avalara isn't the tax provider we assume initial setup
  if (
    taxIntegrationResult.data?.taxIntegrationProvider?.toUpperCase() !==
    'AVALARA'
  ) {
    return {
      data: {
        accountId: '',
        obfuscatedLicenseKey: '',
        companyName: '',
        companyId: '',
        avalaraCompanyId: ''
      },
      error: null
    }
  }

  const avalaraCredentialsResponse =
    await dashboardv99990101Client.getStoredAvalaraCredentials()

  // If there are no stored credenitals, return empty data and treat it as a first time setup
  if (avalaraCredentialsResponse.data === undefined) {
    return {
      data: {
        accountId: '',
        obfuscatedLicenseKey: '',
        companyName: '',
        companyId: '',
        avalaraCompanyId: ''
      },
      error: null
    }
  }

  const avalaraCompaniesResponse =
    await dashboardv99990101Client.getAllAvalaraCompanies()

  const enabledAvalaraCompany = avalaraCompaniesResponse?.data?.items.find(
    company => company.isEnabled
  )

  const companyName = enabledAvalaraCompany?.companyName ?? ''
  // Company ID = Sequence ID for an Avalara company
  const companyId = enabledAvalaraCompany?.id ?? ''
  // Avalara Company ID = Avalara ID for an Avalara company
  const avalaraCompanyId = enabledAvalaraCompany?.companyId ?? ''

  return {
    data: {
      accountId: avalaraCredentialsResponse.data?.accountId ?? '',
      obfuscatedLicenseKey:
        avalaraCredentialsResponse.data?.obfuscatedLicenseKey ?? '',
      companyName,
      companyId,
      avalaraCompanyId
    },
    error: null
  }
}
