import { Box, Icon, Text } from '@chakra-ui/react'
import { Lato14Bold } from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  SelectField,
  SelectFieldWithActions,
  TextField
} from '@sequencehq/core-components'
import { useGraduatedPriceForm } from './useGraduatedPriceForm'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { useState } from 'react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import { PricingInformation } from '../common/PricingInformation'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { FixedTierTableInput } from '../inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from '../inputs/PercentageTierTableInput/PercentageTierTableInput'
import {
  AvailableStandardFrequency,
  UsageCalculationMode,
  UsageTierType
} from 'common/drawers/PricingEditor/domain'
import { useFlags } from 'launchdarkly-react-client-sdk'
import UsageCalculationPeriodDropdown from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown'
import { usageCalculationModeDescriptions } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const GraduatedPriceVariantForm = () => {
  const { fieldsConfig, currency, priceDetails } = useGraduatedPriceForm()
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)
  const flags = useFlags()

  return (
    <>
      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <SelectField
        label="List price"
        data-testid="graduated.listPriceId"
        value={fieldsConfig.listPriceId.value ?? ''}
        onChange={fieldsConfig.listPriceId.onChange}
        options={fieldsConfig.listPriceId.options}
        validationErrors={fieldsConfig.listPriceId.validationErrors}
        isDisabled={fieldsConfig.listPriceId.disabled}
        placeholder="Select list price"
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="graduated.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      {!fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="graduated.currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}

      <Box paddingBottom="8px">
        <HorizontalSelectorField
          label="Type"
          value={fieldsConfig.usageTierType.value}
          options={fieldsConfig.usageTierType.options}
          onChange={newValue =>
            fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
          }
          validationErrors={fieldsConfig.usageTierType.validationErrors}
          isDisabled={fieldsConfig.usageTierType.disabled}
        />

        <Box
          paddingTop="8px"
          marginBottom="0px"
          paddingBottom="0px"
          width="100%"
        >
          <Box {...Lato14Bold} marginBottom="8px">
            Tiers
          </Box>
          {fieldsConfig.usageTierType.value === 'FIXED' ? (
            <FixedTierTableInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          ) : (
            <PercentageTierTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          )}
        </Box>
      </Box>

      <ExternalLedgerForm />

      <Box
        display="flex"
        gap="4px"
        my="16px"
        padding="8px 0px"
        cursor="pointer"
        onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
      >
        <Text {...Lato14Bold} color="#5F5CFF">
          Show additional fields
        </Text>
        {showAdditionalOptions ? (
          <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
        ) : (
          <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
        )}
      </Box>
      <Box
        width="100%"
        marginBottom="16px"
        visibility={showAdditionalOptions ? 'visible' : 'hidden'}
      >
        <PricingInformation
          value={fieldsConfig.pricingModel.value}
          isDisabled={fieldsConfig.pricingModel.disabled}
          options={fieldsConfig.pricingModel.options}
          validationErrors={fieldsConfig.pricingModel.validationErrors}
          onChange={fieldsConfig.pricingModel.onChange}
          description={fieldsConfig.pricingModel.description}
        />

        <HorizontalSelectorField
          label="Frequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <SelectFieldWithActions
          label="Usage metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />

        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>

        {!flags.useUsageResetCadences && (
          <SelectField
            label="Usage calculation mode"
            description={
              usageCalculationModeDescriptions[
                fieldsConfig.usageCalculationMode.value
              ]
            }
            options={fieldsConfig.usageCalculationMode.options}
            validationErrors={
              fieldsConfig.usageCalculationMode.validationErrors
            }
            value={fieldsConfig.usageCalculationMode.value}
            onChange={newValue =>
              fieldsConfig.usageCalculationMode.onChange(
                newValue as UsageCalculationMode
              )
            }
            isDisabled={fieldsConfig.usageCalculationMode.disabled}
            placeholder="Select usage calculation mode"
          />
        )}
        {flags.useUsageResetCadences && (
          <UsageCalculationPeriodDropdown
            options={fieldsConfig.usageCalculationPeriod.options}
            value={fieldsConfig.usageCalculationPeriod.value}
            isDisabled={fieldsConfig.usageCalculationPeriod.disabled}
            onChange={fieldsConfig.usageCalculationPeriod.onChange}
            validationErrors={
              fieldsConfig.usageCalculationPeriod.validationErrors
            }
          />
        )}
      </Box>
    </>
  )
}
