import { ReactNode } from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { useGetApiSelfQuery } from 'features/api'

type CurrentUserSelfRequestWrapperProps = {
  children: (userId: string) => ReactNode
}

export const CurrentUserSelfRequestWrapper = ({
  children
}: CurrentUserSelfRequestWrapperProps) => {
  const { data: selfResponse, isFetching: isFetchingSelf } =
    useGetApiSelfQuery(undefined)

  const self = selfResponse?.value()

  if (!self || isFetchingSelf) {
    return (
      <Center width="100%" height="100%">
        <Spinner />
      </Center>
    )
  }

  return children(self.sequenceUser.id)
}
