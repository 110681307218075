import {
  RecalculateInvoiceFormMutation,
  RecalculateInvoiceFormProps
} from 'components/RecalculateInvoice/types'
import { usePostInvoicesByIdRecalculateMutation } from 'features/api'
import { createFormModal } from 'components/Form/createFormModal'
import RecalculateInvoiceForm from 'components/RecalculateInvoice/RecalculateInvoiceForm'

const RecalculateInvoiceModal = createFormModal<
  RecalculateInvoiceFormMutation,
  RecalculateInvoiceFormProps
>({
  FormComponent: RecalculateInvoiceForm,
  useMutation: usePostInvoicesByIdRecalculateMutation,
  modalTitle: 'Recalculate Invoice',
  successMessage: 'Invoice recalculated',
  submitTitle: 'Recalculate invoice',
  formId: 'recalculateInvoiceForm'
})

export default RecalculateInvoiceModal
