import { Flex } from '@chakra-ui/react'
import AvalaraTaxCategoriesMappingSection from 'Settings/view/taxRatesSettings/avalara/AvalaraTaxCategoriesMappingSection'
import ManagedByAvalaraTaxRatesSection from 'Settings/view/taxRatesSettings/avalara/ManagedByAvalaraTaxRatesSection'

export const AvalaraTaxRatesSettings = () => {
  return (
    <Flex
      data-testid="settings.avalaraTaxRates"
      width="100%"
      flexDirection="column"
      overflow="auto"
      gap={4}
      p={4}
    >
      <AvalaraTaxCategoriesMappingSection />

      <ManagedByAvalaraTaxRatesSection />
    </Flex>
  )
}
