import { LineItemGroup } from '@sequencehq/core-components'
import { Currency } from '@sequencehq/core-models'
import useToggle from 'lib/hooks/useToggle'
import { FC } from 'react'
import CreateCreditNoteLineItemDrawer from './drawers/CreateCreditNoteLineItemDrawer'

type Props = {
  creditNoteId: string
  groupId: string
  currency: Currency
}

export const CreditNoteAddLineItem: FC<Props> = ({
  creditNoteId,
  groupId,
  currency
}) => {
  const [isOpen, setIsOpen] = useToggle(false)

  return (
    <>
      <LineItemGroup.AddLineItem onClick={() => setIsOpen(true)} />
      <CreateCreditNoteLineItemDrawer
        isOpen={isOpen}
        creditNoteCurrency={currency}
        creditNoteId={creditNoteId}
        groupId={groupId}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}
