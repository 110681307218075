import { Box } from '@chakra-ui/react'
import { Button, PageTitle, StandardLayout } from '@sequencehq/core-components'
import { ArrowDownTrayIcon } from '@heroicons/react/16/solid'

export const DeferredRevenueHeader = () => {
  return (
    <Box>
      <StandardLayout.Header title="Deferred revenue" />
    </Box>
  )
}
