import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { Image } from '@chakra-ui/react'
import { UIMatch, useMatches, useNavigate } from 'react-router-dom'

interface IntegrationItem {
  id: string
  logo: FunctionComponent<{ height: string; width: string }>
  label: string
  active: boolean
  onClick: () => void
  status: 'active' | 'warning'
}

type UseIntegrationsNavigation = () => {
  integrationsList: {
    isExpanded: boolean
    toggleExpand: () => void
    integrations: Array<IntegrationItem>
  }
  loading: boolean
  error: boolean
  reloadIntegrations: () => void
}

const loadExpansionState = (): boolean => {
  try {
    return localStorage.getItem('integrationsNavigationExpanded') === 'true'
  } catch {
    return true
  }
}

const saveExpansionState = (newState: boolean) => {
  try {
    localStorage.setItem('integrationsNavigationExpanded', newState.toString())
    return
  } catch {
    return
  }
}

export const useIntegrationsNavigation: UseIntegrationsNavigation = () => {
  const ctx = useIntegrationsDomainContext()
  const matches = useMatches()
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(loadExpansionState())

  const toggleListExpanded = useCallback(() => {
    setIsExpanded(prev => {
      saveExpansionState(!prev)
      return !prev
    })
  }, [])

  const reloadIntegrations = useCallback(() => {
    void ctx.mutators.external.in.load()
  }, [ctx.mutators.external.in])

  const integrationsList = useMemo(
    () => ({
      isExpanded: Boolean(
        isExpanded ||
          matches.find((match: UIMatch<unknown, unknown>) =>
            match.pathname.includes('integration-management')
          )
      ),
      toggleExpand: toggleListExpanded,
      integrations: ctx.queries.rawData.editor.loaded
        ? [
            ...ctx.queries.activeIntegrations.map(int => ({
              id: int.id,
              logo: ({ height, width }: { height: string; width: string }) => (
                <Image src={int.smallLogoUrl} height={height} width={width} />
              ),
              label: int.title,
              active: Boolean(
                matches.find(
                  (match: UIMatch<unknown, unknown>) =>
                    match.params?.integrationId === int.id &&
                    match.pathname.includes('integration-management')
                )
              ),
              onClick: () => {
                navigate(`/integration-management/${int.id}`)
              },
              status: int.authFailure
                ? ('warning' as const)
                : ('active' as const)
            }))
          ]
        : []
    }),
    [
      isExpanded,
      toggleListExpanded,
      ctx.queries.activeIntegrations,
      navigate,
      matches,
      ctx.queries.rawData.editor.loaded
    ]
  )

  return {
    loading: !ctx.queries.rawData.editor.loaded,
    error: ctx.queries.rawData.editor.loadingError,
    reloadIntegrations,
    integrationsList
  }
}
