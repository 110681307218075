import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CubeStatus } from 'Cube/domain/cube.domain.types'
import { useMemo } from 'react'
import { match } from 'ts-pattern'

type UseValidationBanner = () => {
  isVisible: boolean
  validationMessages: string[]
  nextStatusLabel: string
}

export const useValidationBanner: UseValidationBanner = () => {
  const ctx = useCubeContext()

  const validationMessages: string[] = useMemo(() => {
    const activeValidationResults =
      ctx.queries.validation.activeValidationResults

    if (!activeValidationResults) {
      return []
    }

    const allValidationMessages = [
      ...Object.entries(activeValidationResults.common).flatMap(
        ([, validationResults]) => {
          /**
           * Hides any validations related to the phase block as they will still be displayed inline
           */
          if (!validationResults || !Array.isArray(validationResults)) {
            return undefined
          }

          return validationResults.map(({ message }) => message)
        }
      ),
      ...Object.entries(activeValidationResults.quote).flatMap(
        ([, validationResults]) => {
          if (!validationResults) {
            return undefined
          }

          return validationResults.map(({ message }) => message)
        }
      )
    ].filter(Boolean) as string[]

    return Array.from([...new Set(allValidationMessages)])
  }, [ctx.queries.validation.activeValidationResults])

  const isVisible = useMemo(
    () =>
      Boolean(ctx.queries.validation.activeValidationResults) &&
      validationMessages.length > 0,
    [ctx.queries.validation.activeValidationResults, validationMessages.length]
  )

  const nextStatusLabel: string = useMemo(() => {
    return match(ctx.queries.rawData.data.common.status)
      .with(CubeStatus.QuoteDraft, () => 'publishing')
      .with(CubeStatus.QuotePublished, () => 'accepting')
      .with(CubeStatus.QuoteAccepted, () => 'executing')
      .with(
        CubeStatus.QuoteExecuted,
        CubeStatus.QuoteReadyToSign,
        CubeStatus.ScheduleActive,
        CubeStatus.ScheduleCancelled,
        CubeStatus.ScheduleCompleted,
        CubeStatus.ScheduleDraft,
        CubeStatus.SchedulePending,
        CubeStatus.ScheduleSuspended,
        CubeStatus.ScheduleUnsaved,
        () => ''
      )
      .exhaustive()
  }, [ctx.queries.rawData.data.common.status])

  return {
    isVisible,
    validationMessages,
    nextStatusLabel
  }
}
