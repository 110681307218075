import {
  CreateUsageMetricDrawer,
  UsageMetricProvider
} from 'components/CreateUsageMetric'
import { useListPriceEditorUsageMetricsDrawerConnector } from 'modules/Products/drawers/ListPriceEditor/components/UsageMetricsDrawer/useUsageMetricsDrawerConnector'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * @returns
 */
export const ListPriceEditorUsageMetricsRouteComponent = () => {
  const usageMetricDrawerProps = useListPriceEditorUsageMetricsDrawerConnector()

  return (
    <UsageMetricProvider>
      <CreateUsageMetricDrawer {...usageMetricDrawerProps} />
    </UsageMetricProvider>
  )
}
