import { CustomerContact } from '../domain/CustomerContacts.domain.types'

export const sortContacts = (
  contacts: CustomerContact[]
): CustomerContact[] => {
  /**
   * Sorts an array of contacts in the following way:
   * - PRIMARY contact first
   * - STANDARD contacts next
   * - NONE contacts last
   *
   * Within each group, names are sorted alphabetically, with contacts with no names appearing last.
   * If a contact has the same name as another contact in the group, alphabetise by email
   */

  const sortFunc = (a: CustomerContact, b: CustomerContact) => {
    // Sort by email if both contacts name no name
    if (!a.name && !b.name) {
      return a.email.localeCompare(b.email)
    }

    // Contacts with names should come before contacts without names
    if (!a.name) {
      return 1
    }

    if (!b.name) {
      return -1
    }

    // Sort by name
    const nameComparison = a.name.localeCompare(b.name)

    if (nameComparison !== 0) {
      return nameComparison
    }

    // Sort by email is name is the same
    return a.email.localeCompare(b.email)
  }

  const contactPrimary = contacts
    .filter(contact => contact.billingPreference === 'PRIMARY')
    .sort(sortFunc)

  const contactsStandard = contacts
    .filter(contact => contact.billingPreference === 'STANDARD')
    .sort(sortFunc)

  const contactsNone = contacts
    .filter(contact => contact.billingPreference === 'NONE')
    .sort(sortFunc)

  return [...contactPrimary, ...contactsStandard, ...contactsNone]
}
