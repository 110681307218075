import { FC } from 'react'

interface SlackIconProps {
  width?: number
  height?: number
}

export const SlackIcon: FC<SlackIconProps> = ({ width = 48, height = 48 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6101_13798)">
      <path
        d="M18.2807 38.2363C18.2807 40.9953 16.0508 43.2253 13.2917 43.2253C10.5327 43.2253 8.30273 40.9953 8.30273 38.2363C8.30273 35.4772 10.5327 33.2473 13.2917 33.2473H18.2807V38.2363ZM20.7752 38.2363C20.7752 35.4772 23.0051 33.2473 25.7642 33.2473C28.5232 33.2473 30.7531 35.4772 30.7531 38.2363V50.7087C30.7531 53.4678 28.5232 55.6977 25.7642 55.6977C23.0051 55.6977 20.7752 53.4678 20.7752 50.7087V38.2363Z"
        fill="#E01E5A"
      />
      <path
        d="M25.7629 18.2048C23.0038 18.2048 20.7739 15.9748 20.7739 13.2158C20.7739 10.4567 23.0038 8.22681 25.7629 8.22681C28.5219 8.22681 30.7519 10.4567 30.7519 13.2158V18.2048H25.7629ZM25.7629 20.737C28.5219 20.737 30.7519 22.967 30.7519 25.726C30.7519 28.4851 28.5219 30.715 25.7629 30.715H13.2526C10.4936 30.715 8.26367 28.4851 8.26367 25.726C8.26367 22.967 10.4936 20.737 13.2526 20.737H25.7629Z"
        fill="#36C5F0"
      />
      <path
        d="M45.7576 25.726C45.7576 22.967 47.9875 20.737 50.7466 20.737C53.5056 20.737 55.7355 22.967 55.7355 25.726C55.7355 28.4851 53.5056 30.715 50.7466 30.715H45.7576V25.726ZM43.2631 25.726C43.2631 28.4851 41.0332 30.715 38.2741 30.715C35.5151 30.715 33.2852 28.4851 33.2852 25.726V13.2158C33.2852 10.4567 35.5151 8.22681 38.2741 8.22681C41.0332 8.22681 43.2631 10.4567 43.2631 13.2158V25.726Z"
        fill="#2EB67D"
      />
      <path
        d="M38.2741 45.7198C41.0332 45.7198 43.2631 47.9497 43.2631 50.7087C43.2631 53.4678 41.0332 55.6977 38.2741 55.6977C35.5151 55.6977 33.2852 53.4678 33.2852 50.7087V45.7198H38.2741ZM38.2741 43.2253C35.5151 43.2253 33.2852 40.9953 33.2852 38.2363C33.2852 35.4772 35.5151 33.2473 38.2741 33.2473H50.7844C53.5434 33.2473 55.7733 35.4772 55.7733 38.2363C55.7733 40.9953 53.5434 43.2253 50.7844 43.2253H38.2741Z"
        fill="#ECB22E"
      />
    </g>
    <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#F1F4F8" />
    <defs>
      <clipPath id="clip0_6101_13798">
        <rect width="48" height="48" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)
