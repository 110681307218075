import { FC } from 'react'
import { Asset } from '../attachmentReducer'
import { GreyGrey60, GreyGrey70 } from '@sequencehq/design-tokens'
import { Center } from '@chakra-ui/react'
import { DocumentIcon, EllipsisHorizontalIcon } from '@heroicons/react/16/solid'
import {
  Button,
  SequenceMenu,
  SequenceMenuItem
} from '@sequencehq/core-components'
import { AttachmentUI } from './AttachmentUI'

export const ExistingAttachment: FC<{
  attachment: Asset
  onClickDelete: () => void
  onClickPreview: () => void
  disableModifyAttachments: false | { reason: string }
}> = ({
  attachment,
  onClickDelete,
  onClickPreview,
  disableModifyAttachments
}) => {
  return (
    <AttachmentUI
      name={attachment.fileName}
      fileSizeBytes={attachment.fileSizeBytes}
      icon={<DocumentIcon width={16} color={GreyGrey70} />}
      onClick={onClickPreview}
      controls={
        <SequenceMenu
          alignment="bottom-right"
          menuButton={
            <Button variant="ghost" size="24px">
              <Center>
                <EllipsisHorizontalIcon
                  width="16px"
                  height="16px"
                  color={GreyGrey60}
                />
              </Center>
            </Button>
          }
          items={[
            <SequenceMenuItem
              key="delete"
              uuid="delete"
              label="Delete attachment"
              isDisabled={!!disableModifyAttachments}
              onClick={e => {
                e.stopPropagation()
                onClickDelete()
              }}
              style={{ color: 'red' }}
            />
          ]}
        />
      }
    />
  )
}
