import {
  Box,
  Flex,
  Image,
  UnorderedList,
  ListItem,
  Text
} from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import coverImage from './equalsCover.png'

export const EqualsIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={coverImage}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Text {...Lato13Regular} color={GreyGrey90}>
        Easily generate dynamic, customizable ARR dashboards using your Sequence
        data.
      </Text>
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        <UnorderedList spacing={2}>
          <ListItem>
            <strong>Sync data effortlessly:</strong> Automatically pull ARR
            metrics, subscription details, and customer insights from Sequence
            into Equals.
          </ListItem>
          <ListItem>
            <strong>Create tailored dashboards:</strong> Build and customise
            dashboards to track key metrics like MRR growth, churn, and net
            revenue retention.
          </ListItem>
          <ListItem>
            <strong>Collaborate seamlessly:</strong> Share dashboards and
            collaborate on insights directly within Equals for better
            decision-making across teams.
          </ListItem>
          <ListItem>
            <strong>Enhance reporting:</strong> Combine Sequence data with other
            sources in Equals for richer, more comprehensive reporting.
          </ListItem>
        </UnorderedList>
      </Box>
      <Text {...Lato13Regular} color={GreyGrey90}>
        Turn your Sequence data into actionable insights and stay on top of your
        revenue goals with ease.
      </Text>
    </Flex>
  )
}
