import {
  Checkbox,
  CheckboxProps,
  NumberInput,
  NumberInputProps,
  TextareaProps
} from '@chakra-ui/react'
import { createFormField } from '@sequencehq/forms'
import {
  DateInput,
  SelectProps,
  Select,
  TableCell,
  Textarea,
  TabSelect,
  TabRadioGroup,
  Headline
} from 'components/FormInputs'

export const CheckboxField = createFormField<
  CheckboxProps & { disabled?: boolean }
>(Checkbox)
export const DateInputField = createFormField(DateInput)
export const HeadlineField = createFormField(Headline)
export const NumberInputField = createFormField<NumberInputProps>(NumberInput)
export const SelectField = createFormField<SelectProps>(Select)
export const TableCellField = createFormField(TableCell)
export const TabRadioGroupField = createFormField(TabRadioGroup)
export const TabSelectField = createFormField(TabSelect)
export const TextareaField = createFormField<TextareaProps>(Textarea)
