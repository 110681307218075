import { Currency } from '@sequencehq/core-models'
import {
  greaterThan,
  greaterThanEqualTo,
  required
} from '@sequencehq/validation'
import { FormFields, ValidatorWithMetadata, useForm } from '@sequencehq/utils'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  LinearPriceType,
  ListPriceEditorFormData
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { defaultAvailableFrequenciesOptions } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/constants'
import {
  UsageParameterDataModel,
  useUsageMetricParameterFieldConfig
} from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/utils/useUsageMetricParameterFieldConfig'

type LinearPriceFields = ListPriceEditorFormData['LINEAR']
type LinearPriceTypeObject = {
  label: string
  value: LinearPriceType
}

type UseLinearPriceForm = () => {
  fieldsConfig: Omit<FormFields<LinearPriceFields>, 'usageMetricId'> & {
    usageMetricId: FormFields<LinearPriceFields>['usageMetricId'] & {
      onAddNew: () => void
    }
  }
  formData: LinearPriceFields
  currency: Currency
}

const availableLinearPriceTypes: LinearPriceTypeObject[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const validateOptionalNotNegative: ValidatorWithMetadata<
  string,
  Record<string, unknown>,
  Record<string, unknown>
> = value => {
  return value && greaterThanEqualTo(0)(value)
    ? [
        {
          message: 'Cannot be negative',
          metadata: {}
        }
      ]
    : []
}

export const minMaxPriceValidator: ValidatorWithMetadata<
  string,
  {
    minPrice: string
  },
  {
    minPrice: string
  }
> = (maxPrice, additionalData) => {
  return additionalData.minPrice &&
    maxPrice &&
    greaterThan(+additionalData.minPrice)(maxPrice)
    ? [
        {
          message: 'Max. must be greater than min.',
          metadata: {
            minPrice: additionalData.minPrice
          }
        }
      ]
    : []
}

export const useLinearPriceForm: UseLinearPriceForm = () => {
  const navigate = useNavigate()
  const listPriceEditorContext = useListPriceEditorContext()

  /**
   * Define options for the select fields
   */
  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return listPriceEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [listPriceEditorContext.configuration.availableStandardFrequencies])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(listPriceEditorContext.data.metrics).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [listPriceEditorContext.data.metrics])

  /**
   * Initialise the form management
   */
  const checkIfLinearFieldDisabledByDomain = useCallback(
    ({
      property
    }: {
      property: keyof (LinearPriceFields & UsageParameterDataModel)
    }) =>
      listPriceEditorContext.functions.fieldIsDisabled(`LINEAR.${property}`),
    [listPriceEditorContext]
  )

  const parameterFields = useUsageMetricParameterFieldConfig<LinearPriceFields>(
    listPriceEditorContext.data.formData.LINEAR.usageMetricId,
    checkIfLinearFieldDisabledByDomain
  )

  const { fields, queries } = useForm<
    Omit<LinearPriceFields, 'parameters'> & UsageParameterDataModel
  >({
    value: listPriceEditorContext.data.formData.LINEAR,
    fieldConfiguration: [
      {
        property: 'usageMetricId',
        disabled: checkIfLinearFieldDisabledByDomain,
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'billingFrequency',
        disabled: checkIfLinearFieldDisabledByDomain,
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'price',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'PERCENTAGE',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'minPrice',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'FIXED',
        validation: [validateOptionalNotNegative]
      },
      {
        property: 'maxPrice',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'FIXED',
        validation: [validateOptionalNotNegative, minMaxPriceValidator]
      },
      {
        property: 'percentage',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'FIXED',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'linearPriceType',
        disabled: checkIfLinearFieldDisabledByDomain,
        options: availableLinearPriceTypes,
        validation: [required]
      },
      ...parameterFields.fields
    ],
    showValidationErrors: listPriceEditorContext.editor.showValidationErrors,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      listPriceEditorContext.functions.updateEditor({
        formsValid: {
          LINEAR: isValid
        }
      })
    },
    onChange: newData => {
      listPriceEditorContext.functions.updateFormData({
        LINEAR: newData
      })
    }
  })

  /**
   * Enhance the base field config with some extra bits and pieces
   */
  const processedFieldsConfig = useMemo(() => {
    return {
      ...fields,
      usageMetricId: {
        ...fields.usageMetricId,
        onAddNew: () => navigate('./metrics/new')
      }
    }
  }, [fields, navigate])

  return {
    fieldsConfig: processedFieldsConfig,
    formData: queries.formData,
    currency: listPriceEditorContext.data.formData.common.currency
  }
}
