export type DUMMY_SPARKLINE_GRAPH_TYPE = {
  date: string
  amount: number
}

export const DUMMY_SPARKLINE_GRAPH_DATA = [
  { date: '2023-01-01', amount: 100 },
  { date: '2023-02-01', amount: 200 },
  { date: '2023-03-01', amount: 300 },
  { date: '2023-04-01', amount: 280 },
  { date: '2023-05-01', amount: 250 },
  { date: '2023-06-01', amount: 300 },
  { date: '2023-07-01', amount: 400 },
  { date: '2023-08-01', amount: 420 },
  { date: '2023-09-01', amount: 400 }
]
