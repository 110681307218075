import {
  AddProductFormState,
  UpdateFormDataAction
} from 'Products/drawers/AddProduct/domain/addProduct.type'
import deepmerge from 'deepmerge'

export const updateFormData = (
  prevState: AddProductFormState,
  action: UpdateFormDataAction
): AddProductFormState => {
  return {
    ...prevState,
    formData: deepmerge(prevState.formData, action.payload, {
      arrayMerge: (_, source: unknown[]) => source
    }) as AddProductFormState['formData']
  }
}
