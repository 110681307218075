import { Grid, GridItem } from '@chakra-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold,
  YellowYellow50
} from '@sequencehq/design-tokens'
import { createElement } from 'react'

interface IntegrationsNavLinkProps {
  onClick?: () => void
  active?: boolean
  logo: React.ComponentType<{ height: string; width: string }>
  label: string
  status?: 'active' | 'warning'
}

/**
 * TODO: Reconcile this NavLink with the SubNavLink used in NavigationsV3,
 * and the link in the integrations browser modal.
 * @param props
 * @returns
 */
export const IntegrationsNavLink = (props: IntegrationsNavLinkProps) => {
  return (
    <Grid
      padding="0 8px"
      templateColumns="auto 1fr auto"
      onClick={props.onClick}
      height="32px"
      gap="8px"
      borderRadius="6px"
      alignContent="center"
      cursor="pointer"
      color={props.active ? GreyGrey90 : GreyGrey70}
      backgroundColor={props.active ? GreyGrey20 : 'transparent'}
      _hover={{
        backgroundColor: GreyGrey20
      }}
    >
      <GridItem
        height="fit-content"
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        {createElement(props.logo, {
          height: '16px',
          width: '16px'
        })}
      </GridItem>
      <GridItem
        {...Lato13Bold}
        color={props.active ? GreyGrey90 : GreyGrey70}
        data-testid={`navlink.${props.label}`}
      >
        {props.label}
      </GridItem>
      {props.status === 'warning' && (
        <GridItem>
          <ExclamationTriangleIcon
            data-testid="navlink-warning"
            height="16px"
            width="16px"
            style={{
              position: 'relative',
              top: '1px'
            }}
            color={YellowYellow50}
          />
        </GridItem>
      )}
    </Grid>
  )
}
