import { ListBlockConfig } from '@sequencehq/quote-content'
import { BlockAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/blockAdapter.types'

export const numberListAdapter: BlockAdapter<'numberedListItem'> = {
  in: block => {
    return (block.config as ListBlockConfig).items.map(item => {
      return {
        type: 'numberedListItem',
        content: item
      }
    })
  },
  out: () => block => {
    return {
      blockType: 'numberList',
      config: {
        items: block.content
          .filter(item => item.type === 'text' && item.text)
          .map(item => (item.type === 'text' ? item.text : ''))
      },
      version: 1
    }
  }
}
