import { FC } from 'react'

interface GcpLogsIconProps {
  width?: number
  height?: number
}

export const GcpLogsIcon: FC<GcpLogsIconProps> = ({
  width = 16,
  height = 16
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 81 76"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g stroke="none">
      <path fill="white" d="M0 0h24.127v23.492H0z" />
      <path
        d="M27.937 3.81H80v15.873H27.937zm0 27.936H80v15.873H27.937zm0 27.302H80v15.873H27.937z"
        fill="white"
      />
      <path
        fill="white"
        d="M16.508 43.175v20.317h11.429v7.619H16.508h-3.81-4.444V23.492h8.254v12.063h11.429v7.619z"
      />
    </g>
  </svg>
)
