export const NumberedListIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2340_1043)">
        <path
          d="M6.25 7.25C6.05109 7.25 5.86032 7.32902 5.71967 7.46967C5.57902 7.61032 5.5 7.80109 5.5 8C5.5 8.19891 5.57902 8.38968 5.71967 8.53033C5.86032 8.67098 6.05109 8.75 6.25 8.75H13.25C13.4489 8.75 13.6397 8.67098 13.7803 8.53033C13.921 8.38968 14 8.19891 14 8C14 7.80109 13.921 7.61032 13.7803 7.46967C13.6397 7.32902 13.4489 7.25 13.25 7.25H6.25ZM6.25 11.5C6.05109 11.5 5.86032 11.579 5.71967 11.7197C5.57902 11.8603 5.5 12.0511 5.5 12.25C5.5 12.4489 5.57902 12.6397 5.71967 12.7803C5.86032 12.921 6.05109 13 6.25 13H13.25C13.4489 13 13.6397 12.921 13.7803 12.7803C13.921 12.6397 14 12.4489 14 12.25C14 12.0511 13.921 11.8603 13.7803 11.7197C13.6397 11.579 13.4489 11.5 13.25 11.5H6.25Z"
          fill="currentColor"
        />
        <path
          d="M5.71967 3.21967C5.86032 3.07902 6.05109 3 6.25 3H13.25C13.4489 3 13.6397 3.07902 13.7803 3.21967C13.921 3.36032 14 3.55109 14 3.75C14 3.94891 13.921 4.13968 13.7803 4.28033C13.6397 4.42098 13.4489 4.5 13.25 4.5H6.25C6.05109 4.5 5.86032 4.42098 5.71967 4.28033C5.57902 4.13968 5.5 3.94891 5.5 3.75C5.5 3.55109 5.57902 3.36032 5.71967 3.21967Z"
          fill="currentColor"
        />
        <path
          d="M3.6625 4.5594V4.9514H2.21V4.5594H2.7V3.27315C2.7 3.24049 2.70058 3.20782 2.70175 3.17515C2.70292 3.14132 2.70467 3.10749 2.707 3.07365L2.4165 3.31515C2.39083 3.33499 2.36517 3.34724 2.3395 3.3519C2.315 3.3554 2.29167 3.3554 2.2695 3.3519C2.24733 3.34724 2.2275 3.33965 2.21 3.32915C2.1925 3.31749 2.17967 3.30582 2.1715 3.29415L2 3.06665L2.7945 2.3999H3.2425V4.5594H3.6625Z"
          fill="currentColor"
        />
        <path
          d="M3.603 8.68785C3.66133 8.68785 3.70742 8.70418 3.74125 8.73685C3.77508 8.76835 3.792 8.81035 3.792 8.86285V9.17785H2V9.00285C2 8.96901 2.007 8.93285 2.021 8.89435C2.035 8.85585 2.0595 8.82085 2.0945 8.78935L2.8295 8.0491C2.8925 7.9861 2.94733 7.92601 2.994 7.86885C3.04067 7.81168 3.07917 7.75568 3.1095 7.70085C3.13983 7.64485 3.16258 7.58943 3.17775 7.5346C3.19292 7.4786 3.2005 7.41968 3.2005 7.35785C3.2005 7.25635 3.176 7.17876 3.127 7.1251C3.07917 7.07026 3.00567 7.04285 2.9065 7.04285C2.826 7.04285 2.75775 7.06443 2.70175 7.1076C2.64692 7.1496 2.609 7.2021 2.588 7.2651C2.5635 7.32926 2.53142 7.37185 2.49175 7.39285C2.45208 7.41385 2.3955 7.41851 2.322 7.40685L2.035 7.3561C2.05367 7.22893 2.08925 7.1181 2.14175 7.0236C2.19542 6.9291 2.26133 6.85035 2.3395 6.78735C2.41883 6.72435 2.50925 6.67768 2.61075 6.64735C2.71225 6.61585 2.82133 6.6001 2.938 6.6001C3.064 6.6001 3.17717 6.61876 3.2775 6.6561C3.379 6.69226 3.46533 6.74301 3.5365 6.80835C3.60767 6.87368 3.66192 6.95185 3.69925 7.04285C3.73775 7.13268 3.757 7.23185 3.757 7.34035C3.757 7.43368 3.74358 7.52001 3.71675 7.59935C3.69108 7.67868 3.6555 7.75451 3.61 7.82685C3.5645 7.89801 3.51142 7.96743 3.45075 8.0351C3.39125 8.1016 3.32767 8.16926 3.26 8.2381L2.76125 8.7491C2.82542 8.72926 2.889 8.7141 2.952 8.7036C3.015 8.6931 3.07333 8.68785 3.127 8.68785H3.603Z"
          fill="currentColor"
        />
        <path
          d="M2.07 11.756C2.08867 11.6288 2.12425 11.518 2.17675 11.4235C2.23042 11.329 2.29633 11.2503 2.3745 11.1873C2.45383 11.1243 2.54425 11.0776 2.64575 11.0472C2.74725 11.0157 2.85633 11 2.973 11C3.099 11 3.211 11.0181 3.309 11.0543C3.407 11.0893 3.48983 11.1371 3.5575 11.1978C3.62517 11.2584 3.6765 11.329 3.7115 11.4095C3.7465 11.4888 3.764 11.5728 3.764 11.6615C3.764 11.742 3.75583 11.8132 3.7395 11.875C3.72317 11.9357 3.69867 11.9888 3.666 12.0343C3.6345 12.0786 3.59483 12.1165 3.547 12.148C3.50033 12.1783 3.44667 12.204 3.386 12.225C3.66833 12.3242 3.8095 12.5167 3.8095 12.8025C3.8095 12.9378 3.785 13.0557 3.736 13.156C3.687 13.2563 3.62108 13.3398 3.53825 13.4062C3.45658 13.4727 3.3615 13.5229 3.253 13.5568C3.1445 13.5894 3.03133 13.6058 2.9135 13.6058C2.79217 13.6058 2.68425 13.5929 2.58975 13.5673C2.49525 13.5404 2.41125 13.4996 2.33775 13.4447C2.26425 13.3887 2.1995 13.3176 2.1435 13.2312C2.08867 13.1449 2.04083 13.0423 2 12.9233L2.2415 12.8253C2.3045 12.8008 2.36283 12.7943 2.4165 12.806C2.47017 12.8177 2.50867 12.8445 2.532 12.8865C2.5845 12.9833 2.63992 13.0539 2.69825 13.0983C2.75775 13.1414 2.82717 13.163 2.9065 13.163C2.96717 13.163 3.01967 13.1531 3.064 13.1333C3.1095 13.1123 3.14683 13.086 3.176 13.0545C3.20633 13.0218 3.22908 12.9851 3.24425 12.9443C3.25942 12.9034 3.267 12.862 3.267 12.82C3.267 12.7652 3.26292 12.7162 3.25475 12.673C3.24775 12.6287 3.22675 12.5908 3.19175 12.5593C3.15675 12.5278 3.10367 12.5038 3.0325 12.4875C2.9625 12.47 2.8645 12.4613 2.7385 12.4613V12.0868C2.84467 12.0868 2.93042 12.0786 2.99575 12.0623C3.06108 12.0459 3.11125 12.0237 3.14625 11.9957C3.18242 11.9666 3.20633 11.9316 3.218 11.8907C3.22967 11.8499 3.2355 11.805 3.2355 11.756C3.2355 11.6557 3.211 11.5787 3.162 11.525C3.11417 11.4702 3.04067 11.4427 2.9415 11.4427C2.861 11.4427 2.79275 11.4643 2.73675 11.5075C2.68192 11.5495 2.644 11.602 2.623 11.665C2.5985 11.7292 2.56642 11.7717 2.52675 11.7927C2.48708 11.8137 2.4305 11.8184 2.357 11.8068L2.07 11.756Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2340_1043">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
