import { Box, Breadcrumb, BreadcrumbItem, Flex } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey30,
  GreyGrey60,
  IndigoIndigo50,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useAddProductFormContext } from 'Products/drawers/AddProduct/hooks/useAddProductFormContext'
import { AddProductFormMode } from 'Products/drawers/AddProduct/domain/addProduct.type'

export const AddProductFormBreadcrumb = () => {
  const {
    data: { mode }
  } = useAddProductFormContext()

  const breadcrumbItems = [
    {
      label: 'Product information',
      active: mode === AddProductFormMode.ADD_PRODUCT
    },
    {
      label: 'List price',
      active: mode === AddProductFormMode.ADD_PRICE
    },
    {
      label: 'Review',
      active: mode === AddProductFormMode.REVIEW
    }
  ]

  return (
    <Flex
      alignItems="center"
      height="100%"
      padding="24px"
      bgColor={GreyGrey0}
      borderBottom={`1px solid ${GreyGrey30}`}
    >
      <Breadcrumb
        spacing="4px"
        separator={
          <Box
            as="span"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ChevronRightIcon color={GreyGrey60} />
          </Box>
        }
      >
        {breadcrumbItems.map(({ label, active }) => (
          <BreadcrumbItem
            key={`${label}-price-editor-breadcrumb`}
            {...Lato13Bold}
            color={active ? IndigoIndigo50 : GreyGrey60}
          >
            <span>{label}</span>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Flex>
  )
}
