import { SimplePaymentPage } from 'modules/PaymentsApp/components/SimplePaymentPage'
import { AlertTriangle } from 'react-feather'

export const Unavailable = () => {
  return (
    <SimplePaymentPage
      icon={AlertTriangle}
      iconColour="yellow.60"
      header="Payment unavailable"
      subheader={
        'The payment process for this invoice is currently unavailable.'
      }
      nextSteps={[
        'Please contact the issuer to resolve this issue or change the current payment method.'
      ]}
    />
  )
}
