import { FC } from 'react'

interface AvalaraIconProps {
  width?: number
  height?: number
}

export const AvalaraIcon: FC<AvalaraIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.4622 30H29.6911L25.0787 18.9628C23.8284 20.2929 22.6528 21.6914 21.5574 23.1517L24.4622 30Z"
      fill="#FF6600"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4009 19.8491L11.1204 22.871C12.9621 25.6137 14.1794 27.975 14.8491 29.1056C16.1536 26.8945 19.3385 20.3276 25.9388 14.1842L25.2109 12.4678C20.9256 15.6324 17.2769 19.6802 14.8561 22.767C14.8537 22.7716 13.3076 20.8729 12.4009 19.8491Z"
      fill="#059BD2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.1541 0H12.538L0 29.9999H5.22832L14.2935 8.61958L14.7242 7.45916H14.9673L15.3968 8.61958L18.0827 14.9565C19.3114 13.7318 20.6023 12.5711 21.9502 11.4791L17.1541 0Z"
      fill="#FF6600"
    />
  </svg>
)
