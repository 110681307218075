import { Flex } from '@chakra-ui/react'
import { FC, useCallback } from 'react'
import { LineItemGroup } from '@sequencehq/core-components'
import { Currency } from '@sequencehq/core-models'
import { AggregatedLineItemGroup, LineItem } from 'CreditNotes/types'
import { match } from 'ts-pattern'
import { parseFloatOrZero } from 'lib/parsers'
import { MAX_INPUT_TOTAL, MAX_QUANTITY } from 'CreditNotes/constants'
import { CreditNoteAddLineItem } from './CreditNoteAddLineItem'

type Props = {
  creditNoteId: string
  lineItemGroups: AggregatedLineItemGroup[]
  updateLineItems: (lineItemsToUpdate: LineItem[]) => void
  currency: Currency
  editable?: boolean
}

export const CreditNoteLineItems: FC<Props> = ({
  creditNoteId,
  lineItemGroups,
  updateLineItems,
  currency,
  editable = false
}) => {
  return (
    <Flex gap={4} direction="column">
      {lineItemGroups.map(group => {
        return (
          <LineItemGroup
            key={group.id}
            variant="creditNotes"
            currency={currency}
            onChangeSelected={
              !group.lineItems.length || !editable
                ? undefined
                : selected => {
                    updateLineItems(
                      group.lineItems.map(lineItem => {
                        return { ...lineItem, selected }
                      })
                    )
                  }
            }
            selected={match(
              group.lineItems.filter(item => item.selected).length
            )
              .with(0, () => false)
              .with(group.lineItems.length, () => true)
              .otherwise(() => 'indeterminate')}
          >
            {group.lineItems.map(lineItem => (
              <CreditNoteLineItem
                key={lineItem.id}
                lineItem={lineItem}
                editable={editable}
                updateLineItems={updateLineItems}
              />
            ))}
            {editable && (
              <CreditNoteAddLineItem
                creditNoteId={creditNoteId}
                groupId={group.id}
                currency={currency}
              />
            )}
            <LineItemGroup.Subtotal value={group.netTotal} />
          </LineItemGroup>
        )
      })}
    </Flex>
  )
}

const CreditNoteLineItem: FC<{
  lineItem: LineItem
  editable: boolean
  updateLineItems: (lineItemsToUpdate: LineItem[]) => void
}> = ({ lineItem, editable, updateLineItems }) => {
  const LineItemComponent = editable
    ? LineItemGroup.EditableLineItem
    : LineItemGroup.LineItem

  const handleChangeSelected = useCallback(
    (selected: boolean) => {
      updateLineItems([{ ...lineItem, selected }])
    },
    [updateLineItems, lineItem]
  )

  const handleChangeTotal = useCallback(
    (netTotal: string) => {
      const parsedTotal = parseFloatOrZero(netTotal)
      const limitedTotal =
        parsedTotal > MAX_INPUT_TOTAL ? lineItem.netTotal : netTotal

      updateLineItems([
        {
          ...lineItem,
          netTotal: limitedTotal,
          quantity: '1',
          rate: parsedTotal.toString()
        }
      ])
    },
    [updateLineItems, lineItem]
  )

  const handleChangeQuantity = useCallback(
    (quantity: string) => {
      const parsedQuantity = parseFloatOrZero(quantity)
      const limitedQuantity =
        parsedQuantity > MAX_QUANTITY ? lineItem.quantity : quantity

      updateLineItems([{ ...lineItem, quantity: limitedQuantity }])
    },
    [updateLineItems, lineItem]
  )

  const handleDelete = useCallback(() => {
    updateLineItems([{ ...lineItem, selected: true, deleted: true }])
  }, [updateLineItems, lineItem])

  return (
    <LineItemComponent
      selected={lineItem.selected}
      key={lineItem.id}
      title={lineItem.title}
      quantity={lineItem.quantity}
      rate={lineItem.rate}
      rateDisplay={lineItem.rateDisplay}
      taxRate={lineItem.taxRate}
      netTotal={lineItem.netTotal}
      inputsDisabled={!lineItem.selected}
      onChangeQuantity={handleChangeQuantity}
      onChangeSelected={handleChangeSelected}
      onChangeTotal={handleChangeTotal}
      onDelete={handleDelete}
    />
  )
}
