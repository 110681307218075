import { useCallback, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { AvailableStandardFrequency } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { Currency } from '@sequencehq/core-models'

type UseLoadPricesForProduct = (props: {
  productId?: string
  currency?: Currency
  frequencies: AvailableStandardFrequency[]
  skip?: boolean
}) => {
  loading: boolean
  error?: boolean
  data?: {
    prices: Dashboardv20240509Api.GetPrices.PricesList['items']
  }
}

enum Status {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export const useLoadPricesForProduct: UseLoadPricesForProduct = props => {
  const [status, setStatus] = useState<Status>(Status.UNINITIALIZED)
  const [prices, setPrices] = useState<
    Dashboardv20240509Api.GetPrice.Price[] | null
  >(null)

  const loadPrices = useCallback(
    async (args: {
      productId: string
      currency?: Currency
      frequencies: AvailableStandardFrequency[]
    }) => {
      setStatus(Status.LOADING)
      const res = await Promise.all(
        args.frequencies.map(frequency =>
          dashboardv20240509Client.getPrices({
            productId: args.productId,
            currency: args.currency,
            billingFrequency: frequency
          })
        )
      )

      setStatus(Status.SUCCESS)
      return res
        .flatMap(result => result.data?.items || [])
        .filter((price): price is Dashboardv20240509Api.GetPrice.Price =>
          Boolean(price)
        )
    },
    []
  )

  useEffect(() => {
    if (props.skip || !props.productId) {
      setPrices([])
      return
    }

    loadPrices({
      productId: props.productId,
      currency: props.currency,
      frequencies: props.frequencies
    })
      .then(newPrices => {
        setPrices(newPrices)
      })
      .catch(err => {
        setStatus(Status.ERROR)
        Sentry.captureException(err)
      })
  }, [
    props.productId,
    props.currency,
    props.frequencies,
    loadPrices,
    props.skip
  ])

  return {
    loading: status === Status.LOADING,
    error: status === Status.ERROR,
    data:
      status === Status.SUCCESS && prices
        ? {
            prices
          }
        : undefined
  }
}
