import { FC } from 'react'
import { required } from '@sequencehq/validation'
import { TabSelectField } from 'components/FormFields'

const typeOptions = [
  {
    label: 'Product',
    value: 'product'
  },
  {
    label: 'Discount',
    value: 'discount'
  }
]

interface LineItemTypeProps {
  isDisabled?: boolean
}

const LineItemTypeInput: FC<LineItemTypeProps> = ({ isDisabled }) => (
  <TabSelectField
    fieldName="itemType"
    fieldLabel="Type"
    options={typeOptions}
    validate={required}
    disabled={isDisabled}
  />
)

export default LineItemTypeInput
