import { useEffect, useState } from 'react'

import { usePrevious } from 'lib/usePrevious'

export const useSaveStatus = (isSaving: boolean) => {
  const prevSyncing = usePrevious(isSaving)
  const [isDone, setIsDone] = useState(false)

  useEffect(() => {
    if (prevSyncing && !isSaving) {
      setIsDone(true)
      setTimeout(() => {
        setIsDone(false)
      }, 3000)
    }
  }, [prevSyncing, isSaving])

  return { isDone, isLoading: isSaving }
}
