import { PropsWithChildren, createContext, useMemo, useState } from 'react'

import { INITIAL_STATE } from 'components/NavigationV3/NavigationV3Provider.constants'
import { NavigationV3ContextType } from 'components/NavigationV3/NavigationV3Provider.types'
import { useNavigationV3Queries } from 'components/NavigationV3/hooks/useNavigationV3Queries'
import { useNavigationV3Functions } from 'components/NavigationV3/hooks/useNavigationV3Functions'
import { useFetchStytchDataForNavigationV3 } from 'components/NavigationV3/hooks/useFetchStytchDataForNavigationV3'

export const NavigationV3Context =
  createContext<NavigationV3ContextType>(INITIAL_STATE)

export const NavigationV3Provider = ({ children }: PropsWithChildren) => {
  const [isSwitchingWorkspace, setIsSwitchingWorkspace] =
    useState<boolean>(false)

  const { userEmail, userWorkspaces, workspaceId, workspaceName } =
    useFetchStytchDataForNavigationV3()
  const { queries } = useNavigationV3Queries({ userWorkspaces })
  const { functions } = useNavigationV3Functions({ setIsSwitchingWorkspace })

  const data = useMemo(() => {
    return {
      isSwitchingWorkspace,
      workspaceId: workspaceId || '',
      workspaceName: workspaceName || '',
      userEmail: userEmail || '',
      userWorkspaces: userWorkspaces || []
    }
  }, [
    isSwitchingWorkspace,
    userEmail,
    userWorkspaces,
    workspaceId,
    workspaceName
  ])

  return (
    <NavigationV3Context.Provider value={{ data, queries, functions }}>
      {children}
    </NavigationV3Context.Provider>
  )
}
