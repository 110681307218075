import { INITIAL_REDUCER_STATE } from 'InvoiceEditor/domainManagement/invoiceEditor.constants.ts'
import {
  InvoiceEditorReducerState,
  LoadInvoiceEditorDataAction
} from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'

type LoadInvoiceEditorData = (
  prevState: InvoiceEditorReducerState
) => (action: LoadInvoiceEditorDataAction) => InvoiceEditorReducerState

export const loadInvoiceEditorData: LoadInvoiceEditorData = () => action => {
  const loadedData = {
    ...INITIAL_REDUCER_STATE,
    data: action.payload.data,
    configuration: {
      ...INITIAL_REDUCER_STATE.configuration,
      ...(action.payload.configuration ?? {})
    },
    editor: {
      loaded: true
    }
  }

  return {
    ...loadedData,
    initialData: loadedData.data
  }
}
