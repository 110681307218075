import { Attachments } from 'modules/Assets/Attachments'
import { isEqual } from 'lodash'
import { ServerAsset } from '@sequencehq/api/dashboard/v20240730'
import {
  useSelectQuoteAttachments,
  useStoreQuoteAttachments,
  useShouldDisableModifyQuoteAttachments
} from 'Cube/domain/subdomain/attachments'

export const QuoteAttachments = () => {
  const attachments = useSelectQuoteAttachments()
  const storeAttachments = useStoreQuoteAttachments()
  const disableModifyAttachments = useShouldDisableModifyQuoteAttachments()

  const handleAttachmentChange = (assets: ServerAsset[]) => {
    if (
      !isEqual(
        assets.map(({ id }) => id),
        attachments.map(({ id }) => id)
      )
    ) {
      storeAttachments(assets)
    }
  }

  return (
    <Attachments
      initialAttachments={attachments}
      onChange={handleAttachmentChange}
      disableModifyAttachments={disableModifyAttachments}
    />
  )
}
