import { Box, Text } from '@chakra-ui/react'
import { Lato14ExtendedRegular, GreyGrey70 } from '@sequencehq/design-tokens'
import { CheckboxField } from '@sequencehq/forms'
import { FC } from 'react'

type PaymentCollectionProps = {
  oneOff: boolean
  disabled?: boolean
  labelText: string
}

export const PaymentCollection: FC<PaymentCollectionProps> = ({
  oneOff,
  disabled,
  labelText
}) => (
  <Box>
    {oneOff ? (
      <Text textStyle="formLabel">Payment collection</Text>
    ) : (
      <Text textStyle="billingScheduleFormLabel">Payment collection</Text>
    )}
    <Box height={4} />
    <CheckboxField fieldName="stripePayments" disabled={disabled}>
      Online payment via Stripe
    </CheckboxField>
    <Box height={2} />
    <Text
      {...Lato14ExtendedRegular}
      color={GreyGrey70}
      lineHeight="20px"
      marginLeft="24px"
      marginBottom="4px"
      width="520px"
    >
      {labelText}
    </Text>
  </Box>
)
