import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { MenuItemBuilder } from '@sequencehq/core-components'

const duplicateBuilder: (
  setShowDuplicateModal: (
    quoteToDuplicate: DashboardApi20240730.GetQuotes.Quote
  ) => void
) => MenuItemBuilder<DashboardApi20240730.GetQuotes.Quote> =
  setShowDuplicateModal => (quote: DashboardApi20240730.GetQuotes.Quote) => {
    return {
      status: 'LIVE',
      label: 'Duplicate quote',
      action: () => setShowDuplicateModal(quote)
    }
  }

export const menuItemBuilders: (
  setShowDuplicateModal: (
    quoteToDuplicate: DashboardApi20240730.GetQuotes.Quote
  ) => void
) => MenuItemBuilder<DashboardApi20240730.GetQuotes.Quote>[] = setShowDuplicateModal => [
  duplicateBuilder(setShowDuplicateModal)
]
