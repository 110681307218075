import { useMemo } from 'react'
import { AreaClosed, LinePath } from '@visx/shape'
import { curveNatural } from '@visx/curve'
import { scaleTime, scaleLinear } from '@visx/scale'
import { LinearGradient } from '@visx/gradient'
import { max, extent } from '@visx/vendor/d3-array'

type SparklineGraphProps<DataType> = {
  width: number
  height: number
  data: DataType[]
  parseDataX: (d: DataType) => Date
  parseDataY: (d: DataType) => number
}

export const SparklineGraph = <DataType,>({
  width,
  height,
  data,
  parseDataX,
  parseDataY
}: SparklineGraphProps<DataType>) => {
  // config
  const curve = curveNatural
  const shapeRendering = 'geometricPrecision'

  // scales
  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, width],
        domain: extent(data, parseDataX) as [Date, Date]
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, width]
  )

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [height, 0],
        domain: [0, max(data, parseDataY) || 0],
        nice: true
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, height]
  )

  return (
    <svg width={width} height={height}>
      <LinearGradient
        id="area-gradient"
        from="rgba(150, 148, 235, 0.3)"
        to="rgba(150, 148, 235, 0)"
      />

      <AreaClosed<DataType>
        data={data}
        x={d => xScale(parseDataX(d)) ?? 0}
        y={d => yScale(parseDataY(d)) ?? 0}
        yScale={yScale}
        fill="url(#area-gradient)"
        curve={curve}
        shapeRendering={shapeRendering}
      />

      <LinePath<DataType>
        data={data}
        x={d => xScale(parseDataX(d)) ?? 0}
        y={d => yScale(parseDataY(d)) ?? 0}
        stroke="rgba(150, 148, 235, 1)"
        strokeWidth={2}
        curve={curve}
        shapeRendering={shapeRendering}
      />
    </svg>
  )
}
