import { currencyToName } from '@sequencehq/core-models'
import {
  FormFieldConfiguration,
  toBillingFrequencyLabel,
  toPriceSummary
} from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { internalPriceToCommonPrice } from 'common/drawers/PricingEditor/communication/external/v1/adapters/pricingEditor.adapters'
import { PricingEditorReducerState } from 'common/drawers/PricingEditor/domain'
import { PRICING_MODEL_OPTIONS } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'

export type CommonFields =
  PricingEditorReducerState['data']['pricingEditorData']['common']

export const useCommonFieldConfig = <DataModel extends CommonFields>(): {
  fields: FormFieldConfiguration<DataModel>[]
} => {
  const pricingEditorContext = usePricingEditorDomainContext()

  const availableCurrencies = useMemo(() => {
    return pricingEditorContext.queries.rawData.configuration.availableCurrencies.map(
      currency => ({
        label: `${currency} - ${currencyToName[currency]}`,
        value: currency
      })
    )
  }, [pricingEditorContext.queries.rawData.configuration.availableCurrencies])

  const availableListPriceOptions = useMemo(() => {
    if (
      pricingEditorContext.queries.rawData.configuration.priceType != 'variant'
    ) {
      return []
    }

    const existingListPrices = Object.values(
      pricingEditorContext.queries.rawData.data.listPrices
    )

    return existingListPrices.map(listPrice => ({
      value: listPrice.id,
      label: `${toPriceSummary(
        internalPriceToCommonPrice(listPrice)
      )} / ${toBillingFrequencyLabel(listPrice.modelSpecific.billingFrequency)}`
    }))
  }, [
    pricingEditorContext.queries.rawData.configuration.priceType,
    pricingEditorContext.queries.rawData.data.listPrices
  ])

  const fields = useMemo(() => {
    return [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'currency',
        validation: [required],
        options: availableCurrencies
      },
      {
        property: 'pricingModel',
        validation: [required],
        options: PRICING_MODEL_OPTIONS
      },
      {
        property: 'listPriceId',
        options: availableListPriceOptions,
        disabled: () =>
          !pricingEditorContext.queries.availableFeatures.variantPrice
            .canChangeListPrice.available.enabled
      }
    ] as FormFieldConfiguration<DataModel>[]
  }, [
    availableCurrencies,
    availableListPriceOptions,
    pricingEditorContext.queries.availableFeatures.variantPrice
      .canChangeListPrice.available.enabled
  ])

  return { fields }
}
