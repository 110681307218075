export const Heading2Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.4433 10.735C14.59 10.735 14.7055 10.7772 14.7898 10.8615C14.8778 10.9422 14.9218 11.0503 14.9218 11.186V12H9.46582V11.549C9.46582 11.4573 9.48415 11.362 9.52082 11.263C9.56115 11.1603 9.62349 11.0687 9.70782 10.988L12.1223 8.56798C12.324 8.36265 12.5037 8.16648 12.6613 7.97948C12.8227 7.79248 12.9565 7.60732 13.0628 7.42398C13.1692 7.24065 13.2498 7.05548 13.3048 6.86848C13.3635 6.67782 13.3928 6.47798 13.3928 6.26898C13.3928 6.07832 13.3653 5.91148 13.3103 5.76848C13.2553 5.62182 13.1765 5.49898 13.0738 5.39998C12.9748 5.30098 12.8538 5.22765 12.7108 5.17998C12.5715 5.12865 12.4138 5.10298 12.2378 5.10298C12.0765 5.10298 11.9262 5.12682 11.7868 5.17448C11.6512 5.21848 11.5302 5.28265 11.4238 5.36698C11.3175 5.44765 11.2277 5.54298 11.1543 5.65298C11.081 5.76298 11.026 5.88398 10.9893 6.01598C10.927 6.18465 10.8463 6.29648 10.7473 6.35148C10.6483 6.40648 10.5053 6.41932 10.3183 6.38998L9.60332 6.26348C9.65832 5.88215 9.76465 5.54848 9.92232 5.26248C10.08 4.97282 10.2762 4.73265 10.5108 4.54198C10.7492 4.34765 11.0205 4.20282 11.3248 4.10748C11.6328 4.00848 11.9628 3.95898 12.3148 3.95898C12.6815 3.95898 13.017 4.01398 13.3213 4.12398C13.6257 4.23032 13.886 4.38248 14.1023 4.58048C14.3187 4.77848 14.4873 5.01682 14.6083 5.29548C14.7293 5.57415 14.7898 5.88398 14.7898 6.22498C14.7898 6.51832 14.7458 6.78965 14.6578 7.03898C14.5735 7.28832 14.458 7.52665 14.3113 7.75398C14.1683 7.98132 13.9997 8.20132 13.8053 8.41398C13.611 8.62665 13.4075 8.84298 13.1948 9.06298L11.4018 10.8945C11.5742 10.8432 11.7447 10.8047 11.9133 10.779C12.0857 10.7497 12.2488 10.735 12.4028 10.735H14.4433Z"
      fill="currentColor"
    />
    <path
      d="M8.16202 4.04688V11.9999H6.67702V8.52388H2.94252V11.9999H1.45752V4.04688H2.94252V7.46788H6.67702V4.04688H8.16202Z"
      fill="currentColor"
    />
  </svg>
)
