import {
  INITIAL_PRICING_EDITOR_STATE,
  NEW_PRICE_PATTERN
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import { pricingEditorReducer } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditorReducer'
import {
  PricingEditorInterfacePrice,
  PricingEditorFormData,
  PricingEditorMode,
  PricingEditorPrice,
  PricingEditorProduct,
  PricingEditorReducerState,
  RecursivePartial
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { useCallback, useReducer } from 'react'

export type PricingEditorData = {
  data: PricingEditorReducerState['data']
  derived: PricingEditorReducerState['derived']
  editor: PricingEditorReducerState['editor']
  configuration: PricingEditorReducerState['configuration']
  functions: {
    loadPricingEditor: (
      dataToLoad: Pick<
        PricingEditorReducerState,
        'data' | 'configuration' | 'editor'
      >
    ) => void
    updateEditor: (
      updatedEditorState: RecursivePartial<PricingEditorReducerState['editor']>
    ) => void
    updateData: (
      updatedData: RecursivePartial<PricingEditorReducerState['data']>
    ) => void
    updateFormData: (
      updatedFormData: RecursivePartial<PricingEditorFormData>
    ) => void
    addPrice: (
      newPrice?: Omit<RecursivePartial<PricingEditorPrice>, 'id'>
    ) => void
    removePrice: (priceId: PricingEditorPrice['id']) => void
    saveNewPrice: () => Promise<void>
    closeEditor: () => void
    fieldIsDisabled: (fieldName: string) => boolean
  }
}

type UsePricingEditor = (props: {
  onSave: (newData: {
    price: PricingEditorInterfacePrice
    product: PricingEditorProduct
  }) => void | Promise<void>
  onClose: () => void
}) => PricingEditorData

export const usePricingEditor: UsePricingEditor = props => {
  const [state, dispatch] = useReducer(
    pricingEditorReducer,
    INITIAL_PRICING_EDITOR_STATE
  )

  const loadPricingEditor = useCallback(
    (
      dataToLoad: Pick<
        PricingEditorReducerState,
        'data' | 'configuration' | 'editor'
      >
    ) => {
      dispatch({
        type: 'loadPricingEditor',
        payload: dataToLoad
      })
    },
    []
  )

  const updateEditor = useCallback(
    (
      updatedEditorState: RecursivePartial<PricingEditorReducerState['editor']>
    ) => {
      dispatch({
        type: 'updateEditor',
        payload: updatedEditorState
      })
    },
    []
  )

  const updateFormData = useCallback(
    (updatedFormData: RecursivePartial<PricingEditorFormData>) => {
      if (
        state.editor.selectedPrice &&
        !state.editor.selectedPrice?.match(NEW_PRICE_PATTERN) &&
        state.data.prices[state.editor.selectedPrice]
      ) {
        /**
         * If we are editing a price, create a new price and then
         * update that new price with the data we would have otherwise
         * added to the form.
         */
        dispatch({
          type: 'addPrice',
          payload: state.data.prices[state.editor.selectedPrice]
        })
        dispatch({
          type: 'updateData',
          payload: {
            formData: updatedFormData
          }
        })
        return
      }

      dispatch({
        type: 'updateData',
        payload: {
          formData: updatedFormData
        }
      })
    },
    [state.editor.selectedPrice, state.data.prices]
  )

  const addPrice = useCallback(
    (newPrice?: Omit<RecursivePartial<PricingEditorPrice>, 'id'>) => {
      dispatch({
        type: 'addPrice',
        payload: newPrice
      })
    },
    []
  )

  const updateData = useCallback(
    (newData: RecursivePartial<PricingEditorReducerState['data']>) => {
      dispatch({
        type: 'updateData',
        payload: newData
      })
    },
    []
  )

  const removePrice = useCallback((priceId: PricingEditorPrice['id']) => {
    dispatch({
      type: 'removePrice',
      payload: priceId
    })
  }, [])

  const saveNewPrice = useCallback(async () => {
    if (!state.derived.queries.canSave) {
      dispatch({
        type: 'updateEditor',
        payload: {
          showValidationErrors: true
        }
      })
      return
    }

    const apiFormatPrice = state.derived.queries.apiFormatPrices.find(
      price => price.id === state.editor.selectedPrice
    )

    if (!apiFormatPrice) {
      throw new Error('Could not find price to save')
    }

    /**
     * We always want to create a new price id on save, so if the
     * selection was against an existing, saved, price, then create a
     * new price.
     */

    await props.onSave({
      price: {
        ...apiFormatPrice,
        id: state.editor.selectedPrice?.match(NEW_PRICE_PATTERN)
          ? state.editor.selectedPrice
          : `new-price::${crypto.randomUUID()}`
      },
      product: state.data.product
    })
  }, [
    props,
    state.derived.queries.apiFormatPrices,
    state.editor.selectedPrice,
    state.derived.queries.canSave,
    state.data.product
  ])

  const closeEditor = useCallback(() => {
    props.onClose()
  }, [props])

  const fieldIsDisabled = useCallback(
    (fieldName: string) => {
      if (state.configuration.mode === PricingEditorMode.VIEW) {
        return true
      }

      if (state.configuration.disabledFields?.length) {
        return state.configuration.disabledFields.includes(fieldName)
      }

      if (state.configuration.availableFields?.length) {
        return !state.configuration.availableFields.includes(fieldName)
      }

      return false
    },
    [
      state.configuration.availableFields,
      state.configuration.mode,
      state.configuration.disabledFields
    ]
  )

  return {
    data: state.data,
    derived: state.derived,
    editor: state.editor,
    configuration: state.configuration,
    functions: {
      loadPricingEditor,
      updateEditor,
      updateFormData,
      updateData,
      addPrice,
      removePrice,
      saveNewPrice,
      closeEditor,
      fieldIsDisabled
    }
  }
}
