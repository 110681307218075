import { FC } from 'react'

interface TaxRateIconProps {
  width?: string
  height?: string
}

export const TaxRateIcon: FC<TaxRateIconProps> = ({
  width = '37px',
  height = '37px'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18.5" cy="19.3604" r="18.5" fill="#F0F4F7" />
    <path
      d="M18.795 9.86228C16.667 9.90522 14.7217 10.6856 13.2003 11.9556L11.9624 10.5782C11.6483 10.2288 11.0694 10.3824 10.9719 10.8418L10.0198 15.3178C9.93904 15.6983 10.2521 16.0462 10.6396 16.0073L15.2013 15.5495C15.6695 15.5025 15.8861 14.9449 15.5721 14.5955L14.2989 13.1789C15.7297 12.0012 17.6074 11.3476 19.6454 11.5266C23.1031 11.8301 25.9359 14.5847 26.3147 18.0272C26.8034 22.4674 23.3318 26.2241 18.9808 26.2241C15.1586 26.2241 12.0148 23.3241 11.6389 19.6092C11.5962 19.1862 11.2538 18.8605 10.8237 18.8605C10.3424 18.8605 9.95746 19.2673 10.0038 19.7418C10.4478 24.2986 14.2976 27.8604 18.9808 27.8604C24.2998 27.8604 28.5432 23.2664 27.9434 17.8378C27.4809 13.6529 24.0698 10.3043 19.8696 9.90303C19.5073 9.86841 19.1484 9.85515 18.795 9.86228ZM18.9504 13.9507C18.7827 13.9507 18.6701 14.0567 18.6701 14.2408V14.9383C17.1101 15.0164 15.9925 15.8871 15.9925 17.2314C15.9925 18.4362 16.7921 19.0889 18.2569 19.4182L18.6701 19.5133V21.5436C17.9654 21.4822 17.6133 21.1647 17.4346 20.7294C17.278 20.4058 17.0435 20.2276 16.6971 20.2276C16.2387 20.2276 15.9644 20.5003 15.9644 20.902C15.9644 21.0079 15.987 21.1251 16.0317 21.2535C16.3056 22.068 17.2331 22.7317 18.6701 22.7988V23.4795C18.6701 23.6636 18.7827 23.7696 18.9504 23.7696C19.118 23.7696 19.2298 23.6636 19.2298 23.4795V22.7988C20.8398 22.7039 21.9971 21.8507 21.9971 20.4394C21.9971 19.2174 21.1979 18.6038 19.6662 18.2581L19.2298 18.163V16.1991C19.7665 16.2719 20.1183 16.5455 20.3364 17.0029C20.5152 17.3265 20.7563 17.4879 21.1027 17.4879C21.5275 17.4879 21.8066 17.248 21.8066 16.8798C21.8066 16.7624 21.7849 16.6512 21.7513 16.5395C21.4828 15.6804 20.5602 15.0275 19.2298 14.9383V14.2408C19.2298 14.0567 19.118 13.9507 18.9504 13.9507ZM18.6701 16.1943V18.0352C17.8875 17.8343 17.5579 17.5388 17.5579 17.0868C17.5579 16.6347 17.9379 16.2614 18.6701 16.1943ZM19.2298 19.6412C20.1244 19.8645 20.4369 20.1153 20.4373 20.6119C20.4373 21.1249 20.035 21.4822 19.2298 21.5436V19.6412Z"
      fill="#667085"
    />
  </svg>
)
