import { Reducer } from 'react'
import { match } from 'ts-pattern'
import { flow } from 'lodash/fp'
import * as actions from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/actionHandlers'
import * as postActionStages from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/postActionStages'
import {
  PostActionStage,
  PricingEditorActions,
  PricingEditorReducerState
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { INITIAL_PRICING_EDITOR_STATE } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'

/**
 * Only logs when the local storage item is enabled, to allow
 * for simple debugging in any environment without otherwise spamming.
 * @param args
 * @returns
 */
const log = (...args: unknown[]) => {
  if (localStorage.getItem('enable-pricing-drawer-logs')) {
    //eslint-disable-next-line no-console
    return console.info(...args)
  }
  return
}

const handleAction =
  (prevState: PricingEditorReducerState) => (action: PricingEditorActions) => {
    return match(action)
      .with({ type: 'loadPricingEditor' }, matchedAction =>
        actions.loadPricingEditor(prevState)(matchedAction)
      )
      .with({ type: 'updateEditor' }, matchedAction =>
        actions.updateEditor(prevState)(matchedAction)
      )
      .with({ type: 'updateData' }, matchedAction =>
        actions.updateData(prevState)(matchedAction)
      )
      .with({ type: 'addPrice' }, matchedAction =>
        actions.addPrice(prevState)(matchedAction)
      )
      .with({ type: 'removePrice' }, matchedAction =>
        actions.removePrice(prevState)(matchedAction)
      )
      .exhaustive()
  }

const logStage =
  (stageName: string): PostActionStage =>
  () =>
  prevState => {
    log(
      `[Pricing Editor] After post action stage: %c${stageName}`,
      'color: lime;',
      {
        state: prevState
      }
    )
    return prevState
  }

/**
 * The order of stage execution matters - executing validation before initializing
 * the form will result in validation being performed against the old state, for
 * example.
 */
const postActionStageOrder: { name: string; stage: PostActionStage }[] = [
  { name: 'Select price', stage: postActionStages.selectPrice },
  { name: 'Initialize form', stage: postActionStages.initializeForm },
  {
    name: 'Transition price model data',
    stage: postActionStages.transitionPriceModelData
  },
  {
    name: 'Sync invoice and product name',
    stage: postActionStages.syncInvoiceAndProductName
  },
  { name: 'Sync form to price', stage: postActionStages.syncFormToNewPrice },
  { name: 'Create queries', stage: postActionStages.pricingEditorQueries }
]
const handlePostActionStages: PostActionStage = ctx => prevState => {
  return flow(
    postActionStageOrder.flatMap(({ name, stage }) => [
      stage(ctx),
      logStage(name)(ctx)
    ])
  )(prevState) as PricingEditorReducerState
}

export const pricingEditorReducer: Reducer<
  PricingEditorReducerState,
  PricingEditorActions
> = (prevState = INITIAL_PRICING_EDITOR_STATE, action) => {
  log(
    `%c[Pricing Editor] ======== Start of reducer for action ${action.type} =========`,
    'color: cyan;',
    {
      initialState: prevState,
      action
    }
  )

  const postActionState = handleAction(prevState)(action)

  log(
    `%c[Pricing Editor] ======== beginning of post action functionality for action ${action.type} =========`,
    'color: lime;',
    {
      postActionState
    }
  )

  const finalState = handlePostActionStages({
    preActionState: prevState,
    action
  })(postActionState)

  log(
    `%c[Pricing Editor] ======== Running side effects =========`,
    'color: hotpink;',
    {
      finalState
    },
    `
    `
  )

  log(
    `%c[Pricing Editor] ======== End of reducer for action ${action.type} =========`,
    'color: hotpink;',
    {
      finalState
    },
    `
    `
  )

  return finalState
}
