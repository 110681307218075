import { Flex, IconButton } from '@chakra-ui/react'
import PlusIcon from '@heroicons/react/24/outline/PlusIcon'
import { GreyWhite } from '@sequencehq/design-tokens'
import { usePricesFooter } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PricesFooter/usePricesFooter'

export const PricesFooter = () => {
  const { addPrice, disabled } = usePricesFooter()

  return (
    <Flex
      gridArea="footer"
      alignItems="center"
      height="100%"
      padding="0 24px"
      justifyContent="flex-end"
      data-testid="prices-footer"
    >
      <IconButton
        width="32px"
        minWidth="32px"
        height="32px"
        variant="secondary"
        aria-label="Add new price"
        icon={<PlusIcon height="20px" width="20px" />}
        isDisabled={disabled}
        borderRadius="8px"
        boxShadow="0px 2px 4px 0px rgba(20, 23, 28, 0.08), 0px -2px 2px 0px rgba(0, 0, 0, 0.04) inset"
        backgroundColor={GreyWhite}
        onClick={() => addPrice()}
      />
    </Flex>
  )
}
