import { ReactNode } from 'react'
import {
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  Flex,
  DrawerFooter,
  Button,
  SystemStyleObject
} from '@chakra-ui/react'
import { noReturn } from 'lib/noReturn'
import { FormErrors } from '@sequencehq/forms'
import { FormRenderProps } from 'react-final-form'

export type DrawerWrapperProps<FormValues> = {
  title: string
  children: ReactNode
  submitLabel: string
  handleCancel?: () => void
  drawerStyles?: SystemStyleObject
} & FormRenderProps<FormValues, Partial<FormValues>>

export const DrawerForm = <FormValues,>({
  title,
  children,
  submitError,
  handleSubmit,
  handleCancel,
  submitting,
  submitLabel,
  drawerStyles
}: DrawerWrapperProps<FormValues>) => (
  <Flex flexDirection="column" as="form" onSubmit={noReturn(handleSubmit)}>
    <DrawerContent
      margin="8px 12px"
      borderRadius="8px"
      overflow="hidden"
      sx={drawerStyles}
    >
      <DrawerHeader height="56px" as="h2">
        {title}
      </DrawerHeader>
      <DrawerCloseButton top={3} right={3} />

      <DrawerBody>
        <FormErrors formError={submitError} />
        {children}
      </DrawerBody>

      <DrawerFooter>
        <Button variant="secondary2" size="sm" mr={3} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary2"
          size="sm"
          type="submit"
          isLoading={submitting}
        >
          {submitLabel}
        </Button>
      </DrawerFooter>
    </DrawerContent>
  </Flex>
)
