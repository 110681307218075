import { quoteContactToContactOption } from 'Cube/communication/external/quotes.api.v1/adapters/quote.adapters.out'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useMemo } from 'react'

type UsePopoverRecipients = () => {
  contacts: { name: string; email: string }[]
  isVisible: boolean
}

export const usePopoverRecipients: UsePopoverRecipients = () => {
  const ctx = useCubeContext()

  const recipients = useMemo(() => {
    const contacts = ctx.queries.rawData.data.quote.contacts

    return contacts.map(contact => quoteContactToContactOption(contact))
  }, [ctx.queries.rawData.data.quote.contacts])

  const isVisible = useMemo(() => recipients.length > 0, [recipients.length])

  return {
    contacts: recipients,
    isVisible
  }
}
