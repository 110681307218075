import { StandardLayout } from '@sequencehq/core-components'
import { DeferredRevenueHeader } from './DeferredRevenueHeader'
import { DeferredRevenueContent } from './content/DeferredRevenueContent'

export const DeferredRevenue = () => {
  return (
    <StandardLayout
      header={<DeferredRevenueHeader />}
      content={<DeferredRevenueContent />}
    />
  )
}
