import { ListBlockConfigV2 } from '@sequencehq/quote-content'
import { BlockAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/blockAdapter.types'
import { textContentAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/utils/textContent.adapter'

export const numberListAdapterV2: BlockAdapter<'bulletListItem'> = {
  in: block => {
    const config = block.config as ListBlockConfigV2

    return config.items.map(item => {
      return {
        type: 'numberedListItem',
        content: textContentAdapter.in(item)
      }
    }) as ReturnType<BlockAdapter['in']>
  },
  out: () => block => {
    const items =
      block.children.length > 0
        ? (block.children
            .map(child => {
              if (!child.content) {
                return
              }

              return textContentAdapter.out(child.content)
            })
            .filter(Boolean) as ListBlockConfigV2['items'])
        : ([
            textContentAdapter.out(block.content)
          ] as ListBlockConfigV2['items'])

    return {
      blockType: 'numberList',
      config: { items },
      version: 2
    }
  }
}
