import { Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import { Toast } from '@sequencehq/core-components'
import { required } from '@sequencehq/validation'
import { isErrorResponse } from 'components/Form'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { ModalForm } from 'components/Modal'
import { useGetApiSelfQuery } from 'features/api'
import { usePostCustomerAliasesMutation } from 'features/api/integratedApi.generated'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { handleFormResponse } from 'lib/formValidation'
import { FC } from 'react'
import { Form } from 'react-final-form'

type LinkAliasToCustomerFormValues = {
  customerId: string
}

interface LinkAliasToCustomerFormProps {
  alias: string
}

const LinkAliasToCustomerForm: FC<LinkAliasToCustomerFormProps> = ({
  alias
}) => {
  const [submitForm] = usePostCustomerAliasesMutation()
  const { data: selfResponse } = useGetApiSelfQuery(undefined)

  const dispatch = useDispatch()
  const toast = useToast()

  const handleSuccess = () => {
    toast({
      id: 'customerUpdated',
      position: 'bottom',
      isClosable: true,
      render: () => (
        <Toast
          type="success"
          title={`Customer updated`}
          isClosable={true}
        ></Toast>
      )
    })
    dispatch(closeOverlay())
  }

  const self = selfResponse?.value()

  if (!self) {
    return <Spinner></Spinner>
  }

  return (
    <Form<LinkAliasToCustomerFormValues>
      keepDirtyOnReinitialize
      initialValues={{
        customerId: undefined
      }}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          createCustomerAliasEndpointCreateCustomerAliasRequestModel: {
            sequenceAccountId: self.sequenceAccounts[0],
            customerId: values.customerId,
            value: alias
          }
        })

        if (isErrorResponse(res)) {
          return handleFormResponse(res, form.getRegisteredFields())
        }

        handleSuccess()
      }}
      render={formProps => (
        <ModalForm
          {...formProps}
          title="Link customer to alias"
          submitLabel="Link"
        >
          <Flex>
            <Text fontWeight={700}>Alias:</Text>
            <Box width="4px"></Box>
            <Text>{alias}</Text>
          </Flex>
          <Box height={5}></Box>

          <CustomerComboInputComplete
            fieldName="customerId"
            validate={required}
          />
        </ModalForm>
      )}
    />
  )
}

export default LinkAliasToCustomerForm
