import { isDemoEnv } from 'lib/environment/environment'
import { openOverlay } from 'features/overlay'
import type { AnyAction, MiddlewareAPI, Middleware } from '@reduxjs/toolkit'

const middleware: Middleware =
  (middlewareApi: MiddlewareAPI) => next => (action: AnyAction) => {
    const actionType: string = action.type
    const { status } = action.payload ?? {}

    if (
      isDemoEnv() &&
      actionType.includes('executeMutation') &&
      status === 403
    ) {
      middlewareApi.dispatch(openOverlay({ content: 'demoMode' }))
    }

    if (isDemoEnv() && status === 403) {
      // Generic catch-all to prevent bubbling authorization errors up in demo mode
      return
    }

    return next(action)
  }

export default middleware
