import { Flex, Spinner as ChakraSpinner } from '@chakra-ui/react'
import { GreyGrey100 } from '@sequencehq/design-tokens'
import { FC } from 'react'

const Spinner: FC<{
  height?: string
  width?: string
  color?: string
}> = ({ height, width, color = GreyGrey100 }) => (
  <Flex
    width={width ?? '100%'}
    height={height ?? '100%'}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <ChakraSpinner width={width} height={height} color={color} />
  </Flex>
)

export default Spinner
