import { Flex, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { PriceMetadataModel } from '@sequencehq/core-models'
import {
  GreyGrey80,
  IndigoIndigo50,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { Link } from 'react-router-dom'

export const ProductPriceBillingSchedules = ({
  priceMetadata
}: {
  priceMetadata: PriceMetadataModel
}) => {
  return (
    <Flex
      flexDirection="column"
      gap="8px"
      flex={1}
      width="100%"
      maxWidth="480px"
    >
      <Text {...Lato14Bold}>Billing schedules</Text>

      <Table variant="v2" width="100%">
        <Tbody>
          {priceMetadata.billingSchedules.map(schedule => (
            <Tr
              key={schedule.billingScheduleId}
              sx={{
                _hover: {
                  cursor: 'default'
                }
              }}
            >
              <Td>
                <CustomerPreviewCardPill customerId={schedule.customerId} />
              </Td>
              <Td {...Lato13Regular} color={IndigoIndigo50} textAlign="right">
                <Link
                  to={`/billing-schedules/${schedule.billingScheduleId}`}
                  style={{ fontWeight: '600' }}
                >
                  View schedule
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  )
}
