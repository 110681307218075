import { Box, Text } from '@chakra-ui/react'
import { GreyGrey90, Lato14Bold } from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  SelectField,
  SelectFieldWithActions,
  TextField
} from '@sequencehq/core-components'
import { useGraduatedPriceForm } from './useGraduatedPriceForm'
import { PricingInformation } from '../common/PricingInformation'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { FixedTierTableInput } from '../inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from '../inputs/PercentageTierTableInput/PercentageTierTableInput'
import {
  AvailableStandardFrequency,
  UsageCalculationMode,
  UsageTierType
} from 'common/drawers/PricingEditor/domain'
import { useFlags } from 'launchdarkly-react-client-sdk'
import UsageCalculationPeriodDropdown from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'

const usageCalculationModeDescriptions: Record<UsageCalculationMode, string> = {
  CUMULATIVE:
    'Usage is counted from the billing start date and accumulates with each billing period.',
  BILLING_PERIOD: 'Charges are based on usage in each billing period.',
  PERIODIC: 'Charges are based on usage in each billing period.'
}

export const GradudatedPriceStandaloneVariantForm = () => {
  const { fieldsConfig, currency } = useGraduatedPriceForm()
  const flags = useFlags()

  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingInformation
        value={fieldsConfig.pricingModel.value}
        isDisabled={fieldsConfig.pricingModel.disabled}
        options={fieldsConfig.pricingModel.options}
        validationErrors={fieldsConfig.pricingModel.validationErrors}
        onChange={fieldsConfig.pricingModel.onChange}
        description={fieldsConfig.pricingModel.description}
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="graduated.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      {fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="graduated.currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}

      <Box paddingBottom="8px">
        <HorizontalSelectorField
          label="Frequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <SelectFieldWithActions
          label="Usage metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
        <HorizontalSelectorField
          label="Type"
          value={fieldsConfig.usageTierType.value}
          options={fieldsConfig.usageTierType.options}
          onChange={newValue =>
            fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
          }
          validationErrors={fieldsConfig.usageTierType.validationErrors}
          isDisabled={fieldsConfig.usageTierType.disabled}
        />
        <Box
          paddingTop="8px"
          marginBottom="16px"
          paddingBottom="8px"
          width="100%"
        >
          <Box {...Lato14Bold} marginBottom="8px">
            Tiers
          </Box>
          {fieldsConfig.usageTierType.value === 'FIXED' ? (
            <FixedTierTableInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          ) : (
            <PercentageTierTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          )}
        </Box>
        {!flags.useUsageResetCadences && (
          <SelectField
            label="Usage calculation mode"
            description={
              usageCalculationModeDescriptions[
                fieldsConfig.usageCalculationMode.value
              ]
            }
            options={fieldsConfig.usageCalculationMode.options}
            validationErrors={
              fieldsConfig.usageCalculationMode.validationErrors
            }
            value={fieldsConfig.usageCalculationMode.value}
            onChange={newValue =>
              fieldsConfig.usageCalculationMode.onChange(
                newValue as UsageCalculationMode
              )
            }
            isDisabled={fieldsConfig.usageCalculationMode.disabled}
            placeholder="Select usage calculation mode"
          />
        )}
        {flags.useUsageResetCadences && (
          <UsageCalculationPeriodDropdown
            options={fieldsConfig.usageCalculationPeriod.options}
            value={fieldsConfig.usageCalculationPeriod.value}
            isDisabled={fieldsConfig.usageCalculationPeriod.disabled}
            onChange={fieldsConfig.usageCalculationPeriod.onChange}
            validationErrors={
              fieldsConfig.usageCalculationPeriod.validationErrors
            }
          />
        )}
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
