import { Td, Text, Tr, Box } from '@chakra-ui/react'

import { Lato13Bold, Lato13Regular } from '@sequencehq/design-tokens'

export const EmptyContent = () => (
  <Tr>
    <Td colSpan={4}>
      <Box width="400px" margin="12px auto" textAlign="center">
        <Text {...Lato13Bold} color="gray.90">
          No contacts to display
        </Text>
        <Text {...Lato13Regular} color="gray.80">
          You have not created a contact for this customer yet.
        </Text>
      </Box>
    </Td>
  </Tr>
)
