import { Link } from '@chakra-ui/react'
import { PricingModel } from 'common/drawers/PricingEditor/domain'
import { SelectField } from '@sequencehq/core-components'
import { ValidationError } from '@sequencehq/utils'

type PricingInformationProps = {
  value: PricingModel
  isDisabled: boolean
  options: { value: string; label: string }[]
  validationErrors: ValidationError[]
  description?: string
  onChange: (newPricingModel: PricingModel) => void
}

export const PricingInformation = (props: PricingInformationProps) => (
  <>
    <SelectField
      label="Pricing model"
      data-testid="common.pricingModel"
      value={props.value}
      onChange={newPricingModel =>
        props.onChange(newPricingModel as PricingModel)
      }
      options={props.options}
      validationErrors={props.validationErrors}
      isDisabled={props.isDisabled}
      placeholder="Select pricing model"
      description={
        props.description ? (
          <>
            {props.description}{' '}
            <Link
              href="https://docs.sequencehq.com/plans-products/pricing-models"
              isExternal
              color="indigo.50"
            >
              Learn more
            </Link>
          </>
        ) : null
      }
    />
  </>
)
