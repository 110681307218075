import {
  CubeReducerState,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'
import { getCommonFeaturesAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/common'
import { getAvailableFeaturesForPhases } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/phases/getAvailableFeaturesForPhases'
import { getQuoteFeaturesAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/quote'
import { getScheduleFeaturesAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/schedule'

type Context = {
  orderedPhases: ResolvedPhase[]
  resolvedPhases: Record<ResolvedPhase['id'], ResolvedPhase>
}
export const getAvailableFeatures =
  (ctx: Context) =>
  (
    prevState: CubeReducerState
  ): CubeReducerState['queries']['availableFeatures'] => ({
    phases: getAvailableFeaturesForPhases({
      orderedPhases: ctx.orderedPhases,
      resolvedPhases: ctx.resolvedPhases,
      prevState
    }),
    schedule: getScheduleFeaturesAvailable(prevState),
    quote: getQuoteFeaturesAvailable(prevState),
    common: getCommonFeaturesAvailable({ orderedPhases: ctx.orderedPhases })(
      prevState
    )
  })
