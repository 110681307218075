import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, ButtonProps } from '@chakra-ui/react'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'
import { match } from 'ts-pattern'

import { Currency, currencies } from '@sequencehq/core-models'

import { useInvoicesCsvExport } from 'components/Invoices/useInvoicesCsvExport'
import { DownloadInvoicesGridCsvExportModal } from 'components/Invoices/InvoicesGrid/DownloadInvoicesGridCsvExportModal'

const isValidCurrencyString = (value: unknown): value is Currency =>
  currencies.includes(value as (typeof currencies)[number])

export const InvoicesExportToCsv = () => {
  const [searchParams] = useSearchParams()

  const view = searchParams.get('view')
  const currency = searchParams.get('currency')

  const { download } = useInvoicesCsvExport()

  const [showCsvExportModal, setShowCsvExportModal] = useState<boolean>(false)

  const buttonProps: ButtonProps = {
    variant: 'component-library-secondary',
    leftIcon: <ArrowDownTrayIcon width="16px" height="16px" color="inherit" />,
    iconSpacing: '4px'
  }

  if (!download.available) {
    return null
  }

  return match(view)
    .with('grid', () => {
      if (!currency || !isValidCurrencyString(currency)) {
        return null
      }

      return (
        <>
          <Button {...buttonProps} onClick={() => setShowCsvExportModal(true)}>
            Export to CSV
          </Button>

          {showCsvExportModal && (
            <DownloadInvoicesGridCsvExportModal
              currency={currency}
              onClose={() => setShowCsvExportModal(false)}
            ></DownloadInvoicesGridCsvExportModal>
          )}
        </>
      )
    })
    .otherwise(() => (
      <Button
        {...buttonProps}
        onClick={() => download.onClick(searchParams.toString())}
      >
        Export to CSV
      </Button>
    ))
}
