import { Box, Flex, Grid, GridItem, Image } from '@chakra-ui/react'
import {
  Button,
  Modal,
  TextField,
  useModalContext
} from '@sequencehq/core-components'
import { FC } from 'react'
import {
  BorderRadius6,
  GreyGrey10,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useLinkSalesforceOpportunityModal } from './useLinkSalesforceOpportunityModal'
import Spinner from 'components/Loading/Spinner'
import salesforceOpportunityIcon from './salesforceOpportunityIcon.svg'

const LinkSalesforceOpportunityModalContent: FC<{
  onLink: (args: { opportunityId: string }) => void
}> = props => {
  const hook = useLinkSalesforceOpportunityModal({
    onConfirm: props.onLink
  })

  return (
    <Modal unmountOnClose>
      <Grid
        gridTemplateAreas={`
        "header"
        "content"
        "footer"
        `}
        gridTemplateRows="auto 1fr auto"
        overflow="hidden"
        height="100%"
        width="100%"
        data-testid="salesforce.opportunity.link.modal"
      >
        <GridItem gridArea="header">
          <Modal.Header showCloseButton withBorder>
            Link quote to a Salesforce opportunity
          </Modal.Header>
        </GridItem>
        <GridItem gridArea="content" overflow="auto">
          <Flex
            padding="20px"
            color={GreyGrey80}
            {...Lato13Regular}
            flexDirection="column"
            gap="16px"
          >
            <TextField
              value={hook.fields.linkToAccount.value}
              onChange={hook.fields.linkToAccount.onChange}
              label="Opportunity ID or URL"
              data-testid="linkToAccount"
              validationErrors={hook.fields.linkToAccount.validationErrors}
              placeholder="Enter an opportunity ID or URL"
              styles={{
                wrapper: { margin: 0 }
              }}
            />

            {hook.externalAccountDetails.show && (
              <Grid
                gridTemplateColumns="auto 1fr auto"
                gridColumnGap="8px"
                backgroundColor={GreyGrey10}
                borderRadius={BorderRadius6}
                padding="12px"
                alignItems="center"
              >
                {hook.externalAccountDetails.name && (
                  <>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      height="20px"
                      width="20px"
                      borderRadius="1.667px"
                      backgroundColor="#FF5D2D"
                    >
                      <Image
                        src={salesforceOpportunityIcon}
                        height="16px"
                        width="16px"
                      />
                    </Flex>
                    <GridItem {...Lato13Bold} color={GreyGrey70}>
                      {hook.externalAccountDetails.name}
                    </GridItem>
                  </>
                )}
                {hook.externalAccountDetails.fetching && (
                  <GridItem
                    {...Lato13Bold}
                    color={GreyGrey70}
                    display="flex"
                    gap="8px"
                  >
                    <Spinner height="16px" width="16px" color={GreyGrey70} />{' '}
                    Fetching opportunity information...
                  </GridItem>
                )}
              </Grid>
            )}
          </Flex>
        </GridItem>
        <GridItem gridArea="footer">
          <Modal.Footer withBorder>
            <Box flex={1}></Box>
            <Modal.CloseTrigger>
              <Button variant="secondary">Cancel</Button>
            </Modal.CloseTrigger>
            <Button
              variant="primary"
              onClick={() => {
                void hook.submit.onClick()
              }}
              disabled={hook.submit.disabled}
            >
              Create link
            </Button>
          </Modal.Footer>
        </GridItem>
      </Grid>
    </Modal>
  )
}

export const LinkSalesforceOpportunityModal: FC<{
  onLink: (args: { opportunityId: string }) => void
}> = props => {
  const modalContext = useModalContext()

  return (
    <Modal unmountOnClose>
      {modalContext.isOpen && (
        <LinkSalesforceOpportunityModalContent {...props} />
      )}
    </Modal>
  )
}
