import type {
  AvalaraCompanies,
  ConfigurationManagementProps
} from 'Integrations/domain'
import type { AvalaraIntegrationConfig } from 'Integrations/integrationsConfig/avalara/avalara.integration.config'
import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import AvalaraAuthenticationCard from './cards/AvalaraAuthenticationCard'
import AvalaraCompanyCard from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/cards/AvalaraCompanyCard'
import invariant from 'tiny-invariant'
import AvalaraCategoriesConfigurationBanner from 'Integrations/integrationsConfig/avalara/AvalaraConfigurationManagement/cards/AvalaraCategoriesConfigurationBanner'

function checkConfigurationCompletness(
  configuration: AvalaraIntegrationConfig
): configuration is Required<AvalaraIntegrationConfig> {
  return Boolean(
    configuration.accountId &&
      configuration.obfuscatedLicenseKey &&
      configuration.companyId
  )
}

export const AvalaraConfigurationManagement = ({
  updateValidationState,
  updateConfiguration,
  integration
}: ConfigurationManagementProps) => {
  invariant(integration.id === 'Avalara', 'Integration is not Avalara')

  const avalaraConfiguration =
    integration.configuration as AvalaraIntegrationConfig

  const isExistingConfigComplete =
    checkConfigurationCompletness(avalaraConfiguration)

  const [avalaraCompanies, setAvalaraCompanies] = useState<
    AvalaraCompanies | undefined
  >()
  const [wasConnectionSuccessful, setWasConnectionSuccessful] = useState(false)

  const updateCredentials = (
    credentials: { accountId: string; licenseKey: string },
    avalaraCompaniesFromConnectionTest: AvalaraCompanies
  ) => {
    setWasConnectionSuccessful(true)
    setAvalaraCompanies(avalaraCompaniesFromConnectionTest)
    updateConfiguration(credentials)

    // If the companies returned from the updated credentials connection test contain the company
    // that was already enabled in the configuration, we keep it enabled
    if (
      avalaraCompaniesFromConnectionTest.find(
        company => company.companyId === avalaraConfiguration.avalaraCompanyId
      )?.isEnabled
    ) {
      updateValidationState(true)
      updateConfiguration({
        companyId: avalaraConfiguration.companyId
      })
    }
  }

  const resetValidationState = () => {
    if (wasConnectionSuccessful) {
      updateValidationState(false)
      setWasConnectionSuccessful(false)
    }
  }

  return (
    <Flex grow={1} width="100%">
      <Flex flexDirection="column" gap="20px" grow={1}>
        {isExistingConfigComplete ? (
          <AvalaraCategoriesConfigurationBanner />
        ) : null}
        <AvalaraAuthenticationCard
          onConnectionSuccess={updateCredentials}
          onCredentialsChange={resetValidationState}
          existingCredentials={{
            accountId: avalaraConfiguration.accountId,
            licenseKey: avalaraConfiguration.obfuscatedLicenseKey
          }}
        />

        <AvalaraCompanyCard
          enabledAvalaraCompanyId={avalaraConfiguration.avalaraCompanyId}
          avalaraCompanies={avalaraCompanies}
          setAvalaraCompany={companyId => {
            updateConfiguration({
              companyId
            })
            if (wasConnectionSuccessful || isExistingConfigComplete) {
              updateValidationState(true)
            }
          }}
        />
      </Flex>
    </Flex>
  )
}
