import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'
import {
  GreyGrey0,
  GreyGrey20,
  GreyGrey30,
  GreyGrey70,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { IntegrationGroupListItem } from './IntegrationGroupListItem'
import { useIntegrationsList } from './useIntegrationsList'
import { AvailableServices } from 'modules/Integrations/domain'

/**
 * TODO: similarly to the IntegrationsNavigation, it would be good to utilse the same navigation
 * item components here, rather than repeat the pattern with subtle tweaks. DAVIN-579.
 * @returns
 */
type IntegrationListProps = {
  activeIntegration: AvailableServices | null
  onSelectIntegration: (integration: AvailableServices) => void
}
export const IntegrationList = (props: IntegrationListProps) => {
  const hook = useIntegrationsList(props)

  return (
    <Flex
      flexDirection="column"
      gap="16px"
      backgroundColor={GreyGrey0}
      borderRight={`1px solid ${GreyGrey30}`}
      height="100%"
      padding="16px"
    >
      {hook.integrationGroups.map(group => (
        <Box key={group.id}>
          <Grid
            key={group.title}
            padding="0 8px"
            templateColumns="1fr auto"
            height="24px"
            alignContent="center"
            cursor="pointer"
            borderRadius="6px"
            _hover={{
              backgroundColor: GreyGrey20
            }}
            onClick={group.onClick}
            userSelect="none"
          >
            <GridItem
              {...Lato13Bold}
              color={GreyGrey70}
              textTransform="capitalize"
            >
              {group.title}
            </GridItem>
            <GridItem>
              {group.isExpanded ? (
                <ChevronUpIcon height="16px" width="16px" color={GreyGrey70} />
              ) : (
                <ChevronDownIcon
                  height="16px"
                  width="16px"
                  color={GreyGrey70}
                />
              )}
            </GridItem>
          </Grid>
          {group.isExpanded && (
            <Flex flexDirection="column" gap="1px">
              {group.integrations.map(integration => (
                <IntegrationGroupListItem
                  key={integration.id}
                  {...integration}
                />
              ))}
            </Flex>
          )}
        </Box>
      ))}
    </Flex>
  )
}
