import { useCreateSeatTypeOutletConnector } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/drawers/CreatSeatTypeDrawer/useCreateSeatTypeOutletConnector'
import { CreateSeatTypeForm } from 'modules/Seats/SeatMetric'
import Sidebar from 'components/Sidebar'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * @returns
 */
export const SeatTypeRouteComponent = () => {
  const { onSuccess, onCancel } = useCreateSeatTypeOutletConnector()

  return (
    <Sidebar>
      <CreateSeatTypeForm onSuccess={onSuccess} handleCancel={onCancel} />
    </Sidebar>
  )
}
