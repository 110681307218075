import { FormFields, useForm } from '@sequencehq/utils'
import deepmerge from 'deepmerge'
import { useMemo } from 'react'
import sequenceLogo from 'modules/Integrations/integrationsConfig/common/sequenceLogo.svg'
import { HubSpotConfig } from 'modules/Integrations/integrationsConfig/hubSpot/hubspot.integration.config'
import { ConfigurationManagementProps } from 'Integrations/domain'

type UseHubSpotConfigurationManagement = (
  props: ConfigurationManagementProps
) => {
  fieldsConfig: {
    importCustomerWorkflow: FormFields<HubSpotConfig>['importCustomerWorkflow'] & {
      logoUrls: {
        from: string
        to: string
      }
      status: 'ACTIVE' | 'INACTIVE'
    }
  }
}

export const useHubSpotConfigurationManagement: UseHubSpotConfigurationManagement =
  props => {
    const { fields } = useForm({
      value: (props.integration.configuration ?? {
        importCustomerWorkflow: false
      }) as HubSpotConfig,
      fieldConfiguration: [
        {
          property: 'importCustomerWorkflow'
        }
      ],
      onChange: newData => {
        props.updateConfiguration(newData)
      },
      onValidationStateChange: isValid => {
        props.updateValidationState(isValid)
      }
    })

    const enhancedFields = useMemo(() => {
      return deepmerge(fields, {
        importCustomerWorkflow: {
          logoUrls: {
            from: props.integration.smallLogoUrl,
            to: sequenceLogo
          },
          status: props.integration.configuration?.importCustomerWorkflow
            ? ('ACTIVE' as const)
            : ('INACTIVE' as const)
        }
      })
    }, [fields, props.integration])

    return {
      fieldsConfig: enhancedFields
    }
  }
