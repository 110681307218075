import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Button,
  MenuItem,
  Box
} from '@chakra-ui/react'
import Cog6ToothIcon from '@heroicons/react/16/solid/Cog6ToothIcon'
import ChevronDownIcon from '@heroicons/react/16/solid/ChevronDownIcon'

import { GreyGrey30 } from '@sequencehq/design-tokens'
import { SimpleSearchMenuInput, useSimpleSearch } from '@sequencehq/tables'
import { Option } from '@sequencehq/core-models'

import { UseInvoicesGridData } from 'components/Invoices/InvoicesGrid/useInvoicesGrid'
import { InvoicesViewSettingsHeader } from 'components/Invoices/InvoicesViewSettingsHeader'
import { useMemo } from 'react'

type Props = {
  data: UseInvoicesGridData
}

export const InvoicesGridToolbarV3 = ({ data }: Props) => {
  const { enabledCurrencies, currency, updateCurrency } = data

  const allOptions: Option[] = useMemo(
    () =>
      enabledCurrencies.map(enabledCurrency => ({
        label: enabledCurrency,
        value: enabledCurrency
      })),
    [enabledCurrencies]
  )

  const { results, input } = useSimpleSearch({
    items: allOptions,
    searchOn: 'label'
  })

  return (
    <Flex
      borderBottom={`${GreyGrey30} 1px solid`}
      px="16px"
      py="8px"
      width="100%"
      gap="8px"
      justifyContent="space-between"
    >
      <Menu variant="component-library" isLazy={true}>
        <MenuButton
          as={Button}
          rightIcon={
            <ChevronDownIcon color="inherit" width="16px" height="16px" />
          }
          iconSpacing="8px"
          variant="component-library-secondary"
          width="164px"
          textAlign="left"
        >
          {currency ?? 'Select currency'}
        </MenuButton>

        <Portal>
          <MenuList
            maxHeight="500px"
            overflowY="scroll"
            paddingTop="0px !important"
          >
            <SimpleSearchMenuInput
              value={input.value}
              onChange={input.onChange}
              placeholder="Currency"
              showDivider={results.length > 0}
            />

            {results.length > 0 && <Box height="4px" />}

            {results.map(({ label, value }) => (
              <MenuItem key={value} onClick={() => updateCurrency(value)}>
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>

      <Menu variant="component-library" isLazy={true}>
        <MenuButton
          as={Button}
          leftIcon={
            <Cog6ToothIcon color="inherit" width="16px" height="16px" />
          }
          iconSpacing="4px"
          variant="component-library-secondary"
        >
          View settings
        </MenuButton>

        <Portal>
          <MenuList>
            <InvoicesViewSettingsHeader />
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  )
}
