import { FC } from 'react'

interface WordmarkProps {
  height?: string
  width?: string
  color?: string
}

export const Wordmark: FC<WordmarkProps> = ({
  height = '23',
  width = '107',
  color = '#241F25'
}: WordmarkProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 107 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.23277 17.7498C10.339 17.7498 12.0043 17.2973 13.2044 16.3924C14.4044 15.4875 15.0167 14.2492 15.0167 12.6775C15.0167 11.3678 14.5759 10.32 13.6942 9.53413C12.8125 8.77209 11.3921 8.17676 9.40833 7.74812L7.8899 7.43854C6.42045 7.15278 5.44082 6.77177 4.90203 6.34312C4.36323 5.96211 4.09383 5.36677 4.09383 4.58093C4.09383 3.8189 4.38772 3.24738 4.99999 2.81874C5.58777 2.41391 6.46943 2.19959 7.6205 2.19959C9.08994 2.19959 10.143 2.46153 10.8043 2.98543C11.4655 3.50933 11.8329 4.29517 11.9309 5.39059H14.5269C14.4779 3.72365 13.8901 2.41391 12.7391 1.46137C11.588 0.508832 9.87365 0.0325623 7.59601 0.0325623C5.68573 0.0325623 4.19179 0.461204 3.08971 1.29468C1.96314 2.12814 1.42434 3.24738 1.42434 4.62856C1.42434 6.03355 1.86517 7.12897 2.79582 7.91481C3.70198 8.72447 5.04897 9.29599 6.86129 9.62938L8.35522 9.93895C9.82467 10.2723 10.8533 10.6295 11.4411 11.0344C12.0043 11.4392 12.2982 12.0107 12.2982 12.7489C12.2982 13.6777 11.9309 14.3921 11.2451 14.8683C10.5594 15.3446 9.55527 15.5827 8.28175 15.5827C5.14693 15.5827 3.57953 14.3206 3.53054 11.7726H0.910034C0.910034 13.7253 1.57128 15.2255 2.8448 16.2257C4.11832 17.2497 5.93064 17.7498 8.23277 17.7498Z"
      fill={color}
    />
    <path
      d="M28.2376 11.3201C28.2376 9.41506 27.6988 7.86718 26.6457 6.62889C25.5681 5.4144 24.0987 4.79525 22.2374 4.79525C20.4496 4.79525 19.0046 5.39059 17.9025 6.55744C16.776 7.74812 16.2372 9.29599 16.2372 11.2249C16.2372 13.1776 16.776 14.7255 17.9025 15.8923C19.0046 17.083 20.4741 17.6545 22.2864 17.6545C23.7313 17.6545 24.9559 17.2973 25.9355 16.5829C26.9151 15.8685 27.6009 14.8683 27.9437 13.6062H25.4702C24.9804 14.9874 23.9273 15.6542 22.3354 15.6542C21.2578 15.6542 20.4251 15.3684 19.8128 14.7493C19.176 14.1301 18.8087 13.2252 18.7107 12.0107H28.2376V11.3201ZM22.2374 6.79558C23.217 6.79558 24.0007 7.10515 24.613 7.7243C25.2253 8.34345 25.5926 9.1293 25.7151 10.1295H18.7352C18.8822 9.05786 19.2495 8.22439 19.8618 7.65286C20.4741 7.08134 21.2578 6.79558 22.2374 6.79558Z"
      fill={color}
    />
    <path
      d="M39.2815 6.89083C38.3264 5.50965 36.9304 4.79525 35.0936 4.79525C33.3303 4.79525 31.9098 5.39059 30.8812 6.53363C29.8281 7.70049 29.3138 9.27218 29.3138 11.2249C29.3138 13.2014 29.8281 14.7731 30.8812 15.9161C31.9098 17.083 33.3303 17.6545 35.0936 17.6545C36.857 17.6545 38.2284 17.0115 39.1836 15.7018V22.0362H41.6327V5.08101H39.2815V6.89083ZM32.865 14.4873C32.1792 13.7491 31.8608 12.7013 31.8608 11.3201C31.8608 9.93895 32.1792 8.84354 32.865 8.03388C33.5262 7.24803 34.4324 6.84321 35.559 6.84321C36.71 6.84321 37.6162 7.22422 38.2774 7.98625C38.9142 8.77209 39.2571 9.8437 39.2571 11.2249C39.2571 12.6299 38.9142 13.7015 38.2774 14.4397C37.6162 15.2255 36.71 15.6066 35.559 15.6066C34.4324 15.6066 33.5262 15.2493 32.865 14.4873Z"
      fill={color}
    />
    <path
      d="M52.1894 12.0107C52.1894 13.13 51.8711 14.0111 51.2588 14.6302C50.6465 15.2494 49.8873 15.5589 49.0056 15.5589C47.2178 15.5589 46.3362 14.7016 46.3362 12.9871V5.08101H43.8871V13.3205C43.8871 14.7016 44.2789 15.7494 45.0626 16.5115C45.8463 17.2735 46.9239 17.6545 48.3199 17.6545C49.9853 17.6545 51.3078 16.9639 52.2874 15.5351V17.3687H54.6385V5.08101H52.1894V12.0107Z"
      fill={color}
    />
    <path
      d="M68.3047 11.3201C68.3047 9.41506 67.7659 7.86718 66.7128 6.62889C65.6352 5.4144 64.1657 4.79525 62.3044 4.79525C60.5166 4.79525 59.0717 5.39059 57.9696 6.55744C56.843 7.74812 56.3042 9.29599 56.3042 11.2249C56.3042 13.1776 56.843 14.7255 57.9696 15.8923C59.0717 17.083 60.5411 17.6545 62.3534 17.6545C63.7984 17.6545 65.0229 17.2973 66.0025 16.5829C66.9822 15.8685 67.6679 14.8683 68.0108 13.6062H65.5372C65.0474 14.9874 63.9943 15.6542 62.4024 15.6542C61.3248 15.6542 60.4921 15.3684 59.8799 14.7493C59.2431 14.1301 58.8757 13.2252 58.7778 12.0107H68.3047V11.3201ZM62.3044 6.79558C63.2841 6.79558 64.0678 7.10515 64.68 7.7243C65.2923 8.34345 65.6597 9.1293 65.7821 10.1295H58.8023C58.9492 9.05786 59.3166 8.22439 59.9288 7.65286C60.5411 7.08134 61.3248 6.79558 62.3044 6.79558Z"
      fill={color}
    />
    <path
      d="M76.2873 4.79525C74.5974 4.79525 73.2749 5.50965 72.3197 6.91465V5.08101H69.9686V17.3687H72.4177V10.439C72.4177 9.29599 72.7361 8.41489 73.3973 7.79574C74.0341 7.20041 74.7688 6.89083 75.6015 6.89083C77.3648 6.89083 78.271 7.74812 78.271 9.46268V17.3687H80.7201V9.1293C80.7201 7.74812 80.3282 6.70033 79.5445 5.9383C78.7608 5.17627 77.6587 4.79525 76.2873 4.79525Z"
      fill={color}
    />
    <path
      d="M88.4105 17.6545C89.9289 17.6545 91.2024 17.2497 92.231 16.3924C93.2597 15.5589 93.8719 14.4159 94.0679 12.9871H91.5698C91.4229 13.8444 91.0555 14.4873 90.4922 14.916C89.9044 15.3684 89.2187 15.5827 88.4105 15.5827C87.3329 15.5827 86.5002 15.2255 85.8634 14.4635C85.2267 13.7015 84.9328 12.6299 84.9328 11.2249C84.9328 9.8437 85.2267 8.77209 85.8634 8.01007C86.5002 7.24803 87.3329 6.86702 88.4105 6.86702C89.1942 6.86702 89.8799 7.08134 90.4432 7.50998C91.0065 7.93862 91.3739 8.55777 91.5453 9.36743H94.0434C93.8474 7.98625 93.2352 6.86702 92.231 6.03355C91.2269 5.22389 89.9534 4.79525 88.4105 4.79525C86.5982 4.79525 85.1287 5.39059 84.0511 6.53363C82.9246 7.7243 82.3858 9.27218 82.3858 11.2249C82.3858 13.1776 82.9246 14.7255 84.0511 15.8923C85.1532 17.083 86.5982 17.6545 88.4105 17.6545Z"
      fill={color}
    />
    <path
      d="M106.913 11.3201C106.913 9.41506 106.374 7.86718 105.321 6.62889C104.243 5.4144 102.774 4.79525 100.913 4.79525C99.1247 4.79525 97.6798 5.39059 96.5777 6.55744C95.4511 7.74812 94.9123 9.29599 94.9123 11.2249C94.9123 13.1776 95.4511 14.7255 96.5777 15.8923C97.6798 17.083 99.1492 17.6545 100.962 17.6545C102.406 17.6545 103.631 17.2973 104.611 16.5829C105.59 15.8685 106.276 14.8683 106.619 13.6062H104.145C103.656 14.9874 102.602 15.6542 101.011 15.6542C99.9329 15.6542 99.1002 15.3684 98.488 14.7493C97.8512 14.1301 97.4839 13.2252 97.3859 12.0107H106.913V11.3201ZM100.913 6.79558C101.892 6.79558 102.676 7.10515 103.288 7.7243C103.9 8.34345 104.268 9.1293 104.39 10.1295H97.4104C97.5573 9.05786 97.9247 8.22439 98.537 7.65286C99.1492 7.08134 99.9329 6.79558 100.913 6.79558Z"
      fill={color}
    />
  </svg>
)
