import { SimpleDialog } from '@sequencehq/core-components'
import { Text } from '@chakra-ui/react'
import { GreyGrey80, Lato13Regular } from '@sequencehq/design-tokens'

interface Props {
  onConfirm: VoidFunction
  trigger: (openDialog: () => void) => React.ReactNode
  isSubmitting?: boolean
}

export const VoidCreditNoteDialog = ({
  onConfirm,
  trigger,
  isSubmitting
}: Props) => {
  return (
    <SimpleDialog
      title="Void credit note"
      confirmButtonText="Void credit note"
      destructive
      onConfirm={onConfirm}
      trigger={trigger}
      isSubmitting={isSubmitting}
      closeOnCancel={!isSubmitting}
      closeOnSubmit={false}
    >
      <Text {...Lato13Regular} color={GreyGrey80}>
        You won't be able to make any further changes to this credit note after
        voiding.
      </Text>
    </SimpleDialog>
  )
}
