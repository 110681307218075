import { Flex } from '@chakra-ui/react'
import {
  ArchiveTaxRateFormMutation,
  ArchiveTaxRateFormProps
} from 'components/ArchiveTaxRate/ArchiveTaxRateModal'
import { FormErrors } from '@sequencehq/forms'
import { MutationFormProps } from 'components/Form/types'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

export const ArchiveTaxRateForm: FC<
  MutationFormProps<ArchiveTaxRateFormMutation> & ArchiveTaxRateFormProps
> = ({ id, name, formId, submitForm }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{}}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          id
        })
        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            Archive tax rate {name}?
            <FormErrors formError={submitError} />
          </Flex>
        )
      }}
    />
  )
}
