import { FC } from 'react'

interface NetsuiteIconProps {
  width?: number
  height?: number
}

export const NetsuiteIcon: FC<NetsuiteIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12.8159H36.9209V34.155L27.2159 21.8498H12"
      fill="#00467F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1621 29.9644C30.3974 33.9664 33.5031 38.099 36.7024 42.137H52.0006V51.1842H27.1621"
      fill="#00467F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.4805 21.8143V14.647H50.2423V40.5551H42.4295V21.8143"
      fill="#B9C7D4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6654 23.3853V41.9737C22.9006 41.9737 24.2514 41.9737 25.5311 41.9737V49.2934H13.8516V23.3853"
      fill="#B9C7D4"
    />
    <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#F1F4F8" />
  </svg>
)
