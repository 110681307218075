import { usePricingEditorRoot } from './usePricingEditor.root'
import { PricingEditorProvider } from '../communication'
import {
  EditorMode,
  PricingEditorPortErrors,
  PricingEditorVariant
} from '../domain'
import { ListPriceLayout } from './layout/listPrice/ListPriceLayout'
import { VariantPriceLayout } from './layout/variantPrice/VariantPriceLayout'
import { ModalContextProvider } from '@sequencehq/core-components'
import { Outlet } from 'react-router-dom'
import NotFound from 'components/ErrorPages/NotFound'
import PageError from 'components/ErrorPages/PageError'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const PricingEditorRoot = (props: {
  variant: PricingEditorVariant
  initialMode: EditorMode
  currency?: Currency
  variantPriceId?: string
  onSave?: (data: { price: any }) => void
}) => {
  const { domain, ready, error } = usePricingEditorRoot({
    variant: props.variant,
    initialMode: props.initialMode,
    currency: props.currency,
    variantPriceId: props.variantPriceId,
    onSave: props.onSave
  })

  if (error === PricingEditorPortErrors.NotFound) {
    return <NotFound />
  }

  if (error === PricingEditorPortErrors.Other) {
    return <PageError />
  }

  return (
    <PricingEditorProvider value={domain}>
      <ModalContextProvider isOpen>
        <>
          {props.variant === 'list' && (
            <ListPriceLayout loading={!ready} initialMode={props.initialMode} />
          )}
          {props.variant === 'variant' && (
            <VariantPriceLayout
              loading={!ready}
              variantPriceId={props.variantPriceId}
            />
          )}
        </>
        <Outlet />
      </ModalContextProvider>
    </PricingEditorProvider>
  )
}
