import {
  CubeReducerState,
  Discount,
  Minimum,
  Phase,
  Price,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'
import { getInvalidPriceProration } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/getInvalidPriceProration'
import { getInvalidMinimumProration } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/getInvalidMinimumProration'
import { getInvalidDiscountProration } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/getInvalidDiscountProration'
import { getNextBillingDateAfterEndDate } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/getNextBillingDateAfterEndDate'
import { getEndDateAlignedToBillingPeriod } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/getEndDateAlignedToBillingPeriod'
import { getDate } from '@sequencehq/utils/dates'

export type PhaseAnalysis = {
  id: Phase['id']
  isFirstPhase: boolean
  isLastPhase: boolean
  proration: {
    invalidMinimums: Minimum[]
    invalidPrices: Price[]
    invalidDiscounts: Discount[]
  }
  alignedWithPeriod: {
    start: boolean
    end: boolean
    nextBillingDateAfterEndDate: Date | null
  }
} | null

export const getPhaseAnalysis =
  (ctx: { reducerState: CubeReducerState; previousPhase?: ResolvedPhase }) =>
  (phase: Omit<ResolvedPhase, 'analysis'>): PhaseAnalysis | null => {
    const isLastPhase =
      ctx.reducerState.data.common.phaseIds[
        ctx.reducerState.data.common.phaseIds.length - 1
      ] === phase.id

    const alignedWithStartDate = phase.dates.absolute.start
      ? getDate(phase.dates.absolute.start) ===
        ctx.reducerState.data.schedule.recurrenceDayOfMonth
      : true

    return {
      id: phase.id,
      isFirstPhase: ctx.reducerState.data.common.phaseIds[0] === phase.id,
      isLastPhase,
      proration: {
        invalidMinimums: getInvalidMinimumProration({
          previousPhase: ctx.previousPhase,
          phase
        }),
        invalidPrices: getInvalidPriceProration({
          previousPhase: ctx.previousPhase,
          phase
        }),
        invalidDiscounts: getInvalidDiscountProration({
          previousPhase: ctx.previousPhase,
          phase
        })
      },
      alignedWithPeriod: {
        start: alignedWithStartDate,
        end:
          getEndDateAlignedToBillingPeriod({
            recurrenceDayOfMonth:
              ctx.reducerState.data.schedule.recurrenceDayOfMonth,
            phaseDates: phase.dates,
            prices: phase.prices
          }) || isLastPhase,
        nextBillingDateAfterEndDate: getNextBillingDateAfterEndDate({
          recurrenceDayOfMonth:
            ctx.reducerState.data.schedule.recurrenceDayOfMonth,
          phaseDates: phase.dates,
          prices: phase.prices
        })
      }
    }
  }
