import { Box, Button, Grid, GridItem } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { useInstructions } from './useInstructions'

export const Instructions = () => {
  const hook = useInstructions()

  return (
    <Grid
      width="100%"
      gridTemplateColumns="1fr auto"
      height="68px"
      padding="16px"
      alignItems="center"
      backgroundColor={GreyGrey10}
      borderRadius="8px"
      color={GreyGrey70}
      userSelect="none"
    >
      <GridItem display="grid" gridTemplateRows="auto auto" gridGap="4px">
        <Box {...Lato14Bold}>Browse integrations</Box>
        <Box {...Lato13Regular}>Select an integration to get started</Box>
      </GridItem>
      <GridItem display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          variant="component-library-secondary"
          onClick={hook.documentation.onClick}
        >
          Learn more
        </Button>
      </GridItem>
    </Grid>
  )
}
