import { useCallback } from 'react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

export type v1ApiListPrices = Dashboardv20240509Api.GetListPrices.ListPricesList

type UseLoadListPrices = () => (props: {
  productId: string
}) => Promise<v1ApiListPrices['items']>

export const useLoadListPrices: UseLoadListPrices = () => {
  const load = useCallback(async ({ productId }: { productId: string }) => {
    const listPrices =
      (await dashboardv20240509Client.getListPrices({ productId, limit: 100 }))
        .data?.items ?? []

    return listPrices
  }, [])

  return load
}
