import { Box, Text } from '@chakra-ui/react'
import { TextAreaField, TextField } from '@sequencehq/core-components'
import { GreyGrey90 } from '@sequencehq/design-tokens'
import { useProductFieldsForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/ProductFields/useProductFieldsForm'

export const ProductFieldsForm = () => {
  const { fieldsConfig } = useProductFieldsForm()

  return (
    <Box paddingBottom="8px" marginBottom="16px">
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Product Information
      </Text>
      <TextField
        label="Product name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a product name"
      />
      <TextAreaField
        label="Description"
        value={fieldsConfig.label.value}
        onChange={fieldsConfig.label.onChange}
        validationErrors={fieldsConfig.label.validationErrors}
        isDisabled={fieldsConfig.label.disabled}
        placeholder="Enter a product description"
      />
    </Box>
  )
}
