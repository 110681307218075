import {
  HorizontalSelectorField,
  ModalContextProvider,
  SimpleModalUI,
  TextAreaField,
  TextInputField
} from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import {
  isPositiveNumber,
  required,
  percentage,
  lessThanEqualTo
} from '@sequencehq/validation'
import { useForm, decimalFromPercentage } from '@sequencehq/utils'
import { currencyToSymbol, Currency } from '@sequencehq/core-models'
import {
  CreditNoteLineItemRateDisplay,
  creditNoteLineItemRateDisplay
} from '@sequencehq/api/src/clients/dashboard/v99990101/commonModels/creditNoteLineItems'
import { MAX_INPUT_TOTAL, MAX_QUANTITY } from 'CreditNotes/constants'
import useToggle from 'lib/hooks/useToggle'
import { useCreateCreditNoteLineItem } from 'CreditNotes/hooks/useCreateCreditNoteLineItem'

const creditNoteRateDisplayOptions = [
  {
    label: 'Fixed',
    value: creditNoteLineItemRateDisplay.ABSOLUTE
  },
  {
    label: 'Percentage',
    value: creditNoteLineItemRateDisplay.PERCENTAGE
  }
]

const initialValues = {
  name: '',
  description: '',
  rateDisplay:
    creditNoteLineItemRateDisplay.ABSOLUTE as CreditNoteLineItemRateDisplay,
  quantity: '1',
  amount: '',
  tax: ''
}

type FormValues = typeof initialValues

interface Props {
  creditNoteId: string
  groupId: string
  isOpen: boolean
  onClose: () => void
  creditNoteCurrency: Currency
}

const CreateCreditNoteLineItemDrawer = ({
  creditNoteId,
  groupId,
  isOpen,
  onClose,
  creditNoteCurrency
}: Props) => {
  const [isFormValid, toggleIsFormValid] = useToggle(true)

  const createCreditNoteLineItem = useCreateCreditNoteLineItem({
    creditNoteId,
    groupId
  })

  const { fields, queries, mutators } = useForm({
    value: initialValues,
    showValidationErrors: !isFormValid,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'description',
        validation: []
      },
      {
        property: 'rateDisplay',
        validation: []
      },
      {
        property: 'quantity',
        validation: [
          required,
          isPositiveNumber,
          (value: unknown) =>
            lessThanEqualTo(
              MAX_QUANTITY,
              `Amount can't be more than ${MAX_QUANTITY.toLocaleString()}`
            )(value)
        ]
      },
      {
        property: 'amount',
        validation: [
          required,
          isPositiveNumber,
          (value: unknown) =>
            lessThanEqualTo(
              MAX_INPUT_TOTAL,
              `Amount can't be more than ${MAX_INPUT_TOTAL.toLocaleString()}`
            )(value)
        ]
      },
      {
        property: 'tax',
        validation: [required, percentage]
      }
    ]
  })

  const rateDisplay = queries.formData.rateDisplay

  const handleSubmit = async (formValues: FormValues) => {
    const isAbsolute =
      formValues.rateDisplay === creditNoteLineItemRateDisplay.ABSOLUTE

    await createCreditNoteLineItem({
      title: formValues.name,
      description: formValues.description,
      rateDisplay: formValues.rateDisplay,
      quantity: formValues.quantity,
      rate: isAbsolute
        ? formValues.amount
        : decimalFromPercentage(formValues.amount),
      taxRate: decimalFromPercentage(formValues.tax)
    })
  }

  const handleClose = () => {
    mutators.resetFormData()
    toggleIsFormValid(true)
    onClose()
  }

  if (!isOpen) {
    return null
  }

  return (
    <ModalContextProvider isOpen>
      <SimpleModalUI
        unmountOnClose
        onCancel={handleClose}
        onClose={handleClose}
        variant="drawer"
        title="Add line item"
        cancelButtonText="Cancel"
        submitButtonText="Add line item"
        onSubmit={async () => {
          if (!queries.isValid) {
            toggleIsFormValid(false)
            return
          }

          await handleSubmit(queries.formData)
          handleClose()
        }}
        closeOnSubmit={false}
      >
        <Flex flexDirection="column">
          <Box>
            <Label>Name</Label>
            <TextInputField
              data-testid="creditNoteLineItemDrawer.name"
              value={fields.name.value}
              validationErrors={fields.name.validationErrors}
              onChange={event => fields.name.onChange(event.target.value)}
              placeholder="Line item name"
            />
          </Box>
          <Box>
            <Label>Description</Label>
            <TextAreaField
              data-testid="creditNoteLineItemDrawer.description"
              value={fields.description.value}
              validationErrors={fields.description.validationErrors}
              onChange={fields.description.onChange}
              placeholder="Optional description"
            />
          </Box>
          <HorizontalSelectorField
            data-testid="creditNoteLineItemDrawer.rate"
            label="Rate"
            value={fields.rateDisplay.value}
            options={creditNoteRateDisplayOptions}
            validationErrors={[]}
            onChange={(value: string) =>
              fields.rateDisplay.onChange(
                value as CreditNoteLineItemRateDisplay
              )
            }
          />
          {rateDisplay === creditNoteLineItemRateDisplay.ABSOLUTE && (
            <>
              <Box>
                <Label>Quantity</Label>
                <TextInputField
                  type="number"
                  data-testid="creditNoteLineItemDrawer.quantity"
                  value={fields.quantity.value}
                  validationErrors={fields.quantity.validationErrors}
                  onChange={event =>
                    fields.quantity.onChange(event.target.value)
                  }
                  placeholder="1"
                />
              </Box>
              <Box>
                <Label>Amount</Label>
                <TextInputField
                  type="number"
                  data-testid="creditNoteLineItemDrawer.amount"
                  prefix={currencyToSymbol[creditNoteCurrency]}
                  value={fields.amount.value}
                  validationErrors={fields.amount.validationErrors}
                  onChange={event => fields.amount.onChange(event.target.value)}
                  placeholder="0.00"
                />
              </Box>
            </>
          )}

          {rateDisplay === creditNoteLineItemRateDisplay.PERCENTAGE && (
            <>
              <Box>
                <Label>Quantity</Label>
                <TextInputField
                  type="number"
                  data-testid="creditNoteLineItemDrawer.quantity"
                  prefix={currencyToSymbol[creditNoteCurrency]}
                  value={fields.quantity.value}
                  validationErrors={fields.quantity.validationErrors}
                  onChange={event =>
                    fields.quantity.onChange(event.target.value)
                  }
                  placeholder="0.00"
                />
              </Box>
              <Box>
                <Label>Amount</Label>
                <TextInputField
                  type="number"
                  data-testid="creditNoteLineItemDrawer.amount"
                  prefix="%"
                  value={fields.amount.value}
                  onChange={event => fields.amount.onChange(event.target.value)}
                  validationErrors={
                    parseFloat(fields.amount.value) > 100
                      ? [{ message: 'Please enter a number between 0 and 100' }]
                      : fields.amount.validationErrors
                  }
                  placeholder="0"
                />
              </Box>
            </>
          )}
          <Box>
            <Label>Tax</Label>
            <TextInputField
              type="number"
              data-testid="creditNoteLineItemDrawer.tax"
              prefix="%"
              value={fields.tax.value}
              onChange={event => fields.tax.onChange(event.target.value)}
              validationErrors={fields.tax.validationErrors}
              placeholder="0"
            />
          </Box>
        </Flex>
      </SimpleModalUI>
    </ModalContextProvider>
  )
}

export default CreateCreditNoteLineItemDrawer
