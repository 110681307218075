import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getEditStartDateAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const {
    common: { isArchived, status }
  } = prevState.data
  if (isArchived || status === CubeStatus.QuoteExecuted) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: isArchived
            ? DisabledReasonType.CantChangeWhenArchived
            : DisabledReasonType.CantChangeWhenExecuted,
          metadata: {}
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
