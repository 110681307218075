import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { isSuccessResponse } from 'components/Form'
import { Toast } from '@sequencehq/core-components'
import { usePutCustomersByIdArchiveMutation } from 'features/api/integratedApi.generated'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, useCallback, useState } from 'react'
import { Customer } from 'components/Customers/types.ts'

export type ArchiveCustomerModalProps = {
  customer: Pick<Customer, 'legalName' | 'id'>
}

type UseArchiveCustomer = {
  submitting: boolean
  onConfirm: () => void
  onClose: () => void
  onSuccess?: () => void
}

const useArchiveCustomer: (
  customerId: string,
  onSuccess?: () => void
) => UseArchiveCustomer = (customerId, onSuccess) => {
  const formId = 'archiveCustomerForm'

  const [submitForm] = usePutCustomersByIdArchiveMutation()
  const dispatch = useDispatch()
  const toast = useToast()

  const [submitting, setSubmitting] = useState<boolean>(false)

  const onConfirm = useCallback(() => {
    setSubmitting(true)
    submitForm({
      id: customerId
    })
      .then(result => {
        if (isSuccessResponse(result)) {
          const validatedData = result.data?.value()

          dispatch(closeOverlay())

          if (validatedData) {
            toast({
              id: `success-${formId}`,
              position: 'bottom',
              duration: 6000,
              render: () => (
                <Toast
                  title="Customer archived"
                  type="success"
                  isClosable={true}
                ></Toast>
              )
            })

            if (onSuccess) {
              onSuccess()
            }
          }
        }
      })

      .catch(() => {
        toast({
          id: `error-${formId}`,
          position: 'bottom',
          duration: 6000,
          render: () => (
            <Toast
              title="Could not archive customer"
              type="error"
              isClosable={true}
            ></Toast>
          )
        })
      })
      .finally(() => setSubmitting(false))
  }, [])

  const onClose = useCallback(() => dispatch(closeOverlay()), [])

  return {
    submitting,
    onConfirm,
    onClose
  }
}

export const ArchiveCustomerModal: FC<ArchiveCustomerModalProps> = ({
  customer
}) => {
  const { submitting, onConfirm, onClose } = useArchiveCustomer(customer.id)

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="14px">
            Archive {customer.legalName}
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="14px"
          >
            {' '}
            This will archive the customer immediately. All billing schedules
            for this customer will be archived and any remaining draft invoices
            will be voided. Finalised and sent invoices will remain.
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            This action cannot be undone.
          </Box>
          <Flex gap="8px" justifyContent="flex-end">
            <Flex basis="50%">
              <Button
                variant="secondary2"
                onClick={onClose}
                height="32px"
                width="100%"
              >
                Cancel
              </Button>
            </Flex>
            <Flex basis="50%">
              <Button
                variant="primary2"
                onClick={onConfirm}
                height="32px"
                isLoading={submitting}
                width="100%"
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
