import { JournalReportSummary } from './JournalReportSummary'
import { JournalReportActions } from './JournalReportActions'
import { JournalReportTable } from './JournalReportTable'
import { DetailTableLayout } from 'RevenueRecognition/view/common/DetailTableLayout'

export const JournalReportDetailContent = () => {
  return (
    <DetailTableLayout
      summary={<JournalReportSummary />}
      actions={<JournalReportActions />}
      table={<JournalReportTable />}
    />
  )
}
