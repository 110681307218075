import { Flex, GridItem } from '@chakra-ui/react'
import { PriceMetadataModel, PriceModel } from '@sequencehq/core-models'
import { ProductPriceTiersTable } from 'modules/Products/drawers/ProductPrice/components/ProductPriceTiersTable'
import ProductPriceAdditionalInfo from 'modules/Products/drawers/ProductPrice/components/ProductPriceAdditionalInfo'
import { ProductPriceMeta } from 'modules/Products/drawers/ProductPrice/components/ProductPriceMeta'
import { useGetPricesByIdMetadataQuery } from 'features/api'
import Spinner from 'components/Loading/Spinner'
import { ProductPriceBillingSchedules } from 'modules/Products/drawers/ProductPrice/components/ProductPriceBillingSchedules'
import { ListPrice, Product } from 'modules/Products/types'

export const ProductPriceContent = ({
  price,
  product,
  fetchMetadata = true
}: {
  price: PriceModel | ListPrice
  product?: Product
  fetchMetadata?: boolean
}) => {
  const { data, isFetching } = useGetPricesByIdMetadataQuery(
    {
      id: price.id
    },
    { skip: !fetchMetadata }
  )

  if (isFetching) {
    return <Spinner />
  }

  const priceMetadata = data?.value() as PriceMetadataModel

  return (
    <GridItem gridArea="content" overflow="auto">
      <Flex
        gap="24px"
        padding="24px"
        direction="column"
        data-testid="pricingEditor.review"
      >
        <ProductPriceMeta price={price} product={product} />
        <ProductPriceTiersTable price={price} />
        <ProductPriceAdditionalInfo price={price} />

        {priceMetadata?.billingSchedules &&
          priceMetadata?.billingSchedules.length > 0 && (
            <ProductPriceBillingSchedules priceMetadata={priceMetadata} />
          )}
      </Flex>
    </GridItem>
  )
}
