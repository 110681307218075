import * as Sentry from '@sentry/react'
import { baseApi } from 'features/api/baseApi'
import { downloadBlob } from 'lib/downloadBlob'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'

type UseInvoicesCsvExport = () => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (queryParams: string) => void
  }
}

export const useInvoicesCsvExport: UseInvoicesCsvExport = () => {
  const notifications = useNotifications()

  const [getDownload] =
    baseApi.endpoints.getInvoiceCsvExport.useLazyQuerySubscription()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-csv',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const downloadCsvFile = useCallback(
    async (queryParams: string) => {
      try {
        const fulfilled = await getDownload(queryParams).unwrap()

        downloadBlob('invoice-export.csv')(
          new Blob([fulfilled], { type: 'text/csv' })
        )

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [getDownload, showToastError, showToastSuccess]
  )

  const downloadCsv = useCallback(
    (queryParams: string) => {
      showToastPreparing()

      void downloadCsvFile(queryParams)
    },
    [showToastPreparing, downloadCsvFile]
  )

  return {
    download: {
      available: true,
      disabled: false,
      onClick: queryParams => downloadCsv(queryParams)
    }
  }
}
