import { Box, Flex, Grid } from '@chakra-ui/react'
import { Card, Switch } from '@sequencehq/core-components'
import { Lato14Bold } from '@sequencehq/design-tokens'
import { WorkflowManagement } from 'modules/Integrations/integrationsConfig/common/WorkflowManagement'
import { useHubSpotConfigurationManagement } from './useHubSpotConfigurationManagement'
import { ConfigurationManagementProps } from 'Integrations/domain'

export const HubSpotConfigurationManagement = (
  props: ConfigurationManagementProps
) => {
  const { fieldsConfig } = useHubSpotConfigurationManagement(props)

  return (
    <Flex flexDirection="column" gap="20px" width="100%">
      <Card width="100%">
        <Grid gridRowGap="16px">
          <Box {...Lato14Bold}>Push data into Sequence</Box>
          <WorkflowManagement
            logoUrls={fieldsConfig.importCustomerWorkflow.logoUrls}
            status={fieldsConfig.importCustomerWorkflow.status}
            label="Link customers to Hubspot"
            description="Enables the link to Hubspot option in the customers page."
            onClick={() => {
              fieldsConfig.importCustomerWorkflow.onChange(
                !fieldsConfig.importCustomerWorkflow.value
              )
            }}
          >
            <Flex alignContent="center" onClick={e => e.stopPropagation()}>
              <Switch
                data-testid="switch.linkCustomerWorkflow"
                checked={fieldsConfig.importCustomerWorkflow.value}
                onChange={fieldsConfig.importCustomerWorkflow.onChange}
              />
            </Flex>
          </WorkflowManagement>
        </Grid>
      </Card>
    </Flex>
  )
}
