import { useSaveProducts } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSaveProducts'
import { useSaveQuote } from 'modules/Cube/communication/external/quotes.api.v1/ports/entitySaving'
import {
  ApiClientCreatedQuoteModel,
  ApiClientUpdatedQuoteModel,
  ApiClientQuoteModel,
  ApiClientArchivedQuote,
  v1ApiUpdateQuote
} from 'modules/Cube/communication/external/quotes.api.v1/ports/entitySaving/useSaveQuote'
import { Product } from 'modules/Cube/domain/cube.domain.types'
import { useCallback } from 'react'

type UseSaveQuoteEditor = () => {
  acceptQuote: (id: string) => Promise<{
    success: boolean
    quote: ApiClientUpdatedQuoteModel | null
  }>
  createQuote: () => Promise<{
    success: boolean
    quote: ApiClientCreatedQuoteModel | null
  }>
  executeQuote: (id: string) => Promise<{
    success: boolean
    quote: ApiClientUpdatedQuoteModel | null
  }>
  publishQuote: (
    id: string,
    data: {
      sendRecipientsEmail: boolean
      emailMessage: string | undefined
    }
  ) => Promise<{
    success: boolean
    quote: ApiClientUpdatedQuoteModel | null
  }>
  updateQuote: (id: string) => (
    data: v1ApiUpdateQuote,
    products: Product[]
  ) => Promise<{
    success: boolean
    quote: ApiClientQuoteModel | null
  }>
  archiveQuote: (id: string) => Promise<{
    success: boolean
    quote: ApiClientArchivedQuote | null
  }>
  deleteLatestQuoteDraft: (id: string) => Promise<{
    success: boolean
    quote: ApiClientQuoteModel | null
  }>
  duplicateQuote: (id: string) => Promise<{
    success: boolean
    quote: ApiClientQuoteModel | null
  }>
}

const applyNewProductsToPhases =
  (quoteMutation: v1ApiUpdateQuote) =>
  (newProducts: { temporaryId: string; realId: string }[]) => {
    const phasesWithUpdatedPrices = quoteMutation.phases.map(phase => {
      const updatedPrices = phase.prices.map(price => {
        const newProduct = newProducts.find(
          product => product.temporaryId === price.productId
        )

        if (newProduct) {
          return {
            ...price,
            productId: newProduct.realId
          }
        }
        return price
      })

      return {
        ...phase,
        prices: updatedPrices
      }
    })

    return phasesWithUpdatedPrices
  }

export const useSaveQuoteEditor: UseSaveQuoteEditor = () => {
  const quoteMutator = useSaveQuote()
  const productsMutator = useSaveProducts()

  const updateQuote = useCallback(
    (id: string) => async (data: v1ApiUpdateQuote, products: Product[]) => {
      const newProducts = await productsMutator.createProducts(products)

      if (newProducts.some(({ realId }) => !realId)) {
        return {
          quote: null,
          success: false
        }
      }

      // TS is being annoying and insists `realId` can be null here despite the check earlier
      const updatedPhases = applyNewProductsToPhases(data)(
        newProducts as { temporaryId: string; realId: string }[]
      )

      const updatedQuote = await quoteMutator.update(id, {
        ...data,
        phases: updatedPhases
      })

      if (updatedQuote === null) {
        return {
          success: false,
          quote: null
        }
      }

      return {
        success: true,
        quote: updatedQuote
      }
    },
    [productsMutator, quoteMutator]
  )

  const createQuote = useCallback(async () => {
    const newQuote = await quoteMutator.create()

    if (!newQuote) {
      return {
        success: false,
        quote: null
      }
    }

    return {
      success: true,
      quote: newQuote
    }
  }, [quoteMutator])

  const publishQuote = useCallback(
    async (
      quoteId: string,
      data: {
        sendRecipientsEmail: boolean
        emailMessage: string | undefined
      }
    ) => {
      const publishedQuote = await quoteMutator.publish(quoteId, data)

      if (!publishedQuote) {
        return {
          success: false,
          quote: null
        }
      }

      return {
        success: true,
        quote: publishedQuote
      }
    },
    [quoteMutator]
  )

  const acceptQuote = useCallback(
    async (quoteId: string) => {
      const acceptedQuote = await quoteMutator.accept(quoteId)

      if (!acceptedQuote) {
        return {
          success: false,
          quote: null
        }
      }

      return {
        success: true,
        quote: acceptedQuote
      }
    },
    [quoteMutator]
  )

  const archiveQuote = useCallback(
    async (quoteId: string) => {
      const archivedQuote = await quoteMutator.archive(quoteId)

      if (!archivedQuote) {
        return {
          success: false,
          quote: null
        }
      }

      return {
        success: true,
        quote: archivedQuote
      }
    },
    [quoteMutator]
  )

  const deleteLatestQuoteDraft = useCallback(
    async (quoteId: string) => {
      const updatedQuote = await quoteMutator.deleteLatestDraft(quoteId)

      if (!updatedQuote) {
        return {
          success: false,
          quote: null
        }
      }

      return {
        success: true,
        quote: updatedQuote
      }
    },
    [quoteMutator]
  )

  const executeQuote = useCallback(
    async (quoteId: string) => {
      const executedQuote = await quoteMutator.execute(quoteId)

      if (!executedQuote) {
        return {
          success: false,
          quote: null
        }
      }

      return {
        success: true,
        quote: executedQuote
      }
    },
    [quoteMutator]
  )

  const duplicateQuote = useCallback(
    async (quoteId: string) => {
      const duplicatedQuote = await quoteMutator.duplicate(quoteId)

      if (!duplicatedQuote) {
        return {
          success: false,
          quote: null
        }
      }

      return {
        success: true,
        quote: duplicatedQuote
      }
    },
    [quoteMutator]
  )

  return {
    acceptQuote,
    createQuote,
    executeQuote,
    publishQuote,
    updateQuote,
    archiveQuote,
    deleteLatestQuoteDraft,
    duplicateQuote
  }
}
