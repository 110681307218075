import { Box } from '@chakra-ui/react'
import {
  GreyWhite,
  IndigoIndigo50,
  Lato12Bold
} from '@sequencehq/design-tokens'

export const NewPricePill = () => (
  <Box
    padding="2px 6px"
    {...Lato12Bold}
    fontSize="10px"
    lineHeight="16px"
    border={`1px solid ${IndigoIndigo50}`}
    background={`linear-gradient(180deg, #8D8BFD 0%, ${IndigoIndigo50} 100%)`}
    borderRadius="4px"
    userSelect="none"
    color={GreyWhite}
  >
    NEW
  </Box>
)
