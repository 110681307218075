import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

import { CustomerModel } from '@sequencehq/core-models'
import { useCallback } from 'react'
import {
  dashboardv99990101Client,
  Dashboardv99990101CommonModels
} from '@sequencehq/api/dashboard/v99990101'

export type ApiCustomer = Dashboardv99990101CommonModels.Customers.Customer & {
  aliases: { value: string; id: string; label?: string }[]
}

export type v1ApiCustomer = ApiCustomer

type UseLoadSingleCustomer = () => (props: {
  customerId: CustomerModel['id']
}) => Promise<v1ApiCustomer | null>

export const useLoadSingleCustomer: UseLoadSingleCustomer = () => {
  const load = useCallback(
    async ({ customerId }: { customerId: CustomerModel['id'] }) => {
      const [selectedCustomer, selectedCustomerAliases] = await Promise.all([
        customerId
          ? dashboardv99990101Client
              .getCustomer({ id: customerId })
              .then(res => res.data)
          : Promise.resolve(),
        customerId
          ? dashboardv20240509Client
              .getCustomerAliases({ id: customerId })
              .then(res => res.data?.items ?? [])
          : Promise.resolve()
      ])

      if (!selectedCustomer) {
        return null
      }

      return {
        ...selectedCustomer,
        aliases: (selectedCustomerAliases ?? []).map(
          (alias: Dashboardv20240509Api.GetCustomerAliases.CustomerAlias) => ({
            value: alias.value,
            id: alias.id,
            label: alias.value
          })
        )
      }
    },
    []
  )

  return load
}
