import { FC } from 'react'
import { GraduatedPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/GraduatedPrice/GraduatedPriceForm'
import { LinearPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/LinearPrice/LinearPriceForm'
import { PackagedPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/PackagedPrice/PackagedPriceForm'
import { SeatBasedPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/SeatBasedPrice/SeatBasedPriceForm'
import { StandardPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/StandardPrice/StandardPriceForm'
import { VolumePriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/VolumePrice/VolumePriceForm'
import { PricingModel } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

export const pricingModelForms: Record<PricingModel, FC<unknown>> = {
  STANDARD: StandardPriceForm,
  GRADUATED: GraduatedPriceForm,
  LINEAR: LinearPriceForm,
  VOLUME: VolumePriceForm,
  PACKAGED: PackagedPriceForm,
  SEAT_BASED_LINEAR: SeatBasedPriceForm,
  SEAT_BASED_GRADUATED: SeatBasedPriceForm
}
