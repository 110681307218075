import { DeleteNotificationPolicyForm } from 'components/DeleteNotificationPolicy/DeleteNotificationPolicyForm'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { useDeleteApiNotificationsPoliciesIdMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'

export type DeleteNotificationPolicyFormMutation = ExtractMutationParams<
  typeof useDeleteApiNotificationsPoliciesIdMutation
>

export type DeleteNotificationPolicyFormProps = {
  id: string
  name: string
}

export const DeleteNotificationPolicyModal = createFormModal<
  DeleteNotificationPolicyFormMutation,
  DeleteNotificationPolicyFormProps
>({
  FormComponent: DeleteNotificationPolicyForm,
  useMutation: useDeleteApiNotificationsPoliciesIdMutation,
  modalTitleFromProps: () => 'Delete notification policy',
  successMessage: 'Notification policy deleted',
  submitTitle: 'Delete',
  formId: 'deleteNotificationPolicyForm',
  successToast: response => (
    <Toast
      title="Notification policy deleted"
      type="success"
      description={`Notification policy ${response.name} deleted`}
      isClosable={true}
    ></Toast>
  )
})
