import { useState, FC } from 'react'
import { Flex, Text, Icon, useToast } from '@chakra-ui/react'
import { Toast } from '@sequencehq/core-components'

import { Copy, Check } from 'react-feather'

type CopyOptions = {
  value: string
}

const CopyInputField: FC<CopyOptions> = ({ value }) => {
  const [copied, setCopied] = useState(false)
  const toast = useToast()

  const copyToClipboard = (copiedValue: string) => {
    navigator.clipboard.writeText(copiedValue).catch(() => {
      toast({
        id: 'copied',
        position: 'bottom',
        duration: 2000,
        render: () => (
          <Toast
            title="Failed to copy to clipboard"
            type="error"
            isClosable={false}
          ></Toast>
        )
      })
    })
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <Flex
      height="40px"
      minWidth="100%"
      width="100%"
      borderTopStyle="solid"
      borderTopWidth="1px"
      borderTopColor="gray.30"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      borderBottomColor="gray.30"
      borderLeftStyle="solid"
      borderLeftWidth="1px"
      borderLeftColor="gray.30"
      borderRadius="6px"
      maxWidth="262px"
      alignItems="center"
      backgroundColor="white"
      mt="4px"
    >
      <Text overflow="hidden" whiteSpace="nowrap" ml="12px" color="gray.60">
        {value}
      </Text>
      <Flex
        minWidth="40px"
        minHeight="40px"
        ml="auto"
        backgroundColor="white"
        aria-label="Copy to clipboard"
        justifyContent="center"
        cursor="pointer"
        alignItems="center"
        borderTopRightRadius="4px"
        borderBottomRightRadius="4px"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray.30"
        onClick={e => {
          e.stopPropagation()
          copyToClipboard(value)
        }}
      >
        {copied ? (
          <Icon as={Check} w="20px" h="20px" />
        ) : (
          <Icon as={Copy} w="20px" h="20px" />
        )}
      </Flex>
    </Flex>
  )
}

export default CopyInputField
