import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HasId } from '@sequencehq/core-models'

type OpenDrawer = {
  id: string
  position: number
}

const initialState: OpenDrawer[] = []

const { reducer, actions } = createSlice({
  name: 'drawerStack',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<HasId>) => {
      state.forEach(item => (item.position = item.position + 1))
      state.push({
        id: action.payload.id,
        position: 0
      })
    },
    closeDrawer: state => {
      state.pop()
      state.forEach(item => (item.position = item.position - 1))
    }
  }
})

export const { openDrawer, closeDrawer } = actions

export default reducer
