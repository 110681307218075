import { Image } from '@chakra-ui/react'
import { FC } from 'react'

/**
 * A simple utility component which will format a given logo URL
 * into the standard size.
 * @param url - The logo URL.
 * @returns
 */
export const SmallIcon: FC<{ url: string }> = props => {
  return <Image height="16px" width="16px" src={props.url} />
}
