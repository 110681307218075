import {
  Box,
  Flex,
  Grid,
  GridItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StyleProps,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { InvoiceModel } from '@sequencehq/core-models'
import {
  GreyGrey0,
  GreyGrey10,
  GreyGrey100,
  GreyGrey20,
  GreyGrey60,
  GreyGrey80,
  GreyWhite,
  IndigoIndigo50,
  Lato12UppercaseBold,
  Lato14Bold,
  Lato14Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { standardBorder } from '@sequencehq/core-theme'
import { InvoicesPopover } from 'components/Invoices/InvoicesGrid/InvoicesPopover'
import { useInvoicesGrid } from 'components/Invoices/InvoicesGrid/useInvoicesGrid'
import Spinner from 'components/Loading/Spinner'
import { Link, useParams } from 'react-router-dom'
import { FC, useLayoutEffect, useRef } from 'react'
import { InvoicesGridToolbarV3 } from 'components/Invoices/InvoicesGrid/InvoicesGridToolbarV3'

type CustomerInvoices = {
  id: string
  invoices: Record<string, InvoiceModel[]>
}

export type InvoicesView = {
  model: CustomerInvoices
}

/**
 * CSS classes? What are they?
 *
 * But, ya, creating bonus components for just housing
 * styles adds quite the code overhead, and I'd rather avoid
 * having all of the styling properties in the main JSX code, so I'm just
 * taking a CSS-in-JS friendly approach to classes here.
 */

const styles: Record<
  string,
  StyleProps & {
    contentVisibility?: string
  }
> = {
  row: {
    height: '40px',
    borderBottom: standardBorder,
    alignItems: 'center'
  },
  cell: {
    height: '100%',
    alignItems: 'center',
    padding: '0 12px',
    textOverflow: 'ellipsis',
    justifyContent: 'space-between',
    ...Lato14Regular
  },
  invoiceBadge: {
    padding: '0 4px',
    backgroundColor: GreyGrey20,
    ...Lato12UppercaseBold,
    /** Avoids a type error caused by StyleProps not liking 'string' for this value */
    textTransform: 'uppercase',
    color: GreyGrey60,
    borderRadius: '3px'
  },
  header: {
    color: GreyGrey100,
    padding: '16px 24px',
    borderBottom: standardBorder,
    height: '64px',
    alignItems: 'center',
    ...Lato20Bold
  },
  tools: {
    color: GreyGrey100,
    padding: '16px 24px',
    borderBottom: standardBorder,
    height: '65px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px'
  },
  currencySelect: {
    width: '180px'
  }
}

export const InvoicesGrid = () => {
  const { loading, data } = useInvoicesGrid()
  const contentRef = useRef<HTMLDivElement>(null)
  const { invoiceId } = useParams()

  useLayoutEffect(() => {
    if (!loading && data && contentRef.current) {
      contentRef.current.scrollLeft =
        contentRef.current.scrollWidth - contentRef.current.clientWidth
    }
  }, [data, loading])

  if (loading || !data) {
    return (
      <Flex flexDirection="column" width="100%" height="100vh">
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <InvoicesGridToolbarV3 data={data} />

      <Grid
        className="Parent"
        position="relative"
        zIndex={1}
        gridTemplateRows="auto 1fr"
        width="100%"
        height="100%"
        minHeight={0}
      >
        <Flex></Flex>
        <Grid
          ref={contentRef}
          width="100%"
          templateAreas={`
        "left-header table-header right-header"
        "sticky-left table sticky-right"
        "left-footer table-footer right-footer"`}
          templateRows="auto 1fr auto"
          templateColumns="195px 1fr minmax(195px, 80%)"
          overflow="auto"
        >
          {/**
           * HEADER
           * ===============================================================
           *
           * The Customer name header - sticky and fixed at the top left corner
           */}
          <GridItem
            position="sticky"
            top="0"
            left="0"
            gridArea="left-header"
            backgroundColor={GreyWhite}
            borderBottom={standardBorder}
            borderRight={standardBorder}
            zIndex="6"
          >
            <Flex
              {...styles.cell}
              {...Lato12UppercaseBold}
              color={GreyGrey60}
              textTransform="uppercase"
              paddingLeft="24px"
            >
              Customer
            </Flex>
          </GridItem>

          {/**
           * The month column labels - this are sticky on the vertical axis,
           * but scroll horizontally. This keep the headers in line with the
           * main table content when scrolling horizontally.
           */}
          <GridItem
            position="sticky"
            top="0"
            gridArea="table-header"
            backgroundColor={GreyWhite}
            borderBottom={standardBorder}
            zIndex="5"
          >
            <Flex {...styles.row} borderBottom="none">
              {data.months.map(month => (
                <Flex
                  key={month.value}
                  width="130px"
                  {...styles.cell}
                  {...Lato12UppercaseBold}
                  color={GreyGrey60}
                  textTransform="uppercase"
                  _notLast={{
                    borderRight: standardBorder
                  }}
                >
                  {month.label}
                </Flex>
              ))}
            </Flex>
          </GridItem>

          {/**
           * The total column label - fixed at the top right
           */}

          <GridItem
            position="sticky"
            top="0"
            right="0"
            gridArea="right-header"
            backgroundColor={GreyGrey0}
            borderBottom={standardBorder}
            borderLeft={standardBorder}
            zIndex="6"
          >
            <Flex
              {...styles.cell}
              {...Lato12UppercaseBold}
              color={GreyGrey80}
              textTransform="uppercase"
            >
              Total
            </Flex>
          </GridItem>

          {/**
           * MAIN CONTENT
           * ===============================================================
           *
           * Customer names - this area is sticky on the horizontal axis only,
           * and otherwise scrolls vertically
           */}
          <GridItem
            gridArea="sticky-left"
            position="sticky"
            left="0"
            zIndex="5"
            backgroundColor={GreyWhite}
            boxShadow="0px 16px 24px 0px rgba(20, 23, 28, 0.08)"
            borderRight={standardBorder}
          >
            {data.customers.map(customer => (
              <Flex
                {...styles.row}
                key={customer.id}
                _last={{
                  borderBottom: 'none'
                }}
              >
                <Text
                  {...styles.cell}
                  color={IndigoIndigo50}
                  paddingLeft="24px"
                  height="min-content"
                  isTruncated
                >
                  <Link target="_blank" to={`/customers/${customer.id}`}>
                    {customer.name}
                  </Link>
                </Text>
              </Flex>
            ))}
          </GridItem>

          {/**
           * The main table itself. This is not sticky, and otherwise scrolls normally.
           * All of the surrounding grid areas _are_ sticky in various ways in order to
           * scroll correctly in relation to this central element.
           */}

          <GridItem
            gridArea="table"
            backgroundColor={GreyWhite}
            position="relative"
            zIndex="1"
          >
            {/**
             * A bit of smoke and mirrors - this provides the background to the main
             * table which gives the illusion of the columns extending down to the footer
             */}
            <Flex
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              zIndex="0"
            >
              {data.months.map(month => (
                <Flex
                  key={month.value}
                  width="130px"
                  {...styles.cell}
                  _notLast={{
                    borderRight: standardBorder
                  }}
                ></Flex>
              ))}
            </Flex>
            {data.customers.map(customer => (
              <Flex
                {...styles.row}
                key={customer.id}
                _last={{
                  borderBottom: 'none'
                }}
              >
                {data.months.map(month => {
                  /** All the outer gubbins is all in support of this! The cell rendering. */
                  const invoicesForCell = data.getInvoices({
                    customerId: customer.id,
                    month: month.value
                  })

                  const total = data.getTotal({
                    customerId: customer.id,
                    month: month.value
                  })

                  return invoicesForCell.length > 1 ? (
                    <MultiInvoiceCell
                      key={month.value}
                      invoiceId={invoiceId}
                      invoicesForCell={invoicesForCell}
                      total={total}
                    />
                  ) : (
                    <SingleInvoiceCell
                      key={month.value}
                      invoiceId={invoiceId}
                      invoicesForCell={invoicesForCell}
                      total={total}
                    />
                  )
                })}
              </Flex>
            ))}
          </GridItem>

          {/**
           * Customer totals - this area is sticky on the horizontal axis only,
           * and otherwise scrolls vertically
           */}
          <GridItem
            gridArea="sticky-right"
            backgroundColor={GreyGrey0}
            position="sticky"
            right="0"
            zIndex="5"
            borderLeft={standardBorder}
          >
            {data.customers.map(customer => (
              <Flex
                {...styles.row}
                key={customer.id}
                _last={{
                  borderBottom: 'none'
                }}
              >
                <Flex {...styles.cell} {...Lato14Bold}>
                  {data.getTotal({ customerId: customer.id })}
                </Flex>
              </Flex>
            ))}
          </GridItem>

          {/**
           * FOOTER
           * ==============================================================
           *
           * The left of the footer is sticky vertically and horizontally -
           * i.e. it is always chilling down at the bottom left.
           */}
          <GridItem
            position="sticky"
            bottom="0"
            left="0"
            gridArea="left-footer"
            backgroundColor={GreyWhite}
            borderTop={standardBorder}
            borderRight={standardBorder}
            zIndex="10"
          >
            <Flex {...styles.cell} justifyContent="flex-end">
              Total
            </Flex>
          </GridItem>

          {/**
           * The table footer is sticky vertically, but scrolls horizontally.
           * This ensures the footer data, which are the totals for each month,
           * scrolls in line with the main table columns.
           */}
          <GridItem
            position="sticky"
            bottom="0"
            gridArea="table-footer"
            backgroundColor={GreyGrey0}
            borderTop={standardBorder}
            zIndex="2"
          >
            <Flex {...styles.row} borderBottom="none">
              {data.months.map(month => (
                <Flex
                  key={month.value}
                  width="130px"
                  {...styles.cell}
                  {...Lato14Bold}
                  color={GreyGrey80}
                  _notLast={{
                    borderRight: standardBorder
                  }}
                >
                  {data.getTotal({ month: month.value })}
                </Flex>
              ))}
            </Flex>
          </GridItem>
          {/**
           * The right of the footer is the grand total for the current
           * overview, and is fixed down at the bottom right in all scenarios
           */}
          <GridItem
            position="sticky"
            bottom="0"
            right="0"
            gridArea="right-footer"
            backgroundColor={GreyGrey0}
            borderTop={standardBorder}
            borderLeft={standardBorder}
            zIndex="6"
          >
            <Flex {...styles.cell} {...Lato14Bold} color={GreyGrey80}>
              {data.getTotal()}
            </Flex>
          </GridItem>
        </Grid>
      </Grid>
    </>
  )
}

type MultiInvoiceCellProps = {
  invoiceId: string | undefined
  invoicesForCell: string[]
  total: string
}

const MultiInvoiceCell: FC<MultiInvoiceCellProps> = ({
  invoiceId,
  invoicesForCell,
  total
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const isActive = invoiceId && invoicesForCell.includes(invoiceId)

  return (
    <Popover
      trigger="click"
      placement="bottom-start"
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Flex
          width="130px"
          {...styles.cell}
          borderLeft={`3px solid ${isActive ? IndigoIndigo50 : 'transparent'}`}
          backgroundColor={isActive ? GreyGrey10 : GreyWhite}
          _notLast={{
            borderRight: standardBorder
          }}
          _hover={{
            backgroundColor: GreyGrey10
          }}
        >
          <Flex
            zIndex="10"
            cursor="pointer"
            width="100%"
            gap="8px"
            justifyContent="flex-end"
          >
            {invoicesForCell.length > 1 ? (
              <Box {...styles.invoiceBadge}>{invoicesForCell.length}</Box>
            ) : null}

            <Text>{total}</Text>
          </Flex>
          <Portal>
            <PopoverContent width="280px">
              <PopoverBody p="0">
                <InvoicesPopover
                  invoiceIds={invoicesForCell}
                  total={total}
                ></InvoicesPopover>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Flex>
      </PopoverTrigger>
    </Popover>
  )
}

type SingleInvoiceCellProps = {
  invoiceId: string | undefined
  invoicesForCell: string[]
  total: string
}

const SingleInvoiceCell: FC<SingleInvoiceCellProps> = ({
  invoiceId,
  invoicesForCell,
  total
}) => {
  return (
    <Flex
      as={Link}
      to={`/invoices/${invoicesForCell[0]}`}
      width="130px"
      {...styles.cell}
      borderLeft={`3px solid ${
        invoiceId && invoicesForCell.includes(invoiceId)
          ? IndigoIndigo50
          : 'transparent'
      }`}
      _notLast={{
        borderRight: standardBorder
      }}
      _hover={{
        backgroundColor: GreyGrey10
      }}
    >
      <Flex
        zIndex="10"
        cursor="pointer"
        width="100%"
        gap="8px"
        justifyContent="flex-end"
      >
        <Text>{total}</Text>
      </Flex>
    </Flex>
  )
}
