import { Box, Button, Flex, Text } from '@chakra-ui/react'
import {
  CreditNoteModel,
  LineItemGroupCreditNoteModel
} from '@sequencehq/core-models'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import {
  usePostCreditNotesByCreditnoteLineItemGroupsMutation,
  usePutCreditNotesByCreditnoteLineItemGroupsByIdMutation
} from 'features/api'
import { handleFormResponse } from 'lib/formValidation/formValidation'
import { noReturn } from 'lib/noReturn/noReturn'
import { required } from '@sequencehq/validation'
import { FC, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { v4 as uuidv4 } from 'uuid'

interface LineItemGroupSidebarProps {
  creditNote: CreditNoteModel
  group: LineItemGroupCreditNoteModel | undefined
  updateGroup: (item: LineItemGroupCreditNoteModel) => void
  cancel: () => void
  setHasChanges: () => void
}

const LineItemGroupSidebar: FC<LineItemGroupSidebarProps> = ({
  creditNote,
  group,
  updateGroup,
  cancel,
  setHasChanges
}) => {
  const [update] = usePutCreditNotesByCreditnoteLineItemGroupsByIdMutation()
  const [add] = usePostCreditNotesByCreditnoteLineItemGroupsMutation()
  const [id] = useState<string>(group ? group.id : uuidv4())
  return (
    <Form<LineItemGroupCreditNoteModel>
      keepDirtyOnReinitialize
      initialValues={
        group
          ? group
          : {
              id: id,
              creditNoteId: creditNote.id,
              title: '',
              index: 0,
              grossTotal: '0',
              netTotal: '0',
              totalTax: '0',
              description: ''
            }
      }
      onSubmit={async (values, form) => {
        const result = await (group
          ? update({
              creditnote: creditNote.id,
              id: group.id,
              updateCreditNoteLineItemGroupEndpointUpdateCreditNoteLineItemGroupRequestModel:
                {
                  ...group,
                  ...values
                }
            })
          : add({
              creditnote: creditNote.id,
              createCreditNoteLineItemGroupEndpointCreateCreditNoteLineItemGroupRequestModel:
                {
                  ...values
                }
            }))
        return handleFormResponse(result, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitting, submitError, values }) => (
        <>
          {group && (
            <Text textStyle="sectionHeader">
              Edit Line Item Group - {values.title}
            </Text>
          )}
          {!group && (
            <Text textStyle="sectionHeader">Adding new Line Item Group</Text>
          )}
          <Flex
            as="form"
            px={5}
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            w="100%"
          >
            <FormSpy
              subscription={{ values: true, dirty: true, valid: true }}
              onChange={state => {
                if (state.dirty && state.valid) {
                  group
                    ? updateGroup({
                        ...group,
                        ...state.values
                      })
                    : updateGroup(state.values as LineItemGroupCreditNoteModel)
                }
                if (state.dirty) {
                  setHasChanges()
                }
              }}
            />
            <FormErrors formError={submitError} />
            <Box h="16px"></Box>
            <TextInputField
              fieldName="title"
              fieldLabel="Title"
              validate={required}
              maxLength={50}
            />
            <Text textStyle="hint" textAlign="right">
              {values.title?.length || 0}/50
            </Text>

            <Box height={5} />
            <Flex justifyContent="flex-end">
              <Button variant="secondary" onClick={cancel}>
                Cancel
              </Button>

              <Box w="16px"></Box>
              <Button variant="primary" type="submit" isDisabled={submitting}>
                Save
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    />
  )
}

export default LineItemGroupSidebar
