import { useCallback, useMemo, useRef, useState } from 'react'

import * as Sentry from '@sentry/react'

import { getPortalDomain } from '@sequencehq/utils'

import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { getEnvironment } from 'lib/environment/environment'

type UsePopoverPublishedQuote = ({ onClose }: { onClose: () => void }) => {
  linkUrl: string
  buttonCopy: {
    text: string
    onClick: () => void
  }
  buttonClose: {
    onClick: () => void
  }
}

export const usePopoverPublishedQuote: UsePopoverPublishedQuote = ({
  onClose
}) => {
  const ctx = useCubeContext()
  const portalDomain = getPortalDomain(getEnvironment())

  const [isCopied, setIsCopied] = useState<boolean>(false)
  const copiedTimeout = useRef<NodeJS.Timeout | null>(null)

  const linkUrl: string = useMemo(() => {
    if (!portalDomain) {
      return ''
    }

    const customerId: string = ctx.queries.rawData.data.common.customerId
    const quoteId: string = ctx.queries.rawData.data.common.id

    return `${portalDomain}/quotes/${customerId}/${quoteId}`
  }, [
    ctx.queries.rawData.data.common.customerId,
    ctx.queries.rawData.data.common.id,
    portalDomain
  ])

  const buttonCopyText = useMemo(
    () => (isCopied ? 'Copied' : 'Copy link'),
    [isCopied]
  )

  const buttonCopyOnClick = useCallback(() => {
    const copyToClipboard = async () => {
      if (copiedTimeout.current) {
        clearTimeout(copiedTimeout.current)
        copiedTimeout.current = null
      }

      try {
        await navigator.clipboard.writeText(linkUrl)
        setIsCopied(true)

        copiedTimeout.current = setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    void copyToClipboard()
  }, [linkUrl])

  const buttonCloseOnClick = useCallback(() => {
    onClose()
  }, [onClose])

  return {
    linkUrl,
    buttonCopy: {
      text: buttonCopyText,
      onClick: buttonCopyOnClick
    },
    buttonClose: {
      onClick: buttonCloseOnClick
    }
  }
}
