import { Box } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  NumberField,
  PriceField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import { usePackagedPriceForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/PackagedPrice/usePackagedPriceForm'
import { UsageMetricParameterFields } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/UsageMetricParameterFields'
import { AvailableStandardFrequency } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const PackagedPriceForm = () => {
  const { fieldsConfig, currency, fieldsAreHidden } = usePackagedPriceForm()

  return (
    <Box paddingBottom={fieldsAreHidden ? '0px' : '8px'}>
      {!fieldsConfig.billingFrequency.hidden && (
        <HorizontalSelectorField
          label="Frequency"
          data-testid="common-field-billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
      )}
      {!fieldsConfig.usageMetricId.hidden && (
        <SelectFieldWithActions
          label="Usage Metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
      )}
      {!fieldsConfig.usageMetricId.hidden && (
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
      )}
      <PriceField
        label="Price per package"
        validationErrors={fieldsConfig.pricePerPackage.validationErrors}
        value={fieldsConfig.pricePerPackage.value}
        onChange={fieldsConfig.pricePerPackage.onChange}
        isDisabled={fieldsConfig.pricePerPackage.disabled}
        currency={currency}
      />
      <NumberField
        label="Package size"
        validationErrors={fieldsConfig.packageSize.validationErrors}
        precision={0}
        value={fieldsConfig.packageSize.value}
        onChange={fieldsConfig.packageSize.onChange}
        isDisabled={fieldsConfig.packageSize.disabled}
      />
    </Box>
  )
}

export const HiddenFieldsForm = () => {
  const { fieldsConfig } = usePackagedPriceForm()
  return (
    <>
      <HorizontalSelectorField
        label="Frequency"
        data-testid="common-field-billingFrequency"
        value={fieldsConfig.billingFrequency.value}
        options={fieldsConfig.billingFrequency.options}
        onChange={newValue =>
          fieldsConfig.billingFrequency.onChange(
            newValue as AvailableStandardFrequency
          )
        }
        validationErrors={fieldsConfig.billingFrequency.validationErrors}
        isDisabled={fieldsConfig.billingFrequency.disabled}
      />
      <SelectFieldWithActions
        label="Usage Metric"
        options={fieldsConfig.usageMetricId.options}
        validationErrors={fieldsConfig.usageMetricId.validationErrors}
        value={fieldsConfig.usageMetricId.value}
        onChange={fieldsConfig.usageMetricId.onChange}
        isDisabled={fieldsConfig.usageMetricId.disabled}
        placeholder="Select usage metric"
        onAddNew={fieldsConfig.usageMetricId.onAddNew}
      />
    </>
  )
}
