import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { Price } from 'common/drawers/PricingEditor/domain'
import { useMemo } from 'react'

type IntegrationInformation = Record<
  string,
  {
    code: string
    name: string
  }
>
type UseAdditionalInfo = (props: { price: Price }) => {
  validIntegrationIds: IntegrationInformation
}

export const useAdditionalInfo: UseAdditionalInfo = props => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const integrationIds = props.price.common.integrationIds

  const services = useMemo(
    () => pricingEditorContext.queries.rawData.data.integrations,
    [pricingEditorContext.queries.rawData.data.integrations]
  )

  const validIntegrationIds = useMemo(() => {
    const validIntegrations = Object.keys(integrationIds).reduce(
      (acc, integrationId) => {
        const integrationData = services[integrationId]

        if (integrationData) {
          const code = props.price.common.integrationIds[integrationId]

          return {
            ...acc,
            [integrationId]: {
              code,
              name:
                integrationData.options.find(option => option.code === code)
                  ?.name ?? ''
            }
          }
        }

        return acc
      },
      {} as IntegrationInformation
    )

    return validIntegrations
  }, [integrationIds, props.price.common.integrationIds, services])

  return {
    validIntegrationIds
  }
}
