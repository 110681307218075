import { Flex, Icon, Text } from '@chakra-ui/react'
import { FC } from 'react'
import ScaleIcon from '@heroicons/react/24/outline/ScaleIcon'
import UserCircleIcon from '@heroicons/react/24/outline/UserCircleIcon'
import {
  GreyGrey50,
  GreyGrey80,
  GreyGrey100,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { Tooltip } from '@sequencehq/core-components'
import { Customer } from 'modules/Cube/domain/cube.domain.types'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'

type CustomerViewProps = {
  customer: Customer
}

const TAX_STATUS_LABELS: Record<Customer['taxStatus'], string> = {
  TAX_EXEMPT: 'Tax exempt',
  REVERSE_CHARGED: 'Reverse charge',
  TAXED: 'Taxed'
}

export const CustomerView: FC<CustomerViewProps> = ({ customer }) => {
  const cubeContext = useCubeContext()

  if (!customer) {
    return null
  }

  const { address } = customer
  const showTaxRateTooltip =
    cubeContext.queries.rawData.configuration.features.showNewTaxManagement &&
    customer.taxStatus === 'TAXED'

  return (
    <Flex
      flexDirection="column"
      px="24px"
      pb="20px"
      data-testid="customer.view"
    >
      <Flex mb="8px">
        <CustomerPreviewCardPill customer={customer} />
      </Flex>
      <Flex gap="8px" alignItems="flexStart" mb="8px">
        <Icon
          as={UserCircleIcon}
          width="20px"
          height="20px"
          color={GreyGrey100}
        />
        <Flex flexDirection="column">
          <Text>{address.line1}</Text>
          <Text>{address.line2}</Text>
          <Text>{address.town}</Text>
          <Text>{address.country}</Text>
          <Text>{address.postcode}</Text>
        </Flex>
      </Flex>
      <Flex gap="8px" alignItems="center" title="Tax status">
        <Icon as={ScaleIcon} width="20px" height="20px" color={GreyGrey80} />
        <Flex {...Lato14Regular} gap="4px" data-testid="customer.taxStatus">
          {TAX_STATUS_LABELS[customer.taxStatus]}
          {showTaxRateTooltip && (
            <Tooltip
              label="Tax is charged based on the included product’s tax category"
              placement="top"
            >
              <InformationCircleIcon
                height="16px"
                width="16px"
                color={GreyGrey50}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
