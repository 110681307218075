import { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

import {
  CustomerContactsDomainInterface,
  useCustomerContactsDomain
} from '../domain/CustomerContacts.domain'
import { useCustomerContactsPortImplementation } from '../communication/external/v1/ports/customerContacts.ports'

type UseCustomerContactsRoot = (customerId?: string) => {
  customerContactsContext: CustomerContactsDomainInterface
  ready: boolean
}

enum LoadingStatus {
  UNINITIALISED = 'UNINITIALISED',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

export const useCustomerContactsRoot: UseCustomerContactsRoot = customerId => {
  const { customerId: customerIdParam } = useParams<{
    customerId: string
  }>()

  customerId ??= customerIdParam

  invariant(customerId, 'customerId is required')

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.UNINITIALISED
  )

  const customerContactsPortImplementation =
    useCustomerContactsPortImplementation({
      customerId
    })

  const portImplementation = useMemo(
    () => customerContactsPortImplementation(),
    [customerContactsPortImplementation]
  )

  const customerContactsDomain = useCustomerContactsDomain({
    ports: portImplementation
  })

  const reloadDomain = customerContactsDomain.mutators.external.in.core

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.LOADING ||
      loadingStatus === LoadingStatus.ERROR
    ) {
      return
    }

    if (customerContactsDomain.queries.data.customerId === customerId) {
      return
    }

    setLoadingStatus(LoadingStatus.LOADING)

    void reloadDomain()
      .then(() => setLoadingStatus(LoadingStatus.READY))
      .catch(() => setLoadingStatus(LoadingStatus.ERROR))
  }, [
    customerContactsDomain.queries.data.customerId,
    customerId,
    loadingStatus,
    reloadDomain
  ])

  return {
    customerContactsContext: customerContactsDomain,
    ready: loadingStatus === LoadingStatus.READY
  }
}
