import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

export type v1ApiNewBillingSchedule =
  Dashboardv20240509Api.PostBillingSchedule.PostBillingScheduleBody
export type v1ApiUpdateBillingSchedule =
  Dashboardv20240509Api.PutBillingSchedule.PutBillingScheduleBody
type BillingSchedule =
  Dashboardv20240509Api.PostBillingSchedule.Response['data']

type UseSaveBillingSchedule = () => {
  createBillingSchedule: (
    billingSchedule: v1ApiNewBillingSchedule
  ) => Promise<BillingSchedule>
  updateBillingSchedule: (args: {
    id: string
    data: v1ApiUpdateBillingSchedule
  }) => Promise<BillingSchedule>
}

export const useSaveBillingSchedule: UseSaveBillingSchedule = () => {
  const createBillingSchedule = async (data: v1ApiNewBillingSchedule) => {
    const response = await dashboardv20240509Client.postBillingSchedule({
      body: {
        ...data,
        isDraft: true
      }
    })

    /**
     * During refactor it was discovered that the response here is no longer
     * aligned with reality - the post version of the schedule lacks the type
     * and attachmentAssets fields. This results in type errors if passing
     * back the response directly. The refactor in question is already hefty
     * (related to mock tweaks), so also diving into making sure these types
     * make sense is a bit much. I've, therefore, used a cast to retain the
     * old (technically incorrect) path for the meantime.
     */
    return response.data as BillingSchedule
  }

  const updateBillingSchedule = async ({
    id,
    data
  }: {
    id: string
    data: v1ApiUpdateBillingSchedule
  }) => {
    const response = await dashboardv20240509Client.putBillingSchedule({
      id,
      body: data
    })

    /**
     * During refactor it was discovered that the response here is no longer
     * aligned with reality - the post version of the schedule lacks the type
     * and attachmentAssets fields. This results in type errors if passing
     * back the response directly. The refactor in question is already hefty
     * (related to mock tweaks), so also diving into making sure these types
     * make sense is a bit much. I've, therefore, used a cast to retain the
     * old (technically incorrect) path for the meantime.
     */

    return response.data as BillingSchedule
  }

  return {
    createBillingSchedule,
    updateBillingSchedule
  }
}
