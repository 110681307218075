import { BillingScheduleStatus, QuoteStatus } from '@sequencehq/core-models'
import { CubeStatus } from 'modules/Cube/domain/cube.domain.types'

const BILLING_SCHEDULE_STATUS_TO_CUBE_STATUS: Record<
  BillingScheduleStatus | 'NEW',
  CubeStatus
> = {
  ACTIVE: CubeStatus.ScheduleActive,
  DRAFT: CubeStatus.ScheduleDraft,
  PENDING: CubeStatus.SchedulePending,
  SUSPENDED: CubeStatus.ScheduleSuspended,
  COMPLETED: CubeStatus.ScheduleCompleted,
  CANCELLED: CubeStatus.ScheduleCancelled,
  NEW: CubeStatus.ScheduleUnsaved
}

const QUOTE_STATUS_TO_CUBE_STATUS: Record<QuoteStatus, CubeStatus> = {
  DRAFT: CubeStatus.QuoteDraft,
  PUBLISHED: CubeStatus.QuotePublished,
  READY_TO_SIGN: CubeStatus.QuoteReadyToSign,
  ACCEPTED: CubeStatus.QuoteAccepted,
  EXECUTED: CubeStatus.QuoteExecuted
}

export const scheduleStatusAdapter = {
  in: (status: BillingScheduleStatus | 'NEW'): CubeStatus => {
    /**
     * TODO: Make this a query, same for quote equivalent
     */
    return BILLING_SCHEDULE_STATUS_TO_CUBE_STATUS[status]
  },
  out: (status: CubeStatus): BillingScheduleStatus | undefined => {
    return Object.entries(BILLING_SCHEDULE_STATUS_TO_CUBE_STATUS).find(
      ([, value]) => value === status
    )?.[0] as BillingScheduleStatus | undefined
  }
}

export const quoteStatusAdapter = {
  in: (status: QuoteStatus): CubeStatus => {
    /**
     * TODO: Make this a query, same for quote equivalent
     */
    return QUOTE_STATUS_TO_CUBE_STATUS[status]
  },
  out: (status: CubeStatus): QuoteStatus | undefined => {
    return Object.entries(QUOTE_STATUS_TO_CUBE_STATUS).find(
      ([, value]) => value === status
    )?.[0] as QuoteStatus | undefined
  }
}
