import { useCallback, useEffect, useState } from 'react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'

export const useFetchMerchantBySequenceAccountId = () => {
  const [merchant, setMerchant] =
    useState<DashboardApi20240730.GetMerchant.Merchant | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchMerchantBySequenceAccountId = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const merchantData = await dashboard20240730Client.getMerchant()

      if (merchantData.data) {
        setMerchant(merchantData?.data)
      }
    } catch (e) {
      setError(e as Error)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchMerchantBySequenceAccountId()
  }, [fetchMerchantBySequenceAccountId])

  return {
    merchant,
    loading,
    error,
    refetch: fetchMerchantBySequenceAccountId
  }
}
