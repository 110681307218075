import { Flex, Grid } from '@chakra-ui/react'

import { SidebarField } from 'Cube/view/layouts/quote/QuoteEditorSidebar/widgets/SidebarField'
import {
  ContractDurationSelectControl,
  CurrencyEditorControl,
  CustomerEditorControl,
  DealTypeEditorControl,
  ExpirySelectControl,
  RecipientsEditorControl,
  StartDateSelectControl
} from 'modules/Cube/view/layouts/quote/QuoteEditorControls/QuoteEditorControls'
import { UseQuoteEditorSidebarInterface } from 'Cube/view/layouts/quote/QuoteEditorSidebar/useQuoteEditorSidebar'
import {
  ModalCreateRecipient,
  ModalCreateRecipientWrapper
} from '../../QuoteEditorControls/ModalCreateRecipient/ModalCreateRecipient'

type Props = {
  hook: UseQuoteEditorSidebarInterface
}

export const SidebarFields = ({ hook }: Props) => {
  const customerId = hook.fields.customer.value

  return (
    <Grid
      width="100%"
      gridTemplateColumns="1fr 154px"
      alignItems="center"
      marginBottom="16px"
    >
      <Flex flexDirection="column" width="288px">
        <SidebarField
          label="Customer"
          control={
            <CustomerEditorControl
              type="sidebar"
              options={hook.fields.customer.options}
              value={hook.fields.customer.value}
              onChange={hook.fields.customer.onChange}
              onCreate={hook.fields.customer.onAddNew}
              disabled={hook.fields.customer.isDisabled}
              validationErrors={hook.fields.customer.validationErrors}
            />
          }
        />

        {hook.fields.recipients.visible && (
          <SidebarField
            label="Recipients"
            control={
              <RecipientsEditorControl
                type="sidebar"
                options={hook.fields.recipients.options}
                value={hook.fields.recipients.value}
                onChange={hook.fields.recipients.onChange}
                onCreate={hook.features.createRecipient.onClick}
                validationErrors={hook.fields.recipients.validationErrors}
                disabled={hook.fields.recipients.isDisabled}
              />
            }
          />
        )}

        <SidebarField
          label="Currency"
          control={
            <CurrencyEditorControl
              type="sidebar"
              options={hook.fields.currency.options}
              value={hook.fields.currency.value}
              onChange={hook.fields.currency.onChange}
              disabled={hook.fields.currency.isDisabled}
              validationErrors={hook.fields.currency.validationErrors}
            />
          }
        />

        <SidebarField
          label="Type"
          control={
            <DealTypeEditorControl
              type="sidebar"
              options={hook.fields.quoteDealType.options}
              value={hook.fields.quoteDealType.value}
              onChange={hook.fields.quoteDealType.onChange}
              disabled={hook.fields.quoteDealType.isDisabled}
              validationErrors={hook.fields.quoteDealType.validationErrors}
            />
          }
        />

        <SidebarField
          label={hook.fields.validFor.labelField}
          control={
            <ExpirySelectControl
              type="sidebar"
              options={hook.fields.validFor.durations}
              duration={hook.fields.validFor.value}
              onChange={hook.fields.validFor.onChange}
              disabled={hook.fields.validFor.isDisabled}
              validationErrors={hook.fields.validFor.validationErrors}
              labelOverride={hook.fields.validFor.labelOverride}
            />
          }
        />

        <SidebarField
          label="Duration"
          control={
            <ContractDurationSelectControl
              type="sidebar"
              options={hook.fields.contractLength.durations}
              duration={hook.fields.contractLength.value}
              onChange={hook.fields.contractLength.onChange}
              disabled={hook.fields.contractLength.isDisabled}
              validationErrors={hook.fields.contractLength.validationErrors}
            />
          }
        />

        <SidebarField
          label="Start date"
          control={
            <StartDateSelectControl
              type="sidebar"
              value={hook.fields.startDate.value}
              onChange={hook.fields.startDate.onChange}
              disabled={hook.fields.startDate.isDisabled}
              validationErrors={hook.fields.startDate.validationErrors}
            />
          }
        />
      </Flex>

      {hook.modals.createRecipient.active && customerId && (
        <ModalCreateRecipientWrapper customerId={customerId}>
          <ModalCreateRecipient
            onClose={hook.modals.createRecipient.onClose}
            hideOverlay
          />
        </ModalCreateRecipientWrapper>
      )}
    </Grid>
  )
}
