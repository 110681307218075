import { Box, Flex, Text } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import AvalaraTaxCodesSearchLink from './AvalaraTaxCodesSearchLink.tsx'
import { Banner, TextInputField } from '@sequencehq/core-components'
import { useDebouncedCallback } from 'use-debounce'
import { useEffect, useState } from 'react'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import { GreyGrey70, GreyGrey80, Lato13Bold } from '@sequencehq/design-tokens'

interface Props {
  value: string
  onChange: (avalaraTaxCode: string) => void
  onSuccess?: (avalaraTaxCodeInfo: string) => void
}

const AvalaraTaxCodeInput = ({ value, onChange, onSuccess }: Props) => {
  const [taxCode, setTaxCode] = useState('')

  const { data, isPending, isError, isSuccess, error } = useQuery(
    dashboardv99990101Client.getAvalaraTaxCodeInfo,
    {
      taxCode
    },
    {
      enabled: !!taxCode,
      retry: 0
    }
  )

  useEffect(() => {
    if (data?.title) {
      onSuccess?.(data.title)
    }
  }, [data, onSuccess])

  const debouncedSetTaxCode = useDebouncedCallback(setTaxCode, 500)

  return (
    <Box>
      <Flex justifyContent="space-between" alignContent="center">
        <Label size="sm">Avalara tax code</Label>

        <AvalaraTaxCodesSearchLink pb={2} />
      </Flex>
      <Flex flexDirection="column">
        <TextInputField
          data-testid="avalaraTaxCodeInput"
          value={value}
          validationErrors={isError ? [{ message: '' }] : undefined}
          onChange={e => {
            debouncedSetTaxCode(e.target.value)
            onChange(e.target.value)
          }}
          placeholder="XX00000"
        />

        <Flex mt="-8px" mb="17px">
          {!!taxCode && isPending && (
            <Banner variant="loading">Fetching tax code information...</Banner>
          )}

          {!isPending && isError && (
            <Banner data-testid="avalaraTaxCodeErrorBanner" variant="error">
              {error?.status === 404
                ? 'Tax code not found in Avalara'
                : 'Failed to fetch tax code information'}
            </Banner>
          )}

          {isSuccess && data?.title ? (
            <Banner
              data-testid="avalaraTaxCodeSuccessBanner"
              withIcon={false}
              variant="neutral"
            >
              <Flex flexDirection="column" gap={2}>
                <Text {...Lato13Bold} color={GreyGrey80}>
                  {data.title}
                </Text>
                {data?.description ? (
                  <Text color={GreyGrey70}>{data.description}</Text>
                ) : null}
              </Flex>
            </Banner>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  )
}

export default AvalaraTaxCodeInput
