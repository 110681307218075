import {
  CubeReducerState,
  Phase,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'

export const nonFinalPhaseHasDuration =
  (ctx: { phaseId: Phase['id'] }) =>
  (prevState: CubeReducerState): ValidationResult[] | null => {
    const resolvedPhase = prevState.queries.resolvedPhases[ctx.phaseId]
    const isFinalPhase =
      prevState.queries.orderedPhases[
        prevState.queries.orderedPhases.length - 1
      ]?.id === ctx.phaseId

    if (resolvedPhase.dates.duration || isFinalPhase) {
      return null
    }

    return [
      {
        message: 'Phase requires a duration if it is not the final phase',
        metadata: {
          phaseId: ctx.phaseId
        }
      }
    ]
  }
