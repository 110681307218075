import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'

export type v1ApiUpdateListPriceData = Omit<
  Dashboardv20240509Api.GetListPrice.ListPrice,
  'createdAt'
>

export type v1ApiUpdateListPriceResult =
  Dashboardv20240509Api.PutListPrice.Response['data']

type UpdateListPrice = (data: v1ApiUpdateListPriceData) => Promise<{
  success: boolean
  error: PricingEditorPortErrors | null
  data: v1ApiUpdateListPriceResult
}>

export const updateListPrice: UpdateListPrice = async (
  data: v1ApiUpdateListPriceData
) => {
  const { id, ...rest } = data
  const res = await dashboardv20240509Client.putListPrice({
    id,
    body: rest
  })

  return {
    success: !res.error,
    error: res.error ? PricingEditorPortErrors.Other : null,
    data: res.data
  }
}
