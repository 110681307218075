import { SwitchLineInput } from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import {
  BorderRadius6,
  GreyGrey10,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import deepmerge from 'deepmerge'
import { ValidationError } from '@sequencehq/utils'

type UseStripeAutoChargeWidget = (props: {
  dataFromInterface: {
    autoChargeViaStripe: {
      value: boolean
      disabled?: boolean
    }
  }
  onChange: (data: {
    autoChargeViaStripe: {
      value: boolean
      disabled?: boolean
    }
  }) => void
  disabled?: boolean
}) => {
  fields: {
    autoChargeViaStripe: {
      value: boolean
      onChange: (value: boolean) => void
      isDisabled: boolean
      validationErrors: Array<ValidationError>
    }
  }
}

const useStripeAutoChargeWidget: UseStripeAutoChargeWidget = ({
  dataFromInterface,
  onChange,
  disabled
}) => {
  const fields = useMemo(() => {
    return {
      autoChargeViaStripe: {
        value: dataFromInterface.autoChargeViaStripe.value,
        isDisabled:
          (disabled || dataFromInterface.autoChargeViaStripe.disabled) ?? false,
        onChange: (value: boolean) => {
          onChange(
            deepmerge(dataFromInterface, { autoChargeViaStripe: { value } })
          )
        },
        validationErrors: []
      }
    }
  }, [dataFromInterface, onChange, disabled])

  return {
    fields
  }
}

interface StripePaymentInspectorItemProps {
  fieldData: {
    autoChargeViaStripe: {
      value: boolean
      disabled?: boolean
    }
  }
  onChange: (newData: {
    autoChargeViaStripe: {
      value: boolean
      disabled?: boolean
    }
  }) => void
  disabled?: boolean
}

export const StripeAutoChargeWidget: FC<
  StripePaymentInspectorItemProps
> = props => {
  const { fields } = useStripeAutoChargeWidget({
    dataFromInterface: {
      autoChargeViaStripe: props.fieldData.autoChargeViaStripe
    },
    onChange: props.onChange,
    disabled: props.disabled
  })

  return (
    <Flex flexDirection="column" gap="8px">
      <Box {...Lato13Bold} color={GreyGrey80}>
        Payment collection
      </Box>
      <Box
        borderRadius={BorderRadius6}
        backgroundColor={GreyGrey10}
        padding="8px"
      >
        <SwitchLineInput
          isDisabled={fields.autoChargeViaStripe.isDisabled}
          text="Auto-charge customers"
          value={fields.autoChargeViaStripe.value}
          onChange={fields.autoChargeViaStripe.onChange}
          styles={{
            label: {
              ...Lato13Regular,
              color: GreyGrey80
            }
          }}
          data-testid="stripe.inspector.content.autoCharge"
        />
      </Box>
      <Box {...Lato13Regular} color={GreyGrey70}>
        Once a payment method is on file for a customer, Sequence will
        auto-charge the customer when you send subsequent invoices.
      </Box>
    </Flex>
  )
}
