import {
  Contact,
  CubeReducerState,
  Discount,
  ListPrice,
  MerchantBranding,
  Minimum,
  Phase,
  Price,
  QuoteContact
} from 'modules/Cube/domain/cube.domain.types'
import { v1ApiQuote } from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders/useLoadQuote'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import { Currency } from '@sequencehq/core-models'
import { differenceInDays, type Duration } from '@sequencehq/utils/dates'
import { v1ApiCustomer } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadAllCustomers'
import { v1ApiIntegration } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadIntegrations'
import { v1ApiPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadPrices'
import { v1ApiProduct } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadProducts'
import { arrayToIdKeyedObject, priceModelAdapter } from '@sequencehq/utils'
import { presentationToBlocks } from '@sequencehq/quote-content'
import { v1ApiListPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadListPrices'
import { v1ApiUser } from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders/useLoadUser'
import { v1ApiContact } from '../ports/entityLoaders/useLoadContacts'

export type ApiData = {
  quote: v1ApiQuote
  presentation: any[]
  customers: v1ApiCustomer[]
  integrations: v1ApiIntegration[]
  prices: v1ApiPrice[]
  listPrices: v1ApiListPrice[]
  products: v1ApiProduct[]
  currencies: Currency[]
  merchantBranding: MerchantBranding
  createdByUser: v1ApiUser | undefined
  contacts: v1ApiContact[]
}

export const quoteResponseToCommonFields = (
  quote: v1ApiQuote,
  enabledCurrencies: Currency[]
): CubeReducerState['data']['common'] => ({
  id: quote.id,
  title: quote.title ?? '',
  alias: quote.quoteNumber,
  customerId: quote.customerId ?? '',
  status: quoteStatusAdapter.in(quote.status),
  currency: quote.currency ?? enabledCurrencies[0],
  startDate: quote.preliminaryBillingStartDate
    ? new Date(quote.preliminaryBillingStartDate)
    : undefined,
  isArchived: !!quote.archivedAt,
  phaseIds: quote.phases.length
    ? quote.phases?.map(phase => phase.id)
    : ['new-uuid'],
  createdAt: new Date(quote.createdAt)
})

const calculateExpiryDays = (quote: v1ApiQuote): Duration | undefined => {
  if ('expiryDays' in quote) {
    return { days: quote.expiryDays }
  }

  if (quote.expiresAt) {
    const quoteExpiresAt = new Date(quote.expiresAt)
    const now = new Date()

    return { days: differenceInDays(quoteExpiresAt, now) }
  }

  return {
    days: 30
  }
}

export const quoteResponseToFields = (
  quote: v1ApiQuote,
  user: v1ApiUser | undefined
): CubeReducerState['data']['quote'] => ({
  quoteNumber: quote.quoteNumber,
  contractLength: calculateContractLength(
    quote.phases?.map(({ duration }) => duration) ?? []
  ),
  dealType: (quote.dealType ?? 'NEW_BUSINESS') as 'NEW_BUSINESS' | 'RENEWAL',
  expiresIn: calculateExpiryDays(quote),
  publishedAt: quote.publishedAt ? new Date(quote.publishedAt) : undefined,
  acceptedAt: quote.acceptedAt ? new Date(quote.acceptedAt) : undefined,
  attachmentAssets: quote.attachmentAssets,
  readyToSignAt:
    'readyToSignAt' in quote && quote.readyToSignAt
      ? new Date(quote.readyToSignAt)
      : undefined,
  executedAt:
    'executedAt' in quote && quote.executedAt
      ? new Date(quote.executedAt)
      : undefined,
  expiresAt:
    'expiresAt' in quote && quote.expiresAt
      ? new Date(quote.expiresAt)
      : undefined,
  billingScheduleId: quote.billingScheduleId,
  createdByEmail: user?.email,
  contacts: quote.contacts,
  isSignaturesEnabled: quote.isSignaturesEnabled,
  counterSigners: quote.counterSigners,
  customerLegalName: quote.customerLegalName,
  customerAddress: quote.customerAddress,
  salesforceOpportunityId: quote.salesforceOpportunityId,
  pdfStatus: quote.pdfStatus,
  isExpired: quote.isExpired
})

export const quotePhasesToPhases = (
  quotePhases: v1ApiQuote['phases']
): CubeReducerState['data']['phases'] => {
  if (quotePhases.length === 0) {
    return {
      'new-uuid': {
        id: 'new-uuid',
        priceIds: [],
        discountIds: [],
        minimumIds: [],
        duration: {
          years: 1,
          days: -1
        }
      }
    }
  }

  const convertedArray: Phase[] = quotePhases.map(quotePhase => ({
    id: quotePhase.id,
    priceIds: quotePhase.prices.map(({ id }) => id),
    discountIds: quotePhase.discounts.map(discount => discount.id),
    minimumIds: quotePhase.minimums.map(minimum => minimum.id),
    duration: quotePhase.duration
      ? {
          years: quotePhase.duration?.years,
          months: quotePhase.duration?.months,
          days: -1
        }
      : undefined
  }))

  return arrayToIdKeyedObject(convertedArray)
}

export const quotePhasesToDiscounts = (
  quotePhases: v1ApiQuote['phases']
): Record<string, Discount> => {
  return quotePhases.reduce((acc, quotePhase) => {
    const discounts: Discount[] = quotePhase.discounts.map(discount => ({
      id: discount.id,
      message: discount.message,
      discountCalculationType: discount.discountCalculationType,
      amount: parseFloat(discount.amount),
      priceIds: discount.restrictToPrices,
      applyToAllPrices: !discount.restrictToPrices.length
    }))

    return {
      ...acc,
      ...arrayToIdKeyedObject(discounts)
    }
  }, {})
}

export const quotePhasesToMinimums = (
  quotePhases: v1ApiQuote['phases']
): Record<string, Minimum> => {
  return quotePhases.reduce((acc, quotePhase) => {
    const minimums: Minimum[] = quotePhase.minimums.map(minimum => ({
      id: minimum.id,
      value: minimum.amount,
      name: '',
      scope: {
        target: minimum.restrictToPrices.length === 0 ? 'allUsage' : 'specific',
        priceIds: minimum.restrictToPrices
      }
    }))

    return {
      ...acc,
      ...arrayToIdKeyedObject(minimums)
    }
  }, {})
}

type ContractDuration = {
  years: number
  months: number
}

const calculateContractLength = (
  phaseDurations: (Duration | undefined)[]
): ContractDuration | undefined => {
  if (phaseDurations.some(duration => duration === undefined)) {
    return
  }

  const accumulatedDuration: ContractDuration = phaseDurations.reduce(
    (accDuration, phaseDuration) => {
      const accMonths = accDuration.months + (phaseDuration?.months ?? 0)
      const accYears = accDuration.years + (phaseDuration?.years ?? 0)

      return {
        months: accMonths,
        years: accYears
      }
    },
    { months: 0, years: 0 } as ContractDuration
  )

  return accumulatedDuration
}

const pricesToDomainPrices = (
  prices: ApiData['prices'],
  quoteCurrency: Currency
): Price[] => {
  return prices.map(price => {
    const { customMetricParameters, integrationIds, ...rest } = price

    return {
      ...rest,
      /**
       * The error below is to be fixd by an API change on June 3rd.
       */
      structure:
        'includedSeats' in price.structure
          ? //@ts-expect-error The 20240509 endpoint currently returns the 20240101 format for price structure
            priceModelAdapter.out({
              ...price,
              externalIds: []
            })?.structure
          : price.structure,
      currency: quoteCurrency,
      integrationIds,
      customMetricParameters
    }
  })
}

const transformListPrices = (listPrices: ApiData['listPrices']) => {
  return listPrices.reduce<Record<string, Array<ListPrice>>>(
    (acc, listPrice) => {
      const productListPrices = acc[listPrice.productId] ?? []

      return {
        ...acc,
        [listPrice.productId]: [...productListPrices, listPrice]
      }
    },
    {}
  )
}

export const transformContacts = (
  contacts: ApiData['contacts'],
  customerId: ApiData['quote']['customerId']
): Record<string, Contact> => {
  const contactsWithCustomerId = contacts.map(contact => ({
    ...contact,
    customerId: customerId ?? ''
  }))

  return arrayToIdKeyedObject(contactsWithCustomerId)
}

export const apiResponseToFields = (
  apiData: ApiData
): Pick<CubeReducerState, 'data'> => {
  const transformedPhases = quotePhasesToPhases(apiData.quote.phases)
  const currency = apiData.quote.currency ?? apiData.currencies[0]

  return {
    data: {
      common: quoteResponseToCommonFields(apiData.quote, apiData.currencies),
      quote: quoteResponseToFields(apiData.quote, apiData.createdByUser),
      presentation: presentationToBlocks(apiData.presentation),
      phases: transformedPhases,
      schedule: INITIAL_CUBE_STATE.data.schedule,
      customers: arrayToIdKeyedObject(apiData.customers),
      taxRates: {},
      products: arrayToIdKeyedObject(apiData.products),
      discounts: quotePhasesToDiscounts(apiData.quote.phases),
      minimums: quotePhasesToMinimums(apiData.quote.phases),
      prices: arrayToIdKeyedObject<Price>(
        pricesToDomainPrices(apiData.prices, currency)
      ),
      listPrices: transformListPrices(apiData.listPrices),
      merchantBranding: apiData.merchantBranding,
      contacts: transformContacts(apiData.contacts, apiData.quote.customerId)
    }
  }
}

export const contactToQuoteContact: (contact: Contact) => QuoteContact =
  contact => {
    return {
      contactId: contact.id,
      name: contact.name,
      email: contact.email,
      signedAt: undefined
    }
  }
