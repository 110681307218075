import { Currency } from '@sequencehq/core-models'
import { greaterThan, required } from '@sequencehq/validation'
import { defaultAvailableFrequenciesOptions } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/constants'
import { FormFields, useForm } from '@sequencehq/utils'
import {
  AvailableStandardFrequency,
  BillingType,
  PricingEditorFormData
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback, useMemo } from 'react'

type StandardPriceFields = PricingEditorFormData['STANDARD']
type UseStandardPriceForm = (props?: { formDisabled?: boolean }) => {
  fieldsConfig: Omit<
    FormFields<StandardPriceFields>,
    'billingFrequency' | 'billingType'
  > & {
    billingFrequency: FormFields<StandardPriceFields>['billingFrequency'] & {
      hidden: boolean
    }
    billingType: FormFields<StandardPriceFields>['billingType'] & {
      hidden: boolean
    }
  }
  currency: Currency
  fieldsAreHidden: boolean
}

const standardPriceFrequencies: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

const availableBillingTypeOptions: { value: BillingType; label: string }[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export const useStandardPriceForm: UseStandardPriceForm = (
  { formDisabled } = { formDisabled: false }
) => {
  const pricingEditorContext = usePricingEditorContext()

  const checkIfStandardFieldDisabledByDomain = useCallback(
    ({ property }: { property: keyof StandardPriceFields }) => {
      return pricingEditorContext.functions.fieldIsDisabled(
        `STANDARD.${property}`
      )
    },
    [pricingEditorContext.functions]
  )

  const availableFrequencies = useMemo(() => {
    return standardPriceFrequencies.filter(frequency => {
      return pricingEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [pricingEditorContext.configuration.availableStandardFrequencies])

  const { fields } = useForm<StandardPriceFields>({
    value: pricingEditorContext.data.formData.STANDARD,
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    disabled: formDisabled,
    fieldConfiguration: [
      {
        property: 'billingFrequency',
        validation: [required],
        disabled: checkIfStandardFieldDisabledByDomain,
        options: availableFrequencies
      },
      {
        property: 'billingType',
        validation: [required],
        disabled: checkIfStandardFieldDisabledByDomain,
        options: availableBillingTypeOptions
      },
      {
        property: 'price',
        validation: [required, greaterThan(0, "Price can't be zero")],
        disabled: checkIfStandardFieldDisabledByDomain
      }
    ],
    onChange: newData =>
      pricingEditorContext.functions.updateFormData({
        STANDARD: newData
      }),
    onValidationStateChange: isValid =>
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          STANDARD: isValid
        }
      })
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      billingType: {
        ...fields.billingType,
        hidden:
          pricingEditorContext.configuration.enableListPrices &&
          !pricingEditorContext.derived.queries.availableFeatures
            .showAllPriceFields
      },
      billingFrequency: {
        ...fields.billingFrequency,
        hidden:
          pricingEditorContext.configuration.enableListPrices &&
          !pricingEditorContext.derived.queries.availableFeatures
            .showAllPriceFields
      }
    }
  }, [
    fields,
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  const fieldsAreHidden = useMemo(() => {
    return Boolean(
      pricingEditorContext.configuration.enableListPrices &&
        !pricingEditorContext.derived.queries.availableFeatures
          .showAllPriceFields
    )
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  return {
    fieldsConfig: enhancedFields,
    currency: pricingEditorContext.data.formData.common.currency,
    fieldsAreHidden
  }
}
