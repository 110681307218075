import {
  CubeReducerState,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'
import { getAddPhaseAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/common/getAddPhaseAvailable'
import { getCustomPhaseDurationsAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/common/getCustomPhaseDurationsAvailable'
import { getEditCustomerAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/common/getEditCustomerAvailable'
import { getEditStartDateAvailable } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/common/getEditStartDateAvailable'
import {
  getLinkSalesforceCustomerAvailable,
  getUnlinkSalesforceCustomerAvailable
} from './inspector'

type Context = {
  orderedPhases: ResolvedPhase[]
}
export const getCommonFeaturesAvailable =
  (ctx: Context) =>
  (
    prevState: CubeReducerState
  ): CubeReducerState['queries']['availableFeatures']['common'] => ({
    editCustomer: getEditCustomerAvailable(prevState),
    editStartDate: getEditStartDateAvailable(prevState),
    addPhase: getAddPhaseAvailable(ctx)(prevState),
    customPhaseDurations: getCustomPhaseDurationsAvailable(prevState),
    inspector: {
      linkSalesforceCustomer: getLinkSalesforceCustomerAvailable(prevState),
      unlinkSalesforceCustomer: getUnlinkSalesforceCustomerAvailable(prevState)
    }
  })
