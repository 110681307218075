import { PropsWithChildren, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { SidebarSectionToggle } from './SidebarSectionToggle'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { Box } from '@chakra-ui/react'

type Props = PropsWithChildren<{
  title: string
  showBorder?: boolean
}>

export const SidebarSection = ({ title, children, showBorder }: Props) => {
  const [isVisible, setIsVisible] = useState(true)

  return (
    <Box borderBottom={showBorder ? `1px solid ${GreyGrey30}` : undefined}>
      <SidebarSectionToggle
        isVisible={isVisible}
        toggle={() => setIsVisible(visible => !visible)}
        text={title}
      />
      <AnimatePresence initial={false}>
        {isVisible && (
          <motion.div
            initial={{ height: '0px' }}
            animate={{ height: 'fit-content' }}
            exit={{ height: '0px' }}
            style={{
              overflow: 'hidden'
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}
