import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import { MinimumEditorContent } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/content/MinimumEditorContent/MinimumEditorContent'
import { useMinimumEditorRoot } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/content/useMinimumEditorRoot'
import { MinimumEditorDomainProvider } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.domain.context'
import { MinimumEditorRootProps } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'
import { FC } from 'react'

export const MinimumsEditorRoot: FC<MinimumEditorRootProps> = props => {
  const { domain } = useMinimumEditorRoot(props)

  return (
    <MinimumEditorDomainProvider value={domain}>
      <Drawer isOpen onClose={props.onClose}>
        <DrawerOverlay
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <DrawerContent
            width="fit-content"
            margin="8px 12px"
            borderRadius="8px"
            maxWidth="550px"
          >
            <MinimumEditorContent />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </MinimumEditorDomainProvider>
  )
}
