import { useCallback } from 'react'

import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'
import { useToast } from '@chakra-ui/react'
import { Toast } from '@sequencehq/core-components'

export const useCreateSeatMetric = () => {
  const toast = useToast()

  return useCallback(
    async (body: DashboardApi20240730.PostSeatMetrics.PostSeatMetricBody) => {
      return await dashboard20240730Client
        .postSeatMetric({
          body
        })
        .then(result => {
          if (result?.error) {
            return null
          }

          toast({
            id: `success-${result.data?.id}`,
            position: 'bottom',
            duration: 6000,
            render: () => (
              <Toast
                type="success"
                title={`New seat metric ${result.data?.label} added`}
                isClosable={true}
              />
            )
          })

          return result?.data
        })
    },
    [toast]
  )
}
