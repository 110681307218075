import { Box } from '@chakra-ui/react'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { SelectField } from '@sequencehq/core-components'
import { useExternalLedgerForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/ExternalLedger/useExternalLedgerForm'

export const ExternalLedgerForm = () => {
  const { fieldsConfig } = useExternalLedgerForm()

  if (fieldsConfig.integrations.length === 0) {
    return null
  }

  return (
    <Box
      width="100%"
      data-testid="common-fields"
      borderTop={`1px solid ${GreyGrey30}`}
      paddingTop="24px"
    >
      {fieldsConfig.integrations.map(integrationField => (
        <SelectField
          key={integrationField.id}
          label={integrationField.label}
          data-testid={`integration-${integrationField.id}`}
          value={integrationField.value}
          onChange={integrationField.onChange}
          options={integrationField.options}
          validationErrors={integrationField.validationErrors}
          isDisabled={integrationField.disabled}
          placeholder={`Choose ${integrationField.label}`}
        />
      ))}
    </Box>
  )
}
