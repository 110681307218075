import { Flex } from '@chakra-ui/react'
import { CreateApiKeyFormMutation } from 'components/CreateApiKey/CreateApiKeyModal'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import { MutationFormProps } from 'components/Form/types'
import Spinner from 'components/Loading'
import { useGetSequenceAccountIdQuery } from 'features/api'
import { handleFormResponse } from 'lib/formValidation'

import { noReturn } from 'lib/noReturn'
import { required } from '@sequencehq/validation'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { CheckboxField } from 'components/FormFields'

interface FormValues {
  name: string
  pinApiVersion: boolean
}

export const CreateApiKeyForm: FC<
  MutationFormProps<CreateApiKeyFormMutation> & Record<string, never>
> = ({ formId, submitForm }) => {
  const {
    data: sequenceAccountIdResponse,
    isFetching: isFetchingSequenceAccountId
  } = useGetSequenceAccountIdQuery(undefined)

  const sequenceAccountId = sequenceAccountIdResponse?.value()

  if (!sequenceAccountId || isFetchingSequenceAccountId) {
    return <Spinner></Spinner>
  }

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{
        name: undefined,
        pinApiVersion: true
      }}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          createOAuthClientRequest: {
            clientName: values.name,
            accountId: sequenceAccountId.id,
            pinApiVersion: values.pinApiVersion,
            scopes: ['product']
          }
        })
        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <>
            <Flex
              as="form"
              px={5}
              py={5}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
              id={formId}
            >
              <FormErrors formError={submitError} />
              <TextInputField
                fieldName="name"
                fieldLabel="Client name"
                validate={required}
              />
              <Flex height="16px" />
              <CheckboxField
                fieldName="pinApiVersion"
                fieldLabel="Pin API Key to latest API Version"
                defaultChecked={true}
              />
            </Flex>
          </>
        )
      }}
    />
  )
}
