import deepmerge from 'deepmerge'
import {
  ActionHandler,
  UpdateProductAction
} from '../pricingEditor.domain.types'

export const updateProduct: ActionHandler<UpdateProductAction> =
  prevState => action => {
    const newProduct = deepmerge(prevState.data.product, action.payload)

    return {
      ...prevState,
      data: {
        ...prevState.data,
        product: newProduct
      }
    }
  }
