import { usePrevious } from 'lib/usePrevious'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

// Keeps track of a value that can be updated by the client.
// The `currentValue` passed into it is the latest value from the server.
// When updated by the client, the time of the update is recorded.
// When the `currentValue` changes, the client copy and timestamp is reset.
//
// An optional `serverRequestTime` param can be passed to ensure server
// updates are only applied if they were made before the last client update.
export function useClientCopy<T>(
  currentValue: T,
  serverRequestTime: number = 0
): [T, (updatedValue: T) => void] {
  const [clientUpdatedTime, setClientUpdatedTime] = useState<null | number>(
    null
  )
  const [clientCopy, setClientCopy] = useLiveState(
    currentValue,
    (clientUpdatedTime ?? 0) <= serverRequestTime
  )
  const previousValue = usePrevious(currentValue)

  useEffect(() => {
    if (!isEqual(currentValue, previousValue)) {
      setClientUpdatedTime(null)
    }
  }, [currentValue, previousValue])

  const updateClientCopy = useCallback(
    (updatedValue: T) => {
      setClientCopy(updatedValue)
      setClientUpdatedTime(Date.now())
    },
    [setClientCopy]
  )

  return [clientCopy, updateClientCopy]
}

// Like `useState` but when the `currentValue` changes, the state is updated to match it.
const useLiveState = <T>(currentValue: T, shouldUpdate = true) => {
  const hook = useState<T>(currentValue)
  const [, setState] = hook
  const previousValue = usePrevious(currentValue)

  useEffect(() => {
    if (shouldUpdate && !isEqual(currentValue, previousValue)) {
      setState(currentValue)
    }
  }, [currentValue, previousValue, setState, shouldUpdate])

  return hook
}
