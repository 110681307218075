import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Spacer,
  Text
} from '@chakra-ui/react'
import { Badge, SwitchLineInput } from '@sequencehq/core-components'
import {
  BillingScheduleModel,
  CreditNoteModel,
  PlanModel,
  toInvoiceStatusBadgeProps,
  toMoney
} from '@sequencehq/core-models'
import { dateTimeWithFormat, localDateWithFormat } from '@sequencehq/formatters'
import { InvoiceIcon } from 'components/icons'
import { CustomerIcon } from 'components/icons/CustomerIcon'
import { PlanIcon } from 'components/icons/PlanIcon'
import { ScheduleIcon } from 'components/icons/ScheduleIcon'
import { FC } from 'react'
import {
  useGetCustomersByIdQuery,
  usePutCreditNotesByIdMutation
} from 'features/api'
import { datesInSameYear } from '@sequencehq/utils/dates'
import { getLogo } from 'lib/integrations/logos'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface DefaultSidebarProps {
  creditNote: CreditNoteModel
  billingSchedule: BillingScheduleModel | undefined
  plan: PlanModel | undefined
}

const DefaultSidebar: FC<DefaultSidebarProps> = ({
  creditNote,
  billingSchedule,
  plan
}) => {
  const [updateCreditNote] = usePutCreditNotesByIdMutation()
  const flags = useFlags()
  const { data: customerData } = useGetCustomersByIdQuery({
    id: creditNote.customerId
  })

  const customer = customerData?.value()

  return (
    <>
      <Flex>
        <Text textStyle="bold">Credit Note status</Text>
        <Spacer></Spacer>
        <Box>
          <Badge
            {...toInvoiceStatusBadgeProps({ status: creditNote.status })}
          />
        </Box>
      </Flex>
      <Box h="2px"></Box>
      <Divider></Divider>
      {billingSchedule && (
        <>
          <Box h="11px"></Box>
          <Flex>
            <Text textStyle="bold">Schedule</Text>
            <Spacer></Spacer>

            <Flex>
              <ScheduleIcon></ScheduleIcon>
              <Box w="4px"></Box>
              <Text textAlign="right">{billingSchedule?.id}</Text>
            </Flex>
          </Flex>
          <Box h="8px"></Box>
          <Divider></Divider>
        </>
      )}
      {plan && (
        <>
          <Box h="11px"></Box>
          <Flex>
            <Text textStyle="bold">Plan</Text>
            <Spacer></Spacer>

            <Flex>
              <PlanIcon></PlanIcon>
              <Box w="4px"></Box>
              <Text textAlign="right">{plan.name}</Text>
            </Flex>
          </Flex>
          <Box h="8px"></Box>
          <Divider></Divider>
        </>
      )}
      <Box h="11px"></Box>
      <Flex>
        <Text textStyle="bold">Customer</Text>
        <Spacer></Spacer>

        <Flex>
          <CustomerIcon></CustomerIcon>
          <Box w="4px"></Box>
          <Text textAlign="right">
            {' '}
            {creditNote.customerLegalCompanyName}
            {customer?.archivedAt ? ' (archived)' : ''}
          </Text>
        </Flex>
      </Flex>
      <Box h="8px"></Box>
      <Divider></Divider>
      <Box h="11px"></Box>
      <Flex>
        <Text textStyle="bold">Customer emails</Text>
        <Spacer></Spacer>

        <Flex>
          <Text textAlign="right"> {creditNote.customerEmails.join(', ')}</Text>
        </Flex>
      </Flex>
      <Box h="8px"></Box>
      <Divider></Divider>
      {creditNote.invoiceNumber && (
        <>
          <Box h="11px"></Box>
          <Flex>
            <Text textStyle="bold">Related invoice</Text>
            <Spacer></Spacer>

            <Flex>
              <InvoiceIcon></InvoiceIcon>
              <Box w="4px"></Box>
              <Text textAlign="right"> {creditNote.invoiceNumber}</Text>
            </Flex>
          </Flex>
          <Box h="8px"></Box>
          <Divider></Divider>
        </>
      )}
      {creditNote.billingPeriodStart && creditNote.billingPeriodEnd && (
        <>
          <Box h="11px"></Box>
          <Flex>
            <Text textStyle="bold">Billing period</Text>
            <Spacer></Spacer>
            <Text textAlign="right">
              {localDateWithFormat(
                creditNote.billingPeriodStart,
                `d MMM${
                  datesInSameYear(creditNote.billingPeriodStart)(
                    creditNote.billingPeriodEnd
                  )
                    ? ''
                    : ' yy'
                }`
              )}{' '}
              - {localDateWithFormat(creditNote.billingPeriodEnd, 'd MMM yy')}
            </Text>
          </Flex>
          <Box h="8px"></Box>
          <Divider></Divider>
        </>
      )}

      <Box h="11px"></Box>
      <Flex>
        <Text textStyle="bold">Credit total including VAT</Text>
        <Spacer></Spacer>
        <Text textAlign="right">
          {toMoney({
            currency: creditNote.currency,
            value: creditNote.grossTotal
          })}
        </Text>
      </Flex>
      <Box h="8px"></Box>
      {creditNote.status !== 'VOIDED' &&
        flags?.creditNoteCreditGrantsEnabled && (
          <>
            <Divider></Divider>
            <Box h="11px"></Box>
            <Flex>
              <Text textStyle="bold">Create cash credit grant on sending</Text>
              <Spacer></Spacer>
              <Flex textAlign="right">
                <SwitchLineInput
                  value={
                    creditNote.settings.generateCashCreditGrant === 'ENABLED'
                  }
                  onChange={generateGrant =>
                    void updateCreditNote({
                      id: creditNote.id,
                      updateCreditNoteEndpointUpdateCreditNoteRequestModel: {
                        ...creditNote,
                        settings: {
                          generateCashCreditGrant: generateGrant
                            ? 'ENABLED'
                            : 'DISABLED'
                        }
                      }
                    })
                  }
                  styles={{ label: { marginLeft: '0px' } }}
                  text={''}
                  isDisabled={creditNote.status !== 'DRAFT'}
                />
              </Flex>
            </Flex>
            <Box h="8px"></Box>
          </>
        )}
      <Divider></Divider>
      {creditNote.linkedServices.map(service => (
        <>
          <Box h="11px"></Box>

          <Flex>
            <Flex flexDirection="row">
              <Flex flexDirection="column">
                <Box height="10px"></Box>
                <Text textStyle="bold">Synced with </Text>
              </Flex>
              <Box width="8px"></Box>
              {getLogo(service.externalService, 36)}
            </Flex>
            <Box width="10px"></Box>
            {service.externalUrl && (
              <Button
                variant="secondary"
                as={Link}
                href={service.externalUrl}
                target="_blank"
              >
                Go to
              </Button>
            )}
            <Spacer></Spacer>
            <Flex flexDirection="column">
              <Box height="10px"></Box>
              <Text textAlign="right">
                {dateTimeWithFormat(service.syncTime, 'd MMMM yyyy HH:MM:SS')}
              </Text>
            </Flex>
          </Flex>

          <Box h="8px"></Box>
          <Divider></Divider>
        </>
      ))}
    </>
  )
}

export default DefaultSidebar
