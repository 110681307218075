import { Box } from '@chakra-ui/react'
import { Lato14Bold } from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  PriceField
} from '@sequencehq/core-components'
import { useStandardPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/StandardPrice/useStandardPriceForm'
import {
  AvailableStandardFrequency,
  BillingType
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

export const StandardPriceForm = () => {
  const { fieldsConfig, currency } = useStandardPriceForm()
  return (
    <Box paddingBottom="8px">
      <HorizontalSelectorField
        label="Frequency"
        data-testid="standard.billingFrequency"
        value={fieldsConfig.billingFrequency.value}
        options={fieldsConfig.billingFrequency.options}
        onChange={newValue =>
          fieldsConfig.billingFrequency.onChange(
            newValue as AvailableStandardFrequency
          )
        }
        validationErrors={fieldsConfig.billingFrequency.validationErrors}
        isDisabled={fieldsConfig.billingFrequency.disabled}
      />
      <HorizontalSelectorField
        label="Type"
        data-testid="standard.billingType"
        tooltip={{
          title: 'Billing type',
          content: (
            <Box paddingTop="8px">
              <Box {...Lato14Bold} marginBottom="8px">
                In arrears
              </Box>
              <Box marginBottom="16px">
                Charges will be applied at the end of the billing period
              </Box>
              <Box {...Lato14Bold} marginBottom="8px">
                In advance
              </Box>
              <Box>
                Charges will be applied at the start of the billing period
              </Box>
            </Box>
          )
        }}
        value={fieldsConfig.billingType.value}
        onChange={newValue =>
          fieldsConfig.billingType.onChange(newValue as BillingType)
        }
        options={fieldsConfig.billingType.options}
        validationErrors={fieldsConfig.billingType.validationErrors}
        isDisabled={fieldsConfig.billingType.disabled}
      />
      <PriceField
        label="Price"
        data-testid="listPrice.standard.price"
        value={fieldsConfig.price.value}
        onChange={newValue => fieldsConfig.price.onChange(newValue)}
        validationErrors={fieldsConfig.price.validationErrors}
        currency={currency}
        isDisabled={fieldsConfig.price.disabled}
      />
    </Box>
  )
}
