import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey60,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext.ts'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { FC } from 'react'

type ConfirmSendInvoiceModalProps = {
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmSendInvoiceModal: FC<ConfirmSendInvoiceModalProps> = ({
  onConfirm,
  onClose
}) => {
  const { data } = useInvoiceEditorContext()
  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Send invoice
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            Email this invoice to {data?.recipient.customerLegalName} (
            <Text
              as="span"
              {...Lato14ExtendedRegular}
              wordBreak="break-all"
              color={GreyGrey80}
            >
              {data?.recipient.customerEmails.join(', ')}
            </Text>
            ). After sending you can no longer edit the invoice.
          </Box>
          {isSandboxEnv() && (
            <Box
              color={GreyGrey60}
              {...Lato14ExtendedRegular}
              marginBottom="24px"
            >
              Note: No end-customer emails will be sent in {getEnvironment()}.
            </Box>
          )}
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={onConfirm}
              height="32px"
              width="100%"
            >
              Continue
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
