import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'

export const createIntegration = async (service: IntegrationServices) => {
  const res = await dashboardv20240509Client.postIntegration({
    service: service
  })

  return {
    success: !res.error,
    error: res.error ? IntegrationsPortErrors.Other : null
  }
}
