import { Flex, Td, Tr, Box } from '@chakra-ui/react'
import { PriceTableRow } from 'modules/Products/types'
import { useNavigate } from 'react-router-dom'
import { IndigoIndigo50, RedRed50 } from '@sequencehq/design-tokens'
import { ProductListPriceKebabMenu } from './ProductListPriceKebabMenu'
import { TrashIcon } from '@heroicons/react/16/solid'
import { SequenceMenuItem } from '@sequencehq/core-components'

import { toBillingFrequencyLabel } from '@sequencehq/utils'

export const ProductListPrice = ({
  priceRow,
  productId,
  triggerArchiveModal
}: {
  priceRow: PriceTableRow
  productId: string
  triggerArchiveModal: (id: string) => void
}) => {
  const navigate = useNavigate()

  const priceRowFrequency = toBillingFrequencyLabel(priceRow.frequency)

  return (
    <Tr
      key={priceRow.id}
      onClick={() => {
        navigate(`/products/${productId}/list-price/${priceRow.id}`)
      }}
    >
      <Td textColor={IndigoIndigo50}>{priceRow.unitPrice}</Td>
      <Td>{priceRow.name}</Td>
      <Td>{priceRow.currency}</Td>
      <Td>{priceRowFrequency}</Td>
      <Td>{priceRow.pricingModel}</Td>
      <Td>
        <Flex justify="space-between" align="center">
          {priceRow.created}
          <Box onClick={e => e.stopPropagation()}>
            <ProductListPriceKebabMenu
              items={[
                <SequenceMenuItem
                  key="product-list-archive-menu-item"
                  uuid="archive"
                  label="Archive"
                  iconLeft={
                    <TrashIcon width="16px" height="16px" color={RedRed50} />
                  }
                  style={{ color: 'red' }}
                  onClick={() => triggerArchiveModal(priceRow.id)}
                />
              ]}
            />
          </Box>
        </Flex>
      </Td>
    </Tr>
  )
}
