import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import {
  UpdateCreditNoteStatusFormProps,
  VoidCreditNoteFormMutation
} from 'components/UpdateInvoiceStatus/types'
import VoidCreditNoteForm from 'components/UpdateInvoiceStatus/VoidCreditNoteForm'
import { usePostCreditNotesByIdVoidMutation } from 'features/api'

const VoidCreditNoteModal = createFormModal<
  VoidCreditNoteFormMutation,
  UpdateCreditNoteStatusFormProps
>({
  FormComponent: VoidCreditNoteForm,
  useMutation: usePostCreditNotesByIdVoidMutation,
  modalTitle: 'Void Credit Note',
  successMessage: 'Credit Note marked as void',
  submitTitle: 'Void credit note',
  formId: 'voidCreditNoteForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Credit Note ${
        response.creditNoteNumber ? response.creditNoteNumber : ''
      } marked as void`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default VoidCreditNoteModal
