import { useCallback, useMemo } from 'react'
import { currencyToName } from '@sequencehq/core-models'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import deepmerge from 'deepmerge'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import {
  ListPriceEditorFormData,
  PricingModel
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

const PRICING_MODEL_OPTIONS: {
  value: PricingModel
  label: string
  description: string
}[] = [
  {
    value: 'STANDARD',
    label: 'Standard',
    description: 'Charge a recurring or one-off fixed fee'
  },
  {
    value: 'LINEAR',
    label: 'Linear',
    description: 'Charge a single, linear rate for usage.'
  },
  {
    value: 'VOLUME',
    label: 'Volume tiers',
    description: 'All usage is charged a single unit fee based on total volume.'
  },
  {
    value: 'GRADUATED',
    label: 'Graduated tiers',
    description:
      'Usage in each tier is charged separately using respective tier fees.'
  },
  {
    value: 'PACKAGED',
    label: 'Packaged',
    description: 'Charge a fixed fee for a packaged quantity of units.'
  },
  {
    value: 'SEAT_BASED_LINEAR',
    label: 'Seat-based → Linear Pricing',
    description: 'Charge a single, linear rate for seats.'
  },
  {
    value: 'SEAT_BASED_GRADUATED',
    label: 'Seat-based → Graduated Pricing',
    description:
      'Seat usage in each tier is charged separately using respective tier fees.'
  }
]

type CommonFields = ListPriceEditorFormData['common']
type CommonFieldsConfig = {
  name: FormFields<CommonFields>['name']
  currency: FormFields<CommonFields>['currency'] & { hidden: boolean }
  pricingModel: FormFields<CommonFields>['pricingModel'] & {
    description: string
  }
}
type UseCommonFieldsForm = () => {
  fieldsConfig: CommonFieldsConfig
}

export const useCommonFieldsForm: UseCommonFieldsForm = () => {
  const listPriceEditorContext = useListPriceEditorContext()
  const flags = useFlags()

  const availableCurrencies = useMemo(() => {
    return listPriceEditorContext.configuration.availableCurrencies.map(
      currency => ({
        label: `${currency} - ${currencyToName[currency]}`,
        value: currency
      })
    )
  }, [listPriceEditorContext.configuration.availableCurrencies])

  const availablePricingModelOptions = useMemo(() => {
    if (flags.enableSeats) {
      return PRICING_MODEL_OPTIONS
    }

    /**
     * Include seat based if the current price is seat based, even if the flag is
     * disabled. This prevents an error or otherwise weird state if the price
     * is seat based.
     */
    if (
      ['SEAT_BASED_LINEAR', 'SEAT_BASED_GRADUATED'].includes(
        listPriceEditorContext.data.formData.common.pricingModel
      )
    ) {
      return PRICING_MODEL_OPTIONS
    }

    return PRICING_MODEL_OPTIONS.filter(
      option =>
        !['SEAT_BASED_LINEAR', 'SEAT_BASED_GRADUATED'].includes(option.value)
    )
  }, [flags, listPriceEditorContext.data.formData.common.pricingModel])

  /**
   * Initialise the form management
   */
  const checkIfCommonFieldDisabledByDomain = useCallback(
    ({ property }: { property: keyof CommonFields }) =>
      listPriceEditorContext.functions.fieldIsDisabled(`common.${property}`),
    [listPriceEditorContext]
  )

  const { fields, queries } = useForm({
    value: listPriceEditorContext.data.formData.common,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required],
        disabled: checkIfCommonFieldDisabledByDomain
      },
      {
        property: 'currency',
        validation: [required],
        disabled: checkIfCommonFieldDisabledByDomain,
        options: availableCurrencies
      },
      {
        property: 'pricingModel',
        validation: [required],
        disabled: checkIfCommonFieldDisabledByDomain,
        options: availablePricingModelOptions
      }
    ],
    showValidationErrors: listPriceEditorContext.editor.showValidationErrors,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      listPriceEditorContext.functions.updateEditor({
        formsValid: {
          common: isValid
        }
      })
    },
    onChange: newData => {
      listPriceEditorContext.functions.updateFormData({
        common: newData
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return deepmerge(
      fields,
      {
        pricingModel: {
          description:
            availablePricingModelOptions.find(
              option => option.value === queries.formData.pricingModel
            )?.description ?? ''
        },
        currency: {
          hidden: ['SEAT_BASED_LINEAR', 'SEAT_BASED_GRADUATED'].includes(
            listPriceEditorContext.data.formData.common.pricingModel
          )
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    )
  }, [
    fields,
    availablePricingModelOptions,
    listPriceEditorContext.data.formData.common.pricingModel,
    queries.formData.pricingModel
  ])

  return {
    fieldsConfig: enhancedFields
  }
}
