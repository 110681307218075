import {
  dashboardv20240509Client,
  Dashboardv20240509Api
} from '@sequencehq/api/dashboard/v20240509'
import { useCallback } from 'react'

export type v1ApiContactPostBody =
  Dashboardv20240509Api.PostCustomerContact.CustomerContactPostBody

export type v1ApiContact =
  Dashboardv20240509Api.GetCustomerContacts.CustomerContact

type UseCreateContact = () => ({
  customerId,
  body
}: {
  customerId: string
  body: v1ApiContactPostBody
}) => Promise<v1ApiContact | null>

export const useCreateContact: UseCreateContact = () => {
  const createContact = useCallback(
    async ({
      customerId,
      body
    }: {
      customerId: string
      body: v1ApiContactPostBody
    }) => {
      const response = await dashboardv20240509Client.postCustomerContact({
        customerId,
        body
      })

      if (response.error || !response.data) {
        return null
      }

      return response.data
    },
    []
  )

  return createContact
}
