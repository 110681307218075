import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import { Button, ButtonGroup, Flex, Grid } from '@chakra-ui/react'
import { GreyWhite } from '@sequencehq/design-tokens'
import Spinner from 'components/Loading/Spinner'
import Page from 'components/Page'
import { useGetApiSelfQuery } from 'features/api'
import { borderColor } from '@sequencehq/core-theme'
import { TeamSettingsProvider } from 'components/TeamSettings/TeamSettingsProvider'
import { InviteMemberModal } from 'components/TeamSettings/Modals/InviteMemberModal'
import { MembersTable } from 'components/TeamSettings/MembersTable/MembersTable'
import { useTeamSettings } from 'components/TeamSettings/hooks/useTeamSettings'
import { RevokeInviteModal } from 'components/TeamSettings/Modals/RevokeInviteModal'
import { DeleteMemberModal } from 'components/TeamSettings/Modals/DeleteMemberModal'

export const TeamSettings = () => {
  const { data: selfResponse, isFetching: isFetchingSelf } =
    useGetApiSelfQuery(undefined)

  const self = selfResponse?.value()

  if (!self || isFetchingSelf) {
    return (
      <Grid height="100vh" placeItems="center" width="100%">
        <Spinner />
      </Grid>
    )
  }

  return (
    <TeamSettingsProvider>
      <Page title="Team" actionElement={<ActionButton />}>
        <Filters />
        <MembersTable />
        <Modals />
      </Page>
    </TeamSettingsProvider>
  )
}

const Filters = () => {
  const { filter, setFilter } = useTeamSettings()

  return (
    <Flex
      h="65px"
      px="24px"
      alignItems="center"
      borderBottom={`1px solid ${borderColor}`}
      shrink={0}
    >
      <ButtonGroup size="sm" variant="buttonGroup" isAttached>
        <Button isActive={filter === 'all'} onClick={() => setFilter('all')}>
          All
        </Button>
        <Button
          isActive={filter === 'active'}
          onClick={() => setFilter('active')}
        >
          Active
        </Button>
        <Button
          isActive={filter === 'invited'}
          onClick={() => setFilter('invited')}
        >
          Pending
        </Button>
      </ButtonGroup>
    </Flex>
  )
}

const ActionButton = () => {
  const { setAction } = useTeamSettings()

  return (
    <Button
      leftIcon={
        <PlusIcon
          style={{ marginBottom: '-1px' }}
          color={GreyWhite}
          height={16}
          width={16}
        />
      }
      size="sm"
      variant="primary2"
      onClick={() => setAction('invite')}
    >
      New member
    </Button>
  )
}

const Modals = () => {
  const { action, setAction, setSelectedMember } = useTeamSettings()

  const handleClose = () => {
    setAction('view')
    setSelectedMember(undefined)
  }

  switch (action) {
    case 'invite':
      return <InviteMemberModal isOpen={true} onClose={handleClose} />
    case 'revoke':
      return <RevokeInviteModal isOpen={true} onClose={handleClose} />
    case 'delete':
      return <DeleteMemberModal isOpen={true} onClose={handleClose} />
  }
}
export default TeamSettings
