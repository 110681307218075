import { Box, Button, Grid, Icon } from '@chakra-ui/react'
import {
  Lato14ExtendedRegular,
  YellowYellow10,
  YellowYellow80
} from '@sequencehq/design-tokens'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import ArrowsRightLeftIcon from '@heroicons/react/24/outline/ArrowsRightLeftIcon'
import { PhaseHookInterface } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/usePhase'

export const PhaseAlignmentWarning: FC<{
  phasesHook: PhaseHookInterface
}> = ({ phasesHook }) => {
  return (
    <Grid
      alignItems="center"
      color={YellowYellow80}
      borderRadius="8px"
      backgroundColor={YellowYellow10}
      padding="12px 16px"
      width="100%"
      {...Lato14ExtendedRegular}
      marginBottom="16px"
      templateColumns="1fr auto"
      columnGap="36px"
    >
      <Box>
        The selected phase dates fall within a billing period and restrict price
        edits, discounts, and minimums.{' '}
        <Link
          to="https://docs.sequencehq.com/billing/billing-schedule-phases"
          target="_blank"
          style={{ fontWeight: '600' }}
        >
          Learn more
        </Link>
      </Box>
      <Button
        variant="secondary2"
        height="36px"
        minWidth="36px"
        title="Align dates to billing period"
        aria-label="Align dates to billing period"
        onClick={phasesHook.features.alignDates.handler}
      >
        <Icon as={ArrowsRightLeftIcon} marginRight="4px" />
        Align dates
      </Button>
    </Grid>
  )
}
