import { UsageMetricModel } from '@sequencehq/core-models'
import { CreateUsageMetricDrawerProps } from 'components/CreateUsageMetric/CreateUsageMetricDrawer'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type UseListPriceEditorUsageMetricsDrawerConnector =
  () => CreateUsageMetricDrawerProps

export const useListPriceEditorUsageMetricsDrawerConnector: UseListPriceEditorUsageMetricsDrawerConnector =
  () => {
    const listPriceEditorContext = useListPriceEditorContext()
    const navigate = useNavigate()

    const onSuccess = useCallback(
      (newMetric: UsageMetricModel) => {
        listPriceEditorContext.functions.updateData({
          metrics: {
            [newMetric.id]: newMetric
          }
        })
        listPriceEditorContext.functions.updateFormData({
          [listPriceEditorContext.data.formData.common.pricingModel]: {
            usageMetricId: newMetric.id
          }
        })
        navigate('..')
      },
      [navigate, listPriceEditorContext]
    )

    const onCancel = useCallback(() => {
      navigate('..')
    }, [navigate])

    return {
      onSuccess,
      onCancel
    }
  }
