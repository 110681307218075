import { FC } from 'react'

interface ChevronRightIconProps {
  color?: string
}

export const ChevronRightIcon: FC<ChevronRightIconProps> = ({
  color = '#2D3748'
}) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.01625 5.83496L0.738281 1.55699L1.96031 0.334961L7.46031 5.83496L1.96031 11.335L0.738281 10.1129L5.01625 5.83496Z"
      fill={color}
    />
  </svg>
)
