import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Currency, currencyToName } from '@sequencehq/core-models'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import {
  CubeStatus,
  Customer,
  DisabledReasonType,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { match } from 'ts-pattern'
import { contactsToOptions } from './contactsToOptions'
import { contactToQuoteContact } from 'Cube/communication/external/quotes.api.v1/adapters/quote.adapters.in'
import { type Duration, format } from '@sequencehq/utils/dates'

type FieldConfig<Value> = {
  isDisabled: boolean
  disabledReason: string | undefined
  validationErrors: ValidationResult[]
  value: Value | undefined
  onChange: (newValue: Value) => void
  options: Array<{ value: Value; label: string }>
}

type ForeverDuration = {
  diff: 'FOREVER'
  label: string
  value: 'FOREVER'
}

enum AvailableModal {
  CreateRecipient = 'createRecipient'
}

type QuoteDealType = DashboardApi20240730.GetQuote.QuoteDealType

export type UseQuoteEditorSidebarInterface = {
  fields: {
    customer: FieldConfig<Customer['id']> & {
      onAddNew: () => void
    }
    currency: FieldConfig<Currency>
    quoteDealType: FieldConfig<QuoteDealType>
    validFor: Omit<FieldConfig<Duration>, 'options'> & {
      labelField: string
      labelOverride?: string
      durations: Array<{
        diff: Duration
        label: string
        value: string
      }>
      visible: boolean
    }
    startDate: FieldConfig<Date | undefined> & {
      visible: boolean
      showField: () => void
    }
    contractLength: Omit<FieldConfig<Duration>, 'options'> & {
      durations: Array<
        | {
            diff: Duration
            label: string
            value: string
          }
        | ForeverDuration
      >
      onChange: (newValue: Duration | undefined) => void
    }
    recipients: Omit<FieldConfig<string[]>, 'options'> & {
      options: { value: string; label: string }[]
      visible: boolean
    }
  }
  modals: {
    [AvailableModal.CreateRecipient]: {
      active: boolean
      onClose: () => void
    }
  }
  features: {
    createRecipient: {
      visible: boolean
      disabled: boolean
      onClick: () => void
    }
    isSignersVisible: boolean
  }
}

type UseQuoteEditorSidebar = () => UseQuoteEditorSidebarInterface

const QUOTE_DEAL_LABELS: Record<QuoteDealType, string> = {
  NEW_BUSINESS: 'New Business',
  RENEWAL: 'Renewal'
}

export const useQuoteEditorSidebar: UseQuoteEditorSidebar = () => {
  const cubeContext = useCubeContext()
  const navigate = useNavigate()
  const [activeModal, setActiveModal] = useState<AvailableModal | null>(null)
  const [showStartDateSelection, setShowStartDateSelection] = useState(
    Boolean(cubeContext.queries.rawData.data.common.startDate)
  )

  const features = useMemo(() => {
    return {
      createRecipient: {
        visible: true,
        disabled: false,
        onClick: () => setActiveModal(AvailableModal.CreateRecipient)
      },
      isSignersVisible:
        cubeContext.queries.rawData.data.quote.isSignaturesEnabled
    }
  }, [cubeContext.queries.rawData.data.quote.isSignaturesEnabled])

  const modals = useMemo(() => {
    return {
      [AvailableModal.CreateRecipient]: {
        active: activeModal === AvailableModal.CreateRecipient,
        onClose: () => setActiveModal(null)
      }
    }
  }, [activeModal])

  const fields = useMemo(() => {
    const maybeDisabledOverride = !cubeContext.queries.availableFeatures.quote
      .edit.available.enabled
      ? {
          isDisabled: true,
          disabledReason: match(
            cubeContext.queries.availableFeatures.quote.edit.reasons[0]
              ?.reasonType
          )
            .with(DisabledReasonType.CantChangeWhenArchived, () => {
              return "Can't modify an archived quote"
            })
            .with(DisabledReasonType.CantChangeWhenAccepted, () => {
              return "Can't modify a signed quote"
            })
            .with(DisabledReasonType.CantChangeWhenExecuted, () => {
              return "Can't modify an executed quote"
            })
            .otherwise(() => {
              return ''
            })
        }
      : {}

    const expiresAt = cubeContext.queries.rawData.data.quote.expiresAt
    const showValidUntil: boolean =
      cubeContext.queries.rawData.data.common.status !== CubeStatus.QuoteDraft

    return {
      recipients: {
        value: cubeContext.queries.rawData.data.quote.contacts.map(
          contact => contact.contactId
        ),
        onChange: (newValue: string[]) => {
          cubeContext.mutators.updateData({
            quote: {
              contacts: newValue
                .map(value => cubeContext.queries.rawData.data.contacts[value])
                .map(contact => contactToQuoteContact(contact))
            }
          })
        },
        isDisabled:
          !cubeContext.queries.availableFeatures.quote.setRecipients.available
            .enabled,
        disabledReason:
          cubeContext.queries.availableFeatures.quote.setRecipients.reasons[0]
            ?.reasonType === DisabledReasonType.CantChangeAfterPublish
            ? "Can't change recipients after a quote is published"
            : '',
        ...maybeDisabledOverride,
        validationErrors:
          cubeContext.queries.validation.activeValidationResults?.quote
            .contactIds ?? [],
        options: cubeContext.queries.availableFeatures.quote.setRecipients
          .available.enabled
          ? contactsToOptions(
              cubeContext.queries.rawData.data.contacts,
              contact =>
                cubeContext.queries.rawData.data.quote.isSignaturesEnabled &&
                cubeContext.queries.rawData.data.quote.counterSigners
                  .map(countersigner => countersigner.email.toLowerCase())
                  .includes(contact.email.toLowerCase())
            )
          : cubeContext.queries.rawData.data.quote.contacts.map(contact => ({
              label: contact.name ?? contact.email,
              secondaryLabel: contact.name ? contact.email : undefined,
              value: contact.contactId
            })),
        visible:
          cubeContext.queries.availableFeatures.quote.setRecipients.available
            .visible
      },
      customer: {
        value: cubeContext.queries.rawData.data.common.customerId,
        onChange: (newValue: Customer['id']) => {
          cubeContext.mutators.updateData({
            common: {
              customerId: newValue
            },
            quote: {
              contacts: []
            }
          })

          /**
           * Update contacts when customer is changed
           */
          void cubeContext.mutators.external.in.contacts?.reloadContacts(
            newValue
          )
        },
        isDisabled:
          !cubeContext.queries.availableFeatures.common.editCustomer.available
            .enabled,
        disabledReason:
          cubeContext.queries.availableFeatures.common.editCustomer.reasons[0]
            ?.reasonType === DisabledReasonType.CantChangeAfterPublish
            ? "Can't change customer after a quote is published"
            : '',
        ...maybeDisabledOverride,
        validationErrors:
          cubeContext.queries.validation.activeValidationResults?.common
            .customerId ?? [],
        options: cubeContext.queries.availableFeatures.common.editCustomer
          .available.enabled
          ? Object.values(cubeContext.queries.rawData.data.customers).map(
              customer => ({
                value: customer.id,
                label: customer.legalName
              })
            )
          : [
              {
                value: cubeContext.queries.rawData.data.common.customerId,
                label:
                  cubeContext.queries.rawData.data.quote.customerLegalName || ''
              }
            ],
        onAddNew: () => {
          navigate('./customers/new')
        }
      },
      currency: {
        value: cubeContext.queries.rawData.data.common.currency,
        onChange: (newValue: Currency) =>
          cubeContext.mutators.updateData({
            common: {
              currency: newValue
            }
          }),
        isDisabled: false,
        disabledReason: undefined,
        ...maybeDisabledOverride,
        validationErrors:
          cubeContext.queries.validation.activeValidationResults?.common
            .currency ?? [],
        options: cubeContext.queries.rawData.configuration.currency.enabled.map(
          currency => ({
            label: `${currency} - ${
              currencyToName[currency as keyof typeof currencyToName]
            }`,
            value: currency
          })
        )
      },
      quoteDealType: {
        value: cubeContext.queries.rawData.data.quote.dealType,
        onChange: (newValue: QuoteDealType) =>
          cubeContext.mutators.updateData({
            quote: {
              dealType: newValue
            }
          }),
        isDisabled: false,
        disabledReason: undefined,
        ...maybeDisabledOverride,
        validationErrors:
          cubeContext.queries.validation.activeValidationResults?.quote
            .dealType ?? [],
        options: [
          {
            value: 'NEW_BUSINESS',
            label: QUOTE_DEAL_LABELS.NEW_BUSINESS
          },
          {
            value: 'RENEWAL',
            label: QUOTE_DEAL_LABELS.RENEWAL
          }
        ] as Array<{ value: QuoteDealType; label: string }>
      },
      validFor: {
        labelField: showValidUntil ? 'Valid until' : 'Valid for',
        labelOverride:
          showValidUntil && expiresAt
            ? format(expiresAt, 'd MMMM yyyy')
            : undefined,
        value: cubeContext.queries.rawData.data.quote.expiresIn,
        onChange: (newValue: Duration) =>
          cubeContext.mutators.updateData({
            quote: {
              expiresIn: newValue
            }
          }),
        isDisabled: false,
        disabledReason: undefined,
        ...maybeDisabledOverride,
        validationErrors: [],
        durations: [
          {
            diff: {
              days: 30
            },
            label: '30 days',
            value: '30-days'
          },
          {
            diff: {
              days: 45
            },
            label: '45 days',
            value: '45-days'
          },
          {
            diff: {
              days: 60
            },
            label: '60 days',
            value: '60-days'
          },
          {
            diff: {
              days: 90
            },
            label: '90 days',
            value: '90-days'
          }
        ],
        visible:
          cubeContext.queries.rawData.data.common.status ===
          CubeStatus.QuoteDraft
      },
      startDate: {
        value: cubeContext.queries.rawData.data.common.startDate,
        onChange: (newValue: Date | undefined) => {
          if (newValue) {
            setShowStartDateSelection(true)
          }

          cubeContext.mutators.updateData({
            common: {
              startDate: newValue
            }
          })
        },
        visible:
          showStartDateSelection ||
          !!cubeContext.queries.rawData.data.common.startDate,
        isDisabled:
          !cubeContext.queries.availableFeatures.common.editStartDate.available
            .enabled,
        disabledReason: match(
          cubeContext.queries.availableFeatures.common.editStartDate.reasons[0]
            ?.reasonType
        )
          .with(DisabledReasonType.CantChangeAfterPublish, () => {
            return "Can't change start date after a quote is published"
          })
          .with(DisabledReasonType.CantChangeWhenArchived, () => {
            return "Can't change start date of an archived quote"
          })
          .otherwise(() => {
            return undefined
          }),
        validationErrors:
          cubeContext.queries.validation.activeValidationResults?.common
            .startDate ?? [],
        options: [],
        showField: () => {
          setShowStartDateSelection(true)
        }
      },
      contractLength: {
        value: cubeContext.queries.totalPricingDuration,
        onChange: (newValue: Duration | undefined) => {
          const firstPhaseId = cubeContext.queries.orderedPhases[0]?.id
          cubeContext.mutators.updateData({
            phases: {
              [firstPhaseId]: {
                duration: newValue
              }
            }
          })
        },
        isDisabled:
          !cubeContext.queries.availableFeatures.quote.contractLengthEditing
            .available.enabled,
        disabledReason: '',
        ...maybeDisabledOverride,
        validationErrors: [],
        durations: [
          {
            diff: {
              months: 12,
              days: -1
            },
            label: '12 months',
            value: '12-months'
          },
          {
            diff: {
              months: 18,
              days: -1
            },
            label: '18 months',
            value: '18-months'
          },
          {
            diff: {
              months: 24,
              days: -1
            },
            label: '24 months',
            value: '24-months'
          },
          {
            diff: 'FOREVER',
            label: 'Open ended',
            value: 'FOREVER'
          } as ForeverDuration
        ]
      }
    }
  }, [cubeContext, navigate, showStartDateSelection])

  return {
    fields,
    modals,
    features
  }
}
