import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getEditQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const { isArchived, status } = prevState.data.common
  if (isArchived) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.CantChangeWhenArchived,
          metadata: {}
        }
      ]
    }
  }

  if ([CubeStatus.QuoteAccepted, CubeStatus.QuoteExecuted].includes(status)) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType:
            status === CubeStatus.QuoteAccepted
              ? DisabledReasonType.CantChangeWhenAccepted
              : DisabledReasonType.CantChangeWhenExecuted,
          metadata: {}
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
