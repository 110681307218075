import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { GreyGrey30, GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'
import { EmptyContent } from 'modules/Products/Product/components/EmptyContent'
import { ProductListPrice } from 'modules/Products/Product/components/ProductListPrice'
import { priceRowFormatter } from 'modules/Products/formatters'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { getUniquePrices } from 'lib/getUniquePrices'
import { useMemo } from 'react'
import { useProductListPrices } from 'modules/Products/Product/components/useProductListPrices'
import { ListPriceArchiveModal } from 'modules/Products/Product/components/ListPriceArchiveModal'

export const ProductListPrices = () => {
  const {
    data: {
      listPrices,
      product: { id: productId }
    },
    functions: { archiveListPrice }
  } = useProductContext()
  const { modals, features } = useProductListPrices({ archiveListPrice })

  const pricesTableData = useMemo(
    () => getUniquePrices(listPrices).map(priceRowFormatter),
    [listPrices]
  )

  return (
    <>
      <Flex flexDirection="column" p={4}>
        <Text {...Lato14Bold} color={GreyGrey80}>
          List prices
        </Text>

        <TableContainer
          borderRadius="lg"
          overflow="hidden"
          border={`1px solid ${GreyGrey30}`}
          mt={4}
          width="100%"
        >
          <Table variant="v2" width="100%">
            <Thead>
              <Tr>
                <Th width="50%">Price</Th>
                <Th>Name</Th>
                <Th>Currency</Th>
                <Th>Frequency</Th>
                <Th>Pricing model</Th>
                <Th>Created</Th>
              </Tr>
            </Thead>
            <Tbody>
              {pricesTableData.length > 0 ? (
                pricesTableData.map(priceRow => (
                  <ProductListPrice
                    key={priceRow.id}
                    priceRow={priceRow}
                    productId={productId}
                    triggerArchiveModal={
                      features.archive.visible && !features.archive.disabled
                        ? features.archive.onClick
                        : () => {}
                    }
                  />
                ))
              ) : (
                <Tr
                  sx={{
                    cursor: 'auto!important',
                    _hover: {
                      bgColor: 'inherit!important'
                    }
                  }}
                >
                  <Td colSpan={4}>
                    <EmptyContent priceType="LIST" />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      {modals.archive.active && (
        <ListPriceArchiveModal
          onClose={modals.archive.onClose}
          onConfirm={modals.archive.onConfirm}
          submitting={modals.archive.submitting}
        />
      )}
    </>
  )
}
