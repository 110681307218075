import { FC, useCallback, useState } from 'react'
import { Box, Checkbox } from '@chakra-ui/react'
import { Form } from 'react-final-form'
import { DateInputField } from 'components/FormFields'
import { required, skip } from '@sequencehq/validation'
import { handleFormResponse } from 'lib/formValidation'
import { UsageEventModel } from '@sequencehq/core-models'
import { usePostApiUsageEventsMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'
import { DrawerForm, useHandleMutationResult } from 'components/Modal'
import { EventPropertyList } from 'components/FormComponents'
import arrayMutators from 'final-form-arrays'
import { Blocker } from 'components/Form'
import { UsageEventTypeInput } from 'components/CreateUsageMetric/UsageEventTypeInput'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { CustomerAliasComboInput } from 'components/FormComponents/CustomerAliasComboInput'
import { format } from '@sequencehq/utils/dates'

export type CreateUsageEventFormMutation = ExtractMutationParams<
  typeof usePostApiUsageEventsMutation
>

type FormValues = Omit<UsageEventModel, 'eventProperties'> & {
  eventPropertyList: { name: string; value: string }[]
  customerId: string
}

type CreateUsageEventFormProps = {
  formContainer: typeof DrawerForm
  onSuccess: (usageEvent: UsageEventModel) => void
  handleCancel: () => void
}

type MutationParams = ExtractMutationParams<
  typeof usePostApiUsageEventsMutation
>

export const CreateUsageEventForm: FC<CreateUsageEventFormProps> = ({
  formContainer: FormContainer,
  onSuccess,
  handleCancel
}) => {
  const [withAlias, setWithAlias] = useState<boolean>(false)
  const [submitForm, result] = usePostApiUsageEventsMutation()

  useHandleMutationResult<MutationParams>({
    result,
    successMessage: useCallback(({ eventType }: UsageEventModel) => {
      return `New usage event '${eventType}' added`
    }, []),
    onSuccess
  })

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators
      }}
      initialValues={{
        eventPropertyList: [{ name: '', value: '' }]
      }}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          usageEventRequest: {
            ...values,
            eventTimestamp: `${format(
              values.eventTimestamp as unknown as Date,
              'yyyy-MM-dd'
            )}T00:00:00Z`,
            customerAlias: withAlias ? values.customerAlias : values.customerId,
            eventProperties: values.eventPropertyList.reduce(
              (acc, { name, value }) => {
                if (!name && !value) {
                  return acc
                }

                return { ...acc, [name]: value.trim() }
              },
              {} as Record<string, unknown>
            )
          }
        })

        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={formProps => (
        <FormContainer
          {...formProps}
          title="Add test usage event"
          submitLabel="Add test usage event"
          handleCancel={handleCancel}
        >
          <Blocker
            name="usageEvent"
            dirty={formProps.dirty}
            submitting={formProps.submitting}
            modalTitle="Cancel usage event editing"
            message="Progress you made so far will not be saved."
            submitTitle="Yes, Cancel"
            cancelTitle="Keep editing"
          />

          <CustomerComboInputComplete
            fieldName="customerId"
            disabled={withAlias}
            validate={withAlias ? skip : required}
          />
          <Box height={3} />
          <Checkbox
            checked={withAlias}
            onChange={event => {
              setWithAlias(event.target.checked)
            }}
          >
            Use customer alias instead
          </Checkbox>
          {withAlias ? (
            <>
              <Box height={4} />
              <CustomerAliasComboInput fieldName="customerAlias" />
            </>
          ) : null}
          <Box height={4} />
          <DateInputField
            fieldName="eventTimestamp"
            fieldLabel="Event timestamp"
            validate={required}
            isSelectable={() => true}
          />
          <Box height={4} />

          <UsageEventTypeInput fieldName="eventType" />

          <Box height={4} />

          <EventPropertyList
            fieldName="eventPropertyList"
            addPropertyLabel="Add another property"
            preventDuplicateSuggestions={true}
            useMatcher={false}
          />
        </FormContainer>
      )}
    />
  )
}
