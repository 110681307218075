import {
  AvailableServiceTypes,
  Integration,
  IntegrationConfig
} from 'modules/Integrations/domain/integrations.domain.types'
import { salesforceIntegrationConfig } from './salesforce/salesforce.integration.config'
import { hubSpotIntegrationConfig } from './hubSpot/hubspot.integration.config'
import { quickBooksIntegrationConfig } from './quickBooks/quickbooks.integration.config'
import { stripeIntegrationConfig } from './stripe/stripe.integration.config'
import { xeroIntegrationConfig } from './xero/xero.integration.config'
import { googleSheetsIntegrationConfig } from './googleSheets/googleSheets.integration.config'
import { amazonRedshiftIntegrationConfig } from './amazonRedshift/amazonRedshift.integration.config'
import { snowflakeIntegrationConfig } from 'modules/Integrations/integrationsConfig/snowflake/snowflake.integration.config'
import { bigQueryIntegrationConfig } from 'modules/Integrations/integrationsConfig/bigQuery/bigQuery.integration.config'
import { slackIntegrationConfig } from 'modules/Integrations/integrationsConfig/slack/slack.integration.config'
import { attioIntegrationConfig } from 'modules/Integrations/integrationsConfig/attio/attio.integration.config'
import { anrokIntegrationConfig } from 'modules/Integrations/integrationsConfig/anrok/anrok.integration.config'
import { avalaraIntegrationConfig } from 'modules/Integrations/integrationsConfig/avalara/avalara.integration.config'
import { netsuiteIntegrationConfig } from 'modules/Integrations/integrationsConfig/netsuite/netsuite.integration.config'
import { goCardlessIntegrationConfig } from 'modules/Integrations/integrationsConfig/goCardless/goCardless.integration.config'
import { dropboxSignIntegrationConfig } from './dropboxSign/dropboxSign.integration.config'
import { equalsIntegrationConfig } from 'Integrations/integrationsConfig/equals/equals.integration.config'

export type IntegrationsConfig = Partial<
  Record<Integration['id'], IntegrationConfig>
>

export const INTEGRATIONS_CONFIG: IntegrationsConfig = {
  QuickBooks_Online: quickBooksIntegrationConfig,
  Google_Sheets: googleSheetsIntegrationConfig,
  HubSpot: hubSpotIntegrationConfig,
  Xero: xeroIntegrationConfig,
  Stripe: stripeIntegrationConfig,
  Salesforce: salesforceIntegrationConfig,
  Amazon_Redshift: amazonRedshiftIntegrationConfig,
  Snowflake: snowflakeIntegrationConfig,
  Google_BigQuery: bigQueryIntegrationConfig,
  Slack: slackIntegrationConfig,
  NetSuite: netsuiteIntegrationConfig,
  Attio: attioIntegrationConfig,
  Anrok: anrokIntegrationConfig,
  Avalara: avalaraIntegrationConfig,
  Go_Cardless: goCardlessIntegrationConfig,
  Dropbox_Sign: dropboxSignIntegrationConfig,
  Equals: equalsIntegrationConfig
}

/**
 * This configuration determines the order we will display integrations.
 */
export interface IntegrationOrderGroup {
  group: AvailableServiceTypes | 'E-signatures'
  order: Array<Integration['id']>
}

export const INTEGRATIONS_ORDER: Array<IntegrationOrderGroup> = [
  {
    group: 'Accounting',
    order: ['Xero', 'QuickBooks_Online', 'NetSuite']
  },
  {
    group: 'Reporting',
    order: ['Equals']
  },
  {
    group: 'CRM',
    order: ['HubSpot', 'Salesforce', 'Attio']
  },
  {
    group: 'Payments',
    order: ['Stripe', 'Go_Cardless']
  },
  {
    group: 'E-signatures',
    order: ['Dropbox_Sign']
  },
  {
    group: 'Tax',
    order: ['Anrok', 'Avalara']
  },
  {
    group: 'Data',
    order: ['Google_BigQuery', 'Snowflake', 'Amazon_Redshift', 'Google_Sheets']
  },
  {
    group: 'Communication',
    order: ['Slack']
  }
]
