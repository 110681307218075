import { format } from '@sequencehq/utils/dates'

export const parseDateFromApi = (apiDate: string): Date | undefined => {
  if (!apiDate) {
    return
  }

  return new Date(apiDate)
}

export const outputDateToApi = (date: Date | undefined): string => {
  if (!date) {
    return ''
  }

  return format(date, 'yyyy-MM-dd')
}

export const apiDatesAdapters = {
  fromApi: parseDateFromApi,
  toApi: outputDateToApi
}
