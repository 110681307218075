import { Flex, Text } from '@chakra-ui/react'
import {
  DeleteApiKeyFormMutation,
  DeleteApiKeyFormProps
} from 'components/DeleteApiKey/DeleteApiKeyModal'
import { MutationFormProps } from 'components/Form/types'

import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

export const DeleteApiKeyForm: FC<
  MutationFormProps<DeleteApiKeyFormMutation> & DeleteApiKeyFormProps
> = ({ id, name, formId, submitForm }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{}}
      onSubmit={async () => {
        await submitForm({
          id: id
        })
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            <Text>Delete API key {name}?</Text>
          </Flex>
        )
      }}
    />
  )
}
