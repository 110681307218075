import { FC } from 'react'

interface ScheduleIconProps {
  width?: number
  height?: number
}

export const ScheduleIcon: FC<ScheduleIconProps> = ({
  width = 20,
  height = 20
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.0002" cy="10" r="10" fill="#F6E9FB" />
    <path
      d="M8.02867 5.6466C7.79536 5.50834 7.50024 5.6765 7.50024 5.9477V14.0525C7.50024 14.3237 7.79536 14.4919 8.02867 14.3537L14.8672 10.3012C15.0959 10.1657 15.0959 9.83457 14.8672 9.69901L8.02867 5.6466Z"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
