import { useCallback } from 'react'

import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'
import { useToast } from '@chakra-ui/react'
import { Toast } from '@sequencehq/core-components'

export const useCreateUsageMetrics = () => {
  const toast = useToast()

  return useCallback(
    async (body: DashboardApi20240730.PostUsageMetric.PostUsageMetricBody) => {
      return await dashboard20240730Client
        .postUsageMetric({
          body
        })
        .then(result => {
          if (result?.error) {
            return null
          }

          const usageMetric = result.data

          if (usageMetric) {
            toast({
              id: `success-${usageMetric.id}`,
              position: 'bottom',
              duration: 6000,
              render: () => (
                <Toast
                  type="success"
                  title={`Usage metric '${usageMetric.name}' added`}
                  isClosable={true}
                />
              )
            })
          }

          return usageMetric
        })
    },
    [toast]
  )
}
