import { useNotifications } from 'lib/hooks/useNotifications'
import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import {
  ConfigurationManagementProps,
  ResolvedIntegration
} from 'modules/Integrations/domain'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

type UseIntegrationManagement = () => {
  integration: ResolvedIntegration | null
  loading: boolean
  reconnect: {
    show: boolean
    onClick: () => Promise<void>
    disabled: boolean
    reconnecting: boolean
    label: string
  }
  contentProps: ConfigurationManagementProps | null
}

export const useIntegrationManagement: UseIntegrationManagement = () => {
  const { integrationId } = useParams<{ integrationId: string }>()
  const { displayNotification } = useNotifications()
  const ctx = useIntegrationsDomainContext()
  const [reconnecting, setReconnecting] = useState(false)

  const integration: ResolvedIntegration | null = useMemo(() => {
    if (!integrationId) {
      return null
    }

    return (
      ctx.queries.resolvedIntegrations[
        integrationId as ResolvedIntegration['id']
      ] ?? null
    )
  }, [ctx.queries.resolvedIntegrations, integrationId])

  const reconnect = useCallback(async () => {
    if (!integration) {
      return
    }

    setReconnecting(true)
    const { success } = await ctx.mutators.external.out.connect(integration?.id)
    setReconnecting(false)

    if (success) {
      displayNotification('Reconnected successfully', {
        type: 'success'
      })
      return
    }

    displayNotification('Failed to reconnect', {
      type: 'error'
    })
  }, [ctx, integration, displayNotification])

  const loading = !ctx.queries.rawData.editor.loaded || !integration

  /**
   * These props serve as the adapter for the configuration management
   * component that will be rendered. This interface is the only 'legal'
   * one that the configuration management component can interact with
   * respect to the integrations domain. i.e. we should not see
   * `useIntegrationsDomainContext` used in the configuration management components.
   */
  const contentProps = useMemo(() => {
    if (loading) {
      return null
    }

    return {
      integration,
      showValidationErrors:
        ctx.queries.rawData.editor.configuration.showValidationErrors,
      updateConfiguration: (newConfiguration: Record<string, any>) => {
        ctx.mutators.updateEditor({
          configuration: {
            dataToSave: newConfiguration
          }
        })
      },
      updateValidationState: (isValid: boolean) => {
        ctx.mutators.updateEditor({
          configuration: {
            valid: isValid
          }
        })
      }
    }
  }, [
    ctx.mutators,
    integration,
    ctx.queries.rawData.editor.configuration,
    loading
  ])

  return {
    loading,
    reconnect: {
      show: integration?.authFailure ?? false,
      disabled: reconnecting,
      onClick: reconnect,
      reconnecting,
      label: reconnecting ? 'Reconnecting...' : 'Reconnect'
    },
    integration,
    contentProps
  }
}
