import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import BanknotesIcon from '@heroicons/react/24/outline/BanknotesIcon'
import {
  AddressModel,
  CanadianDestinationModel,
  CanadianInternationalDestinationModel,
  Currency,
  SortCodeAccountNumberModel,
  UsAchDestinationModel,
  UsWireDestinationModel,
  toMoney
} from '@sequencehq/core-models'
import {
  GreyGrey10,
  GreyGrey70,
  Lato12Bold,
  Lato16Bold,
  Lato16Regular,
  Lato24Bold
} from '@sequencehq/design-tokens'
import {
  CanadianInternationalPaymentDetails,
  CanadianPaymentDetails,
  IbanPaymentDetails,
  UkPaymentDetails,
  UsAchPaymentDetails,
  UsWirePaymentDetails
} from '@sequencehq/invoice-content'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

interface BankTransferParams {
  invoiceNumber: string
  total: string
  currency: Currency
  ukAccountDetails?: SortCodeAccountNumberModel
  iban?: string
  usAchDestination?: UsAchDestinationModel
  usWireDestination?: UsWireDestinationModel
  caBankAccountDestination?: CanadianDestinationModel
  canadianInternationalDestination?: CanadianInternationalDestinationModel
  merchantLegalCompanyName: string
  merchantAddress: AddressModel
  includeBeneficiaryAddressInPaymentDetails: boolean
}

const BankTransfer: FC<BankTransferParams> = ({
  invoiceNumber,
  total,
  currency,
  ukAccountDetails,
  iban,
  usAchDestination,
  usWireDestination,
  caBankAccountDestination,
  canadianInternationalDestination,
  merchantLegalCompanyName,
  merchantAddress,
  includeBeneficiaryAddressInPaymentDetails
}) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Box margin="auto" width="380px">
        <Box height="192px" />
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          textAlign="center"
        >
          <Icon
            as={BanknotesIcon}
            color="gray.50"
            height="36px"
            width="36px"
            strokeWidth="1px"
          />
          <Text {...Lato24Bold}>Invoice {invoiceNumber}</Text>
          <Box height="18px" />
          <Flex flexDirection="row">
            <Text {...Lato16Regular}>Total due</Text>
            <Box width="6px"></Box>
            <Text {...Lato16Bold}>
              {toMoney({
                currency: currency,
                value: total
              })}
            </Text>
          </Flex>
        </Flex>
        <Box height="36px" />
        <Flex
          justifyContent="center"
          flexDirection="column"
          backgroundColor={GreyGrey10}
          borderRadius="12px 12px 12px 12px"
          padding="16px"
        >
          <Text
            {...Lato12Bold}
            color={GreyGrey70}
            textTransform="uppercase"
            marginBottom="8px"
          >
            Bank details
          </Text>

          {ukAccountDetails && (
            <UkPaymentDetails
              ukBankDetails={ukAccountDetails}
              accountName={merchantLegalCompanyName}
              merchantAddress={merchantAddress}
              includeBeneficiaryAddress={
                includeBeneficiaryAddressInPaymentDetails
              }
            ></UkPaymentDetails>
          )}
          {iban && (
            <IbanPaymentDetails
              iban={iban}
              accountName={merchantLegalCompanyName}
              merchantAddress={merchantAddress}
              includeBeneficiaryAddress={
                includeBeneficiaryAddressInPaymentDetails
              }
            ></IbanPaymentDetails>
          )}

          {usAchDestination && (
            <UsAchPaymentDetails
              achDetails={usAchDestination}
              merchantAddress={merchantAddress}
              includeBeneficiaryAddress={
                includeBeneficiaryAddressInPaymentDetails
              }
            ></UsAchPaymentDetails>
          )}
          {usWireDestination && (
            <UsWirePaymentDetails
              wireDetails={usWireDestination}
              merchantAddress={merchantAddress}
              includeBeneficiaryAddress={
                includeBeneficiaryAddressInPaymentDetails
              }
            ></UsWirePaymentDetails>
          )}
          {caBankAccountDestination && (
            <>
              <CanadianPaymentDetails
                canadianBankDetails={caBankAccountDestination}
                accountName={merchantLegalCompanyName}
                merchantAddress={merchantAddress}
                includeBeneficiaryAddress={
                  includeBeneficiaryAddressInPaymentDetails
                }
              ></CanadianPaymentDetails>
            </>
          )}
          {canadianInternationalDestination && (
            <>
              <CanadianInternationalPaymentDetails
                canadianInternationalDestination={
                  canadianInternationalDestination
                }
                merchantAddress={merchantAddress}
                includeBeneficiaryAddress={
                  includeBeneficiaryAddressInPaymentDetails
                }
              ></CanadianInternationalPaymentDetails>
            </>
          )}
        </Flex>
        <Box height="20px"></Box>
      </Box>
    </Flex>
  )
}

export const BankTransferParamsHandler: FC = () => {
  const [searchParams] = useSearchParams()
  const invoiceDetails: BankTransferParams = JSON.parse(
    atob(searchParams.get('invoiceDetails') || '')
  )
  return (
    <BankTransfer
      invoiceNumber={invoiceDetails.invoiceNumber}
      total={invoiceDetails.total}
      currency={invoiceDetails.currency}
      ukAccountDetails={invoiceDetails.ukAccountDetails}
      iban={invoiceDetails.iban}
      usAchDestination={invoiceDetails.usAchDestination}
      usWireDestination={invoiceDetails.usWireDestination}
      caBankAccountDestination={invoiceDetails.caBankAccountDestination}
      canadianInternationalDestination={
        invoiceDetails.canadianInternationalDestination
      }
      merchantAddress={invoiceDetails.merchantAddress}
      merchantLegalCompanyName={invoiceDetails.merchantLegalCompanyName}
      includeBeneficiaryAddressInPaymentDetails={
        invoiceDetails.includeBeneficiaryAddressInPaymentDetails
      }
    />
  )
}
