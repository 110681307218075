import {
  CompoundInputFieldNew,
  TagInput,
  TagInputProps
} from '@sequencehq/forms'
import { useGetUniqueUsageEventTypesQuery } from 'features/api'
import { FC, useState } from 'react'

type TagValue = {
  id: string
  value: string
}

type UsageEventTypeInputProps = {
  fieldName: string
}

export const UsageEventTypeInput: FC<UsageEventTypeInputProps> = ({
  fieldName
}) => {
  const [query, setQuery] = useState('')

  const { data } = useGetUniqueUsageEventTypesQuery({ search: query })

  const eventTypes = data?.value()?.items.map(v => ({ id: v, value: v })) ?? []

  return (
    <CompoundInputFieldNew<TagInputProps<TagValue>>
      fieldName={fieldName}
      fieldLabel="Event type"
      Component={TagInput}
      placeholder="Select event type"
      items={eventTypes}
      query={query}
      setQuery={setQuery}
      itemToContent={item => ({ title: item.value })}
    />
  )
}
