import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import {
  UpdateInvoiceStatusFormProps,
  VoidInvoiceFormMutation
} from 'components/UpdateInvoiceStatus/types'
import VoidInvoiceForm from 'components/UpdateInvoiceStatus/VoidInvoiceForm'
import { usePostInvoicesByIdVoidMutation } from 'features/api'

const VoidInvoiceModal = createFormModal<
  VoidInvoiceFormMutation,
  UpdateInvoiceStatusFormProps
>({
  FormComponent: VoidInvoiceForm,
  useMutation: usePostInvoicesByIdVoidMutation,
  modalTitle: 'Void Invoice',
  successMessage: 'Invoice marked as void',
  submitTitle: 'Void invoice',
  formId: 'voidInvoiceForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Invoice ${
        response.invoiceNumber ? response.invoiceNumber : ''
      } marked as void`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default VoidInvoiceModal
