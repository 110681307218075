import { Button, Flex, Text } from '@chakra-ui/react'
import { Lato13Regular } from '@sequencehq/design-tokens'
import PlusIcon from '@heroicons/react/16/solid/PlusIcon'

interface LineItemsEmptyStateProps {
  onLineItemAdd: () => void
}

export const LineItemsEmptyState = ({
  onLineItemAdd
}: LineItemsEmptyStateProps) => {
  return (
    <Flex
      mt={2}
      bg="gray.10"
      px={4}
      py={5}
      flexDirection="column"
      alignItems="center"
      gap={3}
      rounded="lg"
    >
      <Text color="gray.70" {...Lato13Regular} lineHeight="20px">
        No line items in this group. Click on "Add line item" to add prices or
        discounts.
      </Text>
      <Button
        data-testid="invoiceEditor.addLineItem"
        onClick={onLineItemAdd}
        leftIcon={<PlusIcon height="16px" width="16px" />}
        variant="secondary2"
        size="sm"
      >
        Add line item
      </Button>
    </Flex>
  )
}
