import { Text, Button, Center, Box } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import {
  GreyGrey30,
  GreyGrey80,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'

import { SubNavLink as SubNavLinkProps } from 'components/NavigationV3/types'

type Props = SubNavLinkProps & {
  subLinks: SubNavLinkProps[]
  index: number
  pathname: string
}

export const SubNavLink = ({
  href,
  label,
  enabled = true,
  subLinks,
  index,
  pathname
}: Props) => {
  if (!enabled) {
    return null
  }

  const isActive: boolean = pathname.startsWith(href)

  const isFirst: boolean = index === 0
  const isLast: boolean = index === subLinks.length - 1
  const isMiddle: boolean = !isFirst && !isLast

  const linkTextStyle = isActive ? Lato13Bold : Lato13Regular

  return (
    <Button
      as={Link}
      to={href}
      variant="unstyled"
      sx={{
        display: 'flex',
        background: 'transparent',
        width: '100%',
        height: '32px',
        justifyContent: 'flex-start',
        color: GreyGrey80
      }}
      _hover={{}}
    >
      <Center height="32px" width="32px">
        {isFirst && (
          <Box
            position="absolute"
            height="50%"
            top="50%"
            width="1px"
            bg={GreyGrey30}
          />
        )}

        {isMiddle && (
          <Box
            position="absolute"
            top="0"
            height="100%"
            width="1px"
            bg={GreyGrey30}
          />
        )}

        {isLast && (
          <Box
            position="absolute"
            height="50%"
            top="0"
            width="1px"
            bg={GreyGrey30}
          />
        )}

        <Box
          position="relative"
          height="16px"
          width="1px"
          bg={isActive ? GreyGrey90 : GreyGrey30}
        />
      </Center>
      <Text {...linkTextStyle} color="inherit">
        {label}
      </Text>
    </Button>
  )
}
