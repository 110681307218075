import { FC } from 'react'

interface CustomerIconProps {
  width?: number
  height?: number
}

export const CustomerIcon: FC<CustomerIconProps> = ({
  width = 20,
  height = 20
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.0002" cy="10" r="10" fill="#FFE9EC" />
    <path
      d="M12.3137 14.1669V13.241C12.3137 12.7499 12.1186 12.2788 11.7713 11.9316C11.424 11.5843 10.9529 11.3892 10.4618 11.3892H6.7581C6.26696 11.3892 5.79593 11.5843 5.44864 11.9316C5.10136 12.2788 4.90625 12.7499 4.90625 13.241V14.1669"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.61015 9.5372C9.6329 9.5372 10.462 8.7081 10.462 7.68535C10.462 6.6626 9.6329 5.8335 8.61015 5.8335C7.5874 5.8335 6.7583 6.6626 6.7583 7.68535C6.7583 8.7081 7.5874 9.5372 8.61015 9.5372Z"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0923 14.1668V13.2409C15.092 12.8306 14.9554 12.432 14.704 12.1077C14.4526 11.7834 14.1007 11.5518 13.7034 11.4492"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8518 5.89355C12.2501 5.99555 12.6032 6.22721 12.8553 6.55203C13.1075 6.87685 13.2443 7.27635 13.2443 7.68754C13.2443 8.09873 13.1075 8.49822 12.8553 8.82304C12.6032 9.14786 12.2501 9.37953 11.8518 9.48152"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
