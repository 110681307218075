export const addOrRemoveValue = (
  previousValues: Record<string, string>,
  key: string,
  value: string
): Record<string, string> => {
  if (!value) {
    const { [key]: _, ...rest } = previousValues
    return rest
  }

  return {
    ...previousValues,
    [key]: value
  }
}

const blobObjectUrlRegex =
  /^blob:(?<origin>[\w\+]+:\/\/(?=.{1,254}(?::|$))(?:(?!\d|-)(?![a-z0-9\-]{1,62}-(?:\.|:|$))[a-z0-9\-]{1,63}\b(?!\.$)\.?)+(:\d+)?)\/(?<uuid>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/

export const isBlobOrObjectUrl = (url: unknown) => {
  return typeof url === 'string' && blobObjectUrlRegex.test(url)
}
