import { useForm } from '@sequencehq/utils'
import { isEmail, required } from '@sequencehq/validation'
import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

type UseQuoteSettingsFormFields = {
  name?: string
  email?: string
  isInternalSignerEnabled?: boolean
  isSignaturesEnabled: boolean
}

type UseQuoteSettingsForm = {
  fieldValues: UseQuoteSettingsFormFields
  onSubmit: (values: UseQuoteSettingsFormFields) => Promise<void>
}

export const useQuoteSettingsForm = ({
  fieldValues,
  onSubmit
}: UseQuoteSettingsForm) => {
  const flags = useFlags()
  const [isDirty, setIsDirty] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { fields, queries } = useForm({
    value: fieldValues,
    showValidationErrors: !isValid,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required],
        disabled: ({ formData }) => {
          return (
            !formData.isInternalSignerEnabled || !formData.isSignaturesEnabled
          )
        }
      },
      {
        property: 'email',
        validation: [isEmail, required],
        disabled: ({ formData }) => {
          return (
            !formData.isInternalSignerEnabled || !formData.isSignaturesEnabled
          )
        }
      },
      {
        property: 'isSignaturesEnabled',
        validation: []
      },
      {
        property: 'isInternalSignerEnabled',
        validation: []
      }
    ],
    onChange: () => {
      setIsDirty(true)
    }
  })

  const submit = async () => {
    if (!queries.isValid) {
      setIsValid(false)
      return
    }

    setIsSubmitting(true)

    const name = !fields.name.disabled ? fields.name.value : undefined
    const email = !fields.email.disabled ? fields.email.value : undefined

    await onSubmit({
      name,
      email,
      isSignaturesEnabled: fields.isSignaturesEnabled.value
    })

    setIsSubmitting(false)
    setIsDirty(false)
    setIsValid(true)

    return
  }

  const formFields = {
    ...fields,
    name: {
      ...fields.name,
      visible: fields.isInternalSignerEnabled.value
    },
    email: {
      ...fields.email,
      visible: fields.isInternalSignerEnabled.value
    },
    isInternalSignerEnabled: {
      ...fields.isInternalSignerEnabled,
      visible: fields.isSignaturesEnabled.value && flags.useSigners,
      disabled: !fields.isSignaturesEnabled.value
    }
  }

  return {
    fieldsConfig: formFields,
    submit,
    isDirty,
    isSubmitting
  }
}
