import { FC, ReactNode } from 'react'
import {
  GreyGrey20,
  GreyGrey60,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Flex, Text } from '@chakra-ui/react'
import { TextOverflow } from '@sequencehq/core-components'
import { formatFileSizeToHumanReadable } from '@sequencehq/formatters'

export const AttachmentUI: FC<{
  name: string
  fileSizeBytes: number
  icon: ReactNode
  controls: ReactNode
  onClick?: () => void
}> = ({ name, fileSizeBytes, icon, controls, onClick }) => {
  return (
    <Flex
      gap={2}
      padding={2}
      mx={-2}
      borderRadius={4}
      alignItems="center"
      role="group"
      _hover={{ background: GreyGrey20 }}
      _focusWithin={{ background: GreyGrey20 }}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      maxHeight="36px"
    >
      {icon}
      <Text
        {...Lato13Regular}
        fontWeight="semibold"
        color={GreyGrey80}
        flex={1}
        overflow="hidden"
      >
        <TextOverflow splitPercentage={80}>{name}</TextOverflow>
      </Text>
      <Text {...Lato13Regular} color={GreyGrey60}>
        {formatFileSizeToHumanReadable(fileSizeBytes)}
      </Text>
      {controls}
    </Flex>
  )
}
