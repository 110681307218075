import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import {
  lineItemGroupHeaderHeight,
  spacing,
  InvoiceLineItemsSubTotal
} from '@sequencehq/invoice-content'
import InvoiceLineItemTableSelect from 'components/InvoiceLineItemSelect/InvoiceLineItemTableSelect'
import {
  Currency,
  LineItemGroupModel,
  LineItemModel
} from '@sequencehq/core-models'
import { FC } from 'react'
// import SelectableSection, {
//   Section
// } from 'components/InvoiceLineItemSelect/SelectableSection'

interface InvoiceLineItemGroupSectionSelectProps {
  currency: Currency
  lineItems: LineItemModel[]
  lineItemGroup: LineItemGroupModel
  selectLineItem: (id: string) => void
  unSelectLineItem: (id: string) => void
  disabledLineItems: string[]
}

const InvoiceLineItemGroupSectionSelect: FC<
  InvoiceLineItemGroupSectionSelectProps
> = ({
  currency,
  lineItems,
  lineItemGroup,
  selectLineItem,
  unSelectLineItem,
  disabledLineItems
}) => {
  return (
    <>
      {/* we need to look at all the cases by selecting entire group, 
        will discuss with UX team and then will add it */}
      {/* <SelectableSection clickSection={() => {}}> */}
      <Box
        backgroundColor="gray.20"
        h={`${lineItemGroupHeaderHeight}px`}
        width="100%"
      >
        <Flex flexDirection="column">
          <Box h="7px"></Box>
          <Flex>
            <Box w="16px"></Box>
            <Text color="gray.100" fontSize="14px" fontWeight="700">
              {lineItemGroup.title}
            </Text>
            <Spacer></Spacer>
          </Flex>
          <Box h="7px"></Box>
        </Flex>
      </Box>
      {/* </SelectableSection> */}
      <InvoiceLineItemTableSelect
        currency={currency}
        lineItems={lineItems}
        selectLineItem={selectLineItem}
        unSelectLineItem={unSelectLineItem}
        disabledLineItems={disabledLineItems}
      ></InvoiceLineItemTableSelect>
      <Box h="8px"></Box>
      <Flex direction={'row'}>
        <Box w="16px"></Box>
        <InvoiceLineItemsSubTotal
          currency={currency}
          lineItemGroup={lineItemGroup}
        ></InvoiceLineItemsSubTotal>
      </Flex>

      <Box h={`${spacing}px`}></Box>
    </>
  )
}

export default InvoiceLineItemGroupSectionSelect
