import { Text, Center } from '@chakra-ui/react'
import { GreyGrey80, Lato12Bold } from '@sequencehq/design-tokens'
import { useMemo } from 'react'

type Props = {
  name: string
}

export const Avatar = ({ name }: Props) => {
  const initials: string = useMemo(() => {
    const trimmedName = name.trim()

    if (!trimmedName) {
      return ''
    }

    const tokens = trimmedName.split(' ')

    if (tokens.length === 1) {
      return tokens[0].substring(0, 2)
    } else {
      return tokens
        .map(token => token[0])
        .slice(0, 2)
        .join('')
    }
  }, [name])

  return (
    <Center
      background={GreyGrey80}
      height="20px"
      width="20px"
      borderRadius="6px"
      flexShrink="0"
    >
      <Text {...Lato12Bold} fontSize="11px" color="#ffffff">
        {initials}
      </Text>
    </Center>
  )
}
