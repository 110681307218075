import { Box, Flex } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/dist/utils'
import {
  DatePickerField,
  SimpleAsyncModal,
  Skeleton,
  TextInputField
} from '@sequencehq/core-components'
import { toCurrencySymbol } from '@sequencehq/core-models'
import {
  GreyGrey60,
  IndigoIndigo10,
  IndigoIndigo50,
  Lato12Bold,
  Lato12Regular
} from '@sequencehq/design-tokens'
import { enforceMinimumPrecision, toMoney, useForm } from '@sequencehq/utils'
import { greaterThan, lessThanEqualTo, required } from '@sequencehq/validation'
import PageError from 'components/ErrorPages/PageError'
import { format } from '@sequencehq/utils/dates'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ReactNode, useMemo, useState } from 'react'
import { ProgressDonut } from 'RevenueRecognition/view/common/ProgressDonut'

/**
 * We use the underlying API data to set the form values, which will
 * also respond to changes in the subscribed data too. However, in this
 * case, we would not expect the service side data to differ.
 * @param props
 * @returns
 */
const useRecognizeRevenueModal = (props: {
  defRevScheduleId: string
  onSuccess?: () => void
}) => {
  const { displayNotification } = useNotifications()
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const scheduleQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedule,
    { id: props.defRevScheduleId },
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          balances: {
            total: res.progress.original,
            remaining: res.progress.remaining
          },
          currency: res.progress.currency
        }
      }
    }
  )

  const defaultValues = useMemo(() => {
    return {
      amountToRecognize:
        enforceMinimumPrecision(2)(scheduleQuery.data?.balances.remaining) ??
        '0.00',
      date: new Date(),
      narration: ''
    }
  }, [scheduleQuery.data])

  const form = useForm<{
    amountToRecognize: string
    date: Date
    narration: string
  }>({
    value: defaultValues,
    showValidationErrors: showValidationErrors,
    fieldConfiguration: [
      {
        property: 'amountToRecognize',
        validation: [
          required,
          greaterThan(0),
          lessThanEqualTo(
            parseFloat(scheduleQuery.data?.balances.remaining ?? '')
          )
        ]
      },
      {
        property: 'date',
        validation: [required]
      },
      {
        property: 'narration',
        validation: [required]
      }
    ]
  })

  const reset = () => {
    setShowValidationErrors(false)
    form.mutators.updateFormData(defaultValues)
  }

  const onSubmit = async () => {
    if (scheduleQuery.isPending || !scheduleQuery.data?.currency) {
      return false
    }

    if (!form.queries.isValid) {
      setShowValidationErrors(true)
      return false
    }

    /**
     * Direct calls, without useMutation, offer the same cache busting benefits
     * but in situations like this are just cleaner to use (i.e. you're not setting
     * up a hook just to make this call)!
     */
    const res = await dashboardv99990101Client.postRecognizeMilestoneRevenue({
      scheduleId: props.defRevScheduleId,
      narrative: form.fields.narration.value,
      date: format(form.fields.date.value, 'yyyy-MM-dd'),
      amount: form.fields.amountToRecognize.value
    })

    if (res.error) {
      displayNotification('Could not recognize revenue', {
        type: 'error'
      })
      return false
    }

    displayNotification(
      `Recognized ${toCurrencySymbol(scheduleQuery.data.currency)}${
        form.fields.amountToRecognize.value
      } of revenue`,
      {
        type: 'success'
      }
    )
    reset()
    props.onSuccess?.()
    return true
  }

  if (scheduleQuery.error) {
    throw new Error('Deferred revenue data could not be loaded')
  }

  return {
    currency: scheduleQuery.data?.currency,
    totalRemaining: parseFloat(scheduleQuery.data?.balances.remaining ?? ''),
    remainingAfterRecognition: Math.max(
      parseFloat(scheduleQuery.data?.balances.remaining ?? '') -
        parseFloat(form.fields.amountToRecognize.value ?? '0'),
      0
    ),
    recognizedPercentage: Math.min(
      Math.round(
        (parseFloat(form.fields.amountToRecognize.value ?? '0') /
          parseFloat(scheduleQuery.data?.balances.total ?? '0')) *
          100
      ),
      100
    ),
    form,
    isLoading: scheduleQuery.isPending,
    onSubmit,
    reset
  }
}

export const RecognizeRevenueModal = (props: {
  defRevScheduleId: string
  onSuccess?: () => void
  trigger: ReactNode | ((triggerModal: () => void) => ReactNode)
}) => {
  const {
    form,
    currency,
    isLoading,
    totalRemaining,
    remainingAfterRecognition,
    recognizedPercentage,
    onSubmit,
    reset
  } = useRecognizeRevenueModal({
    defRevScheduleId: props.defRevScheduleId,
    onSuccess: props.onSuccess
  })

  /**
   * Due to how this modal component is set up, the external state is not
   * unmounted when the modal is closed. Therefore, we need to
   * reset it.
   *
   * We still benefit from the unmountOnClose for the underlying
   * form, however! It just means we have to pipe the 'canSubmit'
   * state back to here using the 'onSubmitAvailabilityChange' prop.
   *
   * One slight issue with this setup is that if we do want to display
   * errors from the API in the future, we need to pass them down a layer,
   * too. It's ultimately all a bit of a tradeoff and how much exposure
   * to 'leftover' state you wish to have. Given that dud state is a cause
   * of many issues, I generally prefer to remove it where possible.
   */

  return (
    <SimpleAsyncModal
      title="Recognize revenue"
      trigger={props.trigger}
      cancelButtonText="Cancel"
      submitButtonText="Recognize revenue"
      submittingButtonText="Submitting..."
      onSubmit={onSubmit}
      onCancel={reset}
      data-testid="revrec.milestoneRecognition.modal"
    >
      <ErrorBoundary fallback={<PageError />}>
        {isLoading ? (
          <Flex flexDirection="column" gap="16px">
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </Flex>
        ) : (
          <Flex flexDirection="column" gap="16px">
            <TextInputField
              height="32px"
              label="Narration"
              {...form.fields.narration}
              styles={{ wrapper: { margin: '0' } }}
              placeholder="Enter brief summary"
              onChange={e => form.fields.narration.onChange(e.target.value)}
              data-testid="revrec.milestoneRecognition.modal.narration"
            />
            <DatePickerField
              label="Recognition date"
              {...form.fields.date}
              width="100%"
              onChange={date => date && form.fields.date.onChange(date)}
              styles={{ wrapper: { margin: '0' } }}
              data-testid="revrec.milestoneRecognition.modal.date"
            />
            <Flex flexDirection="column" gap="8px">
              <TextInputField
                data-testid="revrec.milestoneRecognition.modal.amountToRecognize"
                prefix={currency ? toCurrencySymbol(currency) : ''}
                label="Amount to recognize"
                styles={{ wrapper: { margin: '0' } }}
                {...form.fields.amountToRecognize}
                onKeyDown={e => {
                  if (e.key === '-') {
                    e.preventDefault()
                  }
                }}
                onChange={e => {
                  form.fields.amountToRecognize.onChange(e.target.value)
                }}
                onBlur={e => {
                  form.fields.amountToRecognize.onChange(
                    enforceMinimumPrecision(2)(
                      Math.min(parseFloat(e.target.value), totalRemaining)
                    )
                  )
                }}
                suffix={
                  <Flex
                    borderRadius="6px"
                    backgroundColor={IndigoIndigo10}
                    color={IndigoIndigo50}
                    {...Lato12Bold}
                    height="24px"
                    padding="0 6px"
                    alignItems="center"
                    gap="4px"
                  >
                    <ProgressDonut
                      animation={{ delayBeforeStart: 150 }}
                      percentage={
                        Number.isNaN(recognizedPercentage)
                          ? 0
                          : recognizedPercentage
                      }
                      size="14px"
                    />
                    {Number.isNaN(recognizedPercentage)
                      ? 0
                      : recognizedPercentage}
                    %
                  </Flex>
                }
              />
              {currency && !Number.isNaN(totalRemaining) && (
                <Box color={GreyGrey60} {...Lato12Regular}>
                  Remaining:{' '}
                  {toMoney({
                    value: (Number.isNaN(remainingAfterRecognition)
                      ? totalRemaining
                      : remainingAfterRecognition
                    ).toString(),
                    currency
                  })}
                </Box>
              )}
            </Flex>
          </Flex>
        )}
      </ErrorBoundary>
    </SimpleAsyncModal>
  )
}
