import { BankTransferParamsHandler } from 'modules/PaymentsApp/pages/BankTransfer'
import { Cancelled } from 'modules/PaymentsApp/pages/Cancelled'
import { Error } from 'modules/PaymentsApp/pages/Error'
import { NotFound } from 'modules/PaymentsApp/pages/NotFound'
import { NotReady } from 'modules/PaymentsApp/pages/NotReady'
import { Success } from 'modules/PaymentsApp/pages/Success'
import { Unavailable } from 'modules/PaymentsApp/pages/Unavailable'
import { Outlet, RouteObject, createBrowserRouter } from 'react-router-dom'

const AppContainer = () => (
  <>
    <Outlet />
  </>
)

export const payRoutes: RouteObject[] = [
  {
    path: '/invoice/:id/*',
    element: <AppContainer />,
    children: [
      {
        path: 'cancel',
        element: <Cancelled />
      },
      { path: 'not-found', element: <NotFound /> },
      { path: 'success', element: <Success /> },
      { path: 'unavailable', element: <Unavailable /> },
      { path: 'not-ready', element: <NotReady /> },
      { path: 'error', element: <Error /> },
      { path: 'bank-transfer', element: <BankTransferParamsHandler /> }
    ]
  }
]

export const payRouter = createBrowserRouter(payRoutes, { basename: '/pay' })
