import { TaxRate } from 'Settings/domain/taxRates.types'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import {
  dashboardv99990101Client,
  type Dashboardv99990101Api
} from '@sequencehq/api/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { useNotifications } from 'lib/hooks/useNotifications'

type CreateTaxCategoryBody =
  Dashboardv99990101Api.PostTaxCategory.CreateTaxCategoryBody

export type CreateTaxCategoryInput = Pick<
  CreateTaxCategoryBody,
  'name' | 'description'
> & {
  countries: Partial<Record<TaxRate['country'], TaxRate['amount']>> | undefined
}

export function useCreateTaxCategory() {
  const notifications = useNotifications()

  const mutationOptions = {
    onError: (err: any) => {
      Sentry.captureException(err)
    }
  }

  const {
    mutateAsync: postTaxCategory,
    isError: isPostTaxCategoryError,
    reset: resetPostTaxCategory
  } = useMutation(dashboardv99990101Client.postTaxCategory)

  const {
    mutateAsync: postTaxRate,
    isError: isPostTaxRateError,
    reset: resetPostTaxRate
  } = useMutation(dashboardv99990101Client.postTaxRate)

  return async (newTaxCategoryInput: CreateTaxCategoryInput) => {
    const mutationResult = await postTaxCategory(
      {
        body: {
          name: newTaxCategoryInput.name,
          description: newTaxCategoryInput.description,
          isPublished: true,
          version: 0
        }
      },
      mutationOptions
    )

    const { id: createdTaxCategoryId, name: createdTaxCategoryName } =
      mutationResult ?? {}

    // Short circuit the process if updating the tax category failed
    // or we did not receive a new tax category id back from the update
    if (!createdTaxCategoryId && isPostTaxCategoryError) {
      notifications.displayNotification('Unable to create tax category', {
        type: 'error'
      })

      return
    }

    const taxRatesToCreate = Object.entries(newTaxCategoryInput.countries ?? {})
      .filter(([, amount]) => !isEmpty(amount))
      .map(([country, amount]) => {
        const generatedTaxRateName = `${country} ${createdTaxCategoryName}`

        return {
          body: {
            taxCategoryId: createdTaxCategoryId,
            isPublished: true,
            name: generatedTaxRateName,
            invoiceName: generatedTaxRateName,
            invoiceShortName: generatedTaxRateName,
            country: country as TaxRate['country'],
            amount
          }
        }
      })

    await Promise.all(
      taxRatesToCreate.map(taxRateToCreate => postTaxRate(taxRateToCreate))
    )

    if (isPostTaxRateError) {
      notifications.displayNotification(
        'Tax category created but some tax rates may not have been saved',
        {
          type: 'warning'
        }
      )

      return
    }

    notifications.displayNotification('Tax category created', {
      type: 'success'
    })

    resetPostTaxCategory()
    resetPostTaxRate()
  }
}
