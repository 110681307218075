import { FC } from 'react'
import { LinkCustomerToServiceWidget } from 'Integrations/integrationsConfig/common/LinkEntities/LinkCustomerToService'

export const LinkStripeCustomerWidget: FC<{
  customerId: string
  canUnlink?: boolean
}> = props => (
  <LinkCustomerToServiceWidget
    customerId={props.customerId}
    externalEntityLabel="company"
    sequenceEntityLabel="customer"
    service="Stripe"
    canUnlink={props.canUnlink}
  />
)
