import { Box, Flex, IconButton, Text, useToast } from '@chakra-ui/react'
import X from '@heroicons/react/16/solid/XMarkIcon'
import CheckCircleIcon from '@heroicons/react/16/solid/CheckCircleIcon'
import ExclamationCircleIcon from '@heroicons/react/16/solid/ExclamationCircleIcon'
import {
  GreenGreen80,
  GreyGrey60,
  Lato14ExtendedBold,
  Lato14Regular,
  RedRed70
} from '@sequencehq/design-tokens'

interface ToastProps {
  title: string
  description?: string
  type: 'success' | 'error'
  isClosable: boolean
}

export const Toast = ({
  title,
  description,
  type = 'success',
  isClosable = true
}: ToastProps) => {
  const toast = useToast()

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon color={GreenGreen80} height={20} width={20} />
      case 'error':
        return <ExclamationCircleIcon color={RedRed70} height={20} width={20} />
      default:
        return null
    }
  }

  return (
    <Flex
      bg="white"
      p={4}
      borderRadius={8}
      border="1px solid rgba(0,0,0,0.1)"
      shadow="0 8px 16px 0 rgba(0, 0, 0, 0.08)"
      ml={3}
      mb={3}
      gap={2}
      position="relative"
      width="310px"
    >
      <Box>{renderIcon()}</Box>
      <Flex gap={1} direction="column">
        <Text {...Lato14ExtendedBold} lineHeight={1.2}>
          {title}
        </Text>
        {description && <Text {...Lato14Regular}>{description}</Text>}
      </Flex>

      {isClosable && (
        <IconButton
          position="absolute"
          top={0}
          right={0}
          width="auto"
          aria-label="Close"
          variant="unstyled"
          display="flex"
          onClick={() => toast.closeAll()}
          icon={<X color={GreyGrey60} height={20} width={20} />}
        />
      )}
    </Flex>
  )
}
