import { EditCustomerForm } from './EditCustomerForm'
import { useNavigate } from 'react-router-dom'

export const EditCustomerRouteComponent = () => {
  const navigate = useNavigate()

  const goBack = () => navigate('..')

  return <EditCustomerForm onClose={goBack} />
}
