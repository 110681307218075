import { Box, Collapse, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'

type Props = {
  copyToClipboard: (prop: string) => void
}

export const CustomerOtherBillingContact = ({ copyToClipboard }: Props) => {
  const { customer } = useCustomerContext()

  const [isExpanded, setIsExpanded] = useState(false)

  const otherBillingContacts = customer.contacts.filter(
    contact => contact.billingPreference === 'STANDARD'
  )

  const showExpandableSection = otherBillingContacts.length > 10

  if (otherBillingContacts.length === 0) {
    return <></>
  }

  return (
    <>
      <Text {...Lato13Regular} color={GreyGrey70}>
        Other billing contacts
      </Text>

      <Flex gap={1} direction="column">
        {otherBillingContacts.slice(0, 10).map(otherBillingContact => {
          return (
            <Text
              key={`other-billing-contact-${otherBillingContact.id}`}
              cursor="pointer"
              {...Lato13Regular}
              color={IndigoIndigo50}
              onClick={() => {
                copyToClipboard(otherBillingContact.email)
              }}
            >
              {otherBillingContact.email}
            </Text>
          )
        })}

        {showExpandableSection && (
          <>
            <Collapse in={isExpanded}>
              <Flex gap={1} direction="column">
                {otherBillingContacts.slice(10).map(otherBillingContact => {
                  return (
                    <Text
                      key={`other-billing-contact-${otherBillingContact.id}`}
                      cursor="pointer"
                      {...Lato13Regular}
                      color={IndigoIndigo50}
                      onClick={() => {
                        copyToClipboard(otherBillingContact.email)
                      }}
                    >
                      {otherBillingContact.email}
                    </Text>
                  )
                })}
              </Flex>
            </Collapse>
            <Box
              as={Flex}
              {...Lato13Regular}
              color={IndigoIndigo50}
              cursor="pointer"
              onClick={() => setIsExpanded(!isExpanded)}
              pt={2}
            >
              {isExpanded ? (
                <ChevronUpIcon color={IndigoIndigo50} height={16} width={16} />
              ) : (
                <ChevronDownIcon
                  color={IndigoIndigo50}
                  height={16}
                  width={16}
                />
              )}
              {isExpanded ? 'Hide additional contacts' : 'Show all contacts'}
            </Box>
          </>
        )}
      </Flex>
    </>
  )
}
