import { Flex, Icon } from '@chakra-ui/react'
import PlusIcon from '@heroicons/react/24/outline/PlusIcon'
import { Currency } from '@sequencehq/core-models'
import { IndigoIndigo50, Lato14Regular } from '@sequencehq/design-tokens'
import { SimpleTableInput } from '@sequencehq/core-components'
import { useFixedTierTableInput } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/inputs/FixedTierTableInput/useFixedTierTableInput'
import {
  PricingEditorTier,
  ValidationError
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { FC } from 'react'

type FixedTierTableInputProps = {
  onChange: (value: PricingEditorTier[]) => void
  value: PricingEditorTier[]
  isDisabled: boolean
  currency: Currency
  validationErrors: ValidationError[]
}

export const FixedTierTableInput: FC<FixedTierTableInputProps> = props => {
  const { fieldConfig, addNewTier } = useFixedTierTableInput(props)

  return (
    <>
      <SimpleTableInput {...fieldConfig} />
      <Flex marginTop="8px">
        <Flex
          {...Lato14Regular}
          width="fit-content"
          color={IndigoIndigo50}
          onClick={() => addNewTier()}
          _hover={{ opacity: 0.7 }}
          cursor="pointer"
          padding="4px 0"
          alignItems="center"
        >
          <Icon height="20px" width="20px" as={PlusIcon} marginRight="4px" />{' '}
          Add new tier
        </Flex>
      </Flex>
    </>
  )
}
