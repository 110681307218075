import {
  type Price,
  useLoadPrices
} from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadPrices'
import { useMemo } from 'react'
import { useLoadLedgerIntegrations } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadLedgerIntegrations'
import type { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'
import type { XeroIntegration } from 'lib/hooks/useXeroIntegration'

export type LineItemCustomer = {
  id: string
  integrationIds: Array<{
    service: IntegrationServices
    id: string
  }>
}

// TODO: type the customer prop... properly
type UseLoadLineItemEditor = (props: { customer: any }) => {
  loading: boolean
  error: boolean
  data?: {
    ledgerIntegrations: {
      key: IntegrationServices
      value: XeroIntegration['ledgerAccounts']
      defaultLedgerAccount?: string
    }[]
    prices: Price[]
  }
}

export const useLoadLineItemEditor: UseLoadLineItemEditor = ({ customer }) => {
  const pricesLoader = useLoadPrices()
  const ledgerIntegrationsLoader = useLoadLedgerIntegrations({ customer })

  const error = useMemo(
    () => [ledgerIntegrationsLoader.hasError, pricesLoader.error].some(Boolean),
    [pricesLoader.error, ledgerIntegrationsLoader.hasError]
  )

  const loading = useMemo(
    () =>
      [ledgerIntegrationsLoader.loading, pricesLoader.loading].some(Boolean),
    [pricesLoader.loading, ledgerIntegrationsLoader.loading]
  )

  const data = useMemo(() => {
    if (loading || error) {
      return
    }

    return {
      prices: pricesLoader.data?.prices ?? [],
      ledgerIntegrations:
        ledgerIntegrationsLoader.data?.ledgerIntegrations ?? []
    }
  }, [loading, error, pricesLoader.data, ledgerIntegrationsLoader.data])

  return {
    loading,
    error,
    data
  }
}
