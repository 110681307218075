import { getMonth, getYear } from '@sequencehq/utils/dates'

const roundToNearest1000 = (value: number): number => {
  if (value < 0) {
    return Math.floor(value / 1000) * 1000
  }

  return Math.ceil(value / 1000) * 1000
}
/**
 * Produces the value labels we will render onto the Y axis of the graph.
 * Like the above, this could become a standard global util, especially if
 * we make it more intelligent and add a number of points based on the graph
 * height passed in.
 * @param data
 * @returns
 */
export const calculateYAxisLabels = (data: Array<number>): Array<number> => {
  if (data.length === 0) {
    return [0, 0]
  }

  /**
   * When we have data, we will produce three items on the Y-axis - zero,
   * the mid point, and the maximum value.
   */
  const max = roundToNearest1000(Math.max(...data) > 0 ? Math.max(...data) : 0)
  const min = roundToNearest1000(Math.min(...data) < 0 ? Math.min(...data) : 0)
  const range = max - min
  return [min, min + range / 2, max]
}

export type Bucket = {
  year: number
  month: number
}

/**
 * Given a bucket, return a date
 * @param bucket
 * @returns Date
 */
export const createDateFromBucket = (bucket: Bucket): Date =>
  new Date(
    `${bucket.year}-${bucket.month < 10 ? `0${bucket.month}` : bucket.month}-01`
  )

/**
 * Given a date, return the bucket representation of that date.
 * @param date
 * @returns Bucket
 */
export const createBucketFromDate = (date: Date): Bucket => {
  return {
    year: getYear(date),
    month: getMonth(date) + 1
  }
}

/**
 * For a given start and end bucket, generate all of the intermediate buckets.
 * This is especially useful for filling in the 'blanks' in our XYCharts.
 * @param start
 * @param end
 * @param buckets
 * @returns
 */
export const generateAllBucketsInPeriod = (
  start: { year: number; month: number },
  end: { year: number; month: number },
  buckets: Array<Bucket> = [start]
): Array<Bucket> => {
  const latestBucket = buckets[buckets.length - 1]
  const nextMonth = latestBucket.month === 12 ? 1 : latestBucket.month + 1
  const nextYear =
    latestBucket.month === 12 ? latestBucket.year + 1 : latestBucket.year

  const nextBucket = {
    year: nextYear,
    month: nextMonth
  }

  if (
    nextBucket.year > end.year ||
    (nextBucket.year === end.year && nextBucket.month > end.month)
  ) {
    return buckets
  }

  return generateAllBucketsInPeriod(start, end, [...buckets, nextBucket])
}
