import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import type { AvalaraIntegrationConfig } from 'Integrations/integrationsConfig/avalara/avalara.integration.config'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

const credentialsMutator = async (
  data: AvalaraIntegrationConfig
): Promise<{
  success: boolean
}> => {
  if (data.accountId && data.licenseKey) {
    const result = await dashboardv99990101Client.postStoreAvalaraCredentials({
      accountId: data.accountId,
      licenseKey: data.licenseKey
    })

    return {
      success: !result.error
    }
  }

  return { success: true }
}

const avalaraCompanyMutator = async (
  data: AvalaraIntegrationConfig
): Promise<{
  success: boolean
}> => {
  if (data.companyId) {
    const result = await dashboardv99990101Client.postEnabledAvalaraCompany({
      id: data.companyId
    })

    return {
      success: !result.error
    }
  }

  return { success: true }
}

export const avalaraConfigurationMutator = async (
  data: AvalaraIntegrationConfig
): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  const credentialsMutationResult = await credentialsMutator(data)
  const companyMutationResult = await avalaraCompanyMutator(data)

  const results = [credentialsMutationResult, companyMutationResult]

  if (results.some(result => !result.success)) {
    return {
      success: false,
      error: IntegrationsPortErrors.Other
    }
  }

  return {
    success: true,
    error: null
  }
}
