import { Price } from 'Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { RecursivePartial } from 'Integrations/domain'

export enum AddProductFormMode {
  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_PRICE = 'ADD_PRICE',
  REVIEW = 'REVIEW'
}

export enum AddProductFormActionTypes {
  UPDATE_FORM_MODE = 'addProductForm/UPDATE_FORM_MODE',
  UPDATE_FORM_DATA = 'addProductForm/UPDATE_FORM_DATA'
}

export type AddProductFormActions = UpdateFormModeAction | UpdateFormDataAction

export type Action<T> = {
  type: AddProductFormActionTypes
  payload: T
}

export type UpdateFormModeAction = Action<AddProductFormMode>

export type UpdateFormDataAction = Action<
  RecursivePartial<AddProductFormState['formData']>
>

export type AddProductFormState = {
  formData: {
    product?: {
      label: string
      name: string
    }
    price?: Price
  }
  mode: AddProductFormMode
}
