import StartBillingForm from 'components/BillingSchedules/components/modals/StartBilling/StartBillingForm'
import {
  StartBillingFormMutation,
  StartBillingFormProps
} from 'components/BillingSchedules/components/modals/StartBilling/types'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { usePutBillingSchedulesBillingScheduleIdActivateMutation } from 'features/api'

export const StartBillingModal = createFormModal<
  StartBillingFormMutation,
  StartBillingFormProps
>({
  FormComponent: StartBillingForm,
  useMutation: usePutBillingSchedulesBillingScheduleIdActivateMutation,
  modalTitle: 'Start schedule',
  successMessage: 'Schedule started',
  submitTitle: 'Start schedule',
  formId: 'startScheduleForm',
  successToast: () => (
    <Toast title="Schedule started" type="success" isClosable={true}></Toast>
  ),
  errorToast: (
    <Toast
      title="Could not start schedule"
      type="error"
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})
