import { CubeReducerState } from 'modules/Cube/domain/cube.domain.types'
import { getAcceptQuoteAvailable } from './getAcceptQuoteAvailable'
import { getArchiveQuoteAvailable } from './getArchiveQuoteAvailable'
import { getContractLengthEditingAvailable } from './getContractLengthEditingAvailable'
import { getDeleteLatestQuoteDraftAvailable } from './getDeleteLatestQuoteDraftAvailable'
import { getExecuteQuoteAvailable } from './getExecuteQuoteAvailable'
import { getPreviewQuoteAvailable } from './getPreviewQuoteAvailable'
import { getPublishQuoteAvailable } from './getPublishQuoteAvailable'
import { getEditQuoteAvailable } from './getEditQuoteAvailable'
import { getSetRecipientsAvailable } from './getSetRecipientsAvailable'
import { getDuplicateQuoteAvailable } from './getDuplicateQuoteAvailable'

export const getQuoteFeaturesAvailable = (
  prevState: CubeReducerState
): CubeReducerState['queries']['availableFeatures']['quote'] => ({
  contractLengthEditing: getContractLengthEditingAvailable(prevState),
  publish: getPublishQuoteAvailable(prevState),
  accept: getAcceptQuoteAvailable(prevState),
  execute: getExecuteQuoteAvailable(prevState),
  preview: getPreviewQuoteAvailable(prevState),
  archive: getArchiveQuoteAvailable(prevState),
  deleteLatestDraft: getDeleteLatestQuoteDraftAvailable(prevState),
  edit: getEditQuoteAvailable(prevState),
  setRecipients: getSetRecipientsAvailable(prevState),
  duplicate: getDuplicateQuoteAvailable(prevState)
})
