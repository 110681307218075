import { Grid } from '@chakra-ui/react'
import { GreyGrey0, GreyGrey30 } from '@sequencehq/design-tokens'
import {
  RecognizedRevenueGraph,
  useRecognizedRevenueGraph
} from './RecognizedRevenueGraph'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { useMemo } from 'react'
import { Skeleton } from '@sequencehq/core-components'
import { calculateYAxisLabels } from 'RevenueRecognition/view/utils/graphUtils'
import { BilledRevenueGraph, useBilledRevenueGraph } from './BilledRevenueGraph'

export const useRevenueGraphAxisScale = () => {
  const { currency } = useSelectedCurrency()
  const recognizedRevenueGraphData = useRecognizedRevenueGraph({
    currency
  })
  const billedRevenueGraphData = useBilledRevenueGraph({ currency })

  /**
   * We keep the two graphs on the same relative scale by grabbing the totals
   * for both, and calculating the respective y-axis labels from the maximum
   * of the two.
   */

  const yAxisLabels = useMemo(() => {
    if (
      recognizedRevenueGraphData.isLoading ||
      billedRevenueGraphData.isLoading
    ) {
      return [0, 0]
    }

    return calculateYAxisLabels([
      ...recognizedRevenueGraphData.data.recognizedRevenueByMonth.map(
        rr => rr.value
      ),
      ...billedRevenueGraphData.data.billedRevenueByMonth.map(rr => rr.value)
    ])
  }, [recognizedRevenueGraphData, billedRevenueGraphData])

  return { yAxisLabels }
}

export const RevenueOverviewGraphs = () => {
  const { currency } = useSelectedCurrency()
  const { yAxisLabels } = useRevenueGraphAxisScale()

  return (
    <Grid
      gridTemplateColumns="1fr 1fr"
      gridColumnGap="16px"
      padding="12px 16px"
      backgroundColor={GreyGrey0}
      borderBottom={`1px solid ${GreyGrey30}`}
    >
      {currency ? (
        <>
          <RecognizedRevenueGraph
            currency={currency}
            yAxisLabels={yAxisLabels}
          />
          <BilledRevenueGraph currency={currency} yAxisLabels={yAxisLabels} />
        </>
      ) : (
        <>
          <Skeleton width="100%" height="291px" />
          <Skeleton width="100%" height="291px" />
        </>
      )}
    </Grid>
  )
}
