import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { v1ApiLoadedData } from '../../loadData'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'

type LoadUsageMetrics = () => Promise<
  | {
      data: v1ApiLoadedData['usageMetrics']
      error: null
    }
  | {
      data: null
      error: PricingEditorPortErrors
    }
>

export const loadUsageMetrics: LoadUsageMetrics = async () => {
  const usageMetricsResponse = await dashboardv20240509Client.getUsageMetrics()

  if (usageMetricsResponse.error) {
    return {
      data: null,
      error:
        usageMetricsResponse.res.status == 404
          ? PricingEditorPortErrors.NotFound
          : PricingEditorPortErrors.Other
    }
  }
  return {
    data: usageMetricsResponse.data?.items ?? [],
    error: null
  }
}
