import { useCallback, useEffect, useMemo, useState } from 'react'
import { Currency, InvoiceMerchantDetailsModel } from '@sequencehq/core-models'
import { useLazyGetInvoiceMerchantDetailsQuery } from 'features/api'

type UseLoadMerchantDetails = (props: {
  currency?: Currency
  country?: any
  state?: any
  invoiceMerchantDetails?: InvoiceMerchantDetailsModel
}) => {
  loading: boolean
  data?: {
    merchantDetails: InvoiceMerchantDetailsModel
  }
}

export const useLoadMerchantDetails: UseLoadMerchantDetails = props => {
  const [fetchMerchantDetails, { isLoading, error }] =
    useLazyGetInvoiceMerchantDetailsQuery()
  const [currentMerchantDetails, setCurrentMerchantDetails] = useState<
    InvoiceMerchantDetailsModel | undefined
  >(undefined)

  const loadMerchantDetails = useCallback(
    async (args: {
      currency: Currency
      country: any
      state: any
    }): Promise<InvoiceMerchantDetailsModel | undefined> =>
      fetchMerchantDetails({
        currency: args.currency,
        country: args.country,
        state: args.state
      }).then(response => response.data?.value()),
    [fetchMerchantDetails]
  )

  useEffect(() => {
    if (!props.currency || !props.country) {
      return
    }

    if (props.invoiceMerchantDetails) {
      setCurrentMerchantDetails(props.invoiceMerchantDetails)
      return
    } else {
      loadMerchantDetails({
        currency: props.currency,
        country: props.country,
        state: props.state
      })
        .then(merchantDetails => {
          setCurrentMerchantDetails(merchantDetails)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }, [
    props.currency,
    props.country,
    props.state,
    props.invoiceMerchantDetails,
    loadMerchantDetails
  ])

  const data = useMemo(() => {
    if (!currentMerchantDetails) {
      return
    }

    return {
      merchantDetails: currentMerchantDetails
    }
  }, [currentMerchantDetails])

  return {
    loading: isLoading,
    error,
    data
  }
}
