import { FC, MutableRefObject, useState } from 'react'
import { CustomerModel } from '@sequencehq/core-models'
import { useGetCustomersQuery } from 'features/api'
import { selectPlaceholder } from 'lib/form'
import {
  InfoPopoverProps,
  CompoundInputFieldNew,
  ComboInputRemoteFilterNew
} from '@sequencehq/forms'
import { FieldValidator } from 'final-form'

type CustomerComboInputProps = {
  id?: string
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
  inputRef: MutableRefObject<HTMLInputElement | null>
  postChange?: () => void
  handleBlur?: () => void
  addNewAction?: () => void
  model: CustomerModel | null
  modelId: string
  setModelId: (id: string | undefined) => void
  validate: FieldValidator<string> | undefined
}

export const CustomerComboInput: FC<CustomerComboInputProps> = ({
  disabled,
  ...rest
}) => {
  const [query, setQuery] = useState('')
  const customersResult = useGetCustomersQuery({
    limit: query ? 50 : 5,
    legalName: query
  })

  const { isLoading, isError, data } = customersResult
  const items = data?.value()?.items ?? []

  return (
    <CompoundInputFieldNew<ComboInputRemoteFilterNew<CustomerModel>>
      items={items}
      itemToString={item => item?.legalName ?? ''}
      itemToContent={({ legalName, email }) => ({
        title: legalName,
        subTitle: email
      })}
      setQuery={setQuery}
      query={query}
      placeholder={selectPlaceholder({
        result: customersResult,
        successPrompt: 'Choose customer'
      })}
      disabled={disabled || isLoading || isError}
      Component={ComboInputRemoteFilterNew}
      {...rest}
    />
  )
}
