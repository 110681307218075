import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

export const InvoiceInspectorRedirect = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const navigate = useNavigate()

  invariant(invoiceId, 'invoiceId is required')

  useEffect(() => {
    navigate(`/invoices/${invoiceId}`)
  }, [invoiceId, navigate])

  return <></>
}
