import { MinimumDomainInterface } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.domain'
import { createContext, useContext } from 'react'

const MinimumDomainContext = createContext<MinimumDomainInterface>(
  {} as MinimumDomainInterface
)

export const useMinimumEditorDomainContext = () => {
  const context = useContext(MinimumDomainContext)

  return context
}

export const MinimumEditorDomainProvider = MinimumDomainContext.Provider
