import {
  Box,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react'
import { Badge, InfoBox, InfoRow } from '@sequencehq/core-components'
import { metricPropertyMatchers } from '@sequencehq/core-models'
import {
  GreyGrey100,
  GreyGrey60,
  Lato14Regular,
  Lato16Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import Sidebar from 'components/Sidebar'
import { UsageMetricPreview } from 'components/ViewUsageMetric/UsageMetricPreview'
import { useGetUsageMetricsIdQuery } from 'features/api'
import { useNavigate, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

export const ViewUsageMetric = () => (
  <Sidebar>
    <ViewUsageMetricContent />
  </Sidebar>
)

const ViewUsageMetricContent = () => {
  const navigate = useNavigate()

  const { metricId } = useParams<{ metricId: string }>()

  invariant(metricId, 'metricId is required')

  const res = useGetUsageMetricsIdQuery({ id: metricId })

  if (res.isLoading) {
    return (
      <DrawerContent>
        <DrawerCloseButton top={4} right={4} />

        <DrawerBody>
          <Spinner />
        </DrawerBody>
      </DrawerContent>
    )
  }

  const usageMetric = res.data?.value()

  if (!usageMetric) {
    return (
      <DrawerContent>
        <DrawerCloseButton top={4} right={4} />
        <DrawerBody>Something went wrong. Please try again later.</DrawerBody>
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      <DrawerCloseButton top={4} right={4} />
      <DrawerHeader as="h2" borderBottom="none" {...Lato20Bold} pb="8px">
        {usageMetric?.name}
      </DrawerHeader>
      <DrawerBody padding="0 24px 24px 24px">
        <Flex justify="space-between" width="100%" align="center">
          <Text {...Lato16Regular} color={GreyGrey60}>
            {usageMetric.description}
          </Text>

          {usageMetric.aggregationType !== 'CUSTOM' && (
            <Button
              variant="primary"
              onClick={() => navigate(`/usage-metrics/${usageMetric.id}/edit`)}
            >
              Edit
            </Button>
          )}
        </Flex>
        <Box height={6} />
        <InfoBox>
          <InfoRow label="Metric ID">{usageMetric.id}</InfoRow>
          {usageMetric.aggregationType !== 'CUSTOM' && (
            <InfoRow label="Event type">
              <Badge sentiment="monospaced" textTransform="none">
                {usageMetric.eventType}
              </Badge>
            </InfoRow>
          )}
          <InfoRow label="Calculation">
            {usageMetric.aggregationType === 'CUSTOM'
              ? 'Custom calculation'
              : `${usageMetric.aggregationType} of usage values`}
          </InfoRow>

          {usageMetric.aggregationProperty ? (
            <InfoRow label="Over event property">
              <Badge sentiment="monospaced" textTransform="none">
                {usageMetric.aggregationProperty}
              </Badge>
            </InfoRow>
          ) : null}

          {usageMetric.propertyFilters
            ? Object.entries(usageMetric.propertyFilters).map(
                ([key, value], index) => (
                  <InfoRow
                    key={`${key}${value.toString()}`}
                    label={index === 0 ? 'Filters' : ''}
                  >
                    <Flex alignItems="center">
                      <Badge sentiment="monospaced" textTransform="none">
                        {key}
                      </Badge>
                      <Text {...Lato14Regular} color={GreyGrey100} px="8px">
                        {usageMetric.propertiesToNegate &&
                        usageMetric.propertiesToNegate.includes(key)
                          ? metricPropertyMatchers['isNot']
                          : metricPropertyMatchers['is']}
                      </Text>
                      <Badge sentiment="monospaced" textTransform="none">
                        {value.toString()}
                      </Badge>
                    </Flex>
                  </InfoRow>
                )
              )
            : null}
        </InfoBox>
        <Box height={6} />
        <UsageMetricPreview
          usageMetricId={metricId}
          parameters={usageMetric.parameters}
          aggregationType={usageMetric.aggregationType}
        />
        <Box height={6} />
        {usageMetric.aggregationType === 'CUSTOM' && (
          <Text color={GreyGrey60}>
            Editing is not available for custom metrics. Please reach out to the
            Sequence team for any changes.
          </Text>
        )}
      </DrawerBody>
    </DrawerContent>
  )
}
