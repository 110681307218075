import {
  CubeReducerState,
  Phase,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'

export const atLeastOnePriceInPhase =
  (ctx: { phaseId: Phase['id'] }) =>
  (prevState: CubeReducerState): ValidationResult[] | null => {
    const resolvedPhase = prevState.queries.resolvedPhases[ctx.phaseId]
    if (resolvedPhase.prices.length) {
      return null
    }

    return [
      {
        message: 'At least one price is required in phase',
        metadata: {
          phaseId: ctx.phaseId
        }
      }
    ]
  }
