import { Button, Flex } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { Blocker } from 'common/components/Blocker'
import { useEditorFooter } from 'modules/Products/drawers/ListPriceEditor/components/EditorFooter/useEditorFooter'

export const EditorFooter = () => {
  const {
    reviewAvailable,
    saveText,
    closeText,
    saveDisabled,
    onReview,
    onSave,
    onClose,
    saveAvailable,
    blockerEnabled,
    submitting,
    ignoreBlocker
  } = useEditorFooter()

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      height="100%"
      padding="0 24px"
      gap="8px"
    >
      <Blocker
        name="list price editor"
        dirty={blockerEnabled}
        submitting={submitting}
        ignoreBlock={ignoreBlocker}
      />
      <Button variant="secondary2" size="xs" onClick={onClose}>
        {closeText}
      </Button>
      {reviewAvailable && (
        <Button
          variant="primary2"
          size="xs"
          isDisabled={saveDisabled}
          onClick={onReview}
        >
          Next
        </Button>
      )}
      {saveAvailable && (
        <Button
          variant="primary2"
          size="xs"
          isDisabled={saveDisabled}
          onClick={() => {
            onSave().catch(e => Sentry.captureException(e))
          }}
        >
          {saveText}
        </Button>
      )}
    </Flex>
  )
}
