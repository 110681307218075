import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import {
  GraduatedPrice,
  LinearPrice,
  PackagedPrice,
  Price,
  SeatBasedGraduatedPrice,
  SeatBasedLinearPrice,
  StandardPrice,
  VolumePrice
} from 'common/drawers/PricingEditor/domain'
import { useMemo } from 'react'
import { match } from 'ts-pattern'

export const useReviewForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()

  const price: Price = useMemo(() => {
    const formData = pricingEditorContext.queries.rawData.data.pricingEditorData
    const basePriceData = {
      id: formData.common.id,
      common: formData.common
    }

    return match(formData)
      .with({ common: { pricingModel: 'STANDARD' } }, () => {
        return {
          ...basePriceData,
          common: formData.common,
          modelSpecific: formData.STANDARD
        } as StandardPrice
      })
      .with({ common: { pricingModel: 'GRADUATED' } }, () => {
        return {
          ...basePriceData,
          modelSpecific: formData.GRADUATED
        } as GraduatedPrice
      })
      .with({ common: { pricingModel: 'LINEAR' } }, () => {
        return {
          ...basePriceData,
          modelSpecific: formData.LINEAR
        } as LinearPrice
      })
      .with({ common: { pricingModel: 'PACKAGED' } }, () => {
        return {
          ...basePriceData,
          modelSpecific: formData.PACKAGED
        } as PackagedPrice
      })
      .with({ common: { pricingModel: 'SEAT_BASED_GRADUATED' } }, () => {
        return {
          ...basePriceData,
          modelSpecific: formData.SEAT_BASED_GRADUATED
        } as SeatBasedGraduatedPrice
      })
      .with({ common: { pricingModel: 'SEAT_BASED_LINEAR' } }, () => {
        return {
          ...basePriceData,
          modelSpecific: formData.SEAT_BASED_LINEAR
        } as SeatBasedLinearPrice
      })
      .with({ common: { pricingModel: 'VOLUME' } }, () => {
        return {
          ...basePriceData,
          modelSpecific: formData.VOLUME
        } as VolumePrice
      })
      .exhaustive()
  }, [pricingEditorContext.queries.rawData.data.pricingEditorData])

  const data = useMemo(() => {
    return {
      price,
      product: pricingEditorContext.queries.rawData.data.product
    }
  }, [price, pricingEditorContext.queries.rawData.data.product])

  return data
}
