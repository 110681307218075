import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { format } from '@sequencehq/utils/dates'
import invariant from 'tiny-invariant'

interface JournalReportSummaryData {
  date: Date
  totals: {
    debit: number
    credit: number
  }
  currency: Currency
  ledgerAccounts: {
    name: string
    debit?: number
    credit?: number
  }[]
}

/**
 * Load the higher level data for the given journal report.
 * @returns
 */
export const useJournalReportSummary = (props: {
  dates: {
    startDate: Date
    endDate: Date
  }
  currency: Currency | null
}):
  | {
      summary: JournalReportSummaryData
      isLoading: false
    }
  | {
      summary: null
      isLoading: true
    } => {
  const journalSummaryResponse = useQuery(
    dashboardv99990101Client.getJournals,
    {
      from: format(props.dates.startDate, 'yyyy-MM-dd'),
      to: format(props.dates.endDate, 'yyyy-MM-dd'),
      /**
       * TODO: This is where a skipToken is ideal, but we need to ponder how to
       * introduce it for the simple case. For now, we'll just set the param to
       * a default value to appease Typescript, and rely on `enabled` to control
       * when the query kicks off.
       */
      currency: props.currency ?? 'GBP'
    },
    {
      staleTime: 5000,
      enabled: !!props.currency,
      select: data => {
        if (!data) {
          return null
        }

        return {
          date: new Date(props.dates.startDate),
          totals: {
            debit: parseFloat(data.summary.debits),
            credit: parseFloat(data.summary.credits)
          },
          currency: data.summary.currency,
          ledgerAccounts: data.summary.ledgerAccountsSummary.map(
            ledgerAccount => ({
              name: ledgerAccount.name,
              debit: parseFloat(ledgerAccount.debits),
              credit: parseFloat(ledgerAccount.credits)
            })
          )
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (journalSummaryResponse.error) {
    throw new Error('Journal details could not be loaded')
  }

  if (journalSummaryResponse.isPending) {
    return {
      isLoading: true,
      summary: null
    }
  }

  invariant(journalSummaryResponse.data, 'Summary should be defined, if loaded')

  return {
    isLoading: journalSummaryResponse.isPending,
    summary: journalSummaryResponse.data
  }
}
