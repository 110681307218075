import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'
import { useCallback } from 'react'

export type v1ApiCreateProductData =
  Dashboardv99990101Api.PostProduct.ProductBody

export type v1ApiCreateProductResult =
  Dashboardv99990101Api.PostProduct.Response['data']

type CreateProduct = (data: v1ApiCreateProductData) => Promise<{
  data: v1ApiCreateProductResult
  success: boolean
  error: PricingEditorPortErrors | null
}>

export const useCreateProduct = () => {
  const { mutateAsync } = useMutation(dashboardv99990101Client.postProduct)

  const createProduct: CreateProduct = useCallback(
    (data: v1ApiCreateProductData) => {
      const create = async () => {
        try {
          const res = await mutateAsync({
            body: data
          })
          return { data: res, success: true, error: null }
        } catch (error) {
          return {
            data: null,
            success: false,
            error: PricingEditorPortErrors.Other
          }
        }
      }
      return create()
    },
    [mutateAsync]
  )

  return createProduct
}
