import { CubeReducerState } from 'modules/Cube/domain/cube.domain.types'

/**
 * The primary source of truth for a selected currency is the prices associated
 * the schedule, followed by the globally set currency. This is because whilst the
 * global currency is selectable in quotes (and triggers a post action which updates
 * the prices) it is not in schedules.
 *
 * We only pay attention to the first price currency as all other prices should follow
 * suit.
 * @param prevState
 * @returns
 */
export const getSelectedCurrency = (prevState: CubeReducerState) => {
  const phasePrices = Object.values(prevState.data.phases)
    .flatMap(phase => phase.priceIds)
    .map(priceId => prevState.data.prices[priceId])

  return phasePrices[0]?.currency ?? prevState.data.common?.currency
}
