import {
  Button,
  Divider,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text
} from '@chakra-ui/react'
import { borderColor } from '@sequencehq/core-theme'
import { CancelChangesProps } from 'components/CancelChanges/types'
import { closeOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, useEffect, useState } from 'react'

const CancelChangesModal: FC<CancelChangesProps> = ({ cancel }) => {
  const [cancelled, setCancelled] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!cancelled) {
      return
    }
    cancel()
    dispatch(closeOverlay())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelled, dispatch])

  return (
    <ModalContent borderRadius="24px">
      {!cancelled && (
        <>
          <Flex alignItems="center" position="relative" height="60px">
            <ModalHeader as="h2">Cancel changes</ModalHeader>
            <ModalCloseButton top={4} right={4} />
          </Flex>
          <Divider color={borderColor} />
        </>
      )}
      <ModalBody p={5}>
        <Text>Cancel changes without saving?</Text>
      </ModalBody>
      {!cancelled && (
        <ModalFooter>
          <Button
            variant="secondary"
            mr={3}
            w="150px"
            onClick={() => {
              dispatch(closeOverlay())
            }}
          >
            Return to editing
          </Button>
          <Button variant="warning" onClick={() => setCancelled(true)}>
            Cancel
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  )
}

export default CancelChangesModal
