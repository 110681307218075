import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useCallback, useState } from 'react'
import { Product } from 'Products/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useQuery } from '@sequencehq/api/utils'

type ProductField = Pick<
  Product,
  'name' | 'label' | 'taxCategoryId' | 'revenueRecognitionMethod'
>

export const useAddProductForm = () => {
  const flags = useFlags()

  const { data: taxCategoriesData } = useQuery(
    dashboard20240730Client.getTaxCategories
  )

  const [product, setProduct] = useState<ProductField>()

  const taxCategories = taxCategoriesData?.items ?? []

  const defaultTaxCategory = taxCategories.find(({ isDefault }) => isDefault)

  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const { fields, queries } = useForm<ProductField>({
    value: {
      label: '',
      name: '',
      taxCategoryId: defaultTaxCategory?.id,
      revenueRecognitionMethod: undefined
    },
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'label',
        validation: []
      },
      {
        property: 'taxCategoryId',
        validation: flags.showNewTaxRatesSettings ? [required] : []
      },
      {
        property: 'revenueRecognitionMethod',
        disabled: () => !flags?.useRevRec,
        options: [
          {
            label: 'Milestone',
            value: 'MILESTONE'
          },
          {
            label: 'Straight line',
            value: 'STRAIGHT_LINE'
          },
          {
            label: 'Usage',
            value: 'USAGE'
          }
        ]
      }
    ],
    onChange: newData => {
      setShowValidationErrors(false)
      setProduct(newData)
    }
  })

  const onSubmit = useCallback(() => {
    if (!queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    if (!product) {
      return
    }

    return product
  }, [product, queries.isValid])

  const enhancedFields = {
    ...fields,
    taxCategoryId: {
      ...fields.taxCategoryId,
      hidden: !flags.showNewTaxRatesSettings,
      options: taxCategories.map(category => ({
        value: category.id,
        label: category.name
      }))
    }
  }

  return {
    fieldsConfig: enhancedFields,
    onSubmit
  }
}
