import {
  Modal,
  ModalContextProvider,
  PercentageInput,
  InputSelectControlField,
  SimpleModalUI,
  withUnmountOnModalClose,
  Tooltip
} from '@sequencehq/core-components'
import { type ReactNode, useState } from 'react'
import type { TaxCategory, TaxRate } from 'Settings/domain/taxRates.types'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import {
  decimalFromPercentage,
  percentageFromDecimal,
  useForm
} from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { GreyGrey10, GreyGrey30, GreyGrey60 } from '@sequencehq/design-tokens'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import {
  countriesAlpha2,
  countriesAlpha2Emojis,
  type CountriesAlpha2
} from '@sequencehq/api/commonEnums'
import InformationCircleIcon from '@heroicons/react/24/solid/InformationCircleIcon'
import { isEmpty } from 'lodash'

type FormValues = {
  country: CountriesAlpha2 | undefined
  taxCategories: Record<TaxCategory['id'], TaxRate['amount']>
}

type MiniTaxCategory = Pick<TaxCategory, 'id' | 'name'> & {
  description?: string
}

interface Props {
  trigger: ReactNode | ((openModal: () => void) => ReactNode)
  onSubmit: (
    values: FormValues,
    taxRatesForCountry?: TaxRate[]
  ) => Promise<void>
  taxRatesForCountry?: TaxRate[]
  countriesWithTaxRates?: CountriesAlpha2[]
  preselectedCountry?: CountriesAlpha2
  options?: {
    closeOnCancel: boolean
    closeOnSubmit: boolean
    submitText?: string
    onCancel?: () => void
    onClose?: () => void
  }
  taxCategories?: MiniTaxCategory[]
  filterTaxCategories?: (taxCategories: MiniTaxCategory[]) => MiniTaxCategory[]
}

const getInitialValues = (
  taxCategories: Pick<TaxCategory, 'id' | 'name'>[],
  taxRatesForCountry?: TaxRate[],
  preselectedCountry?: CountriesAlpha2
): FormValues => {
  const country = taxRatesForCountry?.[0]?.country

  // If a country is given, it means we are editing tax rates
  if (country) {
    return {
      country,
      taxCategories: taxCategories.reduce(
        (acc, category) => {
          const taxRate = taxRatesForCountry?.find(
            rate => rate.taxCategoryId === category.id
          )

          return {
            ...acc,
            [category.id]: taxRate?.amount
              ? percentageFromDecimal(taxRate.amount, 2)
              : ''
          }
        },
        {} as FormValues['taxCategories']
      )
    }
  }

  return {
    country: preselectedCountry,
    taxCategories: taxCategories.reduce(
      (acc, category) => {
        return {
          ...acc,
          [category.id]: ''
        }
      },
      {} as FormValues['taxCategories']
    )
  }
}

const convertAmountSafely = (amount: string | undefined) => {
  let convertedAmount = amount || ''
  if (amount) {
    try {
      convertedAmount = decimalFromPercentage(amount)
    } catch (e) {
      // Do nothing, keep the value as is
    }
  }

  return convertedAmount
}

const getModifiedTaxCategories = (
  formTaxCategories: Record<string, string>,
  initialValues: FormValues['taxCategories']
) => {
  return Object.entries(formTaxCategories).reduce(
    (acc, [taxCategoryId, amount]) => {
      const initialRate = initialValues[taxCategoryId]

      if (amount !== initialRate) {
        return {
          ...acc,
          [taxCategoryId]: convertAmountSafely(amount)
        }
      }

      return acc
    },
    {} as FormValues['taxCategories']
  )
}

export const TaxRatesModalUI = withUnmountOnModalClose(
  ({
    onSubmit,
    taxRatesForCountry = [],
    countriesWithTaxRates,
    preselectedCountry,
    options = {
      closeOnCancel: true,
      closeOnSubmit: true
    },
    taxCategories: externalTaxCategories,
    filterTaxCategories
  }: Omit<Props, 'trigger'>) => {
    const isEditing = taxRatesForCountry.length > 0
    const submitText =
      options.submitText ?? (isEditing ? 'Save changes' : 'Add tax rate')

    const { data } = useQuery(
      dashboardv99990101Client.getTaxCategories,
      {},
      {
        enabled: !externalTaxCategories
      }
    )

    const loadedTaxCategories = data?.items ?? externalTaxCategories ?? []
    const taxCategories = filterTaxCategories
      ? filterTaxCategories(loadedTaxCategories)
      : loadedTaxCategories

    const taxRateCategoriesFields: {
      property: `taxCategories.${string}`
    }[] = taxCategories.map(category => ({
      property: `taxCategories.${category.id}`
    }))

    const initialValues = getInitialValues(
      taxCategories,
      taxRatesForCountry,
      preselectedCountry
    )

    const [formData, setFormData] = useState<FormValues>(initialValues)

    const { fields: fieldsConfig, queries } = useForm<FormValues>({
      value: initialValues,
      showValidationErrors: false,
      fieldConfiguration: [
        {
          property: 'country',
          validation: [required],
          disabled: () => isEditing || Boolean(preselectedCountry)
        },
        ...taxRateCategoriesFields
      ],
      onChange: newData => {
        setFormData(newData)
      }
    })

    const modifiedTaxCategories = getModifiedTaxCategories(
      formData.taxCategories,
      initialValues.taxCategories
    )

    const handleSubmit = async (formValues: FormValues) => {
      if (isEditing) {
        const formDataWithOnlyModifiedTaxCategories = {
          ...formValues,
          taxCategories: getModifiedTaxCategories(
            formValues.taxCategories,
            initialValues.taxCategories
          )
        }
        await onSubmit(
          formDataWithOnlyModifiedTaxCategories,
          taxRatesForCountry
        )
        return
      }

      const newTaxRatesAmountsForEachCategory = Object.entries(
        formValues.taxCategories || {}
      ).reduce(
        (acc, [taxCategoryId, amount]) => {
          // For adding new rates, include only non-empty values
          if (amount !== '' && amount !== undefined && amount !== null) {
            return {
              ...acc,
              [taxCategoryId]: decimalFromPercentage(amount)
            }
          }

          return acc
        },
        {} as FormValues['taxCategories']
      )

      const formDataWithNewTaxRateAmounts = {
        ...formValues,
        taxCategories: newTaxRatesAmountsForEachCategory
      }

      await onSubmit(formDataWithNewTaxRateAmounts, taxRatesForCountry)
    }

    // Disable submit if no country is selected or no tax rates are filled
    const submitDisabled =
      !queries.isValid ||
      !Object.values(queries.formData.taxCategories).some(
        rate => rate !== '' && rate !== undefined && rate !== null
      )

    return (
      <SimpleModalUI
        disableOnSubmit
        data-testid="addTaxRates"
        title={isEditing ? 'Edit tax rate' : 'Add tax rate'}
        cancelButtonText="Cancel"
        submitButtonText={submitText}
        submitDisabled={
          isEditing ? isEmpty(modifiedTaxCategories) : submitDisabled
        }
        closeOnCancel={options.closeOnCancel}
        closeOnSubmit={options.closeOnSubmit}
        onCancel={options.onCancel}
        onClose={options.onClose}
        onSubmit={() => {
          void handleSubmit(formData)
        }}
      >
        <Flex flexDirection="column" gap={2}>
          <InputSelectControlField
            data-testid="addTaxRates.country"
            label="Country"
            onChange={value =>
              fieldsConfig.country.onChange(value as CountriesAlpha2)
            }
            options={Object.entries(countriesAlpha2).map(
              ([countryCode, countryName]) => ({
                label: `${
                  countriesAlpha2Emojis[countryCode as CountriesAlpha2]
                } ${countryName}`,
                value: countryCode,
                // If we're creating and there's already a tax rate configured for a country,
                // disable it in the dropdown
                disabled:
                  !isEditing &&
                  countriesWithTaxRates?.includes(
                    countryCode as CountriesAlpha2
                  )
              })
            )}
            validationErrors={fieldsConfig.country.validationErrors}
            initialValue={fieldsConfig.country.value || ''}
            placeholder="Select a country..."
            disabled={fieldsConfig.country.disabled}
          />

          {taxCategories.length > 0 && (
            <>
              <Divider mb={2} borderBottomColor={GreyGrey30} />
              <Box>
                <Label>Tax rates</Label>
                <Flex flexDirection="column" gap={2}>
                  {taxCategories.map(category => (
                    <Flex
                      key={category.id}
                      backgroundColor={GreyGrey10}
                      height="40px"
                      paddingLeft={4}
                      paddingRight={1}
                      borderRadius={6}
                      py={2}
                      justifyContent="space-between"
                      align="center"
                    >
                      <Flex gap={1} align="center">
                        <Text>{category.name}</Text>
                        {category.description ? (
                          <Tooltip placement="top" label={category.description}>
                            <InformationCircleIcon
                              color={GreyGrey60}
                              height="20px"
                            />
                          </Tooltip>
                        ) : null}
                      </Flex>
                      <Box width="100px">
                        <PercentageInput
                          min={0}
                          max={100}
                          data-testid={`addTaxRates.category.${category.id}`}
                          variant="v2"
                          precision={2}
                          onBlur={() => {
                            // This sets the value of the input to 0.00
                            // if a user deleted the value that was in it previously.
                            // Avoids submitting an empty string and explicitly shows the value was zeroed.
                            if (
                              initialValues.taxCategories[category.id] !== '' &&
                              fieldsConfig[`taxCategories.${category.id}`]
                                .value === ''
                            ) {
                              fieldsConfig[
                                `taxCategories.${category.id}`
                              ].onChange('0.00' as never)
                            }
                          }}
                          placeholder="0"
                          value={
                            fieldsConfig[`taxCategories.${category.id}`].value
                          }
                          onChange={value => {
                            fieldsConfig[
                              `taxCategories.${category.id}`
                            ].onChange(value as never)
                          }}
                        />
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              </Box>
            </>
          )}
        </Flex>
      </SimpleModalUI>
    )
  }
)

const TaxRatesModal = ({ trigger, ...props }: Props) => {
  return (
    <ModalContextProvider>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <TaxRatesModalUI {...props} />
    </ModalContextProvider>
  )
}

export default TaxRatesModal
