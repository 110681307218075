import DeleteCreditNoteLineItemGroupForm from 'components/DeleteLineItemGroup/DeleteCreditNoteLineItemGroupForm'
import {
  DeleteCreditNoteLineItemGroupFormMutation,
  DeleteCreditNoteLineItemGroupFormProps
} from 'components/DeleteLineItemGroup/types'
import { createFormModal } from 'components/Form/createFormModal'
import { useDeleteCreditNotesByCreditnoteLineItemGroupsByIdMutation } from 'features/api'

export const DeleteCreditNoteLineItemGroupModal = createFormModal<
  DeleteCreditNoteLineItemGroupFormMutation,
  DeleteCreditNoteLineItemGroupFormProps
>({
  FormComponent: DeleteCreditNoteLineItemGroupForm,
  useMutation: useDeleteCreditNotesByCreditnoteLineItemGroupsByIdMutation,
  modalTitle: 'Delete Line Item Group',
  successMessage: 'Deleted',
  submitTitle: 'Delete line item group',
  formId: 'deleteLineItemGroupForm',
  buttonVariant: 'warning'
})
