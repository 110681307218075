import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems.ts'

export type v1ApiProduct = Dashboardv20240509Api.GetProducts.Product
type UseLoadProducts = () => () => Promise<v1ApiProduct[]>

export const useLoadAllProducts: UseLoadProducts = () => {
  return useLoadAllItems(dashboardv20240509Client.getProducts)
}
