import { ValidationResult } from 'modules/Cube/domain/cube.domain.types'

export const adaptValidationFromLib = (
  validatorResult: string | undefined,
  customMessage?: string
): ValidationResult[] | null => {
  if (!validatorResult) {
    return null
  }

  return [{ message: customMessage ?? validatorResult }]
}
