import { Flex } from '@chakra-ui/react'
import { Outlet, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ProductMeta } from 'modules/Products/Product/ProductMeta'
import { ProductPriceVariants } from 'modules/Products/Product/components/ProductPriceVariants'
import { ProductHeader } from 'modules/Products/Product/ProductHeader'
import {
  ProductContextProvider,
  useProductContext
} from 'modules/Products/hooks/useProductContext'
import { ProductListPrices } from 'modules/Products/Product/components/ProductListPrices'
import { useFlags } from 'launchdarkly-react-client-sdk'
import NotFound from 'components/ErrorPages/NotFound'

export const Product = () => {
  const { productId, priceId } = useParams<{
    productId: string
    priceId?: string
  }>()
  invariant(productId, 'productId is required')

  return (
    <ProductContextProvider productId={productId} priceId={priceId}>
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <ProductContent />
        </Flex>
      </Flex>
    </ProductContextProvider>
  )
}

const ProductContent = () => {
  const flags = useFlags()

  const {
    data: { product }
  } = useProductContext()

  if (product.id === 'uninitialized') {
    return <NotFound />
  }

  return (
    <Flex
      width="100%"
      flexDirection="column"
      gap="6px"
      flex={1}
      overflowY="scroll"
    >
      <ProductHeader />
      <ProductMeta />
      {flags.enableListPrices && <ProductListPrices />}
      <ProductPriceVariants />
      <Outlet />
    </Flex>
  )
}
