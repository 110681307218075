import { enforceMinimumPrecision } from '@sequencehq/utils'
import { PriceEditorPercentageTier } from '../domain'

export const sanitizePercentageTiers = (
  newTiers: PriceEditorPercentageTier[]
): PriceEditorPercentageTier[] => {
  return newTiers.reduce((acc, tier, idx) => {
    const previousTier: PriceEditorPercentageTier | undefined =
      acc[acc.length - 1]
    const previousTierLastUnit = previousTier?.lastUnit ?? ''

    const previousLastUnitPrecision =
      previousTierLastUnit.split('.')[1]?.length > 2
        ? previousTierLastUnit.split('.')[1]?.length
        : 2
    const increment = 1 / 10 ** previousLastUnitPrecision

    const isLastTier = idx === newTiers.length - 1
    const firstUnit =
      idx === 0
        ? 0
        : parseFloat(
            (
              parseFloat(
                enforceMinimumPrecision(previousLastUnitPrecision)(
                  previousTier?.lastUnit
                )
              ) + increment
            ).toFixed(previousLastUnitPrecision)
          )

    const currentLastUnit =
      idx === newTiers.length
        ? 0
        : parseFloat(enforceMinimumPrecision(2)(tier?.lastUnit))

    return [
      ...acc,
      {
        id: tier.id,
        lastUnit: isLastTier
          ? ''
          : enforceMinimumPrecision(previousLastUnitPrecision)(
              currentLastUnit < firstUnit ? firstUnit : currentLastUnit
            ),
        firstUnit: enforceMinimumPrecision(previousLastUnitPrecision)(
          firstUnit
        ),
        unitPercentage: enforceMinimumPrecision(2)(tier.unitPercentage),
        flatFee: enforceMinimumPrecision(2)(tier.flatFee),
        minPrice: tier.minPrice
          ? enforceMinimumPrecision(2)(tier.minPrice)
          : '',
        maxPrice: tier.maxPrice ? enforceMinimumPrecision(2)(tier.maxPrice) : ''
      }
    ]
  }, [] as PriceEditorPercentageTier[])
}
