import { INTEGRATIONS_ORDER } from 'Integrations/integrationsConfig/integrations.config'
import {
  Integration,
  ResolvedIntegration
} from 'modules/Integrations/domain/integrations.domain.types'

export const getActiveIntegrations =
  (ctx: {
    resolvedIntegrations: Partial<
      Record<Integration['id'], ResolvedIntegration>
    >
  }) =>
  (): Array<ResolvedIntegration> => {
    const integrationOrder = INTEGRATIONS_ORDER.flatMap(group => group.order)

    return integrationOrder
      .map(intId => ctx.resolvedIntegrations[intId])
      .filter(int => int?.state && ['CONNECTED'].includes(int.state))
      .filter((int): int is ResolvedIntegration => Boolean(int))
  }
