import { Link } from 'react-router-dom'
import { Text, Flex, Button } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold
} from '@sequencehq/design-tokens'

import { SubNavLink } from 'components/NavigationV3/components/NavLinks/SubNavLink'
import { NavLink as NavLinkProps } from 'components/NavigationV3/types'

import ChevronUpIcon from '@heroicons/react/16/solid/ChevronUpIcon'
import ChevronDownIcon from '@heroicons/react/16/solid/ChevronDownIcon'

type Props = NavLinkProps & { pathname: string }

const pathWithoutParams = (path: string): string => path.split('?')[0]

export const NavLink = ({
  icon: Icon,
  label,
  href,
  pathname,
  enabled = true,
  subLinks
}: Props) => {
  if (!enabled) {
    return null
  }

  const isSubLinkActive: boolean =
    subLinks?.some(subLink =>
      pathname.startsWith(pathWithoutParams(subLink.href))
    ) || false

  const isActive: boolean =
    pathname.startsWith(pathWithoutParams(href)) || isSubLinkActive

  const isExpanded: boolean = isSubLinkActive

  return (
    <>
      <Button
        as={Link}
        to={href}
        variant="unstyled"
        sx={{
          display: 'flex',
          transition: 'none',
          background: 'transparent',
          width: '100%',
          height: '32px',
          borderRadius: '6px',
          padding: '8px',
          justifyContent: 'flex-start',
          color: GreyGrey70,
          ...(isActive
            ? {
                background: GreyGrey20,
                color: GreyGrey90
              }
            : {})
        }}
        _hover={{
          background: GreyGrey20
        }}
      >
        <Flex width="100%" justifyContent="space-between">
          <Flex gap="8px">
            <Icon width="16px" height="16px" color="inherit" />
            <Text {...Lato13Bold} color="inherit">
              {label}
            </Text>
          </Flex>

          {subLinks && (
            <>
              {isExpanded ? (
                <ChevronUpIcon width="16px" height="16px" color="inherit" />
              ) : (
                <ChevronDownIcon width="16px" height="16px" color="inherit" />
              )}
            </>
          )}
        </Flex>
      </Button>

      {subLinks && isExpanded && (
        <Flex direction="column">
          {subLinks.map((subLink, index) => (
            <SubNavLink
              key={subLink.href}
              {...subLink}
              index={index}
              subLinks={subLinks}
              pathname={pathname}
            />
          ))}
        </Flex>
      )}
    </>
  )
}
