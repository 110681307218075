import { loadProduct } from './entityLoaders/loadProduct'
import { loadAccountSettings } from './entityLoaders/loadAccountSettings'
import { loadLedgerIntegrations } from './entityLoaders/loadLedgerIntegrations'
import { loadUsageMetrics } from './entityLoaders/loadUsageMetrics'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { loadListPrices } from '../variant.api.v1/entityLoaders'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Dashboardv99990101Api } from '@sequencehq/api/dist/clients/dashboard/v99990101'

type DataErrorResponse<T> =
  | {
      data: T
      error: null
    }
  | {
      data: null
      error: PricingEditorPortErrors
    }

export type v1ApiLoadedCommonData = {
  accountSettings: DataErrorResponse<Dashboardv20240509Api.GetSequenceAccountSettings.SequenceAccount>
  xeroIntegration: DataErrorResponse<DashboardApi20240730.GetIntegration.XeroIntegration>
  listPrices: Dashboardv20240509Api.GetListPrices.ListPricesList['items']
  product: DataErrorResponse<Dashboardv99990101Api.GetProduct.Product> & {
    taxCategoryName?: string
  }
  usageMetrics: DataErrorResponse<
    Dashboardv20240509Api.GetUsageMetrics.UsageMetric[]
  >
}

export const loadCommonData = async (args: {
  productId: string
}): Promise<v1ApiLoadedCommonData> => {
  const [
    accountSettingsResponse,
    integrationsResponse,
    listPricesResponse,
    productResponse,
    usageMetricsResponse
  ] = await Promise.all([
    loadAccountSettings(),
    loadLedgerIntegrations(),
    loadListPrices({ productId: args.productId }),
    loadProduct(args.productId),
    loadUsageMetrics()
  ])

  return Promise.resolve({
    accountSettings: accountSettingsResponse,
    xeroIntegration: integrationsResponse,
    listPrices: listPricesResponse,
    product: productResponse,
    usageMetrics: usageMetricsResponse
  })
}
