import { useCallback, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'

import {
  useLazyGetCustomerAliasesQuery,
  useLazyGetCustomersQuery
} from 'features/api'
import { ListCustomerAliasEndpointProductListCustomerAliasResponseModel } from 'features/api/integratedApi.generated'
import { CustomerAliasModel, CustomerModel } from '@sequencehq/core-models'

type UseCustomerNamesByAliasReturn = {
  isLoading: boolean
  customersWithAliases?: CustomerWithAliases[]
  aliases?: ListCustomerAliasEndpointProductListCustomerAliasResponseModel[]
}

export type CustomerWithAliases = {
  customerName: string
  aliases: string[]
}

export const useCustomerNamesByAlias = (): UseCustomerNamesByAliasReturn => {
  const [fetchAliases] = useLazyGetCustomerAliasesQuery({})
  const [fetchCustomers] = useLazyGetCustomersQuery({})

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [customersWithAliases, setCustomersWithAliases] = useState<
    CustomerWithAliases[] | undefined
  >()
  const [aliases, setAliases] = useState<
    ListCustomerAliasEndpointProductListCustomerAliasResponseModel[] | undefined
  >()

  const getAllAliases = useCallback(
    async ({
      after,
      acc = [],
      iterations = 0
    }: {
      after?: string
      acc?: CustomerAliasModel[]
      iterations?: number
    }): Promise<CustomerAliasModel[]> => {
      if (iterations === 20) {
        return acc
      }

      const aliasesPage = await fetchAliases({ after })
      const aliasesPageValue = aliasesPage.data?.value()

      if (aliasesPageValue?.pagination.after) {
        return getAllAliases({
          after: aliasesPageValue.pagination.after,
          acc: [...acc, ...aliasesPageValue.items],
          iterations: iterations + 1
        })
      } else {
        return [...acc, ...(aliasesPageValue?.items ?? [])]
      }
    },
    [fetchAliases]
  )

  const getAllCustomers = useCallback(
    async ({
      after,
      acc = [],
      iterations = 0
    }: {
      after?: string
      acc?: CustomerModel[]
      iterations?: number
    }): Promise<CustomerModel[]> => {
      if (iterations === 20) {
        return acc
      }

      const customersPage = await fetchCustomers({ after })
      const customersPageValue = customersPage.data?.value()

      if (customersPageValue?.pagination.after) {
        // has more pages
        return getAllCustomers({
          after: customersPageValue.pagination.after,
          acc: [...acc, ...customersPageValue.items],
          iterations: iterations + 1
        })
      } else {
        // last page
        return [...acc, ...(customersPageValue?.items ?? [])]
      }
    },
    [fetchCustomers]
  )

  useEffect(() => {
    const fetchData = async () => {
      const fetchedAliases = await getAllAliases({})
      const allCustomers = await getAllCustomers({})

      const allCustomersWithAliases: CustomerWithAliases[] =
        allCustomers.reduce((acc: CustomerWithAliases[], customer) => {
          const aliasesForCustomer = fetchedAliases.filter(
            alias => alias.customerId === customer.id
          )

          return [
            ...acc,
            {
              customerName: customer.legalName,
              aliases: [
                customer.id,
                ...aliasesForCustomer.map(alias => alias.value)
              ]
            }
          ]
        }, [])

      setAliases(fetchedAliases)
      setCustomersWithAliases(allCustomersWithAliases)
      setIsLoading(false)
    }

    try {
      void fetchData()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [fetchAliases, getAllAliases, getAllCustomers])

  return {
    isLoading,
    customersWithAliases,
    aliases
  }
}
