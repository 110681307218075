import { PopoverContacts } from '@sequencehq/quote-content'
import { usePopoverRecipients } from './usePopoverRecipients'

export const PopoverRecipients = () => {
  const hook = usePopoverRecipients()

  if (!hook.isVisible) {
    return null
  }

  return <PopoverContacts width="300px" contacts={hook.contacts} />
}
