import { UsageMetricModel } from '@sequencehq/core-models'
import { UsageMetricProvider } from 'components/CreateUsageMetric/UsageMetricProvider'
import { useDefaultUsageMetricDrawerConnector } from 'components/CreateUsageMetric/defaultRouteComponent/useDefaultUsageMetricDrawerConnector'
import { CreateUsageMetricDrawer } from 'components/CreateUsageMetric'

export type CreateUsageMetricDrawerProps = {
  onSuccess?: (newUsageMetric: UsageMetricModel) => void
  onCancel?: () => void
}

export const DefaultCreateUsageMetricDrawerRouteComponent = () => {
  const usageMetricDrawerProps = useDefaultUsageMetricDrawerConnector()

  return (
    <UsageMetricProvider>
      <CreateUsageMetricDrawer {...usageMetricDrawerProps} />
    </UsageMetricProvider>
  )
}
