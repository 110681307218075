import { FC } from 'react'

import { SimpleModal } from '@sequencehq/core-components'

import { useCustomerContactsContext } from 'modules/CustomerContacts/communication/internal/CustomerContacts.domain.context'
import { useContactModalForm } from '../forms/ContactModalForm/useContactModalForm'
import { ContactModalForm } from '../forms/ContactModalForm/ContactModalForm'

interface Props {
  contactId: string
  trigger: React.ReactNode | ((triggerModal: () => void) => React.ReactNode)
  onSubmit?: () => void
  onCancel?: () => void
}

export const EditContactModal: FC<Props> = ({
  contactId,
  trigger,
  onSubmit,
  onCancel
}) => {
  const ctx = useCustomerContactsContext()

  const { fieldsConfig, buttonSubmit } = useContactModalForm({
    contactId,
    fieldsValues: ctx.queries.formData,
    updateFields: ctx.mutators.internal.updateForm,
    createContact: ctx.mutators.external.out.createContact,
    updateContact: ctx.mutators.external.out.updateContact
  })

  return (
    <SimpleModal
      trigger={trigger}
      title="Edit contact"
      onCancel={onCancel}
      onSubmit={() => {
        buttonSubmit.onSubmit(onSubmit)
      }}
      cancelButtonText="Cancel"
      submitButtonText="Save contact"
      submitDisabled={buttonSubmit.disabled}
      closeOnSubmit={buttonSubmit.isValid}
    >
      <ContactModalForm fieldsConfig={fieldsConfig} />
    </SimpleModal>
  )
}
