import { UsageMetricModel } from '@sequencehq/core-models'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { CreateUsageMetricDrawerProps } from 'components/CreateUsageMetric/CreateUsageMetricDrawer'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type UsePricingEditorUsageMetricsDrawerConnector =
  () => CreateUsageMetricDrawerProps

export const usePricingEditorUsageMetricsDrawerConnector: UsePricingEditorUsageMetricsDrawerConnector =
  () => {
    const pricingEditorContext = usePricingEditorContext()
    const navigate = useNavigate()

    const onSuccess = useCallback(
      (newMetric: UsageMetricModel) => {
        pricingEditorContext.functions.updateData({
          metrics: {
            [newMetric.id]: newMetric
          }
        })
        pricingEditorContext.functions.updateFormData({
          [pricingEditorContext.data.formData.common.pricingModel]: {
            usageMetricId: newMetric.id
          }
        })
        navigate('..')
      },
      [navigate, pricingEditorContext]
    )

    const onCancel = useCallback(() => {
      navigate('..')
    }, [navigate])

    return {
      onSuccess,
      onCancel
    }
  }
