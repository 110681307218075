import { Box, Flex } from '@chakra-ui/react'
import { BillingTables } from './components/BillingTables'
import { CustomerMeta } from './components/CustomerMeta/CustomerMeta'
import { BillingScheduleTable } from './components/tables/BillingScheduleTable'
import { QuoteTable } from './components/tables/QuoteTable'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { useCustomerContext } from './hooks/useCustomerContext'

export const CustomerOverviewPage = () => {
  const { customerId } = useParams<{ customerId: string }>()
  const flags = useFlags()
  const { refetchCustomer } = useCustomerContext()

  invariant(customerId, 'customerId is required')

  useEffect(() => {
    void refetchCustomer()
  }, [refetchCustomer])

  return (
    <Flex
      minWidth="1024px"
      height="100%"
      display="flex"
      justifyContent="center"
    >
      <Box maxWidth="1024px" width="100%">
        <CustomerMeta />
        <BillingScheduleTable />
        <BillingTables />
        {flags?.showQuoteBuilder && <QuoteTable />}
        <Outlet />
      </Box>
    </Flex>
  )
}
