import {
  ActionHandler,
  DeleteAction
} from 'modules/Cube/domain/cube.domain.types'
import { compose, get, omit } from 'lodash/fp'

export const deleteData: ActionHandler<DeleteAction> = prevState => action => {
  const filteredData = compose(
    omit(action.payload.ids),
    get(action.payload.type)
  )(prevState.data)

  return {
    ...prevState,
    data: {
      ...prevState.data,
      [action.payload.type]: filteredData
    }
  }
}
