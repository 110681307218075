import { useEffect, useRef, useState } from 'react'
import { Box, Button, Collapse, Text, Wrap } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'
import { Badge } from '@sequencehq/core-components'
import {
  GreyGrey70,
  IndigoIndigo50,
  Lato13Regular
} from '@sequencehq/design-tokens'

interface CustomerAliasesProps {
  aliases: Array<{ id: string; value: string }>
}

const THRESHOLD = 80

export const CustomerAliases = ({ aliases }: CustomerAliasesProps) => {
  const refContainer = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(16)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setHeight(entry.contentRect.height)
      })
    })
    if (!refContainer.current) {
      return
    }
    observer.observe(refContainer.current)
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refContainer.current])

  const handleToggle = () => setIsExpanded(!isExpanded)

  return (
    <>
      <Box mt="4px">
        <Text {...Lato13Regular} color={GreyGrey70}>
          Aliases
        </Text>
      </Box>

      {aliases?.length ? (
        <>
          <Box>
            <Collapse
              startingHeight={height > THRESHOLD ? THRESHOLD : height}
              in={isExpanded}
            >
              <Wrap
                justify="start"
                spacing={1}
                maxW="340px"
                overflow="hidden"
                ref={refContainer}
              >
                {aliases.map(({ value, id }) => (
                  <Badge
                    key={id}
                    size="sm"
                    sentiment="monospaced"
                    textTransform="none"
                  >
                    {value}
                  </Badge>
                ))}
              </Wrap>
            </Collapse>
            {height > THRESHOLD && (
              <Button
                onClick={handleToggle}
                {...Lato13Regular}
                variant="component-library-ghost"
                p={0}
                mt={2}
                h="auto"
                _hover={{
                  boxShadow: 'none'
                }}
                rightIcon={
                  isExpanded ? (
                    <ChevronUpIcon color="#5F5CFF" height={16} width={16} />
                  ) : (
                    <ChevronDownIcon color="#5F5CFF" height={16} width={16} />
                  )
                }
              >
                {isExpanded ? 'Hide additional aliases' : 'Show all aliases'}
              </Button>
            )}
          </Box>
        </>
      ) : (
        <Box mt="4px">
          <Text {...Lato13Regular} color={IndigoIndigo50}>
            -
          </Text>
        </Box>
      )}
    </>
  )
}
