import { useCallback } from 'react'

import {
  dashboardv20240509Client,
  Dashboardv20240509Api
} from '@sequencehq/api/dashboard/v20240509'

import {
  CustomerContactsApiToInternalAdapterResult,
  customerContactsAdapter
} from '../adapters/customerContacts.adapters'

export type CustomerContactPostBody =
  Dashboardv20240509Api.PostCustomerContact.CustomerContactPostBody

export type CustomerContactPutBody =
  Dashboardv20240509Api.PutCustomerContact.CustomerContactPutBody

export type ApiCustomerContactBillingPreference =
  | 'PRIMARY'
  | 'STANDARD'
  | 'NONE'

export type ApiCustomerContact = {
  id: string
  name: string
  email: string
  billingPreference: ApiCustomerContactBillingPreference
  createdAt: string
  updatedAt: string
}

export type CustomerContactPorts = {
  load: () => Promise<CustomerContactsApiToInternalAdapterResult>
  createContact: (body: CustomerContactPostBody) => Promise<ApiCustomerContact>
  updateContact: ({
    contactId,
    body
  }: {
    contactId: string
    body: CustomerContactPutBody
  }) => Promise<ApiCustomerContact>
  archiveContact: (contactId: string) => Promise<ApiCustomerContact>
}

type CustomerContactsPortImplementation = () => CustomerContactPorts

type UseCustomerContactsPortImplementation = (props: {
  customerId: string
}) => CustomerContactsPortImplementation

export const useCustomerContactsPortImplementation: UseCustomerContactsPortImplementation =
  ({ customerId }) => {
    const load = useCallback(async () => {
      const response = await dashboardv20240509Client.getCustomerContacts({
        customerId
      })

      if (response.error || !response.data) {
        throw new Error('Unable to load customer contacts')
      }

      return customerContactsAdapter.in(customerId, response.data)
    }, [customerId])

    const createContact = useCallback(
      async (body: CustomerContactPostBody) => {
        const response = await dashboardv20240509Client.postCustomerContact({
          customerId,
          body
        })

        if (response.error || !response.data) {
          throw new Error('Unable to create new customer contact')
        }

        return response.data
      },
      [customerId]
    )

    const updateContact = useCallback(
      async ({
        contactId,
        body
      }: {
        contactId: string
        body: CustomerContactPutBody
      }) => {
        const response = await dashboardv20240509Client.putCustomerContact({
          customerId,
          contactId,
          body
        })

        if (response.error || !response.data) {
          throw new Error('Unable to update contact')
        }

        return response.data
      },
      [customerId]
    )

    const archiveContact = useCallback(
      async (contactId: string) => {
        const response =
          await dashboardv20240509Client.postCustomerContactArchive({
            customerId,
            contactId
          })

        if (response.error || !response.data) {
          throw new Error('Unable to archive contact')
        }

        return response.data
      },
      [customerId]
    )

    const portImplementation: CustomerContactsPortImplementation = useCallback(
      () => ({ load, createContact, updateContact, archiveContact }),
      [archiveContact, createContact, load, updateContact]
    )

    return portImplementation
  }
