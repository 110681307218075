import { Box } from '@chakra-ui/react'
import { InsightsOutstandingInvoicesModel } from '@sequencehq/core-models'
import { GreyGrey40, IndigoIndigo50 } from '@sequencehq/design-tokens'
import { useMemo } from 'react'

const getWidth = (numberOfInvoices: number, totalInvoices: number) =>
  Math.round((numberOfInvoices / totalInvoices) * 100)

const colors = {
  overdueFrom61days: IndigoIndigo50,
  overdueFrom31to60days: '#6B73FF',
  overdueFrom0to30days: '#9FA6FF',
  outstandingNow: GreyGrey40
} as const

export const OutstandingInvoicesGraph = ({
  data
}: {
  data: InsightsOutstandingInvoicesModel
}) => {
  const totalInvoices = useMemo(() => {
    if (!data) {
      return 0
    }

    return (
      data.overdueFrom61days.numberOfInvoices +
      data.overdueFrom31to60days.numberOfInvoices +
      data.overdueFrom0to30days.numberOfInvoices +
      data.outstandingNow.numberOfInvoices
    )
  }, [data])

  const {
    overdueFrom61days,
    overdueFrom31to60days,
    overdueFrom0to30days,
    outstandingNow
  } = data

  const values = [
    overdueFrom61days,
    overdueFrom31to60days,
    overdueFrom0to30days,
    outstandingNow
  ]

  const [background] = values.reduce(
    (acc: [string, number], { numberOfInvoices }, i) => {
      const [bg, prevWidth] = acc
      const width = getWidth(numberOfInvoices, totalInvoices)

      const nextWidth = prevWidth + width
      const color = Object.values(colors)[i]

      return [
        `${bg}, ${color} ${prevWidth}%, ${color} ${nextWidth}%`.replace(
          /^, /,
          ''
        ),
        prevWidth + width
      ]
    },
    ['', 0] as [string, number]
  )

  return (
    <Box
      background={`linear-gradient(to right, ${background})`}
      h="12px"
      marginTop="12px"
      borderRadius="20px"
    />
  )
}
