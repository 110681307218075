import { useCallback, useEffect, useMemo, useState } from 'react'
import { InvoiceModel } from '@sequencehq/core-models'
import { useLazyGetInvoicesByIdQuery } from 'features/api'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'

type UseLoadInvoice = (props: { invoiceId: InvoiceModel['id'] }) => {
  loading: boolean
  error?: LoaderError
  data?: {
    invoice: InvoiceModel
  }
  reloadInvoice: () => void
}

export const useLoadInvoice: UseLoadInvoice = ({ invoiceId }) => {
  const [invoice, setInvoice] = useState<InvoiceModel | undefined>(undefined)
  const [fetchInvoice, { isLoading, error }] = useLazyGetInvoicesByIdQuery()

  const getInvoice = useCallback(
    async (args: { invoiceId: string }) =>
      fetchInvoice({ id: args.invoiceId }).then(response => {
        return response.data?.value()
      }),
    [fetchInvoice]
  )

  const loadInvoice = useCallback(() => {
    getInvoice({ invoiceId })
      .then(loadedInvoice => {
        setInvoice(loadedInvoice)
      })
      .catch(e => {
        console.log(e)
      })
  }, [getInvoice, invoiceId])

  useEffect(() => {
    loadInvoice()
  }, [loadInvoice])

  const data = useMemo(() => {
    if (isLoading || error || !invoice) {
      return
    }

    return {
      invoice
    }
  }, [error, invoice, isLoading])

  return {
    loading: isLoading,
    error,
    data,
    reloadInvoice: loadInvoice
  }
}
