import { Banner, Button } from '@sequencehq/core-components'
import { Flex, Grid } from '@chakra-ui/react'
import Spinner from 'components/Loading'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { useIntegrationManagement } from './useIntegrationManagement'

export const IntegrationReconnectBanner = () => {
  const hook = useIntegrationManagement()

  if (!hook.reconnect.show) {
    return <></>
  }

  return (
    <Banner variant="warning" alignIcon="center">
      <Grid templateColumns="1fr auto" width="100%" alignItems="center">
        {hook.integration?.title} has been disconnected from Sequence.
        <Button
          variant="secondary"
          disabled={hook.reconnect.disabled}
          onClick={() => {
            void hook.reconnect.onClick()
          }}
        >
          <Flex gap="8px">
            {hook.reconnect.reconnecting && (
              <Spinner height="16px" width="16px" color={GreyGrey60} />
            )}{' '}
            {hook.reconnect.label}
          </Flex>
        </Button>
      </Grid>
    </Banner>
  )
}
