import {
  PostActionStage,
  PricingEditorReducerState
} from '../../pricingEditor.domain.types'
import { getAvailableFeatures } from './getAvailableFeatures'
import { getBreadcrumbSteps } from './getBreadcrumbs'

export const queries: PostActionStage = () => prevState => {
  const newQueryState: PricingEditorReducerState['queries'] = {
    ...prevState.queries,
    availableFeatures: getAvailableFeatures(prevState),
    breadcrumbSteps: getBreadcrumbSteps(prevState)
  }

  return {
    ...prevState,
    queries: newQueryState
  }
}
