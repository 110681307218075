import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { v1ApiLoadedData } from '../../loadData'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'

type LoadVariantPrice = (id: string) => Promise<
  | {
      data: NonNullable<v1ApiLoadedData['variantPrice']>
      error: null
    }
  | {
      data: null
      error: PricingEditorPortErrors
    }
>

export const loadVariantPrice: LoadVariantPrice = async (id: string) => {
  const variantPrice = await dashboardv20240509Client.getPrice({ id })

  if (!variantPrice.data || variantPrice.error) {
    return {
      data: null,
      error:
        variantPrice.res.status === 404
          ? PricingEditorPortErrors.NotFound
          : PricingEditorPortErrors.Other
    }
  }
  return {
    data: variantPrice.data,
    error: null
  }
}
