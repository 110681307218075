import {
  BasicTextStyleButton,
  BlockTypeSelect,
  ColorStyleButton,
  CreateLinkButton,
  FormattingToolbar,
  ReplaceImageButton,
  TextAlignButton
} from '@blocknote/react'

export const CustomFormattingToolbar = () => (
  <FormattingToolbar>
    <BlockTypeSelect key={'blockTypeSelect'} />
    <ReplaceImageButton />
    <BasicTextStyleButton basicTextStyle={'bold'} key={'boldStyleButton'} />
    <BasicTextStyleButton basicTextStyle={'italic'} key={'italicStyleButton'} />
    <BasicTextStyleButton
      basicTextStyle={'underline'}
      key={'underlineStyleButton'}
    />
    <BasicTextStyleButton basicTextStyle={'strike'} key={'strikeStyleButton'} />
    <TextAlignButton textAlignment={'left'} key={'textAlignLeftButton'} />
    <TextAlignButton textAlignment={'center'} key={'textAlignCenterButton'} />
    <TextAlignButton textAlignment={'right'} key={'textAlignRightButton'} />
    <ColorStyleButton key={'colorStyleButton'} />
    <CreateLinkButton key={'createLinkButton'} />
  </FormattingToolbar>
)
