import { useEffect, useMemo } from 'react'
import { dequal } from 'dequal'
import { ListPriceEditorProps } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useLoadListPriceEditor } from 'modules/Products/drawers/ListPriceEditor/hooks/useLoadListPriceEditor'
import {
  ListPriceEditorData,
  useListPriceEditor
} from 'modules/Products/drawers/ListPriceEditor/useListPriceEditor'
import { listPriceEditorLoaderToEditor } from 'modules/Products/drawers/ListPriceEditor/adapters/listPriceEditor.adapters'
import { STANDARD_AVAILABLE_FREQUENCIES } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { useNotifications } from 'lib/hooks/useNotifications'

type UseListPriceEditorRoot = (props: ListPriceEditorProps) => {
  listPriceEditor: ListPriceEditorData
}

export const useListPriceEditorRoot: UseListPriceEditorRoot = props => {
  const notifications = useNotifications()

  const loader = useLoadListPriceEditor({
    productId: props.productId,
    frequencies: STANDARD_AVAILABLE_FREQUENCIES,
    skip: {
      product: Boolean(props.existingData?.product)
    }
  })

  const listPriceEditor = useListPriceEditor({
    onSave: props.onSave,
    onClose: props.onClose
  })

  const transformedApiData = useMemo(() => {
    if (!loader.data || loader.error || loader.loading) {
      return
    }

    return listPriceEditorLoaderToEditor({
      loadedData: {
        ...loader.data,
        product: props.existingData?.product ?? loader.data.product,
        prices: loader.data?.prices ?? []
      },
      mode: props.mode,
      priceId: props.existingData?.price?.id,
      availableFrequencies: STANDARD_AVAILABLE_FREQUENCIES
    })
  }, [loader, props])

  useEffect(() => {
    if (
      listPriceEditor.editor.loaded &&
      (props.productId === listPriceEditor.data.product?.id || !props.productId)
    ) {
      return
    }

    if (!transformedApiData) {
      return
    }

    if (!transformedApiData.configuration.availableCurrencies.length) {
      notifications.displayNotification(
        'No available currencies are configured, cannot edit prices',
        {
          type: 'error'
        }
      )
      props.onClose()
    }
    listPriceEditor.functions.loadListPriceEditor(transformedApiData)
  }, [listPriceEditor, props, transformedApiData, notifications])

  /**
   * This use effect keeps the internal store in sync with any new data
   * supporting data (e.g. metrics) pushed to the store that may change
   * during editing.
   */
  useEffect(() => {
    if (!listPriceEditor.editor.loaded || !transformedApiData) {
      return
    }

    if (
      !dequal(transformedApiData.data.metrics, listPriceEditor.data.metrics)
    ) {
      listPriceEditor.functions.updateData({
        metrics: transformedApiData.data.metrics
      })
    }
    listPriceEditor
  }, [listPriceEditor, props, transformedApiData])

  return {
    listPriceEditor
  }
}
