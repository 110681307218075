import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  useToast
} from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import { noReturn } from 'lib/noReturn'
import { Field, Form } from 'react-final-form'
import { useStytchB2BClient } from '@stytch/react/dist/b2b'
import { GreyGrey30, Lato13Regular, RedRed70 } from '@sequencehq/design-tokens'
import { StytchAPIError } from '@stytch/vanilla-js'
import { FORM_ERROR } from 'final-form'
import { Toast } from 'components/TeamSettings/common/Toast'
import { composeValidators, isEmail } from '@sequencehq/validation'
import { useTeamSettings } from 'components/TeamSettings/hooks/useTeamSettings'

interface FormValues {
  memberEmail: string
}

interface InviteMemberFormProps {
  onCancel: () => void
}

export const InviteMemberForm = ({ onCancel }: InviteMemberFormProps) => {
  const { setAction, refetchMembers } = useTeamSettings()
  const stytch = useStytchB2BClient()
  const toast = useToast()

  const onSubmit = async (values: FormValues) => {
    try {
      const response = await stytch.magicLinks.email.invite({
        email_address: values.memberEmail,
        invite_template_id: 'magic_link_org_invite_email'
      })

      if (response.status_code === 200) {
        toast({
          position: 'bottom-left',
          isClosable: false,
          render: () => (
            <Toast
              type="success"
              title="Invitation sent!"
              description={`Invitation was sent to ${values.memberEmail}`}
              isClosable
            />
          )
        })
        refetchMembers()
      }

      setAction('view')
    } catch (e) {
      const error = e as StytchAPIError
      switch (error.error_type) {
        case 'duplicate_member_email':
          return {
            [FORM_ERROR]: `${values.memberEmail} already exists.`
          }
        default:
          toast({
            position: 'bottom-left',
            isClosable: false,
            render: () => (
              <Toast
                type="error"
                title="An error occurred"
                description={error.error_message}
                isClosable
              />
            )
          })
          setAction('view')
      }
    }
  }

  return (
    <Form<FormValues>
      destroyOnUnregister
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <>
            <Flex
              id="invite-member-form"
              as="form"
              p={6}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
            >
              <Field
                name="memberEmail"
                validate={composeValidators(isEmail)}
                render={({
                  input,
                  meta: { modifiedSinceLastSubmit, error }
                }) => (
                  <FormControl
                    isInvalid={
                      (error || submitError) && !modifiedSinceLastSubmit
                    }
                  >
                    <Label htmlFor="memberEmail">Send an invite to</Label>
                    <Input
                      id="memberEmail"
                      size="sm"
                      variant="formInput2"
                      placeholder="Ex: freddie@example.com"
                      type="email"
                      required
                      disabled={submitting}
                      {...input}
                    />
                    {submitError && !modifiedSinceLastSubmit && (
                      <FormErrorMessage {...Lato13Regular} color={RedRed70}>
                        {submitError}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />
            </Flex>
            <Flex
              p={3}
              justifyContent="end"
              gap={2}
              border={`1px solid ${GreyGrey30}`}
            >
              <Button variant="secondary2" size="sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary2"
                size="sm"
                type="submit"
                form="invite-member-form"
                isDisabled={submitting}
              >
                Send invite
              </Button>
            </Flex>
          </>
        )
      }}
    />
  )
}
