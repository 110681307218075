import { FC } from 'react'

interface GoogleSheetsIconProps {
  width?: number
  height?: number
}

export const GoogleSheetsIcon: FC<GoogleSheetsIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6101_14084)">
      <path
        d="M46.3236 49.6805C46.3231 50.2803 46.0846 50.8553 45.6605 51.2794C45.2364 51.7035 44.6614 51.942 44.0616 51.9425H19.9259C19.3261 51.9425 18.7509 51.7045 18.3264 51.2807C17.902 50.8569 17.6631 50.2821 17.6621 49.6823V14.3556C17.6619 14.0582 17.7203 13.7638 17.8339 13.489C17.9476 13.2142 18.1144 12.9646 18.3246 12.7543C18.5349 12.5441 18.7845 12.3773 19.0593 12.2636C19.3341 12.15 19.6285 12.0916 19.9259 12.0918H35.4156L46.3236 23.2161V49.6805Z"
        fill="#23A566"
      />
      <path
        d="M36.6152 22.9259L46.3229 32.2046V23.1512H37.7237C37.3483 23.1555 36.9782 23.0623 36.6495 22.8809L36.6152 22.9259Z"
        fill="#1C8F5A"
      />
      <path
        d="M46.3221 23.1981H37.6995C37.0997 23.1976 36.5246 22.9591 36.1005 22.535C35.6764 22.1109 35.438 21.5359 35.4375 20.9361V12.0305L46.3221 23.1981Z"
        fill="#8ED1B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4206 44.5959H24.5059V31.6638H39.4206V44.5959ZM32.8905 33.5293V35.7931H37.5876V33.5329L32.8905 33.5293ZM32.8905 37.2188V39.3816H37.5876V37.2079L32.8905 37.2188ZM32.8905 40.7604V42.7629H37.5876V40.7496L32.8905 40.7604ZM31.0467 42.7521V40.7496H26.3515V42.7521H31.0467ZM31.0467 39.3582V37.2079H26.3515V39.369L31.0467 39.3582ZM31.0467 35.8165V33.5293H26.3515V35.7931L31.0467 35.8165Z"
        fill="white"
      />
    </g>
    <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#F1F4F8" />
    <defs>
      <clipPath id="clip0_6101_14084">
        <rect
          width="28.7442"
          height="40"
          fill="white"
          transform="translate(17.627 12)"
        />
      </clipPath>
    </defs>
  </svg>
)
