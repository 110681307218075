import { useMemo } from 'react'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { ListPriceEditorFormData } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { FieldConfig } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/priceForm.types'
import { defaultLedgerAccountValues } from 'Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/ExternalLedger/useExternalLedgerForm'

type UseExternalLedgerForm = () => {
  fieldsConfig: {
    integrations: (FieldConfig<Record<string, string>> & {
      id: string
      label: string
      options: { value: string; label: string }[]
    })[]
  }
}

export const useExternalLedgerForm: UseExternalLedgerForm = () => {
  const listPriceEditorContext = useListPriceEditorContext()
  const presetValues = listPriceEditorContext.data.formData.common.externalIds
  const valuesWithDefaults = defaultLedgerAccountValues(presetValues, listPriceEditorContext.data.ledgerIntegrations)

  const { fields } = useForm<ListPriceEditorFormData['common']['externalIds']>({
    value: valuesWithDefaults,
    showValidationErrors: listPriceEditorContext.editor.showValidationErrors,
    fieldConfiguration: Object.values(
      listPriceEditorContext.data.ledgerIntegrations
    ).map(integration => ({
      property: integration.id,
      disabled: () =>
        listPriceEditorContext.functions.fieldIsDisabled(
          'common.externalIntegrations'
        ),
      options: integration.options.map(option => ({
        value: option.code,
        label: `${option.name} (${option.code})`
      })),
      validation: [required]
    })),
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      listPriceEditorContext.functions.updateEditor({
        formsValid: {
          externalLedger: isValid
        }
      })
    },
    onChange: newData => {
      listPriceEditorContext.functions.updateFormData({
        common: {
          externalIds: newData
        }
      })
    }
  })

  const enhancedFieldsConfig = useMemo(() => {
    return Object.entries(fields).map(([integrationId, fieldConfig]) => ({
      ...fieldConfig,
      id: integrationId,
      label: `${integrationId} ledger account`
    }))
  }, [fields])

  return {
    fieldsConfig: {
      integrations: enhancedFieldsConfig
    }
  }
}
