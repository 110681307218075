import {
  AvailableServices,
  IntegrationsPortErrors
} from 'modules/Integrations/domain'
import { IntegrationAppClient } from '@integration-app/sdk'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { isTestEnv } from 'lib/environment/environment'
import * as Sentry from '@sentry/react'

const integrationApp = new IntegrationAppClient({
  fetchToken: async () => {
    const res = await dashboardv20240509Client.getIntegrationAppToken()
    return res.data?.token ?? ''
  }
})

export const connectWithIntegrationApp = async (args: {
  service: AvailableServices
  parameters?: Record<string, unknown>
}): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  /**
   * We can't integration test with integration.app itself via integration
   * tests. Therefore, skip the integration.app part, but allow for
   * the test suite to configure a pass/fail scenario via localStorage.
   */
  if (isTestEnv()) {
    return {
      success: localStorage.getItem('integrationAppError') !== 'true',
      error: null
    }
  }

  const response = await integrationApp
    .integration(args.service)
    .connect({ parameters: args.parameters ?? {} })

  if (!(response && !response?.error)) {
    Sentry.captureException(
      new Error('Failed to connect with integration app'),
      {
        extra: {
          response
        }
      }
    )
  }

  return {
    success: Boolean(response && !response?.error),
    error: response?.error ? IntegrationsPortErrors.Other : null
  }
}
