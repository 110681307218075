import {
  ActionHandler,
  UpdateEditor
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import deepmerge from 'deepmerge'

export const updateEditor: ActionHandler<UpdateEditor> =
  prevState => action => {
    return {
      ...prevState,
      editor: deepmerge(prevState.editor, action.payload)
    }
  }
