import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { apiPriceToInternalPrice } from 'modules/Products/drawers/ListPriceEditor/adapters/listPriceEditor.adapters'
import { NEW_PRICE_PATTERN } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { ApiListPrice } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { dequal } from 'dequal'
import { useCallback, useEffect, useMemo, useState } from 'react'

type UseLoadPriceExternalIds = () => {
  loading: boolean
}

/**
 * This hook is used as a bandaid to protect against a bug in the /prices
 * list endpoint which means it does not include external ids when loading - this
 * should be removed when the list endpoint returns all of the data associated
 * with the entity.
 * @param props
 * @returns
 */
export const useLoadExistingPriceExternalIds: UseLoadPriceExternalIds = () => {
  const listPriceEditorContext = useListPriceEditorContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [listPrice, setListPrice] = useState<ApiListPrice | null>(null)

  const priceId = listPriceEditorContext.editor.selectedPrice
  const product = listPriceEditorContext.data.product
  const isANewPrice = priceId?.match(NEW_PRICE_PATTERN)

  const loadListPrice = useCallback(async () => {
    if (!priceId || isANewPrice) {
      return
    }

    setLoading(true)
    const response = await dashboardv20240509Client.getListPrice({
      id: priceId
    })

    if (response.error) {
      return
    }

    setListPrice(response.data)
    setLoading(false)
  }, [isANewPrice, priceId, setListPrice])

  useEffect(() => void loadListPrice(), [loadListPrice])

  const price = useMemo(() => {
    if (loading || !listPrice || isANewPrice) {
      return
    }

    return apiPriceToInternalPrice(product)(listPrice)
  }, [loading, listPrice, isANewPrice, product])

  useEffect(() => {
    if (
      !price ||
      !priceId ||
      listPriceEditorContext.derived.queries.priceHasBeenUpdated ||
      dequal(price, listPriceEditorContext.data.prices[priceId])
    ) {
      return
    }

    if (price.id !== listPriceEditorContext.editor.selectedPrice) {
      return
    }

    listPriceEditorContext.functions.updateData({
      prices: {
        [priceId]: {
          common: {
            externalIds: price.common.externalIds
          }
        }
      }
    })
  }, [price, listPriceEditorContext, priceId])

  return {
    loading
  }
}
