import {
  Integration,
  IntegrationsReducerState,
  ResolvedIntegration
} from 'modules/Integrations/domain/integrations.domain.types'
import { INTEGRATIONS_CONFIG } from 'modules/Integrations/integrationsConfig/integrations.config'

export const getResolvedIntegrations = (
  prevState: IntegrationsReducerState
): Partial<Record<Integration['id'], ResolvedIntegration>> => {
  return Object.values(prevState.data.integrations).reduce(
    (acc, integration) => {
      const configuration = INTEGRATIONS_CONFIG[integration.id]

      /**
       * We only include integrations when they have an associated
       * configuration. Otherwise we drop them, as they will not
       * have configuration for what to display in the UI.
       */
      if (!configuration) {
        return acc
      }

      return {
        ...acc,
        [integration.id]: {
          ...integration,
          ...configuration,
          isComingSoon:
            integration.state === 'COMING_SOON' &&
            !configuration.features.isDocumentationOnly
        }
      }
    },
    {}
  )
}
