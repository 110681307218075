import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

export const useIntegrationManagementHeader = () => {
  const { integrationId } = useParams<{ integrationId: string }>()
  const ctx = useIntegrationsDomainContext()

  const integration: ResolvedIntegration | null = useMemo(() => {
    if (!integrationId) {
      return null
    }

    return (
      ctx.queries.resolvedIntegrations[
        integrationId as ResolvedIntegration['id']
      ] ?? null
    )
  }, [ctx.queries.resolvedIntegrations, integrationId])

  invariant(integration, 'Integration not found')

  return {
    integration,
    onDocumentationClick: () => {
      window.open(integration.documentationUrl, '_blank')
    }
  }
}
