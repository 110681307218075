import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import type { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d.ts'
import { match } from 'ts-pattern'

type SelectPlaceHolderArgs<
  QD extends QueryDefinition<any, any, any, any, string>
> = {
  result: UseQueryHookResult<QD>
  successPrompt: string
}

export const selectPlaceholder = <
  QD extends QueryDefinition<any, any, any, any, string>
>({
  result,
  successPrompt
}: SelectPlaceHolderArgs<QD>) => {
  return match(result)
    .with({ isError: true }, () => 'Failed to load')
    .with({ isLoading: true }, () => 'Loading ...')
    .with({ isSuccess: true }, () => successPrompt)
    .otherwise(() => '')
}
