import { useCallback } from 'react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

export type v1ApiUser = Dashboardv20240509Api.GetUser.User

type UseLoadUser = () => (userId: string) => Promise<v1ApiUser | undefined>

export const useLoadUser: UseLoadUser = () => {
  const load = useCallback(async (userId: string) => {
    const userResponse = await dashboardv20240509Client.getUser({
      id: userId
    })

    if (userResponse.error || !userResponse.data) {
      return undefined
    }

    return userResponse.data
  }, [])

  return load
}
