import { useState } from 'react'
import { AvailableServices } from 'Integrations/domain'
import { useIntegrationsDomainContext } from 'Integrations/communication'
import { useNotifications } from 'lib/hooks/useNotifications'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'

export const useReconnectToIntegration = () => {
  const integrationsModuleCtx = useIntegrationsDomainContext()
  const { displayNotification } = useNotifications()
  const [reconnecting, setReconnecting] = useState(false)
  const reconnect = async (service: AvailableServices) => {
    setReconnecting(true)
    const { success } =
      await integrationsModuleCtx.mutators.external.out.connect(service)
    setReconnecting(false)

    if (!success) {
      displayNotification(
        `Failed to reconnect to ${getIntegrationName(service)}`,
        {
          type: 'error'
        }
      )
      return
    }

    displayNotification(`Reconnected to ${getIntegrationName(service)}`, {
      type: 'success'
    })
  }

  return {
    reconnecting,
    reconnect
  }
}
