import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import { DiscountEditorContent } from 'modules/Cube/view/common/drawers/discountEditor/drawer/content/DiscountEditorContent/DiscountEditorContent'
import { DiscountEditorRootProps } from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { useDiscountEditor } from 'modules/Cube/view/common/drawers/discountEditor/drawer/domainManagement/useDiscountEditor.domain'
import { DiscountEditorProvider } from 'modules/Cube/view/common/drawers/discountEditor/drawer/domainManagement/useDiscountEditorContext'
import { FC } from 'react'

export const DiscountEditorRoot: FC<DiscountEditorRootProps> = props => {
  const discountEditorDomain = useDiscountEditor(props)

  return (
    <DiscountEditorProvider value={discountEditorDomain}>
      <Drawer isOpen onClose={props.onClose}>
        <DrawerOverlay
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <DrawerContent
            width="fit-content"
            margin="8px 12px"
            borderRadius="8px"
          >
            <DiscountEditorContent />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </DiscountEditorProvider>
  )
}
