import {
  ActionHandler,
  LoadListPriceEditorAction
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { INITIAL_LIST_PRICE_STATE } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'

export const loadListPriceEditor: ActionHandler<LoadListPriceEditorAction> =
  () => action => {
    return {
      ...INITIAL_LIST_PRICE_STATE,
      ...action.payload,
      editor: {
        ...action.payload.editor,
        formsValid: {},
        loaded: true
      },
      initialData: {
        price: action.payload.editor.selectedPrice
          ? action.payload.data.prices[action.payload.editor.selectedPrice] ??
            null
          : null,
        product: action.payload.data.product
      }
    }
  }
