import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { NewPricingEditorCubeRoute } from './NewPricingEditor.route.connector'
import { PricingEditorCubeRoute } from './PricingEditor.route.connector'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * If a route component needs access to data from the billing schedule context, it
 * should use a connector hook.
 * @returns
 */
export const PricingEditorCubeRouteWrapper = () => {
  const context = useCubeContext()

  if (context.queries.rawData.configuration.features.useNewPricingEditor) {
    return <NewPricingEditorCubeRoute />
  }

  return <PricingEditorCubeRoute />
}
