import { CurrencyInput } from 'components/FormComponents'
import { useGetSequenceAccountSettingsQuery } from 'features/api'
import { FC } from 'react'

type CurrencyInputWrapperProps = {
  disabled?: boolean
  width?: string
}

export const CurrencyInputWrapper: FC<CurrencyInputWrapperProps> = ({
  disabled,
  width
}) => {
  const { data: accountSettings } = useGetSequenceAccountSettingsQuery()
  const availableCurrencies = accountSettings?.value()?.enabledCurrencies

  return (
    <CurrencyInput
      disabled={disabled || !availableCurrencies}
      width={width}
      availableCurrencies={availableCurrencies || []}
    ></CurrencyInput>
  )
}
