import { required } from '@sequencehq/validation'
import { FormFields, useForm } from '@sequencehq/utils'
import { PricingEditorReducerState } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback } from 'react'

type ProductFields = Pick<
  PricingEditorReducerState['data']['product'],
  'name' | 'label'
>

type UseProductFieldsForm = () => {
  fieldsConfig: FormFields<ProductFields>
}

export const useProductFieldsForm: UseProductFieldsForm = () => {
  const pricingEditorContext = usePricingEditorContext()

  const checkIfProductFieldDisabledByDomain = useCallback(
    ({ property }: { property: keyof ProductFields }) => {
      return pricingEditorContext.functions.fieldIsDisabled(
        `product.${property}`
      )
    },
    [pricingEditorContext.functions]
  )

  const { fields } = useForm<ProductFields>({
    /** Data in */
    value: pricingEditorContext.data.product,
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    /** Field config */
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required],
        disabled: checkIfProductFieldDisabledByDomain
      },
      {
        property: 'label',
        disabled: checkIfProductFieldDisabledByDomain
      }
    ],
    /** Data out */
    onChange: newData => {
      pricingEditorContext.functions.updateData({
        product: newData
      })
    },
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          product: isValid
        }
      })
    }
  })

  return {
    fieldsConfig: fields
  }
}
