import {
  Modal,
  ModalContextProvider,
  PercentageInput,
  SimpleModalUI,
  TextAreaField,
  TextInputField,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { type ReactNode, useState } from 'react'
import type { TaxCategory, TaxRate } from 'Settings/domain/taxRates.types'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import {
  decimalFromPercentage,
  percentageFromDecimal,
  useForm
} from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { GreyGrey10, GreyGrey30 } from '@sequencehq/design-tokens'
import {
  countriesAlpha2,
  countriesAlpha2Emojis
} from '@sequencehq/api/commonEnums'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

type FormValues = {
  name: string
  description: string
  countries:
    | Partial<{
        [country in TaxRate['country']]: TaxRate['amount']
      }>
    | undefined
}

interface Props {
  trigger: ReactNode | ((openModal: () => void) => ReactNode)
  onSubmit: (values: FormValues, taxCategory?: TaxCategory) => Promise<void>
  taxCategory?: TaxCategory
}

const getDefaultValues = (
  countries: TaxRate['country'][] = [],
  taxCategory?: TaxCategory
): FormValues => ({
  name: taxCategory?.name ?? '',
  description: taxCategory?.description ?? '',
  countries: countries?.reduce((acc, country) => {
    const taxRate = taxCategory?.taxRates?.find(
      rate => rate.country === country
    )

    return {
      ...acc,
      [country]: taxRate?.amount ? percentageFromDecimal(taxRate.amount) : ''
    }
  }, {})
})

const TaxCategoryModalUI = withUnmountOnModalClose(
  ({ taxCategory, onSubmit }: Omit<Props, 'trigger'>) => {
    // Get tax rates in the modal so there's always up to date data
    const { data } = useQuery(dashboardv99990101Client.getTaxRates)

    const isEditing = !!taxCategory?.id

    const uniqueCountries = Array.from(
      new Set(data?.items.map(({ country }) => country))
    ).sort()

    const defaultValues: FormValues = getDefaultValues(
      uniqueCountries,
      taxCategory
    )

    const [formData, setFormData] = useState<FormValues>(defaultValues)

    const countriesTaxRatesFields = uniqueCountries.map(country => ({
      property: `countries.${country}` as `countries.${TaxRate['country']}`,
      validation: []
    }))

    const { fields: fieldsConfig, queries } = useForm<FormValues>({
      value: defaultValues,
      fieldConfiguration: [
        {
          property: 'name',
          validation: [required]
        },
        {
          property: 'description',
          validation: []
        },
        ...countriesTaxRatesFields
      ],
      onChange: newData => {
        if (newData.countries) {
          newData.countries = Object.entries(newData.countries).reduce(
            (acc, [country, amount]) => {
              return {
                ...acc,
                [country]: amount ? decimalFromPercentage(amount) : ''
              }
            },
            {}
          )
        }

        setFormData(newData)
      }
    })

    const [editedTaxRates, setEditedTaxRates] = useState<TaxRate['country'][]>(
      []
    )

    const handleSubmit = async (formValues: FormValues) => {
      // We want to pass only the countries tax rates that have been edited
      const modifiedCountries = Object.entries(
        formValues.countries || {}
      ).reduce(
        (acc, [country, amount]) => {
          if (editedTaxRates.includes(country as TaxRate['country'])) {
            return {
              ...acc,
              [country]: amount
            }
          }

          return acc
        },
        {} as FormValues['countries']
      )

      const formDataWithOnlyModifiedCountries = {
        ...formValues,
        countries: modifiedCountries
      }

      await onSubmit(formDataWithOnlyModifiedCountries, taxCategory)
    }

    return (
      <SimpleModalUI
        data-testid="addTaxCategory"
        title={isEditing ? 'Edit tax category' : 'Create tax category'}
        cancelButtonText="Cancel"
        submitButtonText={isEditing ? 'Save changes' : 'Create tax category'}
        submitDisabled={!queries.isValid}
        closeOnCancel
        closeOnSubmit
        onSubmit={() => {
          void handleSubmit(formData)
        }}
      >
        <Flex flexDirection="column">
          <Box>
            <Label>Name</Label>
            <TextInputField
              data-testid="addTaxCategory.name"
              value={fieldsConfig.name.value}
              validationErrors={fieldsConfig.name.validationErrors}
              onChange={event => fieldsConfig.name.onChange(event.target.value)}
              placeholder="Tax category name"
            />
          </Box>
          <Box>
            <Label>Description</Label>
            <TextAreaField
              data-testid="addTaxCategory.description"
              value={fieldsConfig.description.value}
              validationErrors={fieldsConfig.description.validationErrors}
              onChange={fieldsConfig.description.onChange}
              placeholder="Tax category description"
            />
          </Box>
          {uniqueCountries.length > 0 && (
            <>
              <Divider mb={2} borderBottomColor={GreyGrey30} />
              <Box mt={2}>
                <Label>Tax rates</Label>
                <Flex flexDirection="column" gap={2}>
                  {uniqueCountries.map(country => (
                    <Flex
                      key={country}
                      backgroundColor={GreyGrey10}
                      height="40px"
                      paddingLeft={4}
                      paddingRight={1}
                      borderRadius={6}
                      py={2}
                      justifyContent="space-between"
                      align="center"
                    >
                      <Flex gap={2} align="center">
                        <Text fontSize="20px">
                          {countriesAlpha2Emojis[country]}
                        </Text>
                        <Text>{countriesAlpha2[country]}</Text>
                      </Flex>
                      <Box width="80px">
                        <PercentageInput
                          min={0}
                          max={100}
                          data-testid={`addTaxCategory.${country}`}
                          variant="v2"
                          precision={2}
                          placeholder="0"
                          value={fieldsConfig[`countries.${country}`].value}
                          onChange={value => {
                            setEditedTaxRates(prevState => [
                              // Set to make sure the countries in array are not duplicated
                              ...new Set([
                                ...prevState,
                                country as TaxRate['country']
                              ])
                            ])

                            fieldsConfig[`countries.${country}`].onChange(
                              value as never
                            )
                          }}
                        />
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              </Box>
            </>
          )}
        </Flex>
      </SimpleModalUI>
    )
  }
)

const TaxCategoryModal = ({ trigger, taxCategory, onSubmit }: Props) => {
  return (
    <ModalContextProvider>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <TaxCategoryModalUI onSubmit={onSubmit} taxCategory={taxCategory} />
    </ModalContextProvider>
  )
}

export default TaxCategoryModal
