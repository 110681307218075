import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'

type ListPriceArchiveModalProps = {
  submitting: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
}

export const ListPriceArchiveModal = (props: ListPriceArchiveModalProps) => {
  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
          top="118px"
          width="414px"
          maxWidth="414px"
        >
          <Box color={GreyGrey100} {...Lato14Bold} marginBottom="4px">
            Archive list price
          </Box>
          <Box {...Lato13Regular} color={GreyGrey80} marginBottom="16px">
            The list price will be archived and will no longer be visible in
            billing schedules or quote builder.
          </Box>
          <Flex gap="8px" justifyContent="flex-end">
            <Button
              variant="secondary2"
              onClick={props.onClose}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              isLoading={props.submitting}
              onClick={() => void props.onConfirm()}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              Archive
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
