import { Select as ChakraSelect } from '@chakra-ui/react'
import type { SelectProps as ChakraSelectProps } from '@chakra-ui/react'
import { Option } from '@sequencehq/core-models'
import { FC } from 'react'

export type SelectProps = {
  options: Option[]
  disabled?: boolean
  optionDisabled?: (option: string) => boolean
} & ChakraSelectProps

export const Select: FC<SelectProps> = ({
  options,
  disabled,
  optionDisabled,
  ...props
}) => {
  return (
    <ChakraSelect variant="sequence-form" disabled={!!disabled} {...props}>
      {options.map(({ label, value }) => (
        <option
          key={value}
          value={value}
          disabled={optionDisabled ? optionDisabled(value) : false}
        >
          {label}
        </option>
      ))}
    </ChakraSelect>
  )
}
