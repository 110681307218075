import { Center } from '@chakra-ui/react'
import { IntegrationService } from '@sequencehq/core-models'

import { XeroIcon } from 'components/icons/XeroIcon'
import { FC } from 'react'

export interface ProgressProps {
  service: IntegrationService
  currentCount: number
  customerCount: number
}

export const Progress: FC<ProgressProps> = ({
  service,
  currentCount,
  customerCount
}) => {
  switch (service) {
    case 'Xero': {
      let colours = 'gray.30, gray.30, gray.30, gray.30'
      if (currentCount / customerCount < 0.25) {
        colours = 'gray.30, gray.30, gray.30, gray.30'
      } else if (currentCount / customerCount < 0.5) {
        colours = 'turquoise.60, gray.30, gray.30, gray.30'
      } else if (currentCount / customerCount < 0.75) {
        colours = 'turquoise.60, turquoise.60, gray.30, gray.30'
      } else if (currentCount / customerCount < 1) {
        colours = 'turquoise.60, turquoise.60, turquoise.60, gray.30'
      } else {
        colours = 'turquoise.60, turquoise.60, turquoise.60, turquoise.60'
      }

      return (
        <>
          <Center
            bgGradient={`conic(${colours})`}
            height="70px"
            width="70px"
            borderRadius="50%"
          >
            <Center
              backgroundColor="white"
              height="66px"
              width="66px"
              borderRadius="50%"
            >
              <XeroIcon></XeroIcon>
            </Center>
          </Center>
        </>
      )
    }
  }
  return <></>
}
