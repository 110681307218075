import { Box, Icon, Text } from '@chakra-ui/react'
import { CommonFieldsForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/CommonFields/CommonFieldsForm'
import { ExternalLedgerForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/ExternalLedger/ExternalLedgerForm'
import { ProductFieldsForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/ProductFields/ProductFieldsForm'
import { useLoadExistingPriceExternalIds } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/useLoadExistingPriceExternalIds'
import { usePriceForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/usePriceForm'
import { Lato14Bold } from '@sequencehq/design-tokens'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import { HiddenFieldsForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/CommonFields/HiddenFieldsForm'
import { ReadOnlyDetails } from './forms/ReadOnlyDetails/ReadOnlyDetails'

export const PriceForm = () => {
  const {
    ActiveForm,
    ActiveHiddenFieldsForm,
    hidePriceFields,
    showProductFields,
    showAdditionalOptions,
    toggleAdditionalOptions
  } = usePriceForm()
  useLoadExistingPriceExternalIds()

  return (
    <Box width="100%" minWidth="400px" data-testid="price-form" padding="24px">
      {hidePriceFields && (
        <Box marginBottom="16px">
          <ReadOnlyDetails />
        </Box>
      )}
      {showProductFields && <ProductFieldsForm />}
      <CommonFieldsForm />
      {ActiveForm && <ActiveForm />}
      <ExternalLedgerForm />
      {hidePriceFields && (
        <>
          <Box
            display="flex"
            gap="4px"
            my="16px"
            padding="8px 0px"
            cursor="pointer"
            onClick={toggleAdditionalOptions}
          >
            <Text {...Lato14Bold} color="#5F5CFF">
              Show additional fields
            </Text>
            {showAdditionalOptions ? (
              <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
            ) : (
              <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
            )}
          </Box>
          <Box
            width="100%"
            data-testid="common-fields"
            marginBottom="16px"
            /**
             * I am of great sad.
             *
             * We need to rebuild these price forms to be less modular. This is an artifact
             * of the modular form approach, and if we don't render the 'hidden'
             * the form, it doesn't connect with the central store to pass along the
             * validation result.
             *
             * Now that we are more used to hexagons, we can just move this validation
             * back into the store when we refactor the view.
             */
            visibility={showAdditionalOptions ? 'visible' : 'hidden'}
          >
            <HiddenFieldsForm />
            {ActiveHiddenFieldsForm && <ActiveHiddenFieldsForm />}
          </Box>
        </>
      )}
    </Box>
  )
}
