import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react'
import {
  BillingScheduleModel,
  CreditNoteModel,
  CustomerModel,
  InvoiceMerchantDetailsModel,
  InvoiceModel,
  LineItemCreditNoteModel,
  LineItemGroupCreditNoteModel,
  LineItemGroupModel,
  LineItemModel,
  MerchantModel,
  PlanModel,
  toInvoiceStatusBadgeProps
} from '@sequencehq/core-models'
import { CreditNotePreview, Section } from '@sequencehq/invoice-content'
import CustomerSidebar from 'components/CreditNote/CustomerSidebar'
import DefaultSidebar from 'components/CreditNote/DefaultSidebar'
import DetailsSidebar from 'components/CreditNote/DetailsSidebar'
import LineItemGroupSidebar from 'components/CreditNote/LineItemGroupSidebar'
import LineItemSidebar from 'components/CreditNote/LineItemSidebar'
import SelectLineItemsSidebar from 'components/CreditNote/SelectLineItemsSidebar'
import { ArrowLeftIcon, CheckboxIcon, CreditNoteIcon } from 'components/icons'
import InvoiceLineItemSelect from 'components/InvoiceLineItemSelect'
import { openOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { FC, useState } from 'react'
import { AtSign, Download, Send, X } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { borderColor } from '@sequencehq/core-theme'
import { Badge, CrossIcon, PageTitle } from '@sequencehq/core-components'
import { useCreditNotePdfDownload } from 'components/CreditNote/useCreditNotePdfDownload'

interface CreditNoteProps {
  creditNote: CreditNoteModel
  lineItems: LineItemCreditNoteModel[]
  lineItemGroups: LineItemGroupCreditNoteModel[]
  billingSchedule: BillingScheduleModel | undefined
  plan: PlanModel | undefined
  setLineItems: (
    fn: (i: LineItemCreditNoteModel[]) => LineItemCreditNoteModel[]
  ) => void
  setLineItemGroups: (
    fn: (i: LineItemGroupCreditNoteModel[]) => LineItemGroupCreditNoteModel[]
  ) => void
  setCreditNote: (inv: CreditNoteModel) => void
  cancelChanges: () => void
  merchant: MerchantModel
  invoiceLineItems: LineItemModel[]
  invoiceLineItemGroups: LineItemGroupModel[]
  invoice: InvoiceModel | undefined
  initialEditLineItemsMode: boolean
  merchantDetails: InvoiceMerchantDetailsModel
  customer: CustomerModel
}

const CreditNote: FC<CreditNoteProps> = ({
  creditNote,
  lineItems,
  lineItemGroups,
  billingSchedule,
  plan,
  setLineItems,
  setLineItemGroups,
  setCreditNote,
  cancelChanges,
  merchant,
  invoiceLineItems,
  invoiceLineItemGroups,
  invoice,
  initialEditLineItemsMode,
  merchantDetails,
  customer
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const disabledLineItems = [
    ...lineItems.map(lineItem => lineItem.lineItemId ?? '')
  ]
  const { downloadPdf } = useCreditNotePdfDownload()

  const [selection, setSelection] = useState<Section | undefined>(undefined)

  const [hasChanges, setHasChanges] = useState<boolean>(false)

  const [editLineItemsMode, setEditLineItemsMode] = useState<boolean>(
    initialEditLineItemsMode
  )

  const [selectedLineItemsFromInvoice, setSelectLineItemsFromInvoice] =
    useState<string[]>([])

  function updateLineItem(item: LineItemCreditNoteModel): void {
    setLineItems(items =>
      items.map(i => {
        if (i.id === item.id) {
          return item
        } else {
          return i
        }
      })
    )
  }

  function addLineItem(item: LineItemCreditNoteModel): void {
    setLineItems(items =>
      items.find(i => i.id === item.id)
        ? items.map(i => {
            if (i.id === item.id) {
              return item
            } else {
              return i
            }
          })
        : [...items, item]
    )
  }

  function addLineItemGroup(group: LineItemGroupCreditNoteModel): void {
    setLineItemGroups(groups =>
      groups.find(g => g.id === group.id)
        ? groups.map(g => {
            if (g.id === group.id) {
              return group
            } else {
              return g
            }
          })
        : [...groups, group]
    )
  }

  function cancel() {
    changeSelection(undefined)
  }

  function changeSelection(newSelection: Section | undefined) {
    if (hasChanges) {
      dispatch(
        openOverlay({
          content: 'cancelChangesModal',
          data: {
            cancel: () => {
              setSelection(newSelection)
              cancelChanges()
              setHasChanges(false)
            }
          }
        })
      )
    } else {
      setSelection(newSelection)
    }
  }

  return (
    <Flex flexDirection="column" height="100vh" flex={1}>
      <Flex
        backgroundColor="white"
        flex="none"
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
        height="64px"
        px={6}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor={borderColor}
      >
        <PageTitle
          title={
            creditNote.creditNoteNumber
              ? [creditNote.creditNoteNumber, 'Credit Notes']
              : ['Draft Credit Note', 'Credit Notes']
          }
        />
        <Text textStyle="titleBar">Credit Note</Text>
        <Box w="4px"></Box>
        <CreditNoteIcon></CreditNoteIcon>
        <Box w="4px"></Box>
        {creditNote.creditNoteNumber && (
          <Text textStyle="default">#{creditNote.creditNoteNumber}</Text>
        )}
        <Box w="8px"></Box>
        <Badge {...toInvoiceStatusBadgeProps({ status: creditNote.status })} />
        <Flex flex={1} justifyContent="flex-end">
          <IconButton
            aria-label="Go back to credit notes list"
            icon={<CrossIcon />}
            onClick={() =>
              hasChanges
                ? dispatch(
                    openOverlay({
                      content: 'cancelChangesModal',
                      data: {
                        cancel: () => {
                          navigate('/credit-notes')
                        }
                      }
                    })
                  )
                : navigate('/credit-notes')
            }
            variant="clear"
          />
        </Flex>
      </Flex>
      <Flex minHeight="0" flex={1}>
        <Box w="70%" overflow="scroll">
          {editLineItemsMode ? (
            <Flex
              bg="gray.10"
              flex={1}
              alignItems="center"
              minHeight="0px"
              overflowY="auto"
              flexDirection="column"
              px="70px"
              pt={8}
              pb="24px"
            >
              <InvoiceLineItemSelect
                lineItems={invoiceLineItems}
                lineItemGroups={invoiceLineItemGroups}
                currency={creditNote.currency}
                invoice={invoice}
                selectedLineItems={selectedLineItemsFromInvoice}
                setSelectedLineItems={(lineItemIds: string[]) => {
                  setSelectLineItemsFromInvoice(lineItemIds)
                }}
                disabledLineItems={disabledLineItems}
              ></InvoiceLineItemSelect>
            </Flex>
          ) : (
            <Flex
              bg="gray.10"
              flex={1}
              alignItems="center"
              minHeight="0px"
              overflowY="auto"
              flexDirection="column"
              px="70px"
              pt={8}
              pb="24px"
            >
              <Flex justifyContent="center">
                {creditNote.status === 'DRAFT' && creditNote.invoiceId && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setEditLineItemsMode(true)
                      }}
                      paddingLeft="16px"
                      paddingRight="24px"
                      isDisabled={invoiceLineItems.length === 0}
                    >
                      <ArrowLeftIcon></ArrowLeftIcon>
                      <Box w="4px"></Box>Edit line items
                    </Button>
                    <Box w="24px"></Box>
                  </>
                )}

                <Button
                  variant="secondary"
                  onClick={() => {
                    downloadPdf({
                      creditNoteId: creditNote.id,
                      creditNoteNumber: creditNote.creditNoteNumber,
                      creditNoteStatus: creditNote.status
                    })
                  }}
                  paddingLeft="16px"
                  paddingRight="24px"
                >
                  <Download></Download>
                  <Box w="4px"></Box>Download
                </Button>

                {creditNote.status === 'DRAFT' && (
                  <>
                    <Box w="24px"></Box>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        dispatch(
                          openOverlay({
                            content: 'voidCreditNoteModal',
                            data: {
                              id: creditNote.id,
                              customerName: creditNote.customerLegalCompanyName
                            }
                          })
                        )
                      }
                      paddingLeft="16px"
                      paddingRight="24px"
                    >
                      <X></X>
                      <Box w="4px"></Box>
                      Void
                    </Button>
                    <Box w="24px"></Box>
                    <Button
                      variant="primary"
                      onClick={() =>
                        dispatch(
                          openOverlay({
                            content: 'finalizeCreditNoteModal',
                            data: {
                              id: creditNote.id,
                              customerName: creditNote.customerLegalCompanyName
                            }
                          })
                        )
                      }
                      paddingLeft="16px"
                      paddingRight="24px"
                    >
                      <CheckboxIcon></CheckboxIcon>
                      <Box w="4px"></Box>Finalize
                    </Button>
                    <Box w="24px"></Box>
                    <Button
                      variant="primary"
                      onClick={() =>
                        dispatch(
                          openOverlay({
                            content: 'finalizeAndSendCreditNoteModal',
                            data: {
                              id: creditNote.id,
                              customerName: creditNote.customerLegalCompanyName,
                              customerEmails: creditNote.customerEmails
                            }
                          })
                        )
                      }
                      paddingLeft="16px"
                      paddingRight="24px"
                    >
                      <Send size={18}></Send>
                      <Box w="4px"></Box>Send
                    </Button>
                    <Box w="24px"></Box>
                    <Button
                      variant="primary"
                      onClick={() =>
                        dispatch(
                          openOverlay({
                            content: 'sendTestCreditNoteEmailModal',
                            data: {
                              id: creditNote.id
                            }
                          })
                        )
                      }
                      paddingLeft="16px"
                      paddingRight="24px"
                    >
                      <AtSign size={18}></AtSign>
                      <Box w="4px"></Box>Send test email
                    </Button>

                    <Box w="24px"></Box>
                  </>
                )}
                {creditNote.status === 'FINAL' && (
                  <>
                    <Box w="24px"></Box>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        dispatch(
                          openOverlay({
                            content: 'voidCreditNoteModal',
                            data: {
                              id: creditNote.id,
                              customerName: creditNote.customerLegalCompanyName
                            }
                          })
                        )
                      }
                      paddingLeft="16px"
                      paddingRight="24px"
                    >
                      <X></X>
                      <Box w="4px"></Box>
                      Void
                    </Button>
                    <Box w="24px"></Box>
                    <Button
                      variant="primary"
                      onClick={() =>
                        dispatch(
                          openOverlay({
                            content: 'sendCreditNoteModal',
                            data: {
                              id: creditNote.id,
                              customerName: creditNote.customerLegalCompanyName,
                              customerEmails: creditNote.customerEmails
                            }
                          })
                        )
                      }
                      paddingLeft="16px"
                      paddingRight="24px"
                    >
                      <Send size={18}></Send>
                      <Box w="4px"></Box>Send
                    </Button>
                    <Box w="24px"></Box>
                    <Button
                      variant="primary"
                      onClick={() =>
                        dispatch(
                          openOverlay({
                            content: 'sendTestCreditNoteEmailModal',
                            data: {
                              id: creditNote.id
                            }
                          })
                        )
                      }
                      paddingLeft="16px"
                      paddingRight="24px"
                    >
                      <AtSign size={18}></AtSign>
                      <Box w="4px"></Box>Send test email
                    </Button>
                    <Box w="24px"></Box>
                  </>
                )}
              </Flex>
              <Box h="32px"></Box>
              {creditNote.status === 'DRAFT' && (
                <Text textStyle="hint">
                  Move your mouse pointer over the credit note to edit details.
                </Text>
              )}
              {creditNote.status === 'VOIDED' && (
                <Text textStyle="hint">Editing is unavailable.</Text>
              )}
              {creditNote.status === 'FINAL' && (
                <Text textStyle="hint">
                  Editing is unavailable for a finalized credit note.
                </Text>
              )}
              {creditNote.status === 'SENT' && (
                <Text textStyle="hint">Editing is unavailable.</Text>
              )}
              <Box position="relative">
                <Box position="relative">
                  <CreditNotePreviewWrapper
                    creditNote={creditNote}
                    lineItems={lineItems}
                    lineItemGroups={lineItemGroups}
                    clickSection={changeSelection}
                    selectedSection={selection}
                    deleteLineItem={(id, title) => {
                      dispatch(
                        openOverlay({
                          content: 'deleteCreditNoteLineItemModal',
                          data: {
                            creditNoteId: creditNote.id,
                            id,
                            title
                          }
                        })
                      )
                      cancel()
                    }}
                    deleteLineItemGroup={(id, title) => {
                      dispatch(
                        openOverlay({
                          content: 'deleteCreditNoteLineItemGroupModal',
                          data: {
                            creditNoteId: creditNote.id,
                            id,
                            title
                          }
                        })
                      )
                      cancel()
                    }}
                    merchant={merchant}
                    merchantDetails={merchantDetails}
                  ></CreditNotePreviewWrapper>
                </Box>
                {creditNote.status === 'VOIDED' && (
                  <Box
                    backgroundColor="red.30"
                    w="278px"
                    h="35px"
                    padding="8px"
                    position="absolute"
                    top="80px"
                    left="280px"
                  >
                    <Text
                      color="red.80"
                      fontWeight="700"
                      fontSize="16px"
                      textAlign="center"
                    >
                      This is a voided credit note.
                    </Text>
                  </Box>
                )}
                {creditNote.status === 'FINAL' && (
                  <Box
                    backgroundColor="gray.20"
                    w="278px"
                    h="48px"
                    padding="8px"
                    position="absolute"
                    top="80px"
                    left="280px"
                  >
                    <Text
                      color="gray.70"
                      fontWeight="400"
                      fontSize="13px"
                      textAlign="center"
                    >
                      Editing is not available on finalized credit notes.
                    </Text>
                    {/* <Text
                      color="gray.70"
                      fontWeight="400"
                      fontSize="13px"
                      textAlign="center"
                    >
                      You can void the credit note and create a new one.
                    </Text> */}
                  </Box>
                )}
                {creditNote.status === 'SENT' && (
                  <Box
                    backgroundColor="gray.20"
                    w="278px"
                    h="48px"
                    padding="8px"
                    position="absolute"
                    top="80px"
                    left="280px"
                  >
                    <Text
                      color="gray.70"
                      fontWeight="400"
                      fontSize="13px"
                      textAlign="center"
                    >
                      Editing is not available on sent credit notes.
                    </Text>
                  </Box>
                )}
              </Box>
            </Flex>
          )}
        </Box>
        <Box w="30%">
          <Flex
            bg="white"
            borderLeftWidth="1px"
            borderLeftStyle="solid"
            borderLeftColor="gray.40"
            flex={1}
            minHeight="0px"
            overflowY="auto"
            flexDirection="column"
            p="32px"
          >
            {editLineItemsMode && (
              <SelectLineItemsSidebar
                cancel={() => {
                  setEditLineItemsMode(false)
                }}
                selectedLineItemIds={selectedLineItemsFromInvoice}
                creditNoteId={creditNote.id}
              ></SelectLineItemsSidebar>
            )}
            {!selection && !editLineItemsMode && (
              <DefaultSidebar
                creditNote={creditNote}
                billingSchedule={billingSchedule}
                plan={plan}
              ></DefaultSidebar>
            )}
            {selection?.type === 'CUSTOMER' && !editLineItemsMode && (
              <CustomerSidebar
                creditNote={creditNote}
                setCreditNote={setCreditNote}
                cancel={() => cancel()}
                setHasChanges={() => setHasChanges(true)}
              ></CustomerSidebar>
            )}
            {selection?.type === 'DETAILS' && !editLineItemsMode && (
              <DetailsSidebar
                creditNote={creditNote}
                setCreditNote={setCreditNote}
                cancel={() => cancel()}
                setHasChanges={() => setHasChanges(true)}
              ></DetailsSidebar>
            )}
            {selection?.type === 'ITEM' &&
              !editLineItemsMode &&
              lineItems.find(item => selection.id === item.id) && (
                <LineItemSidebar
                  lineItem={lineItems.find(item => selection.id === item.id)}
                  creditNote={creditNote}
                  linkedServices={customer.externalIds.map(
                    service => service.key
                  )}
                  updateLineItem={updateLineItem}
                  cancel={() => cancel()}
                  setHasChanges={() => setHasChanges(true)}
                ></LineItemSidebar>
              )}
            {selection?.type === 'GROUP' && !editLineItemsMode && (
              <LineItemSidebar
                lineItem={undefined}
                creditNote={creditNote}
                linkedServices={customer.externalIds.map(
                  service => service.key
                )}
                groupId={selection.id}
                updateLineItem={addLineItem}
                cancel={() => cancel()}
                setHasChanges={() => setHasChanges(true)}
              ></LineItemSidebar>
            )}
            {selection?.type === 'NEW_GROUP' && !editLineItemsMode && (
              <LineItemGroupSidebar
                group={undefined}
                creditNote={creditNote}
                updateGroup={addLineItemGroup}
                cancel={() => cancel()}
                setHasChanges={() => setHasChanges(true)}
              ></LineItemGroupSidebar>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

interface CreditNotePreviewWrapperProps {
  creditNote: CreditNoteModel
  lineItems: LineItemCreditNoteModel[]
  lineItemGroups: LineItemGroupCreditNoteModel[]
  clickSection: (section: Section) => void
  selectedSection: Section | undefined
  deleteLineItem: (id: string, title: string) => void
  deleteLineItemGroup: (id: string, title: string) => void
  merchant: MerchantModel
  plan?: PlanModel
  merchantDetails: InvoiceMerchantDetailsModel
}

const CreditNotePreviewWrapper: FC<CreditNotePreviewWrapperProps> = ({
  creditNote,
  lineItems,
  lineItemGroups,
  clickSection,
  selectedSection,
  deleteLineItem,
  deleteLineItemGroup,
  merchant,
  merchantDetails
}) => {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <Box borderRadius="10px">
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CreditNotePreview
          clickSection={creditNote.status === 'DRAFT' ? clickSection : () => {}}
          selectedSection={selectedSection}
          editable={creditNote.status === 'DRAFT'}
          invoiceHover={hover}
          creditNote={creditNote}
          lineItems={lineItems}
          lineItemGroups={lineItemGroups}
          deleteLineItem={deleteLineItem}
          deleteLineItemGroup={deleteLineItemGroup}
          borderRadius="8px"
          merchant={merchant}
          hideWatermark={true}
          merchantDetails={merchantDetails}
        />
      </Box>
    </Box>
  )
}

export default CreditNote
