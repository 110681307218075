import { FC } from 'react'
import { Flex, Text, Image } from '@chakra-ui/react'
import { useDispatch } from 'features/store'
import { openOverlay } from 'features/overlay'

export type InsightsTemplateType =
  | 'ALL'
  | 'AR_ANALYTICS'
  | 'USAGE_ANALYTICS'
  | 'SALES_COMMISSIONS'
  | 'REVENUE_ANALYTICS'

type AvailableExportData = {
  id: string
  title: string
  description: string
}

interface CtaCardProps {
  template: InsightsTemplateType
  title: string
  image: string
  imageAlt: string
  copyLink: string
  exampleLink?: string
  icon: string
  description: string
  availableExportData: AvailableExportData[]
}

const CtaCard: FC<CtaCardProps> = ({
  template,
  title,
  image,
  imageAlt,
  copyLink,
  exampleLink,
  icon,
  description,
  availableExportData
}) => {
  const dispatch = useDispatch()

  return (
    <>
      <Flex
        flexDirection="column"
        maxWidth="282px"
        minWidth="282px"
        mr="24px"
        mb="24px"
        pb="12px"
        borderRadius="6px"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray.30"
        boxShadow="0px 2px 4px 0px rgba(20, 23, 28, 0.08)"
        cursor="pointer"
        onClick={() => {
          dispatch(
            openOverlay({
              content: 'createInsightExportTemplateModal',
              size: 'exports',
              data: {
                template: template,
                title: title,
                description: description,
                copyLink: copyLink,
                exampleLink: exampleLink,
                image: image,
                imageAlt: imageAlt,
                icon: icon,
                availableExportData: availableExportData
              },
              closeOnOverlayClick: true
            })
          )
        }}
      >
        <Image src={image} alt={imageAlt} p="12px" />
        <Flex alignItems="center" px="12px">
          <Flex flexDirection="column" p="8px">
            <Text textStyle="default" pb="4px">
              {title}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default CtaCard
