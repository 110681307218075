import { FC, ReactNode } from 'react'
import {
  ModalBody,
  ModalContent,
  Divider,
  ModalHeader,
  ModalCloseButton,
  Flex,
  ModalFooter,
  Button,
  Box
} from '@chakra-ui/react'
import { AppDispatch, useDispatch } from 'features/store'
import { closeOverlay } from 'features/overlay'
import { ArrowLeftIcon } from 'components/icons/ArrowLeftIcon'
import { borderColor } from '@sequencehq/core-theme'

export type ModalProps = {
  title?: string | ReactNode
  children: ReactNode
  submitTitle: string
  formId: string
  showSuccess: boolean
  buttonVariant?: string
  isLoading?: boolean
  overrideSubmitButton?: boolean
  previousModal?: (dispatch: AppDispatch) => void
  leftButton?: ReactNode
  showClose: boolean
  onClose?: () => void
}

const Modal: FC<ModalProps> = ({
  title,
  children,
  submitTitle,
  showSuccess,
  formId,
  isLoading,
  buttonVariant,
  overrideSubmitButton = false,
  previousModal,
  leftButton,
  showClose,
  onClose
}) => {
  const dispatch = useDispatch()

  return (
    <ModalContent borderRadius="8px">
      {!showSuccess && (
        <>
          <Flex alignItems="center" position="relative" height="60px">
            {title ? (
              <ModalHeader as="h2">
                <Flex flexDirection="row">
                  {previousModal && (
                    <>
                      <Flex flexDirection="column">
                        <Box height="6px"></Box>
                        <Box
                          cursor="pointer"
                          onClick={() => previousModal(dispatch)}
                        >
                          <ArrowLeftIcon height={20}></ArrowLeftIcon>
                        </Box>
                      </Flex>
                      <Box width="13px"></Box>
                    </>
                  )}
                  {title}
                </Flex>
              </ModalHeader>
            ) : null}
            <ModalCloseButton
              top={4}
              right={4}
              onClick={() => {
                onClose?.()
              }}
            />
          </Flex>
          {title ? <Divider color={borderColor} /> : null}
        </>
      )}
      <ModalBody p="0">{children}</ModalBody>
      {!showSuccess && !overrideSubmitButton && (
        <ModalFooter>
          {leftButton}
          <Flex grow="1"></Flex>
          <Button
            variant="secondary"
            mr={3}
            onClick={() => {
              onClose?.()
              dispatch(closeOverlay())
            }}
          >
            Cancel
          </Button>
          <Button
            variant={buttonVariant || 'primary'}
            type="submit"
            form={formId}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            {submitTitle}
          </Button>
        </ModalFooter>
      )}
      {showClose && (
        <ModalFooter>
          <Button
            variant="secondary"
            mr={3}
            onClick={() => {
              onClose?.()
              dispatch(closeOverlay())
            }}
          >
            Close
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  )
}

export default Modal
