import { omit, omitBy } from 'lodash/fp'
import { dequal } from 'dequal'
import {
  ListPriceEditorMode,
  ListPriceEditorReducerState,
  PostActionStage,
  Price
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { NEW_PRICE_PATTERN } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { internalPriceToApiPrice } from 'modules/Products/drawers/ListPriceEditor/adapters/listPriceEditor.adapters'

export const getSelectedPrice = (
  prevState: ListPriceEditorReducerState
): Price | null => {
  const selectedPrice = prevState.editor?.selectedPrice
    ? prevState.data.prices[prevState.editor?.selectedPrice]
    : null

  return selectedPrice ?? null
}

/**
 * When using common utils (and when saving) we may want access to the API format
 * version of prices (i.e. PriceModel), so provide a pre-formatted version of prices
 * for these cases.
 * @param product
 * @returns
 */
export const getApiFormatPrices =
  (product: ListPriceEditorReducerState['data']['product']) =>
  (prices: ListPriceEditorReducerState['data']['prices']) => {
    if (!product) {
      return []
    }

    return Object.values(prices).map(internalPriceToApiPrice(product.id))
  }

export const getExistingPrices = (
  prices: ListPriceEditorReducerState['data']['prices']
): ListPriceEditorReducerState['data']['prices'] =>
  omitBy((price: Price) => price.id.match(NEW_PRICE_PATTERN), prices)

export const getNewPrices = (
  prices: ListPriceEditorReducerState['data']['prices']
): ListPriceEditorReducerState['data']['prices'] =>
  omitBy((price: Price) => !price.id.match(NEW_PRICE_PATTERN), prices)

/**
 * This query returns a deduped list of prices, using the id of the first instance
 * of a duplicate as the canonical id for that given price structure.
 * @param prices
 * @returns
 */
export const getUniquePrices = (
  prices: Record<Price['id'], Price>
): Record<Price['id'], Price> => {
  return Object.values(prices).reduce(
    (acc: Record<Price['id'], Price>, { id, ...priceData }) => {
      const match = Object.values(acc).find(({ id: _, ...existingPriceData }) =>
        dequal(
          omit(['common.externalIds'])(existingPriceData),
          omit(['common.externalIds'])(priceData)
        )
      )

      if (match) {
        return acc
      }

      return {
        ...acc,
        [id]: {
          id,
          ...priceData
        }
      }
    },
    {}
  )
}

export const getAvailableFeatures = (
  prevState: ListPriceEditorReducerState
): ListPriceEditorReducerState['derived']['queries']['availableFeatures'] => {
  return {
    review: [ListPriceEditorMode.CREATE, ListPriceEditorMode.EDIT].includes(
      prevState.configuration.mode
    ),
    save: prevState.configuration.mode === ListPriceEditorMode.REVIEW
  }
}

export const getPriceHasBeenUpdated = (
  prevState: ListPriceEditorReducerState
): boolean => {
  return (
    prevState.initialData.price?.id !== prevState.editor.selectedPrice ||
    !dequal(
      prevState.initialData.price,
      prevState.data.prices[prevState.editor.selectedPrice]
    )
  )
}

export const getProductHasBeenUpdated = (
  prevState: ListPriceEditorReducerState
): boolean => {
  return !dequal(prevState.initialData.product, prevState.data.product)
}

/**
 * Check the forms states to see if we can save the price - detail on validation
 * for each from can be found in the relevant hooks. We only store the high level
 * validation status in the domain.
 * @param prevState
 * @returns
 */
export const getCanSave = (prevState: ListPriceEditorReducerState): boolean => {
  const requiredForms: Array<
    keyof ListPriceEditorReducerState['editor']['formsValid']
  > = ['common', prevState.data.formData.common.pricingModel, 'externalLedger']

  return requiredForms.every(form => prevState.editor.formsValid[form])
}
/**
 * A stage to generate a set of queries over the current data for
 * use elsewhere. This prevents us replicating logic and lookups
 * in multiple places.
 * @returns
 */
export const listPriceEditorQueries: PostActionStage = () => prevState => {
  const existingPrices = getExistingPrices(prevState.data.prices)

  const newQueryState: ListPriceEditorReducerState['derived']['queries'] = {
    selectedPrice: getSelectedPrice(prevState),
    existingPrices,
    availableFeatures: getAvailableFeatures(prevState),
    priceHasBeenUpdated: getPriceHasBeenUpdated(prevState),
    canSave: getCanSave(prevState)
  }

  return {
    ...prevState,
    derived: {
      ...prevState.derived,
      queries: newQueryState
    }
  }
}
