import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './redshiftLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { AmazonRedshiftIntegrationDetail } from './AmazonRedshiftIntegrationDetail'

export const amazonRedshiftIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/data-overview',
  title: 'Amazon Redshift',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: true
  },
  description: 'Sync Sequence data to your data stack.',
  components: {
    detail: AmazonRedshiftIntegrationDetail,
    management: NoConfiguration
  }
}
