import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'

export type v1ApiCreateVariantPriceData = Omit<
  Dashboardv20240509Api.PostPrice.Price,
  'id' | 'createdAt'
>

export type v1ApiCreateVariantPriceResponse =
  Dashboardv20240509Api.PostPrice.Response['data']

export const createVariantPrice = async (
  dataToSave: v1ApiCreateVariantPriceData
) => {
  const res = await dashboardv20240509Client.postPrice({
    body: dataToSave
  })

  return {
    success: !res.error,
    error: res.error ? PricingEditorPortErrors.Other : null,
    data: res.data
  }
}
