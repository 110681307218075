import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { XYChart, Card, Skeleton } from '@sequencehq/core-components'
import { currencyToSymbol } from '@sequencehq/core-models'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import { calculateYAxisLabels } from 'RevenueRecognition/view/utils/graphUtils'
import { ChartTooltip } from 'RevenueRecognition/view/common/ChartTooltip'
import {
  format,
  formatDateRange,
  lastDayOfMonth
} from '@sequencehq/utils/dates'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import { createDateFromBucket } from 'RevenueRecognition/view/utils/graphUtils'
import invariant from 'tiny-invariant'
import { floatSafeAdd } from 'RevenueRecognition/view/utils/floatSafeAdd'
import { toMoney } from '@sequencehq/utils'

interface RecognizedRevenueForMonth {
  date: string
  endDate: string
  value: number
}

interface Data {
  startDate: Date
  endDate: Date
  recognizedRevenueByMonth: Array<RecognizedRevenueForMonth>
  total: number
}
/**
 * Returns the last 12 months of data for the given currency
 * @returns
 */
export const useRecognizedRevenueGraph = (props: {
  currency?: Currency | null
}):
  | {
      isLoading: true
      data: null
    }
  | {
      isLoading: false
      data: Data
    } => {
  const recognizedRevenueResponse = useQuery(
    dashboardv99990101Client.getRevRecChartsData,
    {
      currency: props.currency ?? 'GBP'
    },
    {
      enabled: !!props.currency,
      staleTime: 60000,
      select: data => {
        if (!data) {
          return
        }

        const recognizedRevenueByMonth = data.period.buckets.map(
          (bucket): RecognizedRevenueForMonth => {
            const recognizedRevenue =
              bucket.recognizedAndDeferred.ledgerAccountTotals.find(
                i => i.name === 'RECOGNIZED_REVENUE'
              )?.total || 0

            const startDate = createDateFromBucket({
              year: bucket.year,
              month: bucket.month
            })

            return {
              date: format(startDate, 'yyyy-MM-dd'),
              endDate: format(lastDayOfMonth(startDate), 'yyyy-MM-dd'),
              value: recognizedRevenue
            }
          }
        )

        return {
          recognizedRevenueByMonth,
          total: recognizedRevenueByMonth.reduce((acc, entry) => {
            return floatSafeAdd(acc, entry.value)
          }, 0),
          startDate: new Date(data.period.startDate),
          endDate: new Date(data.period.endDate)
        }
      }
    }
  )

  if (recognizedRevenueResponse.isError) {
    throw new Error('Failed to load recognized revenue data')
  }

  if (recognizedRevenueResponse.isPending) {
    return {
      isLoading: true,
      data: null
    }
  }

  invariant(
    recognizedRevenueResponse.data,
    'Recognized revenue should be defined, if loaded'
  )

  return {
    data: {
      recognizedRevenueByMonth:
        recognizedRevenueResponse.data.recognizedRevenueByMonth,
      total: recognizedRevenueResponse.data.total,
      startDate: recognizedRevenueResponse.data.startDate,
      endDate: recognizedRevenueResponse.data.endDate
    },
    isLoading: false
  }
}

/**
 * The tooltip displayed by the XYChart component when hovering over
 * different bars in the chart.
 * @param date - the formatted date of the item
 * @param value - the value to display
 * @returns
 */
const GraphDataTooltip = ({
  date,
  values
}: {
  date: string
  values: {
    deferred: string
  }
}) => {
  return (
    <ChartTooltip>
      <ChartTooltip.Header>
        <Text {...Lato13Bold} color={GreyGrey70}>
          {date}
        </Text>
      </ChartTooltip.Header>
      <ChartTooltip.Body>
        <Flex width="100%" justifyContent="space-between">
          <Text {...Lato13Regular} color={GreyGrey70}>
            Recognized
          </Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {values.deferred}
          </Text>
        </Flex>
      </ChartTooltip.Body>
    </ChartTooltip>
  )
}

interface EventTypesWidgetProps {
  currency: Currency
  yAxisLabels?: number[]
}

/**
 * Displays the recognized revenue over a given date range and currency.
 *
 * @param param0
 * @returns
 */
export const RecognizedRevenueGraph = ({
  currency,
  yAxisLabels
}: EventTypesWidgetProps) => {
  const { isLoading, data } = useRecognizedRevenueGraph({
    currency
  })

  if (isLoading) {
    return (
      <Skeleton minWidth="390px" minHeight="291px" width="100%" height="100%" />
    )
  }

  return (
    <Card
      minWidth="390px"
      width="100%"
      data-testid="revrec.home.recognizedRevenueGraph"
    >
      <Grid rowGap="8px" marginBottom="16px">
        <Flex flexDirection="column" gap="8px">
          <Text {...Lato13Regular} color={GreyGrey60}>
            Recognized revenue
          </Text>
          <Text {...Lato20Bold} color={GreyGrey90}>
            {toMoney({ currency, value: data.total.toString() })}
          </Text>
          <Text {...Lato13Regular} color={GreyGrey60}>
            {formatDateRange({
              from: data.startDate,
              to: data.endDate
            })}
          </Text>
        </Flex>
      </Grid>
      <Box padding="8px">
        <Box height="155px" width="100%">
          <XYChart<{
            value: number
            date: string
          }>
            variant="bar"
            dateFrom={format(data.startDate, 'yyyy-MM-dd')}
            dateTo={format(data.endDate, 'yyyy-MM-dd')}
            data={data.recognizedRevenueByMonth}
            dateFormat="d MMM yyyy"
            unitTickValues={
              yAxisLabels ??
              calculateYAxisLabels(
                data.recognizedRevenueByMonth.map(({ value }) => value)
              )
            }
            unit={currencyToSymbol[currency]}
            animate={false}
            renderTooltip={({ date, value }) => (
              <GraphDataTooltip
                date={format(new Date(date), 'MMMM yyyy')}
                values={{
                  deferred: toMoney({
                    value: value.toString(),
                    currency
                  })
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Card>
  )
}
