import { useState, useMemo } from 'react'

type UseProductListPricesProps = {
  archiveListPrice: (id: string) => Promise<void>
}

enum AvailableModal {
  Archive = 'archive'
}

type UseProductListPrices = (props: UseProductListPricesProps) => {
  modals: {
    [AvailableModal.Archive]: {
      active: boolean
      onClose: () => void
      onConfirm: () => Promise<void>
      submitting: boolean
    }
  }
  features: {
    archive: {
      visible: boolean
      disabled: boolean
      onClick: (id: string) => void
    }
  }
}

export const useProductListPrices: UseProductListPrices = props => {
  const { archiveListPrice } = props
  const [activeModal, setActiveModal] = useState<AvailableModal | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [selectedListPriceId, setSelectedListPriceId] = useState<string | null>(
    null
  )

  const modals = useMemo(() => {
    return {
      [AvailableModal.Archive]: {
        active: activeModal === AvailableModal.Archive,
        submitting,
        onClose: () => {
          setSelectedListPriceId(null)
          setActiveModal(null)
        },
        onConfirm: async () => {
          if (!selectedListPriceId) {
            setActiveModal(null)
            return
          }

          setSubmitting(true)
          await archiveListPrice(selectedListPriceId)
          setSelectedListPriceId(null)
          setSubmitting(false)
          setActiveModal(null)
        }
      }
    }
  }, [activeModal, archiveListPrice, selectedListPriceId, submitting])

  const features = useMemo(() => {
    return {
      archive: {
        visible: true,
        disabled: false,
        onClick: (id: string) => {
          setSelectedListPriceId(id)
          setActiveModal(AvailableModal.Archive)
        }
      }
    }
  }, [])

  return {
    modals,
    features
  }
}
