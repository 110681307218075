import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { FC } from 'react'

type ConfirmArchiveQuoteModalProps = {
  onClose: () => void
  onConfirm: () => Promise<void>
  submitting: boolean
  showSignatureWarning: boolean
}

export const ConfirmArchiveQuoteModal: FC<
  ConfirmArchiveQuoteModalProps
> = props => {
  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
          top="118px"
          width="414px"
          maxWidth="414px"
        >
          <Box color={GreyGrey100} {...Lato14Bold} marginBottom="4px">
            Archive quote
          </Box>
          <Box {...Lato13Regular} color={GreyGrey80} marginBottom="16px">
            {props.showSignatureWarning ? (
              <>
                This will archive the quote immediately. Any signature requests
                will be cancelled. This action cannot be&nbsp;undone.
              </>
            ) : (
              <>
                This will archive the quote immediately. This action cannot
                be&nbsp;undone.
              </>
            )}
          </Box>
          <Flex gap="8px" justifyContent="flex-end">
            <Button
              variant="secondary2"
              onClick={props.onClose}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              isLoading={props.submitting}
              onClick={() => {
                void props.onConfirm()
              }}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              Archive
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
