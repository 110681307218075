import { usePostCreditNotesByIdLineItemsFromInvoiceMutation } from 'features/api/integratedApi.generated'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { handleFormResponse } from 'lib/formValidation'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { noReturn } from 'lib/noReturn'

interface SelectLineItemsSidebarProps {
  cancel: () => void
  selectedLineItemIds: string[]
  creditNoteId: string
}

const SelectLineItemsSidebar: FC<SelectLineItemsSidebarProps> = ({
  cancel,
  selectedLineItemIds,
  creditNoteId
}) => {
  const [submitForm] = usePostCreditNotesByIdLineItemsFromInvoiceMutation()
  return (
    <>
      <Form
        keepDirtyOnReinitialize
        onSubmit={async (_values, form) => {
          const result = await submitForm({
            id: creditNoteId,
            addCreditNoteLineItemsFromInvoiceEndpointRequestModel: {
              lineItemIds: selectedLineItemIds
            }
          }).then(res => {
            cancel()
            return res
          })
          return handleFormResponse(result, form.getRegisteredFields())
        }}
        render={({ handleSubmit, submitting }) => (
          <Flex
            as="form"
            px={5}
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            w="100%"
          >
            <Text textStyle="sectionHeader">Select line items to credit!</Text>
            <Box height={3} />
            <Text textStyle="infoText">
              Select line items from the original invoice displayed on the left
              to generate Credit Note
            </Text>
            <Box height={5} />
            <Flex justifyContent="flex-end">
              <Button variant="secondary" onClick={cancel}>
                Cancel
              </Button>
              <Box w="16px"></Box>
              <Button
                variant="primary"
                type="submit"
                width="180px"
                isDisabled={submitting || selectedLineItemIds.length === 0}
              >
                {`Confirm selection (${selectedLineItemIds.length})`}
              </Button>
            </Flex>
          </Flex>
        )}
      ></Form>
    </>
  )
}

export default SelectLineItemsSidebar
