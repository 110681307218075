import {
  PdfQuoteRendererData,
  ReactQuoteRendererData
} from '@sequencehq/quote-content'
import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'

type UseQuoteEditorPreviewRoot = (props: QuoteEditorPreviewProps) => {
  quoteId: string
  reactRendererData: ReactQuoteRendererData
  pdfRendererData: PdfQuoteRendererData
}

export const useQuoteEditorPreviewRoot: UseQuoteEditorPreviewRoot = props => {
  return {
    quoteId: props.quoteId,
    reactRendererData: props.reactRendererData,
    pdfRendererData: props.pdfRendererData
  }
}
