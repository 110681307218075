import {
  DiscountEditorDiscount,
  DiscountEditorMode,
  DiscountEditorRootProps
} from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { uniq } from 'lodash/fp'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { Discount } from 'modules/Cube/domain/cube.domain.types'
import { discountEditorCubeAdapter } from 'modules/Cube/view/common/drawers/discountEditor/discountEditor.adapters'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { priceModelAdapter } from '@sequencehq/utils'

type UseDiscountEditorOutletConnector = () => DiscountEditorRootProps

export const useDiscountEditorConnector: UseDiscountEditorOutletConnector =
  () => {
    const navigate = useNavigate()
    const { discountId, phaseId } = useParams<{
      phaseId?: string
      discountId?: string
    }>()

    const cubeContext = useCubeContext()

    const phaseBeingEdited = useMemo(() => {
      if (!phaseId) {
        return
      }

      return cubeContext.queries.resolvedPhases[phaseId]
    }, [cubeContext, phaseId])

    /**
     * Error handling that will redirect to the main editor if the current phase doesn't
     * exist - this may happen upon a refresh.
     */
    useEffect(() => {
      if (!phaseBeingEdited) {
        navigate('..')
        return
      }
    }, [phaseBeingEdited, navigate])

    const currency =
      cubeContext.queries.selectedCurrency ??
      cubeContext.queries.rawData.configuration.currency.default

    const existingDiscount = useMemo(() => {
      if (!phaseBeingEdited) {
        return
      }

      if (
        !discountId ||
        !cubeContext.queries.resolvedPhases[phaseBeingEdited?.id]?.discounts
      ) {
        return
      }

      const discountInPhase = cubeContext.queries.resolvedPhases[
        phaseBeingEdited?.id
      ]?.discounts?.find(phaseDiscount => phaseDiscount.id === discountId)

      if (!discountInPhase) {
        return
      }

      return discountEditorCubeAdapter.in(discountInPhase, {
        currency: currency
      })
    }, [cubeContext, discountId, phaseBeingEdited, currency])

    useEffect(() => {
      if (!phaseBeingEdited) {
        return
      }

      if (
        !existingDiscount &&
        !cubeContext.queries.availableFeatures.phases[phaseBeingEdited.id]
          ?.phase.discount.edit.available.enabled
      ) {
        navigate('..')
      }
    }, [
      navigate,
      existingDiscount,
      cubeContext.queries.availableFeatures,
      phaseBeingEdited
    ])

    const mode = useMemo(() => {
      if (!existingDiscount) {
        return DiscountEditorMode.CREATE
      }

      if (!phaseBeingEdited) {
        return DiscountEditorMode.VIEW
      }

      if (
        !cubeContext.queries.availableFeatures.phases[phaseBeingEdited.id].phase
          .discount.edit.available.enabled
      ) {
        return DiscountEditorMode.VIEW
      }

      return DiscountEditorMode.EDIT
    }, [cubeContext, existingDiscount, phaseBeingEdited])

    useEffect(() => {
      if (mode === DiscountEditorMode.VIEW && !existingDiscount) {
        navigate('..')
      }
    }, [mode, navigate, existingDiscount])

    const onClose = useCallback(() => {
      navigate('..')
    }, [navigate])

    const discountsForPhase = useMemo(() => {
      if (!phaseBeingEdited) {
        return
      }

      return (
        cubeContext.queries.resolvedPhases[phaseBeingEdited?.id]?.discounts ??
        []
      )
    }, [cubeContext, phaseBeingEdited])

    const onSave = useCallback(
      (newDiscount: DiscountEditorDiscount) => {
        if (!phaseBeingEdited || !discountsForPhase) {
          return
        }
        /**
         * We only allow one discount per price, so if we will remove any
         * references to the priceIds included in this discount for the active
         * phase.
         */
        const modifiedDiscounts =
          newDiscount.applyTo === 'ALL'
            ? []
            : discountsForPhase.map(discount => ({
                ...discount,
                priceIds: discount.priceIds.filter(
                  priceId => !newDiscount.priceIds.includes(priceId)
                )
              }))
        const formattedDiscount: Discount =
          discountEditorCubeAdapter.out(newDiscount)

        cubeContext.mutators.updateData({
          phases: {
            [phaseBeingEdited.id]: {
              discountIds:
                newDiscount.applyTo === 'ALL'
                  ? [formattedDiscount.id]
                  : [
                      /**
                       * We only allow one discount per price, so if we will replace any
                       */
                      ...modifiedDiscounts.map(discount => discount.id),
                      formattedDiscount.id
                    ]
            }
          },
          discounts: {
            ...modifiedDiscounts.reduce(
              (acc, discount) => ({
                ...acc,
                [discount.id]: discount
              }),
              {}
            ),
            [formattedDiscount.id]: formattedDiscount
          }
        })
        navigate('..')
      },
      [cubeContext, navigate, discountsForPhase, phaseBeingEdited]
    )

    const allPrices = useMemo(() => {
      if (!phaseBeingEdited) {
        return []
      }

      const pricesForPhaseBeingEdited =
        cubeContext.queries.resolvedPhases[phaseBeingEdited?.id]?.prices ?? []

      return pricesForPhaseBeingEdited.map(priceModelAdapter.in)
    }, [cubeContext, phaseBeingEdited])

    /**
     * Do not allow for the selection of prices that belong to a different discount
     */
    const otherDiscounts = useMemo(() => {
      if (!discountsForPhase) {
        return
      }

      return discountsForPhase.filter(
        discount => discount.id !== existingDiscount?.id
      )
    }, [existingDiscount, discountsForPhase])

    const availablePrices = useMemo(() => {
      if (!allPrices || !otherDiscounts) {
        return []
      }

      return allPrices
        .filter(
          price =>
            !otherDiscounts.find(discount =>
              discount.priceIds.includes(price.id)
            )
        )
        .map(({ id }) => id)
    }, [allPrices, otherDiscounts])

    const defaultApplyTo = useMemo(() => {
      if (!allPrices) {
        return
      }

      return allPrices.length ? 'SPECIFIC' : 'ALL'
    }, [allPrices])

    const applyToAllAvailable = useMemo(() => {
      const allRecurringFrequencies = uniq(
        allPrices
          .map(price =>
            price.billingFrequency !== 'ONE_TIME'
              ? price.billingFrequency
              : undefined
          )
          .filter(Boolean)
      )

      return allRecurringFrequencies.length < 2
    }, [allPrices])

    return {
      existingDiscount,
      mode,
      onClose,
      onSave,
      allPrices,
      availablePrices,
      defaultApplyTo,
      currency,
      applyToAllAvailable: applyToAllAvailable
    }
  }
