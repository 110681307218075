import { useListPriceEditorConnector } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditor.connector'
import { ListPriceEditorRoot } from 'modules/Products/drawers/ListPriceEditor/ListPriceEditorRoot'
import { useNavigate } from 'react-router-dom'

export const ListPriceEditorRoute = () => {
  const context = useListPriceEditorConnector()
  const navigate = useNavigate()

  if (context.canUseListPrices) {
    return <ListPriceEditorRoot {...context} />
  }

  navigate('..')
  return
}
