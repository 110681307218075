import { useLazyGetSeatTypesByIdQuery } from 'features/api'
import {
  FC,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer
} from 'react'
import { match } from 'ts-pattern'
import * as Sentry from '@sentry/react'
import { SeatTypeModel } from '@sequencehq/core-models'

type SeatTypeState = {
  seatType: SeatTypeModel | undefined
  seatTypeId: string | undefined
}

type SeatTypeContextType = {
  state: SeatTypeState
  dispatch: (action: SeatTypeAction) => void
}

const SeatTypeContext = createContext<SeatTypeContextType | undefined>(
  undefined
)

type SeatTypeAction =
  | {
      type: 'setSeatTypeId'
      seatTypeId: string | undefined
    }
  | {
      type: 'setSeatType'
      seatType: SeatTypeModel | undefined
    }

const seatTypeReducer = (state: SeatTypeState, action: SeatTypeAction) => {
  return match(action)
    .with({ type: 'setSeatTypeId' }, ({ seatTypeId }) => ({
      ...state,
      seatTypeId,
      seatType: state.seatTypeId === seatTypeId ? state.seatType : undefined
    }))
    .with({ type: 'setSeatType' }, ({ seatType }) => {
      return seatType?.id === state.seatTypeId
        ? {
            ...state,
            seatType
          }
        : state
    })
    .exhaustive()
}

type SeatTypeProviderProps = {
  children: ReactNode
}

export const SeatTypeProvider: FC<SeatTypeProviderProps> = ({ children }) => {
  const [seatTypeQueryTrigger, seatTypeRes] = useLazyGetSeatTypesByIdQuery()
  const [state, dispatch] = useReducer<Reducer<SeatTypeState, SeatTypeAction>>(
    seatTypeReducer,
    {
      seatType: undefined,
      seatTypeId: undefined
    }
  )
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  useEffect(() => {
    if (!state.seatTypeId) {
      return dispatch({ type: 'setSeatType', seatType: undefined })
    }

    seatTypeQueryTrigger({ id: state.seatTypeId }).catch(err =>
      Sentry.captureException(err)
    )
  }, [state.seatTypeId, seatTypeQueryTrigger])

  useEffect(() => {
    if (!seatTypeRes?.isSuccess) {
      return
    }

    const seatType = seatTypeRes.data?.value()

    dispatch({ type: 'setSeatType', seatType })
  }, [seatTypeRes])

  return (
    <SeatTypeContext.Provider value={value}>
      {children}
    </SeatTypeContext.Provider>
  )
}

export const useSeatType = () => {
  const context = useContext(SeatTypeContext)
  if (context === undefined) {
    throw new Error('useSeatType must be used within a SeatTypeProvider')
  }
  return context
}
