import { dequal } from 'dequal'
import { pick } from 'lodash/fp'
import { useCallback } from 'react'
import { NEW_PRICE_PATTERN } from 'modules/Cube/domain/cube.constants'
import { v1ApiNewPrice } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSavePrices'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

export type DedupedPrice = {
  price: v1ApiNewPrice
  matchingNewPrices: v1ApiNewPrice[]
}
type UsePriceDeduplication = () => {
  dedupePrices: (newPrices: v1ApiNewPrice[]) => Promise<DedupedPrice[]>
}

export const dedupeUnsavedPrices = (
  newPrices: v1ApiNewPrice[]
): DedupedPrice[] => {
  return newPrices.reduce((acc: DedupedPrice[], currentPrice) => {
    const pickCheckableProperties = pick([
      'listPriceId',
      'structure',
      'currency',
      'productId',
      'name',
      'integrationIds',
      'billingFrequency',
      'billingType'
    ])

    const match = acc.find(dedupedPrice =>
      dequal(
        pickCheckableProperties(dedupedPrice.price),
        pickCheckableProperties(currentPrice)
      )
    )

    if (!match) {
      return [
        ...acc,
        {
          price: currentPrice,
          matchingNewPrices: [currentPrice]
        }
      ]
    }

    return acc.map(dedupedConfig => {
      if (dedupedConfig.price.id === match.price.id) {
        return {
          ...dedupedConfig,
          matchingNewPrices: [...dedupedConfig.matchingNewPrices, currentPrice]
        }
      }

      return dedupedConfig
    })
  }, [])
}

/**
 * This optimisation approach is a way of trying to reduce the number
 * of price entities that we create in the backend. Over time, this optimisation approach
 * will make a notable impact on the number of records we have created, at the cost of some
 * time when saving new prices. We can always remove this optimisation if the backend implements
 * a way of deduplicating prices (which is the best place for this optimisation to live, given
 * that the API explicitly allows users to do whatever they wish, priceId wise), or we just
 * decide it's not worth the up front cost of deduping to prevent the spread of duplicate prices.
 *
 * Note that this optimisation cannot help the system work correctly beyond just having fewer records
 * - depending on each price reference being the _only_ reference to that way of charging a customer
 * a certain amount is not sound - and certainly not if we don't enforce this at the API level.
 */
type ApiPrice = Dashboardv20240509Api.GetPrice.Price
export const usePriceDeduplication: UsePriceDeduplication = () => {
  const getPricesForProducts = useCallback(
    async (productIds: string[]): Promise<Record<string, ApiPrice[]>> => {
      const loadedPrices = await Promise.all(
        productIds.map(productId =>
          dashboardv20240509Client.getPrices({ productId }).then(res => {
            return {
              id: productId,
              prices: res.data?.items ?? []
            }
          })
        )
      )

      return loadedPrices.reduce(
        (acc, pricesForProducts) => ({
          ...acc,
          [pricesForProducts.id]: pricesForProducts.prices
        }),
        {}
      )
    },
    []
  )

  /**
   * This is a sequential check, rather than checking all prices at once - it's difficult to judge what the most
   * effective approach here will be, since we don't want to necessarily spam the service with a whole bunch of
   * requests at once, but this will also be slower to lookup. However, we should hopefully find that for a given
   * exact price on the same product, the integrationIds will be the same, so we should find the correct match
   * quickly.
   */

  const checkPricesOnService = useCallback(
    ([priceToCheck, ...remainingPricesToCheck]: ApiPrice[]) =>
      async (dedupedPrice: DedupedPrice): Promise<DedupedPrice> => {
        if (!priceToCheck) {
          return dedupedPrice
        }

        const loadedPrice = (
          await dashboardv20240509Client.getPrice({
            id: priceToCheck.id
          })
        ).data

        /**
         * When checking at this level, also include integrationIds
         */
        const pickCheckableProperties = pick([
          'listPriceId',
          'structure',
          'productId',
          'currency',
          'name',
          'integrationIds',
          'billingFrequency',
          'billingType'
        ])

        if (
          loadedPrice &&
          dequal(
            pickCheckableProperties(loadedPrice),
            pickCheckableProperties(dedupedPrice.price)
          )
        ) {
          return {
            ...dedupedPrice,
            price: loadedPrice
          }
        }

        if (!remainingPricesToCheck.length) {
          return dedupedPrice
        }

        return checkPricesOnService(remainingPricesToCheck)(dedupedPrice)
      },
    []
  )

  const checkForMatchingPrice = useCallback(
    (pricesForProduct: ApiPrice[]) => async (dedupedPrice: DedupedPrice) => {
      /**
       * This particular bit of functionality is here because of a bug in the service
       * at the moment where we don't return integrationIds for a price - because of this
       * we can't be certain a price is an exact match to an existing one until we've
       * loaded the price in specifically. However, to speed things up we use the result
       * from /prices to give us a 'shortlist' to check, which should hopefully return a
       * match quickly!
       */
      const pickCheckableProperties = pick([
        'listPriceId',
        'structure',
        'currency',
        'productId',
        'name',
        'billingFrequency',
        'billingType'
      ])

      const potentialMatchingPrices = pricesForProduct.filter(price => {
        return dequal(
          pickCheckableProperties(dedupedPrice.price),
          pickCheckableProperties(price)
        )
      })

      if (!potentialMatchingPrices.length) {
        return dedupedPrice
      }

      return checkPricesOnService(potentialMatchingPrices)(dedupedPrice)
    },
    [checkPricesOnService]
  )

  const dedupePrices = useCallback(
    async (newPrices: v1ApiNewPrice[]) => {
      /**
       * Our first phase is to check which prices are the same within the collection
       * we're about to save - if they're the exact same, then we can condense them down
       * into one price Id to save. This shouldn't happen for a single phase schedule, but
       * is a more notable concern for multi-phase schedules.
       */
      const uniqueNewPrices = dedupeUnsavedPrices(newPrices)

      /**
       * With our deduped set, we now need to query the backend for all of the prices for each
       * product, so that we can assess them,
       */
      const pricesForProducts = await getPricesForProducts(
        uniqueNewPrices
          .filter(dedupedPrice =>
            dedupedPrice.price.id.match(NEW_PRICE_PATTERN)
          )
          .map(dedupedPrice => dedupedPrice.price.productId)
      )

      return Promise.all(
        uniqueNewPrices.map(dedupedPrice => {
          if (!dedupedPrice.price.id.match(NEW_PRICE_PATTERN)) {
            return dedupedPrice
          }

          return checkForMatchingPrice(
            pricesForProducts[dedupedPrice.price.productId] ?? []
          )(dedupedPrice)
        })
      )
    },
    [getPricesForProducts, checkForMatchingPrice]
  )

  return {
    dedupePrices
  }
}
