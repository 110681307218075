import DashboardAuth from 'modules/DashboardApp/DashboardAuth'
import {
  ProviderConfig,
  useFlags,
  withLDProvider
} from 'launchdarkly-react-client-sdk'
import { useEffect, useMemo, useState } from 'react'
import { isDemoEnv } from 'lib/environment/environment'
import { RouterProvider, RouterProviderProps } from 'react-router-dom'
import { dashboardRouter } from 'modules/DashboardApp/dashboardApp.router'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Wordmark } from 'components/icons/Wordmark'
import { Section } from 'modules/LoginApp/components/common/Section'
import Spinner from 'components/Loading/Spinner'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/dist/utils'
import { initializeApiClients } from 'features/api/initializeApiClients'

/**
 * We need to be able to access feature flags to inform the router, so we
 * need to define this as a specific component in order to easily access the LD
 * flags.
 *
 * We don't, however, want to immediately apply the flags on change, as that could
 * cause lost work for users if their current route blinks out of existance and is
 * replaced. Therefore we only resolve once per load.
 *
 * This leads to quite an awkward series of components, since our API functionality
 * is tied to the redux provider. Therefore we need to first initialise the provider
 * for the /self call, then initialise launch darkly, _then_ initialise the router!
 * @returns
 */

const DashboardRouter = () => {
  const [resolvedRouter, setResolvedRouter] = useState<
    RouterProviderProps['router'] | undefined
  >()
  const [flagsTimedOut, setFlagsTimedOut] = useState(false)
  const flags = useFlags()

  const flagsReady = useMemo(() => {
    return Boolean(Object.values(flags).length) || flagsTimedOut
  }, [flags, flagsTimedOut])

  /**
   * Just in case LD ever borks! This will result in everything always resolving to false,
   * flag wise.
   */
  useEffect(() => {
    setTimeout(() => setFlagsTimedOut(true), 500)
  }, [])

  useEffect(() => {
    if (resolvedRouter || !flagsReady) {
      return
    }

    setResolvedRouter(dashboardRouter({ flags }))

    // Reinitialize api clients to ensure we don't have an empty organizationId in the baseQueryKey
    initializeApiClients()
  }, [flags, resolvedRouter, flagsReady])

  if (!resolvedRouter) {
    return (
      <PageContainer paddingTop="32px">
        <Wordmark width="126" height="26.74" />
        <Section gap="8px">
          <Spinner />
        </Section>
      </PageContainer>
    )
  }

  return <RouterProvider router={resolvedRouter} />
}

const getInitializedRoutingComponent = (ldConfig: ProviderConfig) =>
  withLDProvider(ldConfig)(DashboardRouter)

const DashboardRouting = () => {
  const user = useQuery(dashboardv99990101Client.getSelf)
  const self = user.data

  const ldConfig: ProviderConfig | undefined = useMemo(() => {
    if (isDemoEnv()) {
      return {
        clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
        context: {
          kind: 'multi',
          user: {
            key: 'demo-user',
            email: 'demo@sequencehq.com',
            accountId: import.meta.env.VITE_DEMO_ACCOUNT_ID
          },
          account: {
            key: import.meta.env.VITE_DEMO_ACCOUNT_ID
          }
        },
        options: {
          disableSyncEventPost: true,
          hash: import.meta.env.VITE_LAUNCHDARKLY_DEMO_HASH
        }
      }
    }

    if (!self) {
      return
    }

    return {
      clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
      context: {
        kind: 'multi',
        user: {
          key: self.sequenceUser.id,
          email: self.sequenceUser.email,
          accountId: self.sequenceAccounts[0]
        },
        account: {
          // Despite being an array, this only seems to contain 1 account ID, which is the currently selected account
          key: self.sequenceAccounts[0]
        }
      },
      options: {
        disableSyncEventPost: true,
        hash: self.launchDarklyHash
      }
    }
  }, [self])

  if (!ldConfig) {
    return null
  }

  const RoutingComponent = getInitializedRoutingComponent(ldConfig)

  return <RoutingComponent />
}

/**
 * The dashboard app root is a lil' nested because we:
 * - Need to fetch the LD configuration for the current user
 * - Subsequently initialise the router, based on the flags returned from LD
 * - Render the router
 *
 * This allows us to control conditional behaviour at the routing config level via launch
 * darkly, but also 'lock in' the conditional behaviour so that we don't forcibly reload
 * pages when flipping flags (which would be a bad user experience if you're mid billing
 * schedule!).
 * @returns
 */

export const DashboardApp = () => {
  return (
    <DashboardAuth>
      <DashboardRouting />
    </DashboardAuth>
  )
}
