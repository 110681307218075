import { createContext, useContext } from 'react'
import { IntegrationsDomainInterface } from 'modules/Integrations/domain'

const IntegrationsContext = createContext<IntegrationsDomainInterface>({
  queries: {
    rawData: {
      editor: {
        loaded: false
      }
    },
    resolvedIntegrations: {}
  } as IntegrationsDomainInterface['queries'],
  mutators: {
    external: {
      in: {},
      out: {}
    }
  } as IntegrationsDomainInterface['mutators']
})

export const useIntegrationsDomainContext = () => {
  const integrationsContext = useContext(IntegrationsContext)

  return integrationsContext
}

export const IntegrationsProvider = IntegrationsContext.Provider
