import { Fade, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { ArchiveCustomerModal } from 'components/ArchiveCustomer/ArchiveCustomerModal'
import { ArchiveTaxRateModal } from 'components/ArchiveTaxRate/ArchiveTaxRateModal'
import CancelChangesModal from 'components/CancelChanges/CancelChangesModal'
import CollectEmailModal from 'components/CollectEmail/CollectEmailModal'
import { CreateApiKeyModal } from 'components/CreateApiKey'
import { ArchiveBillingScheduleModal } from 'components/BillingSchedules/components/modals/ArchiveBillingScheduleModal'
import CreateCreditNoteModal from 'components/CreateCreditNote'
import { CreateCustomerModalForm } from 'components/CreateCustomer'
import CreateInvoiceModal from 'components/CreateInvoice'
import LinkAliasToCustomerForm from 'components/LinkAliasToCustomer/LinkAliasToCustomerForm'
import { CreateNotificationPolicyModal } from 'components/CreateNotificationPolicy'
import { CreateTaxRateModal } from 'components/CreateTaxRate'
import { CustomerIntegrationLinkModal } from 'components/CustomerIntegrationLink/CustomerIntegrationLinkModal'
import { DeleteApiKeyModal } from 'components/DeleteApiKey'
import {
  DeleteCreditNoteLineItemModal,
  DeleteInvoiceLineItemModal
} from 'components/DeleteLineItem'
import {
  DeleteCreditNoteLineItemGroupModal,
  DeleteInvoiceLineItemGroupModal
} from 'components/DeleteLineItemGroup'
import { DeleteNotificationPolicyModal } from 'components/DeleteNotificationPolicy/DeleteNotificationPolicyModal'
import { DeleteUserModal } from 'components/DeleteUser'
import DemoInfoModal from 'components/DemoMode/DemoInfoModal'
import { EditCustomerModalForm } from 'components/EditCustomer'
import {
  ImportCustomersModal,
  SelectImportCustomersModal
} from 'components/ImportCustomers'
import RecalculateInvoiceModal from 'components/RecalculateInvoice/RecalculateInvoiceModal'
import SendInvoicePaymentReminderModal from 'components/SendInvoicePaymentReminder/SendInvoicePaymentReminderModal'
import SendTestCreditNoteEmailModal from 'components/SendTestEmail/SendTestCreditNoteEmailModal'
import SendTestInvoiceEmailModal from 'components/SendTestEmail/SendTestInvoiceEmailModal'
import FinalizeAndSendCreditNoteModal from 'components/UpdateInvoiceStatus/FinalizeAndSendCreditNoteModal'
import FinalizeAndSendInvoiceModal from 'components/UpdateInvoiceStatus/FinalizeAndSendInvoiceModal'
import FinalizeCreditNoteModal from 'components/UpdateInvoiceStatus/FinalizeCreditNoteModal'
import FinalizeInvoiceModal from 'components/UpdateInvoiceStatus/FinalizeInvoiceModal'
import SendCreditNoteModal from 'components/UpdateInvoiceStatus/SendCreditNoteModal'
import SendInvoiceModal from 'components/UpdateInvoiceStatus/SendInvoiceModal'
import VoidCreditNoteModal from 'components/UpdateInvoiceStatus/VoidCreditNoteModal'
import VoidInvoiceModal from 'components/UpdateInvoiceStatus/VoidInvoiceModal'
import { OverlayState, closeOverlay } from 'features/overlay'
import { useDispatch, useSelector } from 'features/store'
import { FC, Fragment } from 'react'
import { StartBillingModal } from 'components/BillingSchedules/components/modals/StartBilling'
import CreateInsightExportTemplateModal from 'components/InsightsV2/modals/CreateInsightExportTemplateModal'

type OverlayContentProps = {
  overlay: OverlayState
}

const OverlayContent: FC<OverlayContentProps> = ({ overlay }) => {
  switch (overlay.content) {
    case 'createCustomerModal':
      return <CreateCustomerModalForm {...overlay.data} />

    case 'editCustomerModal':
      return <EditCustomerModalForm customerId={overlay.data.id} />

    case 'finalizeAndSendInvoiceModal':
      return (
        <FinalizeAndSendInvoiceModal
          id={overlay.data.id}
          customerName={overlay.data.customerName}
          billingScheduleId={overlay.data.billingScheduleId}
          customerEmails={overlay.data.customerEmails}
        />
      )

    case 'finalizeInvoiceModal':
      return (
        <FinalizeInvoiceModal
          id={overlay.data.id}
          customerName={overlay.data.customerName}
        />
      )

    case 'sendInvoiceModal':
      return (
        <SendInvoiceModal
          id={overlay.data.id}
          billingScheduleId={overlay.data.billingScheduleId}
          customerEmails={overlay.data.customerEmails}
          customerName={overlay.data.customerName}
        />
      )

    case 'sendTestInvoiceEmailModal':
      return <SendTestInvoiceEmailModal id={overlay.data.id} />

    case 'sendTestCreditNoteEmailModal':
      return <SendTestCreditNoteEmailModal id={overlay.data.id} />

    case 'recalculateInvoiceModal':
      return <RecalculateInvoiceModal id={overlay.data.id} />

    case 'createInvoiceModal':
      return (
        <CreateInvoiceModal
          customer={overlay.data.customer}
          variant={overlay.variant}
        />
      )

    case 'createCreditNoteModal':
      return (
        <CreateCreditNoteModal
          customer={overlay.data.customer}
          variant={overlay.variant}
        ></CreateCreditNoteModal>
      )

    case 'voidInvoiceModal':
      return (
        <VoidInvoiceModal
          id={overlay.data.id}
          customerName={overlay.data.customerName}
        />
      )

    case 'deleteInvoiceLineItemModal':
      return (
        <DeleteInvoiceLineItemModal
          invoiceId={overlay.data.invoiceId}
          id={overlay.data.id}
          title={overlay.data.title}
        />
      )

    case 'deleteInvoiceLineItemGroupModal':
      return (
        <DeleteInvoiceLineItemGroupModal
          invoiceId={overlay.data.invoiceId}
          id={overlay.data.id}
          title={overlay.data.title}
        />
      )

    case 'deleteCreditNoteLineItemModal':
      return (
        <DeleteCreditNoteLineItemModal
          creditNoteId={overlay.data.creditNoteId}
          id={overlay.data.id}
          title={overlay.data.title}
        />
      )

    case 'deleteCreditNoteLineItemGroupModal':
      return (
        <DeleteCreditNoteLineItemGroupModal
          creditNoteId={overlay.data.creditNoteId}
          id={overlay.data.id}
          title={overlay.data.title}
        />
      )

    case 'cancelChangesModal':
      return (
        <CancelChangesModal cancel={overlay.data.cancel}></CancelChangesModal>
      )

    case 'finalizeAndSendCreditNoteModal':
      return (
        <FinalizeAndSendCreditNoteModal
          id={overlay.data.id}
          customerName={overlay.data.customerName}
          customerEmails={overlay.data.customerEmails}
          onSuccess={overlay.data.onSuccess}
        />
      )

    case 'finalizeCreditNoteModal':
      return (
        <FinalizeCreditNoteModal
          id={overlay.data.id}
          customerName={overlay.data.customerName}
          onSuccess={overlay.data.onSuccess}
        />
      )

    case 'sendCreditNoteModal':
      return (
        <SendCreditNoteModal
          id={overlay.data.id}
          customerName={overlay.data.customerName}
          customerEmails={overlay.data.customerEmails}
          onSuccess={overlay.data.onSuccess}
        />
      )

    case 'voidCreditNoteModal':
      return (
        <VoidCreditNoteModal
          id={overlay.data.id}
          customerName={overlay.data.customerName}
          onSuccess={overlay.data.onSuccess}
        />
      )

    case 'configureAmazon_RedshiftModal':
      return <ModalContent></ModalContent>

    case 'connectToAmazon_RedshiftModal':
      return <ModalContent></ModalContent>

    case 'configureGoogle_BigQueryModal':
      return <ModalContent></ModalContent>

    case 'connectToGoogle_BigQueryModal':
      return <ModalContent></ModalContent>

    case 'configureGoogle_SheetsModal':
      return <ModalContent></ModalContent>

    case 'connectToGoogle_SheetsModal':
      return <ModalContent></ModalContent>

    case 'configureNetSuiteModal':
      return <ModalContent></ModalContent>

    case 'connectToNetSuiteModal':
      return <ModalContent></ModalContent>

    case 'configureSlackModal':
      return <ModalContent></ModalContent>

    case 'connectToSlackModal':
      return <ModalContent></ModalContent>

    case 'configureSnowflakeModal':
      return <ModalContent></ModalContent>

    case 'connectToSnowflakeModal':
      return <ModalContent></ModalContent>

    case 'customerIntegrationLinkModal':
      return (
        <CustomerIntegrationLinkModal
          customer={overlay.data.customer}
          integrationService={overlay.data.integrationService}
        />
      )

    case 'importCustomersModal':
      return <ImportCustomersModal></ImportCustomersModal>

    case 'selectImportCustomersModal':
      return (
        <SelectImportCustomersModal
          service={overlay.data.service}
        ></SelectImportCustomersModal>
      )

    case 'deleteUserModal':
      return (
        <DeleteUserModal
          id={overlay.data.id}
          email={overlay.data.email}
        ></DeleteUserModal>
      )

    case 'createNotificationPolicyModal':
      return <CreateNotificationPolicyModal></CreateNotificationPolicyModal>

    case 'deleteNotificationPolicyModal':
      return (
        <DeleteNotificationPolicyModal
          id={overlay.data.id}
          name={overlay.data.name}
        ></DeleteNotificationPolicyModal>
      )

    case 'archiveTaxRateModal':
      return (
        <ArchiveTaxRateModal
          id={overlay.data.id}
          name={overlay.data.name}
        ></ArchiveTaxRateModal>
      )

    case 'createTaxRateModal':
      return <CreateTaxRateModal></CreateTaxRateModal>

    case 'deleteApiKeyModal':
      return (
        <DeleteApiKeyModal
          id={overlay.data.id}
          name={overlay.data.name}
        ></DeleteApiKeyModal>
      )

    case 'createApiKeyModal':
      return <CreateApiKeyModal></CreateApiKeyModal>

    case 'demoMode':
      return <DemoInfoModal />

    case 'createInsightExportTemplateModal':
      return (
        <CreateInsightExportTemplateModal
          template={overlay.data.template}
          title={overlay.data.title}
          description={overlay.data.description}
          copyLink={overlay.data.copyLink}
          exampleLink={overlay.data.exampleLink}
          image={overlay.data.image}
          imageAlt={overlay.data.imageAlt}
          icon={overlay.data.icon}
          availableExportData={overlay.data.availableExportData}
        />
      )
    case 'sendInvoicePaymentReminderModal':
      return (
        <SendInvoicePaymentReminderModal
          invoiceId={overlay.data.invoiceId}
          customer={overlay.data.customer}
          invoiceNumber={overlay.data.invoiceNumber}
        ></SendInvoicePaymentReminderModal>
      )

    case 'startBillingModal':
      return <StartBillingModal {...overlay.data}></StartBillingModal>

    case 'archiveBillingScheduleModal':
      return (
        <ArchiveBillingScheduleModal
          {...overlay.data}
        ></ArchiveBillingScheduleModal>
      )

    case 'archiveCustomerModal':
      return <ArchiveCustomerModal {...overlay.data}></ArchiveCustomerModal>

    case 'collectEmailModal':
      return <CollectEmailModal></CollectEmailModal>

    case 'linkAliasToCustomerModal':
      return (
        <LinkAliasToCustomerForm {...overlay.data}></LinkAliasToCustomerForm>
      )

    default:
      return <Fragment />
  }
}

const Overlay: FC = () => {
  const dispatch = useDispatch()
  const overlay = useSelector(state => state.overlay)

  return (
    <Modal
      onClose={() => dispatch(closeOverlay())}
      size={overlay.size || 'xl'}
      isOpen={overlay.open}
      scrollBehavior="inside"
      closeOnOverlayClick={overlay.closeOnOverlayClick}
      isCentered={overlay.isCentered}
      variant={overlay.variant}
      motionPreset="none"
    >
      <Fade in={overlay.open}>
        <ModalOverlay opacity={0.7} />
        <OverlayContent overlay={overlay} />
      </Fade>
    </Modal>
  )
}

export default Overlay
