import { Box, Flex, Text } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  PercentageField,
  PriceField,
  SelectField,
  SelectFieldWithActions,
  TextField
} from '@sequencehq/core-components'
import { useLinearPriceForm } from './useLinearPriceForm'
import {
  AvailableStandardFrequency,
  LinearPriceType
} from 'common/drawers/PricingEditor/domain'
import { PricingInformation } from '../common/PricingInformation'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { GreyGrey90 } from '@sequencehq/design-tokens'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'

export const LinearPriceListPriceForm = () => {
  const { fieldsConfig, currency, formData } = useLinearPriceForm()

  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingInformation
        value={fieldsConfig.pricingModel.value}
        isDisabled={fieldsConfig.pricingModel.disabled}
        options={fieldsConfig.pricingModel.options}
        validationErrors={fieldsConfig.pricingModel.validationErrors}
        onChange={fieldsConfig.pricingModel.onChange}
        description={fieldsConfig.pricingModel.description}
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="linear.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <SelectField
        label="Currency"
        data-testid="linear.currency"
        value={fieldsConfig.currency.value}
        onChange={newCurrency =>
          fieldsConfig.currency.onChange(newCurrency as Currency)
        }
        options={fieldsConfig.currency.options}
        validationErrors={fieldsConfig.currency.validationErrors}
        isDisabled={fieldsConfig.currency.disabled}
        placeholder="Select currency"
      />

      <Box paddingBottom="8px">
        <HorizontalSelectorField
          label="Frequency"
          data-testid="linear.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <SelectFieldWithActions
          label="Usage metric"
          data-testid="linear.usageMetric"
          placeholder="Select usage metric"
          value={fieldsConfig.usageMetricId.value}
          options={fieldsConfig.usageMetricId.options}
          onChange={fieldsConfig.usageMetricId.onChange}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
        <HorizontalSelectorField
          label="Type"
          data-testid="linear.linearPriceType"
          value={fieldsConfig.linearPriceType.value}
          options={fieldsConfig.linearPriceType.options}
          onChange={newValue =>
            fieldsConfig.linearPriceType.onChange(newValue as LinearPriceType)
          }
          validationErrors={fieldsConfig.linearPriceType.validationErrors}
          isDisabled={fieldsConfig.linearPriceType.disabled}
        />
        {formData.linearPriceType === 'FIXED' && (
          <PriceField
            label="Price per unit"
            data-testid="linear.price"
            value={fieldsConfig.price.value}
            onChange={newValue => fieldsConfig.price.onChange(newValue)}
            validationErrors={fieldsConfig.price.validationErrors}
            currency={currency}
            isDisabled={fieldsConfig.price.disabled}
          />
        )}
        {fieldsConfig.linearPriceType.value === 'PERCENTAGE' && (
          <>
            <PercentageField
              label="Percentage"
              data-testid="linear.percentage"
              value={fieldsConfig.percentage.value}
              onChange={newValue => fieldsConfig.percentage.onChange(newValue)}
              validationErrors={fieldsConfig.percentage.validationErrors}
              isDisabled={fieldsConfig.percentage.disabled}
            />
            <Flex flexDirection="row">
              <PriceField
                label="Floor fee (optional)"
                data-testid="linear.minPrice"
                value={fieldsConfig.minPrice.value}
                onChange={newValue => fieldsConfig.minPrice.onChange(newValue)}
                validationErrors={fieldsConfig.minPrice.validationErrors}
                currency={currency}
                isDisabled={fieldsConfig.minPrice.disabled}
              />
              <Box width="16px"></Box>
              <PriceField
                label="Fee cap (optional)"
                data-testid="linear.maxPrice"
                value={fieldsConfig.maxPrice.value}
                onChange={newValue => fieldsConfig.maxPrice.onChange(newValue)}
                validationErrors={fieldsConfig.maxPrice.validationErrors}
                currency={currency}
                isDisabled={fieldsConfig.maxPrice.disabled}
              />
            </Flex>
          </>
        )}
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
