export const IntegrationMoreInfo = {
  OverviewLink: 'https://docs.sequencehq.com/integrations/overview',
  AccountingOverviewLink:
    'https://docs.sequencehq.com/integrations/accounting-overview',
  XeroLink: 'https://docs.sequencehq.com/integrations/xero',
  StripeLink: 'https://docs.sequencehq.com/integrations/stripe',
  QuickbooksLink: 'https://docs.sequencehq.com/integrations/quickbooks',
  NetsuiteLink: 'https://docs.sequencehq.com/integrations/netsuite',
  GoogleSheetsLink: 'https://docs.sequencehq.com/data/exporting-data',
  BigQueryLink: 'https://docs.sequencehq.com/integrations/bigquery',
  SnowflakeLink: 'https://docs.sequencehq.com/integrations/snowflake',
  RedshiftLink: 'https://docs.sequencehq.com/integrations/redshift',
  HubSpotLink: 'https://docs.sequencehq.com/integrations/hubspot'
}
