import { INITIAL_PRICING_EDITOR_STATE } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import { PricingEditorData } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/usePricingEditor'
import { createContext, useContext } from 'react'

export type PricingEditorContextData = {
  data: PricingEditorData['data']
  derived: PricingEditorData['derived']
  editor: PricingEditorData['editor']
  configuration: PricingEditorData['configuration']
  functions: Omit<PricingEditorData['functions'], 'loadPricingEditor'>
}

// eslint-disable-next-line no-console
const notImplemented = () => console.error('Function not implemented')

const PricingEditorContext = createContext<PricingEditorContextData>({
  data: INITIAL_PRICING_EDITOR_STATE.data,
  derived: INITIAL_PRICING_EDITOR_STATE.derived,
  editor: INITIAL_PRICING_EDITOR_STATE.editor,
  configuration: INITIAL_PRICING_EDITOR_STATE.configuration,
  functions: {
    updateData: notImplemented,
    updateEditor: notImplemented,
    updateFormData: notImplemented,
    addPrice: notImplemented,
    removePrice: notImplemented,
    saveNewPrice: () => Promise.reject('Function not implemented'),
    closeEditor: notImplemented,
    fieldIsDisabled: () => {
      // eslint-disable-next-line no-console
      console.error('Function not implemented')
      return true
    }
  }
})

export const usePricingEditorContext = () => {
  const pricingEditorContext = useContext(PricingEditorContext)

  return pricingEditorContext
}

export const PricingEditorProvider = PricingEditorContext.Provider
