import { useFlags } from 'launchdarkly-react-client-sdk'
import { CreditNoteDetailsPage } from 'CreditNotes/pages'
import CreditNoteWrapper from 'components/CreditNote'

const CreditNoteDetailRoute = () => {
  const flags = useFlags()

  if (flags.useCreditNotesV2) {
    return <CreditNoteDetailsPage />
  }

  return <CreditNoteWrapper />
}

export default CreditNoteDetailRoute
