import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react'
import { GreyGrey80, Lato14ExtendedRegular } from '@sequencehq/design-tokens'
import { useStytchB2BClient } from '@stytch/react/dist/b2b'
import { Toast } from 'components/TeamSettings/common/Toast'
import { useTeamSettings } from 'components/TeamSettings/hooks/useTeamSettings'
import { useState } from 'react'

interface RevokeInviteModalProps {
  isOpen: boolean
  onClose: () => void
}

export const RevokeInviteModal = ({
  isOpen,
  onClose
}: RevokeInviteModalProps) => {
  const stytch = useStytchB2BClient()
  const { selectedMember: member, refetchMembers } = useTeamSettings()
  const toast = useToast()
  const [isRevoking, setIsRevoking] = useState(false)

  if (!member) {
    return null
  }

  const revokeInvite = async () => {
    try {
      setIsRevoking(true)
      const response = await stytch.organization.members.delete(
        member.member_id
      )

      if (response.status_code === 200) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          render: () => (
            <Toast
              type="success"
              title="Invitation revoked!"
              description={`Invitation to ${member.email_address} was revoked.`}
              isClosable
            />
          )
        })
        onClose()
        refetchMembers()
      }
    } catch (e) {
      toast({
        position: 'bottom-left',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title="Error revoking invitation"
            description={`An error occurred while revoking the invitation to ${member.email_address}.`}
            isClosable
          />
        )
      })
      onClose()
    } finally {
      setIsRevoking(false)
    }
  }

  return (
    <Modal variant="v2" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW={414}>
        <ModalHeader as="h2" border="none" p="16px 16px 4px">
          Revoke Invite
        </ModalHeader>
        <ModalBody p="0 16px 16px">
          <Text {...Lato14ExtendedRegular} color={GreyGrey80}>
            This will revoke the pending invite for {member.email_address}. You
            can invite them again afterwards.
          </Text>
          <Flex width="100%" gap={2} mt={4}>
            <Button
              variant="secondary2"
              size="md"
              onClick={onClose}
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="warning2"
              size="md"
              onClick={() => void revokeInvite()}
              width="100%"
              isDisabled={isRevoking}
            >
              Revoke
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
