import { Dispatch, SetStateAction, useCallback } from 'react'

import * as Sentry from '@sentry/react'
import { useStytchB2BClient } from '@stytch/react/dist/b2b'
import posthog from 'posthog-js'

import { redirectToLogin } from 'lib/auth/session'
import { NavigationV3ContextType } from 'components/NavigationV3/NavigationV3Provider.types'

type UseNavigationV3Functions = ({
  setIsSwitchingWorkspace
}: {
  setIsSwitchingWorkspace: Dispatch<SetStateAction<boolean>>
}) => {
  functions: NavigationV3ContextType['functions']
}

export const useNavigationV3Functions: UseNavigationV3Functions = ({
  setIsSwitchingWorkspace
}) => {
  const stytch = useStytchB2BClient()

  const switchWorkspace = useCallback(
    async (props: { workspaceId: string }) => {
      try {
        setIsSwitchingWorkspace(true)

        await stytch.session.exchange({
          organization_id: props.workspaceId,
          /**
           * `session_duration_minutes` is set to 45 days to match the session length set by
           * the backend when logging in.
           * Thread: https://sequenceslack.slack.com/archives/C0393LQDTJS/p1712223109829969
           */
          session_duration_minutes: 64800
        })

        window.location.reload()
      } catch (e) {
        Sentry.captureException(e)
        setIsSwitchingWorkspace(false)
      }
    },
    [setIsSwitchingWorkspace, stytch.session]
  )

  const handleLogOut = useCallback(async () => {
    try {
      await stytch.session.revoke()
      posthog.reset()
      redirectToLogin()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [stytch])

  return {
    functions: {
      switchWorkspace,
      handleLogOut
    }
  }
}
