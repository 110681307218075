import { developerLog } from '@sequencehq/utils'
import {
  EditorMode,
  PricingEditorDomainInput,
  PricingEditorDomainOutput,
  PricingEditorPortErrors,
  PricingEditorPortImplementation
} from 'common/drawers/PricingEditor/domain'
import { useCallback, useMemo } from 'react'
import { loadCommonData } from '../common.api.v1/loadCommonData'
import { loadListPrices, loadVariantPrice } from './entityLoaders'
import * as adapters from '../../adapters'
import {
  BASE_INITIAL_VARIANT_PRICE,
  INITIAL_PRICING_EDITOR_STATE,
  NEW_PRICE_PATTERN
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import * as mutators from './entitySaving'
import deepmerge from 'deepmerge'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

type UseVariantPricePorts = (props: {
  initialMode: EditorMode
  productId: string
  variantPriceId: string
  listPriceId?: string
  currency?: Currency
}) => PricingEditorPortImplementation

export const loadAllData = async (args: {
  configuration: Partial<PricingEditorDomainInput['configuration']>
  initialData: Partial<PricingEditorDomainInput['initialData']>
  variantPriceId?: string
  productId: string
  listPriceId?: string
}): Promise<{
  data: PricingEditorDomainInput | null
  error: PricingEditorPortErrors | null
}> => {
  developerLog(`%c[PricingEditor] Loading variant price`, 'color: pink;', {
    props: { id: args.variantPriceId }
  })
  const { accountSettings, xeroIntegration, product, usageMetrics } =
    await loadCommonData({ productId: args.productId })

  const errorResponse = [
    accountSettings,
    xeroIntegration,
    product,
    usageMetrics
  ].some(response => Boolean(response.error))

  if (
    errorResponse ||
    !accountSettings.data ||
    !usageMetrics.data ||
    !xeroIntegration.data ||
    !product.data
  ) {
    return {
      data: null,
      error: PricingEditorPortErrors.Other
    }
  }

  const listPrices = await loadListPrices({ productId: args.productId })
  const listPrice = listPrices.find(({ id }) => id === args.listPriceId)
  const loadedVariantPrice =
    args.variantPriceId && !args.variantPriceId.match(NEW_PRICE_PATTERN)
      ? await loadVariantPrice(args.variantPriceId)
      : undefined

  if (loadedVariantPrice?.error) {
    return {
      data: null,
      error: loadedVariantPrice.error
    }
  }

  const res = {
    xeroIntegration: xeroIntegration.data,
    listPrice,
    listPrices,
    product: product.data,
    usageMetrics: usageMetrics.data,
    price: loadedVariantPrice?.data ?? {
      ...BASE_INITIAL_VARIANT_PRICE,
      ...listPrice,
      productId: product.data.id,
      listPriceId: args.listPriceId,
      currency:
        args.configuration.currency ??
        listPrice?.currency ??
        accountSettings.data.enabledCurrencies[0]
    }
  }

  const domainInput = adapters.dataAdapter.in.variant(res)

  return {
    data: {
      data: domainInput.domainInputData,
      configuration: deepmerge(args.configuration, {
        availableCurrencies: accountSettings.data.enabledCurrencies
      }),
      initialData: deepmerge(args.initialData, domainInput.initialData, {
        arrayMerge: (_, source: unknown[]) => source
      })
    },
    error: null
  }
}

export const useVariantPricePorts: UseVariantPricePorts = props => {
  const loadCore = useCallback(async () => {
    return loadAllData({
      configuration: {
        ...INITIAL_PRICING_EDITOR_STATE['configuration'],
        currency: props.currency,
        mode: props.initialMode,
        priceType: 'variant'
      },
      initialData: {
        mode: props.initialMode
      },
      listPriceId: props.listPriceId,
      variantPriceId: props.variantPriceId,
      productId: props.productId
    })
  }, [
    props.currency,
    props.initialMode,
    props.listPriceId,
    props.productId,
    props.variantPriceId
  ])

  const saveVariant = useCallback(async (data: PricingEditorDomainOutput) => {
    developerLog(`%c[PricingEditor] Saving variant price`, 'color: pink;', {
      data
    })
    const saveData = adapters.dataAdapter.out.variant(data)
    const result = await mutators.createVariantPrice(saveData)

    return {
      success: result.success,
      error: result.error,
      data: { price: result.data }
    }
  }, [])

  const portImplementation: PricingEditorPortImplementation = useMemo(() => {
    return {
      in: {
        load: loadCore
      },
      out: {
        save: saveVariant
      }
    }
  }, [loadCore, saveVariant])

  return portImplementation
}
