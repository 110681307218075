import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'

export type v1ApiNewProduct = Dashboardv20240509Api.PostProduct.ProductBody & {
  id: string
}
type UseSaveProducts = () => {
  createProducts: (newProducts: v1ApiNewProduct[]) => Promise<
    {
      temporaryId: string
      realId: string | null
    }[]
  >
}

export const useSaveProducts: UseSaveProducts = () => {
  const createProducts = useCallback(async (newProducts: v1ApiNewProduct[]) => {
    const response = await Promise.all(
      newProducts.map(({ label, ...product }) =>
        dashboardv20240509Client
          .postProduct({
            body: {
              label: label ?? undefined,
              name: product.name
            }
          })
          .then(result => {
            if (result.error) {
              return {
                temporaryId: product.id,
                realId: null
              }
            }

            return {
              temporaryId: product.id,
              realId: result.data?.id ?? null
            }
          })
      )
    )

    return response
  }, [])

  return {
    createProducts
  }
}
