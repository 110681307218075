import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { useIntegrationManagement } from './useIntegrationManagement'
import NotFound from 'components/ErrorPages/NotFound'
import { IntegrationManagementHeader } from './IntegrationManagementHeader'
import { GreyGrey0 } from '@sequencehq/design-tokens'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import { IntegrationManagementActions } from './IntegrationManagementActions/IntegrationManagementActions'
import { IntegrationReconnectBanner } from './IntegrationReconnectBanner'
import { DefaultIntegrationManagementInspector } from './DefaultIntegrationManagementInspector'

const defaultTemplateAreas = `
      "header"
      "actions"
      "content"
    `

const inspectorTemplateAreas = `
      "header header"
      "actions actions"
      "content inspector"
    `

export const IntegrationManagement = () => {
  const hook = useIntegrationManagement()

  if (hook.loading) {
    return <SpinnerContainer />
  }

  if (!hook.integration || !hook.contentProps) {
    return <NotFound />
  }
  const ContentComponent = hook.integration.components.management
  const InspectorComponent =
    hook.integration.components.inspector ??
    DefaultIntegrationManagementInspector

  return (
    <Grid
      gridTemplateRows={'auto auto 1fr'}
      gridTemplateColumns="1fr auto"
      gridTemplateAreas={
        InspectorComponent ? inspectorTemplateAreas : defaultTemplateAreas
      }
      width="100%"
      minWidth="808px"
      overflow="hidden"
      height="100vh"
    >
      <GridItem gridArea="header">
        <IntegrationManagementHeader />
      </GridItem>
      <GridItem gridArea="actions">
        <IntegrationManagementActions />
      </GridItem>
      <GridItem
        gridArea="content"
        display="grid"
        gridTemplateColumns={'1fr auto'}
        backgroundColor={GreyGrey0}
        overflow="auto"
      >
        <GridItem display="flex" justifyContent="center">
          <Flex
            width="100%"
            maxWidth="808px"
            flexDirection="column"
            gap="20px"
            alignItems="center"
            padding="16px"
          >
            <IntegrationReconnectBanner />
            <ContentComponent {...hook.contentProps} />
          </Flex>
        </GridItem>
      </GridItem>
      <GridItem
        gridArea="inspector"
        backgroundColor={GreyGrey0}
        display="flex"
        justifyContent="flex-end"
      >
        <InspectorComponent {...hook.contentProps} />
      </GridItem>
    </Grid>
  )
}
