import * as Sentry from '@sentry/react'
import { Currency } from '@sequencehq/core-models'
import { baseApi } from 'features/api/baseApi'
import { downloadBlob } from 'lib/downloadBlob'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'
import { format } from '@sequencehq/utils/dates'

type UseInvoicesGridCsvExport = () => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (
      currency: Currency,
      startDate: Date | undefined,
      endDate: Date | undefined
    ) => void
  }
}

export const useInvoicesGridCsvExport: UseInvoicesGridCsvExport = () => {
  const notifications = useNotifications()

  const [getDownload] =
    baseApi.endpoints.getInvoiceGridCsvExport.useLazyQuerySubscription()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-csv',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  function formatDate(
    date: Date | undefined,
    formatStr: string
  ): string | undefined {
    try {
      return date
        ? format(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
            formatStr
          )
        : undefined
    } catch (e) {
      return undefined
    }
  }

  const downloadCsvFile = useCallback(
    async ({
      currency,
      startDate,
      endDate
    }: {
      currency: Currency
      startDate: Date | undefined
      endDate: Date | undefined
    }) => {
      try {
        const fulfilled = await getDownload({
          currency: currency,
          startDate: formatDate(startDate, 'yyyy-MM-dd'),
          endDate: formatDate(endDate, 'yyyy-MM-dd')
        }).unwrap()

        downloadBlob('invoice-totals-export.csv')(
          new Blob([fulfilled], { type: 'text/csv' })
        )

        showToastSuccess()
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [getDownload, showToastError, showToastSuccess]
  )

  const downloadCsv = useCallback(
    ({
      currency,
      startDate,
      endDate
    }: {
      currency: Currency
      startDate: Date | undefined
      endDate: Date | undefined
    }) => {
      showToastPreparing()

      void downloadCsvFile({ currency, startDate, endDate })
    },
    [showToastPreparing, downloadCsvFile]
  )

  return {
    download: {
      available: true,
      disabled: false,
      onClick: (currency, startDate, endDate) =>
        downloadCsv({ currency, startDate, endDate })
    }
  }
}
