import { useMemo } from 'react'
import { usePricingEditorContext } from '../../../../hooks/usePricingEditorContext'
import { toBillingFrequencyLabel } from '@sequencehq/utils'
import { useLinearPriceForm } from '../LinearPrice/useLinearPriceForm'
import { usePackagedPriceForm } from '../PackagedPrice/usePackagedPriceForm'
import { useSeatBasedPriceForm } from '../SeatBasedPrice/useSeatBasedPriceForm'
import { useGraduatedPriceForm } from '../GraduatedPrice/useGraduatedPriceForm'
import { useVolumePriceForm } from '../VolumePrice/useVolumePriceForm'
import { useCommonFieldsForm } from '../CommonFields/useCommonFieldsForm'
import { PricingModel } from '../../../../domainManagement/pricingEditor.types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { seatProrationLabels } from 'common/drawers/PricingEditor/view/editors/SeatBasedPrice/SeatProrationDropdown/SeatProrationDropdown.tsx'

type PriceDetails = Array<{
  label: string
  value: string
}>
type UseReadOnlyDetails = () => {
  priceDetails: PriceDetails
}

export const useReadOnlyDetails: UseReadOnlyDetails = () => {
  const ctx = usePricingEditorContext()
  const flags = useFlags()

  /**
   * Can't say I like this setup (using the form hooks elsewhere),
   * but it's the best approach I could
   * think of in the short term that didn't involve restructuring all
   * the forms to drop the reusable block approach as it's set up
   * today (reusable hooks? Sure! But components, not so much).
   *
   * At least this approach can be easily sliced up and moved to the
   * various form hooks later!
   */
  const commonFieldsForm = useCommonFieldsForm()

  const commonPriceDetails = useMemo(() => {
    return [
      {
        label: 'Pricing model',
        value:
          commonFieldsForm.fieldsConfig.pricingModel.options.find(
            opt =>
              opt.value === commonFieldsForm.fieldsConfig.pricingModel.value
          )?.label ?? 'Not selected'
      },
      {
        label: 'Billing frequency',
        value: `${toBillingFrequencyLabel(
          ctx.data.formData[ctx.data.formData.common.pricingModel]
            .billingFrequency
        )} / ${
          ctx.data.formData[ctx.data.formData.common.pricingModel]
            .billingType === 'IN_ADVANCE'
            ? 'In advance'
            : 'In arrears'
        }`
      }
    ]
  }, [commonFieldsForm, ctx])

  const linearPriceForm = useLinearPriceForm({
    formDisabled: true
  })
  const linearPriceDetails: PriceDetails = useMemo(() => {
    return [
      {
        label: 'Usage metric',
        value:
          linearPriceForm.fieldsConfig.usageMetricId.options.find(
            opt =>
              opt.value === linearPriceForm.fieldsConfig.usageMetricId.value
          )?.label ?? 'Not selected'
      }
    ]
  }, [linearPriceForm])

  const packagedPriceForm = usePackagedPriceForm({
    formDisabled: true
  })
  const packagedPriceDetails: PriceDetails = useMemo(() => {
    return [
      {
        label: 'Usage metric',
        value:
          packagedPriceForm.fieldsConfig.usageMetricId.options.find(
            opt =>
              opt.value === packagedPriceForm.fieldsConfig.usageMetricId.value
          )?.label ?? 'Not selected'
      }
    ]
  }, [packagedPriceForm])

  const seatBasedPriceForm = useSeatBasedPriceForm({
    formDisabled: true
  })

  const prorationDetail = flags.useSeatsProrateAllChanges
    ? {
        label: 'Proration',
        value:
          seatProrationLabels[
            seatBasedPriceForm.fieldsConfig.prorationStrategy.value
          ]
      }
    : {
        label: 'Prorate overage fees',
        value:
          seatBasedPriceForm.fieldsConfig.prorationStrategy.value ===
          'PRORATE_INCREMENTS'
            ? 'Yes'
            : 'No'
      }

  const seatBasedPriceDetails: PriceDetails = useMemo(() => {
    return [
      {
        label: 'Seat metric',
        value:
          seatBasedPriceForm.fieldsConfig.seatTypeId.options.find(
            opt =>
              opt.value === seatBasedPriceForm.fieldsConfig.seatTypeId.value
          )?.label ?? 'Not selected'
      },
      prorationDetail
    ]
  }, [seatBasedPriceForm])

  const standardPriceDetails: PriceDetails = useMemo(() => {
    return []
  }, [])

  const graduatedPriceForm = useGraduatedPriceForm({
    formDisabled: true
  })
  const graduatedPriceDetails: PriceDetails = useMemo(() => {
    return [
      {
        label: 'Usage metric',
        value:
          graduatedPriceForm.fieldsConfig.usageMetricId.options.find(
            opt =>
              opt.value === graduatedPriceForm.fieldsConfig.usageMetricId.value
          )?.label ?? 'Not selected'
      },
      {
        label: 'Type',
        value:
          graduatedPriceForm.fieldsConfig.usageTierType.options.find(
            opt => opt.value === graduatedPriceForm.formData.usageTierType
          )?.label ?? 'Not selected'
      },
      {
        label: flags.useUsageResetCadences
          ? 'Usage calculation period'
          : 'Calculation mode',
        value:
          (flags.useUsageResetCadences
            ? graduatedPriceForm.fieldsConfig.usageCalculationMode.options.find(
                opt =>
                  opt.value ===
                  graduatedPriceForm.fieldsConfig.usageCalculationMode.value
              )?.label
            : graduatedPriceForm.fieldsConfig.usageCalculationPeriod.options.find(
                opt =>
                  opt.value ===
                  graduatedPriceForm.fieldsConfig.usageCalculationPeriod.value
              )?.label) ?? 'Not selected'
      }
    ]
  }, [flags.useUsageResetCadences, graduatedPriceForm])

  const volumePriceForm = useVolumePriceForm({
    formDisabled: true
  })
  const volumePriceDetails: PriceDetails = useMemo(() => {
    return [
      {
        label: 'Usage metric',
        value:
          volumePriceForm.fieldsConfig.usageMetricId.options.find(
            opt =>
              opt.value === volumePriceForm.fieldsConfig.usageMetricId.value
          )?.label ?? 'Not selected'
      },
      {
        label: 'Type',
        value:
          volumePriceForm.fieldsConfig.usageTierType.options.find(
            opt => opt.value === volumePriceForm.formData.usageTierType
          )?.label ?? 'Not selected'
      }
    ]
  }, [volumePriceForm])

  const priceDetailsMap: Record<PricingModel, PriceDetails> = useMemo(() => {
    return {
      LINEAR: linearPriceDetails,
      PACKAGED: packagedPriceDetails,
      SEAT_BASED_LINEAR: seatBasedPriceDetails,
      SEAT_BASED_GRADUATED: seatBasedPriceDetails,
      STANDARD: standardPriceDetails,
      VOLUME: volumePriceDetails,
      GRADUATED: graduatedPriceDetails
    }
  }, [
    linearPriceDetails,
    packagedPriceDetails,
    seatBasedPriceDetails,
    standardPriceDetails,
    graduatedPriceDetails,
    volumePriceDetails
  ])

  const priceDetails = useMemo(() => {
    return [
      ...commonPriceDetails,
      ...priceDetailsMap[ctx.data.formData.common.pricingModel]
    ]
  }, [
    commonPriceDetails,
    priceDetailsMap,
    ctx.data.formData.common.pricingModel
  ])

  return {
    priceDetails
  }
}
