import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

type UseLoadIntegrations = () => {
  loading: boolean
  data?: {
    integrations: Integration[]
  }
}

export type Integration = Dashboardv20240509Api.GetIntegrations.Integration

export const useLoadIntegrations: UseLoadIntegrations = () => {
  const [integrations, setIntegrations] = useState<Integration[] | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadIntegrations = useCallback(
    async () => dashboardv20240509Client.getIntegrations(),
    []
  )

  useEffect(() => {
    setIsLoading(true)
    loadIntegrations()
      .then(loadedIntegrations => {
        setIntegrations(loadedIntegrations?.data?.items)
      })
      .catch(error => Sentry.captureException(error))
      .finally(() => setIsLoading(false))
  }, [loadIntegrations])

  const data = useMemo(() => {
    if (isLoading || !integrations) {
      return
    }

    return {
      integrations
    }
  }, [isLoading, integrations])

  return {
    loading: isLoading,
    data
  }
}
