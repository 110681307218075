import { FC } from 'react'
import { CustomerView } from 'modules/Cube/view/layouts/billingSchedule/widgets/Customer/CustomerView'
import { WidgetSection } from 'modules/Cube/view/common/atomic/WidgetSection'
import { Box, Flex, Icon, Link, Text } from '@chakra-ui/react'
import { useCustomerWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/Customer/useCustomerWidget'
import {
  InputSelectControlField,
  SwitchLineField,
  TooltipLegacy
} from '@sequencehq/core-components'
import {
  GreyGrey10,
  GreyGrey30,
  GreyGrey60,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Regular,
  Lato14Bold,
  Lato14ExtendedBold,
  Lato14ExtendedRegular,
  Lato14Regular,
  RedRed60
} from '@sequencehq/design-tokens'
import BuildingOffice2Icon from '@heroicons/react/20/solid/BuildingOffice2Icon'
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon'
import { Link as RouterLink } from 'react-router-dom'

export const CustomerWidget: FC = () => {
  const {
    customer,
    showEditButton,
    showCancelButton,
    showCustomerSelection,
    showCustomerView,
    updateView,
    fieldConfig
  } = useCustomerWidget()

  return (
    <WidgetSection
      width="794px"
      title="Customer"
      options={
        <>
          {showEditButton && (
            <Text
              textStyle="link"
              onClick={() => updateView('customerSelection')}
              _hover={{
                cursor: 'pointer'
              }}
              data-testid="customer.edit"
            >
              Edit
            </Text>
          )}
          {showCancelButton && (
            <Text
              textStyle="link"
              onClick={() => updateView('customerView')}
              _hover={{
                cursor: 'pointer'
              }}
            >
              Cancel
            </Text>
          )}
        </>
      }
    >
      {showCustomerView && <CustomerView customer={customer} />}
      {showCustomerSelection && (
        <Flex flexDirection="column" px="24px" pb="20px">
          <Box width="376px">
            <InputSelectControlField
              data-testid="customer.select"
              placeholder="Select a customer"
              initialValue={fieldConfig.customer.value}
              options={fieldConfig.customer.options}
              onChange={fieldConfig.customer.onChange}
              onCreate={fieldConfig.customer.onAddNew}
              createLabel="Create new"
              matchWidth
              disabled={fieldConfig.customer.disabled}
              validationErrors={fieldConfig.customer.validationErrors}
            />
          </Box>

          {Boolean(fieldConfig.customer.validationErrors.length) && (
            <Box
              marginTop="8px"
              key={fieldConfig.customer.validationErrors[0].message}
              {...Lato13Regular}
              color={RedRed60}
            >
              {fieldConfig.customer.validationErrors[0].message}
            </Box>
          )}
        </Flex>
      )}
      {!fieldConfig.rollUpBilling.hidden && (
        <Flex
          padding="12px 16px"
          margin="0 24px"
          marginBottom="20px"
          backgroundColor={GreyGrey10}
          borderRadius="6px"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Flex alignItems="center" gap="4px" justifyContent="start">
            <SwitchLineField
              data-testid="customer.rollUpBilling"
              text={<Text {...Lato14Regular}>Enable roll-up billing for </Text>}
              styles={{
                wrapper: {
                  marginBottom: 0
                }
              }}
              {...fieldConfig.rollUpBilling}
            />

            <TooltipLegacy
              style={{
                wrapper: {
                  display: 'inline-block',
                  borderBottom: `1px dashed ${GreyGrey70}`,
                  padding: '4px 0 3px',
                  color: GreyGrey70,
                  transition: '250ms',
                  cursor: 'help',
                  _hover: {
                    color: IndigoIndigo50,
                    borderBottom: `1px dashed ${IndigoIndigo50}`
                  },
                  whiteSpace: 'nowrap'
                },
                content: {
                  padding: '0'
                }
              }}
              content={
                <Box>
                  <Flex
                    backgroundColor={GreyGrey10}
                    color={GreyGrey70}
                    borderBottom={GreyGrey30}
                    padding="8px 16px"
                    alignItems="center"
                    gap="8px"
                    {...Lato14ExtendedBold}
                  >
                    <Icon as={BuildingOffice2Icon} height="16px" width="16px" />
                    Child{' '}
                    {fieldConfig.rollUpBilling.childAccountCount > 1
                      ? 'customers'
                      : 'customer'}
                  </Flex>
                  <Flex flexDirection="column" gap="8px" padding="12px 16px">
                    {fieldConfig.rollUpBilling.childAccounts?.map(alias => (
                      <RouterLink
                        key={alias}
                        {...Lato14ExtendedRegular}
                        color={GreyGrey90}
                        to={`/customers/${customer.id}`}
                      >
                        {alias}
                      </RouterLink>
                    ))}
                  </Flex>
                </Box>
              }
            >
              <Text {...Lato14Regular} color={GreyGrey70}>
                {`${fieldConfig.rollUpBilling.childAccountCount} ${
                  fieldConfig.rollUpBilling.childAccountCount > 1
                    ? 'customers'
                    : 'customer'
                }`}
              </Text>
            </TooltipLegacy>
          </Flex>

          <TooltipLegacy
            content={
              <Text>
                Use roll-up billing to bill a parent customer for any child
                customer's usage.{' '}
                <Link
                  href="https://docs.sequencehq.com/billing/create-billing-schedules#roll-up-billing]"
                  isExternal
                  {...Lato14Bold}
                  color="indigo.50"
                  display="inline-block"
                >
                  Learn more
                </Link>
              </Text>
            }
          >
            <InformationCircleIcon height="20px" color={GreyGrey60} />
          </TooltipLegacy>
        </Flex>
      )}
    </WidgetSection>
  )
}
