import {
  AvailableServices,
  IntegrationFeatureDisabledReason,
  ResolvedIntegrations
} from 'modules/Integrations/domain/integrations.domain.types'

export const getUninstallAvailable =
  (ctx: { resolvedIntegrations: ResolvedIntegrations }) =>
  (service: AvailableServices) =>
  () => {
    const integrationConfig = ctx.resolvedIntegrations[service]

    if (!integrationConfig) {
      throw new Error('Integration not found')
    }

    const featureAvailable = Boolean(
      integrationConfig.features?.canUninstall &&
        integrationConfig.state === 'CONNECTED'
    )

    return {
      available: {
        visible: true,
        enabled: featureAvailable
      },
      reasons: !featureAvailable
        ? [IntegrationFeatureDisabledReason.FeatureNotAvailable]
        : []
    }
  }
