import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

const editableDuringStatuses = [
  CubeStatus.ScheduleDraft,
  CubeStatus.ScheduleUnsaved,
  CubeStatus.SchedulePending
]

export const getRecurrenceDateAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const phasesOnlyContainMonthlyPrices = !Object.values(
    prevState.data.phases
  ).find(phase => {
    return (
      phase.priceIds.length &&
      phase.priceIds
        .map(priceId => prevState.data.prices[priceId])
        .find(
          price => !['MONTHLY', 'ONE_TIME'].includes(price?.billingFrequency)
        )
    )
  })

  const viablePhase = Object.values(editableDuringStatuses).includes(
    prevState.data.common.status
  )

  if (!phasesOnlyContainMonthlyPrices || !viablePhase) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        ...(!phasesOnlyContainMonthlyPrices
          ? [
              {
                reasonType: DisabledReasonType.NonMonthlyPricesPresent,
                metadata: {}
              }
            ]
          : []),
        ...(!viablePhase
          ? [
              {
                reasonType: DisabledReasonType.InvalidStatus,
                metadata: {}
              }
            ]
          : [])
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
