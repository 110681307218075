import {
  PricingEditorMode,
  PricingEditorReducerState
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const INITIAL_PRICING_EDITOR_STATE: PricingEditorReducerState = {
  initialData: {
    price: null,
    product: {
      id: '',
      name: '',
      label: ''
    }
  },
  data: {
    prices: {},
    listPrices: {},
    metrics: {},
    product: {
      id: '',
      name: '',
      label: ''
    },
    ledgerIntegrations: {},
    formData: {
      common: {
        externalIds: {},
        name: '',
        pricingModel: 'STANDARD',
        currency: 'GBP',
        listPriceId: ''
      },
      STANDARD: {
        price: '0.00',
        billingType: 'IN_ARREARS',
        billingFrequency: 'MONTHLY'
      },
      LINEAR: {
        price: '0.00',
        percentage: '0',
        usageMetricId: '',
        linearPriceType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        minPrice: '',
        maxPrice: '',
        parameters: {}
      },
      VOLUME: {
        usageMetricId: '',
        usageTierType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        includePercentageLimits: false,
        parameters: {},
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ],
        percentageTiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0.00',
            lastUnit: '1.00',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '1.01',
            lastUnit: '',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          }
        ]
      },
      GRADUATED: {
        usageMetricId: '',
        usageTierType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        parameters: {},
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ],
        percentageTiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0.00',
            lastUnit: '1.00',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '1.01',
            lastUnit: '',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          }
        ],
        usageCalculationMode: 'BILLING_PERIOD'
      },
      PACKAGED: {
        usageMetricId: '',
        pricePerPackage: '0.00',
        packageSize: '0',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        parameters: {}
      },
      SEAT_BASED_LINEAR: {
        seatTypeId: '',
        pricePerSeat: '0.00',
        minimumSeats: '0',
        prorationStrategy: 'PRORATE_INCREMENTS',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        overagesBillingFrequency: 'MONTHLY'
      },
      SEAT_BASED_GRADUATED: {
        seatTypeId: '',
        pricePerSeat: '0.00',
        minimumSeats: '0',
        prorationStrategy: 'PRORATE_INCREMENTS',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        overagesBillingFrequency: 'MONTHLY',
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ]
      }
    },
    defaultPricingType: 'STANDARD'
  },
  derived: {
    queries: {
      selectedPrice: null,
      canAddPrice: true,
      apiFormatPrices: [],
      existingPrices: {},
      newPrices: {},
      uniquePrices: {},
      availableFeatures: {
        changeListPrice: true,
        save: true,
        showAllPriceFields: true,
        showPriceList: true,
        showProductFields: false
      },
      canSave: false,
      priceHasBeenUpdated: false,
      productHasBeenUpdated: false
    }
  },
  configuration: {
    enableListPrices: false,
    currency: undefined,
    availableCurrencies: [],
    availableStandardFrequencies: ['MONTHLY', 'QUARTERLY', 'YEARLY'],
    mode: PricingEditorMode.CREATE
  },
  editor: {
    formsValid: {},
    loaded: false,
    selectedPrice: null,
    showValidationErrors: false
  }
}

export const NEW_PRICE_PATTERN = /^new-price::/
export const NEW_PRODUCT_PATTERN = /^new-product::/

export const NEW_PRICE_PREFIX = 'new-price::'
export const NEW_PRODUCT_PREFIX = 'new-product::'
