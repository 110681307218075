import {
  ActionHandler,
  UpdateEditorAction
} from 'modules/Cube/domain/cube.domain.types'
import deepmerge from 'deepmerge'

export const updateEditor: ActionHandler<UpdateEditorAction> =
  prevState => action => {
    const newEditorData = deepmerge(prevState.editor, action.payload)

    return {
      ...prevState,
      editor: newEditorData
    }
  }
