import { NEW_PRICE_PATTERN } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { ListPriceEditorMode } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { useMemo } from 'react'

export const useEditorHeader = () => {
  const listPriceEditorContext = useListPriceEditorContext()

  const editorTitle = useMemo(() => {
    if (
      listPriceEditorContext.configuration.mode === ListPriceEditorMode.VIEW
    ) {
      return `List price for ${listPriceEditorContext.data.product.name}`
    }

    if (listPriceEditorContext.editor.selectedPrice?.match(NEW_PRICE_PATTERN)) {
      return `Add list price to ${listPriceEditorContext.data.product.name}`
    }

    return `Edit list price for ${listPriceEditorContext.data.product.name}`
  }, [listPriceEditorContext])

  return {
    title: editorTitle,
    showNewPill:
      listPriceEditorContext.editor.selectedPrice?.match(NEW_PRICE_PATTERN)
  }
}
