import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import { Button, Modal, useModalContext } from '@sequencehq/core-components'
import PageError from 'components/ErrorPages/PageError'
import { DefRevScheduleProgressVisualization } from '../../common/DefRevScheduleProgressVisualization'
import { DefRevScheduleSummary } from '../../common/DefRevScheduleSummary'
import { CheckCircleIcon } from '@heroicons/react/16/solid'
import { RecognizeRevenueModal } from './RecognizeRevenueModal'
import { DefRevScheduleProgressGraph } from '../../DefRevScheduleProgressGraph'

export const MilestoneRecognitionDrawerLayout = (props: {
  defRevScheduleId: string
}) => {
  const modalCtx = useModalContext()

  return (
    <Grid
      gridTemplateAreas={`
    "header"
    "content"
    "footer"
    `}
      gridTemplateRows="auto 1fr auto"
      overflow="hidden"
      height="100%"
      width="100%"
    >
      <GridItem gridArea="header">
        <Modal.Header showCloseButton withBorder>
          Overview
        </Modal.Header>
      </GridItem>
      <GridItem gridArea="content" overflow="auto">
        <ErrorBoundary fallback={PageError}>
          <Modal.Content>
            <Flex flexDirection="column" gap="24px">
              <DefRevScheduleProgressVisualization
                defRevScheduleId={props.defRevScheduleId}
              />
              <DefRevScheduleSummary
                defRevScheduleId={props.defRevScheduleId}
              />
              <DefRevScheduleProgressGraph
                defRevScheduleId={props.defRevScheduleId}
              />
            </Flex>
          </Modal.Content>
        </ErrorBoundary>
      </GridItem>
      <GridItem gridArea="footer">
        <Modal.Footer>
          <RecognizeRevenueModal
            defRevScheduleId={props.defRevScheduleId}
            onSuccess={() => {
              modalCtx.setIsOpen(false)
            }}
            trigger={open => (
              <Button
                style={{
                  width: '100%'
                }}
                onClick={open}
                variant="secondary"
                leadingIcon={<CheckCircleIcon height="16px" width="16px" />}
                data-testid="revrec.defrevDetail.modal.milestoneRecognition.new"
              >
                Recognize revenue
              </Button>
            )}
          />
        </Modal.Footer>
      </GridItem>
    </Grid>
  )
}
