import {
  CubeReducerState,
  ValidationResults
} from 'modules/Cube/domain/cube.domain.types'

type SaveQuoteValidation = (prevState: CubeReducerState) => ValidationResults

export const saveQuoteValidation: SaveQuoteValidation = () => {
  return {
    common: {
      title: null,
      customerId: null,
      currency: null,
      phaseIds: null,
      phases: null,
      startDate: null
    },
    schedule: {
      recurrenceDayOfMonth: null,
      taxRate: null
    },
    quote: {
      contactIds: null,
      dealType: null,
      expiry: null,
      presentation: null,
      quoteNumber: null
    }
  }
}
