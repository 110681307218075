import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

const editableDuringStatuses = [
  CubeStatus.ScheduleUnsaved,
  CubeStatus.ScheduleDraft,
  CubeStatus.SchedulePending,
  CubeStatus.ScheduleActive
]

export const getPurchaseOrderNumberAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (!editableDuringStatuses.includes(prevState.data.common.status)) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: {
            status: prevState.data.common.status
          }
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
