import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { useEffect, useMemo } from 'react'
import { ApiQueryDevtools, ApiQueryProvider } from '@sequencehq/api'
import { apiQueryClient } from './features/api/apiQueryClient'
import {
  isProductionEnv,
  isSandboxEnv,
  isDemoEnv
} from 'lib/environment/environment'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'
import { setNotify } from '@sequencehq/core-models'
import { showError } from 'lib/toast'
import Plausible from 'plausible-tracker'
import { newStore } from 'features/store'
import { Provider } from 'react-redux'
import { StytchB2BProvider } from '@stytch/react/dist/b2b'
import { stytchClient } from 'lib/auth/stytch'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@sequencehq/core-theme'
import GlobalStyles from 'components/GlobalStyles'
import { PaymentsApp } from 'modules/PaymentsApp/PaymentsApp'
import { LoginApp } from 'modules/LoginApp/LoginApp'
import { DashboardApp } from 'modules/DashboardApp/DashboardApp'
import { PostHogProvider } from 'posthog-js/react'
import { initializeApiClients } from 'features/api/initializeApiClients'
import { getSentryConfig } from 'features/sentry/getSentryConfig'

const { dsn, environment } = getSentryConfig()

const posthogKey = import.meta.env.VITE_POSTHOG_API_KEY ?? ''
const posthogApiHost = import.meta.env.VITE_POSTHOG_API_HOST ?? ''

const isSandboxOrProduction = ['sandbox', 'production'].includes(environment)
const isDemo = ['demo'].includes(environment)
const isDeployed = [
  'dev',
  'stable',
  'test',
  'sandbox',
  'production',
  'demo'
].includes(environment)
const plausible = Plausible({
  domain: import.meta.env.VITE_PLAUSIBLE_DOMAIN
})

if (isDeployed) {
  const integrations = [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ]

  if (isSandboxOrProduction) {
    integrations.push(Sentry.replayIntegration({ maskAllText: false }))
  }

  Sentry.init({
    ...(isSandboxOrProduction
      ? { replaysSessionSampleRate: 0.01, replaysOnErrorSampleRate: 1.0 }
      : {}),
    dsn,
    environment,
    integrations,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.001
  })

  if (isDemo || isSandboxOrProduction) {
    plausible.enableAutoPageviews()
  }
}

initializeApiClients()

const isVerboseEnv = () => {
  return ['local', 'dev'].includes(import.meta.env.VITE_SENTRY_ENVIRONMENT)
}

setNotify(({ codec, formattedMessage }) => {
  Sentry.captureMessage(formattedMessage)

  if (isVerboseEnv()) {
    // eslint-disable-next-line no-console
    console.log(formattedMessage)
    // Don't remove above console.log, it's used to output
    // parsing errors in local, dev environments

    showError({
      type: 'Data validation error',
      message: `Failed to validate ${codec.name}`
    })
  }
})

const AppRoot = () => {
  const SubApp = useMemo(() => {
    if (window.location.pathname.startsWith('/pay')) {
      return PaymentsApp
    }

    if (window.location.pathname.startsWith('/auth')) {
      return LoginApp
    }

    return DashboardApp
  }, [])

  const posthogOptions = useMemo(() => {
    if (posthogApiHost) {
      return {
        api_host: posthogApiHost,
        disable_session_recording:
          import.meta.env.VITEST ||
          !(isProductionEnv() || isSandboxEnv() || isDemoEnv()) ||
          window.location.hostname.includes('preview.seqhq.io')
      }
    }

    return
  }, [])

  return (
    <ApiQueryProvider client={apiQueryClient}>
      <ApiQueryDevtools />
      <ChakraProvider theme={theme} portalZIndex={40}>
        <GlobalStyles />
        <Provider store={newStore()}>
          <StytchB2BProvider stytch={stytchClient}>
            <PostHogProvider apiKey={posthogKey} options={posthogOptions}>
              <SubApp />
            </PostHogProvider>
          </StytchB2BProvider>
        </Provider>
      </ChakraProvider>
    </ApiQueryProvider>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AppRoot />
)
