import {
  AvailablePricingEditorFields,
  AvailableStandardFrequency,
  PricingEditorProps,
  PricingEditorMode,
  PricingEditorProduct,
  PricingEditorInterfacePrice,
  PricingModel,
  PricingEditorInterfaceListPrice
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import {
  NEW_PRICE_PREFIX,
  NEW_PRODUCT_PATTERN
} from 'modules/Cube/domain/cube.constants'
import { Minimum, Product } from 'modules/Cube/domain/cube.domain.types'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { match } from 'ts-pattern'
import { useFlags } from 'launchdarkly-react-client-sdk'

/**
 * The outlet connector is how we expect, and enforce, the interface of the
 * module to be set in relation to the context of the main billing schedule loop.
 * Using the OutletContext hook outside of the context of an outlet connector
 * is not something we encourage!
 * @returns
 */
export const usePricingEditorConnector = (): PricingEditorProps => {
  const flags = useFlags()
  const navigate = useNavigate()
  const params = useParams<{
    phaseId?: string
    productId: string
    listPriceId?: string
  }>()
  const cubeContext = useCubeContext()

  const currentPhase = useMemo(() => {
    if (!params.phaseId) {
      return
    }
    return cubeContext.queries.resolvedPhases[params.phaseId]
  }, [cubeContext, params.phaseId])

  /**
   * Error handling that will redirect to the main editor if the current phase doesn't
   * exist - this may happen upon a refresh.
   */
  useEffect(() => {
    if (!currentPhase) {
      navigate('..')
      return
    }
  }, [currentPhase, navigate])

  const productId = useMemo(() => {
    if (!params.productId || params.productId === 'new') {
      return
    }

    return params.productId
  }, [params.productId])

  const listPriceId = useMemo(() => {
    if (!params.listPriceId || !flags.enableListPrices) {
      return
    }

    return params.listPriceId
  }, [flags.enableListPrices, params.listPriceId])

  const currentPrice = useMemo(() => {
    // If there is a productId, this means we're editing an existing price
    if (!currentPhase || !productId) {
      return
    }

    return currentPhase.prices.find(price => price.productId === productId)
  }, [productId, currentPhase])

  /**
   * The mode of the editor is driven primarily from queries against the main
   * billing schedule, which will determine the availability of these features.
   */
  const mode = useMemo(() => {
    if (!currentPhase) {
      return PricingEditorMode.VIEW
    }

    if (!productId && !listPriceId) {
      if (
        cubeContext.queries.availableFeatures.phases[currentPhase.id].phase
          .product.add.available.enabled
      ) {
        return PricingEditorMode.ADD_PRODUCT
      }

      navigate('..')
      return PricingEditorMode.VIEW
    }

    if (!currentPrice) {
      return cubeContext.queries.availableFeatures.phases[currentPhase.id].phase
        .product.add.available.enabled
        ? PricingEditorMode.CREATE
        : PricingEditorMode.VIEW
    }

    const canEdit =
      productId &&
      cubeContext.queries.availableFeatures.phases[currentPhase.id].products[
        productId
      ].edit.available.enabled

    return canEdit ? PricingEditorMode.EDIT : PricingEditorMode.VIEW
  }, [
    currentPhase,
    productId,
    listPriceId,
    currentPrice,
    cubeContext.queries.availableFeatures.phases,
    navigate
  ])

  const onClose = useCallback(() => {
    navigate('..')
  }, [navigate])

  const existingProductPrice: PricingEditorInterfacePrice | undefined =
    useMemo(() => {
      if (!currentPhase || !productId) {
        return
      }

      const existingPrice = cubeContext.queries.resolvedPhases[
        currentPhase.id
      ].prices.find(price => price.productId === productId)

      if (!existingPrice) {
        return
      }

      return existingPrice
    }, [cubeContext.queries, currentPhase, productId])

  const existingProduct: Product | undefined = useMemo(() => {
    if (!productId) {
      return
    }

    return cubeContext.queries.rawData.data.products[productId]
  }, [cubeContext, productId])

  const listPrice: PricingEditorInterfaceListPrice | undefined = useMemo(() => {
    if (!listPriceId) {
      return
    }

    const price = Object.values(cubeContext.queries.rawData.data.listPrices)
      .flatMap(listPrices => listPrices)
      .find(({ id }) => id === listPriceId)

    if (!price) {
      return
    }

    return price
  }, [cubeContext, listPriceId])

  const listPrices = useMemo(() => {
    if (!productId) {
      return []
    }

    const productListPrices =
      cubeContext.queries.rawData.data.listPrices[productId]
    return productListPrices ?? []
  }, [cubeContext.queries.rawData.data.listPrices, productId])

  const onSave = useCallback(
    ({
      price: newPrice,
      product: newProduct
    }: {
      price: PricingEditorInterfacePrice
      product: PricingEditorProduct
    }) => {
      /**
       * With this update we want to add our new price to the price datastructure,
       * and replace the reference to the product being updated with our new price,
       * if present.
       */
      if (!currentPhase) {
        return
      }

      const existingPrices =
        cubeContext.queries.resolvedPhases[currentPhase.id]?.prices
      const existingDiscountForPrice = existingProductPrice
        ? cubeContext.queries.resolvedPhases[currentPhase.id]?.discounts?.find(
            discount => discount.priceIds.includes(existingProductPrice?.id)
          )
        : undefined
      const existingMinimumForPrice: Minimum | undefined = existingProductPrice
        ? cubeContext.queries.resolvedPhases[currentPhase.id]?.minimums?.find(
            minimum => minimum.scope.priceIds.includes(existingProductPrice.id)
          )
        : undefined

      cubeContext.mutators.updateData({
        prices: {
          [newPrice.id]: newPrice
        },
        products: {
          [newProduct.id]: newProduct
        },
        phases: {
          [currentPhase.id]: {
            priceIds: existingProductPrice
              ? existingPrices.map(existingPrice => {
                  if (existingPrice.productId === newPrice.productId) {
                    return newPrice.id
                  }
                  return existingPrice.id
                })
              : [
                  ...existingPrices.map(existingPrice => existingPrice.id),
                  newPrice.id
                ]
          }
        },
        discounts: existingDiscountForPrice
          ? {
              [existingDiscountForPrice.id]: {
                ...existingDiscountForPrice,
                priceIds: [...existingDiscountForPrice.priceIds, newPrice.id]
              }
            }
          : {},
        minimums: existingMinimumForPrice
          ? {
              [existingMinimumForPrice.id]: {
                ...existingMinimumForPrice,
                scope: {
                  ...existingMinimumForPrice.scope,
                  priceIds: [
                    ...existingMinimumForPrice.scope.target,
                    newPrice.id
                  ]
                }
              }
            }
          : {}
      })

      navigate('..')
    },
    [navigate, currentPhase, cubeContext, existingProductPrice]
  )

  const availableFrequencies: AvailableStandardFrequency[] = useMemo(() => {
    return cubeContext.queries.availableFrequencies
  }, [cubeContext.queries.availableFrequencies])

  const scheduleCurrency: Currency | undefined = useMemo(() => {
    return cubeContext.queries.selectedCurrency
  }, [cubeContext])

  /**
   * Allow editing pricing models at all times on billing schedules,
   * or if adding the first price in a product in quotes
   */
  const isEditPricingModelEnabled: boolean = useMemo(() => {
    if (!currentPhase || !productId) {
      return true
    }

    return (
      cubeContext.queries.availableFeatures.phases[currentPhase.id]?.products[
        productId
      ]?.canEditPricingModel.available.enabled ?? true
    )
  }, [cubeContext.queries.availableFeatures.phases, currentPhase, productId])

  const { availableFields, disabledFields } = useMemo((): {
    availableFields: AvailablePricingEditorFields[]
    disabledFields: AvailablePricingEditorFields[]
  } => {
    if (
      mode === PricingEditorMode.EDIT &&
      currentPhase?.phaseHasStarted &&
      existingProduct &&
      currentPrice?.billingType === 'IN_ADVANCE'
    ) {
      /**
       * For an in advance price, we only allow editing of the common name,
       * so we set that in available fields, but in arrears prices are freely
       * editable as long as editing is allowed in general.
       */
      return {
        availableFields: ['common.name'],
        disabledFields: []
      }
    }

    /**
     * Disable changing pricing model when editing a price in the quote builder
     */
    if (!isEditPricingModelEnabled) {
      return {
        availableFields: [],
        disabledFields: ['common.pricingModel']
      }
    }

    return {
      availableFields: [],
      disabledFields: []
    }
  }, [
    mode,
    currentPhase?.phaseHasStarted,
    existingProduct,
    currentPrice?.billingType,
    isEditPricingModelEnabled
  ])

  /**
   * This error handling will automatically close the drawer if we
   * are attempting to load a 'new' product that does not exist,
   * or work against a phase that doesn't exist either.
   */
  useEffect(() => {
    if (productId?.match(NEW_PRODUCT_PATTERN) && !existingProduct) {
      navigate('..')
      return
    }

    if (!currentPrice && mode === PricingEditorMode.VIEW) {
      navigate('..')
      return
    }
  }, [
    navigate,
    currentPrice,
    productId,
    existingProduct,
    existingProductPrice,
    mode
  ])

  const pricingModelForNewPrice: PricingModel = useMemo(() => {
    const DEFAULT_NEW_PRICE_MODEL: PricingModel = 'STANDARD'

    if (!existingProduct || isEditPricingModelEnabled) {
      return DEFAULT_NEW_PRICE_MODEL
    }

    const otherPhasePrices = Object.entries(cubeContext.queries.resolvedPhases)
      .filter(([phaseId]) => phaseId !== (currentPhase?.id ?? ''))
      .flatMap(([, phase]) => phase.prices)

    if (!otherPhasePrices[0]) {
      return DEFAULT_NEW_PRICE_MODEL
    }

    return match(otherPhasePrices[0])
      .with(
        { structure: { pricingType: 'FIXED' } },
        { structure: { pricingType: 'ONE_TIME' } },
        () => 'STANDARD'
      )
      .with({ structure: { pricingType: 'LINEAR' } }, () => 'LINEAR')
      .with({ structure: { pricingType: 'VOLUME' } }, () => 'VOLUME')
      .with({ structure: { pricingType: 'GRADUATED' } }, () => 'GRADUATED')
      .with({ structure: { pricingType: 'PACKAGE' } }, () => 'PACKAGED')
      .with({ structure: { pricingType: 'SEAT_BASED' } }, price => {
        if (price.structure.tiers && price.structure.tiers.length > 0) {
          return 'SEAT_BASED_GRADUATED'
        }

        return 'SEAT_BASED_LINEAR'
      })
      .exhaustive() as PricingModel
  }, [
    cubeContext.queries.resolvedPhases,
    currentPhase?.id,
    existingProduct,
    isEditPricingModelEnabled
  ])

  const existingProductPricesForProduct:
    | undefined
    | PricingEditorInterfacePrice[] = useMemo(() => {
    if (!existingProduct) {
      return []
    }

    const otherPhasePrices = Object.entries(cubeContext.queries.resolvedPhases)
      .filter(([phaseId]) => phaseId !== (currentPhase?.id ?? ''))
      .flatMap(([, phase]) => phase.prices)

    return otherPhasePrices
      .filter(
        price =>
          price.productId === existingProduct.id &&
          price.id !== existingProductPrice?.id
      )
      .filter(price => {
        if (isEditPricingModelEnabled) {
          return true
        }

        return price.structure.pricingType === pricingModelForNewPrice
      })
      .map(price => ({
        ...price,
        /**
         * All existing prices will be a 'new' price, but we don't want to leak
         * that knowledge to the editor, so we'll just prefix it with 'existing'.
         * We always create a new price (including selection of an existing price)
         * as a 'new' price anyway. Any price id deduping happens as part of saving
         * the prices to the API (usePriceDeduplication.ts)
         */
        id: `existing:${price.id}`
      })) as PricingEditorInterfacePrice[]
  }, [
    existingProduct,
    cubeContext.queries.resolvedPhases,
    currentPhase?.id,
    existingProductPrice?.id,
    isEditPricingModelEnabled,
    pricingModelForNewPrice
  ])

  const customer = useMemo(() => {
    return cubeContext.queries.rawData.data.customers?.[
      cubeContext.queries.rawData.data.common?.customerId
    ]
  }, [cubeContext])

  const existingPrice = useMemo(() => {
    if (existingProductPrice) {
      return existingProductPrice
    }

    if (listPrice) {
      return {
        ...listPrice,
        id: `${NEW_PRICE_PREFIX}${crypto.randomUUID()}`
      }
    }
  }, [existingProductPrice, listPrice])

  const canUseListPrices = useMemo(() => {
    return Boolean(flags.enableListPrices)
  }, [flags])

  return {
    canUseListPrices,
    mode,
    productId,
    listPriceId,
    customer,
    existingData: {
      price: existingPrice,
      product: existingProduct,
      productPrices: existingProductPricesForProduct,
      listPrices
    },
    onClose,
    onSave,
    scheduleCurrency,
    availableFrequencies,
    availableFields,
    disabledFields,
    pricingModelForNewPrice
  }
}
