import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/16/solid'
import { Badge } from '@sequencehq/core-components'
import {
  InvoiceModel,
  toInvoicePaymentStatusBadgeProps,
  toInvoiceStatusBadgeProps,
  toMoney
} from '@sequencehq/core-models'
import {
  GreyGrey30,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import { useGetInvoicesQuery } from 'features/api'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { Link, useNavigate } from 'react-router-dom'
import { formatDateRange } from '@sequencehq/utils/dist/dates'

export const InvoiceTable = () => {
  const navigate = useNavigate()
  const { customer } = useCustomerContext()
  const { data } = useGetInvoicesQuery({
    customerId: customer.id,
    limit: 12,
    sortBy: 'ACCOUNTING_DATE'
  })

  const invoices = data?.value()

  if (!invoices) {
    return (
      <Center height="200px" width="100%" mt={4}>
        <Spinner />
      </Center>
    )
  }
  return (
    <>
      <TableContainer
        borderRadius="lg"
        overflow="hidden"
        border={`1px solid ${GreyGrey30}`}
        mt={4}
        width="100%"
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)"
      >
        <Table variant="v2" width="100%">
          <Thead>
            <Tr>
              <Th width={100} textAlign="right">
                Amount
              </Th>
              <Th width={160}>Status</Th>
              <Th width={100}>Invoice #</Th>
              <Th width={200}>Billing period</Th>
              <Th width={100}>Sent</Th>
              <Th width={100}>Due</Th>
              <Th width={100}>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoices.items.length > 0 ? (
              invoices.items.map((invoice: InvoiceModel) => {
                return (
                  <Tr
                    key={invoice.id}
                    onClick={() => navigate(`/invoices/${invoice.id}`)}
                  >
                    <Td textAlign="right">
                      <Text
                        {...Lato13Bold}
                        as={Link}
                        to={`/invoices/${invoice.id}`}
                        color="#5F5CFF"
                        onClick={e => e.stopPropagation()}
                      >
                        {`${toMoney({
                          currency: invoice.currency,
                          value: invoice.grossTotal
                        })}`}
                      </Text>
                    </Td>
                    <Td>
                      <Flex gap={2} alignItems="center">
                        <Badge
                          {...toInvoiceStatusBadgeProps({
                            status: invoice.status,
                            size: 'sm'
                          })}
                        />
                        {invoice.status === 'SENT' && (
                          <Badge
                            {...toInvoicePaymentStatusBadgeProps({
                              status: invoice.paymentStatus,
                              size: 'sm'
                            })}
                          />
                        )}
                      </Flex>
                    </Td>
                    <Td>{invoice.invoiceNumber || '-'}</Td>
                    <Td>
                      {invoice.billingPeriod
                        ? formatDateRange({
                            from: new Date(invoice.billingPeriod.start),
                            to: new Date(invoice.billingPeriod.endInclusive)
                          })
                        : '-'}
                    </Td>
                    <Td>
                      {invoice.issueDate
                        ? dateTimeWithFormat(invoice.issueDate, 'd MMM yyyy')
                        : '-'}
                    </Td>
                    <Td>
                      {invoice.dueDate
                        ? dateTimeWithFormat(invoice.dueDate, 'd MMM yyyy')
                        : '-'}
                    </Td>
                    <Td>
                      {invoice.accountingDate
                        ? dateTimeWithFormat(
                            invoice.accountingDate,
                            'd MMM yyyy'
                          )
                        : '-'}
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <Tr
                sx={{
                  cursor: 'auto!important',
                  _hover: {
                    bgColor: 'inherit!important'
                  }
                }}
              >
                <Td colSpan={7}>
                  <EmptyContent />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {Number(invoices?.pagination?.totalResultSize) > 12 && (
        <Button
          mt={2}
          {...Lato13Bold}
          variant="component-library-ghost"
          rightIcon={<ArrowRightIcon color="#5F5CFF" height={16} width={16} />}
          as={Link}
          to={`/invoices/?sortBy=accountingDate_desc&customerId=${customer.id}`}
        >
          View all invoices
        </Button>
      )}
    </>
  )
}

const EmptyContent = () => {
  return (
    <Box width="400px" margin="12px auto" textAlign="center">
      <Text {...Lato13Bold} color="gray.90">
        No invoices to display
      </Text>
      <Text {...Lato13Regular} color="gray.80">
        You have not created an invoice for this customer yet.
      </Text>
    </Box>
  )
}
