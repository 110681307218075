import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { useCallback } from 'react'

type AggregatedGroup = {
  title: string
  lineItems: {
    taxRate: string
    groupId: string
    grossTotal: string
    quantity: string
    title: string,
    rate: string,
  }[]
}

export const useCreateAggregatedLineItemGroups = () => {
  const { mutateAsync: postLineItemGroup } = useMutation(dashboardv99990101Client.postCreditNoteLineItemGroup)
  const { mutateAsync: postLineItem } = useMutation(dashboardv99990101Client.postCreditNoteLineItem)

  return useCallback(async (creditNoteId: string, aggregatedGroups: AggregatedGroup[]) => {
    await Promise.all(
      aggregatedGroups.map(async group => {
        const { lineItems, ...groupData } = group
        const res = await postLineItemGroup({
          creditNoteId,
          body: {
            ...groupData
          }
        })

        if (!res) {
          console.error('Failed to create line item group')
          return
        }

        const { id: groupId } = res

        await Promise.all(
          lineItems.map(lineItem => {
            return postLineItem({
              creditNoteId,
              body: { ...lineItem, creditNoteId, rateDisplay: 'ABSOLUTE', externalIds: [], groupId }
            })
          })
        )
      })
    )
  }, [postLineItemGroup, postLineItem])
}
