import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import { useMemo, useState } from 'react'

type UseRemoveIntegrationModal = (args: {
  onSuccess: (args: { removeLinks: boolean }) => Promise<void>
  onClose: () => void
  service: ResolvedIntegration
}) => {
  fields: {
    confirmationField: {
      value: string
      onChange: (newValue: string) => void
      onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void
    }
    removeLinks: {
      value: boolean
      onChange: (newValue: boolean) => void
      visible: boolean
    }
  }
  actions: {
    cancel: {
      onClick: () => void
    }
    confirm: {
      label: string
      disabled: boolean
      onClick: () => void
      removing: boolean
    }
  }
  serviceName: string
}

export const useRemoveIntegrationModal: UseRemoveIntegrationModal = props => {
  const [confirmationFieldValue, setConfirmationFieldValue] = useState('')
  const [removeLinksValue, setRemoveLinks] = useState(false)
  const ctx = useIntegrationsDomainContext()

  const fields = useMemo(
    () => ({
      confirmationField: {
        value: confirmationFieldValue,
        onChange: (newValue: string) => setConfirmationFieldValue(newValue),
        onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' && confirmationFieldValue === 'UNINSTALL') {
            void props.onSuccess({
              removeLinks: removeLinksValue
            })
          }
        }
      },
      removeLinks: {
        visible:
          ctx.queries.availableFeatures.integrations[props.service.id]
            ?.canRemoveLinks.available.visible ?? false,
        value: removeLinksValue,
        onChange: (newValue: boolean) => setRemoveLinks(newValue)
      }
    }),
    [
      confirmationFieldValue,
      props,
      removeLinksValue,
      ctx.queries.availableFeatures.integrations
    ]
  )

  const actions = useMemo(
    () => ({
      cancel: {
        onClick: props.onClose
      },
      confirm: {
        label: ctx.queries.rawData.editor.removing
          ? 'Uninstalling...'
          : 'Yes, uninstall',
        disabled:
          confirmationFieldValue !== 'UNINSTALL' ||
          ctx.queries.rawData.editor.removing,
        onClick: () =>
          props.onSuccess({
            removeLinks: removeLinksValue
          }),
        removing: ctx.queries.rawData.editor.removing
      }
    }),
    [
      confirmationFieldValue,
      props,
      ctx.queries.rawData.editor.removing,
      removeLinksValue
    ]
  )

  return {
    fields,
    actions,
    serviceName: props.service.title
  }
}
