import { NavigationV3ContextType } from 'components/NavigationV3/NavigationV3Provider.types'

const INITIAL_DATA: NavigationV3ContextType['data'] = {
  isSwitchingWorkspace: false,
  workspaceId: '',
  workspaceName: '',
  userEmail: '',
  userWorkspaces: []
}

export const INITIAL_STATE: NavigationV3ContextType = {
  data: INITIAL_DATA,
  queries: {
    canShareFeedback: () => false,
    canUseSettings: () => false,
    canLogOut: () => false,
    canSwitchWorkspaces: () => false,
    canEnterDemoMode: () => false,
    canRequestSandboxAccess: () => false
  },
  functions: {
    switchWorkspace: () => {},
    handleLogOut: () => {}
  }
}

export const DEMO_MODE_ORG_NAME: string = 'AcmeLabs'
