import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'

export const useProductForm = () => {
  const flags = useFlags()
  const pricingEditorContext = usePricingEditorDomainContext()

  const { fields } = useForm({
    value: pricingEditorContext.queries.rawData.data.product,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'label'
      },
      {
        property: 'taxCategoryId',
        validation: flags.showNewTaxRatesSettings ? [required] : []
      },
      {
        property: 'revenueRecognitionMethod',
        disabled: () => !flags?.useRevRec,
        options: [
          {
            label: 'Milestone',
            value: 'MILESTONE'
          },
          {
            label: 'Straight line',
            value: 'STRAIGHT_LINE'
          },
          {
            label: 'Usage',
            value: 'USAGE'
          }
        ]
      }
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    onValidationStateChange: isValid =>
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      }),
    onChange: newData =>
      pricingEditorContext.mutators.common.updateProduct({
        name: newData.name,
        label: newData.label,
        taxCategoryId: newData.taxCategoryId,
        revenueRecognitionMethod: newData.revenueRecognitionMethod
      })
  })

  const enhancedFields = useMemo(
    () => ({
      ...fields,
      taxCategoryId: {
        ...fields.taxCategoryId,
        hidden: !flags.showNewTaxRatesSettings
      }
    }),
    [fields, flags.showNewTaxRatesSettings]
  )

  return {
    fieldsConfig: enhancedFields
  }
}
