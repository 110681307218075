import {
  CubeReducerState,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'

export const getOrderedPhases =
  (ctx: { resolvedPhases: Record<ResolvedPhase['id'], ResolvedPhase> }) =>
  (prevState: CubeReducerState): ResolvedPhase[] => {
    return prevState.data.common.phaseIds.map(
      phaseId => ctx.resolvedPhases[phaseId]
    )
  }
