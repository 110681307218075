import { Currency } from '@sequencehq/core-models'
import { greaterThan, required } from '@sequencehq/validation'
import { defaultAvailableFrequenciesOptions } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/constants'
import { FormFields, useForm } from '@sequencehq/utils'
import {
  UsageParameterDataModel,
  useUsageMetricParameterFieldConfig
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/useUsageMetricParameterFieldConfig'
import { PricingEditorFormData } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import deepmerge from 'deepmerge'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

type PackagedPriceFields = PricingEditorFormData['PACKAGED']
type UsePackagedPriceForm = (props?: { formDisabled?: boolean }) => {
  fieldsConfig: Omit<FormFields<PackagedPriceFields>, 'usageMetricId'> & {
    billingFrequency: FormFields<PackagedPriceFields>['billingFrequency'] & {
      hidden: boolean
    }
    usageMetricId: FormFields<PackagedPriceFields>['usageMetricId'] & {
      hidden: boolean
      onAddNew: () => void
    }
  }
  formData: PackagedPriceFields
  currency: Currency
  fieldsAreHidden: boolean
}

export const usePackagedPriceForm: UsePackagedPriceForm = (
  { formDisabled } = { formDisabled: false }
) => {
  const pricingEditorContext = usePricingEditorContext()
  const navigate = useNavigate()

  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return pricingEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [pricingEditorContext.configuration.availableStandardFrequencies])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(pricingEditorContext.data.metrics).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [pricingEditorContext.data.metrics])

  /**
   * Initialise the form management
   */
  const checkIfPackagedFieldDisabledByDomain = useCallback(
    ({
      property
    }: {
      property: keyof (PackagedPriceFields & UsageParameterDataModel)
    }) =>
      pricingEditorContext.functions.fieldIsDisabled(`PACKAGED.${property}`),
    [pricingEditorContext]
  )

  const parameterFields =
    useUsageMetricParameterFieldConfig<PackagedPriceFields>(
      pricingEditorContext.data.formData.PACKAGED.usageMetricId,
      checkIfPackagedFieldDisabledByDomain
    )

  const { fields, queries } = useForm<
    Omit<PackagedPriceFields, 'parameters'> & UsageParameterDataModel
  >({
    value: pricingEditorContext.data.formData.PACKAGED,
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    disabled: formDisabled,
    fieldConfiguration: [
      {
        property: 'usageMetricId',
        disabled: checkIfPackagedFieldDisabledByDomain,
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'billingFrequency',
        disabled: checkIfPackagedFieldDisabledByDomain,
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'packageSize',
        disabled: checkIfPackagedFieldDisabledByDomain,
        validation: [required, greaterThan(0, "Package size can't be zero")]
      },
      {
        property: 'pricePerPackage',
        disabled: checkIfPackagedFieldDisabledByDomain,
        validation: [
          required,
          greaterThan(0, "Price per package can't be zero")
        ]
      },
      ...parameterFields.fields
    ],
    onChange: newData => {
      pricingEditorContext.functions.updateFormData({
        PACKAGED: newData
      })
    },
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          PACKAGED: isValid
        }
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return deepmerge(
      fields,
      {
        billingFrequency: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        },
        usageMetricId: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields,
          onAddNew: () => navigate('./metrics/new')
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    )
  }, [
    fields,
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields,
    navigate
  ])

  const fieldsAreHidden = useMemo(() => {
    return Boolean(
      pricingEditorContext.configuration.enableListPrices &&
        !pricingEditorContext.derived.queries.availableFeatures
          .showAllPriceFields
    )
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  return {
    fieldsConfig: enhancedFields,
    currency: pricingEditorContext.data.formData.common.currency,
    formData: queries.formData,
    fieldsAreHidden
  }
}
