import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import { HubSpotConfig } from 'modules/Integrations/integrationsConfig/hubSpot/hubspot.integration.config'

export const hubSpotConfigurationLoader = async (): Promise<{
  data: HubSpotConfig | null
  error: IntegrationsPortErrors | null
}> => {
  const integrationsResponse = await dashboardv20240509Client.getIntegrations()

  if (integrationsResponse.error) {
    return {
      data: null,
      error: IntegrationsPortErrors.Other
    }
  }

  const integration = integrationsResponse.data?.items.find(
    int => int.service === 'HubSpot'
  )

  return {
    data: {
      importCustomerWorkflow: integration?.enabled ?? false
    },
    error: null
  }
}
