import { FC, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  Text,
  ModalOverlay,
  Checkbox
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14Bold,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext.ts'
import { formatTotal } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'

type CreditNoteLineItemsModalProps = {
  onClose: () => void
  onConfirm: (lineItemIds: string[]) => void
}

export const CreditNoteLineItemsModal: FC<CreditNoteLineItemsModalProps> = ({
  onConfirm,
  onClose
}) => {
  const [selectedLineItemIds, setSelectedLineItemIds] = useState<string[]>([])
  const { data } = useInvoiceEditorContext()
  const listLineItems = Object.values(data.lineItems) ?? []
  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Select line items to credit
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginTop="16px"
            marginBottom="24px"
          >
            <Flex direction="column" gap="16px">
              <Text {...Lato14Bold}>Line items</Text>
              {listLineItems.map(lineItem => (
                <Flex justify="space-between" key={lineItem.id}>
                  <Checkbox
                    value={lineItem.id}
                    isChecked={selectedLineItemIds.includes(lineItem.id)}
                    onChange={e => {
                      if (selectedLineItemIds.includes(lineItem.id)) {
                        setSelectedLineItemIds(
                          selectedLineItemIds.filter(id => id !== lineItem.id)
                        )
                      } else {
                        setSelectedLineItemIds([
                          ...selectedLineItemIds,
                          e.target.value
                        ])
                      }
                    }}
                  >
                    <Text>
                      {lineItem.title} x {lineItem.quantity}
                    </Text>
                  </Checkbox>

                  <Flex>
                    <Text>
                      {formatTotal(data.invoice.currency, lineItem.netTotal)}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Box>
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={() => onConfirm(selectedLineItemIds)}
              height="32px"
              width="100%"
            >
              Continue
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
