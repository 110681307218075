import {
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { GreyGrey30, GreyGrey60, Lato12Bold } from '@sequencehq/design-tokens'
import { flexRender } from '@tanstack/react-table'
import { MemberRow } from 'components/TeamSettings/MembersTable/MemberRow'
import { useMembersTable } from 'components/TeamSettings/hooks/useMembersTable'

export const MembersTable = () => {
  const table = useMembersTable()
  const headers = table.getHeaderGroups()[0].headers

  return (
    <TableContainer
      position="relative"
      height="calc(100vh - 130px)"
      overflowY="scroll"
    >
      <Table position="relative">
        <Thead
          backgroundColor="white"
          overflow="hidden"
          position="sticky"
          top="0px"
          zIndex={10}
        >
          <Tr borderBottom={`1px solid ${GreyGrey30}`}>
            {headers.map(header => (
              <Th
                key={header.id}
                padding={3}
                border="none"
                height="40px"
                maxWidth={`${header.getSize()}px`}
                _first={{
                  paddingLeft: '24px'
                }}
              >
                <Text
                  textTransform="uppercase"
                  color={GreyGrey60}
                  {...Lato12Bold}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody position="relative" zIndex="1">
          {table.getRowModel().rows.map((row, idx) => (
            <MemberRow key={idx} row={row} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
