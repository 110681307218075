import { Flex, Grid, Text } from '@chakra-ui/react'
import {
  GreyGrey100,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { Fragment } from 'react'
import { useAdditionalInfo } from './useAdditionalInfo'
import { Price } from 'common/drawers/PricingEditor/domain'

export const AdditionalInfo = ({ price }: { price: Price }) => {
  const { validIntegrationIds } = useAdditionalInfo({
    price
  })

  if (Object.keys(validIntegrationIds).length === 0) {
    return null
  }

  return (
    <Flex flexDirection="column" mt={6}>
      <Text {...Lato14Bold} color={GreyGrey100}>
        Additional information
      </Text>
      <Grid templateColumns="160px 1fr" gap={3} mt={4} color={GreyGrey70}>
        {Object.keys(validIntegrationIds).map((service, index) => (
          <Fragment key={index}>
            <Text {...Lato13Regular}>{service} Ledger account</Text>
            <Text color={GreyGrey90}>
              {validIntegrationIds[service].name} (
              {validIntegrationIds[service].code})
            </Text>
          </Fragment>
        ))}
      </Grid>
    </Flex>
  )
}
