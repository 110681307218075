import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import {
  UpdateCreditNoteStatusFormMutation,
  UpdateAndSendCreditNoteStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import SendCreditNoteForm from 'components/UpdateInvoiceStatus/SendCreditNoteForm'
import { usePostCreditNotesByIdSendMutation } from 'features/api'

const SendCreditNoteModal = createFormModal<
  UpdateCreditNoteStatusFormMutation,
  UpdateAndSendCreditNoteStatusFormProps
>({
  FormComponent: SendCreditNoteForm,
  useMutation: usePostCreditNotesByIdSendMutation,
  modalTitle: 'Send Credit Note',
  successMessage: 'Credit Note sent',
  submitTitle: 'Send Credit Note',
  formId: 'sendCreditNoteForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Credit Note ${
        response.creditNoteNumber ? response.creditNoteNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default SendCreditNoteModal
