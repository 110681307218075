import {
  IntegrationsReducerState,
  ResolvedIntegration,
  ResolvedIntegrations
} from 'modules/Integrations/domain/integrations.domain.types'
import { getConnectAvailable } from 'modules/Integrations/domain/postActionStages/queries/getAvailableFeatures/getConnectAvailable'
import { getManageAvailable } from 'modules/Integrations/domain/postActionStages/queries/getAvailableFeatures/getManageAvailable'
import { getRemoveLinksAvailable } from 'modules/Integrations/domain/postActionStages/queries/getAvailableFeatures/getRemoveLinksAvailable'
import { getUninstallAvailable } from 'modules/Integrations/domain/postActionStages/queries/getAvailableFeatures/getUninstallAvailable'

export const getAvailableFeatures =
  (ctx: {
    resolvedIntegrations: ResolvedIntegrations
    activeIntegrations: ResolvedIntegration[]
  }) =>
  (
    prevState: IntegrationsReducerState
  ): IntegrationsReducerState['queries']['availableFeatures'] => {
    /**
     * Each integration service will have a set of available features based on their config and other
     * factors. Therefore, resolve the available features for each integration service here.
     */
    const integrationLevelFeatures = Object.values(
      ctx.resolvedIntegrations
    ).reduce(
      (acc, ri) => ({
        ...acc,
        [ri.id]: {
          canRemoveLinks: getRemoveLinksAvailable(ctx)(ri.id)(),
          canUninstall: getUninstallAvailable(ctx)(ri.id)(),
          canConnect: getConnectAvailable(ctx)(ri.id)(prevState),
          canManage: getManageAvailable(ctx)(ri.id)()
        }
      }),
      {}
    )

    return {
      integrations: integrationLevelFeatures
    }
  }
