import { Flex, useToast } from '@chakra-ui/react'
import {
  IndigoIndigo20,
  IndigoIndigo50,
  TurquoiseTurquoise30,
  TurquoiseTurquoise70
} from '@sequencehq/design-tokens'
import { Toast } from '@sequencehq/core-components'
import { useCallback } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'

type NotificationId = string | number
type UseUserNotifications = () => {
  displayNotification: (
    message: string,
    config?: {
      description?: string
      type?: 'info' | 'success' | 'error' | 'warning' | 'neutral'
      isClosable?: boolean
      id?: NotificationId
      duration?: number
      confetti?: boolean
    }
  ) => NotificationId
  hideNotification: (id: NotificationId) => void
}

/**
 * A simple convenience hook which abstracts the need to manually define
 * toast components or usage - it'll also more easily allow us to tweak
 * our notification approach in the future.
 * @returns
 */
export const useNotifications: UseUserNotifications = () => {
  const toast = useToast()

  const displayNotification = useCallback(
    (
      message: string,
      config: {
        type?: 'info' | 'success' | 'error' | 'warning' | 'neutral'
        isClosable?: boolean
        id?: NotificationId
        description?: string
        duration?: number
        confetti?: boolean
      } = {}
    ) => {
      toast.closeAll()
      return toast({
        id: config.id,
        duration: config.duration,
        render: () => (
          <>
            {config.confetti && (
              <Flex alignContent="center" justifyContent="center">
                <ConfettiExplosion
                  force={0.8}
                  zIndex={1}
                  colors={[
                    IndigoIndigo50,
                    IndigoIndigo20,
                    TurquoiseTurquoise30,
                    TurquoiseTurquoise70
                  ]}
                />
              </Flex>
            )}
            <Toast
              type={config.type ?? 'info'}
              isClosable={config.isClosable ?? false}
              title={message}
              description={config.description}
            />
          </>
        )
      })
    },
    [toast]
  )

  const hideNotification = (toastId: NotificationId) => {
    toast.close(toastId)
  }

  return {
    displayNotification,
    hideNotification
  }
}
