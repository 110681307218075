import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Portal,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { InvoicePaymentStatus } from '@sequencehq/core-models'
import { isErrorResponse, isSuccessResponse } from 'components/Form'
import { Toast } from '@sequencehq/core-components'
import {
  usePostInvoicesFinalizeAndSendMutation,
  usePostInvoicesFinalizeMutation,
  usePostInvoicesSendMutation,
  usePostInvoicesVoidMutation,
  usePutInvoicesPaymentStatusMutation,
  usePostInvoicesRecalculateMutation
} from 'features/api'
import { FC, useEffect, useState } from 'react'
import { ChevronDown, MoreHorizontal, X } from 'react-feather'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice

export type InvoiceBulkActionsProps = {
  children: React.ReactNode
  selectedRows: Invoice[]
  clearSelectedRows: () => void
  popoverOffset?: [number, number]
}

type Action =
  | 'finalize'
  | 'send'
  | 'void'
  | 'markAs'
  | 'finalizeAndSend'
  | 'recalculate'

export const InvoiceBulkActions: FC<InvoiceBulkActionsProps> = ({
  children,
  selectedRows,
  clearSelectedRows,
  popoverOffset
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [finalizeInvoices] = usePostInvoicesFinalizeMutation()
  const [sendInvoices] = usePostInvoicesSendMutation()
  const [recalculateInvoices] = usePostInvoicesRecalculateMutation()
  const [voidInvoices] = usePostInvoicesVoidMutation()
  const [updatePaymentStatus] = usePutInvoicesPaymentStatusMutation()
  const [finalizeAndSendInvoices] = usePostInvoicesFinalizeAndSendMutation()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState<{
    loading: boolean
    action: Action | undefined
  }>({
    loading: false,
    action: undefined
  })

  //['finalize', 'send', 'send', 'markAs']

  // if selected row is draft - posible actions are finalize and send
  // if selected row is final - posible actions are send
  // if selected row is sent - possible actions are mark as

  // filter out actions that are not possible

  // if action is finalize filter selected rows to only include draft
  // if action is send filter selected rows to only include draft and final

  // if selectedRows is empty, close the popover if it's open

  const filterSelectedRows = (action: Action) => {
    switch (action) {
      case 'finalize':
        return selectedRows.filter(row => row.status === 'DRAFT')

      case 'recalculate':
        return selectedRows.filter(
          row =>
            ['IN_PROGRESS', 'DRAFT'].includes(row.status) &&
            !!row.billingScheduleId
        )

      case 'send':
        return selectedRows.filter(
          row => row.status === 'DRAFT' || row.status === 'FINAL'
        )

      case 'markAs':
        return selectedRows.filter(row => row.status === 'SENT')

      case 'void':
        return selectedRows.filter(
          row => row.status === 'DRAFT' || row.status === 'FINAL'
        )
    }

    return selectedRows
  }

  const getSelectedRowsIds = (selected: Invoice[]) => {
    return selected.map(row => row.id)
  }

  useEffect(() => {
    if (selectedRows.length > 0 && !isOpen) {
      onToggle()
    }
    if (selectedRows.length === 0 && isOpen) {
      onClose()
    }
  }, [selectedRows, isOpen, onToggle, onClose])

  const finalizeSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await finalizeInvoices({
      finaliseMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })
      onClose()
      clearSelectedRows()
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoices finalized`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to finalize invoices`}
            isClosable={true}
          />
        )
      })
    }
    onClose()
    return invoiceUpdateRes
  }

  const recalculateSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await recalculateInvoices({
      recalculateMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })
      onClose()
      clearSelectedRows()
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoices recalculated`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to recalculate invoices`}
            isClosable={true}
          />
        )
      })
    }
    onClose()
    return invoiceUpdateRes
  }

  const finalizeAndSendSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await finalizeAndSendInvoices({
      finaliseAndSendMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })
      clearSelectedRows()
      onClose()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoices finalized and sent`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to finalise and send invoices`}
            isClosable={true}
          />
        )
      })
    }
    onClose()
    return invoiceUpdateRes
  }

  const sendSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await sendInvoices({
      sendMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })
      clearSelectedRows()
      onClose()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast type="success" title={`Invoices sent`} isClosable={true} />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to send invoices`}
            isClosable={true}
          />
        )
      })
    }
    return invoiceUpdateRes
  }

  const voidSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await voidInvoices({
      voidMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })
      clearSelectedRows()
      onClose()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast type="success" title={`Invoices voided`} isClosable={true} />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to void invoices`}
            isClosable={true}
          />
        )
      })
    }

    return invoiceUpdateRes
  }

  const markAsSelectedInvoices = async (
    status: 'UNPAID' | 'PARTIALLY_PAID' | 'PAID' | 'UNCOLLECTIBLE',
    filtered: Invoice[]
  ) => {
    const filteredIds: string[] = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await updatePaymentStatus({
      updateMultipleInvoicesPaymentStatusEndpointRequestModel: {
        invoiceIds: filteredIds,
        paymentStatus: status
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })
      clearSelectedRows()
      onClose()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Invoices marked as ${status}`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      setIsLoading({ loading: false, action: undefined })

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to update invoice payment statuses`}
            isClosable={true}
          />
        )
      })
    }
    return invoiceUpdateRes
  }

  const handleSubmitRows = (action: Action) => {
    setIsLoading({
      loading: true,
      action: action
    })

    const filteredSelectedRows = filterSelectedRows(action)

    if (filteredSelectedRows.length > 0) {
      switch (action) {
        case 'finalize':
          return finalizeSelectedInvoices(filteredSelectedRows)

        case 'recalculate':
          return recalculateSelectedInvoices(filteredSelectedRows)

        case 'send': {
          const draftSelectedRows = filteredSelectedRows.filter(
            row => row.status === 'DRAFT'
          )

          const sendSelectedRows = filteredSelectedRows.filter(
            row => row.status !== 'DRAFT'
          )

          if (draftSelectedRows) {
            return Promise.all([
              finalizeAndSendSelectedInvoices(draftSelectedRows),
              sendSelectedInvoices(sendSelectedRows)
            ])
          }
          return sendSelectedInvoices(filteredSelectedRows)
        }

        case 'void':
          return voidSelectedInvoices(filteredSelectedRows)
      }
    }
    return
  }

  const handleMarkAsRows = (status: InvoicePaymentStatus) => {
    setIsLoading({ loading: true, action: 'markAs' })

    const filtered = filterSelectedRows('markAs')

    if (getSelectedRowsIds(selectedRows).length > 0) {
      return markAsSelectedInvoices(status, filtered)
    }
    return
  }

  const handleCloseBulkActions = () => {
    clearSelectedRows()
    onClose()
  }

  const sortActions = () => {
    const actions = []
    if (selectedRows.some(row => row.status === 'DRAFT')) {
      actions.push('finalize', 'send', 'void')
    }
    if (
      selectedRows.some(
        row =>
          ['IN_PROGRESS', 'DRAFT'].includes(row.status) &&
          !!row.billingScheduleId
      )
    ) {
      actions.push('recalculate')
    }
    if (selectedRows.some(row => row.status === 'FINAL')) {
      if (actions.includes('finalize')) {
        actions.filter(action => action !== 'finalize')
        actions.push('send', 'void')
      }
      actions.push('send', 'void')
    }
    if (selectedRows.some(row => row.status === 'SENT')) {
      actions.push('markAs')
    }

    const uniqueArray = [...new Set(actions)]
    return uniqueArray
  }

  const possibleActions = sortActions()

  const markAsItems: { status: InvoicePaymentStatus; label: string }[] = [
    {
      status: 'PAID',
      label: 'Paid'
    },
    {
      status: 'PARTIALLY_PAID',
      label: 'Partially paid'
    },
    {
      status: 'UNCOLLECTIBLE',
      label: 'Uncollectible'
    },
    {
      status: 'UNPAID',
      label: 'Unpaid'
    }
  ]

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement="top"
      closeOnBlur={false}
      offset={popoverOffset}
    >
      <PopoverAnchor>{children}</PopoverAnchor>
      <Portal>
        <PopoverContent
          width="100%"
          p="12px"
          borderRadius="8px"
          boxShadow="0px 16px 24px rgba(20, 23, 28, 0.08)"
        >
          <PopoverBody width="100%" height="100%" p="0">
            <Flex height="100%" alignItems="center">
              <Text
                textStyle="formLabel"
                color="gray.70"
                my="8px"
                pl="8px"
                whiteSpace="nowrap"
              >
                {selectedRows.length} selected
              </Text>

              <Flex alignItems="center">
                {possibleActions &&
                  (possibleActions.includes('finalize') ||
                  possibleActions.includes('send') ||
                  possibleActions.includes('recalculate') ? (
                    <>
                      <Box
                        mx="16px"
                        width="1px"
                        height="16px"
                        backgroundColor="gray.40"
                      />
                      <Button
                        variant="secondary"
                        size="sm"
                        mr="8px"
                        onClick={() => void handleSubmitRows('recalculate')}
                        isDisabled={!possibleActions?.includes('recalculate')}
                        isLoading={
                          isLoading.loading &&
                          isLoading.action === 'recalculate'
                        }
                      >
                        Recalculate ({filterSelectedRows('recalculate').length})
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        mr="8px"
                        onClick={() => void handleSubmitRows('finalize')}
                        isDisabled={!possibleActions?.includes('finalize')}
                        isLoading={
                          isLoading.loading && isLoading.action === 'finalize'
                        }
                      >
                        Finalize ({filterSelectedRows('finalize').length})
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => void handleSubmitRows('send')}
                        isDisabled={!possibleActions?.includes('send')}
                        isLoading={
                          isLoading.loading && isLoading.action === 'send'
                        }
                      >
                        Send ({filterSelectedRows('send').length})
                      </Button>
                    </>
                  ) : null)}

                {possibleActions && possibleActions.includes('markAs') ? (
                  <>
                    <Box
                      mx="16px"
                      width="1px"
                      height="16px"
                      backgroundColor="gray.40"
                    />

                    <Menu placement="top">
                      <MenuButton
                        as={Button}
                        variant="selectBulk"
                        rightIcon={<ChevronDown width="32px" height="16px" />}
                        isDisabled={!possibleActions?.includes('markAs')}
                      >
                        Mark as ({filterSelectedRows('markAs').length})
                      </MenuButton>
                      <MenuList>
                        {markAsItems.map(item => (
                          <MenuItem
                            key={item.status}
                            px="16px"
                            py="20px"
                            borderRadius="8px"
                            onClick={() => void handleMarkAsRows(item.status)}
                            isDisabled={!possibleActions?.includes('markAs')}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </>
                ) : null}

                <Menu placement="top" variant="select">
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    variant="more-horizontal"
                    icon={<Icon as={MoreHorizontal} />}
                    color="gray.70"
                    minWidth="24px"
                    maxHeight="24px"
                    maxWidth="24px"
                    borderRadius="50%"
                    mx="8px"
                    _hover={{ backgroundColor: 'gray.20' }}
                    _active={{ backgroundColor: 'gray.30' }}
                    _focus={{
                      borderWidth: '2px',
                      borderColor: 'turquoise.60'
                    }}
                  />
                  <MenuList>
                    <MenuItem
                      px="16px"
                      py="20px"
                      color="red.60"
                      borderRadius="8px"
                      onClick={() => void handleSubmitRows('void')}
                      isDisabled={!possibleActions?.includes('void')}
                    >
                      Void ({filterSelectedRows('void').length})
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                width="24px"
                height="24px"
                _hover={{ backgroundColor: 'gray.20' }}
                _active={{ backgroundColor: 'gray.30' }}
                _focus={{
                  borderWidth: '2px',
                  borderColor: 'turquoise.60'
                }}
                borderRadius="50%"
                mr="8px"
              >
                <Icon
                  as={X}
                  onClick={handleCloseBulkActions}
                  cursor="pointer"
                  width="20px"
                  height="20px"
                />
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
