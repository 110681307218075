import { Box } from '@chakra-ui/react'
import { TextInputField } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { FC } from 'react'

export const IbanFields: FC<{ fieldName: string }> = ({ fieldName }) => {
  return (
    <Box width="250px">
      <TextInputField
        fieldLabel="IBAN"
        fieldName={`${fieldName}.value.iban`}
        validate={required}
      />
    </Box>
  )
}
