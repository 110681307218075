import DeleteLineItemForm from 'components/DeleteLineItem/DeleteInvoiceLineItemForm'
import { useDeleteInvoicesByInvoiceLineItemsByIdMutation } from 'features/api'
import { createFormModal } from 'components/Form/createFormModal'
import {
  DeleteInvoiceLineItemFormMutation,
  DeleteInvoiceLineItemFormProps
} from 'components/DeleteLineItem/types'

export const DeleteInvoiceLineItemModal = createFormModal<
  DeleteInvoiceLineItemFormMutation,
  DeleteInvoiceLineItemFormProps
>({
  FormComponent: DeleteLineItemForm,
  useMutation: useDeleteInvoicesByInvoiceLineItemsByIdMutation,
  modalTitle: 'Delete Line Item',
  successMessage: 'Deleted',
  submitTitle: 'Delete line item',
  formId: 'deleteLineItemForm',
  buttonVariant: 'warning'
})
