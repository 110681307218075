import { Flex, Link, Text, Tooltip } from '@chakra-ui/react'
import { GreyGrey70, Lato13Regular } from '@sequencehq/design-tokens'
import { Customer } from 'Cube/domain/cube.domain.types'
import { getLogo } from 'lib/integrations/logos'
import { FC } from 'react'

type CustomerLinkedServicesProps = {
  integrationIds: Customer['integrationIds']
}

export const CustomerLinkedServices: FC<CustomerLinkedServicesProps> = ({
  integrationIds
}) => {
  if (!integrationIds || integrationIds.length === 0) {
    return null
  }

  return (
    <>
      <Text {...Lato13Regular} color={GreyGrey70}>
        External links
      </Text>
      <Flex gap={2} overflow="hidden">
        {integrationIds.map(linkedService => (
          <Tooltip
            key={linkedService.service}
            label={linkedService.service.replace(/_/g, ' ')}
            hasArrow
          >
            {linkedService.url ? (
              <Link href={linkedService.url} isExternal>
                {getLogo(linkedService.service, 20)}
              </Link>
            ) : (
              <div>{getLogo(linkedService.service, 20)}</div>
            )}
          </Tooltip>
        ))}
      </Flex>
    </>
  )
}
