import { CreateApiKeyForm } from 'components/CreateApiKey/CreateApiKeyForm'
import { CreateApiKeyResponse } from 'components/CreateApiKey/CreateApiKeyResponse'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { usePostOauthClientsMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'

export type CreateApiKeyFormMutation = ExtractMutationParams<
  typeof usePostOauthClientsMutation
>

export const CreateApiKeyModal = createFormModal<
  CreateApiKeyFormMutation,
  Record<string, never>
>({
  FormComponent: CreateApiKeyForm,
  useMutation: usePostOauthClientsMutation,
  modalTitleFromProps: () => 'Create API key',
  successMessage: 'API key created',
  submitTitle: 'Create',
  formId: 'createApiKeyForm',
  successToast: response => (
    <Toast
      title="API key created"
      type="success"
      description={`API key ${response.name} created`}
      isClosable={true}
    ></Toast>
  ),
  successContent: response => (
    <CreateApiKeyResponse
      secret={response.secret}
      clientId={response.id}
      name={response.name}
    ></CreateApiKeyResponse>
  )
})
