import { AnyAction, Middleware, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import api from 'features/api'
import overlayReducer from 'features/overlay'
import drawerStackReducer from 'features/drawerStack'
import apiCachingReducer from 'features/apiCaching'
import demoModeMiddleware from 'features/demoMode'
import {
  TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useDispatch as useReduxDispatch,
  // eslint-disable-next-line no-restricted-imports
  useSelector as useReduxSelector
} from 'react-redux'
import { redirectToLogin } from 'lib/auth/session'

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export const newStore = () =>
  configureStore({
    reducer: {
      apiCaching: apiCachingReducer,
      overlay: overlayReducer,
      drawerStack: drawerStackReducer,
      [api.reducerPath]: api.reducer
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [
            'api/executeQuery/fulfilled',
            'api/executeMutation/fulfilled',
            'overlay/openOverlay'
          ],
          ignoredPaths: ['api.queries', 'api.mutations', 'overlay.data']
        }
      }).concat([api.middleware, demoModeMiddleware]),
    devTools: true,
    enhancers: [sentryReduxEnhancer]
  })

/**
 * TODO: is there any way to make this work? The action payload does not return the status code
 * or any error information on a 401 failure, but - problematically - also not on 5xx errors and
 * similar. This leads to 'doom loops' whenever there are any server issues, where the app continually
 * logs in and out.
 * @returns
 */
export const logoutOnFailedRequest: Middleware =
  () => next => (action: AnyAction) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    const { status } = action.payload ?? {}

    // Using 'FETCH_ERROR' since HTTP response code seems inaccessible when error occurs
    if (status === 'FETCH_ERROR' || status === 401) {
      Sentry.captureMessage("Logging out due to 'FETCH_ERROR' or 401")
      redirectToLogin()
    }
    return next(action)
  }

export type Store = ReturnType<typeof newStore>
export type AppDispatch = Store['dispatch']
export const useDispatch: () => AppDispatch = useReduxDispatch
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
export type RootState = ReturnType<Store['getState']>
