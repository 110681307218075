import { Badge, Inspector } from '@sequencehq/core-components'
import {
  AccordionButton,
  AccordionIcon,
  Accordion,
  AccordionItem,
  Box,
  Flex,
  AccordionPanel,
  Icon,
  Text
} from '@chakra-ui/react'
import { Lato14Bold, Lato14Regular } from '@sequencehq/design-tokens'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/16/solid'
import {
  ConfigurationManagementProps,
  ResolvedIntegration
} from 'modules/Integrations/domain'
import { FunctionComponent } from 'react'
import { match } from 'ts-pattern'

export interface HealthStatusConfig {
  label: string
  icon: FunctionComponent<{
    height: string | number | undefined
    width: string | number | undefined
  }>
  sentiment: 'success' | 'highlight' | 'neutral'
}

export const HealthStatusBadge = (props: {
  integration: Pick<ResolvedIntegration, 'authFailure' | 'enabled' | 'state'>
}) => {
  return match(props.integration)
    .with({ authFailure: true }, () => {
      return (
        <Badge
          sentiment="highlight"
          textStyle={Lato14Regular}
          containerStyle={{
            backgroundColor: 'none'
          }}
        >
          <Icon as={ExclamationTriangleIcon} height="16px" width="16px" />
          <Text {...Lato14Regular}>Auth failure</Text>
        </Badge>
      )
    })
    .with({ enabled: false }, () => {
      return (
        <Badge
          sentiment="neutral"
          textStyle={Lato14Regular}
          containerStyle={{
            backgroundColor: 'none'
          }}
        >
          <Icon as={XCircleIcon} height="16px" width="16px" />
          <Text {...Lato14Regular}>Inactive</Text>
        </Badge>
      )
    })
    .with({ state: 'CONNECTED' }, () => {
      return (
        <Badge
          sentiment="success"
          textStyle={Lato14Regular}
          containerStyle={{
            backgroundColor: 'none'
          }}
        >
          <Icon as={CheckCircleIcon} height="16px" width="16px" />
          <Text {...Lato14Regular}>Active</Text>
        </Badge>
      )
    })
    .otherwise(() => {
      return (
        <Badge
          sentiment="neutral"
          textStyle={Lato14Regular}
          containerStyle={{
            backgroundColor: 'none'
          }}
        >
          <Icon as={XCircleIcon} height="16px" width="16px" />
          <Text {...Lato14Regular}>Inactive</Text>
        </Badge>
      )
    })
}

export const DefaultIntegrationManagementInspector: React.FC<
  ConfigurationManagementProps
> = props => {
  return (
    <Inspector>
      <Inspector.ItemGroup>
        <Inspector.IconItem
          data-testid="defaultIntegration.inspector.item"
          content={
            <Inspector.Content
              padding="0px"
              data-testid="inspector.statusWidget"
            >
              <Accordion
                allowMultiple={true}
                defaultIndex={[0, 1]}
                mt="8px"
                px="8px"
              >
                <AccordionItem border="none">
                  <AccordionButton px="8px">
                    <Box
                      as={Flex}
                      flex="1"
                      gap={1}
                      textAlign="left"
                      borderRadius="8px"
                      {...Lato14Bold}
                    >
                      <span>Status</span>
                      <AccordionIcon />
                    </Box>
                  </AccordionButton>
                  <AccordionPanel
                    pb={4}
                    px={2}
                    as={Flex}
                    gap={4}
                    flexDirection="column"
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      {...Lato14Regular}
                    >
                      <p>Health</p>
                      <HealthStatusBadge integration={props.integration} />
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Inspector.Content>
          }
          icon={<InformationCircleIcon height={16} />}
        />
      </Inspector.ItemGroup>
    </Inspector>
  )
}
