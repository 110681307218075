import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import {
  AvailableServices,
  ResolvedIntegration
} from 'modules/Integrations/domain'
import { useMemo, useState } from 'react'

type UseIntegrations = (props: {
  activeIntegration: AvailableServices | null
  onSelectIntegration: (integration: AvailableServices) => void
}) => {
  integrationGroups: Array<{
    id: string
    title: string
    isExpanded: boolean
    onClick: () => void
    integrations: Array<
      ResolvedIntegration & {
        onClick: () => void
        selected: boolean
      }
    >
  }>
}

export const useIntegrationsList: UseIntegrations = props => {
  const ctx = useIntegrationsDomainContext()
  const [groupExpansion, setGroupExpansion] = useState<Record<string, boolean>>(
    Object.values(ctx.queries.integrationGroups).reduce(
      (acc, ig) => ({
        ...acc,
        [ig.title]: true
      }),
      {}
    )
  )

  const integrationGroups = useMemo(() => {
    return Object.values(ctx.queries.integrationGroups).map(group => ({
      ...group,
      id: group.title,
      isExpanded: groupExpansion[group.title] ?? true,
      onClick: () => {
        setGroupExpansion({
          ...groupExpansion,
          [group.title]: !groupExpansion[group.title]
        })
      },
      integrations: group.integrations.map(integration => ({
        ...integration,
        onClick: () => props.onSelectIntegration(integration.id),
        selected: props.activeIntegration === integration.id
      }))
    }))
  }, [ctx.queries.integrationGroups, props, groupExpansion])

  return {
    integrationGroups
  }
}
