import { BillingFrequency } from '@sequencehq/core-models'
import { add, Duration, isEqual } from '@sequencehq/utils/dates'
import { ResolvedPhase } from 'modules/Cube/domain/cube.domain.types'
import { getBillingDates } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/utils/getBillingDates'

const FREQUENCY_MONTH_MAPPINGS: Record<BillingFrequency, number> = {
  ONE_TIME: 0,
  MONTHLY: 1,
  QUARTERLY: 3,
  YEARLY: 12
}

const getEndDateValidForAbsoluteDates = ({
  recurrenceDayOfMonth,
  phaseDates,
  prices
}: {
  recurrenceDayOfMonth: number
  phaseDates: {
    start: Date
    end: Date
  }
  prices: ResolvedPhase['prices']
}) => {
  const viableEndDates = getBillingDates({
    recurrenceDayOfMonth,
    phaseDates
  })

  const minimumPhaseBillingFrequency = prices.reduce(
    (acc: BillingFrequency, price) => {
      if (
        FREQUENCY_MONTH_MAPPINGS[price.billingFrequency] >
        FREQUENCY_MONTH_MAPPINGS[acc]
      ) {
        return price.billingFrequency
      }

      return acc
    },
    'ONE_TIME'
  )

  const viableEndDatesForCurrentFrequency =
    viableEndDates[minimumPhaseBillingFrequency]

  const dayAfterEndDate = add(phaseDates.end, { days: 1 })

  return Boolean(
    viableEndDatesForCurrentFrequency.find(date =>
      isEqual(dayAfterEndDate, date)
    )
  )
}

const getEndDateValidForRelativeDates = ({
  phaseDuration,
  prices
}: {
  phaseDuration: Duration
  prices: ResolvedPhase['prices']
}) => {
  /**
   * Until we actively support proration across the board, the
   * smallest valid duration is a month when we are dealing with
   * relative durations. Durations with absolute dates may be smaller, since we
   * can use the known dates for recurrence date and start date
   * to know if the end date is aligned to the billing period.
   */
  if (phaseDuration.days && phaseDuration.days > -1) {
    return false
  }

  const phaseBillingInterval = prices.reduce((acc, price) => {
    if (FREQUENCY_MONTH_MAPPINGS[price.billingFrequency] > acc) {
      return FREQUENCY_MONTH_MAPPINGS[price.billingFrequency]
    }

    return acc
  }, 0)

  const durationInMonths =
    (phaseDuration.years ?? 0) * 12 + (phaseDuration.months ?? 0)

  return !(durationInMonths % phaseBillingInterval)
}

/**
 * We check this alignment for one key reason - our proration implementation
 * in the billing engine is incomplete. This means some pricing models - notably usage
 * based - don't work if the price changes mid billing period.
 *
 * To prevent users shooting themselves in the foot (since we don't validate this
 * on the API) we assert that the user is not trying to do said foot shooting. This
 * is achieved by this function, as well as the 'invalid proration' functions in the
 * same directory.
 *
 * When proration works for a given feature, we can remove the associated 'invalid proration'
 * check function!
 *
 * Reference on the borked proration: https://docs.google.com/document/d/1JLUQlleUOo6zE6qTwUSat9g_KNL0SjHFRv4BgSPCCGc/edit#heading=h.ap0rwoihlxo
 * @param param0
 * @returns
 */
export const getEndDateAlignedToBillingPeriod = ({
  recurrenceDayOfMonth,
  phaseDates,
  prices
}: {
  recurrenceDayOfMonth?: number
  phaseDates: ResolvedPhase['dates']
  prices: ResolvedPhase['prices']
}) => {
  /**
   * Ongoing phases never end, so they are always valid. This is also the
   * case when we have no pricing data for the phase.
   */
  if (!phaseDates.duration || !prices.length) {
    return true
  }

  if (
    recurrenceDayOfMonth &&
    phaseDates.absolute.start &&
    phaseDates.absolute.end
  ) {
    return getEndDateValidForAbsoluteDates({
      recurrenceDayOfMonth,
      phaseDates: {
        start: phaseDates.absolute.start,
        end: phaseDates.absolute.end
      },
      prices
    })
  }

  return getEndDateValidForRelativeDates({
    phaseDuration: phaseDates.duration,
    prices
  })
}
