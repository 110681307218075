import { Box, Grid, GridItem } from '@chakra-ui/react'
import { useVariantPriceLayout } from './useVariantPriceLayout'
import { StandardPriceVariantPriceForm } from '../../editors/StandardPrice/StandardPriceVariantPriceForm'
import { SimpleModalUI } from '@sequencehq/core-components'
import { match } from 'ts-pattern'
import { GradudatedPriceStandaloneVariantForm } from '../../editors/GraduatedPrice/GraduatedPriceStandaloneVariantForm'
import { GraduatedPriceVariantForm } from '../../editors/GraduatedPrice/GraduatedPriceVariantForm'
import { StandardPriceStandaloneVariantPriceForm } from '../../editors/StandardPrice/StandardPriceStandaloneVariantPriceForm'
import { LinearPriceVariantPriceForm } from '../../editors/LinearPrice/LinearPriceVariantPriceForm'
import { LinearPriceStandaloneVariantPriceForm } from '../../editors/LinearPrice/LinearPriceStandaloneVariantPriceForm'
import { VolumePriceVariantPriceForm } from '../../editors/VolumePrice/VolumePriceVariantPriceForm'
import { VolumePriceStandaloneVariantPriceForm } from '../../editors/VolumePrice/VolumePriceStandaloneVariantPriceForm'
import { PackagedPriceVariantPriceForm } from '../../editors/PackagedPrice/PackagedPriceVariantPriceForm'
import { PackagedPriceStandaloneVariantPriceForm } from '../../editors/PackagedPrice/PackagedPriceStandaloneVariantPriceForm'
import { SeatBasedPriceVariantPriceForm } from '../../editors/SeatBasedPrice/SeatBasedPriceVariantPriceForm'
import { SeatBasedPriceStandaloneVariantPriceForm } from '../../editors/SeatBasedPrice/SeatBasedPriceStandaloneVariantPriceForm'
import Spinner from 'components/Loading/Spinner'

export const VariantPriceLayout = (props: {
  loading: boolean
  variantPriceId?: string
}) => {
  const {
    formValid,
    isListPriceBasedVariant,
    pricingModel,
    onCancel,
    submit,
    submitting,
    title
  } = useVariantPriceLayout({ variantPriceId: props.variantPriceId })

  return (
    <SimpleModalUI
      variant="drawer"
      title={title}
      cancelButtonText="Cancel"
      submitButtonText={submit.text}
      onSubmit={submit.action}
      closeOnSubmit={formValid}
      contentProps={{
        padding: 0
      }}
      submitDisabled={submitting || submit.disabled}
      onCancel={onCancel}
      data-testid="priceEditor.variant"
    >
      <Grid
        templateAreas={`"content"`}
        templateRows={'1fr'}
        width="100%"
        height="100%"
      >
        <GridItem gridArea="content">
          <Box
            width="100%"
            minWidth="400px"
            data-testid="price-form"
            padding="24px"
          >
            {props.loading ? (
              <Spinner />
            ) : (
              match(pricingModel)
                .with('STANDARD', () =>
                  isListPriceBasedVariant ? (
                    <StandardPriceVariantPriceForm />
                  ) : (
                    <StandardPriceStandaloneVariantPriceForm />
                  )
                )
                .with('GRADUATED', () =>
                  isListPriceBasedVariant ? (
                    <GraduatedPriceVariantForm />
                  ) : (
                    <GradudatedPriceStandaloneVariantForm />
                  )
                )
                .with('LINEAR', () =>
                  isListPriceBasedVariant ? (
                    <LinearPriceVariantPriceForm />
                  ) : (
                    <LinearPriceStandaloneVariantPriceForm />
                  )
                )
                .with('VOLUME', () =>
                  isListPriceBasedVariant ? (
                    <VolumePriceVariantPriceForm />
                  ) : (
                    <VolumePriceStandaloneVariantPriceForm />
                  )
                )
                .with('PACKAGED', () =>
                  isListPriceBasedVariant ? (
                    <PackagedPriceVariantPriceForm />
                  ) : (
                    <PackagedPriceStandaloneVariantPriceForm />
                  )
                )
                .with('SEAT_BASED_GRADUATED', 'SEAT_BASED_LINEAR', () =>
                  isListPriceBasedVariant ? (
                    <SeatBasedPriceVariantPriceForm />
                  ) : (
                    <SeatBasedPriceStandaloneVariantPriceForm />
                  )
                )
                .exhaustive()
            )}
          </Box>
        </GridItem>
      </Grid>
    </SimpleModalUI>
  )
}
