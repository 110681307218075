import { Dispatch, FC, SetStateAction, useRef } from 'react'
import {
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Input,
  InputGroup,
  InputRightAddon,
  FormErrorMessage,
  FormControl
} from '@chakra-ui/react'
import { FormErrors, Label } from '@sequencehq/forms'
import { DateRangePopover } from 'components/FormInputs/Dates/DateRangePopover'
import { DateObj } from 'dayzed'
import { FieldMetaState } from 'react-final-form'
import {
  borderColor,
  errorBoxShadow,
  focusedBoxShadow,
  inputBorderRadius,
  standardBorder
} from '@sequencehq/core-theme'
import { RedRed60, IndigoIndigo50 } from '@sequencehq/design-tokens'
import { Calendar } from 'react-feather'

type UseDateRangeInputArgs = {
  emitValue?: (value: Date | undefined) => void
}

type UseDateRangeInput = (args: UseDateRangeInputArgs) => {
  label: string
  focusedInput: 'start' | 'end'
  handleBlur: () => void
  handleFocus: () => void
  handleDateSelected: (dateObj: DateObj) => void
  selectedDates: [Date | undefined, Date | undefined]
  localDateString: string
  setLocalDateString: Dispatch<SetStateAction<string>>
  popoverOpen: boolean
  setPopoverOpen: Dispatch<SetStateAction<boolean>>
  meta: FieldMetaState<Date>
}

type DateRangeInputProps = {
  emitValue?: (value: Date | undefined) => void
  useDateRangeInput: UseDateRangeInput
  className?: string
  disabled?: boolean
  minDate?: Date
  placeholder?: string
}

const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']

/**
 * Date Range Input
 *
 * @prop emitValue - optional callback to emit the value of the input - must be stable
 */

export const DateRangeInput: FC<DateRangeInputProps> = ({
  emitValue,
  useDateRangeInput,
  minDate,
  disabled,
  ...props
}) => {
  const initialFocusRef = useRef<HTMLButtonElement | null>(null)
  const groupRef = useRef<HTMLDivElement | null>(null)

  const {
    label,
    focusedInput,
    handleBlur,
    handleFocus,
    handleDateSelected,
    selectedDates,
    localDateString,
    setLocalDateString,
    popoverOpen,
    setPopoverOpen,
    meta
  } = useDateRangeInput({ emitValue })

  const errorMessage = meta.error ?? meta.submitError
  const isInvalid = errorMessage && meta.touched

  return (
    <Popover
      variant="dateRange"
      isOpen={popoverOpen}
      placement={focusedInput === 'start' ? 'bottom-start' : 'bottom-end'}
      onClose={() => setPopoverOpen(false)}
      autoFocus
      returnFocusOnClose={false}
      closeOnBlur={false}
      initialFocusRef={initialFocusRef}
      isLazy
      lazyBehavior="unmount"
    >
      <FormControl isInvalid={isInvalid}>
        <Label>{label}</Label>
        <PopoverAnchor>
          <InputGroup
            variant="input-in-group"
            tabIndex={popoverOpen ? -1 : disabled ? undefined : 0}
            ref={groupRef}
            border={standardBorder}
            borderColor={isInvalid ? RedRed60 : borderColor}
            borderRadius={inputBorderRadius}
            {...(isInvalid ? { boxShadow: errorBoxShadow } : {})}
            _focusWithin={{
              borderColor: IndigoIndigo50,
              boxShadow: focusedBoxShadow
            }}
            onClick={event => {
              if (
                event.target instanceof HTMLButtonElement &&
                event.target.className?.includes('date-button')
              ) {
                setPopoverOpen(false)
                document.querySelector<HTMLInputElement>('.end-date')?.focus()
              }
            }}
            onFocus={() => {
              if (!popoverOpen) {
                setPopoverOpen(true)
              }
            }}
            onBlur={event => {
              if (!groupRef.current?.contains(event.relatedTarget)) {
                setPopoverOpen(false)
              }
            }}
            isolation="auto"
          >
            <Input
              autoComplete="off"
              variant="input-in-group"
              value={localDateString}
              onChange={event => setLocalDateString(event.target.value)}
              onBlur={handleBlur}
              onFocus={() => handleFocus()}
              onKeyDown={event => {
                if (arrowKeys.includes(event.key)) {
                  event.stopPropagation()
                }

                if (event.key === 'Enter') {
                  event.stopPropagation()
                  handleBlur()
                  if (popoverOpen) {
                    setPopoverOpen(false)
                    document
                      .querySelector<HTMLInputElement>('.end-date')
                      ?.focus()
                  } else {
                    setPopoverOpen(true)
                  }
                }
              }}
              onClick={event => {
                event.stopPropagation()

                setPopoverOpen(true)
              }}
              borderRight={standardBorder}
              tabIndex={-1}
              isInvalid={errorMessage && meta.touched}
              isDisabled={disabled}
              {...props}
            />
            <InputRightAddon borderLeft={standardBorder}>
              <Calendar />
            </InputRightAddon>
            <PopoverContent>
              <PopoverBody>
                <DateRangePopover
                  focusedInput={focusedInput}
                  initialRef={initialFocusRef}
                  selectedDates={selectedDates}
                  onDateSelected={handleDateSelected}
                  monthsToDisplay={2}
                  setPopoverOpen={setPopoverOpen}
                  minDate={minDate}
                />
              </PopoverBody>
            </PopoverContent>
          </InputGroup>
        </PopoverAnchor>
        {errorMessage ? (
          <FormErrors formError={errorMessage} as={FormErrorMessage} />
        ) : null}
      </FormControl>
    </Popover>
  )
}
