import { isAfter } from '@sequencehq/utils/dates'

export const sortArrayByStartDate = <
  Model extends { startDate: Date | null | undefined }
>(
  phases: Model[]
): Model[] => {
  return phases.sort(({ startDate: startDateA }, { startDate: startDateB }) => {
    if (!startDateA) {
      return 1
    }
    if (!startDateB) {
      return -1
    }
    return isAfter(startDateA, startDateB) ? 1 : -1
  })
}
