import { useCallback } from 'react'

export const useInstructions = () => {
  const navigateToDocumentation = useCallback(() => {
    window.open('https://docs.sequencehq.com/integrations/overview', '_blank')
  }, [])

  return {
    documentation: {
      onClick: navigateToDocumentation
    }
  }
}
