import { Flex, Text } from '@chakra-ui/react'
import {
  DeleteUserFormMutation,
  DeleteUserProps
} from 'components/DeleteUser/DeleteUserModal'
import { MutationFormProps } from 'components/Form/types'

import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

export const DeleteUserForm: FC<
  MutationFormProps<DeleteUserFormMutation> & DeleteUserProps
> = ({ id, email, formId, submitForm }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{}}
      onSubmit={async () => {
        await submitForm({
          id: id
        })
      }}
      render={({ handleSubmit }) => {
        return (
          <>
            <Flex
              as="form"
              px={5}
              py={5}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
              id={formId}
            >
              <Text>Remove access for user {email}?</Text>
            </Flex>
          </>
        )
      }}
    />
  )
}
