import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { match } from 'ts-pattern'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { FC } from 'react'
import { BillingScheduleStatus } from '@sequencehq/core-models'

export type ArchiveBillingScheduleModalProps = {
  status: BillingScheduleStatus
  submitting: boolean
  onConfirm: () => Promise<void> | void
  onClose: () => void
}

export const ArchiveBillingScheduleModal: FC<
  ArchiveBillingScheduleModalProps
> = ({ submitting, onConfirm, onClose, status }) => {
  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="14px">
            Archive schedule
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="14px"
          >
            {match(status)
              .with('DRAFT', () => (
                <>This will archive the billing schedule immediately.</>
              ))
              .with('PENDING', () => (
                <>
                  This will cancel and archive the billing schedule immediately.
                </>
              ))
              .with('ACTIVE', () => (
                <>
                  This will cancel and archive the billing schedule immediately.
                  All in progress invoices will be deleted and all draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .with('SUSPENDED', () => (
                <>
                  This will cancel and archive the billing schedule immediately.
                  All in progress invoices will be deleted and all draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .with('CANCELLED', () => (
                <>
                  This will archive the billing schedule immediately. All draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .with('COMPLETED', () => (
                <>
                  This will archive the billing schedule immediately. All draft
                  invoices will be voided. Finalised and sent invoices will
                  remain.
                </>
              ))
              .exhaustive()}
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            This action cannot be undone.
          </Box>
          <Flex gap="8px" justifyContent="flex-end">
            <Flex basis="50%">
              <Button
                variant="secondary2"
                onClick={onClose}
                height="32px"
                width="100%"
              >
                Cancel
              </Button>
            </Flex>
            <Flex basis="50%">
              <Button
                variant="primary2"
                onClick={() => {
                  void onConfirm()
                }}
                height="32px"
                isLoading={submitting}
                width="100%"
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
