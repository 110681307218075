import { Box, Flex, Image } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import salesforceCover from './salesforceCover.png'

export const SalesforceIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={salesforceCover}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Enhance your Salesforce workflows with Sequence quoting and real-time
        billing data. Easily create quotes, convert them to billing schedules,
        and track invoices and usage directly from Salesforce. Maintain an
        up-to-date view of customer and billing data in Salesforce for future
        upsell and renewal opportunities. Install the Sequence app in
        Salesforce, connect your accounts, and start syncing customer and
        product data for streamlined operations and enriched account management.
      </Box>
    </Flex>
  )
}
