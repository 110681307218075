import { useInvoicePdfPreview } from 'InvoiceEditor/components/drawers/InvoicePdfPreview/useInvoicePdfPreview'
import { PDFPreviewDrawer } from 'components/PDFPreviewDrawer/PDFPreviewDrawer'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const InvoicePdfPreview = ({ isOpen, onClose }: Props) => {
  const { previewDataUrl, download } = useInvoicePdfPreview({ isOpen })

  return (
    <PDFPreviewDrawer
      title="Preview invoice"
      fileUrl={`${previewDataUrl}`}
      isLoading={!previewDataUrl}
      onDownload={download.available ? download.onClick : undefined}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}
