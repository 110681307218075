import { Inspector } from '@sequencehq/core-components'
import {
  HubspotInspectorItem,
  LinkHubSpotCustomerWidget,
  LinkQuickbooksCustomerWidget,
  LinkSalesforceAccountWidget,
  LinkStripeCustomerWidget,
  LinkXeroCustomerWidget,
  QuickBooksInspectorItem,
  SalesforceInspectorItem,
  StripeInspectorItem,
  XeroInspectorItem
} from 'Integrations/index'
import { FC } from 'react'

export const CustomerOverviewInspector: FC<{ customerId: string }> = props => {
  return (
    <Inspector>
      <Inspector.ItemGroup>
        <XeroInspectorItem>
          <LinkXeroCustomerWidget customerId={props.customerId} />
        </XeroInspectorItem>
        <QuickBooksInspectorItem>
          <LinkQuickbooksCustomerWidget customerId={props.customerId} />
        </QuickBooksInspectorItem>
        <HubspotInspectorItem>
          <LinkHubSpotCustomerWidget customerId={props.customerId} />
        </HubspotInspectorItem>
        <SalesforceInspectorItem>
          <LinkSalesforceAccountWidget customerId={props.customerId} />
        </SalesforceInspectorItem>
        <StripeInspectorItem>
          <LinkStripeCustomerWidget customerId={props.customerId} />
        </StripeInspectorItem>
      </Inspector.ItemGroup>
    </Inspector>
  )
}
