import { useCallback } from 'react'

import { useCustomerContactsContext } from 'modules/CustomerContacts/communication/internal/CustomerContacts.domain.context'
import { CustomerContact } from 'CustomerContacts/domain/CustomerContacts.domain.types'

type UseCustomerContactsLayout = () => {
  buttonAddContact: {
    onClick: () => void
  }
  contacts: CustomerContact[]
}

export const useCustomerContactsLayout: UseCustomerContactsLayout = () => {
  const ctx = useCustomerContactsContext()

  const buttonAddContactOnClick = useCallback(() => {
    /**
     * Ensure the form is blank before showing modal
     */
    ctx.mutators.internal.updateForm({
      name: '',
      email: '',
      billingPreference: 'STANDARD'
    })
  }, [ctx.mutators.internal])

  return {
    buttonAddContact: {
      onClick: buttonAddContactOnClick
    },
    contacts: ctx.queries.data.contacts
  }
}
