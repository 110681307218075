import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './slackLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { SlackIntegrationDetail } from './SlackIntegrationDetail'

export const slackIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: 'COMING_SOON',
  documentationUrl: '',
  title: 'Slack',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: false
  },
  description:
    'Stay up to date with real-time notifications for Sequence events.',
  components: {
    detail: SlackIntegrationDetail,
    management: NoConfiguration
  }
}
