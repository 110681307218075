import { FC } from 'react'

interface ChevronLeftIconProps {
  color?: string
}

export const ChevronLeftIcon: FC<ChevronLeftIconProps> = ({
  color = '#2D3748'
}) => (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.47531 5.83496L6.75328 10.1129L5.53125 11.335L0.03125 5.83496L5.53125 0.334961L6.75328 1.55699L2.47531 5.83496Z"
      fill={color}
    />
  </svg>
)
