import {
  ActionHandler,
  CubeReducerState,
  UpdateConfigurationAction
} from 'modules/Cube/domain/cube.domain.types'
import deepmerge from 'deepmerge'

export const updateConfiguration: ActionHandler<UpdateConfigurationAction> =
  prevState => action => {
    const newConfigurationData = deepmerge(
      prevState.configuration,
      action.payload
    ) as CubeReducerState['configuration']

    return {
      ...prevState,
      configuration: newConfigurationData
    }
  }
