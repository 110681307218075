import { AddProductFormProvider } from 'Products/drawers/AddProduct/hooks/useAddProductFormContext'
import { AddProductFormDrawer } from 'Products/drawers/AddProduct/components/AddProductDrawer/AddProductDrawer'
import { FC, PropsWithChildren } from 'react'
import { ProductContextProvider } from 'Products/hooks/useProductContext'
import { NEW_PRODUCT_PREFIX } from 'Cube/domain/cube.constants'
import { useListPriceEditorConnector } from 'Products/drawers/ListPriceEditor/useListPriceEditor.connector'
import { useListPriceEditorRoot } from 'Products/drawers/ListPriceEditor/useListPriceEditorRoot'
import { ListPriceEditorProvider } from 'Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { Outlet } from 'react-router-dom'

export const AddProductConnector = () => {
  const productId = `${NEW_PRODUCT_PREFIX}${crypto.randomUUID()}`

  return (
    <ProductContextProvider productId={productId}>
      <AddProductListPriceEditorConnector>
        <AddProductFormProvider>
          <AddProductFormDrawer />
          <Outlet />
        </AddProductFormProvider>
      </AddProductListPriceEditorConnector>
    </ProductContextProvider>
  )
}

const AddProductListPriceEditorConnector: FC<PropsWithChildren> = ({
  children
}) => {
  const context = useListPriceEditorConnector()
  const { listPriceEditor } = useListPriceEditorRoot(context)

  return (
    <ListPriceEditorProvider value={listPriceEditor}>
      {children}
    </ListPriceEditorProvider>
  )
}
