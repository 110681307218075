import { Box, Button, Flex, FormLabel, Text } from '@chakra-ui/react'
import { AddressModel, CreditNoteModel } from '@sequencehq/core-models'
import {
  FormErrors,
  TextInputField,
  validateEmailItem,
  noReturn,
  MultiTagField
} from '@sequencehq/forms'
import { AddressInput } from 'components/FormComponents'
import { usePutCreditNotesByIdMutation } from 'features/api'
import { handleFormResponse } from 'lib/formValidation'
import { isEmailsList, required } from '@sequencehq/validation'
import { FC, useMemo } from 'react'
import { Form, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

interface DefaultSidebarProps {
  creditNote: CreditNoteModel
  setCreditNote: (cn: CreditNoteModel) => void
  cancel: () => void
  setHasChanges: () => void
}

type FormValues = {
  customerBillingAddress: AddressModel
  customerEmails: string[]
  customerLegalCompanyName: string
  customerTaxId: string | undefined
}

const DefaultSidebar: FC<DefaultSidebarProps> = ({
  creditNote,
  setCreditNote,
  cancel,
  setHasChanges
}) => {
  const [submitForm] = usePutCreditNotesByIdMutation()

  /* Added useMemo since invoice is updated on change,
     which in turn causes a new space (' ') to be added
     when initialValues is reinitialised. We need a space
     at the end to ensure the last email is rendered as
     a tag rather than text */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialEmails = useMemo(() => creditNote.customerEmails, [])

  return (
    <>
      <Form<FormValues>
        keepDirtyOnReinitialize
        destroyOnUnregister
        mutators={{
          ...arrayMutators
        }}
        initialValues={{
          customerBillingAddress: creditNote.customerBillingAddress,
          customerEmails: [...initialEmails, ''],
          customerLegalCompanyName: creditNote.customerLegalCompanyName,
          customerTaxId: creditNote.customerTaxId
        }}
        onSubmit={async ({ customerEmails, ...values }, form) => {
          const result = await submitForm({
            id: creditNote.id,
            updateCreditNoteEndpointUpdateCreditNoteRequestModel: {
              ...creditNote,
              ...values,
              customerEmails: customerEmails.filter(email => Boolean(email))
            }
          })

          return handleFormResponse(result, form.getRegisteredFields())
        }}
        render={({ handleSubmit, submitting, submitError }) => (
          <Flex
            as="form"
            px={5}
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            w="100%"
          >
            <FormSpy
              subscription={{ values: true, dirty: true, valid: true }}
              onChange={state => {
                if (state.dirty && state.valid) {
                  setCreditNote({ ...creditNote, ...state.values })
                }
                if (state.dirty) {
                  setHasChanges()
                }
              }}
            />
            <FormErrors formError={submitError} />
            <Text textStyle="sectionHeader">Recipient</Text>
            <Box h="16px"></Box>
            <TextInputField
              fieldName="customerLegalCompanyName"
              fieldLabel="Customer's Legal Company Name"
              validate={required}
            />
            <Box height="24px" />
            <MultiTagField
              fieldName="customerEmails"
              fieldLabel="Customer Emails"
              validateItem={validateEmailItem}
              validate={isEmailsList({ permitEmpty: true })}
            />
            <Box height="24px" />

            <TextInputField
              fieldName="customerTaxId"
              fieldLabel="Customer's VAT Number"
            />
            <Box height="24px" />
            <FormLabel>Address</FormLabel>
            <Box height={2} />
            <AddressInput name="customerBillingAddress." />
            <Box height={5} />
            <Flex justifyContent="flex-end">
              <Button variant="secondary" onClick={cancel}>
                Cancel
              </Button>
              <Box w="16px"></Box>
              <Button variant="primary" type="submit" isDisabled={submitting}>
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      />
    </>
  )
}

export default DefaultSidebar
