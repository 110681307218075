import { useCallback, useState } from 'react'

import { FormFields, useForm } from '@sequencehq/utils'
import {
  isEmail,
  required,
  notInListCaseInsensitive
} from '@sequencehq/validation'

import {
  CustomerContact,
  CustomerContactBillingPreference
} from 'modules/CustomerContacts/domain/CustomerContacts.domain.types'
import { useCustomerContactsContext } from 'CustomerContacts/communication/internal/CustomerContacts.domain.context.tsx'

export type CustomerContactFields = {
  name: Required<CustomerContact['name']>
  email: CustomerContact['email']
  billingPreference: CustomerContact['billingPreference']
}

export type CustomerContactFieldsConfig = {
  name: FormFields<CustomerContactFields>['name']
  email: FormFields<CustomerContactFields>['email']
  billingPreference: FormFields<CustomerContactFields>['billingPreference']
}

export type ContactBody = {
  name: string
  email: string
  billingPreference: CustomerContactBillingPreference
}

type UseContactModalFormProps = {
  contactId: string
  fieldsValues: CustomerContactFields
  updateFields: (newFields: CustomerContactFields) => void
  createContact: (body: ContactBody) => Promise<void>
  updateContact: ({
    contactId,
    body
  }: {
    contactId: string
    body: ContactBody
  }) => Promise<void>
}

type UseContactModalForm = (props: UseContactModalFormProps) => {
  fieldsConfig: CustomerContactFieldsConfig
  buttonSubmit: {
    onSubmit: (callback?: () => void) => void
    disabled: boolean
    isValid?: boolean
  }
  isUpdating: boolean
  resetForm: VoidFunction
}

export const useContactModalForm: UseContactModalForm = ({
  contactId,
  fieldsValues,
  updateFields,
  createContact,
  updateContact
}) => {
  const ctx = useCustomerContactsContext()

  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const { fields, queries } = useForm({
    value: fieldsValues,
    showValidationErrors: !isValid,
    fieldConfiguration: [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'email',
        validation: [
          required,
          isEmail,
          notInListCaseInsensitive(
            ctx.queries.data.contacts.filter(contact => contact.id !== contactId).map(contact => contact.email)
          )
        ]
      },
      {
        property: 'billingPreference',
        validation: [required]
      }
    ],
    onChange: newData => {
      updateFields(newData)
      setIsDirty(true)
    }
  })

  const handleSubmit = useCallback(
    (onSubmit?: () => void) => {
      const run = async () => {
        if (!queries.isValid) {
          setIsValid(false)
          return
        }

        setIsUpdating(true)

        if (contactId === 'new') {
          await createContact({
            name: fieldsValues.name || '',
            email: fieldsValues.email,
            billingPreference: fieldsValues.billingPreference
          })
        } else {
          await updateContact({
            contactId,
            body: {
              name: fieldsValues.name || '',
              email: fieldsValues.email,
              billingPreference: fieldsValues.billingPreference
            }
          })
        }

        setIsUpdating(false)
        setIsDirty(false)
        setIsValid(true)

        onSubmit?.()
      }
      void run()
    },
    [
      contactId,
      createContact,
      fieldsValues.billingPreference,
      fieldsValues.email,
      fieldsValues.name,
      updateContact,
      queries.isValid
    ]
  )

  const resetForm = () => {
    setIsDirty(false)
    setIsValid(true)
  }

  return {
    fieldsConfig: fields,
    buttonSubmit: {
      onSubmit: handleSubmit,
      disabled: isUpdating || !isDirty,
      isValid: queries.isValid
    },
    isUpdating,
    resetForm
  }
}
