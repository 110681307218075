import { Drawer, DrawerOverlay } from '@chakra-ui/react'
import { closeDrawer, openDrawer } from 'features/drawerStack'
import { useDispatch, useSelector } from 'features/store'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { RemoveScroll } from 'react-remove-scroll'

type SidebarProps = {
  children: ReactNode
}

const Sidebar: FC<SidebarProps> = ({ children }) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const [sidebarId] = useState(uuidv4())

  const drawerStack = useSelector(state => state.drawerStack)
  const position = drawerStack.find(drawer => drawer.id === sidebarId)?.position

  useEffect(() => {
    dispatch(openDrawer({ id: sidebarId }))

    return () => {
      dispatch(closeDrawer())
    }
  }, [])

  return (
    <Drawer
      isOpen={true}
      placement="right"
      onClose={() => navigate(`..${search}`)}
      variant="sidebar"
      size={position ? `depth-${position}` : undefined}
      /* By default Chakra blocks scrolling outside of first modal, which
      also prevents scrolling in of nested modals. To fix this, we switch off
      blockScrollOnMount and use react-remove-scroll manually apply scroll 
      blocking. This similar to how Chakra's Modal component works, but 
      gives us more control over its behaviour. */
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <RemoveScroll>{children}</RemoveScroll>
    </Drawer>
  )
}

export default Sidebar
