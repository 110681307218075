import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import {
  InputSelectControlLegacyStyleBridge,
  ControlContextProvider
} from '@sequencehq/core-components'
import { countries } from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import { Field } from 'react-final-form'
import { FormErrors } from '@sequencehq/forms'
import { FieldSubscription } from 'final-form'

const options = Object.entries(countries).map(([value, label]) => ({
  value,
  label
}))

export const CountrySelect = ({
  name,
  value
}: {
  name: string
  value?: string
}) => {
  return (
    <Field
      name={name}
      validate={required}
      defaultValue=""
      subscription={
        { error: true, submitError: true, touched: true } as FieldSubscription
      }
    >
      {({ input, meta }) => {
        const errorMessage = meta.error ?? meta.submitError
        return (
          <ControlContextProvider isLazy={false}>
            <FormControl isInvalid={errorMessage && meta.touched}>
              <InputSelectControlLegacyStyleBridge
                name={input.name}
                placeholder="Choose a country..."
                initialValue={value}
                onChange={input.onChange}
                options={options}
              />

              {errorMessage && (
                <FormErrors formError={errorMessage} as={FormErrorMessage} />
              )}
            </FormControl>
          </ControlContextProvider>
        )
      }}
    </Field>
  )
}
