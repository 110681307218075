import {
  BillingFrequency,
  GraduatedPricingStructure,
  ListPrice,
  UsageCalculationFrequency
} from 'Products/types.ts'
import { match } from 'ts-pattern'
import { UsageCalculationPeriodDropdownValue } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'

const cumulativeUsageCalculationMode = (): {
  usageCalculationMode: GraduatedPricingStructure['usageCalculationMode']
} => ({
  usageCalculationMode: 'CUMULATIVE'
})

// Extracted because for now, all `usageCalculationPeriod.interval` is 1 by default
// and for this type of pricing, `usageCalculationMode` is `PERIODIC`.
const periodicCalculationMode =
  (usageCalculationFrequency: UsageCalculationFrequency) =>
  (): {
    usageCalculationMode: GraduatedPricingStructure['usageCalculationMode']
    usageCalculationPeriod: ListPrice['usageCalculationPeriod']
  } => ({
    usageCalculationMode: 'PERIODIC',
    usageCalculationPeriod: {
      frequency: usageCalculationFrequency,
      interval: 1
    }
  })

// Converts the combination of `Usage calculation period` dropdown value from the graduated price form
// and `Billing frequency` to the corresponding price API types
function fromUsageCalculationPeriodDropdownToApiPriceProperties(
  usageCalculationPeriod: UsageCalculationPeriodDropdownValue,
  billingFrequency: BillingFrequency
): {
  usageCalculationMode: GraduatedPricingStructure['usageCalculationMode']
  usageCalculationPeriod?: ListPrice['usageCalculationPeriod']
} {
  return match({ usageCalculationPeriod, billingFrequency })
    .with(
      {
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: 'BILLING_PERIOD'
      },
      periodicCalculationMode('MONTHLY')
    )
    .with(
      {
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: 'CUMULATIVE_USAGE'
      },
      {
        billingFrequency: 'QUARTERLY',
        usageCalculationPeriod: 'CUMULATIVE_USAGE'
      },
      cumulativeUsageCalculationMode
    )
    .with(
      { billingFrequency: 'MONTHLY', usageCalculationPeriod: 'QUARTERLY' },
      {
        billingFrequency: 'QUARTERLY',
        usageCalculationPeriod: 'BILLING_PERIOD'
      },
      { billingFrequency: 'QUARTERLY', usageCalculationPeriod: 'QUARTERLY' },
      { usageCalculationPeriod: 'QUARTERLY', billingFrequency: 'YEARLY' },
      {
        billingFrequency: 'YEARLY',
        usageCalculationPeriod: 'CUMULATIVE_USAGE'
      },
      {
        billingFrequency: 'ONE_TIME'
      },
      periodicCalculationMode('QUARTERLY')
    )
    .with(
      { billingFrequency: 'MONTHLY', usageCalculationPeriod: 'ANNUAL' },
      { billingFrequency: 'QUARTERLY', usageCalculationPeriod: 'ANNUAL' },
      {
        billingFrequency: 'YEARLY',
        usageCalculationPeriod: 'BILLING_PERIOD'
      },
      {
        billingFrequency: 'YEARLY',
        usageCalculationPeriod: 'ANNUAL'
      },
      periodicCalculationMode('YEARLY')
    )
    .exhaustive()
}

// Converts the price `usageCalculationMode`, `usageCalculationPeriod`, and `billingFrequency`
// into value suitable for the `Usage calculation period` dropdown in the graduated price form
function fromApiPricePropertiesToUsageCalculationPeriodDropdown(
  usageCalculationStructure: {
    usageCalculationMode: GraduatedPricingStructure['usageCalculationMode']
    usageCalculationPeriod?: ListPrice['usageCalculationPeriod']
  },
  billingFrequency: BillingFrequency
): UsageCalculationPeriodDropdownValue {
  const { usageCalculationMode, usageCalculationPeriod } =
    usageCalculationStructure

  return match({
    usageCalculationMode,
    usageCalculationPeriod,
    billingFrequency
  })
    .with(
      {
        usageCalculationMode: 'BILLING_PERIOD'
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: { frequency: 'MONTHLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'QUARTERLY',
        usageCalculationPeriod: { frequency: 'QUARTERLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'YEARLY',
        usageCalculationPeriod: { frequency: 'YEARLY' }
      },
      (): UsageCalculationPeriodDropdownValue => 'BILLING_PERIOD'
    )
    .with(
      {
        usageCalculationMode: 'CUMULATIVE'
      },
      (): UsageCalculationPeriodDropdownValue => 'CUMULATIVE_USAGE'
    )
    .with(
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: { frequency: 'QUARTERLY' }
      },
      (): UsageCalculationPeriodDropdownValue => 'QUARTERLY'
    )
    .with(
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: { frequency: 'YEARLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'QUARTERLY',
        usageCalculationPeriod: { frequency: 'YEARLY' }
      },
      (): UsageCalculationPeriodDropdownValue => 'ANNUAL'
    )
    .otherwise((): UsageCalculationPeriodDropdownValue => 'BILLING_PERIOD')
}

export default {
  fromUsageCalculationPeriodDropdownToApiPriceProperties,
  fromApiPricePropertiesToUsageCalculationPeriodDropdown
}
