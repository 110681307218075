import { useState } from 'react'

import { useNavigationV3Context } from 'components/NavigationV3/hooks/useNavigationV3Context'

type UseFooter = () => {
  feedbackModal: {
    available: boolean
    toggle: () => void
    visible: boolean
  }
}

export const useFooter: UseFooter = () => {
  const { queries } = useNavigationV3Context()

  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)

  return {
    feedbackModal: {
      available: queries.canShareFeedback(),
      toggle: () => setShowFeedbackModal(!showFeedbackModal),
      visible: showFeedbackModal
    }
  }
}
