import { Flex, Text, Center, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  GreyGrey20,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { SequenceMenu, SequenceMenuItemType } from '@sequencehq/core-components'

import { Avatar } from 'components/NavigationV3/components/Avatar/Avatar'
import { useNavigationV3Context } from 'components/NavigationV3/hooks/useNavigationV3Context'

import ChevronDownIcon from '@heroicons/react/16/solid/ChevronDownIcon'
import Cog6ToothIcon from '@heroicons/react/16/solid/Cog6ToothIcon'
import BuildingOffice2Icon from '@heroicons/react/16/solid/BuildingOffice2Icon'
import ArrowLeftEndOnRectangleIcon from '@heroicons/react/16/solid/ArrowLeftEndOnRectangleIcon'
import UserGroupIcon from '@heroicons/react/16/solid/UserGroupIcon'
import CurrencyPoundIcon from '@heroicons/react/16/solid/CurrencyPoundIcon'
import KeyIcon from '@heroicons/react/16/solid/KeyIcon'
import BellIcon from '@heroicons/react/16/solid/BellIcon'
import BuildingStorefrontIcon from '@heroicons/react/16/solid/BuildingStorefrontIcon'
import CreditCardIcon from '@heroicons/react/16/solid/CreditCardIcon'
import ReceiptPercentIcon from '@heroicons/react/16/solid/ReceiptPercentIcon'
import WalletIcon from '@heroicons/react/16/solid/WalletIcon'
import CheckIcon from '@heroicons/react/16/solid/CheckIcon'
import LockClosedIcon from '@heroicons/react/16/solid/LockClosedIcon'
import DocumentCheckIcon from '@heroicons/react/16/solid/DocumentCheckIcon'
import { WrenchScrewdriverIcon } from '@heroicons/react/16/solid'
import {
  isDemoEnv,
  isProductionEnv,
  isSandboxEnv
} from 'lib/environment/environment'
import ReceiptRefundIcon from '@heroicons/react/16/solid/ReceiptRefundIcon'

export const MainMenu = () => {
  const { data, queries, functions } = useNavigationV3Context()
  const navigate = useNavigate()
  const flags = useFlags()

  const items: SequenceMenuItemType[] = [
    {
      label: 'Settings',
      iconLeft: <Cog6ToothIcon width="16px" height="16px" color="inherit" />,
      divider: true,
      subItems: [
        {
          label: 'Team',
          iconLeft: (
            <UserGroupIcon width="16px" height="16px" color="inherit" />
          ),
          onClick: () => navigate('/settings/team')
        },
        {
          label: 'Currencies',
          iconLeft: (
            <CurrencyPoundIcon width="16px" height="16px" color="inherit" />
          ),
          onClick: () => navigate('/settings/currencies')
        },
        {
          label: 'API keys',
          iconLeft: <KeyIcon width="16px" height="16px" color="inherit" />,
          onClick: () => navigate('/settings/api-keys')
        },
        {
          label: 'Notifications',
          iconLeft: <BellIcon width="16px" height="16px" color="inherit" />,
          onClick: () => navigate('/settings/notifications')
        },
        {
          label: 'Merchant',
          iconLeft: (
            <BuildingStorefrontIcon
              width="16px"
              height="16px"
              color="inherit"
            />
          ),
          onClick: () => navigate('/settings/merchant')
        },
        {
          label: 'Bank details',
          iconLeft: (
            <CreditCardIcon width="16px" height="16px" color="inherit" />
          ),
          onClick: () => navigate('/settings/bank-details')
        },
        {
          label: 'Sales tax',
          iconLeft: (
            <ReceiptPercentIcon width="16px" height="16px" color="inherit" />
          ),
          onClick: () => navigate('/settings/tax-rates')
        },
        {
          label: 'Invoices',
          iconLeft: <WalletIcon width="16px" height="16px" color="inherit" />,
          onClick: () => navigate('/settings/invoices')
        },
        {
          label: 'Credit notes',
          iconLeft: (
            <ReceiptRefundIcon width="16px" height="16px" color="inherit" />
          ),
          onClick: () => navigate('/settings/credit-notes'),
          hidden: !flags?.creditNoteCreditGrantsEnabled
        },
        {
          label: 'Quotes',
          iconLeft: (
            <DocumentCheckIcon width="16px" height="16px" color="inherit" />
          ),
          onClick: () => navigate('/settings/quotes'),
          hidden: !flags?.showQuoteBuilder || !flags?.isQuoteSettingsEnabled
        }
      ],
      hidden: !queries.canUseSettings()
    },
    {
      label: 'Switch workspace',
      iconLeft: (
        <BuildingOffice2Icon width="16px" height="16px" color="inherit" />
      ),
      subItems: data.userWorkspaces.map(workspace => ({
        label: workspace.name,
        iconLeft: <Avatar name={workspace.name} />,
        iconRight:
          data.workspaceId === workspace.id ? (
            <CheckIcon width="16px" height="16px" color="inherit" />
          ) : undefined,
        onClick: () => {
          void functions.switchWorkspace({ workspaceId: workspace.id })
        },
        style: {
          paddingTop: '7px !important',
          paddingBottom: '7px !important'
        }
      })),
      subItemsHeader: (
        <Flex padding="8px" gap="8px" flexDirection="column">
          <Text {...Lato13Bold} color={GreyGrey80}>
            Signed in with
          </Text>
          <Text {...Lato13Regular} color={GreyGrey80}>
            {data.userEmail}
          </Text>
        </Flex>
      ),
      hidden: !queries.canSwitchWorkspaces()
    },
    {
      label: 'Switch environment',
      iconLeft: (
        <WrenchScrewdriverIcon width="16px" height="16px" color="inherit" />
      ),
      divider: true,
      subItems: [
        {
          label: 'Sandbox',
          onClick: () => {
            window.location.href = 'https://dashboard.sandbox.sequencehq.com'
          },
          iconRight: isSandboxEnv() ? (
            <CheckIcon width="16px" height="16px" color="inherit" />
          ) : undefined
        },
        {
          label: 'Production',
          onClick: () => {
            window.location.href = 'https://dashboard.sequencehq.com'
          },
          iconRight: isProductionEnv() ? (
            <CheckIcon width="16px" height="16px" color="inherit" />
          ) : undefined
        },
        {
          label: 'Demo mode',
          onClick: () => {
            window.location.href = 'https://demo.sequencehq.com'
          },
          iconRight: isDemoEnv() ? (
            <CheckIcon width="16px" height="16px" color="inherit" />
          ) : undefined
        }
      ]
    },
    {
      label: 'Request sandbox access',
      onClick: () => {
        window.location.href = 'https://access.sequencehq.com'
      },
      iconLeft: <LockClosedIcon width="16px" height="16px" color="inherit" />,
      hidden: !queries.canRequestSandboxAccess()
    },
    {
      label: 'Log out',
      iconLeft: (
        <ArrowLeftEndOnRectangleIcon
          width="16px"
          height="16px"
          color="inherit"
        />
      ),
      onClick: () => {
        void functions.handleLogOut()
      },
      hidden: !queries.canLogOut()
    }
  ]

  return (
    <SequenceMenu
      width={220}
      menuButton={
        <Button
          data-testid="mainMenu"
          variant="unstyled"
          display="flex"
          height="fit-content"
          gap="6px"
          alignItems="center"
          padding="6px"
          maxWidth="100%"
          _hover={{ background: GreyGrey20 }}
          _focus={{ background: GreyGrey20 }}
        >
          <Avatar name={data.workspaceName} />
          <Text
            {...Lato14Bold}
            color={GreyGrey80}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {data.workspaceName}
          </Text>
          <Center width="16px" height="16px" flexShrink="0">
            <ChevronDownIcon width="16px" height="16px" color="black" />
          </Center>
        </Button>
      }
      items={items}
    />
  )
}
