import {
  Box,
  StyleProps,
  Flex,
  MenuButton,
  MenuList,
  Menu,
  Button,
  MenuItem
} from '@chakra-ui/react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import {
  BorderRadius4,
  GreyGrey10,
  GreyGrey30,
  Lato14Regular,
  IndigoIndigo50
} from '@sequencehq/design-tokens'
import { FC, JSXElementConstructor, ReactElement, useMemo } from 'react'

interface IconSelectProps {
  options: Array<{
    label: string
    value: string
    icon: ReactElement<any, string | JSXElementConstructor<any>>
  }>
  value: string
  onChange: (newValue: string) => void
  styles?: {
    button: StyleProps
  }
}

export const IconSelect: FC<IconSelectProps> = ({
  options,
  value,
  onChange,
  styles = {
    button: {}
  }
}) => {
  const currentValue = useMemo(() => {
    return options.find(opt => opt.value === value) ?? options[0]
  }, [options, value])

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={
          <ChevronDownIcon
            style={{
              height: '18px',
              width: '18px',
              marginRight: '-4px',
              marginLeft: '-4px'
            }}
          />
        }
        backgroundColor="transparent"
        border={`1px solid ${GreyGrey30}`}
        _focus={{
          outline: `2px solid ${IndigoIndigo50}`
        }}
        _hover={{
          backgroundColor: GreyGrey10
        }}
        _active={{
          backgroundColor: GreyGrey10
        }}
        {...Lato14Regular}
        {...styles.button}
      >
        <Flex alignItems="center">
          <Box marginRight="4px">{currentValue.icon}</Box>
          {currentValue.label}
        </Flex>
      </MenuButton>
      <MenuList
        borderRadius={BorderRadius4}
        border={`1px solid ${GreyGrey30}`}
        padding="8px"
      >
        {options.map(opt => {
          return (
            <MenuItem
              key={opt.value}
              alignItems="center"
              padding="8px 24px 8px 8px"
              _hover={{
                backgroundColor: GreyGrey10
              }}
              onClick={() => {
                onChange(opt.value)
              }}
              icon={opt.icon}
              /**
               * Handled this way to lean on specificity to always override the
               * border radius behaviour related to first and last. This is one
               * of the downsides of CSS-in-JS approaches - specificity based
               * overrides tend to be difficult to handle and rely on these
               * more 'brute force' methods.
               */
              style={{
                borderRadius: BorderRadius4
              }}
              {...Lato14Regular}
            >
              {opt.label}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
