import {
  ActionHandler,
  PricingEditorReducerState,
  UpdateData
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import deepmerge from 'deepmerge'

export const updateData: ActionHandler<UpdateData> = prevState => action => {
  return {
    ...prevState,
    data: deepmerge(prevState.data, action.payload, {
      arrayMerge: (_, source: unknown[]) => source
    })
  } as PricingEditorReducerState
}
