import { Box, Flex, Text } from '@chakra-ui/react'
import { ArchiveBoxIcon } from '@heroicons/react/16/solid'
import {
  MenuItemBuilder,
  Modal,
  ModalContextProvider
} from '@sequencehq/core-components'
import { IntegrationService } from '@sequencehq/core-models'
import { RedRed50, RedRed60 } from '@sequencehq/design-tokens'
import { openOverlay } from 'features/overlay'
import { AppDispatch } from 'features/store'
import { integrationName } from 'lib/integrations/integrationName'
import { getLogo } from 'lib/integrations/logos'
import { NavigateFunction } from 'react-router-dom'
import { Customer } from 'components/Customers/types.ts'
import {
  customCustomerLinkModals,
  LinkCustomerModal,
  UnlinkCustomerModal
} from 'Integrations/index'

export const useDetailBuilder: MenuItemBuilder<Omit<Customer, 'contacts'>> = (
  customer: Omit<Customer, 'contacts'>
) => {
  return {
    status: 'LIVE',
    action: ({ navigate }: { navigate: NavigateFunction }) =>
      navigate(`/customers/${customer.id}`),
    label: 'View customer details'
  }
}

export const useArchiveBuilder: MenuItemBuilder<Omit<Customer, 'contacts'>> = (
  customer: Omit<Customer, 'contacts'>
) => {
  return {
    itemIcon: <ArchiveBoxIcon color={RedRed50} height={16} width={16} />,
    styles: {
      color: RedRed60
    },
    status: customer.archivedAt ? 'DISABLED' : 'LIVE',
    label: 'Archive customer',
    action: ({ dispatch }: { dispatch: AppDispatch }) =>
      dispatch(
        openOverlay({
          content: 'archiveCustomerModal',
          data: {
            customer
          }
        })
      )
  }
}

export const useLinkBuilder =
  (ctx: { onLink: () => void; onUnlink: () => void }) =>
  (
    integrationService: IntegrationService
  ): MenuItemBuilder<Omit<Customer, 'contacts'>> => {
    return (customer: Omit<Customer, 'contacts'>) => {
      const alreadyLinked = customer.integrationIds.some(
        service => service.service === integrationService
      )
      const LinkModalComponent =
        customCustomerLinkModals[integrationService] ?? LinkCustomerModal

      if (alreadyLinked) {
        return {
          status: 'LIVE',
          action: () => {},
          label: (
            <ModalContextProvider>
              <Modal.Trigger>
                <Flex flexDirection="row" align="center">
                  <Text>{`Unlink from ${integrationName(
                    integrationService
                  )}`}</Text>
                  <Box width="6px"></Box>
                  {getLogo(integrationService, 18)}
                </Flex>
              </Modal.Trigger>
              <UnlinkCustomerModal
                customerId={customer.id}
                service={integrationService}
                onUnlink={ctx.onUnlink}
              />
            </ModalContextProvider>
          )
        }
      }
      return {
        status: 'LIVE',
        action: () => {},
        label: (
          <ModalContextProvider>
            <Modal.Trigger>
              <Flex flexDirection="row" align="center">
                <Text>{`Link to ${integrationName(integrationService)}`}</Text>
                <Box width="6px"></Box>
                {getLogo(integrationService, 18)}
              </Flex>
            </Modal.Trigger>
            <LinkModalComponent
              customerId={customer.id}
              service={integrationService}
              onLink={ctx.onLink}
            />
          </ModalContextProvider>
        )
      }
    }
  }

export const menuItemBuilders =
  (ctx: { onLink: () => void; onUnlink: () => void }) =>
  (integrations: IntegrationService[]) => {
    return [
      ...integrations.map(integration => useLinkBuilder(ctx)(integration))
    ]
  }
