import { Box } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { baseApi } from 'features/api/baseApi'
import * as Sentry from '@sentry/react'

type InvoiceProps = {
  invoiceId: string
  invoiceNumber: string
}

const InvoicePdf: FC<InvoiceProps> = ({ invoiceId, invoiceNumber }) => {
  const navigate = useNavigate()
  const [getInvoiceDownload] =
    baseApi.endpoints.getInvoiceDownload.useLazyQuerySubscription()

  useEffect(() => {
    getInvoiceDownload({ id: invoiceId })
      .unwrap()
      .then(fulfilled => {
        const blobUrl = URL.createObjectURL(
          new Blob([fulfilled], { type: 'application/pdf' })
        )

        const a = document.createElement('a')
        a.download = invoiceNumber
        a.href = blobUrl
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .catch(e => Sentry.captureException(e))

    navigate(-1)
  }, [getInvoiceDownload, invoiceId, invoiceNumber, navigate])

  return <></>
}

const InvoiceParamsHandler: FC = () => {
  const { invoiceId, invoiceNumber } = useParams<InvoiceProps>()
  if (!invoiceId) {
    return <Box>Missing invoice parameters</Box>
  }
  return (
    <InvoicePdf invoiceId={invoiceId} invoiceNumber={invoiceNumber || ''} />
  )
}

export default InvoiceParamsHandler
