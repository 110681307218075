import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'

export type Data = Dashboardv20240509Api.GetBillingSchedule.BillingSchedule

type UseCallArchiveBillingSchedule = () => (
  billingScheduleId: string
) => Promise<Data>

export const useCallArchiveBillingSchedule: UseCallArchiveBillingSchedule =
  () => {
    return useCallback(async (billingScheduleId: string) => {
      const response = await dashboardv20240509Client.putBillingScheduleArchive(
        {
          id: billingScheduleId
        }
      )
      const archived = response.data

      invariant(archived, 'Billing Schedule not found')

      return archived
    }, [])
  }
