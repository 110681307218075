import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { v1ApiLoadedData } from '../../loadData'

type LoadListPrices = (args: {
  productId: string
}) => Promise<NonNullable<v1ApiLoadedData['listPrices']>>

export const loadListPrices: LoadListPrices = async ({
  productId
}: {
  productId: string
}) => {
  const listPrices = await dashboardv20240509Client.getListPrices({ productId })
  return listPrices.data?.items ?? []
}
