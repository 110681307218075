import {
  AvailableServices,
  Integration,
  IntegrationFeatureAvailable,
  IntegrationFeatureDisabledReason,
  ResolvedIntegration
} from 'modules/Integrations/domain/integrations.domain.types'
import invariant from 'tiny-invariant'

export const getRemoveLinksAvailable =
  (ctx: {
    resolvedIntegrations: Partial<
      Record<Integration['id'], ResolvedIntegration>
    >
  }) =>
  (service: AvailableServices) =>
  (): IntegrationFeatureAvailable => {
    const integrationConfig = ctx.resolvedIntegrations[service]

    invariant(integrationConfig, 'Integration not found')

    const featureAvailable = integrationConfig.features?.canDeleteLinks ?? false

    return {
      available: {
        visible: featureAvailable,
        enabled: featureAvailable
      },
      reasons: !featureAvailable
        ? [IntegrationFeatureDisabledReason.FeatureNotAvailable]
        : []
    }
  }
