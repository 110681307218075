import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'

export const getCustomPhaseDurationsAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const isAQuote = Boolean(quoteStatusAdapter.out(prevState.data.common.status))

  /**
   * We don't support custom durations in quotes. The reason is that we cannot
   * guarentee that the quotes will work when converted into schedules without
   * the recurrence date and a start date.
   *
   * This ultimately ties into issues we have with proration of certain pricing
   * features (see the phase analysis for all the checks that are made to assess
   * for this) in the billing engine. Once we fix that, all of this can go away and
   * users can roam free, selecting whichever durations their hearts desire.
   *
   * Also, _technically_ it would be nice to have custom durations selectable still
   * in quotes even in the short term, but change the input to provide some kind of
   * month selector option in that scenario rather than a datepicker. At which point
   * this feature check would change to whether we allow custom duration with days.
   */
  if (isAQuote) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: {
            status: prevState.data.common.status
          }
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
