import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { useMutation } from '@sequencehq/api/utils'
import { SimpleDialogUI } from '@sequencehq/core-components'
import { Text } from '@chakra-ui/react'
import { GreyGrey80, Lato14ExtendedRegular } from '@sequencehq/design-tokens'

type Props = {
  invoiceId: string
  onSuccess?: VoidFunction
}

export const ArchiveInvoiceModal = ({ invoiceId, onSuccess }: Props) => {
  const notifications = useNotifications()
  const deleteInvoiceMutation = useMutation(
    dashboard20240730Client.deleteInvoice
  )

  const onSubmit = async () => {
    try {
      await deleteInvoiceMutation.mutateAsync({
        invoiceId
      })

      notifications.displayNotification(`Invoice deleted`, {
        type: 'success'
      })

      onSuccess?.()
    } catch (e) {
      const error = e as Error

      notifications.displayNotification(`Unable to delete invoice`, {
        type: 'error',
        description: error.message
      })
    }
  }

  return (
    <SimpleDialogUI
      title="Delete invoice"
      cancelButtonText="Cancel"
      confirmButtonText="Delete invoice"
      onConfirm={() => void onSubmit()}
      destructive
    >
      <Text color={GreyGrey80} {...Lato14ExtendedRegular}>
        This will delete the invoice immediately. This action cannot be undone.
      </Text>
    </SimpleDialogUI>
  )
}
