import {
  pricingModelForms,
  pricingModelHiddenFieldForms
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { FC, useCallback, useMemo, useState } from 'react'

type UsePriceForm = () => {
  ActiveForm: FC<unknown> | null
  ActiveHiddenFieldsForm: FC<unknown> | null
  hidePriceFields: boolean
  showProductFields: boolean
  showAdditionalOptions: boolean
  toggleAdditionalOptions: () => void
}

export const usePriceForm: UsePriceForm = () => {
  const pricingEditorContext = usePricingEditorContext()
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)

  const ActiveForm = useMemo(() => {
    if (!pricingEditorContext.data.formData.common.pricingModel) {
      return null
    }

    return pricingModelForms[
      pricingEditorContext.data.formData.common.pricingModel
    ]
  }, [pricingEditorContext.data.formData.common.pricingModel])

  const ActiveHiddenFieldsForm = useMemo(() => {
    if (
      pricingEditorContext.configuration.enableListPrices &&
      pricingEditorContext.data.formData.common.pricingModel
    ) {
      return pricingModelHiddenFieldForms[
        pricingEditorContext.data.formData.common.pricingModel
      ]
    }

    return null
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.data.formData.common.pricingModel
  ])

  const toggleAdditionalOptions = useCallback(() => {
    setShowAdditionalOptions(!showAdditionalOptions)
  }, [showAdditionalOptions])

  const hidePriceFields = useMemo(() => {
    return Boolean(
      pricingEditorContext.configuration.enableListPrices &&
        !pricingEditorContext.derived.queries.availableFeatures
          .showAllPriceFields
    )
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  const showProductFields =
    pricingEditorContext.derived.queries.availableFeatures.showProductFields

  return {
    ActiveForm,
    ActiveHiddenFieldsForm,
    hidePriceFields,
    showProductFields,
    showAdditionalOptions,
    toggleAdditionalOptions
  }
}
