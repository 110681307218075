import { Flex, Td, Text, Tr } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Bold,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { Button } from '@sequencehq/core-components'
import { PlusIcon } from '@heroicons/react/16/solid'
import { TaxRatesTableContainer } from 'Settings/view/taxRatesSettings/components/TaxRatesTable'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'
import { avalaraIntegrationConfig } from 'Integrations/integrationsConfig/avalara/avalara.integration.config'

const ManagedByAvalaraTaxRatesSection = () => {
  return (
    <Flex flexDirection="column" width="100%">
      <Flex justifyContent="space-between" align="center" opacity="0.5">
        <Text {...Lato14Bold} color={GreyGrey80}>
          Rates
        </Text>
        <Button
          disabled
          variant="ghost"
          leadingIcon={<PlusIcon width="16px" height="16px" color="inherit" />}
        >
          New tax rate
        </Button>
      </Flex>

      <TaxRatesTableContainer disabled>
        <Tr>
          <Td colSpan={4}>
            <Flex
              height="36px"
              width="100%"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <SmallIcon url={avalaraIntegrationConfig.logoUrl} />

              <Text {...Lato13Bold} color={GreyGrey60}>
                Managed via Avalara
              </Text>
            </Flex>
          </Td>
        </Tr>
      </TaxRatesTableContainer>
    </Flex>
  )
}
export default ManagedByAvalaraTaxRatesSection
