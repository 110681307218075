import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Grid,
  GridItem,
  Flex
} from '@chakra-ui/react'
import {
  type LineItemEditorLineItem,
  type LineItemEditorLineItemGroup,
  type LineItemEditorMode,
  useLineItemEditor
} from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditor'
import { GreyGrey30, Lato16Bold } from '@sequencehq/design-tokens'
import { LineItemEditorForm } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/components/LineItemEditorForm/LineItemEditorForm'
import type { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { LineItemEditorProvider } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditorContext'
import { type FC } from 'react'

export type LineItemEditorRootProps = {
  lineItemGroup?: LineItemEditorLineItemGroup
  existingLineItem?: LineItemEditorLineItem
  currency: Currency
  customer: any
  mode: LineItemEditorMode
  onClose: () => void
  onDelete: () => void
  onCreate: (lineItem: LineItemEditorLineItem) => void
  onUpdate: (lineItem: LineItemEditorLineItem) => void
}

export const LineItemEditorRoot: FC<LineItemEditorRootProps> = props => {
  const lineItemEditorDomain = useLineItemEditor(props)
  const { editor, data, functions } = lineItemEditorDomain

  return (
    <LineItemEditorProvider value={lineItemEditorDomain}>
      <Drawer isOpen onClose={props.onClose}>
        <DrawerOverlay
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <DrawerContent
            style={{
              width: 'fit-content',
              maxWidth: '100%',
              minWidth: '550px'
            }}
            margin="8px 12px"
            borderRadius="8px"
          >
            <Grid
              templateAreas={`
            "header"
            "content"
            "footer"
          `}
              templateRows={'56px 1fr 56px'}
              width="100%"
              height="100%"
            >
              <Flex
                gridArea="header"
                borderBottom={`1px solid ${GreyGrey30}`}
                {...Lato16Bold}
                height="56px"
                px="24px"
                alignItems="center"
              >
                {data.title}
              </Flex>

              <GridItem gridArea="content" overflow="auto">
                <LineItemEditorForm showTaxRate={!editor.canUseTaxCategories} />
              </GridItem>

              <Flex
                gridArea="footer"
                align="center"
                borderTop={`1px solid ${GreyGrey30}`}
                gap="8px"
                height="100%"
                justify="flex-end"
                padding="24px"
              >
                <Button variant="secondary2" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  data-testid="lineItemEditor.save"
                  isDisabled={!editor.canSave}
                  variant="primary2"
                  onClick={
                    editor.mode === 'CREATE'
                      ? functions.onCreate
                      : functions.onUpdate
                  }
                >
                  Save
                </Button>
              </Flex>
            </Grid>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </LineItemEditorProvider>
  )
}
