import {
  Box,
  Flex,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { ArrowsRightLeftIcon } from '@heroicons/react/16/solid'
import { ErrorBoundary } from '@sentry/react'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useMutation, useQuery } from '@sequencehq/api/utils'
import {
  Checkbox,
  DatePickerField,
  SimpleAsyncModal,
  Skeleton,
  TextFieldInput,
  TextInputField
} from '@sequencehq/core-components'
import { toCurrencySymbol } from '@sequencehq/core-models'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey80,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  RedRed60,
  ShadowXs
} from '@sequencehq/design-tokens'
import { enforceMinimumPrecision, toMoney, useForm } from '@sequencehq/utils'
import { format } from '@sequencehq/utils/dates'
import { greaterThanEqualTo, required } from '@sequencehq/validation'
import PageError from 'components/ErrorPages/PageError'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ReactNode, useMemo, useState } from 'react'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { AccountName, FormData } from './manualJournalModal.types'
import {
  atLeastTwoJournalsSelected,
  calculateTotals,
  formatAccountAmountForForm,
  formatAccountDataForApi,
  getAmountForAccount,
  onlyOneDebitOrCredit,
  totalsMatch
} from './manualJournalModal.utils'

/**
 * We use the underlying API data to set the form values, which will
 * also respond to changes in the subscribed data too. However, in this
 * case, we would not expect the service side data to differ.
 * @param props
 * @returns
 */
const useManualJournalModal = (props: {
  journalId: string
  onSuccess?: () => void
}) => {
  const { displayNotification } = useNotifications()
  const selectedCurrency = useSelectedCurrency()

  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const postJournalMutation = useMutation(
    dashboardv99990101Client.postManualJournal
  )

  const journalQuery = useQuery(
    dashboardv99990101Client.getJournal,
    { id: props.journalId },
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          date: new Date(res.date),
          accountAmounts: {
            billed: getAmountForAccount('BILLED_REVENUE')(res.entries),
            unbilled: getAmountForAccount('UNBILLED_REVENUE')(res.entries),
            deferred: getAmountForAccount('DEFERRED_REVENUE')(res.entries),
            recognized: getAmountForAccount('RECOGNIZED_REVENUE')(res.entries)
          }
        }
      }
    }
  )

  const defaultValues: FormData = useMemo(() => {
    return {
      accountAmounts: {
        billed: formatAccountAmountForForm(
          journalQuery.data?.accountAmounts.billed
        ),
        unbilled: formatAccountAmountForForm(
          journalQuery.data?.accountAmounts.unbilled
        ),
        deferred: formatAccountAmountForForm(
          journalQuery.data?.accountAmounts.deferred
        ),
        recognized: formatAccountAmountForForm(
          journalQuery.data?.accountAmounts.recognized
        )
      },
      date: journalQuery.data?.date ?? new Date(),
      narrative: ''
    }
  }, [journalQuery.data])

  const form = useForm<FormData>({
    value: defaultValues,
    showValidationErrors: showValidationErrors,
    fieldConfiguration: [
      {
        property: 'date',
        validation: [required]
      },
      ...(
        ['billed', 'unbilled', 'deferred', 'recognized'] as Array<AccountName>
      ).flatMap(account => [
        {
          property: `accountAmounts.${account}.credit` as const,
          disabled: ({ formData }: { formData: FormData }) =>
            !formData.accountAmounts[account].include,
          validation: [onlyOneDebitOrCredit(account), greaterThanEqualTo(0)]
        },
        {
          property: `accountAmounts.${account}.debit` as const,
          disabled: ({ formData }: { formData: FormData }) =>
            !formData.accountAmounts[account].include,
          validation: [onlyOneDebitOrCredit(account), greaterThanEqualTo(0)]
        },
        {
          property: `accountAmounts.${account}.include` as const
        }
      ]),
      {
        property: 'narrative',
        validation: [required]
      }
    ]
  })

  const globalEntriesValidationErrors = useMemo(() => {
    return [totalsMatch, atLeastTwoJournalsSelected].flatMap(validator =>
      validator(form.queries.formData)
    )
  }, [form.queries.formData])

  const formValid =
    globalEntriesValidationErrors.length === 0 && form.queries.isValid

  const reset = () => {
    setShowValidationErrors(false)
    form.mutators.updateFormData(defaultValues)
  }

  const onSubmit = async () => {
    if (journalQuery.isPending || !selectedCurrency.currency) {
      return false
    }

    if (!formValid) {
      setShowValidationErrors(true)
      return false
    }

    try {
      await postJournalMutation.mutateAsync({
        narrative: form.queries.formData.narrative,
        date: format(form.queries.formData.date, 'yyyy-MM-dd'),
        currency: selectedCurrency.currency,
        entries: [
          ...formatAccountDataForApi('BILLED_REVENUE')(
            form.queries.formData.accountAmounts.billed
          ),
          ...formatAccountDataForApi('UNBILLED_REVENUE')(
            form.queries.formData.accountAmounts.unbilled
          ),
          ...formatAccountDataForApi('DEFERRED_REVENUE')(
            form.queries.formData.accountAmounts.deferred
          ),
          ...formatAccountDataForApi('RECOGNIZED_REVENUE')(
            form.queries.formData.accountAmounts.recognized
          )
        ]
      })

      displayNotification(`Adjustment journal created`, {
        type: 'success'
      })
      reset()
      props.onSuccess?.()
      return true
    } catch {
      displayNotification(`Failed to create adjustment journal`, {
        type: 'error'
      })
      return false
    }
  }

  const reverseAccountAmounts = () => {
    const { accountAmounts } = form.queries.formData
    form.mutators.updateFormData({
      accountAmounts: {
        billed: {
          credit: accountAmounts.billed.debit,
          debit: accountAmounts.billed.credit,
          include: accountAmounts.billed.include
        },
        unbilled: {
          credit: accountAmounts.unbilled.debit,
          debit: accountAmounts.unbilled.credit,
          include: accountAmounts.unbilled.include
        },
        deferred: {
          credit: accountAmounts.deferred.debit,
          debit: accountAmounts.deferred.credit,
          include: accountAmounts.deferred.include
        },
        recognized: {
          credit: accountAmounts.recognized.debit,
          debit: accountAmounts.recognized.credit,
          include: accountAmounts.recognized.include
        }
      }
    })
  }

  if (journalQuery.error) {
    throw new Error('Manual journal data could not be loaded')
  }

  return {
    form,
    reverseAccountAmounts,
    currency: selectedCurrency.currency,
    isLoading: journalQuery.isPending,
    onSubmit,
    reset,
    totals: calculateTotals(form.queries.formData),
    globalEntriesValidationErrors: showValidationErrors
      ? globalEntriesValidationErrors
      : []
  }
}

export const ManualJournalModal = (props: {
  journalId: string
  trigger: ReactNode | ((triggerModal: () => void) => ReactNode)
  onSuccess?: () => void
}) => {
  const {
    form,
    currency,
    isLoading,
    onSubmit,
    reset,
    reverseAccountAmounts,
    globalEntriesValidationErrors: globalValidationErrors,
    totals
  } = useManualJournalModal({
    journalId: props.journalId,
    onSuccess: props.onSuccess
  })

  const currencySymbol = currency ? toCurrencySymbol(currency) : ''

  /**
   * Due to how this modal component is set up, the external state is not
   * unmounted when the modal is closed. Therefore, we need to
   * reset it.
   *
   * We still benefit from the unmountOnClose for the underlying
   * form, however! It just means we have to pipe the 'canSubmit'
   * state back to here using the 'onSubmitAvailabilityChange' prop.
   *
   * One slight issue with this setup is that if we do want to display
   * errors from the API in the future, we need to pass them down a layer,
   * too. It's ultimately all a bit of a tradeoff and how much exposure
   * to 'leftover' state you wish to have. Given that dud state is a cause
   * of many issues, I generally prefer to remove it where possible.
   */
  return (
    <SimpleAsyncModal
      title="Create adjustment journal"
      trigger={props.trigger}
      cancelButtonText="Cancel"
      submitButtonText="Post journal"
      submittingButtonText="Posting..."
      onSubmit={onSubmit}
      onCancel={reset}
      data-testid="revrec.createManualJournal.modal"
    >
      <ErrorBoundary fallback={<PageError />}>
        {isLoading ? (
          <Flex flexDirection="column" gap="16px">
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="120px" />
          </Flex>
        ) : (
          <Flex flexDirection="column" gap="16px">
            <TextInputField
              height="32px"
              label="Narration"
              {...form.fields.narrative}
              styles={{ wrapper: { margin: '0' } }}
              placeholder="Enter brief summary"
              onChange={e => form.fields.narrative.onChange(e.target.value)}
              data-testid="revrec.createManualJournal.modal.narrative"
            />
            <DatePickerField
              label="Date"
              {...form.fields.date}
              width="100%"
              onChange={date => date && form.fields.date.onChange(date)}
              styles={{ wrapper: { margin: '0' } }}
              data-testid="revrec.createManualJournal.modal.date"
            />
            <Flex flexDirection="column" gap="8px">
              <Grid
                gridTemplateColumns="1fr auto"
                height="24px"
                alignItems="center"
              >
                <Box {...Lato13Bold} color={GreyGrey80}>
                  Entries
                </Box>
                <Flex
                  alignItems="center"
                  onClick={reverseAccountAmounts}
                  gap="4px"
                  color={IndigoIndigo50}
                  cursor="pointer"
                  userSelect="none"
                  {...Lato13Bold}
                  data-testid="revrec.createManualJournal.modal.reverseEntries"
                >
                  <ArrowsRightLeftIcon
                    height="16px"
                    width="16px"
                    color={IndigoIndigo50}
                  />
                  Reverse entries
                </Flex>
              </Grid>
              <TableContainer
                data-testid="revrec.journalDrawer.accountAmounts"
                borderRadius="lg"
                overflow="hidden"
                border={`1px solid ${globalValidationErrors.length ? RedRed60 : GreyGrey30}`}
                width="100%"
                boxShadow={ShadowXs}
              >
                <Table variant="v2" width="100%">
                  <Thead>
                    <Tr>
                      <Th width="155px"></Th>
                      <Th width="100%" textAlign="right">
                        <span style={{ color: GreyGrey60 }}>Debit</span>
                      </Th>
                      <Th width="100%" color={GreyGrey60} textAlign="right">
                        <span style={{ color: GreyGrey60 }}>Credit</span>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {(
                      ['billed', 'deferred', 'unbilled', 'recognized'] as Array<
                        'billed' | 'unbilled' | 'deferred' | 'recognized'
                      >
                    ).map(account => {
                      const rowEnabled =
                        form.fields[`accountAmounts.${account}.include`].value

                      return (
                        <Tr key={account}>
                          <Td
                            onClick={() => {
                              form.fields[
                                `accountAmounts.${account}.include`
                              ].onChange(!rowEnabled)
                            }}
                          >
                            <Flex>
                              <Checkbox
                                isChecked={rowEnabled}
                                data-testid={`revrec.createManualJournal.modal.${account}.include`}
                                onChange={e => {
                                  e.stopPropagation()
                                  form.fields[
                                    `accountAmounts.${account}.include`
                                  ].onChange(e.target.checked)
                                }}
                              />
                              <Box
                                {...Lato13Bold}
                                fontWeight={rowEnabled ? 'semibold' : 'normal'}
                                color={GreyGrey80}
                                userSelect="none"
                              >
                                {
                                  {
                                    billed: 'Billed revenue',
                                    deferred: 'Deferred revenue',
                                    unbilled: 'Unbilled revenue',
                                    recognized: 'Recognized revenue'
                                  }[account]
                                }
                              </Box>
                            </Flex>
                          </Td>
                          <Td padding="4px 8px !important">
                            <TextFieldInput
                              prefix={currencySymbol}
                              height="28px"
                              width="100%"
                              style={{
                                textAlign: 'right'
                              }}
                              data-testid={`revrec.createManualJournal.modal.${account}.debit`}
                              value={`${form.fields[`accountAmounts.${account}.debit`].value}`}
                              onChange={e => {
                                form.mutators.updateFormData({
                                  accountAmounts: {
                                    ...form.queries.formData.accountAmounts,
                                    [account]: {
                                      ...form.queries.formData.accountAmounts[
                                        account
                                      ],
                                      credit: 0,
                                      debit: e.target.value
                                    }
                                  }
                                })
                              }}
                              onBlur={e => {
                                form.mutators.updateFormData({
                                  accountAmounts: {
                                    ...form.queries.formData.accountAmounts,
                                    [account]: {
                                      ...form.queries.formData.accountAmounts[
                                        account
                                      ],
                                      debit: enforceMinimumPrecision(2)(
                                        e.target.value
                                      )
                                    }
                                  }
                                })
                              }}
                              isInvalid={Boolean(
                                form.fields[`accountAmounts.${account}.debit`]
                                  .validationErrors.length
                              )}
                              isDisabled={
                                form.fields[`accountAmounts.${account}.debit`]
                                  ?.disabled
                              }
                            />
                          </Td>
                          <Td padding="4px 8px !important">
                            <TextFieldInput
                              prefix={currencySymbol}
                              width="100%"
                              height="28px"
                              style={{
                                textAlign: 'right'
                              }}
                              value={`${form.fields[`accountAmounts.${account}.credit`].value}`}
                              data-testid={`revrec.createManualJournal.modal.${account}.credit`}
                              onChange={e => {
                                form.mutators.updateFormData({
                                  accountAmounts: {
                                    ...form.queries.formData.accountAmounts,
                                    [account]: {
                                      ...form.queries.formData.accountAmounts[
                                        account
                                      ],
                                      debit: '0.00',
                                      credit: e.target.value
                                    }
                                  }
                                })
                              }}
                              onBlur={e => {
                                form.mutators.updateFormData({
                                  accountAmounts: {
                                    ...form.queries.formData.accountAmounts,
                                    [account]: {
                                      ...form.queries.formData.accountAmounts[
                                        account
                                      ],
                                      credit: enforceMinimumPrecision(2)(
                                        e.target.value
                                      )
                                    }
                                  }
                                })
                              }}
                              isInvalid={Boolean(
                                form.fields[`accountAmounts.${account}.credit`]
                                  .validationErrors.length
                              )}
                              isDisabled={
                                form.fields[`accountAmounts.${account}.credit`]
                                  ?.disabled
                              }
                            />
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Td fontWeight="bold">Total</Td>
                      <Td
                        fontWeight="semibold"
                        textAlign="right"
                        paddingRight="24px !important"
                      >
                        {currency &&
                          toMoney({
                            currency,
                            value: totals.debit.toString()
                          })}
                      </Td>
                      <Td
                        fontWeight="semibold"
                        textAlign="right"
                        paddingRight="24px !important"
                      >
                        {currency &&
                          toMoney({
                            currency,
                            value: totals.credit.toString()
                          })}
                      </Td>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
              {globalValidationErrors.length > 0 && (
                <Box {...Lato13Regular} color={RedRed60}>
                  {globalValidationErrors[0]}
                </Box>
              )}
            </Flex>
          </Flex>
        )}
      </ErrorBoundary>
    </SimpleAsyncModal>
  )
}
