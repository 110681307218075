import { CreateCustomerForm } from 'components/Customers/components/drawers/CreateCustomer/CreateCustomerForm'
import { useNavigate } from 'react-router-dom'

export const CreateCustomerRouteComponent = () => {
  const navigate = useNavigate()

  const goToCustomersList = () => navigate('/customers')

  return (
    <CreateCustomerForm
      onClose={goToCustomersList}
      onSuccess={goToCustomersList}
    />
  )
}
