import { dequal } from 'dequal'
import { omitBy } from 'lodash/fp'
import { Price, ResolvedPhase } from 'modules/Cube/domain/cube.domain.types'

export const getInvalidPriceProration = ({
  previousPhase,
  phase
}: {
  previousPhase?: ResolvedPhase
  phase: Omit<ResolvedPhase, 'analysis'>
}): Price[] => {
  const previousPhaseAnalysis = previousPhase?.analysis

  /**
   * If the phase is not misaligned, or is the last phase, then we can safely assume all is well
   * for this phase.
   */
  if (!previousPhaseAnalysis || previousPhaseAnalysis.alignedWithPeriod.end) {
    return []
  }

  const pricesInPreviousPhase = previousPhase.prices.filter(
    previousProductPrice =>
      phase.prices.find(
        currentProductPrice =>
          currentProductPrice.productId === previousProductPrice.productId
      )
  )

  if (!pricesInPreviousPhase.length) {
    return []
  }

  const changedPrices = phase.prices.filter(currentProductPrice => {
    const priceInPreviousPhase = pricesInPreviousPhase.find(
      previousProductPrice =>
        previousProductPrice.productId === currentProductPrice.productId
    )

    return (
      priceInPreviousPhase &&
      !dequal(
        omitBy(v => !v)(currentProductPrice.structure),
        omitBy(v => !v)(priceInPreviousPhase.structure)
      )
    )
  })

  const changedIncompatiblePrices = changedPrices.filter(price =>
    ['LINEAR', 'VOLUME', 'GRADUATED', 'PACKAGED', 'SEAT_BASED'].includes(
      price.structure.pricingType
    )
  )

  return changedIncompatiblePrices
}
