import { Banner } from '@sequencehq/core-components'
import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey90,
  IndigoIndigo50,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Link } from 'react-router-dom'
import { ArrowUpRightIcon } from '@heroicons/react/16/solid'

const AvalaraCategoriesConfigurationBanner = () => {
  return (
    <Banner
      data-testid="avalaraConfiguration.banner.info"
      variant="neutral"
      withIcon={false}
    >
      <Flex flexDirection="column" width="100%" gap={1}>
        <Text color={GreyGrey90} width="100%">
          Tax is calculated based on the category of each product. Add an
          Avalara tax code to each tax category to automatically calculate sales
          tax.
        </Text>
        <Flex width="100%">
          <Link to="/settings/tax-rates">
            <Flex alignItems="center" alignContent="center" gap={1}>
              <Text color={IndigoIndigo50}>Configure tax categories</Text>
              <ArrowUpRightIcon
                {...Lato13Regular}
                style={{
                  display: 'inline',
                  color: IndigoIndigo50
                }}
                height="16px"
                width="16px"
              />
            </Flex>
          </Link>
        </Flex>
      </Flex>
    </Banner>
  )
}

export default AvalaraCategoriesConfigurationBanner
