import { FC } from 'react'

interface PartiallyPaidIconProps {
  height?: number
  width?: number
}

export const PartiallyPaidIcon: FC<PartiallyPaidIconProps> = ({
  height = 20,
  width = 20
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <g id="Ellipse 219">
          <mask id="path-1-inside-1_291_602" fill="white">
            <path d="M10 17C8.14348 17 6.36301 16.2625 5.05025 14.9497C3.7375 13.637 3 11.8565 3 10C3 8.14348 3.7375 6.36301 5.05025 5.05025C6.36301 3.7375 8.14348 3 10 3V4.99872C8.67358 4.99872 7.40149 5.52564 6.46356 6.46356C5.52564 7.40148 4.99872 8.67358 4.99872 10C4.99872 11.3264 5.52564 12.5985 6.46356 13.5364C7.40148 14.4744 8.67358 15.0013 10 15.0013L10 17Z" />
          </mask>
          <path
            d="M10 17C8.14348 17 6.36301 16.2625 5.05025 14.9497C3.7375 13.637 3 11.8565 3 10C3 8.14348 3.7375 6.36301 5.05025 5.05025C6.36301 3.7375 8.14348 3 10 3V4.99872C8.67358 4.99872 7.40149 5.52564 6.46356 6.46356C5.52564 7.40148 4.99872 8.67358 4.99872 10C4.99872 11.3264 5.52564 12.5985 6.46356 13.5364C7.40148 14.4744 8.67358 15.0013 10 15.0013L10 17Z"
            fill="#DEDAFF"
            stroke="#716FFF"
            strokeWidth="4"
            mask="url(#path-1-inside-1_291_602)"
          />
        </g>
        <g id="Ellipse 220">
          <mask id="path-2-inside-2_291_602" fill="white">
            <path d="M5.45859 4.67313C6.47755 3.80441 7.72429 3.24618 9.05094 3.06464C10.3776 2.88309 11.7285 3.08586 12.9434 3.64888C14.1582 4.21191 15.1862 5.11157 15.9052 6.24116C16.6242 7.37074 17.0041 8.68285 17 10.0219C16.9958 11.3609 16.6076 12.6706 15.8816 13.7956C15.1555 14.9207 14.122 15.8139 12.9036 16.3694C11.6853 16.9248 10.3331 17.1191 9.00766 16.9293C7.68217 16.7395 6.43894 16.1735 5.42542 15.2984L6.71208 13.8082C7.44054 14.4371 8.33409 14.8439 9.28677 14.9803C10.2394 15.1168 11.2113 14.9771 12.087 14.5779C12.9626 14.1787 13.7055 13.5367 14.2273 12.7281C14.7492 11.9194 15.0281 10.9781 15.0311 10.0157C15.0341 9.05332 14.761 8.11026 14.2443 7.29838C13.7275 6.48651 12.9887 5.83989 12.1155 5.43522C11.2423 5.03056 10.2714 4.88482 9.31788 5.0153C8.36437 5.14578 7.46829 5.54701 6.73592 6.17138L5.45859 4.67313Z" />
          </mask>
          <path
            d="M5.45859 4.67313C6.47755 3.80441 7.72429 3.24618 9.05094 3.06464C10.3776 2.88309 11.7285 3.08586 12.9434 3.64888C14.1582 4.21191 15.1862 5.11157 15.9052 6.24116C16.6242 7.37074 17.0041 8.68285 17 10.0219C16.9958 11.3609 16.6076 12.6706 15.8816 13.7956C15.1555 14.9207 14.122 15.8139 12.9036 16.3694C11.6853 16.9248 10.3331 17.1191 9.00766 16.9293C7.68217 16.7395 6.43894 16.1735 5.42542 15.2984L6.71208 13.8082C7.44054 14.4371 8.33409 14.8439 9.28677 14.9803C10.2394 15.1168 11.2113 14.9771 12.087 14.5779C12.9626 14.1787 13.7055 13.5367 14.2273 12.7281C14.7492 11.9194 15.0281 10.9781 15.0311 10.0157C15.0341 9.05332 14.761 8.11026 14.2443 7.29838C13.7275 6.48651 12.9887 5.83989 12.1155 5.43522C11.2423 5.03056 10.2714 4.88482 9.31788 5.0153C8.36437 5.14578 7.46829 5.54701 6.73592 6.17138L5.45859 4.67313Z"
            stroke="#716FFF"
            strokeWidth="4"
            strokeDasharray="4 4"
            mask="url(#path-2-inside-2_291_602)"
          />
        </g>
      </g>
    </svg>
  )
}
