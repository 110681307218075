import { Box, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey90,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'

export const AvalaraIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap={6}>
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Integrate with Avalara to ensure your invoices are accurate and
        compliant with all state and local sales tax regulations. This
        integration requires an account with Avalara.
      </Box>

      <Flex flexDirection="column" gap={6}>
        <Flex gap={1}>
          <Text {...Lato13Bold} color={GreyGrey90} lineHeight="18px">
            Automated tax calculations:
          </Text>
          <Text {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
            Calculate accurate sales tax for every invoice generated by Sequence
          </Text>
        </Flex>
        <Flex gap={1}>
          <Text {...Lato13Bold} color={GreyGrey90} lineHeight="18px">
            Account for tax rate changes:
          </Text>
          <Text {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
            Avalara automatically applies the latest tax rates and changes to
            your invoices.
          </Text>
        </Flex>
        <Flex gap={1}>
          <Text {...Lato13Bold} color={GreyGrey90} lineHeight="18px">
            Prevent tax errors:
          </Text>
          <Text {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
            Avoid manual tax calculation errors and ensure compliance with local
            tax regulations.
          </Text>
        </Flex>
        <Flex gap={1}>
          <Text {...Lato13Bold} color={GreyGrey90} lineHeight="18px">
            Quick setup:
          </Text>
          <Text {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
            Get your license key and integrate with Avalara in minutes.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
