import { Box, Flex, Spinner, useToast } from '@chakra-ui/react'
import { PhotoIcon, TrashIcon } from '@heroicons/react/16/solid'
import { ServerAsset } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Button, Toast } from '@sequencehq/core-components'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import {
  useSelectCoverImageSrc,
  useStoreCoverImageAsset
} from 'Cube/domain/subdomain/coverImage'
import { UploadAssetButton } from 'modules/Assets/UploadAssetButton'
import { FC, PropsWithChildren } from 'react'
import { useCoverImageEditorContext } from './CoverImageEditorContext'

const TRANSITION_DURATION = 150

type CoverImageEditorUploadButtonProps = {
  text?: string
}

export const CoverImageEditorUploadButton = ({
  text = 'Add cover'
}: CoverImageEditorUploadButtonProps) => {
  const storeAsset = useStoreCoverImageAsset()
  const toast = useToast()

  const { setFile, setImageSrc, prevImageSrc } = useCoverImageEditorContext()

  const handleFilePicked = (pickedFile: File) => {
    setFile(pickedFile)
    setImageSrc(fileToImageSrc(pickedFile))
  }

  const handleUploadSuccess = (serverAsset: ServerAsset) => {
    setFile(undefined)
    storeAsset(serverAsset)
  }

  const handleUploadFail = (error: string) => {
    setFile(undefined)
    setImageSrc(prevImageSrc)

    toast({
      render: () => (
        <Toast
          type="error"
          title="Upload failed"
          description={error}
          isClosable
        />
      )
    })
  }

  return (
    <ShowOnGroupHover>
      <UploadAssetButton
        leadingIcon={<PhotoIcon width={16} />}
        variant="secondary"
        onFilePicked={handleFilePicked}
        onUploadSuccess={handleUploadSuccess}
        onUploadFailure={handleUploadFail}
        accept="image/jpeg,image/png"
      >
        {text}
      </UploadAssetButton>
    </ShowOnGroupHover>
  )
}

export const CoverImageEditor: FC = () => {
  const assetSrc = useSelectCoverImageSrc()
  const storeAsset = useStoreCoverImageAsset()
  const { file, imageSrc, setImageSrc, isDisabled } =
    useCoverImageEditorContext()

  const removeCoverImage = () => {
    storeAsset(null)
    setTimeout(() => {
      setImageSrc(undefined)
    }, TRANSITION_DURATION)
  }

  const showImage = !!assetSrc || !!file

  const showControls = !!assetSrc && !file && !isDisabled

  return (
    <Box position="relative" role="group">
      <Flex
        transition={`height ${TRANSITION_DURATION}ms ease-out, opacity ${TRANSITION_DURATION}ms ease-out`}
        position="relative"
        justifyContent="center"
        alignItems="center"
        height={showImage ? '240px' : 0}
        opacity={showImage ? 1 : 0}
        backgroundImage={imageSrc ? `url(${imageSrc})` : undefined}
        backgroundSize="100% auto"
        backgroundPosition="50% 50%"
        backgroundRepeat="no-repeat"
        mt={!imageSrc ? 3 : undefined}
      >
        {!!file && (
          <Spinner color={IndigoIndigo50} width="36px" height="36px" />
        )}
        <Box opacity={showControls ? 1 : 0}>
          <ShowOnGroupHover>
            <Flex gap="4px" style={{ position: 'absolute', top: 8, right: 8 }}>
              <CoverImageEditorUploadButton text="Replace cover" />
              <Button
                variant="secondary"
                icon={<TrashIcon width={16} />}
                aria-label="remove cover image"
                onClick={removeCoverImage}
              />
            </Flex>
          </ShowOnGroupHover>
        </Box>
      </Flex>
    </Box>
  )
}

const ShowOnGroupHover: FC<PropsWithChildren> = ({ children }) => (
  <Box
    _groupHover={{ opacity: 1 }}
    opacity={0}
    transition="opacity 0.15s ease-out"
  >
    {children}
  </Box>
)

function fileToImageSrc(file: File): string {
  return URL.createObjectURL(file)
}
