import { SimpleModalUI } from '@sequencehq/core-components'
import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext'
import { useCustomerContactsContext } from 'CustomerContacts/communication/internal/CustomerContacts.domain.context'
import {
  ContactModalForm,
  CustomerContactsRoot,
  useContactModalForm
} from 'CustomerContacts/index'
import { FC } from 'react'

type CustomerContactsContentProps = {
  customerId: string
  onClose: () => void
  onSubmit: () => Promise<void>
}

export const CustomerContactsContent = (
  props: CustomerContactsContentProps
) => {
  return (
    <CustomerContextProvider customerId={props.customerId}>
      <CustomerContactsRoot customerId={props.customerId}>
        <ContactNeededContent
          onCancel={props.onClose}
          onSubmit={() => void props.onSubmit()}
        />
      </CustomerContactsRoot>
    </CustomerContextProvider>
  )
}

const ContactNeededContent: FC<{
  onCancel: () => void
  onSubmit: () => void
}> = ({ onCancel, onSubmit }) => {
  const ctx = useCustomerContactsContext()

  const { fieldsConfig, buttonSubmit } = useContactModalForm({
    contactId: 'new',
    fieldsValues: {
      ...ctx.queries.formData,
      billingPreference: 'PRIMARY'
    },
    updateFields: ctx.mutators.internal.updateForm,
    createContact: ctx.mutators.external.out.createContact,
    updateContact: ctx.mutators.external.out.updateContact
  })

  return (
    <SimpleModalUI
      data-testid="contact"
      title="Add primary billing contact"
      cancelButtonText="Cancel"
      onCancel={onCancel}
      submitButtonText="Save and continue"
      onSubmit={() => {
        buttonSubmit.onSubmit(onSubmit)
      }}
      submitDisabled={buttonSubmit.disabled}
      closeOnSubmit={false}
    >
      <ContactModalForm fieldsConfig={fieldsConfig} />
    </SimpleModalUI>
  )
}
