import { Box, Checkbox, Flex, Text } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { BillingScheduleModel } from '@sequencehq/core-models'
import {
  GreyGrey60,
  GreyGrey80,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import { MutationFormProps } from 'components/Form/types'
import Spinner from 'components/Loading/Spinner'
import {
  UpdateAndSendInvoiceStatusFormProps,
  UpdateInvoiceStatusFormMutation
} from 'components/UpdateInvoiceStatus/types'
import {
  useGetMerchantForSequenceAccountQuery,
  useLazyGetBillingScheduleBillingScheduleIdQuery,
  usePutBillingSchedulesBillingScheduleIdMutation
} from 'features/api'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { noReturn } from 'lib/noReturn'
import { FC, useEffect, useState } from 'react'
import { Form } from 'react-final-form'

const FinalizeAndSendInvoiceForm: FC<
  MutationFormProps<UpdateInvoiceStatusFormMutation> &
    UpdateAndSendInvoiceStatusFormProps
> = ({
  id,
  customerName,
  customerEmails,
  billingScheduleId,
  submitForm,
  formId
}) => {
  const flags = useFlags()
  const enableAutoIssueInvoices = flags?.enableAutoIssueInvoices

  const [autoIssue, setAutoIssue] = useState<boolean>(false)
  const [updateBillingSchedule] =
    usePutBillingSchedulesBillingScheduleIdMutation()

  const { data: merchant, isFetching: isFetchingMerchant } =
    useGetMerchantForSequenceAccountQuery()

  const [getBillingSchedule] = useLazyGetBillingScheduleBillingScheduleIdQuery()

  const [billingSchedule, setBillingSchedule] = useState<BillingScheduleModel>()

  useEffect(() => {
    if (!billingScheduleId) {
      return
    }

    getBillingSchedule({
      id: billingScheduleId
    })
      .then(res => {
        if (res.isSuccess) {
          const billingScheduleResponse = res.data.value()

          if (!billingScheduleResponse) {
            return
          }

          setBillingSchedule({
            ...billingScheduleResponse.activeVersion,
            id: billingScheduleResponse.id,
            createdAt: billingScheduleResponse.createdAt,
            customerId: billingScheduleResponse.customerId,
            sequenceAccountId: billingScheduleResponse.sequenceAccountId,
            status: billingScheduleResponse.status
          })
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [getBillingSchedule, billingScheduleId])

  const merchantValue = merchant?.value()

  if (isFetchingMerchant || !merchantValue) {
    return <Spinner></Spinner>
  }

  const showAutoInvoiceCheckbox =
    enableAutoIssueInvoices &&
    billingSchedule &&
    !billingSchedule.autoIssueInvoices &&
    merchantValue

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          })
        ]

        if (autoIssue && billingSchedule) {
          promises.push(
            updateBillingSchedule({
              billingScheduleId: billingSchedule.id,
              body: {
                ...billingSchedule,
                autoIssueInvoices: true
              }
            })
          )
        }

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Email this invoice to ${customerName} (${customerEmails.join(
              ', '
            )}). After sending you can no longer edit the invoice. To make further changes, use the Credit Note feature.`}

            {showAutoInvoiceCheckbox && (
              <Box pt="40px">
                <Checkbox
                  isChecked={autoIssue}
                  width="100%"
                  onChange={e => setAutoIssue(e.target.checked)}
                >
                  <Text color={GreyGrey80} {...Lato14ExtendedRegular}>
                    Auto-send future invoices created by this Billing Schedule
                  </Text>
                </Checkbox>
              </Box>
            )}
            {isSandboxEnv() && (
              <Box pt={5} color={GreyGrey60}>
                Note: No end-customer emails will be sent in {getEnvironment()}.
              </Box>
            )}
          </Flex>
        )
      }}
    />
  )
}

export default FinalizeAndSendInvoiceForm
