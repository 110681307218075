import { internalPriceToListPrice } from 'Products/drawers/ListPriceEditor/adapters/listPriceEditor.adapters'
import { ProductPriceContent } from 'Products/drawers/ProductPrice/components/ProductPriceContent'
import { NEW_PRODUCT_PREFIX } from 'Cube/domain/cube.constants'
import { useListPriceEditorContext } from 'Products/drawers/ListPriceEditor/useListPriceEditorContext'
import Loading from 'components/Loading/Spinner'
import { useAddProductFormContext } from 'Products/drawers/AddProduct/hooks/useAddProductFormContext'
import { Product } from 'Products/types'

export const AddProductFormReview = () => {
  const data = useListPriceEditorContext()
  const {
    data: {
      formData: { product }
    }
  } = useAddProductFormContext()
  const productId = `${NEW_PRODUCT_PREFIX}${crypto.randomUUID()}`

  return !data.derived.queries.selectedPrice ? (
    <Loading />
  ) : (
    <ProductPriceContent
      price={internalPriceToListPrice(productId)(
        data.derived.queries.selectedPrice
      )}
      product={product as Product}
      fetchMetadata={false}
    />
  )
}
