import { Inspector } from '@sequencehq/core-components'
import { stripeIntegrationConfig } from '../stripe.integration.config'
import { Flex, Img } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'
import addStripeIcon from './addStripeIcon.svg'
import addStripeIconInactive from './addStripeIconInactive.svg'
import { NotConnectedItemContent } from 'Integrations/integrationsConfig/common/NotConnectedItemContent'

const StripeNotConnected = () => (
  <Inspector.IconItem
    data-testid="stripe.inspector.item"
    tooltip="Stripe"
    icon={<Img src={addStripeIconInactive} height="30px" width="30px" />}
    activeIcon={<Img src={addStripeIcon} height="30px" width="30px" />}
    content={
      <NotConnectedItemContent
        serviceName={stripeIntegrationConfig.title}
        serviceDescription={stripeIntegrationConfig.description}
        logo={
          <Img
            src={stripeIntegrationConfig.smallLogoUrl}
            height="24px"
            width="24px"
          />
        }
        service="Stripe"
        data-testid="stripe.inspector.content.notConnected"
      />
    }
  />
)

export const StripeInspectorItem: FC<{
  alwaysShow?: boolean
  children: ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('Stripe')

  if (!integrationActive) {
    return <StripeNotConnected />
  }

  return (
    <Inspector.IconItem
      data-testid="stripe.inspector.item"
      tooltip="Stripe"
      icon={<SmallIcon url={stripeIntegrationConfig.smallLogoUrl} />}
      content={
        <Inspector.Content
          data-testid="stripe.inspector.content"
          title="Stripe"
          icon={<SmallIcon url={stripeIntegrationConfig.smallLogoUrl} />}
        >
          <Flex flexDirection="column" gap="16px">
            {props.children}
          </Flex>
        </Inspector.Content>
      }
    />
  )
}
