import { SimplePaymentPage } from 'modules/PaymentsApp/components/SimplePaymentPage'
import { AlertTriangle } from 'react-feather'

export const NotReady = () => {
  return (
    <SimplePaymentPage
      icon={AlertTriangle}
      iconColour="yellow.60"
      header="Invoice not ready yet"
      subheader={'This invoice is not ready for payment yet'}
      nextSteps={[
        'Please finalize and send the invoice to make payment available.'
      ]}
    />
  )
}
