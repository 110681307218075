import { Grid, GridItem } from '@chakra-ui/react'
import { IntegrationsContent } from './content/IntegrationsContent'
import { Modal, ModalContextProvider } from '@sequencehq/core-components'
import { useIntegrationsBrowserModal } from './useIntegrationsBrowserModal'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import { AvailableServices } from 'Integrations/domain'

type IntegrationsModalProps = {
  trigger: React.ReactNode
  defaultService?: AvailableServices
}

/**
 * Browser for the current integrations!
 *
 * Like other 'view' components, this was developed at speed and lacks some of the
 * 'swappable adapter' concepts that we want to standardise. Therefore this component
 * is currently directly dependant on the 'global' integrations context in multiple
 * places, rather than having a singular top level interface.
 *
 * The path to refactoring this module - if we ever need to - is, therefore:
 * - Introduce a top level domain/context for the modal itself, rather than directly using
 * the integrations context.
 * - Add a standard adapter that connects to that global integrations context (or API!).
 *
 * @param props
 * @returns
 */
export const IntegrationsBrowserModal = (props: IntegrationsModalProps) => {
  const hook = useIntegrationsBrowserModal()

  return (
    <ModalContextProvider>
      <Modal.Trigger>{props.trigger}</Modal.Trigger>
      <Modal width="940px" height="808px">
        <Modal.Header withBorder showCloseButton>
          Browse integrations
        </Modal.Header>
        <Grid
          height="100%"
          templateAreas={`"header" "content"`}
          templateRows="auto 1fr"
          overflow="hidden"
          borderRadius="8px"
          data-testid="integrationsBrowser"
        >
          <GridItem gridArea="content" overflow="hidden">
            {hook.loading ? (
              <SpinnerContainer />
            ) : (
              <IntegrationsContent defaultService={props.defaultService} />
            )}
          </GridItem>
        </Grid>
      </Modal>
    </ModalContextProvider>
  )
}
