import { Currency, PriceModel } from '@sequencehq/core-models'

export enum MinimumEditorMode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create'
}

export type MinimumScopeTarget = 'allUsage' | 'specific'
export type MinimumScope = {
  target: MinimumScopeTarget
  priceIds: MinimumEditorPrice['id'][]
}

export type Minimum = {
  id: string
  value: string
  name: string
  scope: MinimumScope
}

export type MinimumEditorPrice = Omit<
  PriceModel,
  'sequenceAccountId' | 'updatedAt' | 'createdAt'
>

export type MinimumEditorRootProps = {
  existingMinimum: Minimum | null
  applyToAllAvailable: boolean
  availablePrices: MinimumEditorPrice[]
  mode: MinimumEditorMode
  currency: Currency
  onClose: () => void
  onSave: (updatedMinimum: Minimum) => void
}
