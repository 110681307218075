import { INITIAL_PRICING_EDITOR_STATE } from '../pricingEditor.constants'
import { ActionHandler, LoadAction } from '../pricingEditor.domain.types'

export const load: ActionHandler<LoadAction> = () => action => {
  /**
   * When loading, we will remove any existing state, and start over. Therefore
   * we do not use the prevState here and instead use the initial value.
   *
   * Note: it may be the case in the future that we may wish to retain some
   * data, like the configuration for the editor, but for now we will reset everything
   * and look to introduce further options to the action if that is indeed the case.
   **/

  return {
    ...INITIAL_PRICING_EDITOR_STATE,
    ...action.payload,
    editor: {
      ...INITIAL_PRICING_EDITOR_STATE.editor,
      loaded: true
    }
  }
}
