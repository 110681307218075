import {
  Modal,
  ModalContextProvider,
  SimpleModalUI
} from '@sequencehq/core-components'
import {
  ContactModalForm,
  CustomerContactsRoot
} from 'modules/CustomerContacts'
import { useModalCreateRecipient } from './useModalCreateRecipient'
import { PropsWithChildren } from 'react'
import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext'
import { Flex } from '@chakra-ui/react'

type Props = {
  onClose: () => void
  hideOverlay?: boolean
}

export const ModalCreateRecipient = ({
  onClose,
  hideOverlay = false
}: Props) => {
  const { modalTitle, fieldsConfig, buttonSubmit, buttonCancel } =
    useModalCreateRecipient({
      onClose
    })

  return (
    <ModalContextProvider isOpen={true}>
      <SimpleModalUI
        title={modalTitle}
        onSubmit={buttonSubmit.onSubmit}
        submitButtonText="Add recipient"
        submitDisabled={buttonSubmit.disabled}
        cancelButtonText="Cancel"
        onCancel={buttonCancel.onCancel}
        hideOverlay={hideOverlay}
      >
        <ContactModalForm fieldsConfig={fieldsConfig} />
      </SimpleModalUI>
    </ModalContextProvider>
  )
}

export const ModalCreateRecipientWrapper = ({
  customerId,
  children
}: PropsWithChildren<{ customerId: string }>) => {
  return (
    <ModalContextProvider isOpen={true}>
      <Modal>
        <Modal.Content>
          <Flex height="220px" alignItems="center" justifyContent="center">
            <CustomerContextProvider customerId={customerId}>
              <CustomerContactsRoot customerId={customerId}>
                {children}
              </CustomerContactsRoot>
            </CustomerContextProvider>
          </Flex>
        </Modal.Content>
      </Modal>
    </ModalContextProvider>
  )
}
