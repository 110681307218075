import {
  ActionHandler,
  LoadPricingEditorAction
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { INITIAL_PRICING_EDITOR_STATE } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'

export const loadPricingEditor: ActionHandler<LoadPricingEditorAction> =
  () => action => {
    return {
      ...INITIAL_PRICING_EDITOR_STATE,
      ...action.payload,
      editor: {
        ...action.payload.editor,
        formsValid: {},
        loaded: true
      },
      initialData: {
        price: action.payload.editor.selectedPrice
          ? action.payload.data.prices[action.payload.editor.selectedPrice] ??
            null
          : null,
        product: action.payload.data.product
      }
    }
  }
