import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { useIntegrationsDomainContext } from 'Integrations/communication'
import { useMemo } from 'react'

export const useIntegrationAuthError = (
  integrationId: IntegrationServices
): boolean => {
  const integrationsContext = useIntegrationsDomainContext()

  const authFailure = useMemo(() => {
    return Boolean(
      integrationsContext.queries.activeIntegrations.find(
        int => int.id === integrationId
      )?.authFailure
    )
  }, [integrationsContext.queries.activeIntegrations, integrationId])

  return authFailure
}
