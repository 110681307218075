import { dequal } from 'dequal'
import {
  EditorMode,
  PricingEditorReducerState,
  VisibleEnabledFeatureAvailable
} from '../../pricingEditor.domain.types'

const getBreadcrumbAvailable = (
  prevState: PricingEditorReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: prevState.editor.steps.length > 0,
      enabled: true
    }
  }
}

export const getAddProductAvailable = (
  prevState: PricingEditorReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: prevState.configuration.mode === EditorMode.ADD_PRODUCT,
      enabled: true
    }
  }
}

const getReviewAvailable = (
  prevState: PricingEditorReducerState
): VisibleEnabledFeatureAvailable => {
  const priceHasChanged = !(
    dequal(prevState.data.pricingEditorData.common, {
      id: prevState.initialData.price.id,
      ...prevState.initialData.price.common
    }) &&
    dequal(
      prevState.data.pricingEditorData[
        prevState.data.pricingEditorData.common.pricingModel
      ],
      prevState.initialData.price.modelSpecific
    )
  )

  return {
    available: {
      visible: [EditorMode.CREATE, EditorMode.EDIT].includes(
        prevState.configuration.mode
      ),
      enabled: priceHasChanged
    }
  }
}

const getSaveAvailable = (
  prevState: PricingEditorReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible:
        (prevState.configuration.priceType === 'list' &&
          prevState.configuration.mode === EditorMode.REVIEW) ||
        (prevState.configuration.priceType === 'variant' &&
          prevState.configuration.mode === EditorMode.CREATE),
      enabled: prevState.editor.valid
    }
  }
}

export const getCanChangeListPrice = (
  prevState: PricingEditorReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: prevState.configuration.priceType === 'variant',
      enabled: Object.keys(prevState.data.listPrices).length > 1
    }
  }
}

export const getFormAvailable = (
  prevState: PricingEditorReducerState
): VisibleEnabledFeatureAvailable => {
  /**
   * The design of the review modes is such that list prices have an entirely separate layout for it,
   * but for variants the "review" screen is the usual form, only disabled.
   *
   * Thus, we determine that the list price form is always enabled,
   * but for variants it'll only be enabled when in a mode other than REVIEW.
   */
  return {
    available: {
      visible: true,
      enabled:
        prevState.configuration.priceType === 'list' ||
        (prevState.configuration.priceType === 'variant' &&
          prevState.initialData.mode !== EditorMode.REVIEW)
    }
  }
}

export const getAvailableFeatures = (
  prevState: PricingEditorReducerState
): PricingEditorReducerState['queries']['availableFeatures'] => {
  return {
    common: {
      breadcrumb: getBreadcrumbAvailable(prevState),
      form: getFormAvailable(prevState),
      review: getReviewAvailable(prevState),
      save: getSaveAvailable(prevState)
    },
    listPrice: {
      addProduct: getAddProductAvailable(prevState)
    },
    variantPrice: {
      canChangeListPrice: getCanChangeListPrice(prevState)
    }
  }
}
