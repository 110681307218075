import {
  InputSelectControlField,
  TextAreaField,
  TextField
} from '@sequencehq/core-components'
import { Flex } from '@chakra-ui/react'
import { useAddProductFormContext } from 'Products/drawers/AddProduct/hooks/useAddProductFormContext'
import { TaxCategoryField } from 'common/drawers/PricingEditor/view/layout/listPrice/ProductForm/TaxCategoryField'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { RevenueRecognitionMethod } from 'Products/types'

export const AddProductForm = () => {
  const { fieldsConfig } = useAddProductFormContext()
  const flags = useFlags()

  return (
    <Flex gap="24px" padding="24px" direction="column">
      <Flex direction="column" data-testid="addProduct" gap="16px">
        <TextField
          data-testid="addProduct.name"
          label="Product name"
          value={fieldsConfig.name.value}
          onChange={fieldsConfig.name.onChange}
          validationErrors={fieldsConfig.name.validationErrors}
          isDisabled={fieldsConfig.name.disabled}
          placeholder="Enter a product name"
          autoFocus
          styles={{
            wrapper: {
              marginBottom: 0
            }
          }}
        />
        <TextAreaField
          data-testid="addProduct.description"
          label="Description"
          value={fieldsConfig.label.value}
          onChange={fieldsConfig.label.onChange}
          validationErrors={fieldsConfig.label.validationErrors}
          isDisabled={fieldsConfig.label.disabled}
          placeholder="Enter a product description"
          styles={{
            wrapper: {
              marginBottom: 0
            }
          }}
        />

        <TaxCategoryField
          data-testid="addProduct.taxCategoryId"
          field={fieldsConfig.taxCategoryId}
          formChangeset={{}}
        />

        {flags.useRevRec && (
          <InputSelectControlField
            label="Revenue recognition method"
            data-testid="editProduct.revenueRecognitionMethod"
            placeholder="Select method"
            {...fieldsConfig.revenueRecognitionMethod}
            initialValue={fieldsConfig.revenueRecognitionMethod.value}
            onChange={(value: string) => {
              fieldsConfig.revenueRecognitionMethod.onChange(
                value as RevenueRecognitionMethod
              )
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}
