import { Currency } from '@sequencehq/api/utils/commonEnums'
import { enforceMinimumPrecision } from '@sequencehq/utils'
import { INITIAL_PRICING_EDITOR_STATE } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import {
  PricingEditorInterfacePrice,
  ApiPricingTier,
  AvailableStandardFrequency,
  GraduatedPrice,
  LinearPrice,
  PackagedPrice,
  PricingEditorMode,
  PricingEditorPercentageTier,
  PricingEditorPrice,
  PricingEditorReducerState,
  PricingEditorTier,
  PricingModel,
  SeatBasedGraduatedPrice,
  SeatBasedLinearPrice,
  StandardPrice,
  UsageTierType,
  VolumePrice,
  PricingEditorProduct,
  PricingEditorListPriceProp
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { LoadedPricingEditorData } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/useLoadPricingEditor/useLoadPricingEditor'
import { sanitizePercentageTiers } from 'modules/Cube/view/common/drawers/priceEditor/drawer/utils/sanitizePercentageTiers'
import { sanitizeTiers } from 'modules/Cube/view/common/drawers/priceEditor/drawer/utils/sanitizeTiers'

import { match } from 'ts-pattern'

export const internalTiersToApiTiers = ({
  tiers
}: {
  tiers: PricingEditorTier[]
}): ApiPricingTier[] => {
  return tiers.map((tier, idx) => {
    return {
      price: enforceMinimumPrecision(2)(tier.unitPrice),
      isPricePercentage: false,
      /**
       * The last tier never has an upper bound, so we will never save it.
       */
      ...(idx === tiers.length - 1 ? {} : { upperBound: tier.lastUnit }),
      fee: enforceMinimumPrecision(2)(tier.flatFee)
    }
  })
}

export const internalPercentageTiersToApiTiers = ({
  tiers,
  includePercentageLimits
}: {
  tiers: PricingEditorPercentageTier[]
  includePercentageLimits: boolean
}): ApiPricingTier[] => {
  return tiers.map((tier, idx) => {
    return {
      price: enforceMinimumPrecision(2)(tier.unitPercentage),
      isPricePercentage: true,
      /**
       * The last tier never has an upper bound, so we will never save it.
       */
      ...(idx === tiers.length - 1
        ? {}
        : { upperBound: enforceMinimumPrecision(2)(tier.lastUnit) }),
      fee: enforceMinimumPrecision(2)(tier.flatFee),
      maxPrice:
        tier.maxPrice && includePercentageLimits
          ? enforceMinimumPrecision(2)(tier.maxPrice)
          : undefined,
      minPrice:
        tier.minPrice && includePercentageLimits
          ? enforceMinimumPrecision(2)(tier.minPrice)
          : undefined
    }
  })
}

/**
 * Converts the internal price to the format expected for the PriceModel, which
 * is compatible with the Sequence API.
 *
 * One quirk to look out for is around 'numbers' - they're all saved as strings, but
 * the values themselves are explicitly float or integer, but this isn't easily conveyed
 * through types alone. The default is usually float (and the float properties can accept
 * an 'integer' string) but in some fields, like 'package_size' are integers, and
 * an API error will occur if sending a float string (e.g. '1.00').
 *
 * The best way to be certain of the target type is to look up the pricing structure proto
 * in sequence-platform-api. The files are call, for example 'PackagePricingStructure.proto'.
 * If you look at that proto, you will see the types such as BigInteger to give you confidence
 * in the transform here.
 *
 * Further validation of the data on the API can be found in the PricingStructure.kt file - such
 * as the check that VOLUME and GRADUATED tiers must have at least one unbounded tier.
 * @param productId
 * @returns
 */
export const internalPriceToApiPrice =
  (productId: PricingEditorProduct['id']) =>
  (price: PricingEditorPrice): PricingEditorInterfacePrice => {
    return {
      id: price.id,
      name: price.common.name,
      currency: price.common.currency,
      billingFrequency: price.modelSpecific.billingFrequency,
      usageCalculationPeriod:
        'usageCalculationPeriod' in price.modelSpecific
          ? price.modelSpecific.usageCalculationPeriod
          : undefined,
      billingType: price.modelSpecific.billingType,
      productId,
      listPriceId: price.common.listPriceId,
      integrationIds: Object.entries(price.common.externalIds).map(
        ([key, code]) =>
          ({
            service: key,
            id: code
          } as PricingEditorInterfacePrice['integrationIds'][0])
      ),
      customMetricParameters: match(price)
        .with({ common: { pricingModel: 'STANDARD' } }, () => [])
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as PricingEditorInterfacePrice['customMetricParameters']
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as PricingEditorInterfacePrice['customMetricParameters']
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as PricingEditorInterfacePrice['customMetricParameters']
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as PricingEditorInterfacePrice['customMetricParameters']
        )
        .with({ common: { pricingModel: 'SEAT_BASED_LINEAR' } }, () => [])
        .with({ common: { pricingModel: 'SEAT_BASED_GRADUATED' } }, () => [])
        .exhaustive(),
      structure: match(price)
        .with(
          { common: { pricingModel: 'STANDARD' } },
          ({ modelSpecific: matchedModel }) => {
            if (matchedModel.billingFrequency === 'ONE_TIME') {
              return {
                pricingType: 'ONE_TIME' as const,
                price: enforceMinimumPrecision(2)(matchedModel.price)
              }
            }

            return {
              pricingType: 'FIXED' as const,
              price: enforceMinimumPrecision(2)(matchedModel.price)
            }
          }
        )
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'LINEAR' as const,
            pricePerUnit:
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? enforceMinimumPrecision(2)(matchedModel.percentage)
                : enforceMinimumPrecision(2)(matchedModel.price),
            minPrice:
              matchedModel.minPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.minPrice
                : undefined,
            maxPrice:
              matchedModel.maxPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.maxPrice
                : undefined,
            usageMetricId: matchedModel.usageMetricId,
            isPricePercentage: matchedModel.linearPriceType === 'PERCENTAGE'
          })
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'VOLUME' as const,
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits:
                      matchedModel.includePercentageLimits
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'GRADUATED' as const,
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits: false
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId,
            usageCalculationMode: matchedModel.usageCalculationMode
          })
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'PACKAGE' as const,
            packageSize: matchedModel.packageSize,
            pricePerPackage: enforceMinimumPrecision(2)(
              matchedModel.pricePerPackage
            ),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED' as const,
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(matchedModel.minimumSeats),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatTypeId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED' as const,
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(matchedModel.minimumSeats),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatTypeId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency,
            tiers: internalTiersToApiTiers({
              tiers: matchedModel.tiers
            })
          })
        )
        .exhaustive()
    }
  }

export const apiTiersToInternalTiers = (
  apiTiers: (Omit<ApiPricingTier, 'isPricePercentage'> & {
    isPricePercentage?: boolean
  })[]
): {
  usageTierType: UsageTierType
  percentageTiers: PricingEditorPercentageTier[]
  tiers: PricingEditorTier[]
} => {
  if (!apiTiers || apiTiers.length === 0) {
    return {
      usageTierType: 'FIXED',
      percentageTiers: sanitizePercentageTiers([
        {
          id: 'percentage-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPercentage: '0',
          flatFee: '0.00',
          maxPrice: '',
          minPrice: ''
        }
      ]),
      tiers: sanitizeTiers([
        {
          id: 'fixed-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPrice: '0.00',
          flatFee: '0.00'
        }
      ])
    }
  }

  if (apiTiers[0]?.isPricePercentage) {
    return {
      usageTierType: 'PERCENTAGE',
      percentageTiers: sanitizePercentageTiers(
        apiTiers.map((apiTier, idx) => ({
          id: `tier-${idx}`,
          firstUnit:
            idx === 0
              ? '0.00'
              : (
                  parseFloat(apiTiers[idx - 1]?.upperBound ?? '0.00') + 0.01
                ).toFixed(2) ?? '0.00',
          lastUnit: apiTier.upperBound
            ? enforceMinimumPrecision(2)(apiTier.upperBound)
            : '',
          unitPrice: '0.00',
          unitPercentage: enforceMinimumPrecision(2)(apiTier.price ?? '0.00'),
          flatFee: enforceMinimumPrecision(2)(apiTier.fee ?? '0') ?? '0.00',
          maxPrice: apiTier.maxPrice
            ? enforceMinimumPrecision(2)(apiTier.maxPrice)
            : '',
          minPrice: apiTier.minPrice
            ? enforceMinimumPrecision(2)(apiTier.minPrice)
            : ''
        }))
      ),
      tiers: sanitizeTiers([
        {
          id: 'fixed-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPrice: '0.00',
          flatFee: '0.00'
        }
      ])
    }
  }

  return {
    usageTierType: 'FIXED',
    percentageTiers: sanitizePercentageTiers([
      {
        id: 'percentage-tier-1',
        firstUnit: '0',
        lastUnit: '',
        unitPercentage: '0',
        flatFee: '0.00',
        maxPrice: '',
        minPrice: ''
      }
    ]),
    tiers: sanitizeTiers(
      apiTiers.map((apiTier, idx) => ({
        id: `tier-${idx}`,
        firstUnit:
          idx === 0
            ? '0'
            : (
                parseFloat(apiTiers[idx - 1]?.upperBound ?? '0') + 1
              ).toString() ?? '0',
        lastUnit: apiTier.upperBound ?? '',
        unitPrice: enforceMinimumPrecision(2)(apiTier.price ?? '0.00'),
        flatFee: enforceMinimumPrecision(2)(apiTier.fee ?? '0') ?? '0.00'
      }))
    )
  }
}

/*
 * Ideally we should use the same function for list price and variants,
 * but the list price API is more up-to-date. When we move away from the core-models definition for variants,
 * we can use the same function for both.
 */
export const apiListPriceToInternalPrice =
  (product: PricingEditorProduct) =>
  (listPrice: PricingEditorListPriceProp): PricingEditorPrice => {
    const commonData = <PM extends PricingModel>(pricingModel: PM) => ({
      id: listPrice.id,
      common: {
        currency: listPrice.currency,
        name: listPrice.name || product.name,
        pricingModel,
        externalIds: listPrice.integrationIds.reduce(
          (acc, externalId) => ({
            ...acc,
            [externalId.service]: externalId.id
          }),
          {}
        )
      }
    })

    return match(listPrice.structure)
      .with(
        { pricingType: 'FIXED' },
        (matchedApiPriceStructure): StandardPrice => {
          return {
            ...commonData('STANDARD'),
            modelSpecific: {
              billingType: listPrice.billingType,
              price: enforceMinimumPrecision(2)(matchedApiPriceStructure.price),
              billingFrequency: listPrice.billingFrequency
            }
          }
        }
      )
      .with(
        { pricingType: 'ONE_TIME' },
        (matchedApiPriceStructure): StandardPrice => {
          return {
            ...commonData('STANDARD'),
            modelSpecific: {
              billingType: listPrice.billingType,
              price: matchedApiPriceStructure.price,
              billingFrequency: listPrice.billingFrequency
            }
          }
        }
      )
      .with(
        { pricingType: 'GRADUATED' },
        (matchedApiPriceStructure): GraduatedPrice => {
          return {
            ...commonData('GRADUATED'),
            modelSpecific: {
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              usageCalculationMode:
                matchedApiPriceStructure.usageCalculationMode,
              usageCalculationPeriod: listPrice.usageCalculationPeriod,
              ...apiTiersToInternalTiers(matchedApiPriceStructure.tiers),
              billingFrequency: listPrice.billingFrequency,
              billingType: 'IN_ARREARS',
              parameters: listPrice.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'VOLUME' },
        (matchedApiPriceStructure): VolumePrice => {
          return {
            ...commonData('VOLUME'),
            modelSpecific: {
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              ...apiTiersToInternalTiers(matchedApiPriceStructure.tiers),
              billingFrequency: listPrice.billingFrequency,
              billingType: 'IN_ARREARS',
              includePercentageLimits:
                matchedApiPriceStructure.tiers.filter(
                  tier => !!tier.maxPrice || !!tier.minPrice
                ).length > 0,
              parameters: listPrice.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'PACKAGE' },
        (matchedApiPriceStructure): PackagedPrice => {
          return {
            ...commonData('PACKAGED'),
            modelSpecific: {
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              pricePerPackage: parseFloat(
                matchedApiPriceStructure.pricePerPackage
              ).toFixed(2),
              packageSize: matchedApiPriceStructure.packageSize,
              billingFrequency: listPrice.billingFrequency,
              billingType: 'IN_ARREARS',
              parameters: listPrice.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'LINEAR' },
        (matchedApiPriceStructure): LinearPrice => {
          return {
            ...commonData('LINEAR'),
            modelSpecific: {
              price: matchedApiPriceStructure.isPricePercentage
                ? '0.00'
                : enforceMinimumPrecision(2)(
                    matchedApiPriceStructure.pricePerUnit
                  ),
              percentage: matchedApiPriceStructure.isPricePercentage
                ? matchedApiPriceStructure.pricePerUnit
                : '0',
              minPrice:
                matchedApiPriceStructure.isPricePercentage &&
                matchedApiPriceStructure.minPrice
                  ? matchedApiPriceStructure.minPrice
                  : '',
              maxPrice:
                matchedApiPriceStructure.isPricePercentage &&
                matchedApiPriceStructure.maxPrice
                  ? matchedApiPriceStructure.maxPrice
                  : '',
              linearPriceType: matchedApiPriceStructure.isPricePercentage
                ? 'PERCENTAGE'
                : 'FIXED',
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              billingFrequency: listPrice.billingFrequency,
              billingType: 'IN_ARREARS',
              parameters: listPrice.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'SEAT_BASED' },
        (
          matchedApiPriceStructure
        ): SeatBasedLinearPrice | SeatBasedGraduatedPrice => {
          // If there are tiers, then it's a graduated price
          if (
            'tiers' in listPrice.structure &&
            (listPrice.structure.tiers ?? []).length > 0
          ) {
            return {
              ...commonData('SEAT_BASED_GRADUATED'),
              modelSpecific: {
                seatTypeId: matchedApiPriceStructure.seatMetricId,
                pricePerSeat: enforceMinimumPrecision(2)(
                  matchedApiPriceStructure.pricePerSeat
                ),
                minimumSeats: (
                  matchedApiPriceStructure.contractedMinimumSeats ?? 0
                ).toFixed(0),
                prorationStrategy: matchedApiPriceStructure.prorationStrategy,
                billingFrequency: listPrice.billingFrequency,
                billingType: listPrice.billingType,
                overagesBillingFrequency:
                  matchedApiPriceStructure.overagesBillingFrequency ?? 'NEVER',
                ...apiTiersToInternalTiers(matchedApiPriceStructure.tiers ?? [])
              }
            }
          }

          return {
            ...commonData('SEAT_BASED_LINEAR'),
            modelSpecific: {
              seatTypeId: matchedApiPriceStructure.seatMetricId,
              pricePerSeat: enforceMinimumPrecision(2)(
                matchedApiPriceStructure.pricePerSeat
              ),
              minimumSeats: (
                matchedApiPriceStructure.contractedMinimumSeats ?? 0
              ).toFixed(0),
              prorationStrategy: matchedApiPriceStructure.prorationStrategy,
              billingFrequency: listPrice.billingFrequency,
              billingType: listPrice.billingType,
              overagesBillingFrequency:
                matchedApiPriceStructure.overagesBillingFrequency ?? 'NEVER'
            }
          }
        }
      )
      .exhaustive()
  }

export const apiPriceToInternalPrice =
  (product: PricingEditorProduct, listPriceId?: string) =>
  (price: PricingEditorInterfacePrice): PricingEditorPrice => {
    const commonData = <PM extends PricingModel>(pricingModel: PM) => ({
      id: price.id,
      common: {
        currency: price.currency,
        name: price.name || product.name,
        listPriceId: price.listPriceId ?? listPriceId,
        pricingModel,
        externalIds: price.integrationIds.reduce(
          (acc, externalId) => ({
            ...acc,
            [externalId.service]: externalId.id
          }),
          {}
        )
      }
    })

    return match(price.structure)
      .with(
        { pricingType: 'FIXED' },
        (matchedApiPriceStructure): StandardPrice => {
          return {
            ...commonData('STANDARD'),
            modelSpecific: {
              billingType: price.billingType,
              price: enforceMinimumPrecision(2)(matchedApiPriceStructure.price),
              billingFrequency: price.billingFrequency
            }
          }
        }
      )
      .with(
        { pricingType: 'ONE_TIME' },
        (matchedApiPriceStructure): StandardPrice => {
          return {
            ...commonData('STANDARD'),
            modelSpecific: {
              billingType: price.billingType,
              price: matchedApiPriceStructure.price,
              billingFrequency: price.billingFrequency
            }
          }
        }
      )
      .with(
        { pricingType: 'GRADUATED' },
        (matchedApiPriceStructure): GraduatedPrice => {
          return {
            ...commonData('GRADUATED'),
            modelSpecific: {
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              usageCalculationMode:
                matchedApiPriceStructure.usageCalculationMode,
              usageCalculationPeriod: price.usageCalculationPeriod,
              ...apiTiersToInternalTiers(matchedApiPriceStructure.tiers),
              billingFrequency: price.billingFrequency,
              billingType: 'IN_ARREARS',
              parameters: price.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'VOLUME' },
        (matchedApiPriceStructure): VolumePrice => {
          return {
            ...commonData('VOLUME'),
            modelSpecific: {
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              ...apiTiersToInternalTiers(matchedApiPriceStructure.tiers),
              billingFrequency: price.billingFrequency,
              billingType: 'IN_ARREARS',
              includePercentageLimits:
                matchedApiPriceStructure.tiers.filter(
                  tier => !!tier.maxPrice || !!tier.minPrice
                ).length > 0,
              parameters: price.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'PACKAGE' },
        (matchedApiPriceStructure): PackagedPrice => {
          return {
            ...commonData('PACKAGED'),
            modelSpecific: {
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              pricePerPackage: parseFloat(
                matchedApiPriceStructure.pricePerPackage
              ).toFixed(2),
              packageSize: matchedApiPriceStructure.packageSize,
              billingFrequency: price.billingFrequency,
              billingType: 'IN_ARREARS',
              parameters: price.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'LINEAR' },
        (matchedApiPriceStructure): LinearPrice => {
          return {
            ...commonData('LINEAR'),
            modelSpecific: {
              price: matchedApiPriceStructure.isPricePercentage
                ? '0.00'
                : enforceMinimumPrecision(2)(
                    matchedApiPriceStructure.pricePerUnit
                  ),
              percentage: matchedApiPriceStructure.isPricePercentage
                ? matchedApiPriceStructure.pricePerUnit
                : '0',
              minPrice:
                matchedApiPriceStructure.isPricePercentage &&
                matchedApiPriceStructure.minPrice
                  ? matchedApiPriceStructure.minPrice
                  : '',
              maxPrice:
                matchedApiPriceStructure.isPricePercentage &&
                matchedApiPriceStructure.maxPrice
                  ? matchedApiPriceStructure.maxPrice
                  : '',
              linearPriceType: matchedApiPriceStructure.isPricePercentage
                ? 'PERCENTAGE'
                : 'FIXED',
              usageMetricId: matchedApiPriceStructure.usageMetricId,
              billingFrequency: price.billingFrequency,
              billingType: 'IN_ARREARS',
              parameters: price.customMetricParameters.reduce(
                (acc, param) => ({
                  ...acc,
                  [param.parameterId]: param.value
                }),
                {}
              )
            }
          }
        }
      )
      .with(
        { pricingType: 'SEAT_BASED' },
        (
          matchedApiPriceStructure
        ): SeatBasedLinearPrice | SeatBasedGraduatedPrice => {
          // If there are tiers, then it's a graduated price
          if (
            'tiers' in matchedApiPriceStructure &&
            (matchedApiPriceStructure.tiers?.length ?? 0) > 0
          ) {
            return {
              ...commonData('SEAT_BASED_GRADUATED'),
              modelSpecific: {
                seatTypeId: matchedApiPriceStructure.seatMetricId,
                pricePerSeat: enforceMinimumPrecision(2)(
                  matchedApiPriceStructure.pricePerSeat
                ),
                minimumSeats: (
                  matchedApiPriceStructure.contractedMinimumSeats ?? 0
                ).toFixed(0),
                prorationStrategy: matchedApiPriceStructure.prorationStrategy,
                billingFrequency: price.billingFrequency,
                billingType: price.billingType,
                overagesBillingFrequency:
                  matchedApiPriceStructure.overagesBillingFrequency ?? 'NEVER',
                ...apiTiersToInternalTiers(matchedApiPriceStructure.tiers ?? [])
              }
            }
          }

          return {
            ...commonData('SEAT_BASED_LINEAR'),
            modelSpecific: {
              seatTypeId: matchedApiPriceStructure.seatMetricId,
              pricePerSeat: enforceMinimumPrecision(2)(
                matchedApiPriceStructure.pricePerSeat
              ),
              minimumSeats: (
                matchedApiPriceStructure.contractedMinimumSeats ?? 0
              ).toFixed(0),
              prorationStrategy: matchedApiPriceStructure.prorationStrategy,
              billingFrequency: price.billingFrequency,
              billingType: price.billingType,
              overagesBillingFrequency:
                matchedApiPriceStructure.overagesBillingFrequency ?? 'NEVER'
            }
          }
        }
      )
      .exhaustive()
  }

export const pricingEditorLoaderToEditor =
  (ctx: { enableListPrices: boolean }) =>
  (args: {
    loadedData: LoadedPricingEditorData
    mode?: PricingEditorMode
    priceId?: PricingEditorPrice['id']
    availableFrequencies: AvailableStandardFrequency[]
    currency?: Currency
    availableFields?: string[]
    disabledFields?: string[]
    defaultPricingType: PricingModel
    listPriceId?: string
  }): Pick<PricingEditorReducerState, 'data' | 'configuration' | 'editor'> => {
    const prices = arrayToIdKeyedObject(
      args.loadedData.prices.map(
        apiPriceToInternalPrice(args.loadedData.product, args.listPriceId)
      )
    )
    const listPrices = arrayToIdKeyedObject(
      args.loadedData.listPrices.map(
        apiListPriceToInternalPrice(args.loadedData.product)
      )
    )

    return {
      data: {
        prices,
        listPrices,
        metrics: arrayToIdKeyedObject(args.loadedData.metrics),
        product: args.loadedData.product,
        formData: INITIAL_PRICING_EDITOR_STATE.data.formData,
        ledgerIntegrations: arrayToIdKeyedObject(
          args.loadedData.ledgerIntegrations.map(
            ({ key, value, defaultLedgerAccount }) => ({
              id: key,
              defaultLedgerAccount,
              options: value
            })
          )
        ),
        defaultPricingType: args.defaultPricingType
      },
      configuration: {
        currency: args.currency,
        enableListPrices: ctx.enableListPrices,
        availableCurrencies: args.loadedData.availableCurrencies,
        availableStandardFrequencies: args.availableFrequencies,
        mode: args.mode ?? PricingEditorMode.CREATE,
        availableFields: args.availableFields,
        disabledFields: args.disabledFields,
        listPriceId: args.listPriceId
      },
      editor: {
        loaded: true,
        selectedPrice: args.priceId ?? null,
        showValidationErrors: false,
        formsValid: {}
      }
    }
  }

export const arrayToIdKeyedObject = <T extends { id: string }>(
  items: T[]
): Record<T['id'], T> =>
  items?.reduce(
    (acc: Record<T['id'], T>, item) => ({ ...acc, [item.id]: item }),
    {} as Record<T['id'], T>
  ) ?? {}
