import { InvoiceModel, InvoiceUsageItemGroup } from '@sequencehq/core-models'
import { useLazyGetInvoicesByInvoiceIdUsageItemGroupsQuery } from 'features/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'

export const MOCK_SUB_ACCOUNT_USAGE_DATA_2: InvoiceUsageItemGroup[] = [
  {
    id: '1aaeac35-cdc3-4adb-a741-5fa4816619ad',
    invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
    title: 'Metric 1',
    items: [
      {
        id: 'cf8ad70f-f79a-4803-a41f-9a0b436c865a',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Eldest Child with a super long name that should wrap',
        value: '150',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      },
      {
        id: 'f00fcb1a-76ff-44d9-b8c3-b444e554cc9c',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Youngest Child',
        value: '300',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      }
    ]
  },
  {
    id: '1aaeac35-cdc3-4adb-a741-5fa4816619ad',
    invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
    title: 'Metric 2',
    items: [
      {
        id: 'cf8ad70f-f79a-4803-a41f-9a0b436c865a',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Eldest Child',
        value: '250',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      },
      {
        id: 'f00fcb1a-76ff-44d9-b8c3-b444e554cc9c',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Youngest Child',
        value: '350',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      }
    ]
  },
  {
    id: '1aaeac35-cdc3-4adb-a741-5fa4816619ad',
    invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
    title: 'Metric 3',
    items: [
      {
        id: 'cf8ad70f-f79a-4803-a41f-9a0b436c865a',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Eldest Child',
        value: '50',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298626',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      },
      {
        id: 'f00fcb1a-76ff-44d9-b8c3-b444e554cc9c',
        invoiceId: '6cedbb5e-4f77-4217-b8d4-020e7e4d33c9',
        title: 'Youngest Child',
        value: '450',
        groupedValues: [],
        lineItemGroupId: 'f4457a3c-a0f2-44ea-9c8d-548fa1298628',
        usageItemGroupId: '1aaeac35-cdc3-4adb-a741-5fa4816619ad'
      }
    ]
  }
]

type UseLoadSubAccountUsage = (props: {
  invoiceId?: InvoiceModel['id']
  async: boolean
}) => {
  loading: boolean
  error?: LoaderError
  data?: {
    subAccountUsageBreakdown: InvoiceUsageItemGroup[]
  }
  loadSubAccountUsage: (args: {
    invoiceId: string
  }) => Promise<InvoiceUsageItemGroup[]>
}

export const useLoadSubAccountUsage: UseLoadSubAccountUsage = props => {
  const [subAccountUsage, setSubAccountUsage] = useState<
    InvoiceUsageItemGroup[] | null
  >(null)
  const [fetchSubAccountUsage, { isLoading, error }] =
    useLazyGetInvoicesByInvoiceIdUsageItemGroupsQuery()

  const loadSubAccountUsage = useCallback(
    async (args: { invoiceId: string }): Promise<InvoiceUsageItemGroup[]> => {
      const response = await fetchSubAccountUsage({
        invoiceId: args.invoiceId
      })
      const subAccountUsageResponse = response.data?.value()?.items
      if (!subAccountUsageResponse) {
        return []
      }

      return subAccountUsageResponse as InvoiceUsageItemGroup[]
    },
    [fetchSubAccountUsage]
  )

  useEffect(() => {
    /**
     * We only want to perform this auto-fetch if the async property is set to false
     */
    if (props.async || !props.invoiceId) {
      return
    }

    loadSubAccountUsage({ invoiceId: props.invoiceId })
      .then(res => {
        setSubAccountUsage(res)
      })
      .catch(e => Sentry.captureException(e))
  }, [loadSubAccountUsage, props.invoiceId, props.async])

  const data = useMemo(() => {
    if (!subAccountUsage) {
      return
    }

    return {
      subAccountUsageBreakdown: subAccountUsage
    }
  }, [subAccountUsage])

  return {
    loading: isLoading,
    error,
    data,
    loadSubAccountUsage
  }
}
