interface RedirectIconProps {
  width?: number
  height?: number
  color?: string
}
export const RedirectIcon = ({
  width = 16,
  height = 16,
  color = '#344054'
}: RedirectIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4001 4.4001C3.06873 4.4001 2.8001 4.66873 2.8001 5.0001V11.8001C2.8001 12.1315 3.06873 12.4001 3.4001 12.4001H10.2001C10.5315 12.4001 10.8001 12.1315 10.8001 11.8001V8.6001C10.8001 8.26873 11.0687 8.0001 11.4001 8.0001C11.7315 8.0001 12.0001 8.26873 12.0001 8.6001V11.8001C12.0001 12.7942 11.1942 13.6001 10.2001 13.6001H3.4001C2.40599 13.6001 1.6001 12.7942 1.6001 11.8001V5.0001C1.6001 4.00599 2.40598 3.2001 3.4001 3.2001H7.4001C7.73147 3.2001 8.0001 3.46873 8.0001 3.8001C8.0001 4.13147 7.73147 4.4001 7.4001 4.4001H3.4001Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95518 10.2026C5.1775 10.4484 5.55692 10.4673 5.80265 10.245L13.2001 3.55209V5.8001C13.2001 6.13147 13.4687 6.4001 13.8001 6.4001C14.1315 6.4001 14.4001 6.13147 14.4001 5.8001V2.2001C14.4001 1.86873 14.1315 1.6001 13.8001 1.6001H10.2001C9.86873 1.6001 9.6001 1.86873 9.6001 2.2001C9.6001 2.53147 9.86873 2.8001 10.2001 2.8001H12.2426L4.99755 9.35518C4.75183 9.5775 4.73285 9.95692 4.95518 10.2026Z"
        fill={color}
      />
    </svg>
  )
}
