import deepmerge from 'deepmerge'
import {
  ActionHandler,
  UpdateEditorAction
} from '../pricingEditor.domain.types'

export const updateEditor: ActionHandler<UpdateEditorAction> =
  prevState => action => {
    const newEditorData = deepmerge(prevState.editor, action.payload)

    return {
      ...prevState,
      editor: newEditorData
    }
  }
