import { Text } from '@chakra-ui/react'
import {
  GreyGrey80,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'

import { useQuoteByline } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/QuoteByline/useQuoteByline'

export const QuoteByline = () => {
  const { createdBy, createdAt } = useQuoteByline()

  if (!createdBy && !createdAt) {
    return null
  }

  return (
    <Text
      {...Lato14Regular}
      marginBottom="16px"
      paddingX="18px"
      color={GreyGrey80}
    >
      Created
      {createdBy && (
        <>
          {' '}
          by{' '}
          <Text as="span" {...Lato14Bold} color="inherit">
            {createdBy}
          </Text>
        </>
      )}
      {createdAt && ` on ${createdAt}`}
    </Text>
  )
}
