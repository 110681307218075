import { useModalContext } from '@sequencehq/core-components'
import { useMemo } from 'react'

type UseUnlinkSalesforceOpportunityModal = (props: {
  onConfirm: () => void
}) => {
  submit: {
    onClick: () => void
  }
}

/**
 * This hook powers the unlink customer modal. The modal itself is designed to
 * be used in any situation, and therefore packs its own data loading capabilities.
 * As of the point of implementation, we expect to be using this modal in
 * the context of the 'Inspector' present in Quote and Customer screens as well
 * as a kebab menu item in the customer list.
 *
 * @param props
 * @returns
 */
export const useUnlinkSalesforceOpportunityModal: UseUnlinkSalesforceOpportunityModal =
  props => {
    const modalContext = useModalContext()

    const submit = useMemo(() => {
      return {
        onClick: () => {
          props.onConfirm?.()
          modalContext.setIsOpen(false)
        }
      }
    }, [modalContext, props])

    return {
      submit
    }
  }
