import { ReactNode } from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { Lato13Regular, GreyGrey60 } from '@sequencehq/design-tokens'

type Props = {
  label: string
  control: ReactNode
}

export const SidebarField = ({ label, control }: Props) => (
  <Flex paddingY="6px" gap="16px" alignItems="center">
    <Text {...Lato13Regular} color={GreyGrey60} width="81px" flexShrink="0">
      {label}
    </Text>
    {control}
  </Flex>
)
