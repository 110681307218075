import { useMinimumEditorDomainContext } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.domain.context'
import { MinimumEditorMode } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'
import { useMemo, useState } from 'react'
import { match } from 'ts-pattern'

export const useMinimumEditorContent = () => {
  const [submitting, setSubmitting] = useState(false)
  const minimumEditorContext = useMinimumEditorDomainContext()

  const { title, saveText, closeText } = useMemo(() => {
    return match(minimumEditorContext.queries.editor.mode)
      .with(MinimumEditorMode.CREATE, () => ({
        title: 'Add minimum',
        saveText: 'Add minimum',
        closeText: 'Cancel'
      }))
      .with(MinimumEditorMode.VIEW, () => ({
        title: 'View minimum',
        saveText: 'Update minimum',
        closeText: 'Cancel'
      }))
      .with(MinimumEditorMode.EDIT, () => ({
        title: 'Edit minimum',
        saveText: 'Update minimum',
        closeText: 'Cancel'
      }))
      .exhaustive()
  }, [minimumEditorContext.queries.editor.mode])

  const blockerEnabled = useMemo(() => {
    return minimumEditorContext.queries.editor.changesMade
  }, [minimumEditorContext.queries.editor.changesMade])

  const saveConfig = useMemo(() => {
    return {
      text: saveText,
      handler: async () => {
        setSubmitting(true)
        await new Promise(resolve => setTimeout(resolve, 0))
        minimumEditorContext.mutators.save()
        setSubmitting(false)
      },
      disabled:
        (!minimumEditorContext.queries.editor.canSave &&
          minimumEditorContext.queries.editor.mode !==
            MinimumEditorMode.VIEW) ||
        submitting,
      hidden:
        minimumEditorContext.queries.editor.mode === MinimumEditorMode.VIEW
    }
  }, [
    minimumEditorContext.queries.editor.canSave,
    minimumEditorContext.queries.editor.mode,
    minimumEditorContext.mutators,
    saveText,
    submitting
  ])

  const closeConfig = useMemo(() => {
    return {
      text: closeText,
      handler: () => {
        minimumEditorContext.mutators.close()
      }
    }
  }, [closeText, minimumEditorContext.mutators])

  return {
    title,
    save: saveConfig,
    close: closeConfig,
    submitting,
    blockerEnabled
  }
}
