import { SimplePaymentPage } from 'modules/PaymentsApp/components/SimplePaymentPage'
import { AlertTriangle } from 'react-feather'

export const NotFound = () => {
  return (
    <SimplePaymentPage
      icon={AlertTriangle}
      iconColour="yellow.60"
      header="Invoice not found"
      subheader={
        'The invoice you are looking for does not exist or is outdated.'
      }
      nextSteps={[
        'Please verify you have entered the correct payment link or contact the issuer for further assistance.'
      ]}
    />
  )
}
