import { Currency, currencyToName } from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import { SelectField } from 'components/FormFields'
import { FC } from 'react'

type CurrencyInputProps = {
  disabled?: boolean
  width?: string
  availableCurrencies: Currency[]
}

export const CurrencyInput: FC<CurrencyInputProps> = ({
  disabled,
  width,
  availableCurrencies
}) => {
  return (
    <SelectField
      fieldName="currency"
      fieldLabel="Currency"
      options={availableCurrencies.map(ccy => ({
        value: ccy,
        label: `${ccy} - ${currencyToName[ccy]}`
      }))}
      validate={required}
      disabled={disabled}
      width={width}
    />
  )
}
