import { Currency } from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import { defaultAvailableFrequenciesOptions } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/constants'
import { FormFields, useForm } from '@sequencehq/utils'
import {
  UsageParameterDataModel,
  useUsageMetricParameterFieldConfig
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/useUsageMetricParameterFieldConfig'
import {
  PricingEditorFormData,
  UsageTierType
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import {
  percentageTiersValidator,
  tiersValidator
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/utils/validators/tiers.validators'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

type VolumePriceFields = PricingEditorFormData['VOLUME']
type UseVolumePriceForm = (props?: { formDisabled?: boolean }) => {
  fieldsConfig: Omit<
    FormFields<VolumePriceFields>,
    'usageMetricId' | 'billingFrequency'
  > & {
    billingFrequency: FormFields<VolumePriceFields>['billingFrequency'] & {
      hidden: boolean
    }
    usageMetricId: FormFields<VolumePriceFields>['usageMetricId'] & {
      hidden: boolean
      onAddNew: () => void
    }
  }
  formData: VolumePriceFields
  currency: Currency
  fieldsAreHidden: boolean
}

const availableUsageTierTypes: { label: string; value: UsageTierType }[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const useVolumePriceForm: UseVolumePriceForm = (
  { formDisabled } = { formDisabled: false }
) => {
  const navigate = useNavigate()
  const pricingEditorContext = usePricingEditorContext()

  /**
   * Define options for the select fields
   */
  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return pricingEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [pricingEditorContext.configuration.availableStandardFrequencies])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(pricingEditorContext.data.metrics).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [pricingEditorContext.data.metrics])

  /**
   * Initialise the form management
   */
  const checkIfVolumeFieldDisabledByDomain = useCallback(
    ({
      property
    }: {
      property: keyof (VolumePriceFields & UsageParameterDataModel)
    }) => pricingEditorContext.functions.fieldIsDisabled(`VOLUME.${property}`),
    [pricingEditorContext]
  )

  const parameterFields = useUsageMetricParameterFieldConfig<VolumePriceFields>(
    pricingEditorContext.data.formData.VOLUME.usageMetricId,
    checkIfVolumeFieldDisabledByDomain
  )

  const { fields, queries } = useForm<
    Omit<VolumePriceFields, 'parameters'> & UsageParameterDataModel
  >({
    value: pricingEditorContext.data.formData.VOLUME,
    disabled: formDisabled,
    fieldConfiguration: [
      {
        property: 'billingFrequency',
        disabled: checkIfVolumeFieldDisabledByDomain,
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'usageMetricId',
        disabled: checkIfVolumeFieldDisabledByDomain,
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'tiers',
        disabled: ctx =>
          checkIfVolumeFieldDisabledByDomain(ctx) ||
          ctx.formData.usageTierType !== 'FIXED',
        validation: [required, tiersValidator]
      },
      {
        property: 'percentageTiers',
        disabled: ctx =>
          checkIfVolumeFieldDisabledByDomain(ctx) ||
          ctx.formData.usageTierType !== 'PERCENTAGE',
        validation: [required, percentageTiersValidator]
      },
      {
        property: 'usageTierType',
        disabled: checkIfVolumeFieldDisabledByDomain,
        validation: [required],
        options: availableUsageTierTypes
      },
      {
        property: 'includePercentageLimits'
      },
      ...parameterFields.fields
    ],
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          VOLUME: isValid
        }
      })
    },
    onChange: newData => {
      pricingEditorContext.functions.updateFormData({
        VOLUME: newData
      })
    }
  })

  /**
   * Enhance the base field config with some extra bits and pieces
   */
  const processedFieldsConfig = useMemo(() => {
    return {
      ...fields,
      billingFrequency: {
        ...fields.billingFrequency,
        hidden:
          pricingEditorContext.configuration.enableListPrices &&
          !pricingEditorContext.derived.queries.availableFeatures
            .showAllPriceFields
      },
      usageMetricId: {
        ...fields.usageMetricId,
        hidden:
          pricingEditorContext.configuration.enableListPrices &&
          !pricingEditorContext.derived.queries.availableFeatures
            .showAllPriceFields,
        onAddNew: () => navigate('./metrics/new')
      }
    }
  }, [
    fields,
    pricingEditorContext.configuration.enableListPrices,
    navigate,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  const fieldsAreHidden = useMemo(() => {
    return Boolean(
      pricingEditorContext.configuration.enableListPrices &&
        !pricingEditorContext.derived.queries.availableFeatures
          .showAllPriceFields
    )
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  return {
    fieldsConfig: processedFieldsConfig,
    formData: queries.formData,
    currency: pricingEditorContext.data.formData.common.currency,
    fieldsAreHidden
  }
}
