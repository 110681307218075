import { greaterThan, required } from '@sequencehq/validation'
import { FormFields, useForm } from '@sequencehq/utils'
import deepmerge from 'deepmerge'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ListPriceEditorFormData } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { defaultAvailableFrequenciesOptions } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/constants'
import {
  UsageParameterDataModel,
  useUsageMetricParameterFieldConfig
} from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/utils/useUsageMetricParameterFieldConfig'

type PackagedPriceFields = ListPriceEditorFormData['PACKAGED']
type UsePackagedPriceForm = () => {
  fieldsConfig: Omit<FormFields<PackagedPriceFields>, 'usageMetricId'> & {
    usageMetricId: FormFields<PackagedPriceFields>['usageMetricId'] & {
      onAddNew: () => void
    }
  }
  formData: PackagedPriceFields
  currency: Currency
}

export const usePackagedPriceForm: UsePackagedPriceForm = () => {
  const listPriceEditorContext = useListPriceEditorContext()
  const navigate = useNavigate()

  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return listPriceEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [listPriceEditorContext.configuration.availableStandardFrequencies])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(listPriceEditorContext.data.metrics).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [listPriceEditorContext.data.metrics])

  /**
   * Initialise the form management
   */
  const checkIfPackagedFieldDisabledByDomain = useCallback(
    ({
      property
    }: {
      property: keyof (PackagedPriceFields & UsageParameterDataModel)
    }) =>
      listPriceEditorContext.functions.fieldIsDisabled(`PACKAGED.${property}`),
    [listPriceEditorContext]
  )

  const parameterFields =
    useUsageMetricParameterFieldConfig<PackagedPriceFields>(
      listPriceEditorContext.data.formData.PACKAGED.usageMetricId,
      checkIfPackagedFieldDisabledByDomain
    )

  const { fields, queries } = useForm<
    Omit<PackagedPriceFields, 'parameters'> & UsageParameterDataModel
  >({
    value: listPriceEditorContext.data.formData.PACKAGED,
    showValidationErrors: listPriceEditorContext.editor.showValidationErrors,
    fieldConfiguration: [
      {
        property: 'usageMetricId',
        disabled: checkIfPackagedFieldDisabledByDomain,
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'billingFrequency',
        disabled: checkIfPackagedFieldDisabledByDomain,
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'packageSize',
        disabled: checkIfPackagedFieldDisabledByDomain,
        validation: [required, greaterThan(0, "Package size can't be zero")]
      },
      {
        property: 'pricePerPackage',
        disabled: checkIfPackagedFieldDisabledByDomain,
        validation: [
          required,
          greaterThan(0, "Price per package can't be zero")
        ]
      },
      ...parameterFields.fields
    ],
    onChange: newData => {
      listPriceEditorContext.functions.updateFormData({
        PACKAGED: newData
      })
    },
    onValidationStateChange: isValid => {
      listPriceEditorContext.functions.updateEditor({
        formsValid: {
          PACKAGED: isValid
        }
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return deepmerge(
      fields,
      {
        usageMetricId: {
          onAddNew: () => navigate('./metrics/new')
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    )
  }, [fields, navigate])

  return {
    fieldsConfig: enhancedFields,
    currency: listPriceEditorContext.data.formData.common.currency,
    formData: queries.formData
  }
}
