import { Grid, GridItem } from '@chakra-ui/react'
import { JournalReportsTable } from './table/JournalReportsTable'
import { RevenueOverviewGraphs } from './graphs/RevenueOverviewGraphs'
import { ErrorBoundary } from '@sentry/react'
import PageError from 'components/ErrorPages/PageError'

export const JournalReportsContent = () => {
  return (
    <Grid height="100%" gridTemplateRows="auto 1fr">
      <GridItem minHeight="318px">
        <ErrorBoundary fallback={<PageError />}>
          <RevenueOverviewGraphs />
        </ErrorBoundary>
      </GridItem>
      <GridItem overflow="auto">
        <JournalReportsTable />
      </GridItem>
    </Grid>
  )
}
