import { toBillingFrequencyLabel } from '@sequencehq/utils'
import {
  AvailableStandardFrequency,
  BillingType,
  PricingModel
} from 'common/drawers/PricingEditor/domain'
import { PRICING_MODEL_OPTIONS } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'

const pricingModelToLabel = (pricingModel: PricingModel) => {
  return (
    PRICING_MODEL_OPTIONS.find(option => option.value === pricingModel)
      ?.label ?? 'Not selected'
  )
}

export const commonPriceDetails = (args: {
  taxCategoryName?: string
  pricingModel: PricingModel
  billingFrequency: AvailableStandardFrequency
  billingType: BillingType
}) => {
  return [
    {
      label: 'Pricing model',
      value: pricingModelToLabel(args.pricingModel)
    },
    {
      label: 'Billing frequency',
      value: `${toBillingFrequencyLabel(args.billingFrequency)} / ${
        args.billingType === 'IN_ADVANCE' ? 'In advance' : 'In arrears'
      }`
    },
    ...(args.taxCategoryName
      ? [
          {
            label: 'Tax category',
            value: args.taxCategoryName
          }
        ]
      : [])
  ]
}
