import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { useMutation } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export const useCreateAvalaraMapping = () => {
  const notifications = useNotifications()

  const createMappingMutator = useMutation(
    dashboardv99990101Client.postTaxCategoryAvalaraMapping
  )

  return async ({
    taxCategoryId,
    avalaraTaxCode
  }: {
    taxCategoryId: string
    avalaraTaxCode: string
  }) => {
    await createMappingMutator.mutateAsync(
      {
        taxCategoryId,
        avalaraTaxCode
      },
      {
        onSuccess: () => {
          notifications.displayNotification(
            'Avalara tax code linked to category',
            {
              type: 'success'
            }
          )
        },
        onError: () => {
          notifications.displayNotification(
            'Failed to link Avalara tax code to category',
            {
              type: 'error'
            }
          )
        }
      }
    )
  }
}
