import { Box } from '@chakra-ui/react'
import {
  BillingScheduleModel,
  LineItemGroupCreditNoteModel,
  LineItemCreditNoteModel,
  PlanModel,
  CreditNoteModel,
  LineItemGroupModel,
  LineItemModel,
  InvoiceMerchantDetailsModel,
  InvoiceModel,
  CustomerModel
} from '@sequencehq/core-models'
import CreditNote from 'components/CreditNote/CreditNote'
import {
  useGetCreditNotesByIdLineItemGroupsAllQuery,
  useGetCreditNotesByIdLineItemsAllQuery,
  useGetCreditNotesByIdQuery,
  useGetMerchantForSequenceAccountQuery,
  useLazyGetBillingScheduleBillingScheduleIdQuery,
  useLazyGetApiPlansPlanIdQuery,
  usePostCreditNotesTotalsMutation,
  useLazyGetInvoicesByIdLineItemGroupsAllQuery,
  useLazyGetInvoicesByIdLineItemsAllQuery,
  useLazyGetInvoicesByIdQuery,
  useLazyGetInvoiceMerchantDetailsQuery,
  useLazyGetCustomersByIdQuery
} from 'features/api'
import * as Sentry from '@sentry/react'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isSuccessResponse } from 'components/Form'
import SpinnerContainer from 'components/Loading/SpinnerContainer'

interface CreditNoteWrapperProps {
  creditNoteId: string
}

const CreditNoteWrapper: FC<CreditNoteWrapperProps> = ({ creditNoteId }) => {
  const { data: creditNoteResponse, isLoading: isLoadingCreditNote } =
    useGetCreditNotesByIdQuery({
      id: creditNoteId
    })

  const [creditNote, setCreditNote] = useState<CreditNoteModel>()

  const [getCreditNoteTotals] = usePostCreditNotesTotalsMutation()

  const { data: lineItemsResponseData, isLoading: isLoadingLineItems } =
    useGetCreditNotesByIdLineItemsAllQuery({ id: creditNoteId })

  const [lineItems, setLineItems] = useState<LineItemCreditNoteModel[]>([])

  const {
    data: lineItemGroupsResponseData,
    isLoading: isLoadingLineItemGroups
  } = useGetCreditNotesByIdLineItemGroupsAllQuery({ id: creditNoteId })

  const [lineItemGroups, setLineItemGroups] = useState<
    LineItemGroupCreditNoteModel[]
  >([])

  const [getBillingSchedule] = useLazyGetBillingScheduleBillingScheduleIdQuery()

  const [getPlan] = useLazyGetApiPlansPlanIdQuery()

  const [getCustomer] = useLazyGetCustomersByIdQuery()

  const [billingSchedule, setBillingSchedule] = useState<
    BillingScheduleModel | undefined
  >(undefined)

  const [plan, setPlan] = useState<PlanModel | undefined>(undefined)

  const [customer, setCustomer] = useState<CustomerModel | undefined>(undefined)

  const { data: merchantData, isLoading: isLoadingMerchant } =
    useGetMerchantForSequenceAccountQuery()

  const [getInvoice] = useLazyGetInvoicesByIdQuery()
  const [invoice, setInvoice] = useState<InvoiceModel | undefined>(undefined)

  const [getInvoiceLineItems] = useLazyGetInvoicesByIdLineItemsAllQuery()
  const [invoiceLineItems, setInvoiceLineItems] = useState<
    LineItemModel[] | undefined
  >(undefined)

  const [getInvoiceLineItemGroups] =
    useLazyGetInvoicesByIdLineItemGroupsAllQuery()
  const [invoiceLineItemGroups, setInvoiceLineItemGroups] = useState<
    LineItemGroupModel[]
  >([])

  const [getMerchantDetails] = useLazyGetInvoiceMerchantDetailsQuery()
  const [merchantDetails, setMerchantDetails] = useState<
    InvoiceMerchantDetailsModel | undefined
  >(undefined)

  useEffect(() => {
    if (!creditNote?.billingScheduleId) {
      return
    }
    getBillingSchedule({
      id: creditNote.billingScheduleId
    })
      .then(res => {
        if (res.isSuccess) {
          const billingScheduleResponse = res.data.value()

          if (!billingScheduleResponse) {
            return
          }

          setBillingSchedule({
            ...billingScheduleResponse.activeVersion,
            id: billingScheduleResponse.id,
            createdAt: billingScheduleResponse.createdAt,
            customerId: billingScheduleResponse.customerId,
            sequenceAccountId: billingScheduleResponse.sequenceAccountId,
            status: billingScheduleResponse.status
          })
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [getBillingSchedule, creditNote?.billingScheduleId])

  useEffect(() => {
    if (!creditNote?.customerId) {
      return
    }
    getCustomer({
      id: creditNote.customerId
    })
      .then(res => {
        if (res.isSuccess) {
          setCustomer(res.data.value())
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [getCustomer, creditNote?.customerId])

  useEffect(() => {
    if (!billingSchedule || billingSchedule.type !== 'PLAN') {
      return
    }

    getPlan({
      planId: billingSchedule.planId
    })
      .then(res => {
        if (res.isSuccess) {
          setPlan(res.data.value())
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [getPlan, billingSchedule])

  useEffect(() => {
    if (!creditNote?.currency || !creditNote.customerBillingAddress) {
      return
    }

    getMerchantDetails({
      currency: creditNote.currency,
      country: creditNote.customerBillingAddress.country,
      state: creditNote.customerBillingAddress.state
    })
      .then(res => {
        if (res.isSuccess) {
          setMerchantDetails(res.data.value())
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [
    getMerchantDetails,
    creditNote?.currency,
    creditNote?.customerBillingAddress
  ])

  useEffect(() => {
    if (!creditNote?.invoiceId) {
      return
    }
    getInvoice({
      id: creditNote.invoiceId
    })
      .then(res => {
        if (res.isSuccess) {
          setInvoice(res.data.value())
        }
      })
      .catch(e => Sentry.captureException(e))

    getInvoiceLineItems({
      id: creditNote.invoiceId
    })
      .then(res => {
        if (res.isSuccess) {
          setInvoiceLineItems(res.data?.value()?.items ?? [])
        }
      })
      .catch(e => Sentry.captureException(e))

    getInvoiceLineItemGroups({
      id: creditNote.invoiceId
    })
      .then(res => {
        if (res.isSuccess) {
          setInvoiceLineItemGroups(res.data.value()?.items ?? [])
        }
      })
      .catch(e => Sentry.captureException(e))
  }, [
    getInvoiceLineItems,
    getInvoiceLineItemGroups,
    getInvoice,
    creditNote?.invoiceId
  ])

  useEffect(
    () => {
      if (!creditNote) {
        return
      }

      getCreditNoteTotals({
        calculateCreditNoteTotalsEndpointCalculateCreditNoteTotalsRequest: {
          lineItems
        }
      })
        .then(res => {
          if (!isSuccessResponse(res)) {
            return
          }

          const loadedData = res.data.value()

          if (!loadedData) {
            return
          }

          setCreditNote(cn =>
            cn
              ? {
                  ...cn,
                  netTotal: loadedData.total.netTotal,
                  totalTax: loadedData.total.totalTax,
                  grossTotal: loadedData.total.grossTotal
                }
              : cn
          )

          setLineItemGroups(exitingGroups =>
            exitingGroups.map(existingGroup => {
              const matchedGroup = loadedData.lineItemGroupTotals.find(
                group => group.id === existingGroup.id
              )
              return matchedGroup
                ? {
                    ...existingGroup,
                    netTotal: matchedGroup.total.netTotal,
                    totalTax: matchedGroup.total.totalTax,
                    grossTotal: matchedGroup.total.grossTotal
                  }
                : {
                    ...existingGroup,
                    netTotal: '0',
                    totalTax: '0',
                    grossTotal: '0'
                  }
            })
          )
        })
        .catch(e => Sentry.captureException(e))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(lineItems)]
  )

  useEffect(
    () => {
      setLineItems(lineItemsResponseData?.value()?.items ?? [])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(lineItemsResponseData?.value())]
  )

  useEffect(
    () => setLineItemGroups(lineItemGroupsResponseData?.value()?.items ?? []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(lineItemGroupsResponseData?.value())]
  )

  useEffect(
    () => {
      setCreditNote(creditNoteResponse?.value())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(creditNoteResponse?.value())]
  )

  function cancelChanges() {
    setCreditNote(creditNoteResponse?.value())
    setLineItems(lineItemsResponseData?.value()?.items ?? [])
    setLineItemGroups(lineItemGroupsResponseData?.value()?.items ?? [])
  }

  const merchant = merchantData?.value()

  if (
    isLoadingCreditNote ||
    isLoadingLineItems ||
    isLoadingLineItemGroups ||
    isLoadingMerchant ||
    !lineItemsResponseData?.value() ||
    !creditNote ||
    !merchant ||
    !merchantDetails ||
    !customer
  ) {
    return <SpinnerContainer />
  }

  return (
    <CreditNote
      creditNote={creditNote}
      lineItems={lineItems}
      lineItemGroups={lineItemGroups}
      setLineItems={setLineItems}
      billingSchedule={billingSchedule}
      plan={plan}
      setCreditNote={setCreditNote}
      setLineItemGroups={setLineItemGroups}
      cancelChanges={cancelChanges}
      merchant={merchant}
      invoiceLineItemGroups={invoiceLineItemGroups || []}
      invoiceLineItems={invoiceLineItems || []}
      invoice={invoice}
      initialEditLineItemsMode={
        invoiceLineItems
          ? invoiceLineItems?.length > 0 &&
            lineItems.length === 0 &&
            creditNote.status === 'DRAFT'
          : false
      }
      merchantDetails={merchantDetails}
      customer={customer}
    ></CreditNote>
  )
}

const CreditNoteParamsHandler: FC = () => {
  const { creditNoteId } = useParams<{ creditNoteId: string }>()

  if (!creditNoteId) {
    return <Box>Missing creditNoteId url parameter</Box>
  }

  return <CreditNoteWrapper creditNoteId={creditNoteId} />
}

export default CreditNoteParamsHandler
