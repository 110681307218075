import { Currency, UsageMetricModel } from '@sequencehq/core-models'
import { useLoadPricesForProduct } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/useLoadPricingEditor/useLoadPricesForProduct'
import {
  AvailableStandardFrequency,
  PricingEditorCustomer,
  PricingEditorInterfacePrice,
  PricingEditorListPriceProp,
  PricingEditorProduct
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import {
  useGetProductsByIdQuery,
  useGetSequenceAccountSettingsQuery
} from 'features/api'
import { useMemo } from 'react'
import { useLoadLedgerIntegrations } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/useLoadPricingEditor/useLoadLedgerIntegrations'
import { skipToken } from '@reduxjs/toolkit/query'
import { XeroIntegration } from 'lib/hooks/useXeroIntegration.ts'
import { useFetchUsageMetrics } from 'lib/hooks/useFetchUsageMetrics.ts'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export type LoadedPricingEditorData = {
  prices: PricingEditorInterfacePrice[]
  listPrices: PricingEditorListPriceProp[]
  product: PricingEditorProduct
  availableCurrencies: Currency[]
  metrics: UsageMetricModel[]
  ledgerIntegrations: {
    key: string
    value: XeroIntegration['ledgerAccounts']
    defaultLedgerAccount?: string
  }[]
}

type UseLoadPricingEditor = (props: {
  customer?: PricingEditorCustomer
  productId?: PricingEditorProduct['id']
  currency?: Currency
  frequencies: AvailableStandardFrequency[]
  skip?: {
    prices?: boolean
    product?: boolean
  }
}) => {
  loading: boolean
  error: boolean
  data?: LoadedPricingEditorData
}

export const useLoadPricingEditor: UseLoadPricingEditor = props => {
  const ledgerIntegrationsLoader = useLoadLedgerIntegrations({
    customer: props.customer
  })
  const pricesLoader = useLoadPricesForProduct({
    productId: props.productId,
    currency: props.currency,
    frequencies: props.frequencies,
    skip: props.skip?.prices
  })

  const productLoader = useGetProductsByIdQuery(
    props.skip?.product || !props.productId
      ? skipToken
      : {
          id: props.productId
        }
  )
  const accountSettingsLoader = useGetSequenceAccountSettingsQuery()
  const metricsLoader = useFetchUsageMetrics()

  const loading = useMemo(() => {
    return [
      pricesLoader.loading,
      productLoader.isLoading,
      accountSettingsLoader.isLoading,
      metricsLoader.loading,
      ledgerIntegrationsLoader.loading
    ].some(Boolean)
  }, [
    pricesLoader.loading,
    productLoader.isLoading,
    accountSettingsLoader.isLoading,
    metricsLoader.loading,
    ledgerIntegrationsLoader.loading
  ])

  const error = useMemo(() => {
    return [
      pricesLoader.error,
      productLoader.error,
      accountSettingsLoader.error,
      metricsLoader.error,
      ledgerIntegrationsLoader.error
    ].some(Boolean)
  }, [
    pricesLoader.error,
    productLoader.error,
    accountSettingsLoader.error,
    metricsLoader.error,
    ledgerIntegrationsLoader.error
  ])

  const { data: taxCategoriesData } = useQuery(
    dashboardv99990101Client.getTaxCategories
  )

  const data = useMemo(() => {
    if (loading || error) {
      return
    }

    let product = productLoader.data?.value() as
      | PricingEditorProduct
      | undefined

    if (product?.taxCategoryId) {
      product = {
        ...product,
        taxCategoryName: taxCategoriesData?.items?.find(
          taxCategory => taxCategory.id === product?.taxCategoryId
        )?.name
      } as PricingEditorProduct
    }

    return {
      prices: pricesLoader.data?.prices ?? [],
      listPrices: [],
      product: product as PricingEditorProduct,
      availableCurrencies:
        accountSettingsLoader.data?.value()?.enabledCurrencies ?? [],
      metrics: metricsLoader.metrics ?? [],
      ledgerIntegrations:
        ledgerIntegrationsLoader.data?.ledgerIntegrations ?? []
    }
  }, [
    taxCategoriesData,
    pricesLoader.data,
    productLoader.data,
    accountSettingsLoader.data,
    metricsLoader.metrics,
    ledgerIntegrationsLoader.data,
    loading,
    error
  ])

  return {
    loading,
    error,
    data
  }
}
