import { Box, Flex } from '@chakra-ui/react'
import { FC } from 'react'
import { Lato14Regular, RedRed60, GreyGrey30 } from '@sequencehq/design-tokens'
import { Phase as PhaseType } from 'modules/Cube/domain/cube.domain.types'
import { usePhase } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/usePhase'
import { SearchForProduct } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/SearchForProduct/SearchForProduct'
import { PhaseHeader } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/PhaseHeader'
import { PhasePricingTable } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/PhasePricingTable'
import { PhaseDurationSelection } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/PhaseDurationSelection'
import { PhaseActions } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/PhaseActions'
import { PhaseAlignmentWarning } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/PhaseAlignmentWarning'

export const Phase: FC<{
  phaseId: PhaseType['id']
  expanded: boolean
  onExpand: (expanded: boolean) => void
  focusDurationOnLoad?: boolean
}> = props => {
  const phasesHook = usePhase({
    phaseId: props.phaseId,
    expanded: props.expanded,
    onExpand: props.onExpand,
    focusDurationOnLoad: props.focusDurationOnLoad
  })

  return (
    <Box
      padding="0 16px"
      _notFirst={{
        borderTop: `1px solid ${GreyGrey30}`
      }}
      data-testid={`phase.section`}
      data-phaseId={props.phaseId}
    >
      <PhaseHeader phasesHook={phasesHook} />
      {phasesHook.expanded && (
        <Box paddingBottom="16px">
          {phasesHook.features.alignDates.visible && (
            <PhaseAlignmentWarning phasesHook={phasesHook} />
          )}
          <PhaseDurationSelection phasesHook={phasesHook} />
          <PhasePricingTable phasesHook={phasesHook} />
          {phasesHook.showProductSearch && (
            <Box marginBottom="16px">
              <SearchForProduct
                onAddNew={() => phasesHook.setShowAddProductSearch(false)}
                onSelection={() => phasesHook.setShowAddProductSearch(false)}
                phaseId={props.phaseId}
                onBlur={() => phasesHook.setShowAddProductSearch(false)}
              />
            </Box>
          )}
          {Boolean(phasesHook.validationErrors.length) && (
            <Flex
              marginBottom="16px"
              color={RedRed60}
              {...Lato14Regular}
              justifyContent="center"
            >
              {phasesHook.validationErrors[0].message}
            </Flex>
          )}
          <PhaseActions phasesHook={phasesHook} />
        </Box>
      )}
    </Box>
  )
}
