import {
  dashboardv99990101Client,
  type Dashboardv99990101Api
} from '@sequencehq/api/dashboard/v99990101'
import type { TaxCategory, TaxRate } from 'Settings/domain/taxRates.types'
import { useMutation, useQuery } from '@sequencehq/api/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import * as Sentry from '@sentry/react'
import type { CountriesAlpha2 } from '@sequencehq/api/commonEnums'

type CreateTaxRateBody = Dashboardv99990101Api.PostTaxRate.CreateTaxRateBody

export type CreateTaxRateInput = {
  country: CountriesAlpha2 | undefined
  taxCategories: Record<TaxCategory['id'], TaxRate['amount']>
}

export function useCreateTaxRate(
  props: { invalidateQueries?: boolean } = { invalidateQueries: true }
) {
  const notifications = useNotifications()
  const { data } = useQuery(dashboardv99990101Client.getTaxCategories)
  const taxCategories = data?.items ?? []

  const {
    mutateAsync: postTaxRate,
    isError,
    reset: resetPostTaxRate
  } = useMutation(dashboardv99990101Client.postTaxRate, {
    invalidateQueries: props.invalidateQueries ? undefined : () => []
  })

  return async (newTaxRateInput: CreateTaxRateInput) => {
    const country = newTaxRateInput.country

    if (!country) {
      return
    }

    const taxRatesToCreate: { body: CreateTaxRateBody }[] = Object.entries(
      newTaxRateInput.taxCategories
    ).map(([categoryId, amount]) => {
      const taxCategoryName = taxCategories.find(
        taxCategory => taxCategory.id === categoryId
      )?.name

      const generatedTaxRateName = `${country} ${taxCategoryName}`

      return {
        body: {
          name: generatedTaxRateName,
          invoiceName: generatedTaxRateName,
          invoiceShortName: generatedTaxRateName,
          isPublished: true,
          taxCategoryId: categoryId,
          amount,
          country
        }
      }
    })

    await Promise.all(
      taxRatesToCreate.map(taxRateToCreate => {
        return postTaxRate(taxRateToCreate, {
          onError: err => {
            Sentry.captureException(err)
          }
        })
      })
    )

    if (isError) {
      notifications.displayNotification('Failed to create tax rates', {
        type: 'error'
      })
      return
    }

    notifications.displayNotification('Tax rates created', {
      type: 'success'
    })

    resetPostTaxRate()
  }
}
