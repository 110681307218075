import { useIntegrationsRoot } from 'modules/Integrations/view/useIntegrations.root'
import { IntegrationsProvider } from 'modules/Integrations/communication'
import { ReactElement } from 'react'

export const IntegrationsRoot = (props: { children: ReactElement[] }) => {
  const { domain } = useIntegrationsRoot()

  return (
    <IntegrationsProvider value={domain}>{props.children}</IntegrationsProvider>
  )
}
