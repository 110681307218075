import { Box, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { Card, Totals } from '@sequencehq/core-components'
import { Currency } from '@sequencehq/core-models'
import { LineItem } from 'CreditNotes/types'
import { toMoney } from '@sequencehq/utils'
import {
  GreyGrey10,
  GreyGrey80,
  GreyGrey90,
  Lato13Bold,
  Lato20Bold
} from '@sequencehq/design-tokens'

type Props = {
  lineItems: LineItem[]
  currency: Currency
  netTotal: string
  totalTax: string
  grossTotal: string
}

export const CreditNoteAdjustmentTotal: FC<Props> = ({
  lineItems,
  currency,
  netTotal,
  totalTax,
  grossTotal
}) => {
  return (
    <Card variant="v2" py={3}>
      <Flex gap={2} direction="column">
        <Text {...Lato13Bold} color={GreyGrey80}>
          Credit total
        </Text>
        <Box {...Lato20Bold} color={GreyGrey90}>
          {toMoney(
            {
              currency: currency,
              value: grossTotal
            },
            '',
            2
          )}
        </Box>

        <Box
          background={GreyGrey10}
          px={2}
          py={1}
          borderRadius={6}
          mx={-2}
          mt={2}
        >
          <Totals currency={currency}>
            {lineItems.map(lineItem => (
              <Totals.Row
                key={lineItem.id}
                title={lineItem.title}
                amount={lineItem.netTotal}
              />
            ))}
            {!!lineItems.length && <Totals.Divider />}
            <Totals.Row title="Credit subtotal" amount={netTotal} />
            <Totals.Row title="Tax" amount={totalTax} />
            <Totals.Divider />
            <Totals.Total title="Credit total" amount={grossTotal} />
          </Totals>
        </Box>
      </Flex>
    </Card>
  )
}
