import { Box } from '@chakra-ui/react'
import { TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  eightDigitNumber,
  required,
  sixDigitNumber
} from '@sequencehq/validation'
import { FC } from 'react'

export const UkBankAccountFields: FC<{ fieldName: string }> = ({
  fieldName
}) => {
  return (
    <>
      <Box width="250px">
        <TextInputField
          fieldLabel="Sort Code"
          fieldName={`${fieldName}.value.ukBankAccount.sortCode`}
          validate={composeValidators(required, sixDigitNumber)}
        />
      </Box>
      <Box width="10px"></Box>
      <Box width="250px">
        <TextInputField
          fieldLabel="Account Number"
          fieldName={`${fieldName}.value.ukBankAccount.accountNumber`}
          validate={composeValidators(required, eightDigitNumber)}
        />
      </Box>
    </>
  )
}
