import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import * as Sentry from '@sentry/react'

export function useMarkTaxCategoryAsDefault() {
  const notifications = useNotifications()

  const { mutateAsync: markTaxCategoryAsDefault } = useMutation(
    dashboardv99990101Client.postSetTaxCategoryAsDefault
  )

  return async (taxCategoryId: string) => {
    await markTaxCategoryAsDefault(
      {
        id: taxCategoryId
      },
      {
        onSuccess: data => {
          notifications.displayNotification(`${data?.name} marked as default`, {
            type: 'success'
          })
        },
        onError: err => {
          notifications.displayNotification(
            'Failed to mark tax rate as default',
            {
              type: 'warning'
            }
          )

          Sentry.captureException(err)
        }
      }
    )
  }
}
