import { DeferredRevenueSummary } from './DeferredRevenueSummary'
import { DeferredRevenueActions } from './DeferredRevenueActions'
import { DeferredRevenueTable } from './DeferredRevenueTable'
import { DetailTableLayout } from 'RevenueRecognition/view/common/DetailTableLayout'

export const DeferredRevenueContent = () => {
  return (
    <DetailTableLayout
      summary={<DeferredRevenueSummary />}
      actions={<DeferredRevenueActions />}
      table={<DeferredRevenueTable />}
    />
  )
}
