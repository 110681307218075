import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'
import { scheduleEndStateStatuses } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/utils/availableFeatures.constants'

export const getRollUpBillingAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const selectedCustomer =
    prevState.data.customers[prevState.data.common.customerId]

  const visible = Boolean(
    selectedCustomer?.organizations?.some(
      org =>
        org?.owner?.id === prevState.data.common.customerId &&
        org?.members?.length !== 0
    )
  )

  if (scheduleEndStateStatuses.includes(prevState.data.common.status)) {
    return {
      available: {
        visible,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: {}
        }
      ]
    }
  }

  return {
    available: {
      visible,
      enabled: visible
    },
    reasons: []
  }
}
