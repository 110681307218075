import {
  CubeReducerState,
  CubeStatus,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getExecuteQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible:
        !prevState.data.common.isArchived &&
        [CubeStatus.QuoteAccepted].includes(prevState.data.common.status),
      enabled: !prevState.data.common.isArchived
    },
    reasons: []
  }
}
