import {
  ControlContextProvider,
  PopoverWrapper,
  SelectControl
} from '@sequencehq/core-components'
import { SidebarSelectControlTrigger } from 'Cube/view/layouts/quote/QuoteEditorSidebar/widgets/SidebarSelectControlTrigger'
import { useEffect, useState } from 'react'
import { Option } from '@sequencehq/core-models'

type Props = {
  icon: React.ReactNode
  options: Option[]
  onChange: (value: string) => void
  labelWhenNoSelection: string
  initialValue?: string
  placeholder?: string
  onCreate?: () => void
  createLabel?: string
  errorMessage?: string
  disabled?: boolean
  popover?: React.ReactNode
  name?: string
  labelOverride?: string
}

export const SidebarSelectControl = ({
  options,
  initialValue,
  onChange,
  onCreate,
  createLabel,
  icon,
  labelWhenNoSelection,
  placeholder,
  errorMessage,
  disabled = false,
  popover,
  name,
  labelOverride
}: Props) => {
  const [value, setValue] = useState(initialValue)

  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (val: string) => {
    setValue(val)
    onChange(val)
  }
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const selectedOption = options.find(opt => opt.value === value)

  const control = (
    <ControlContextProvider
      placement="bottom-end"
      matchWidth={false}
      maxWidth={350}
    >
      <SelectControl
        name={name}
        initialValue={initialValue}
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        onCreate={onCreate}
        createLabel={createLabel}
        disabled={disabled}
        onToggle={setIsOpen}
        trigger={
          <SidebarSelectControlTrigger
            isMenuOpen={isOpen}
            icon={icon}
            labelSelectedOption={labelOverride ?? selectedOption?.label}
            labelWhenNoSelection={labelWhenNoSelection}
            errorMessage={errorMessage}
            disabled={disabled}
          />
        }
      />
    </ControlContextProvider>
  )

  return !popover ? (
    control
  ) : (
    <PopoverWrapper disabled={isOpen} popover={popover}>
      {control}
    </PopoverWrapper>
  )
}
