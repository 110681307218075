import { Box, Flex } from '@chakra-ui/react'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { MutationFormProps } from 'components/Form/types'
import Spinner from 'components/Loading'
import {
  UpdateAndSendCreditNoteStatusFormProps,
  UpdateCreditNoteStatusFormMutation
} from 'components/UpdateInvoiceStatus/types'
import { useGetMerchantForSequenceAccountQuery } from 'features/api'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const FinalizeAndSendCreditNoteForm: FC<
  MutationFormProps<UpdateCreditNoteStatusFormMutation> &
    UpdateAndSendCreditNoteStatusFormProps
> = ({ id, customerName, customerEmails, submitForm, formId, onSuccess }) => {
  const { data: merchant, isFetching: isFetchingMerchant } =
    useGetMerchantForSequenceAccountQuery()

  const merchantValue = merchant?.value()

  if (isFetchingMerchant || !merchantValue) {
    return <Spinner></Spinner>
  }

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          }).then(() => {
            onSuccess?.()
          })
        ]

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Email this credit note to ${customerName} (${customerEmails.join(
              ', '
            )}). After sending you can no longer edit the credit note.`}

            {isSandboxEnv() && (
              <Box pt={5} color={GreyGrey60}>
                Note: No end-customer emails will be sent in {getEnvironment()}.
              </Box>
            )}
          </Flex>
        )
      }}
    />
  )
}

export default FinalizeAndSendCreditNoteForm
