import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useModalContext } from '@sequencehq/core-components'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type FormData = {
  linkToAccount: string
}

type UseLinkSalesforceOpportunityModal = (props: {
  onConfirm: (args: { opportunityId: string }) => void
}) => {
  fields: FormFields<FormData>
  externalAccountDetails: {
    show: boolean
    fetching: boolean
    valid: boolean
    name?: string
  }
  submit: {
    disabled: boolean
    onClick: () => void
  }
}

enum Status {
  READY = 'READY',
  VALIDATING = 'VALIDATING'
}

const salesforceUrlRegexp = /r\/Opportunity\/(.*)\/view$/

export const useLinkSalesforceOpportunityModal: UseLinkSalesforceOpportunityModal =
  props => {
    const modalContext = useModalContext()
    const [checkResult, setCheckResult] = useState<{
      isValid: boolean
      name?: string
      value: string
    } | null>(null)
    const [status, setStatus] = useState<Status>(Status.READY)

    const isAVerifiedId = useCallback(
      (value: unknown) => {
        if (typeof value !== 'string') {
          return 'Invalid Opportunity ID'
        }

        if (checkResult?.isValid) {
          return
        }

        return 'Failed to load Opportunity'
      },
      [checkResult]
    )

    const form = useForm<FormData>({
      value: {
        linkToAccount: ''
      },
      showValidationErrors:
        !isEmpty(checkResult) && status !== Status.VALIDATING,
      fieldConfiguration: [
        {
          property: 'linkToAccount',
          validation: [required, isAVerifiedId]
        }
      ]
    })

    const parsedId = useMemo(() => {
      return (
        form.queries.formData.linkToAccount.match(salesforceUrlRegexp)?.[1] ??
        form.queries.formData.linkToAccount
      )
    }, [form.queries.formData.linkToAccount])

    const externalAccountDetails = useMemo(() => {
      const fetching =
        (form.queries.formData.linkToAccount && checkResult === undefined) ||
        status === Status.VALIDATING
      const valid = checkResult?.isValid ?? false

      return {
        fetching,
        valid: valid,
        show: fetching || valid,
        name: checkResult?.name
      }
    }, [status, checkResult, form.queries.formData.linkToAccount])

    const validate = useDebouncedCallback(async (externalId: string) => {
      setStatus(Status.VALIDATING)

      const res = await dashboard20240730Client.postValidateExternalEntity({
        id: parsedId,
        service: 'Salesforce',
        entityType: 'OPPORTUNITY'
      })

      setCheckResult({
        isValid: res.data?.isValid ?? false,
        value: externalId,
        name: res.data?.name
      })

      setStatus(Status.READY)
    }, 500)

    useEffect(() => {
      /**
       * This hook is designed to manage the validation of our current ID. We store
       * the last result from our validation in the `checkResult` state. If the value of
       * the field changes, we want to clear that state and prepare a for a new validation
       * call.
       */
      if (parsedId === checkResult?.value || !parsedId) {
        return
      }

      if (parsedId !== checkResult?.value) {
        setCheckResult(null)
      }

      void validate(parsedId)
    }, [validate, checkResult?.isValid, parsedId, checkResult?.value])

    const submit = useMemo(() => {
      return {
        disabled: !form.queries.isValid,
        onClick: () => {
          props.onConfirm({
            opportunityId: parsedId
          })
          modalContext.setIsOpen(false)
        }
      }
    }, [parsedId, modalContext, props, form.queries.isValid])

    return {
      fields: form.fields,
      externalAccountDetails,
      submit
    }
  }
