import { usePricingEditorUsageMetricsDrawerConnector } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/drawers/UsageMetricsDrawer/useUsageMetricsDrawerConnector'
import {
  CreateUsageMetricDrawer,
  UsageMetricProvider
} from 'components/CreateUsageMetric'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * @returns
 */
export const PricingEditorUsageMetricsRouteComponent = () => {
  const usageMetricDrawerProps = usePricingEditorUsageMetricsDrawerConnector()

  return (
    <UsageMetricProvider>
      <CreateUsageMetricDrawer {...usageMetricDrawerProps} />
    </UsageMetricProvider>
  )
}
