import {
  ActionHandler,
  ListPriceEditorReducerState,
  UpdateData
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import deepmerge from 'deepmerge'

export const updateData: ActionHandler<UpdateData> = prevState => action => {
  return {
    ...prevState,
    data: deepmerge(prevState.data, action.payload, {
      arrayMerge: (_, source: unknown[]) => source
    })
  } as ListPriceEditorReducerState
}
