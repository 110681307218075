import { CustomerContact } from '../domain/CustomerContacts.domain.types'

export const updateContactsState = ({
  contactId,
  previousContacts,
  updatedContact
}: {
  contactId: string
  previousContacts: CustomerContact[]
  updatedContact: CustomerContact
}): CustomerContact[] => {
  return previousContacts.reduce(
    (acc: CustomerContact[], curr: CustomerContact) => {
      if (curr.id === contactId) {
        return [...acc, { ...curr, ...updatedContact }]
      }

      return [...acc, curr]
    },
    []
  )
}
