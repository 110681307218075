import { Box, Flex, Grid } from '@chakra-ui/react'
import { Card, Switch } from '@sequencehq/core-components'
import { Lato14Bold } from '@sequencehq/design-tokens'
import { WorkflowManagement } from 'modules/Integrations/integrationsConfig/common/WorkflowManagement'
import { useQuickBooksConfigurationManagement } from './useQuickBooksConfigurationManagement'
import { ConfigurationManagementProps } from 'Integrations/domain'

export const QuickBooksConfigurationManagement = (
  props: ConfigurationManagementProps
) => {
  const { fieldsConfig } = useQuickBooksConfigurationManagement(props)

  return (
    <Flex flexDirection="column" gap="20px" width="100%">
      <Card>
        <Grid gridRowGap="16px">
          <Box {...Lato14Bold}>Push data into Sequence</Box>
          <WorkflowManagement
            logoUrls={fieldsConfig.importCustomerWorkflow.logoUrls}
            status={fieldsConfig.importCustomerWorkflow.status}
            label="Link customers to Quickbooks"
            description="Enables the link to Quickbooks option in the customers page."
            onClick={() => {
              fieldsConfig.importCustomerWorkflow.onChange(
                !fieldsConfig.importCustomerWorkflow.value
              )
            }}
          >
            <Flex alignContent="center" onClick={e => e.stopPropagation()}>
              <Switch
                data-testId="switch.linkCustomerWorkflow"
                checked={fieldsConfig.importCustomerWorkflow.value}
                onChange={fieldsConfig.importCustomerWorkflow.onChange}
              />
            </Flex>
          </WorkflowManagement>
        </Grid>
      </Card>
    </Flex>
  )
}
