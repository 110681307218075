import type { SequenceApiError } from '@sequencehq/api'

export const getAvalaraAddressError = (_error: any) => {
  const error = _error as SequenceApiError

  const errorKeys =
    error?.response?.error?.errors?.map(err => err.key).filter(Boolean) ?? []

  return errorKeys.includes('avalara')
}
