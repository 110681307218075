import { useCallback } from 'react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems.ts'

export type v1ApiListPrices = Dashboardv20240509Api.GetListPrices.ListPricesList

type UseLoadListPrices = () => (props: {
  productId: string
}) => Promise<v1ApiListPrices['items']>

export const useLoadListPrices: UseLoadListPrices = () => {
  const loadListPrices = useLoadAllItems(dashboardv20240509Client.getListPrices)
  const load = useCallback(async ({ productId }: { productId: string }) => {
    const listPrices = (await loadListPrices({ productId })) ?? []

    return listPrices
  }, [])

  return load
}
