import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import FinalizeInvoiceForm from 'components/UpdateInvoiceStatus/FinalizeInvoiceForm'
import {
  UpdateInvoiceStatusFormMutation,
  UpdateInvoiceStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import { usePostInvoicesByIdFinalizeMutation } from 'features/api'

const FinalizeInvoiceModal = createFormModal<
  UpdateInvoiceStatusFormMutation,
  UpdateInvoiceStatusFormProps
>({
  FormComponent: FinalizeInvoiceForm,
  useMutation: usePostInvoicesByIdFinalizeMutation,
  modalTitle: 'Finalize Invoice',
  successMessage: 'Invoice finalized',
  submitTitle: 'Finalize Invoice',
  formId: 'finalizeInvoiceForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Invoice ${
        response.invoiceNumber ? response.invoiceNumber : ''
      } finalized`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default FinalizeInvoiceModal
