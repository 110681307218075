import {
  CubeReducerState,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

/**
 * NOTE @ihutc: left in the `prevState` param even though it isn't used, to allow the tests to be explicit
 * that the duplicate feature should be available on all statuses, and so there's a starting point if we ever
 * decide to change when this feature is available in the future.
 */

export const getDuplicateQuoteAvailable = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
