import { Box } from '@chakra-ui/react'
import { CommonFieldsForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/CommonFields/CommonFieldsForm'
import { ExternalLedgerForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/ExternalLedger/ExternalLedgerForm'
import { useListPriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/useListPriceForm'
import { useLoadExistingPriceExternalIds } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/useLoadExistingPriceExternalIds'

export const PriceForm = () => {
  const { ActiveForm } = useListPriceForm()
  useLoadExistingPriceExternalIds()

  return (
    <Box
      width="100%"
      minWidth="400px"
      data-testid="listPriceForm"
      padding="24px"
    >
      <CommonFieldsForm />
      {ActiveForm && <ActiveForm />}
      <ExternalLedgerForm />
    </Box>
  )
}
