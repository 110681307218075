import { useCallback } from 'react'

import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'
import { Toast } from '@sequencehq/core-components'
import { useToast } from '@chakra-ui/react'

export const useUpdateUsageMetric = () => {
  const toast = useToast()

  return useCallback(
    async (
      id: string,
      body: DashboardApi20240730.PutUsageMetric.PutUsageMetricBody
    ) => {
      return await dashboard20240730Client
        .putUsageMetric({
          id,
          body
        })
        .then(result => {
          if (result?.error) {
            return null
          }

          const usageMetric = result.data

          if (usageMetric) {
            toast({
              id: `success-${usageMetric.id}`,
              position: 'bottom',
              duration: 6000,
              render: () => (
                <Toast
                  type="success"
                  title={`Usage metric '${usageMetric.name}' updated`}
                  isClosable={true}
                />
              )
            })
          }

          return result?.data
        })
    },
    [toast]
  )
}
