import { add, endOfMonth, getDate, isValid, set } from '@sequencehq/utils/dates'

export const getLatestRecurrenceDate =
  (recurrenceDayOfMonth: number) => (dateToCheck: Date) => {
    if (!isValid(dateToCheck)) {
      return undefined
    }

    const dateOfStart = getDate(dateToCheck)

    if (dateOfStart < recurrenceDayOfMonth) {
      const endOfPreviousMonth = new Date(
        endOfMonth(add(dateToCheck, { months: -1 }))
      )
      const endOfPreviousMonthDate = getDate(endOfPreviousMonth)

      const newDate = set(endOfPreviousMonth, {
        date:
          recurrenceDayOfMonth < endOfPreviousMonthDate
            ? recurrenceDayOfMonth
            : endOfPreviousMonthDate
      })

      /** Ensure we don't get any time shenanigans (notably related to DST) by ignoring the time part */
      return new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate()
      )
    }

    if (dateOfStart === recurrenceDayOfMonth) {
      return dateToCheck
    }

    const newDate = set(dateToCheck, {
      date: recurrenceDayOfMonth
    })

    /** Ensure we don't get any time shenanigans (notably related to DST) by ignoring the time part */
    return new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate()
    )
  }
