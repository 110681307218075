import { Flex, Text, Button as ChakraButton, Center } from '@chakra-ui/react'
import { HeroIcon } from '@sequencehq/tables'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'

import Bars4Icon from '@heroicons/react/16/solid/Bars4Icon'
import Squares2X2Icon from '@heroicons/react/16/solid/Squares2X2Icon'
import {
  GreyGrey10,
  GreyGrey40,
  GreyGrey60,
  GreyWhite,
  Lato12Bold
} from '@sequencehq/design-tokens'
import { useDefaultCurrency } from 'components/CurrencySettings/useCurrencies'
import { useMemo } from 'react'

const Button = ({
  icon: Icon,
  label,
  isActive,
  to
}: {
  icon: HeroIcon
  label: string
  isActive: boolean
  to: string
}) => {
  const styleActive = {
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 0px 5px 0px rgba(0, 0, 0, 0.03)',
    border: 'rgba(55, 66, 250, 1) 1px solid',
    background: GreyWhite,
    color: 'rgba(55, 66, 250, 1)'
  }

  const styleInactive = {
    border: `${GreyGrey40} 1px solid`,
    background: GreyGrey10,
    color: GreyGrey60
  }

  return (
    <ChakraButton
      as={RouterLink}
      to={to}
      variant="unstyled"
      display="flex"
      flexDirection="column"
      flex="1"
      flexShrink="0"
      p="8px"
      alignItems="center"
      justifyContent="center"
      height="initial"
      minWidth="initial"
      gap="2px"
      borderRadius="8px"
      _hover={styleActive}
      sx={isActive ? styleActive : styleInactive}
    >
      <Center flexShrink="0">
        <Icon color="inherit" width="16px" height="16px" />
      </Center>
      <Text {...Lato12Bold} color="inherit">
        {label}
      </Text>
    </ChakraButton>
  )
}

export const InvoicesViewSettingsHeader = () => {
  const defaultCurrency = useDefaultCurrency()
  const [searchParams] = useSearchParams()

  const isGridViewActive = useMemo(() => {
    const view = searchParams.get('view')
    return view === 'grid'
  }, [searchParams])

  return (
    <Flex p="4px" gap="8px">
      <Button
        icon={Bars4Icon}
        label="List"
        to="/invoices?sortBy=accountingDate_desc"
        isActive={!isGridViewActive}
      />
      <Button
        icon={Squares2X2Icon}
        label="Grid"
        to={`/invoices?view=grid&currency=${defaultCurrency}`}
        isActive={isGridViewActive}
      />
    </Flex>
  )
}
