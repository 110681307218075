import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'

/**
 * This method is used to connect to services which are not currently set up
 * to work with integration.app. As of 2024-07-16 this is exclusively Stripe.
 * @param args
 * @returns
 */
export const connectWithOAuth = async (args: {
  service: IntegrationServices
}): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  const response = await dashboardv20240509Client.postIntegrationAuthRedirect({
    service: args.service
  })

  if (!response.data?.url) {
    return {
      success: false,
      error: IntegrationsPortErrors.Other
    }
  }

  /**
   * The OAuth path redirects back to Sequence dashboard, so we need to change the window
   * location.
   */
  window.location.href = response.data.url
  /**
   * We will be exiting the application because of the window location
   * change anyway, so stall the response to give time for the address to change. This
   * prevents the 'connecting' status from changing back to a ready to connect
   * state.
   */
  await new Promise(resolve => setTimeout(resolve, 10000))

  return {
    success: true,
    error: null
  }
}
