import type {
  InvoiceModel,
  UsageDataResponseModel
} from '@sequencehq/core-models'
import { useLazyGetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataQuery } from 'features/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import type { ApiLineItemGroup } from 'InvoiceEditor/domainManagement/invoiceEditor.types'

type UseLoadUsageData = (props: {
  invoiceId?: string
  lineItemGroups: ApiLineItemGroup[]
  async?: boolean
}) => {
  loading: boolean
  data?: {
    lineItemGroupUsage: (UsageDataResponseModel & { lineItemGroupId: string })[]
  }
  loadUsageData: ({
    invoiceId,
    lineItemGroups
  }: {
    invoiceId: string
    lineItemGroups: ApiLineItemGroup[]
  }) => Promise<{
    data: (UsageDataResponseModel & { lineItemGroupId: string })[]
  }>
}

export const useLoadUsageData: UseLoadUsageData = ({
  invoiceId,
  lineItemGroups,
  async = false
}) => {
  const [getLineItemGroupUsageData] =
    useLazyGetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataQuery()

  const [lineItemGroupUsage, setLineItemGroupUsage] = useState<
    (UsageDataResponseModel & { lineItemGroupId: string })[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)

  const getUsageDataAndParse = useCallback(
    async (props: {
      invoiceId: InvoiceModel['id']
      lineItemGroups: ApiLineItemGroup[]
    }) => {
      const responses = await Promise.all(
        props.lineItemGroups.map(({ id }) =>
          getLineItemGroupUsageData({
            invoiceId: props.invoiceId,
            lineItemGroupId: id
          })
        )
      )

      const usageData: (UsageDataResponseModel & {
        lineItemGroupId: string
      })[] = responses
        .filter(
          ({ data: usageDataResponse }) =>
            usageDataResponse?.value()?.data !== undefined
        )
        .map(({ originalArgs, data: usageDataResponse }) => {
          return {
            ...usageDataResponse?.value()?.data,
            lineItemGroupId: originalArgs?.lineItemGroupId
          } as UsageDataResponseModel & { lineItemGroupId: string }
        })

      return usageData
    },
    [getLineItemGroupUsageData]
  )

  const loadUsageData = useCallback(
    async (props: {
      invoiceId: string
      lineItemGroups: ApiLineItemGroup[]
    }) => {
      const usageData = await getUsageDataAndParse({
        invoiceId: props.invoiceId,
        lineItemGroups: props.lineItemGroups
      })

      return {
        data: usageData
      }
    },
    [getUsageDataAndParse]
  )

  useEffect(() => {
    if (!invoiceId || !lineItemGroups || lineItemGroups.length === 0 || async) {
      return
    }

    setLoading(true)
    loadUsageData({ invoiceId, lineItemGroups })
      .then(({ data }) => {
        setLineItemGroupUsage(data)
        setLoading(false)
      })
      .catch(e => Sentry.captureException(e))
  }, [async, invoiceId, lineItemGroups, loadUsageData])

  const data = useMemo(() => {
    if (!lineItemGroupUsage || async) {
      return
    }

    return {
      lineItemGroupUsage
    }
  }, [lineItemGroupUsage])

  return {
    data,
    loading,
    loadUsageData
  }
}
