import {
  CubeReducerState,
  CubeStatus,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getPublishQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible:
        !prevState.data.common.isArchived &&
        [
          CubeStatus.QuoteDraft,
          CubeStatus.QuotePublished,
          CubeStatus.QuoteReadyToSign
        ].includes(prevState.data.common.status),
      enabled:
        !prevState.data.common.isArchived &&
        ([CubeStatus.QuoteDraft].includes(prevState.data.common.status) ||
          ([CubeStatus.QuotePublished, CubeStatus.QuoteReadyToSign].includes(
            prevState.data.common.status
          ) &&
            prevState.data.quote.isExpired))
    },
    reasons: []
  }
}
