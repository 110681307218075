import { Button, Flex, Grid, GridItem } from '@chakra-ui/react'
import { GreyGrey30, Lato16Bold } from '@sequencehq/design-tokens'
import { useDiscountEditorContent } from 'modules/Cube/view/common/drawers/discountEditor/drawer/content/DiscountEditorContent/useDiscountEditorContent'
import * as Sentry from '@sentry/react'
import { DiscountEditorForm } from 'modules/Cube/view/common/drawers/discountEditor/drawer/content/DiscountEditorForm/DiscountEditorForm'
import { Blocker } from 'common/components/Blocker'

export const DiscountEditorContent = () => {
  const {
    title,
    saveDisabled,
    saveText,
    closeText,
    onClose,
    saveAvailable,
    onSave,
    blockerEnabled,
    submitting
  } = useDiscountEditorContent()

  return (
    <Grid
      templateAreas={`
  "header"
  "body"
  "footer"
`}
      templateRows={`56px 1fr 56px`}
      width="100%"
      height="100%"
      position="relative"
    >
      <Blocker
        name="discount editor"
        dirty={blockerEnabled}
        submitting={submitting}
      />
      <Flex
        gridArea="header"
        borderBottom={`1px solid ${GreyGrey30}`}
        {...Lato16Bold}
        height="56px"
        alignItems="center"
        paddingLeft="24px"
      >
        {title}
      </Flex>
      <GridItem gridArea="body" overflow="auto">
        <DiscountEditorForm />
      </GridItem>
      <Flex
        gridArea="footer"
        alignItems="center"
        justifyContent="flex-end"
        height="100%"
        padding="0 24px"
        gap="8px"
        borderTop={`1px solid ${GreyGrey30}`}
      >
        <Button variant="secondary2" onClick={onClose}>
          {closeText}
        </Button>
        {saveAvailable && (
          <Button
            variant="primary2"
            isDisabled={saveDisabled}
            onClick={() => {
              onSave().catch(e => Sentry.captureException(e))
            }}
          >
            {saveText}
          </Button>
        )}
      </Flex>
    </Grid>
  )
}
