import { Box } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { baseApi } from 'features/api/baseApi'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

type CreditNoteProps = {
  creditNoteId: string
  creditNoteNumber: string
}

const CreditNotePdf: FC<CreditNoteProps> = ({
  creditNoteId,
  creditNoteNumber
}) => {
  const navigate = useNavigate()
  const [getCreditNoteDownload] =
    baseApi.endpoints.getCreditNoteDownload.useLazyQuerySubscription()

  useEffect(() => {
    getCreditNoteDownload({ id: creditNoteId })
      .unwrap()
      .then(fulfilled => {
        const blobUrl = URL.createObjectURL(
          new Blob([fulfilled], { type: 'application/pdf' })
        )

        const a = document.createElement('a')
        a.download = creditNoteNumber
        a.href = blobUrl
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
      .catch(e => Sentry.captureException(e))

    navigate(-1)
  }, [getCreditNoteDownload, creditNoteId, creditNoteNumber, navigate])

  return <></>
}

const CreditNoteParamsHandler: FC = () => {
  const { creditNoteId, creditNoteNumber } = useParams<CreditNoteProps>()
  if (!creditNoteId) {
    return <Box>Missing credit note parameters</Box>
  }
  return (
    <CreditNotePdf
      creditNoteId={creditNoteId}
      creditNoteNumber={creditNoteNumber || ''}
    />
  )
}

export default CreditNoteParamsHandler
