import { Box, Flex, Link } from '@chakra-ui/react'
import { EnvironmentBanner } from '@sequencehq/core-components'
import { GreyGrey0 } from '@sequencehq/design-tokens'
import { getEnvironment } from 'lib/environment/environment'
import { useEffect, useRef } from 'react'

export const PageContainer = ({
  children,
  ...props
}: React.ComponentProps<typeof Flex>) => {
  const environment = getEnvironment()
  const maskRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.addEventListener('pointermove', pos => {
      const x = (pos.clientX / window.innerWidth) * 100
      const y = (pos.clientY / window.innerHeight) * 100

      maskRef.current?.style.setProperty('--mouse-x', x + '%')
      maskRef.current?.style.setProperty('--mouse-y', y + '%')
    })
  }, [])

  return (
    <>
      <Box width="100%" position="fixed" zIndex={3}>
        {environment === 'demo' ? (
          <EnvironmentBanner environment={environment}>
            Demo -{' '}
            <Link href="https://access.sequencehq.com" isExternal>
              Request a Sandbox
            </Link>
          </EnvironmentBanner>
        ) : (
          <EnvironmentBanner environment={environment} />
        )}
      </Box>
      <Flex
        height="100vh"
        placeItems="center"
        placeContent="center"
        direction="column"
        gap="32px"
        padding="0 16px"
        position="relative"
        {...props}
      >
        <Box
          style={{
            backgroundColor: GreyGrey0,
            backgroundImage:
              'linear-gradient(180deg,transparent 98%,rgba(228, 232, 239, 0.75) 0),linear-gradient(90deg,transparent 94%,rgba(228, 232, 239, 0.75) 0)',
            backgroundSize: '16px 35px',
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            zIndex: -1
          }}
        />
        <Box
          style={{
            position: 'absolute',
            backgroundImage:
              'radial-gradient( circle at top left, rgba(0, 160, 162, 0.1),transparent 20%,  transparent 40%), radial-gradient( circle at bottom right, rgba(0, 160, 162, 0.1) ,transparent 20%,  transparent 40% )',
            inset: 0,
            width: '100%',
            height: '100%',
            zIndex: -1
          }}
        />
        <Box
          ref={maskRef}
          style={{
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            background: `radial-gradient(transparent, rgba(252, 252, 253, .75))`,
            zIndex: -1,
            mask: 'radial-gradient( circle at var(--mouse-x) var(--mouse-y), transparent 70px, black 200px)'
          }}
        />

        {children}
      </Flex>
    </>
  )
}
