import { Grid, GridItem } from '@chakra-ui/react'
import { AddProductFormBreadcrumb } from 'Products/drawers/AddProduct/components/AddProductBreadcrumb/AddProductBreadcrumb'
import { useNavigate } from 'react-router-dom'
import { AddProductFormMode } from 'Products/drawers/AddProduct/domain/addProduct.type'
import { AddProductForm } from 'Products/drawers/AddProduct/components/AddProductForm/AddProductForm'
import { useAddProductFormContext } from 'Products/drawers/AddProduct/hooks/useAddProductFormContext'
import { PriceForm } from 'Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/PriceForm'
import Spinner from 'components/Loading'
import { useListPriceEditorContext } from 'Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { AddProductFormReview } from 'Products/drawers/AddProduct/components/AddProductReview'
import {
  ModalContextProvider,
  SimpleModalUI
} from '@sequencehq/core-components'

const FORM_COMPONENT_MAP: Record<AddProductFormMode, React.ReactNode> = {
  [AddProductFormMode.ADD_PRODUCT]: <AddProductForm />,
  [AddProductFormMode.ADD_PRICE]: <PriceForm />,
  [AddProductFormMode.REVIEW]: <AddProductFormReview />
}

type FooterItem = {
  onNext: VoidFunction | (() => Promise<void>)
  onBack: VoidFunction
  nextButtonLabel: string
  backButtonLabel: string
}

export const AddProductFormDrawer = () => {
  const navigate = useNavigate()
  const {
    data: { mode },
    actions: { updateFormMode, submitProductForm, submitPriceForm, submitForm },
    isSubmitting
  } = useAddProductFormContext()

  const {
    editor: { loaded }
  } = useListPriceEditorContext()

  const footerItems: Record<AddProductFormMode, FooterItem> = {
    [AddProductFormMode.ADD_PRODUCT]: {
      onNext: submitProductForm,
      onBack: () => navigate('..'),
      nextButtonLabel: 'Next',
      backButtonLabel: 'Cancel'
    },
    [AddProductFormMode.ADD_PRICE]: {
      onNext: submitPriceForm,
      onBack: () => updateFormMode(AddProductFormMode.ADD_PRODUCT),
      nextButtonLabel: 'Next',
      backButtonLabel: 'Back'
    },
    [AddProductFormMode.REVIEW]: {
      onNext: submitForm,
      onBack: () => updateFormMode(AddProductFormMode.ADD_PRICE),
      nextButtonLabel: 'Create list price',
      backButtonLabel: 'Back'
    }
  }

  const footer: FooterItem = footerItems[mode]

  const Component = FORM_COMPONENT_MAP[mode]

  return (
    <ModalContextProvider isOpen>
      <SimpleModalUI
        variant="drawer"
        title="Add product"
        cancelButtonText={footer.backButtonLabel}
        submitButtonText={footer.nextButtonLabel}
        onCancel={footer.onBack}
        onSubmit={() => {
          void footer.onNext()
          return
        }}
        closeOnSubmit={false}
        closeOnCancel={false}
        submitDisabled={isSubmitting}
        contentProps={{
          padding: 0
        }}
      >
        {loaded ? (
          <Grid
            templateAreas={`
                "breadcrumb"
                "content"
              `}
            templateRows={'48px 1fr'}
            width="100%"
            height="100%"
          >
            <GridItem gridArea="breadcrumb">
              <AddProductFormBreadcrumb />
            </GridItem>
            <GridItem gridArea="content" overflow="auto">
              {Component}
            </GridItem>
          </Grid>
        ) : (
          <Spinner />
        )}
      </SimpleModalUI>
    </ModalContextProvider>
  )
}
