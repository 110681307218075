import { Box, Flex, Grid, Icon, Center } from '@chakra-ui/react'
import {
  Lato16Bold,
  GreyGrey20,
  Lato12Regular,
  GreyGrey70,
  RedRed60,
  GreyGrey60,
  RedRed50,
  Lato14Bold,
  YellowYellow10,
  YellowYellow80
} from '@sequencehq/design-tokens'
import {
  SequenceMenu,
  SequenceMenuItemType,
  Button
} from '@sequencehq/core-components'
import EllipsisHorizontalIcon from '@heroicons/react/16/solid/EllipsisHorizontalIcon'
import ArrowLongRightIcon from '@heroicons/react/16/solid/ArrowLongRightIcon'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import TrashIcon from '@heroicons/react/24/solid/TrashIcon'
import {
  AvailablePhaseActions,
  PhaseHookInterface
} from 'modules/Cube/view/common/widgets/PricingPhases/Phase/usePhase'
import { FC } from 'react'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid'

const phaseMenuItems = (
  phaseActions: AvailablePhaseActions
): SequenceMenuItemType[] => [
  {
    label: 'Remove phase',
    onClick: phaseActions.deletePhase.handler,
    style: { color: RedRed60 },
    isDisabled: !phaseActions.deletePhase.available,
    iconLeft: <TrashIcon width="16px" height="16px" color={RedRed50} />
  }
]

export const PhaseHeader: FC<{ phasesHook: PhaseHookInterface }> = ({
  phasesHook
}) => {
  const cubeContext = useCubeContext()

  if (
    Boolean(
      quoteStatusAdapter.out(cubeContext.queries.rawData.data.common.status)
    ) &&
    phasesHook.isOnlyPhase
  ) {
    return <Box height="16px"></Box>
  }

  return (
    <Grid
      height="56px"
      templateColumns={'1fr auto'}
      alignItems="center"
      {...(phasesHook.isOnlyPhase
        ? {}
        : {
            cursor: 'pointer',
            onClick: () => phasesHook.updateExpanded(!phasesHook.expanded)
          })}
      {...Lato14Bold}
      userSelect="none"
      data-testid="phase.header"
    >
      <Flex alignItems="center" gap="8px" padding="0 8px">
        {!phasesHook.isOnlyPhase && (
          <Icon
            as={phasesHook.expanded ? ChevronDownIcon : ChevronRightIcon}
            height="20px"
            width="20px"
          />
        )}
        {phasesHook.isOnlyPhase ? (
          <Flex alignItems="center">Schedule</Flex>
        ) : phasesHook.phaseDuration.startDateFormatted &&
          phasesHook.phaseDuration.endDateFormatted ? (
          <Flex gap="8px" alignItems="center">
            <Box {...Lato16Bold}>
              {phasesHook.phaseDuration.startDateFormatted}
            </Box>

            <Icon
              as={ArrowLongRightIcon}
              height="16px"
              width="16px"
              position="relative"
              top="1px"
            />

            <Box {...Lato16Bold}>
              {phasesHook.phaseDuration.endDateFormatted}
            </Box>
          </Flex>
        ) : (
          <Box {...Lato16Bold}>
            {phasesHook.phaseDuration.differenceFormatted}
          </Box>
        )}
        {phasesHook.features.alignDates.visible && (
          <Flex
            backgroundColor={YellowYellow10}
            borderRadius="6px"
            height="24px"
            width="24px"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              as={ExclamationTriangleIcon}
              height="16px"
              width="16px"
              color={YellowYellow80}
            />
          </Flex>
        )}
      </Flex>
      <Flex alignItems="center" gap="8px" padding="0 8px">
        <Box>
          {phasesHook.phaseComplete && (
            <Flex
              backgroundColor={GreyGrey20}
              borderRadius="6px"
              alignItems="center"
              padding="0 6px"
              height="24px"
              color={GreyGrey70}
              gap="4px"
              {...Lato12Regular}
            >
              <Icon
                as={CheckCircleIcon}
                height="16px"
                width="16px"
                color={GreyGrey70}
              />
              Completed
            </Flex>
          )}
        </Box>
        <Box onClick={e => e.stopPropagation()}>
          <SequenceMenu
            alignment="bottom-right"
            menuButton={
              <Button variant="ghost" size="24px" data-testid="phase.options">
                <Center flexShrink="0">
                  <EllipsisHorizontalIcon
                    width="16px"
                    height="16px"
                    color={GreyGrey60}
                  />
                </Center>
              </Button>
            }
            items={phaseMenuItems(phasesHook.phaseActions)}
          />
        </Box>
      </Flex>
    </Grid>
  )
}
