import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { useQuery } from '@sequencehq/api/utils'
import { useModalContext } from '@sequencehq/core-components'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useMemo, useState } from 'react'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

type FormData = {
  linkToAccount: string
  createNew: boolean
}

type UseLinkXeroCustomerModal = (props: {
  onConfirm?: () => void
  onClose?: () => void
  customerId: string
}) => {
  fields: FormFields<FormData>
  customerName: string
  serviceName: string
  loaded: boolean
  externalAccountDetails: {
    name: string
    externalUrl: string
  } | null
  submit: {
    disabled: boolean
    linking: boolean
    onClick: () => void
  }
}

export const useLinkXeroCustomerModal: UseLinkXeroCustomerModal = props => {
  const [isLinking, setIsLinking] = useState(false)
  const modalContext = useModalContext()
  const { displayNotification } = useNotifications()

  const serviceName = getIntegrationName('Xero')

  const customerResponse = useQuery(
    dashboardv99990101Client.getCustomer,
    {
      id: props.customerId
    },
    {
      enabled: !isLinking,
      select: res => ({
        /**
         * Return this so we can use it as part of the PUT, but select the
         * useful bits otherwise.
         */
        fullCustomer: res,
        name: res?.legalName ?? '',
        externalId:
          res?.integrationIds.find(int => int.service === 'Xero')?.id ?? null
      })
    }
  )

  const customersOnExternalServiceResponse = useQuery(
    dashboard20240730Client.getIntegrationCustomersService,
    'Xero',
    {
      select: res => res?.items ?? []
    }
  )

  /**
   * Form management
   */
  const accountLinkingOptions = customersOnExternalServiceResponse.data
    ?.filter(acc => !acc.isLinked)
    .map(acc => ({
      label: acc.companyName,
      value: acc.id
    }))

  const form = useForm<FormData>({
    value: {
      linkToAccount: customerResponse?.data?.externalId ?? '',
      createNew: false
    },
    fieldConfiguration: [
      {
        property: 'createNew'
      },
      {
        property: 'linkToAccount',
        disabled: ({ formData }) => formData.createNew,
        validation: [required],
        options: accountLinkingOptions
      }
    ]
  })

  /**
   * Link the customer to an account on the third party service.
   */
  const linkToExistingXeroCustomer = async (): Promise<boolean> => {
    const existingCustomer = customerResponse.data?.fullCustomer
    if (!existingCustomer) {
      return false
    }

    const res = await dashboardv99990101Client.putCustomer({
      id: props.customerId,
      body: {
        ...existingCustomer,
        contacts: existingCustomer.contacts.map(contact => ({
          email: contact.email,
          name: contact.name ?? '',
          billingPreference: contact.billingPreference
        })),
        integrationIds: [
          ...(existingCustomer?.integrationIds ?? []).filter(
            int => int.service !== 'Xero'
          ),
          {
            service: 'Xero',
            id: form.fields.linkToAccount.value
          }
        ]
      }
    })

    if (res.error) {
      return false
    }

    return true
  }

  const pushCustomerToXero = async () => {
    const res = await dashboard20240730Client.postPushCustomerToExternalService(
      {
        customerId: props.customerId,
        service: 'Xero'
      }
    )

    if (res.error) {
      return false
    }

    return true
  }

  const externalAccountDetails = useMemo(() => {
    const externalAccount = customersOnExternalServiceResponse.data?.find(
      account => account.id === form.fields.linkToAccount.value
    )

    if (!externalAccount) {
      return null
    }

    return {
      name: externalAccount?.companyName ?? '',
      externalUrl: externalAccount?.externalUrl ?? ''
    }
  }, [customersOnExternalServiceResponse.data, form.fields.linkToAccount.value])

  const submit = {
    disabled: !form.queries.isValid || isLinking,
    linking: isLinking,
    onClick: async () => {
      setIsLinking(true)
      const success = await (form.queries.formData.createNew
        ? pushCustomerToXero()
        : linkToExistingXeroCustomer())

      if (!success) {
        displayNotification(`Could not link customer to ${serviceName}`, {
          type: 'error'
        })
        setIsLinking(false)
        return
      }

      displayNotification(`Customer linked to ${serviceName}`, {
        type: 'success'
      })
      modalContext.setIsOpen(false)
      setIsLinking(false)
      props.onConfirm?.()
    }
  }

  return {
    loaded:
      !customerResponse.isPending &&
      !customersOnExternalServiceResponse.isPending,
    fields: form.fields,
    serviceName,
    externalAccountDetails,
    customerName: customerResponse.data?.name ?? '',
    submit
  }
}
