import { Flex, Text } from '@chakra-ui/react'
import {
  BuildingOfficeIcon,
  CheckBadgeIcon,
  ClockIcon,
  EnvelopeIcon
} from '@heroicons/react/16/solid'
import { Tooltip } from '@sequencehq/core-components'
import {
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CubeStatus } from 'Cube/domain/cube.domain.types'
import { FC } from 'react'

type SigningStatus = 'pending' | 'ready' | 'signed'

const useSigners = () => {
  const cubeContext = useCubeContext()

  const baseStatus: SigningStatus =
    cubeContext.queries.rawData.data.common.status ===
    CubeStatus.QuoteReadyToSign
      ? 'ready'
      : 'pending'

  return {
    signers: cubeContext.queries.rawData.data.quote.contacts,
    counterSigners: cubeContext.queries.rawData.data.quote.counterSigners,
    baseStatus
  }
}

export const Signers: FC = () => {
  const { signers, counterSigners, baseStatus } = useSigners()

  const showEmptyState = signers.length === 0 && counterSigners.length === 0

  if (showEmptyState) {
    return (
      <Text color={GreyGrey60} {...Lato13Regular}>
        All quote recipients will appear here as signers.
      </Text>
    )
  }

  return (
    <div>
      {signers.map(signer => (
        <Signer
          key={signer.contactId}
          name={signer.name ?? ''}
          email={signer.email}
          status={signer.signedAt ? 'signed' : baseStatus}
        />
      ))}
      {counterSigners.map(counterSigner => (
        <Signer
          key={counterSigner.email}
          name={counterSigner.name}
          email={counterSigner.email}
          status={counterSigner.signedAt ? 'signed' : baseStatus}
          isCounterSigner
        />
      ))}
    </div>
  )
}

const Signer: FC<{
  name: string
  email: string
  status: SigningStatus
  isCounterSigner?: boolean
}> = ({ name, email, status, isCounterSigner = false }) => {
  return (
    <Flex gap={1} py={2} whiteSpace="nowrap">
      <Flex color={GreyGrey70}>
        <StatusIcon status={status} />
      </Flex>
      <Text ml={1} color={GreyGrey80} {...Lato13Regular} fontWeight="semibold">
        {name}
      </Text>
      <Text
        {...Lato13Regular}
        color={GreyGrey60}
        flex={1}
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {email}
      </Text>
      {isCounterSigner && (
        <Tooltip label="Countersigner">
          <BuildingOfficeIcon width={16} />
        </Tooltip>
      )}
    </Flex>
  )
}

const StatusIcon: FC<{ status: SigningStatus }> = ({ status }) => {
  switch (status) {
    case 'pending':
      return (
        <Tooltip label="Signature request pending">
          <ClockIcon width={16} />
        </Tooltip>
      )
    case 'ready':
      return (
        <Tooltip label="Signature request sent">
          <EnvelopeIcon width={16} />
        </Tooltip>
      )
    case 'signed':
      return (
        <Tooltip label="Signed">
          <CheckBadgeIcon width={16} />
        </Tooltip>
      )
  }
}
