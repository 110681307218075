import { Flex, Text } from '@chakra-ui/react'
import {
  GreyWhite,
  GreyGrey30,
  Lato14Bold,
  Lato14Regular,
  GreyGrey80,
  GreyGrey70
} from '@sequencehq/design-tokens'
import {
  CreditNoteStatusBadge,
  CreditNoteDetailsHeaderActions
} from 'CreditNotes/components'
import { CreditNoteStatus } from 'CreditNotes/types'
import { match, P } from 'ts-pattern'
import useToggle from 'lib/hooks/useToggle.tsx'
import { CreditNotePDFPreview } from 'CreditNotes/components/drawers/CreditNotePDFPreview.tsx'

interface Props {
  creditNoteNumber: string | undefined
  invoiceNumber: string | undefined
  creditNoteId: string
  status: CreditNoteStatus
  customerLegalCompanyName: string
  customerEmails: string[]
}

export const CreditNoteDetailsHeader = ({
  creditNoteNumber,
  creditNoteId,
  invoiceNumber,
  status,
  customerLegalCompanyName,
  customerEmails
}: Props) => {
  const [showPDFPreview, toggleShowPDFPreview] = useToggle(false)

  return (
    <>
      <Flex
        height="48px"
        padding={4}
        backgroundColor={GreyWhite}
        borderBottom={`1px solid ${GreyGrey30}`}
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
      >
        <Flex alignItems="center" alignContent="center" gap="4px">
          <CreditNoteTitle
            creditNoteNumber={creditNoteNumber}
            invoiceNumber={invoiceNumber}
          />
          <CreditNoteStatusBadge status={status} />
        </Flex>

        <CreditNoteDetailsHeaderActions
          creditNoteId={creditNoteId}
          creditNoteNumber={creditNoteNumber}
          creditNoteStatus={status}
          onPreviewCreditNotePDF={() => toggleShowPDFPreview(true)}
          customerLegalCompanyName={customerLegalCompanyName}
          customerEmails={customerEmails}
        />
      </Flex>
      <CreditNotePDFPreview
        creditNote={{
          creditNoteNumber,
          status,
          id: creditNoteId
        }}
        isOpen={showPDFPreview}
        onClose={() => {
          toggleShowPDFPreview(false)
        }}
      />
    </>
  )
}

const CreditNoteTitle = ({
  creditNoteNumber,
  invoiceNumber
}: { creditNoteNumber?: string; invoiceNumber?: string }) => {
  return match({ creditNoteNumber, invoiceNumber })
    .with({ creditNoteNumber: undefined, invoiceNumber: undefined }, () => (
      <Text {...Lato14Bold} color={GreyGrey80}>
        Issue a credit note
      </Text>
    ))
    .with({ creditNoteNumber: P._, invoiceNumber: undefined }, () => (
      <Text {...Lato14Bold} color={GreyGrey80}>
        {creditNoteNumber}
      </Text>
    ))
    .with({ creditNoteNumber: undefined, invoiceNumber: P._ }, () => (
      <Flex alignItems="center" alignContent="center" gap="4px">
        <Text {...Lato14Bold} color={GreyGrey80}>
          Issue a credit note
        </Text>
        <Text {...Lato14Regular} color={GreyGrey70}>
          for {invoiceNumber}
        </Text>
      </Flex>
    ))
    .with({ creditNoteNumber: P._, invoiceNumber: P._ }, () => (
      <Flex alignItems="center" alignContent="center" gap="4px">
        <Text {...Lato14Bold} color={GreyGrey80}>
          {creditNoteNumber}
        </Text>
        <Text {...Lato14Regular} color={GreyGrey70}>
          for {invoiceNumber}
        </Text>
      </Flex>
    ))
    .exhaustive()
}
