import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback } from 'react'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems.ts'

export type v1ApiTaxRate = Dashboardv20240509Api.GetTaxRate.TaxRate
type UseLoadTaxRates = () => () => Promise<v1ApiTaxRate[]>

export const useLoadAllTaxRates: UseLoadTaxRates = () => {
  const loadTaxRates = useLoadAllItems(dashboardv20240509Client.getTaxRates)
  const load = useCallback(async () => {
    const taxRates = (await loadTaxRates()) ?? []

    return taxRates
  }, [])

  return load
}
