import { Button, SequenceMenu } from '@sequencehq/core-components'
import { Center } from '@chakra-ui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid'
import { GreyGrey60 } from '@sequencehq/design-tokens'

export const ProductListPriceKebabMenu = ({
  items
}: {
  items: React.ReactNode[]
}) => {
  return (
    <SequenceMenu
      width={226}
      alignment="bottom-right"
      items={items}
      menuButton={
        <Button variant="ghost" size="24px">
          <Center flexShrink="0">
            <EllipsisHorizontalIcon
              width="16px"
              height="16px"
              color={GreyGrey60}
            />
          </Center>
        </Button>
      }
    />
  )
}
