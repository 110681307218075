import { FC, useEffect, useRef, useState } from 'react'
import {
  CustomerAliasModel,
  CustomerModel,
  emptyPaginatedResponseNew
} from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import {
  useGetCustomerAliasesQuery,
  useLazyGetCustomersByAliasByAliasQuery
} from 'features/api'
import { selectPlaceholder } from 'lib/form'
import {
  InfoPopoverProps,
  CompoundInputFieldNew,
  ComboInputRemoteFilterNew,
  Label
} from '@sequencehq/forms'
import { Badge } from '@sequencehq/core-components'
import * as Sentry from '@sentry/react'
import { Box } from '@chakra-ui/react'

type CustomerAliasComboInputProps = {
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
  postChange?: () => void
  handleBlur?: () => void
  addNewAction?: () => void
  labelPadding?: number
}

export const CustomerAliasComboInput: FC<CustomerAliasComboInputProps> = ({
  fieldName,
  disabled,
  labelPadding
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [query, setQuery] = useState('')
  const [customerByAlias, setCustomerByAlias] = useState<
    Record<string, CustomerModel>
  >({})
  const [aliasById, setAliasById] = useState<
    Record<string, CustomerAliasModel>
  >({})

  const aliasesResult = useGetCustomerAliasesQuery({
    limit: query ? 50 : 5
  })

  const { isLoading, isError, data } = aliasesResult
  const { items } = data?.value() ?? emptyPaginatedResponseNew

  const [customerByAliasTrigger] = useLazyGetCustomersByAliasByAliasQuery()
  const [aliasId, setAliasId] = useState<string>()
  const [aliasObject, setAliasObject] = useState<CustomerAliasModel>()

  useEffect(() => {
    const aliasMap = items.reduce(
      (acc, alias) => ({
        ...acc,
        [alias.id]: alias
      }),
      {}
    )

    setAliasById(aliasMap)
  }, [items])

  useEffect(() => {
    items.forEach(alias => {
      customerByAliasTrigger({ alias: alias.value })
        .then(res => {
          const customer = res.data?.value()
          if (customer) {
            setCustomerByAlias(prev => ({ ...prev, [alias.value]: customer }))
          }
        })
        .catch(error => Sentry.captureException(error))
    })
  }, [customerByAliasTrigger, items])

  useEffect(() => {
    if (!aliasId) {
      return setAliasObject(undefined)
    }

    setAliasObject(aliasById[aliasId])
  }, [aliasById, aliasId])

  return (
    <Box>
      <Label>Customer alias</Label>
      {!!labelPadding && labelPadding > 0 && <Box height={labelPadding || 2} />}
      <CompoundInputFieldNew<ComboInputRemoteFilterNew<CustomerAliasModel>>
        inputRef={inputRef}
        items={items.filter(alias => alias.value.includes(query))}
        itemToString={item => item?.value ?? ''}
        itemToContent={({ value }) => ({
          title: customerByAlias[value]?.legalName ?? '',
          tag: (
            <Badge sentiment="neutral" size="sm">
              {value}
            </Badge>
          )
        })}
        setQuery={setQuery}
        query={query}
        validate={required}
        placeholder={selectPlaceholder({
          result: aliasesResult,
          successPrompt: 'Choose customer alias'
        })}
        disabled={disabled || isLoading || isError}
        Component={ComboInputRemoteFilterNew}
        fieldName={fieldName}
        model={aliasObject ?? null}
        modelId={aliasObject?.value ?? ''}
        setModelId={setAliasId}
      />
    </Box>
  )
}
