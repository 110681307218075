import { IntegrationsReducerState } from 'modules/Integrations/domain/integrations.domain.types'

export const INITIAL_EXAMPLE_STATE: IntegrationsReducerState = {
  data: {
    integrations: {},
    featuredIntegrations: []
  },
  configuration: {
    enableFeature: false
  },
  queries: {
    resolvedIntegrations: {},
    integrationGroups: {},
    featuredIntegrations: [],
    activeIntegrations: [],
    availableFeatures: {
      integrations: {}
    }
  },
  editor: {
    loaded: false,
    loadingError: false,
    updating: false,
    connecting: false,
    removing: false,
    configuration: {
      valid: false,
      showValidationErrors: false
    }
  },
  initialData: {
    integrations: {},
    featuredIntegrations: []
  }
}
