import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type UseCustomerEditorOutletConnector = () => {
  onSuccess: (
    customerModel: DashboardApi20240730.PutCustomer.PutCustomerResponse
  ) => void
  onCancel: () => void
}

export const useEditCustomerEditorConnector: UseCustomerEditorOutletConnector =
  () => {
    const navigate = useNavigate()
    const cubeContext = useCubeContext()

    const updateCustomer = useCallback(
      (customer: DashboardApi20240730.PutCustomer.PutCustomerResponse) => {
        cubeContext.mutators.updateData({
          customers: {
            [customer.id]: customer
          },
          common: {
            customerId: customer.id
          },
          quote: {
            customerLegalName: customer.legalName,
            customerAddress: customer.address
          }
        })
      },
      [cubeContext, navigate]
    )

    const onCancel = useCallback(() => {
      navigate('../../..', { relative: 'path' })
    }, [navigate])

    return {
      onSuccess: updateCustomer,
      onCancel
    }
  }
