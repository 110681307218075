import { parseFloatOrZero } from 'lib/parsers'
import {
  ServerLineItem,
  LineItem,
  CreditNote,
  LineItemGroup,
  AggregatedCreditNote,
  AggregatedLineItemGroup
} from './types'
import { mergeLineItemsIntoGroups } from './utils/lineItemGroup'

type ServerLineItemWithGroup = ServerLineItem & { groupId: string }

export const lineItem = {
  fromServer: (serverLineItem: ServerLineItemWithGroup): LineItem => {
    return { ...serverLineItem, selected: true, deleted: false }
  },
  toServer: (lineItem: LineItem): ServerLineItem => {
    const { selected, rate, netTotal, deleted, ...serverLineItem } = lineItem
    return {
      ...serverLineItem,
      rate: parseFloatOrZero(rate).toString(),
      netTotal: parseFloatOrZero(netTotal).toString()
    }
  }
}

export const lineItems = {
  fromServer: (serverLineItems: ServerLineItem[]) => {
    return filterInvalidLineItems(serverLineItems)
      .map(lineItem.fromServer)
      .reverse() // Server returns newest line items first
  },
  toServer: (clientLineItems: LineItem[]): ServerLineItem[] => {
    return clientLineItems.filter(item => item.selected).map(lineItem.toServer)
  }
}

export const lineItemGroups = {
  fromServer: (
    lineItemGroups: LineItemGroup[],
    serverLineItems: ServerLineItem[]
  ): AggregatedLineItemGroup[] => {
    return mergeLineItemsIntoGroups(
      lineItems.fromServer(serverLineItems),
      lineItemGroups
    )
  }
}

export const creditNote = {
  fromServer: (
    serverCreditNote: CreditNote,
    serverLineItemGroups: LineItemGroup[],
    serverLineItems: ServerLineItem[]
  ): AggregatedCreditNote => {
    return {
      ...serverCreditNote,
      lineItemGroups: lineItemGroups.fromServer(
        serverLineItemGroups,
        serverLineItems
      )
    }
  }
}

const filterInvalidLineItems = (lineItems: ServerLineItem[]) => {
  const filtered = lineItems.filter(
    lineItem => lineItem.groupId !== null
  ) as ServerLineItemWithGroup[]
  if (filtered.length !== lineItems.length) {
    console.error('filterInvalidLineItems: Invalid line items found')
  }
  return filtered
}
