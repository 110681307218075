import {
  PostActionStage,
  PricingEditorFormData,
  PricingEditorPrice
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import {
  INITIAL_PRICING_EDITOR_STATE,
  NEW_PRICE_PATTERN
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import deepmerge from 'deepmerge'
import { dequal } from 'dequal'

const getInitialFormData = (
  price: PricingEditorPrice
): PricingEditorFormData => {
  return deepmerge(
    INITIAL_PRICING_EDITOR_STATE.data.formData,
    {
      common: price.common,
      [price.common.pricingModel]: price.modelSpecific
    } as Partial<PricingEditorFormData>,
    {
      arrayMerge: (_, source: unknown[]) => source
    }
  )
}

/**
 * If the selected price id has changed during this 'tick', initialise
 * the form appropriately based on the data from that new price.
 *
 * @param param0
 * @returns
 */
export const initializeForm: PostActionStage =
  ({ preActionState }) =>
  prevState => {
    if (!prevState.editor?.selectedPrice) {
      return prevState
    }

    /**
     * Note that this behaviour is a smidge more complex than strictly neccessary if the
     * prices are all laoded in ahead of time using the prices loader. However we currently
     * have a bug in the prices list where the externalIds aren't returned. This means
     * we need to dynamically load in the price. As a result, this side effect handler needs
     * to be able to handle the scenario where the actively viewed 'form' is an existing price
     * which changes whilst active (and, therefore, we want to load the new values into the form).
     */
    const preActionSelectedPrice = preActionState.editor.selectedPrice
      ? preActionState.data.prices[preActionState.editor.selectedPrice]
      : null
    const currentSelectedPrice =
      prevState.data.prices[prevState.editor?.selectedPrice]

    /**
     * If nothing has changed, we have nothing to reinitialise.
     */
    if (dequal(preActionSelectedPrice, currentSelectedPrice)) {
      return prevState
    }

    /**
     * However, we also don't want to reinitialise if we are currently looking
     * at a new price - since the user will be making changes, so we don't
     * want to overwrite them.
     */
    if (
      prevState.editor.selectedPrice === preActionState.editor.selectedPrice &&
      prevState.editor.selectedPrice.match(NEW_PRICE_PATTERN)
    ) {
      return prevState
    }

    const newlySelectedPrice =
      prevState.data.prices[prevState.editor?.selectedPrice]

    if (!newlySelectedPrice) {
      throw new Error(
        `Could not find price data for id ${prevState.editor?.selectedPrice}`
      )
    }

    return {
      ...prevState,
      data: {
        ...prevState.data,
        formData: getInitialFormData(newlySelectedPrice)
      }
    }
  }
