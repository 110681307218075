import { Box, Flex, Image } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import netsuiteCover from './netsuiteCover.png'

export const NetsuiteIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={netsuiteCover}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Sequence provides NetSuite-compatible invoice exports via the invoices
        page in the dashboard.
      </Box>
    </Flex>
  )
}
