import { useToast } from '@chakra-ui/react'
import {
  useStytchB2BClient,
  useStytchIsAuthorized
} from '@stytch/react/dist/b2b'
import { Member } from '@stytch/vanilla-js'
import {
  MagicTableColumnDef,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { Toast } from 'components/TeamSettings/common/Toast'
import { generateMemberViewData } from 'components/TeamSettings/helpers'
import { useTeamSettings } from 'components/TeamSettings/hooks/useTeamSettings'
import { MemberRowView } from 'components/TeamSettings/types'
import { useCallback, useMemo } from 'react'

const columns: MagicTableColumnDef<MemberRowView>[] = [
  {
    id: 'email',
    accessorKey: 'email',
    header: 'Email',
    cell: props => props.row.original.email,
    size: 650
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: props => props.row.original.status,
    size: 95
  },
  {
    id: 'actions',
    accessorKey: 'actions',
    cell: props => props.row.original.actions,
    header: ' '
  }
]

export const useMembersTable = () => {
  const stytch = useStytchB2BClient()

  const { members, user, filter, setSelectedMember, setAction } =
    useTeamSettings()

  const toast = useToast()

  const { isAuthorized: canDeleteMembers } = useStytchIsAuthorized(
    'stytch.member',
    'delete'
  )

  const filteredMembers = useMemo(
    () =>
      members.filter(member => {
        if (filter === 'invited') {
          return member.status === 'invited'
        }
        if (filter === 'active') {
          return member.status === 'active'
        }
        return true
      }),
    [filter, members]
  )

  const handleResendInvite = useCallback(
    async (member: Member) => {
      const response = await stytch.magicLinks.email.invite({
        email_address: member.email_address,
        invite_template_id: 'magic_link_org_invite_email'
      })

      if (response.status_code === 200) {
        toast({
          position: 'bottom-left',
          isClosable: true,
          render: () => (
            <Toast
              type="success"
              title="Invitation sent!"
              description={`Invitation was sent to ${member.email_address}`}
              isClosable
            />
          )
        })
      }
    },
    [stytch.magicLinks.email, toast]
  )

  const handleRevokeInvite = useCallback(
    (member: Member) => {
      setSelectedMember(member)
      setAction(member.status === 'invited' ? 'revoke' : 'delete')
    },
    [setAction, setSelectedMember]
  )

  const data: MemberRowView[] = useMemo(
    () =>
      generateMemberViewData(
        filteredMembers,
        user.member_id,
        canDeleteMembers,
        handleResendInvite,
        handleRevokeInvite
      ),
    [
      filteredMembers,
      user.member_id,
      canDeleteMembers,
      handleResendInvite,
      handleRevokeInvite
    ]
  )

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return table
}
