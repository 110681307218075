import {
  ActionHandler,
  RemovePrice
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const removePrice: ActionHandler<RemovePrice> = prevState => action => {
  const { [action.payload]: _, ...remainingPrices } = prevState.data.prices

  return {
    ...prevState,
    data: {
      ...prevState.data,
      prices: remainingPrices
    }
  }
}
