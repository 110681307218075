import { FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { useModalContext } from '@sequencehq/core-components'
import { baseApi } from 'features/api/baseApi'
import { useDispatch } from 'features/store'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  dashboardv99990101Client,
  type Dashboardv99990101CommonModels
} from '@sequencehq/api/dashboard/v99990101'

interface LoadedData {
  customer: Dashboardv99990101CommonModels.Customers.Customer | null
}

enum Status {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  UNLINKING = 'UNLINKING',
  LOADED = 'LOADED'
}

type UseUnlinkCustomerModal = (props: {
  customerId: string
  service: IntegrationServices
  onConfirm?: () => void
  onCancel?: () => void
}) => {
  loaded: boolean
  customerName: string
  serviceName: string
  cancel: {
    onClick: () => void
  }
  submit: {
    onClick: () => Promise<void>
    disabled: boolean
    unlinking: boolean
  }
}

/**
 * This hook powers the unlink customer modal. The modal itself is designed to
 * be used in any situation, and therefore packs its own data loading capabilities.
 * As of the point of implementation, we expect to be using this modal in
 * the context of the 'Inspector' present in Quote and Customer screens as well
 * as a kebab menu item in the customer list.
 *
 * @param props
 * @returns
 */
export const useUnlinkCustomerModal: UseUnlinkCustomerModal = props => {
  const globalStoreDispatch = useDispatch()
  const { displayNotification } = useNotifications()
  const [status, setStatus] = useState<Status>(Status.UNINITIALIZED)
  const [loadedData, setLoadedData] = useState<LoadedData>({
    customer: null
  })
  const modalContext = useModalContext()

  const serviceName = useMemo(() => {
    return getIntegrationName(props.service)
  }, [props.service])
  /**
   * A simple load function which will grab the customer and the integration
   * customers for the given service.
   */
  const load = useCallback(async ({ customerId }: { customerId: string }) => {
    setStatus(Status.LOADING)

    const customer = await dashboardv99990101Client.getCustomer({
      id: customerId
    })

    setLoadedData({
      customer: customer.data ?? null
    })
    setStatus(Status.LOADED)
  }, [])

  const unlinkCustomer = useCallback(
    async ({
      service,
      customerId
    }: {
      service: IntegrationServices
      customerId: string
    }) => {
      setStatus(Status.UNLINKING)

      const res = await dashboard20240730Client.deleteLinkedEntity({
        type: 'CUSTOMER',
        id: customerId,
        service
      })

      setStatus(Status.LOADED)
      if (res.error) {
        displayNotification(`Could not unlink customer from ${serviceName}`, {
          type: 'error'
        })
        return false
      }

      displayNotification(`Customer unlinked from ${serviceName}`, {
        type: 'success'
      })
      return true
    },
    [displayNotification, serviceName]
  )

  useEffect(() => {
    void load({
      customerId: props.customerId
    })
  }, [props.customerId, load])

  const cancel = useMemo(() => {
    return {
      onClick: () => {
        props.onCancel?.()
      }
    }
  }, [props])

  const submit = useMemo(() => {
    return {
      onClick: async () => {
        const success = await unlinkCustomer({
          service: props.service,
          customerId: props.customerId
        })
        if (success) {
          /**
           * Clear the cache so that any functionality still tied into rtk-query will also
           * update as expected.
           */
          globalStoreDispatch(
            /**
             * This is a valid argument for the function (even in the examples!) but the typing
             * incorrectly complains about it, so forcibly cast it.
             */
            baseApi.util.invalidateTags([
              'Customers' as unknown as FullTagDescription<never>
            ])
          )

          props.onConfirm?.()
          modalContext.setIsOpen(false)
        }
      },
      disabled: status === Status.UNLINKING,
      unlinking: status === Status.UNLINKING
    }
  }, [status, unlinkCustomer, props, modalContext, globalStoreDispatch])

  return {
    loaded: ![Status.LOADING, Status.LOADING].includes(status),
    customerName: loadedData.customer?.legalName ?? '',
    serviceName,
    cancel,
    submit
  }
}
