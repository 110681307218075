import { Box } from '@chakra-ui/react'
import { GreyGrey40 } from '@sequencehq/design-tokens'
import { ReactNode } from 'react'

export const Container: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="390px"
      height="291px"
      padding="16px"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      border={`1px solid ${GreyGrey40}`}
      borderRadius="lg"
      p={4}
    >
      {children}
    </Box>
  )
}
