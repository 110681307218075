import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import { FC, useEffect } from 'react'
import { unstable_useBlocker as useBlocker, Location } from 'react-router-dom'

type BlockerProps = {
  dirty: boolean
  submitting: boolean
  name: string
  ignoreBlock?: (nextLocation: Location) => boolean
  modalTitle?: string
  message?: string
  submitTitle?: string
  cancelTitle?: string
}

export const Blocker: FC<BlockerProps> = ({
  dirty,
  submitting,
  name,
  ignoreBlock,
  modalTitle = `Leave ${name} form?`,
  message = 'You have unsaved changes which will be lost',
  submitTitle = 'Proceed',
  cancelTitle = 'Cancel'
}) => {
  const blocked = dirty && !submitting
  const blocker = useBlocker(({ nextLocation }) => {
    if (ignoreBlock && ignoreBlock(nextLocation)) {
      return false
    }

    return blocked
  })

  useEffect(() => {
    if (blocker.state === 'blocked' && !blocked) {
      return blocker.reset()
    }
  }, [blocker, blocked])

  return (
    <ModalContextProvider isOpen={blocker.state === 'blocked'}>
      <SimpleDialogUI
        title={modalTitle}
        onConfirm={() => blocker.state === 'blocked' && blocker.proceed()}
        onCancel={() => blocker.state === 'blocked' && blocker.reset()}
        confirmButtonText={submitTitle}
        cancelButtonText={cancelTitle}
        destructive
      >
        {message}
      </SimpleDialogUI>
    </ModalContextProvider>
  )
}
