import { FC } from 'react'

interface XeroIconProps {
  width?: number
  height?: number
}

export const XeroIcon: FC<XeroIconProps> = ({ width = 48, height = 48 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 -3.8147e-06 24 -3.8147e-06C10.7452 -3.8147e-06 0 10.7452 0 24C0 37.2549 10.7452 48 24 48Z"
      fill="#1AB4D7"
    />
    <path
      d="M11.4869 23.9302L15.5827 19.8243C15.7184 19.6859 15.7942 19.5027 15.7942 19.3083C15.7942 18.9033 15.465 18.5745 15.06 18.5745C14.8627 18.5745 14.6778 18.6518 14.5383 18.793C14.5379 18.7938 10.4462 22.8831 10.4462 22.8831L6.3358 18.7868C6.19691 18.6499 6.01287 18.5745 5.81772 18.5745C5.41322 18.5745 5.08392 18.9032 5.08392 19.3078C5.08392 19.5051 5.16272 19.6903 5.30414 19.8298L9.39936 23.9245L5.30626 28.0253C5.16239 28.1669 5.08392 28.3525 5.08392 28.5508C5.08392 28.9557 5.41322 29.2842 5.81772 29.2842C6.0132 29.2842 6.1974 29.2083 6.3358 29.0698L10.4398 24.9714L14.5283 29.0549C14.6733 29.2048 14.8604 29.2847 15.06 29.2847C15.4646 29.2847 15.7938 28.9557 15.7938 28.5508C15.7938 28.3554 15.7181 28.1718 15.5804 28.0334L11.4869 23.9302Z"
      fill="white"
    />
    <path
      d="M35.8339 23.9297C35.8339 24.665 36.4319 25.2632 37.1681 25.2632C37.9026 25.2632 38.5008 24.665 38.5008 23.9297C38.5008 23.1941 37.9026 22.596 37.1681 22.596C36.4319 22.596 35.8339 23.1941 35.8339 23.9297Z"
      fill="white"
    />
    <path
      d="M33.3085 23.9287C33.3085 21.7992 35.0408 20.0666 37.1705 20.0666C39.2992 20.0666 41.0319 21.7992 41.0319 23.9287C41.0319 26.0576 39.2992 27.7896 37.1705 27.7896C35.0408 27.7896 33.3085 26.0576 33.3085 23.9287ZM31.7893 23.9287C31.7893 26.8954 34.2033 29.3091 37.1705 29.3091C40.1377 29.3091 42.5529 26.8954 42.5529 23.9287C42.5529 20.9615 40.1377 18.5472 37.1705 18.5472C34.2033 18.5472 31.7893 20.9615 31.7893 23.9287Z"
      fill="white"
    />
    <path
      d="M31.4048 18.6415L31.179 18.6408C30.5012 18.6408 29.8475 18.8546 29.3015 19.2753C29.2296 18.9461 28.9353 18.6984 28.5848 18.6984C28.1814 18.6984 27.8581 19.0216 27.8571 19.4259C27.8571 19.4274 27.8596 28.4892 27.8596 28.4892C27.8606 28.8927 28.1898 29.2207 28.5934 29.2207C28.9971 29.2207 29.3261 28.8927 29.3272 28.4883C29.3272 28.4865 29.3275 22.9155 29.3275 22.9155C29.3275 21.0581 29.4973 20.308 31.0884 20.1091C31.2354 20.0909 31.3954 20.0937 31.3961 20.0937C31.8314 20.0788 32.1408 19.7796 32.1408 19.3753C32.1408 18.9707 31.8105 18.6415 31.4048 18.6415Z"
      fill="white"
    />
    <path
      d="M17.3157 23.0446C17.3157 23.0245 17.3172 23.0036 17.3182 22.983C17.7442 21.2988 19.2696 20.0524 21.0862 20.0524C22.9247 20.0524 24.464 21.3294 24.8682 23.0446H17.3157ZM26.371 22.9061C26.0548 21.4088 25.2351 20.179 23.9869 19.3892C22.1623 18.2308 19.7529 18.2949 17.9906 19.5482C16.553 20.5708 15.7232 22.2438 15.7232 23.9671C15.7232 24.3992 15.7753 24.8354 15.8837 25.2647C16.4266 27.4001 18.2622 29.0165 20.4508 29.2844C21.1003 29.3631 21.7324 29.3254 22.387 29.1559C22.9495 29.0191 23.4938 28.7909 23.9953 28.4696C24.5158 28.1349 24.9507 27.6937 25.3719 27.1655C25.3804 27.1558 25.3889 27.1472 25.3974 27.137C25.6897 26.7745 25.6355 26.2589 25.3143 26.0128C25.0434 25.8052 24.5886 25.721 24.2307 26.1793C24.1538 26.2889 24.068 26.4016 23.9735 26.5144C23.689 26.8288 23.3359 27.1335 22.9129 27.3698C22.3745 27.6574 21.7611 27.8217 21.1089 27.8254C18.9744 27.8016 17.8322 26.3117 17.4259 25.2482C17.3549 25.0496 17.3002 24.8436 17.2621 24.6314C17.2571 24.5918 17.2534 24.5535 17.252 24.5182C17.6933 24.5182 24.9118 24.517 24.9118 24.517C25.962 24.4949 26.5272 23.7537 26.371 22.9061Z"
      fill="white"
    />
  </svg>
)
