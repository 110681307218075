import { Flex, IconButton, Text } from '@chakra-ui/react'
import { useInvoiceEditorSummary } from './useInvoiceEditorSummary'
import {
  GreyGrey70,
  Lato14Regular,
  LineHeights20
} from '@sequencehq/design-tokens'
import { DatePickerInput } from '@sequencehq/core-components'
import { PencilSquareIcon } from '@heroicons/react/16/solid'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { format } from '@sequencehq/utils/dates'

export const InvoiceEditorSummaryInvoiceDate = () => {
  const { data: contextData, functions } = useInvoiceEditorContext()

  const { data, editInvoiceDate } = useInvoiceEditorSummary()

  return (
    <>
      <Text {...Lato14Regular} lineHeight={LineHeights20} color={GreyGrey70}>
        Invoice date
      </Text>
      <Flex alignItems="center" gap={1} role="group">
        <Text
          {...Lato14Regular}
          lineHeight={LineHeights20}
          color={data.invoiceDate ? 'gray.90' : 'gray.60'}
          flexShrink={0}
        >
          {data.invoiceDate || '—'}
        </Text>

        {editInvoiceDate.available && (
          <DatePickerInput
            name="invoiceDate"
            value={
              contextData.invoice.accountingDate
                ? new Date(contextData.invoice.accountingDate)
                : undefined
            }
            onChange={newDate => {
              const formattedDate = newDate
                ? format(newDate, 'yyyy-MM-dd')
                : undefined

              if (formattedDate === contextData.invoice.accountingDate) {
                return
              }

              functions.updateData({
                ...contextData,
                invoice: {
                  ...contextData.invoice,
                  accountingDate: formattedDate
                }
              })

              editInvoiceDate.onChange(formattedDate)
            }}
            variant="new"
            width="226px"
            trigger={
              <IconButton
                aria-label="Edit invoice date"
                minWidth="16px"
                height="16px"
                width="16px"
                icon={<PencilSquareIcon height="16px" width="16px" />}
                variant="ghost2"
                visibility="hidden"
                _groupHover={{
                  visibility: 'visible'
                }}
              />
            }
          />
        )}
      </Flex>
    </>
  )
}
