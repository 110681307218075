import { Box, Flex, Table, Td, Text, Tr } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { currencyToSymbol } from '@sequencehq/core-models'
import { calculateYAxisLabels } from 'RevenueRecognition/view/utils/graphUtils'
import { Currency, useQuery } from '@sequencehq/api/dist/utils'
import { ProgressBar } from 'RevenueRecognition/view/common/ProgressBar'
import { Skeleton, XYChart } from '@sequencehq/core-components'
import { ChartTooltip } from 'RevenueRecognition/view/common/ChartTooltip'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import invariant from 'tiny-invariant'
import { add, format, sub } from '@sequencehq/utils/dates'
import { UserCircleIcon } from '@heroicons/react/16/solid'
import { toMoney } from '@sequencehq/utils'

/**
 * The tooltip displayed by the XYChart component when hovering over
 * different points in the chart.
 * @param date - the formatted date of the item
 * @param balances - the balances on the date
 * @param currency - the currency of the balances
 * @returns
 */
const GraphDataTooltip = ({
  date,
  userEmail,
  balances,
  currency
}: {
  date: Date
  userEmail?: string
  currency: Currency
  balances: {
    remaining: {
      absolute: number
      percentage: number
    }
    recognized: {
      absolute: number
      percentage: number
    }
  }
}) => {
  return (
    <ChartTooltip width="344px">
      <ChartTooltip.Header>
        <Text {...Lato13Bold} color={GreyGrey70}>
          {format(date, 'd MMM yyyy')}
        </Text>
      </ChartTooltip.Header>
      <ChartTooltip.Body padding="0 12px 4px">
        <Box width="100%">
          <Table variant="v2" width="100%" {...Lato13Regular}>
            <Tr borderBottom={`1px solid ${GreyGrey30}`}>
              <Td width="113px" color={GreyGrey80} padding={'8px 0'}>
                Recognized
              </Td>
              <Td color={GreyGrey60} padding={'8px 0'} textAlign="right">
                <Flex gap="8px" alignItems="center" justifyContent="flex-end">
                  {balances.recognized.percentage}%
                  <ProgressBar
                    width="56px"
                    percentage={balances.recognized.percentage}
                  />
                </Flex>
              </Td>
              <Td
                {...Lato13Bold}
                color={GreyGrey90}
                padding={'8px 0'}
                textAlign="right"
                width="74px"
              >
                {toMoney({
                  value: balances.recognized.absolute.toString(),
                  currency
                })}
              </Td>
            </Tr>
            <Tr borderBottom={userEmail ? `1px solid ${GreyGrey30}` : 'none'}>
              <Td color={GreyGrey80} padding={'8px 0'}>
                Remaining balance
              </Td>
              <Td color={GreyGrey60} padding={'8px 0'} textAlign="right">
                <Flex gap="8px" alignItems="center" justifyContent="flex-end">
                  {balances.remaining.percentage}%
                  <ProgressBar
                    width="56px"
                    percentage={balances.remaining.percentage}
                  />
                </Flex>
              </Td>
              <Td
                {...Lato13Bold}
                width="100%"
                color={GreyGrey90}
                padding={'8px 0'}
                textAlign="right"
              >
                {toMoney({
                  value: balances.remaining.absolute.toString(),
                  currency
                })}
              </Td>
            </Tr>
            {userEmail && (
              <Tr>
                <Td colSpan={3} padding={'8px 0'}>
                  <Flex gap="6px" alignItems="center">
                    <UserCircleIcon
                      height="16px"
                      width="16px"
                      color={GreyGrey70}
                    />
                    <Box color={GreyGrey70} {...Lato13Regular}>
                      {userEmail}
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            )}
          </Table>
        </Box>
      </ChartTooltip.Body>
    </ChartTooltip>
  )
}

interface ChartData {
  currency: Currency
  dateFrom: Date
  dateTo: Date
  dataSeries: Array<{
    date: string
    value: number
    userEmail?: string
    balances: {
      recognized: {
        percentage: number
        absolute: number
      }
      remaining: {
        percentage: number
        absolute: number
      }
    }
  }>
}

type UseDefRevScheduleProgressGraph = (props: { scheduleId: string }) =>
  | {
      chartData: ChartData
      isLoading: false
    }
  | {
      chartData: null
      isLoading: true
    }

/**
 * Data acquisition for the graph
 * @param scheduleId - the schedule id
 * @returns
 */
export const useDefRevScheduleProgressGraph: UseDefRevScheduleProgressGraph =
  props => {
    const scheduleQuery = useQuery(
      dashboardv99990101Client.getDeferredRevenueSchedule,
      { id: props.scheduleId },
      {
        select: res => {
          if (!res) {
            return null
          }

          const apiDateSeries = res.progress.details.map(detail => ({
            date: detail.date,
            value:
              parseFloat(res.progress.original) - parseFloat(detail.balance),
            userEmail: detail.invokingUser?.name,
            balances: {
              recognized: {
                percentage:
                  100 -
                  Math.round(
                    (parseFloat(detail.balance) /
                      parseFloat(res.progress.original)) *
                      100
                  ),
                absolute:
                  parseFloat(res.progress.original) - parseFloat(detail.balance)
              },
              remaining: {
                percentage: Math.round(
                  (parseFloat(detail.balance) /
                    parseFloat(res.progress.original)) *
                    100
                ),
                absolute: parseFloat(detail.balance)
              }
            }
          }))

          /**
           * Milestone recognition methods will not have any kind of recognition,
           * so we want to extend them into the future to demonstrate how the
           * progression will look. We also need to, for a milestone, massage
           * the data to give us a nice 'step' appearence in a line chart.
           */
          const dataSeriesWithMilestoneMeddling =
            res.recognitionMethod === 'MILESTONE' && apiDateSeries.length
              ? [
                  ...apiDateSeries.flatMap((item, idx) => {
                    if (idx === 0) {
                      return item
                    }

                    return [
                      {
                        ...apiDateSeries[idx - 1],
                        date: format(sub(item.date, { days: 1 }), 'yyyy-MM-dd')
                      },
                      {
                        ...apiDateSeries[idx - 1],
                        date: item.date
                      },
                      item
                    ]
                  }),
                  {
                    ...apiDateSeries[apiDateSeries.length - 1],
                    date: format(
                      add(apiDateSeries[apiDateSeries.length - 1].date, {
                        years: 1
                      }),
                      'yyyy-MM-dd'
                    )
                  }
                ]
              : apiDateSeries

          return {
            currency: res.progress.currency,
            dataSeries: dataSeriesWithMilestoneMeddling,
            dateFrom: new Date(dataSeriesWithMilestoneMeddling[0].date),
            dateTo: new Date(
              dataSeriesWithMilestoneMeddling[
                dataSeriesWithMilestoneMeddling.length - 1
              ].date
            )
          }
        }
      }
    )

    /**
     * We throw this error to be caught by the appropriate error boundary.
     */
    if (scheduleQuery.error) {
      throw new Error('Deferred revenue chart data could not be loaded')
    }

    if (scheduleQuery.isPending) {
      return {
        isLoading: true,
        chartData: null
      }
    }

    invariant(scheduleQuery.data, 'Chart data should be defined, if loaded')

    return {
      isLoading: false,
      chartData: scheduleQuery.data
    }
  }

export const DefRevScheduleProgressGraph = (props: {
  defRevScheduleId: string
}) => {
  const { isLoading, chartData } = useDefRevScheduleProgressGraph({
    scheduleId: props.defRevScheduleId
  })

  const yAxisLabels = isLoading
    ? []
    : calculateYAxisLabels(chartData.dataSeries.map(d => d.value))

  return (
    <Box position="relative">
      <Box {...Lato13Bold} color={GreyGrey80} marginBottom="8px">
        Recognition flow
      </Box>
      {isLoading ? (
        <Skeleton width="100%" height="292px" />
      ) : (
        <Box width="100%" height="292px">
          <XYChart<{
            date: string
            value: number
            userEmail?: string
            balances: {
              remaining: {
                absolute: number
                percentage: number
              }
              recognized: {
                absolute: number
                percentage: number
              }
            }
          }>
            variant="line"
            dateFrom={format(chartData.dateFrom, 'yyyy-MM-dd')}
            dateTo={format(chartData.dateTo, 'yyyy-MM-dd')}
            data={chartData.dataSeries}
            dateFormat="d MMM yyyy"
            unit={currencyToSymbol[chartData?.currency]}
            animate={false}
            unitTickValues={yAxisLabels}
            renderTooltip={item => (
              <GraphDataTooltip
                {...item}
                currency={chartData?.currency}
                date={new Date(item.date)}
              />
            )}
          />
        </Box>
      )}
    </Box>
  )
}
