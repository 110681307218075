import { useQuery } from '@sequencehq/api/utils'
import {
  dashboardv99990101Client,
  Dashboardv99990101CommonModels
} from '@sequencehq/api/dist/clients/dashboard/v99990101'

export type TaxIntegrationProvider =
  Dashboardv99990101CommonModels.TaxIntegration.TaxIntegrationProvider
export const TAX_INTEGRATION_PROVIDERS: Record<
  TaxIntegrationProvider,
  TaxIntegrationProvider
> = {
  AVALARA: 'AVALARA',
  SEQUENCE: 'SEQUENCE'
}

const getTaxIntegrationProviderFlags = (
  taxIntegrationProvider: TaxIntegrationProvider | undefined
) => ({
  isAvalara: taxIntegrationProvider === TAX_INTEGRATION_PROVIDERS.AVALARA,
  isSequence: taxIntegrationProvider === TAX_INTEGRATION_PROVIDERS.SEQUENCE,
  isExternalTaxIntegrationProvider:
    taxIntegrationProvider !== TAX_INTEGRATION_PROVIDERS.SEQUENCE,
  taxIntegrationProvider
})

// We sometimes check the provider outside of React components so this is the same that hook does just in an async fn
export const getTaxIntegrationProvider = async () => {
  const result = await dashboardv99990101Client.getEnabledTaxIntegration()

  const taxIntegrationProvider = result?.data?.taxIntegrationProvider

  return getTaxIntegrationProviderFlags(taxIntegrationProvider)
}

export const useTaxIntegrationProvider = () => {
  const { data: taxIntegrationData } = useQuery(
    dashboardv99990101Client.getEnabledTaxIntegration
  )

  const taxIntegrationProvider = taxIntegrationData?.taxIntegrationProvider

  return getTaxIntegrationProviderFlags(taxIntegrationProvider)
}
