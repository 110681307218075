import { Box, Flex } from '@chakra-ui/react'
import { Controls } from 'components/InsightsV2/Controls'
import { DEFAULT_PAGE_SIDEBAR_WIDTH } from 'lib/constants'
import { useMemo, useState } from 'react'
import { EventTypesWidget } from 'components/InsightsV2/widgets/EventTypesWidget/EventTypesWidget'
import { InvoicedRevenueWidget } from 'components/InsightsV2/widgets/InvoicedRevenueWidget/InvoicedRevenueWidget'
import OutstandingInvoices from 'components/InsightsV2/widgets/OutstandingInvoices/OutstandingInvoices'
import TotalCustomersWidget from 'components/InsightsV2/widgets/TotalCustomersWidget/TotalCustomersWidget'
import { UsageMetricsWidget } from 'components/InsightsV2/widgets/UsageMetricsWidget/UsageMetricsWidget'
import { lastDayOfMonth, startOfMonth, sub } from '@sequencehq/utils/dates'

const NOW = new Date()

const DATE_RANGE_PRESETS = {
  this_month: [new Date(startOfMonth(NOW)), new Date(lastDayOfMonth(NOW))],
  '3_months': [new Date(startOfMonth(sub(NOW, { months: 2 }))), NOW],
  '6_months': [new Date(startOfMonth(sub(NOW, { months: 5 }))), NOW],
  last_year: [new Date(startOfMonth(sub(NOW, { months: 12 }))), NOW]
}

export const InsightsV2 = () => {
  const [preset, setPreset] = useState('this_month')

  const dateRange = useMemo(
    () => ({
      start: DATE_RANGE_PRESETS[preset as keyof typeof DATE_RANGE_PRESETS][0],
      end: DATE_RANGE_PRESETS[preset as keyof typeof DATE_RANGE_PRESETS][1]
    }),
    [preset]
  )

  return (
    <Flex
      flex="1"
      flexDirection="column"
      overflow="hidden"
      width="100%"
      height="100%"
    >
      <Controls
        preset={preset}
        onChangePreset={(newPreset: string) => {
          setPreset(newPreset)
        }}
      />
      <Box width="100%" height="100%">
        <Flex
          width="100%"
          flexWrap="wrap"
          gap={4}
          padding={4}
          transform="translateX(8px)"
        >
          <TotalCustomersWidget />
          <InvoicedRevenueWidget dateRange={dateRange} />
          <OutstandingInvoices />
          <EventTypesWidget dateRange={dateRange} />
          <UsageMetricsWidget dateRange={dateRange} />
        </Flex>
      </Box>
    </Flex>
  )
}
