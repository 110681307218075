import {
  CubeReducerState,
  CubeStatus,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getPreviewQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: [
        CubeStatus.QuoteDraft,
        CubeStatus.QuotePublished,
        CubeStatus.QuoteReadyToSign
      ].includes(prevState.data.common.status),
      enabled: [CubeStatus.QuoteDraft].includes(prevState.data.common.status)
    },
    reasons: []
  }
}
