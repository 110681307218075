import { createContext, useContext } from 'react'
import { dequal } from 'dequal'
import { PricingEditorDomainInterface } from '../../domain'

const PricingEditorContext = createContext<PricingEditorDomainInterface>({
  queries: {} as PricingEditorDomainInterface['queries'],
  mutators: {} as PricingEditorDomainInterface['mutators']
})

export const usePricingEditorDomainContext = () => {
  const pricingEditorContext = useContext(PricingEditorContext)

  if (dequal(pricingEditorContext, { queries: {}, mutators: {} })) {
    throw new Error('Cannot use context before initialisation')
  }

  return pricingEditorContext
}

export const PricingEditorProvider = PricingEditorContext.Provider
