import { INITIAL_EXAMPLE_STATE } from 'modules/Integrations/domain/integrations.constants'
import {
  ActionHandler,
  LoadAction
} from 'modules/Integrations/domain/integrations.domain.types'

export const load: ActionHandler<LoadAction> = () => action => {
  /**
   * When loading, we will remove any existing state, and start over. Therefore
   * we do not use the prevState here and instead use the initial value.
   *
   * Note: it may be the case in the future that we may wish to retain some
   * data, like the configuration for the editor, but for now we will reset everything
   * and look to introduce further options to the action if that is indeed the case.
   **/

  return {
    ...INITIAL_EXAMPLE_STATE,
    ...action.payload,
    editor: {
      ...INITIAL_EXAMPLE_STATE.editor,
      loaded: true
    },
    initialData: action.payload.data
  }
}
