import type { ComponentProps } from 'react'
import { InputSelectControl } from '@sequencehq/core-components'

type InputSelectControlProps = ComponentProps<typeof InputSelectControl>

type Props = {
  placeholder: InputSelectControlProps['placeholder']
  value: InputSelectControlProps['initialValue']
  options: InputSelectControlProps['options']
  onChange: InputSelectControlProps['onChange']
}

export const FilterSelectInput = ({
  placeholder,
  value,
  options,
  onChange
}: Props) => {
  return (
    <InputSelectControl
      placeholder={placeholder}
      initialValue={value}
      options={options}
      onChange={onChange}
    />
  )
}
