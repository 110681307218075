import { ComponentProps } from 'react'
import { SelectField } from '@sequencehq/core-components'
import { Link } from '@chakra-ui/react'

export type UsageCalculationPeriodDropdownValue =
  | 'BILLING_PERIOD'
  | 'CUMULATIVE_USAGE'
  | 'QUARTERLY'
  | 'ANNUAL'

interface Props
  extends Pick<
    ComponentProps<typeof SelectField>,
    'validationErrors' | 'isDisabled'
  > {
  options: { label: string; value: UsageCalculationPeriodDropdownValue }[]
  value: UsageCalculationPeriodDropdownValue
  onChange: (value: UsageCalculationPeriodDropdownValue) => void
}

const desriptionByValue: Record<UsageCalculationPeriodDropdownValue, string> = {
  BILLING_PERIOD: 'Calculate usage for each billing period.',
  CUMULATIVE_USAGE:
    'Tiers in each billing period are based on all prior usage in the schedule.',
  QUARTERLY: 'Tiers in each billing period are based on quarterly usage.',
  ANNUAL: 'Tiers in each billing period are based on annual usage.'
}

const UsageCalculationPeriodDropdown = ({
  options,
  value,
  isDisabled,
  onChange,
  ...selectFieldProps
}: Props) => {
  return (
    <SelectField
      label="Usage calculation period"
      placeholder="Select usage calculation mode"
      options={options}
      value={value}
      isDisabled={isDisabled}
      onChange={selectedValue =>
        onChange(selectedValue as UsageCalculationPeriodDropdownValue)
      }
      description={
        <>
          {desriptionByValue[value]}{' '}
          <Link
            href="https://docs.sequencehq.com/metrics-events/usage-metrics#usage-calculation-period"
            isExternal
            color="indigo.50"
          >
            Learn more
          </Link>
        </>
      }
      {...selectFieldProps}
    />
  )
}

export default UsageCalculationPeriodDropdown
