import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import {
  Lato14ExtendedRegular,
  Lato14Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Section } from 'modules/LoginApp/components/common/Section'
import { GoogleMailIcon } from 'components/icons/GoogleMailIcon'
import { OutlookIcon } from 'components/icons/OutlookIcon'
import { Wordmark } from 'components/icons/Wordmark'

export const CheckEmailPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')

  useEffect(() => {
    if (!email) {
      navigate(`/login`)
    }
  }, [email, navigate])

  return (
    <PageContainer>
      <Wordmark width="126" height="26.74" />
      <Section gap="0" padding={0} overflow="hidden">
        <Flex padding="24px  " flexDirection="column" gap="8px">
          <Text {...Lato16Bold} color="gray.90">
            Check your email for a sign in link
          </Text>
          <Text {...Lato14ExtendedRegular} color="gray.80">
            An email was sent to <strong>{email}</strong>. The sign in link
            expires shortly and can only be used once.
          </Text>
        </Flex>
        <Flex width="100%" borderTop="1px solid" borderColor="gray.30">
          <Button
            as="a"
            size="lg"
            href="https://mail.google.com/mail/u/0/#advanced-search/subset=all&has=%22Your+login+request+to+Sequence%22"
            target="_blank"
            leftIcon={<GoogleMailIcon />}
            variant="ghost"
            width="50%"
            borderRadius={0}
            color="indigo.50"
            {...Lato14Regular}
            zIndex="10"
            _focus={{
              boxShadow: 'none'
            }}
          >
            Open Gmail
          </Button>
          <Button
            as="a"
            size="lg"
            href="https://outlook.com"
            target="_blank"
            leftIcon={<OutlookIcon />}
            variant="ghost"
            width="50%"
            borderRadius={0}
            color="indigo.50"
            {...Lato14Regular}
            zIndex="10"
            _focus={{
              boxShadow: 'none'
            }}
          >
            Open Outlook
          </Button>
        </Flex>
      </Section>
      <Text position="absolute" bottom="40px" textAlign="center" zIndex={10}>
        No email? Check your spam folder or{' '}
        <Link href="/auth/login" color="indigo.50">
          Try again
        </Link>
      </Text>
    </PageContainer>
  )
}

export default CheckEmailPage
