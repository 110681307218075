import { Box, Flex, Grid } from '@chakra-ui/react'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import {
  Button,
  Dialog,
  Modal,
  useModalContext
} from '@sequencehq/core-components'
import { FC } from 'react'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import Spinner from 'components/Loading/Spinner'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useMutation } from '@sequencehq/api/dist/utils'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications'

const useUnlinkInvoiceModal = (props: {
  service: IntegrationServices
  invoiceId: string
}) => {
  const modalContext = useModalContext()
  const notifications = useNotifications()

  const unlinkingMutation = useMutation(
    dashboard20240730Client.deleteLinkedEntity
  )

  const serviceName = getIntegrationName(props.service)
  const onUnlink = () => {
    unlinkingMutation.mutate(
      {
        type: 'INVOICE',
        id: props.invoiceId,
        service: props.service
      },
      {
        onError: () => {
          notifications.displayNotification(
            `Could not unlink invoice from ${serviceName}`,
            {
              type: 'error'
            }
          )
        },
        onSuccess: () => {
          notifications.displayNotification(
            `Invoice unlinked from ${serviceName}`,
            {
              type: 'success'
            }
          )
          modalContext.setIsOpen(false)
        }
      }
    )
  }

  return { serviceName, onUnlink, unlinking: unlinkingMutation.isPending }
}

export const UnlinkInvoiceModal: FC<{
  invoiceId: string
  service: IntegrationServices
}> = props => {
  const hook = useUnlinkInvoiceModal({
    service: props.service,
    invoiceId: props.invoiceId
  })

  return (
    <Dialog>
      <Dialog.Header showCloseButton>
        Unlink Invoice from {hook.serviceName}
      </Dialog.Header>
      <Dialog.Content>
        <Box
          color={GreyGrey80}
          {...Lato13Regular}
          flex={1}
          data-testid="modal.unlinkInvoice"
        >
          This will unlink the customer from {hook.serviceName} immediately. You
          can re-link the customer again if required.
        </Box>
      </Dialog.Content>
      <Modal.Footer>
        <Grid
          gridTemplateColumns="1fr 1fr"
          gap="8px"
          width="100%"
          paddingTop="12px"
        >
          <Modal.CloseTrigger>
            <Box width="100%">
              <Button variant="secondary" style={{ width: '100%' }}>
                Cancel
              </Button>
            </Box>
          </Modal.CloseTrigger>
          <Button
            variant="destructive"
            onClick={hook.onUnlink}
            disabled={hook.unlinking}
            data-testid="unlinkInvoice.confirm"
          >
            {hook.unlinking ? (
              <Flex gap="8px">
                <Spinner height="16px" width="16px" color={GreyGrey60} />
                Unlinking...
              </Flex>
            ) : (
              'Unlink'
            )}
          </Button>
        </Grid>
      </Modal.Footer>
    </Dialog>
  )
}
