import { StytchB2BUIClient } from '@stytch/vanilla-js/dist/b2b'

export const stytchClient = new StytchB2BUIClient(
  import.meta.env.VITE_STYTCH_PUBLIC_TOKEN,
  {
    cookieOptions: {
      jwtCookieName: import.meta.env.VITE_AUTH_SESSION_JWT_COOKIE_NAME,
      opaqueTokenCookieName: import.meta.env.VITE_AUTH_SESSION_COOKIE_NAME,
      domain: import.meta.env.VITE_AUTH_COOKIE_DOMAIN,
      availableToSubdomains: true,
      path: '/'
    }
  }
)

/**
 * Sometimes the Stytch Intermediate Session Token (IST) cookie isn't deleted after completing the org discovery auth flow.
 * Then the Stytch SDK tries to use the IST cookie instead of the Session cookie, resulting in errors.
 * So we should delete this cookie if the user is outside the auth flow.
 */
export function deleteIntermediateSessionTokenCookie() {
  const domain = import.meta.env.VITE_AUTH_COOKIE_DOMAIN
  const expires = 'Thu, 01 Jan 1970 00:00:00 UTC'
  document.cookie = `stytch_intermediate_session_token=; Domain=.${domain}; Path=/; secure; SameSite=Lax; expires=${expires}`
}
