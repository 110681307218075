import { Button, Flex, Text, Box } from '@chakra-ui/react'
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GreyWhite, Lato14ExtendedRegular } from '@sequencehq/design-tokens'
import { MagicTableEmptyStateProps } from '@sequencehq/tables'
import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import { RedirectIcon } from 'components/icons/RedirectIcon'
import { EmptyStateContent } from 'components/Loading/EmptyState'

interface SeatTableEmptyStateProps extends MagicTableEmptyStateProps {
  emptyContent: EmptyStateContent
}

const SeatTableEmptyState: FC<SeatTableEmptyStateProps> = ({
  emptyContent,
  ...props
}) => {
  const navigate = useNavigate()

  if (!emptyContent) {
    return null
  }

  const { title, description, docLink, linkCopy, route, buttonCopy } =
    emptyContent

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p="64px"
      {...props}
    >
      <Flex
        maxWidth="450px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text textStyle="emptyStateTitle">{title}</Text>
        <Box h="8px"></Box>

        <Text {...Lato14ExtendedRegular} textAlign="center">
          {description}
        </Text>
        <Flex gap={2} mt={4}>
          {route && (
            <Button
              leftIcon={
                <PlusIcon
                  style={{ marginBottom: '-1px' }}
                  color={GreyWhite}
                  height={16}
                  width={16}
                />
              }
              size="sm"
              variant="primary2"
              onClick={() => navigate(route)}
            >
              {buttonCopy}
            </Button>
          )}

          <Button
            leftIcon={<RedirectIcon />}
            as={Link}
            variant="secondary2"
            size="sm"
            to={docLink}
            target="_blank"
          >
            {linkCopy}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SeatTableEmptyState
