import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { MAX_FILE_SIZE } from 'Assets/constants'

export async function uploadFile(file: File): Promise<string> {
  if (!isValidFile(file)) {
    throw 'invalid file'
  }

  const formData = new FormData()
  formData.append('file', file)

  const res = await dashboard20240730Client.postAssets({
    formData
  })

  if (!res.data?.url) {
    throw 'invalid response'
  }

  return res.data.url
}

const VALID_TYPES = ['image/jpeg', 'image/png']

function isValidFile(file: File) {
  return VALID_TYPES.includes(file.type) && file.size <= MAX_FILE_SIZE
}
