import { useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'

import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dashboard/v20240509'
import { useNotifications } from 'lib/hooks/useNotifications'

type QuoteSettings = Dashboardv20240509Api.GetQuoteSettings.QuoteSettings

export type UseQuoteSettingsInterface = {
  isLoading: boolean
  linkDocumentation: {
    visible: boolean
    href: string
  }
  quoteSettings: QuoteSettings
  functions: {
    onSubmit: (props: {
      name?: string
      email?: string
      isSignaturesEnabled: boolean
    }) => Promise<void>
  }
}

type UseQuoteSettings = () => UseQuoteSettingsInterface

export const useQuoteSettings: UseQuoteSettings = () => {
  const notifications = useNotifications()

  const [quoteSettings, setQuoteSettings] = useState<QuoteSettings>(
    {} as QuoteSettings
  )

  const [isLoading, setIsLoading] = useState(true)

  const primarySigner = quoteSettings.counterSigners?.[0]

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await dashboardv20240509Client.getQuoteSettings()

        if (!response.data || response.error) {
          throw new Error('Unable to fetch quote settings')
        }

        setQuoteSettings(response.data)

        setIsLoading(false)
      } catch (e) {
        notifications.displayNotification('Unable to fetch quote settings', {
          type: 'error'
        })

        Sentry.captureException(e)
      }
    }

    void loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async ({
    name,
    email,
    isSignaturesEnabled
  }: {
    name?: string
    email?: string
    isSignaturesEnabled: boolean
  }) => {
    try {
      const counterSigners =
        name && email
          ? [
              {
                id: primarySigner?.id,
                name,
                email
              }
            ]
          : []

      const response = await dashboardv20240509Client.putQuoteSettings({
        body: {
          isSignaturesEnabled,
          counterSigners
        }
      })

      if (!response.data || response.error) {
        throw new Error('Unable to update quote settings')
      }

      setQuoteSettings(response.data)

      notifications.displayNotification('Quote settings updated', {
        type: 'success'
      })
    } catch (e) {
      notifications.displayNotification('Unable to update quote settings', {
        type: 'error'
      })

      Sentry.captureException(e)
    }
  }

  return {
    isLoading,
    linkDocumentation: {
      visible: false,
      href: 'https://docs.sequencehq.com'
    },
    functions: {
      onSubmit
    },
    quoteSettings
  }
}
