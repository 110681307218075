import { SentryConfig } from '@sequencehq/utils'
import { getSentryConfig } from 'features/sentry/getSentryConfig'

const { environment } = getSentryConfig()

const isProduction = ['production'].includes(environment)
const isSandbox = ['sandbox'].includes(environment)
const isDemo = ['demo'].includes(environment)
const isTest = ['test'].includes(environment)

export function isProductionEnv(): boolean {
  return isProduction
}

export function isProductionOrDemoEnv(): boolean {
  return isProduction || isDemo
}

export function isSandboxEnv(): boolean {
  return isSandbox
}

export function isDemoEnv(): boolean {
  return isDemo
}

export function isTestEnv(): boolean {
  return isTest
}

export function getEnvironment(): SentryConfig['environment'] {
  return environment
}
