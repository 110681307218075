import { Currency } from '@sequencehq/core-models'
import { MinimumDomainInterface } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.domain'
import { useMinimumEditorDomainContext } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.domain.context'

type UseMinimumEditorForm = () => {
  fieldConfig: MinimumDomainInterface['queries']['form']['fields']
  currency: Currency
}

export const useMinimumEditorForm: UseMinimumEditorForm = () => {
  const minimumEditorContext = useMinimumEditorDomainContext()

  return {
    fieldConfig: minimumEditorContext.queries.form.fields,
    currency: minimumEditorContext.queries.editor.currency
  }
}
