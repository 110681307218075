import { Box, Checkbox, Flex, FormLabel, Link, Text } from '@chakra-ui/react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useMutation } from '@sequencehq/api/dist/utils'
import { SeatSnapshotModel } from '@sequencehq/core-models'
import {
  IndigoIndigo50,
  Lato14Bold,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import { TextInputField } from '@sequencehq/forms'
import { isPositiveInteger, required, skip } from '@sequencehq/validation'
import { Blocker } from 'components/Form'
import { CustomerAliasComboInput } from 'components/FormComponents/CustomerAliasComboInput'
import { CustomerComboInputComplete } from 'components/FormComponents/CustomerComboInputComplete'
import { DateInputField } from 'components/FormFields'
import { DrawerForm, useHandleMutationResult } from 'components/Modal'
import { SeatMetricInput } from 'modules/Seats/SeatMetric/SeatMetricInput'
import { useSeatType } from 'modules/Seats/SeatMetric/SeatTypeProvider'
import arrayMutators from 'final-form-arrays'
import { handleFormResponse } from 'lib/formValidation'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ExtractMutationParams } from 'lib/types'
import { FC, useCallback, useState } from 'react'
import { Form } from 'react-final-form'

type FormValues = {
  customerAlias: string
  customerId: string
  seatType: string
  snapshotTimestamp: string
  total: number
}

type CreateSeatSnapshotFormProps = {
  onSuccess: (
    seatType: DashboardApi20240730.PostSeatEvent.Response['data']
  ) => void
  handleCancel: () => void
}

export const CreateSeatSnapshotForm: FC<CreateSeatSnapshotFormProps> = ({
  onSuccess,
  handleCancel
}) => {
  const createSeatEventMutation = useMutation(
    dashboard20240730Client.postSeatEvent
  )
  const { displayNotification } = useNotifications()
  const [withAlias, setWithAlias] = useState<boolean>(false)

  const { state } = useSeatType()
  const { seatType } = state

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      mutators={{
        ...arrayMutators
      }}
      onSubmit={async values => {
        if (seatType) {
          try {
            const res = await createSeatEventMutation.mutateAsync({
              customerAlias: withAlias
                ? values.customerAlias
                : values.customerId,
              seatType: seatType.key,
              eventTimestamp: values.snapshotTimestamp,
              total: values.total
            })

            displayNotification('Seat event created', {
              type: 'success'
            })
            onSuccess(res)
          } catch {
            displayNotification("Couldn't create seat event", {
              type: 'error'
            })
          }
        }
      }}
      render={formProps => (
        <DrawerForm
          {...formProps}
          title="Create seat event"
          submitLabel="Create seat event"
          handleCancel={handleCancel}
          drawerStyles={{
            maxWidth: '520px'
          }}
        >
          <Blocker
            name="seatSnapshot"
            dirty={formProps.dirty}
            submitting={formProps.submitting}
            modalTitle="Cancel seat assingment editing"
            message="Progress you made so far will not be saved."
            submitTitle="Yes, Cancel"
            cancelTitle="Keep editing"
            ignoreBlock={({ pathname }) => {
              return pathname.startsWith('/seats/balances/new')
            }}
          />

          <Flex direction="column" gap="16px" data-testid="seatEvents.new.form">
            <Text {...Lato14ExtendedRegular} color="inherit">
              Use the 'Seat Events' API endpoint to programmatically submit seat
              events to update the balance for a customer.{' '}
              <Link
                href="https://docs.sequencehq.com/seats/seat-updates"
                isExternal
                color={IndigoIndigo50}
              >
                Learn more
              </Link>
            </Text>

            <CustomerComboInputComplete
              fieldName="customerId"
              disabled={withAlias}
              validate={withAlias ? skip : required}
              labelPadding={0}
            />
            <Checkbox
              checked={withAlias}
              onChange={event => {
                setWithAlias(event.target.checked)
              }}
            >
              Use customer alias instead
            </Checkbox>
            {withAlias ? (
              <>
                <CustomerAliasComboInput
                  fieldName="customerAlias"
                  labelPadding={0}
                />
              </>
            ) : null}
            <DateInputField
              fieldName="snapshotTimestamp"
              fieldLabel="Event timestamp"
              validate={required}
              isSelectable={() => true}
            />
            <Box>
              <Text {...Lato14Bold} paddingRight="8px" mb={2}>
                Seat type
              </Text>
              <SeatMetricInput fieldName="seatType" />
            </Box>
            <Flex flexDirection="column" gap="8px">
              <FormLabel>Total seats</FormLabel>
              <TextInputField
                data-testid="createSeat.total"
                fieldName="total"
                validate={isPositiveInteger}
              />
            </Flex>
          </Flex>
        </DrawerForm>
      )}
    />
  )
}
