import { Box, Checkbox, Grid, Link } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey40,
  Lato13Regular,
  Lato14Bold,
  Lato14Regular,
  RedRed60
} from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  HorizontalSelectorInput,
  PercentageField,
  PriceField,
  TextField
} from '@sequencehq/core-components'
import { useDiscountEditorForm } from 'modules/Cube/view/common/drawers/discountEditor/drawer/content/DiscountEditorForm/useDiscountEditorForm'
import {
  ApplyDiscountTo,
  DiscountCalculationType
} from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'

export const DiscountEditorForm = () => {
  const { fieldsConfig } = useDiscountEditorForm()

  return (
    <Box padding="24px">
      <TextField
        label="Name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        isDisabled={fieldsConfig.name.disabled}
        validationErrors={fieldsConfig.name.validationErrors}
        description={
          <>
            The discount name will be displayed on the invoice.{' '}
            <Link
              href="https://docs.sequencehq.com/billing/create-billing-schedules#recurring-discounts"
              isExternal
              color="indigo.50"
            >
              Learn more
            </Link>
          </>
        }
      />
      <HorizontalSelectorField
        label="Type"
        value={fieldsConfig.discountCalculationType.value}
        onChange={newValue =>
          fieldsConfig.discountCalculationType.onChange(
            newValue as DiscountCalculationType
          )
        }
        options={fieldsConfig.discountCalculationType.options}
        validationErrors={fieldsConfig.discountCalculationType.validationErrors}
        isDisabled={fieldsConfig.discountCalculationType.disabled}
      />
      {!fieldsConfig.nominalValue.hidden && (
        <PriceField
          label="Amount"
          value={fieldsConfig.nominalValue.value}
          onChange={fieldsConfig.nominalValue.onChange}
          validationErrors={fieldsConfig.nominalValue.validationErrors}
          isDisabled={fieldsConfig.nominalValue.disabled}
          currency={fieldsConfig.nominalValue.currency}
        />
      )}
      {!fieldsConfig.percentageValue.hidden && (
        <PercentageField
          label="Amount"
          value={fieldsConfig.percentageValue.value}
          onChange={fieldsConfig.percentageValue.onChange}
          validationErrors={fieldsConfig.percentageValue.validationErrors}
          isDisabled={fieldsConfig.percentageValue.disabled}
        />
      )}

      {/** The applyTo field - at some point we may want to extract this input style and create an input from it! */}
      <Box {...Lato14Bold} marginBottom="8px">
        Apply to
      </Box>
      <Box
        boxShadow="0px 2px 4px 0px rgba(20, 23, 28, 0.08)"
        borderRadius="8px"
        border={
          fieldsConfig.applyTo.validationErrors.length
            ? `1px solid ${RedRed60}`
            : `1px solid ${GreyGrey40}`
        }
        overflow="hidden"
        marginBottom="16px"
      >
        <HorizontalSelectorInput
          options={fieldsConfig.applyTo.options}
          value={fieldsConfig.applyTo.value}
          isDisabled={fieldsConfig.applyTo.disabled}
          onChange={newValue =>
            fieldsConfig.applyTo.onChange(newValue as ApplyDiscountTo)
          }
          {...(fieldsConfig.applyTo.value === 'SPECIFIC'
            ? {
                borderBottomRadius: '0',
                borderBottom: `1px solid ${GreyGrey40}`
              }
            : {})}
          boxShadow="none"
          border="none"
        />
        {fieldsConfig.applyTo.value === 'SPECIFIC' &&
          fieldsConfig.applyTo.prices.map(price => (
            <Grid
              key={price.id}
              alignItems="center"
              padding="12px 16px"
              templateColumns="auto 1fr auto"
              columnGap="8px"
              cursor="pointer"
              _hover={{
                backgroundColor: GreyGrey10
              }}
              {...(price.disabled || fieldsConfig.applyTo.disabled
                ? {
                    opacity: 0.4
                  }
                : {})}
            >
              <Checkbox
                isChecked={price.selected}
                isDisabled={fieldsConfig.applyTo.disabled || price.disabled}
                onChange={() => {
                  !price.disabled && price.toggleSelection()
                }}
              />
              <Box
                {...Lato14Regular}
                onClick={() => {
                  !price.disabled && price.toggleSelection()
                }}
              >
                {price.label}
              </Box>
              <Box
                {...Lato14Regular}
                onClick={() => {
                  !price.disabled && price.toggleSelection()
                }}
              >
                {price.price}
              </Box>
            </Grid>
          ))}
      </Box>
      {Boolean(fieldsConfig.applyTo.validationErrors.length) && (
        <Box
          marginTop="-8px"
          marginBottom="16px"
          key={fieldsConfig.applyTo.validationErrors[0].message}
          {...Lato13Regular}
          color={RedRed60}
        >
          {fieldsConfig.applyTo.validationErrors[0].message}
        </Box>
      )}
    </Box>
  )
}
