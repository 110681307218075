import { useCallback } from 'react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dashboard/v20240509'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export type ListPriceMutationInterface = {
  productId: string
  name: string
  structure:
    | Dashboardv20240509Api.CommonModels.PricingStructure.OneTimePricingStructure
    | Dashboardv20240509Api.CommonModels.PricingStructure.FixedPricingStructure
    | Dashboardv20240509Api.CommonModels.PricingStructure.LinearPricingStructure
    | Dashboardv20240509Api.CommonModels.PricingStructure.PackagePricingStructure
    | Dashboardv20240509Api.CommonModels.PricingStructure.VolumePricingStructure
    | Dashboardv20240509Api.CommonModels.PricingStructure.GraduatedPricingStructure
    | Dashboardv20240509Api.CommonModels.PricingStructure.SeatBasedPricingStructure
  billingFrequency: Dashboardv20240509Api.CommonModels.PricingStructure.BillingFrequency
  usageCalculationPeriod?: Dashboardv20240509Api.GetPrice.Price['usageCalculationPeriod']
  billingType: Dashboardv20240509Api.CommonModels.PricingStructure.BillingType
  integrationIds: Dashboardv20240509Api.CommonModels.Integrations.IntegrationId[]
  customMetricParameters: Dashboardv20240509Api.CommonModels.PriceParameter[]
  currency: Currency
}

type ApiClientListPrice = Dashboardv20240509Api.GetListPrice.ListPrice

type UseSaveListPrice = () => {
  archive: (id: string) => Promise<ApiClientListPrice | null>
  create: (
    price: ListPriceMutationInterface
  ) => Promise<ApiClientListPrice | null>
  update: (
    id: string
  ) => (price: ListPriceMutationInterface) => Promise<ApiClientListPrice | null>
}

export const useSaveListPrice: UseSaveListPrice = () => {
  const archive = useCallback(async (id: string) => {
    const response = await dashboardv20240509Client.putListPriceArchive({
      id
    })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const create = useCallback(async (params: ListPriceMutationInterface) => {
    const response = await dashboardv20240509Client.postListPrice({
      body: {
        productId: params.productId,
        name: params.name,
        structure: params.structure,
        billingFrequency: params.billingFrequency,
        usageCalculationPeriod: params.usageCalculationPeriod,
        billingType: params.billingType,
        integrationIds: params.integrationIds,
        customMetricParameters: params.customMetricParameters,
        currency: params.currency
      }
    })

    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const update = useCallback(
    (id: string) => async (params: ListPriceMutationInterface) => {
      const response = await dashboardv20240509Client.putListPrice({
        id,
        body: {
          productId: params.productId,
          name: params.name,
          structure: params.structure,
          billingFrequency: params.billingFrequency,
          usageCalculationPeriod: params.usageCalculationPeriod,
          billingType: params.billingType,
          integrationIds: params.integrationIds,
          customMetricParameters: params.customMetricParameters,
          currency: params.currency
        }
      })

      if (response.error) {
        return null
      }

      return response.data ?? null
    },
    []
  )

  return { archive, create, update }
}
