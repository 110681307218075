import { ComponentProps, FC, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Button } from '@sequencehq/core-components'
import {
  dashboard20240730Client,
  ServerAsset
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { FilePickerButton } from '@sequencehq/core-components/src/components/FilePickerButton'
import { MAX_FILE_SIZE } from './constants'

type Props = ComponentProps<typeof Button> & {
  onFilePicked?: (file: File) => void
  onUploadSuccess: (asset: ServerAsset) => void
  onUploadFailure?: (error: string) => void
  accept: string
}

export const UploadAssetButton: FC<Props> = ({
  onFilePicked,
  onUploadSuccess,
  onUploadFailure,
  accept,
  ...props
}) => {
  const [file, setFile] = useState<File | null>(null)

  const handleFilesPicked = (files: File[]) => {
    const pickedFile = files[0]

    if (pickedFile.size > MAX_FILE_SIZE) {
      onUploadFailure?.('File size too large. Maximum 5 MB')
      return
    }

    setFile(pickedFile)
    onFilePicked?.(pickedFile)
  }

  useEffect(() => {
    if (file) {
      uploadFile(file)
        .then(async res => {
          if (!res.data) {
            const errorResponse: { error: { errors: { message: string }[] } } =
              await res.res.json()

            onUploadFailure?.(
              errorResponse.error.errors?.[0]?.message ??
                'Something went wrong.'
            )
            return
          }
          onUploadSuccess(res.data)
        })
        .catch(Sentry.captureException)

      setFile(null)
    }
  }, [file, onUploadSuccess, onUploadFailure])

  return (
    <FilePickerButton
      accept="image/jpeg,image/png"
      {...props}
      onFilesPicked={handleFilesPicked}
    />
  )
}

const uploadFile = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return await dashboard20240730Client.postAssets({
    formData
  })
}
