import { useIntegrationsDomainContext } from 'modules/Integrations/communication'
import {
  AvailableServices,
  ResolvedIntegration
} from 'modules/Integrations/domain'
import { useMemo } from 'react'

type UseFeaturedIntegrations = (props: {
  onSelectIntegration: (integration: AvailableServices) => void
}) => {
  featuredIntegrations: Array<
    ResolvedIntegration & {
      onClick: () => void
    }
  >
}

export const useFeaturedIntegrations: UseFeaturedIntegrations = props => {
  const ctx = useIntegrationsDomainContext()

  const featuredIntegrations = useMemo(() => {
    return ctx.queries.featuredIntegrations.map(int => ({
      ...int,
      onClick: () => props.onSelectIntegration(int.id)
    }))
  }, [ctx.queries.featuredIntegrations, props])

  return {
    featuredIntegrations
  }
}
