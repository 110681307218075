import {
  Currency,
  CustomerModel,
  InvoiceModel,
  InvoicesGrid,
  toMoney
} from '@sequencehq/core-models'
import { useGetInvoicesInvoicesGridQuery } from 'features/api'
import { useCallback, useEffect, useMemo } from 'react'
import invariant from 'tiny-invariant'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  useDefaultCurrency,
  useEnabledCurrencies
} from 'components/CurrencySettings/useCurrencies'
import { useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import { match } from 'ts-pattern'

type InvoiceMonth = string

export type UseInvoicesGridData = {
  enabledCurrencies: Currency[]
  currency: Currency | undefined
  updateCurrency: (newCurrency: string) => void
  months: { label: string; value: string }[]
  customers: {
    id: InvoiceModel['customerId']
    name: InvoiceModel['customerLegalCompanyName']
  }[]
  getTotal: (filters?: {
    customerId?: CustomerModel['id']
    month?: InvoiceMonth
  }) => string
  getInvoices: (filters?: {
    customerId?: CustomerModel['id']
    month?: InvoiceMonth
  }) => InvoiceModel['id'][]
  getNavigateableInvoices: (
    invoiceId: InvoiceModel['id']
  ) => InvoiceModel['id'][]
}

type UseInvoicesGrid = () => {
  loading: boolean
  data?: UseInvoicesGridData
}

export const useInvoicesGrid: UseInvoicesGrid = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currencyResponse = useEnabledCurrencies()
  const defaultCurrency = useDefaultCurrency()

  const { enabledCurrencies, currency } = match(currencyResponse)
    .with({ status: 'SUCCESS' }, matched => ({
      enabledCurrencies: matched.enabledCurrencies,
      currency: matched.enabledCurrencies.find(
        enabledCurrency => enabledCurrency === searchParams.get('currency')
      )
    }))
    .otherwise(() => ({
      enabledCurrencies: [],
      currency: undefined
    }))

  useEffect(() => {
    if (!currency && defaultCurrency) {
      setSearchParams(prev => {
        const params = prev
        params.append('currency', defaultCurrency)
        return params
      })
    }
  }, [currency, defaultCurrency, setSearchParams])

  const invoicesGridQueryParams = currency
    ? {
        invoiceCurrency: currency,
        invoiceStatuses: 'IN_PROGRESS,DRAFT,FINAL,SENT'
      }
    : skipToken
  const { data, isLoading } = useGetInvoicesInvoicesGridQuery(
    invoicesGridQueryParams
  )
  const invoiceOverviewResponse = data?.value()

  const invoicesTotals: InvoicesGrid | null = useMemo(() => {
    if (!invoiceOverviewResponse) {
      return null
    }
    return invoiceOverviewResponse
  }, [invoiceOverviewResponse])

  const sortedCustomers = useMemo(() => {
    if (!invoicesTotals) {
      return []
    }

    return invoicesTotals.customerDetails
      .map(({ customerId, legalName, ...rest }) => ({
        id: customerId,
        name: legalName,
        ...rest
      }))
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (a.name < b.name) {
          return -1
        }
        return 0
      })
  }, [invoicesTotals])

  const invoicesPerMonth: Record<string, InvoiceModel['id'][]> = useMemo(() => {
    /**
     * We use the sorted customers as the basis so that the order of the invoices
     * will match the customer order in the grid.
     */
    return (
      sortedCustomers
        .flatMap(customerDetail => customerDetail.monthDetails)
        .reduce(
          (
            acc: Record<string, InvoiceModel['id'][]>,
            monthDetail: {
              month: string
              total: string
              invoiceIds: string[]
            }
          ) => {
            if (!acc[monthDetail.month]) {
              return {
                ...acc,
                [monthDetail.month]: monthDetail.invoiceIds
              }
            }

            return {
              ...acc,
              [monthDetail.month]: [
                ...acc[monthDetail.month],
                ...monthDetail.invoiceIds
              ]
            }
          },
          {}
        ) ?? {}
    )
  }, [sortedCustomers])

  const getInvoices = useCallback(
    ({
      customerId,
      month
    }: {
      customerId?: CustomerModel['id']
      month?: InvoiceMonth
    } = {}) => {
      if (!invoicesTotals) {
        return []
      }

      if (!customerId && month) {
        return invoicesPerMonth[month]
      }

      if (!month && customerId) {
        return (
          invoicesTotals.customerDetails
            .find(customer => customer.customerId === customerId)
            ?.monthDetails.flatMap(monthOverview => monthOverview.invoiceIds) ??
          []
        )
      }

      invariant(customerId, 'Must specify customer Id')
      invariant(month, 'Must specify month')

      return (
        invoicesTotals.customerDetails
          .find(customer => customer.customerId === customerId)
          ?.monthDetails.find(monthOverview => monthOverview.month === month)
          ?.invoiceIds ?? []
      )
    },
    [invoicesTotals, invoicesPerMonth]
  )

  const getNavigateableInvoices = useCallback(
    (invoiceId: InvoiceModel['id']) => {
      const invoicesForMonth = Object.values(invoicesPerMonth).find(invoices =>
        invoices.includes(invoiceId)
      ) ?? [invoiceId]

      return invoicesForMonth
    },
    [invoicesPerMonth]
  )

  const getTotal = useCallback(
    ({
      customerId,
      month
    }: {
      customerId?: CustomerModel['id']
      month?: InvoiceMonth
    } = {}): string | undefined => {
      if (!invoicesTotals) {
        return '0.00'
      }

      if (!customerId && !month) {
        return invoicesTotals.accountTotal
      }

      if (!customerId && month) {
        return invoicesTotals.monthDetails.find(
          monthOverview => monthOverview.month === month
        )?.total
      }

      if (!month && customerId) {
        return invoicesTotals.customerDetails.find(
          customer => customer.customerId === customerId
        )?.total
      }

      invariant(customerId, 'Must specify customer Id')
      invariant(month, 'Must specify month')

      return invoicesTotals.customerDetails
        .find(customer => customer.customerId === customerId)
        ?.monthDetails.find(monthOverview => monthOverview.month === month)
        ?.total
    },
    [invoicesTotals]
  )

  const getFormattedTotal = useCallback(
    ({
      customerId,
      month
    }: {
      customerId?: CustomerModel['id']
      month?: InvoiceMonth
    } = {}): string => {
      if (!currency) {
        return ''
      }
      const total = getTotal({
        customerId,
        month
      })
      const invoices =
        month || customerId
          ? getInvoices({
              customerId,
              month
            })
          : []

      if (!total && !invoices?.length) {
        return ''
      }

      return toMoney({ value: `${total ?? 0.0}`, currency })
    },
    [getTotal, currency, getInvoices]
  )

  const months = useMemo(() => {
    if (!invoicesTotals) {
      return []
    }
    return invoicesTotals.monthDetails
      .map(monthOverview => ({
        label: dateTimeWithFormat(monthOverview.month, 'MMM yy'),
        value: monthOverview.month
      }))
      .sort((a, b) => {
        if (a.value > b.value) {
          return 1
        }
        if (a.value < b.value) {
          return -1
        }
        return 0
      })
  }, [invoicesTotals])

  const updateCurrency = (newCurrency: string) => {
    setSearchParams(values => ({
      ...Object.fromEntries(values),
      currency: newCurrency
    }))
  }

  return {
    loading: isLoading,
    data: {
      enabledCurrencies,
      currency,
      updateCurrency,
      getInvoices,
      getTotal: getFormattedTotal,
      getNavigateableInvoices,
      months,
      customers: sortedCustomers
    }
  }
}
