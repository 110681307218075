import { FC } from 'react'

interface UnpaidIconProps {
  height?: number
  width?: number
}

export const UnpaidIcon: FC<UnpaidIconProps> = ({
  height = 20,
  width = 20
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <circle
          id="Ellipse 219"
          cx="10"
          cy="10"
          r="6"
          stroke="#A9B3C5"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
