import {
  MinimumDomainInterface,
  useMinimumEditorDomain
} from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.domain'
import { MinimumEditorRootProps } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'
import { useMemo } from 'react'
import {
  DEFAULT_MINIMUM,
  NEW_MINIMUM_PREFIX
} from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.constants'

type UseMinimumEditorRoot = (props: MinimumEditorRootProps) => {
  domain: MinimumDomainInterface
}

export const useMinimumEditorRoot: UseMinimumEditorRoot = props => {
  const existingMinimum = useMemo(() => {
    if (props.existingMinimum) {
      return props.existingMinimum
    }

    return {
      ...DEFAULT_MINIMUM,
      scope: props.applyToAllAvailable
        ? {
            target: 'allUsage' as const,
            priceIds: []
          }
        : {
            target: 'specific' as const,
            priceIds: []
          },
      id: `${NEW_MINIMUM_PREFIX}${crypto.randomUUID()}`
    }
  }, [props.existingMinimum, props.applyToAllAvailable])

  const domain = useMinimumEditorDomain({
    data: {
      initialMinimumData: existingMinimum
    },
    configuration: {
      availablePrices: props.availablePrices,
      currency: props.currency,
      mode: props.mode,
      applyToAllAvailable: props.applyToAllAvailable
    },
    handlers: {
      onSave: props.onSave,
      onClose: props.onClose
    }
  })

  return {
    domain
  }
}
