import { FC } from 'react'

interface ArrowLeftIconProps {
  width?: number
  height?: number
}

export const ArrowLeftIcon: FC<ArrowLeftIconProps> = ({
  width = 24,
  height = 24
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 12H5"
      stroke="#485972"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19L5 12L12 5"
      stroke="#485972"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
