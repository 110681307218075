import { SimplePaymentPage } from 'modules/PaymentsApp/components/SimplePaymentPage'
import { CheckCircle } from 'react-feather'

export const Success = () => {
  return (
    <SimplePaymentPage
      icon={CheckCircle}
      iconColour="green.60"
      header="Payment successful"
      subheader="You have been successfully charged for the invoice."
      nextSteps={[
        'Your payment details have been saved for recurring payments. To change the current payment method you can contact the issuer.'
      ]}
    />
  )
}
