import { INITIAL_LIST_PRICE_STATE } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import {
  ListPriceEditorReducerState,
  Price,
  StandardPrice
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

export const getNewPrice =
  (prevState: ListPriceEditorReducerState) =>
  (id: Price['id']): StandardPrice => ({
    id,
    common: {
      ...INITIAL_LIST_PRICE_STATE.data.formData.common,
      pricingModel: 'STANDARD',
      name: prevState.data.product?.name ?? '',
      currency:
        prevState.configuration.currency ??
        prevState.configuration.availableCurrencies[0],
      externalIds: Object.values(prevState.data.ledgerIntegrations).reduce(
        (acc, account) => ({
          ...acc,
          [account.id]: account.defaultLedgerAccount
        }),
        {}
      )
    },
    modelSpecific: INITIAL_LIST_PRICE_STATE.data.formData.STANDARD
  })
