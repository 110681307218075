import {
  MinimumEditorMode,
  MinimumEditorRootProps
} from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'
import { uniq } from 'lodash/fp'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { Minimum, Phase } from 'modules/Cube/domain/cube.domain.types'
import { minimumEditorCubeAdapter } from 'modules/Cube/view/common/drawers/minimumEditor/minimumEditor.adapters'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { priceModelAdapter } from '@sequencehq/utils'
type UseMinimumsEditorConnector = () => MinimumEditorRootProps

export const useMinimumsEditorConnector: UseMinimumsEditorConnector = () => {
  const navigate = useNavigate()
  const routeParams = useParams<{
    phaseId: Phase['id']
    minimumId: Minimum['id']
  }>()
  const cubeContext = useCubeContext()

  useEffect(() => {
    if (
      !routeParams.phaseId ||
      !cubeContext.queries.rawData.data.phases[routeParams.phaseId]
    ) {
      /**
       * Bail if we are trying to access the editor for a phase that doesn't exist
       */
      navigate('..')
    }
  }, [cubeContext, navigate, routeParams.phaseId])

  const existingMinimum = useMemo(() => {
    if (!routeParams.phaseId || !routeParams.minimumId) {
      return null
    }

    const existingData = cubeContext.queries.resolvedPhases[
      routeParams.phaseId
    ]?.minimums.find(minimum => minimum.id === routeParams.minimumId)

    /**
     * If we attempted to access the editor for a minimum that doesn't exist, also bail.
     */
    if (!existingData) {
      navigate('..')
      return null
    }

    return minimumEditorCubeAdapter.in(existingData)
  }, [cubeContext, routeParams, navigate])

  const handleClose = useCallback(() => {
    navigate('..')
  }, [navigate])

  const handleSave = useCallback(
    (newMinimum: Minimum) => {
      if (!routeParams.phaseId) {
        return
      }

      cubeContext.mutators.updateData({
        minimums: {
          [newMinimum.id]: minimumEditorCubeAdapter.out(newMinimum)
        },
        phases: {
          [routeParams.phaseId]: {
            minimumIds: [newMinimum.id]
          }
        }
      })
      navigate('..')
    },
    [navigate, cubeContext.mutators, routeParams.phaseId]
  )

  const currency = useMemo(() => {
    return (
      cubeContext.queries.selectedCurrency ??
      cubeContext.queries.rawData.configuration.currency.default
    )
  }, [
    cubeContext.queries.selectedCurrency,
    cubeContext.queries.rawData.configuration.currency.default
  ])

  const mode = useMemo(() => {
    if (!routeParams.phaseId) {
      return MinimumEditorMode.VIEW
    }

    if (!routeParams.minimumId) {
      const addMinimumAvailable =
        cubeContext.queries.availableFeatures.phases[routeParams.phaseId].phase
          .minimum.add.available.enabled

      if (!addMinimumAvailable) {
        navigate('..')
        return MinimumEditorMode.VIEW
      }

      return MinimumEditorMode.CREATE
    }

    const editMinimumAvailable =
      cubeContext.queries.availableFeatures.phases[routeParams.phaseId].phase
        .minimum.edit.available.enabled

    return editMinimumAvailable
      ? MinimumEditorMode.EDIT
      : MinimumEditorMode.VIEW
  }, [cubeContext, routeParams.phaseId, routeParams.minimumId, navigate])

  /**
   * For minimums, we only want to include products which have a usage based
   * price
   */
  const availablePrices = useMemo(() => {
    if (
      !routeParams.phaseId ||
      !cubeContext.queries.resolvedPhases[routeParams.phaseId]?.prices
    ) {
      return []
    }

    return cubeContext.queries.resolvedPhases[routeParams.phaseId]?.prices
      .filter(price => 'usageMetricId' in price.structure)
      .map(priceModelAdapter.in)
  }, [cubeContext, routeParams.phaseId])

  const applyToAllAvailable = useMemo(() => {
    const allRecurringFrequencies = uniq(
      availablePrices
        .map(price =>
          price.billingFrequency !== 'ONE_TIME'
            ? price.billingFrequency
            : undefined
        )
        .filter(Boolean)
    )

    return allRecurringFrequencies.length < 2
  }, [availablePrices])

  return {
    existingMinimum,
    availablePrices,
    currency,
    applyToAllAvailable,
    mode,
    onClose: handleClose,
    onSave: handleSave
  }
}
