import CreateOrEditUsageMetricForm from 'components/CreateUsageMetric/CreateUsageMetricForm'
import Sidebar from 'components/Sidebar'
import { DrawerForm } from 'components/Modal'
import { UsageMetricModel } from '@sequencehq/core-models'
import { useUsageMetric } from 'components/CreateUsageMetric/UsageMetricProvider'
import { FC, useCallback } from 'react'

export type CreateUsageMetricDrawerProps = {
  onSuccess?: (newUsageMetric: UsageMetricModel) => void
  onCancel?: () => void
}

export const CreateUsageMetricDrawer: FC<
  CreateUsageMetricDrawerProps
> = props => {
  const { dispatch } = useUsageMetric()

  const onSuccess = useCallback(
    (usageMetric: UsageMetricModel) => {
      dispatch({
        type: 'setUsageMetricId',
        usageMetricId: usageMetric.id
      })
      props.onSuccess?.(usageMetric)
    },
    [dispatch, props]
  )

  const handleCancel = useCallback(() => {
    props.onCancel?.()
  }, [props])

  return (
    <Sidebar>
      <CreateOrEditUsageMetricForm
        formContainer={DrawerForm}
        onSuccess={onSuccess}
        handleCancel={handleCancel}
      />
    </Sidebar>
  )
}
