import { Dashboardv20240509Api } from '@sequencehq/api/dashboard/v20240509'

import { CustomerContact } from 'modules/CustomerContacts/domain/CustomerContacts.domain.types'

export type CustomerContactListV1Response =
  Dashboardv20240509Api.GetCustomerContacts.CustomerContactList

export type CustomerContactsApiToInternalAdapterResult = {
  customerId: string
  contacts: CustomerContact[]
}

const apiToInternal = (
  customerId: string,
  apiData: CustomerContactListV1Response
): CustomerContactsApiToInternalAdapterResult => {
  return {
    customerId,
    contacts: apiData.items
  }
}

export const customerContactsAdapter = {
  in: apiToInternal
}
