import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { PricingEditorPortErrors } from 'common/drawers/PricingEditor/domain'
import { v1ApiLoadedData } from '../../loadData'

type LoadAccountSettings = () => Promise<
  | {
      data: v1ApiLoadedData['accountSettings']
      error: null
    }
  | {
      data: null
      error: PricingEditorPortErrors
    }
>

export const loadAccountSettings: LoadAccountSettings = async () => {
  const accountSettingsResponse =
    await dashboardv20240509Client.getSequenceAccountSettings()

  if (accountSettingsResponse.error || !accountSettingsResponse.data) {
    return {
      data: null,
      error:
        accountSettingsResponse.res.status === 404
          ? PricingEditorPortErrors.NotFound
          : PricingEditorPortErrors.Other
    }
  }

  return {
    data: accountSettingsResponse.data,
    error: null
  }
}
