import { SelectField } from '@sequencehq/core-components'
import { ComponentProps } from 'react'
import { SeatBasedProrationStrategy } from 'common/drawers/PricingEditor/domain'
import { GreyGrey60, Lato13Regular } from '@sequencehq/design-tokens'
import { Text } from '@chakra-ui/react'

export const seatProrationLabels: Record<SeatBasedProrationStrategy, string> = {
  USE_MAXIMUM: 'No proration (charge full amount)',
  PRORATE_INCREMENTS: 'Added seats only',
  PRORATE_ALL_CHANGES: 'Added and removed seats'
}

const prorationStrategyOptions: {
  value: SeatBasedProrationStrategy
  label: string
}[] = [
  {
    value: 'USE_MAXIMUM',
    label: seatProrationLabels['USE_MAXIMUM']
  },
  {
    value: 'PRORATE_INCREMENTS',
    label: seatProrationLabels['PRORATE_INCREMENTS']
  },
  {
    value: 'PRORATE_ALL_CHANGES',
    label: seatProrationLabels['PRORATE_ALL_CHANGES']
  }
]

const descriptionByValue: Record<SeatBasedProrationStrategy, string> = {
  USE_MAXIMUM:
    'Charge the full amount for seats added mid-billing period. Removed seats are not refunded.',
  PRORATE_INCREMENTS:
    'Charge a prorated overage fee for seats added mid-billing period. Removed seats are not refunded.',
  PRORATE_ALL_CHANGES:
    'Charge a prorated overage fee for seats added mid-billing period. Removed seats are refunded pro-rata.'
}

interface Props
  extends Pick<ComponentProps<typeof SelectField>, 'validationErrors'> {
  value: SeatBasedProrationStrategy
  onChange: (value: SeatBasedProrationStrategy) => void
}

const SeatProrationDropdown = ({
  value,
  onChange,
  ...selectFieldProps
}: Props) => {
  return (
    <SelectField
      label="Proration"
      placeholder="Select seat proration"
      options={prorationStrategyOptions}
      value={value}
      onChange={selectedValue =>
        onChange(selectedValue as SeatBasedProrationStrategy)
      }
      description={
        <Text {...Lato13Regular} color={GreyGrey60}>
          {descriptionByValue[value]}
        </Text>
      }
      {...selectFieldProps}
    />
  )
}

export default SeatProrationDropdown
