import { toPriceSummary } from '@sequencehq/core-models'
import { priceModelAdapter } from '@sequencehq/utils'
import { PricingEditorPrice } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback, useMemo } from 'react'

type AvailablePrice = {
  id: PricingEditorPrice['id']
  label: string
}

type UsePricesList = () => {
  existingPrices: AvailablePrice[]
  newPrices: AvailablePrice[]
  selectedPriceId: PricingEditorPrice['id'] | null
  selectPrice: (newPriceId: PricingEditorPrice['id']) => void
}

export const usePricesList: UsePricesList = () => {
  const pricingEditorContext = usePricingEditorContext()

  const existingPrices = useMemo(() => {
    return Object.values(pricingEditorContext.derived.queries.uniquePrices)
      .map(price => {
        const apiFormatPrice =
          pricingEditorContext.derived.queries.apiFormatPrices.find(
            apiPrice => apiPrice.id === price.id
          )

        if (!apiFormatPrice) {
          return null
        }

        return {
          id: price.id,
          label: toPriceSummary(priceModelAdapter.in(apiFormatPrice))
        }
      })
      .filter((price): price is AvailablePrice => Boolean(price))
  }, [
    pricingEditorContext.derived.queries.uniquePrices,
    pricingEditorContext.derived.queries.apiFormatPrices
  ])

  const newPrices = useMemo(() => {
    return Object.values(pricingEditorContext.derived.queries.newPrices)
      .map(price => {
        const apiFormatPrice =
          pricingEditorContext.derived.queries.apiFormatPrices.find(
            apiPrice => apiPrice.id === price.id
          )

        if (!apiFormatPrice) {
          return null
        }

        return {
          id: price.id,
          label:
            toPriceSummary(priceModelAdapter.in(apiFormatPrice)) ||
            price.common.name ||
            'New price'
        }
      })
      .filter((price): price is AvailablePrice => Boolean(price))
  }, [
    pricingEditorContext.derived.queries.newPrices,
    pricingEditorContext.derived.queries.apiFormatPrices
  ])

  const selectedPriceId = pricingEditorContext.editor.selectedPrice

  const selectPrice = useCallback(
    (newPriceId: PricingEditorPrice['id']) => {
      pricingEditorContext.functions.updateEditor({
        selectedPrice: newPriceId
      })
    },
    [pricingEditorContext.functions]
  )

  return {
    existingPrices,
    newPrices,
    selectedPriceId,
    selectPrice
  }
}
