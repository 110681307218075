import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spinner
} from '@chakra-ui/react'
import { usePricingEditorRoot } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PricingEditorRoot/usePricingEditorRoot'
import { PricingEditorContent } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PricingEditorContent'
import { PricingEditorProvider } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { PricingEditorProps } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { Outlet } from 'react-router-dom'

export const PricingEditorRoot = (props: PricingEditorProps) => {
  const { pricingEditor } = usePricingEditorRoot(props)
  return (
    <PricingEditorProvider value={pricingEditor}>
      <>
        <Drawer isOpen onClose={props.onClose}>
          <DrawerOverlay
            display="flex"
            alignContent="center"
            justifyContent="center"
          >
            <DrawerContent
              data-testid="priceEditor.content"
              margin="8px 12px"
              /**
               * Chakra _wants_ to ignore the width we set for the drawer content, but what
               * do we say in the face of stubborn chakra? Not today.
               */
              style={{
                width: 'fit-content',
                maxWidth: '100%',
                minWidth: '550px'
              }}
              borderRadius="8px"
              overflow="hidden"
            >
              {pricingEditor.editor.loaded ? (
                <PricingEditorContent />
              ) : (
                <Flex
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner />
                </Flex>
              )}
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
        <Outlet />
      </>
    </PricingEditorProvider>
  )
}
