import {
  IntegrationsReducerState,
  PostActionStage
} from 'modules/Integrations/domain/integrations.domain.types'
import { getResolvedIntegrations } from './getResolvedIntegrations'
import { getIntegrationGroups } from './getIntegrationGroups'
import { getFeaturedIntegrations } from './getFeaturedIntegrations'
import { getActiveIntegrations } from './getActiveIntegrations'
import { getAvailableFeatures } from './getAvailableFeatures'

export const queries: PostActionStage = () => prevState => {
  const resolvedIntegrations = getResolvedIntegrations(prevState)
  const integrationGroups = getIntegrationGroups({ resolvedIntegrations })()
  const activeIntegrations = getActiveIntegrations({ resolvedIntegrations })()

  const newQueryState: IntegrationsReducerState['queries'] = {
    resolvedIntegrations,
    integrationGroups,
    activeIntegrations,
    featuredIntegrations: getFeaturedIntegrations({ resolvedIntegrations })(
      prevState
    ),
    availableFeatures: getAvailableFeatures({
      resolvedIntegrations,
      activeIntegrations
    })(prevState)
  }

  return {
    ...prevState,
    queries: {
      ...prevState.queries,
      ...newQueryState
    }
  }
}
