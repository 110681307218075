import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'
import { Currency } from '@sequencehq/core-models'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14Bold,
  Lato14Regular,
  Lato16Bold,
  TurquoiseTurquoise90
} from '@sequencehq/design-tokens'
import { DateInput } from 'components/FormInputs'
import { useInvoicesGridCsvExport } from 'components/Invoices/InvoicesGrid/useInvoicesGridCsvExport'
import { FC, useState } from 'react'
import { Switch } from '@sequencehq/core-components'
import { sub } from '@sequencehq/utils/dates'

type DownloadInvoicesGridCsvExportModalProps = {
  currency: Currency
  onClose: () => void
}

export const DownloadInvoicesGridCsvExportModal: FC<
  DownloadInvoicesGridCsvExportModalProps
> = ({ currency, onClose }) => {
  const { download } = useInvoicesGridCsvExport()
  const [startDate, setStartDate] = useState<Date | undefined>(
    sub(new Date(), { months: 1 })
  )
  const [endDate, setEndDate] = useState<Date | undefined>(new Date())
  const [showDates, setShowDates] = useState<boolean>(false)

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Download CSV export
          </Box>
          <Box padding="12px 0 24px 0">
            <Switch
              {...Lato14Bold}
              color={TurquoiseTurquoise90}
              isChecked={!showDates}
              style={{ alignItems: 'center', display: 'flex' }}
              onChange={() => setShowDates(prev => !prev)}
            >
              <Text {...Lato14Bold}>Export over all time</Text>
            </Switch>
          </Box>
          {showDates && (
            <Flex
              padding="12px 0 24px 0"
              gap="8px"
              justifyContent="space-between"
            >
              <Flex flexDirection="column">
                <Box color={GreyGrey100} {...Lato14Regular} marginBottom="8px">
                  Start date (inclusive)
                </Box>
                <DateInput
                  name="startDate"
                  value={startDate}
                  onChange={date => setStartDate(date)}
                  isSelectable={date => !endDate || date < endDate}
                ></DateInput>
              </Flex>
              <Flex flexDirection="column">
                <Box color={GreyGrey100} {...Lato14Regular} marginBottom="8px">
                  End date (inclusive)
                </Box>
                <DateInput
                  name="endDate"
                  value={endDate}
                  onChange={date => setEndDate(date)}
                  isSelectable={date => !startDate || date > startDate}
                ></DateInput>
              </Flex>
            </Flex>
          )}

          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            {download.available && !!currency && (
              <Button
                variant="secondary2"
                onClick={() => {
                  download.onClick(
                    currency,
                    showDates ? startDate : undefined,
                    showDates ? endDate : undefined
                  )
                  onClose()
                }}
                height="32px"
                width="100%"
              >
                <Box marginRight="6px">
                  <ArrowDownTrayIcon
                    height={16}
                    width={16}
                    color={GreyGrey80}
                  />
                </Box>
                Download
              </Button>
            )}
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
