import { Box, Grid, GridItem, ResponsiveValue } from '@chakra-ui/react'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import { QuoteEditorContent } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/QuoteEditorContent'
import { QuoteEditorHeader } from 'modules/Cube/view/layouts/quote/QuoteEditorHeader/QuoteEditorHeader'
import { QuoteEditorSidebar } from 'Cube/view/layouts/quote/QuoteEditorSidebar/QuoteEditorSidebar'
import { useQuoteEditorLayout } from 'modules/Cube/view/layouts/quote/useQuoteEditorLayout'
import { QuoteEditorInspector } from './QuoteEditorInspector/QuoteEditorInspector'
import { CoverImageEditor } from './QuoteEditorContent/CoverImageEditor/CoverImageEditor'
import { CoverImageEditorProvider } from './QuoteEditorContent/CoverImageEditor/CoverImageEditorContext'
import { useFlags } from 'launchdarkly-react-client-sdk'

const styles = {
  container: {
    width: '100%',
    minWidth: '1218px',
    height: '100vh',
    overflow: 'hidden'
  },
  content: {
    overflowY: 'auto' as const,
    position: 'relative' as ResponsiveValue<'relative'>
  },
  sidebar: {
    position: 'sticky' as ResponsiveValue<'sticky'>,
    height: '100%',
    right: 0,
    top: 0,
    borderLeft: `1px solid ${GreyGrey30}`
  }
}

export const QuoteEditorLayout = () => {
  const { ready } = useQuoteEditorLayout()
  const flags = useFlags()

  if (!ready) {
    return <SpinnerContainer />
  }

  return (
    <Grid
      data-testid="quote.layout"
      templateAreas={`
    "header"
    "content"
    `}
      templateRows={`auto 1fr`}
      columnGap="20px"
      width="100%"
      height="100vh"
      overflow="auto"
    >
      <GridItem gridArea="header">
        <QuoteEditorHeader />
      </GridItem>
      <CoverImageEditorProvider>
        <Grid
          templateColumns={`1fr auto`}
          templateRows={`auto 1fr`}
          templateAreas={`
          "cover sidebar"
          "main sidebar"`}
          gridArea="content"
          height="100%"
          minWidth="1218px"
          overflow="hidden"
        >
          <Box overflow="auto">
            {flags.useQuoteCoverImages && (
              <GridItem gridArea="cover" width="100%">
                <CoverImageEditor />
              </GridItem>
            )}

            <GridItem gridArea="main" width="100%" overflow="auto">
              <Grid rowGap="20px" justifyItems="center">
                <QuoteEditorContent />
              </Grid>
            </GridItem>
          </Box>

          <GridItem gridArea="sidebar" zIndex="10">
            <QuoteEditorInspector />
          </GridItem>
        </Grid>
      </CoverImageEditorProvider>
    </Grid>
  )
}
