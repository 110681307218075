import { FC } from 'react'

interface AmazonRedshiftIconProps {
  width?: number
  height?: number
}

export const AmazonRedshiftIcon: FC<AmazonRedshiftIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.9735 48.9338L56.3112 54.7285V9.14233L31.9735 14.937V48.9338Z"
      fill="#205B97"
    />
    <path
      d="M56.3113 9.14233L60.947 11.4602V52.4106L56.3113 54.7285V9.14233ZM31.9735 48.9338L7.6358 54.7285V9.14233L31.9735 14.937V48.9338Z"
      fill="#5193CE"
    />
    <path
      d="M7.63576 9.14233L3 11.4602V52.4106L7.63576 54.7285V9.14233Z"
      fill="#205B97"
    />
    <path
      d="M38.1557 64L47.4272 59.3642V4.63576L38.1557 0L35.3211 30.3908L38.1557 64Z"
      fill="#5193CE"
    />
    <path
      d="M25.7913 64L16.5198 59.3642V4.63576L25.7913 0L28.6258 30.3908L25.7913 64Z"
      fill="#205B97"
    />
    <path d="M25.7913 0H38.2813V63.8708H25.7913V0Z" fill="#2E73B7" />
  </svg>
)
