import { Box } from '@chakra-ui/react'

import { Lato14Bold } from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  SelectField,
  SelectFieldWithActions
} from '@sequencehq/core-components'
import { useGraduatedPriceForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/GraduatedPrice/useGraduatedPriceForm'
import { FixedTierTableInput } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/inputs/PercentageTierTableInput/PercentageTierTableInput'
import { UsageMetricParameterFields } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/UsageMetricParameterFields'
import {
  AvailableStandardFrequency,
  UsageCalculationMode,
  UsageTierType
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import UsageCalculationPeriodDropdown from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import { usageCalculationModeDescriptions } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants'

export const GraduatedPriceForm = () => {
  const { fieldsConfig, currency, fieldsAreHidden } = useGraduatedPriceForm()
  const flags = useFlags()

  return (
    <Box paddingBottom={fieldsAreHidden ? '0px' : '8px'}>
      {!fieldsConfig.billingFrequency.hidden && (
        <HorizontalSelectorField
          label="Frequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
      )}
      {!fieldsConfig.usageMetricId.hidden && (
        <SelectFieldWithActions
          label="Usage metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
      )}
      {!fieldsConfig.usageMetricId.hidden && (
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
      )}
      <HorizontalSelectorField
        label="Type"
        value={fieldsConfig.usageTierType.value}
        options={fieldsConfig.usageTierType.options}
        onChange={newValue =>
          fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
        }
        validationErrors={fieldsConfig.usageTierType.validationErrors}
        isDisabled={fieldsConfig.usageTierType.disabled}
      />
      <Box
        paddingTop="8px"
        marginBottom={fieldsAreHidden ? '0px' : '16px'}
        paddingBottom={fieldsAreHidden ? '0px' : '8px'}
        width="100%"
      >
        <Box {...Lato14Bold} marginBottom="8px">
          Tiers
        </Box>
        {fieldsConfig.usageTierType.value === 'FIXED' ? (
          <FixedTierTableInput
            value={fieldsConfig.tiers.value}
            onChange={fieldsConfig.tiers.onChange}
            isDisabled={fieldsConfig.tiers.disabled}
            currency={currency}
            validationErrors={fieldsConfig.tiers.validationErrors}
          />
        ) : (
          <PercentageTierTableInput
            value={fieldsConfig.percentageTiers.value}
            onChange={fieldsConfig.percentageTiers.onChange}
            isDisabled={fieldsConfig.percentageTiers.disabled}
            currency={currency}
            validationErrors={fieldsConfig.percentageTiers.validationErrors}
          />
        )}
      </Box>
      {!fieldsConfig.usageCalculationMode.hidden && (
        <>
          {!flags.useUsageResetCadences && (
            <SelectField
              label="Usage calculation mode"
              description={
                usageCalculationModeDescriptions[
                  fieldsConfig.usageCalculationMode.value
                ]
              }
              options={fieldsConfig.usageCalculationMode.options}
              validationErrors={
                fieldsConfig.usageCalculationMode.validationErrors
              }
              value={fieldsConfig.usageCalculationMode.value}
              onChange={newValue =>
                fieldsConfig.usageCalculationMode.onChange(
                  newValue as UsageCalculationMode
                )
              }
              isDisabled={fieldsConfig.usageCalculationMode.disabled}
              placeholder="Select usage calculation mode"
            />
          )}

          {flags.useUsageResetCadences && (
            <UsageCalculationPeriodDropdown
              options={fieldsConfig.usageCalculationPeriod.options}
              value={fieldsConfig.usageCalculationPeriod.value}
              onChange={fieldsConfig.usageCalculationPeriod.onChange}
              validationErrors={
                fieldsConfig.usageCalculationPeriod.validationErrors
              }
            />
          )}
        </>
      )}
    </Box>
  )
}

export const HiddenFieldsForm = () => {
  const { fieldsConfig } = useGraduatedPriceForm()
  const flags = useFlags()

  return (
    <>
      <HorizontalSelectorField
        label="Frequency"
        value={fieldsConfig.billingFrequency.value}
        options={fieldsConfig.billingFrequency.options}
        onChange={newValue =>
          fieldsConfig.billingFrequency.onChange(
            newValue as AvailableStandardFrequency
          )
        }
        validationErrors={fieldsConfig.billingFrequency.validationErrors}
        isDisabled={fieldsConfig.billingFrequency.disabled}
      />
      <SelectFieldWithActions
        label="Usage metric"
        options={fieldsConfig.usageMetricId.options}
        validationErrors={fieldsConfig.usageMetricId.validationErrors}
        value={fieldsConfig.usageMetricId.value}
        onChange={fieldsConfig.usageMetricId.onChange}
        isDisabled={fieldsConfig.usageMetricId.disabled}
        placeholder="Select usage metric"
        onAddNew={fieldsConfig.usageMetricId.onAddNew}
      />
      <UsageMetricParameterFields
        fieldsConfig={fieldsConfig}
        usageMetricId={fieldsConfig.usageMetricId.value}
      ></UsageMetricParameterFields>
      <HorizontalSelectorField
        label="Type"
        value={fieldsConfig.usageTierType.value}
        options={fieldsConfig.usageTierType.options}
        onChange={newValue =>
          fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
        }
        validationErrors={fieldsConfig.usageTierType.validationErrors}
        isDisabled={fieldsConfig.usageTierType.disabled}
      />
      {!flags.useUsageResetCadences && (
        <SelectField
          label="Usage calculation mode"
          description={
            usageCalculationModeDescriptions[
              fieldsConfig.usageCalculationMode.value
            ]
          }
          options={fieldsConfig.usageCalculationMode.options}
          validationErrors={fieldsConfig.usageCalculationMode.validationErrors}
          value={fieldsConfig.usageCalculationMode.value}
          onChange={newValue =>
            fieldsConfig.usageCalculationMode.onChange(
              newValue as UsageCalculationMode
            )
          }
          isDisabled={fieldsConfig.usageCalculationMode.disabled}
          placeholder="Select usage calculation mode"
        />
      )}

      {flags.useUsageResetCadences && (
        <UsageCalculationPeriodDropdown
          options={fieldsConfig.usageCalculationPeriod.options}
          value={fieldsConfig.usageCalculationPeriod.value}
          onChange={fieldsConfig.usageCalculationPeriod.onChange}
          validationErrors={
            fieldsConfig.usageCalculationPeriod.validationErrors
          }
        />
      )}
    </>
  )
}
