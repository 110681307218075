import {
  GraduatedPriceForm,
  HiddenFieldsForm as GraduatedHiddenFields
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/GraduatedPrice/GraduatedPriceForm'
import {
  LinearPriceForm,
  HiddenFieldsForm as LinearHiddenFields
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/LinearPrice/LinearPriceForm'
import {
  PackagedPriceForm,
  HiddenFieldsForm as PackagedHiddenFields
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/PackagedPrice/PackagedPriceForm'
import {
  SeatBasedPriceForm,
  HiddenFieldsForm as SeatBasedHiddenFields
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/SeatBasedPrice/SeatBasedPriceForm'
import {
  StandardPriceForm,
  HiddenFieldsForm as StandardHiddenFields
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/StandardPrice/StandardPriceForm'
import {
  VolumePriceForm,
  HiddenFieldsForm as VolumePriceHiddenFields
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/VolumePrice/VolumePriceForm'
import { PricingModel } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { FC } from 'react'

export const pricingModelForms: Record<PricingModel, FC<unknown>> = {
  STANDARD: StandardPriceForm,
  GRADUATED: GraduatedPriceForm,
  LINEAR: LinearPriceForm,
  VOLUME: VolumePriceForm,
  PACKAGED: PackagedPriceForm,
  SEAT_BASED_LINEAR: SeatBasedPriceForm,
  SEAT_BASED_GRADUATED: SeatBasedPriceForm
}

export const pricingModelHiddenFieldForms: Record<PricingModel, FC<unknown>> = {
  STANDARD: StandardHiddenFields,
  GRADUATED: GraduatedHiddenFields,
  LINEAR: LinearHiddenFields,
  VOLUME: VolumePriceHiddenFields,
  PACKAGED: PackagedHiddenFields,
  SEAT_BASED_LINEAR: SeatBasedHiddenFields,
  SEAT_BASED_GRADUATED: SeatBasedHiddenFields
}
