import { CubeStatus } from 'modules/Cube/domain/cube.domain.types'
import {
  quoteStatusAdapter,
  scheduleStatusAdapter
} from 'modules/Cube/utils/status.adapter'

export const scheduleEndStateStatuses = [
  CubeStatus.ScheduleSuspended,
  CubeStatus.ScheduleCancelled,
  CubeStatus.ScheduleCompleted
]

export const statusIsQuote = (status: CubeStatus) =>
  Boolean(quoteStatusAdapter.out(status))

export const statusIsSchedule = (status: CubeStatus) =>
  Boolean(scheduleStatusAdapter.out(status))
