import { FC } from 'react'

interface PlanIconProps {
  width?: number
  height?: number
}

export const PlanIcon: FC<PlanIconProps> = ({ width = 20, height = 20 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.0002" cy="10" r="10" fill="#EAF7FC" />
    <g clipPath="url(#clip0_492_51795)">
      <path
        d="M11.0002 14.2V11.3C11.0002 11.1343 11.1345 11 11.3002 11H14.2002C14.3659 11 14.5002 11.1343 14.5002 11.3V14.2C14.5002 14.3657 14.3659 14.5 14.2002 14.5H11.3002C11.1345 14.5 11.0002 14.3657 11.0002 14.2Z"
        stroke="#485972"
        strokeWidth="1.5"
      />
      <path
        d="M5.50024 14.2V11.3C5.50024 11.1343 5.63456 11 5.80024 11H8.70024C8.86593 11 9.00024 11.1343 9.00024 11.3V14.2C9.00024 14.3657 8.86593 14.5 8.70024 14.5H5.80024C5.63456 14.5 5.50024 14.3657 5.50024 14.2Z"
        stroke="#485972"
        strokeWidth="1.5"
      />
      <path
        d="M11.0002 8.7V5.8C11.0002 5.63431 11.1345 5.5 11.3002 5.5H14.2002C14.3659 5.5 14.5002 5.63431 14.5002 5.8V8.7C14.5002 8.86568 14.3659 9 14.2002 9H11.3002C11.1345 9 11.0002 8.86568 11.0002 8.7Z"
        stroke="#485972"
        strokeWidth="1.5"
      />
      <path
        d="M5.50024 8.7V5.8C5.50024 5.63431 5.63456 5.5 5.80024 5.5H8.70024C8.86593 5.5 9.00024 5.63431 9.00024 5.8V8.7C9.00024 8.86568 8.86593 9 8.70024 9H5.80024C5.63456 9 5.50024 8.86568 5.50024 8.7Z"
        stroke="#485972"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_492_51795">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(4.00024 4)"
        />
      </clipPath>
    </defs>
  </svg>
)
