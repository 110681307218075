import { Grid, GridItem } from '@chakra-ui/react'
import { GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'

export const DeferredRevenueActions = () => {
  return (
    <Grid gridTemplateColumns="1fr auto" alignItems="center">
      <GridItem {...Lato14Bold} color={GreyGrey80} userSelect="none">
        Overview
      </GridItem>
    </Grid>
  )
}
