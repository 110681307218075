import { FC } from 'react'

interface HubspotIconProps {
  width?: number
  height?: number
}

export const HubspotIcon: FC<HubspotIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64C49.6731 64 64 49.6731 64 32Z"
      fill="#FF7A59"
    />
    <path
      d="M39.8603 39.9411C37.1723 39.9411 34.9919 37.7846 34.9919 35.124C34.9919 32.4634 37.1716 30.3062 39.8603 30.3062C42.5491 30.3062 44.7302 32.4627 44.7302 35.124C44.7302 37.7853 42.5505 39.9411 39.8603 39.9411ZM41.3186 25.8491V21.56C41.8888 21.2989 42.3726 20.8803 42.7131 20.3535C43.0535 19.8267 43.2364 19.2137 43.2402 18.5865V18.4874C43.2402 16.6747 41.7412 15.1925 39.9109 15.1925H39.8104C37.9823 15.1925 36.4811 16.6754 36.4811 18.4874V18.5865C36.4851 19.2136 36.6681 19.8266 37.0085 20.3534C37.3489 20.8801 37.8326 21.2987 38.4027 21.56V25.8491C36.761 26.0967 35.2128 26.7705 33.9126 27.8031L22.0227 18.6505C22.106 18.3494 22.1506 18.0389 22.1556 17.7266C22.1556 15.6727 20.4759 14.0056 18.4009 14C16.326 13.9944 14.6427 15.6587 14.6406 17.7132C14.6385 19.7677 16.3176 21.4313 18.3925 21.4349C19.0424 21.4321 19.6804 21.2603 20.2438 20.9363L31.9424 29.9434C30.89 31.511 30.3402 33.3621 30.3662 35.2501C30.3922 37.138 30.9928 38.9733 32.088 40.5113L28.5288 44.034C28.1077 43.902 27.663 43.8629 27.2253 43.9193C26.7876 43.9757 26.3674 44.1264 25.9935 44.3608C25.6196 44.5953 25.3011 44.908 25.0597 45.2775C24.8184 45.6469 24.66 46.0643 24.5954 46.5009C24.5309 46.9374 24.5618 47.3828 24.686 47.8062C24.8102 48.2297 25.0247 48.6212 25.3147 48.9538C25.6048 49.2864 25.9635 49.5522 26.3661 49.7328C26.7687 49.9134 27.2058 50.0046 27.647 50C29.3514 50 30.7338 48.6317 30.7338 46.9449C30.7307 46.6485 30.6833 46.3541 30.5931 46.0717L34.1088 42.5898C35.7637 43.8442 37.7837 44.5225 39.8603 44.5213C45.1063 44.5213 49.3567 40.3145 49.3567 35.124C49.3567 30.4257 45.8685 26.5459 41.3186 25.8491Z"
      fill="white"
    />
  </svg>
)
