import { Box, Flex, Image } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import attioCover from './attioCover.png'

export const AttioIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={attioCover}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Launch quotes from Attio and sync records with Sequence.
      </Box>
    </Flex>
  )
}
