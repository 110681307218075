import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner
} from '@chakra-ui/react'
import { SwitchLineInput, TextInput } from '@sequencehq/core-components'
import {
  GreyGrey10,
  GreyGrey100,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular,
  Lato14ExtendedBold,
  RedRed60
} from '@sequencehq/design-tokens'
import { ResolvedIntegration } from 'modules/Integrations/domain'
import { useRemoveIntegrationModal } from 'modules/Integrations/view/integrationManagement/IntegrationManagementActions/useRemoveIntegrationModal'

/**
 * TODO: Improve data flow by introducing a context for each of the views (browser, modal, and
 * management) to share the state around, including the service scope.
 */
type RemoveIntegrationModalProps = {
  service: ResolvedIntegration
  onClose: () => void
  onSuccess: (args: { removeLinks: boolean }) => Promise<void>
}

export const RemoveIntegrationModal = (props: RemoveIntegrationModalProps) => {
  const hook = useRemoveIntegrationModal({
    service: props.service,
    onSuccess: props.onSuccess,
    onClose: props.onClose
  })

  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent borderRadius="8px" width="410px" maxWidth="410px">
          <Grid padding="16px" gridRowGap="16px">
            <GridItem>
              <Box
                {...Lato14ExtendedBold}
                color={GreyGrey100}
                marginBottom="8px"
              >
                Are you sure you’d like to disconnect Sequence from{' '}
                {hook.serviceName}?
              </Box>
              <Box {...Lato13Regular} color={GreyGrey80}>
                This stops all future sync workflows, and removes the connection
                between your current records and {hook.serviceName}.
              </Box>
            </GridItem>
            {hook.fields.removeLinks.visible && (
              <GridItem>
                <SwitchLineInput
                  text={`Also remove all links between Sequence and ${hook.serviceName}`}
                  value={hook.fields.removeLinks.value}
                  onChange={hook.fields.removeLinks.onChange}
                  styles={{
                    label: {
                      ...Lato13Bold
                    }
                  }}
                />
              </GridItem>
            )}
            <Box
              borderRadius="8px"
              backgroundColor={GreyGrey10}
              width="100%"
              padding="12px"
            >
              <Box {...Lato13Bold} color={GreyGrey80} marginBottom="16px">
                Type UNINSTALL to continue
              </Box>
              <TextInput
                data-testid="removeIntegrationModal.confirmationField"
                width="100%"
                placeholder="UNINSTALL"
                onKeyPress={hook.fields.confirmationField.onKeyPress}
                value={hook.fields.confirmationField.value}
                onChange={hook.fields.confirmationField.onChange}
              />
            </Box>
            <GridItem display="grid" gridTemplateColumns="1fr 1fr" gap="8px">
              <Button
                variant="component-library-secondary"
                width="100%"
                onClick={hook.actions.cancel.onClick}
              >
                Cancel
              </Button>
              <Button
                variant="component-library-secondary"
                color={RedRed60}
                width="100%"
                onClick={hook.actions.confirm.onClick}
                isDisabled={hook.actions.confirm.disabled}
              >
                <Flex gap="8px" alignItems="center">
                  {hook.actions.confirm.removing && (
                    <Spinner height="16px" width="16px" color={RedRed60} />
                  )}
                  {hook.actions.confirm.label}
                </Flex>
              </Button>
            </GridItem>
          </Grid>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
