import { PostActionStage } from 'modules/Cube/domain/cube.domain.types'
import { scheduleStatusAdapter } from 'modules/Cube/utils/status.adapter'

/**
 * We want the price currency to track the overarching currency of the quote/schedule.
 * In schedules the currency is derived from the currency of the prices, but in quotes
 * this is manually set. When changing the currency, it makes sense to ensure the prices
 * follow suit.
 *
 * @param ctx
 * @returns
 */
export const updatePriceCurrencies: PostActionStage = ctx => prevState => {
  const pricesInSchedule = prevState.data.common.phaseIds
    .flatMap(phaseId => prevState.data.phases[phaseId].priceIds)
    .map(priceId => prevState.data.prices[priceId])

  const priceCurrency = pricesInSchedule[0]?.currency ?? undefined

  /**
   * If the currency has been changed, up date the prices to match the new currency.
   */
  if (
    ctx.preActionState.data.common.currency !== prevState.data.common.currency
  ) {
    return {
      ...prevState,
      data: {
        ...prevState.data,
        prices: Object.keys(prevState.data.prices).reduce((acc, priceId) => {
          return {
            ...acc,
            [priceId]: {
              ...prevState.data.prices[priceId],
              currency: prevState.data.common.currency
            }
          }
        }, {})
      }
    }
  }

  /**
   * If the common currency is defined and different from the price currency, align it.
   * This is a schedule specific path, since schedules derive their currency exclusively
   * from prices, so this keeps the data in sync for that scenario. We should look to
   * make currency control explicit in schedules, which removes this branch and provides
   * a more consistent UX.
   */
  const isASchedule = scheduleStatusAdapter.out(prevState.data.common.status)
  if (isASchedule && priceCurrency !== prevState.data.common.currency) {
    return {
      ...prevState,
      data: {
        ...prevState.data,
        common: {
          ...prevState.data.common,
          currency: priceCurrency
        }
      }
    }
  }

  return prevState
}
