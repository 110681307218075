import {
  Currency,
  PriceModel,
  PricingTier,
  PricingType,
  decimalFromPercentage,
  toCurrencySymbol,
  toPricingTypeTableLabel
} from '@sequencehq/core-models'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import currency from 'currency.js'
import {
  ListPrice,
  PriceTableRow,
  PriceTierTableRow,
  VariantPrice
} from 'modules/Products/types'
import { match } from 'ts-pattern'
import { priceStructureToUrlChunk, toPriceSummary } from '@sequencehq/utils'

const getPriceFormatter = (symbol: string) => (price: string) =>
  currency(price, { symbol: symbol }).format()

const percentageFormatter = (percentage: string) =>
  Number(decimalFromPercentage(percentage)).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2
  })

export const getCurrencyValue = (symbol: Currency, price: string) =>
  currency(price, {
    symbol: toCurrencySymbol(symbol)
  }).value

export const priceRowFormatter = (
  price: ListPrice | VariantPrice
): PriceTableRow => ({
  id: price.id,
  name: price.name,
  frequency: price.billingFrequency,
  currency: price.currency,
  unitPrice: toPriceSummary(price),
  pricingModel: `${toPricingTypeTableLabel(
    price.structure.pricingType,
    pricingTypeLabel(price.structure.pricingType)
  )}`,
  pricingModelUrlChunk: priceStructureToUrlChunk(price.structure),
  created: dateTimeWithFormat(price.createdAt, 'dd MMM yyyy')
})

export const pricingTypeLabel = (pricingType: PricingType) => {
  if (pricingType === 'FIXED' || pricingType === 'ONE_TIME') {
    return 'Standard'
  }

  return pricingType
}

export const priceTierRowFormatter = (
  tier: PricingTier,
  index: number,
  tiers: PricingTier[],
  price: PriceModel | ListPrice
): PriceTierTableRow => {
  const currencySymbol = toCurrencySymbol(price.currency)
  const priceFormatter = getPriceFormatter(currencySymbol)

  const previousTier = tiers[index - 1]
  const flatFee =
    Number(tier?.fee) > 0 ? priceFormatter(Number(tier.fee).toString()) : ''

  return match(tier)
    .with({ isPricePercentage: true }, () => {
      const firstUnit = previousTier?.upperBound
        ? currency(previousTier.upperBound, { symbol: currencySymbol })
            .add(0.01)
            .format()
        : priceFormatter('0')

      const lastUnit = tier?.upperBound ? priceFormatter(tier.upperBound) : '∞'

      const perUnit = percentageFormatter(tier.price)

      return { firstUnit, lastUnit, perUnit, flatFee }
    })
    .otherwise(() => {
      const firstUnit = previousTier?.upperBound
        ? (Number(previousTier.upperBound) + 1).toString()
        : '0'

      const lastUnit = tier?.upperBound || '∞'

      const perUnit = priceFormatter(tier.price)

      return { firstUnit, lastUnit, perUnit, flatFee }
    })
}
