import {
  Integration,
  IntegrationsReducerState,
  ResolvedIntegration
} from 'modules/Integrations/domain/integrations.domain.types'

export const getFeaturedIntegrations =
  (ctx: {
    resolvedIntegrations: Partial<
      Record<Integration['id'], ResolvedIntegration>
    >
  }) =>
  (prevState: IntegrationsReducerState): Array<ResolvedIntegration> => {
    return prevState.data.featuredIntegrations
      .map(id => ctx.resolvedIntegrations[id])
      .filter((res): res is ResolvedIntegration => Boolean(res))
  }
