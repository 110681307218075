import {
  CubeReducerState,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'

export const atLeastOnePhasesBlock = (
  prevState: CubeReducerState
): ValidationResult[] | null => {
  const hasPhaseBlocks = prevState.data.presentation.some(
    block => block.blockType == 'phases'
  )

  if (hasPhaseBlocks) {
    return null
  }

  return [
    {
      message: 'At least one pricing section is required'
    }
  ]
}
