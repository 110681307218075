import { CreateCreditGrantEditor } from 'components/CreateCreditGrant'
import { UsageMetricProvider } from 'components/CreateUsageMetric'
import Sidebar from 'components/Sidebar'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'

export const CreateCreditGrant: FC = () => (
  <UsageMetricProvider>
    <Sidebar>
      <CreateCreditGrantEditor />
    </Sidebar>
    <Outlet />
  </UsageMetricProvider>
)
