import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'Cube/domain/cube.domain.types'

export const getLinkSalesforceCustomerAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (!prevState.data.common.customerId) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.NoCustomerSelected,
          metadata: {}
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
