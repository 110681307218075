import { useCallback } from 'react'
import { Currency } from '@sequencehq/core-models'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'

type UseLoadEnabledCurrencies = () => () => Promise<Currency[]>

export const useLoadEnabledCurrencies: UseLoadEnabledCurrencies = () => {
  const load = useCallback(async () => {
    const allCurrencies =
      (await dashboardv20240509Client.getSequenceAccountSettings())?.data
        ?.enabledCurrencies ?? []

    return allCurrencies
  }, [])

  return load
}
