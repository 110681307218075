import { EditorBreadcrumb } from './EditorBreadcrumb/EditorBreadcrumb'
import { StandardPriceListPriceForm } from '../../editors/StandardPrice/StandardPriceListPriceForm'
import { useListPriceLayout } from './useListPriceLayout'
import { SimpleModalUI } from '@sequencehq/core-components'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import { match } from 'ts-pattern'
import { EditorMode } from 'common/drawers/PricingEditor/domain'
import { GraduatedListPriceForm } from '../../editors/GraduatedPrice/GraduatedPriceListPriceForm'
import { LinearPriceListPriceForm } from '../../editors/LinearPrice/LinearPriceListPriceForm'
import { VolumePriceListPriceForm } from '../../editors/VolumePrice/VolumePriceListPriceForm'
import { PackagedPriceListPriceForm } from '../../editors/PackagedPrice/PackagedPriceListPriceForm'
import { SeatBasedPriceListPriceForm } from '../../editors/SeatBasedPrice/SeatBasedPriceListPriceForm'
import { AddProductForm } from './ProductForm/ProductForm'
import { ReviewForm } from './ReviewForm/ReviewForm'
import Spinner from 'components/Loading/Spinner'

export const ListPriceLayout = (props: {
  loading: boolean
  initialMode: EditorMode
}) => {
  const {
    cancelText,
    closeOnSubmit,
    editorMode,
    pricingModel,
    showBreadcrumbs,
    submitAction,
    title,
    onCancel
  } = useListPriceLayout({ initialMode: props.initialMode })

  return (
    <SimpleModalUI
      variant="drawer"
      title={title}
      cancelButtonText={cancelText}
      submitButtonText={submitAction.text}
      onSubmit={submitAction.action}
      closeOnSubmit={closeOnSubmit}
      contentProps={{
        padding: 0
      }}
      submitDisabled={submitAction.disabled}
      onCancel={onCancel}
    >
      <Grid
        templateAreas={`
                "breadcrumb"
                "content"
              `}
        templateRows={'48px 1fr'}
        width="100%"
        height="100%"
      >
        {showBreadcrumbs && (
          <GridItem gridArea="breadcrumb">
            <EditorBreadcrumb />
          </GridItem>
        )}
        <GridItem gridArea="content" overflow="auto">
          <Box
            width="100%"
            minWidth="400px"
            data-testid="pricingEditor.listPriceForm"
            padding="24px"
          >
            {props.loading ? (
              <Spinner />
            ) : (
              match(editorMode)
                .with(EditorMode.ADD_PRODUCT, () => <AddProductForm />)
                .with(EditorMode.CREATE, EditorMode.EDIT, () => {
                  return match(pricingModel)
                    .with('STANDARD', () => <StandardPriceListPriceForm />)
                    .with('GRADUATED', () => <GraduatedListPriceForm />)
                    .with('LINEAR', () => <LinearPriceListPriceForm />)
                    .with('VOLUME', () => <VolumePriceListPriceForm />)
                    .with('PACKAGED', () => <PackagedPriceListPriceForm />)
                    .with('SEAT_BASED_GRADUATED', 'SEAT_BASED_LINEAR', () => (
                      <SeatBasedPriceListPriceForm />
                    ))
                    .otherwise(() => 'form')
                })
                .with(EditorMode.REVIEW, () => <ReviewForm />)
                .exhaustive()
            )}
          </Box>
        </GridItem>
      </Grid>
    </SimpleModalUI>
  )
}
