import { Button, ButtonProps } from '@chakra-ui/react'
import { match } from 'ts-pattern'
import { FC, MutableRefObject } from 'react'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'

type DateButtonProps = {
  selected: boolean
  today: boolean
  inRange?: boolean
  dateProps?: Record<string, any>
  selectableDate: boolean
  label: number
  position?: 'start' | 'end'
  initialRef?: MutableRefObject<HTMLButtonElement | null>
  hasInitialFocus?: boolean
  focusedInput?: 'start' | 'end'
}

const getKeyOffset = (number: number) => {
  const e = document.activeElement
  const buttons =
    document.querySelectorAll<HTMLButtonElement>('button.date-button')
  buttons.forEach((el, i) => {
    const newNodeKey = i + number
    if (el == e) {
      if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
        buttons[newNodeKey].focus()
      } else {
        buttons[0].focus()
      }
    }
  })
}

export const DateButton: FC<DateButtonProps> = ({
  selected,
  today,
  dateProps,
  inRange,
  selectableDate,
  label,
  position,
  initialRef,
  hasInitialFocus,
  focusedInput
}) => {
  const styles = dateStyling({
    today,
    selected,
    selectable: selectableDate,
    inRange,
    position
  })

  return (
    <Button
      {...dateProps}
      {...styles}
      className="date-button"
      ref={hasInitialFocus ? initialRef : null}
      tabIndex={-1}
      isDisabled={!selectableDate}
      _focus={{
        zIndex: 1,
        boxShadow: `0 0 0 2px ${IndigoIndigo50}`,
        borderRadius: match(focusedInput)
          .with('start', () => '8px 0 0 8px')
          .with('end', () => '0 8px 8px 0')
          .otherwise(() => '8px')
      }}
      onKeyDown={event => {
        match(event.key)
          .with('ArrowUp', () => {
            event.preventDefault()
            getKeyOffset(-7)
          })
          .with('ArrowRight', () => {
            event.preventDefault()
            return getKeyOffset(1)
          })
          .with('ArrowLeft', () => {
            event.preventDefault()
            return getKeyOffset(-1)
          })
          .with('ArrowDown', () => {
            event.preventDefault()
            return getKeyOffset(7)
          })
          .otherwise(() => {})
      }}
    >
      {label}
    </Button>
  )
}

type DateStylingArgs = {
  today: boolean
  selected: boolean
  selectable: boolean
  inRange?: boolean
  position?: 'start' | 'end'
}

const dateStyling = ({
  today,
  selected,
  selectable,
  inRange,
  position
}: DateStylingArgs) => {
  const styles: ButtonProps = {}
  styles.border = '1px solid'
  styles.bg = 'white'
  styles.borderColor = 'white'
  styles.fontWeight = '400'
  styles.fontSize = '14px'
  styles.lineHeight = '16px'
  styles.width = '40px'
  styles.height = '40px'
  styles.borderRadius = '0'
  styles._hover = {}

  if (today) {
    styles.borderRadius = '8px'
  }

  if (selectable) {
    if (selected) {
      styles.bg = 'turquoise.80'
      styles.borderColor = 'turquoise.80'
      styles.color = 'white'

      if (position === 'start') {
        styles.borderRadius = '8px 0 0 8px'
      } else if (position === 'end') {
        styles.borderRadius = '0 8px 8px 0'
      } else {
        styles.borderRadius = '8px'
      }
    } else if (inRange) {
      styles.bg = 'gray.20'
      styles.borderColor = 'gray.20'
    } else {
      styles.color = 'gray.100'
      styles._hover = {
        bg: 'gray.20',
        borderColor: 'gray.20'
      }
    }
  }

  if (today) {
    styles.borderColor = 'turquoise.80'
  }

  return styles
}
