import {
  AddProductFormActionTypes,
  AddProductFormState,
  AddProductFormMode,
  AddProductFormActions,
  UpdateFormModeAction,
  UpdateFormDataAction
} from 'Products/drawers/AddProduct/domain/addProduct.type'
import { updateFormMode } from 'Products/drawers/AddProduct/domain/actions/updateFormMode'
import { updateFormData } from 'Products/drawers/AddProduct/domain/actions/updateFormData'

export const INITIAL_ADD_PRODUCT_STATE: AddProductFormState = {
  formData: {
    product: undefined,
    price: undefined
  },
  mode: AddProductFormMode.ADD_PRODUCT
}

export const addProductFormReducer = (
  prevState: AddProductFormState,
  action: AddProductFormActions
): AddProductFormState => {
  switch (action.type) {
    case AddProductFormActionTypes.UPDATE_FORM_MODE:
      return updateFormMode(prevState, action as UpdateFormModeAction)
    case AddProductFormActionTypes.UPDATE_FORM_DATA:
      return updateFormData(prevState, action as UpdateFormDataAction)

    default:
      return prevState
  }
}
