import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { useCallback } from 'react'

type UseSaveProduct = () => {
  createProduct: (
    newProduct: Dashboardv99990101Api.PostProduct.ProductBody
  ) => Promise<Dashboardv99990101Api.PostProduct.Product | null>
}

export const useSaveProduct: UseSaveProduct = () => {
  const { mutateAsync } = useMutation(dashboardv99990101Client.postProduct)
  const createProduct = useCallback(
    async (newProduct: Dashboardv99990101Api.PostProduct.ProductBody) => {
      return mutateAsync({ body: newProduct })
    },
    [mutateAsync]
  )

  return {
    createProduct
  }
}
