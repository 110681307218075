import { pricingModelForms } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { FC, useMemo } from 'react'

type UseListPriceForm = () => {
  ActiveForm: FC<unknown> | null
}

export const useListPriceForm: UseListPriceForm = () => {
  const listPriceEditorContext = useListPriceEditorContext()

  const ActiveForm = useMemo(() => {
    if (!listPriceEditorContext.data.formData.common.pricingModel) {
      return null
    }

    return pricingModelForms[
      listPriceEditorContext.data.formData.common.pricingModel
    ]
  }, [listPriceEditorContext.data.formData.common.pricingModel])

  return {
    ActiveForm
  }
}
