import { Box, Flex, Grid, Text, useToast } from '@chakra-ui/react'
import { Toast } from '@sequencehq/core-components'
import { CustomerModel, toCountryLabel } from '@sequencehq/core-models'
import {
  GreyGrey70,
  GreyGrey80,
  IndigoIndigo50,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { formatAddressForInvoice } from '@sequencehq/invoice-content'
import { taxStatusOptions } from 'components/Customers/data'
import { CustomerAliases } from './CustomerAliases'
import { CustomerLinkedServices } from './CustomerLinkedServices'
import { CustomerRelationship } from './CustomerRelationship'
import { getPrimaryBillingContact } from 'CustomerContacts/utils/getPrimaryBillingContact'
import { CustomerOtherBillingContact } from './CustomerOtherBillingContact.tsx'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { toStateLabel } from '@sequencehq/utils'
import { CustomerLogo } from 'Customer/components/CustomerMeta/CustomerLogo.tsx'

export const CustomerMeta = () => {
  const { customer, customerAliases, taxRegistration } = useCustomerContext()
  const toast = useToast()

  const copyToClipboard = (copiedValue: string) => {
    navigator.clipboard
      .writeText(copiedValue)
      .then(() => {
        toast({
          position: 'bottom',
          isClosable: true,
          duration: 6000,
          render: () => (
            <Toast
              type="success"
              title="Email copied to clipboard"
              isClosable
            />
          )
        })
      })
      .catch(() => {
        //eslint-disable-next-line no-console
        console.error('Failed to copy to clipboard')
      })
  }

  const primaryBillingEmail = getPrimaryBillingContact(customer.contacts)?.email

  return (
    <Flex p={5} flexDirection="column" gap="16px" width="100%">
      <CustomerLogo customer={customer} />
      <Text {...Lato14Bold}>{customer.legalName}</Text>
      <Grid templateColumns="150px 1fr" gap={4} mt={4} color={GreyGrey80}>
        <CustomerRelationship customer={customer} />
        {customerAliases.length > 0 && (
          <CustomerAliases aliases={customerAliases} />
        )}

        <>
          <Text {...Lato13Regular} color={GreyGrey70}>
            Primary billing contact
          </Text>

          <Text
            cursor="pointer"
            {...Lato13Regular}
            color={IndigoIndigo50}
            onClick={() => {
              if (primaryBillingEmail) {
                copyToClipboard(primaryBillingEmail)
              }
            }}
          >
            {primaryBillingEmail}
          </Text>
        </>

        <CustomerOtherBillingContact copyToClipboard={copyToClipboard} />

        <Text {...Lato13Regular} color={GreyGrey70}>
          Tax status
        </Text>
        <Text {...Lato13Regular} color={GreyGrey80}>
          {
            taxStatusOptions[
              customer?.taxStatus as keyof typeof taxStatusOptions
            ]
          }
        </Text>
        {taxRegistration && (
          <>
            <Text {...Lato13Regular} color={GreyGrey70}>
              Tax location
            </Text>
            <Text {...Lato13Regular} color={GreyGrey80}>
              {taxRegistration.state &&
                `${toStateLabel(taxRegistration.state)}, `}
              {toCountryLabel(taxRegistration.country)}
            </Text>
            <Text {...Lato13Regular} color={GreyGrey70}>
              Tax registration ID
            </Text>
            <Text {...Lato13Regular} color={GreyGrey80}>
              {taxRegistration.taxIdentifier}
            </Text>
          </>
        )}

        <Text {...Lato13Regular} color={GreyGrey70}>
          Address
        </Text>
        <Box>
          {formatAddressForInvoice(
            (customer as unknown as CustomerModel).address
          ).map((textValue, index) => (
            <Text
              {...Lato13Regular}
              maxW="120px"
              color={GreyGrey80}
              key={index}
            >
              {textValue}
            </Text>
          ))}
        </Box>
        <CustomerLinkedServices integrationIds={customer.integrationIds} />
      </Grid>
    </Flex>
  )
}
