import { CreateNotificationPolicyForm } from 'components/CreateNotificationPolicy/CreateNotificationPolicyForm'
import { createFormModal } from 'components/Form/createFormModal'
import {
  ModalContextProvider,
  SimpleDialogUI,
  Toast
} from '@sequencehq/core-components'
import { usePostApiNotificationsPoliciesMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'
import CopyInputField from 'components/Form'

export type CreateNotificationPolicyFormMutation = ExtractMutationParams<
  typeof usePostApiNotificationsPoliciesMutation
>

export const CreateNotificationPolicyModal = createFormModal<
  CreateNotificationPolicyFormMutation,
  Record<string, never>
>({
  FormComponent: CreateNotificationPolicyForm,
  useMutation: usePostApiNotificationsPoliciesMutation,
  modalTitleFromProps: () => 'Create notification policy',
  successMessage: 'Notification policy created',
  submitTitle: 'Create',
  formId: 'createNotificationPolicyForm',
  toastDuration: 6000000,
  successToast: response =>
    response.channel === 'WEBHOOK' ? (
      <ModalContextProvider isOpen>
        <SimpleDialogUI
          title="Policy created"
          cancelButtonText="Close"
          confirmButtonText={null}
        >
          Secret key:
          <CopyInputField value={response.webhookSecret ?? ''} />
        </SimpleDialogUI>
      </ModalContextProvider>
    ) : (
      <Toast
        title="Notification policy created"
        type="success"
        description={`Notification policy ${response.name} created`}
        isClosable={true}
      />
    )
})
