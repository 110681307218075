import { Box, Flex } from '@chakra-ui/react'
import { Country, decimalFromPercentage } from '@sequencehq/core-models'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import { CreateTaxRateFormMutation } from 'components/CreateTaxRate/CreateTaxRateModal'
import { MutationFormProps } from 'components/Form/types'
import { DecimalInput } from 'components/FormComponents/DecimalInput'
import { handleFormResponse } from 'lib/formValidation'

import { composeValidators, decimal, required } from '@sequencehq/validation'
import { CountrySelect } from 'components/FormComponents/CountrySelect'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

interface FormValues {
  name: string
  description?: string
  amount: string
  country: Country
  region?: string
}

export const CreateTaxRateForm: FC<
  MutationFormProps<CreateTaxRateFormMutation> & Record<string, never>
> = ({ formId, submitForm }) => {
  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{
        name: undefined,
        description: undefined,
        amount: undefined,
        country: undefined,
        region: undefined
      }}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          createTaxRateRequest: {
            ...values,
            amount: decimalFromPercentage(values.amount),
            isPublished: true,
            invoiceName: values.name,
            invoiceShortName: values.name
          }
        })
        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <>
            <Flex
              as="form"
              px={5}
              py={5}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
              id={formId}
            >
              <FormErrors formError={submitError} />
              <TextInputField
                fieldName="name"
                fieldLabel="Name"
                validate={required}
              />
              <Box height={5} />
              <TextInputField
                fieldName="description"
                fieldLabel="Description (optional)"
              />
              <Box height={5} />
              <DecimalInput
                fieldName="amount"
                fieldLabel="Amount"
                validate={composeValidators(required, decimal('2'))}
                decimalPlaces="4"
                rightAddon="%"
              />
              <Box height={5} />
              <CountrySelect name="country"></CountrySelect>
              <Box height={5} />
              <TextInputField
                fieldName="region"
                fieldLabel="Region (optional)"
              />
            </Flex>
          </>
        )
      }}
    />
  )
}
