import { FC } from 'react'

interface GoogleBigQueryIconProps {
  width?: number
  height?: number
}

export const GoogleBigQueryIcon: FC<GoogleBigQueryIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7598 50.6007L9.161 33.9744C8.79195 33.3352 8.59766 32.6101 8.59766 31.872C8.59766 31.1339 8.79195 30.4088 9.161 29.7696L18.7598 13.1436C19.129 12.5043 19.6599 11.9734 20.2992 11.6043C20.9385 11.2353 21.6637 11.041 22.4019 11.041H41.5996C42.3378 11.041 43.0629 11.2354 43.7022 11.6044C44.3414 11.9735 44.8723 12.5044 45.2413 13.1436L54.8402 29.7696C55.2093 30.4088 55.4036 31.134 55.4036 31.8722C55.4036 32.6103 55.2093 33.3355 54.8402 33.9748L45.2413 50.6007C44.8722 51.2399 44.3414 51.7707 43.7021 52.1397C43.0629 52.5087 42.3377 52.703 41.5996 52.7029H22.4019C21.6637 52.703 20.9385 52.5088 20.2991 52.1397C19.6598 51.7706 19.1289 51.24 18.7598 50.6007Z"
      fill="url(#paint0_linear_6101_13262)"
    />
    <path
      opacity="0.07"
      d="M52.1966 40.0923L38.0803 25.9753L32.001 24.3484L26.5405 26.3779L24.4316 31.8721L26.1273 37.9894L41.0971 52.9592L44.2736 52.818L52.1966 40.0923Z"
      fill="black"
    />
    <path
      d="M31.9993 23.3899C27.3151 23.3899 23.5176 27.1874 23.5176 31.872C23.5176 36.5562 27.3151 40.3537 31.9993 40.3537C36.6835 40.3537 40.4807 36.5562 40.4807 31.872C40.4807 27.1874 36.6832 23.3899 31.9993 23.3899ZM31.9993 38.3074C28.445 38.3074 25.5635 35.4259 25.5635 31.8716C25.5635 28.3173 28.445 25.4362 31.9993 25.4362C35.5536 25.4362 38.4351 28.3177 38.4351 31.872C38.4351 35.4263 35.5536 38.3074 31.9993 38.3074Z"
      fill="white"
    />
    <path
      d="M27.9727 31.5443V34.1808C28.3628 34.8505 28.9131 35.4128 29.5743 35.8172V31.5443H27.9727ZM31.1485 29.3218V36.4198C31.4209 36.4699 31.6996 36.5003 31.9859 36.5003C32.247 36.5003 32.5015 36.4728 32.7512 36.4311V29.3218H31.1485ZM34.4728 32.6399V35.7707C35.1445 35.3436 35.6961 34.752 36.0752 34.0521V32.6402L34.4728 32.6399ZM38.1021 37.0963L37.2234 37.9757C37.149 38.0503 37.1072 38.1514 37.1072 38.2568C37.1072 38.3621 37.149 38.4632 37.2234 38.5378L40.5565 41.8698C40.6311 41.9442 40.7321 41.986 40.8375 41.986C40.9429 41.986 41.0439 41.9442 41.1185 41.8698L41.9972 40.9914C42.0712 40.9167 42.1127 40.8157 42.1127 40.7106C42.1127 40.6054 42.0712 40.5045 41.9972 40.4297L38.6638 37.0963C38.5891 37.0222 38.4881 36.9806 38.3829 36.9806C38.2777 36.9806 38.1768 37.0222 38.1021 37.0963Z"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="63" height="63" rx="31.5" stroke="#F1F4F8" />
    <defs>
      <linearGradient
        id="paint0_linear_6101_13262"
        x1="32.0008"
        y1="11.0414"
        x2="32.0008"
        y2="52.6382"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4387FD" />
        <stop offset="1" stopColor="#4683EA" />
      </linearGradient>
    </defs>
  </svg>
)
