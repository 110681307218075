import { Box } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'

export const AnrokIntegrationDetail = () => {
  return (
    <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
      Automatically calculate sales tax for invoices.
    </Box>
  )
}
