import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey40,
  GreyGrey50,
  GreyGrey80,
  GreyWhite
} from '@sequencehq/design-tokens'
import Bars4Icon from '@heroicons/react/24/outline/Bars4Icon'
import TableCellsIcon from '@heroicons/react/24/outline/TableCellsIcon'
import { Link as RouterLink } from 'react-router-dom'
import { FC } from 'react'
import { useDefaultCurrency } from 'components/CurrencySettings/useCurrencies'

const viewSelectorStyles = {
  wrapper: {
    borderRadius: '6px',
    background: GreyWhite,
    border: `1px solid ${GreyGrey40}`,
    boxShadow:
      '0px 2px 4px 0px rgba(20, 23, 28, 0.08), 0px -2px 2px 0px rgba(0, 0, 0, 0.04) inset',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  iconWrapper: {
    cursor: 'pointer',
    padding: '7px 0',
    transitionProperty: 'background-color',
    transitionDuration: '200ms'
  },
  icon: {
    height: '20px',
    width: '20px'
  }
}

type InvoiceViewSelectorProps = {
  active: 'list' | 'grid'
}

export const InvoiceViewSelector: FC<InvoiceViewSelectorProps> = ({
  active
}) => {
  const defaultCurrency = useDefaultCurrency()

  return (
    <Flex {...viewSelectorStyles.wrapper}>
      <Box
        {...viewSelectorStyles.iconWrapper}
        as={RouterLink}
        to="/invoices"
        paddingRight="2px"
        paddingLeft="8px"
        _hover={{
          backgroundColor: active === 'list' ? 'none' : GreyGrey20
        }}
      >
        <Bars4Icon
          style={{
            ...viewSelectorStyles.icon,
            color: active !== 'list' ? GreyGrey50 : GreyGrey80
          }}
        />
      </Box>
      <Box
        {...viewSelectorStyles.iconWrapper}
        as={RouterLink}
        to={`/invoices?view=grid&currency=${defaultCurrency}`}
        paddingLeft="2px"
        paddingRight="8px"
        _hover={{
          backgroundColor: active === 'grid' ? 'none' : GreyGrey20
        }}
      >
        <TableCellsIcon
          style={{
            ...viewSelectorStyles.icon,
            color: active !== 'grid' ? GreyGrey50 : GreyGrey80
          }}
        />
      </Box>
    </Flex>
  )
}
