import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'
import { hasCubeBeenUpdated } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/utils/hasCubeBeenUpdated'

export const getSaveAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const available = [
    CubeStatus.ScheduleDraft,
    CubeStatus.ScheduleUnsaved
  ].includes(prevState.data.common.status)

  if (!available) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: { status: prevState.data.common.status }
        }
      ]
    }
  }

  if (prevState.editor.savingDraft || prevState.editor.savingSchedule) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.SavingInProgress,
          metadata: {}
        }
      ]
    }
  }

  const enabled = hasCubeBeenUpdated(prevState)
  return {
    available: {
      visible: true,
      enabled: enabled
    },
    reasons: enabled
      ? []
      : [{ reasonType: DisabledReasonType.NoChangesMade, metadata: {} }]
  }
}
