import { FC } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import CtaCard from 'components/Home/CtaCard'
import { isDemoEnv } from 'lib/environment/environment'

const Home: FC = () => {
  const ctaCards = [
    {
      title: 'Automate your billing process',
      description:
        'Create a new billing schedule for a customer and put invoicing on autopilot.',
      ctaLink: '/billing-schedules/new',
      image: '/img/home-billing.png',
      imageAlt: 'Create a customer',
      chevron: true
    },
    {
      title: 'Learn how to use Sequence',
      description:
        'Head over to our docs to learn more about what Sequence has to offer.',
      ctaLink: 'https://docs.sequencehq.com/getting-started/overview',
      image: '/img/home-learn.png',
      imageAlt: 'Open Sequence docs',
      chevron: true
    },
    {
      title: 'Switch to Demo Mode',
      description:
        'Try our pre-filled Demo account to get a sense of Sequence with realistic data.',
      ctaLink: import.meta.env.VITE_DEMO_URL,
      image: '/img/home-demo.png',
      imageAlt: 'Launch demo mode',
      chevron: true,
      disabled: isDemoEnv()
    }
  ]

  return (
    <>
      <Flex flexDirection="column" p="24px">
        <Text textStyle="sectionHeader" mb="8px">
          Getting started
        </Text>
        <Text textStyle="default">
          You're just a few steps away from getting the most out of Sequence
        </Text>
      </Flex>
      <Flex px="24px" flexWrap="wrap">
        {ctaCards.map(ctaCard => (
          <CtaCard
            key={ctaCard.title}
            title={ctaCard.title}
            description={ctaCard.description}
            ctaLink={ctaCard.ctaLink}
            image={ctaCard.image}
            imageAlt={ctaCard.imageAlt}
            disabled={ctaCard.disabled}
          />
        ))}
      </Flex>
    </>
  )
}

export default Home
