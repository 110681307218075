import { IconButton } from '@chakra-ui/react'
import { GreyGrey70 } from '@sequencehq/design-tokens'

import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import CalendarDaysIcon from '@heroicons/react/16/solid/CalendarDaysIcon'
import DocumentCheckIcon from '@heroicons/react/16/solid/DocumentCheckIcon'
import CubeIcon from '@heroicons/react/16/solid/CubeIcon'

import { useNavigate } from 'react-router-dom'
import { SequenceMenu, SequenceMenuItemType } from '@sequencehq/core-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const AddMenu = () => {
  const navigate = useNavigate()
  const flags = useFlags()

  const items: SequenceMenuItemType[] = [
    {
      label: 'New schedule',
      iconRight: (
        <CalendarDaysIcon width="16px" height="16px" color="inherit" />
      ),
      onClick: () => navigate('/billing-schedules/new?reset=true')
    },
    {
      label: 'New quote',
      iconRight: (
        <DocumentCheckIcon width="16px" height="16px" color="inherit" />
      ),
      onClick: () => navigate('/quotes/new?reset=true'),
      hidden: !flags?.showQuoteBuilder
    },
    {
      label: 'New product',
      iconRight: <CubeIcon width="16px" height="16px" color="inherit" />,
      onClick: () => {},
      hidden: true
    }
  ]

  return (
    <SequenceMenu
      width={180}
      menuButton={
        <IconButton
          height="28px"
          width="28px"
          padding="0"
          minWidth="initial"
          variant="secondary2"
          aria-label="Action"
          icon={<PlusIcon width="16px" height="16px" color={GreyGrey70} />}
          flexShrink="0"
        />
      }
      items={items}
    />
  )
}
