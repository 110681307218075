import { PricingEditorRoot } from 'common/drawers/PricingEditor/view/pricingEditor.root'
import { useNewListPriceEditorConnector } from './useNewListPriceEditor.connector'

export const NewListPriceEditorRoute = () => {
  const context = useNewListPriceEditorConnector()

  return (
    <PricingEditorRoot
      variant="list"
      initialMode={context.initialPriceEditorMode}
      onSave={context.onSave}
    />
  )
}
