import { Flex } from '@chakra-ui/react'
import { GreyGrey0 } from '@sequencehq/design-tokens'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const navigationWidth = 190

export const Container = ({ children }: { children: React.ReactNode }) => {
  /**
   * We track useRevRec because when revenue recognition is enabled we will
   * have a slightly wider navigation to accomodate the module title. However,
   * before then we'll retain the previous width to prevent the Nav being overly
   * wide.
   */
  const flags = useFlags()

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      width={flags.useRevRec ? '206px' : '190px'}
      height="100vh"
      bg={GreyGrey0}
      flexShrink="0"
      position="sticky"
      top="0"
      left="0"
    >
      {children}
    </Flex>
  )
}
