import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './attioLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { AttioIntegrationDetail } from './AttioIntegrationDetail/AttioIntegrationDetail'

export const attioIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: 'COMING_SOON',
  documentationUrl: '',
  title: 'Attio',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: false
  },
  description: 'Launch quotes from Attio and sync records with Sequence.',
  components: {
    detail: AttioIntegrationDetail,
    management: NoConfiguration
  }
}
