import { StandardLayout } from '@sequencehq/core-components'
import { JournalReportDetailContent } from './content/JournalReportDetailContent'
import { JournalReportDetailHeader } from './JournalReportDetailHeader'

export const JournalReportDetail = () => {
  return (
    <StandardLayout
      header={<JournalReportDetailHeader />}
      content={<JournalReportDetailContent />}
    />
  )
}
