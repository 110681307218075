import {
  dashboardv20240509Client,
  Dashboardv20240509Api
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import {
  SchedulePreviewArguments,
  SchedulePreviewResponse
} from 'modules/Cube/domain/cube.domain.types'
import { useCallback } from 'react'

export type UseLoadBillingSchedulePreview = () => (
  args: Dashboardv20240509Api.PostBillingScheduleBillingPreview.BillingPreviewBody
) => Promise<
  Dashboardv20240509Api.PostBillingScheduleBillingPreview.BillingPreview['occurrences']
>

export const useLoadSchedulePreview = () => {
  const load = useCallback(
    async (
      args: SchedulePreviewArguments
    ): Promise<SchedulePreviewResponse> => {
      const response =
        await dashboardv20240509Client.postBillingScheduleBillingPreview({
          body: args
        })

      return response.data?.occurrences ?? []
    },
    []
  )

  return load
}
