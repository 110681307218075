import { ArchiveTaxRateForm } from 'components/ArchiveTaxRate/ArchiveTaxRateForm'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { usePutTaxRatesByIdArchiveMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'

export type ArchiveTaxRateFormMutation = ExtractMutationParams<
  typeof usePutTaxRatesByIdArchiveMutation
>

export type ArchiveTaxRateFormProps = {
  id: string
  name: string
}

export const ArchiveTaxRateModal = createFormModal<
  ArchiveTaxRateFormMutation,
  ArchiveTaxRateFormProps
>({
  FormComponent: ArchiveTaxRateForm,
  useMutation: usePutTaxRatesByIdArchiveMutation,
  modalTitleFromProps: () => 'Archive tax rate',
  successMessage: 'Tax rate archived',
  submitTitle: 'Archive',
  formId: 'archiveTaxRateForm',
  successToast: response => (
    <Toast
      title="Tax rate archived"
      type="success"
      description={`Tax rate ${response.name} archived`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})
