import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { GreyGrey30, GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'
import { EmptyContent } from 'modules/Products/Product/components/EmptyContent'
import { ProductPriceVariant } from 'modules/Products/Product/components/ProductPriceVariant'
import { priceRowFormatter } from 'modules/Products/formatters'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { useMemo } from 'react'

export const ProductPriceVariants = () => {
  const {
    data: {
      variantPrices,
      product: { id: productId }
    }
  } = useProductContext()

  const pricesTableData = useMemo(
    () => variantPrices.map(priceRowFormatter),
    [variantPrices]
  )

  return (
    <Flex flexDirection="column" p={4}>
      <Text {...Lato14Bold} color={GreyGrey80}>
        Price variants
      </Text>

      <TableContainer
        borderRadius="lg"
        overflow="hidden"
        border={`1px solid ${GreyGrey30}`}
        mt={4}
        width="100%"
      >
        <Table variant="v2" width="100%">
          <Thead>
            <Tr>
              <Th width="50%">Price</Th>
              <Th>Customer</Th>
              <Th>Currency</Th>
              <Th>Frequency</Th>
              <Th>Pricing model</Th>
              <Th>Created</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pricesTableData.length > 0 ? (
              pricesTableData.map(priceRow => (
                <ProductPriceVariant
                  key={priceRow.id}
                  priceRow={priceRow}
                  productId={productId}
                />
              ))
            ) : (
              <Tr
                sx={{
                  cursor: 'auto!important',
                  _hover: {
                    bgColor: 'inherit!important'
                  }
                }}
              >
                <Td colSpan={5}>
                  <EmptyContent priceType="VARIANT" />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}
