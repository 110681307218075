import { match } from 'ts-pattern'
import { v4 as uuidv4 } from 'uuid'
import type { ServerAsset } from '@sequencehq/api/dashboard/v20240730'

export const MAX_FILE_COUNT = 10

export type ATTACHMENT_ERROR = 'MAX_FILE_COUNT_EXCEEDED'

type UserAddedAttachment = {
  clientId: string
  file: File
}

export type Asset = ServerAsset

type Attachment = UserAddedAttachment | Asset

export type AttachmentReducerState = {
  error: ATTACHMENT_ERROR | null
  attachments: Attachment[] | null
  attachmentToPreview: Asset | null
}

type UserPickedFilesAction = {
  type: 'USER_PICKED_FILES'
  files: File[]
}

type UploadSuccessAction = {
  type: 'UPLOAD_SUCCESS'
  clientId: string
  asset: Asset
}

type DeleteAttachmentAction = {
  type: 'DELETE_ATTACHMENT'
  attachment: Attachment
}

type PreviewAttachmentAction = {
  type: 'PREVIEW_ATTACHMENT'
  attachment: Asset | null
}

export type AttachmentReducerAction =
  | UserPickedFilesAction
  | UploadSuccessAction
  | DeleteAttachmentAction
  | PreviewAttachmentAction

export const attachmentReducer = (
  state: AttachmentReducerState,
  action: AttachmentReducerAction
): AttachmentReducerState => {
  return match<AttachmentReducerAction, AttachmentReducerState>(action)
    .with({ type: 'USER_PICKED_FILES' }, ({ files }) => {
      const attachments = [
        ...(state.attachments ?? []),
        ...files.map(file => ({
          file,
          clientId: uuidv4()
        }))
      ]

      if (attachments.length > MAX_FILE_COUNT) {
        return {
          ...state,
          error: 'MAX_FILE_COUNT_EXCEEDED'
        }
      }

      return {
        ...state,
        error: null,
        attachments
      }
    })
    .with({ type: 'UPLOAD_SUCCESS' }, ({ clientId, asset }) => {
      return {
        ...state,
        attachments:
          state.attachments?.map(attachment =>
            isUserAddedAttachment(attachment) &&
            attachment.clientId === clientId
              ? asset
              : attachment
          ) ?? null
      }
    })
    .with({ type: 'DELETE_ATTACHMENT' }, ({ attachment }) => {
      return {
        ...state,
        attachmentToPreview: null,
        error: state.error === 'MAX_FILE_COUNT_EXCEEDED' ? null : state.error,
        attachments:
          state.attachments?.filter(
            currentAttachment =>
              !isSameAttachment(currentAttachment, attachment)
          ) ?? null
      }
    })
    .with({ type: 'PREVIEW_ATTACHMENT' }, ({ attachment }) => {
      return {
        ...state,
        attachmentToPreview: attachment
      }
    })
    .otherwise(() => state)
}

export const isUserAddedAttachment = (
  attachment: Attachment
): attachment is UserAddedAttachment => 'clientId' in attachment

export const isServerAsset = (attachment: Attachment): attachment is Asset =>
  'id' in attachment

const isSameAttachment = (a: Attachment, b: Attachment) => {
  if (
    isUserAddedAttachment(a) &&
    isUserAddedAttachment(b) &&
    a.clientId === b.clientId
  ) {
    return true
  }

  if (isServerAsset(a) && isServerAsset(b) && a.id === b.id) {
    return true
  }

  return false
}

export function noAttachments(state: AttachmentReducerState) {
  return !state.attachments || state.attachments.length === 0
}
