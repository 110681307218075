import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems.ts'

export type v1ApiListPrice = Dashboardv20240509Api.GetListPrice.ListPrice

type UseLoadListPrices = () => () => Promise<v1ApiListPrice[]>

export const useLoadListPrices: UseLoadListPrices = () => {
  return useLoadAllItems(dashboardv20240509Client.getListPrices)
}
