import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { useMemo } from 'react'

type UseQuoteEditorInspector = () => {
  customerLinking: {
    customerId: string
    canUnlink: boolean
    canLink: boolean
  }
  opportunityLinking: {
    canLink: boolean
    canUnlink: boolean
    onLink: (args: { opportunityId: string }) => void
    onUnlink: () => void
    opportunityId?: string
  }
}

export const useQuoteEditorInspector: UseQuoteEditorInspector = () => {
  const ctx = useCubeContext()

  const opportunityLinking = useMemo(() => {
    return {
      canLink: ctx.queries.availableFeatures.quote.edit.available.enabled,
      canUnlink: ctx.queries.availableFeatures.quote.edit.available.enabled,
      onLink: (args: { opportunityId: string }) => {
        ctx.mutators.updateData({
          quote: {
            salesforceOpportunityId: args.opportunityId
          }
        })
      },
      onUnlink: () => {
        ctx.mutators.updateData({
          quote: {
            salesforceOpportunityId: undefined
          }
        })
      },
      opportunityId: ctx.queries.rawData.data.quote.salesforceOpportunityId
    }
  }, [
    ctx.mutators,
    ctx.queries.rawData.data.quote.salesforceOpportunityId,
    ctx.queries.availableFeatures.quote.edit
  ])

  const customerLinking = useMemo(() => {
    return {
      customerId: ctx.queries.rawData.data.common.customerId,
      canUnlink:
        ctx.queries.availableFeatures.common.inspector.unlinkSalesforceCustomer
          .available.enabled,
      canLink:
        ctx.queries.availableFeatures.common.inspector.linkSalesforceCustomer
          .available.enabled
    }
  }, [
    ctx.queries.rawData.data.common.customerId,
    ctx.queries.availableFeatures.common.inspector.unlinkSalesforceCustomer
      .available.enabled,
    ctx.queries.availableFeatures.common.inspector.linkSalesforceCustomer
      .available.enabled
  ])

  return {
    customerLinking,
    opportunityLinking
  }
}
