import type { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './avalaraLogo.svg'
import { AvalaraIntegrationDetail } from './AvalaraIntegrationDetail'
import { AvalaraConfigurationManagement } from './AvalaraConfigurationManagement/AvalaraConfigurationManagement'
import { avalaraConfigurationLoader } from 'Integrations/integrationsConfig/avalara/avalara.loader'
import { avalaraConfigurationMutator } from 'Integrations/integrationsConfig/avalara/avalara.mutator'

export const avalaraIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/avalara',
  title: 'Avalara',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: false,
    disableSubmitUntilValid: true
  },
  description: 'Automatically calculate sales tax for invoices.',
  components: {
    detail: AvalaraIntegrationDetail,
    management: AvalaraConfigurationManagement
  },
  communication: {
    configuration: {
      loader: avalaraConfigurationLoader,
      mutator: avalaraConfigurationMutator
    }
  }
}

export type AvalaraIntegrationConfig = {
  accountId: string
  licenseKey?: string
  obfuscatedLicenseKey: string
  companyName: string
  companyId?: string
  avalaraCompanyId?: string
}
