import { Grid, GridItem } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey30,
  GreyWhite,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { EditorFooter } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/EditorFooter/EditorFooter'
import { PricesList } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PricesList/PricesList'
import { PriceForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/PriceForm'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { PricesFooter } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PricesFooter/PricesFooter'
import { EditorHeader } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/EditorHeader/EditorHeader'

const styles = {
  header: {
    ...Lato16Bold,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    borderBottom: `1px solid ${GreyGrey30}`,
    minHeight: '56px'
  }
}
export const PricingEditorContent = () => {
  const pricingEditorContext = usePricingEditorContext()

  /*
   * Check the flag instead of adding logic to `availableFeatures.showPriceList` because we'll completely remove the price list
   * and the associated `availableFeature` check once list prices are fully released.
   */
  const showPriceList =
    !pricingEditorContext.configuration.enableListPrices &&
    pricingEditorContext.derived.queries.availableFeatures.showPriceList

  return (
    <Grid
      templateAreas={`
   "prices editor"
`}
      templateColumns={showPriceList ? '230px 550px' : '0 550px'}
      templateRows={'100%'}
      width="fit-content"
      height="100%"
      position="relative"
      borderRadius="8px"
    >
      {showPriceList && (
        <Grid
          gridArea="prices"
          backgroundColor={GreyGrey0}
          borderRight={`1px solid ${GreyGrey30}`}
          templateAreas={'"header" "prices" "footer"'}
          templateRows={'auto 1fr 56px'}
          position="relative"
        >
          <GridItem
            backgroundColor={GreyGrey0}
            gridArea="header"
            {...styles.header}
          >
            {pricingEditorContext.data.product?.name}
          </GridItem>
          <GridItem gridArea="prices" overflowY="auto">
            <PricesList />
          </GridItem>
          <GridItem gridArea="footer">
            <PricesFooter />
          </GridItem>
        </Grid>
      )}
      <Grid
        gridArea="editor"
        backgroundColor={GreyWhite}
        templateAreas={'"header" "editor" "footer"'}
        templateRows={'56px 1fr 56px'}
        position="relative"
        boxShadow="0px 16px 24px 0px rgba(20, 23, 28, 0.08)"
        borderRadius="8px"
      >
        <GridItem
          gridArea="header"
          position="sticky"
          top="0"
          left="0"
          borderTopRightRadius="8px"
          borderTopLeftRadius={showPriceList ? '0' : '8px'}
          overflow="hidden"
        >
          <EditorHeader />
        </GridItem>
        <GridItem gridArea="editor" overflow="auto">
          <PriceForm />
        </GridItem>
        <GridItem
          gridArea="footer"
          position="sticky"
          bottom="0"
          left="0"
          backgroundColor={GreyWhite}
          borderTop={`1px solid ${GreyGrey30}`}
          zIndex="2"
        >
          <EditorFooter />
        </GridItem>
      </Grid>
    </Grid>
  )
}
