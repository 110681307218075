export const creditNoteLineItemRateDisplay = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENTAGE: 'PERCENTAGE'
} as const

export type CreditNoteLineItemRateDisplay =
  (typeof creditNoteLineItemRateDisplay)[keyof typeof creditNoteLineItemRateDisplay]

type CreditNoteLineItemExternalIdKey =
  | 'Amazon_Redshift'
  | 'Avalara'
  | 'Google_BigQuery'
  | 'Google_Sheets'
  | 'HubSpot'
  | 'NetSuite'
  | 'QuickBooks_Online'
  | 'Salesforce'
  | 'Slack'
  | 'Snowflake'
  | 'Stripe'
  | 'Xero'

export type CreditNoteLineItem = {
  id: string
  creditNoteId: string
  groupId?: string
  lineItemId?: string
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  index: number
  priceId?: string
  netTotal: string
  totalTax: string
  grossTotal: string
  rateDisplay: CreditNoteLineItemRateDisplay
  externalIds: Array<{
    key: CreditNoteLineItemExternalIdKey
    value: string
  }>
}
