import { enforceMinimumPrecision } from '@sequencehq/utils'
import { PricingEditorTier } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const sanitizeTiers = (
  newTiers: PricingEditorTier[]
): PricingEditorTier[] => {
  return newTiers.reduce((acc, tier, idx) => {
    const previousTier: PricingEditorTier | undefined = acc[acc.length - 1]

    const isLastTier = idx === newTiers.length - 1
    const firstUnit =
      idx === 0 ? 0 : parseInt(previousTier?.lastUnit || '0') + 1
    const currentLastUnit =
      idx === newTiers.length ? 0 : parseInt(tier.lastUnit || '0')

    return [
      ...acc,
      {
        id: tier.id,
        lastUnit: isLastTier
          ? ''
          : (currentLastUnit < firstUnit
              ? firstUnit
              : currentLastUnit
            ).toString(),
        firstUnit: firstUnit.toString(),
        unitPrice: enforceMinimumPrecision(2)(tier.unitPrice),
        flatFee: enforceMinimumPrecision(2)(tier.flatFee)
      }
    ]
  }, [] as PricingEditorTier[])
}
