import * as Sentry from '@sentry/react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useCallback, useEffect, useMemo, useState } from 'react'

type UseLoadDistinctCustomerAliasCount = (
  filters: Dashboardv20240509Api.GetUsageEvents.DistinctCustomerAliasCountFilters
) => string | undefined

export const useLoadDistinctCustomerAliasCount: UseLoadDistinctCustomerAliasCount =
  filters => {
    const [count, setCount] = useState<string | undefined>()

    const loadCount = useCallback(async () => {
      return (
        await dashboardv20240509Client.getDistinctCustomerAliasCountForUsageEvents(
          { filters }
        )
      ).data?.distinctAliasCount
    }, [JSON.stringify(filters)])

    useEffect(() => {
      loadCount()
        .then(loadedCount => {
          setCount(loadedCount)
        })
        .catch(error => Sentry.captureException(error))
    }, [loadCount])

    const data = useMemo(() => {
      if (!count) {
        return
      }

      return count
    }, [count])

    return data
  }
