import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import {
  UpdateCreditNoteStatusFormMutation,
  UpdateAndSendCreditNoteStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import FinalizeAndSendCreditNoteForm from 'components/UpdateInvoiceStatus/FinalizeAndSendCreditNoteForm'
import { usePostCreditNotesByIdFinalizeAndSendMutation } from 'features/api'

const FinalizeAndSendCreditNoteModal = createFormModal<
  UpdateCreditNoteStatusFormMutation,
  UpdateAndSendCreditNoteStatusFormProps
>({
  FormComponent: FinalizeAndSendCreditNoteForm,
  useMutation: usePostCreditNotesByIdFinalizeAndSendMutation,
  modalTitle: 'Finalize and Send Credit Note',
  successMessage: 'Credit Note sent',
  submitTitle: 'Finalize and Send',
  formId: 'finalizeAndSendCreditNoteForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Credit Note ${
        response.creditNoteNumber ? response.creditNoteNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default FinalizeAndSendCreditNoteModal
