import { Box, Button, Circle, Flex, Text } from '@chakra-ui/react'
import { TaxRateModel, percentageFromDecimal } from '@sequencehq/core-models'
import { KebabMenu, MenuItemBuilder } from '@sequencehq/core-components'
import {
  ColumnConfig,
  VirtualTableConfig,
  VirtualTable
} from '@sequencehq/tables'
import SettingsTab from 'components/SettingsPage/SettingsTab'
import { TaxRatesView } from 'components/TaxRateSettings/types'
import { TaxRateIcon } from 'components/icons/TaxRateIcon'
import { TagTypes, useLazyGetTaxRatesQuery } from 'features/api'
import { openOverlay } from 'features/overlay'
import { AppDispatch, useDispatch, useSelector } from 'features/store'
import { flow } from 'fp-ts/lib/function'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { INITIAL_RESULT_SIZE } from 'lib/constants'
import { FC, memo } from 'react'

const deleteBuilder: MenuItemBuilder<TaxRateModel> = (
  taxRate: TaxRateModel
) => {
  return {
    status: 'LIVE',
    label: 'Archive tax rate',
    action: ({ dispatch }) => {
      dispatch(
        openOverlay({
          content: 'archiveTaxRateModal',
          data: {
            id: taxRate.id,
            name: taxRate.name
          }
        })
      )
    }
  }
}

const menuItemBuilders: () => MenuItemBuilder<TaxRateModel>[] = () => [
  deleteBuilder
]

const tableConfig: (
  dispatch: AppDispatch,
  flags: {
    [key: string]: boolean
  }
) => VirtualTableConfig<TaxRatesView> = (dispatch, flags) => {
  const columns: ColumnConfig<TaxRateModel>[] = [
    {
      heading: 'Name',
      formatter: flow(({ name }) => (
        <Flex flexDirection="row" alignItems="center">
          <Circle backgroundColor="#F0F4F7" size="24px">
            <TaxRateIcon width="24px" height="24px" />
          </Circle>
          <Box width="8px"></Box>
          <Text>{name}</Text>
        </Flex>
      ))
    },
    {
      heading: 'Description',
      formatter: flow(({ description }) => <Text>{description}</Text>)
    },
    {
      heading: 'Amount',
      formatter: flow(({ amount }) => (
        <Text>{percentageFromDecimal(amount)}%</Text>
      ))
    },
    {
      heading: 'Region',
      formatter: flow(({ region, country }) => (
        <Text>
          {region ? `${region}, ` : ''}
          {country}
        </Text>
      ))
    }
  ]

  columns.push({
    width: '56px',
    sticky: true,
    contentTextAlign: 'center',
    formatter: flow(
      model => menuItemBuilders().map(builder => builder(model)),
      items => <KebabMenu menuItems={items} flags={flags} />
    )
  })

  return {
    columns
  }
}

const TaxRateSettings: FC = memo(() => {
  const dispatch = useDispatch()
  const flags = useFlags()

  const resetKey = useSelector(state => state.apiCaching.tags['TaxRates'])

  return (
    <SettingsTab
      title="Configure Tax Rates"
      summarySection={
        <Flex flexDirection="row">
          <Circle backgroundColor="#F0F4F7" size="37px">
            <TaxRateIcon />
          </Circle>
          <Box width="8px"></Box>
          <Flex flexDirection="column">
            <Box height="12px"></Box>
            <Text>Configure and manage your tax rates</Text>
          </Flex>
          <Flex grow="1"></Flex>
          <Button
            variant="primary"
            onClick={() =>
              dispatch(
                openOverlay({
                  content: 'createTaxRateModal'
                })
              )
            }
          >
            New Tax Rate
          </Button>
        </Flex>
      }
    >
      <VirtualTable<TaxRatesView, TagTypes>
        resetKey={resetKey}
        useLazyQuery={useLazyGetTaxRatesQuery}
        tableConfig={tableConfig(dispatch, flags)}
        noBorder={true}
        width="100%"
        initialResultSize={INITIAL_RESULT_SIZE}
      />
    </SettingsTab>
  )
})

export default TaxRateSettings
