import { Box, Flex, Image } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import dropboxSignImage from './dropboxSignCover.png'

export const DropboxSignIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Use the native Dropbox Sign integration to collect electronic signatures
        for quotes and contracts. Provide a frictionless signing experience for
        your customers and handle everything from draft to signature within
        Sequence, saving time and reducing errors. The Dropbox Sign integration
        is embedded into Sequence - a Dropbox account is not required.
      </Box>
      <Image src={dropboxSignImage} width="100%" draggable={false} />
    </Flex>
  )
}
