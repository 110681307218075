import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  AvailableStandardFrequency,
  ListPriceEditorFormData,
  ListPriceEditorMode,
  ListPriceEditorReducerState,
  PricingModel
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

export const NEW_PRICE_PREFIX = 'new-price::'
export const NEW_PRICE_PATTERN = /^new-price::/
export const STANDARD_AVAILABLE_FREQUENCIES: AvailableStandardFrequency[] = [
  'ONE_TIME',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY'
]

export const INITIAL_LIST_PRICE_STATE: ListPriceEditorReducerState = {
  data: {
    formData: {
      common: {
        name: '',
        pricingModel: 'STANDARD' as PricingModel,
        currency: 'GBP' as Currency,
        externalIds: {}
      },
      STANDARD: {
        price: '0.00',
        billingType: 'IN_ARREARS',
        billingFrequency: 'MONTHLY'
      } as ListPriceEditorFormData['STANDARD'],
      LINEAR: {
        price: '0.00',
        percentage: '0',
        usageMetricId: '',
        linearPriceType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        minPrice: '',
        maxPrice: '',
        parameters: {}
      } as ListPriceEditorFormData['LINEAR'],
      VOLUME: {
        usageMetricId: '',
        usageTierType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        includePercentageLimits: false,
        parameters: {},
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ],
        percentageTiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0.00',
            lastUnit: '1.00',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '1.01',
            lastUnit: '',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          }
        ]
      } as ListPriceEditorFormData['VOLUME'],
      GRADUATED: {
        usageMetricId: '',
        usageTierType: 'FIXED',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        parameters: {},
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ],
        percentageTiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0.00',
            lastUnit: '1.00',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '1.01',
            lastUnit: '',
            unitPercentage: '0.00',
            flatFee: '0.00',
            maxPrice: '',
            minPrice: ''
          }
        ],
        usageCalculationMode: 'BILLING_PERIOD'
      } as ListPriceEditorFormData['GRADUATED'],
      PACKAGED: {
        usageMetricId: '',
        pricePerPackage: '0.00',
        packageSize: '0',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        parameters: {}
      } as ListPriceEditorFormData['PACKAGED'],
      SEAT_BASED_LINEAR: {
        seatTypeId: '',
        pricePerSeat: '0.00',
        minimumSeats: '0',
        prorationStrategy: 'PRORATE_INCREMENTS',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        overagesBillingFrequency: 'MONTHLY'
      } as ListPriceEditorFormData['SEAT_BASED_LINEAR'],
      SEAT_BASED_GRADUATED: {
        seatTypeId: '',
        pricePerSeat: '0.00',
        minimumSeats: '0',
        prorationStrategy: 'PRORATE_INCREMENTS',
        billingFrequency: 'MONTHLY',
        billingType: 'IN_ARREARS',
        overagesBillingFrequency: 'MONTHLY',
        tiers: [
          {
            id: crypto.randomUUID(),
            firstUnit: '0',
            lastUnit: '1',
            unitPrice: '0.00',
            flatFee: '0.00'
          },
          {
            id: crypto.randomUUID(),
            firstUnit: '2',
            lastUnit: '',
            unitPrice: '0.00',
            flatFee: '0.00'
          }
        ]
      } as ListPriceEditorFormData['SEAT_BASED_GRADUATED']
    },
    ledgerIntegrations: {},
    metrics: {},
    product: {
      id: '',
      name: '',
      label: ''
    },
    prices: {}
  },
  initialData: {
    price: null,
    product: {
      id: '',
      name: '',
      label: ''
    }
  },
  derived: {
    queries: {
      availableFeatures: {
        review: true,
        save: false
      },
      selectedPrice: null,
      canSave: false,
      existingPrices: {},
      priceHasBeenUpdated: false
    }
  },
  editor: {
    selectedPrice: `${NEW_PRICE_PREFIX}${crypto.randomUUID()}`,
    showValidationErrors: false,
    formsValid: {},
    loaded: false
  },
  configuration: {
    availableCurrencies: ['GBP', 'USD', 'EUR'] as Currency[],
    currency: 'GBP',
    availableStandardFrequencies: [
      'MONTHLY',
      'QUARTERLY',
      'YEARLY'
    ] as AvailableStandardFrequency[],
    mode: ListPriceEditorMode.CREATE
  }
}
