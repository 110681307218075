import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import { QuickBooksConfig } from 'modules/Integrations/integrationsConfig/quickBooks/quickbooks.integration.config'

export const quickBooksConfigurationMutator = async (
  data: QuickBooksConfig
): Promise<{
  success: boolean
  error: IntegrationsPortErrors | null
}> => {
  const enabledRes = await dashboardv20240509Client.putIntegration({
    service: 'QuickBooks_Online',
    body: {
      enabled: data.importCustomerWorkflow
    }
  })

  if (enabledRes.error) {
    return {
      success: false,
      error: IntegrationsPortErrors.Other
    }
  }

  return {
    success: true,
    error: null
  }
}
