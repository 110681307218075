import { Currency } from '@sequencehq/core-models'
import {
  greaterThan,
  greaterThanEqualTo,
  required
} from '@sequencehq/validation'
import { defaultAvailableFrequenciesOptions } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/constants'

import { FormFields, ValidatorWithMetadata, useForm } from '@sequencehq/utils'
import {
  UsageParameterDataModel,
  useUsageMetricParameterFieldConfig
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/useUsageMetricParameterFieldConfig'
import {
  LinearPriceType,
  PricingEditorFormData
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

type LinearPriceFields = PricingEditorFormData['LINEAR']
type LinearPriceTypeObject = {
  label: string
  value: LinearPriceType
}

type UseLinearPriceForm = (props?: { formDisabled?: boolean }) => {
  fieldsConfig: Omit<
    FormFields<LinearPriceFields>,
    'usageMetricId' | 'billingFrequency'
  > & {
    billingFrequency: FormFields<LinearPriceFields>['billingFrequency'] & {
      hidden: boolean
    }
    usageMetricId: FormFields<LinearPriceFields>['usageMetricId'] & {
      hidden: boolean
      onAddNew: () => void
    }
  }
  formData: LinearPriceFields
  currency: Currency
  fieldsAreHidden: boolean
}

const availableLinearPriceTypes: LinearPriceTypeObject[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const validateOptionalNotNegative: ValidatorWithMetadata<
  string,
  Record<string, unknown>,
  Record<string, unknown>
> = value => {
  return value && greaterThanEqualTo(0)(value)
    ? [
        {
          message: 'Cannot be negative',
          metadata: {}
        }
      ]
    : []
}

export const minMaxPriceValidator: ValidatorWithMetadata<
  string,
  {
    minPrice: string
  },
  {
    minPrice: string
  }
> = (maxPrice, additionalData) => {
  return additionalData.minPrice &&
    maxPrice &&
    greaterThan(+additionalData.minPrice)(maxPrice)
    ? [
        {
          message: 'Max. must be greater than min.',
          metadata: {
            minPrice: additionalData.minPrice
          }
        }
      ]
    : []
}

export const useLinearPriceForm: UseLinearPriceForm = (
  { formDisabled } = { formDisabled: false }
) => {
  const navigate = useNavigate()
  const pricingEditorContext = usePricingEditorContext()

  /**
   * Define options for the select fields
   */
  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return pricingEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [pricingEditorContext.configuration.availableStandardFrequencies])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(pricingEditorContext.data.metrics).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [pricingEditorContext.data.metrics])

  /**
   * Initialise the form management
   */
  const checkIfLinearFieldDisabledByDomain = useCallback(
    ({
      property
    }: {
      property: keyof (LinearPriceFields & UsageParameterDataModel)
    }) => pricingEditorContext.functions.fieldIsDisabled(`LINEAR.${property}`),
    [pricingEditorContext]
  )

  const parameterFields = useUsageMetricParameterFieldConfig<LinearPriceFields>(
    pricingEditorContext.data.formData.LINEAR.usageMetricId,
    checkIfLinearFieldDisabledByDomain
  )

  const { fields, queries } = useForm<
    Omit<LinearPriceFields, 'parameters'> & UsageParameterDataModel
  >({
    value: pricingEditorContext.data.formData.LINEAR,
    disabled: formDisabled,
    fieldConfiguration: [
      {
        property: 'usageMetricId',
        disabled: checkIfLinearFieldDisabledByDomain,
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'billingFrequency',
        disabled: checkIfLinearFieldDisabledByDomain,
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'price',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'PERCENTAGE',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'minPrice',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'FIXED',
        validation: [validateOptionalNotNegative]
      },
      {
        property: 'maxPrice',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'FIXED',
        validation: [validateOptionalNotNegative, minMaxPriceValidator]
      },
      {
        property: 'percentage',
        disabled: ctx =>
          checkIfLinearFieldDisabledByDomain(ctx) ||
          ctx.formData.linearPriceType === 'FIXED',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'linearPriceType',
        disabled: checkIfLinearFieldDisabledByDomain,
        options: availableLinearPriceTypes,
        validation: [required]
      },
      ...parameterFields.fields
    ],
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          LINEAR: isValid
        }
      })
    },
    onChange: newData => {
      pricingEditorContext.functions.updateFormData({
        LINEAR: newData
      })
    }
  })

  /**
   * Enhance the base field config with some extra bits and pieces
   */
  const processedFieldsConfig = useMemo(() => {
    return {
      ...fields,
      billingFrequency: {
        ...fields.billingFrequency,
        hidden:
          pricingEditorContext.configuration.enableListPrices &&
          !pricingEditorContext.derived.queries.availableFeatures
            .showAllPriceFields
      },
      usageMetricId: {
        ...fields.usageMetricId,
        hidden:
          pricingEditorContext.configuration.enableListPrices &&
          !pricingEditorContext.derived.queries.availableFeatures
            .showAllPriceFields,
        onAddNew: () => navigate('./metrics/new')
      }
    }
  }, [
    fields,
    pricingEditorContext.configuration.enableListPrices,
    navigate,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  const fieldsAreHidden = useMemo(() => {
    return Boolean(
      pricingEditorContext.configuration.enableListPrices &&
        !pricingEditorContext.derived.queries.availableFeatures
          .showAllPriceFields
    )
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  return {
    fieldsConfig: processedFieldsConfig,
    formData: queries.formData,
    currency: pricingEditorContext.data.formData.common.currency,
    fieldsAreHidden
  }
}
