import { phasesAdapterIn } from 'modules/Cube/communication/external/billingSchedule.api.v1/adapters/billingSchedule.adapters.phases.in'
import { phasesAdapterOut } from 'modules/Cube/communication/external/billingSchedule.api.v1/adapters/billingSchedule.adapters.phases.out'
import { CubeReducerState } from 'modules/Cube/domain/cube.domain.types'
import { LoadedBillingScheduleData } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useLoadBillingScheduleEditor'
import { SaveBillingScheduleData } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useSaveBillingScheduleEditor'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'

const billingSchedulePhasesApiAdapter = {
  in: phasesAdapterIn,
  out: phasesAdapterOut
}

/**
 * The top level adapter will choose the appropriate underlying adapter
 * for the operation.
 *
 * For inbound:
 * - Phases are preferentially chosen if phases is active, and available (controlled
 *   by the configuration based in)
 *
 * For outbound:
 * - Phases are chosen if the domain reports that phases are available are the configuration
 * passed in
 */
export const billingScheduleAdapter = {
  in: (
    billingScheduleData: LoadedBillingScheduleData,
    configuration: CubeReducerState['configuration']
  ): Pick<CubeReducerState, 'data' | 'configuration'> => {
    return billingSchedulePhasesApiAdapter.in(
      billingScheduleData,
      configuration
    )
  },
  out:
    (existingData: CubeReducerState['data']) =>
    (domainOutput: CubeDomainInterface['queries']): SaveBillingScheduleData => {
      return billingSchedulePhasesApiAdapter.out(existingData)(domainOutput)
    }
}
