import { Box, Flex } from '@chakra-ui/react'
import { spacing, InvoiceLineItemsTotal } from '@sequencehq/invoice-content'
import {
  Currency,
  LineItemGroupModel,
  LineItemModel,
  InvoiceModel
} from '@sequencehq/core-models'
import InvoiceLineItemGroupSectionSelect from 'components/InvoiceLineItemSelect/InvoiceLineItemGroupSectionSelect'
import InvoiceLineItemTableSelect from 'components/InvoiceLineItemSelect/InvoiceLineItemTableSelect'
import { FC, Fragment } from 'react'

type InvoiceLineItemSelectProps = {
  lineItems: LineItemModel[]
  lineItemGroups: LineItemGroupModel[]
  invoice: InvoiceModel | undefined
  currency: Currency
  selectedLineItems: string[]
  setSelectedLineItems: (lineItemIds: string[]) => void
  disabledLineItems: string[]
}

const InvoiceLineItemSelect: FC<InvoiceLineItemSelectProps> = ({
  lineItems,
  lineItemGroups,
  currency,
  invoice,
  selectedLineItems,
  setSelectedLineItems,
  disabledLineItems
}) => {
  const unGroupedLineItems = lineItems.filter(item => !item.groupId)
  const selectLineItem = (id: string) => {
    setSelectedLineItems([...selectedLineItems, id])
  }

  const unSelectLineItem = (id: string) => {
    const finalLineItems = selectedLineItems.filter(
      selectedId => selectedId !== id
    )
    setSelectedLineItems(finalLineItems)
  }

  return (
    <>
      {unGroupedLineItems.length > 0 && (
        <Fragment>
          <InvoiceLineItemTableSelect
            currency={currency}
            lineItems={unGroupedLineItems}
            selectLineItem={selectLineItem}
            unSelectLineItem={unSelectLineItem}
            disabledLineItems={disabledLineItems}
          ></InvoiceLineItemTableSelect>
          <Box h={`${spacing}px`}></Box>
        </Fragment>
      )}

      {lineItemGroups.map(group => (
        <InvoiceLineItemGroupSectionSelect
          currency={currency}
          lineItemGroup={group}
          lineItems={lineItems.filter(item => item.groupId === group.id)}
          key={group.id}
          selectLineItem={selectLineItem}
          unSelectLineItem={unSelectLineItem}
          disabledLineItems={disabledLineItems}
        ></InvoiceLineItemGroupSectionSelect>
      ))}

      {invoice && (
        <Flex direction={'column'} ml={'15px'}>
          <InvoiceLineItemsTotal
            currency={invoice.currency}
            netTotal={invoice.netTotal}
            totalTax={invoice.totalTax}
            grossTotal={invoice.grossTotal}
          ></InvoiceLineItemsTotal>
        </Flex>
      )}
    </>
  )
}

export default InvoiceLineItemSelect
