import { createFormModal } from 'components/Form/createFormModal'
import SendTestCreditNoteEmailForm from 'components/SendTestEmail/SendTestCreditNoteEmailForm'
import {
  SendTestCreditNoteEmailFormMutation,
  SendTestEmailFormProps
} from 'components/SendTestEmail/types'
import { Toast } from '@sequencehq/core-components'
import { usePostCreditNotesByIdSendByTestEmailMutation } from 'features/api'

const SendTestCreditNoteEmailModal = createFormModal<
  SendTestCreditNoteEmailFormMutation,
  SendTestEmailFormProps
>({
  FormComponent: SendTestCreditNoteEmailForm,
  useMutation: usePostCreditNotesByIdSendByTestEmailMutation,
  modalTitle: 'Send Test Credit Note Email',
  successMessage: 'Email sent',
  submitTitle: 'Send Test',
  formId: 'sendTestCreditNoteEmailForm',
  successToast: () => (
    <Toast type="success" title={`Test email sent`} isClosable={true}></Toast>
  )
})

export default SendTestCreditNoteEmailModal
