import { Box, Flex, Spinner } from '@chakra-ui/react'
import { GreyGrey60, Lato14Regular, Spacing16 } from '@sequencehq/design-tokens'
import { required } from '@sequencehq/validation'
import { SelectField } from 'components/FormFields'
import { updateXeroExternalIds } from './useIntegrationServices'
import { FC } from 'react'
import { OnChange } from 'react-final-form-listeners'
import { useXeroIntegration } from 'lib/hooks/useXeroIntegration.ts'

export const IntegrationLedgerAccountsFields: FC = () => {
  const { xeroIntegration, loading: loadingXeroIntegration } =
    useXeroIntegration()

  if (loadingXeroIntegration) {
    return (
      <Flex alignItems="center">
        <Spinner />
        <Box {...Lato14Regular} color={GreyGrey60} marginLeft={Spacing16}>
          Fetching external ledger accounts...
        </Box>
      </Flex>
    )
  }

  return (
    <>
      {xeroIntegration && xeroIntegration.enabled && (
        <SelectField<string>
          fieldName="externalIds.Xero"
          fieldLabel="Xero ledger account"
          options={xeroIntegration.ledgerAccounts.map(account => ({
            value: account.code,
            label: `${account.name} (${account.code})`
          }))}
          validate={required}
          placeholder={'Choose account'}
          onExternalChange={({ value: externalId, onChange }) => (
            <OnChange name="billingType">
              {billingType => {
                const updatedExternalId = updateXeroExternalIds({
                  xeroIntegration,
                  externalId,
                  billingType
                })
                onChange(updatedExternalId)
              }}
            </OnChange>
          )}
        />
      )}
    </>
  )
}
