import { Flex } from '@chakra-ui/react'
import { GreyGrey0 } from '@sequencehq/design-tokens'
import { ProductGraph } from 'modules/Products/ProductGraphs/ProductGraph'
import { ProductGraphDivider } from 'modules/Products/ProductGraphs/ProductGraphDivider'

export const ProductsGraphs = () => (
  <Flex padding="8px 24px" width="100%" background={GreyGrey0}>
    <ProductGraph value="€29,993.00" label="Total revenue" percentBadge={10} />
    <ProductGraphDivider />
    <ProductGraph value="€19,993.00" label="Billed monthly" />
    <ProductGraphDivider />
    <ProductGraph value="€5,820.00" label="Billed annually" />
    <ProductGraphDivider />
    <ProductGraph
      value="€4,180.00"
      label="Billed in advance"
      percentBadge={-10}
    />
  </Flex>
)
