import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './equalsLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { EqualsIntegrationDetail } from './EqualsIntegrationDetail/EqualsIntegrationDetail'

export const equalsIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/equals',
  title: 'Equals',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: true
  },
  description:
    'Sync Sequence revenue and billing data with Equals and unlock advanced ARR dashboards',
  components: {
    detail: EqualsIntegrationDetail,
    management: NoConfiguration
  }
}
