import { Flex, Img } from '@chakra-ui/react'
import { Inspector } from '@sequencehq/core-components'
import { FC, ReactNode } from 'react'
import { salesforceIntegrationConfig } from '../salesforce.integration.config'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'
import { useIntegrationAuthError } from 'Integrations/utils/useIntegrationAuthError'
import { NotAuthenticatedItemContent } from 'Integrations/integrationsConfig/common/NotAuthenticatedItemContent'

export const SalesforceInspectorItem: FC<{
  children: ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('Salesforce')
  const integrationAuthError = useIntegrationAuthError('Salesforce')

  if (!integrationActive && !integrationAuthError) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="salesforce.inspector.item"
      content={
        integrationAuthError ? (
          <NotAuthenticatedItemContent
            service="Salesforce"
            data-testid="salesforce.inspector.content.unauth"
            serviceDescription={salesforceIntegrationConfig.description}
            serviceName={salesforceIntegrationConfig.title}
            logo={
              <Img
                src={salesforceIntegrationConfig.smallLogoUrl}
                height="24px"
                width="24px"
              />
            }
          />
        ) : (
          <Inspector.Content
            data-testid="salesforce.inspector.content"
            title="Salesforce"
            icon={<SmallIcon url={salesforceIntegrationConfig.smallLogoUrl} />}
          >
            <Flex flexDirection="column" gap="16px">
              {props.children}
            </Flex>
          </Inspector.Content>
        )
      }
      tooltip="Salesforce"
      icon={<SmallIcon url={salesforceIntegrationConfig.smallLogoUrl} />}
    />
  )
}
