import {
  FetchBaseQueryStatusError,
  RtkqResponseError,
  ValidationError,
  ValidationErrorItem,
  toRtkqResponse
} from '@sequencehq/core-models'
import { FORM_ERROR } from 'final-form'
import { DashboardApi20240730 } from '@sequencehq/api/dashboard/v20240730'

const isStatusError = (
  error: RtkqResponseError['error']
): error is FetchBaseQueryStatusError => {
  return 'status' in error && typeof error.status === 'number'
}

const isValidationError = (
  error: FetchBaseQueryStatusError['data']['error']
): error is ValidationError => error.type === 'validationError'

const formatValidationResponse = (
  errors: ValidationErrorItem[],
  output: Record<string, string[]>,
  fieldNames: string[],
  remapKeys: Record<string, string>
): Record<string, string[]> => {
  // Flatten nested structure
  // This may not work where properties in nested structures have same name
  return errors.reduce<Record<string, string[]>>(
    (acc, { key, message, ...rest }) => {
      const mappedKey = key && remapKeys[key] ? remapKeys[key] : key
      // Errors which do not have a key or key is not
      // one of form input names get assigned to form errors

      const formKey =
        mappedKey && fieldNames.includes(mappedKey) ? mappedKey : FORM_ERROR

      // Allow each error target to have mutliple messages
      acc[formKey] = acc[formKey] ? acc[formKey].concat(message) : [message]

      // If current error has sub-errors, recurse through them
      return rest.errors
        ? formatValidationResponse(rest.errors, acc, fieldNames, remapKeys)
        : acc
    },
    output
  )
}

export const handleFormResponse = (
  res: unknown,
  fieldNames: string[],
  remapKeys: Record<string, string> | undefined = {}
) => {
  const rtkqResponse = toRtkqResponse(res).value()

  if (!rtkqResponse || !('error' in rtkqResponse)) {
    return
  }

  if (
    isStatusError(rtkqResponse.error) &&
    isValidationError(rtkqResponse.error.data.error)
  ) {
    const errors = formatValidationResponse(
      rtkqResponse.error.data.error.errors,
      {},
      fieldNames,
      remapKeys
    )

    return errors
  }
}

export const handleFormResponseV2 = async (
  response: DashboardApi20240730.PostCustomer.Response,
  fieldNames: string[],
  remapKeys: Record<string, string> | undefined = {}
) => {
  type ErrorDetails = {
    type: string
    errors: ValidationErrorItem[]
  }

  type Error = {
    error: ErrorDetails
  }

  if (response.error) {
    const errorData = (await response.res.json()) as Error

    if (errorData?.error?.type === 'validationError') {
      const errors = formatValidationResponse(
        errorData?.error?.errors,
        {},
        fieldNames,
        remapKeys
      )

      return errors
    }
  }
}
