import { Link } from '@chakra-ui/react'
import { Currency } from '@sequencehq/core-models'
import { SelectField } from '@sequencehq/core-components'
import { useHiddenFieldsForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/CommonFields/useHiddenFieldsForm'
import { PricingModel } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const HiddenFieldsForm = () => {
  const { fieldsConfig } = useHiddenFieldsForm()

  return (
    <>
      <SelectField
        label="Pricing model"
        data-testid="common-field-pricingModel"
        value={fieldsConfig.pricingModel.value}
        onChange={newPricingModel =>
          fieldsConfig.pricingModel.onChange(newPricingModel as PricingModel)
        }
        options={fieldsConfig.pricingModel.options}
        validationErrors={fieldsConfig.pricingModel.validationErrors}
        isDisabled={fieldsConfig.pricingModel.disabled}
        placeholder="Select pricing model"
        description={
          fieldsConfig.pricingModel.description ? (
            <>
              {fieldsConfig.pricingModel.description}{' '}
              <Link
                href="https://docs.sequencehq.com/plans-products/pricing-models"
                isExternal
                color="indigo.50"
              >
                Learn more
              </Link>
            </>
          ) : null
        }
      />

      {!fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="common-field-currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}
    </>
  )
}
