import { Box, Center, Flex, Text } from '@chakra-ui/react'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import {
  KebabMenu,
  HorizontalSelectorField,
  SelectFieldWithActions,
  TextField,
  SelectField
} from '@sequencehq/core-components'
import { GreyGrey90, Lato14Bold } from '@sequencehq/design-tokens'
import { FixedTierTableInput } from '../inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from '../inputs/PercentageTierTableInput/PercentageTierTableInput'
import { PercentageTierWithLimitsTableInput } from '../inputs/PercentageTierWithLimitsTableInput/PercentageTierWithLimitsTableInput'
import {
  AvailableStandardFrequency,
  UsageTierType
} from 'common/drawers/PricingEditor/domain'
import { useVolumePriceForm } from './useVolumePriceForm'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { kebabMenuItems } from './volumePrice.utils'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { PricingInformation } from '../common/PricingInformation'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const VolumePriceStandaloneVariantPriceForm = () => {
  const { fieldsConfig, currency } = useVolumePriceForm()

  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>

      <PricingInformation
        value={fieldsConfig.pricingModel.value}
        isDisabled={fieldsConfig.pricingModel.disabled}
        options={fieldsConfig.pricingModel.options}
        validationErrors={fieldsConfig.pricingModel.validationErrors}
        onChange={fieldsConfig.pricingModel.onChange}
        description={fieldsConfig.pricingModel.description}
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="volume.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <SelectField
        label="Currency"
        data-testid="volume.currency"
        value={fieldsConfig.currency.value}
        onChange={newCurrency =>
          fieldsConfig.currency.onChange(newCurrency as Currency)
        }
        options={fieldsConfig.currency.options}
        validationErrors={fieldsConfig.currency.validationErrors}
        isDisabled={fieldsConfig.currency.disabled}
        placeholder="Select currency"
      />

      <Box paddingBottom="8px">
        <HorizontalSelectorField
          label="Frequency"
          data-testid="volume.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <SelectFieldWithActions
          label="Usage Metric"
          options={fieldsConfig.usageMetricId.options}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          placeholder="Select usage metric"
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
        <HorizontalSelectorField
          label="Type"
          value={fieldsConfig.usageTierType.value}
          options={fieldsConfig.usageTierType.options}
          onChange={newValue =>
            fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
          }
          validationErrors={fieldsConfig.usageTierType.validationErrors}
          isDisabled={fieldsConfig.usageTierType.disabled}
        />
        <Box
          paddingTop="8px"
          marginBottom="16px"
          paddingBottom="8px"
          width="100%"
        >
          <Flex flexDirection="row">
            <Box {...Lato14Bold} marginBottom="8px">
              Tiers
            </Box>
            <Flex grow="1"></Flex>
            {fieldsConfig.usageTierType.value === 'PERCENTAGE' && (
              <Center height="14px" paddingBottom="8px">
                <KebabMenu
                  kebabIcon={<EllipsisHorizontalIcon height="20px" />}
                  menuItems={kebabMenuItems({
                    includePercentageLimits:
                      fieldsConfig.includePercentageLimits.value,
                    onChange: fieldsConfig.includePercentageLimits.onChange
                  })}
                />
              </Center>
            )}
          </Flex>
          {fieldsConfig.usageTierType.value === 'FIXED' ? (
            <FixedTierTableInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          ) : fieldsConfig.includePercentageLimits.value ? (
            <PercentageTierWithLimitsTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          ) : (
            <PercentageTierTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          )}
        </Box>
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
