import {
  ApplyDiscountTo,
  DiscountEditorDiscount,
  DiscountEditorDomainInterface,
  DiscountEditorEditorState,
  DiscountEditorMode,
  DiscountEditorPrice
} from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { INITIAL_DISCOUNT } from 'modules/Cube/view/common/drawers/discountEditor/drawer/domainManagement/discounts.constants'
import { discountValidationConfig } from 'modules/Cube/view/common/drawers/discountEditor/drawer/domainManagement/discounts.validation'

import { dequal } from 'dequal'
import { Currency } from 'features/api/integratedApi.generated'
import { useCallback, useMemo, useState } from 'react'
import { arrayToIdKeyedObject, evaluateValidators } from '@sequencehq/utils'

type UseDiscountEditor = (props: {
  mode: DiscountEditorMode
  onSave: (newDiscount: DiscountEditorDiscount) => void
  onClose: () => void
  allPrices: DiscountEditorPrice[]
  availablePrices: string[]
  existingDiscount?: DiscountEditorDiscount
  defaultApplyTo?: ApplyDiscountTo
  currency: Currency
  applyToAllAvailable: boolean
}) => DiscountEditorDomainInterface

export const useDiscountEditor: UseDiscountEditor = props => {
  const initialState = useMemo(() => {
    return (
      props.existingDiscount ?? {
        ...INITIAL_DISCOUNT,
        id: `new-discount::${crypto.randomUUID()}`,
        applyTo: props.defaultApplyTo ?? INITIAL_DISCOUNT.applyTo,
        currency: props.currency
      }
    )
  }, [props.defaultApplyTo, props.existingDiscount, props.currency])

  const [discount, setDiscount] = useState<DiscountEditorDiscount>(initialState)
  const [editorState, setEditorState] = useState<DiscountEditorEditorState>({
    mode: props.mode,
    showErrors: false
  })

  const data = useMemo(() => {
    return { discount, prices: arrayToIdKeyedObject(props.allPrices) }
  }, [discount, props])

  const queries = useMemo(() => {
    const allValidationErrors = evaluateValidators(discountValidationConfig)(
      discount
    )

    return {
      allValidationErrors,
      validation: editorState.showErrors ? allValidationErrors : [],
      changesMade: !dequal(initialState, discount),
      canSave: !dequal(initialState, discount)
    }
  }, [discount, editorState, initialState])

  const updateDiscount = useCallback(
    (newDiscountData: Partial<DiscountEditorDiscount>) => {
      setDiscount(prevDiscount => ({ ...prevDiscount, ...newDiscountData }))
    },
    []
  )

  const saveDiscount = useCallback(() => {
    if (queries.allValidationErrors.length) {
      setEditorState(prevState => ({ ...prevState, showErrors: true }))
      return
    }

    props.onSave(discount)
  }, [props, discount, queries])

  const closeEditor = useCallback(() => {
    props.onClose()
  }, [props])

  if (localStorage.getItem('enable-discount-drawer-logs')) {
    //eslint-disable-next-line no-console
    console.info(
      `%c[Discount Editor] useDiscountEditor output`,
      'color: green;',
      {
        data,
        queries,
        editor: editorState
      },
      {
        originalDiscount: props.existingDiscount
      }
    )
  }

  return {
    data,
    queries,
    configuration: {
      availablePrices: props.availablePrices,
      applyToAllAvailable: props.applyToAllAvailable
    },
    editor: editorState,
    functions: {
      updateDiscount,
      saveDiscount,
      closeEditor
    }
  }
}
