import { useMemo, useState } from 'react'

import { useContactModalForm } from 'modules/CustomerContacts'
import {
  ContactBody,
  CustomerContactFields,
  CustomerContactFieldsConfig
} from 'modules/CustomerContacts/view/forms/ContactModalForm/useContactModalForm'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'

type UseModalCreateRecipient = ({ onClose }: { onClose: () => void }) => {
  fieldsConfig: CustomerContactFieldsConfig
  buttonSubmit: {
    onSubmit: () => void
    disabled: boolean
  }
  buttonCancel: {
    onCancel: () => void
    disabled: boolean
  }
  modalTitle: string
}

export const useModalCreateRecipient: UseModalCreateRecipient = ({
  onClose
}) => {
  const ctx = useCubeContext()

  const [formData, setFormData] = useState<CustomerContactFields>({
    name: '',
    email: '',
    billingPreference: 'STANDARD'
  })

  const { fieldsConfig, buttonSubmit, isUpdating } = useContactModalForm({
    contactId: 'new',
    fieldsValues: formData,
    updateFields: (newFields: CustomerContactFields) => {
      return setFormData(newFields)
    },
    createContact: async (body: ContactBody) => {
      const newContact =
        await ctx.mutators.external.out.contacts?.createContact({
          customerId: ctx.queries.rawData.data.common.customerId,
          body
        })

      if (newContact) {
        ctx.mutators.updateData({
          quote: {
            contacts: [
              ...ctx.queries.rawData.data.quote.contacts,
              {
                contactId: newContact.id,
                name: newContact.name,
                email: newContact.email,
                signedAt: undefined
              }
            ]
          }
        })
      }

      onClose()
    },
    updateContact: async () => {}
  })

  const buttonCancel = useMemo(
    () => ({
      onCancel: onClose,
      disabled: isUpdating
    }),
    [isUpdating, onClose]
  )

  const modalTitle = useMemo(() => {
    const customerId = ctx.queries.rawData.data.common.customerId

    if (!customerId) {
      return ''
    }

    const customer = ctx.queries.rawData.data.customers[customerId]

    if (!customer) {
      return ''
    }

    return `Create recipient for ${customer.legalName}`
  }, [
    ctx.queries.rawData.data.common.customerId,
    ctx.queries.rawData.data.customers
  ])

  return {
    fieldsConfig,
    buttonSubmit,
    buttonCancel,
    modalTitle
  }
}
