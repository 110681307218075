import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ListPriceEditorMode,
  ListPriceEditorProps,
  Price
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import {
  apiPriceToInternalPrice,
  internalPriceToApiPrice
} from 'modules/Products/drawers/ListPriceEditor/adapters/listPriceEditor.adapters'
import { NEW_PRICE_PATTERN } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const useListPriceEditorConnector = (): ListPriceEditorProps => {
  const navigate = useNavigate()
  const params = useParams<{
    productId: string
    listPriceId: string
  }>()
  const flags = useFlags()
  const productContext = useProductContext()

  const productId = useMemo(() => {
    if (!params.productId) {
      return ''
    }

    return params.productId
  }, [params.productId])

  const existingPrice = useMemo(() => {
    const existingListPrice = productContext.data.listPrices.find(
      listPrice => listPrice.id === params.listPriceId
    )

    if (!existingListPrice) {
      return
    }

    return apiPriceToInternalPrice(productContext.data.product)(
      existingListPrice
    )
  }, [
    params.listPriceId,
    productContext.data.listPrices,
    productContext.data.product
  ])

  const mode = useMemo(() => {
    if (!params.listPriceId || params.listPriceId.match(NEW_PRICE_PATTERN)) {
      return ListPriceEditorMode.CREATE
    }

    return ListPriceEditorMode.EDIT
  }, [params.listPriceId])

  const onClose = useCallback(() => {
    navigate('..')
  }, [navigate])

  const onSave = useCallback(
    async (price: Price) => {
      const apiPrice = internalPriceToApiPrice(productContext.data.product.id)(
        price
      )
      if (price.id.match(NEW_PRICE_PATTERN)) {
        await productContext.functions.createListPrice(apiPrice)
      } else {
        await productContext.functions.updateListPrice(price.id)(apiPrice)
      }
      navigate('..')
    },
    [navigate, productContext.functions, productContext.data.product.id]
  )

  const canUseListPrices = useMemo(
    () => Boolean(flags.enableListPrices),
    [flags]
  )

  return {
    canUseListPrices,
    productId,
    mode,
    existingData: {
      product: productContext.data.product,
      price: existingPrice
    },
    onClose,
    onSave
  }
}
