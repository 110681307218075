import { FC } from 'react'
import { Flex, Text, Image, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { ChevronRightIcon } from '@chakra-ui/icons'

interface CtaCardProps {
  title: string
  description?: string
  ctaLink?: string
  image: string
  imageAlt: string
  disabled?: boolean
}

const CtaCard: FC<CtaCardProps> = ({
  title,
  description,
  ctaLink,
  image,
  imageAlt,
  disabled
}) => {
  const isExternalLink =
    !ctaLink?.startsWith('/') && ctaLink?.startsWith('http')

  return (
    <>
      <Link
        as={RouterLink}
        to={ctaLink && !disabled ? ctaLink : '#'}
        isExternal={isExternalLink}
        mb="24px"
        _hover={{
          textDecoration: 'none'
        }}
        {...(disabled
          ? { pointerEvents: 'none', cursor: 'not-allowed', opacity: 0.4 }
          : {})}
      >
        <Flex
          flexDirection="column"
          maxWidth="376px"
          width="100%"
          minWidth="350px"
          mr="24px"
          pb="12px"
          borderRadius="6px"
          borderStyle="solid"
          borderWidth="1px"
          borderColor="gray.30"
          boxShadow="0px 2px 4px 0px rgba(20, 23, 28, 0.08)"
          cursor={!ctaLink ? 'not-allowed' : 'pointer'}
        >
          <Image src={image} alt={imageAlt} p="12px" />
          <Flex alignItems="center" px="12px">
            <Flex flexDirection="column" p="8px">
              <Text textStyle="bold" pb="4px">
                {title}
              </Text>
              <Text textStyle="ctaCardDescription">{description}</Text>
            </Flex>
            <Flex alignItems="center" pr="8px">
              <ChevronRightIcon />
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </>
  )
}

export default CtaCard
