import { Button } from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey60,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { ReactNode } from 'react'
import { AvailableServices } from 'Integrations/domain'
import Spinner from 'components/Loading/Spinner'
import { useReconnectToIntegration } from 'Integrations/utils/useReconnectToIntegration'

export const NotAuthenticatedItemContent = (props: {
  service: AvailableServices
  serviceName: string
  serviceDescription: string
  'data-testid'?: string
  logo: ReactNode
}) => {
  const { reconnect, reconnecting } = useReconnectToIntegration()

  return (
    <Flex
      width="320px"
      height="100%"
      overflow="auto"
      padding="16px"
      gap="16px"
      data-testid={props['data-testid']}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        padding="6px"
        borderRadius="6px"
        backgroundColor={GreyGrey20}
        width="fit-content"
      >
        {props.logo}
      </Box>
      <Flex flexDirection="column" gap="4px" alignItems="center">
        <Box {...Lato13Bold} color={GreyGrey80}>
          {props.serviceName} has an authentication failure
        </Box>
        <Box {...Lato13Regular} color={GreyGrey70} textAlign="center">
          {props.serviceDescription}
        </Box>
      </Flex>
      <Button
        variant={'secondary'}
        onClick={() => void reconnect(props.service)}
        disabled={reconnecting}
      >
        <Flex gap="8px" alignItems="center">
          {reconnecting && (
            <Spinner height="16px" width="16px" color={GreyGrey60} />
          )}{' '}
          {reconnecting
            ? `Reconnecting to ${props.serviceName}...`
            : `Reconnect to ${props.serviceName}`}
        </Flex>
      </Button>
    </Flex>
  )
}
