import { apiPriceToInternalPrice } from 'modules/Cube/view/common/drawers/priceEditor/drawer/adapters/pricingEditor.adapters'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback, useEffect, useState } from 'react'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { NEW_PRICE_PATTERN } from 'common/drawers/PricingEditor/domain/pricingEditor.constants.ts'

type UseLoadPriceExternalIds = () => {
  loading: boolean
}
enum Status {
  Unintialized = 'uninitialized',
  Loading = 'loading',
  Success = 'success',
  Error = 'error'
}
/**
 * This hook is used as a bandaid to protect against a bug in the /prices
 * list endpoint which means it does not include external ids when loading - this
 * should be removed when the list endpoint returns all of the data associated
 * with the entity.
 * @param props
 * @returns
 */
export const useLoadExistingPriceExternalIds: UseLoadPriceExternalIds = () => {
  const pricingEditorContext = usePricingEditorContext()
  const [status, setStatus] = useState<Status>(Status.Unintialized)
  const priceId = pricingEditorContext.editor.selectedPrice
  const product = pricingEditorContext.data.product

  const loadPrice = useCallback(
    async (id: string) => {
      if (id.match(NEW_PRICE_PATTERN)) {
        return
      }

      const res = await dashboardv20240509Client.getPrice({ id })

      if (!res.data) {
        return
      }
      return apiPriceToInternalPrice(product)(res.data)
    },
    [product]
  )

  useEffect(() => {
    setStatus(Status.Unintialized)
  }, [priceId])

  useEffect(() => {
    if (
      !priceId ||
      status !== Status.Unintialized ||
      priceId !== pricingEditorContext.editor.selectedPrice
    ) {
      return
    }

    setStatus(Status.Loading)
    void loadPrice(priceId)
      .then(price => {
        if (!price) {
          return
        }

        pricingEditorContext.functions.updateData({
          prices: {
            [priceId]: {
              common: {
                externalIds: price.common.externalIds
              }
            }
          }
        })
      })
      .finally(() => {
        setStatus(Status.Success)
      })
  }, [priceId, loadPrice, pricingEditorContext, status])

  return {
    loading: status === Status.Loading
  }
}
