import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import {
  UpdateCreditNoteStatusFormMutation,
  UpdateCreditNoteStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import FinalizeCreditNoteForm from 'components/UpdateInvoiceStatus/FinalizeCreditNoteForm'
import { usePostCreditNotesByIdFinalizeMutation } from 'features/api'

const FinalizeCreditNoteModal = createFormModal<
  UpdateCreditNoteStatusFormMutation,
  UpdateCreditNoteStatusFormProps
>({
  FormComponent: FinalizeCreditNoteForm,
  useMutation: usePostCreditNotesByIdFinalizeMutation,
  modalTitle: 'Finalize Credit Note',
  successMessage: 'Credit Note finalized',
  submitTitle: 'Finalize Credit Note',
  formId: 'finalizeCreditNoteForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Credit Note ${
        response.creditNoteNumber ? response.creditNoteNumber : ''
      } finalized`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default FinalizeCreditNoteModal
