import {
  NEW_PRICE_PATTERN,
  NEW_PRODUCT_PATTERN
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import {
  PostActionStage,
  PricingEditorMode,
  PricingEditorPrice,
  PricingEditorReducerState
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { internalPriceToApiPrice } from 'modules/Cube/view/common/drawers/priceEditor/drawer/adapters/pricingEditor.adapters'
import { omit, omitBy } from 'lodash/fp'
import { dequal } from 'dequal'

export const getSelectedPrice = (
  prevState: PricingEditorReducerState
): PricingEditorPrice | null => {
  const selectedPrice = prevState.editor?.selectedPrice
    ? prevState.data.prices[prevState.editor?.selectedPrice]
    : null

  return selectedPrice ?? null
}

/**
 * We only want to allow the addition of a new price if there isn't one already
 * existing. This logic may change in the future if this drawer is used in the context
 * of the product catalogue.
 * @param prices
 * @returns
 */
export const getCanAddPrice = (
  prices: PricingEditorReducerState['data']['prices']
): boolean => {
  return !Object.keys(prices).some(priceId => {
    return priceId.match(NEW_PRICE_PATTERN)
  })
}

/**
 * When using common utils (and when saving) we may want access to the API format
 * version of prices (i.e. PriceModel), so provide a pre-formatted version of prices
 * for these cases.
 * @param product
 * @returns
 */
export const getApiFormatPrices =
  (product: PricingEditorReducerState['data']['product']) =>
  (prices: PricingEditorReducerState['data']['prices']) => {
    if (!product) {
      return []
    }

    return Object.values(prices).map(internalPriceToApiPrice(product.id))
  }

export const getExistingPrices = (
  prices: PricingEditorReducerState['data']['prices']
): PricingEditorReducerState['data']['prices'] =>
  omitBy(
    (price: PricingEditorPrice) => price.id.match(NEW_PRICE_PATTERN),
    prices
  )

export const getNewPrices = (
  prices: PricingEditorReducerState['data']['prices']
): PricingEditorReducerState['data']['prices'] =>
  omitBy(
    (price: PricingEditorPrice) => !price.id.match(NEW_PRICE_PATTERN),
    prices
  )

/**
 * This query returns a deduped list of prices, using the id of the first instance
 * of a duplicate as the canonical id for that given price structure.
 * @param prices
 * @returns
 */
export const getUniquePrices = (
  prices: Record<PricingEditorPrice['id'], PricingEditorPrice>
): Record<PricingEditorPrice['id'], PricingEditorPrice> => {
  return Object.values(prices).reduce(
    (
      acc: Record<PricingEditorPrice['id'], PricingEditorPrice>,
      { id, ...priceData }
    ) => {
      const match = Object.values(acc).find(({ id: _, ...existingPriceData }) =>
        dequal(
          omit(['common.externalIds'])(existingPriceData),
          omit(['common.externalIds'])(priceData)
        )
      )

      if (match) {
        return acc
      }

      return {
        ...acc,
        [id]: {
          id,
          ...priceData
        }
      }
    },
    {}
  )
}

export const getAvailableFeatures = (
  prevState: PricingEditorReducerState
): PricingEditorReducerState['derived']['queries']['availableFeatures'] => {
  return {
    changeListPrice: Object.values(prevState.data.listPrices).length > 1,
    save: prevState.configuration.mode !== PricingEditorMode.VIEW,
    showAllPriceFields: !prevState.configuration.listPriceId,
    showPriceList: prevState.configuration.mode === PricingEditorMode.CREATE,
    showProductFields: Boolean(
      prevState.configuration.mode === PricingEditorMode.ADD_PRODUCT ||
        prevState.data.product?.id.match(NEW_PRODUCT_PATTERN)
    )
  }
}

export const getPriceHasBeenUpdated = (
  prevState: PricingEditorReducerState
): boolean => {
  return (
    !!prevState.configuration.listPriceId ||
    prevState.initialData.price?.id !== prevState.editor.selectedPrice ||
    !dequal(
      prevState.initialData.price,
      prevState.data.prices[prevState.editor.selectedPrice]
    )
  )
}

export const getProductHasBeenUpdated = (
  prevState: PricingEditorReducerState
): boolean => {
  return !dequal(prevState.initialData.product, prevState.data.product)
}

/**
 * Check the forms states to see if we can save the price - detail on validation
 * for each from can be found in the relevant hooks. We only store the high level
 * validation status in the domain.
 * @param prevState
 * @returns
 */
export const getCanSave = (prevState: PricingEditorReducerState): boolean => {
  const requiredForms: Array<
    keyof PricingEditorReducerState['editor']['formsValid']
  > = [
    'common',
    prevState.data.formData.common.pricingModel,
    ...(prevState.configuration.mode === PricingEditorMode.ADD_PRODUCT
      ? ['product' as const]
      : []),
    'externalLedger'
  ]

  return requiredForms.every(form => prevState.editor.formsValid[form])
}

export const getCanChangeListPrice = (
  prevState: PricingEditorReducerState
): boolean => {
  return Object.values(prevState.data.listPrices).length > 1
}

/**
 * A stage to generate a set of queries over the current data for
 * use elsewhere. This prevents us replicating logic and lookups
 * in multiple places.
 * @returns
 */
export const pricingEditorQueries: PostActionStage = () => prevState => {
  const existingPrices = getExistingPrices(prevState.data.prices)

  const newQueryState: PricingEditorReducerState['derived']['queries'] = {
    selectedPrice: getSelectedPrice(prevState),
    canAddPrice: getCanAddPrice(prevState.data.prices),
    apiFormatPrices: getApiFormatPrices(prevState.data.product)(
      prevState.data.prices
    ),
    existingPrices,
    newPrices: getNewPrices(prevState.data.prices),
    uniquePrices: getUniquePrices(existingPrices),
    availableFeatures: getAvailableFeatures(prevState),
    priceHasBeenUpdated: getPriceHasBeenUpdated(prevState),
    productHasBeenUpdated: getProductHasBeenUpdated(prevState),
    canSave: getCanSave(prevState)
  }

  return {
    ...prevState,
    derived: {
      ...prevState.derived,
      queries: newQueryState
    }
  }
}
