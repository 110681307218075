import { Button, Flex, Text, Box } from '@chakra-ui/react'
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'features/store'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import { OverlayPayload, openOverlay } from 'features/overlay'

export type EmptyStateContent = {
  title: string
  description: string
  docLink: string
  linkCopy: string
  route?: string
  overlayPayload?: OverlayPayload
  action?: () => void
  buttonCopy?: string
}

interface EmptyStateProps {
  width?: string
  height?: string
  position?: 'sticky'
  left?: string
  top?: string
  emptyContent: EmptyStateContent
}

const EmptyState: FC<EmptyStateProps> = ({
  height = 'vh',
  width = '100%',
  position,
  left,
  top,
  emptyContent
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  if (!emptyContent) {
    return null
  }

  const {
    title,
    description,
    docLink,
    linkCopy,
    route,
    overlayPayload,
    action,
    buttonCopy
  } = emptyContent

  return (
    <Flex
      width={width}
      height={height}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p="64px"
      position={position}
      left={left}
      top={top}
    >
      <Flex
        maxWidth="450px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text textStyle="emptyStateTitle">{title}</Text>
        <Box h="8px"></Box>

        <Text textStyle="emptyStateDescription" textAlign="center">
          {description}{' '}
          <Link to={docLink} target="_blank">
            <Text
              as="span"
              color={IndigoIndigo50}
              textStyle="emptyStateDescription"
            >
              {linkCopy}
            </Text>
          </Link>
        </Text>
        <Box h="24px"></Box>
        {buttonCopy && (
          <Button
            variant="primary"
            mt="16px"
            onClick={() => {
              if (route) {
                navigate(route)
              }

              if (overlayPayload) {
                dispatch(openOverlay(overlayPayload))
              }

              if (action) {
                action()
              }
            }}
          >
            {buttonCopy}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default EmptyState
