import { FC } from 'react'

interface CreditNoteIconProps {
  width?: number
  height?: number
}

export const CreditNoteIcon: FC<CreditNoteIconProps> = ({
  width = 20,
  height = 20
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#ebfbc9" />
    <path
      d="M11.1654 4.16699H6.4987C6.18928 4.16699 5.89253 4.28991 5.67374 4.5087C5.45495 4.72749 5.33203 5.02424 5.33203 5.33366V14.667C5.33203 14.9764 5.45495 15.2732 5.67374 15.492C5.89253 15.7107 6.18928 15.8337 6.4987 15.8337H13.4987C13.8081 15.8337 14.1049 15.7107 14.3237 15.492C14.5424 15.2732 14.6654 14.9764 14.6654 14.667V7.66699L11.1654 4.16699Z"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.168 4.16699V7.66699H14.668"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3346 10.583H7.66797"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3346 12.917H7.66797"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83464 8.25H8.2513H7.66797"
      stroke="#485972"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
