import { Box, Flex, Image } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import hubSpotCover from './hubspotCover.png'

export const HubspotIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={hubSpotCover}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Integrate HubSpot with Sequence to import closed-won customers and sync
        deal records. Connecting your HubSpot account is straightforward –
        authorize the integration and Sequence will automatically import
        closed-won deals, preventing duplicate data entry. Ensure primary
        billing addresses are complete for accurate invoicing. Automatically
        link new closed-won deals for streamlined billing.
      </Box>
    </Flex>
  )
}
