import { Box, Flex } from '@chakra-ui/react'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { MutationFormProps } from 'components/Form/types'
import {
  SendInvoicePaymentReminderFormMutation,
  SendInvoicePaymentReminderFormProps
} from 'components/SendInvoicePaymentReminder/types'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const SendInvoicePaymentReminderForm: FC<
  MutationFormProps<SendInvoicePaymentReminderFormMutation> &
    SendInvoicePaymentReminderFormProps
> = ({ invoiceId, customer, invoiceNumber, submitForm, formId }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async (values, form) => {
        const res = await submitForm({
          id: invoiceId
        })

        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit }) => {
        const dayzedDate = new Date()
        dayzedDate.setDate(dayzedDate.getDate() - 1)
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            <Box>
              Send a reminder email to {customer} for invoice {invoiceNumber}?
            </Box>
            {isSandboxEnv() && (
              <Box pt={5} color={GreyGrey60}>
                Note: No end-customer emails will be sent in {getEnvironment()}.
              </Box>
            )}
          </Flex>
        )
      }}
    />
  )
}

export default SendInvoicePaymentReminderForm
