import Spinner from 'components/Loading/Spinner'
import { FC, memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const UserSettings: FC = memo(() => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/settings/team')
  }, [navigate])

  return <Spinner></Spinner>
})

export default UserSettings
