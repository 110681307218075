import FinalizeAndSendInvoiceForm from 'components/UpdateInvoiceStatus/FinalizeAndSendInvoiceForm'
import {
  UpdateInvoiceStatusFormMutation,
  UpdateAndSendInvoiceStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import { createFormModal } from 'components/Form/createFormModal'
import { usePostInvoicesByIdFinalizeAndSendMutation } from 'features/api'
import { Toast } from '@sequencehq/core-components'

const FinalizeAndSendInvoiceModal = createFormModal<
  UpdateInvoiceStatusFormMutation,
  UpdateAndSendInvoiceStatusFormProps
>({
  FormComponent: FinalizeAndSendInvoiceForm,
  useMutation: usePostInvoicesByIdFinalizeAndSendMutation,
  modalTitle: 'Finalize and Send Invoice',
  successMessage: 'Invoice sent',
  submitTitle: 'Finalize and Send',
  formId: 'finalizeAndSendInvoiceForm',
  successToast: response => (
    <Toast
      type="success"
      title={`Invoice ${
        response.invoiceNumber ? response.invoiceNumber : ''
      } sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default FinalizeAndSendInvoiceModal
