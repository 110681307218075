import { useCallback } from 'react'
import invariant from 'tiny-invariant'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'

export type v1ApiProduct = Dashboardv99990101Api.GetProduct.Product

type UseLoadProduct = () => (productId: string) => Promise<v1ApiProduct>

export const useLoadProduct: UseLoadProduct = () => {
  const load = useCallback(async (productId: string) => {
    const productResponse = await dashboardv99990101Client.getProduct({
      id: productId
    })
    const product = productResponse.data

    invariant(product, 'Product not found')

    return product
  }, [])

  return load
}
