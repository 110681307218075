import { Box, Divider, Flex } from '@chakra-ui/react'
import { borderColor } from '@sequencehq/core-theme'
import { FC, ReactNode, memo } from 'react'

type SettingsTabProps = {
  title: string
  summarySection: ReactNode
  children: ReactNode
}

const SettingsTab: FC<SettingsTabProps> = memo(
  ({ summarySection, children }) => {
    return (
      <Flex flexDirection="column" overflow="scroll">
        <Flex
          backgroundColor="white"
          pt="4"
          borderTop="0px"
          py="24px"
          flexDirection="column"
        >
          <Box px="24px">{summarySection}</Box>
          <Box height="24px"></Box>
          <Divider color={borderColor}></Divider>
          <Box width="100%">{children}</Box>
        </Flex>
      </Flex>
    )
  }
)

export default SettingsTab
