import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { useMutation } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export const useRemoveAvalaraMapping = () => {
  const notifications = useNotifications()

  const deleteMappingMutator = useMutation(
    dashboardv99990101Client.deleteTaxCategoryAvalaraMapping
  )

  return async (taxCategoryId: string) => {
    await deleteMappingMutator.mutateAsync(
      {
        id: taxCategoryId
      },
      {
        onSuccess: () => {
          notifications.displayNotification('Tax code removed from category', {
            type: 'success'
          })
        },
        onError: () => {
          notifications.displayNotification(
            'Failed to remove tax code from category',
            {
              type: 'error'
            }
          )
        }
      }
    )
  }
}
