import { PricingEditorRoot } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PricingEditorRoot/PricingEditorRoot'
import { usePricingEditorConnector } from 'modules/Cube/view/common/drawers/priceEditor/usePricingEditor.connector'
import { useNavigate } from 'react-router-dom'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * If a route component needs access to data from the billing schedule context, it
 * should use a connector hook.
 * @returns
 */
export const PricingEditorCubeRoute = () => {
  const context = usePricingEditorConnector()
  const navigate = useNavigate()

  // If you're trying to create a price based on a list price AND you don't have access to list prices,
  // return from whence you came.
  if (context.listPriceId && !context.canUseListPrices) {
    navigate('..')
    return
  }

  return <PricingEditorRoot {...context} />
}
