import {
  ConnectionMethod,
  IntegrationConfig
} from 'modules/Integrations/domain/integrations.domain.types'
import stripeLogo from './stripeLogo.svg'
import { StripeIntegrationDetail } from './StripeIntegrationDetail/StripeIntegrationDetail'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'

export const stripeIntegrationConfig: IntegrationConfig = {
  logoUrl: stripeLogo,
  smallLogoUrl: stripeLogo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/stripe',
  title: 'Stripe',
  features: {
    canDeleteLinks: true,
    canUninstall: false,
    isDocumentationOnly: false
  },
  description:
    'Automatically collect payments from customers and reconcile Sequence invoices.',
  components: {
    detail: StripeIntegrationDetail,
    management: NoConfiguration
  },
  communication: {
    connecting: {
      method: ConnectionMethod.OAuth
    }
  }
}
