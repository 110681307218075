import { Link, Spacer, Text } from '@chakra-ui/react'
import { Lato16Bold } from '@sequencehq/design-tokens'
import { useStytchB2BClient } from '@stytch/react/dist/b2b'
import { StytchAPIError } from '@stytch/vanilla-js/dist/b2b'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Section } from 'modules/LoginApp/components/common/Section'
import { WorkspaceList } from 'modules/LoginApp/components/common/WorkspaceList'
import { Wordmark } from 'components/icons/Wordmark'
import { baseApi } from 'features/api/baseApi'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Spinner from 'components/Loading/Spinner'
import { redirectToDashboard } from 'lib/auth/session'
import LinkExpiredPage from 'modules/LoginApp/components/LinkExpiredPage'
import PkceMismatchPage from 'modules/LoginApp/components/PkceMismatchPage'
import PkceExpectedCodeVerifierPage from 'modules/LoginApp/components/PkceExpectedCodeVerifierPage'
import ErrorPage from 'modules/LoginApp/components/ErrorPage'

interface Workspace {
  id: string
  name: string
  action?: React.ReactNode
}

export const SelectWorkspacePage = () => {
  const navigate = useNavigate()
  const stytch = useStytchB2BClient()
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [intermediateSessionToken, setIntermediateSessionToken] = useState<
    string | null
  >(null)
  const [isLoading, setIsLoading] = useState(true)
  const [stytchFlowError, setStytchFlowError] = useState<
    StytchAPIError | Error | null
  >(null)
  const [
    exchangeStytchIst,
    {
      data: dataExchangeStytchIst,
      isError: isErrorExchangeStytchIst,
      isLoading: isLoadingExchangeStytchIst
    }
  ] = baseApi.endpoints.postExchangeStytchIst.useMutation()

  const [
    magicLinksFlowAuth,
    {
      data: dataMagicLinksFlowAuth,
      isError: isErrorMagicLinksFlowAuth,
      isLoading: isLoadingMagicLinksFlowAuth
    }
  ] = baseApi.endpoints.postMagicLinksFlowAuth.useMutation()

  const handleSelectWorkspace = useCallback(
    async (organisationId: string, token: string) => {
      setIsLoading(true)
      await exchangeStytchIst({
        body: {
          discoveryFlow: {
            organisationId,
            intermediateSessionToken: token
          }
        }
      })

      setIsLoading(false)
    },
    [exchangeStytchIst]
  )

  const handleDiscoveryFlow = useCallback(async () => {
    const token = new URLSearchParams(window.location.search).get('token')
    if (!token) {
      throw new Error('Missing token type or discovery token in query params')
    }

    try {
      const { intermediate_session_token, discovered_organizations } =
        await stytch.magicLinks.discovery.authenticate({
          discovery_magic_links_token: token
        })

      if (discovered_organizations.length === 0) {
        navigate('/no-workspace')
        return
      }
      const formattedWorkspaces = discovered_organizations
        .map(org => {
          return {
            id: org.organization.organization_id,
            name: org.organization.organization_name
          }
        })
        .sort((orgA, orgB) => {
          if (orgA.name > orgB.name) {
            return 1
          }
          if (orgA.name < orgB.name) {
            return -1
          }
          return 0
        })

      // Automatically select the workspace if there is only one
      if (formattedWorkspaces.length === 1) {
        const singleWorkspaceId = formattedWorkspaces[0].id
        void handleSelectWorkspace(
          singleWorkspaceId,
          intermediate_session_token
        )
      }

      setWorkspaces(formattedWorkspaces)
      setIntermediateSessionToken(intermediate_session_token)
      setStytchFlowError(null)
    } catch (error) {
      setStytchFlowError(error as Error)
    } finally {
      setIsLoading(false)
    }
  }, [handleSelectWorkspace, navigate, stytch.magicLinks.discovery])

  const handleMultiTenantFlow = useCallback(async () => {
    const token = new URLSearchParams(window.location.search).get('token')
    if (!token) {
      throw new Error('Missing token type or magic link token in query params')
    }

    try {
      await magicLinksFlowAuth({
        body: {
          magicLinksFlow: {
            magicLinksToken: token
          }
        }
      })

      setStytchFlowError(null)
    } catch (error) {
      setStytchFlowError(error as Error)
    } finally {
      setIsLoading(false)
    }
  }, [magicLinksFlowAuth])

  useEffect(() => {
    const authenticate = () => {
      const stytchTokenType = new URLSearchParams(window.location.search).get(
        'stytch_token_type'
      )

      if (stytchTokenType === 'discovery') {
        void handleDiscoveryFlow()
      } else if (stytchTokenType === 'multi_tenant_magic_links') {
        void handleMultiTenantFlow()
      } else {
        setStytchFlowError(
          new StytchAPIError({
            status_code: 400,
            error_type: 'stytch_token_type_not_found',
            error_message: 'Request is missing a valid stytch_token_type param',
            error_url: 'https://stytch.com/docs/api/errors'
          })
        )
      }
    }

    authenticate()
  }, [handleDiscoveryFlow, handleMultiTenantFlow, stytch.magicLinks.discovery])

  if (dataExchangeStytchIst || dataMagicLinksFlowAuth) {
    redirectToDashboard()
    return null
  }

  if (stytchFlowError) {
    if ('error_type' in stytchFlowError) {
      switch (stytchFlowError.error_type) {
        case 'magic_link_not_found':
        case 'unable_to_auth_magic_link':
          return <LinkExpiredPage />
        case 'pkce_mismatch':
          return <PkceMismatchPage />
        case 'pkce_expected_code_verifier':
          return <PkceExpectedCodeVerifierPage />
        default:
          return <ErrorPage />
      }
    }

    return <ErrorPage />
  }

  if (isErrorMagicLinksFlowAuth) {
    return <LinkExpiredPage />
  }

  if (isErrorExchangeStytchIst) {
    return <ErrorPage />
  }

  return (
    <PageContainer paddingTop="32px">
      <Wordmark width="126" height="26.74" />
      {isLoading ||
      isLoadingExchangeStytchIst ||
      isLoadingMagicLinksFlowAuth ? (
        <Section gap="8px">
          <Spinner />
        </Section>
      ) : (
        <Section gap="8px">
          <Text {...Lato16Bold} color="gray.90" textAlign="center">
            Select a workspace to continue
          </Text>
          <Spacer height="20px" />
          <WorkspaceList>
            {workspaces.map(workspace => (
              <WorkspaceList.Item
                key={workspace.id}
                name={workspace.name}
                action={workspace.action}
                onClick={() => {
                  void handleSelectWorkspace(
                    workspace.id,
                    intermediateSessionToken ?? ''
                  )
                }}
                link="#"
              />
            ))}
          </WorkspaceList>
        </Section>
      )}
      <Text position="absolute" bottom="40px" textAlign="center" zIndex={10}>
        Can’t find your workspace? &nbsp;
        <Link color="indigo.50">Request access</Link>
      </Text>
    </PageContainer>
  )
}

export default SelectWorkspacePage
