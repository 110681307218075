import { FormFields, useForm } from '@sequencehq/utils'
import { greaterThan, required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import {
  AvailableStandardFrequency,
  BillingType,
  PricingEditorReducerState
} from 'common/drawers/PricingEditor/domain'
import {
  defaultAvailableFrequenciesOptions,
  PRICING_MODEL_OPTIONS
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useCommonFieldConfig } from '../common/useCommonFieldConfig'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'

type CommonFields =
  PricingEditorReducerState['data']['pricingEditorData']['common']
type StandardPriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['STANDARD']

type StandardPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel' | 'currency'
> &
  FormFields<StandardPriceFields> & {
    pricingModel: FormFields<CommonFields>['pricingModel'] & {
      description: string
    }
    currency: FormFields<CommonFields>['currency'] & {
      hidden: boolean
    }
  }

type UseStandardPriceForm = () => {
  fieldsConfig: StandardPriceFormFieldConfig
  priceDetails: { label: string; value: string }[]
}

const standardPriceFrequencies: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

const availableBillingTypeOptions: { value: BillingType; label: string }[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export const useStandardPriceForm: UseStandardPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()

  const availableFrequencies = useMemo(() => {
    return standardPriceFrequencies.filter(frequency => {
      return pricingEditorContext.queries.rawData.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [
    pricingEditorContext.queries.rawData.configuration
      .availableStandardFrequencies
  ])

  const commonFields = useCommonFieldConfig<
    StandardPriceFields & CommonFields
  >()

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.STANDARD
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'price',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'billingFrequency',
        validation: [required],
        options: availableFrequencies
      },
      {
        property: 'billingType',
        validation: [required],
        options: availableBillingTypeOptions
      }
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name: newData.name,
          pricingModel: newData.pricingModel,
          currency: newData.currency
        },
        STANDARD: {
          price: newData.price,
          billingFrequency: newData.billingFrequency,
          billingType: newData.billingType
        }
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      pricingModel: {
        ...fields.pricingModel,
        description:
          PRICING_MODEL_OPTIONS.find(
            option => option.value === queries.formData.pricingModel
          )?.description ?? ''
      },
      currency: {
        ...fields.currency,
        hidden: Boolean(
          pricingEditorContext.queries.rawData.configuration.currency
        )
      }
    }
  }, [
    fields,
    pricingEditorContext.queries.rawData.configuration.currency,
    queries.formData.pricingModel
  ])

  const priceDetails = useMemo(() => {
    return commonPriceDetails({
      taxCategoryName:
        pricingEditorContext.queries.rawData.data.product.taxCategoryName,
      pricingModel: 'STANDARD',
      billingFrequency: queries.formData.billingFrequency,
      billingType: queries.formData.billingType
    })
  }, [queries.formData.billingFrequency, queries.formData.billingType])

  return {
    fieldsConfig: enhancedFields,
    priceDetails
  }
}
