import { FC } from 'react'

import { Box, Center, Flex } from '@chakra-ui/react'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'

import {
  Modal,
  Button,
  ModalContextProvider
} from '@sequencehq/core-components'
import Spinner from 'components/Loading'
import { GreyGrey80 } from '@sequencehq/design-tokens'
import { PdfPreview } from 'Assets/Attachments/PdfPreview'

type Props = {
  title: string
  fileUrl: string
  isLoading?: boolean
  isOpen?: boolean
  onClose?: VoidFunction
  onDownload?: VoidFunction | null
}

export const PDFPreviewDrawer: FC<Props> = ({
  title,
  onDownload,
  fileUrl,
  isLoading,
  isOpen,
  onClose
}) => {
  return (
    <ModalContextProvider isOpen={Boolean(isOpen)}>
      <Modal variant="drawer" onClose={onClose}>
        <Modal.Header showCloseButton withBorder>
          <Flex justify="space-between" alignItems="center" width="100%">
            {title}
            {onDownload && (
              <Button variant="secondary" onClick={onDownload}>
                <Box marginRight="6px">
                  <ArrowDownTrayIcon
                    height={16}
                    width={16}
                    color={GreyGrey80}
                  />
                </Box>
                Download PDF
              </Button>
            )}
          </Flex>
        </Modal.Header>
        <Modal.Content padding={0}>
          <Center width="100%" height="100%" position="relative">
            {isLoading ? (
              <Spinner />
            ) : (
              <Box
                width="100%"
                height="100%"
                position="absolute"
                top="0"
                left="0"
              >
                <PdfPreview url={fileUrl} />
              </Box>
            )}
          </Center>
        </Modal.Content>
      </Modal>
    </ModalContextProvider>
  )
}
