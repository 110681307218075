import { Currency } from '@sequencehq/core-models'
import { required } from '@sequencehq/validation'
import { FormFields, useForm } from '@sequencehq/utils'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ListPriceEditorFormData,
  UsageTierType
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { defaultAvailableFrequenciesOptions } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/forms/constants'
import {
  UsageParameterDataModel,
  useUsageMetricParameterFieldConfig
} from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/utils/useUsageMetricParameterFieldConfig'
import {
  percentageTiersValidator,
  tiersValidator
} from 'modules/Products/drawers/ListPriceEditor/validators/tiers.validators'

type VolumePriceFields = ListPriceEditorFormData['VOLUME']
type UseVolumePriceForm = () => {
  fieldsConfig: Omit<FormFields<VolumePriceFields>, 'usageMetricId'> & {
    usageMetricId: FormFields<VolumePriceFields>['usageMetricId'] & {
      onAddNew: () => void
    }
  }
  formData: VolumePriceFields
  currency: Currency
}

const availableUsageTierTypes: { label: string; value: UsageTierType }[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const useVolumePriceForm: UseVolumePriceForm = () => {
  const navigate = useNavigate()
  const listPriceEditorContext = useListPriceEditorContext()

  /**
   * Define options for the select fields
   */
  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return listPriceEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [listPriceEditorContext.configuration.availableStandardFrequencies])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(listPriceEditorContext.data.metrics).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [listPriceEditorContext.data.metrics])

  /**
   * Initialise the form management
   */
  const checkIfVolumeFieldDisabledByDomain = useCallback(
    ({
      property
    }: {
      property: keyof (VolumePriceFields & UsageParameterDataModel)
    }) =>
      listPriceEditorContext.functions.fieldIsDisabled(`VOLUME.${property}`),
    [listPriceEditorContext]
  )

  const parameterFields = useUsageMetricParameterFieldConfig<VolumePriceFields>(
    listPriceEditorContext.data.formData.VOLUME.usageMetricId,
    checkIfVolumeFieldDisabledByDomain
  )

  const { fields, queries } = useForm<
    Omit<VolumePriceFields, 'parameters'> & UsageParameterDataModel
  >({
    value: listPriceEditorContext.data.formData.VOLUME,
    fieldConfiguration: [
      {
        property: 'billingFrequency',
        disabled: checkIfVolumeFieldDisabledByDomain,
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'usageMetricId',
        disabled: checkIfVolumeFieldDisabledByDomain,
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'tiers',
        disabled: ctx =>
          checkIfVolumeFieldDisabledByDomain(ctx) ||
          ctx.formData.usageTierType !== 'FIXED',
        validation: [required, tiersValidator]
      },
      {
        property: 'percentageTiers',
        disabled: ctx =>
          checkIfVolumeFieldDisabledByDomain(ctx) ||
          ctx.formData.usageTierType !== 'PERCENTAGE',
        validation: [required, percentageTiersValidator]
      },
      {
        property: 'usageTierType',
        disabled: checkIfVolumeFieldDisabledByDomain,
        validation: [required],
        options: availableUsageTierTypes
      },
      {
        property: 'includePercentageLimits'
      },
      ...parameterFields.fields
    ],
    showValidationErrors: listPriceEditorContext.editor.showValidationErrors,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      listPriceEditorContext.functions.updateEditor({
        formsValid: {
          VOLUME: isValid
        }
      })
    },
    onChange: newData => {
      listPriceEditorContext.functions.updateFormData({
        VOLUME: newData
      })
    }
  })

  /**
   * Enhance the base field config with some extra bits and pieces
   */
  const processedFieldsConfig = useMemo(() => {
    return {
      ...fields,
      usageMetricId: {
        ...fields.usageMetricId,
        onAddNew: () => navigate('./metrics/new')
      }
    }
  }, [fields, navigate])

  return {
    fieldsConfig: processedFieldsConfig,
    formData: queries.formData,
    currency: listPriceEditorContext.data.formData.common.currency
  }
}
