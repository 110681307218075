import {
  InsightsOutstandingInvoicesModel,
  toCurrencySymbol
} from '@sequencehq/core-models'
import {
  useDefaultCurrency,
  useEnabledCurrencies
} from 'components/CurrencySettings/useCurrencies'
import { useGetInsightsOutstandingInvoicesQuery } from 'features/api'
import { Currency } from 'features/api/integratedApi.generated'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface UseOutstandingInvoices {
  data: InsightsOutstandingInvoicesModel | undefined
  isFetching: boolean
  currencyField: {
    value: Currency
    label: Currency
    onChange: (newCurrency: string) => void
    options: { value: Currency; label: Currency }[]
  }
  valueFormat: (value: number) => string
}

export const useOutstandingInvoices = (): UseOutstandingInvoices => {
  const res = useEnabledCurrencies()
  const enabledCurrencies = useMemo(() => {
    if (res.status === 'SUCCESS') {
      return res.enabledCurrencies
    }
    return []
  }, [res])
  const currencyOptions = useMemo(
    () =>
      enabledCurrencies.map(currency => ({
        value: currency,
        label: currency
      })),
    [enabledCurrencies]
  )

  const [currency, setCurrency] = useState(useDefaultCurrency() || 'GBP')

  const { data, refetch, isFetching } = useGetInsightsOutstandingInvoicesQuery({
    currency
  })

  const dataValue = data?.value()

  const currencyField = {
    value: currency,
    label: currency,
    onChange: (newCurrency: string) => setCurrency(newCurrency as Currency),
    options: currencyOptions
  }

  const valueFormat = useCallback(
    (value: number) =>
      `${toCurrencySymbol(currencyField.value)}${Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(value)}`,
    [currencyField.value]
  )

  useEffect(() => {
    void refetch()
  }, [currency, refetch])

  return {
    data: dataValue,

    isFetching,
    currencyField,
    valueFormat
  }
}
