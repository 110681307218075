import { PricingEditorMode } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { NEW_PRICE_PATTERN } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.constants'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback, useMemo, useState } from 'react'
import { Location } from 'react-router-dom'

export const useEditorFooter = () => {
  const [submitting, setSubmitting] = useState(false)
  const pricingEditorContext = usePricingEditorContext()

  const saveText = useMemo(() => {
    if (pricingEditorContext.configuration.mode === PricingEditorMode.EDIT) {
      return 'Update price'
    }

    return (pricingEditorContext.editor.selectedPrice ?? '').match(
      NEW_PRICE_PATTERN
    )
      ? 'Add new price'
      : 'Add price'
  }, [pricingEditorContext])

  const closeText = useMemo(
    () =>
      pricingEditorContext.configuration.mode === PricingEditorMode.VIEW
        ? 'Close'
        : 'Cancel',
    [pricingEditorContext]
  )

  const onSave = useCallback(async () => {
    /**
     * This is a bit weird isn't it? ... Yep! The issue with the blocker is
     * that it's rendered into the DOM as a 'static' component, but it's really quite a 'reactionary'
     * and conditional bit of functionality that really should ideally be called in response
     * to an action (such as leaving a route) more directly rather than be tied to current React state,
     * which is difficult to make gel neatly as we need to trigger the React render loop (and make this
     * save function async to give the loop a chance to render before we continue).
     *
     * Because of that, to integrate it well, we need to do a bit of shenanigans to set
     * the blocker as being disabled when adding a new price, and then re-enable it afterwards.
     * The setTimeout here is to give React an opportunity to re-render the component with the
     * blocker removed before we call the 'add price' functionality, and on completion of that
     * we want to re-enable it (in case the add fails for validation, for example).
     *
     * We may want to review Blocker in the future to see if we can integrate it a smidge more
     * neatly.
     */
    setSubmitting(true)
    await new Promise(resolve => setTimeout(resolve, 0))
    await pricingEditorContext.functions.saveNewPrice()
    setSubmitting(false)
  }, [pricingEditorContext.functions])

  const blockerEnabled = useMemo(() => {
    return Boolean(
      !submitting &&
        pricingEditorContext.derived.queries.priceHasBeenUpdated &&
        Object.values(pricingEditorContext.derived.queries.newPrices).length
    )
  }, [
    pricingEditorContext.derived.queries.priceHasBeenUpdated,
    submitting,
    pricingEditorContext.derived.queries.newPrices
  ])

  const saveDisabled = useMemo(() => {
    return (
      submitting ||
      (!pricingEditorContext.derived.queries.priceHasBeenUpdated &&
        !pricingEditorContext.derived.queries.productHasBeenUpdated)
    )
  }, [pricingEditorContext.derived.queries, submitting])

  const ignoreBlocker = useCallback((nextLocation: Location): boolean => {
    if (
      nextLocation.pathname.match(/\/metrics\/new/) ||
      nextLocation.pathname.match(/\/seat-types\/new/)
    ) {
      return true
    }
    return false
  }, [])

  return {
    saveAvailable: pricingEditorContext.derived.queries.availableFeatures.save,
    saveDisabled,
    closeText,
    saveText,
    onSave,
    onClose: pricingEditorContext.functions.closeEditor,
    blockerEnabled,
    submitting,
    ignoreBlocker
  }
}
