import { PricingEditorReducerState } from '../../pricingEditor.domain.types'

const getBreadcrumbs = (
  prevState: PricingEditorReducerState
): PricingEditorReducerState['queries']['breadcrumbSteps'] => {
  return prevState.editor.steps.map(step => ({
    name: step.name,
    active: step.activeInMode.includes(prevState.configuration.mode)
  }))
}

export const getBreadcrumbSteps = (
  prevState: PricingEditorReducerState
): PricingEditorReducerState['queries']['breadcrumbSteps'] => {
  return getBreadcrumbs(prevState)
}
