import CreditNotes from 'components/CreditNotes'

const CreditNotesRoute = () => {
  // WIP
  // We will still be using the credit notes list page for now as a directory for credit notes
  // the only thing being worked on atm is the credit note details v2 page
  return <CreditNotes />
}

export default CreditNotesRoute
