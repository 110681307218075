import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Text
} from '@chakra-ui/react'
import { CrossIcon } from '@sequencehq/core-components'
import {
  NotificationChannel,
  NotificationType,
  notificationTypeGroups,
  notificationTypes
} from '@sequencehq/core-models'
import { toTitleCase } from '@sequencehq/formatters'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import { atLeast, isEmail, isUrl, required } from '@sequencehq/validation'
import { CreateNotificationPolicyFormMutation } from 'components/CreateNotificationPolicy/CreateNotificationPolicyModal'
import { MutationFormProps } from 'components/Form/types'
import { PlusIcon } from 'components/icons'
import arrayMutators from 'final-form-arrays'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import { FC, Fragment, useState } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

interface FormValues {
  name: string
  recipients: string[]
  notificationTypes: NotificationType[] | undefined
}

export const CreateNotificationPolicyForm: FC<
  MutationFormProps<CreateNotificationPolicyFormMutation> &
    Record<string, never>
> = ({ formId, submitForm }) => {
  const [allNotificationTypes, setAllNotificationTypes] =
    useState<string>('ALL')
  const [channel, setChannel] = useState<NotificationChannel>('EMAIL')

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
          changeValue(state, field, () => value)
        }
      }}
      initialValues={{
        name: undefined,
        recipients: [''],
        notificationTypes: undefined
      }}
      onSubmit={async (values, form) => {
        const res = await submitForm({
          createNotificationPolicyRequest: {
            ...values,
            channel: channel,
            notificationTypes:
              allNotificationTypes === 'ALL'
                ? undefined
                : values.notificationTypes
          }
        })
        return handleFormResponse(res, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <>
            <Flex
              as="form"
              px={5}
              py={5}
              flexDirection="column"
              onSubmit={noReturn(handleSubmit)}
              id={formId}
            >
              <FormErrors formError={submitError} />
              <TextInputField
                fieldName="name"
                fieldLabel="Policy name"
                validate={required}
              />
              <Box height={5} />
              <RadioGroup
                onChange={newChannel =>
                  setChannel(newChannel as NotificationChannel)
                }
                value={channel}
              >
                <Flex direction="row">
                  <Radio value="EMAIL">Email</Radio>
                  <Box width="8px"></Box>
                  <Radio value="WEBHOOK">Webhook</Radio>
                </Flex>
              </RadioGroup>
              <Box height={5} />
              <Recipients channel={channel}></Recipients>
              <Box height={5} />
              <FormLabel>Notification Types</FormLabel>
              <Box height={2} />
              <RadioGroup
                onChange={setAllNotificationTypes}
                value={allNotificationTypes}
              >
                <Flex direction="row">
                  <Radio value="ALL">All notification types</Radio>
                  <Box width="8px"></Box>
                  <Radio value="SELECT">Select notification types</Radio>
                </Flex>
              </RadioGroup>
              <Box height={5} />
              {allNotificationTypes === 'SELECT' && (
                <NotificationTypes channel={channel}></NotificationTypes>
              )}
            </Flex>
          </>
        )
      }}
    />
  )
}

const Recipients: FC<{ channel: NotificationChannel }> = ({ channel }) => (
  <>
    <FieldArray name="recipients" validate={atLeast(1, 'recipient')}>
      {({ fields, meta }) => (
        <>
          <FormLabel>
            <Flex flexDirection="row">
              <Text fontWeight="700">
                {channel === 'EMAIL' ? 'Recipients' : 'Webhook URLs'}
              </Text>
              <Box width="8px"></Box>
              {meta.submitError && (
                <Text color="red.60" fontSize="11px" lineHeight="18px">
                  {meta.submitError}
                </Text>
              )}
            </Flex>
          </FormLabel>
          <Box height={2} />

          {fields.map((name, index) => (
            <Fragment key={index}>
              <Flex>
                <TextInputField
                  data-testid={name}
                  fieldName={name}
                  marginRight={2}
                  alt={name}
                  validate={channel === 'EMAIL' ? isEmail : isUrl}
                />
                <IconButton
                  aria-label={
                    channel === 'EMAIL' ? 'Delete recipient' : 'Delete URL'
                  }
                  icon={<CrossIcon />}
                  onClick={() => {
                    fields.remove(index)
                  }}
                  variant="clear"
                />
              </Flex>
              <Box height={2} />
            </Fragment>
          ))}

          <Box height={4} />
          <Button
            size="sm"
            leftIcon={<PlusIcon />}
            variant="secondary"
            onClick={() => fields.push('')}
          >
            Add another {channel === 'EMAIL' ? 'recipient' : 'URL'}
          </Button>
        </>
      )}
    </FieldArray>
  </>
)

type NotificationTypesProps = {
  channel: NotificationChannel
}

const NotificationTypes: FC<NotificationTypesProps> = ({ channel }) => {
  const flags = useFlags()
  return (
    <>
      <FieldArray name="notificationTypes">
        {({ fields }) => (
          <>
            {Object.entries(notificationTypeGroups)
              .filter(
                ([group]) => {
                  const hideIntegrationsForWebhooks = (channel === 'WEBHOOK' && group === 'INTEGRATIONS')
                  if (hideIntegrationsForWebhooks) {
                    return false
                  }

                  const hideQuotes = group === 'QUOTES' && !flags?.showQuoteBuilder
                  if (hideQuotes) {
                    return false
                  }

                  return true
                }
              )
              .sort()
              .map(([group]) => (
                <>
                  <Text textStyle="bold">{toTitleCase(group)}</Text>
                  <Box height="4px"></Box>
                  <Flex flexDirection="column">
                    {Object.entries(notificationTypes)
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      .filter(([value, label]) => label === group.toString())
                      .sort()
                      .map(([value]) => (
                        <Flex key={value}>
                          <Checkbox
                            value={value}
                            onChange={e => {
                              if (e.target.value) {
                                fields.push(value)
                              } else {
                                fields.remove(fields.value.indexOf(value))
                              }
                            }}
                          >
                            {toTitleCase(value)}
                          </Checkbox>
                          <Box width="16px"></Box>
                        </Flex>
                      ))}
                  </Flex>
                  <Box height="10px"></Box>
                </>
              ))}
          </>
        )}
      </FieldArray>
    </>
  )
}
