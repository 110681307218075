import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import Spinner from 'components/Loading/Spinner'
import { ListPriceForm } from 'modules/Products/drawers/ListPriceEditor/ListPriceForm'
import { ListPriceEditorProps } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { ListPriceEditorProvider } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { useListPriceEditorRoot } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorRoot'
import { Outlet } from 'react-router-dom'

export const ListPriceEditorRoot = (props: ListPriceEditorProps) => {
  const { listPriceEditor } = useListPriceEditorRoot(props)

  return (
    <ListPriceEditorProvider value={listPriceEditor}>
      <Drawer isOpen onClose={props.onClose}>
        <DrawerOverlay
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <DrawerContent
            margin="8px 12px"
            /**
             * Chakra _wants_ to ignore the width we set for the drawer content, but what
             * do we say in the face of stubborn chakra? Not today.
             */
            style={{
              width: 'fit-content',
              maxWidth: '100%',
              minWidth: '550px'
            }}
            borderRadius="8px"
            overflow="hidden"
          >
            {listPriceEditor.editor.loaded ? <ListPriceForm /> : <Spinner />}
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Outlet />
    </ListPriceEditorProvider>
  )
}
