import { Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { Outlet } from 'react-router-dom'

const HomePage = () => (
  <Flex flexDirection="column" flex={1}>
    <Flex position="sticky" top={0} height="100vh">
      <Page title="Home">
        <Outlet />
      </Page>
    </Flex>
  </Flex>
)

export default HomePage
