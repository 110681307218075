import {
  ValidatorWithMetadata,
  ValidatorWithMetadataError
} from '@sequencehq/utils'
import {
  greaterThan,
  greaterThanEqualTo,
  required
} from '@sequencehq/validation'
import {
  PricingEditorPercentageTier,
  PricingEditorTier
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'

export const tiersLessThanPrevious: ValidatorWithMetadata<
  { lastUnit: string; id: string }[],
  Record<string, unknown>,
  {
    tierId: string
    property: 'lastUnit'
  }
> = (tiers: { lastUnit: string; id: string }[]) => {
  const { errors } = tiers.reduce(
    (acc, currentTier, idx) => {
      /**
       * The 'lastUnit' of the final tier should always be empty. Therefore, exclude
       * it from the check
       */
      if (
        idx === tiers.length - 1 &&
        !parseFloat(currentTier.lastUnit ?? '0.00')
      ) {
        return {
          ...acc,
          checkedTiers: [...acc.checkedTiers, currentTier]
        }
      }

      if (
        acc.checkedTiers.find(
          tier =>
            parseFloat(tier.lastUnit ?? '0.00') >=
            parseFloat(currentTier.lastUnit ?? '0.00')
        )
      ) {
        return {
          ...acc,
          checkedTiers: [...acc.checkedTiers, currentTier],
          errors: [
            ...acc.errors,
            {
              message: `Tier last unit must be greater than previous tier`,
              metadata: {
                tierId: currentTier.id,
                property: 'lastUnit' as const
              }
            }
          ]
        }
      }

      return {
        ...acc,
        checkedTiers: [...acc.checkedTiers, currentTier]
      }
    },
    {
      checkedTiers: [],
      errors: []
    } as {
      checkedTiers: { lastUnit: string; id: string }[]
      errors: ValidatorWithMetadataError<{
        tierId: string
        property: 'lastUnit'
      }>[]
    }
  )

  return errors
}

export const valueMissing =
  <Tier extends Record<string, unknown> & { id: string }>(tier: Tier) =>
  (
    propName: keyof Tier
  ):
    | ValidatorWithMetadataError<{
        tierId: string
        property: keyof Tier
      }>
    | undefined => {
    if (required(tier[propName]) || greaterThan(0)(tier[propName])) {
      return {
        message: `${propName as string} is required`,
        metadata: {
          tierId: tier.id,
          property: propName
        }
      }
    }

    return
  }

export const tiersMissingValue: ValidatorWithMetadata<
  PricingEditorTier[],
  Record<string, unknown>,
  {
    tierId: string
    property: keyof PricingEditorTier
  }
> = (tiers: PricingEditorTier[]) => {
  return tiers
    .flatMap(currentTier => {
      const unitPriceMissing = required(currentTier.unitPrice)
      const lastUnitMissing = valueMissing(currentTier)('lastUnit')

      const errors = [lastUnitMissing, unitPriceMissing].filter(
        (
          result
        ): result is ValidatorWithMetadataError<{
          tierId: string
          property: keyof PricingEditorTier
        }> => Boolean(result)
      )

      if (!errors.length) {
        return []
      }

      return errors
    })
    .filter(Boolean)
}

export const finalTierMissingValue: ValidatorWithMetadata<
  PricingEditorTier,
  Record<string, unknown>,
  {
    tierId: string
    property: keyof PricingEditorTier
  }
> = (finalTier: PricingEditorTier) => {
  const unitPriceMissing = required(finalTier.unitPrice)
    ? {
        message: `Unit percentage is required`,
        metadata: {
          tierId: finalTier.id,
          property: 'unitPercentage'
        }
      }
    : null
  const lastUnitPresent = required(finalTier.lastUnit)
    ? null
    : {
        message: `The final tier must be unbounded`,
        metadata: {
          tierId: finalTier.id,
          property: 'lastUnit'
        }
      }

  return [unitPriceMissing, lastUnitPresent].filter(
    (
      result
    ): result is ValidatorWithMetadataError<{
      tierId: string
      property: keyof PricingEditorTier
    }> => Boolean(result)
  )
}

export const percentageTiersMissingValue: ValidatorWithMetadata<
  PricingEditorPercentageTier[],
  Record<string, unknown>,
  {
    tierId: string
    property: keyof PricingEditorPercentageTier
  }
> = (tiers: PricingEditorPercentageTier[]) => {
  return tiers
    .flatMap(currentTier => {
      const unitPercentageMissing = required(currentTier.unitPercentage)
      const lastUnitMissing = valueMissing(currentTier)('lastUnit')

      const errors = [lastUnitMissing, unitPercentageMissing].filter(
        (
          result
        ): result is ValidatorWithMetadataError<{
          tierId: string
          property: keyof PricingEditorPercentageTier
        }> => Boolean(result)
      )

      if (!errors.length) {
        return []
      }

      return errors
    })
    .filter(Boolean)
}

export const percentageFinalTierMissingValue: ValidatorWithMetadata<
  PricingEditorPercentageTier,
  Record<string, unknown>,
  {
    tierId: string
    property: keyof PricingEditorPercentageTier
  }
> = (finalTier: PricingEditorPercentageTier) => {
  const unitPercentageMissing = required(finalTier.unitPercentage)
    ? {
        message: `Unit percentage is required`,
        metadata: {
          tierId: finalTier.id,
          property: 'unitPercentage'
        }
      }
    : null
  const lastUnitPresent = required(finalTier.lastUnit)
    ? null
    : {
        message: `The final tier must be unbounded`,
        metadata: {
          tierId: finalTier.id,
          property: 'lastUnit'
        }
      }

  return [unitPercentageMissing, lastUnitPresent].filter(
    (
      result
    ): result is ValidatorWithMetadataError<{
      tierId: string
      property: keyof PricingEditorPercentageTier
    }> => Boolean(result)
  )
}

export const percentageTiersValidator: ValidatorWithMetadata<
  PricingEditorPercentageTier[],
  Record<string, unknown>,
  {
    tierId?: string
    property?: keyof PricingEditorPercentageTier
  }
> = (value: PricingEditorPercentageTier[], additionalData) => {
  if (!Array.isArray(value)) {
    return []
  }

  if (!value.length) {
    return [
      {
        message: 'At least one tier is required',
        metadata: {
          tierId: undefined
        }
      }
    ]
  }

  const tiers = value.slice(0, value.length - 1)
  const finalTier = value[value.length - 1]

  return [
    ...tiersLessThanPrevious(value, additionalData),
    ...percentageTiersMissingValue(tiers, additionalData),
    ...percentageFinalTierMissingValue(finalTier, additionalData)
  ]
}

export const percentageTierLimitsValidator: ValidatorWithMetadata<
  PricingEditorPercentageTier[],
  Record<string, unknown>,
  {
    tierId: string
    property: keyof PricingEditorPercentageTier
  }
> = (value: PricingEditorPercentageTier[]) => {
  return value
    .flatMap(currentTier => {
      const minimumIsNegativeError =
        currentTier.minPrice && greaterThanEqualTo(0)(+currentTier.minPrice)
          ? {
              message: 'Cannot be negative',
              metadata: {
                tierId: currentTier.id,
                property: 'minPrice'
              }
            }
          : undefined
      const maximumIsNegativeError =
        currentTier.maxPrice && greaterThanEqualTo(0)(+currentTier.maxPrice)
          ? {
              message: 'Cannot be negative',
              metadata: {
                tierId: currentTier.id,
                property: 'maxPrice'
              }
            }
          : undefined
      const maximumLessThanMinimumError =
        currentTier.minPrice &&
        currentTier.maxPrice &&
        greaterThan(+currentTier.minPrice)(currentTier.maxPrice)
          ? {
              message: 'Max. must be greater than min.',
              metadata: {
                tierId: currentTier.id,
                property: 'maxPrice'
              }
            }
          : undefined

      return [
        minimumIsNegativeError,
        maximumIsNegativeError,
        maximumLessThanMinimumError
      ]
    })
    .filter(
      (
        error
      ): error is ValidatorWithMetadataError<{
        tierId: string
        property: keyof PricingEditorPercentageTier
      }> => Boolean(error)
    )
}

export const tiersValidator: ValidatorWithMetadata<
  PricingEditorTier[],
  Record<string, unknown>,
  {
    tierId?: string
    property?: keyof PricingEditorTier
  }
> = (value: PricingEditorTier[], additionalData) => {
  if (!Array.isArray(value)) {
    return []
  }

  if (!value.length) {
    return [
      {
        message: 'At least one tier is required',
        metadata: {
          tierId: undefined
        }
      }
    ]
  }

  const tiers = value.slice(0, value.length - 1)
  const finalTier = value[value.length - 1]

  return [
    ...tiersLessThanPrevious(value, additionalData),
    ...tiersMissingValue(tiers, additionalData),
    ...finalTierMissingValue(finalTier, additionalData)
  ]
}
