import {
  Card,
  CardBody,
  CardBodyProps,
  CardHeader,
  CardHeaderProps,
  Flex
} from '@chakra-ui/react'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { ReactNode } from 'react'

/**
 * Adapted from the InsightsV2 equivalent initiallty. This component would be good
 * to combine with the charts, in the 'shared-components' library, and move the
 * whole set of chart components to core-components. Or, at the very least, make
 * 'share-components' a clear 'charts' library if for some reason visX disagrees
 * with core-components (like we saw with animation libs).
 *
 * @param param0
 * @returns
 */
const ChartTooltipBase = ({
  children,
  width = '270px'
}: {
  children: ReactNode
  width?: string
}) => {
  return (
    <Card
      bg="white"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      borderRadius="md"
      width={width}
      border={`1px solid ${GreyGrey30}`}
    >
      {children}
    </Card>
  )
}

const Header = ({ children, ...props }: CardHeaderProps) => {
  return (
    <CardHeader
      px={3}
      py={2}
      bg="gray.10"
      borderBottom={`1px solid ${GreyGrey30}`}
      {...props}
    >
      {children}
    </CardHeader>
  )
}

const Body = ({ children, ...props }: CardBodyProps) => {
  return (
    <CardBody py={2} px={3} as={Flex} justifyContent="space-between" {...props}>
      {children}
    </CardBody>
  )
}

export const ChartTooltip = Object.assign(ChartTooltipBase, {
  Header,
  Body
})
