import {
  TextInputField,
  InputProps,
  CombinedFieldProps
} from '@sequencehq/forms'
import { formatDecimal } from '@sequencehq/formatters'
import { parseDecimal } from 'lib/parsers'
import { FC, memo } from 'react'

type DecimalInputProps = CombinedFieldProps<string, InputProps> & {
  decimalPlaces: '2' | '4' | '10'
  disabled?: boolean
}

export const DecimalInput: FC<DecimalInputProps> = memo(
  ({ decimalPlaces, ...props }) => (
    <TextInputField<string>
      {...props}
      parse={parseDecimal({ decimalPlaces })}
      format={formatDecimal()}
    />
  )
)
