import {
  CubeReducerState,
  Phase,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'

export const noIncompatibleDiscountProration =
  (ctx: { phaseId: Phase['id'] }) =>
  (prevState: CubeReducerState): ValidationResult[] | null => {
    const thisPhase = prevState.queries.resolvedPhases[ctx.phaseId]

    if (thisPhase.analysis?.proration.invalidDiscounts.length) {
      return [
        {
          message:
            'Phase contains discounts which are updated in the middle of a billing period.',
          metadata: {
            invalidMinimums: thisPhase.analysis?.proration.invalidDiscounts
          }
        }
      ]
    }

    return null
  }
