import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { loginRouter } from 'modules/LoginApp/loginApp.router'
import { RouterProvider } from 'react-router-dom'

const LoginAppBase = () => {
  return <RouterProvider router={loginRouter} />
}

/**
 * Initialise the login app with the anonymous launch darkly user. This
 * wont grant us access to user targetted flags, but it will still allow
 * us to make use of features flags that are globally enabled/disabled for
 * all users in the current environment.
 */
export const LoginApp = withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
  context: {
    kind: 'user',
    key: import.meta.env.VITE_LAUNCHDARKLY_ANONYMOUS_USER_ID
  },
  options: {
    disableSyncEventPost: true,
    hash: import.meta.env.VITE_LAUNCHDARKLY_ANONYMOUS_USER_HASH
  }
})(LoginAppBase)
