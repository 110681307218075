import { Box } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'

export const GoCardlessIntegrationDetail = () => {
  return (
    <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
      Set up direct debits, collect payments from customers and reconcile
      Sequence invoices automatically.
    </Box>
  )
}
