import { Box, Flex } from '@chakra-ui/react'
import { WrenchScrewdriverIcon } from '@heroicons/react/16/solid'
import { Card } from '@sequencehq/core-components'
import {
  GreyGrey10,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular
} from '@sequencehq/design-tokens'

export const NoConfiguration = () => {
  return (
    <Box width="100%">
      <Card width="100%">
        <Flex
          height="160px"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          borderRadius="8px"
          backgroundColor={GreyGrey10}
          gap="12px"
        >
          <WrenchScrewdriverIcon
            height="24px"
            width="24px"
            color={GreyGrey70}
          />
          <Box {...Lato13Regular} color={GreyGrey90}>
            This integration does not have any configuration.
          </Box>
        </Flex>
      </Card>
    </Box>
  )
}
