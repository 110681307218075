import {
  Modal,
  ModalContextProvider,
  SimpleModalUI,
  TextInputField,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { ReactNode, useState } from 'react'
import { Box, Flex, Divider } from '@chakra-ui/react'
import { Label } from '@sequencehq/forms'
import { useForm, type FormFields } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import AvalaraTaxCodeInput from 'Settings/view/taxRatesSettings/avalara/common/AvalaraTaxCodeInput.tsx'

type TaxCategoryFields = FormFields<{
  name: string
  avalaraTaxCode: string
}>
type FormValues = {
  name: TaxCategoryFields['name']['value']
  avalaraTaxCode: TaxCategoryFields['avalaraTaxCode']['value']
}

interface Props {
  trigger: ReactNode | ((openModal: () => void) => ReactNode)
  onCreate: (values: {
    taxCategoryName: string
    avalaraTaxCode: string
  }) => Promise<void>
}

const AddTaxCategoryForAvalaraModalUI = withUnmountOnModalClose(
  ({ onCreate }: Omit<Props, 'trigger'>) => {
    const { fields, queries } = useForm({
      value: {
        name: '',
        avalaraTaxCode: ''
      },
      fieldConfiguration: [
        {
          property: 'name',
          validation: [required]
        },
        {
          property: 'avalaraTaxCode',
          validation: [required]
        }
      ]
    })

    const [avalaraTaxCodeInfo, setAvalaraTaxCodeInfo] = useState<
      string | undefined
    >()

    const canSubmit = queries.isValid && avalaraTaxCodeInfo

    const handleSubmit = (formValues: FormValues) => {
      if (formValues.name && formValues.avalaraTaxCode) {
        void onCreate({
          taxCategoryName: formValues.name,
          avalaraTaxCode: formValues.avalaraTaxCode
        })
      }
    }

    return (
      <SimpleModalUI
        title="Add tax category"
        cancelButtonText="Cancel"
        submitButtonText="Create tax category"
        submitDisabled={!canSubmit}
        closeOnCancel
        closeOnSubmit
        contentProps={{
          minHeight: '0px'
        }}
        onSubmit={() => {
          void handleSubmit({
            name: fields.name.value,
            avalaraTaxCode: fields.avalaraTaxCode.value
          })
        }}
      >
        <Flex flexDirection="column" gap={2} mb="-17px">
          <Box>
            <Label size="sm">Name</Label>
            <TextInputField
              data-testid="avalaraTaxCategoryModal.name"
              value={fields.name.value}
              validationErrors={fields.name.validationErrors}
              onChange={e => fields.name.onChange(e.target.value)}
              placeholder="Software"
            />
          </Box>
          <Divider mb={2} mt={-1} borderBottomColor={GreyGrey30} />
          <AvalaraTaxCodeInput
            value={fields.avalaraTaxCode.value}
            onChange={value => {
              setAvalaraTaxCodeInfo(undefined)
              fields.avalaraTaxCode.onChange(value)
            }}
            onSuccess={setAvalaraTaxCodeInfo}
          />
        </Flex>
      </SimpleModalUI>
    )
  }
)

const AddTaxCategoryForAvalaraModal = ({ trigger, ...props }: Props) => {
  return (
    <ModalContextProvider>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <AddTaxCategoryForAvalaraModalUI {...props} />
    </ModalContextProvider>
  )
}

export default AddTaxCategoryForAvalaraModal
