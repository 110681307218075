import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { BillingScheduleModel } from '@sequencehq/core-models'
import { CorePortErrors } from 'modules/Cube/domain/cube.domain.types'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export type v1ApiBillingSchedule =
  Dashboardv20240509Api.GetBillingSchedule.BillingSchedule
export type v1ApiBillingScheduleSettings =
  Dashboardv20240509Api.GetBillingScheduleSettings.BillingScheduleSettings

type Data = {
  billingSchedule: v1ApiBillingSchedule
  billingScheduleSettings: v1ApiBillingScheduleSettings
}

type UseLoadBillingSchedule = () => (
  billingScheduleId: BillingScheduleModel['id']
) => Promise<{ data: Data | null; error: CorePortErrors | null }>

export const useLoadBillingSchedule: UseLoadBillingSchedule = () => {
  const load = useCallback(
    async (billingScheduleId: BillingScheduleModel['id']) => {
      const [billingScheduleResponse, billingScheduleSettingsResponse] =
        await Promise.all([
          dashboardv20240509Client.getBillingSchedule({
            id: billingScheduleId
          }),
          dashboardv20240509Client.getBillingScheduleSettings({
            billingScheduleId
          })
        ])

      if (!billingScheduleResponse.data || billingScheduleResponse.error) {
        return {
          data: null,
          error:
            billingScheduleResponse.res.status === 404
              ? CorePortErrors.NotFound
              : CorePortErrors.Other
        }
      }

      invariant(
        billingScheduleSettingsResponse.data ||
          billingScheduleSettingsResponse.res.status === 404,
        'Billing schedule settings not found'
      )
      const defaultAutoChargeSetting =
        await dashboardv99990101Client.getStripeCollectionsSettings()

      /**
       * If we encounter a 404 on loading the billing schedule settings,
       * create a new settings object for this schedule. This is a temporary
       * fix until we perform this logic on the service instead.
       */
      const billingScheduleSettings =
        billingScheduleSettingsResponse.data ||
        (
          await dashboardv20240509Client.postBillingScheduleSettings({
            body: {
              billingScheduleId,
              paymentProvider: 'NONE',
              autoCharge:
                !!defaultAutoChargeSetting.data?.enableAutomaticPayments
            }
          })
        )?.data

      invariant(billingScheduleSettings, 'Billing schedule settings not found')

      return {
        data: {
          billingSchedule: billingScheduleResponse.data,
          billingScheduleSettings
        },
        error: null
      }
    },
    []
  )

  return load
}
