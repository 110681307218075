import deepmerge from 'deepmerge'
import {
  ActionHandler,
  IntegrationsReducerState,
  UpdateEditorAction
} from 'modules/Integrations/domain/integrations.domain.types'

export const updateEditor: ActionHandler<UpdateEditorAction> =
  prevState => action => {
    const newEditorData = deepmerge(prevState.editor, action.payload)

    return {
      ...prevState,
      editor: newEditorData
    } as IntegrationsReducerState
  }
