import {
  CubeReducerState,
  TaxRate
} from 'modules/Cube/domain/cube.domain.types'

/**
 * The available tax rates depend on the customer's tax status and country.
 * @param prevState
 * @returns
 */
export const getAvailableTaxRates = (
  prevState: CubeReducerState
): Record<TaxRate['id'], TaxRate> => {
  const activeCustomer =
    prevState.data.customers[prevState.data.common.customerId]

  if (
    !activeCustomer ||
    ['TAX_EXEMPT', 'REVERSE_CHARGED'].includes(activeCustomer.taxStatus)
  ) {
    return {}
  }

  return Object.values(prevState.data.taxRates).reduce((acc, taxRate) => {
    if (taxRate.country === activeCustomer.address.country) {
      return {
        ...acc,
        [taxRate.id]: taxRate
      }
    }
    return acc
  }, {})
}
