import {
  CubeReducerState,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'

export const getPricingDates =
  (ctx: { orderedPhases: ResolvedPhase[] }) =>
  (prevState: CubeReducerState) => {
    const lastPhase = ctx.orderedPhases[ctx.orderedPhases.length - 1]
    return {
      start: prevState.data.common.startDate,
      end: lastPhase?.dates.absolute.end
    }
  }
