import { FC } from 'react'

import {
  Modal,
  ModalContextProvider,
  SimpleModalUI
} from '@sequencehq/core-components'

import { useCustomerContactsContext } from 'modules/CustomerContacts/communication/internal/CustomerContacts.domain.context'
import { useContactModalForm } from '../forms/ContactModalForm/useContactModalForm'
import { ContactModalForm } from '../forms/ContactModalForm/ContactModalForm'
import { CustomerContactBillingPreference } from 'CustomerContacts/domain/CustomerContacts.domain.types'

interface Props {
  trigger: React.ReactNode
  onSubmit?: () => void
  onCancel?: () => void
  billingPreference?: CustomerContactBillingPreference
}

export const CreateContactModal: FC<Props> = ({ trigger, ...restProps }) => {
  return (
    <ModalContextProvider>
      <Modal.Trigger>{trigger}</Modal.Trigger>
      <CreateContactModalUI {...restProps} />
    </ModalContextProvider>
  )
}

export const CreateContactModalUI: FC<Omit<Props, 'trigger'>> = ({
  onSubmit,
  onCancel,
  billingPreference
}) => {
  const ctx = useCustomerContactsContext()

  const { fieldsConfig, buttonSubmit, resetForm } = useContactModalForm({
    contactId: 'new',
    fieldsValues: {
      ...ctx.queries.formData,
      billingPreference:
        billingPreference ?? ctx.queries.formData.billingPreference
    },
    updateFields: ctx.mutators.internal.updateForm,
    createContact: ctx.mutators.external.out.createContact,
    updateContact: ctx.mutators.external.out.updateContact
  })

  return (
    <SimpleModalUI
      title="Add contact"
      onSubmit={() => {
        buttonSubmit.onSubmit(onSubmit)
      }}
      onCancel={() => {
        resetForm()
        onCancel?.()
      }}
      cancelButtonText="Cancel"
      submitButtonText="Add contact"
      submitDisabled={buttonSubmit.disabled}
      closeOnSubmit={buttonSubmit.isValid}
    >
      <ContactModalForm fieldsConfig={fieldsConfig} />
    </SimpleModalUI>
  )
}
