import { Minimum } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'

export const DEFAULT_MINIMUM: Minimum = {
  id: 'replace-me',
  value: '0.00',
  name: '',
  scope: {
    target: 'allUsage',
    priceIds: []
  }
}

export const NEW_MINIMUM_PREFIX = 'new-minimum::'
export const NEW_MINIMUM_PATTERN = /^new-minimum::/
