import {
  Badge,
  MenuItemBuilder,
  Modal,
  ModalContextProvider,
  Toast
} from '@sequencehq/core-components'
import {
  InvoicePaymentStatus,
  toInvoicePaymentStatusBadgeProps
} from '@sequencehq/core-models'
import integratedApi from 'features/api'
import { openOverlay } from 'features/overlay'
import * as Sentry from '@sentry/react'
import { UseToastOptions, Box, Flex, Text } from '@chakra-ui/react'
import { DownloadInvoicePdfProps } from 'InvoiceEditor/components/Header/useInvoicePdfDownload'
import { Dashboardv20240509Api } from '@sequencehq/api/dashboard/v20240509'
import { RedRed60 } from '@sequencehq/design-tokens'
import { ArchiveInvoiceModal } from 'components/Invoices/modals/ArchiveInvoice.tsx'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice

const { putInvoicesByIdPaymentStatus } = integratedApi.endpoints

const finalizeAndSendBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: invoice.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Send Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeAndSendInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName,
            billingScheduleId: invoice.billingScheduleId,
            customerEmails: invoice.customerEmails
          }
        })
      )
  }
}

export const archiveInvoiceBuilder: MenuItemBuilder<Invoice> = (
  invoice: Invoice,
  options = {}
) => {
  return {
    status: ['IN_PROGRESS', 'DRAFT'].includes(invoice.status)
      ? 'LIVE'
      : 'DISABLED',
    action: () => {},
    label: (
      <ModalContextProvider>
        <Modal.Trigger>
          {triggerModal => {
            return (
              <Flex
                flexDirection="row"
                align="center"
                gap={2}
                width="100%"
                onClick={triggerModal}
                data-testid="kebab-menu-archive-button"
              >
                <Text color={RedRed60}>Delete invoice</Text>
              </Flex>
            )
          }}
        </Modal.Trigger>
        <ArchiveInvoiceModal
          invoiceId={invoice.id}
          onSuccess={options.onSuccess}
        />
      </ModalContextProvider>
    )
  }
}

export const finalizeBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: invoice.status === 'DRAFT' ? 'LIVE' : 'DISABLED',
    label: 'Finalize Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'finalizeInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName
          }
        })
      )
  }
}

export const sendBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: invoice.status === 'FINAL' ? 'LIVE' : 'DISABLED',
    label: 'Send Invoice',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'sendInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName,
            billingScheduleId: invoice.billingScheduleId,
            customerEmails: invoice.customerEmails
          }
        })
      )
  }
}

const previewBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status: 'LIVE',
    action: ({ navigate }) => navigate(`/invoices/${invoice.id}`),
    label: invoice.status === 'DRAFT' ? 'View/Edit invoice' : 'View invoice'
  }
}

const downloadBuilder: (
  downloadPdf: (props: DownloadInvoicePdfProps) => void
) => MenuItemBuilder<Invoice> = download => (invoice: Invoice) => {
  return {
    status: 'LIVE',
    action: () => {
      download({
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        invoiceStatus: invoice.status === 'VOID' ? 'VOIDED' : invoice.status,
        customerId: invoice.customerId
      })
    },
    label: 'Download invoice as PDF'
  }
}

export const voidBuilder: MenuItemBuilder<Invoice> = (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'DRAFT' || invoice.status === 'FINAL'
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'voidInvoiceModal',
          data: {
            id: invoice.id,
            customerName: invoice.customerLegalCompanyName
          }
        })
      ),
    label: <Box color={'red.70'}>Void Invoice</Box>
  }
}

const paidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'PAID'
          }
        })
      )
        .then(() =>
          toast({
            id: `paid${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as paid`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('PAID')
  }
}

const partiallyPaidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'PARTIALLY_PAID'
          }
        })
      )
        .then(() =>
          toast({
            id: `partially_paid${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as partially paid`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('PARTIALLY_PAID')
  }
}

const uncollectibleBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'UNCOLLECTIBLE'
          }
        })
      )
        .then(() =>
          toast({
            id: `uncollectible${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as uncollectible`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('UNCOLLECTIBLE')
  }
}

const unpaidBuilder: (
  successToast: (options: UseToastOptions) => void
) => MenuItemBuilder<Invoice> = toast => (invoice: Invoice) => {
  return {
    status:
      invoice.status === 'SENT' && invoice.linkedServices.length === 0
        ? 'LIVE'
        : 'DISABLED',
    action: ({ dispatch }) => {
      dispatch(
        putInvoicesByIdPaymentStatus.initiate({
          id: invoice.id,
          updateInvoicePaymentStatusEndpointRequestModel: {
            paymentStatus: 'UNPAID'
          }
        })
      )
        .then(() =>
          toast({
            id: `unpaid${invoice.invoiceNumber || ''}`,
            position: 'bottom',
            isClosable: true,
            render: () => (
              <Toast
                type="success"
                title={`Invoice ${
                  invoice.invoiceNumber || ''
                } is marked as unpaid`}
                isClosable={true}
              ></Toast>
            )
          })
        )
        .catch(e => Sentry.captureException(e))
    },
    label: invoicePaymentStatusMenuLabel('UNPAID')
  }
}

export const menuItemBuilders: (
  successToast: (options: UseToastOptions) => void,
  downloadPdf: (props: DownloadInvoicePdfProps) => void
) => MenuItemBuilder<Invoice>[] = (toast, download) => [
  previewBuilder,
  finalizeAndSendBuilder,
  finalizeBuilder,
  sendBuilder,
  voidBuilder,
  paidBuilder(toast),
  partiallyPaidBuilder(toast),
  uncollectibleBuilder(toast),
  unpaidBuilder(toast),
  downloadBuilder(download),
  archiveInvoiceBuilder
]

const invoicePaymentStatusMenuLabel = (status: InvoicePaymentStatus) => (
  <Flex direction={'row'} lineHeight="28px">
    Mark as <Box w="1"></Box>{' '}
    <Badge {...toInvoicePaymentStatusBadgeProps({ status })} />
  </Flex>
)
