import { useCallback } from 'react'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'

type UseLoadVariantPrices = () => (props: {
  productId: string
}) => Promise<
  Dashboardv20240509Api.GetProductMetadata.ProductMetadata['priceVariants']
>

export const useLoadVariantPrices: UseLoadVariantPrices = () => {
  const load = useCallback(async ({ productId }: { productId: string }) => {
    const variantPrices =
      (await dashboardv20240509Client.getProductMetadata({ id: productId }))
        .data?.priceVariants ?? []

    return variantPrices
  }, [])

  return load
}
