import { Box, Link, Text } from '@chakra-ui/react'
import { GreyGrey90 } from '@sequencehq/design-tokens'
import { TextField, SelectField } from '@sequencehq/core-components'
import { useCommonFieldsForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/CommonFields/useCommonFieldsForm'
import { PricingModel } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const CommonFieldsForm = () => {
  const { fieldsConfig } = useCommonFieldsForm()

  return (
    <Box width="100%" data-testid="common-fields" marginBottom="16px">
      {!fieldsConfig.pricingModel.hidden && (
        <>
          <Text
            color={GreyGrey90}
            fontWeight="bold"
            fontSize="l"
            textColor="##1D2939"
            mb="6"
          >
            Pricing Information
          </Text>
          <SelectField
            label="Pricing model"
            data-testid="common-field-pricingModel"
            value={fieldsConfig.pricingModel.value}
            onChange={newPricingModel =>
              fieldsConfig.pricingModel.onChange(
                newPricingModel as PricingModel
              )
            }
            options={fieldsConfig.pricingModel.options}
            validationErrors={fieldsConfig.pricingModel.validationErrors}
            isDisabled={fieldsConfig.pricingModel.disabled}
            placeholder="Select pricing model"
            description={
              fieldsConfig.pricingModel.description ? (
                <>
                  {fieldsConfig.pricingModel.description}{' '}
                  <Link
                    href="https://docs.sequencehq.com/plans-products/pricing-models"
                    isExternal
                    color="indigo.50"
                  >
                    Learn more
                  </Link>
                </>
              ) : null
            }
          />
        </>
      )}

      {!fieldsConfig.listPriceId.hidden && (
        <SelectField
          label="List price"
          data-testid="common-field-listPriceId"
          value={fieldsConfig.listPriceId.value}
          onChange={fieldsConfig.listPriceId.onChange}
          options={fieldsConfig.listPriceId.options}
          validationErrors={fieldsConfig.listPriceId.validationErrors}
          isDisabled={fieldsConfig.listPriceId.disabled}
          placeholder="Select list price"
        />
      )}

      {!fieldsConfig.name.hidden && (
        <TextField
          label="Display name (appears on invoice)"
          data-testid="common.name"
          value={fieldsConfig.name.value}
          onChange={fieldsConfig.name.onChange}
          validationErrors={fieldsConfig.name.validationErrors}
          isDisabled={fieldsConfig.name.disabled}
          placeholder="Enter a display name"
        />
      )}

      {!fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="common-field-currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}
    </Box>
  )
}
