import { FC } from 'react'
import { Inspector } from '@sequencehq/core-components'
import { DocumentIcon } from '@heroicons/react/16/solid'
import { GreyGrey70 } from '@sequencehq/design-tokens'
import { BillingScheduleAttachments } from './BillingScheduleAttachments'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const AttachmentsInspectorItem: FC = () => {
  const flags = useFlags()

  if (!flags.useAttachments) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="attachments.inspector.item"
      tooltip="Attachments"
      content={<BillingScheduleAttachments />}
      icon={<DocumentIcon height="16px" width="16px" color={GreyGrey70} />}
    />
  )
}
