import { useRef } from 'react'

import { Flex, Input, Text, useOutsideClick } from '@chakra-ui/react'
import { XMarkIcon } from '@heroicons/react/16/solid'

import { Button } from '@sequencehq/core-components'
import { GreyGrey60, GreyGrey80, Lato13Bold } from '@sequencehq/design-tokens'
import { usePopoverPublishedQuote } from './usePopoverPublishedQuote'

type Props = {
  onClose: () => void
}

export const PopoverPublishedQuote = ({ onClose }: Props) => {
  const refWrapper = useRef<HTMLDivElement | null>(null)
  useOutsideClick({ ref: refWrapper, handler: () => onClose() })

  const { linkUrl, buttonCopy, buttonClose } = usePopoverPublishedQuote({
    onClose
  })

  return (
    <Flex
      ref={refWrapper}
      flexDirection="column"
      padding="12px"
      width="347px"
      gap="8px"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text {...Lato13Bold} color={GreyGrey80}>
          Published quote
        </Text>

        <Button variant="unstyled" size="20px" onClick={buttonClose.onClick}>
          <XMarkIcon width="16px" height="16px" color={GreyGrey80} />
        </Button>
      </Flex>

      <Flex gap="8px">
        <Input
          variant="component-library"
          value={linkUrl}
          color={GreyGrey60}
          textOverflow="ellipsis"
        />
        <Button variant="primary" onClick={buttonCopy.onClick}>
          {buttonCopy.text}
        </Button>
      </Flex>
    </Flex>
  )
}
