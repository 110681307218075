import { Attachments } from 'modules/Assets/Attachments'
import { isEqual } from 'lodash'
import { ServerAsset } from '@sequencehq/api/dashboard/v20240730'
import {
  useSelectBillingScheduleAttachments,
  useShouldDisableModifyBillingScheduleAttachments,
  useStoreBillingScheduleAttachments
} from 'Cube/domain/subdomain/attachments'

export const BillingScheduleAttachments = () => {
  const attachments = useSelectBillingScheduleAttachments()
  const storeAttachments = useStoreBillingScheduleAttachments()
  const disableModifyAttachments =
    useShouldDisableModifyBillingScheduleAttachments()

  const handleAttachmentChange = (assets: ServerAsset[]) => {
    if (
      !isEqual(
        assets.map(({ id }) => id),
        attachments.map(({ id }) => id)
      )
    ) {
      storeAttachments(assets)
    }
  }

  return (
    <Attachments
      initialAttachments={attachments}
      onChange={handleAttachmentChange}
      disableModifyAttachments={disableModifyAttachments}
    />
  )
}
