import {
  AddProductFormState,
  UpdateFormModeAction
} from 'Products/drawers/AddProduct/domain/addProduct.type'

export const updateFormMode = (
  prevState: AddProductFormState,
  action: UpdateFormModeAction
): AddProductFormState => {
  return {
    ...prevState,
    mode: action.payload
  }
}
