import { Grid, GridItem } from '@chakra-ui/react'
import { GreyGrey30, GreyWhite } from '@sequencehq/design-tokens'
import { PriceForm } from 'modules/Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/PriceForm'
import { EditorFooter } from 'modules/Products/drawers/ListPriceEditor/components/EditorFooter/EditorFooter'
import { EditorHeader } from 'modules/Products/drawers/ListPriceEditor/components/EditorHeader/EditorHeader'
import { EditorBreadcrumb } from 'modules/Products/drawers/ListPriceEditor/components/EditorBreadcrumb/EditorBreadcrumb'
import { useListPriceEditorContext } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'
import { ListPriceEditorMode } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { ProductPriceContent } from 'modules/Products/drawers/ProductPrice/components/ProductPriceContent'
import { internalPriceToListPrice } from 'modules/Products/drawers/ListPriceEditor/adapters/listPriceEditor.adapters'

export const ListPriceForm = () => {
  const editorContext = useListPriceEditorContext()
  const showBreadcrumb =
    editorContext.configuration.mode !== ListPriceEditorMode.VIEW
  const showForm =
    editorContext.configuration.mode === ListPriceEditorMode.CREATE ||
    editorContext.configuration.mode === ListPriceEditorMode.EDIT

  return (
    <Grid
      templateAreas={`
         "prices editor"
      `}
      templateColumns="0 550px"
      templateRows={'100%'}
      width="fit-content"
      height="100%"
      position="relative"
      borderRadius="8px"
    >
      <Grid
        gridArea="editor"
        backgroundColor={GreyWhite}
        templateAreas={'"header" "breadcrumb" "editor" "footer"'}
        templateRows={`56px ${showBreadcrumb ? '56px' : '0px'} 1fr 56px`}
        position="relative"
        boxShadow="0px 16px 24px 0px rgba(20, 23, 28, 0.08)"
        borderRadius="8px"
      >
        <GridItem
          gridArea="header"
          position="sticky"
          top="0"
          left="0"
          borderTopRightRadius="8px"
          borderTopLeftRadius="8px"
          overflow="hidden"
        >
          <EditorHeader />
        </GridItem>
        {showBreadcrumb && (
          <GridItem
            gridArea="breadcrumb"
            borderBottom={`1px solid ${GreyGrey30}`}
            overflow="hidden"
            position="sticky"
            top="56px"
            left="0"
            zIndex="1"
          >
            <EditorBreadcrumb />
          </GridItem>
        )}
        <GridItem gridArea="editor" overflow="auto">
          {showForm ? (
            <PriceForm />
          ) : (
            editorContext.derived.queries.selectedPrice && (
              <ProductPriceContent
                fetchMetadata={false}
                price={internalPriceToListPrice(editorContext.data.product.id)(
                  editorContext.derived.queries.selectedPrice
                )}
              />
            )
          )}
        </GridItem>
        <GridItem
          gridArea="footer"
          position="sticky"
          bottom="0"
          left="0"
          backgroundColor={GreyWhite}
          borderTop={`1px solid ${GreyGrey30}`}
          zIndex="2"
        >
          <EditorFooter />
        </GridItem>
      </Grid>
    </Grid>
  )
}
