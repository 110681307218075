import integratedApiWithTags from 'features/api'
import { useDispatch } from 'features/store'
import { useCallback } from 'react'

export const useNewAddProductOutletConnector = () => {
  const dispatch = useDispatch()

  const onSuccess = useCallback(
    (newData: { price: any; product?: any }) => {
      if (newData.product) {
        /**
         * Sadly while rtk-query is still in our lives, we have to trigger a cache invalidation for the products table manually,
         * so when a product is added via the pricing editor, rtk-query re-fetches and adds our new product to the table.
         *
         * When rtk-query is replaced on the products table, this will instead need to push the new product into
         * the equivalent reducer/other tracked state.
         * */
        dispatch(integratedApiWithTags.util.invalidateTags(['Products']))
      }
    },
    [dispatch]
  )

  return {
    onSuccess
  }
}
