import { MenuItem } from '@sequencehq/core-components'
import { GreyWhite, IndigoIndigo50 } from '@sequencehq/design-tokens'

export const kebabMenuItems = (args: {
  includePercentageLimits: boolean
  onChange: (newValue: boolean) => void
}) => {
  const text = args.includePercentageLimits
    ? 'Remove fee thresholds'
    : 'Add fee thresholds'

  return [
    {
      status: 'LIVE',
      label: text,
      action: () => {
        args.onChange(!args.includePercentageLimits)
      },
      styles: {
        padding: '8px',
        borderRadius: '8px',
        _hover: {
          backgroundColor: IndigoIndigo50,
          color: GreyWhite
        },
        cursor: 'pointer'
      }
    } as MenuItem
  ]
}
