import { required } from '@sequencehq/validation'
import {
  CubeReducerState,
  ValidationResults
} from 'modules/Cube/domain/cube.domain.types'
import { adaptValidationFromLib } from 'modules/Cube/domain/postActionStages/validation/util/adaptValidatorFromLib'
import { atLeastOnePhase } from 'modules/Cube/domain/postActionStages/validation/validators/atLeastOnePhase'
import { atLeastOnePriceInPhase } from 'modules/Cube/domain/postActionStages/validation/validators/phases/atLeastOnePriceInPhase'
import { noIncompatibleDiscountProration } from 'modules/Cube/domain/postActionStages/validation/validators/phases/noIncompatibleDiscountProration'
import { noIncompatibleMinimumProration } from 'modules/Cube/domain/postActionStages/validation/validators/phases/noIncompatibleMinimumProration'
import { noIncompatiblePriceProration } from 'modules/Cube/domain/postActionStages/validation/validators/phases/noIncompatiblePriceProration'
import { nonFinalPhaseHasDuration } from 'modules/Cube/domain/postActionStages/validation/validators/phases/nonFinalPhaseHasDuration'
import { taxRateRequired } from 'modules/Cube/domain/postActionStages/validation/validators/schedule/taxRateRequired'

type PublishScheduleValidation = (
  prevState: CubeReducerState
) => ValidationResults

export const publishScheduleValidation: PublishScheduleValidation =
  prevState => {
    return {
      common: {
        currency: null,
        customerId: adaptValidationFromLib(
          required(prevState.data.common.customerId)
        ),
        phaseIds: atLeastOnePhase(prevState),
        phases: prevState.queries.orderedPhases.reduce(
          (acc, resolvedPhase) => ({
            ...acc,
            [resolvedPhase.id]: {
              prices: [
                atLeastOnePriceInPhase({ phaseId: resolvedPhase.id })(
                  prevState
                ),
                noIncompatiblePriceProration({ phaseId: resolvedPhase.id })(
                  prevState
                ),
                noIncompatibleDiscountProration({ phaseId: resolvedPhase.id })(
                  prevState
                ),
                noIncompatibleMinimumProration({ phaseId: resolvedPhase.id })(
                  prevState
                )
              ]
                .filter(Boolean)
                .flatMap(validationResult => validationResult),
              duration: nonFinalPhaseHasDuration({ phaseId: resolvedPhase.id })(
                prevState
              )
            }
          }),
          {}
        ),
        startDate: adaptValidationFromLib(
          required(prevState.data.common.startDate)
        ),
        title: null
      },
      schedule: {
        recurrenceDayOfMonth: adaptValidationFromLib(
          required(prevState.data.schedule.recurrenceDayOfMonth)
        ),
        taxRate: taxRateRequired(prevState)
      },
      quote: {
        dealType: null,
        expiry: null,
        presentation: null,
        quoteNumber: null,
        contactIds: null
      }
    }
  }
