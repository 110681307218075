import { Link } from 'react-router-dom'

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'
import { EyeIcon } from '@heroicons/react/16/solid'

import {
  GreyGrey30,
  GreyGrey60,
  GreyGrey80,
  GreyWhite,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { Badge, PopoverWrapper, Tooltip } from '@sequencehq/core-components'

import { ConfirmAcceptQuoteModal } from 'modules/Cube/view/layouts/quote/QuoteEditorHeader/modals/AcceptQuoteModal'
import { ConfirmExecuteQuoteModal } from 'modules/Cube/view/layouts/quote/QuoteEditorHeader/modals/ExecuteQuoteModal'
import { ConfirmPublishQuoteModal } from 'modules/Cube/view/layouts/quote/QuoteEditorHeader/modals/PublishQuoteModal'
import { useQuoteEditorHeader } from 'modules/Cube/view/layouts/quote/QuoteEditorHeader/useQuoteEditorHeader'
import { QuoteEditorKebabMenu } from './QuoteEditorKebabMenu/QuoteEditorKebabMenu'
import { QuoteEditorHeaderSaveStatus } from './SaveStatus'
import { PopoverPublishedQuote } from './PopoverPublishedQuote/PopoverPublishedQuote'
import { DisabledReasonType } from 'Cube/domain/cube.domain.types'

const styles = {
  title: {
    ...Lato14Bold,
    color: GreyGrey80
  },
  archivedTitle: {
    ...Lato14Bold,
    color: GreyGrey60
  }
}

export const QuoteEditorHeader = () => {
  const headerHook = useQuoteEditorHeader()

  return (
    <>
      <Grid
        gridTemplateColumns="1fr auto"
        width="100%"
        height="48px"
        alignItems="center"
        borderBottom={`1px solid ${GreyGrey30}`}
        backgroundColor={GreyWhite}
        padding="0 16px"
        position="relative"
      >
        <Box>
          <QuoteEditorHeaderSaveStatus isSaving={headerHook.syncing} />
        </Box>

        <Flex
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          gap="4px"
          alignItems="center"
        >
          <Text {...Lato14Regular} color={GreyGrey60}>
            <Text as={Link} color="inherit" to="/quotes">
              Quotes
            </Text>{' '}
            /{' '}
            <Text
              as="span"
              style={
                headerHook.isArchived ? styles.archivedTitle : styles.title
              }
            >
              {headerHook.title}
            </Text>
          </Text>

          {headerHook.badgeUnpublishedChanges.visible && (
            <Badge sentiment="highlight" size="md" textTransform="none">
              Unpublished changes
            </Badge>
          )}
          {headerHook.badgeIsExpired.visible && (
            <Badge sentiment="error" size="md" textTransform="none">
              Expired
            </Badge>
          )}
        </Flex>

        <Flex gap="8px">
          {headerHook.features.preview.visible && (
            <Button
              variant="component-library-secondary"
              onClick={headerHook.features.preview.onClick}
              isDisabled={headerHook.features.preview.disabled}
              leftIcon={<EyeIcon width="16px" height="16px" color="inherit" />}
              iconSpacing="4px"
            >
              Preview
            </Button>
          )}
          {headerHook.features.publish.visible && (
            <PopoverWrapper
              popover={
                <PopoverPublishedQuote
                  onClose={headerHook.publishedLinkPopover.onClose}
                />
              }
              isOpen={headerHook.publishedLinkPopover.isOpen}
              placement="bottom-end"
            >
              <Button
                variant="component-library-primary"
                onClick={headerHook.features.publish.onClick}
                isDisabled={headerHook.features.publish.disabled}
              >
                {headerHook.features.publish.text}
              </Button>
            </PopoverWrapper>
          )}
          {headerHook.features.accept.visible && (
            <Tooltip
              isDisabled={headerHook.features.accept.reasons.length === 0}
              label={
                headerHook.features.accept.reasons.find(
                  reason =>
                    reason.reasonType ===
                    DisabledReasonType.CantChangeWhenExpired
                )
                  ? `Can't accept an expired quote`
                  : `Can't accept this quote`
              }
            >
              <Button
                variant="component-library-primary"
                onClick={headerHook.features.accept.onClick}
                isDisabled={headerHook.features.accept.disabled}
              >
                Accept
              </Button>
            </Tooltip>
          )}
          {headerHook.features.execute.visible && (
            <Button
              variant="component-library-primary"
              onClick={headerHook.features.execute.onClick}
              isDisabled={headerHook.features.execute.disabled}
            >
              Create billing schedule
            </Button>
          )}

          <QuoteEditorKebabMenu />
        </Flex>
      </Grid>

      {headerHook.modals.publish.active && (
        <ConfirmPublishQuoteModal
          submitting={headerHook.modals.publish.submitting}
          onClose={headerHook.modals.publish.onClose}
          onConfirm={headerHook.modals.publish.onConfirm}
          hasBeenPublished={headerHook.hasBeenPublished}
          isReadyToSign={headerHook.isReadyToSign}
          recipientCountText={headerHook.modals.publish.recipientCountText}
          republishingWithoutChanges={
            !headerHook.badgeUnpublishedChanges.visible &&
            headerHook.badgeIsExpired.visible
          }
        />
      )}
      {headerHook.modals.accept.active && (
        <ConfirmAcceptQuoteModal
          submitting={headerHook.modals.accept.submitting}
          onClose={headerHook.modals.accept.onClose}
          onConfirm={headerHook.modals.accept.onConfirm}
        />
      )}
      {headerHook.modals.execute.active && (
        <ConfirmExecuteQuoteModal
          submitting={headerHook.modals.execute.submitting}
          onClose={headerHook.modals.execute.onClose}
          onConfirm={headerHook.modals.execute.onConfirm}
        />
      )}
    </>
  )
}
