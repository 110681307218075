import { useCallback, useEffect, useMemo, useState } from 'react'
import { Integration } from 'components/Customers/types'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'

export const useFetchIntegrations = () => {
  const [integrations, setIntegrations] = useState<Integration[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const fetchIntegrations = useCallback(async () => {
    setLoading(true)
    try {
      const integrationsData = await dashboardv20240509Client.getIntegrations()

      setError(integrationsData.error)
      if (integrationsData.data) {
        setIntegrations(integrationsData.data.items)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const connectedIntegrations = useMemo(
    () =>
      integrations?.filter(
        integration => integration.enabled && integration.state === 'CONNECTED'
      ),
    [integrations]
  )

  useEffect(() => {
    void fetchIntegrations()
  }, [fetchIntegrations])

  return {
    integrations,
    connectedIntegrations,
    loading,
    error,
    refetch: fetchIntegrations
  }
}
