import { Grid, GridItem, Image } from '@chakra-ui/react'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/16/solid'
import { Badge } from '@sequencehq/core-components'
import {
  GreenGreen80,
  GreyGrey20,
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold,
  YellowYellow60
} from '@sequencehq/design-tokens'
import { ResolvedIntegration } from 'modules/Integrations/domain'

export const IntegrationGroupListItem = (
  props: ResolvedIntegration & {
    onClick: () => void
    selected: boolean
  }
) => {
  return (
    <Grid
      padding="0 4px 0 8px"
      templateColumns="auto 1fr auto"
      height="32px"
      gap="8px"
      borderRadius="6px"
      alignContent="center"
      cursor="pointer"
      alignItems="center"
      backgroundColor={props.selected ? GreyGrey20 : 'transparent'}
      _hover={{
        backgroundColor: GreyGrey20
      }}
      userSelect="none"
      onClick={props.onClick}
    >
      <GridItem>
        {props.smallLogoUrl ? (
          <Image height="16px" width="16px" src={props.smallLogoUrl} />
        ) : (
          <ExclamationTriangleIcon
            height="16px"
            width="16px"
            color={YellowYellow60}
          />
        )}
      </GridItem>
      <GridItem
        {...Lato13Bold}
        color={props.selected ? GreyGrey90 : GreyGrey70}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {props.title}
      </GridItem>
      <GridItem display="flex" alignItems="center">
        {props.state === 'CONNECTED' && (
          <Badge
            sentiment="success"
            containerStyle={
              props.selected ? { backgroundColor: GreyGrey30 } : {}
            }
          >
            <CheckCircleIcon
              height="16px"
              width="16px"
              color={props.selected ? GreyGrey70 : GreenGreen80}
            />
          </Badge>
        )}
      </GridItem>
    </Grid>
  )
}
