import { SimplePaymentPage } from 'modules/PaymentsApp/components/SimplePaymentPage'
import { AlertTriangle } from 'react-feather'

export const Error = () => {
  return (
    <SimplePaymentPage
      icon={AlertTriangle}
      iconColour="red.60"
      header="Payment error"
      subheader="An unexpected error occurred while initiating the payment process."
      nextSteps={[
        'If you wish to proceed with the payment please try again or contact the issuer for further assistance.'
      ]}
    />
  )
}
