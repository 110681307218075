import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'

export type TaxCategory = DashboardApi20240730.GetTaxCategories.TaxCategory

type LoadTaxCategories = () => Promise<
  | {
      error: boolean
      data: null
    }
  | {
      error: boolean
      data: TaxCategory[]
    }
>

export const loadTaxCategories: LoadTaxCategories = async () => {
  const result = await dashboard20240730Client.getTaxCategories()

  if (result.error || !result.data) {
    return {
      error: true,
      data: null
    }
  }

  return {
    error: false,
    data: result.data.items
  }
}
