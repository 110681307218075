import { Button, Flex } from '@chakra-ui/react'
import { GreyGrey10 } from '@sequencehq/design-tokens'
import { PhaseHookInterface } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/usePhase'
import { FC } from 'react'
import ReceiptPercentIcon from '@heroicons/react/16/solid/ReceiptPercentIcon'
import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import { Tooltip } from '@sequencehq/core-components'
import Square3Stack3DIcon from '@heroicons/react/16/solid/Square3Stack3DIcon'

export const PhaseActions: FC<{ phasesHook: PhaseHookInterface }> = ({
  phasesHook
}) => {
  return (
    <Flex
      justifyContent="space-between"
      bg={GreyGrey10}
      borderRadius="6px"
      padding="8px"
      gap="12px"
    >
      <Tooltip label={phasesHook.features.addProduct.reason} placement="top">
        <Button
          variant="component-library-secondary"
          isDisabled={phasesHook.features.addProduct.disabled}
          onClick={phasesHook.features.addProduct.handler}
          leftIcon={<PlusIcon width="16px" height="16px" color="inherit" />}
          iconSpacing="4px"
          data-testid="phase.addProduct"
        >
          Add product
        </Button>
      </Tooltip>
      <Flex gap="8px">
        <Tooltip label={phasesHook.features.addDiscount.reason} placement="top">
          <Button
            variant="component-library-secondary"
            isDisabled={phasesHook.features.addDiscount.disabled}
            onClick={phasesHook.features.addDiscount.handler}
            leftIcon={
              <ReceiptPercentIcon width="16px" height="16px" color="inherit" />
            }
            iconSpacing="4px"
          >
            Add discount
          </Button>
        </Tooltip>
        <Tooltip label={phasesHook.features.addMinimum.reason} placement="top">
          <Button
            variant="component-library-secondary"
            isDisabled={phasesHook.features.addMinimum.disabled}
            onClick={phasesHook.features.addMinimum.handler}
            leftIcon={
              <Square3Stack3DIcon width="16px" height="16px" color="inherit" />
            }
            iconSpacing="4px"
          >
            Add minimum
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  )
}
