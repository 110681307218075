import { Flex, Text } from '@chakra-ui/react'
import { HashtagIcon, IdentificationIcon } from '@heroicons/react/16/solid'
import {
  customerAliasOptions,
  SeatBalanceModel,
  SeatTypeBalanceModel
} from '@sequencehq/core-models'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import EmptyState, { EmptyStateContent } from 'components/Loading/EmptyState'
import { SeatBalancesViewSelector } from 'modules/Seats/SeatBalances/SeatBalancesViewSelector'
import { SeatBalancesView } from 'modules/Seats/SeatBalances/types'
import { useGetCustomersByAliasByAliasQuery } from 'features/api'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { ExtractQueryParams } from 'lib/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Badge } from '@sequencehq/core-components'
import { useCustomerNamesByAlias } from 'components/UsageEvents/useCustomerNamesByAlias.tsx'
import Spinner from 'components/Loading'

type SeatBalancesMagicTableProps = {
  emptyContent: EmptyStateContent
}

export const SeatBalancesMagicTable = ({
  emptyContent
}: SeatBalancesMagicTableProps) => {
  const { fetchCustomerAliasOptions } = useGetMagicTableFilterOptions()

  const { infiniteQuery, setQueryParams } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getSeatBalances
  )

  const {
    isLoading: isLoadingAliases,
    aliases,
    customersWithAliases
  } = useCustomerNamesByAlias()

  if (isLoadingAliases || !customersWithAliases || !aliases) {
    return <Spinner></Spinner>
  }

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<SeatBalancesView>
          entityNamePlural="seats"
          sequenceUserId={userId}
          infiniteQuery={infiniteQuery}
          onQueryParamsChanged={setQueryParams}
          getSubRows={row => {
            return (
              row?.balances?.map(balance => {
                return {
                  ...balance,
                  id: '',
                  sequenceAccountId: '',
                  legalName: '',
                  customerAliases: [],
                  balances: []
                }
              }) ?? []
            )
          }}
          columns={[
            {
              id: 'customerAliases',
              accessorKey: 'customerAliases',
              header: 'Customer',
              cell: value => {
                return (
                  <MagicTableAutoLoader<
                    ExtractQueryParams<
                      typeof useGetCustomersByAliasByAliasQuery
                    >,
                    { customerId?: string }
                  >
                    queryParams={{
                      alias:
                        value.getValue<SeatBalanceModel['customerAliases']>()[0]
                    }}
                    useQuery={useGetCustomersByAliasByAliasQuery}
                    extract={customer => ({
                      customerId: customer?.id
                    })}
                  >
                    {({ customerId }) => {
                      if (customerId) {
                        return (
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            height="40px"
                            pl="6px"
                          >
                            <CustomerPreviewCardPill
                              customerId={customerId}
                              variant="ghost"
                            />
                          </Flex>
                        )
                      }

                      return (
                        <MagicTableCell text={value.row.original.legalName} />
                      )
                    }}
                  </MagicTableAutoLoader>
                )
              }
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat metric',
              cell: value => {
                const isSubRow = value.row.depth > 0
                const totalSubRows = value.row.subRows.length
                const hasSubRows = totalSubRows > 0

                if (!hasSubRows && !isSubRow) {
                  return (
                    <MagicTableCell
                      text={value.getValue<SeatTypeBalanceModel['seatType']>()}
                    />
                  )
                }

                if (hasSubRows && !isSubRow) {
                  return (
                    <MagicTableCell
                      text={`${totalSubRows} seat ${
                        totalSubRows > 1 ? 'metrics' : 'metric'
                      }`}
                      textColor={GreyGrey60}
                    />
                  )
                }
                return (
                  <MagicTableCell
                    text={value.getValue<SeatTypeBalanceModel['seatType']>()}
                  />
                )
              }
            },
            {
              id: 'total',
              accessorKey: 'total',
              header: 'Balance',
              cell: value => (
                <MagicTableCell
                  text={String(value.getValue<SeatBalanceModel['total']>())}
                />
              )
            },
            {
              id: 'changeTimestamp',
              accessorKey: 'changeTimestamp',
              header: 'Last Updated (UTC)',
              cell: value => {
                const changeTimestamp =
                  value.getValue<SeatBalanceModel['changeTimestamp']>()

                return (
                  <MagicTableCell
                    text={dateTimeWithFormat(
                      changeTimestamp,
                      'dd-MM-yyyy ∙ HH:mm:ss'
                    )}
                  />
                )
              }
            }
          ]}
          emptyState={props => (
            <EmptyState emptyContent={emptyContent} {...props} />
          )}
          additionalToolbarComponents={
            <SeatBalancesViewSelector active="balances" />
          }
          filters={[
            {
              type: 'multiSelectAliased',
              paramName: 'customerName',
              paramNameAliasOf: 'customerAliases',
              options: customersWithAliases.map(customer => ({
                label: customer.customerName,
                value: customer.aliases.join(',')
              })),
              format: value => (
                <Text>
                  {
                    customersWithAliases.find(
                      customer => customer.aliases.join(',') === value
                    )?.customerName
                  }
                </Text>
              ),
              label: 'Customer',
              icon: IdentificationIcon
            }
          ]}
        />
      )}
    </CurrentUserId>
  )
}
