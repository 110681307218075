import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { PriceModel } from '@sequencehq/core-models'
import { useCallback } from 'react'

export type v1ApiPrice = Dashboardv20240509Api.GetPrice.Price

type UseLoadPrices = () => (props: {
  priceIds: Array<v1ApiPrice['id']>
}) => Promise<{
  prices: v1ApiPrice[]
}>

export const useLoadPrices: UseLoadPrices = () => {
  const load = useCallback(
    async ({ priceIds }: { priceIds: Array<PriceModel['id']> }) => {
      const prices = (
        await Promise.all(
          priceIds.map(priceId =>
            dashboardv20240509Client.getPrice({ id: priceId })
          )
        )
      )
        .map(res => res.data)
        .filter((price): price is v1ApiPrice => Boolean(price))

      return {
        prices
      }
    },
    []
  )

  return load
}
