import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useProductPriceOutletConnector = () => {
  const navigate = useNavigate()
  const {
    data: { price }
  } = useProductContext()

  const onClose = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    onClose,
    price
  }
}
