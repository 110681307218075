import { DocumentIcon } from '@heroicons/react/16/solid'
import { Inspector } from '@sequencehq/core-components'
import { GreyGrey70 } from '@sequencehq/design-tokens'
import { FC } from 'react'
import { QuoteAttachments } from './QuoteAttachments'

export const AttachmentsInspectorItem: FC = () => {
  return (
    <Inspector.IconItem
      tooltip="Attachments"
      content={<QuoteAttachments />}
      icon={<DocumentIcon height="16px" width="16px" color={GreyGrey70} />}
    />
  )
}
