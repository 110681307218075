import { DeleteApiKeyForm } from 'components/DeleteApiKey'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { useDeleteOauthClientsByIdMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'

export interface DeleteApiKeyFormProps {
  id: string
  name: string
}

export type DeleteApiKeyFormMutation = ExtractMutationParams<
  typeof useDeleteOauthClientsByIdMutation
>

export const DeleteApiKeyModal = createFormModal<
  DeleteApiKeyFormMutation,
  DeleteApiKeyFormProps
>({
  FormComponent: DeleteApiKeyForm,
  useMutation: useDeleteOauthClientsByIdMutation,
  modalTitleFromProps: () => 'Delete API key',
  successMessage: 'API key deleted',
  submitTitle: 'Delete',
  formId: 'deleteApiKeyForm',
  successToast: (response, inputs) => (
    <Toast
      title="API key deleted"
      type="success"
      description={`API key ${inputs.name} deleted`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})
