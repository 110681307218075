import { Flex, Td, Tr } from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey70,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { flexRender, Row } from '@tanstack/react-table'

interface MemberRowProps<T extends object> {
  row: Row<T>
}

export const MemberRow = <T extends object>({ row }: MemberRowProps<T>) => {
  return (
    <Tr {...Lato14Regular} color={GreyGrey70}>
      {row.getVisibleCells().map(cell => (
        <Td
          key={cell.id}
          p="8px 12px"
          _first={{
            paddingLeft: '24px'
          }}
          _last={{
            textAlign: 'right'
          }}
          height="40px"
          borderBottom={`1px solid ${GreyGrey30}`}
          width={`${cell.column.getSize()}px`}
        >
          <Flex alignItems="center">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Flex>
        </Td>
      ))}
    </Tr>
  )
}
