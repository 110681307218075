import { Flex, Text } from '@chakra-ui/react'
import { IdentificationIcon } from '@heroicons/react/16/solid'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { KebabMenu } from '@sequencehq/core-components'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellBasic,
  MagicTableCellQuoteStatus,
  MagicTableFilterConfig,
  MagicTable,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import EmptyState, { EmptyStateContent } from 'components/Loading/EmptyState'
import { useDuplicateQuote } from 'components/Quotes/hooks/useDuplicateQuote'
import { menuItemBuilders } from 'components/Quotes/menuItemBuilders'
import { QuoteView, QuotesFilters } from 'components/Quotes/types'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { useGetUsersByIdQuery } from 'features/api'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { ExtractQueryParams } from 'lib/types'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const contractLengthOptions = [
  {
    label: '6 months',
    value: '180'
  },
  {
    label: '1 year',
    value: '365'
  },
  {
    label: '2 years',
    value: '730'
  },
  {
    label: '3 years',
    value: '1095'
  }
]

type QuotesMagicTableProps = {
  emptyContent: EmptyStateContent
}

const QuotesMagicTable = ({ emptyContent }: QuotesMagicTableProps) => {
  const flags = useFlags()
  const notifications = useNotifications()
  const navigate = useNavigate()
  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()

  const { infiniteQuery, setQueryParams } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getQuotes
  )

  const duplicate = useDuplicateQuote()

  const duplicateQuote = useCallback(
    (quote: ListQuoteModel) => {
      notifications.displayNotification('Duplicating quote...', {
        type: 'info'
      })

      duplicate(quote.id)
        .then(duplicatedQuote => {
          notifications.displayNotification('Quote duplicated', {
            type: 'success'
          })

          navigate(`/quotes/${duplicatedQuote.id}`)
        })
        .catch(() => {
          notifications.displayNotification('Unable to duplicate quote', {
            type: 'error'
          })
        })
    },
    [duplicate, navigate, notifications]
  )

  const filters: MagicTableFilterConfig<QuotesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options: [],
      optionsFunc: fetchCustomerNameOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      paramName: 'includeArchived',
      type: 'toggle',
      label: 'Show archived quotes'
    }
  ]

  type ListQuoteModel = DashboardApi20240730.GetQuotes.Quote

  return (
    <CurrentUserId>
      {userId => (
        <>
          <MagicTable<QuoteView>
            entityNamePlural="quotes"
            sequenceUserId={userId}
            infiniteQuery={infiniteQuery}
            onQueryParamsChanged={setQueryParams}
            rowPath={quote => `/quotes/${quote.id}`}
            columns={[
              {
                id: 'title',
                accessorFn: row => ({
                  title: row.title,
                  archivedAt: row.archivedAt
                }),
                header: 'Title',
                cell: value => {
                  const { archivedAt, title } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    title: ListQuoteModel['title']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={`${title || 'Untitled'}${
                        archivedAt ? ' (archived)' : ''
                      }`}
                    />
                  )
                }
              },
              {
                id: 'quoteNumber',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  quoteNumber: row.quoteNumber
                }),
                header: 'Number',
                cell: value => {
                  const { archivedAt, quoteNumber } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    quoteNumber: ListQuoteModel['quoteNumber']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={quoteNumber}
                    />
                  )
                }
              },
              {
                id: 'customer',
                accessorKey: 'customerId',
                header: 'Customer',
                hasLink: true,
                cell: value => {
                  const customerId =
                    value.getValue<ListQuoteModel['customerId']>()
                  return customerId ? (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      height="40px"
                      pl="6px"
                    >
                      <CustomerPreviewCardPill
                        customerId={customerId}
                        variant="ghost"
                      />
                    </Flex>
                  ) : (
                    <MagicTableCellBasic>
                      <Text padding="3px 6px">Unknown</Text>
                    </MagicTableCellBasic>
                  )
                }
              },
              {
                id: 'status',
                header: 'Status',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  publishedAt: row.publishedAt,
                  isExpired: row.isExpired,
                  status: row.status
                }),
                cell: value => {
                  const { archivedAt, status, publishedAt, isExpired } =
                    value.getValue<
                      Pick<
                        ListQuoteModel,
                        'archivedAt' | 'status' | 'publishedAt' | 'isExpired'
                      >
                    >()

                  return (
                    <MagicTableCellQuoteStatus
                      status={status}
                      isArchived={Boolean(archivedAt)}
                      isPublished={Boolean(publishedAt)}
                      isExpired={Boolean(isExpired)}
                    />
                  )
                }
              },
              {
                id: 'user',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  createdBy: row.createdBy
                }),
                header: 'Owner',
                cell: value => {
                  const { archivedAt, createdBy } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    createdBy: ListQuoteModel['createdBy']
                  }>()
                  return (
                    <MagicTableAutoLoader<
                      ExtractQueryParams<typeof useGetUsersByIdQuery>,
                      { email: string }
                    >
                      queryParams={{
                        id: createdBy
                      }}
                      useQuery={useGetUsersByIdQuery}
                      extract={user => ({
                        email: user?.email ?? '-'
                      })}
                    >
                      {({ email }) => (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={email}
                        />
                      )}
                    </MagicTableAutoLoader>
                  )
                }
              },
              {
                id: 'expiresAt',
                accessorFn: row => ({
                  expiresAt: row.expiresAt,
                  expiryDays: row.expiryDays,
                  archivedAt: row.archivedAt
                }),
                header: 'Expires',
                cell: value => {
                  const { expiresAt, expiryDays, archivedAt } = value.getValue<{
                    expiresAt: ListQuoteModel['expiresAt']
                    expiryDays: ListQuoteModel['expiryDays']
                    archivedAt: ListQuoteModel['archivedAt']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={
                        expiresAt
                          ? dateTimeWithFormat(expiresAt, 'd MMM yyyy')
                          : expiryDays
                            ? `${expiryDays} days after publishing`
                            : ''
                      }
                    />
                  )
                }
              }
            ]}
            kebabMenu={(model: ListQuoteModel, props) => (
              <KebabMenu
                isLazy
                menuItems={menuItemBuilders(quote => {
                  void duplicateQuote(quote)
                })
                  .map(builder => builder(model))
                  .filter(builder => builder.status === 'LIVE')}
                flags={flags}
                {...props}
              />
            )}
            emptyState={props => (
              <EmptyState emptyContent={emptyContent} {...props} />
            )}
            filters={filters}
          />
        </>
      )}
    </CurrentUserId>
  )
}

export default QuotesMagicTable
