import { Button } from '@sequencehq/core-components'
import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { IntegrationsBrowserModal } from 'Integrations/view/integrationsBrowserModal/IntegrationsBrowserModal'
import { ReactNode } from 'react'
import { AvailableServices } from 'Integrations/domain'

export const NotConnectedItemContent = (props: {
  service: AvailableServices
  serviceName: string
  serviceDescription: string
  'data-testid'?: string
  logo: ReactNode
}) => (
  <Flex
    width="320px"
    height="100%"
    overflow="auto"
    padding="16px"
    gap="16px"
    data-testid={props['data-testid']}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Box
      padding="6px"
      borderRadius="6px"
      backgroundColor={GreyGrey20}
      width="fit-content"
    >
      {props.logo}
    </Box>
    <Flex flexDirection="column" gap="4px" alignItems="center">
      <Box {...Lato13Bold} color={GreyGrey80}>
        {props.serviceName} is not connected
      </Box>
      <Box {...Lato13Regular} color={GreyGrey70} textAlign="center">
        {props.serviceDescription}
      </Box>
    </Flex>
    <IntegrationsBrowserModal
      trigger={
        <Button
          variant={'secondary'}
          data-testid={`notConnected.${props.service}.connectTo`}
        >
          Connect to {props.serviceName}
        </Button>
      }
      defaultService={props.service}
    />
  </Flex>
)
