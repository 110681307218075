import { Box } from '@chakra-ui/react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useMutation } from '@sequencehq/api/dist/utils'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import {
  LinkStripeCustomerWidget,
  StripeInspectorItem,
  StripePaymentWidget
} from 'Integrations/index'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { useNotifications } from 'lib/hooks/useNotifications'

const useInvoiceStripeInspectorItem = () => {
  const ctx = useInvoiceEditorContext()
  const notification = useNotifications()

  const createSettingsMutator = useMutation(
    dashboard20240730Client.postInvoiceSettings
  )
  const updateSettingsMutator = useMutation(
    dashboard20240730Client.putInvoiceSettings
  )

  const updateInvoiceSettings = async (isStripePaymentSelected: boolean) => {
    const res = await createSettingsMutator.mutateAsync({
      invoiceId: ctx.data.invoice.id,
      customerId: ctx.data.invoice.customerId,
      paymentProvider: isStripePaymentSelected ? 'STRIPE' : 'NONE'
    })
    return !!res
  }

  const isUpdating =
    createSettingsMutator.isPending || updateSettingsMutator.isPending

  return {
    customerId: ctx.data.invoice.customerId,
    stripePayments: {
      /**
       * The service does not handle the scenario of removing or updating the payment provider
       * from Stripe to 'NONE'. That needs to be fixed, but for now we just disable the switch
       * when enabled. This isn't a regression over the previous state, even if it is rather jank.
       * We also assume 'LINK' to always mean that Stripe is enabled, rather than using the
       * payment settings. Also kinda jank!
       */
      value: ctx.data.invoice.paymentOptions.includes('LINK'),
      isDisabled:
        isUpdating ||
        !ctx.derived.queries.availableFeatures
          .canUpdatePaymentCollectionMethod ||
        ctx.data.invoice.paymentOptions.includes('LINK'),
      onChange: async (isStripePaymentSelected: boolean) => {
        try {
          const success = await updateInvoiceSettings(isStripePaymentSelected)
          if (!success) {
            throw new Error('Could not update')
          }

          notification.displayNotification('Invoice Stripe settings updated', {
            type: 'success'
          })

          /**
           * Keep the local state of the invoice updated - this value should be
           * derived from the above settings when reloading the invoice afterwards.
           * In the future, we can make use of caching to avoid this syncing.
           */
          ctx.functions.updateData({
            invoice: {
              paymentOptions: isStripePaymentSelected
                ? [...ctx.data.invoice.paymentOptions, 'LINK']
                : ctx.data.invoice.paymentOptions.filter(p => p !== 'LINK')
            }
          })
        } catch (e) {
          notification.displayNotification(
            'Could not update invoice Stripe settings',
            {
              type: 'error'
            }
          )
        }
      }
    }
  }
}

export const InvoiceStripeInspectorItem = () => {
  const hook = useInvoiceStripeInspectorItem()

  return (
    <StripeInspectorItem>
      <LinkStripeCustomerWidget customerId={hook.customerId} />
      <Box paddingTop="16px" borderTop={`1px solid ${GreyGrey30}`}>
        <StripePaymentWidget
          fieldData={{
            acceptPaymentsViaStripe: {
              disabled: hook.stripePayments.isDisabled,
              value: hook.stripePayments.value
            }
          }}
          onChange={newData =>
            void hook.stripePayments.onChange(
              newData.acceptPaymentsViaStripe.value
            )
          }
        />
      </Box>
    </StripeInspectorItem>
  )
}
