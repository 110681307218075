import { CreateUsageMetricDrawerProps } from 'components/CreateUsageMetric/CreateUsageMetricDrawer'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type UseDefaultUsageMetricDrawerConnector = () => CreateUsageMetricDrawerProps

export const useDefaultUsageMetricDrawerConnector: UseDefaultUsageMetricDrawerConnector =
  () => {
    const navigate = useNavigate()

    const onSuccess = useCallback(() => {
      navigate('..')
    }, [navigate])

    const onCancel = useCallback(() => {
      navigate('..')
    }, [navigate])

    return {
      onSuccess,
      onCancel
    }
  }
