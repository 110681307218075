import { PostActionStage } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { getNewPrice } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/actionHandlers/addPrice'

/**
 * A hook to select a new price when there is no price currently selected.
 * @returns
 */
export const selectPrice: PostActionStage = () => prevState => {
  const selectedPrice = prevState.editor?.selectedPrice
    ? prevState.data.prices[prevState.editor?.selectedPrice]
    : null

  if (!selectedPrice) {
    /**
     * This feature does not apply in a list price-y world, so
     * we should always default to a new price.
     */
    const newPriceToSelect = prevState.configuration.enableListPrices
      ? null
      : prevState.data.prices[Object.keys(prevState.data.prices)[0]]?.id

    if (!newPriceToSelect) {
      const newPriceId = `new-price::${crypto.randomUUID()}`
      const newPrice = getNewPrice(prevState)(newPriceId)

      return {
        ...prevState,
        data: {
          ...prevState.data,
          prices: {
            ...prevState.data.prices,
            [newPriceId]: {
              ...newPrice,
              id: newPriceId
            }
          }
        },
        editor: {
          ...prevState.editor,
          selectedPrice: newPriceId
        }
      }
    }

    return {
      ...prevState,
      editor: {
        ...prevState.editor,
        selectedPrice:
          prevState.data.prices[Object.keys(prevState.data.prices)[0]].id
      }
    }
  }

  return prevState
}
