import { SimpleDialog } from '@sequencehq/core-components'
import { FC } from 'react'

export const RemoveContactDialog: FC<{
  title: string
  onConfirm: () => void
  onCancel: () => void
  trigger: React.ReactNode
}> = ({ title, onConfirm, onCancel, trigger }) => {
  return (
    <SimpleDialog
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      trigger={trigger}
      destructive
    >
      This will remove the contact from the customer's account. This action
      cannot be undone.
    </SimpleDialog>
  )
}
