import { useCallback, useEffect, useMemo } from 'react'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import {
  CustomerModel,
  InclusiveDateRange,
  InvoiceModel,
  toCurrencyDescription
} from '@sequencehq/core-models'
import { Totals } from '@sequencehq/invoice-content'
import { formatTotal } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { localDateWithFormat } from '@sequencehq/formatters'
import { add, format } from '@sequencehq/utils/dates'

const TAX_STATUS_LABELS: Record<CustomerModel['taxStatus'], string> = {
  TAX_EXEMPT: 'Tax exempt',
  REVERSE_CHARGED: 'Reverse charge',
  TAXED: 'Taxed'
}

type UseInvoiceEditorSummary = () => {
  data: {
    total: string
    dueDateInformation: string
    customerId: string
    customerName: string
    customerAddress: string[]
    customerEmails: string[]
    billingPeriod: string | null
    billingScheduleId: string | undefined
    groupSummary: { description: string; total: string }[]
    totals: Totals
    memo?: string
    purchaseOrderNumber?: string
    reference?: string
    customerArchived: boolean
    invoiceNumber?: string
    invoiceDate?: string
    taxStatus?: string
    currency: string
  }
  editDueDate: {
    available: boolean
    onChange: (newDueDate: string | undefined) => void
  }
  editMemo: {
    available: boolean
    disabled: boolean
    onChange: (newMemo: InvoiceModel['memo']) => void
  }
  editPurchaseOrderNumber: {
    available: boolean
    onChange: (newPurchaseOrderNumber: string | undefined) => void
  }
  editReference: {
    available: boolean
    onChange: (newReference: string | undefined) => void
  }
  showMemo: {
    available: boolean
  }
  editInvoiceDate: {
    available: boolean
    onChange: (newInvoiceDate: string | undefined) => void
  }
  editBillingPeriod: {
    available: boolean
    onChange: (newBillingPeriod: InclusiveDateRange) => void
  }
}

export const useInvoiceEditorSummary: UseInvoiceEditorSummary = () => {
  const { data: contextData, derived, functions } = useInvoiceEditorContext()

  const dueDateInformation = useMemo(() => {
    const dueDate = contextData.invoice.dueDate

    if (dueDate) {
      return localDateWithFormat(dueDate, 'd MMMM yyyy')
    } else {
      return `in ${contextData.merchant?.defaultDueDateDays} days`
    }
  }, [contextData.invoice.dueDate, contextData.merchant?.defaultDueDateDays])

  const groupSummary = useMemo(() => {
    if (!contextData.lineItemGroups && !contextData.lineItems) {
      return []
    }

    const ungroupedLineItems = Object.values(contextData.lineItems).filter(
      lineItem => !lineItem.groupId
    )

    const groupedLineItems = Object.values(contextData.lineItemGroups)

    return [...ungroupedLineItems, ...groupedLineItems].map(
      groupOrLineItem => ({
        description: groupOrLineItem.title ?? groupOrLineItem.description,
        total: formatTotal(
          contextData.invoice.currency,
          groupOrLineItem.netTotal
        )
      })
    )
  }, [contextData.lineItems, contextData.lineItemGroups])

  const data = useMemo(() => {
    return {
      total: contextData.totals.grossTotal,
      dueDateInformation,
      customerId: contextData.recipient.customerId,
      customerName: contextData.recipient.customerLegalName,
      customerAddress: contextData.recipient.customerAddressFields,
      customerEmails: contextData.recipient.customerEmails,
      memo: contextData.invoice.memo ?? '',
      billingPeriod: contextData.invoice.billingPeriod,
      billingScheduleId: contextData.invoice.billingScheduleId,
      groupSummary,
      totals: contextData.totals,
      purchaseOrderNumber: contextData.invoice.purchaseOrderNumber,
      reference: contextData.invoice.reference,
      customerArchived: contextData.recipient.isArchived,
      invoiceNumber: contextData.invoice.invoiceNumber,
      taxStatus: contextData.invoice.customerTaxStatus
        ? TAX_STATUS_LABELS[contextData.invoice.customerTaxStatus]
        : '',
      invoiceDate: localDateWithFormat(
        contextData.invoice.accountingDate,
        'd MMMM yyyy'
      ),
      currency: toCurrencyDescription(contextData.invoice.currency)
    }
  }, [
    contextData.invoice,
    contextData.recipient,
    contextData.totals,
    dueDateInformation
  ])

  const onEditMemo = useCallback(
    (newStatus: InvoiceModel['memo']) => {
      functions.updateMemo(contextData, newStatus)
    },
    [contextData, functions]
  )

  const onEditDueDate = useCallback(
    (newDueDate: string | undefined) => {
      functions.updateDueDate(contextData, newDueDate)
    },
    [contextData, functions]
  )

  const onEditPurchaseOrderNumber = useCallback(
    (newPurchaseOrderNumber: string | undefined) => {
      functions.updatePurchaseOrderNumber(contextData, newPurchaseOrderNumber)
    },
    [contextData, functions]
  )

  const onEditReference = useCallback(
    (newReference: string | undefined) => {
      functions.updateReference(contextData, newReference)
    },
    [contextData, functions]
  )

  const showMemoAvailable: boolean = useMemo(() => {
    if (contextData.invoice.status === 'DRAFT') {
      return true
    }

    return Boolean(contextData.invoice.memo)
  }, [contextData.invoice.memo, contextData.invoice.status])

  const onEditInvoiceDate = useCallback(
    (newDueDate: string | undefined) => {
      if (!newDueDate) {
        return
      }
      functions.updateInvoiceDate(contextData, newDueDate)
    },
    [contextData, functions]
  )

  const onEditBillingPeriod = useCallback(
    (newBillingPeriod: InclusiveDateRange) => {
      functions.updateBillingPeriod(contextData, newBillingPeriod)
    },
    [contextData, functions]
  )

  useEffect(() => {
    if (!contextData.invoice.dueDate) {
      const formatedDefaultDueDate = format(
        new Date(
          add(new Date(), {
            days: contextData.merchant?.defaultDueDateDays ?? 30
          })
        ),
        'yyyy-MM-dd'
      )
      functions.updateData({
        ...contextData,
        invoice: {
          ...contextData.invoice,
          dueDate: formatedDefaultDueDate
        }
      })
    }
  }, [contextData, contextData.merchant?.defaultDueDateDays])

  const {
    canEditDueDate,
    canEditMemo,
    canEditPurchaseOrderNumber,
    canEditReference,
    canEditInvoiceDate,
    canEditBillingPeriod
  } = derived.queries.availableFeatures

  return {
    data,
    editDueDate: {
      available: canEditDueDate,
      onChange: onEditDueDate
    },
    editMemo: {
      available: canEditMemo,
      disabled: false,
      onChange: onEditMemo
    },
    editPurchaseOrderNumber: {
      available: canEditPurchaseOrderNumber,
      onChange: onEditPurchaseOrderNumber
    },
    editReference: {
      available: canEditReference,
      onChange: onEditReference
    },
    showMemo: {
      available: showMemoAvailable
    },
    editInvoiceDate: {
      available: canEditInvoiceDate,
      onChange: onEditInvoiceDate
    },
    editBillingPeriod: {
      available: canEditBillingPeriod,
      onChange: onEditBillingPeriod
    }
  }
}
