import { Flex } from '@chakra-ui/react'
import { GreyGrey30 } from '@sequencehq/design-tokens'

export const Section = ({
  children,
  ...props
}: React.ComponentProps<typeof Flex>) => {
  return (
    <Flex
      backgroundColor="white"
      maxWidth="410px"
      width="100%"
      border={`1px solid ${GreyGrey30}`}
      boxShadow="0px 2px 4px 0px rgba(20, 23, 28, 0.08)"
      borderRadius="8px"
      padding="24px"
      flexDirection="column"
      gap="20px"
      {...props}
    >
      {children}
    </Flex>
  )
}
