import { Currency } from '@sequencehq/core-models'
import { defaultAvailableFrequenciesOptions } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/constants'
import {
  availableBillingTypeOptions,
  PricingEditorFormData
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency,
  prorationStrategyOptions
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/SeatBasedPrice/seatBasedPrice.constants.ts'
import { FormFields, useForm } from '@sequencehq/utils'
import {
  greaterThan,
  greaterThanEqualTo,
  required
} from '@sequencehq/validation'
import deepmerge from 'deepmerge'
import { tiersValidator } from 'modules/Cube/view/common/drawers/priceEditor/drawer/utils/validators/tiers.validators'
import { useCommonFieldsForm } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/CommonFields/useCommonFieldsForm'
import { useFetchSeatMetrics } from 'modules/Seats/SeatMetric/useFetchSeatMetrics'

type SeatBasedPriceFields = PricingEditorFormData['SEAT_BASED_GRADUATED']
type FieldsConfig = Omit<
  FormFields<SeatBasedPriceFields>,
  'seatTypeId' | 'overagesBillingFrequency' | 'prorationStrategy'
> & {
  seatTypeId: FormFields<SeatBasedPriceFields>['seatTypeId'] & {
    hidden: boolean
    onAddNew: () => void
  }
  billingFrequency: FormFields<SeatBasedPriceFields>['billingFrequency'] & {
    hidden: boolean
  }
  billingType: FormFields<SeatBasedPriceFields>['billingType'] & {
    hidden: boolean
  }
  minimumSeats: FormFields<SeatBasedPriceFields>['minimumSeats'] & {
    hidden: boolean
  }
  overagesBillingFrequency: Omit<
    FormFields<SeatBasedPriceFields>['overagesBillingFrequency'],
    'options'
  > & {
    options: { value: string; label: string; description: string }[]
    selected: { value: string; label: string; description: string }
  }
  prorationStrategy: Omit<
    FormFields<SeatBasedPriceFields>['prorationStrategy'],
    'options'
  > & {
    options: { value: string; label: string; description: string }[]
    selected: { value: string; label: string; description: string }
  }
  pricePerSeat: FormFields<SeatBasedPriceFields>['pricePerSeat'] & {
    hidden: boolean
  }
}

type UseSeatBasedPriceForm = (props?: { formDisabled?: boolean }) => {
  fieldsConfig: FieldsConfig
  currency: Currency
  fieldsAreHidden: boolean
}

export const useSeatBasedPriceForm: UseSeatBasedPriceForm = (
  { formDisabled } = { formDisabled: false }
) => {
  const { fieldsConfig } = useCommonFieldsForm()
  const pricingEditorContext = usePricingEditorContext()
  const navigate = useNavigate()
  const seatMetricsLoader = useFetchSeatMetrics()

  const seatMetricOptions = useMemo(() => {
    if (!seatMetricsLoader.metrics) {
      return []
    }

    return (
      seatMetricsLoader.metrics?.map(seatMetric => ({
        value: seatMetric.id,
        label: seatMetric.label
      })) ?? []
    )
  }, [seatMetricsLoader.metrics])

  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return pricingEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [pricingEditorContext.configuration.availableStandardFrequencies])

  const overagesFrequencies = useMemo(() => {
    return getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency(
      pricingEditorContext.data.formData[fieldsConfig.pricingModel.value]
        .billingFrequency
    )
  }, [fieldsConfig.pricingModel.value, pricingEditorContext.data.formData])

  const checkIfSeatBasedFieldDisabledByDomain = useCallback(
    ({ property }: { property: keyof SeatBasedPriceFields }) =>
      pricingEditorContext.functions.fieldIsDisabled(`SEAT_BASED.${property}`),
    [pricingEditorContext]
  )

  const { fields, queries } = useForm<SeatBasedPriceFields>({
    value: pricingEditorContext.data.formData[
      fieldsConfig.pricingModel.value
    ] as SeatBasedPriceFields,
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    disabled: formDisabled,
    fieldConfiguration: [
      {
        property: 'billingFrequency',
        validation: [required],
        disabled: checkIfSeatBasedFieldDisabledByDomain,
        options: availableFrequencies
      },
      {
        property: 'billingType',
        validation: [required],
        disabled: checkIfSeatBasedFieldDisabledByDomain,
        options: availableBillingTypeOptions
      },
      {
        property: 'pricePerSeat',
        validation:
          fieldsConfig.pricingModel.value === 'SEAT_BASED_LINEAR'
            ? [required, greaterThan(0, "Price per seat can't be zero")]
            : [],
        disabled: checkIfSeatBasedFieldDisabledByDomain
      },
      {
        property: 'tiers',
        disabled: checkIfSeatBasedFieldDisabledByDomain,
        validation:
          fieldsConfig.pricingModel.value === 'SEAT_BASED_GRADUATED'
            ? [required, tiersValidator]
            : []
      },
      {
        property: 'minimumSeats',
        validation: [
          required,
          greaterThanEqualTo(0, 'Please enter at least 0 seats')
        ],
        disabled: checkIfSeatBasedFieldDisabledByDomain
      },
      {
        property: 'prorationStrategy',
        validation: [required],
        disabled: checkIfSeatBasedFieldDisabledByDomain
      },
      {
        property: 'overagesBillingFrequency',
        validation: [required],
        disabled: checkIfSeatBasedFieldDisabledByDomain
      },
      {
        property: 'seatTypeId',
        validation: [required],
        disabled: checkIfSeatBasedFieldDisabledByDomain,
        options: seatMetricOptions
      }
    ],
    onChange: newData => {
      pricingEditorContext.functions.updateFormData({
        [fieldsConfig.pricingModel.value]: {
          ...newData,
          /**
           * We want to ensure the overages billing frequency remains valid.
           * Therefore, if it does not match the billing frequency, set
           * it to monthly (the default value).
           *
           * This means the value updates whenever billing frequency changes,
           * to retain a valid value.
           */
          overagesBillingFrequency:
            newData.overagesBillingFrequency === newData.billingFrequency
              ? newData.overagesBillingFrequency
              : 'MONTHLY'
        }
      })
    },
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          [fieldsConfig.pricingModel.value]: isValid
        }
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return deepmerge(
      fields,
      {
        seatTypeId: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields,
          onAddNew: () => navigate('./seats/new')
        },
        billingFrequency: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        },
        billingType: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        },
        minimumSeats: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        },
        prorationStrategy: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields,
          options: prorationStrategyOptions,
          selected: prorationStrategyOptions.find(
            opt => queries.formData.prorationStrategy === opt.value
          )
        },
        overagesBillingFrequency: {
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields,
          options: overagesFrequencies,
          selected: overagesFrequencies.find(
            opt => queries.formData.overagesBillingFrequency === opt.value
          )
        },
        pricePerSeat: {
          hidden: fieldsConfig.pricingModel.value === 'SEAT_BASED_GRADUATED'
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    ) as FieldsConfig
  }, [
    fields,
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields,
    overagesFrequencies,
    fieldsConfig.pricingModel.value,
    navigate,
    queries.formData.prorationStrategy,
    queries.formData.overagesBillingFrequency
  ])

  const fieldsAreHidden = useMemo(() => {
    return Boolean(
      pricingEditorContext.configuration.enableListPrices &&
        !pricingEditorContext.derived.queries.availableFeatures
          .showAllPriceFields
    )
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  return {
    fieldsConfig: enhancedFields,
    currency: pricingEditorContext.data.formData.common.currency,
    fieldsAreHidden
  }
}
