import {
  PageTitle,
  Button,
  Skeleton,
  StandardLayout
} from '@sequencehq/core-components'
import { Flex } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { GreyGrey60, Lato14Bold } from '@sequencehq/design-tokens'
import { ArrowDownTrayIcon, ChevronRightIcon } from '@heroicons/react/16/solid'
import { ExportCSVModal } from '../common/ExportCSVModal'
import { useSelectedDates } from './content/selectorHooks'
import { useSelectedCurrency } from '../utils/useSelectedCurrency'
import { format } from '@sequencehq/utils/dates'

const RevenueRecognitionHomeBreadcrumb = () => {
  const { currency } = useSelectedCurrency()
  return (
    <Link
      data-testid="revrec.detail.homeBreadcrumb"
      to={`/revenue-recognition/journal-reports?currency=${currency}`}
      style={{
        color: GreyGrey60,
        textDecoration: 'none'
      }}
    >
      <Flex gap="4px" {...Lato14Bold} alignItems="center">
        Journal Reports
        <ChevronRightIcon
          width="16px"
          color={GreyGrey60}
          style={{ position: 'relative', top: '1px' }}
        />
      </Flex>
    </Link>
  )
}

export const JournalReportDetailHeader = () => {
  const dates = useSelectedDates()
  const { currency, isLoading: isLoadingCurrency } = useSelectedCurrency()

  return (
    <StandardLayout.Header
      actions={
        isLoadingCurrency ? (
          <Skeleton width="132px" height="32px" />
        ) : (
          <ExportCSVModal
            from={dates.startDate}
            to={dates.endDate}
            currency={currency}
            trigger={
              <Button
                variant="secondary"
                leadingIcon={<ArrowDownTrayIcon width="16px" />}
                data-testid="revrec.detail.exportCSV"
              >
                Export to CSV
              </Button>
            }
          />
        )
      }
      title={
        <StandardLayout.Title>
          <PageTitle
            title={['Journal Report', format(dates.startDate, 'MMMM yyyy')]}
          />
          <Flex gap="4px" {...Lato14Bold} alignItems="center">
            <RevenueRecognitionHomeBreadcrumb />
            {format(new Date(dates.startDate), 'MMMM yyyy')}
          </Flex>
        </StandardLayout.Title>
      }
    />
  )
}
