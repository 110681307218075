import { Center, Spinner } from '@chakra-ui/react'
import { TaxRegistrationModel } from '@sequencehq/core-models'
import {
  useGetMerchantForSequenceAccountQuery,
  useGetTaxRegistrationsForCustomerByIdQuery
} from 'features/api'

import { useFetchCustomer } from 'Customer/hooks/useFetchCustomer'
import { useFetchCustomerAliases } from 'Customer/hooks/useFetchCustomerAliases'
import {
  Customer,
  CustomerAlias,
  Integration
} from 'components/Customers/types'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useFetchIntegrations } from 'Customer/hooks/useFetchIntegrations.ts'

export type CustomerContextData = {
  customer: Customer
  customerPortalEnabled: boolean
  customerAliases: CustomerAlias[]
  taxRegistration: TaxRegistrationModel | undefined
  integrations: Integration[]
  refetchCustomer: () => Promise<void>
  refetchCustomerAliases: () => Promise<void>
  refetchTaxRegistration: () => Promise<any>
}

const CustomerContext = createContext<CustomerContextData | undefined>(
  undefined
)

export const CustomerContextProvider = ({
  customerId,
  children
}: PropsWithChildren<{ customerId: string }>) => {
  const { customerDetails, refetch: refetchCustomer } =
    useFetchCustomer(customerId)

  const { customerAliases, refetch: refetchCustomerAliases } =
    useFetchCustomerAliases(customerId)

  const { data: merchantResponseData } = useGetMerchantForSequenceAccountQuery()

  const {
    data: taxRegistrationsResponseData,
    refetch: refetchTaxRegistration
  } = useGetTaxRegistrationsForCustomerByIdQuery({
    id: customerId
  })

  const { integrations } = useFetchIntegrations()

  const context = useMemo(() => {
    if (customerDetails) {
      return {
        customer: customerDetails,
        customerPortalEnabled:
          merchantResponseData?.value()?.customerPortalEnabled || false,
        customerAliases: customerAliases?.items ?? [],
        taxRegistration: (taxRegistrationsResponseData?.value()?.items ??
          [])?.[0],
        integrations: integrations ?? [],
        refetchCustomer,
        refetchCustomerAliases,
        refetchTaxRegistration
      }
    }
  }, [
    customerDetails,
    merchantResponseData,
    customerAliases?.items,
    taxRegistrationsResponseData,
    integrations,
    refetchCustomer,
    refetchCustomerAliases,
    refetchTaxRegistration
  ])

  if (!context?.customer) {
    return (
      <Center height="100%" width="100%">
        <Spinner />
      </Center>
    )
  }
  return (
    <CustomerContext.Provider value={context}>
      {children}
    </CustomerContext.Provider>
  )
}

export const useCustomerContext = () => {
  const context = useContext(CustomerContext)
  if (!context) {
    throw new Error(
      'useCustomerContext must be used within a CustomerContextProvider'
    )
  }
  return context
}
