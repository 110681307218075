import { usePricingEditorContext } from 'modules/Cube/view/common/drawers/priceEditor/drawer/hooks/usePricingEditorContext'
import { PricingEditorFormData } from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { useCallback, useMemo } from 'react'
import deepmerge from 'deepmerge'
import { defaultAvailableFrequenciesOptions } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/forms/constants'
import { useNavigate } from 'react-router-dom'
import { BillingFrequency, Currency } from '@sequencehq/core-models'
import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import {
  percentageTiersValidator,
  tiersValidator
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/utils/validators/tiers.validators'
import {
  UsageParameterDataModel,
  useUsageMetricParameterFieldConfig
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PriceForm/utils/useUsageMetricParameterFieldConfig'
import { UsageCalculationPeriodDropdownValue } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import usageCalculationPeriodMapper from 'common/drawers/PricingEditor/utils/usageCalculationPeriodMapper.ts'
import {
  availableUsageCalculationModes,
  availableUsageCalculationPeriodsByBillingFrequency,
  availableUsageTierTypes
} from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants.ts'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { omit } from 'lodash'

type GraduatedPriceFields = Omit<
  PricingEditorFormData['GRADUATED'],
  'parameters' | 'usageCalculationPeriod'
> &
  UsageParameterDataModel & {
    usageCalculationPeriod: UsageCalculationPeriodDropdownValue
  }

type UseGraduatedPriceForm = (props?: { formDisabled?: boolean }) => {
  fieldsConfig: Omit<
    FormFields<GraduatedPriceFields>,
    'usageMetricId' | 'billingFrequency'
  > & {
    billingFrequency: FormFields<GraduatedPriceFields>['billingFrequency'] & {
      hidden: boolean
    }
    usageMetricId: FormFields<GraduatedPriceFields>['usageMetricId'] & {
      hidden: boolean
      onAddNew: () => void
    }
    usageCalculationMode: FormFields<GraduatedPriceFields>['usageCalculationMode'] & {
      hidden: boolean
    }
    usageCalculationPeriod: FormFields<GraduatedPriceFields>['usageCalculationPeriod'] & {
      hidden: boolean
    }
  }
  formData: GraduatedPriceFields
  currency: Currency
  fieldsAreHidden: boolean
}

export const useGraduatedPriceForm: UseGraduatedPriceForm = (
  { formDisabled } = { formDisabled: false }
) => {
  const navigate = useNavigate()
  const pricingEditorContext = usePricingEditorContext()
  const flags = useFlags()

  /**
   * Define options for the select fields
   */
  const availableFrequencies = useMemo(() => {
    return defaultAvailableFrequenciesOptions.filter(frequency => {
      return pricingEditorContext.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [pricingEditorContext.configuration.availableStandardFrequencies])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(pricingEditorContext.data.metrics).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [pricingEditorContext.data.metrics])

  /**
   * Initialise the form management
   */
  const checkIfGraduatedFieldDisabledByDomain = useCallback(
    ({
      property
    }: {
      property: keyof (GraduatedPriceFields & UsageParameterDataModel)
    }) =>
      pricingEditorContext.functions.fieldIsDisabled(`GRADUATED.${property}`),
    [pricingEditorContext]
  )

  const parameterFields =
    useUsageMetricParameterFieldConfig<GraduatedPriceFields>(
      pricingEditorContext.data.formData.GRADUATED.usageMetricId,
      checkIfGraduatedFieldDisabledByDomain
    )

  const { fields, queries, mutators } = useForm<
    Omit<GraduatedPriceFields, 'parameters'> & UsageParameterDataModel
  >({
    value: {
      ...pricingEditorContext.data.formData.GRADUATED,
      usageCalculationPeriod:
        usageCalculationPeriodMapper.fromApiPricePropertiesToUsageCalculationPeriodDropdown(
          {
            usageCalculationMode:
              pricingEditorContext.data.formData.GRADUATED.usageCalculationMode,
            usageCalculationPeriod:
              pricingEditorContext.data.formData.GRADUATED
                .usageCalculationPeriod
          },
          pricingEditorContext.data.formData.GRADUATED.billingFrequency
        )
    },
    disabled: formDisabled,
    fieldConfiguration: [
      {
        property: 'billingFrequency',
        disabled: checkIfGraduatedFieldDisabledByDomain,
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'usageMetricId',
        disabled: checkIfGraduatedFieldDisabledByDomain,
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'tiers',
        disabled: ctx =>
          checkIfGraduatedFieldDisabledByDomain(ctx) ||
          ctx.formData.usageTierType !== 'FIXED',
        validation: [required, tiersValidator]
      },
      {
        property: 'percentageTiers',
        disabled: ctx =>
          checkIfGraduatedFieldDisabledByDomain(ctx) ||
          ctx.formData.usageTierType !== 'PERCENTAGE',
        validation: [required, percentageTiersValidator]
      },
      {
        property: 'usageTierType',
        disabled: checkIfGraduatedFieldDisabledByDomain,
        validation: [required],
        options: availableUsageTierTypes
      },
      {
        property: 'usageCalculationMode',
        disabled: checkIfGraduatedFieldDisabledByDomain,
        validation: !flags.useUsageResetCadences ? [required] : [],
        options: availableUsageCalculationModes
      },
      {
        property: 'usageCalculationPeriod',
        disabled: checkIfGraduatedFieldDisabledByDomain,
        validation: flags.useUsageResetCadences ? [required] : [],
        options:
          availableUsageCalculationPeriodsByBillingFrequency[
            pricingEditorContext.data.formData.GRADUATED.billingFrequency
          ]
      },
      ...parameterFields.fields
    ],
    showValidationErrors: pricingEditorContext.editor.showValidationErrors,
    /**
     * Sync the data back to the domain
     */
    onValidationStateChange: isValid => {
      pricingEditorContext.functions.updateEditor({
        formsValid: {
          GRADUATED: isValid
        }
      })
    },
    onChange: newData => {
      if (!flags.useUsageResetCadences) {
        pricingEditorContext.functions.updateFormData({
          GRADUATED: {
            ...omit(newData, 'usageCalculationPeriod')
          }
        })
        return
      }

      const mappedCalculationStructure =
        usageCalculationPeriodMapper.fromUsageCalculationPeriodDropdownToApiPriceProperties(
          newData.usageCalculationPeriod,
          newData.billingFrequency
        )

      pricingEditorContext.functions.updateFormData({
        GRADUATED: {
          ...newData,
          usageCalculationMode: mappedCalculationStructure.usageCalculationMode,
          usageCalculationPeriod:
            mappedCalculationStructure.usageCalculationPeriod
        }
      })
    }
  })

  /**
   * Enhance the base field config with some extra bits and pieces
   */
  const processedFieldsConfig = useMemo(() => {
    return deepmerge(
      fields,
      {
        billingFrequency: {
          ...fields.billingFrequency,
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields,
          onChange: (newValue: BillingFrequency) => {
            mutators.updateFormData({
              usageCalculationPeriod: 'BILLING_PERIOD',
              billingFrequency: newValue
            })
          }
        },
        usageMetricId: {
          ...fields.usageMetricId,
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields,
          onAddNew: () => navigate('./metrics/new')
        },
        usageTierType: {
          ...fields.usageTierType,
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        },
        usageCalculationMode: {
          ...fields.usageCalculationMode,
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        },
        usageCalculationPeriod: {
          ...fields.usageCalculationPeriod,
          hidden:
            pricingEditorContext.configuration.enableListPrices &&
            !pricingEditorContext.derived.queries.availableFeatures
              .showAllPriceFields
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    )
  }, [
    fields,
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields,
    mutators,
    navigate
  ])

  const fieldsAreHidden = useMemo(() => {
    return Boolean(
      pricingEditorContext.configuration.enableListPrices &&
        !pricingEditorContext.derived.queries.availableFeatures
          .showAllPriceFields
    )
  }, [
    pricingEditorContext.configuration.enableListPrices,
    pricingEditorContext.derived.queries.availableFeatures.showAllPriceFields
  ])

  return {
    fieldsConfig: processedFieldsConfig,
    formData: queries.formData,
    currency: pricingEditorContext.data.formData.common.currency,
    fieldsAreHidden
  }
}
