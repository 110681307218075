import { createContext, useContext } from 'react'

export type SeatBalanceContextType = {
  data: {
    resetKey: number
  }
  functions: {
    updateResetKey: () => void
  }
}

const SeatBalanceContext = createContext<SeatBalanceContextType>({
  data: {
    resetKey: 0
  },
  functions: {
    updateResetKey: () => {}
  }
})

export const useSeatBalanceContext = () => useContext(SeatBalanceContext)
export const SeatBalanceProvider = SeatBalanceContext.Provider
