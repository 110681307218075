import { Button, Link, Text } from '@chakra-ui/react'
import { Lato14ExtendedRegular, Lato16Bold } from '@sequencehq/design-tokens'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Section } from 'modules/LoginApp/components/common/Section'
import { Wordmark } from 'components/icons/Wordmark'

export const ErrorPage = () => {
  return (
    <PageContainer>
      <Wordmark width="126" height="26.74" />
      <Section gap="8px">
        <Text {...Lato16Bold} color="gray.90">
          Unable to sign in
        </Text>
        <Text {...Lato14ExtendedRegular} color="gray.80">
          We apologize for the inconvenience. We are currently experiencing
          technical difficulties. Please try again later.
        </Text>
        <Button
          as={Link}
          href="/auth/login"
          mt="2"
          variant="primary2"
          width="100%"
          _hover={{
            textDecoration: 'none'
          }}
        >
          Try again
        </Button>
      </Section>
      <Text position="absolute" bottom="40px" textAlign="center" zIndex={10}>
        Check our{' '}
        <Link
          isExternal
          href="https://status.sequencehq.com/"
          color="indigo.50"
        >
          status page
        </Link>{' '}
        for more information
      </Text>
    </PageContainer>
  )
}

export default ErrorPage
