import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import {
  GreyGrey30,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Skeleton } from '@sequencehq/core-components'
import { Link } from 'react-router-dom'
import { capitalize, compose, replace } from 'lodash/fp'
import { DefRevScheduleDetailDrawer } from './DefRevScheduleDetailDrawer/DefRevScheduleDetailDrawer'
import { ProgressBar } from 'RevenueRecognition/view/common/ProgressBar'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { Currency, useQuery } from '@sequencehq/api/utils'
import invariant from 'tiny-invariant'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { toMoney } from '@sequencehq/utils'

export interface DeferredRevenueSchedule {
  id: string
  customer: {
    id: string
    name: string
  }
  product: {
    id: string
    name: string
  }
  invoice?: {
    id: string
    name: string
  }
  method: Dashboardv99990101Api.GetDeferredRevenueSchedules.DeferredRecognitionMethod
  term?: {
    start: Date
    endInclusive: Date
  }
  progress: {
    currency: Currency
    recognized: {
      percentage: number
      amount: number
    }
    remaining: {
      percentage: number
      amount: number
    }
  }
}

interface DeferredRevenueScheduleData {
  count: number
  schedules: Array<DeferredRevenueSchedule>
}

type UseDeferredRevenueSchedules = () =>
  | {
      detail: DeferredRevenueScheduleData
      isLoading: false
    }
  | {
      detail: null
      isLoading: true
    }

/**
 * Data acquisition for the table
 * @param props
 * @returns
 */
export const useDeferredRevenueTable: UseDeferredRevenueSchedules = () => {
  const schedulesQuery = useQuery(
    dashboardv99990101Client.getDeferredRevenueSchedules,
    undefined,
    {
      select: res => {
        if (!res) {
          return null
        }

        return {
          count: res.items.length,
          schedules: res.items.map(
            (item): DeferredRevenueSchedule => ({
              id: item.id,
              customer: {
                id: item.customer.id,
                name: item.customer.name
              },
              product: item.product,
              invoice: item.invoice,
              method: item.recognitionMethod,
              progress: {
                currency: item.progress.currency,
                recognized: {
                  percentage: Math.round(
                    (parseFloat(item.progress.recognized) /
                      parseFloat(item.progress.original)) *
                      100
                  ),
                  amount: parseFloat(item.progress.recognized)
                },
                remaining: {
                  percentage: Math.round(
                    (parseFloat(item.progress.remaining) /
                      parseFloat(item.progress.original)) *
                      100
                  ),
                  amount: parseFloat(item.progress.remaining)
                }
              }
            })
          )
        }
      }
    }
  )

  /**
   * We throw this error to be caught by the appropriate error boundary.
   */
  if (schedulesQuery.error) {
    throw new Error('Schedules detail could not be loaded')
  }

  if (schedulesQuery.isPending) {
    return {
      isLoading: true,
      detail: null
    }
  }

  invariant(schedulesQuery.data, 'Detail should be defined, if loaded')

  return {
    isLoading: false,
    detail: schedulesQuery.data
  }
}

const cellBorderStyle = {
  _notLast: { borderRight: `1px solid ${GreyGrey30}` }
}

const HeaderRow = () => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr boxShadow={`0 1px 0 ${GreyGrey30}`} borderBottom="none !important">
    <Th width="100%">Customer</Th>
    <Th width="100%">Product</Th>
    <Th width="100%">Invoice</Th>
    <Th width="100%">Recognition method</Th>
    <Th width="158px">Recognized</Th>
    <Th width="100%" textAlign="right">
      Remaining
    </Th>
  </Tr>
)

const TableRow = (
  props: DeferredRevenueSchedule & {
    onClick: () => void
    progressAnimationDelay: number
    'data-testid'?: string
  }
) => (
  <Tr
    onClick={props.onClick}
    cursor="pointer"
    data-testid={props['data-testid']}
  >
    <Td
      width="100%"
      color={GreyGrey80}
      {...cellBorderStyle}
      paddingLeft="4px !important"
      data-testid="revrec.defrev.table.customer"
    >
      {props.customer && (
        <CustomerPreviewCardPill
          customerId={props.customer.id}
          variant="ghost"
        />
      )}
    </Td>
    <Td
      width="100%"
      color={GreyGrey80}
      {...cellBorderStyle}
      data-testid="revrec.defrev.table.product"
    >
      {props.product && (
        <Link
          to={`/products/${props.product.id}`}
          style={{
            color: IndigoIndigo50,
            ...Lato13Regular
          }}
        >
          {props.product.name}
        </Link>
      )}
    </Td>
    <Td
      width="100%"
      color={GreyGrey80}
      {...cellBorderStyle}
      data-testid="revrec.defrev.table.invoice"
    >
      {props.invoice && (
        <Link
          to={`/invoices/${props.invoice.id}`}
          style={{
            color: IndigoIndigo50,
            ...Lato13Regular
          }}
        >
          {props.invoice.name}
        </Link>
      )}
    </Td>
    <Td
      width="100%"
      color={GreyGrey80}
      {...cellBorderStyle}
      data-testid="revrec.defrev.table.method"
    >
      {compose(capitalize, replace('_', ' '))(props.method)}
    </Td>
    <Td
      width="158px"
      color={GreyGrey80}
      {...cellBorderStyle}
      align="center"
      data-testid="revrec.defrev.table.progress"
    >
      <Flex
        gap="8px"
        justifyContent="flex-end"
        alignItems="center"
        paddingRight="8px"
      >
        {props.progress.recognized.percentage}%
        <ProgressBar
          percentage={props.progress.recognized.percentage}
          animation={{ delayBeforeStart: props.progressAnimationDelay }}
          width="84px"
        />
      </Flex>
    </Td>
    <Td
      width="100%"
      color={GreyGrey80}
      {...cellBorderStyle}
      textAlign="right"
      data-testid="revrec.defrev.table.remaining"
    >
      <Box {...Lato13Bold}>
        {toMoney({
          currency: props.progress.currency,
          value: props.progress.remaining.amount.toString()
        })}
      </Box>
    </Td>
  </Tr>
)

const TotalRow = (totals: { totalRecords: number }) => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr
    boxShadow={`0 -1px 0 ${GreyGrey30}`}
    borderTop="none !important"
    data-testid="revrec.defrev.table.totalRow"
  >
    <Td
      width="100%"
      {...Lato13Bold}
      color={GreyGrey90}
      cursor="default"
      colSpan={5}
    >
      {totals.totalRecords !== 1
        ? `${totals.totalRecords} records`
        : '1 record'}
    </Td>
    <Td width="153px" {...cellBorderStyle} cursor="default"></Td>
  </Tr>
)

const PlaceholderRow = () => (
  <Tr>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
  </Tr>
)

const PlaceholderTotalRow = () => (
  <Tr>
    <Td width="100%" colSpan={5} cursor="default">
      <Skeleton />
    </Td>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
  </Tr>
)

export const DeferredRevenueTable = () => {
  const { isLoading, detail } = useDeferredRevenueTable()

  return (
    <TableContainer
      data-testid="revrec.defrev.table"
      borderRadius="lg"
      overflowX="auto"
      overflowY="auto"
      maxHeight="100%"
      border={`1px solid ${GreyGrey30}`}
      width="100%"
    >
      <Table variant="v2" width="100%">
        <Thead position="sticky" insetBlockStart={0}>
          <HeaderRow />
        </Thead>
        <Tbody>
          {isLoading ? (
            <>
              <PlaceholderRow />
              <PlaceholderRow />
              <PlaceholderRow />
            </>
          ) : (
            detail.schedules.map((schedule, idx) => (
              <DefRevScheduleDetailDrawer
                key={schedule.id}
                trigger={openFn => (
                  <TableRow
                    onClick={openFn}
                    {...schedule}
                    progressAnimationDelay={idx * 15}
                    data-testid={`revrec.defrev.table.row.${schedule.id}`}
                  />
                )}
                defRevScheduleId={schedule.id}
              />
            ))
          )}
        </Tbody>
        <Tfoot position="sticky" insetBlockEnd={0}>
          {isLoading ? (
            <PlaceholderTotalRow />
          ) : (
            <TotalRow totalRecords={detail?.count} />
          )}
        </Tfoot>
      </Table>
    </TableContainer>
  )
}
