import { Flex, Grid, Text } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import { capitalize, compose, replace } from 'lodash/fp'
import { useProductContext } from 'modules/Products/hooks/useProductContext'

export const ProductMeta = () => {
  const {
    data: { product, taxCategories },
    features
  } = useProductContext()
  const taxCategoryName = taxCategories.find(
    category => category.id === product.taxCategoryId
  )?.name

  return (
    <Flex p={5} flexDirection="column">
      <Text {...Lato14Bold} color={GreyGrey80}>
        Details
      </Text>
      <Grid
        templateColumns={`${product?.revenueRecognitionMethod ? '166px' : '150px'} 1fr`}
        gap={4}
        mt={4}
        color={GreyGrey80}
      >
        <Text {...Lato13Regular}>Name</Text>
        <Text {...Lato13Regular} data-testid="product.name">
          {product.name}
        </Text>
        <Text {...Lato13Regular}>Description</Text>
        <Text
          {...Lato13Regular}
          sx={{
            textColor: product.label ? GreyGrey80 : GreyGrey60
          }}
          data-testid="product.description"
        >
          {product.label || 'Not set'}
        </Text>
        {product.revenueRecognitionMethod && (
          <>
            <Text {...Lato13Regular}>Revenue recognition method</Text>
            <Text
              {...Lato13Regular}
              data-testid="product.revenueRecognitionMethod"
            >
              {compose(
                replace('_', ' '),
                capitalize
              )(product.revenueRecognitionMethod)}
            </Text>
          </>
        )}
        <Text {...Lato13Regular}>Updated</Text>
        <Text {...Lato13Regular} data-testid="product.updatedAt">
          {dateTimeWithFormat(product.updatedAt, 'dd MMM yyyy')}
        </Text>
        <Text {...Lato13Regular}>Created</Text>
        <Text {...Lato13Regular} data-testid="product.createdAt">
          {dateTimeWithFormat(product.createdAt, 'dd MMM yyyy')}
        </Text>
        {features.taxCategories && (
          <>
            <Text {...Lato13Regular}>Tax category</Text>
            <Text
              {...Lato13Regular}
              sx={{
                textColor: taxCategoryName ? GreyGrey80 : GreyGrey60
              }}
              data-testid="product.taxCategory"
            >
              {taxCategoryName ?? 'Not set'}
            </Text>
          </>
        )}
      </Grid>
    </Flex>
  )
}
