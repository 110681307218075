import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { QuoteEditorPreviewProps } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/QuoteEditorPreview.types'
import { previewRenderers } from 'modules/Cube/view/layouts/quote/QuoteEditorPreview/previewRenderers.adapters'

export const useQuoteEditorPreviewConnector = (): QuoteEditorPreviewProps => {
  const cubeContext = useCubeContext()

  return {
    quoteId: cubeContext.queries.rawData.data.common.id,
    reactRendererData: previewRenderers.reactPreview.in(
      cubeContext.queries.rawData.data
    ),
    pdfRendererData: previewRenderers.pdfPreview.in(
      cubeContext.queries.rawData.data
    )
  }
}
