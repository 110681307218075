import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { CreditNoteModel } from '@sequencehq/core-models'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import { DateInputField } from 'components/FormFields'
import { usePutCreditNotesByIdMutation } from 'features/api'
import { handleFormResponse } from 'lib/formValidation'
import { noReturn } from 'lib/noReturn'
import {
  composeValidators,
  dateMustBeAfter,
  dateMustBeBefore,
  requiredIfFieldPopulated
} from '@sequencehq/validation'
import { FC, useMemo } from 'react'
import { format } from '@sequencehq/utils/dates'
import { Form, FormSpy } from 'react-final-form'

interface DetailsSidebarProps {
  creditNote: CreditNoteModel
  setCreditNote: (cn: CreditNoteModel) => void
  cancel: () => void
  setHasChanges: () => void
}

type FormValues = {
  purchaseOrderNumber: string | undefined
  billingPeriodStart: Date | undefined
  billingPeriodEnd: Date | undefined
}

const DetailsSidebar: FC<DetailsSidebarProps> = ({
  creditNote,
  setCreditNote,
  cancel,
  setHasChanges
}) => {
  const [submitForm] = usePutCreditNotesByIdMutation()
  function formatDate(
    date: Date | undefined,
    formatString: string
  ): string | undefined {
    return date
      ? format(
          Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
          formatString
        )
      : undefined
  }

  const initialValues = useMemo(() => {
    return {
      purchaseOrderNumber: creditNote.purchaseOrderNumber,
      billingPeriodStart:
        creditNote.billingPeriodStart &&
        typeof creditNote.billingPeriodStart === 'string'
          ? /** Strip timezone on parse */
            new Date(creditNote.billingPeriodStart.slice(0, 10))
          : undefined,
      billingPeriodEnd:
        creditNote.billingPeriodEnd &&
        typeof creditNote.billingPeriodStart === 'string'
          ? new Date(creditNote.billingPeriodEnd.slice(0, 10))
          : undefined
    }
  }, [
    creditNote.purchaseOrderNumber,
    creditNote.billingPeriodStart,
    creditNote.billingPeriodEnd
  ])

  return (
    <>
      <Form<FormValues>
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={async (values, form) => {
          const result = await submitForm({
            id: creditNote.id,
            updateCreditNoteEndpointUpdateCreditNoteRequestModel: {
              ...creditNote,
              purchaseOrderNumber: values.purchaseOrderNumber,
              billingPeriodStart: formatDate(
                values.billingPeriodStart,
                "yyyy-MM-dd'T'HH:mm:ss'Z'"
              ),
              billingPeriodEnd: `${formatDate(
                values.billingPeriodEnd,
                "yyyy-MM-dd'T'HH:mm:ss'Z'"
              )}`
            }
          })

          return handleFormResponse(result, form.getRegisteredFields())
        }}
        render={({ handleSubmit, submitting, submitError }) => (
          <Flex
            as="form"
            px={5}
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            w="100%"
          >
            <FormSpy
              subscription={{ values: true, dirty: true, valid: true }}
              onChange={state => {
                if (state.dirty && state.valid) {
                  setCreditNote({
                    ...creditNote,
                    purchaseOrderNumber: state.values.purchaseOrderNumber,
                    billingPeriodStart: state.values.billingPeriodStart,
                    billingPeriodEnd: state.values.billingPeriodEnd
                  })
                }
                if (state.dirty) {
                  setHasChanges()
                }
              }}
            />
            <FormErrors formError={submitError} />
            <Text textStyle="sectionHeader">Billing period</Text>
            <Box h="16px"></Box>
            <DateInputField
              fieldName="billingPeriodStart"
              fieldLabel="Billing period start"
              isSelectable={() => true}
              validate={composeValidators(
                requiredIfFieldPopulated(
                  'billingPeriodEnd',
                  'Must provide both start and end'
                ),
                dateMustBeBefore('billingPeriodEnd', 'Must be before end date')
              )}
            />
            <Box h="24px"></Box>
            <DateInputField
              fieldName="billingPeriodEnd"
              fieldLabel="Billing period end"
              isSelectable={() => true}
              validate={composeValidators(
                requiredIfFieldPopulated(
                  'billingPeriodStart',
                  'Must provide both start and end'
                ),
                dateMustBeAfter(
                  'billingPeriodStart',
                  'Must be after start date'
                )
              )}
            />
            <Box h="24px"></Box>
            <Divider></Divider>
            <Box h="24px"></Box>
            <TextInputField
              fieldName="purchaseOrderNumber"
              fieldLabel="Purchase order number"
            />
            <Box height={5} />
            <Flex justifyContent="flex-end">
              <Button variant="secondary" size="sm" onClick={cancel}>
                Cancel
              </Button>
              <Box w="16px"></Box>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                isDisabled={submitting}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      />
    </>
  )
}

export default DetailsSidebar
