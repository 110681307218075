import { useQuery } from '@sequencehq/api/dist/utils'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import {
  dashboardv99990101Client,
  Dashboardv99990101CommonModels
} from '@sequencehq/api/dashboard/v99990101'

export type Customer = Dashboardv99990101CommonModels.Customers.Customer

type UseLoadCustomer = (customerId: string | undefined) => {
  loading: boolean
  error?: LoaderError
  data?: {
    customer: Customer
  }
}

export const useLoadCustomer: UseLoadCustomer = customerId => {
  const fetchCustomer = useQuery(dashboardv99990101Client.getCustomer, {
    id: customerId ?? ''
  })
  const customer = fetchCustomer.data
  const data = customer ? { customer } : undefined

  return {
    loading: fetchCustomer.isLoading,
    data
  }
}
