import { Box } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'

export const SlackIntegrationDetail = () => {
  return (
    <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
      Stay up to date with real-time notifications for Sequence events.
    </Box>
  )
}
