import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const useAddProductOutletConnector = () => {
  const navigate = useNavigate()
  const flags = useFlags()

  const onSuccess = useCallback(() => {
    navigate('..')
  }, [navigate])

  const onCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  const enableListPrices = useMemo(
    () => Boolean(flags.enableListPrices),
    [flags.enableListPrices]
  )

  return {
    enableListPrices,
    onSuccess,
    onCancel
  }
}
