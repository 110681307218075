import DeleteCreditNoteLineItemForm from 'components/DeleteLineItem/DeleteCreditNoteLineItemForm'
import { createFormModal } from 'components/Form/createFormModal'
import {
  DeleteCreditNoteLineItemFormMutation,
  DeleteCreditNoteLineItemFormProps
} from 'components/DeleteLineItem/types'
import { useDeleteCreditNotesByCreditnoteLineItemsByIdMutation } from 'features/api/integratedApi.generated'

export const DeleteCreditNoteLineItemModal = createFormModal<
  DeleteCreditNoteLineItemFormMutation,
  DeleteCreditNoteLineItemFormProps
>({
  FormComponent: DeleteCreditNoteLineItemForm,
  useMutation: useDeleteCreditNotesByCreditnoteLineItemsByIdMutation,
  modalTitle: 'Delete Line Item',
  successMessage: 'Deleted',
  submitTitle: 'Delete line item',
  formId: 'deleteLineItemForm',
  buttonVariant: 'warning'
})
