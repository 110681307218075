import { Flex } from '@chakra-ui/react'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { DatePresetSelect } from 'components/InsightsV2/common/DatePresetSelect/DatePresetSelect'

interface ControlsProps {
  preset: string
  onChangePreset: (preset: string) => void
}

export const Controls: React.FC<ControlsProps> = ({
  preset,
  onChangePreset
}) => {
  return (
    <Flex
      placeItems="center"
      px={6}
      py={3.5}
      h="65px"
      borderBottom={`1px solid ${GreyGrey30}`}
      gap={3}
    >
      <DatePresetSelect value={preset} onChangeDatePreset={onChangePreset} />
    </Flex>
  )
}
