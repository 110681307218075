import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey60,
  Lato13Regular
} from '@sequencehq/design-tokens'
import {
  DateDurationField,
  DatePickerField,
  DayOfMonthField
} from '@sequencehq/core-components'
import { PhaseHookInterface } from 'modules/Cube/view/common/widgets/PricingPhases/Phase/usePhase'
import { FC } from 'react'

export const PhaseDurationSelection: FC<{ phasesHook: PhaseHookInterface }> = ({
  phasesHook
}) => {
  return (
    <Box
      backgroundColor={GreyGrey10}
      borderRadius="6px"
      gap="8px"
      padding="12px 8px"
      alignItems="flex-end"
    >
      <Flex gap="16px">
        <DatePickerField
          data-testid="phase.startDate"
          variant="new"
          width="226px"
          label="Start date"
          validationErrors={
            phasesHook.fieldsConfig.scheduleDateRange.validationErrors.startDate
          }
          minDate={phasesHook.fieldsConfig.scheduleDateRange.minDate}
          value={phasesHook.fieldsConfig.scheduleDateRange.fromDate}
          onChange={phasesHook.fieldsConfig.scheduleDateRange.onChangeStartDate}
          isDisabled={phasesHook.fieldsConfig.scheduleDateRange.disabled.start}
          styles={{
            wrapper: {
              marginBottom: 0
            }
          }}
        />
        <Flex grow={1} gap={2}>
          <DateDurationField
            inputRef={phasesHook.refs.durationInput}
            width="226px"
            label="Duration"
            mode="relative"
            validationErrors={
              phasesHook.fieldsConfig.scheduleDateRange.validationErrors.endDate
            }
            fromDate={phasesHook.fieldsConfig.scheduleDateRange.fromDate}
            value={phasesHook.fieldsConfig.scheduleDateRange.value}
            onChange={newDuration =>
              phasesHook.fieldsConfig.scheduleDateRange.onChangeDuration(
                newDuration
              )
            }
            isDisabled={phasesHook.fieldsConfig.scheduleDateRange.disabled.end}
            styles={{
              wrapper: {
                marginBottom: 0
              }
            }}
            height="32px"
            inputStyleVariant="new"
            durationFilterFn={phasesHook.durationFilter}
          />
          {Boolean(phasesHook.phaseDuration.endDateDescription) && (
            <Flex alignItems="flex-end" color={GreyGrey60}>
              <Flex height="32px" alignItems="center" {...Lato13Regular}>
                {phasesHook.phaseDuration.endDateDescription}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      {!phasesHook.fieldsConfig.recurrenceDayOfMonth.hidden && (
        <DayOfMonthField
          styles={{
            wrapper: {
              marginBottom: 0,
              marginTop: '16px'
            }
          }}
          data-testid="phase.recurrenceDayOfMonth"
          width="226px"
          tooltip={{
            title: 'Proration',
            content:
              'Automatically prorate charges for partial billing periods. If you bill monthly on the 1st, but want to start charging a new customer mid-month, Sequence prorates the first billing period (e.g. 14th-31st Jan).'
          }}
          fieldTooltip={{
            label: phasesHook.fieldsConfig.recurrenceDayOfMonth.disabledReason,
            placement: 'top'
          }}
          label="Start billing period every"
          {...phasesHook.fieldsConfig.recurrenceDayOfMonth}
          isDisabled={phasesHook.fieldsConfig.recurrenceDayOfMonth.disabled}
        />
      )}
    </Box>
  )
}
