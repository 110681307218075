import { PricingEditorRoot } from 'common/drawers/PricingEditor/view/pricingEditor.root'
import { useNewPricingEditorConnector } from './useNewPricingEditor.connector'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * If a route component needs access to data from the billing schedule context, it
 * should use a connector hook.
 * @returns
 */
export const NewPricingEditorCubeRoute = () => {
  const context = useNewPricingEditorConnector()

  return (
    <PricingEditorRoot
      variant="variant"
      initialMode={context.mode}
      currency={context.scheduleCurrency}
      variantPriceId={context.existingData?.price?.id}
      onSave={context.onSave}
    />
  )
}
