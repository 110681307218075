import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import {
  GreyGrey10,
  GreyGrey30,
  GreyGrey40,
  GreyGrey60,
  RedRed60
} from '@sequencehq/design-tokens'
import { Member } from '@stytch/vanilla-js'

interface MemberActionButtonProps {
  member: Member
  onResendInvite: (member: Member) => Promise<void>
  onRevokeInvite: (member: Member) => void
}

export const MemberActionsButton = ({
  member,
  onResendInvite,
  onRevokeInvite
}: MemberActionButtonProps) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<EllipsisHorizontalIcon width={20} color={GreyGrey60} />}
        variant="unstyled"
        width="28px"
        height="24px"
        background="white"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="6px"
        borderWidth="1px"
        borderColor="transparent"
        minW={0}
        _hover={{
          bg: GreyGrey10,
          borderColor: GreyGrey30,
          boxShadow: '0px 2px 4px 0px rgba(20, 23, 28, 0.08)'
        }}
      />
      <MenuList
        fontSize="14px"
        overflow="hidden"
        borderColor={GreyGrey40}
        padding="8px"
        boxShadow="0px 8px 16px 0px rgba(0, 0, 0, 0.08)"
      >
        {member.status === 'invited' && (
          <MenuItem onClick={() => void onResendInvite(member)}>
            Resend Invite
          </MenuItem>
        )}
        <MenuItem color={RedRed60} onClick={() => onRevokeInvite(member)}>
          {member.status === 'invited' ? 'Revoke Invite' : 'Remove Member'}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
