import { Box, Text } from '@chakra-ui/react'
import { Lato12Bold } from '@sequencehq/design-tokens'

type ProductGraphPercentBadgeProps = {
  value: number
}

const getStyle = (
  value: number,
  {
    positive,
    negative,
    neutral
  }: { positive: string; negative: string; neutral: string }
) => {
  if (value > 0) {
    return positive
  } else if (value < 0) {
    return negative
  } else {
    return neutral
  }
}

export const ProductGraphPercentBadge = ({
  value
}: ProductGraphPercentBadgeProps) => {
  const sign = getStyle(value, { positive: '+', negative: '', neutral: '' })

  const boxBorderColor = getStyle(value, {
    positive: 'rgba(174, 238, 38, 1)',
    negative: 'rgba(255, 142, 82, 1)',
    neutral: ''
  })

  const boxBackgroundColor = getStyle(value, {
    positive: 'rgba(235, 251, 201, 1)',
    negative: 'rgba(255, 230, 217, 1)',
    neutral: ''
  })

  const textColor = getStyle(value, {
    positive: 'rgba(74, 144, 0, 1)',
    negative: 'rgba(166, 71, 20, 1)',
    neutral: ''
  })

  return (
    <Box
      padding="2px 8px"
      borderWidth="1px"
      borderColor={boxBorderColor}
      background={boxBackgroundColor}
      borderRadius="99px"
    >
      <Text color={textColor} {...Lato12Bold}>
        {sign}
        {value}%
      </Text>
    </Box>
  )
}
