import { Box, Button, Circle, Flex, Text } from '@chakra-ui/react'
import { Currency, currencyToName } from '@sequencehq/core-models'
import { useEnabledCurrencies } from 'components/CurrencySettings/useCurrencies'
import SettingsTab from 'components/SettingsPage/SettingsTab'
import arrayMutators from 'final-form-arrays'
import { noReturn } from 'lib/noReturn'
import { FC, memo } from 'react'
import { DollarSign } from 'react-feather'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Switch } from '@sequencehq/core-components'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useDispatch } from 'features/store'
import integratedApiWithTags from 'features/api'
import { useNotifications } from 'lib/hooks/useNotifications'

interface FormValues {
  enabledCurrencies: Currency[]
}

const CurrencySettings: FC = memo(() => {
  const res = useEnabledCurrencies()
  const notifications = useNotifications()
  const globalStoreDispatch = useDispatch()

  const enabledCurrencies =
    res.status === 'SUCCESS' ? res.enabledCurrencies : []

  return (
    <SettingsTab
      title="Configure Currencies"
      summarySection={
        <Flex flexDirection="row">
          <Circle backgroundColor="#F0F4F7" size="37px">
            <DollarSign color="#667085" />
          </Circle>
          <Box width="8px"></Box>
          <Flex flexDirection="column">
            <Box height="12px"></Box>
            <Text>Configure and manage enabled currencies</Text>
          </Flex>
          <Flex grow="1"></Flex>
        </Flex>
      }
    >
      <Form<FormValues>
        keepDirtyOnReinitialize
        destroyOnUnregister
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
            changeValue(state, field, () => value)
          }
        }}
        initialValues={{ enabledCurrencies }}
        onSubmit={async values => {
          const saveRes =
            await dashboard20240730Client.putSequenceAccountSettings({
              enabledCurrencies: values.enabledCurrencies
            })

          if (saveRes.error) {
            notifications.displayNotification(
              'Could not save currency settings',
              {
                type: 'error'
              }
            )
            return
          }

          notifications.displayNotification('Currency settings saved', {
            type: 'success'
          })
          /**
           * The ideal here is to fully eliminate rtk-query in general, but in the interest of keeping
           * the refactor (which introduces the above statement) minimal and focused, we're just going
           * to invalidate the tags here for now.
           */
          globalStoreDispatch(
            integratedApiWithTags.util.invalidateTags(['AccountSettings'])
          )
        }}
        render={({ handleSubmit, submitting }) => (
          <Flex
            as="form"
            px={10}
            py={10}
            flexDirection="column"
            gap="10px"
            onSubmit={noReturn(handleSubmit)}
          >
            <FieldArray<Currency> name="enabledCurrencies">
              {({ fields }) => {
                const currencies = fields.value ?? []
                return (
                  <>
                    {Object.entries(currencyToName).map(([ccy]) => {
                      const currency = ccy as Currency
                      return (
                        <Flex key={currency}>
                          <Switch
                            checked={currencies.includes(currency)}
                            value={currency}
                            data-testid={`currency.${currency}`}
                            onChange={(checked: boolean) => {
                              if (checked) {
                                fields.push(currency)
                              } else {
                                fields.remove(currencies.indexOf(currency))
                              }
                            }}
                          >
                            {currency} - {currencyToName[currency]}
                          </Switch>
                        </Flex>
                      )
                    })}
                  </>
                )
              }}
            </FieldArray>
            <Box height={5} />
            <Flex justifyContent="flex-end">
              <Button
                data-testid="saveCurrencies"
                variant="primary"
                type="submit"
                isDisabled={res.status !== 'SUCCESS'}
                isLoading={submitting}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      />
    </SettingsTab>
  )
})

export default CurrencySettings
