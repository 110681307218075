import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey60,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { match } from 'ts-pattern'

type PriceType = 'LIST' | 'VARIANT'

type EmptyContentProps = {
  priceType: PriceType
}

export const EmptyContent = (props: EmptyContentProps) => {
  const title = useMemo(() => {
    return match(props.priceType)
      .with('LIST', () => 'No list prices')
      .with('VARIANT', () => 'No price variants')
      .exhaustive()
  }, [props.priceType])

  const description = useMemo(() => {
    return match(props.priceType)
      .with(
        'LIST',
        () =>
          'List prices will appear here once you create them for this product.'
      )
      .with(
        'VARIANT',
        () =>
          'Prices from billing schedules will appear here once you create a billing schedule and add prices.'
      )
      .exhaustive()
  }, [props.priceType])

  return (
    <Flex width="full" placeContent="center" py={4}>
      <Flex
        width="265px"
        whiteSpace="pre-line"
        flexDirection="column"
        textAlign="center"
        gap={1}
      >
        <Text {...Lato13Bold}>{title}</Text>
        <Text {...Lato13Regular} color={GreyGrey60}>
          {description}{' '}
          <Link
            style={{ display: 'inline', color: IndigoIndigo50 }}
            to="https://docs.sequencehq.com/plans-products/product-catalogue"
            target="_blank"
          >
            {' '}
            Learn more
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}
