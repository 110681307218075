import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Currency } from '@sequencehq/api/utils/commonEnums'
import {
  GreyGrey20,
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  Lato12Bold,
  Lato13Bold
} from '@sequencehq/design-tokens'
import { useJournalReportSummary } from './useJournalReportSummary'
import { useSelectedDates } from './selectorHooks'
import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { Skeleton } from '@sequencehq/core-components'
import { toMoney } from '@sequencehq/utils'

const cellBorderStyle = {
  _notLast: { borderRight: `1px solid ${GreyGrey30}` }
}

const HeaderRow = () => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr boxShadow={`0 1px 0 ${GreyGrey30}`} borderBottom="none !important">
    <Th width="100%" {...cellBorderStyle}>
      Ledger account
    </Th>
    <Th width="153px" {...cellBorderStyle}>
      Debit
    </Th>
    <Th width="153px">Credit</Th>
  </Tr>
)

const TableRow = (rowData: {
  currency: Currency
  ledgerAccount: string
  debit?: number
  credit?: number
}) => (
  <Tr>
    <Td width="100%" {...cellBorderStyle} cursor="default">
      <Flex
        height="24px"
        alignItems="center"
        borderRadius="6px"
        backgroundColor={GreyGrey20}
        padding="0 6px"
        {...Lato12Bold}
        color={GreyGrey70}
        width="fit-content"
      >
        {rowData.ledgerAccount}
      </Flex>
    </Td>
    <Td width="153px" color={GreyGrey90} {...cellBorderStyle} cursor="default">
      {rowData.debit
        ? toMoney({
            currency: rowData.currency,
            value: rowData.debit.toString()
          })
        : null}
    </Td>
    <Td width="153px" color={GreyGrey90} cursor="default">
      {rowData.credit
        ? toMoney({
            currency: rowData.currency,
            value: rowData.credit.toString()
          })
        : null}
    </Td>
  </Tr>
)

const TotalRow = (totals: {
  currency: Currency
  debit: number
  credit: number
  totalRecords: number
}) => (
  /**
   * We use a box shadow here, instead of a border, because the border
   * will 'follow' the original position of the sticky row when scrolling.
   * An outline doesn't, but an outline can only cover all sides at once.
   * That leaves us with a box shadow to save the day.
   */
  <Tr boxShadow={`0 -1px 0 ${GreyGrey30}`} borderTop="none !important">
    <Td
      width="100%"
      {...Lato13Bold}
      color={GreyGrey90}
      {...cellBorderStyle}
      cursor="default"
    ></Td>
    <Td width="153px" {...cellBorderStyle} cursor="default">
      <Text {...Lato13Bold} color={GreyGrey90}>
        {toMoney({ currency: totals.currency, value: totals.debit.toString() })}
      </Text>
    </Td>
    <Td width="153px" {...Lato13Bold} color={GreyGrey90} cursor="default">
      <Text {...Lato13Bold} color={GreyGrey90}>
        {toMoney({
          currency: totals.currency,
          value: totals.credit.toString()
        })}
      </Text>
    </Td>
  </Tr>
)

const PlaceholderRow = () => (
  <Tr>
    <Td
      width="100%"
      {...Lato13Bold}
      {...cellBorderStyle}
      color={GreyGrey90}
      cursor="default"
    >
      <Skeleton />
    </Td>
    <Td width="153px" {...cellBorderStyle} cursor="default">
      <Skeleton />
    </Td>
    <Td width="153px" {...Lato13Bold} cursor="default">
      <Skeleton />
    </Td>
  </Tr>
)

export const JournalReportCollapsedTable = () => {
  const { currency } = useSelectedCurrency()
  const dates = useSelectedDates()
  const { summary, isLoading } = useJournalReportSummary({
    currency,
    dates
  })

  return (
    <TableContainer
      data-testid="revrec.detail.collapsedTable"
      borderRadius="lg"
      overflow="hidden"
      border={`1px solid ${GreyGrey30}`}
      width="100%"
    >
      <Table variant="v2" width="100%">
        <Thead position="sticky" insetBlockStart={0}>
          <HeaderRow />
        </Thead>
        <Tbody>
          {isLoading ? (
            <>
              <PlaceholderRow />
              <PlaceholderRow />
              <PlaceholderRow />
            </>
          ) : (
            summary.ledgerAccounts.map((ledgerAccount, idx) => (
              <TableRow
                key={idx}
                currency={summary.currency}
                ledgerAccount={ledgerAccount.name}
                debit={ledgerAccount.debit}
                credit={ledgerAccount.credit}
              />
            ))
          )}
        </Tbody>
        <Tfoot position="sticky" insetBlockEnd={0}>
          {isLoading ? (
            <PlaceholderRow />
          ) : (
            <TotalRow
              currency={summary.currency}
              debit={summary.totals.debit}
              credit={summary.totals.credit}
              totalRecords={summary.ledgerAccounts.length}
            />
          )}
        </Tfoot>
      </Table>
    </TableContainer>
  )
}
