import { useNotifications } from 'lib/hooks/useNotifications'
import { useMutation } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export const useCreateTaxCategoryWithMapping = () => {
  const notifications = useNotifications()

  const createTaxCategoryMutator = useMutation(
    dashboardv99990101Client.postTaxCategory
  )

  const createMappingMutator = useMutation(
    dashboardv99990101Client.postTaxCategoryAvalaraMapping
  )

  return async ({
    taxCategoryName,
    avalaraTaxCode
  }: {
    taxCategoryName: string
    avalaraTaxCode: string
  }) => {
    const result = await createTaxCategoryMutator.mutateAsync({
      body: {
        name: taxCategoryName,
        description: '',
        isPublished: true,
        version: 0
      }
    })

    if (!result?.id) {
      notifications.displayNotification('Failed to create tax category', {
        type: 'error'
      })

      return
    }

    await createMappingMutator.mutateAsync(
      {
        taxCategoryId: result.id,
        avalaraTaxCode
      },
      {
        onSuccess: () => {
          notifications.displayNotification('Tax category created', {
            type: 'success'
          })
        },
        onError: () => {
          notifications.displayNotification('Failed to create tax category', {
            type: 'error'
          })
        }
      }
    )
  }
}
