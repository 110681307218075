import { Button, Link } from '@chakra-ui/react'
import { IntegrationService } from '@sequencehq/core-models'
import { createFormModal } from 'components/Form/createFormModal'
import { SelectImportCustomersForm } from 'components/ImportCustomers/SelectImportCustomersForm'
import { Toast } from '@sequencehq/core-components'
import { usePostApiIntegrationsServiceCustomersMutation } from 'features/api'
import { openOverlay } from 'features/overlay'
import { IntegrationMoreInfo } from 'lib/docsLink'
import { ExtractMutationParams } from 'lib/types'
import { integrationName } from 'lib/integrations/integrationName'

export interface SelectImportCustomersProps {
  service: IntegrationService
}

export type SelectImportCustomersFormMutation = ExtractMutationParams<
  typeof usePostApiIntegrationsServiceCustomersMutation
>

export const SelectImportCustomersModal = createFormModal<
  SelectImportCustomersFormMutation,
  SelectImportCustomersProps
>({
  FormComponent: SelectImportCustomersForm,
  useMutation: usePostApiIntegrationsServiceCustomersMutation,
  modalTitleFromProps: (props: SelectImportCustomersProps) =>
    `${integrationName(props.service)} customers`,
  previousModal: dispatch => {
    dispatch(
      openOverlay({
        content: 'importCustomersModal'
      })
    )
  },
  successMessage: 'Customers imported',
  submitTitle: 'Import',
  formId: 'selectImportCustomersForm',
  successToast: response => (
    <Toast
      title="Customer import successful"
      type="success"
      description={`${response.count} customer${
        response.count === 1 ? '' : 's'
      } ${response.count === 1 ? 'has' : 'have'} been imported`}
      isClosable={true}
    ></Toast>
  ),
  leftButton: (
    <Button
      as={Link}
      href={IntegrationMoreInfo.AccountingOverviewLink}
      variant="ghost"
      width="134px"
      isExternal
    >
      More info
    </Button>
  )
})
