import { CustomerContact } from '../domain/CustomerContacts.domain.types'

export function getPrimaryBillingContact(
  contacts: Partial<CustomerContact>[]
): Partial<CustomerContact> | undefined {
  const primaryBillingContact = contacts.find(
    contact => contact.billingPreference === 'PRIMARY'
  )

  return primaryBillingContact
}
