import { Box, Button, Flex, Text } from '@chakra-ui/react'
import {
  CreditNoteModel,
  currencyToSymbol,
  decimalFromPercentage,
  formatInitialLineItemRate,
  IntegrationService,
  LineItemCreditNoteModel,
  normalisedLineItemRate,
  percentageFromDecimal
} from '@sequencehq/core-models'
import { FormErrors, TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  decimal,
  percentage,
  required
} from '@sequencehq/validation'
import { DecimalInput } from 'components/FormComponents/DecimalInput'
import LineItemRateTypeInput from 'components/FormComponents/LineItemRateTypeInput'
import LineItemTypeInput from 'components/FormComponents/LineItemTypeInput'
import {
  usePostCreditNotesByCreditnoteLineItemsMutation,
  usePutCreditNotesByCreditnoteLineItemsByIdMutation
} from 'features/api'
import { handleFormResponse } from 'lib/formValidation/formValidation'
import { noReturn } from 'lib/noReturn/noReturn'
import { FC, useCallback, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { v4 as uuidv4 } from 'uuid'
import { IntegrationLedgerAccountsFields } from './IntegrationLedgerAccountsFields/IntegrationLedgerAccountsFields'
import {
  externalIdsMapToArray,
  toExternalIds
} from './IntegrationLedgerAccountsFields/useIntegrationServices'
import { useXeroIntegration } from 'lib/hooks/useXeroIntegration.ts'

type LineItemCreditNoteFormValues = Omit<
  LineItemCreditNoteModel,
  'externalIds'
> & {
  externalIds?: Partial<Record<IntegrationService, string>>
  itemType: string
  rateType: string
}

interface LineItemSidebarProps {
  creditNote: CreditNoteModel
  lineItem: LineItemCreditNoteModel | undefined
  linkedServices: IntegrationService[]
  updateLineItem: (item: LineItemCreditNoteModel) => void
  groupId?: string
  cancel: () => void
  setHasChanges: () => void
}

const LineItemSidebar: FC<LineItemSidebarProps> = ({
  creditNote,
  lineItem,
  linkedServices,
  updateLineItem,
  groupId,
  cancel,
  setHasChanges
}) => {
  const [update] = usePutCreditNotesByCreditnoteLineItemsByIdMutation()
  const [add] = usePostCreditNotesByCreditnoteLineItemsMutation()
  const [id] = useState<string>(lineItem ? lineItem.id : uuidv4())

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateRate = useCallback(
    composeValidators(required, decimal('10')),
    []
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateVat = useCallback(
    composeValidators(required, decimal('2'), percentage),
    []
  )

  const { xeroIntegration, loading: xeroIntegrationLoading } =
    useXeroIntegration()

  const externalLedgerAccountsValue = xeroIntegration?.ledgerAccounts

  const rate = lineItem?.rate ? lineItem.rate : '0'
  let lineItemIsDiscount = Number.parseFloat(rate) < 0
  let lineItemIsPercentage =
    lineItem?.rateDisplay === 'PERCENTAGE' ? true : false

  return (
    <Form<LineItemCreditNoteFormValues>
      keepDirtyOnReinitialize
      initialValues={
        lineItem
          ? {
              ...lineItem,
              taxRate: percentageFromDecimal(lineItem.taxRate),
              rate: lineItemIsPercentage
                ? percentageFromDecimal(lineItem.rate)
                : formatInitialLineItemRate(lineItem.rate, lineItemIsDiscount),
              itemType: lineItemIsDiscount ? 'discount' : 'product',
              rateType: lineItemIsPercentage ? 'percentage' : 'fixed',
              externalIds: toExternalIds({
                externalIds: lineItem.externalIds,
                xeroIntegration: linkedServices.includes('Xero')
                  ? xeroIntegration
                  : undefined
              })
            }
          : {
              id: id,
              title: '',
              rate: '0',
              quantity: '1',
              taxRate: '0',
              creditNoteId: creditNote.id,
              groupId,
              grossTotal: '0',
              netTotal: '0',
              totalTax: '0',
              description: '',
              index: 0,
              itemType: 'product',
              rateType: 'fixed',
              externalIds: toExternalIds({
                xeroIntegration: linkedServices.includes('Xero')
                  ? xeroIntegration
                  : undefined
              })
            }
      }
      onSubmit={async ({ itemType, rateType, ...values }, form) => {
        lineItemIsDiscount = itemType === 'discount'
        lineItemIsPercentage = rateType === 'percentage' && !lineItemIsDiscount
        const finalRate = lineItemIsPercentage
          ? decimalFromPercentage(values.rate)
          : normalisedLineItemRate(values.rate, lineItemIsDiscount)
        const rateDisplay = lineItemIsPercentage ? 'PERCENTAGE' : 'ABSOLUTE'

        const result = await (lineItem
          ? update({
              creditnote: creditNote.id,
              id: lineItem.id,
              updateCreditNoteLineItemEndpointUpdateCreditNoteLineItemRequestModel:
                {
                  ...lineItem,
                  ...values,
                  taxRate: decimalFromPercentage(values.taxRate),
                  rate: finalRate,
                  rateDisplay,
                  externalIds: values.externalIds
                    ? externalIdsMapToArray(values.externalIds)
                    : []
                }
            })
          : add({
              creditnote: creditNote.id,
              createCreditNoteLineItemEndpointCreateCreditNoteLineItemRequestModel:
                {
                  ...values,
                  taxRate: decimalFromPercentage(values.taxRate),
                  rate: finalRate,
                  rateDisplay,
                  externalIds: values.externalIds
                    ? externalIdsMapToArray(values.externalIds)
                    : []
                }
            }))
        return handleFormResponse(result, form.getRegisteredFields())
      }}
      render={({ handleSubmit, submitting, submitError, values }) => (
        <>
          {lineItem && (
            <Text textStyle="sectionHeader">
              Edit Line Item - {values.title}
            </Text>
          )}
          {!lineItem && (
            <Text textStyle="sectionHeader">Adding new Line Item</Text>
          )}
          <Flex
            as="form"
            px={5}
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            w="100%"
          >
            <FormSpy<LineItemCreditNoteFormValues>
              subscription={{ values: true, dirty: true, valid: true }}
              onChange={state => {
                if (state.dirty && state.valid) {
                  lineItemIsDiscount = state.values.itemType === 'discount'
                  lineItemIsPercentage =
                    state.values.rateType === 'percentage' &&
                    !lineItemIsDiscount

                  const finalRate = lineItemIsPercentage
                    ? decimalFromPercentage(state.values.rate)
                    : normalisedLineItemRate(
                        state.values.rate,
                        lineItemIsDiscount
                      )
                  const rateDisplay = lineItemIsPercentage
                    ? 'PERCENTAGE'
                    : 'ABSOLUTE'

                  const rateAsNumber = parseFloat(finalRate)
                  const quantityAsNumber = parseFloat(values.quantity)
                  const taxRateAsNumber = parseFloat(
                    decimalFromPercentage(values.taxRate)
                  )
                  const netTotal = rateAsNumber * quantityAsNumber
                  const totalTax = taxRateAsNumber * netTotal
                  const grossTotal = netTotal + totalTax

                  lineItem
                    ? updateLineItem({
                        ...lineItem,
                        ...state.values,
                        taxRate: decimalFromPercentage(state.values.taxRate),
                        rate: finalRate,
                        rateDisplay,
                        externalIds: state.values.externalIds
                          ? externalIdsMapToArray(state.values.externalIds)
                          : [],
                        netTotal: netTotal.toString(),
                        totalTax: totalTax.toString(),
                        grossTotal: grossTotal.toString()
                      })
                    : updateLineItem({
                        ...state.values,
                        taxRate: decimalFromPercentage(state.values.taxRate),
                        rate: finalRate,
                        rateDisplay,
                        externalIds: state.values.externalIds
                          ? externalIdsMapToArray(state.values.externalIds)
                          : [],
                        netTotal: netTotal.toString(),
                        totalTax: totalTax.toString(),
                        grossTotal: grossTotal.toString()
                      } as LineItemCreditNoteModel)
                }
                if (state.dirty) {
                  setHasChanges()
                }
              }}
            />
            <FormErrors formError={submitError} />
            <Box h="16px"></Box>
            <LineItemTypeInput isDisabled={!!lineItem} />
            <Box height={2} />
            <LineItemRateTypeInput
              isDisabled={!!lineItem || values.itemType === 'discount'}
            />
            <Box height={2} />
            <TextInputField
              fieldName="title"
              fieldLabel="Title"
              validate={required}
              maxLength={42}
            />
            <Text textStyle="hint" textAlign="right">
              {values.title?.length || 0}/42
            </Text>
            <Box height={2} />
            <DecimalInput
              fieldName="rate"
              fieldLabel="Amount"
              validate={validateRate}
              decimalPlaces="10"
              leftAddon={
                values.rateType === 'percentage' &&
                values.itemType !== 'discount'
                  ? '%'
                  : currencyToSymbol[creditNote.currency]
              }
            />
            <Box height={2} />

            <DecimalInput
              fieldName="quantity"
              fieldLabel="Quantity"
              validate={validateRate}
              decimalPlaces="10"
            />
            <Box height={2} />

            <DecimalInput
              fieldName="taxRate"
              fieldLabel="VAT %"
              decimalPlaces="2"
              validate={validateVat}
              rightAddon="%"
            />

            <Box height={2} />

            <IntegrationLedgerAccountsFields />

            <Box height={5} />
            <Flex justifyContent="flex-end">
              <Button variant="secondary" onClick={cancel}>
                Cancel
              </Button>

              <Box w="16px"></Box>
              <Button
                variant="primary"
                type="submit"
                isDisabled={
                  submitting ||
                  xeroIntegrationLoading ||
                  !externalLedgerAccountsValue
                }
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    />
  )
}

export default LineItemSidebar
