import { Divider, Flex, Text } from '@chakra-ui/react'
import { FC, memo } from 'react'
import { Button, SwitchLineInput } from '@sequencehq/core-components'
import { GreyGrey30, GreyGrey70, GreyGrey90, Lato13Bold, Lato13Regular } from '@sequencehq/design-tokens'
import { useCreditNotesSettings } from 'modules/Settings/view/creditNotesSettings/useCreditNotesSettings.ts'

export const CreditNotesSettings: FC = memo(() => {
  const hook = useCreditNotesSettings()

  return (
    <Flex padding="24px" gap="24px" flexDirection="column">
      <Flex gap="16px" width={'25vw'} direction="column">
        <Text {...Lato13Bold} color={GreyGrey90}>
          Cash credit grants
        </Text>
        <SwitchLineInput
          value={hook.fields.generateCashCreditGrant.value}
          onChange={hook.fields.generateCashCreditGrant.onChange}
          text={
            <Text {...Lato13Bold} color={GreyGrey90}>
              Generate cash credit grants
            </Text>
          }
          styles={{ label: { marginLeft: '0px' } }}
        />

        <Flex flexDirection="column" gap="8px" marginLeft="36px">
          <Text {...Lato13Regular} color={GreyGrey70}>
            This will enable cash credit grants to be generated for each credit note by default. The credit grant is
            created upon sending the credit note. This can be enabled/disabled on each credit note as well.
          </Text>
        </Flex>


      </Flex>


      <Divider borderBottomColor={GreyGrey30}/>

      <Button
        variant="primary"
        disabled={!hook.hasChanges || !hook.ready}
        onClick={hook.onSave}
        style={{
          width: 'fit-content'
        }}
      >
        Save changes
      </Button>
    </Flex>
  )
})
