import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import { ArrowUpRightIcon } from '@heroicons/react/16/solid'
import { useNavigate } from 'react-router-dom'
import { ReactNode } from 'react'

interface Props {
  confirmTitleText: string
  onClose: () => void
  children: ReactNode
}

export const AvalaraBlocker = ({
  confirmTitleText,
  onClose,
  children
}: Props) => {
  const navigate = useNavigate()
  return (
    <ModalContextProvider isOpen>
      <SimpleDialogUI
        data-testid="common.avalaraBlocker"
        title={confirmTitleText}
        cancelButtonText="Cancel"
        closeOnCancel={true}
        closeOnSubmit={true}
        onClose={onClose}
        onCancel={onClose}
        confirmButtonText="Manage tax categories"
        submitButtonProps={{
          trailingIcon: (
            <ArrowUpRightIcon
              style={{ display: 'inline', marginLeft: '4px' }}
              height="16px"
              width="16px"
            />
          )
        }}
        onConfirm={() => navigate('/settings/tax-rates')}
      >
        {children}
      </SimpleDialogUI>
    </ModalContextProvider>
  )
}
