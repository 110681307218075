import { Reducer } from 'react'
import { match } from 'ts-pattern'
import { flow } from 'lodash/fp'
import {
  ListPriceEditorActions,
  ListPriceEditorReducerState,
  PostActionStage
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import * as actions from 'modules/Products/drawers/ListPriceEditor/domain/actionHandlers'
import * as postActionStages from 'modules/Products/drawers/ListPriceEditor/domain/postActionStages'
import { INITIAL_LIST_PRICE_STATE } from 'modules/Products/drawers/ListPriceEditor/useListPriceEditorContext'

/**
 * Only logs when the local storage item is enabled, to allow
 * for simple debugging in any environment without otherwise spamming.
 * @param args
 * @returns
 */
const log = (...args: unknown[]) => {
  if (localStorage.getItem('enable-list-price-editor-logs')) {
    //eslint-disable-next-line no-console
    return console.info(...args)
  }
  return
}

const handleAction =
  (prevState: ListPriceEditorReducerState) =>
  (action: ListPriceEditorActions) => {
    return match(action)
      .with({ type: 'loadListPriceEditor' }, matchedAction =>
        actions.loadListPriceEditor(prevState)(matchedAction)
      )
      .with({ type: 'updateEditor' }, matchedAction =>
        actions.updateEditor(prevState)(matchedAction)
      )
      .with({ type: 'updateConfiguration' }, matchedAction =>
        actions.updateConfiguration(prevState)(matchedAction)
      )
      .with({ type: 'updateData' }, matchedAction =>
        actions.updateData(prevState)(matchedAction)
      )
      .exhaustive()
  }

const logStage =
  (stageName: string): PostActionStage =>
  () =>
  prevState => {
    log(
      `[Pricing Editor] After post action stage: %c${stageName}`,
      'color: lime;',
      {
        state: prevState
      }
    )
    return prevState
  }

/**
 * The order of stage execution matters - executing validation before initializing
 * the form will result in validation being performed against the old state, for
 * example.
 */
const postActionStageOrder: { name: string; stage: PostActionStage }[] = [
  { name: 'Select price', stage: postActionStages.selectPrice },
  { name: 'Initialize form', stage: postActionStages.initializeForm },
  {
    name: 'Transition price model data',
    stage: postActionStages.transitionPriceModelData
  },
  {
    name: 'Sync invoice and product name',
    stage: postActionStages.syncInvoiceAndProductName
  },
  { name: 'Sync form to price', stage: postActionStages.syncFormToNewPrice },
  { name: 'Create queries', stage: postActionStages.listPriceEditorQueries }
]
const handlePostActionStages: PostActionStage = ctx => prevState => {
  return flow(
    postActionStageOrder.flatMap(({ name, stage }) => [
      stage(ctx),
      logStage(name)(ctx)
    ])
  )(prevState) as ListPriceEditorReducerState
}

export const listPriceEditorReducer: Reducer<
  ListPriceEditorReducerState,
  ListPriceEditorActions
> = (prevState = INITIAL_LIST_PRICE_STATE, action) => {
  log(
    `%c[List Price Editor] ======== Start of reducer for action ${action.type} =========`,
    'color: cyan;',
    {
      initialState: prevState,
      action
    }
  )

  const postActionState = handleAction(prevState)(action)

  log(
    `%c[List Price Editor] ======== beginning of post action functionality for action ${action.type} =========`,
    'color: lime;',
    {
      postActionState
    }
  )

  const finalState = handlePostActionStages({
    preActionState: prevState,
    action
  })(postActionState)

  log(
    `%c[List Price Editor] ======== Running side effects =========`,
    'color: hotpink;',
    {
      finalState
    },
    `
    `
  )

  log(
    `%c[List Price Editor] ======== End of reducer for action ${action.type} =========`,
    'color: hotpink;',
    {
      finalState
    },
    `
    `
  )

  return finalState
}
