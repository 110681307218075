import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { NEW_PRICE_PATTERN } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useVariantPriceLayout = (props: { variantPriceId?: string }) => {
  const context = usePricingEditorDomainContext()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)

  const title = useMemo(() => {
    if (
      props.variantPriceId &&
      !props.variantPriceId.match(NEW_PRICE_PATTERN)
    ) {
      return `Edit price`
    }

    return `Configure new price`
  }, [props.variantPriceId])

  const submitButtonText = useMemo(() => {
    if (
      props.variantPriceId &&
      !props.variantPriceId.match(NEW_PRICE_PATTERN)
    ) {
      return `Update price`
    }

    return `Add new price`
  }, [props.variantPriceId])

  const submitDisabled = useMemo(() => {
    return !context.queries.availableFeatures.common.form.available.enabled
  }, [context.queries.availableFeatures.common.form.available.enabled])

  const onSave = useCallback(async () => {
    setSubmitting(true)
    await new Promise(resolve => setTimeout(resolve, 0))
    await context.mutators.external.out.save()
    setSubmitting(false)
  }, [context.mutators.external.out])

  const pricingModel = useMemo(
    () => context.queries.rawData.data.pricingEditorData.common.pricingModel,
    [context.queries.rawData.data.pricingEditorData.common.pricingModel]
  )

  const isListPriceBasedVariant = useMemo(
    () =>
      Boolean(
        context.queries.rawData.data.pricingEditorData.common.listPriceId
      ),
    [context.queries.rawData.data.pricingEditorData.common.listPriceId]
  )

  const formValid = useMemo(
    () => context.queries.rawData.editor.valid,
    [context.queries.rawData.editor.valid]
  )

  const onCancel = useCallback(() => navigate('..'), [navigate])

  return {
    formValid,
    isListPriceBasedVariant,
    onCancel,
    pricingModel,
    submit: {
      action: () => void onSave(),
      text: submitButtonText,
      disabled: submitDisabled
    },
    submitting,
    title
  }
}
