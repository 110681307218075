import type { CreateCustomer, Customer } from 'components/Customers/types'
import { useNotifications } from 'lib/hooks/useNotifications'
import lodash from 'lodash'
import { useAddTaxRegistration } from './useAddTaxRegistration'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'
import { getAvalaraAddressError } from 'components/Customers/utils/getAvalaraAddressError'
import { ErrorResponse } from '@sequencehq/api'

export type CreateCustomerErrorType = 'INVALID_AVALARA_ADDRESS' | 'OTHER'

export type CreateCustomerError = {
  type: CreateCustomerErrorType
}

export const useCreateCustomer = () => {
  const notifications = useNotifications()
  const addCustomer = useMutation(dashboardv99990101Client.postCustomer)
  const { addTaxRegistration } = useAddTaxRegistration()

  return async (
    customer: CreateCustomer
  ): Promise<{
    customer: Customer | null
    error: CreateCustomerError | null
  }> => {
    const filterEmptyValues = (obj: object): object => {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([, value]) => value !== '' && value !== undefined)
          .map(([key, value]) =>
            value && typeof value === 'object' && !Array.isArray(value)
              ? [key, filterEmptyValues(value as object)]
              : [key, value]
          )
      )
    }

    const filteredCustomerBody = filterEmptyValues(customer) as CreateCustomer
    const customerBody = {
      ...lodash.omit(filteredCustomerBody, [
        'taxIdentifier',
        'taxCountry',
        'taxState',
        'addTaxRegistration'
      ])
    }

    try {
      const saveResult = await addCustomer.mutateAsync({ body: customerBody })

      if (!saveResult) {
        return {
          customer: null,
          error: {
            type: 'OTHER'
          }
        }
      }

      if (customer.addTaxRegistration && !!customer.taxIdentifier) {
        const taxRegistrationBody = {
          taxIdentifier: customer.taxIdentifier,
          country: customer.taxCountry,
          state: ['US', 'CA'].includes(customer.taxCountry)
            ? customer.taxState
            : undefined,
          customerId: saveResult.id
        }

        const taxRegistrationResult =
          await addTaxRegistration(taxRegistrationBody)

        if (!taxRegistrationResult) {
          notifications.displayNotification(
            'Failed to create tax registration for customer',
            {
              type: 'error'
            }
          )
        }
      }

      notifications.displayNotification('Customer created', {
        type: 'success'
      })

      return {
        customer: saveResult,
        error: null
      }
    } catch (e) {
      let errorType: CreateCustomerErrorType = 'OTHER'

      if (getAvalaraAddressError(e)) {
        errorType = 'INVALID_AVALARA_ADDRESS'
      }

      return {
        customer: null,
        error: {
          type: errorType
        }
      }
    }
  }
}
