import { Dialog, SimpleDialog, Switch } from '@sequencehq/core-components'
import { FC } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey80,
  GreyGrey90,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { toMoney } from '@sequencehq/utils'
import { useCreditNoteQuery } from 'CreditNotes/hooks/useCreditNote.ts'

type Props = {
  onConfirm: VoidFunction
  trigger: React.ReactNode
  customerLegalCompanyName: string
  customerEmails: string[]
  creditNoteId: string
  onCancel?: VoidFunction
  isIssuingCreditNote?: boolean
  isGrantingCredit?: boolean
  showGrantCredits?: boolean
  onCreditGrantChange: (param: boolean) => void
}

export const CreditNoteIssueDialog: FC<Props> = ({
  onConfirm,
  trigger,
  customerLegalCompanyName,
  customerEmails,
  isIssuingCreditNote,
  isGrantingCredit,
  showGrantCredits,
  creditNoteId,
  onCreditGrantChange
}) => {
  const { data: creditNote } = useCreditNoteQuery(creditNoteId)

  return (
    <SimpleDialog
      title="Send credit note"
      onConfirm={onConfirm}
      trigger={trigger}
      confirmButtonText="Send credit note"
      isSubmitting={isIssuingCreditNote}
      closeOnCancel={!isIssuingCreditNote}
      closeOnSubmit={false}
      confirmDisabled={isGrantingCredit}
    >
      <Text {...Lato13Regular} color={GreyGrey80}>
        Send this credit note to <b>{customerLegalCompanyName}</b> (
        {customerEmails.join(', ')}). After sending you can no longer edit the
        credit note.
      </Text>
      {showGrantCredits && creditNote && (
        <Box paddingTop={4}>
          <Dialog.Note showIcon={false}>
            <Flex flexDirection="column" gap={2}>
              <Switch
                onChange={onCreditGrantChange}
                defaultChecked={
                  creditNote?.settings?.generateCashCreditGrant === 'ENABLED'
                }
                disabled={isIssuingCreditNote}
              >
                <Text {...Lato14Bold} color={GreyGrey90}>
                  Add as cash credit
                </Text>
              </Switch>
              <Text {...Lato13Regular} color={GreyGrey80}>
                Creates a{' '}
                {toMoney(
                  {
                    currency: creditNote.currency,
                    value: creditNote.grossTotal.toString()
                  },
                  '',
                  2
                )}{' '}
                cash credit grant for <b>{customerLegalCompanyName}</b>.
                Available credit is automatically applied to their future
                invoices.
              </Text>
            </Flex>
          </Dialog.Note>
        </Box>
      )}
    </SimpleDialog>
  )
}
