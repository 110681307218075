import {
  CubeReducerState,
  CubeStatus,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getDeleteLatestQuoteDraftAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible:
        !prevState.data.common.isArchived &&
        prevState.data.common.status === CubeStatus.QuoteDraft &&
        !!prevState.data.quote.publishedAt,
      enabled:
        !prevState.data.common.isArchived &&
        prevState.data.common.status === CubeStatus.QuoteDraft &&
        !!prevState.data.quote.publishedAt
    },
    reasons: []
  }
}
