import { useLinearPriceForm } from './useLinearPriceForm'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import {
  HorizontalSelectorField,
  PercentageField,
  PriceField,
  SelectField,
  SelectFieldWithActions,
  TextField
} from '@sequencehq/core-components'
import { Lato14Bold } from '@sequencehq/design-tokens'
import {
  AvailableStandardFrequency,
  LinearPriceType
} from 'common/drawers/PricingEditor/domain'
import { useState } from 'react'
import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { PricingInformation } from '../common/PricingInformation'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'

export const LinearPriceVariantPriceForm = () => {
  const { fieldsConfig, currency, formData, priceDetails } =
    useLinearPriceForm()
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)

  return (
    <>
      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <SelectField
        label="List price"
        data-testid="linear.listPriceId"
        value={fieldsConfig.listPriceId.value ?? ''}
        onChange={fieldsConfig.listPriceId.onChange}
        options={fieldsConfig.listPriceId.options}
        validationErrors={fieldsConfig.listPriceId.validationErrors}
        isDisabled={fieldsConfig.listPriceId.disabled}
        placeholder="Select list price"
      />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="linear.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      {!fieldsConfig.currency.hidden && (
        <SelectField
          label="Currency"
          data-testid="linear.currency"
          value={fieldsConfig.currency.value}
          onChange={newCurrency =>
            fieldsConfig.currency.onChange(newCurrency as Currency)
          }
          options={fieldsConfig.currency.options}
          validationErrors={fieldsConfig.currency.validationErrors}
          isDisabled={fieldsConfig.currency.disabled}
          placeholder="Select currency"
        />
      )}

      <Box paddingBottom="8px">
        <HorizontalSelectorField
          label="Type"
          data-testid="linear.linearPriceType"
          value={fieldsConfig.linearPriceType.value}
          options={fieldsConfig.linearPriceType.options}
          onChange={newValue =>
            fieldsConfig.linearPriceType.onChange(newValue as LinearPriceType)
          }
          validationErrors={fieldsConfig.linearPriceType.validationErrors}
          isDisabled={fieldsConfig.linearPriceType.disabled}
        />
        {formData.linearPriceType === 'FIXED' && (
          <PriceField
            label="Price per unit"
            data-testid="linear.price"
            value={fieldsConfig.price.value}
            onChange={newValue => fieldsConfig.price.onChange(newValue)}
            validationErrors={fieldsConfig.price.validationErrors}
            currency={currency}
            isDisabled={fieldsConfig.price.disabled}
          />
        )}
        {fieldsConfig.linearPriceType.value === 'PERCENTAGE' && (
          <>
            <PercentageField
              label="Percentage"
              data-testid="linear.percentage"
              value={fieldsConfig.percentage.value}
              onChange={newValue => fieldsConfig.percentage.onChange(newValue)}
              validationErrors={fieldsConfig.percentage.validationErrors}
              isDisabled={fieldsConfig.percentage.disabled}
            />
            <Flex flexDirection="row">
              <PriceField
                label="Floor fee (optional)"
                data-testid="linear.minPrice"
                value={fieldsConfig.minPrice.value}
                onChange={newValue => fieldsConfig.minPrice.onChange(newValue)}
                validationErrors={fieldsConfig.minPrice.validationErrors}
                currency={currency}
                isDisabled={fieldsConfig.minPrice.disabled}
                styles={{ wrapper: { marginBottom: '0px' } }}
              />
              <Box width="16px"></Box>
              <PriceField
                label="Fee cap (optional)"
                data-testid="linear.maxPrice"
                value={fieldsConfig.maxPrice.value}
                onChange={newValue => fieldsConfig.maxPrice.onChange(newValue)}
                validationErrors={fieldsConfig.maxPrice.validationErrors}
                currency={currency}
                isDisabled={fieldsConfig.maxPrice.disabled}
                styles={{ wrapper: { marginBottom: '0px' } }}
              />
            </Flex>
          </>
        )}
      </Box>

      <ExternalLedgerForm />

      <Box
        display="flex"
        gap="4px"
        my="16px"
        padding="8px 0px"
        cursor="pointer"
        onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
      >
        <Text {...Lato14Bold} color="#5F5CFF">
          Show additional fields
        </Text>
        {showAdditionalOptions ? (
          <Icon as={ChevronDownIcon} color="#5F5CFF" w="20px" h="20px" />
        ) : (
          <Icon as={ChevronRightIcon} color="#5F5CFF" w="20px" h="20px" />
        )}
      </Box>
      <Box
        width="100%"
        marginBottom="16px"
        visibility={showAdditionalOptions ? 'visible' : 'hidden'}
      >
        <PricingInformation
          value={fieldsConfig.pricingModel.value}
          isDisabled={fieldsConfig.pricingModel.disabled}
          options={fieldsConfig.pricingModel.options}
          validationErrors={fieldsConfig.pricingModel.validationErrors}
          onChange={fieldsConfig.pricingModel.onChange}
          description={fieldsConfig.pricingModel.description}
        />

        <HorizontalSelectorField
          label="Frequency"
          data-testid="linear.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={newValue =>
            fieldsConfig.billingFrequency.onChange(
              newValue as AvailableStandardFrequency
            )
          }
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />
        <SelectFieldWithActions
          label="Usage metric"
          data-testid="linear.usageMetric"
          placeholder="Select usage metric"
          value={fieldsConfig.usageMetricId.value}
          options={fieldsConfig.usageMetricId.options}
          onChange={fieldsConfig.usageMetricId.onChange}
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          isDisabled={fieldsConfig.usageMetricId.disabled}
          onAddNew={fieldsConfig.usageMetricId.onAddNew}
        />
        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>
      </Box>
    </>
  )
}
