import { AggregatedLineItemGroup } from 'CreditNotes/types'

export function mergeLineItemsIntoGroups<
  Group extends { id: string },
  LineItem extends { groupId: string },
  AggregatedGroup = Group & { lineItems: LineItem[] }
>(lineItems: LineItem[], groups: Group[]): AggregatedGroup[] {
  return groups.map(group => {
    return {
      ...group,
      lineItems: lineItems.filter(lineItem => lineItem.groupId === group.id)
    } as AggregatedGroup
  })
}

export function removeUnselectedLineItems(
  lineItemGroup: AggregatedLineItemGroup
) {
  return {
    ...lineItemGroup,
    lineItems: lineItemGroup.lineItems.filter(item => item.selected)
  }
}
