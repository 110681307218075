export const downloadBlob = (fileName: string) => (blob: Blob) => {
  const url = URL.createObjectURL(blob)
  const link = Object.assign(document.createElement('a'), {
    download: fileName,
    href: url
  })

  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(link)

  link.click()
  link.remove()
}
