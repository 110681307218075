import { Flex, Grid } from '@chakra-ui/react'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { IntegrationServices, useQuery } from '@sequencehq/api/dist/utils'
import {
  Banner,
  Button,
  Modal,
  ModalContextProvider
} from '@sequencehq/core-components'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import Spinner from 'components/Loading/Spinner'
import {
  LinkCustomerModal,
  LinkSalesforceCustomerModal,
  LinkXeroCustomerModal,
  useReconnectToIntegration
} from 'Integrations/index'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { FunctionComponent } from 'react'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

const useInvoiceIntegrationWarningBar = () => {
  const invoiceModuleCtx = useInvoiceEditorContext()

  const viableIntegrationsQuery = useQuery(
    dashboardv20240509Client.getIntegrations,
    undefined,
    {
      select: response => {
        if (!response) {
          return []
        }

        return (
          response.items
            .filter(
              int =>
                int.syncedEntityTypes.includes('INVOICE') &&
                int.state === 'CONNECTED'
            )
            .map(int => ({
              syncedEntityTypes: int.syncedEntityTypes,
              service: int.service,
              authFailure: int.authFailure
            })) ?? []
        )
      }
    }
  )

  const customerQuery = useQuery(
    dashboardv99990101Client.getCustomer,
    {
      id: invoiceModuleCtx.data.invoice.customerId
    },
    {
      select: (
        customer
      ): {
        name: string
        externalLinks: Partial<{ [service in IntegrationServices]: string }>
      } | null => {
        if (!customer) {
          return null
        }
        return {
          name: customer.legalName,
          externalLinks: customer.integrationIds.reduce(
            (acc, customerLink) => ({
              ...acc,
              [customerLink.service]: customerLink.id
            }),
            {}
          )
        }
      }
    }
  )

  const authFailures = viableIntegrationsQuery.data
    ?.filter(int => int.authFailure)
    .map(int => ({
      service: int.service,
      serviceName: getIntegrationName(int.service)
    }))

  const missingCustomerLinks = viableIntegrationsQuery.data
    ?.filter(
      int =>
        int.syncedEntityTypes.includes('CUSTOMER') &&
        !customerQuery.data?.externalLinks[int.service]
    )
    /**
     * Don't bother with a warning if we're already showing one for an auth failure
     */
    .filter(int => !authFailures?.find(af => af.service === int.service))
    .map(int => ({
      service: int.service,
      serviceName: getIntegrationName(int.service)
    }))

  const isInvoiceFinalized = ['FINAL', 'SENT'].includes(
    invoiceModuleCtx.data.invoice.status
  )

  const { reconnect, reconnecting } = useReconnectToIntegration()

  return {
    isLoading: viableIntegrationsQuery.isPending || customerQuery.isPending,
    customerName: customerQuery.data?.name,
    customerId: invoiceModuleCtx.data.invoice.customerId,
    missingCustomerLinks,
    authFailures,
    reconnect,
    reconnecting,
    isInvoiceFinalized
  }
}

/**
 * We have different modals for linking customers for different services. this
 * map ensures we will load in the correct one! If a component isn't found here,
 * then we expect to not show the 'Resolve' button.
 */
const customerLinkModalMap: Partial<{
  [service in IntegrationServices]: FunctionComponent<{ customerId: string }>
}> = {
  Xero: ({ customerId }: { customerId: string }) => (
    <LinkXeroCustomerModal customerId={customerId} />
  ),
  Salesforce: ({ customerId }: { customerId: string }) => (
    <LinkSalesforceCustomerModal customerId={customerId} />
  ),
  QuickBooks_Online: ({ customerId }: { customerId: string }) => (
    <LinkCustomerModal customerId={customerId} service="QuickBooks_Online" />
  )
}

export const InvoiceIntegrationWarningBanners = () => {
  const hook = useInvoiceIntegrationWarningBar()

  if (
    hook.isLoading ||
    (hook.missingCustomerLinks?.length === 0 && hook.authFailures?.length === 0)
  ) {
    return null
  }

  return (
    <Flex flexDirection="column" gap="8px" paddingBottom="24px">
      {hook.missingCustomerLinks?.map(int => {
        const ModalComponent = customerLinkModalMap[int.service]

        return (
          <Banner
            variant="warning"
            alignIcon="center"
            key={`${int.service}-customer`}
            data-testid={`invoice.integrationWarnings.${int.service}.customer`}
          >
            <Grid templateColumns="1fr auto" width="100%" alignItems="center">
              Invoice {hook.isInvoiceFinalized ? "can't" : "won't"} be synced to{' '}
              {int.serviceName} because {hook.customerName} isn't linked.
              {ModalComponent && (
                <ModalContextProvider>
                  <Modal.Trigger>
                    <Button variant="secondary">Resolve</Button>
                    <ModalComponent customerId={hook.customerId} />
                  </Modal.Trigger>
                </ModalContextProvider>
              )}
            </Grid>
          </Banner>
        )
      })}
      {hook.authFailures?.map(int => (
        <Banner
          variant="warning"
          alignIcon="center"
          key={`${int.service}-auth`}
          data-testid={`invoice.integrationWarnings.${int.service}.auth`}
        >
          <Grid templateColumns="1fr auto" width="100%" alignItems="center">
            Invoice {hook.isInvoiceFinalized ? "can't" : "won't"} be synced to{' '}
            {int.serviceName} because of an authentication failure.
            <Button
              variant="secondary"
              onClick={() => {
                void hook.reconnect(int.service)
              }}
              disabled={hook.reconnecting}
            >
              <Flex gap="8px" alignItems="center">
                {hook.reconnecting && (
                  <Spinner height="16px" width="16px" color={GreyGrey60} />
                )}{' '}
                {hook.reconnecting ? 'Resolving...' : 'Resolve'}
              </Flex>
            </Button>
          </Grid>
        </Banner>
      ))}
    </Flex>
  )
}
