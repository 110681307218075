import { Box } from '@chakra-ui/react'
import {
  Country,
  State,
  stateFieldName,
  states,
  statesForCountry
} from '@sequencehq/core-models'
import { TextInputField } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { CountrySelect } from 'components/FormComponents/CountrySelect'
import { SelectField } from 'components/FormFields'
import { FC } from 'react'
import { Field } from 'react-final-form'

interface AddressInputProps {
  name: string
}

export const AddressInput: FC<AddressInputProps> = ({ name }) => (
  <Field name={`${name}country`} subscription={{ value: true }}>
    {({ input: { value: country } }) => {
      if (!country) {
        return <CountrySelect name={`${name}country`} />
      }

      return (
        <>
          <CountrySelect name={`${name}country`} value={country} />
          <Box height={2} />
          <TextInputField
            fieldName={`${name}line1`}
            placeholder="Address Line 1"
            validate={required}
          />
          <Box height={2} />
          <TextInputField
            fieldName={`${name}line2`}
            placeholder="Address Line 2 (optional)"
            isOptional
          />
          <Box height={2} />
          <TextInputField
            fieldName={`${name}town`}
            placeholder="City/Town"
            validate={required}
          />
          <Box height={2} />
          <TextInputField
            fieldName={`${name}postcode`}
            placeholder="Postcode"
            validate={required}
          />
          <Box height={2} />
          {statesForCountry(country as Country).length > 0 && (
            <SelectField
              fieldName={`${name}state`}
              placeholder={`Choose ${stateFieldName(country as Country)}...`}
              options={Object.entries(states)
                .filter(([value]) =>
                  statesForCountry(country as Country).includes(value as State)
                )
                .sort(([, label1], [, label2]) => {
                  if (label1 > label2) {
                    return 1
                  } else if (label1 < label2) {
                    return -1
                  } else {
                    return 0
                  }
                })
                .map(([value, label]) => ({
                  value,
                  label
                }))}
              validate={required}
              disabled={statesForCountry(country as Country).length === 0}
            />
          )}
        </>
      )
    }}
  </Field>
)
