import { deleteIntermediateSessionTokenCookie } from 'lib/auth/stytch'

export const redirectToLogin = () =>
  (window.location.href = `${window.location.origin}/auth/login?return_to=${window.location.href}`)

export const redirectToDashboard = () => {
  deleteIntermediateSessionTokenCookie()

  const returnTo = window.localStorage.getItem('return_to')
  const path = returnTo ?? `${window.location.origin}/home`
  window.location.href = path
}
