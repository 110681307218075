import { FC } from 'react'
import { LinkCustomerToServiceWidget } from '../../../common/LinkEntities/LinkCustomerToService'
import { LinkSalesforceCustomerModal } from '../LinkSalesforceCustomerModal/LinkSalesforceCustomerModal'

export const LinkSalesforceAccountWidget: FC<{
  customerId?: string
  canUnlink?: boolean
}> = props => (
  <LinkCustomerToServiceWidget
    canLink={Boolean(props.customerId)}
    customerId={props.customerId ?? ''}
    service="Salesforce"
    externalEntityLabel="account"
    sequenceEntityLabel="customer"
    canUnlink={props.canUnlink}
    linkModal={linkModalProps => (
      <LinkSalesforceCustomerModal {...linkModalProps} />
    )}
    noLinkFoundText={
      props.customerId
        ? undefined
        : 'Select a customer to enable Salesforce account linking'
    }
  />
)
