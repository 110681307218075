import CreateOrEditUsageMetricForm from 'components/CreateUsageMetric/CreateUsageMetricForm'
import Sidebar from 'components/Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { DrawerForm } from 'components/Modal'
import { UsageMetricModel } from '@sequencehq/core-models'
import { useUsageMetric } from 'components/CreateUsageMetric/UsageMetricProvider'
import { FC, useCallback } from 'react'
import {
  Box,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Text
} from '@chakra-ui/react'
import { useGetUsageMetricsIdQuery } from 'features/api'
import Spinner from 'components/Loading/Spinner'

interface EditUsageMetricDrawerProps {
  usageMetricId: string
}

const EditUsageMetricDrawer: FC<EditUsageMetricDrawerProps> = ({
  usageMetricId
}) => {
  const navigate = useNavigate()
  const { dispatch } = useUsageMetric()
  const { data: usageMetricData } = useGetUsageMetricsIdQuery({
    id: usageMetricId
  })

  const onSuccess = useCallback(
    (usageMetric: UsageMetricModel) => {
      dispatch({
        type: 'setUsageMetricId',
        usageMetricId: usageMetric.id
      })
      navigate(`/usage-metrics/${usageMetric.id}`)
    },
    [dispatch, navigate]
  )

  const handleCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  const usageMetric = usageMetricData?.value()

  if (!usageMetric) {
    return <Spinner></Spinner>
  }

  return (
    <Sidebar>
      {usageMetric.aggregationType !== 'CUSTOM' ? (
        <CreateOrEditUsageMetricForm
          formContainer={DrawerForm}
          onSuccess={onSuccess}
          handleCancel={handleCancel}
          existingMetric={usageMetric}
        />
      ) : (
        <DrawerContent>
          <DrawerCloseButton top={4} right={4} />
          <DrawerBody>
            <Text>
              Editing is not available for custom metrics. Please reach out to
              the Sequence team for any changes.
            </Text>
          </DrawerBody>
        </DrawerContent>
      )}
    </Sidebar>
  )
}

const EditUsageMetricDrawerParamsHandler: FC = () => {
  const { metricId } = useParams<{ metricId: string }>()

  if (!metricId) {
    return <Box>Missing metricId url parameter</Box>
  }

  return <EditUsageMetricDrawer usageMetricId={metricId} />
}

export default EditUsageMetricDrawerParamsHandler
