import { DiscountCalculationType, PriceModel } from '@sequencehq/core-models'
import { greaterThan, lessThan, required } from '@sequencehq/validation'
import { DiscountEditorDiscount } from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { ValidationConfig, ValidatorWithMetadata } from '@sequencehq/utils'

const validateNominalValue: ValidatorWithMetadata<
  string,
  {
    calculationType: DiscountCalculationType
  }
> = (value, { calculationType }) => {
  const message = [
    required(value),
    greaterThan(0, "Discount can't be zero")(value)
  ].find(Boolean)
  if (calculationType === 'NOMINAL' && message) {
    return [
      {
        message,
        metadata: {}
      }
    ]
  }
  return []
}

const validatePercentageValue: ValidatorWithMetadata<
  string,
  {
    calculationType: DiscountCalculationType
  }
> = (value, { calculationType }) => {
  const message = [
    required(value),
    greaterThan(0, "Discount can't be zero")(value),
    lessThan(100.01, "Discount can't be more than 100%")(value)
  ].find(Boolean)
  if (calculationType === 'PERCENTAGE' && message) {
    return [
      {
        message,
        metadata: {}
      }
    ]
  }
  return []
}

const validatePrices: ValidatorWithMetadata<
  PriceModel['id'][],
  {
    applyTo: DiscountEditorDiscount['applyTo']
  }
> = (selectedPrices, { applyTo }) => {
  if (applyTo === 'SPECIFIC' && !selectedPrices.length) {
    return [
      {
        message: 'At least one price must be selected',
        metadata: {}
      }
    ]
  }

  return []
}

export const discountValidationConfig: ValidationConfig[] = [
  {
    fieldName: 'name',
    dataLocation: 'name',
    validators: [required]
  },
  {
    fieldName: 'nominalValue',
    dataLocation: 'nominalValue',
    additionalData: {
      calculationType: 'discountCalculationType'
    },
    validators: [validateNominalValue]
  } as ValidationConfig<string>,
  {
    fieldName: 'percentageValue',
    dataLocation: 'percentageValue',
    additionalData: {
      calculationType: 'discountCalculationType'
    },
    validators: [validatePercentageValue]
  } as ValidationConfig<string>,
  {
    fieldName: 'applyTo',
    dataLocation: 'priceIds',
    additionalData: {
      applyTo: 'applyTo'
    },
    validators: [validatePrices]
  } as ValidationConfig<PriceModel['id'][]>
]
