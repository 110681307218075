import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { v1ApiLoadedData } from 'modules/Integrations/communication/external/v1/ports'
import { IntegrationsDomainInput } from 'modules/Integrations/domain'

export const v1IntegrationsAdapter = {
  toDomain: (data: v1ApiLoadedData): IntegrationsDomainInput['data'] => {
    return {
      integrations: arrayToIdKeyedObject(
        data.integrations.map(int => {
          switch (int.service) {
            case 'Xero':
              return {
                id: int.service,
                type: int.type,
                state: int.state,
                configuration: int.configuration,
                enabled: int.enabled,
                authFailure: int.authFailure,
                defaultLedgerAccount: int.defaultLedgerAccounts.chargeAccount,
                creditDefaultLedgerAccount:
                  int.defaultLedgerAccounts.creditAccount
              }
            case 'HubSpot':
              return {
                id: int.service,
                type: int.type,
                state: int.state,
                configuration: int.configuration,
                enabled: int.enabled,
                authFailure: int.authFailure,
                enabledPipelineStages: int.enabledPipelineStages
              }
            default:
              return {
                id: int.service,
                type: int.type,
                state: int.state,
                configuration: int.configuration,
                enabled: int.enabled,
                authFailure: int.authFailure
              }
          }
        })
      ),
      featuredIntegrations: data.featuredIntegrations
    }
  }
}
