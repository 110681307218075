import { SimplePaymentPage } from 'modules/PaymentsApp/components/SimplePaymentPage'
import { XCircle } from 'react-feather'

export const Cancelled = () => {
  return (
    <SimplePaymentPage
      icon={XCircle}
      iconColour="red.60"
      header="Payment cancelled"
      subheader="The payment for this invoice has not been processed."
      nextSteps={[
        'If you did not intend to cancel the payment, please try again or contact the issuer for further assistance',
        'If you intentionally cancelled, note that no payment credentials have been saved and no charges were made to your account'
      ]}
    />
  )
}
