import { Box } from '@chakra-ui/react'
import { Loading } from '@sequencehq/tables'
import { QueryParams, UseQ } from 'lib/types/query'
import { ReactNode } from 'react'

type MagicTableAutoLoaderProps<Query extends QueryParams, ReturnType> = {
  children: (result: ReturnType) => ReactNode
  queryParams: Query['input']
  useQuery: UseQ<Query>
  extract: (model?: Query['response']) => ReturnType
}

const MagicTableAutoLoader = <Query extends QueryParams, ReturnType = string>({
  children,
  queryParams,
  useQuery,
  extract
}: MagicTableAutoLoaderProps<Query, ReturnType>) => {
  const res = useQuery(queryParams)

  if (res.isLoading) {
    return (
      <Box padding="8px 12px">
        <Loading />
      </Box>
    )
  }

  return children(extract(res.data?.value()))
}

export default MagicTableAutoLoader
