import { Box, Flex, Text } from '@chakra-ui/react'
import { Lato16Regular } from '@sequencehq/design-tokens'
import { TextInputField } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { MutationFormProps } from 'components/Form/types'
import {
  SendTestInvoiceEmailFormMutation,
  SendTestEmailFormProps
} from 'components/SendTestEmail/types'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

type FormValues = {
  testEmail: string
}

const SendTestInvoiceEmailForm: FC<
  MutationFormProps<SendTestInvoiceEmailFormMutation> & SendTestEmailFormProps
> = ({ id, submitForm, formId }) => {
  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      onSubmit={async values => {
        await submitForm({
          id,
          testEmail: values.testEmail
        })
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            px={5}
            py={5}
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            <Text {...Lato16Regular}>
              Send a test email with this invoice attached.
            </Text>
            <Box height="20px"></Box>
            <TextInputField
              fieldLabel="Email address"
              fieldName="testEmail"
              placeholder="email"
              validate={required}
            />
          </Flex>
        )
      }}
    />
  )
}

export default SendTestInvoiceEmailForm
