import { useCallback } from 'react'
import {
  CreditNoteModel,
  LineItemCreditNoteModel,
  LineItemGroupCreditNoteModel,
  InvoiceMerchantDetailsModel,
  MerchantModel
} from '@sequencehq/core-models'
import {
  useLazyGetCreditNotesByIdLineItemGroupsAllQuery,
  useLazyGetCreditNotesByIdLineItemsAllQuery,
  useLazyGetCreditNotesByIdQuery,
  useLazyGetInvoiceMerchantDetailsQuery,
  useLazyGetMerchantForSequenceAccountQuery
} from 'features/api'

type UseFetchDataForLocalPdfCreditNote = () => {
  fetchData: ({
    creditNoteId
  }: {
    creditNoteId: CreditNoteModel['id']
  }) => Promise<{
    creditNote: CreditNoteModel
    lineItems: LineItemCreditNoteModel[]
    lineItemGroups: LineItemGroupCreditNoteModel[]
    merchantDetails: InvoiceMerchantDetailsModel
    merchant: MerchantModel
  }>
}

export const useFetchDataForLocalPdfCreditNote: UseFetchDataForLocalPdfCreditNote =
  () => {
    /**
     * Credit note
     */
    const [fetchCreditNote] = useLazyGetCreditNotesByIdQuery()
    const getCreditNote = useCallback(
      async (args: { creditNoteId: CreditNoteModel['id'] }) =>
        fetchCreditNote({ id: args.creditNoteId }).then(response =>
          response.data?.value()
        ),
      [fetchCreditNote]
    )

    /**
     * Line items
     */
    const [fetchLineItems] = useLazyGetCreditNotesByIdLineItemsAllQuery()
    const getLineItems = useCallback(
      async (args: { creditNoteId: CreditNoteModel['id'] }) =>
        fetchLineItems({ id: args.creditNoteId }).then(
          response => response.data?.value()?.items
        ),
      [fetchLineItems]
    )

    /**
     * Line item groups
     */
    const [fetchLineItemGroups] =
      useLazyGetCreditNotesByIdLineItemGroupsAllQuery()
    const getLineItemGroups = useCallback(
      async (args: { creditNoteId: CreditNoteModel['id'] }) =>
        fetchLineItemGroups({ id: args.creditNoteId }).then(
          response => response.data?.value()?.items
        ),
      [fetchLineItemGroups]
    )

    /**
     * Merchant details
     */
    const [fetchMerchantDetails] = useLazyGetInvoiceMerchantDetailsQuery()
    const getMerchantDetails = useCallback(
      async (args: {
        currency: CreditNoteModel['currency']
        country: CreditNoteModel['customerBillingAddress']['country']
        state: CreditNoteModel['customerBillingAddress']['state']
      }) =>
        fetchMerchantDetails({
          currency: args.currency,
          country: args.country,
          state: args.state
        }).then(response => response.data?.value()),
      [fetchMerchantDetails]
    )

    /**
     * Merchant
     */
    const [fetchMerchant] = useLazyGetMerchantForSequenceAccountQuery()
    const getMerchant = useCallback(
      async () => fetchMerchant().then(response => response.data?.value()),
      [fetchMerchant]
    )

    /**
     * Fetch data
     */
    const fetchData = useCallback(
      async ({ creditNoteId }: { creditNoteId: CreditNoteModel['id'] }) => {
        const fetchedCreditNote = await getCreditNote({ creditNoteId })

        if (typeof fetchedCreditNote === 'undefined') {
          throw new Error('fetchedCreditNote is undefined')
        }

        const fetchedLineItems = await getLineItems({ creditNoteId })
        const fetchedLineItemGroups = await getLineItemGroups({ creditNoteId })
        const fetchedMerchantDetails = await getMerchantDetails({
          currency: fetchedCreditNote.currency,
          country: fetchedCreditNote.customerBillingAddress.country,
          state: fetchedCreditNote.customerBillingAddress.state
        })
        const fetchedMerchant = await getMerchant()

        if (
          typeof fetchedCreditNote === 'undefined' ||
          typeof fetchedLineItems === 'undefined' ||
          typeof fetchedLineItemGroups === 'undefined' ||
          typeof fetchedMerchantDetails === 'undefined' ||
          typeof fetchedMerchant === 'undefined'
        ) {
          throw new Error('Incomplete data to generate credit note PDF locally')
        }

        return {
          creditNote: fetchedCreditNote,
          lineItems: fetchedLineItems,
          lineItemGroups: fetchedLineItemGroups,
          merchantDetails: fetchedMerchantDetails,
          merchant: fetchedMerchant
        }
      },
      [
        getCreditNote,
        getLineItemGroups,
        getLineItems,
        getMerchant,
        getMerchantDetails
      ]
    )

    return { fetchData }
  }
