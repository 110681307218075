import { FC } from 'react'

interface QuickbooksIconProps {
  width?: number
  height?: number
}

export const QuickbooksIcon: FC<QuickbooksIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 64 64`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.5711 63.8953C13.7833 62.2942 2.07674 50.7531 0.165973 35.8914C-0.0553243 34.1703 -0.0553243 29.8689 0.165973 28.1472C1.56325 17.2792 8.05122 8.14728 17.818 3.30076C25.566 -0.543595 34.9306 -1.06041 43.0107 1.91086C53.3177 5.70135 60.9565 14.4722 63.3471 25.2608C64.2176 29.1899 64.2176 34.8487 63.3471 38.7779C61.2134 48.4069 54.9041 56.4807 46.1844 60.7389C43.0834 62.2536 40.5676 63.0633 37.1645 63.6425C35.1887 63.9785 30.5893 64.1139 28.5711 63.8953ZM30.5434 35.0466V19.2067L26.2467 19.1534C21.3229 19.1001 19.8573 19.2281 17.9678 19.8558C13.8268 21.2281 10.7724 24.5135 9.68074 28.7696C9.23745 30.4977 9.174 33.0839 9.53484 34.7175C10.5101 39.1325 13.674 42.6729 17.9779 44.1647C19.2754 44.6127 19.5948 44.666 21.2809 44.7225L23.1475 44.7812V42.5305V40.2803L21.7035 40.211C19.4072 40.099 17.7926 39.3854 16.1725 37.7618C14.2143 35.7997 13.4394 32.9799 14.0572 30.0651C14.5532 27.7264 16.1727 25.6858 18.3577 24.6463C19.9645 23.8821 20.624 23.7701 23.5066 23.7701H25.965V37.2632C25.965 44.6841 26.0087 50.8 26.0623 50.8534C26.1159 50.9067 27.146 50.9334 28.3515 50.912L30.5434 50.8587V35.0188L30.5434 35.0466ZM43.856 44.6111C48.2499 43.8996 52.0872 40.931 53.6676 37.0205C54.398 35.213 54.6011 34.1234 54.6126 31.9489C54.6217 30.2454 54.5705 29.711 54.3036 28.72C53.2901 24.9583 50.8228 21.9791 47.3603 20.3353C45.6784 19.5374 44.5602 19.2681 42.5493 19.1769L40.7496 19.0969L40.7885 21.4052L40.8275 23.7135L42.4476 23.7989C44.1912 23.8895 44.8642 24.0762 46.0269 24.7893C47.7461 25.8426 49.0331 27.3653 49.6683 29.0982C50.078 30.2161 50.1755 32.8033 49.8558 34.0738C49.2823 36.3533 47.781 38.2163 45.6788 39.2574C44.1469 40.0158 42.8546 40.2462 40.0879 40.252L37.9396 40.2574V26.6698V13.0829H35.6151H33.2907V28.9227V44.7625L38.1157 44.7615C40.8151 44.7615 43.3437 44.6964 43.856 44.6111Z"
      fill="#2C9F1C"
    />
  </svg>
)
