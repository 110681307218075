import { CurrentUserSelfRequestWrapper } from 'components/CurrentUserId/CurrentUserSelfRequestWrapper'
import { isDemoEnv, isTestEnv } from 'lib/environment/environment'
import { ReactNode } from 'react'

type CurrentUserIdProps = {
  children: (userId: string) => ReactNode
}

export const CurrentUserId = ({ children }: CurrentUserIdProps) => {
  if (isDemoEnv() || isTestEnv()) {
    return children('demo')
  }

  // eslint-disable-next-line react/no-children-prop
  return <CurrentUserSelfRequestWrapper children={children} />
}
