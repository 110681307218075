import { INITIAL_LIST_PRICE_STATE } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.constants'
import {
  ListPriceEditorFormData,
  ListPriceEditorMode,
  ListPriceEditorReducerState,
  Price,
  RecursivePartial
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { listPriceEditorReducer } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditorReducer'
import { useCallback, useReducer } from 'react'

export type ListPriceEditorData = {
  data: ListPriceEditorReducerState['data']
  derived: ListPriceEditorReducerState['derived']
  editor: ListPriceEditorReducerState['editor']
  configuration: ListPriceEditorReducerState['configuration']
  functions: {
    loadListPriceEditor: (
      dataToLoad: Pick<
        ListPriceEditorReducerState,
        'data' | 'configuration' | 'editor'
      >
    ) => void
    updateEditor: (
      updatedEditorState: RecursivePartial<
        ListPriceEditorReducerState['editor']
      >
    ) => void
    reviewPrice: () => void
    updateData: (
      updatedData: RecursivePartial<ListPriceEditorReducerState['data']>
    ) => void
    updateFormData: (
      updatedFormData: RecursivePartial<ListPriceEditorFormData>
    ) => void
    saveNewPrice: () => Promise<void>
    closeEditor: () => void
    fieldIsDisabled: (fieldName: string) => boolean
  }
}
type UseListPriceEditor = (props: {
  onSave: (price: Price) => void | Promise<void>
  onClose: () => void
}) => ListPriceEditorData

export const useListPriceEditor: UseListPriceEditor = ({ onSave, onClose }) => {
  const [state, dispatch] = useReducer(
    listPriceEditorReducer,
    INITIAL_LIST_PRICE_STATE
  )

  const loadListPriceEditor = useCallback(
    (
      dataToLoad: Pick<
        ListPriceEditorReducerState,
        'data' | 'configuration' | 'editor'
      >
    ) => {
      dispatch({
        type: 'loadListPriceEditor',
        payload: dataToLoad
      })
    },
    []
  )

  const updateEditor = useCallback(
    (data: RecursivePartial<ListPriceEditorReducerState['editor']>) => {
      dispatch({
        type: 'updateEditor',
        payload: data
      })
    },
    []
  )

  const updateFormData = useCallback(
    (
      data: RecursivePartial<ListPriceEditorReducerState['data']['formData']>
    ) => {
      dispatch({
        type: 'updateData',
        payload: {
          formData: data
        }
      })
    },
    []
  )

  const updateData = useCallback(
    (data: RecursivePartial<ListPriceEditorReducerState['data']>) => {
      dispatch({
        type: 'updateData',
        payload: data
      })
    },
    []
  )

  const reviewPrice = useCallback(() => {
    if (!state.derived.queries.canSave) {
      dispatch({
        type: 'updateEditor',
        payload: {
          showValidationErrors: true
        }
      })
      return
    }

    dispatch({
      type: 'updateConfiguration',
      payload: {
        mode: ListPriceEditorMode.REVIEW
      }
    })
  }, [state.derived.queries.canSave])

  const saveNewPrice = useCallback(async () => {
    if (!state.derived.queries.canSave) {
      dispatch({
        type: 'updateEditor',
        payload: {
          showValidationErrors: true
        }
      })
      return
    }

    const price = state.derived.queries.selectedPrice

    if (!price) {
      return
    }

    await onSave(price)
  }, [state, onSave])

  const closeEditor = useCallback(() => {
    onClose()
  }, [onClose])

  const fieldIsDisabled = useCallback(() => {
    return false
  }, [])

  return {
    data: state.data,
    derived: state.derived,
    editor: state.editor,
    configuration: state.configuration,
    functions: {
      loadListPriceEditor,
      updateEditor,
      updateFormData,
      updateData,
      reviewPrice,
      saveNewPrice,
      closeEditor,
      fieldIsDisabled
    }
  }
}
