import { CreateTaxRateForm } from 'components/CreateTaxRate/CreateTaxRateForm'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { usePostTaxRatesMutation } from 'features/api'
import { ExtractMutationParams } from 'lib/types'

export type CreateTaxRateFormMutation = ExtractMutationParams<
  typeof usePostTaxRatesMutation
>

export const CreateTaxRateModal = createFormModal<
  CreateTaxRateFormMutation,
  Record<string, never>
>({
  FormComponent: CreateTaxRateForm,
  useMutation: usePostTaxRatesMutation,
  modalTitleFromProps: () => 'Create tax rate',
  successMessage: 'Tax rate created',
  submitTitle: 'Create',
  formId: 'createTaxRateForm',
  successToast: response => (
    <Toast
      title="Tax rate created"
      type="success"
      description={`Tax rate ${response.name} created`}
      isClosable={true}
    ></Toast>
  )
})
