import { Flex, Box, Text } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey70,
  Lato12Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { ParentSize } from '@visx/responsive'
import { ProductGraphPercentBadge } from 'modules/Products/ProductGraphs/ProductGraphPercentBadge'
import { SparklineGraph } from 'modules/Products/ProductGraphs/SparklineGraph'
import {
  DUMMY_SPARKLINE_GRAPH_DATA,
  DUMMY_SPARKLINE_GRAPH_TYPE
} from 'modules/Products/ProductGraphs/dummyData'

type ProductGraphProps = {
  value: string
  label: string
  percentBadge?: number
  // TODO: add graph data
}

export const ProductGraph = ({
  value,
  label,
  percentBadge
}: ProductGraphProps) => (
  // TODO: add loading states
  <Flex flex="1" flexDirection="column" gap="4px">
    <Flex flexDirection="column" gap="4px" marginTop="24px">
      <Flex gap="8px" alignItems="center">
        <Text color={GreyGrey70} {...Lato16Bold}>
          {value}
        </Text>

        {!!percentBadge && <ProductGraphPercentBadge value={percentBadge} />}
      </Flex>

      <Text color={GreyGrey60} textTransform="uppercase" {...Lato12Regular}>
        {label}
      </Text>
    </Flex>

    <Box height="64px" marginBottom="8px">
      <ParentSize>
        {({ width, height }) => (
          <SparklineGraph
            width={width}
            height={height}
            data={DUMMY_SPARKLINE_GRAPH_DATA}
            parseDataX={(d: DUMMY_SPARKLINE_GRAPH_TYPE) => new Date(d.date)}
            parseDataY={(d: DUMMY_SPARKLINE_GRAPH_TYPE) => d.amount}
          />
        )}
      </ParentSize>
    </Box>
  </Flex>
)
