import { Flex, Icon } from '@chakra-ui/react'
import PlusIcon from '@heroicons/react/24/outline/PlusIcon'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import { Currency } from '@sequencehq/core-models'
import { GreyGrey50, GreyGrey70 } from '@sequencehq/design-tokens'
import {
  PercentageInput,
  PriceInput,
  SimpleTableColumn,
  SimpleTableInputProps,
  SimpleTableInvalidRow,
  SimpleTableRowActionsProps
} from '@sequencehq/core-components'
import {
  PricingEditorPercentageTier,
  ValidationError
} from 'modules/Cube/view/common/drawers/priceEditor/drawer/domainManagement/pricingEditor.types'
import { FC, useCallback, useMemo } from 'react'
import { sanitizePercentageTiers } from 'modules/Cube/view/common/drawers/priceEditor/drawer/utils/sanitizePercentageTiers'
import { enforceMinimumPrecision } from '@sequencehq/utils'

const tableInputStyle = {
  border: 'none',
  boxShadow: 'none',
  borderRadius: 'none'
}

const tableColumnsConfig: SimpleTableColumn<
  PricingEditorPercentageTier,
  keyof PricingEditorPercentageTier,
  {
    currency: Currency
  }
>[] = [
  {
    property: 'firstUnit',
    label: 'First Unit',
    bodyComponent: ({ value, config, onChange }) => (
      <PriceInput
        isDisabled
        currency={config.currency}
        value={value ?? '0.00'}
        onChange={onChange}
        variant="table"
        {...tableInputStyle}
      />
    )
  },
  {
    property: 'lastUnit',
    label: 'Last Unit',
    bodyComponent: ({
      value,
      onChange,
      onBlur,
      rowIndex,
      rows,
      disabled,
      config
    }) => {
      return (
        <PriceInput
          isDisabled={disabled || rowIndex === rows.length - 1}
          currency={config.currency}
          value={rowIndex === rows.length - 1 ? '∞' : value ?? ''}
          onChange={newValue => {
            if (rowIndex === rows.length - 1) {
              return
            }
            return onChange(newValue)
          }}
          variant="table"
          onBlur={onBlur}
          placeholder="0.00"
          onFocus={() => {
            if (value === '0.00') {
              onChange('')
            }
          }}
          {...tableInputStyle}
        />
      )
    }
  },
  {
    property: 'unitPercentage',
    label: 'Unit Price',
    bodyComponent: ({ value, onChange, onBlur, disabled }) => (
      <PercentageInput
        isDisabled={disabled}
        onChange={onChange}
        value={value ?? ''}
        onBlur={onBlur}
        placeholder="0.00"
        onFocus={() => {
          if (value === '0.00') {
            onChange('')
          }
        }}
        {...tableInputStyle}
        variant="table"
      />
    )
  },
  {
    property: 'flatFee',
    label: 'Flat Fee',
    bodyComponent: ({ value, onChange, onBlur, disabled, config }) => (
      <PriceInput
        isDisabled={disabled}
        currency={config.currency}
        onChange={onChange}
        value={value ?? ''}
        variant="table"
        onBlur={onBlur}
        placeholder="0.00"
        onFocus={() => {
          if (value === '0.00') {
            onChange('')
          }
        }}
        {...tableInputStyle}
      />
    )
  }
]

type UsePercentageTierTableInput = (props: {
  onChange: (value: PricingEditorPercentageTier[]) => void
  value: PricingEditorPercentageTier[]
  isDisabled: boolean
  currency: Currency
  validationErrors: ValidationError[]
}) => {
  addNewTier: (rowIndex?: number) => void
  fieldConfig: SimpleTableInputProps<
    PricingEditorPercentageTier,
    {
      currency: Currency
    }
  > & {
    invalidRows: SimpleTableInvalidRow<PricingEditorPercentageTier>[]
    onBlur: () => void
  }
  test: {
    onDelete: (id: PricingEditorPercentageTier['id']) => void
    rowActionsAvailable: {
      delete: boolean
      addNewTier: boolean
    }
  }
}

export const usePercentageTierTableInput: UsePercentageTierTableInput =
  props => {
    const rowActionsAvailable = useMemo(() => {
      return {
        delete: props.value.length > 1 && !props.isDisabled,
        addNewTier: !props.isDisabled
      }
    }, [props])

    const onDelete = useCallback(
      (id: PricingEditorPercentageTier['id']) => {
        const newTiers = props.value.filter(tier => tier.id !== id)

        props.onChange(sanitizePercentageTiers(newTiers))
      },
      [props]
    )

    const addNewTier = useCallback(
      (rowIndex: number = props.value.length - 1) => {
        const previousTier = props.value[rowIndex]

        const newTiers = sanitizePercentageTiers([
          ...props.value.slice(0, rowIndex + 1),
          {
            id: crypto.randomUUID(),
            /** These will be calculated and set by the prepare tiers functionality */
            firstUnit: '',
            lastUnit: '',
            unitPercentage: enforceMinimumPrecision(2)(
              previousTier?.unitPercentage
            ),
            flatFee: enforceMinimumPrecision(2)(previousTier?.flatFee),
            minPrice: '',
            maxPrice: ''
          },
          ...props.value.slice(rowIndex + 1)
        ])
        props.onChange(newTiers)
      },
      [props]
    )

    const rowActionsComponent: FC<
      SimpleTableRowActionsProps<PricingEditorPercentageTier>
    > = useMemo(() => {
      return ({ rowData, rowIndex }) => {
        return (
          <Flex alignItems="center" height="100%" padding="0 8px" gap="4px">
            <Icon
              data-test="fixed-delete-tier"
              as={TrashIcon}
              width="20px"
              height="20px"
              {...(rowActionsAvailable.delete
                ? {
                    color: GreyGrey70,
                    cursor: 'pointer'
                  }
                : {
                    color: GreyGrey50
                  })}
              _hover={{
                color: GreyGrey50
              }}
              onClick={() => rowActionsAvailable.delete && onDelete(rowData.id)}
            />
            <Icon
              data-test="fixed-add-new-tier"
              as={PlusIcon}
              width="20px"
              height="20px"
              {...(rowActionsAvailable.addNewTier
                ? {
                    color: GreyGrey70,
                    cursor: 'pointer'
                  }
                : {
                    color: GreyGrey50
                  })}
              cursor="pointer"
              _hover={{
                color: GreyGrey50
              }}
              onClick={() => {
                rowActionsAvailable.addNewTier && addNewTier(rowIndex)
              }}
            />
          </Flex>
        )
      }
    }, [onDelete, addNewTier, rowActionsAvailable])

    const rowValidationErrors: SimpleTableInvalidRow<PricingEditorPercentageTier>[] =
      useMemo(() => {
        return Object.values(
          props.validationErrors.reduce((acc, error) => {
            const tierId = error.metadata?.tierId as string
            if (!tierId) {
              return acc
            }

            if (!acc[tierId]) {
              return {
                ...acc,
                [tierId]: {
                  id: tierId,
                  invalidProperties: [
                    error.metadata
                      ?.property as keyof PricingEditorPercentageTier
                  ]
                }
              }
            }

            return {
              ...acc,
              [tierId]: {
                ...acc[tierId],
                invalidProperties: [
                  ...(acc[tierId]?.invalidProperties ?? []),
                  error.metadata?.property as keyof PricingEditorPercentageTier
                ]
              }
            }
          }, {} as Record<string, SimpleTableInvalidRow<PricingEditorPercentageTier>>)
        )
      }, [props.validationErrors])

    const onChange = useCallback(
      (newValue: PricingEditorPercentageTier[]) => {
        props.onChange(newValue)
      },
      [props]
    )

    const onBlur = useCallback(() => {
      props.onChange(sanitizePercentageTiers(props.value))
    }, [props])

    return {
      addNewTier,
      fieldConfig: {
        columns: tableColumnsConfig,
        rowActions: {
          component: rowActionsComponent
        },
        invalidRows: rowValidationErrors,
        onChange,
        onBlur,
        rows: props.value,
        isDisabled: props.isDisabled,
        config: {
          currency: props.currency
        }
      },
      test: {
        onDelete,
        rowActionsAvailable
      }
    }
  }
