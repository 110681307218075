import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid
} from '@chakra-ui/react'
import { PriceModel } from '@sequencehq/core-models'
import { GreyGrey30, Lato16Bold } from '@sequencehq/design-tokens'
import Loading from 'components/Loading'
import { ProductPriceContent } from 'modules/Products/drawers/ProductPrice/components/ProductPriceContent'

export const ProductPrice = (props: {
  onClose: () => void
  price?: PriceModel
}) => {
  const { price, onClose } = props

  return (
    <Drawer isOpen onClose={onClose}>
      <DrawerOverlay
        display="flex"
        alignContent="center"
        justifyContent="center"
      />
      <DrawerContent
        margin="8px 12px"
        borderRadius="8px"
        style={{
          width: 'fit-content',
          maxWidth: '100%',
          minWidth: '550px'
        }}
      >
        <Grid
          templateAreas={`
            "header"
            "content"
          `}
          templateRows={'56px 1fr '}
          width="100%"
          height="100%"
        >
          <Flex
            gridArea="header"
            borderBottom={`1px solid ${GreyGrey30}`}
            {...Lato16Bold}
            height="56px"
            px="24px"
            alignItems="center"
            justify="space-between"
          >
            Price variant {price ? `for ${price.name}` : ''}
            <DrawerCloseButton
              style={{
                position: 'inherit'
              }}
            />
          </Flex>
          {!price ? <Loading /> : <ProductPriceContent price={price} />}
        </Grid>
      </DrawerContent>
    </Drawer>
  )
}
