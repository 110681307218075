import { Box } from '@chakra-ui/react'
import { Dialog, SimpleDialog } from '@sequencehq/core-components'
import { FC } from 'react'

export const MarkAsPrimaryBillingContactDialog: FC<{
  title: string
  onConfirm: () => void
  onCancel: () => void
  trigger: React.ReactNode
}> = ({ onConfirm, onCancel, title, trigger }) => {
  return (
    <SimpleDialog
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      trigger={trigger}
    >
      <Box mb={4}>
        A primary contact is required for integrations and payment gateways that
        only accept a single email per customer.
      </Box>
      <Dialog.Note>
        Sequence will use this email address when linking this customer to a
        third party integration.
      </Dialog.Note>
    </SimpleDialog>
  )
}
