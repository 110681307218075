import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useReadOnlyDetails } from './useReadOnlyDetails'

const styles = {
  value: {
    color: GreyGrey80
  }
}

export const ReadOnlyDetails = () => {
  const hook = useReadOnlyDetails()

  return (
    <Flex flexDirection="column" gap="8px">
      <Text {...Lato13Bold} color={GreyGrey80}>
        Details
      </Text>
      <Grid
        gridTemplateColumns="150px 1fr"
        columnGap="28px"
        rowGap="12px"
        borderRadius="6px"
        padding="12px 8px"
        backgroundColor={GreyGrey10}
        color={GreyGrey70}
        {...Lato13Regular}
      >
        {hook.priceDetails.map(({ label, value }) => (
          <>
            <Box>{label}</Box>
            <Box {...styles.value}>{value}</Box>
          </>
        ))}
      </Grid>
    </Flex>
  )
}
