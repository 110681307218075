import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey60,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useIntegrations } from 'InvoiceEditor/hooks/useIntegrations'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext.ts'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { FC } from 'react'

type ConfirmSendAndFinaliseInvoiceModalProps = {
  onClose: () => void
  onConfirm: (shouldRefetch: boolean) => void
}

export const ConfirmSendAndFinaliseInvoiceModal: FC<
  ConfirmSendAndFinaliseInvoiceModalProps
> = ({ onConfirm, onClose }) => {
  const { data } = useInvoiceEditorContext()
  const {
    data: { integrations }
  } = useIntegrations()

  const hasIntegrations = integrations.length > 0

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Finalize and send invoice
          </Box>
          <Text
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            Email this invoice to {data?.recipient.customerLegalName}? (
            <Text
              as="span"
              {...Lato14ExtendedRegular}
              wordBreak="break-all"
              color={GreyGrey80}
            >
              {data.recipient.customerEmails.join(', ')}
            </Text>
            ). After sending you can no longer edit the invoice.
          </Text>

          {isSandboxEnv() && (
            <Box
              color={GreyGrey60}
              {...Lato14ExtendedRegular}
              marginBottom="24px"
            >
              Note: No end-customer emails will be sent in {getEnvironment()}.
            </Box>
          )}
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={() => onConfirm(hasIntegrations)}
              height="32px"
              width="100%"
            >
              Finalize and send invoice
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
