import { Grid, GridItem } from '@chakra-ui/react'
import { AvailableServices } from 'modules/Integrations/domain'
import { FeaturedIntegrations } from 'modules/Integrations/view/integrationsBrowserModal/content/home/FeaturedIntegrations'
import { Instructions } from 'modules/Integrations/view/integrationsBrowserModal/content/home/Instructions'

type IntegrationHomeProps = {
  onSelectIntegration: (integration: AvailableServices) => void
}

export const IntegrationsHome = (props: IntegrationHomeProps) => {
  return (
    <Grid
      gridTemplateRows="auto 1fr"
      height="100%"
      width="100%"
      rowGap="8px"
      padding="8px"
    >
      <GridItem>
        <Instructions />
      </GridItem>
      <GridItem>
        <FeaturedIntegrations onSelectIntegration={props.onSelectIntegration} />
      </GridItem>
    </Grid>
  )
}
