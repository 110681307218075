import { Box, Grid, GridItem, Img, Text } from '@chakra-ui/react'
import { Badge } from '@sequencehq/core-components'
import {
  BorderRadius8,
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  GreyWhite,
  Lato12Regular,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { ResolvedIntegration } from 'modules/Integrations/domain'

export const FeaturedIntegrationCard = (
  props: ResolvedIntegration & {
    onClick: () => void
  }
) => {
  return (
    <Grid
      gridTemplateAreas={`"logo blurb" "empty-space labels"`}
      gridTemplateRows={`auto auto`}
      gridTemplateColumns={'auto 1fr'}
      rowGap="12px"
      columnGap="16px"
      padding="16px"
      width="100%"
      minHeight="104px"
      backgroundColor={GreyWhite}
      border={`1px solid ${GreyGrey30}`}
      borderRadius={BorderRadius8}
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.02), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)"
      userSelect="none"
      cursor="pointer"
      onClick={props.onClick}
    >
      <GridItem
        gridArea="logo"
        height="28px"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Img src={props.logoUrl} maxHeight="28px" />
      </GridItem>
      <GridItem gridArea="blurb" overflow="hidden" height="fit-content">
        <Text {...Lato13Bold} color={GreyGrey90} marginBottom="4px">
          {props.title}
        </Text>
        <Text
          {...Lato13Regular}
          color={GreyGrey70}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {props.description}
        </Text>
      </GridItem>
      <GridItem
        gridArea="labels"
        height="24px"
        display="flex"
        gap="8px"
        overflow="hidden"
      >
        <Badge>
          <Box {...Lato12Regular}>{props.type}</Box>
        </Badge>
      </GridItem>
    </Grid>
  )
}
