import { useMemo, useState, useCallback } from 'react'
import type { IntegrationLedgerAccount } from '@sequencehq/core-models'
import type {
  Currency,
  IntegrationServices
} from '@sequencehq/api/dist/utils/commonEnums'
import type { LineItemEditorInterface } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditorContext.ts'
import { useLoadLineItemEditor } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadLineItemEditor.ts'
import type { UsageData } from '@sequencehq/invoice-content'
import { dequal } from 'dequal'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'

export type LineItemEditorLineItem = {
  id: string
  title: string
  description: string | undefined
  quantity: string
  rate: string
  rateType: 'FIXED' | 'PERCENTAGE'
  itemType: 'PRODUCT' | 'DISCOUNT'
  priceId?: string
  taxRate: string
  externalIds: Record<string, IntegrationLedgerAccount['code']>
  taxCategoryId?: string
}

export type LineItemEditorLineItemGroup = {
  id: string
  description: string
  servicePeriodLabel?: string
  lineItems: LineItemEditorLineItem[]
  total?: string
  usageData?: UsageData
  taxCategoryId?: string
}

export type LineItemEditorMode = 'CREATE' | 'EDIT'

type EditorState = {
  canSave: boolean
  showValidationErrors: boolean
  mode: LineItemEditorMode
  canUseTaxCategories: boolean
}

type UseLineItemEditor = (props: {
  lineItemGroup?: LineItemEditorLineItemGroup
  existingLineItem?: LineItemEditorLineItem
  currency: Currency
  customer: any
  mode: LineItemEditorMode
  onCreate: (lineItem: LineItemEditorLineItem) => void
  onDelete: () => void
  onUpdate: (lineItem: LineItemEditorLineItem) => void
}) => LineItemEditorInterface

export const useLineItemEditor: UseLineItemEditor = props => {
  const {
    data: { customer, lineItemGroups }
  } = useInvoiceEditorContext()
  const flags = useFlags()
  const loader = useLoadLineItemEditor({ customer })

  const canUseTaxCategories = useMemo(
    () =>
      !!flags?.showNewTaxManagement &&
      Object.values(lineItemGroups)?.some(group => group.taxCategory?.id),
    [flags.showNewTaxManagement, lineItemGroups]
  )

  const ledgerIntegrations:
    | Record<
        IntegrationServices,
        { id: IntegrationServices; defaultLedgerAccount: string; options: any }
      >
    | Record<string, never> = useMemo(() => {
    if (loader.loading || loader.error) {
      return {}
    }

    const customerExternalIdsKeys =
      customer.integrationIds?.map(({ service }) => service) ?? []

    const customerLedgerIntegrations = (
      loader.data?.ledgerIntegrations ?? []
    ).filter(integration => customerExternalIdsKeys.includes(integration.key))

    return arrayToIdKeyedObject(
      customerLedgerIntegrations.map(
        ({ key, value, defaultLedgerAccount }) => ({
          id: key,
          defaultLedgerAccount,
          options: value
        })
      )
    )
  }, [customer, loader.data?.ledgerIntegrations, loader.error, loader.loading])

  const defaultExternalIds:
    | Record<IntegrationServices, string>
    | Record<string, never> = useMemo(() => {
    if (loader.loading || loader.error) {
      return {}
    }

    return Object.keys(ledgerIntegrations).reduce((acc, integrationKey) => {
      const { defaultLedgerAccount } = ledgerIntegrations[integrationKey]
      if (defaultLedgerAccount) {
        return {
          ...acc,
          [integrationKey]: defaultLedgerAccount
        }
      }

      return acc
    }, {})
  }, [ledgerIntegrations, loader.error, loader.loading])

  const initialState = useMemo(() => {
    return (
      props.existingLineItem ?? {
        id: `new-line-item::${crypto.randomUUID()}`,
        title: '',
        description: '',
        quantity: '1',
        rate: '',
        rateType: 'FIXED' as LineItemEditorLineItem['rateType'],
        itemType: 'PRODUCT' as LineItemEditorLineItem['itemType'],
        taxRate: '0',
        externalIds: defaultExternalIds
      }
    )
  }, [props.existingLineItem])

  const [isValid, setIsValid] = useState<boolean>(true)
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false)
  const [lineItem, setLineItem] = useState<LineItemEditorLineItem>(initialState)

  const editorState: EditorState = useMemo(() => {
    return {
      canSave: !dequal(initialState, lineItem),
      showValidationErrors,
      mode: props.mode,
      canUseTaxCategories
    }
  }, [
    isValid,
    props,
    initialState,
    lineItem,
    showValidationErrors,
    canUseTaxCategories
  ])

  const title = useMemo(() => {
    return editorState.mode === 'CREATE' ? 'Add line item' : 'Edit line item'
  }, [editorState.mode])

  const onDelete = useCallback(() => {
    props.onDelete()
  }, [props])

  const onCreate = useCallback(() => {
    if (!isValid) {
      setShowValidationErrors(true)
      return
    }

    props.onCreate(lineItem)
  }, [lineItem, props, isValid])

  const onUpdate = useCallback(() => {
    if (!isValid) {
      setShowValidationErrors(true)
      return
    }

    props.onUpdate(lineItem)
  }, [lineItem, props, isValid])

  const updateLineItem = useCallback(
    (newData: Partial<LineItemEditorLineItem>) => {
      setLineItem(prev => ({
        ...prev,
        ...newData
      }))
    },
    []
  )

  const onValidationChange = useCallback(
    (newValidity: boolean) => setIsValid(newValidity),
    []
  )

  const currency = useMemo(() => {
    return props.currency
  }, [props.currency])

  const prices = useMemo(() => {
    if (loader.loading || loader.error) {
      return {}
    }

    const domainPrices = (loader.data?.prices ?? []).map(price => ({
      id: price.id,
      pricingType: price.structure.pricingType
    }))

    return arrayToIdKeyedObject(domainPrices)
  }, [loader.data?.prices, loader.error, loader.loading])

  const data = useMemo(() => {
    return {
      currency,
      title,
      lineItem,
      prices,
      ledgerIntegrations
    }
  }, [currency, title, lineItem, prices, ledgerIntegrations])

  return {
    data,
    editor: editorState,
    functions: {
      onCreate,
      onDelete,
      onUpdate,
      onValidationChange,
      updateLineItem
    }
  }
}
