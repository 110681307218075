import { FormFields, useForm } from '@sequencehq/utils'
import { greaterThan, required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import {
  AvailableStandardFrequency,
  PricingEditorReducerState
} from 'common/drawers/PricingEditor/domain'
import {
  defaultAvailableFrequenciesOptions,
  PRICING_MODEL_OPTIONS
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useCommonFieldConfig } from '../common/useCommonFieldConfig'
import { useUsageMetricParameterFieldConfig } from 'Products/drawers/ListPriceEditor/components/ListPriceForm/PriceForm/utils/useUsageMetricParameterFieldConfig'
import { useNavigate } from 'react-router-dom'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'

type CommonFields =
  PricingEditorReducerState['data']['pricingEditorData']['common']
type PackagedPriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['PACKAGED']

type PackagedPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel' | 'currency'
> & {
  pricingModel: FormFields<CommonFields>['pricingModel'] & {
    description: string
  }
  currency: FormFields<CommonFields>['currency'] & {
    hidden: boolean
  }
} & Omit<FormFields<PackagedPriceFields>, 'usageMetricId'> & {
    usageMetricId: FormFields<PackagedPriceFields>['usageMetricId'] & {
      onAddNew: () => void
    }
  }

type UsePackagedPriceForm = () => {
  fieldsConfig: PackagedPriceFormFieldConfig
  currency: Currency
  priceDetails: { label: string; value: string }[]
}

const standardPriceFrequencies: {
  value: AvailableStandardFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

export const usePackagedPriceForm: UsePackagedPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const navigate = useNavigate()

  const availableFrequencies = useMemo(() => {
    return standardPriceFrequencies.filter(frequency => {
      return pricingEditorContext.queries.rawData.configuration.availableStandardFrequencies.includes(
        frequency.value
      )
    })
  }, [
    pricingEditorContext.queries.rawData.configuration
      .availableStandardFrequencies
  ])

  const availableUsageMetrics = useMemo(() => {
    return Object.values(
      pricingEditorContext.queries.rawData.data.usageMetrics
    ).map(metric => ({
      label: `${metric.name} (${metric.aggregationType})`,
      value: metric.id
    }))
  }, [pricingEditorContext.queries.rawData.data.usageMetrics])

  const commonFields = useCommonFieldConfig<
    PackagedPriceFields & CommonFields
  >()
  const parameterFields = useUsageMetricParameterFieldConfig<
    PackagedPriceFields & CommonFields
  >(
    pricingEditorContext.queries.rawData.data.pricingEditorData.PACKAGED
      .usageMetricId,
    () => false
  )

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.PACKAGED
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'usageMetricId',
        options: availableUsageMetrics,
        validation: [required]
      },
      {
        property: 'billingFrequency',
        options: availableFrequencies,
        validation: [required]
      },
      {
        property: 'packageSize',
        validation: [required, greaterThan(0, "Package size can't be zero")]
      },
      {
        property: 'pricePerPackage',
        validation: [
          required,
          greaterThan(0, "Price per package can't be zero")
        ]
      },
      ...parameterFields.fields
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      const { name, pricingModel, currency, ...rest } = newData

      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name,
          pricingModel,
          currency
        },
        PACKAGED: rest
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      pricingModel: {
        ...fields.pricingModel,
        description:
          PRICING_MODEL_OPTIONS.find(
            option => option.value === queries.formData.pricingModel
          )?.description ?? ''
      },
      currency: {
        ...fields.currency,
        hidden: Boolean(
          pricingEditorContext.queries.rawData.configuration.currency
        )
      },
      usageMetricId: {
        ...fields.usageMetricId,
        onAddNew: () => navigate('./metrics/new')
      }
    }
  }, [
    fields,
    navigate,
    pricingEditorContext.queries.rawData.configuration.currency,
    queries.formData.pricingModel
  ])

  const priceDetails = useMemo(
    () => [
      ...commonPriceDetails({
        taxCategoryName:
          pricingEditorContext.queries.rawData.data.product.taxCategoryName,
        pricingModel: 'PACKAGED',
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Usage metric',
        value:
          fields.usageMetricId.options.find(
            opt => opt.value === queries.formData.usageMetricId
          )?.label ?? 'Not selected'
      }
    ],
    [
      fields.usageMetricId.options,
      queries.formData.billingFrequency,
      queries.formData.billingType,
      queries.formData.usageMetricId
    ]
  )

  return {
    fieldsConfig: enhancedFields,
    currency: queries.formData.currency,
    priceDetails
  }
}
