import { PostActionStage } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { set } from 'lodash/fp'

export const syncInvoiceAndProductName: PostActionStage =
  ({ preActionState }) =>
  prevState => {
    /**
     * If the product name hasn't changed, don't do anything
     */
    if (preActionState.data.product.name === prevState.data.product.name) {
      return prevState
    }
    /**
     * If the invoice name is not tracking the product name, then we do not need to
     * sync the value
     */
    if (
      prevState.data.formData.common.name &&
      prevState.data.formData.common.name !== preActionState.data.product.name
    ) {
      return prevState
    }

    /**
     * Otherwise, align the invoice name with the product name
     */
    return set(
      'data.formData.common.name',
      prevState.data.product.name
    )(prevState)
  }
