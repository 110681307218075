import { createFormModal } from 'components/Form/createFormModal'
import SendInvoicePaymentReminderForm from 'components/SendInvoicePaymentReminder/SendInvoicePaymentReminderForm'
import {
  SendInvoicePaymentReminderFormMutation,
  SendInvoicePaymentReminderFormProps
} from 'components/SendInvoicePaymentReminder/types'
import { Toast } from '@sequencehq/core-components'
import { usePostInvoicesByIdSendPaymentReminderMutation } from 'features/api'

const SendInvoicePaymentReminderModal = createFormModal<
  SendInvoicePaymentReminderFormMutation,
  SendInvoicePaymentReminderFormProps
>({
  FormComponent: SendInvoicePaymentReminderForm,
  useMutation: usePostInvoicesByIdSendPaymentReminderMutation,
  modalTitle: 'Send payment reminder',
  successMessage: 'Payment reminder sent',
  submitTitle: 'Send',
  formId: 'sendInvoicePaymentReminderForm',
  successToast: () => (
    <Toast
      type="success"
      title={`Payment reminder sent`}
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})

export default SendInvoicePaymentReminderModal
