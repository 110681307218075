import {
  CreateUsageMetricDrawer,
  UsageMetricProvider
} from 'components/CreateUsageMetric'
import { useUsageMetricsCreateUsageMetricDrawerConnector } from 'components/UsageMetrics/drawers/useUsageMetricsCreateUsageMetricDrawerConnector'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * @returns
 */
export const UsageMetricsCreateUsageMetricsRouteComponent = () => {
  const usageMetricDrawerProps =
    useUsageMetricsCreateUsageMetricDrawerConnector()

  return (
    <UsageMetricProvider>
      <CreateUsageMetricDrawer {...usageMetricDrawerProps} />
    </UsageMetricProvider>
  )
}
