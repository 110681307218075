import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'
import { useCallback } from 'react'

type Body = DashboardApi20240730.PostTaxRegistration.TaxRegistrationBody & {
  customerId: string
}
type UseUpdateTaxRegistration = () => {
  updateTaxRegistration: (
    id: string,
    updateTaxRegistrationBody: Body
  ) => Promise<DashboardApi20240730.PostTaxRegistration.TaxRegistration | null>
}

export const useUpdateTaxRegistration: UseUpdateTaxRegistration = () => {
  const updateTaxRegistration = useCallback(
    async (id: string, updateTaxRegistrationBody: Body) => {
      const response = await dashboard20240730Client
        .putTaxRegistration({
          id,
          body: updateTaxRegistrationBody
        })
        .then(result => {
          if (result?.error) {
            return null
          }

          return result?.data
        })
      return response
    },
    []
  )
  return {
    updateTaxRegistration
  }
}
