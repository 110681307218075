import {
  Box,
  Button,
  Grid,
  GridItem,
  IconButton,
  MenuButton,
  Text
} from '@chakra-ui/react'
import { Badge, KebabMenu, PageTitle } from '@sequencehq/core-components'
import {
  InvoicePaymentStatus,
  toInvoiceStatusBadgeProps
} from '@sequencehq/core-models'
import { borderColor } from '@sequencehq/core-theme'
import {
  GreyGrey100,
  GreyGrey20,
  GreyGrey60,
  GreyGrey80,
  GreyWhite,
  Lato14Regular,
  Lato18Regular,
  Lato20Bold,
  Spacing8
} from '@sequencehq/design-tokens'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import { useHeader } from 'InvoiceEditor/components/Header/useHeader'
import SpinnerContainer from 'components/Loading/SpinnerContainer'
import {
  PaidIcon,
  PartiallyPaidIcon,
  UncollectibleIcon,
  UnpaidIcon
} from 'components/icons'
import { FC } from 'react'
import { IconSelect } from 'InvoiceEditor/components/IconSelect'
import CheckCircleIcon from '@heroicons/react/16/solid/CheckCircleIcon'
import EyeIcon from '@heroicons/react/16/solid/EyeIcon'

export const Header: FC = () => {
  const {
    data,
    send,
    finalise,
    sendAndFinalise,
    sendPaymentReminder,
    updatePaymentStatus,
    preview,
    menuItems
  } = useHeader()

  if (!data) {
    return <SpinnerContainer />
  }

  return (
    <Grid
      templateColumns="auto auto auto 1fr"
      backgroundColor={GreyWhite}
      width="100%"
      alignContent="center"
      alignItems="center"
      height="64px"
      px={6}
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor={borderColor}
      columnGap={Spacing8}
      padding="0 16px 0 24px"
    >
      {data.isInvoiceReady && <PageTitle title={[data.title, 'Invoices']} />}

      <GridItem color={GreyGrey100} {...Lato20Bold}>
        {data.title}
      </GridItem>
      <GridItem color={GreyGrey60} {...Lato18Regular}>
        for {data.total}
      </GridItem>
      <GridItem>
        <Badge
          {...toInvoiceStatusBadgeProps({ status: data.status })}
          containerStyle={{
            borderRadius: '6px',
            fontWeight: 600,
            padding: '4px 6px',
            fontSize: '12px'
          }}
        />
      </GridItem>
      <Grid justifyContent="right" autoFlow="column" alignItems="center">
        {data.calculatedAt && (
          <Text {...Lato14Regular} color={GreyGrey60} marginRight="12px">
            {data.calculatedAt}
          </Text>
        )}

        {preview.available && (
          <Button
            variant="secondary2"
            marginRight={Spacing8}
            onClick={preview.onClick}
            height="36px"
            color={GreyGrey80}
          >
            <Box marginRight="6px">
              <EyeIcon height={16} width={16} color={GreyGrey80} />
            </Box>
            Preview invoice
          </Button>
        )}
        {finalise.available && (
          <Button
            variant="secondary2"
            marginRight={Spacing8}
            onClick={finalise.onClick}
            isDisabled={finalise.disabled}
            height="36px"
            color={GreyGrey80}
          >
            <Box marginRight="6px">
              <CheckCircleIcon height={16} width={16} color={GreyGrey80} />
            </Box>
            Finalize
          </Button>
        )}
        {send.available && (
          <Button
            variant="primary2"
            marginRight={Spacing8}
            onClick={send.onClick}
            isDisabled={send.disabled}
            height="36px"
            paddingBottom="0"
          >
            Send Invoice
          </Button>
        )}
        {sendAndFinalise.available && (
          <Button
            data-testid="sendAndFinalize"
            variant="primary2"
            marginRight={Spacing8}
            onClick={sendAndFinalise.onClick}
            isDisabled={sendAndFinalise.disabled}
            height="36px"
            paddingBottom="0"
          >
            Review & send
          </Button>
        )}
        {updatePaymentStatus.available && (
          <IconSelect
            value={data.paymentStatus}
            options={[
              { icon: <UnpaidIcon />, value: 'UNPAID', label: 'Unpaid' },
              {
                icon: <PartiallyPaidIcon />,
                value: 'PARTIALLY_PAID',
                label: 'Partially paid'
              },
              {
                icon: <UncollectibleIcon />,
                value: 'UNCOLLECTIBLE',
                label: 'Uncollectible'
              },
              { icon: <PaidIcon />, value: 'PAID', label: 'Paid' }
            ]}
            data-testid="paymentStatusDropdown"
            onChange={(newValue: string) =>
              updatePaymentStatus.onChange(newValue as InvoicePaymentStatus)
            }
            styles={{
              button: {
                marginRight: Spacing8,
                height: '36px'
              }
            }}
          />
        )}
        {sendPaymentReminder.available && (
          <Button
            variant="primary2"
            marginRight={Spacing8}
            onClick={sendPaymentReminder.onClick}
            height="36px"
            paddingBottom="0"
          >
            Send reminder
          </Button>
        )}
        <KebabMenu
          menuItems={menuItems}
          renderListInPortal
          buttonComponent={
            <MenuButton
              as={IconButton}
              variant="unstyled"
              data-testid="invoiceMenu"
              icon={
                <EllipsisHorizontalIcon
                  style={{ height: '24px', width: '24px' }}
                />
              }
              onClick={e => {
                e.stopPropagation()
              }}
              minWidth="initial"
              width="40px"
              height="40px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="6px"
              _hover={{
                backgroundColor: GreyGrey20
              }}
            />
          }
        />
      </Grid>
    </Grid>
  )
}
