import { useQuery } from '@sequencehq/api/dist/utils'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { useMemo } from 'react'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'

type UseLinkCustomerToService = (props: {
  customerId: string
  service: IntegrationServices
  canUnlink?: boolean
}) => {
  isLoaded: boolean
  customerName: string | undefined
  serviceName: string
  linkedAccount: {
    value: string | undefined
    href: string | undefined
    label: string
    canUnlink: boolean
    isPending: boolean
  }
}

export const useLinkCustomerToService: UseLinkCustomerToService = props => {
  const customerResponse = useQuery(
    dashboardv99990101Client.getCustomer,
    {
      id: props.customerId
    },
    {
      select: data => {
        return {
          name: data?.legalName,
          linkedAccount: (data?.integrationIds ?? []).find(
            int => int.service === props.service
          )
        }
      }
    }
  )

  /**
   * Create the data, and handlers, for driving the display of the linked
   * account.
   */

  const serviceName = useMemo(() => {
    return getIntegrationName(props.service)
  }, [props.service])

  const currentLinkedAccount = customerResponse.data?.linkedAccount

  return {
    isLoaded: !customerResponse.isPending,
    customerName: customerResponse.data?.name,
    serviceName,
    linkedAccount: {
      value: currentLinkedAccount?.id,
      href: currentLinkedAccount?.url,
      label: currentLinkedAccount?.id ?? '',
      canUnlink: props.canUnlink ?? true,
      isPending: currentLinkedAccount?.isPending ?? false
    }
  }
}
