import { useState } from 'react'

import { Avatar, Box, Image } from '@chakra-ui/react'

import { Customer } from 'components/Customers/types'
import { getLogoSrc } from 'Customer/utils/logo'

export const CustomerLogo = ({ customer }: { customer: Customer }) => {
  const [isLogoError, setIsLogoError] = useState(false)

  const logoSrc = getLogoSrc(customer)

  if (isLogoError || !logoSrc) {
    return <Avatar name={customer.legalName} mr={2} />
  }

  return (
    <Box
      display="flex"
      width="48px"
      height="48px"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={logoSrc} width="100%" onError={() => setIsLogoError(true)} />
    </Box>
  )
}
