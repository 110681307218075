import { CircularProgress } from '@chakra-ui/react'
import { IndigoIndigo30, IndigoIndigo50 } from '@sequencehq/design-tokens'
import { useEffect, useState } from 'react'

/**
 * All hail the mighty progress donut!
 *
 * A lovely circular version of the progress bar, complete
 * with an animated fill
 * @param percentage - Current progress percentage, has a cap of 100
 * @param size - the height/width of the donut
 * @param thickness - the thickness of the donut 'path'
 * @param animation - configuration for the animation
 *   - delayBeforeStart - the delay before the animation starts
 *   - duration - the duration of the animation
 * @returns
 */
export const ProgressDonut = (props: {
  percentage: number
  size?: string
  thickness?: string
  animation?: {
    delayBeforeStart?: number
    duration?: number
  }
}) => {
  /**
   * This setup, of staggering state, powers the animation by giving us
   * an initial number (in this case, zero!) to transition from.
   */
  const [percentageToShow, setPercentageToShow] = useState(0)

  useEffect(() => {
    if (props.percentage !== percentageToShow) {
      setTimeout(() => {
        setPercentageToShow(Math.min(props.percentage, 100))
      }, props.animation?.delayBeforeStart ?? 0)
    }
  }, [props.percentage, percentageToShow, props.animation?.delayBeforeStart])

  return (
    <CircularProgress
      thickness={props.thickness ?? '16px'}
      size={props.size ?? '16px'}
      value={percentageToShow}
      color={IndigoIndigo50}
      trackColor={IndigoIndigo30}
      sx={{
        '& > div:first-child': {
          transition: `width ${
            props.animation?.duration ?? 500
          }ms cubic-bezier(0.165, 0.84, 0.44, 1)`
        }
      }}
    />
  )
}
