import { Center, Spinner } from '@chakra-ui/react'

import { useCustomerContactsRoot } from './useCustomerContacts.root'
import { CustomerContactsProvider } from '../communication/internal/CustomerContacts.domain.context'
import { ReactNode } from 'react'

export const CustomerContactsRoot = ({
  children,
  customerId
}: {
  children: ReactNode
  customerId?: string
}) => {
  const { customerContactsContext, ready } = useCustomerContactsRoot(customerId)

  if (!ready) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <CustomerContactsProvider value={customerContactsContext}>
      {children}
    </CustomerContactsProvider>
  )
}
