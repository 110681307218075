export const OutlookIcon = ({
  height = 20,
  width = 20,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_164_23831)">
        <path
          d="M12.9886 4.24038V8.15249L14.3558 9.01333C14.3918 9.02385 14.47 9.0246 14.506 9.01333L20.39 5.04639C20.39 4.5769 19.952 4.24038 19.7049 4.24038H12.9886Z"
          fill="#0072C6"
        />
        <path
          d="M12.9887 9.61202L14.2364 10.4691C14.4121 10.5983 14.624 10.4691 14.624 10.4691C14.4129 10.5983 20.3907 6.62761 20.3907 6.62761V13.8178C20.3907 14.6006 19.8897 14.9288 19.3263 14.9288H12.9879V9.61202H12.9887Z"
          fill="#0072C6"
        />
        <path
          d="M6.52859 7.80845C6.10342 7.80845 5.76464 8.00826 5.5145 8.40714C5.26436 8.80601 5.13892 9.33408 5.13892 9.99136C5.13892 10.6584 5.26436 11.1857 5.5145 11.5733C5.76464 11.9617 6.09291 12.1547 6.49854 12.1547C6.91694 12.1547 7.24896 11.9662 7.49385 11.5891C7.73873 11.212 7.86192 10.6884 7.86192 10.0192C7.86192 9.32131 7.74324 8.77822 7.50511 8.38986C7.26699 8.00225 6.94173 7.80845 6.52859 7.80845Z"
          fill="#0072C6"
        />
        <path
          d="M0.609314 2.25352V17.5587L12.2525 20V0L0.609314 2.25352ZM8.40049 12.4935C7.90847 13.141 7.26697 13.4655 6.47523 13.4655C5.70377 13.4655 5.07579 13.1516 4.58978 12.5243C4.10453 11.8963 3.86115 11.0791 3.86115 10.071C3.86115 9.00657 4.10753 8.14573 4.60105 7.48845C5.09457 6.83117 5.74809 6.50216 6.56161 6.50216C7.33007 6.50216 7.95204 6.81615 8.42603 7.44564C8.90077 8.07512 9.13814 8.90441 9.13814 9.93427C9.13889 10.9927 8.89251 11.846 8.40049 12.4935Z"
          fill="#0072C6"
        />
      </g>
      <defs>
        <clipPath id="clip0_164_23831">
          <rect
            width="19.7814"
            height="20"
            fill="white"
            transform="translate(0.609314)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
