import { getNewPrice } from 'modules/Products/drawers/ListPriceEditor/domain/actionHandlers/addPrice'
import { PostActionStage } from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'

/**
 * A hook to select a new price when there is no price currently selected.
 * @returns
 */
export const selectPrice: PostActionStage = () => prevState => {
  const selectedPrice = prevState.editor?.selectedPrice
    ? prevState.data.prices[prevState.editor?.selectedPrice]
    : null

  if (!selectedPrice) {
    const newPriceId = `new-price::${crypto.randomUUID()}`
    const newPrice = getNewPrice(prevState)(newPriceId)

    return {
      ...prevState,
      data: {
        ...prevState.data,
        prices: {
          ...prevState.data.prices,
          [newPriceId]: {
            ...newPrice,
            id: newPriceId
          }
        }
      },
      editor: {
        ...prevState.editor,
        selectedPrice: newPriceId
      }
    }
  }

  return prevState
}
