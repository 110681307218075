import { useOutletContext, Outlet } from 'react-router-dom'
import {
  useSeatBalanceContext,
  SeatBalanceContextType
} from 'modules/Seats/SeatBalances/hooks/useSeatBalanceContext'

export const useSeatBalanceOutletContext = () =>
  useOutletContext<SeatBalanceContextType>()

export const SeatBalanceOutletProvider = () => {
  const seatBalanceContext = useSeatBalanceContext()

  return <Outlet context={seatBalanceContext} />
}
