import { FormFields, useForm } from '@sequencehq/utils'
import deepmerge from 'deepmerge'
import { useCallback, useEffect, useMemo, useState } from 'react'
import sequenceLogo from 'modules/Integrations/integrationsConfig/common/sequenceLogo.svg'
import { XeroIntegrationConfig } from 'modules/Integrations/integrationsConfig/xero/xero.integration.config'
import { ConfigurationManagementProps } from 'Integrations/domain'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type UseXeroConfigurationManagement = (props: ConfigurationManagementProps) => {
  loading: boolean
  fieldsConfig: {
    defaultLedgerAccount: FormFields<XeroIntegrationConfig>['defaultLedgerAccount'] & {
      options: Array<{ value: string; label: string }>
    }
    untaxedCustomerTaxCode: FormFields<XeroIntegrationConfig>['untaxedCustomerTaxCode'] & {
      options: Array<{ value: string; label: string }>
    }
    defaultCreditLedgerAccount: FormFields<XeroIntegrationConfig>['defaultCreditLedgerAccount'] & {
      options: Array<{ value: string; label: string }>
    }
    defaultMinimumLedgerAccount: FormFields<XeroIntegrationConfig>['defaultMinimumLedgerAccount'] & {
      options: Array<{ value: string; label: string }>
    }
    importCustomerWorkflow: FormFields<XeroIntegrationConfig>['importCustomerWorkflow'] & {
      logoUrls: {
        from: string
        to: string
      }
      status: 'ACTIVE' | 'INACTIVE'
    }
  }
}

type Option = {
  value: string
  label: string
}

type SessionOptions = {
  ledgers: Array<Option>
  taxCodes: Array<Option>
}

/**
 * We make use of session storage as a cache for these options. They have
 * low volatility, so there's little risk of them being out of date in the
 * context of a session. By doing this, we speed up the loading of the page
 * when returning to it, without any danger of stale options being present
 * on subsequent loads.
 *
 * Naturally, if session storage is blocked this wont work, but them's the
 * breaks. Progressive enhancement, innit.
 * @returns
 */
const loadOptionsFromSessionStorage = (): SessionOptions => {
  try {
    const options = sessionStorage.getItem('integrations.xeroOptions')

    return options
      ? (JSON.parse(options) as SessionOptions)
      : { ledgers: [], taxCodes: [] }
  } catch {
    return { ledgers: [], taxCodes: [] }
  }
}

const saveOptionsToSessionStorage = (options: SessionOptions) => {
  try {
    sessionStorage.setItem('integrations.xeroOptions', JSON.stringify(options))
  } catch {
    return
  }
}

export const useXeroConfigurationManagement: UseXeroConfigurationManagement =
  props => {
    const sessionStorageOptions = useMemo(
      () => loadOptionsFromSessionStorage(),
      []
    )

    const [optionsLoaded, setOptionsLoaded] = useState(
      sessionStorageOptions.ledgers?.length > 0 &&
        sessionStorageOptions.taxCodes?.length > 0
    )

    const [options, setOptions] = useState<{
      ledgerAccounts: Array<Option>
      taxCodes: Array<Option>
    }>({
      ledgerAccounts: sessionStorageOptions.ledgers,
      taxCodes: sessionStorageOptions.taxCodes
    })

    const loadOptions = useCallback(async () => {
      setOptionsLoaded(false)
      const res = await dashboard20240730Client.getIntegration('Xero')
      const integration = res.data

      if (!integration) {
        setOptionsLoaded(true)
        return
      }

      const formattedLedgerOptions = (
        integration as DashboardApi20240730.GetIntegration.XeroIntegration
      ).ledgerAccounts.options.map(opt => ({
        label: `${opt.name} (${opt.code})`,
        value: opt.code
      }))

      const formattedTaxCodeOptions = (
        integration as DashboardApi20240730.GetIntegration.XeroIntegration
      ).taxCodes.options.map(opt => ({
        label: `${opt.name} (${opt.taxType})`,
        value: opt.taxType
      }))

      saveOptionsToSessionStorage({
        ledgers: formattedLedgerOptions,
        taxCodes: formattedTaxCodeOptions
      })
      setOptions({
        ledgerAccounts: formattedLedgerOptions,
        taxCodes: formattedTaxCodeOptions
      })

      setOptionsLoaded(true)
    }, [])

    const { fields } = useForm({
      value: (props.integration.configuration ?? {}) as XeroIntegrationConfig,
      showValidationErrors: props.showValidationErrors,
      fieldConfiguration: [
        {
          property: 'defaultLedgerAccount',
          disabled: () =>
            !props.integration.configuration?.importCustomerWorkflow
        },
        {
          property: 'defaultCreditLedgerAccount',
          disabled: () =>
            !props.integration.configuration?.importCustomerWorkflow
        },
        {
          property: 'defaultMinimumLedgerAccount',
          disabled: () =>
            !props.integration.configuration?.importCustomerWorkflow
        },
        {
          property: 'untaxedCustomerTaxCode',
          disabled: () =>
            !props.integration.configuration?.importCustomerWorkflow
        },
        {
          property: 'importCustomerWorkflow'
        }
      ],
      onChange: newData => {
        props.updateConfiguration(newData)
      },
      onValidationStateChange: isValid => {
        props.updateValidationState(isValid)
      }
    })

    const enhancedFields = useMemo(() => {
      return deepmerge(fields, {
        defaultLedgerAccount: {
          options: options.ledgerAccounts
        },
        defaultCreditLedgerAccount: {
          options: options.ledgerAccounts
        },
        defaultMinimumLedgerAccount: {
          options: options.ledgerAccounts
        },
        untaxedCustomerTaxCode: {
          options: options.taxCodes
        },
        importCustomerWorkflow: {
          logoUrls: {
            from: props.integration.smallLogoUrl,
            to: sequenceLogo
          },
          status: props.integration.configuration?.importCustomerWorkflow
            ? ('ACTIVE' as const)
            : ('INACTIVE' as const)
        }
      })
    }, [fields, options, props.integration])

    useEffect(() => {
      if (!optionsLoaded) {
        void loadOptions()
      }
    }, [loadOptions, optionsLoaded])

    return {
      loading: !optionsLoaded,
      fieldsConfig: enhancedFields
    }
  }
