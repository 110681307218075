import { Box, Flex, Image } from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import xeroCover from './xeroCover.png'

export const XeroIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={xeroCover}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        Connect Sequence with Xero to automate your invoicing and accounting.
        Import your customers from Xero to speed up onboarding and link invoices
        and credit notes seamlessly. Keep financial records in sync, reduce
        manual data entry, and ensure accurate ledger accounts. Whether you bill
        in multiple currencies or need to finalize invoices, our integration
        simplifies the entire process.
      </Box>
    </Flex>
  )
}
