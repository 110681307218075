import {
  ActionHandler,
  CubeReducerState,
  UpdateAction
} from 'modules/Cube/domain/cube.domain.types'
import deepmerge from 'deepmerge'

export const updateData: ActionHandler<UpdateAction> = prevState => action => {
  return {
    ...prevState,
    data: deepmerge(prevState.data, action.payload, {
      arrayMerge: (_, source: unknown[]) => source
    })
  } as CubeReducerState
}
