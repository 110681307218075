import { useCallback, useEffect, useState } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useLoadAllItems } from '@sequencehq/api/src/utils/useLoadAllItems.ts'
import { UsageMetricModel } from '@sequencehq/core-models'

export const useFetchUsageMetrics = () => {
  const [metrics, setMetrics] = useState<UsageMetricModel[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const loadMetrics = useLoadAllItems(dashboard20240730Client.getUsageMetrics)

  const fetchMetrics = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const metricsData = await loadMetrics()

      if (metricsData) {
        setMetrics(metricsData as UsageMetricModel[])
      }
    } catch (e) {
      setError(e as Error)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchMetrics()
  }, [fetchMetrics])

  return { metrics, loading, error, refetch: fetchMetrics }
}
