import { FC, memo } from 'react'
import EmptyState from 'components/Loading/EmptyState'
import { OverlayCreateCreditNoteModal } from 'features/overlay/overlay'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CreditNotesView } from 'components/CreditNotes/types'
import ArrowDownTrayIcon from '@heroicons/react/16/solid/ArrowDownTrayIcon'
import { menuItemBuilders } from 'components/CreditNotes/creditNotesTableConfig'
import {
  MagicTableCell,
  MagicTableCellInvoiceStatus,
  MagicTable,
  useMagicTableInfiniteQuery
} from '@sequencehq/tables'
import {
  CreditNoteStatus,
  creditNoteStatus,
  toMoney,
  toCreditNoteStatusBadgeProps
} from '@sequencehq/core-models'
import {
  formatISODate,
  localDateWithFormat,
  pastRelativeToDate
} from '@sequencehq/formatters'
import { Badge, KebabMenu } from '@sequencehq/core-components'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import Page from 'components/Page'
import { useCreditNotePdfDownload } from 'components/CreditNote/useCreditNotePdfDownload'
import { useCreditNotesCsvExport } from 'components/CreditNotes/useCreditNotesCsvExport'
import { useSearchParams } from 'react-router-dom'
import { GreyGrey80, Spacing8 } from '@sequencehq/design-tokens'
import { CreditNotesExportToCsv } from 'components/CreditNotes/CreditNotesExportToCsv'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import {
  CalendarIcon,
  IdentificationIcon,
  ViewColumnsIcon
} from '@heroicons/react/16/solid'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { apiQueryClient } from 'features/api/apiQueryClient.ts'
import { CreditNote } from 'CreditNotes/types.ts'
import { formatDateRange } from '@sequencehq/utils/dist/dates'

const CreditNotes: FC = memo(() => {
  const flags = useFlags()
  const { downloadPdf } = useCreditNotePdfDownload()
  const { download: downloadCsvExport } = useCreditNotesCsvExport()
  const [searchParams] = useSearchParams()

  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()

  const { infiniteQuery, setQueryParams } = useMagicTableInfiniteQuery(
    dashboardv99990101Client.getCreditNotes
  )

  const modalContent: OverlayCreateCreditNoteModal = {
    content: 'createCreditNoteModal',
    data: {
      customer: undefined
    }
  }

  const emptyContent = {
    title: 'No credit notes',
    description:
      'Use credit notes to make corrections to previously issued invoices.',
    linkCopy: 'Learn more about credit notes.',
    docLink: `https://docs.sequencehq.com/invoicing/credit-notes`,
    overlayPayload: modalContent,
    buttonCopy: 'Add new credit note'
  }

  const statusFilterOptions = () => {
    return (Object.keys(creditNoteStatus.keys) as CreditNoteStatus[]).map(
      status => ({
        label: toCreditNoteStatusBadgeProps({ status }).children,
        value: status
      })
    )
  }

  const sentFilterOptions = [
    {
      label: '1 week ago',
      value: pastRelativeToDate(new Date(), { weeks: 1 })
    },
    {
      label: '1 month ago',
      value: pastRelativeToDate(new Date(), { months: 1 })
    },
    {
      label: '3 months ago',
      value: pastRelativeToDate(new Date(), { months: 3 })
    },
    {
      label: '6 months ago',
      value: pastRelativeToDate(new Date(), { months: 6 })
    }
  ]

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Credit Notes"
          addNewConfig={{
            buttonText: 'New credit note',
            overlayPayload: {
              content: 'createCreditNoteModal',
              data: {
                customer: undefined
              }
            }
          }}
          paddingBottom={0}
          menuButtons={<CreditNotesExportToCsv />}
        >
          <CurrentUserId>
            {userId => (
              <MagicTable<CreditNotesView>
                entityNamePlural="credit notes"
                onQueryParamsChanged={setQueryParams}
                infiniteQuery={infiniteQuery}
                rowPath={(row: CreditNotesView['model']) =>
                  `/credit-notes/${row.id}`
                }
                sequenceUserId={userId}
                columns={[
                  {
                    id: 'grossTotal',
                    header: 'Amount',
                    accessorFn: row => ({
                      currency: row.currency,
                      value: row.grossTotal
                    }),
                    cell: value => (
                      <MagicTableCell
                        text={toMoney(
                          value.getValue<{
                            currency: CreditNote['currency']
                            value: CreditNote['grossTotal']
                          }>()
                        )}
                        textAlign={value.column.columnDef.meta?.textAlign}
                      />
                    ),
                    meta: { textAlign: 'right' }
                  },
                  {
                    id: 'status',
                    header: 'Status',
                    accessorKey: 'status',
                    cell: value => (
                      <MagicTableCellInvoiceStatus
                        invoiceStatus={value.getValue<CreditNote['status']>()}
                      />
                    )
                  },
                  {
                    id: 'creditNoteNumber',
                    header: 'Credit Note #',
                    accessorKey: 'creditNoteNumber',
                    cell: value => (
                      <MagicTableCell
                        text={
                          value.getValue<CreditNote['creditNoteNumber']>() ||
                          'Pending'
                        }
                      />
                    )
                  },
                  {
                    id: 'customer',
                    header: 'Customer',
                    accessorFn: row => ({
                      customerId: row.customerId
                    }),
                    cell: value => {
                      const { customerId } = value.getValue<{
                        customerId: CreditNote['customerId']
                      }>()

                      return (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          height="40px"
                          pl="6px"
                        >
                          <CustomerPreviewCardPill
                            customerId={customerId}
                            variant="ghost"
                          />
                        </Flex>
                      )
                    }
                  },
                  {
                    id: 'billingPeriod',
                    header: 'Billing Period',
                    accessorFn: row => ({
                      billingPeriodStart: row.billingPeriodStart,
                      billingPeriodEnd: row.billingPeriodEnd
                    }),
                    cell: value => {
                      const { billingPeriodStart, billingPeriodEnd } =
                        value.getValue<{
                          billingPeriodStart: CreditNote['billingPeriodStart']
                          billingPeriodEnd: CreditNote['billingPeriodEnd']
                        }>()

                      return (
                        <MagicTableCell
                          text={
                            billingPeriodStart && billingPeriodEnd
                              ? formatDateRange({
                                  from: new Date(billingPeriodStart),
                                  to: new Date(billingPeriodEnd)
                                })
                              : ''
                          }
                        />
                      )
                    }
                  },
                  {
                    id: 'issueDate',
                    header: 'Sent',
                    accessorKey: 'issueDate',
                    cell: value => (
                      <MagicTableCell
                        text={
                          localDateWithFormat(
                            value.getValue<CreditNote['issueDate']>(),
                            'd MMM'
                          ) || ''
                        }
                      />
                    )
                  }
                ]}
                emptyState={props => (
                  <EmptyState emptyContent={emptyContent} {...props} />
                )}
                kebabMenu={(model: CreditNote, props) => (
                  <KebabMenu
                    menuItems={menuItemBuilders(downloadPdf)
                      .map(builder =>
                        builder(model, {
                          onSuccess: () => {
                            void apiQueryClient.invalidateQueries({
                              queryKey:
                                dashboardv99990101Client.getCreditNotes.queryKey
                            })
                          }
                        })
                      )
                      .filter(builder => builder.status === 'LIVE')}
                    flags={flags}
                    {...props}
                  />
                )}
                filters={[
                  {
                    type: 'multiSelect',
                    paramName: 'customerId',
                    label: 'Customer',
                    icon: IdentificationIcon,
                    options: [],
                    optionsFunc: fetchCustomerNameOptions,
                    format: (value: string) => <Text>{value}</Text>
                  },
                  {
                    type: 'multiSelect',
                    paramName: 'creditNoteStatus',
                    options: statusFilterOptions(),
                    format: status => (
                      <Badge
                        {...toCreditNoteStatusBadgeProps({
                          status: status as CreditNoteStatus
                        })}
                      />
                    ), // Remove casting once we've got support for multiple Value types in Filters
                    label: 'Status',
                    optionsSortFn: () => 0,
                    icon: ViewColumnsIcon
                  },
                  {
                    type: 'date',
                    paramName: 'sent',
                    paramNameBefore: 'sentBefore',
                    paramNameAfter: 'sentAfter',
                    optionsBefore: sentFilterOptions,
                    optionsAfter: sentFilterOptions,
                    format: (_value, label) => label,
                    label: 'Sent',
                    stringifyDate: date => formatISODate(date),
                    icon: CalendarIcon
                  }
                ]}
                additionalToolbarComponents={
                  <>
                    {downloadCsvExport.available && (
                      <Button
                        variant="secondary2"
                        marginRight={Spacing8}
                        onClick={() =>
                          downloadCsvExport.onClick(searchParams.toString())
                        }
                        height="36px"
                        color={GreyGrey80}
                      >
                        <Box marginRight="6px">
                          <ArrowDownTrayIcon
                            height={16}
                            width={16}
                            color={GreyGrey80}
                          />
                        </Box>
                        Export to CSV
                      </Button>
                    )}
                  </>
                }
              />
            )}
          </CurrentUserId>
        </Page>
      </Flex>
    </Flex>
  )
})

export default CreditNotes
