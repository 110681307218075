import { Box, Flex } from '@chakra-ui/react'
import { TextInputField } from '@sequencehq/forms'
import {
  composeValidators,
  required,
  sixDigitNumber,
  swiftCode
} from '@sequencehq/validation'
import { FC } from 'react'

export const AustralianBankAccountFields: FC<{
  fieldName: string
}> = ({ fieldName }) => {
  return (
    <Flex flexDirection="column">
      <Flex>
        <Box width="200px">
          <TextInputField
            fieldLabel="Account Number"
            fieldName={`${fieldName}.value.australianDestination.accountNumber`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Legal Name"
            fieldName={`${fieldName}.value.australianDestination.legalName`}
            validate={composeValidators(required)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="250px">
          <TextInputField
            fieldLabel="BSB"
            fieldName={`${fieldName}.value.australianDestination.bsb`}
            validate={composeValidators(required, sixDigitNumber)}
          />
        </Box>
        <Box width="10px"></Box>
        <Box width="200px">
          <TextInputField
            fieldLabel="Swift Code"
            fieldName={`${fieldName}.value.australianDestination.swiftCode`}
            validate={composeValidators(required, swiftCode)}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
