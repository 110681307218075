import { ServerAsset } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CoverImageBlock, QuoteBlock } from '@sequencehq/quote-content'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'

const useSelectPresentation = () => {
  const cubeContext = useCubeContext()
  return cubeContext.queries.rawData.data.presentation
}

const useSelectCoverImageBlock = () => {
  const coverImageBlock = useSelectPresentation().find(
    block => block.blockType === 'coverImage'
  )
  if (coverImageBlock && isCoverImageBlock(coverImageBlock)) {
    return coverImageBlock
  }
}

export const useSelectCoverImageSrc = () => {
  return useSelectCoverImageBlock()?.config.asset.url
}

export const useStoreCoverImageAsset = () => {
  const cubeContext = useCubeContext()

  const presentation = useSelectPresentation()
  const existingBlock = useSelectCoverImageBlock()

  return (asset: ServerAsset | null) => {
    let newPresentation = []

    if (asset === null) {
      if (existingBlock) {
        cubeContext.mutators.updateData({
          presentation: presentation.filter(
            block => block.blockType !== 'coverImage'
          )
        })
      }
      return
    }

    const newCoverImageBlock: QuoteBlock = {
      blockType: 'coverImage',
      version: 1,
      config: {
        asset
      }
    }

    if (existingBlock) {
      newPresentation = presentation.map(block =>
        block.blockType === 'coverImage' ? newCoverImageBlock : block
      )
    } else {
      newPresentation = [newCoverImageBlock, ...presentation]
    }

    cubeContext.mutators.updateData({
      presentation: newPresentation
    })
  }
}

const isCoverImageBlock = (block: QuoteBlock): block is CoverImageBlock => {
  return block.blockType === 'coverImage'
}
