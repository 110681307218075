import { Box, Flex, Link, Text } from '@chakra-ui/react'
import InformationCircleIcon from '@heroicons/react/16/solid/InformationCircleIcon'
import { InfoPopover, XYChart } from '@sequencehq/core-components'
import {
  GreyGrey40,
  GreyGrey50,
  GreyGrey60,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { ChartTooltip } from 'components/InsightsV2/common/ChartTooltip'
import { FilterSelectInput } from 'components/InsightsV2/common/FilterSelectInput/FilterSelectInput'
import { WidgetLoading } from 'components/InsightsV2/common/WidgetLoading/WidgetLoading'
import { useUsageMetricsWidget } from 'components/InsightsV2/widgets/UsageMetricsWidget/useUsageMetricsWidget'
import { format, formatDateRange } from '@sequencehq/utils/dates'

interface UsageMetricsWidgetProps {
  dateRange: {
    start: Date
    end: Date
  }
}

export const UsageMetricsWidget = ({ dateRange }: UsageMetricsWidgetProps) => {
  const {
    isLoading,
    usageMetricTypeField,
    chart: { data, total, unit, unitTickValues, tooltipFormat }
  } = useUsageMetricsWidget(dateRange)

  if (isLoading) {
    return <WidgetLoading />
  }

  return (
    <Box
      width="390px"
      height="291px"
      boxShadow="0 2px 4px 0 rgba(20, 23, 28, 0.08)"
      border={`1px solid ${GreyGrey40}`}
      borderRadius="lg"
      p={4}
    >
      <Flex gap={2}>
        <Flex flexGrow="1" flexDirection="column" gap={2}>
          <Text as={Flex} {...Lato13Bold} color={GreyGrey60} gap={1}>
            Usage Metrics
            <InfoTooltip />
          </Text>
          <Text {...Lato20Bold} color={GreyGrey90}>
            {total.toLocaleString('en-US')}
          </Text>
          <Text {...Lato13Regular} color={GreyGrey60}>
            {formatDateRange({
              from: new Date(dateRange.start),
              to: new Date(dateRange.end)
            })}
          </Text>
        </Flex>
        <Flex width="120px" alignSelf="baseline">
          {usageMetricTypeField?.options?.length > 0 && (
            <FilterSelectInput
              placeholder="Select metric"
              value={usageMetricTypeField.value}
              options={usageMetricTypeField.options}
              onChange={usageMetricTypeField.onChange}
            />
          )}
        </Flex>
      </Flex>
      <Box mt={4} height="170px" width="100%">
        <XYChart
          variant="line"
          dateFrom={format(dateRange.start, 'yyyy-MM-dd')}
          dateTo={format(dateRange.end, 'yyyy-MM-dd')}
          data={data}
          unitTickValues={unitTickValues}
          unit={unit}
          renderTooltip={({ date, value }) => (
            <ChartTooltip>
              <Text {...Lato13Bold} color="gray.70">
                {format(new Date(date), 'EEE MMM d, yyyy')}
              </Text>
              <>
                <Text
                  as={Flex}
                  {...Lato13Regular}
                  color="gray.70"
                  alignItems="center"
                  gap={2}
                >
                  <Flex h={2} w={2} bg={IndigoIndigo50} borderRadius="full" />
                  {format(new Date(date), 'EEE MMM d, yyyy')}
                </Text>
                <Text {...Lato13Bold} color="gray.80">
                  {tooltipFormat(value)}
                </Text>
              </>
            </ChartTooltip>
          )}
        />
      </Box>
    </Box>
  )
}

const InfoTooltip = () => {
  return (
    <InfoPopover
      variant="info"
      minimal
      padding="0"
      placement="top"
      body={
        <Box width={300}>
          Usage metrics aggregate units of usage into overall measures of how
          your users interact with your product.{' '}
          <Link
            href="https://docs.sequencehq.com/metrics-events/usage-metrics"
            isExternal
            {...Lato14Bold}
            color="indigo.50"
          >
            Learn more
          </Link>
        </Box>
      }
      icon={<InformationCircleIcon height={16} width={16} color={GreyGrey50} />}
    />
  )
}
