import { Flex } from '@chakra-ui/react'

import TaxCategoriesSection from 'Settings/view/taxRatesSettings/TaxCategoriesSection'
import TaxRatesSection from 'Settings/view/taxRatesSettings/TaxRatesSection'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useLoadAllItemsQuery } from '@sequencehq/api/utils'

export const TaxRatesSettings = () => {
  const { data: taxCategoriesData, isLoading: isLoadingTaxCategories } =
    useLoadAllItemsQuery(dashboardv99990101Client.getTaxCategories)

  const { data: taxRatesData, isLoading: isLoadingTaxRates } =
    useLoadAllItemsQuery(dashboardv99990101Client.getTaxRates)

  const taxCategories =
    taxCategoriesData?.pages.flatMap(page => page.items) ?? []
  // Temporary measure to map tax category name to every tax rate
  // will be removed once backend provides tax category names on the tax rate object
  const taxRates = (taxRatesData?.pages.flatMap(page => page.items) ?? []).map(
    taxRate => ({
      ...taxRate,
      taxCategoryName: taxCategories.find(
        category => taxRate.taxCategoryId === category.id
      )?.name
    })
  )

  return (
    <Flex width="100%" flexDirection="column" overflow="auto" gap={4} p={4}>
      <TaxCategoriesSection
        taxCategories={taxCategories}
        isLoadingTaxCategories={isLoadingTaxCategories}
      />

      <TaxRatesSection
        taxRates={taxRates}
        isLoadingTaxRates={isLoadingTaxRates}
      />
    </Flex>
  )
}
