import {
  ActionHandler,
  UpdateEditor
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import deepmerge from 'deepmerge'

export const updateEditor: ActionHandler<UpdateEditor> =
  prevState => action => {
    return {
      ...prevState,
      editor: deepmerge(prevState.editor, action.payload)
    }
  }
