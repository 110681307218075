import { Divider, Flex, Text } from '@chakra-ui/react'
import { FC, memo } from 'react'
import {
  Button,
  SelectInput,
  SwitchLineInput
} from '@sequencehq/core-components'
import {
  GreyGrey30,
  GreyGrey70,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useInvoicesSettings } from './useInvoicesSettings'

export const InvoicesSettings: FC = memo(() => {
  const hook = useInvoicesSettings()

  const allPaymentsSettingsHidden =
    hook.fields.paymentLinkDisplay.hidden &&
    hook.fields.accountPaymentSettings.hidden

  return (
    <Flex padding="24px" gap="24px" flexDirection="column">
      <Flex gap="16px" width={'25vw'} direction="column">
        <Text {...Lato13Bold} color={GreyGrey90}>
          Accounting date preference
        </Text>
        <SelectInput
          placeholder="Select account date preference"
          value={hook.fields.accountingDatePreference.value}
          options={hook.fields.accountingDatePreference.options}
          mb={4}
          isDisabled={!hook.ready}
          onChange={hook.fields.accountingDatePreference.onChange}
        />
        <Text {...Lato13Regular} color={GreyGrey70}>
          Choose 'Billing date' for the date corresponding to the billing period
          (depending on advance or arrears billing), or 'Issue date' for when
          the invoice is sent.
        </Text>
        {!allPaymentsSettingsHidden && (
          <Flex gap="8px" flexDirection="column">
            <Text {...Lato13Bold} color={GreyGrey90}>
              Payments
            </Text>

            <Flex gap="12px" flexDirection="column">
              {!hook.fields.paymentLinkDisplay.hidden && (
                <Flex gap="4px" flexDirection="column">
                  <SwitchLineInput
                    value={hook.fields.paymentLinkDisplay.value}
                    onChange={hook.fields.paymentLinkDisplay.onChange}
                    text={
                      <Text {...Lato13Bold} color={GreyGrey90}>
                        Display Stripe payment links
                      </Text>
                    }
                    styles={{ label: { marginLeft: '0px' } }}
                  />

                  <Flex flexDirection="column" gap="8px" marginLeft="36px">
                    <Text {...Lato13Regular} color={GreyGrey70}>
                      Disabling this will remove Stripe payment links from
                      invoice notifications, PDFs and hosted invoices. Useful if
                      you want to build your own white labelled payments
                      experience.
                    </Text>
                  </Flex>
                </Flex>
              )}

              {!hook.fields.accountPaymentSettings.hidden && (
                <Flex gap="4px" flexDirection="column">
                  <SwitchLineInput
                    value={hook.fields.accountPaymentSettings.value}
                    onChange={hook.fields.accountPaymentSettings.onChange}
                    text={
                      <Text {...Lato13Bold} color={GreyGrey90}>
                        Failed payment notifications
                      </Text>
                    }
                    styles={{ label: { marginLeft: '0px' } }}
                  />

                  <Flex flexDirection="column" gap="8px" marginLeft="36px">
                    <Text {...Lato13Regular} color={GreyGrey70}>
                      Email customers when Stripe failed to collect payment. You
                      will also receive a notification. Emails will include a
                      Stripe payment link to add new payment details.
                    </Text>
                  </Flex>
                </Flex>
              )}

              {!hook.fields.stripeAutoCharge.hidden && (
                <Flex gap="4px" flexDirection="column">
                  <SwitchLineInput
                    value={hook.fields.stripeAutoCharge.value}
                    onChange={hook.fields.stripeAutoCharge.onChange}
                    text={
                      <Text {...Lato13Bold} color={GreyGrey90}>
                        Auto-charge customers via Stripe
                      </Text>
                    }
                    styles={{ label: { marginLeft: '0px' } }}
                  />

                  <Flex flexDirection="column" gap="8px" marginLeft="36px">
                    <Text {...Lato13Regular} color={GreyGrey70}>
                      If a payment method is on file, charge customers
                      automatically when invoices are sent. You can still change
                      this when you create a billing schedule.
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>

      <Divider borderBottomColor={GreyGrey30} />

      <Button
        variant="primary"
        disabled={!hook.hasChanges || !hook.ready}
        onClick={hook.onSave}
        style={{
          width: 'fit-content'
        }}
      >
        Save changes
      </Button>
    </Flex>
  )
})
