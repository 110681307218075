import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import { XeroIntegrationConfig } from 'modules/Integrations/integrationsConfig/xero/xero.integration.config'

export const xeroConfigurationLoader = async (): Promise<{
  data: XeroIntegrationConfig | null
  error: IntegrationsPortErrors | null
}> => {
  const integrationsResponse = await dashboardv20240509Client.getIntegrations()

  if (integrationsResponse.error) {
    return {
      data: null,
      error: IntegrationsPortErrors.Other
    }
  }

  const xeroIntegration = integrationsResponse.data?.items.find(
    int => int.service === 'Xero'
  )

  if (!xeroIntegration) {
    return {
      data: {
        defaultCreditLedgerAccount: '',
        defaultLedgerAccount: '',
        defaultMinimumLedgerAccount: '',
        untaxedCustomerTaxCode: '',
        importCustomerWorkflow: false
      },
      error: null
    }
  }

  return {
    data: {
      defaultCreditLedgerAccount:
        xeroIntegration.defaultLedgerAccounts.creditAccount ?? '',
      defaultLedgerAccount:
        xeroIntegration.defaultLedgerAccounts.chargeAccount ?? '',
      defaultMinimumLedgerAccount:
        xeroIntegration.defaultLedgerAccounts.minimumAccount ?? '',
      importCustomerWorkflow: xeroIntegration?.enabled ?? false,
      untaxedCustomerTaxCode:
        xeroIntegration.defaultTaxCodes.untaxedCustomerTaxCode ?? ''
    },
    error: null
  }
}
