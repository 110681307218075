import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey60,
  GreyGrey80,
  IndigoIndigo50,
  Lato12Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { NewPricePill } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/NewPricePill'
import { usePricesList } from 'modules/Cube/view/common/drawers/priceEditor/drawer/components/PricesList/usePricesList'

const styles = {
  listItem: {
    standard: {
      padding: '12px 24px',
      cursor: 'pointer',
      ...Lato14Regular,
      color: GreyGrey60,
      position: 'relative' as const,
      alignItems: 'center',
      userSelect: 'none' as const
    },
    active: {
      backgroundColor: GreyGrey20,
      color: GreyGrey80
    }
  },
  activeIndicator: {
    backgroundColor: IndigoIndigo50,
    height: '100%',
    position: 'absolute' as const,
    top: '0',
    bottom: '0',
    left: '0',
    width: '4px'
  }
}
export const PricesList = () => {
  const { newPrices, existingPrices, selectedPriceId, selectPrice } =
    usePricesList()

  return (
    <Box>
      {Boolean(newPrices.length) && (
        <>
          <Flex
            {...Lato12Bold}
            textTransform="uppercase"
            color={GreyGrey60}
            padding="24px 24px 12px"
          >
            New price
          </Flex>
          {newPrices.map(price => {
            const priceIsActive = selectedPriceId === price.id
            return (
              <Flex
                key={price.id}
                {...styles.listItem.standard}
                {...(priceIsActive ? styles.listItem.active : {})}
                onClick={() => {
                  if (priceIsActive) {
                    return
                  }
                  selectPrice(price.id)
                }}
              >
                {priceIsActive ? <Box {...styles.activeIndicator}></Box> : null}
                {price.label}
                <Flex
                  alignItems="center"
                  position="absolute"
                  right="0"
                  background="linear-gradient(90deg, rgba(241, 244, 248, 0.00) 0.62%, #F1F4F8 27.42%)"
                  height="100%"
                  padding="0 16px 0 32px"
                >
                  <NewPricePill />
                </Flex>
              </Flex>
            )
          })}
        </>
      )}
      {Boolean(existingPrices.length) && (
        <Flex
          {...Lato12Bold}
          textTransform="uppercase"
          color={GreyGrey60}
          padding="24px 24px 12px"
        >
          Existing prices
        </Flex>
      )}
      {existingPrices.map(price => {
        const priceIsActive = selectedPriceId === price.id
        return (
          <Flex
            key={price.id}
            {...styles.listItem.standard}
            {...(priceIsActive ? styles.listItem.active : {})}
            onClick={() => {
              if (priceIsActive) {
                return
              }
              selectPrice(price.id)
            }}
          >
            {priceIsActive ? <Box {...styles.activeIndicator}></Box> : null}
            {price.label}
          </Flex>
        )
      })}
    </Box>
  )
}
