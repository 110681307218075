import { Button, Text } from '@chakra-ui/react'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'

import { GreyGrey70, Lato13Bold } from '@sequencehq/design-tokens'

type Props = {
  text: string
  isVisible: boolean
  toggle: () => void
}

export const SidebarSectionToggle = ({ text, isVisible, toggle }: Props) => {
  const iconProps = {
    width: '16px',
    height: '16px',
    color: 'black'
  }

  return (
    <Button
      variant="unstyled"
      minWidth="initial"
      minHeight="initial"
      height="initial"
      textAlign="left"
      onClick={toggle}
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      gap="8px"
      marginBottom="16px"
    >
      <Text {...Lato13Bold} color={GreyGrey70}>
        {text}
      </Text>

      {isVisible ? (
        <ChevronDownIcon {...iconProps} />
      ) : (
        <ChevronUpIcon {...iconProps} />
      )}
    </Button>
  )
}
