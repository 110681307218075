import { ErrorBoundary } from '@sentry/react'
import PageError from 'components/ErrorPages/PageError'
import { Outlet } from 'react-router-dom'

export const RevRecRoot = () => {
  /**
   * We use the common layout component to ensure consistency across the module,
   * and the contents of the header differs depending on route. Therefore, the root
   * being a simple outlet makes for the most simple code, too.
   */
  return (
    <ErrorBoundary fallback={<PageError />}>
      <Outlet />
    </ErrorBoundary>
  )
}
