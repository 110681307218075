import { useCallback } from 'react'

const MAX_PAGES = 50

export const useLoadAllItems = <T, V>(
  apiCall: (
    params: {
      limit?: number
      after?: string
    } & V
  ) => Promise<{
    data: {
      items: T[]
      pagination: { after?: string; totalResultSize: number }
    } | null
  }>
) => {
  return useCallback(
    async (additionalParams: V) => {
      const items: T[] = []
      let page = (await apiCall({ limit: 100, ...additionalParams }))?.data
      page?.items?.forEach(item => items.push(item))
      let pageCount = 1

      while (
        !!page &&
        page.items.length > 0 &&
        page.pagination.after &&
        pageCount < MAX_PAGES
      ) {
        page = (
          await apiCall({
            limit: 100,
            after: page.pagination.after,
            ...additionalParams
          })
        )?.data
        page?.items?.forEach(item => items.push(item))

        pageCount++
      }

      return items
    },
    [apiCall]
  )
}
