import {
  MutationResponse,
  SuccessResponse,
  ErrorResponse
} from 'components/Form/types'

export const isSuccessResponse = <Model>(
  res: MutationResponse<Model>
): res is SuccessResponse<Model> => {
  return 'data' in res
}

export const isErrorResponse = <Model>(
  res: MutationResponse<Model>
): res is ErrorResponse => {
  return 'error' in res
}
