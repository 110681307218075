import { Grid, GridItem } from '@chakra-ui/react'
import { GreyWhite } from '@sequencehq/design-tokens'

/**
 * A convenient layout component for the 'detail table' layout we
 * see in the journal report detail and deferred revenue screens. This is
 * likely to also crop up in future revrec screens too.
 *
 * If it becomes more standard across modules, let's lift this out into core-components.
 * @param props
 * @returns
 */
export const DetailTableLayout = (props: {
  summary: React.ReactNode
  actions: React.ReactNode
  table: React.ReactNode
}) => (
  <Grid
    gridTemplateRows="auto auto 1fr"
    gridTemplateColumns={'1fr 1240px 1fr'}
    gridTemplateAreas={`
"margin-left summary margin-right"
"margin-left actions margin-right"
"margin-left table margin-right"
`}
    width="100%"
    padding="16px"
    paddingBottom="0"
    rowGap="16px"
    height="100%"
    overflow="auto"
    backgroundColor={GreyWhite}
  >
    <GridItem gridArea="summary">{props.summary}</GridItem>
    <GridItem gridArea="actions">{props.actions}</GridItem>
    <GridItem
      overflow="auto"
      paddingBottom="16px"
      width="fit-content"
      gridArea="table"
    >
      {props.table}
    </GridItem>
  </Grid>
)
