import deepmerge from 'deepmerge'
import {
  ActionHandler,
  UpdateConfigurationAction
} from '../pricingEditor.domain.types'

export const updateConfiguration: ActionHandler<UpdateConfigurationAction> =
  prevState => action => {
    const newConfiguration = deepmerge(prevState.configuration, action.payload)

    return {
      ...prevState,
      configuration: newConfiguration
    }
  }
