import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { FC, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Banner, PopoverWrapper } from '@sequencehq/core-components'
import { PopoverRecipients } from '../../QuoteEditorControls/PopoverRecipients/PopoverRecipients'

type ConfirmPublishQuoteModalProps = {
  onClose: () => void
  onConfirm: (body: {
    sendRecipientsEmail: boolean
    emailMessage: string | undefined
  }) => Promise<void>
  submitting: boolean
  hasBeenPublished?: boolean
  isReadyToSign?: boolean
  recipientCountText: string
  republishingWithoutChanges?: boolean
}

export const ConfirmPublishQuoteModal: FC<ConfirmPublishQuoteModalProps> = ({
  hasBeenPublished,
  isReadyToSign,
  republishingWithoutChanges,
  ...props
}) => {
  const [notifyRecipient, setNotifyRecipient] = useState<boolean>(false)
  const [emailMessage, setEmailMessage] = useState<string | undefined>(
    undefined
  )

  const ctaText = republishingWithoutChanges
    ? 'Republish quote'
    : hasBeenPublished
    ? 'Publish changes'
    : 'Publish quote'
  const detailText = republishingWithoutChanges
    ? "You're about to republish this quote. This will reset the expiry date."
    : hasBeenPublished
    ? 'You’re about to publish changes to this quote. All changes will be visible to anyone with the link and cannot be undone.'
    : ' You’re about to publish this quote. This quote will be visible to anyone with the link.'

  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
          top="118px"
          width="414px"
          maxWidth="414px"
        >
          <Box color={GreyGrey100} {...Lato14Bold} marginBottom="4px">
            {ctaText}
          </Box>

          <Box {...Lato13Regular} color={GreyGrey80} marginBottom="16px">
            {detailText}
          </Box>

          <Checkbox
            isChecked={notifyRecipient}
            onChange={() => setNotifyRecipient(notify => !notify)}
            size="md"
            spacing="8px"
            marginBottom="16px"
          >
            <Text
              {...Lato13Regular}
              color={GreyGrey90}
              display="flex"
              alignItems="baseline"
              gap="3px"
            >
              Notify{' '}
              <PopoverWrapper popover={<PopoverRecipients />}>
                <Text
                  as="span"
                  {...Lato13Bold}
                  color="inherit"
                  borderBottom={`${GreyGrey70} 1px dashed`}
                >
                  {props.recipientCountText}
                </Text>
              </PopoverWrapper>{' '}
              by email
            </Text>
          </Checkbox>

          <AnimatePresence initial={false}>
            {notifyRecipient && (
              <motion.div
                initial={{ height: '0px' }}
                animate={{ height: 'fit-content' }}
                exit={{ height: '0px' }}
                style={{ overflow: 'hidden' }}
              >
                <Text {...Lato13Bold} marginBottom="8px" color={GreyGrey80}>
                  Message (optional)
                </Text>
                <Textarea
                  value={emailMessage}
                  onChange={event => setEmailMessage(event.target.value)}
                  marginBottom="16px"
                  {...Lato14Regular}
                  color={GreyGrey80}
                ></Textarea>
              </motion.div>
            )}
          </AnimatePresence>

          {isReadyToSign && (
            <Box mb={4}>
              <Banner variant="warning">
                All signers will receive a new signature request via email.
                Previous signature requests will be cancelled.
              </Banner>
            </Box>
          )}

          <Flex gap="8px" justifyContent="flex-end">
            <Button
              variant="secondary2"
              onClick={props.onClose}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              isLoading={props.submitting}
              onClick={() => {
                void props.onConfirm({
                  sendRecipientsEmail: notifyRecipient,
                  emailMessage: emailMessage ? emailMessage : undefined
                })
              }}
              height="32px"
              width="100%"
              {...Lato13Bold}
            >
              {ctaText}
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
