import {
  useGetProductsByIdQuery,
  useGetSequenceAccountSettingsQuery
} from 'features/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  ApiLedgerIntegrationAccount,
  ApiListPrice,
  AvailableStandardFrequency,
  Product,
  UsageMetric
} from 'modules/Products/drawers/ListPriceEditor/domain/listPriceEditor.types'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { ListPrice } from 'modules/Products/types'
import { useLoadListPrices } from 'modules/Products/drawers/ListPriceEditor/hooks/entityLoading/useLoadListPrices'
import { useXeroIntegration } from 'lib/hooks/useXeroIntegration.ts'
import { useFetchUsageMetrics } from 'lib/hooks/useFetchUsageMetrics.ts'

export type LoadedListPriceEditorData = {
  prices: ApiListPrice[]
  product: Product
  availableCurrencies: Currency[]
  metrics: UsageMetric[]
  ledgerIntegrations: {
    key: string
    value: ApiLedgerIntegrationAccount[]
    defaultLedgerAccount?: string
  }[]
}

type UseLoadListPriceEditor = (props: {
  productId: Product['id']
  currency?: Currency
  frequencies: AvailableStandardFrequency[]
  skip?: {
    prices?: boolean
    product?: boolean
  }
}) => {
  loading: boolean
  error: boolean
  data?: LoadedListPriceEditorData
}

export const useLoadListPriceEditor: UseLoadListPriceEditor = props => {
  const {
    xeroIntegration,
    loading: xeroIntegrationLoading,
    hasError: xeroIntegrationError
  } = useXeroIntegration()
  const pricesLoader = useLoadListPrices()
  const [listPricesLoading, setListPricesLoading] = useState<boolean>(true)
  const [listPrices, setListPrices] = useState<ListPrice[]>([])

  const productLoader = useGetProductsByIdQuery(
    props.skip?.product || !props.productId
      ? skipToken
      : {
          id: props.productId
        }
  )
  const accountSettingsLoader = useGetSequenceAccountSettingsQuery()
  const metricsLoader = useFetchUsageMetrics()

  const load = useCallback(async () => {
    const loadedListPrices = await pricesLoader({ productId: props.productId })
    setListPrices(loadedListPrices)
    setListPricesLoading(false)
  }, [pricesLoader, props.productId])

  const loading = useMemo(() => {
    return [
      listPricesLoading,
      productLoader.isLoading,
      accountSettingsLoader.isLoading,
      metricsLoader.loading,
      xeroIntegrationLoading
    ].some(Boolean)
  }, [
    listPricesLoading,
    productLoader.isLoading,
    accountSettingsLoader.isLoading,
    metricsLoader.loading,
    xeroIntegrationLoading
  ])

  const error = useMemo(() => {
    return [
      productLoader.error,
      accountSettingsLoader.error,
      metricsLoader.error,
      xeroIntegrationError
    ].some(Boolean)
  }, [
    productLoader.error,
    accountSettingsLoader.error,
    metricsLoader.error,
    xeroIntegrationError
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void load(), [])

  const data = useMemo(() => {
    if (loading || error) {
      return
    }

    return {
      prices: listPrices,
      product: productLoader.data?.value() as Product,
      availableCurrencies:
        accountSettingsLoader.data?.value()?.enabledCurrencies ?? [],
      metrics: metricsLoader.metrics ?? [],
      ledgerIntegrations:
        xeroIntegration && xeroIntegration.enabled
          ? [
              {
                key: 'Xero',
                value: xeroIntegration.ledgerAccounts,
                defaultLedgerAccount: xeroIntegration.defaultLedgerAccount
              }
            ]
          : []
    }
  }, [
    listPrices,
    productLoader.data,
    accountSettingsLoader.data,
    metricsLoader.metrics,
    xeroIntegration,
    loading,
    error
  ])

  return {
    loading,
    error,
    data
  }
}
