import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { contactToQuoteContact } from 'Cube/communication/external/quotes.api.v1/adapters/quote.adapters.in'
import { keyBy } from 'lodash'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type UseCustomerEditorOutletConnector = () => {
  onSuccess: (
    customerModel: DashboardApi20240730.PostCustomer.CreateCustomerResponse
  ) => void
  onCancel: () => void
}
export const useCustomerEditorConnector: UseCustomerEditorOutletConnector =
  () => {
    const navigate = useNavigate()
    const cubeContext = useCubeContext()

    const addCustomer = useCallback(
      (
        newCustomer: DashboardApi20240730.PostCustomer.CreateCustomerResponse
      ) => {
        const contacts = newCustomer.contacts.map(contact => ({
          ...contact,
          customerId: newCustomer.id
        }))

        cubeContext.mutators.updateData({
          customers: {
            [newCustomer.id]: newCustomer
          },
          contacts: keyBy(contacts, 'id'),
          common: {
            customerId: newCustomer.id
          },
          quote: {
            contacts: contacts.map(contact => contactToQuoteContact(contact))
          }
        })
        navigate('../..', { relative: 'path' })
      },
      [cubeContext, navigate]
    )

    const onCancel = useCallback(() => {
      navigate('../..', { relative: 'path' })
    }, [navigate])

    return {
      onSuccess: addCustomer,
      onCancel
    }
  }
