import {
  InvoiceEditorReducerState,
  ResetInvoiceEditorDataAction
} from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'

type ResetInvoiceEditorData = (
  prevState: InvoiceEditorReducerState
) => (action: ResetInvoiceEditorDataAction) => InvoiceEditorReducerState

export const resetInvoiceEditorData: ResetInvoiceEditorData =
  prevState => action => ({
    ...prevState,
    data: {
      ...prevState.data,
      ...action.payload
    }
  })
