import { Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { NavLink as NavLinkProps } from 'components/NavigationV3/types'
import { NavLink } from 'components/NavigationV3/components/NavLinks/NavLink'

import HomeIcon from '@heroicons/react/16/solid/HomeIcon'
import CalendarDaysIcon from '@heroicons/react/16/solid/CalendarDaysIcon'
import RectangleStackIcon from '@heroicons/react/16/solid/RectangleStackIcon'
import DocumentCheckIcon from '@heroicons/react/16/solid/DocumentCheckIcon'
import CubeIcon from '@heroicons/react/16/solid/CubeIcon'
import UsersIcon from '@heroicons/react/16/solid/UsersIcon'
import CommandLineIcon from '@heroicons/react/16/solid/CommandLineIcon'
import IdentificationIcon from '@heroicons/react/16/solid/IdentificationIcon'
import PresentationChartBarIcon from '@heroicons/react/16/solid/PresentationChartBarIcon'
import ReceiptRefundIcon from '@heroicons/react/16/solid/ReceiptRefundIcon'
import BuildingLibraryIcon from '@heroicons/react/16/solid/BuildingLibraryIcon'

export const NavLinks = () => {
  const { pathname } = useLocation()
  const flags = useFlags()

  const links: NavLinkProps[] = [
    { icon: HomeIcon, label: 'Home', href: '/home' },
    {
      icon: CalendarDaysIcon,
      label: 'Schedules',
      href: '/billing-schedules'
    },
    { icon: IdentificationIcon, label: 'Customers', href: '/customers' },
    {
      icon: RectangleStackIcon,
      label: 'Invoices',
      href: '/invoices?sortBy=accountingDate_desc'
    },
    {
      icon: ReceiptRefundIcon,
      label: 'Credit notes',
      href: '/credit-notes'
    },
    {
      icon: DocumentCheckIcon,
      label: 'Quotes',
      href: '/quotes',
      enabled: flags?.showQuoteBuilder
    },
    { icon: CubeIcon, label: 'Products', href: '/products' },
    {
      icon: CommandLineIcon,
      label: 'Usage',
      href: '/usage-events',
      subLinks: [
        { label: 'Events', href: '/usage-events' },
        { label: 'Metrics', href: '/usage-metrics' }
      ]
    },
    {
      icon: UsersIcon,
      label: 'Seats',
      href: '/seat-events',
      enabled: flags?.enableSeats,
      subLinks: [
        { label: 'Events', href: '/seat-events' },
        { label: 'Balances', href: '/seat-balances' },
        { label: 'Metrics', href: '/seats' }
      ]
    },
    {
      icon: BuildingLibraryIcon,
      label: 'Revenue recognition',
      href: '/revenue-recognition',
      enabled: flags?.useRevRec,
      subLinks: [
        {
          label: 'Journal reports',
          href: '/revenue-recognition/journal-reports'
        },
        {
          label: 'Deferred revenue',
          href: '/revenue-recognition/deferred-revenue'
        }
      ]
    },
    {
      icon: PresentationChartBarIcon,
      label: 'Insights',
      href: '/insights'
    }
  ]

  return (
    <Flex direction="column" gap="1px" px="8px" data-testid="navbar">
      {links
        .filter(link => ('enabled' in link ? link.enabled : true))
        .map(link => (
          <NavLink key={link.href} {...link} pathname={pathname} />
        ))}
    </Flex>
  )
}
