import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react'
import { borderColor } from '@sequencehq/core-theme'
import { ChevronRightIcon, PlusIcon } from 'components/icons'
import Spinner from 'components/Loading/Spinner'
import {
  integratedApiWithTags,
  useGetApiIntegrationsCustomersQuery
} from 'features/api/integratedApiTags'
import { CrossIcon } from '@sequencehq/core-components'

import { closeOverlay, openOverlay } from 'features/overlay'
import { useDispatch } from 'features/store'
import { IntegrationMoreInfo } from 'lib/docsLink'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { RefreshCw } from 'react-feather'
import { getLogo } from 'lib/integrations/logos'
import { integrationName } from 'lib/integrations/integrationName'

export const ImportCustomersModal: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: integrationCustomers, isFetching: isFetchingCustomers } =
    useGetApiIntegrationsCustomersQuery()

  const integrationCustomersValue = integrationCustomers?.value()

  if (!integrationCustomersValue) {
    return <Spinner></Spinner>
  }

  const customers = integrationCustomersValue.customers

  return (
    <ModalContent borderRadius={'8px'}>
      <ModalHeader>
        <Flex flexDirection="row">
          <Text fontSize="20px" fontWeight="700">
            Import customers
          </Text>
          <Flex grow="1"></Flex>
          <Flex flexDirection="column">
            <Box height="4px"></Box>
            <Box
              cursor="pointer"
              onClick={() => {
                dispatch(closeOverlay())
              }}
            >
              <CrossIcon color="#485972" height={12} width={12}></CrossIcon>
            </Box>
          </Flex>
        </Flex>
      </ModalHeader>
      <Divider color={borderColor}></Divider>
      <ModalBody p="24px">
        <Flex flexDirection="row" alignItems="center">
          <Text fontSize="14px" fontWeight="700">
            Import from integration
          </Text>
          <Flex grow="1"></Flex>
          <Popover trigger="hover" variant="fieldInfo">
            <PopoverTrigger>
              <Button
                variant="secondary"
                width="40px"
                height="40px"
                padding="5px"
                isLoading={isFetchingCustomers}
                onClick={() =>
                  dispatch(
                    integratedApiWithTags.util.invalidateTags([
                      'IntegrationCustomers'
                    ])
                  )
                }
              >
                <RefreshCw size={15}></RefreshCw>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              backgroundColor="gray.90"
              borderColor="none"
              width="fit-content"
            >
              <PopoverBody padding="8px">
                <Text color="white" fontSize="14px">
                  Reload customers
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
        <Box height="20px"></Box>
        {customers.map(integration => (
          <Box key={integration.key}>
            <Flex
              flexDirection="row"
              cursor={
                integration.value.filter(customer => !customer.isLinked)
                  .length > 0
                  ? 'pointer'
                  : 'default'
              }
              onClick={() =>
                integration.value.filter(customer => !customer.isLinked)
                  .length > 0
                  ? dispatch(
                      openOverlay({
                        content: 'selectImportCustomersModal',
                        data: {
                          service: integration.key
                        }
                      })
                    )
                  : {}
              }
            >
              {getLogo(integration.key)}
              <Box width="12px"></Box>
              <Flex flexDirection="column">
                <Box height="8px"></Box>
                <Text>{integrationName(integration.key)}</Text>
              </Flex>
              <Box width="12px"></Box>
              <Flex flexDirection="column">
                <Box height="8px"></Box>
                <Text fontSize="14px" color="gray.60">
                  {
                    integration.value.filter(customer => customer.isLinked)
                      .length
                  }{' '}
                  of {integration.value.length} customer
                  {integration.value.length === 1 ? '' : 's'} already linked
                </Text>
              </Flex>
              <Flex grow="1"></Flex>
              <Flex flexDirection="column">
                <Box height="8px"></Box>
                <ChevronRightIcon color="#344054"></ChevronRightIcon>
              </Flex>
            </Flex>
            <Box height="16px"></Box>
          </Box>
        ))}
        <Divider color={borderColor}></Divider>
        <Box height="16px"></Box>
        <Flex
          flexDirection="row"
          onClick={() => {
            navigate('/integrations')
            dispatch(closeOverlay())
          }}
          cursor="pointer"
        >
          <PlusIcon color="#344054"></PlusIcon>
          <Box width="12px"></Box>
          <Text fontSize="14px">Add integration</Text>
        </Flex>
        <Box height="16px"></Box>
        <Divider color={borderColor}></Divider>
      </ModalBody>
      <ModalFooter>
        <Button
          as={Link}
          href={IntegrationMoreInfo.AccountingOverviewLink}
          variant="ghost"
          width="134px"
          isExternal
        >
          More info
        </Button>
        <Flex grow="1"></Flex>
        {customers.length === 1 && (
          <Button
            variant="primary"
            onClick={() =>
              dispatch(
                openOverlay({
                  content: 'selectImportCustomersModal',
                  data: {
                    service: customers[0].key
                  }
                })
              )
            }
            isDisabled={
              customers[0].value.filter(customer => !customer.isLinked)
                .length === 0
            }
          >
            Continue
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  )
}
