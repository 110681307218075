import { AddProductForm } from 'modules/Products/drawers/AddProduct/AddProductForm'
import { useAddProductOutletConnector } from 'modules/Products/drawers/AddProduct/hooks/useAddProductOutletConnector'
import { AddProductConnector } from 'Products/drawers/AddProduct/components/AddProductConnector'

export const AddProductRouteComponent = () => {
  const { enableListPrices, onCancel } = useAddProductOutletConnector()

  return enableListPrices ? (
    <AddProductConnector />
  ) : (
    <AddProductForm onClose={onCancel} />
  )
}
