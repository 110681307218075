import { Button, Flex, Spacer, Tooltip, Text } from '@chakra-ui/react'
import { ChevronRightIcon, ChevronLeftIcon } from 'components/icons'
import { Calendar, GetBackForwardPropsOptions } from 'dayzed'
import { FC, ReactNode } from 'react'

interface DateSkipperButtonProps {
  calendar: Calendar
  directionProps: (data: GetBackForwardPropsOptions) => Record<string, any>
  offset: number
  children: ReactNode
  type?: 'back' | 'forward'
  hideYearSkippers?: boolean
}

const DateSkipperButton: FC<DateSkipperButtonProps> = ({
  calendar,
  directionProps,
  offset,
  children,
  type
}) => {
  const tooltipLabel =
    offset === 1 && type === 'back'
      ? 'Previous month'
      : offset === 1 && type === 'forward'
      ? 'Next month'
      : offset === 12 && type === 'back'
      ? 'Previous year'
      : offset === 12 && type === 'forward'
      ? 'Next year'
      : ''

  return (
    <Tooltip
      label={tooltipLabel}
      placement="top"
      isDisabled={!tooltipLabel ? true : false}
      hasArrow
      bg="#171923"
      color="white"
      px="8px"
      py="4px"
      fontSize="14px"
      fontWeight="400"
      borderRadius="4px"
    >
      <Button
        {...directionProps({
          calendars: [calendar],
          offset
        })}
        tabIndex={-1}
        variant="dateSkipper"
        width="24px"
        height="24px"
      >
        {children}
      </Button>
    </Tooltip>
  )
}

type CalendarHeaderProps = {
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>
  calendar: Calendar
  monthLabel: string
  hideYearSkippers?: boolean
}

export const CalendarHeader: FC<CalendarHeaderProps> = ({
  getBackProps,
  getForwardProps,
  calendar,
  monthLabel,
  hideYearSkippers
}) => {
  return (
    <Flex w="100%">
      {!hideYearSkippers && (
        <DateSkipperButton
          calendar={calendar}
          directionProps={getBackProps}
          offset={12}
          type="back"
        >
          <ChevronLeftIcon />
          <ChevronLeftIcon />
        </DateSkipperButton>
      )}
      <DateSkipperButton
        calendar={calendar}
        directionProps={getBackProps}
        offset={1}
        type="back"
      >
        <ChevronLeftIcon />
      </DateSkipperButton>
      <Spacer />
      <Flex justify="center" align="center">
        <Text fontWeight={700}>{monthLabel}</Text>
      </Flex>
      <Spacer />
      <DateSkipperButton
        calendar={calendar}
        directionProps={getForwardProps}
        offset={1}
        type="forward"
      >
        <ChevronRightIcon />
      </DateSkipperButton>
      {!hideYearSkippers && (
        <DateSkipperButton
          calendar={calendar}
          directionProps={getForwardProps}
          offset={12}
          type="forward"
        >
          <ChevronRightIcon />
          <ChevronRightIcon />
        </DateSkipperButton>
      )}
    </Flex>
  )
}
