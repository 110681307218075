import { Flex, Img } from '@chakra-ui/react'
import { Inspector } from '@sequencehq/core-components'
import { FC } from 'react'
import { hubSpotIntegrationConfig } from '../hubspot.integration.config'
import { LinkCustomerToServiceWidget } from '../../common/LinkEntities/LinkCustomerToService'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'
import { useIntegrationAuthError } from 'Integrations/utils/useIntegrationAuthError'
import { NotAuthenticatedItemContent } from 'Integrations/integrationsConfig/common/NotAuthenticatedItemContent'

export interface HubSpotInspectorItemProps {
  customerId: string
  canUnlink?: boolean
}

export const LinkHubSpotCustomerWidget: FC<{
  customerId: string
  canUnlink?: boolean
}> = props => (
  <LinkCustomerToServiceWidget
    customerId={props.customerId}
    service="HubSpot"
    canUnlink={props.canUnlink}
    externalEntityLabel="company"
    sequenceEntityLabel="customer"
  />
)

export const HubspotInspectorItem: FC<{
  children: React.ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('HubSpot')
  const integrationAuthError = useIntegrationAuthError('HubSpot')

  if (!integrationActive && !integrationAuthError) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="hubspot.inspector.item"
      content={
        integrationAuthError ? (
          <NotAuthenticatedItemContent
            service="HubSpot"
            data-testid="hubspot.inspector.content.unauth"
            serviceDescription={hubSpotIntegrationConfig.description}
            serviceName={hubSpotIntegrationConfig.title}
            logo={
              <Img
                src={hubSpotIntegrationConfig.smallLogoUrl}
                height="24px"
                width="24px"
              />
            }
          />
        ) : (
          <Inspector.Content
            title="HubSpot"
            data-testid="hubspot.inspector.content"
            icon={<SmallIcon url={hubSpotIntegrationConfig.smallLogoUrl} />}
          >
            <Flex flexDirection="column" gap="16px">
              {props.children}
            </Flex>
          </Inspector.Content>
        )
      }
      tooltip="HubSpot"
      icon={<SmallIcon url={hubSpotIntegrationConfig.smallLogoUrl} />}
    />
  )
}
