import { toMoney } from '@sequencehq/core-models'
import { match } from 'ts-pattern'
import {
  FixedPricingStructure,
  LinearPricingStructure,
  PackagePricingStructure,
  OneTimePricingStructure,
  SeatBasedPricingStructure,
  GraduatedPricingStructure,
  VolumePricingStructure
} from '@sequencehq/api/src/clients/dashboard/v20240730/commonModels/pricingStructure'
import { Currency } from '@sequencehq/api/utils'

type Discount = {
  discountCalculationType: 'NOMINAL' | 'PERCENTAGE'
  amount: number
}

type PriceStructure =
  | Pick<OneTimePricingStructure, 'price' | 'pricingType'>
  | Pick<FixedPricingStructure, 'price' | 'pricingType'>
  | Pick<
      LinearPricingStructure,
      'pricePerUnit' | 'pricingType' | 'isPricePercentage'
    >
  | Pick<PackagePricingStructure, 'pricePerPackage' | 'pricingType'>
  | Pick<SeatBasedPricingStructure, 'pricePerSeat' | 'pricingType'>
  | Pick<GraduatedPricingStructure, 'pricingType'>
  | Pick<VolumePricingStructure, 'pricingType'>

export const getPriceStructureAmount = ({
  structure: priceStructure
}: { structure: PriceStructure }) => {
  return match(priceStructure)
    .with({ pricingType: 'FIXED' }, { pricingType: 'ONE_TIME' }, structure =>
      Number(structure.price)
    )
    .with({ pricingType: 'LINEAR' }, structure =>
      Number(structure.pricePerUnit)
    )
    .with({ pricingType: 'PACKAGE' }, structure =>
      Number(structure.pricePerPackage)
    )
    .with({ pricingType: 'SEAT_BASED' }, structure =>
      Number(structure.pricePerSeat)
    )
    .with({ pricingType: 'VOLUME' }, { pricingType: 'GRADUATED' }, () => {
      throw new Error(
        `${priceStructure.pricingType} pricing type is not supported`
      )
    })
    .exhaustive()
}

export const applyDiscountToAmount = ({
  amount,
  discount
}: {
  amount: number
  discount: Discount
}) => {
  if (discount.discountCalculationType === 'NOMINAL') {
    return Math.max(0, amount - Number(discount.amount))
  }

  if (discount.discountCalculationType === 'PERCENTAGE') {
    return amount * ((100 - Number(discount.amount)) / 100)
  }

  return amount
}

export const getPriceStructureDiscountedAmount = ({
  price,
  discount
}: {
  price: { structure: PriceStructure }
  discount: Discount
}) => {
  const priceStructure = price.structure

  const isPricingTypeSupported =
    ['FIXED', 'ONE_TIME', 'PACKAGE', 'SEAT_BASED'].includes(
      priceStructure.pricingType
    ) ||
    (priceStructure.pricingType === 'LINEAR' &&
      !priceStructure.isPricePercentage)

  if (!isPricingTypeSupported) {
    throw new Error(
      `${priceStructure.pricingType === 'LINEAR' ? 'LINEAR with price percentage' : priceStructure.pricingType} pricing type not supported`
    )
  }

  const amount: number = getPriceStructureAmount({ structure: priceStructure })

  return applyDiscountToAmount({ amount, discount })
}

export const calculateDiscountedPrice = (props: {
  price: {
    structure: PriceStructure
    currency: Currency
  }
  discount?: Discount
}): string | null => {
  /**
   * don't calculate discounts for VOLUME or GRADUATED pricingTypes,
   * or for LINEAR pricingTypes use percentage prices
   */
  const isIncompatiblePricingType: boolean =
    ['VOLUME', 'GRADUATED', 'LINEAR'].includes(
      props.price.structure.pricingType
    ) ||
    (props.price.structure.pricingType === 'LINEAR' &&
      props.price.structure.isPricePercentage)

  if (!props.discount || isIncompatiblePricingType) {
    return null
  }

  const amount = getPriceStructureDiscountedAmount({
    price: props.price,
    discount: props.discount
  })

  return toMoney({
    currency: props.price.currency,
    value: String(amount)
  })
}
