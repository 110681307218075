import Page from 'components/Page'
import { settingsPages } from 'components/SettingsPage'
import { useLocation, Outlet } from 'react-router-dom'
import invariant from 'tiny-invariant'

const SettingsPage = () => {
  const location = useLocation()
  const currentPage = settingsPages.find(page =>
    location.pathname.endsWith(page.path)
  )

  invariant(currentPage, 'No page found')

  const { label } = currentPage
  return (
    <Page title={label} paddingBottom={0}>
      <Outlet />
    </Page>
  )
}

export default SettingsPage
