import {
  AvailableServices,
  ConnectionMethod,
  IntegrationsDomainInput,
  IntegrationsPortErrors,
  IntegrationsPortImplementation
} from 'modules/Integrations/domain'
import * as adapters from 'modules/Integrations/communication/external/v1/adapters'
import * as ports from 'modules/Integrations/communication/external/v1/ports'
import {
  INTEGRATIONS_CONFIG,
  IntegrationsConfig
} from 'modules/Integrations/integrationsConfig/integrations.config'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'

export const loadData = async (args: {
  configuration: IntegrationsDomainInput['configuration']
}): Promise<{
  data: IntegrationsDomainInput | null
  error: IntegrationsPortErrors | null
}> => {
  try {
    const res = await ports.loadData()
    return {
      data: {
        data: adapters.dataAdapter.toDomain(res),
        configuration: args.configuration
      },
      error: null
    }
  } catch {
    return {
      data: null,
      error: IntegrationsPortErrors.Other
    }
  }
}

export const updateIntegrationConfiguration =
  (integrationsConfig: IntegrationsConfig = INTEGRATIONS_CONFIG) =>
  async (args: {
    service: AvailableServices
    configuration: Record<string, unknown>
  }) => {
    const configurationMutator =
      integrationsConfig[args.service]?.communication?.configuration?.mutator

    if (!configurationMutator) {
      return {
        success: false,
        error: IntegrationsPortErrors.IncompleteIntegration
      }
    }

    const res = await configurationMutator(args.configuration)

    return {
      success: res.success,
      error: res.error
    }
  }

export const connect =
  (integrationsConfig: IntegrationsConfig = INTEGRATIONS_CONFIG) =>
  async (args: {
    service: IntegrationServices
    params?: Record<string, unknown>
  }): Promise<{ success: boolean; error: IntegrationsPortErrors | null }> => {
    /**
     * We have a legacy method for integrating, which is currently used for exclusively
     * Stripe. Our default approach is to use Integration.app, today.
     */
    const integrationConfig = integrationsConfig[args.service]

    if (
      integrationConfig?.communication?.connecting?.method ===
      ConnectionMethod.OAuth
    ) {
      return ports.connectWithOAuth(args)
    }

    /**
     * When connecting with integration app we also need to manually create
     * the integration.
     */
    const configParams =
      integrationConfig?.communication?.connecting?.integrationAppParameters ??
      {}

    const runtimeParams = args.params ?? {}

    const response = await ports.connectWithIntegrationApp({
      service: args.service,
      parameters: { ...configParams, ...runtimeParams }
    })

    if (!response.success) {
      return response
    }

    return ports.createIntegration(args.service)
  }

export const v1PortImplementation = (args: {
  configuration: IntegrationsDomainInput['configuration']
}): IntegrationsPortImplementation => {
  return {
    in: {
      load: () =>
        loadData({
          configuration: args.configuration
        })
    },
    out: {
      updateIntegrationConfiguration: updateIntegrationConfiguration(),
      connect: connect(),
      removeIntegration: ports.removeIntegration
    }
  }
}
