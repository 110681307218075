import { Flex } from '@chakra-ui/react'
import { MutationFormProps } from 'components/Form/types'
import Spinner from 'components/Loading'
import {
  UpdateCreditNoteStatusFormMutation,
  UpdateCreditNoteStatusFormProps
} from 'components/UpdateInvoiceStatus/types'
import { useGetMerchantForSequenceAccountQuery } from 'features/api'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const FinalizeCreditNoteForm: FC<
  MutationFormProps<UpdateCreditNoteStatusFormMutation> &
    UpdateCreditNoteStatusFormProps
> = ({ id, customerName, submitForm, formId, onSuccess }) => {
  const { data: merchant, isFetching: isFetchingMerchant } =
    useGetMerchantForSequenceAccountQuery()

  const merchantValue = merchant?.value()

  if (isFetchingMerchant || !merchantValue) {
    return <Spinner></Spinner>
  }

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          }).then(() => {
            onSuccess?.()
          })
        ]

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Lock this credit note and prevent editing. Once finalized, you can send the credit note to ${customerName}.`}
          </Flex>
        )
      }}
    />
  )
}

export default FinalizeCreditNoteForm
