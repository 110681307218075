import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  IconButton,
  Text
} from '@chakra-ui/react'
import { PlusIcon, TrashIcon } from '@heroicons/react/16/solid'
import {
  Banner,
  InfoPopover,
  InputSelectControlLegacyStyleBridge,
  ModalContextProvider,
  SelectField,
  SimpleModalUI,
  TextField,
  TextInput
} from '@sequencehq/core-components'
import { Country, State, TaxStatus } from '@sequencehq/core-models'
import {
  GreyGrey70,
  GreyGrey80,
  IndigoIndigo50,
  Lato13Bold,
  Lato14Bold,
  Lato14Regular,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { useEditCustomer } from './hooks/useEditCustomer'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import { ContactSelector } from './ContactSelector'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'

interface Props {
  onClose: () => void
  onSuccess?: (
    customer: DashboardApi20240730.PutCustomer.PutCustomerResponse
  ) => void
}

export const EditCustomerForm: FC<Props> = props => {
  const [flowComplete, setFlowComplete] = useState(false)

  const flags = useFlags()
  const { fieldsConfig, canUpdate, isSaving, handleSubmit, refetchCustomer } =
    useEditCustomer()

  useEffect(() => {
    void refetchCustomer()
  }, [refetchCustomer])

  const [avalaraAddressError, setAvalaraAddressError] = useState(false)

  const handleUpdate = async () => {
    setAvalaraAddressError(false)

    const result = await handleSubmit()

    if (result?.error?.type === 'INVALID_AVALARA_ADDRESS') {
      setAvalaraAddressError(true)
    }

    if (result?.customer) {
      props.onSuccess?.(result.customer)
      setFlowComplete(true)
    }
  }

  return (
    <ModalContextProvider isOpen={!flowComplete}>
      <SimpleModalUI
        variant="drawer"
        title="Edit customer"
        cancelButtonText="Cancel"
        submitButtonText="Update customer"
        onCancel={props.onClose}
        onSubmit={handleUpdate}
        submitDisabled={!canUpdate || isSaving}
        closeOnSubmit={false}
      >
        <Flex gap="16px" direction="column">
          {avalaraAddressError && (
            <Banner variant="error" alignIcon="center">
              <Text {...Lato13Bold} color="inherit" marginBottom="4px">
                Address lookup failed:
              </Text>
              Please check address details for tax calculation with Avalara.
            </Banner>
          )}
          <Text {...Lato16Bold}>Account information</Text>
          <Flex direction="column">
            <TextField
              label="Company name"
              value={fieldsConfig.legalName.value}
              onChange={fieldsConfig.legalName.onChange}
              validationErrors={fieldsConfig.legalName.validationErrors}
              isDisabled={fieldsConfig.legalName.disabled}
              autoFocus
            />
          </Flex>

          <Text {...Lato16Bold}>Billing information</Text>

          <ContactSelector fieldConfig={fieldsConfig.contacts} />

          <Text {...Lato14Bold} color={GreyGrey80}>
            Billing details
          </Text>

          <Flex direction="column">
            <TextField
              isInvalid={avalaraAddressError}
              value={fieldsConfig['address.line1'].value}
              onChange={fieldsConfig['address.line1'].onChange}
              placeholder="Address line 1"
              validationErrors={fieldsConfig['address.line1'].validationErrors}
            />

            <TextField
              isInvalid={avalaraAddressError}
              value={fieldsConfig['address.line2'].value}
              onChange={fieldsConfig['address.line2'].onChange}
              placeholder="Address line 2 (optional)"
              validationErrors={[]}
            />

            <TextField
              isInvalid={avalaraAddressError}
              value={fieldsConfig['address.town'].value}
              onChange={fieldsConfig['address.town'].onChange}
              placeholder="City/town"
              validationErrors={fieldsConfig['address.town'].validationErrors}
            />

            {fieldsConfig['address.state'].visible && (
              <SelectField
                isInvalid={avalaraAddressError}
                value={fieldsConfig['address.state'].value ?? ''}
                onChange={newStateValue =>
                  fieldsConfig['address.state'].onChange(newStateValue as never)
                }
                options={fieldsConfig['address.state'].options}
                placeholder="Choose state..."
                validationErrors={
                  fieldsConfig['address.state'].validationErrors
                }
              />
            )}

            <Box mb="16px">
              <FormControl isInvalid={avalaraAddressError}>
                <InputSelectControlLegacyStyleBridge
                  triggerStyle={{ paddingLeft: 16 }}
                  initialValue={fieldsConfig['address.country'].value}
                  onChange={newCountryValue =>
                    fieldsConfig['address.country'].onChange(
                      newCountryValue as Country
                    )
                  }
                  options={fieldsConfig['address.country'].options}
                  placeholder="Select country"
                />
              </FormControl>
            </Box>

            <TextField
              isInvalid={avalaraAddressError}
              value={fieldsConfig['address.postcode'].value}
              onChange={fieldsConfig['address.postcode'].onChange}
              validationErrors={
                fieldsConfig['address.postcode'].validationErrors
              }
            />

            <SelectField
              label="Tax status"
              data-testid="tax-status"
              value={fieldsConfig.taxStatus.value}
              onChange={newTaxStatus =>
                fieldsConfig.taxStatus.onChange(newTaxStatus as TaxStatus)
              }
              options={fieldsConfig.taxStatus.options}
              validationErrors={fieldsConfig.taxStatus.validationErrors}
              isDisabled={fieldsConfig.taxStatus.disabled}
              placeholder="Select tax status"
            />
          </Flex>

          {fieldsConfig.showTaxRegistration.visible && (
            <Flex flex={1} alignItems="center" justifyContent="space-between">
              <Checkbox
                isChecked={fieldsConfig.showTaxRegistration.value}
                onChange={event => {
                  fieldsConfig.showTaxRegistration.onChange(
                    event.target.checked
                  )
                }}
                mb="16px"
                data-testid="show-tax-registration-checkbox"
              >
                <Text {...Lato14Regular} paddingRight="8px">
                  Add tax registration
                </Text>
              </Checkbox>
              <InfoPopover
                title="Tax registration"
                body="Add a tax registration ID for your customer. This is often used for cross-border transactions."
              />
            </Flex>
          )}
          {fieldsConfig.showTaxRegistration.value && (
            <Flex flexDirection="column">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                flex={1}
                flexDirection="row"
              >
                <Box mr={4}>
                  <InputSelectControlLegacyStyleBridge
                    triggerStyle={{ paddingLeft: 16 }}
                    initialValue={fieldsConfig['taxCountry'].value}
                    onChange={newCountryValue =>
                      fieldsConfig['taxCountry'].onChange(
                        newCountryValue as Country
                      )
                    }
                    options={fieldsConfig['taxCountry'].options}
                    placeholder="Select country"
                    data-testid="tax-country-select"
                  />
                </Box>

                <TextField
                  placeholder="Tax ID"
                  data-testid="tax-id-input"
                  value={fieldsConfig.taxIdentifier.value}
                  onChange={fieldsConfig.taxIdentifier.onChange}
                  validationErrors={fieldsConfig.taxIdentifier.validationErrors}
                  styles={{
                    wrapper: {
                      mb: 0,
                      flex: 1
                    }
                  }}
                />
              </Flex>
              {fieldsConfig['taxState'].visible && (
                <>
                  <Box height="16px"></Box>
                  <SelectField
                    data-testid="tax-state-select"
                    value={fieldsConfig['taxState'].value ?? ''}
                    onChange={newStateValue =>
                      fieldsConfig['taxState'].onChange(newStateValue as State)
                    }
                    options={fieldsConfig['taxState'].options}
                    placeholder="Choose state..."
                    validationErrors={fieldsConfig['taxState'].validationErrors}
                  />
                </>
              )}
            </Flex>
          )}

          <Text {...Lato16Bold}>Other information</Text>
          <Flex direction="column">
            <TextField
              label="Label (optional)"
              value={fieldsConfig.label.value}
              onChange={fieldsConfig.label.onChange}
              validationErrors={fieldsConfig.label.validationErrors}
              isDisabled={fieldsConfig.label.disabled}
            />

            {flags?.customerParentChildRelationship &&
              fieldsConfig.parentId.visible && (
                <Box marginBottom="16px">
                  <Text {...Lato14Bold} marginBottom="8px" color={GreyGrey70}>
                    Parent account (optional)
                  </Text>
                  <InputSelectControlLegacyStyleBridge
                    triggerStyle={{ paddingLeft: 16 }}
                    initialValue={fieldsConfig.parentId.value}
                    onChange={newCountryValue =>
                      fieldsConfig.parentId.onChange(newCountryValue)
                    }
                    options={fieldsConfig.parentId.options}
                    placeholder="Choose parent account"
                  />
                </Box>
              )}

            <TextField
              label="Aliases (optional)"
              value={fieldsConfig.customerAliases.value[0] ?? ''}
              onChange={updatedValue => {
                const otherAliases =
                  fieldsConfig.customerAliases.value.slice(1) ?? []
                fieldsConfig.customerAliases.onChange([
                  updatedValue,
                  ...otherAliases
                ])
              }}
              validationErrors={[]}
            />

            {fieldsConfig.customerAliases.value.length > 1 && (
              <Flex gap="16px" direction="column">
                {fieldsConfig.customerAliases.value
                  .slice(1)
                  .map((alias, index) => (
                    <Flex align="center" gap="4px" key={index}>
                      <TextInput
                        value={alias}
                        onChange={updatedAlias => {
                          // Because we're iterating through .slice(1) on the original values array
                          const originalIndex = index + 1
                          const aliasesBefore =
                            fieldsConfig.customerAliases.value.slice(
                              0,
                              originalIndex
                            )
                          const aliasesAfter =
                            fieldsConfig.customerAliases.value.slice(
                              originalIndex + 1
                            ) ?? []

                          fieldsConfig.customerAliases.onChange([
                            ...aliasesBefore,
                            updatedAlias,
                            ...aliasesAfter
                          ])
                        }}
                      />
                      <IconButton
                        aria-label="Remove alias"
                        minWidth="16px"
                        height="16px"
                        width="16px"
                        icon={<TrashIcon height="16px" width="16px" />}
                        onClick={() =>
                          fieldsConfig.customerAliases.onRemove(index + 1)
                        }
                        variant="ghost2"
                      />
                    </Flex>
                  ))}
              </Flex>
            )}

            <Button
              color={IndigoIndigo50}
              mt={
                fieldsConfig.customerAliases.value.length > 1 ? '16px' : '0px'
              }
              padding="4px"
              height="auto"
              variant="ghost2"
              onClick={fieldsConfig.customerAliases.onAddNew}
              leftIcon={
                <PlusIcon color={IndigoIndigo50} height={16} width={16} />
              }
            >
              Add another customer alias
            </Button>
          </Flex>
        </Flex>
      </SimpleModalUI>
    </ModalContextProvider>
  )
}
