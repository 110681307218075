import deepmerge from 'deepmerge'
import {
  ActionHandler,
  PricingEditorReducerState,
  UpdatePricingEditorDataAction
} from '../pricingEditor.domain.types'

export const updatePricingEditorData: ActionHandler<
  UpdatePricingEditorDataAction
> = prevState => action => {
  return {
    ...prevState,
    data: {
      ...prevState.data,
      pricingEditorData: deepmerge(
        prevState.data.pricingEditorData,
        action.payload,
        {
          arrayMerge: (_, source: unknown[]) => source
        }
      )
    }
  } as PricingEditorReducerState
}
